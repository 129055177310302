import request from '@/utils/request'

// 积分列表
export function integralList(data) {
  return request({
    url: `/mall/pointsrecord/queryCustomerPointsSummaryForPage`,
    method: 'post',
    data,
  })
}

// 积分明细
export function ReportForPage(data) {
  return request({
    url: `/mall/pointsrecord/queryCustomerPointsReportForPage`,
    method: 'post',
    data,
  })
}
