var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "cont-value" }, [
      _c("div", { staticClass: "misr-title" }, [_vm._v("计划名称")]),
      _c("div", { staticClass: "value-content" }, [
        _vm._v(_vm._s(_vm.Projectdetails.planName)),
      ]),
    ]),
    _c("div", { staticClass: "cont-value" }, [
      _c("div", { staticClass: "misr-title" }, [_vm._v("计划类型")]),
      _c("div", { staticClass: "value-content" }, [
        _vm._v(_vm._s(_vm.Projectdetails.planType)),
      ]),
    ]),
    _c("div", { staticClass: "cont-value" }, [
      _c("div", { staticClass: "misr-title" }, [_vm._v("计划图片")]),
      _c(
        "div",
        { staticClass: "value-content" },
        _vm._l(_vm.Projectdetails.planLogo, function (ym, index) {
          return _c("el-image", {
            key: index,
            staticStyle: { width: "100px", height: "100px" },
            attrs: { "preview-src-list": _vm.Projectdetails.planLogo, src: ym },
          })
        }),
        1
      ),
    ]),
    _c("div", { staticClass: "cont-value" }, [
      _c("div", { staticClass: "misr-title" }, [_vm._v("计划类型执行方案")]),
      _c(
        "div",
        { staticClass: "value-content", staticStyle: { width: "100%" } },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.Projectdetails.vitMealPlanOptPos },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "天数",
                  prop: "optDay",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v("第" + _vm._s(scope.row.optDay) + "天"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "时间",
                  prop: "optTime",
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.optionVos, function (item, ind) {
                        return _c("el-time-select", {
                          key: ind,
                          attrs: {
                            disabled: "",
                            "picker-options": {
                              start: "00:00",
                              end: "23:59",
                            },
                            placeholder: "选择时间",
                            value: item,
                          },
                          model: {
                            value: item.optTime,
                            callback: function ($$v) {
                              _vm.$set(item, "optTime", $$v)
                            },
                            expression: "item.optTime",
                          },
                        })
                      })
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "预计执行时长（分）",
                  prop: "optNum",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.optionVos, function (item, ind) {
                        return _c("el-input", {
                          key: ind,
                          attrs: {
                            disabled: "",
                            placeholder: "请输入预计执行时长",
                          },
                          model: {
                            value: item.optNum,
                            callback: function ($$v) {
                              _vm.$set(item, "optNum", $$v)
                            },
                            expression: "item.optNum",
                          },
                        })
                      })
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "执行内容", prop: "optInfo" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "add-tablea" },
                          _vm._l(scope.row.optionVos, function (item, ind) {
                            return _c("el-input", {
                              key: ind,
                              attrs: {
                                disabled: "",
                                placeholder: "请输入执行内容",
                                type: "textarea",
                              },
                              model: {
                                value: item.optInfo,
                                callback: function ($$v) {
                                  _vm.$set(item, "optInfo", $$v)
                                },
                                expression: "item.optInfo",
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "cont-value" }, [
      _c("div", { staticClass: "misr-title" }, [_vm._v("计划类型执行说明")]),
      _c(
        "div",
        { staticClass: "value-content", staticStyle: { width: "100%" } },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.Projectdetails.vitMealPlanDesPos },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "标题",
                  prop: "desTitle",
                  "show-overflow-tooltip": "",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "标题内容",
                  prop: "desContent",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }