import { render, staticRenderFns } from "./index.vue?vue&type=template&id=08df9e1e"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\健康约-后管-前端\\health-front-backend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('08df9e1e')) {
      api.createRecord('08df9e1e', component.options)
    } else {
      api.reload('08df9e1e', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=08df9e1e", function () {
      api.rerender('08df9e1e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mall/health/afterRecordView/components/beforcont/index.vue"
export default component.exports