<template>
  <div class="mt40">
    <el-row class="mt30">
      <el-col class="mb20">
        <span class="themeTitel">活动列表：</span>
        <el-button plain type="primary" @click="handleAdd()">添加</el-button>
      </el-col>
      <el-table border :data="actList" default-expand-all>
        <el-table-column
          align="center"
          :formatter="formatterActType"
          label="活动类型"
          prop="actName"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="实例名称"
          prop="instName"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="实例封面"
          prop="instLogo"
          show-overflow-tooltip
        >
          <template #default="{ row }">
            <div>
              <img
                alt=""
                :src="row.instLogo"
                width="50px"
                @click="handleImgClick(row.instLogo)"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="活动时间"
          prop="startDate"
          show-overflow-tooltip
        >
          <template #default="{ row }">
            <div>
              <div>开始：{{ row.startTime | formatDate }}</div>
              <div>结束：{{ row.endTime | formatDate }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="发布时间"
          prop="publishTime"
          show-overflow-tooltip
        >
          <template #default="{ row }">
            <div v-if="row.publishTime">
              {{ row.publishTime | formatDate }}
            </div>
            <div v-else>{{ row.updateTime | formatDate }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :formatter="formatterActState"
          label="状态"
          prop="actStatus"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="排序"
          prop="instSort"
          show-overflow-tooltip
        >
          <template #default="{ row }">
            <div>
              <el-input-number
                v-model="row.instSort"
                controls-position="right"
                :max="1000"
                :min="1"
                @change="handleChange(row)"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" min-width="120px">
          <template #default="{ row }">
            <el-button type="text" @click="handleDelete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <div class="pay-button-group mt20">
      <el-button :loading="loading" type="primary" @click="handleSubmit">
        保存
      </el-button>
    </div>
    <!-- 添加弹框 -->
    <el-dialog
      v-if="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      title="活动选择"
      :visible.sync="dialogVisible"
      width="60%"
      @close="close"
    >
      <el-form
        ref="queryForm"
        :inline="true"
        :model="queryForm"
        @submit.native.prevent
      >
        <el-form-item label="选择活动">
          <el-select
            v-model="queryForm.actType"
            class="filterItems"
            clearable
            placeholder="请选择"
            @change="selectActType"
          >
            <el-option
              v-for="item in actTypeList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <el-table
        border
        :data="actTableList"
        default-expand-all
        :row-key="getRowKeys"
        @selection-change="setSelectRows"
      >
        <el-table-column
          :reserve-selection="true"
          :selectable="checkboxTable"
          show-overflow-tooltip
          type="selection"
          width="40px"
        />
        <el-table-column
          align="center"
          :formatter="formatterActType"
          label="活动类型"
          prop="actType"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="实例名称"
          prop="instName"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="实例封面"
          prop="instLogo"
          show-overflow-tooltip
        >
          <template #default="{ row }">
            <div>
              <img
                alt=""
                :src="row.instLogo"
                width="50px"
                @click="handleImgClick(row.instLogo)"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="活动时间"
          prop="startDate"
          show-overflow-tooltip
        >
          <template #default="{ row }">
            <div>
              <div>开始：{{ row.startTime | formatDate }}</div>
              <div>结束：{{ row.endTime | formatDate }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="发布时间"
          prop="publishTime"
          show-overflow-tooltip
        >
          <template #default="{ row }">
            <div v-if="row.publishTime">
              {{ row.publishTime | formatDate }}
            </div>
            <div v-else>{{ row.updateTime | formatDate }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :formatter="formatterActState"
          label="状态"
          prop="instStatus"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="排序"
          prop="instSort"
          show-overflow-tooltip
        />
      </el-table>
      <el-row v-if="selectRowsList" class="mt10">
        已选择（{{ selectRowsList.length }}）个实例
      </el-row>
      <el-row v-else class="mt10">已选择（0）个实例</el-row>
      <!-- 分页 -->
      <el-pagination
        background
        :current-page="forms.currentPage"
        :layout="layout"
        :page-size="forms.pageSize"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
      <template #footer>
        <el-button plain @click="close">取消</el-button>
        <el-button plain type="primary" @click="handleSave">确认</el-button>
      </template>
    </el-dialog>
    <!-- 图片预览 -->
    <el-dialog :visible.sync="imgDialog">
      <img :src="imagesUrl" width="100%" />
    </el-dialog>
  </div>
</template>
<script>
  import { selectSysDict } from '@/api/mall/common'
  import {
    addThemeInstInfo,
    actListPage,
    detailsActList,
    editActSort,
  } from '@/api/activity/theme'

  export default {
    name: 'StepTwo',
    props: {
      infos: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        imgDialog: false,
        imagesUrl: '',
        dialogVisible: false,
        actTableList: [],
        queryForm: {
          actType: '10',
        },
        isShow: true,
        forms: { currentPage: 1, pageSize: 10 },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        actList: [],
        actTypeList: [],
        actPlatformLsit: [],
        actStateList: [],
        loading: false,
        selectRowsList: null,
        currentPage: 1,
        pageSize: 100,
      }
    },

    created() {
      this.selectSysDict()
      if (this.infos.id || this.infos.tip) {
        this.lista()
      }
    },
    methods: {
      // 若列表有当前数据则禁止选中
      checkboxTable(row) {
        const actLists =
          this.actList &&
          this.actList.map((item) => {
            return item.instId
          })
        if (actLists.includes(row.instId)) {
          return 0
        } else {
          return 1
        }
      },
      // 选择活动表格操作
      setSelectRows(val) {
        this.selectRowsList = val
      },
      getRowKeys(row) {
        return row.instId
      },
      // 排序
      handleChange(row) {
        // 编辑排序
        editActSort({
          id: row.instId,
          instSort: row.instSort,
        })
          .then(() => {
            // this.MS(res.message)
          })
          .catch(() => {})
      },
      // 图片预览
      handleImgClick(url) {
        this.imagesUrl = url
        this.imgDialog = true
      },
      // 详情(活动列表)
      async lista() {
        await detailsActList({
          themeId: this.infos.id,
          currentPage: this.currentPage,
          pageSize: this.pageSize,
        }).then((res) => {
          if (res.data) {
            this.actList = res.data.records
          }
        })
      },
      // 删除
      handleDelete(row) {
        this.actList.forEach((item, index) => {
          if (row.instId) {
            if (row.instId == item.instId) {
              this.actList.splice(index, 1)
            }
          }
        })
      },
      // 选择活动确认
      handleSave() {
        if (this.selectRowsList) {
          if (this.actList.length > 0) {
            this.selectRowsList.map((item) => {
              this.actList.push(item)
            })
          } else {
            this.actList = this.selectRowsList
          }
          this.close()
        } else {
          return this.ME('请勾选活动')
        }
      },
      // 下一步
      handleSubmit() {
        if (this.actList.length == 0) return this.ME('请添加活动')
        const actLists =
          this.actList &&
          this.actList.map((item) => {
            return item.instId
          })
        this.loading = false
        if (this.infos.id && !this.infos.tip) {
          // 编辑
          addThemeInstInfo({
            themeId: this.infos.id,
            instIds: actLists,
          })
            .then((res) => {
              this.MS(res.message)
              this.$router.push({
                path: 'theme-list',
              })
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else if (!this.infos.tip && !this.infos.id) {
          // 新增
          addThemeInstInfo({
            themeId: this.infos.themeId,
            instIds: actLists,
          })
            .then((res) => {
              this.MS(res.message)
              this.$router.push({
                path: 'theme-list',
              })
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else if (this.infos.tip && this.infos.id) {
          // 复制
          addThemeInstInfo({
            themeId: this.infos.themeId,
            instIds: actLists,
          })
            .then((res) => {
              this.MS(res.message)
              this.$router.push({
                path: 'theme-list',
              })
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        }
      },
      // 上一步
      handlePrev() {
        this.$emit('change-step', 2)
      },
      // 活动类型
      formatterActType(row) {
        for (let i = 0; i < this.actTypeList.length; i++) {
          if (this.actTypeList[i].dictKey == row.actType) {
            return this.actTypeList[i].dictValue
          }
        }
      },
      // 活动状态
      formatterActState(row) {
        for (let i = 0; i < this.actStateList.length; i++) {
          if (this.actStateList[i].dictKey == row.instStatus) {
            return this.actStateList[i].dictValue
          }
        }
      },
      // 字典
      async selectSysDict() {
        await selectSysDict({
          dictTypeList: ['actType', 'actPlatform', 'instanceStatus'],
        })
          .then((res) => {
            if (res.data) {
              this.actTypeList = res.data[0].dictInfoVoList
              this.actPlatformLsit = res.data[1].dictInfoVoList
              this.actStateList = res.data[2].dictInfoVoList
            }
          })
          .catch(() => {})
      },

      // 选择活动类型
      selectActType(val) {
        this.forms.currentPage = 1
        this.queryForm.actType = val
        this.fetchData()
      },
      // 关闭
      close() {
        this.dialogVisible = false
        this.selectRowsList = null
      },

      // 添加
      handleAdd() {
        this.dialogVisible = true
        this.fetchData()
        this.selectSysDict()
      },
      // (活动列表)
      async fetchData(options) {
        await actListPage({
          ...options,
          ...this.forms,
          actType: this.queryForm.actType,
        }).then((res) => {
          if (res.data) {
            this.actTableList = res.data.records
            this.total = res.data.total
          }
        })
      },
      // 分页
      handleSizeChange(val) {
        this.forms.pageSize = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
      // 分页
      handleCurrentChange(val) {
        this.forms.currentPage = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .pay-button-group {
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }
  .refundTitel {
    margin-top: 30px;
    font-weight: 700;
    padding-left: 5px;
    border-left: 4px solid rgb(0, 122, 255);
  }
</style>
