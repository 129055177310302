var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c("div", { staticClass: "header-title" }, [_vm._v("添加计划")]),
      _c(
        "el-form",
        { attrs: { "label-width": "80px", model: _vm.subForm } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "计划名称", prop: "planName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入计划名称" },
                model: {
                  value: _vm.subForm.planName,
                  callback: function ($$v) {
                    _vm.$set(_vm.subForm, "planName", $$v)
                  },
                  expression: "subForm.planName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "计划类型", prop: "planType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择计划类型" },
                  model: {
                    value: _vm.subForm.planType,
                    callback: function ($$v) {
                      _vm.$set(_vm.subForm, "planType", $$v)
                    },
                    expression: "subForm.planType",
                  },
                },
                _vm._l(_vm.platype, function (ym, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: ym.planName, value: ym.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "计划图片", prop: "planLogo" } },
            [_c("YmUpload", { on: { backkuck: _vm.check } })],
            1
          ),
          _c("el-form-item", { attrs: { label: "执行计划", prop: "" } }),
          _c(
            "el-table",
            {
              staticClass: "plans-table",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.subForm.vitMealPlanOptPos },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "天数",
                  prop: "date",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { "line-height": "30px" } }, [
                          _vm._v("第" + _vm._s(scope.$index + 1) + "天"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    align: "center",
                    label: "时间",
                    prop: "optTime",
                    width: "200",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._l(
                          scope.row.vitMealPlanOptionPos,
                          function (item, ind) {
                            return _c("el-time-select", {
                              key: ind,
                              attrs: {
                                "picker-options": {
                                  start: "00:00",
                                  end: "23:59",
                                },
                                placeholder: "选择时间",
                                value: item,
                              },
                              model: {
                                value: item.optTime,
                                callback: function ($$v) {
                                  _vm.$set(item, "optTime", $$v)
                                },
                                expression: "item.optTime",
                              },
                            })
                          }
                        )
                      },
                    },
                  ]),
                },
                [_vm._v(' " ')]
              ),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "预计执行时长(分)",
                  prop: "optNum",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.vitMealPlanOptionPos,
                        function (item, ind) {
                          return _c("el-input", {
                            key: ind,
                            attrs: { placeholder: "请输入预计执行时长" },
                            model: {
                              value: item.optNum,
                              callback: function ($$v) {
                                _vm.$set(item, "optNum", $$v)
                              },
                              expression: "item.optNum",
                            },
                          })
                        }
                      )
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "执行内容", prop: "optInfo" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "add-tablea" },
                          _vm._l(
                            scope.row.vitMealPlanOptionPos,
                            function (item, ind) {
                              return _c("el-input", {
                                key: ind,
                                attrs: { placeholder: "请输入执行内容" },
                                model: {
                                  value: item.optInfo,
                                  callback: function ($$v) {
                                    _vm.$set(item, "optInfo", $$v)
                                  },
                                  expression: "item.optInfo",
                                },
                              })
                            }
                          ),
                          1
                        ),
                        _c("el-input", {
                          attrs: { placeholder: "添加执行内容" },
                          on: {
                            change: function ($event) {
                              return _vm.addTabel(scope.$index)
                            },
                          },
                          model: {
                            value: scope.row.addTable,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "addTable", $$v)
                            },
                            expression: "scope.row.addTable",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  prop: "name",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.vitMealPlanOptionPos,
                        function (item, ind) {
                          return _c(
                            "div",
                            { key: ind, staticClass: "sc" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkdel(ind, scope.$index)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          )
                        }
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "addDaye", on: { click: _vm.addDayeplay } },
            [_vm._v("添加下一天执行方案")]
          ),
          _c("el-form-item", { attrs: { label: "计划说明", prop: "" } }),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.subForm.vitMealPlanDesPos },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "标题",
                  prop: "desTitle",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { placeholder: "请输入标题" },
                          model: {
                            value: scope.row.desTitle,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "desTitle", $$v)
                            },
                            expression: "scope.row.desTitle",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "标题内容",
                  prop: "desContent",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { placeholder: "请输入标题内容" },
                          model: {
                            value: scope.row.desContent,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "desContent", $$v)
                            },
                            expression: "scope.row.desContent",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "addDaye", on: { click: _vm.addDayered } }, [
            _vm._v("添加下一条说明"),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                margin: "auto",
                display: "flex",
                "justify-content": "center",
              },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("添加计划")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }