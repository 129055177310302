<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogFormVisible" @close="close">
      <ul v-for="(item, index) in evaluteList" :key="index" class="evaluteUl">
        <li class="colorBlue">商品名称：{{ item.goodsName }}</li>
        <li class="mt10">
          <el-row :gutter="20">
            <el-col :span="6">评价时间：{{ item.evaluteTime }}</el-col>
            <el-col :span="6" style="display: flex">
              商品评分：
              <el-rate
                v-model="values"
                disabled
                score-template="{value}"
                show-score
                text-color="#ff9900"
              />
            </el-col>
            <el-col :span="6" style="display: flex">
              服务评分：
              <el-rate
                v-model="values"
                disabled
                score-template="{value}"
                show-score
                text-color="#ff9900"
              />
            </el-col>
            <el-col :span="6" style="display: flex">
              物流评分：
              <el-rate
                v-model="values"
                disabled
                score-template="{value}"
                show-score
                text-color="#ff9900"
              />
            </el-col>
          </el-row>
        </li>
        <li class="mt20">
          <span>{{ item.evalut }}</span>
        </li>
        <li class="mt10">
          <el-image class="evaluteImg" :src="item.img" />
        </li>
        <li class="color117 mt20">回复内容</li>
        <li class="mt20">
          <el-input v-model="evalute" type="textarea" />
        </li>
      </ul>
      <template #footer>
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        values: 4,
        dialogFormVisible: false,
        title: '评价回复',
        evalute: '',
        evaluteList: [
          {
            id: 1,
            goodsName: '贵州飞天茅台',
            img: '',
            evaluteTime: '2012-10-12',
            evalut: '喝起真的安逸，巴适得很，不信你来整两瓶，爽歪歪',
          },
        ],
      }
    },
    methods: {
      // 弹框
      showEdit() {
        this.dialogFormVisible = true
      },
      // 弹框关闭
      close() {
        this.dialogFormVisible = false
      },
      // 确定
      save() {},
    },
  }
</script>
<style lang="scss" scoped>
  .evaluteImg {
    width: 100px;
    height: 100px;
    margin-right: 10px;
  }
  .el-textarea__inner {
    min-height: 100px;
  }
</style>
