var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cont" },
    [
      _c(
        "el-form",
        {
          ref: "FormData",
          attrs: {
            "label-position": "top",
            "label-width": "100px",
            model: _vm.formdate,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商品:" } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    data: _vm.date.checkout,
                    height: "390px",
                    "row-class-name": "rowClassName",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", label: "商品信息" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "shop_info",
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "60px",
                                    height: "60px",
                                    "margin-right": "8px",
                                  },
                                  attrs: { src: scope.row.picUrl },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "shop_msg",
                                    staticStyle: { width: "calc(100% - 60px)" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "shop name speacvname",
                                        staticStyle: { "text-align": "left" },
                                      },
                                      [_vm._v(_vm._s(scope.row.name))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "shop name",
                                        staticStyle: {
                                          display: "flex",
                                          color: "red",
                                          "text-align": "left",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " ¥ " +
                                            _vm._s(scope.row.salesPrice) +
                                            "   "
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "shop name",
                                            staticStyle: {
                                              "font-size": "12px",
                                              color: "gray",
                                              "text-align": "left",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "规格:" +
                                                _vm._s(
                                                  scope.row.specValueNames
                                                ) +
                                                " 库存:" +
                                                _vm._s(scope.row.stock)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "flx",
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "right",
                                },
                              },
                              [
                                _c("el-input-number", {
                                  attrs: { placeholder: "", size: "mini" },
                                  on: { change: _vm.quanmun },
                                  model: {
                                    value: scope.row.quantity,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "quantity", $$v)
                                    },
                                    expression: "scope.row.quantity",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "cel",
                                    staticStyle: {
                                      width: "150px",
                                      "margin-left": "10px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: {
                                          placeholder: "",
                                          size: "mini",
                                        },
                                        on: { change: _vm.sales },
                                        model: {
                                          value: scope.row.paymentPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "paymentPrice",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.paymentPrice",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "append" }, [
                                          _vm._v("元"),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.remove(scope.$index, scope.row)
                                  },
                                },
                              },
                              [_vm._v("移除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "con" }, [
            _c("div", { staticClass: "span" }, [_vm._v("物流方式:")]),
            _c(
              "div",
              { staticClass: "showcont" },
              [
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { label: "" },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.formdate.deliveryWay,
                          callback: function ($$v) {
                            _vm.$set(_vm.formdate, "deliveryWay", $$v)
                          },
                          expression: "formdate.deliveryWay",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("快递配送"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.uploadClick },
                      },
                      [_vm._v("上传")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "d",
                    staticStyle: {
                      display: "grid",
                      "grid-template-columns": "repeat(2, 1fr)",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "订单类型:", required: true } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "请选择 订单类型" },
                            on: {
                              focus: function ($event) {
                                return _vm.queryPages(0)
                              },
                            },
                            model: {
                              value: _vm.formdate.busType,
                              callback: function ($$v) {
                                _vm.$set(_vm.formdate, "busType", $$v)
                              },
                              expression: "formdate.busType",
                            },
                          },
                          _vm._l(_vm.dbotions, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.labelName, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "复购次数:", required: true } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "请选择 复购次数" },
                            on: {
                              focus: function ($event) {
                                return _vm.queryPages(1)
                              },
                            },
                            model: {
                              value: _vm.formdate.repNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.formdate, "repNum", $$v)
                              },
                              expression: "formdate.repNum",
                            },
                          },
                          _vm._l(_vm.dotions, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.labelName, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "是否加急:", required: true } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "请选择 是否加急" },
                            model: {
                              value: _vm.formdate.urgState,
                              callback: function ($$v) {
                                _vm.$set(_vm.formdate, "urgState", $$v)
                              },
                              expression: "formdate.urgState",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "加急", value: "0" },
                            }),
                            _c("el-option", {
                              attrs: { label: "不加急", value: "1" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "预存金额：", prop: "prestoreMoney" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请输入 预存金额" },
                          model: {
                            value: _vm.formdate.prestoreMoney,
                            callback: function ($$v) {
                              _vm.$set(_vm.formdate, "prestoreMoney", $$v)
                            },
                            expression: "formdate.prestoreMoney",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.formdate.deliveryWay == 1
                              ? "手机号:"
                              : "联系电话:",
                          prop: "telNum",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请输入 手机号" },
                          model: {
                            value: _vm.formdate.telNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.formdate, "telNum", $$v)
                            },
                            expression: "formdate.telNum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.formdate.deliveryWay == 1
                              ? "收货人:"
                              : "联系人:",
                          prop: "userName",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请输入 收货人" },
                          model: {
                            value: _vm.formdate.userName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formdate, "userName", $$v)
                            },
                            expression: "formdate.userName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "地区:", prop: "province" } },
                  [
                    _c("el-cascader", {
                      staticClass: "full-width",
                      staticStyle: { width: "80%" },
                      attrs: {
                        options: _vm.areaSelectData,
                        placeholder: "请选择地址",
                        props: {
                          children: "children",
                          label: "areaName",
                          value: "areaName",
                        },
                        size: "large",
                      },
                      on: { change: _vm.addres },
                      model: {
                        value: _vm.formdate.provinces,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formdate,
                            "provinces",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formdate.provinces",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "详细地址:", prop: "address" } },
                  [
                    _c(
                      "div",
                      { staticClass: "ro", staticStyle: { display: "flex" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请输入 详细地址",
                            type: "textarea",
                          },
                          model: {
                            value: _vm.formdate.address,
                            callback: function ($$v) {
                              _vm.$set(_vm.formdate, "address", $$v)
                            },
                            expression: "formdate.address",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "ym_head_title",
                    staticStyle: {
                      "margin-top": "10px",
                      "margin-bottom": "30px",
                    },
                  },
                  [_vm._v("价格明细")]
                ),
                _c(
                  "el-form",
                  {
                    ref: "FormData",
                    staticStyle: {
                      display: "flex",
                      "align-items": "flex-start",
                    },
                    attrs: {
                      "label-position": "top",
                      "label-width": "100px",
                      model: _vm.skus,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "font-weight": "bold" },
                        attrs: { label: "运费:" },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            staticStyle: {
                              width: "190px",
                              "margin-top": "5px",
                            },
                            attrs: { placeholder: "请输入 运费" },
                            on: { input: _vm.freightPric },
                            model: {
                              value: _vm.freightPrice,
                              callback: function ($$v) {
                                _vm.freightPrice = $$v
                              },
                              expression: "freightPrice",
                            },
                          },
                          [_c("template", { slot: "append" }, [_vm._v("元")])],
                          2
                        ),
                      ],
                      1
                    ),
                    _c("el-form-item", {
                      staticStyle: {
                        "margin-left": "10px",
                        "font-weight": "bold",
                      },
                      attrs: { label: "明细:" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "item",
                                  staticStyle: {
                                    "margin-top": "0",
                                    "font-size": "12px",
                                    "font-weight": "500",
                                  },
                                },
                                [_vm._v("金额 ￥" + _vm._s(_vm.paymentPrice))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "item",
                                  staticStyle: {
                                    "font-size": "12px",
                                    "font-weight": "500",
                                  },
                                },
                                [
                                  _vm._v(
                                    "运费 ￥" + _vm._s(_vm.freightPrice) + ".00"
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "item",
                                  staticStyle: {
                                    "font-size": "12px",
                                    "font-weight": "500",
                                  },
                                },
                                [
                                  _vm._v(
                                    "到货 ￥" +
                                      _vm._s(
                                        (
                                          _vm.skus.paymentPrice -
                                          _vm.formdate.prestoreMoney
                                        ).toFixed(2)
                                      )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple-dark" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-right": "10px",
                                        "font-size": "12px",
                                        "font-weight": "500",
                                      },
                                    },
                                    [_vm._v("积分折扣")]
                                  ),
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.skus.isPointDiscount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.skus,
                                            "isPointDiscount",
                                            $$v
                                          )
                                        },
                                        expression: "skus.isPointDiscount",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "1" } },
                                        [_vm._v("是")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "0" } },
                                        [_vm._v("否")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "item",
                                  staticStyle: {
                                    "font-size": "12px",
                                    "font-weight": "500",
                                  },
                                },
                                [
                                  _vm._v(" 合计 "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.skus.paymentPrice,
                                        expression: "skus.paymentPrice",
                                      },
                                    ],
                                    attrs: {
                                      disabled: _vm.skus.isPointDiscount == 1,
                                    },
                                    domProps: { value: _vm.skus.paymentPrice },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.skus,
                                          "paymentPrice",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "solt",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "right",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "118px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.settlement },
                      },
                      [_vm._v("结算")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("Upload", {
        attrs: { limitnum: 1, visible: _vm.uploadVisible },
        on: { closeUpload: _vm.closeUpload, fillData: _vm.fillData },
      }),
      _c("logamountdialog", {
        attrs: { logamounthide: _vm.logAmountshow, sound: _vm.listinfo },
        on: { closeloagmount: _vm.closehide },
      }),
      _c("addressdialog", {
        attrs: { showhide: _vm.hide, usercode: _vm.listinfo, userid: _vm.date },
        on: { tranhdie: _vm.transhow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }