var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vab-query-form-top-panel",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: true, "label-width": "89px", model: _vm.queryForm },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "订单编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入订单编号" },
                model: {
                  value: _vm.queryForm.orderNo,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.queryForm,
                      "orderNo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "queryForm.orderNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单状态" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择订单状态" },
                  model: {
                    value: _vm.queryForm.status,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.queryForm,
                        "status",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "queryForm.status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "待付款", value: "0" } }),
                  _c("el-option", { attrs: { label: "待发货", value: "1" } }),
                  _c("el-option", { attrs: { label: "待收货", value: "2" } }),
                  _c("el-option", { attrs: { label: "已完成", value: "3" } }),
                  _c("el-option", { attrs: { label: "已取消", value: "5" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "质检状态" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择质检状态" },
                  model: {
                    value: _vm.queryForm.quaState,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.queryForm,
                        "quaState",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "queryForm.quaState",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "待质检", value: "1" } }),
                  _c("el-option", { attrs: { label: "已通过", value: "2" } }),
                  _c("el-option", { attrs: { label: "已驳回", value: "3" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "物流状态" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择物流状态" },
                  model: {
                    value: _vm.queryForm.logisticsStatus,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.queryForm,
                        "logisticsStatus",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "queryForm.logisticsStatus",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "在途", value: "0" } }),
                  _c("el-option", { attrs: { label: "揽收", value: "1" } }),
                  _c("el-option", { attrs: { label: "疑难", value: "2" } }),
                  _c("el-option", { attrs: { label: "签收", value: "3" } }),
                  _c("el-option", { attrs: { label: "退签", value: "4" } }),
                  _c("el-option", { attrs: { label: "派件", value: "5" } }),
                  _c("el-option", { attrs: { label: "退回", value: "6" } }),
                  _c("el-option", { attrs: { label: "转投", value: "7" } }),
                  _c("el-option", { attrs: { label: "清关", value: "8" } }),
                  _c("el-option", { attrs: { label: "拒签", value: "14" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单日期" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "default-time": ["00:00:00", "23:59:59"],
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                on: { change: _vm.changedate },
                model: {
                  value: _vm.value1,
                  callback: function ($$v) {
                    _vm.value1 = $$v
                  },
                  expression: "value1",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    "native-type": "submit",
                    type: "primary",
                  },
                  on: { click: _vm.searchquery },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-refresh-right",
                    "native-type": "submit",
                    type: "warning",
                  },
                  on: { click: _vm.clearcont },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }