<template>
  <vab-query-form-top-panel>
    <el-form ref="form" :inline="true" label-width="89px" :model="queryForm" @submit.native.prevent>
      <el-form-item label="客户姓名">
        <el-input v-model="queryForm.userName" placeholder="请输入客户姓名" />
      </el-form-item>
      <el-form-item label="客户电话">
        <el-input v-model="queryForm.phone" placeholder="请输入客户电话" />
      </el-form-item>
      <el-form-item label="客户性别">
        <el-select v-model="queryForm.sex" placeholder="请选择客户性别">
          <el-option v-for="item in options" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item label="交流类型">
        <el-select v-model="queryForm.interId" placeholder="请选择交流类型" @focus="queryPages(1)">
          <el-option v-for="item in communication" :key="item.id" :label="item.labelName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item v-show="!fold" label="渠道类型">
        <el-select v-model="queryForm.channelId" placeholder="请选择渠道类型" @focus="queryPages(2)">
          <el-option v-for="item in Channel" :key="item.id" :label="item.labelName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="订单类型">
        <el-select v-model="queryForm.orderKey" placeholder="请选择订单类型" @focus="queryPages(3)">
          <el-option v-for="item in Ordertype" :key="item.id" :label="item.labelName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item v-show="!fold" label="物流类型">
        <el-select v-model="queryForm.logisticsKey" placeholder="请选择物流类型" @focus="queryPages(3)">
          <el-option v-for="item in logisticsKey" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey" />
        </el-select>
      </el-form-item>
      <el-form-item label="注册状态">
        <el-select v-model="queryForm.bindStatus" placeholder="请选择注册状态">
          <el-option v-for="item in rigest" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item label="客户类型">
        <el-select v-model="queryForm.clientId" placeholder="请选择会员类型" @focus="queryPages(5)">
          <el-option v-for="item in Membership" :key="item.id" :label="item.labelName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="导入时间">
        <el-date-picker
          v-model="value1"
          :default-time="['00:00:00', '23:59:59']"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="changedate"
        />
      </el-form-item>
      <el-form-item v-show="!fold" label="复购次数">
        <el-select v-model="queryForm.repNum" placeholder="请选择复购次数">
          <el-option v-for="item in repurchases" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item v-show="!fold" label="客户区域">
        <el-cascader
          v-model.trim="provinces"
          clearable
          :options="areaSelectData"
          :props="{ children: 'children', label: 'areaName', value: 'areaName', checkStrictly: true }"
          @change="addres"
        />
      </el-form-item>

      <el-form-item v-show="!fold" label="分配时间">
        <el-date-picker
          v-model="value2"
          :default-time="['00:00:00', '23:59:59']"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="changedates"
        />
      </el-form-item>
      <el-form-item v-show="!fold" label="购买时间">
        <el-date-picker
          v-model="value3"
          :default-time="['00:00:00', '23:59:59']"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="daterange"
        />
      </el-form-item>
      <el-form-item v-show="!fold" label="初次联系">
        <el-date-picker
          v-model="value4"
          :default-time="['00:00:00', '23:59:59']"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="firstStartTime"
        />
      </el-form-item>
      <el-form-item v-show="!fold" label="末次联系">
        <el-date-picker
          v-model="value5"
          :default-time="['00:00:00', '23:59:59']"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="lastStartTime"
        />
      </el-form-item>
      <el-form-item v-show="!fold" label="风险状态">
        <el-select v-model="queryForm.riskState" placeholder="请选择风险状态">
          <el-option v-for="item in risktatus" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button icon="el-icon-search" native-type="submit" type="primary" @click="searchquery">查询</el-button>
        <el-button icon="el-icon-refresh-right" native-type="submit" type="warning" @click="clearcont">重置</el-button>
        <el-button type="text" @click="handleFold">
          <span v-if="fold">展开</span>
          <span v-else>合并</span>
          <vab-icon class="vab-dropdown" :class="{ 'vab-dropdown-active': fold }" icon="arrow-up-s-line" />
        </el-button>
      </el-form-item>
    </el-form>
  </vab-query-form-top-panel>
</template>

<script>
import { getAreaThree } from '@/api/mall/common'
import { queryPage } from '@/api/mall/health/call/myClient'
import { queryPages } from '@/api/mall/setting/stplabel'
import { selectSysDict } from '@/api/mall/common'
export default {
  data() {
    return {
      areaSelectData: [],
      provinces: null,
      queryForm: {
        menuType: 3, //1:公海管理  2：部门公海 3：我的客户 , 4:客情数据 5： 我的客情  6：客情审核 7：质检数据 8 我的质检
      },
      fold: true,
      value1: null,
      value2: null,
      value3: null,
      value4: null,
      value5: null,
      communication: null,
      options: [
        {
          label: '男',
          key: '1',
        },
        {
          label: '女',
          key: '0',
        },
      ],
      risktatus: [
        {
          label: '有风险',
          key: '1',
        },
        {
          label: '无风险',
          key: '0',
        },
      ],
      rigest: [
        {
          label: '已注册',
          key: '1',
        },
        {
          label: '未注册',
          key: '0',
        },
      ],
      repurchases: [
        {
          label: '未购买',
          key: '0',
        },
        {
          label: '一次购买',
          key: '1',
        },
        {
          label: '两次购买',
          key: '2',
        },
        {
          label: '三次购买',
          key: '3',
        },
        {
          label: '四次购买',
          key: '4',
        },
        {
          label: '五次购买',
          key: '5',
        },
        {
          label: '多次购买',
          key: '6',
        },
      ],
      Channel: null,
      Ordertype: null,
      Membership: null,
      logisticsKey: null,
    }
  },
  created() {
    this.getAreaThree()
    this.selectSysDict()
  },
  methods: {
    clearcont() {
      this.queryForm = {
        menuType: 3,
      }
      this.value1 = []
      this.value2 = []
      this.value3 = []
      this.value4 = []
      this.value5 = []
      this.$emit('receive', false)
      this.$baseEventBus.$emit('Qualityinspection')
    },
    searchquery() {
      queryPage({ ...this.queryForm, size: 10, current: 1 }).then((res) => {
        if (res.data.records) {
          this.$emit('receive', res.data)
        } else {
          this.$message.error('查询为空')
        }
      })
    },
    selectSysDict() {
      selectSysDict({ dictTypeList: ['orderStatus'] }).then((res) => {
        this.logisticsKey = res.data[0].dictInfoVoList
      })
    },
    changedate() {
      this.queryForm.startTime = this.value1[0]
      this.queryForm.endTime = this.value1[1]
      console.log(this.queryForm)
    },
    changedates() {
      this.queryForm.allotStartTime = this.value2[0]
      this.queryForm.allotEndTime = this.value2[1]
    },
    daterange() {
      this.queryForm.buyStartTime = this.value3[0]
      this.queryForm.buyEndTime = this.value3[1]
    },
    firstStartTime() {
      this.queryForm.firstStartTime = this.value4[0]
      this.queryForm.firstEndTime = this.value4[1]
    },
    lastStartTime() {
      this.queryForm.lastStartTime = this.value5[0]
      this.queryForm.lastEndTime = this.value5[1]
    },
    addres(e) {
      this.queryForm.province = e[0]
      this.queryForm.city = e[1]
      this.queryForm.county = e[2]
    },
    async getAreaThree() {
      await getAreaThree()
        .then((res) => {
          if (res.data) {
            this.areaSelectData = res.data
          }
        })
        .catch(() => { })
    },
    queryPages(e) {
      queryPages({ labelType: e }).then((res) => {
        if (e == 1) {
          this.communication = res.data
        }
        if (e == 2) {
          this.Channel = res.data
        }
        if (e == 3) {
          this.Ordertype = res.data
        }
        if (e == 5) {
          this.Membership = res.data
        }
      })
    },
    handleFold() {
      this.fold = !this.fold
      // this.handleHeight()
    },
    reset() {
      this.$emit('receive', false)
    },
    searchbtn() {
      if (this.value1) {
        console.log(this.value1)
        this.form.startTime = this.value1[0]
        this.form.endTime = this.value1[1]
      }
      if (this.value2) {
        this.form.allotStartTime = this.value2[0]
        this.form.allotEndTime = this.value2[1]
      }
      queryPage({ ...this.form }).then((res) => {
        this.$emit('receive', res.data)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-input,
  .el-date-editor {
    width: 210px;
  }
  .el-range-input,
  .el-range-separator {
    font-size: 12px;
  }
  .el-range-separator {
    width: 20px;
  }
}
</style>
