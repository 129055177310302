import request from '@/utils/request'
export function queryPage(data) {
  return request({
    url: `/mall/user/cereActivity/queryPage`,
    method: 'post',
    data,
  })
}
export function queryPageadd(data) {
  return request({
    url: `/mall/user/cereActivity/add`,
    method: 'post',
    data,
  })
}

export function queryPageedit(data) {
  return request({
    url: `/mall/user/cereActivity/edit`,
    method: 'post',
    data,
  })
}

export function queryPageedel(data) {
  return request({
    url: `/mall/user/cereActivity/delete`,
    method: 'post',
    data,
  })
}
