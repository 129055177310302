var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "header",
      style: {
        background: _vm.componentContent[_vm.terminal].pageBgColor,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "search",
          staticStyle: { display: "flex", "align-items": "center" },
          style: {
            paddingTop: _vm.componentContent[_vm.terminal].margin.top + "px",
            paddingBottom:
              _vm.componentContent[_vm.terminal].margin.bottom + "px",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "search-content",
              staticStyle: { display: "flex", width: "100%" },
              style: {
                margin:
                  `0 ${_vm.componentContent[_vm.terminal].margin.both}` + "px",
                marginTop: _vm.componentContent[_vm.terminal].margin.top + "px",
                marginBottom:
                  _vm.componentContent[_vm.terminal].margin.bottom + "px",
              },
            },
            [
              _vm.componentContent[_vm.terminal].searchStyle == 2
                ? _c("img", {
                    staticStyle: {
                      "max-width": "85px",
                      height: "30px",
                      "margin-right": "10px",
                    },
                    attrs: {
                      alt: "",
                      src: _vm.componentContent[_vm.terminal].imageUrl,
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "search-sacnner",
                  staticStyle: {
                    position: "relative",
                    width: "100%",
                    height: "30px",
                  },
                  style: {
                    color: _vm.componentContent[_vm.terminal].textColor,
                    background:
                      _vm.componentContent[_vm.terminal].componentBgColor,
                  },
                },
                [
                  _c("div", { staticClass: "searchcon" }, [
                    _c(
                      "div",
                      {
                        staticClass: "sear-txt",
                        staticStyle: { "line-height": "30px" },
                        style: {
                          "text-indent":
                            _vm.componentContent[_vm.terminal].textAlign ==
                            "left"
                              ? "2em"
                              : "",
                          textAlign:
                            _vm.componentContent[_vm.terminal].textAlign ==
                            "center"
                              ? "center"
                              : "",
                          marginLeft:
                            _vm.componentContent[_vm.terminal].textAlign ==
                            "left"
                              ? "0"
                              : "",
                          borderRadius:
                            _vm.componentContent[_vm.terminal].borderType == "1"
                              ? "0"
                              : "50px",
                          background:
                            _vm.componentContent[_vm.terminal].elementBgColor,
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.componentContent[_vm.terminal].title) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("i", {
                    staticClass: "iconfont icon-sousuo",
                    staticStyle: {
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                    },
                    style: {
                      right:
                        _vm.componentContent[_vm.terminal].searchStyle < 3
                          ? "10px"
                          : "",
                      left:
                        _vm.componentContent[_vm.terminal].searchStyle == 3
                          ? "10px"
                          : "",
                    },
                  }),
                ]
              ),
              _vm.componentContent[_vm.terminal].searchStyle == 3
                ? _c("div", { staticClass: "search-icon" }, [
                    _c("img", {
                      staticStyle: {
                        "max-width": "85px",
                        height: "30px",
                        "margin-left": "10px",
                      },
                      attrs: {
                        alt: "",
                        src: _vm.componentContent[_vm.terminal].imageUrl,
                      },
                    }),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }