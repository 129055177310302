<template>
  <div>
    <div class="user_info" :style="{ borderColor: selectauthor == 2 ? '#1786ff' : '#e5e5e5' }"
      @click="selectuser(checkuser, 2)">
      <div class="user_picurl">
        <el-image lazy :src="checkuser.headimgUrl" style="width: 40px; height: 40px; border-radius: 100%" />
      </div>
      <div class="user_name" style="margin: 2px 10px">姓名:{{ checkuser.nickName }}</div>
      <div class="user_name" style="margin: 2px 10px">编号:{{ checkuser.userCode }}</div>
      <div class="userphone" style="margin: 2px 10px; white-space: nowrap">手机号:{{ checkuser.phone }}</div>
    </div>
    <div class="return">
      <p>回访提醒</p>
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column align="center" label="提醒日期" prop="vistTime" />
        <el-table-column align="center" label="回访内容" prop="content" />
      </el-table>
    </div>
    <div class="return">
      <p>交流记录</p>
      <el-table border :data="tablist" max-height="300">
        <el-table-column align="center" label="序号" type="index" width="55" />
        <el-table-column align="center" label="员工工号" prop="profile">
          <template #default="scope">
            {{ scope.profile || '' }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="通话类型" prop="interType" />
        <el-table-column align="center" label="交流时间" prop="createTime" />
        <el-table-column align="center" label="交流类型" prop="interTitle" show-overflow-tooltip />
        <el-table-column align="center" label="交流内容" prop="interContent" show-overflow-tooltip />
      </el-table>
    </div>
    <div class="return">
      <p>通话记录</p>
      <el-table border :data="tablist1" max-height="300">
        <!-- <el-table-column align="center" type="selection" width="55" /> -->
        <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="50" />
        <el-table-column align="center" label="开始时间" prop="startTime" width="170px" />
        <el-table-column align="center" label="结束时间" prop="endTime" width="170px" />
        <el-table-column align="center" label="坐席工号" prop="agent" show-overflow-tooltip />
        <el-table-column align="center" label="主叫号码" prop="callingPhone" />
        <el-table-column align="center" label="被叫号码" prop="calledPhone" />
        <!-- <el-table-column align="center" label="呼叫时长(秒)" prop="callDuration" /> -->
        <el-table-column align="center" label="振铃时长(秒)" prop="ringingDuration" />
        <el-table-column align="center" label="应答时长(秒)" prop="answerDuration" />
        <el-table-column align="center" label="呼叫状态" prop="callState">
          <template #default="scope">
            <div v-if="scope.row.callState == 0">未接</div>
            <div v-if="scope.row.callState == 1">未转接</div>
            <div v-if="scope.row.callState == 2">呼损</div>
            <div v-if="scope.row.callState == 3">接通后挂断</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="return">
      <p>购物记录</p>
      <el-table border :data="tablist2" max-height="300">
        <el-table-column align="center" label="序号" type="index" width="55" />
        <el-table-column align="center" label="订单编号" prop="orderNo" width="190" />

        <el-table-column align="center" label="下单用户" prop="nickName" width="140" />
        <el-table-column align="center" label="订单金额" prop="paymentPrice" width="100" />
        <el-table-column align="center" label="下单员工" prop="profile" show-overflow-tooltip width="140">
          <template #default="scope">{{ scope.row.realName || '' }}{{ scope.row.profile == '' ? '' : '(' +
            scope.row.profile + ')' }}</template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="statusDesc" show-overflow-tooltip width="80" />

        <el-table-column align="center" label="所购产品" prop="product" show-overflow-tooltip />
        <el-table-column align="center" label="下单地址" prop="address" show-overflow-tooltip />

        <!-- 状态 1-待质检 2-已通过 3-驳回 -->
        <el-table-column align="center" label="质检审核" prop="quaStateDesc" width="80">
          <template #default="{ row }">
            <div>
              <el-tag v-if="row.quaState == 1" type="succes">待质检</el-tag>
              <el-tag v-if="row.quaState == 2" type="danger">已通过</el-tag>
              <el-tag v-if="row.quaState == 3" type="info">已驳回</el-tag>
              <!-- <el-tag v-if="row.quaState == 4"  type="info" >已关联</el-tag> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="质检信息" prop="quaContent" show-overflow-tooltip width="80" />
        <el-table-column align="center" label="预存金额" prop="presAmount" width="80" />
        <el-table-column align="center" label="订单日期" prop="createTime" width="200" />
      </el-table>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/api/mall/users/userLevel'
import { Communication } from '@/api/mall/health/casemy/index'
import { agentrecordList } from '@/api/mall/seas/index'
import { orderrecord } from '@/api/mall/health/casemy/index'
import { uuUserSugarVist } from '@/api/mall/health/call/repurchase'
export default {
  props: {
    userinfo: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      checkuser: [],
      selectauthor: 2,
      tableData: [],
      tablist: [],
      form: {
        //交流记录
        size: 3,
        current: 1,
        recordId: this.userinfo.id,
      },
      tablist1: [], //童话记录
      form1: {
        size: 3,
        current: 1,
        calledPhone: this.userinfo.phone,
      },
      tablist2: [], //购物记录
      form2: {
        size: 3,
        current: 1,
        recordId: this.userinfo.id,
      },
    }
  },
  watch: {
    userinfo(newValue, oldValue) {
      if (newValue != oldValue) {
        // this.userinfo = newValue
        this.seleuser(newValue.userId)
      }
    },
  },
  created() {
    this.seleuser(this.userinfo.userId)
    this.uuUserSugarRecordLog()
    this.uuUserSugarRecordLoga()
    this.uuUserSugarRecordLogs()
    this.uuUserSugarVist()
  },
  onload() {
    this.seleuser(this.userinfo.userId)
    // this.uuUserSugarRecordLog()
    // this.uuUserSugarRecordLoga()
    // this.uuUserSugarRecordLogs()
    // this.uuUserSugarVist()
  },
  onshow() {
    this.getUserInfo()
  },
  mounted() {
    this.$EventBus.$on('repload', () => {
      this.uuUserSugarVist()
    })
  },
  methods: {
    uuUserSugarVist() {
      uuUserSugarVist({ ...this.form }).then((res) => {
        this.tableData = res.data.records
      })
    },
    seleuser(e) {
      getUserInfo({ userCode: e }).then((res) => {
        if (res.data != null) {
          this.checkuser = res.data
        } else {
          this.checkuser = {}
        }
      })
    },
    uuUserSugarRecordLog() {
      Communication({ ...this.form }).then((res) => {
        this.form.size = res.data.size
        this.form.current = res.data.current
        this.tablist = res.data.records
      })
    },
    uuUserSugarRecordLogs() {
      agentrecordList({ ...this.form1 }).then((res) => {
        this.form1.size = res.data.size
        this.form1.current = res.data.current
        this.tablist1 = res.data.records
      })
    },
    uuUserSugarRecordLoga() {
      orderrecord({ ...this.form2 }).then((res) => {
        this.form2.size = res.data.size
        this.form2.current = res.data.current
        this.tablist2 = res.data.records
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user_info {
  display: flex;
  align-items: center;
  width: calc(100% - 16px);
  padding: 5px 0;
  margin: 10px auto;
  margin-top: 10px;
  font-size: 12px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;

  .user_picurl {
    margin-left: 10px;
  }
}
</style>