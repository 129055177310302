// 导入api接口模块
export const panelList = [
  {
    title: '基础组件',
    type: 1,
    classList: [
      {
        title: '搜索栏',
        iconClass: 'search-line',
        type: 'Search',
        undraggable: false, // 不可拖动
        onlyAdmin: true, // 平台端显示
        onlyApp: true, // 只在app显示
        onlyWeixin: true,
        componentContent: {
          // searchStyle: 1,
          // searchLink: {
          //   name: '',
          // },
          // title: '搜索',
          // textAlign: 'left',
          // borderType: 2,
          // iconType: 'img',
          // icon: '',
          // style: {
          //   fontSize: '60',
          //   iconBgColor: [],
          //   iconBgColorDeg: 0,
          //   iconBgImg: '',
          //   bgRadius: 0,
          //   iconColor: ['#000000'],
          //   iconColorDeg: 0,
          // },
          // imageUrl: 'https://cclove.club/xcx/user.jpg',
          // positionWay: 'fixed',
          // id: '6j2zx7w62580',
          // addonName: '',
          // componentName: 'Search',
          // componentTitle: '搜索框',
          // isDelete: 0,
          // pageBgColor: '',
          // textColor: '#303133',
          // componentBgColor: '',
          // topAroundRadius: 0,
          // bottomAroundRadius: 0,
          // elementBgColor: '#FFFFFF',
          // topElementAroundRadius: 0,
          // bottomElementAroundRadius: 0,
          // margin: {
          //   top: 0,
          //   bottom: 0,
          //   both: 5,
          // },
          // imgWidth: '60',
          // imgHeight: '60',
          searchStyle: 2,
          title: '请输入搜索关键词',
          textAlign: 'left',
          borderType: 2,
          iconType: 'img',
          icon: '',
          style: {
            fontSize: '60',
            iconBgColor: [],
            iconBgColorDeg: 0,
            iconBgImg: '',
            bgRadius: 0,
            iconColor: ['#000000'],
            iconColorDeg: 0,
          },
          imageUrl: 'https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png',
          id: '1c949kg3efj4',
          addonName: '',
          componentName: 'Search',
          componentTitle: '搜索框',
          searchLink: {
            name: '',
          },
          isDelete: 0,
          pageBgColor: '',
          textColor: '#303133',
          componentBgColor: '',
          topAroundRadius: 0,
          bottomAroundRadius: 0,
          elementBgColor: '#FFFFFF',
          topElementAroundRadius: 0,
          bottomElementAroundRadius: 0,
          margin: {
            top: 6,
            bottom: 6,
            both: 12,
          },
          imgWidth: '60',
          imgHeight: '60',
          positionWay: 'fixed',
        },
      },   
      {
        title: '顶部tab',
        iconClass: 'table-alt-line',
        type: 'header',
        undraggable: true, // 不可拖
        onlyAdmin: true, // 平台端显示
        onlyApp: true, // 只在app显示
        onlyWeixin: true,
        componentContent: {
          title: '公用头部模板',
          pageBgColor: '#f5f7ff',
          topNavColor: '#04C561',
          topNavBg: true,
          navBarSwitch: true,
          navStyle: '2',
          textNavColor: '#FFFFFF',
          topNavImg: 'https://chongtui.oss-cn-chengdu.aliyuncs.com/upload/goods/ba3e423a08224740ac3735625a83a98c.png',
          moreLink: {
            name: '',
          },
          openBottomNav: true,
          textImgPosLink: 'center',
          mpCollect: false,
          popWindow: {
            imageUrl: '',
            count: -1,
            show: 0,
            link: {
              name: '',
            },
            imgWidth: '',
            imgHeight: '',
          },
          bgUrl: 'https://chongtui.oss-cn-chengdu.aliyuncs.com/upload/goods/adbc414b93f24463a2822516194528e4.png',
          imgWidth: '375',
          imgHeight: '247',
          template: {
            pageBgColor: '',
            textColor: '#303133',
            componentBgColor: '',
            componentAngle: 'round',
            topAroundRadius: 0,
            bottomAroundRadius: 0,
            elementBgColor: '',
            elementAngle: 'round',
            topElementAroundRadius: 0,
            bottomElementAroundRadius: 0,
            margin: {
              top: 0,
              bottom: 0,
              both: 0,
            },
          },
        },
      },
      {
        title: '图文广告',
        iconClass: 'image-2-line',
        type: 'banner',
        componentContent: {
          indicatorIsShow: true,
          indicatorColor: '',
          carouselStyle: 'circle',
          indicatorLocation: 'center',
          list: [
            {
              imageUrl: 'https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png',
              link: {
                name: '',
              },
              imgWidth: '1176',
              imgHeight: '7561',
              id: '23xnjfameiw00',
              imageMode: 'scaleToFill',
            },
          ],
          id: '12olcnw4caq8',
          addonName: '',
          componentName: 'ImageAds',
          componentTitle: '图片广告',
          isDelete: 0,
          pageBgColor: '',
          componentBgColor: '',
          componentAngle: 'round',
          topAroundRadius: 0,
          bottomAroundRadius: 0,
          topElementAroundRadius: 0,
          bottomElementAroundRadius: 0,
          margin: {
            top: 0,
            bottom: 0,
            both: 5,
          },
        },
      },
      {
        title: '图文导航',
        iconClass: 'image-line',
        type: 'Graphicnavigation',
        componentContent: {
          mode: 'graphic', //graphic全显示
          type: 'img',
          showStyle: 'fixed',
          ornament: {
            type: 'default',
            color: '#f60f60',
          },
          rowCount: 5,
          pageCount: 2,
          carousel: {
            type: 'circle',
            color: '#FFFFFF',
          },
          imageSize: 50,
          aroundRadius: 30,
          font: {
            size: 14,
            weight: 'normal',
            color: '#303133',
          },
          list: [
            {
              title: '',
              imageUrl: 'https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png',
              iconType: 'img',
              style: {
                fontSize: '60',
                iconBgColor: [],
                iconBgColorDeg: 0,
                iconBgImg: '',
                bgRadius: 0,
                iconColor: ['#000000'],
                iconColorDeg: 0,
              },
              link: {
                name: '',
              },
              label: {
                control: false,
                text: '',
                textColor: '#FFFFFF',
                bgColorStart: '#FE8681',
                bgColorEnd: '#FF5754',
              },
              icon: '',
              id: '1v99h14wqhr40',
              imgWidth: '605',
              imgHeight: '605',
            },
            {
              title: '',
              imageUrl: 'https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png',
              iconType: 'img',
              style: {
                fontSize: '60',
                iconBgColor: [],
                iconBgColorDeg: 0,
                iconBgImg: '',
                bgRadius: 0,
                iconColor: ['#000000'],
                iconColorDeg: 0,
              },
              link: {
                name: '',
              },
              label: {
                control: false,
                text: '',
                textColor: '#FFFFFF',
                bgColorStart: '#FE8681',
                bgColorEnd: '#FF5754',
              },
              icon: '',
              id: '1v99h14wqhr40',
              imgWidth: '605',
              imgHeight: '605',
            },
            {
              title: '',
              imageUrl: 'https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png',
              iconType: 'img',
              style: {
                fontSize: '60',
                iconBgColor: [],
                iconBgColorDeg: 0,
                iconBgImg: '',
                bgRadius: 0,
                iconColor: ['#000000'],
                iconColorDeg: 0,
              },
              link: {
                name: '',
              },
              label: {
                control: false,
                text: '',
                textColor: '#FFFFFF',
                bgColorStart: '#FE8681',
                bgColorEnd: '#FF5754',
              },
              icon: '',
              id: '1v99h14wqhr40',
              imgWidth: '605',
              imgHeight: '605',
            },
            {
              title: '',
              imageUrl: 'https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png',
              iconType: 'img',
              style: {
                fontSize: '60',
                iconBgColor: [],
                iconBgColorDeg: 0,
                iconBgImg: '',
                bgRadius: 0,
                iconColor: ['#000000'],
                iconColorDeg: 0,
              },
              link: {
                name: '',
              },
              label: {
                control: false,
                text: '',
                textColor: '#FFFFFF',
                bgColorStart: '#FE8681',
                bgColorEnd: '#FF5754',
              },
              icon: '',
              id: '1v99h14wqhr40',
              imgWidth: '605',
              imgHeight: '605',
            },
          ],
          id: '10l2kb3mi4nk',
          addonName: '',
          componentName: 'GraphicNav',
          componentTitle: '图文导航',
          isDelete: 0,
          pageBgColor: '',
          componentBgColor: '#FFFFFF',
          componentAngle: 'round',
          topAroundRadius: 12,
          bottomAroundRadius: 12,
          topElementAroundRadius: 0,
          bottomElementAroundRadius: 0,
          margin: {
            top: 6,
            bottom: 8,
            both: 12,
          },
        },
      },
      {
        title: '多商品组',
        iconClass: 'product-hunt-line',
        type: 'ManyGoodsList',
        onlyAdmin: true, // 平台端显示
        componentContent: {
          style: 'style-2',
          ornament: {
            type: 'default',
            color: '#EDEDED',
          },
          template: 'row1-of2',
          goodsMarginType: 'default',
          goodsMarginNum: 10,
          count: 6,
          sortWay: 'default',
          nameLineMode: 'single',
          imgAroundRadius: 0,
          slideMode: 'scroll',
          theme: 'diy',
          btnStyle: {
            fontWeight: false,
            padding: 0,
            cartEvent: 'detail',
            text: '购买',
            textColor: '#FFFFFF',
            theme: 'diy',
            aroundRadius: 25,
            control: true,
            support: true,
            bgColor: '#FF6A00',
            style: 'button',
            iconDiy: {
              iconType: 'icon',
              icon: '',
              style: {
                fontSize: '60',
                iconBgColor: [],
                iconBgColorDeg: 0,
                iconBgImg: '',
                bgRadius: 0,
                iconColor: ['#000000'],
                iconColorDeg: 0,
              },
            },
          },
          tag: {
            text: '隐藏',
            value: 'hidden',
          },
          goodsNameStyle: {
            color: '#303133',
            control: true,
            fontWeight: false,
          },
          saleStyle: {
            color: '#999CA7',
            control: false,
            support: true,
          },
          priceStyle: {
            mainColor: '#FF6A00',
            mainControl: true,
            lineColor: '#999CA7',
            lineControl: false,
            lineSupport: true,
          },
          list: [
            {
              title: '热卖',
              desc: '热卖推荐',
              sources: 'diy',
              categoryId: 0,
              categoryName: '请选择',
              goodsId: {
                name: '',
              },
            },
            {
              title: '新品',
              desc: '新品推荐',
              sources: 'diy',
              categoryId: 0,
              categoryName: '请选择',
              goodsId: {
                name: '',
              },
            },
            {
              title: '精品',
              desc: '精品推荐',
              sources: 'diy',
              categoryId: 0,
              categoryName: '请选择',
              goodsId: {
                name: '',
              },
            },
            {
              title: '促销',
              desc: '促销推荐',
              sources: 'diy',
              categoryId: 0,
              categoryName: '请选择',
              goodsId: {
                name: '',
              },
            },
          ],
          id: '40r828sqrdw0',
          addonName: '',
          componentName: 'ManyGoodsList',
          componentTitle: '多商品组',
          isDelete: 0,
          pageBgColor: '',
          componentBgColor: '',
          componentAngle: 'round',
          topAroundRadius: 0,
          bottomAroundRadius: 0,
          elementBgColor: '#FFFFFF',
          elementAngle: 'round',
          topElementAroundRadius: 0,
          bottomElementAroundRadius: 0,
          margin: {
            top: 0,
            bottom: 0,
            both: 12,
          },
          headStyle: {
            titleColor: '#303133',
            selectBackground: '#1780ff',
          },
        },
      },
      {
        title: '商品列表',
        iconClass: 'file-list-line',
        type: 'GoodsLiss',
        onlyAdmin: true, // 平台端显示
        componentContent: {
          style: 'style-2',
          ornament: {
            type: 'default',
            color: '#EDEDED',
          },
          template: 'row1-of2',
          goodsMarginType: 'default',
          goodsMarginNum: 10,
          count: 6,
          sortWay: 'default',
          nameLineMode: 'single',
          imgAroundRadius: 0,
          slideMode: 'scroll',
          theme: 'diy',
          btnStyle: {
            fontWeight: false,
            padding: 0,
            cartEvent: 'detail',
            text: '购买',
            textColor: '#FFFFFF',
            theme: 'diy',
            aroundRadius: 25,
            control: true,
            support: true,
            bgColor: '#FF6A00',
            style: 'button',
            iconDiy: {
              iconType: 'icon',
              icon: '',
              style: {
                fontSize: '60',
                iconBgColor: [],
                iconBgColorDeg: 0,
                iconBgImg: '',
                bgRadius: 0,
                iconColor: ['#000000'],
                iconColorDeg: 0,
              },
            },
          },
          tag: {
            text: '隐藏',
            value: 'hidden',
          },
          goodsNameStyle: {
            color: '#303133',
            control: true,
            fontWeight: false,
          },
          saleStyle: {
            color: '#999CA7',
            control: false,
            support: true,
          },
          priceStyle: {
            mainColor: '#FF6A00',
            mainControl: true,
            lineColor: '#999CA7',
            lineControl: false,
            lineSupport: true,
          },
          list: [
            {
              title: '热卖',
              desc: '热卖推荐',
              sources: 'diy',
              categoryId: 0,
              categoryName: '请选择',
              goodsId: {
                name: '',
              },
            },
            {
              title: '新品',
              desc: '新品推荐',
              sources: 'diy',
              categoryId: 0,
              categoryName: '请选择',
              goodsId: {
                name: '',
              },
            },
            {
              title: '精品',
              desc: '精品推荐',
              sources: 'diy',
              categoryId: 0,
              categoryName: '请选择',
              goodsId: {
                name: '',
              },
            },
            {
              title: '促销',
              desc: '促销推荐',
              sources: 'diy',
              categoryId: 0,
              categoryName: '请选择',
              goodsId: {
                name: '',
              },
            },
          ],
          id: '40r828sqrdw0',
          addonName: '',
          componentName: 'GoodsLiss',
          componentTitle: '商品列表',
          isDelete: 0,
          pageBgColor: '',
          componentBgColor: '',
          componentAngle: 'round',
          topAroundRadius: 0,
          bottomAroundRadius: 0,
          elementBgColor: '#FFFFFF',
          elementAngle: 'round',
          topElementAroundRadius: 0,
          bottomElementAroundRadius: 0,
          margin: {
            top: 0,
            bottom: 0,
            both: 12,
          },
          headStyle: {
            titleColor: '#303133',
            selectBackground: '#1780ff',
          },
        },
      },
      {
        title: '会员信息',
        iconClass: 'vip-crown-2-line',
        type: 'brandList',
        onlyAdmin: true, // 平台端显示
        componentContent: {
          style: '1',
          theme: 'diy',
          bgColorStart: '#FF7230',
          bgColorEnd: '#FF1544',
          gradientAngle: '129',
          infoMargin: 12,
          id: '1tkaoxbhavj4',
          addonName: '',
          componentName: 'MemberInfo',
          componentTitle: '会员信息',
          isBtnshow: [
            {
              isTitle: '步数',
              isNumber: '200',
              isShow: true,
            },
            {
              isTitle: '余额',
              isNumber: '100',
              isShow: false,
            },
            {
              isTitle: '积分',
              isNumber: '360',
              isShow: true,
            },
            {
              isTitle: '优惠券',
              isNumber: '460',
              isShow: true,
            },
          ],
          isDelete: 0,
          pageBgColor: '',
          textColor: '#303133',
          componentBgColor: '',
          topAroundRadius: 0,
          bottomAroundRadius: 0,
          elementBgColor: '',
          elementAngle: 'round',
          topElementAroundRadius: 0,
          bottomElementAroundRadius: 0,
          margin: { top: 0, bottom: 0, both: 0 },
        },
      },
      {
        title: '标题',
        iconClass: 'file-text-line',
        type: 'Text',
        componentContent: {
          style: 'style-12',
          styleName: '风格12',
          text: '标题栏',
          link: {
            name: '',
          },
          fontSize: 16,
          fontWeight: 'normal',
          subTitle: {
            text: '副标题',
            color: '#999999',
            fontSize: 14,
            isElementShow: true,
            bgColor: '',
            icon: '',
            fontWeight: 'normal',
          },
          more: {
            text: '查看更多',
            link: {
              name: '',
            },
            isElementShow: true,
            isShow: true,
            color: '#999999',
          },
          id: '6cztetvb6o40',
          addonName: '',
          componentName: 'Text',
          componentTitle: '标题',
          isDelete: 0,
          pageBgColor: '',
          textColor: '#303133',
          componentBgColor: '',
          componentAngle: 'round',
          topAroundRadius: 0,
          bottomAroundRadius: 0,
          topElementAroundRadius: 0,
          bottomElementAroundRadius: 0,
          margin: {
            top: 0,
            bottom: 0,
            both: 0,
          },
        },
      },
      {
        title: '我的订单',
        iconClass: 'file-list-line',
        type: 'MemberMyOrder',
        componentContent: {
          icon: {
            waitPay: {
              title: '待付款',
              icon: 'icondiy icon-system-daifukuan2',
              style: {
                bgRadius: 0,
                fontSize: 75,
                iconBgColor: [],
                iconBgColorDeg: 0,
                iconBgImg: '',
                iconColor: ['#d1a9ff', '#A253FF'],
                iconColorDeg: 0,
              },
            },
            waitSend: {
              title: '待发货',
              icon: 'icondiy icon-system-daifahuo2',
              style: {
                bgRadius: 0,
                fontSize: 75,
                iconBgColor: [],
                iconBgColorDeg: 0,
                iconBgImg: '',
                iconColor: ['#d1a9ff', '#A253FF'],
                iconColorDeg: 0,
              },
            },
            waitConfirm: {
              title: '待收货',
              icon: 'icondiy icon-system-daishouhuo2',
              style: {
                bgRadius: 0,
                fontSize: 75,
                iconBgColor: [],
                iconBgColorDeg: 0,
                iconBgImg: '',
                iconColor: ['#d1a9ff', '#A253FF'],
                iconColorDeg: 0,
              },
            },
            waitUse: {
              title: '待评价',
              icon: 'icondiy icon-system-daishiyong2',
              style: {
                bgRadius: 0,
                fontSize: 75,
                iconBgColor: [],
                iconBgColorDeg: 0,
                iconBgImg: '',
                iconColor: ['#d1a9ff', '#A253FF'],
                iconColorDeg: 0,
              },
            },
            refunding: {
              title: '退换货',
              icon: 'icondiy icon-system-shuhou2',
              style: {
                bgRadius: 0,
                fontSize: 75,
                iconBgColor: [],
                iconBgColorDeg: 0,
                iconBgImg: '',
                iconColor: ['#d1a9ff', '#A253FF'],
                iconColorDeg: 0,
              },
            },
          },
          style: 1,
          id: '6agfxcpp57s0',
          addonName: '',
          componentName: 'MemberMyOrder',
          componentTitle: '我的订单',
          isDelete: 0,
          pageBgColor: '',
          textColor: '#303133',
          componentBgColor: '',
          componentAngle: 'round',
          topAroundRadius: 0,
          bottomAroundRadius: 0,
          elementBgColor: '',
          elementAngle: 'round',
          topElementAroundRadius: 0,
          bottomElementAroundRadius: 0,
          margin: {
            top: 0,
            bottom: 0,
            both: 0,
          },
        },
      },
      {
        title: '文章',
        iconClass: 'article-line',
        type: 'Article',
        componentContent: {
          style: 'style-1',
          sources: 'initial',
          articletype: 1,
          articlesecords: {
            name: '',
          },
          previewList: {
            brand_id_es3tbjtvj: {
              article_title: '文章标题',
              article_abstract: '这里是文章内容',
              read_num: 24,
              category_name: '文章分类',
              create_time: 1662202804,
            },
            brand_id_969c64fjlr: {
              article_title: '文章标题',
              article_abstract: '这里是文章内容',
              read_num: 36,
              category_name: '文章分类',
              create_time: 1662202804,
            },
          },
          count: 8,
          ornament: {
            type: 'default',
            color: '#EDEDED',
          },
          articleIds: [],
          id: '7854i429nbw0',
          addonName: '',
          componentName: 'Article',
          componentTitle: '文章',
          isDelete: 0,
          pageBgColor: '',
          textColor: '#303133',
          componentBgColor: '',
          componentAngle: 'round',
          topAroundRadius: 0,
          bottomAroundRadius: 0,
          elementBgColor: '',
          elementAngle: 'round',
          topElementAroundRadius: 0,
          bottomElementAroundRadius: 0,
          margin: {
            top: 0,
            bottom: 0,
            both: 20,
          },
        },
      },
      {
        title: '健康顾问',
        iconClass: 'health-book-line',
        type: 'Healthconsultant',
        componentContent: {
          style: 'style-1',
          sources: 'initial',
          articletype: 1,
          articlesecords: {
            name: '',
          },
          previewList: {
            brand_id_es3tbjtvj: {
              article_title: '健康顾问',
              article_abstract: '这里是文章内容',
              read_num: 24,
              category_name: '健康顾问分类',
              create_time: 1662202804,
            },
            brand_id_969c64fjlr: {
              article_title: '健康顾问',
              article_abstract: '这里是文章内容',
              read_num: 36,
              category_name: '健康顾问分类',
              create_time: 1662202804,
            },
          },
          count: 8,
          ornament: {
            type: 'default',
            color: '#EDEDED',
          },
          articleIds: [],
          id: '7854i429nbw0',
          addonName: '',
          componentName: 'Healthconsultant',
          componentTitle: '健康顾问',
          isDelete: 0,
          pageBgColor: '',
          textColor: '#303133',
          componentBgColor: '',
          componentAngle: 'round',
          topAroundRadius: 0,
          bottomAroundRadius: 0,
          elementBgColor: '',
          elementAngle: 'round',
          topElementAroundRadius: 0,
          bottomElementAroundRadius: 0,
          margin: {
            top: 0,
            bottom: 0,
            both: 20,
          },
        },
      },
      {
        title: '列表导航',
        iconClass: 'list-ordered',
        type: 'Listnavigation',
        componentContent: {
          articlesecords: {
            name: '',
          },
          style: {
            iconColor: '#999999',
            pgboth: 0,
            showIcon: true,
            marginBottom: 8,
            marginTop: 8,
            marginLeftRight: 0,
            borderRadius: 0,
            itemBgColor: 'transparent',
            bgColor: '#FFFFFF',
            lineColor: '#ededed',
            textColor: '#999999',
            newStyle: '',
            fontSize: 15,
            fontWeight: false,
            contHeight: 40,
          },
          data: [
            {
              text: '文字',
              linkUrl: {
                name: '',
              },
              linkName: '',
              linkType: '',
              iconUrl: 'https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png',
              unicode: '&#xe602',
            },
            {
              text: '文字',
              linkUrl: {
                name: '',
              },
              linkName: '',
              linkType: '',
              iconUrl: 'https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png',
              unicode: '&#xe602',
            },
            {
              text: '文字',
              linkUrl: {
                name: '',
              },
              linkName: '',
              linkType: '',
              iconUrl: 'https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png',
              unicode: '&#xe602',
            },
          ],
          isShow: true,
          id: '7854i429nbw0',
          componentName: 'Listnavigation',
          componentTitle: '列表导航',
          pageBgColor: '',
          componentBgColor: '',
          componentAngle: 'round',
          topAroundRadius: 0,
          bottomAroundRadius: 0,
          elementBgColor: '',
          elementAngle: 'round',
          topElementAroundRadius: 0,
          bottomElementAroundRadius: 0,
          margin: {
            top: 0,
            bottom: 0,
            both: 0,
          },
        },
      },
      {
        title: '魔方',
        iconClass: 'apps-2-line',
        type: 'RubikCube',
        componentContent: {
          mode: 'row1-of2',
          imageGap: 0,
          list: [
            {
              imageUrl: '',
              imgWidth: '328',
              imgHeight: '216',
              previewWidth: 175.5,
              previewHeight: '115.57317073170732px',
              link: {
                name: '',
              },
              imageMode: 'scaleToFill',
            },
            {
              imageUrl: '',
              imgWidth: '328',
              imgHeight: '216',
              previewWidth: 175.5,
              previewHeight: '115.57317073170732px',
              link: {
                name: '',
              },
              imageMode: 'scaleToFill',
            },
            {
              imageUrl: '',
              imgWidth: '328',
              imgHeight: '216',
              previewWidth: 175.5,
              previewHeight: '115.57317073170732px',
              link: {
                name: '',
              },
              imageMode: 'scaleToFill',
            },
          ],
          id: '4kklg85qckg0',
          addonName: '',
          componentName: 'RubikCube',
          componentTitle: '魔方',
          isDelete: 0,
          pageBgColor: '',
          componentBgColor: '',
          componentAngle: 'round',
          topAroundRadius: 0,
          bottomAroundRadius: 0,
          elementAngle: 'round',
          topElementAroundRadius: 0,
          bottomElementAroundRadius: 0,
          margin: {
            top: 0,
            bottom: 0,
            both: 12,
          },
        },
      },
      // {
      //   title: '铺助分割',
      //   iconClass: 'icon-fuzhufenge',
      //   type: 'assistDiv',
      //   componentContent: {
      //     height: 20,
      //     bgColor: '#ffffff',
      //   },
      // },

      // {
      //   title: '自定义',
      //   iconClass: 'icon-mofang1',
      //   type: 'custom',
      //   componentContent: {
      //     layoutType: 'L1',
      //     density: '4',
      //     maxH: 0,
      //     imgClearance: 0,
      //     pageSpacing: 0,
      //     averageBoxData: [], // 记录格子的激活状态
      //     imgBoxActive: 0, // 记录框的位置
      //     elementNum: 1, // 生成格子数量
      //     imgData: [
      //       {
      //         src: '',
      //         linkObj: {
      //           selectValue: '',
      //           selectName: '',
      //           typeText: '',
      //           url: '',
      //         },
      //       },
      //       {
      //         src: '',
      //         linkObj: {
      //           selectValue: '',
      //           selectName: '',
      //           typeText: '',
      //           url: '',
      //         },
      //       },
      //     ],
      //   },
      // },
      // {
      //   title: '视频',
      //   iconClass: 'icon-shipin',
      //   type: 'videoBox',
      //   componentContent: {
      //     title: '标题',
      //     videoUrl: 'http://cereshop.oss-cn-shenzhen.aliyuncs.com/0000/2020/05/432bb17e-536c-4762-a699-b3f5a2642742.mp4',
      //     mainBody: '描述',
      //     coverImg: '',
      //   },
      // },
      // {
      //   title: '直播',
      //   iconClass: 'icon-zhibo',
      //   onlyAdmin: true, // 平台端显示
      //   onlyWeixin: true, // 只在小程序显示
      //   type: 'live',
      //   componentContent: {
      //     showMore: false,
      //   },
      // },
    ],
  },
  // {
  //   title: '商品组件',
  //   type: 1,
  //   classList: [
  //     {
  //       title: '商品列表',
  //       iconClass: 'icon-shangpin',
  //       type: 'productList',
  //       componentContent: {
  //         title: '商品列表',
  //         productData: {
  //           sourceType: '1',
  //           categoryId: 0,
  //           categoryName: '',
  //           imgTextData: [],
  //           productIdList: [],
  //         },
  //         productRowNum: 1, // 展示行数
  //         productNum: 4, //  展示列数
  //         arrangeType: '多行多列', // 布局方式
  //         showMore: false, // 显示隐藏更多
  //         styleValue: '1', // 查看更多样式
  //         openBottomLoad: false, // 是否开启触底加载
  //         linkObj: {
  //           selectValue: '',
  //           selectName: '',
  //           typeText: '',
  //           url: '',
  //         }, // 查看更多链接
  //       },
  //     },
  //     {
  //       title: '类别列表',
  //       iconClass: 'icon-leibieliebiao',
  //       type: 'categoryList',
  //       componentContent: {
  //         title: '类别列表',
  //         categoryData: [
  //           {
  //             id: 1,
  //             selClassData: [],
  //             img: '',
  //           },
  //           {
  //             id: 2,
  //             selClassData: [],
  //             img: '',
  //           },
  //           {
  //             id: 3,
  //             selClassData: [],
  //             img: '',
  //           },
  //           {
  //             id: 4,
  //             selClassData: [],
  //             img: '',
  //           },
  //         ],
  //         textAlign: 'center',
  //       },
  //     },
  //   ],
  // },
  // {
  //   title: '店铺组件',
  //   type: 2,
  //   classList: [
  //     {
  //       title: '拼团专区',
  //       iconClass: 'icon-pintuan1',
  //       type: 'groupList',
  //       componentContent: {
  //         // title: '拼团专区',
  //         productData: {
  //           products: [],
  //         },
  //         productRowNum: 1, // 展示行数
  //         productNum: 4, //  展示列数
  //         arrangeType: '多行多列', // 布局方式
  //         showMore: false, // 显示隐藏更多
  //         styleValue: '1', // 查看更多样式
  //         shopGroupWorkId: '',
  //       },
  //     },
  //     {
  //       title: '秒杀专区',
  //       iconClass: 'icon-miaosha1',
  //       type: 'spikeList',
  //       componentContent: {
  //         // title: '秒杀专区',
  //         productData: {
  //           products: [],
  //         },
  //         shopSeckillId: '',
  //       },
  //     },
  //     {
  //       title: '限时折扣',
  //       iconClass: 'icon-zhekou1',
  //       type: 'discountList',
  //       componentContent: {
  //         // title: '限时折扣',
  //         productData: {},
  //         discountId: '',
  //         arrangeType: '多行多列', // 布局方式
  //         moreBg: '',
  //       },
  //     },
  //     {
  //       title: '定价捆绑',
  //       iconClass: 'icon-price',
  //       type: 'priceList',
  //       onlyMerchant: true, // 商家端显示
  //       componentContent: {
  //         // title: '定价捆绑',
  //         productData: {
  //           composeProducts: [],
  //         },
  //         productRowNum: 1, // 展示行数
  //         productNum: 4, //  展示列数
  //         arrangeType: '多行多列', // 布局方式
  //         showMore: false, // 显示隐藏更多
  //         styleValue: '1', // 查看更多样Z
  //         priceId: '',
  //       },
  //     },
  //     {
  //       title: '会员专区',
  //       iconClass: 'icon-huiyuan',
  //       type: 'vip',
  //       onlyAdmin: true, // 平台端显示
  //       componentContent: {
  //         // title: '会员专区',
  //         productData: {
  //           productIdList: [],
  //         },
  //         productRowNum: 1, // 展示行数
  //         productNum: 4, //  展示列数
  //         arrangeType: '多行多列', // 布局方式
  //         showMore: false, // 显示隐藏更多
  //         styleValue: '1', // 查看更多样式
  //       },
  //     },
  //     {
  //       title: '优惠券',
  //       iconClass: 'icon-youhuiquan',
  //       type: 'coupon',
  //       componentContent: {
  //         arrangeActiveIndex: 2,
  //         cardActiveIndex: 1,
  //         colorActiveIndex: 0,
  //         selectedCoupon: [],
  //       },
  //     },
  //     {
  //       title: '每日上新',
  //       iconClass: 'icon-new',
  //       type: 'newProduct',
  //       onlyApp: true, // 只在app显示
  //       componentContent: {
  //         productData: {
  //           sourceType: '1',
  //           imgTextData: [],
  //           productIdList: [],
  //         },
  //         showMore: false, // 显示隐藏更多
  //         styleValue: '1', // 查看更多样式
  //         linkObj: {
  //           selectValue: '',
  //           selectName: '',
  //           typeText: '',
  //           url: '',
  //         }, // 查看更多链接
  //       },
  //     },
  //     {
  //       title: '每日好店',
  //       iconClass: 'icon-toubu',
  //       type: 'shop',
  //       onlyApp: true, // 只在app显示
  //       componentContent: {
  //         imgTextData: [
  //           {
  //             linkObj: {
  //               selectValue: '',
  //               selectName: '',
  //               typeText: '',
  //               url: '',
  //             },
  //             img: '',
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // },
]
export default panelList
