var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "baseEditor" } },
    [
      _vm.editorType == "2"
        ? _c("froala", {
            attrs: { config: _vm.config, tag: "textarea" },
            model: {
              value: _vm.content,
              callback: function ($$v) {
                _vm.content = $$v
              },
              expression: "content",
            },
          })
        : _vm._e(),
      _vm.editorType == "1"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.quillUpdateImg,
                  expression: "quillUpdateImg",
                },
              ],
              attrs: { "element-loading-text": "请稍等，图片上传中" },
            },
            [
              _c("el-upload", {
                staticClass: "avatar-uploader",
                attrs: {
                  action: _vm.serverUrl,
                  "before-upload": _vm.beforeUpload,
                  data: _vm.uploadData,
                  headers: _vm.header,
                  name: "file",
                  "on-error": _vm.uploadError,
                  "on-success": _vm.uploadSuccess,
                  "show-file-list": false,
                },
              }),
              _c("quillEditor", {
                ref: "myQuillEditor",
                staticClass: "editor",
                attrs: { options: _vm.editorOption },
                on: {
                  blur: function ($event) {
                    return _vm.onEditorBlur($event)
                  },
                  change: function ($event) {
                    return _vm.onEditorChange($event)
                  },
                  focus: function ($event) {
                    return _vm.onEditorFocus($event)
                  },
                  ready: function ($event) {
                    return _vm.onEditorReady($event)
                  },
                },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }