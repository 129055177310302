<template>
  <div class="cy-container">
    <el-form ref="queryForm" :inline="true" :model="queryForm" @submit.native.prevent>
      <el-form-item label="标题：" prop="artTitle">
        <el-input v-model="queryForm.artTitle" class="filterItems" placeholder="请输入文章名称" />
      </el-form-item>
      <el-form-item label="消息类型：" prop="artType">
        <el-select v-model="queryForm.artType" class="filterItems" clearable placeholder="请选择">
          <el-option v-for="(item, index) in artTypeList" :key="index" :label="item.typeName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="发送时间：" prop="artStatus">
        <el-date-picker v-model="queryForm.value1" end-placeholder="结束日期" range-separator="至" start-placeholder="开始日期" type="daterange" />
        <!-- <el-select
          v-model="queryForm.artStatus"
          class="filterItems"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in artStatusList"
            :key="index"
            :label="item.dictValue"
            :value="item.dictKey"
          />
        </el-select> -->
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" native-type="submit" type="warning" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh-left" native-type="submit" type="primary" @click="handleReset">重置</el-button>
      </el-form-item>
      <el-form-item style="float: right">
        <!-- <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          添加文章
        </el-button> -->
        <el-button
          v-for="item in searchButton"
          :key="item.id"
          :plain="item.cssType && item.cssType == 'plain' ? true : false"
          :type="item.buttonCss"
          @click.native="handleClickBtn(item)"
        >
          <span v-if="!item.buttonIcon"></span>
          <vab-icon v-else :icon="item.buttonIcon" />
          {{ item.buttonName }}
        </el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="listLoading" border :data="tableData" default-expand-all max-height="600">
      <el-table-column align="center" label="序号" type="index" width="100" />
      <el-table-column align="center" label="标题" prop="noticeTitle"   width="350" />
      <el-table-column align="center" label="内容" prop="noticeContent" show-overflow-tooltip />
      <el-table-column align="center" label="消息类型" prop="noticeType" show-overflow-tooltip width="120px" />
      <el-table-column align="center" label="发送时间" prop="createTime" show-overflow-tooltip width="180px" />
      <el-table-column v-if="operationShow" align="center" label="操作" min-width="80px">
        <template #default="{ row }">
          <template v-for="item in listButton">
            <el-button
              v-if="showListButton(row, item)"
              :key="item.id"
              :plain="item.cssType && item.cssType == 'plain' ? true : false"
              size="default"
              :type="item.buttonCss"
              @click.native="handleClickBtn(item, row)"
            >
              <span v-if="!item.buttonIcon"></span>
              <!-- <vab-icon v-else :icon="item.buttonIcon" /> -->
              {{ item.buttonName }}
            </el-button>
          </template>
          <!-- <el-button
            v-if="row.artStatus != 4"
            type="text"
            @click="handelEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="row.artStatus != 4"
            type="text"
            @click="handelDelete(row)"
          >
            删除
          </el-button>
          <el-button
            v-if="row.artStatus == 2"
            type="text"
            @click="handelRelease(row)"
          >
            发布
          </el-button>
          <el-button
            v-if="row.artStatus == 1"
            type="text"
            @click="handelShelves(row)"
          >
            下架
          </el-button>
          <el-button
            v-if="row.artStatus == 4"
            type="text"
            @click="handelRestore(row)"
          >
            恢复
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="forms.currentPage"
      :layout="layout"
      :page-size="forms.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <preview ref="preview" />
  </div>
</template>
<script>
import {

  queryListType,
  messageList,
  // queryListColumn,
  isHot,
  isTop,
  release,
  deleted,
  restore,
  shelves,
} from '@/api/mall/article/list'
import { selectSysDict, selectRoleMenuButtonList } from '@/api/mall/common'
import Preview from './components/preview.vue'

export default {
  name: 'ArtLlist',
  components: {
    Preview,
  },
  data() {
    return {
      operationShow: false,
      artTypeList: [],
      artStatusList: [],
      listLoading: false,
      forms: { current: 1, size: 10 },
      isShow: true,
      queryForm: {
        artStatus: '',
        artTitle: '',
        artType: '',
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      listShows: false,
      searchButton: [],
      listButton: [],
    }
  },

  created() {
    this.selectSysDict()
    this.fetchData()
    this.queryListType()
    this.selectRoleMenuButtonList()
  },
  activated() {
    this.fetchData()
  },
  mounted() {},
  methods: {
    // 按钮状态显示判断
    showListButton(row, item) {
      if (row.artStatus == 4 && item.buttonUrl == 'handelRestore') {
        return true
      } else if (row.artStatus != 4 && (item.buttonUrl == 'handelEdit' || item.buttonUrl == 'handelDelete')) {
        return true
      } else if (row.artStatus == 2 && item.buttonUrl == 'handelRelease') {
        return true
      } else if (row.artStatus == 1 && item.buttonUrl == 'handelShelves') {
        return true
      } else {
        return false
      }
    },
    // 按钮点击
    handleClickBtn(item, row) {
      if (item.buttonUrl) {
        this[item.buttonUrl](row)
      }
    },
    // 按钮权限
    selectRoleMenuButtonList() {
      selectRoleMenuButtonList({
        roleIdStrList: this.$store.getters['acl/roleList'],
        menuId: this.$route.meta.id,
      })
        .then((res) => {
          if (res.data) {
            this.searchButton = res.data.filter(function (item) {
              return item.buttonType == 1
            })
            this.listButton = res.data.filter(function (item) {
              return item.buttonType == 2
            })
            if (this.listButton.length > 0) {
              this.operationShow = true
            } else {
              this.operationShow = false
            }
            const buttonUrl = this.listButton.map((item) => {
              return item.buttonUrl
            })
            if (buttonUrl.includes('handelEdit')) {
              this.listShows = true
            } else {
              this.listShows = false
            }
          }
        })
        .catch(() => {})
    },
    // 文章名称预览
    handleArtTitle(row) {
      this.$refs['preview'].shows(row)
    },
    //  字典
    selectSysDict() {
      selectSysDict({ dictTypeList: ['articleStatus'] })
        .then((res) => {
          if (res.data) {
            this.artStatusList = res.data[0].dictInfoVoList
          }
        })
        .catch(() => {})
    },
    // 文章状态过滤
    formatterOrder(row) {
      for (let i = 0; i < this.artStatusList.length; i++) {
        if (this.artStatusList[i].dictKey == row.artStatus) {
          return this.artStatusList[i].dictValue
        }
      }
    },
    // 发布
    handelRelease(row) {
      this.$confirm('是否确认要发布此文章?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          release({ id: row.id, artStatus: row.artStatus })
            .then(() => {
              this.fetchData()
              this.MS('发布成功！')
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 加精、置顶
    handleUpdate(tag, row) {
      if (tag == '是否加精') {
        isHot({ id: row.id, isHot: row.isHot })
          .then((res) => {
            console.log(res)
            this.fetchData()
            this.MS('更新成功！')
          })
          .catch(() => {})
      }
      if (tag == '是否置顶') {
        isTop({ id: row.id, isTop: row.isTop })
          .then((res) => {
            console.log(res)
            this.fetchData()
            this.MS('更新成功！')
          })
          .catch(() => {})
      }
    },
    // 文章类型
    async queryListType() {
      await queryListType()
        .then((res) => {
          if (res.data) {
            this.artTypeList = res.data
          }
        })
        .catch(() => {})
    },
    // 专栏
    // async queryListColumn() {
    //   await queryListColumn()
    //     .then((res) => {
    //       if (res.data) {
    //         // this.artTypeList = res.data.records
    //       }
    //     })
    //     .catch(() => {})
    // },
    // 列表
    fetchData(options) {
      this.listLoading = true
      messageList({
        ...options,
        ...this.forms,
        // currentPage: this.currentPage,
        // pageSize: this.pageSize,
        // artStatus: this.queryForm.artStatus,
        // artTitle: this.queryForm.artTitle,
        // artType: this.queryForm.artType,
      })
        .then((res) => {
          if (res.data) {
            this.tableData = res.data.records
            this.total = res.data.total
            this.tableData.map((v) => {
              this.$set(v, 'listShows', false)
              return v
            })
          }
        })
        .catch(() => {})
      this.listLoading = false
    },
    // 添加
    handleAdd() {
      this.$router.push({
        path: '/release',
        // query: { id: row.id, orgId: row.orgId },
      })
    },
    // 编辑
    handelEdit(row) {
      this.$router.push({
        path: '/editArt',
        query: { id: row.id },
      })
    },
    // 下架
    handelShelves(row) {
      this.$confirm('是否确认要下架此文章?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          shelves({ id: row.id })
            .then(() => {
              this.MS('下架成功')
              this.fetchData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 恢复
    handelRestore(row) {
      this.$confirm('是否确认要恢复此文章?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          restore({ id: row.id })
            .then(() => {
              this.MS('恢复成功')
              this.fetchData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 删除
    handelDelete(row) {
      this.$confirm('是否确认要删除文章，删除后前端将不做展示?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleted({ id: row.id })
            .then(() => {
              this.MS('删除成功')
              this.fetchData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 重置
    handleReset() {
      this.forms.currentPage = 1
      this.forms.pageSize = 10
      this.queryForm = {}
      this.handleQuery()
    },
    // 搜索
    async handleQuery() {
      this.forms.currentPage = 1
      await this.fetchData({ ...this.queryForm }, false)
      let temp = false
      Object.keys(this.queryForm).forEach((key) => {
        this.queryForm[key] && (temp = true)
      })
      this.isShow = temp
    },
    // 分页
    handleSizeChange(val) {
      this.forms.size = val
      let data = this.isShow === true ? this.queryForm : {}
      this.fetchData({ ...data }, false)
    },
    // 分页
    handleCurrentChange(val) {
      this.forms.current = val
      let data = this.isShow === true ? this.queryForm : {}
      this.fetchData({ ...data }, false)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep(.ql-video) {
  width: 20% !important;
  height: 200px;
}
.filterItems {
  width: 150px;
}
</style>
