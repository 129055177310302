<template>
  <div class="OrderDetail cy-container">
    <el-row style="display: flex; align-items: center; background: #f0f0f0; padding: 20px">
      <el-col v-if="form.tip" class="fs16">复制主题</el-col>
      <el-col v-else-if="form.id" class="fs16">编辑主题</el-col>
      <el-col v-else class="fs16">添加主题</el-col>
    </el-row>
    <div class="mt30">
      <el-steps :active="active" align-center>
        <el-step v-if="form.tip" description="复制主题" @click.native="updateData()" />
        <el-step v-else-if="form.id" description="编辑主题" @click.native="handelClick(1)" />
        <el-step v-else description="添加主题" @click.native="updateData()" />
        <el-step v-if="form.tip" description="选择活动" @click.native="updateData()" />
        <el-step v-else-if="form.id" description="选择活动" @click.native="handelClick(2)" />
        <el-step v-else description="选择活动" @click.native="updateData()" />
      </el-steps>
      <step-one v-if="active === 1" :infos="form" v-bind="$attrs" v-on="$listeners" @change-step="handleSetStep" />
      <step-two v-if="active === 2" :infos="form" v-bind="$attrs" v-on="$listeners" @change-step="handleSetStep" />
    </div>
  </div>
</template>
<script>
import StepOne from './components/StepOne.vue'
import StepTwo from './components/StepTwo'
import { mapActions } from 'vuex'
export default {
  components: {
    StepOne,
    StepTwo,
  },
  data() {
    return {
      route: {},
      active: 1,
      form: {
        id: this.$route.query.id,
        publishUserId: this.$route.query.id,
        themeId: '',
        tip: this.$route.query.tip,
      },
    }
  },
  created() {
    if (this.form.id) {
      const route = this.$route
      this.$nextTick(() => {
        this.changeTabsMeta({
          title: '添加主题',
          meta: {
            title: `编辑主题`,
          },
        })
        this.route = {
          path: route.path,
          params: route.params,
          query: route.query,
          name: route.name,
          meta: route.meta,
        }
        // console.log(route)
      })
    }
  },
  methods: {
    ...mapActions({
      changeTabsMeta: 'tabs/changeTabsMeta',
    }),
    handleSetStep(active, form) {
      this.active = active
      if (form) {
        this.form.themeId = form
      }
      // if (form) this.form = Object.assign(this.form, form)
    },
    updateData() { },
    handelClick(num) {
      if (num == 1) {
        this.handleSetStep(1)
      }
      if (num == 2) {
        this.handleSetStep(2)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.OrderDetail {
  padding: 20px;
  color: #000;
  .refund {
    .refundTitel {
      margin-top: 30px;
      font-weight: 700;
      padding-left: 5px;
      border-left: 4px solid rgb(0, 122, 255);
    }
  }
  .themeTitel {
    color: #9f9f9f;
  }
  .el-step {
    ::v-deep(.el-step__description) {
      margin-top: 5px;
    }
  }
}
</style>
