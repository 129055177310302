/**
 * 字典
 */
export default {
  // 按钮状态
  roleSelectList: [
    {
      name: '正常',
      id: 0,
    },
    {
      name: '删除',
      id: 1,
    },
  ],
  // 按钮类型
  buttonTypeList: [
    {
      name: '查询栏',
      id: 1,
    },
    {
      name: '列表栏',
      id: 2,
    },
  ],
  // 触发类型
  actionTypeList: [
    {
      name: 'onClick',
      id: '1',
    },
    {
      name: 'url',
      id: '2',
    },
  ],
  // 订单状态
  orderStates: [
    { id: 10, name: '待确定' },
    { id: 11, name: '订单取消' },
    { id: 20, name: '待发货' },
    { id: 30, name: '待收货' },
    { id: 40, name: '待评价' },
    { id: 50, name: '订单完成' },
    { id: 60, name: '全部退货' },
  ],
  // 订单类型
  orderType: [{ id: 10, name: '普通订单' }],
  //审核状态
  checkState: [
    { id: 1, name: '未审核' },
    { id: 2, name: '审核通过' },
    { id: 3, name: '审核拒绝' },
  ],
  // 客情状态
  afterSaleState: [
    { id: 1, name: '申请客情' },
    { id: 2, name: '通过客情' },
    { id: 3, name: '拒绝客情' },
    { id: 4, name: '客情完成' },
    { id: 5, name: '买家取消客情' },
    { id: 6, name: '商家终止客情' },
  ],
  // 详情入驻类型
  enterTypeList: [
    { id: 7, orgTypeKey: 'shop', orgTypeValue: '门店中心' },

    { id: 1, orgTypeKey: 'province', orgTypeValue: '省运营中心' },
    { id: 6, orgTypeKey: 'district', orgTypeValue: '区运营中心' },
  ],
  // 选择门店
  doorsList: [
    { id: 1, name: 'MINI门店' },
    { id: 2, name: '专营门店' },
    { id: 3, name: '旗舰门店' },
  ],
  // 机构列表入驻类型
  applyTypeList: [
    { id: 1, name: '体验中心' },
    { id: 2, name: '服务中心' },
    { id: 3, name: '运营中心' },
  ],
  // 机构列表审核状态
  applyStatusList: [
    { id: 10, name: '待指派员工' },
    { id: 20, name: '待完善信息' },
    { id: 30, name: '待确认合同' },
    { id: 40, name: '入驻成功' },
  ],
  // 户型
  houseTypeList: [
    { id: 1, name: '一室一厅' },
    { id: 2, name: '二室一厅' },
    { id: 3, name: '三室一厅' },
    { id: 4, name: '三室二厅' },
    { id: 5, name: '四室二厅' },
  ],
  // 房屋状态
  houseStatusList: [
    { id: 1, name: '自住' },
    { id: 2, name: '待售' },
    { id: 3, name: '出租' },
  ],
  // 物业类型
  propertyTypeList: [
    { id: 1, name: '住宅' },
    { id: 2, name: '商铺' },
  ],
  // 报修状态
  repairStatusList: [
    { id: 1, name: '未处理' },
    { id: 2, name: '处理中' },
    { id: 3, name: '已处理' },
  ],
  // 物业管理-人员信息表-下拉选择--会删掉
  userList: [
    { id: 1, name: '苗头' },
    { id: 2, name: '鲜少龙' },
    { id: 3, name: '川少' },
    { id: 4, name: '春春' },
    { id: 5, name: '张飞' },
  ],
  // 物业管理-部门-下拉选择--会删掉
  departList: [
    { id: 1, name: '保安部' },
    { id: 2, name: '保洁部' },
  ],
}
