<template>
  <div class="comprehensive-table-container">
    <div class="list-item">
      <div class="list-title">
        <div class="line"></div>
        <div class="title-text">领取详情</div>
      </div>
      <el-table border :data="list" stripe>
        <el-table-column align="center" label="奖品等级" prop="itemLevel" />
        <el-table-column align="center" label="奖品名称" prop="goodsName" />
        <el-table-column align="center" label="奖品图片" prop="goodsImage">
          <template #default="{ row }">
            <el-image
              fit="fill"
              :src="row.goodsImage"
              style="cursor: pointer"
              @click="handlePreView(row.goodsImage)"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="抽奖时间" prop="startDate">
          <template #default="{ row }">
            <div>{{ row.startDate ? parseTime(row.startDate) : '' }}</div>
            <!-- <div>{{ row.startDate }}</div> -->
          </template>
        </el-table-column>
        <el-table-column align="center" label="中奖时间" prop="lotteryTime">
          <template #default="{ row }">
            <div>{{ row.lotteryTime ? parseTime(row.lotteryTime) : '' }}</div>
            <!-- <div>{{ row.lotteryTime }}</div> -->
          </template>
        </el-table-column>
        <el-table-column
          v-if="list[0] && list[0].goodsType != 1"
          align="center"
          label="发放方式"
        >
          <template #default="{ row }">
            {{ getDicValue('disburseMent', row.disburseMent) }}
          </template>
        </el-table-column>
        <el-table-column v-else align="center" label="发放方式">
          <template #default="{ row }">
            {{ getDicValue('deliveryType', row.deliveryType) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="支付金额" prop="paidAmount" />
        <el-table-column
          v-if="list[0] && list[0].isRedem == 1"
          align="center"
          label="领取时间"
          prop="receiveTime"
        >
          <template #default="{ row }">
            <div>{{ row.receiveTime ? parseTime(row.receiveTime) : '' }}</div>
            <!-- <div>{{ row.receiveTime }}</div> -->
          </template>
        </el-table-column>
        <el-table-column
          v-if="list[0] && list[0].goodsType == 1 && list[0].deliveryType == 12"
          align="center"
          label="核销码"
          prop="redemCode"
        />
      </el-table>
    </div>
    <div
      v-if="list[0] && list[0].goodsType == 1"
      class="list-item"
      style="margin-top: 100px"
    >
      <div class="list-title">
        <div class="line"></div>
        <div class="title-text">订单详情</div>
      </div>
      <el-table border :data="list" stripe>
        <el-table-column align="center" label="订单编号" prop="orderCode" />
        <el-table-column align="center" label="商品名称" prop="goodsName" />
        <el-table-column align="center" label="收货人" prop="consignee" />
        <el-table-column align="center" label="电话号码" prop="phone" />
        <el-table-column
          align="center"
          label="收货地址"
          prop="receivingAddress"
        />
        <el-table-column align="center" label="下单时间" prop="orderTime">
          <template #default="{ row }">
            <div>{{ row.orderTime ? parseTime(row.orderTime) : '' }}</div>
            <!-- <div>{{ row.orderTime }}</div> -->
          </template>
        </el-table-column>
        <el-table-column align="center" label="发货店铺" prop="shopName" />
        <el-table-column align="center" label="订单状态" prop="orderState">
          <template #default="{ row }">
            <div>{{ getDicValue('orderState', row.orderState) }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      append-to-body
      title="图片详情"
      :visible.sync="imgDetailVisible"
      width="50%"
    >
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <el-image fit="fill" :src="imgUrl" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getLuckyDetail } from '@/api/activity/lucky'
  import { parseTime } from '@/utils'
  import { selectSysDict } from '@/api/mall/common'
  export default {
    filters: {
      parseTime,
    },
    data() {
      return {
        id: 0,
        list: [],
        imgUrl: '',
        imgDetailVisible: false,
        orderState: [],
        disburseMent: [
          {
            dictValue: '立即到账',
            dictKey: 1,
          },
          {
            dictValue: '24小时到账',
            dictKey: 2,
          },
        ],
        deliveryType: [
          {
            dictValue: '门店自提',
            dictKey: 12,
          },
          {
            dictValue: '同城速达',
            dictKey: 11,
          },
          {
            dictValue: '快递发送',
            dictKey: 10,
          },
        ],
      }
    },
    created() {
      this.id = this.$route.query.id
      this.fetchData()
    },
    methods: {
      parseTime,
      handlePreView(url) {
        this.imgUrl = url
        this.imgDetailVisible = true
      },
      getDicValue(key, val) {
        let str = ''
        let obj = this[key].find((item) => item.dictKey == val)
        if (obj) {
          str = obj.dictValue
        }
        return str
      },
      fetchData() {
        getLuckyDetail({ id: this.id }).then((res) => {
          const {
            data: { records },
          } = res
          this.list = records
        })
        selectSysDict({
          dictTypeList: ['orderStatus'],
        }).then((res) => {
          const { data } = res
          this.orderState = data[0].dictInfoVoList
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .list-item {
    .list-title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .line {
        height: 20px;
        width: 4px;
        background-color: rgb(41, 121, 255);
        border-radius: 3px;
      }
      .title-text {
        margin-left: 10px;
        font-size: 18px;
      }
    }
    .item-line {
      display: flex;
      align-items: center;
      margin: 20px;
      .line-left {
        width: 80px;
        color: #bbb;
      }
      .right-list {
        .right-item {
          margin-bottom: 10px;
          .input {
            height: 30px;
            width: 200px;
            border: 1px solid #ddd;
            padding-left: 10px;
          }
          .input::placeholder {
            color: #bbb;
          }
        }
      }
      .line-right {
        display: flex;
        align-items: center;
        .input {
          height: 30px;
          width: 200px;
          border: 1px solid #ddd;
          padding-left: 10px;
        }
        .input::placeholder {
          color: #bbb;
        }
        .right-item {
          margin-right: 20px;
        }
        .img {
          width: 80px;
          height: 80px;
          cursor: pointer;
        }
      }
    }
  }
</style>
