<template>
  <div class="comprehensive-table-container page-contaier">
    <div class="steps">
      <el-steps :active="activeIndex" finish-status="success">
        <el-step title="基本设置" />
        <el-step title="参与设置" />
        <el-step title="奖品设置" />
      </el-steps>
    </div>
    <div v-show="activeIndex === 0">
      <div class="list-item">
        <div class="list-title">
          <div class="line"></div>
          <div class="title-text">实例设置</div>
        </div>
        <div class="item-line">
          <div class="line-left">实例名称：</div>
          <div class="line-right">
            <input v-model.trim="step1Data.instName" class="input" placeholder="请输入实例名称" type="text" />
          </div>
        </div>
        <div class="item-line">
          <div class="line-left">实例图片：</div>
          <div class="line-right">
            <el-upload
              accept=".jpg,.png,.jpeg,.mp4"
              :action="uploadParams.uploadURL"
              :file-list="instLogoList"
              :headers="{ Authorization: uploadParams.token }"
              :limit="1"
              list-type="picture-card"
              :multiple="false"
              :on-preview="handlePreView"
              :on-remove="handleActLogoRemove"
              :on-success="handleActLogoSuccess"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
        <div class="item-line">
          <div class="line-left">实例背景图片：</div>
          <div class="line-right">
            <el-upload
              accept=".jpg,.png,.jpeg,.mp4"
              :action="uploadParams.uploadURL"
              :file-list="instBgList"
              :headers="{ Authorization: uploadParams.token }"
              :limit="1"
              list-type="picture-card"
              :multiple="false"
              :on-preview="handlePreView"
              :on-remove="handleActBgRemove"
              :on-success="handleActBgSuccess"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
        <div class="item-line">
          <div class="line-left">实例时间：</div>
          <div class="line-right">
            <el-date-picker
              v-model="instTimeList"
              align="center"
              end-placeholder="请选择活动结束时间"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="请选择活动开始时间"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="dateChange"
            />
          </div>
          <span style="margin-left: 20px; color: #ccc">不得早于或晚于活动时间</span>
        </div>
        <div class="item-line">
          <div class="line-left">发布时间：</div>
          <div class="line-right">
            <el-radio-group v-model="step1Data.publishType">
              <el-radio label="1">立即发布</el-radio>
              <el-radio label="2">定时发布</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div v-if="step1Data.publishType == 2" class="second-item">
          <div class="item-title">选择时间：</div>
          <el-date-picker v-model="step1Data.publishTime" align="center" placeholder="选择日期时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" />
        </div>
        <div class="item-line">
          <div class="line-left">实例样式：</div>
          <div v-if="allActType == 10" class="line-right">
            <el-radio-group v-model="step1Data.instStyle">
              <el-radio v-for="(item, index) in instStyle" :key="index" :label="item.dictKey.toString()">
                {{ item.dictValue }}
              </el-radio>
            </el-radio-group>
          </div>
          <div v-if="allActType == 20" class="line-right">
            <el-radio-group v-model="step1Data.instStyle">
              <el-radio v-for="(item, index) in instStyle2" :key="index" :label="item.dictKey.toString()">
                {{ item.dictValue }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="item-line">
          <div class="line-left">实例期数：</div>
          <div class="line-right">
            <el-input-number v-model="step1Data.totalActCount" label="请输入实例期数" :min="1" :step="1" :step-strictly="true" />
            &nbsp;期
          </div>
        </div>
        <div v-if="$route.query.allActType == 20" class="item-line">
          <div class="line-left">开盒金额：</div>
          <div class="line-right">
            <el-input v-model="step1Data.boxAmount" style="width: 200px" @change="checkInput($event)" />
            &nbsp;&nbsp;元
          </div>
        </div>
        <div v-if="$route.query.allActType == 20" class="item-line">
          <div class="line-left">回收金额：</div>
          <div class="line-right">
            <el-input v-model="step1Data.boxRecoverAmount" style="width: 200px" @change="checkInput($event)" />
            &nbsp;&nbsp;元
          </div>
          <!-- <div class="line-right">
            <el-input-number
              v-model="step1Data.boxRecoverAmount"
              :controls="true"
              label=""
              :max="100"
              :min="0.1"
              size="normal"
              :step="0.1"
            />
            %
          </div> -->
        </div>
        <div class="item-line">
          <div class="line-left">参与方式：</div>
          <div class="line-right">
            <el-radio-group v-model="step1Data.instWay">
              <el-radio label="1">线上参与</el-radio>
              <el-radio label="2">线下参与</el-radio>
            </el-radio-group>
          </div>
        </div>
        <!-- <div class="item-line">
          <div class="line-left">开奖条件：</div>
          <div class="line-right">
            <el-checkbox-group v-model="checkList">
              <el-checkbox disabled label="定时开奖" />
            </el-checkbox-group>
          </div>
        </div> -->
        <!-- <div class="second-item">
          <div class="item-title" style="width: 20px">每</div>
          <el-select clearable>
            <el-option label="周" />
            <el-option label="天" />
            <el-option label="月" />
          </el-select>
          <el-select clearable style="margin: 0 20px">
            <el-option v-for="i in 7" :key="i" :label="i" />
          </el-select>
          <el-time-picker v-model="value1" placeholder="任意时间点" />
        </div> -->
        <!-- <div class="second-item">
          <el-checkbox :indeterminate="false" label="">满员开奖</el-checkbox>
        </div> -->
        <div class="second-item">
          <div>参与份数达到&nbsp;</div>
          <el-input-number v-model="step1Data.personCount" :min="1" :step="1" :step-strictly="true" />
          <!-- <input class="input" type="number" /> -->
          <div>&nbsp;份</div>
          <span>达到开奖时间参与份数满开奖</span>
        </div>
        <div class="second-item">
          <div>最低参与份数&nbsp;</div>
          <el-input-number v-model="step1Data.lowerPersonLimit" :min="1" :step="1" :step-strictly="true" />
          <!-- <input class="input" type="number" /> -->
          <div>&nbsp;份</div>
          <span>参与份数满足最低开奖份数开奖</span>
        </div>
        <div class="item-line" style="align-items: stretch">
          <div class="line-left">实例说明：</div>
          <div class="line-right">
            <textarea v-model="remark" cols="50" rows="10" style="border: 1px solid #ccc" @input="handleInput"></textarea>
            <!-- <el-input clearable rows="5" style="width: 350px" type="textarea" /> -->
          </div>
        </div>
      </div>
    </div>
    <div v-show="activeIndex === 1">
      <div class="list-item">
        <div class="list-title">
          <div class="line"></div>
          <div class="title-text">参与设置</div>
        </div>
        <div class="item-line">
          <div class="line-left">参与用户：</div>
          <div class="line-right">
            <el-radio-group v-model="step2Data.partType">
              <el-radio label="1">全部用户</el-radio>
              <el-radio label="2">部分用户</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div v-if="step2Data.partType == 2" class="second-item">
          <div class="item-title">最低身份&nbsp;</div>
          <el-select v-model="step2Data.limitUserLvl" clearable>
            <el-option v-for="(item, index) in limitUserLvl" :key="index" :label="item.dictValue" :value="item.dictKey" />
          </el-select>
        </div>
        <!-- <div class="second-item">
          <div class="item-title">区域&nbsp;</div>
          <el-cascader />
        </div>
        <div class="second-item">
          <div class="item-title">标签&nbsp;</div>
          <el-cascader />
          <div class="desc">
            两个条件都设置后，必须这些条件都满足的用户才能参加抽奖
          </div>
        </div> -->
        <div class="item-line" style="align-items: stretch">
          <div class="line-left">参与条件：</div>
          <div class="right-list">
            <div class="right-item">
              <span>每次抽奖消耗积分：&nbsp;</span>
              <el-input-number v-model="step2Data.consumeIntegral" :min="0" :step="1" step-strictly />
              <span>&nbsp;分</span>
            </div>
            <div class="right-item">
              <span>每次抽奖消耗金豆：&nbsp;</span>
              <el-input-number v-model="step2Data.consumeGoldenBean" :min="0" :step="1" step-strictly />
              <span>&nbsp;颗</span>
            </div>
            <div class="right-item">
              <span>每次抽奖消耗余额：&nbsp;</span>
              <el-input-number v-model="step2Data.consumeBalance" :min="0" :step="0.1" step-strictly />
              <span>&nbsp;元</span>
            </div>
            <div class="right-item">
              <span>每次抽奖消耗现金：&nbsp;</span>
              <el-input-number v-model="step2Data.consumeCash" :min="0" :step="0.1" step-strictly />
              <span>&nbsp;元</span>
            </div>
          </div>
        </div>
        <div class="item-line">
          <div class="line-left">参与份数：</div>
          <div class="line-right">
            <el-radio-group value="1">
              <el-radio label="1">全部</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="second-item">
          <div class="item-title">每人一共</div>
          <el-input-number v-model="step2Data.shareLimit" :min="1" :step="1" step-strictly />
          <span>&nbsp;份</span>
        </div>
        <div style="margin-top: 20px; margin-left: 100px; color: #bbb; font-size: 14px">参与份数可用于增加抽奖概率</div>
      </div>
    </div>
    <div v-show="activeIndex === 2">
      <div class="list-item">
        <div class="list-title">
          <div class="line"></div>
          <div class="title-text">奖品设置</div>
        </div>
        <div class="item-line">
          <div class="line-left">奖品列表：</div>
          <div class="line-right">
            <el-button size="small" type="primary" @click="addLevel">添加</el-button>
          </div>
        </div>
        <div class="table-wrapper">
          <el-table border :data="prizeList" stripe>
            <el-table-column align="center" label="奖品名称" prop="itemName" />
            <el-table-column align="center" label="奖品等级" prop="itemLevel" />
            <el-table-column align="center" label="排序" prop="itemSort" />
            <el-table-column align="center" label="中奖概率" prop="itemRate" />
            <el-table-column align="center" label="奖品状态" prop="itemStatus">
              <template #default="{ row }">
                {{ getItemStatus(row.itemStatus) }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="创建时间" prop="createTime">
              <template #default="{ row }">
                <div>{{ row.createTime | parseTime }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template #default="{ row }">
                <div>
                  <el-button type="text" @click="openEditPrize(row)">奖品明细</el-button>
                  <el-button type="text" @click="handleEditPrizeDetailLevel(row)">编辑</el-button>
                  <el-button type="text" @click="handleDeletePrize(row)">删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            :current-page="prizeQueryForm.currentPage"
            :layout="layout"
            :page-size="prizeQueryForm.pageSize"
            :total="prizeTotal"
            @current-change="handlePrizeCurrentChange"
            @size-change="handlePrizeSizeChange"
          />
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button v-if="activeIndex == 0" size="small" @click="back">返回</el-button>
      <el-button v-if="activeIndex != 0" size="small" @click="lastStep">上一步</el-button>
      <el-button v-if="activeIndex != 2" :loading="stepNextBtnLoading" size="small" type="primary" @click="nextStep">下一步</el-button>
      <el-button v-else :loading="createBtnLoading" size="small" type="primary" @click="handleCreate">
        {{ pageStatus == 1 ? '保存' : '创建' }}
      </el-button>
    </div>
    <!-- 添加、编辑奖品等级 -->
    <el-dialog :title="`${prizeLevelData.isEdit == 1 ? '编辑' : '添加'}奖品等级`" :visible.sync="addLevelVisible" width="30%" @close="coloseAddLevelDialog">
      <div class="list-item">
        <div class="item-line">
          <div class="line-left">等级名称：</div>
          <div class="line-right">
            <el-input v-model.trim="prizeLevelData.itemName" clearable placeholder="请输入等级名称" size="normal" />
          </div>
        </div>
        <div class="item-line">
          <div class="line-left">排序：</div>
          <div class="line-right">
            <el-input-number v-model="prizeLevelData.itemSort" :min="0" :step="1" step-strictly />
          </div>
        </div>
        <div class="item-line">
          <div class="line-left">奖品等级：</div>
          <div class="line-right">
            <el-input-number v-model="prizeLevelData.itemLevel" :min="1" :step="1" step-strictly />
          </div>
        </div>
        <div class="item-line">
          <div class="line-left">中奖概率：</div>
          <div class="line-right">
            <el-input-number v-model="prizeLevelData.itemRate" :min="1" :step="1" step-strictly />
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button :loading="addLevelBtnLoading" type="primary" @click="handleAddPrize">确定</el-button>
      </span>
    </el-dialog>
    <!-- 奖品明细 -->
    <el-dialog title="奖品明细" :visible.sync="prizeDetailVisible" width="70%" @close="colosePrizeDetailDialog">
      <div class="list-item">
        <div class="item-line">
          <div class="line-left">奖品列表：</div>
          <div class="line-right">
            <el-button size="small" type="primary" @click="handleAddGoodsDetail">添加</el-button>
          </div>
        </div>
        <el-table border :data="prizeDetailList" stripe>
          <el-table-column align="center" label="图片">
            <template #default="{ row }">
              <div>
                <el-image fit="fill" :src="row.goodsImage" />
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="奖品信息" prop="goodsName" />
          <el-table-column align="center" label="奖品类型">
            <template #default="{ row }">
              <div>{{ getDetailGoodsType(row.goodsType) }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="提示语" prop="winGoodsTips" />
          <el-table-column align="center" label="价值" prop="goodsAmount">
            <template #default="{ row }">
              <div>{{ row.goodsAmount | numFormat }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="数量" prop="goodsCount" />
          <el-table-column align="center" label="奖项权重" prop="goodsRate" />
          <el-table-column align="center" label="操作">
            <template #default="{ row }">
              <div>
                <el-button type="text" @click="handleEditPrizeDetail(row)">编辑</el-button>
                <el-button type="text" @click="handleDeletePrizeDetail(row)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :current-page="prizeDetailQueryForm.currentPage"
          :layout="layout"
          :page-size="prizeDetailQueryForm.pageSize"
          :total="prizeDetailTotal"
          @current-change="handlePrizeDetailCurrentChange"
          @size-change="handlePrizeDetailSizeChange"
        />
      </div>
      <span slot="footer">
        <el-button type="primary" @click="prizeDetailVisible = false">确定</el-button>
      </span>
      <!-- 添加、编辑奖品 -->
      <el-dialog append-to-body :title="`${addPrizeDetailDialogStatus == 1 ? '编辑' : '添加'}奖品`" :visible.sync="addPrizeDetailVisible" width="50%" @close="addPrizeDetailClose">
        <div class="list-item">
          <div class="item-line">
            <div class="line-left">奖品：</div>
            <div class="line-right">
              <el-select v-model="goodsDetail.goodsType" clearable>
                <el-option v-for="(item, index) in goodsType" :key="index" :label="item.dictValue" :value="item.dictKey.toString()" />
              </el-select>
            </div>
          </div>
          <div v-if="allActType == 20" class="list-item">
            <div class="item-line">
              <div class="line-left"></div>
              <div class="line-right">
                <el-button plain type="primary" @click.native="handleGoods($event)">选择商品</el-button>
              </div>
            </div>
          </div>
          <div v-if="allActType == 20" class="item-line">
            <div class="line-left">奖品名称：</div>
            <div class="line-right">
              <input v-model.trim="goodsDetail.goodsName" class="input" disabled type="text" />
            </div>
          </div>
          <div v-if="allActType == 20" class="item-line">
            <div class="line-left">奖品图片：</div>
            <div class="line-right">
              <el-image fit="fill" :src="goodsDetail.goodsImage" style="width: 100px; height: 100px; cursor: pointer" />
            </div>
          </div>
          <div v-if="allActType != 20" class="item-line">
            <div class="line-left">奖品名称：</div>
            <div class="line-right">
              <input v-model.trim="goodsDetail.goodsName" class="input" type="text" />
            </div>
          </div>
          <div v-if="allActType != 20" class="item-line">
            <div class="line-left">奖品图片：</div>
            <div class="line-right">
              <el-upload
                accept=".jpg,.png,.jpeg,.mp4"
                :action="uploadParams.uploadURL"
                :file-list="goodsImg"
                :headers="{ Authorization: uploadParams.token }"
                :limit="1"
                list-type="picture-card"
                :multiple="false"
                :on-preview="handlePreView"
                :on-remove="handleGoodsImgRemove"
                :on-success="handleGoodsImgSuccess"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </div>

          <div class="item-line">
            <div class="line-left">奖品价值：</div>
            <div class="line-right">
              <el-input-number v-model="goodsDetail.goodsAmount" :min="0" :step="0.1" />
            </div>
          </div>
          <div v-if="goodsDetail.goodsType == 1 && allActType != 20" class="item-line">
            <div class="line-left">额外支付金额：</div>
            <div class="line-right">
              <el-input-number v-model="goodsDetail.needPayAmount" :min="0" :step="0.1" />
            </div>
          </div>
          <div class="item-line">
            <div class="line-left">奖品数量：</div>
            <div class="line-right">
              <el-input-number v-model="goodsDetail.goodsCount" :min="0" :step="1" step-strictly />
            </div>
          </div>
          <div class="item-line">
            <div class="line-left">奖品权重：</div>
            <div class="line-right">
              <el-input-number v-model="goodsDetail.goodsRate" :min="1" :step="1" step-strictly />
            </div>
          </div>
          <div class="item-line">
            <div class="line-left">提示语：</div>
            <div class="line-right">
              <input v-model.trim="goodsDetail.winGoodsTips" class="input" type="text" />
            </div>
          </div>
          <div v-if="goodsDetail.goodsType != 1" class="item-line">
            <div class="line-left">发放方式：</div>
            <div class="line-right">
              <el-select v-model="goodsDetail.disburseMent" clearable>
                <el-option v-for="(item, index) in disburseMent" :key="index" :label="item.dictValue" :value="item.dictKey.toString()" />
              </el-select>
            </div>
          </div>
          <div v-else class="item-line">
            <div class="line-left">发放方式：</div>
            <div class="line-right">
              <el-checkbox-group v-model="deliveryTypeList" @change="handleDeliveryTypeChange">
                <el-checkbox v-for="(item, index) in deliveryType" :key="index" :label="item.dictKey.toString()">
                  {{ item.dictValue }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div v-if="goodsDetail.goodsType == 1" class="item-line">
            <div class="line-left">是否包邮：</div>
            <div class="line-right">
              <el-radio-group v-model="goodsDetail.postalPayType" size="normal">
                <el-radio-button label="1" value="1">包邮</el-radio-button>
                <el-radio-button label="2" value="2">固定邮费</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div v-if="goodsDetail.goodsType == 1 && goodsDetail.postalPayType == 2" class="second-item">
            <el-input-number v-model="goodsDetail.postageAmount" :min="0" :step="0.1" step-strictly />
            <span>&nbsp;元</span>
          </div>
        </div>
        <span slot="footer">
          <el-button @click="addPrizeDetailVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSubmitPrizeDetail">确定</el-button>
        </span>
      </el-dialog>
    </el-dialog>
    <el-dialog title="图片详情" :visible.sync="imgDetailVisible" width="50%">
      <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
        <el-image fit="fill" :src="imgUrl" />
      </div>
    </el-dialog>
    <!-- 新增 -->
    <change-goods ref="changeGoods" @change="changeGood" />
  </div>
</template>

<script>
  import { getToken } from '@/utils/token'
  import { uploadURL } from '@/config'
  import {
    createStep1,
    createStep2,
    createStep3,
    addPrizeLevel,
    getPrizeList,
    getPrizeDetailList,
    addGoodsDetail,
    deletePrizeLevel,
    deletePrizeDetail,
    editGoodsDetail,
    getStep1,
    getStep2,
    editStep1,
    editStep2,
    editStep3,
    copyPrizes,
    editPrizeLevel,
  } from '@/api/activity/indiana'
  import { parseTime } from '@/utils'
  import ChangeGoods from './components/ChangeGoods.vue'
  export default {
    // name: 'IndianaAdd',
    filters: {
      parseTime,
    },
    components: {
      ChangeGoods,
    },
    data() {
      return {
        allActType: '',
        activeIndex: 0,
        list: [],
        checkList: [],
        deliveryTypeList: [],
        addLevelVisible: false,
        prizeDetailVisible: false,
        addPrizeDetailVisible: false,
        stepNextBtnLoading: false,
        addLevelBtnLoading: false,
        createBtnLoading: false,
        pickerOptions: {
          disabledDate(val) {
            console.log(val)
          },
        },
        instId: 0,
        oldInstId: 0,
        imgUrl: '',
        imgDetailVisible: false,
        activityEndTime: '',
        activityStartTime: '',
        layout: 'total, sizes, prev, pager, next, jumper',
        instBgList: [],
        uploadParams: {
          token: `Bearer ${getToken()}`,
          uploadURL,
          fileList: [],
          dialogImageUrl: '',
        },
        instStyle: [
          {
            dictValue: '抽奖',
            dictKey: 10,
          },
        ],
        instStyle2: [
          {
            dictValue: '盲盒',
            dictKey: 20,
          },
        ],
        limitUserLvl: [
          {
            dictValue: '个人用户',
            dictKey: 0,
          },
          {
            dictValue: '普通会员',
            dictKey: 1,
          },
          {
            dictValue: '高级会员',
            dictKey: 2,
          },
          {
            dictValue: '淘侠',
            dictKey: 20,
          },
          {
            dictValue: '讲师',
            dictKey: 30,
          },
          {
            dictValue: '门主',
            dictKey: 40,
          },
        ],
        goodsType: [
          {
            dictValue: '实物',
            dictKey: 1,
          },
          {
            dictValue: '积分',
            dictKey: 2,
          },
          {
            dictValue: '金豆',
            dictKey: 3,
          },
          {
            dictValue: '余额（现金）',
            dictKey: 4,
          },
          {
            dictValue: 'V卡',
            dictKey: 5,
          },
          {
            dictValue: '礼品卡',
            dictKey: 6,
          },
          {
            dictValue: '优惠券',
            dictKey: 7,
          },
          {
            dictValue: '未中奖',
            dictKey: 8,
          },
        ],
        disburseMent: [
          {
            dictValue: '立即到账',
            dictKey: 1,
          },
          {
            dictValue: '24小时到账',
            dictKey: 2,
          },
          // {
          //   dictValue: '门店自提',
          //   dictKey: 3,
          // },
          // {
          //   dictValue: '快递发送',
          //   dictKey: 4,
          // },
        ],
        deliveryType: [
          {
            dictValue: '门店自提',
            dictKey: 12,
          },
          // {
          //   dictValue: '同城速达',
          //   dictKey: 11,
          // },
          {
            dictValue: '快递发送',
            dictKey: 10,
          },
        ],
        itemStatus: [
          {
            dictValue: '禁用',
            dictKey: 0,
          },
          {
            dictValue: '正常',
            dictKey: 1,
          },
        ],
        instTimeList: [],
        instLogoList: [],
        remark: '',
        step1Data: {
          id: '',
          instName: '',
          actId: '',
          instLogo: '',
          startDate: '',
          endDate: '',
          publishType: '1',
          publishTime: '',
          instStyle: this.$route.query.allActType == 10 ? '10' : '20',
          instWay: '1',
          personCount: '',
          lowerPersonLimit: '',
          remark: '',
          totalActCount: 1,
          instBackdrop: '',
          instStatus: '',
          boxAmount: 0,
          boxRecoverAmount: 0,
        },
        step2Data: {
          instId: '',
          partType: '1',
          limitUserLvl: '',
          consumeIntegral: 0,
          consumeGoldenBean: 0,
          consumeBalance: 0,
          consumeCash: 0,
          shareLimit: 1,
        },
        step3Data: {},
        prizeLevelData: {
          id: '',
          instanceId: 0,
          itemName: '',
          itemInfo: '',
          itemLevel: 1,
          itemSort: 0,
          itemRate: 1,
          itemStatus: 1,
          isEdit: 0,
        },
        prizeQueryForm: {
          currentPage: 1,
          pageSize: 10,
        },
        prizeDetailQueryForm: {
          currentPage: 1,
          pageSize: 10,
        },
        prizeTotal: 0,
        prizeList: [],
        prizeDetailList: [],
        prizeDetailTotal: 0,
        selectItemId: '',
        selectGoodsId: '',
        // 编辑、添加奖品弹框状态 0 新增奖品 1 编辑奖品
        addPrizeDetailDialogStatus: 0,
        goodsDetail: {
          goodsName: '',
          goodsImage: '', //图片
          winGoodsTips: '',
          goodsInfo: '', //物品内容
          goodsType: '1',
          goodsSort: 0, //物品排序
          itemId: '',
          goodsId: '',
          goodsRate: 1,
          goodsCount: 0,
          disburseMent: '1',
          postalPayType: '1',
          postageAmount: 0,
          deliveryType: '',
          goodsAmount: 0,
          needPayAmount: 0,
          specId: '', //规格id
        },
        goodsImg: [],
        pageStatus: 0,
      }
    },
    created() {
      this.allActType = this.$route.query.allActType
      const { actId, status, instId, instStatus } = this.$route.query
      this.step1Data.actId = actId
      this.pageStatus = status
      this.activityEndTime = new Date(this.$route.query.endTime).getTime()
      this.activityStartTime = new Date(this.$route.query.startTime).getTime()
      if (this.pageStatus == 1 || this.pageStatus == 2) {
        this.instId = instId
        this.oldInstId = instId
        this.pageStatus == 1 && (this.step1Data.instStatus = instStatus)
        this.fetchStep1()
        this.fetchStep2()
      }
    },
    methods: {
      lastStep() {
        if (this.activeIndex == 0) {
          this.$router.back()
        } else {
          this.activeIndex--
        }
      },
      // 返回
      back() {
        if (this.activeIndex == 0) {
          this.$router.back()
        } else {
          this.activeIndex--
        }
      },
      // 选择商品
      handleGoods(item) {
        this.$refs.changeGoods.showEdit(item)
      },
      // 选择的入库商品
      changeGood(goods, Specs) {
        console.log(goods, Specs)
        this.goodsDetail.goodsName = goods[0].goodsName //商品名称
        // this.goodsDetail.goodsId = goods[0].id //商品id
        this.goodsDetail.goodsImage = Specs[0].specsLog //规格图片
        this.goodsDetail.specId = Specs[0].id //规格id

        // console.log(goodsName, specsLog, specId)
        // this.goodsName = []
        // this.goodsSpecs = []
        // this.goodsName = goodsName
        // this.goodsSpecs = goodsSpecs
        // this.goodsIndex = parseInt(index)
        // this.inGoogsList.map((item, index) => {
        //   if (this.goodsIndex == index) {
        //     item.goodBarCode = this.goodsSpecs[0].barCode
        //     if (item.goodBarCode) {
        //       this.$refs.goodCodes[index].disabled == true
        //     }
        //     item.depotName =
        //       this.goodsName[0].goodsName + ' ' + this.goodsSpecs[0].specsName
        //     item.goodsData = this.goodsSpecs[0]
        //     item.goodsId = this.goodsName[0].id
        //     if (item.depotName) {
        //       item.tableData[0].goodBarCode = this.goodsSpecs[0].barCode
        //     }
        //   }
        // })
      },
      checkInput(e) {
        let num = Number(e)
        if (isNaN(num) || num < 0) {
          this.$message.error('非法参数！')
        }
      },
      fetchStep1() {
        return new Promise((resolve, reject) => {
          getStep1({ id: this.instId })
            .then((res) => {
              const { data: res1 } = res
              this.step1Data = {
                id: this.step1Data.instStatus ? res1.id : '',
                instName: res1.instName,
                actId: res1.actId,
                instLogo: res1.instLogo,
                startDate: res1.startDate,
                endDate: res1.endDate,
                publishType: String(res1.publishType),
                publishTime: res1.publishTime,
                instStyle: String(res1.instStyle),
                // actCount: res1.actCount,
                instWay: String(res1.instWay),
                personCount: res1.personCount,
                lowerPersonLimit: res1.lowerPersonLimit,
                remark: res1.remark,
                totalActCount: res1.totalActCount,
                instBackdrop: res1.instBackdrop,
                instStatus: res1.instStatus,
                boxAmount: res1.boxAmount, //盲盒金额
                boxRecoverAmount: res1.boxRecoverAmount, //单个盲盒回收金额
              }
              console.log(this.step1Data.id)
              this.instId = res1.id
              this.remark = res1.remark.replaceAll('<br>', '\n')
              this.instLogoList = [{ url: res1.instLogo }]
              this.instBgList = [{ url: res1.instBackdrop }]
              this.instTimeList = [res1.startDate, res1.endDate]
              resolve(res1)
            })
            .catch((err) => {
              reject(err)
            })
        })
      },
      fetchStep2() {
        return new Promise((resolve, reject) => {
          getStep2({ instId: this.instId })
            .then((res) => {
              const { data: res2 } = res
              Object.keys(this.step2Data).forEach((key) => {
                if (res2[key]) {
                  if (key === 'partType') {
                    this.step2Data[key] = res2[key].toString()
                  } else {
                    this.step2Data[key] = res2[key]
                  }
                }
              })
              this.step2Data.instId = this.instId
              resolve(res2)
            })
            .catch((err) => {
              reject(err)
            })
        })
      },
      handleDeliveryTypeChange(e) {
        this.goodsDetail.deliveryType = e.join(',')
      },
      handleInput(e) {
        const str = e.target.value
        this.step1Data.remark = str.replaceAll(/\n/g, '<br>')
        // console.log(this.step1Data.remark)
      },
      getItemStatus(val) {
        let str = ''
        let obj = this.itemStatus.find((item) => item.dictKey == val)
        if (obj) {
          str = obj.dictValue
        }
        return str
      },

      getDetailGoodsType(key) {
        let str = ''
        const obj = this.goodsType.find((item) => item.dictKey == key)
        if (obj) {
          str = obj.dictValue
        }
        return str
      },
      handleEditPrizeDetail(row) {
        this.selectGoodsId = row.id
        this.addPrizeDetailDialogStatus = 1
        this.goodsDetail = {
          goodsName: row.goodsName,
          goodsImage: row.goodsImage,
          winGoodsTips: row.winGoodsTips,
          goodsType: row.goodsType.toString(),
          itemId: this.selectItemId,
          goodsId: this.selectGoodsId,
          goodsRate: row.goodsRate,
          goodsCount: row.goodsCount,
          disburseMent: row.disburseMent.toString(),
          postalPayType: row.postalPayType ? row.postalPayType.toString() : '1',
          postageAmount: row.postageAmount || 0,
          deliveryType: row.deliveryType ? row.deliveryType.toString() : '10',
          goodsAmount: row.goodsAmount,
          needPayAmount: row.needPayAmount,
        }
        this.deliveryTypeList = row.deliveryType.split(',')
        this.goodsImg = [{ url: row.goodsImage }]
        this.addPrizeDetailVisible = true
      },
      addPrizeDetailClose() {
        this.selectGoodsId = ''
        this.goodsDetail = {
          goodsName: '',
          goodsImage: '',
          winGoodsTips: '',
          goodsType: '1',
          itemId: '',
          goodsId: '',
          goodsRate: 1,
          goodsCount: 0,
          disburseMent: '1',
          postalPayType: '1',
          postageAmount: 0,
          deliveryType: '',
          goodsAmount: 0,
          needPayAmount: 0,
          specId: '',
        }
        this.goodsImg = []
        this.deliveryTypeList = []
        this.fetchPrizeDetailData(this.selectItemId)
      },
      handleSubmitPrizeDetail() {
        const data = this.goodsDetail
        console.log(data.deliveryType, data.goodsType)
        console.log(this.goodsDetail)
        if (!data.goodsName) {
          this.$message.error('奖品名称不能为空！')
        } else if (!data.goodsImage) {
          this.$message.error('奖品图片不能为空！')
        } else if (!data.goodsCount) {
          this.$message.error('奖品数量不能为空！')
        } else if (!data.goodsRate) {
          this.$message.error('奖品权重不能为空！')
        } else if (!data.winGoodsTips) {
          this.$message.error('奖品提示语不能为空！')
        } else if (!data.disburseMent) {
          this.$message.error('奖品发放方式不能为空！')
        } else if (!data.itemId) {
          this.$message.error('奖项ID不能为空！')
        } else if (data.goodsType == 1 && !data.deliveryType) {
          this.$message.error('实物商品发货方式不能为空！')
        } else if (data.goodsType == 1 && data.postalPayType == 2 && !data.postageAmount) {
          this.$message.error('固定邮费必须大于0！')
        } else {
          let temp = addGoodsDetail
          if (this.addPrizeDetailDialogStatus == 1) {
            temp = editGoodsDetail
          }
          temp(this.goodsDetail).then(() => {
            this.$message.success(`${this.addPrizeDetailDialogStatus == 1 ? '编辑' : '新增'}奖品成功！`)
            this.addPrizeDetailVisible = false
          })
        }
      },
      handleAddGoodsDetail() {
        this.goodsDetail.itemId = this.selectItemId
        this.addPrizeDetailDialogStatus = 0
        this.addPrizeDetailVisible = true
      },
      handleDeletePrizeDetail(row) {
        this.$confirm('确定要删除此奖品吗？', '提示', {
          type: 'warning',
        }).then(() => {
          deletePrizeDetail({ id: row.id }).then(() => {
            this.$message.success('删除成功！')
            this.fetchPrizeDetailData(this.selectItemId)
          })
        })
      },
      colosePrizeDetailDialog() {
        this.prizeDetailList = []
        this.prizeDetailTotal = 0
        this.prizeDetailQueryForm = {
          pageSize: 10,
          currentPage: 1,
        }
        this.selectItemId = ''
        this.fetchPrizeData()
      },
      handleDeletePrize(row) {
        this.$confirm('确定要删除此奖品设置吗？', '提示', {
          type: 'warning',
        }).then(() => {
          deletePrizeLevel({ id: row.id }).then(() => {
            this.$message.success('删除成功！')
            this.fetchPrizeData()
          })
        })
      },
      openEditPrize(row) {
        const { id } = row
        this.selectItemId = id
        this.fetchPrizeDetailData(this.selectItemId).then(() => {
          this.prizeDetailVisible = true
        })
      },
      handleCreate() {
        if (this.instId) {
          this.createBtnLoading = true
          let temp = null
          if (this.pageStatus == 1) {
            temp = editStep3
          } else {
            temp = createStep3
          }
          temp({ instId: this.instId })
            .then(() => {
              this.$message.success(`${this.pageStatus == 1 ? '编辑' : '新增'}实例成功！`)
              this.$router.replace({
                path: '/activity/indiana/add/success',
                query: {
                  actId: this.$route.query.actId,
                  status: this.pageStatus,
                  instId: this.instId,
                  allActType: this.allActType,
                },
              })
            })
            .finally(() => {
              this.createBtnLoading = false
            })
        }
      },
      dateChange(e) {
        if (e && e.length) {
          this.step1Data.startDate = e[0]
          this.step1Data.endDate = e[1]
        } else {
          this.step1Data.startDate = ''
          this.step1Data.endDate = ''
        }
      },
      handlePreView(e) {
        this.imgUrl = e.url
        this.imgDetailVisible = true
      },
      handleActLogoSuccess(e) {
        this.step1Data.instLogo = e.data.filePath
      },
      handleActBgRemove() {
        this.step1Data.instBackdrop = ''
      },
      handleActBgSuccess(e) {
        this.step1Data.instBackdrop = e.data.filePath
      },
      handleActLogoRemove() {
        this.step1Data.instLogo = ''
      },
      handleGoodsImgSuccess(e) {
        this.goodsDetail.goodsImage = e.data.filePath
      },
      handleGoodsImgRemove() {
        this.goodsDetail.goodsImage = ''
      },
      handleEditPrizeDetailLevel(row) {
        this.prizeLevelData = {
          id: row.id,
          instanceId: this.instId,
          itemName: row.itemName,
          itemInfo: row.itemInfo,
          itemLevel: row.itemLevel,
          itemSort: row.itemSort,
          itemRate: row.itemRate,
          itemStatus: row.itemStatus,
          isEdit: 1,
        }
        this.addLevelVisible = true
      },
      addLevel() {
        this.prizeLevelData.instanceId = this.instId
        this.prizeLevelData.isEdit = 0
        this.addLevelVisible = true
      },
      handleAddPrize() {
        const data = this.prizeLevelData
        if (!data.itemName) {
          this.$message.error('奖项名称不能为空！')
        } else {
          this.addLevelBtnLoading = true
          let temp = addPrizeLevel
          if (data.isEdit) {
            temp = editPrizeLevel
          }
          temp(data)
            .then(() => {
              this.$message.success('添加奖品等级成功！')
              this.addLevelVisible = false
            })
            .finally(() => {
              this.addLevelBtnLoading = false
            })
        }
      },
      coloseAddLevelDialog() {
        this.prizeLevelData = {
          instanceId: this.instId,
          itemName: '',
          itemInfo: '',
          itemLevel: 1,
          itemSort: 0,
          itemRate: 1,
          itemStatus: 1,
          isEdit: 0,
          id: '',
        }
        this.fetchPrizeData()
      },
      // 添加实例
      async nextStep() {
        if (this.activeIndex == 0) {
          const data = this.step1Data
          let startTime = new Date(data.startDate).getTime()
          let endTime = new Date(data.endDate).getTime()
          let publishTime = new Date(data.publishTime).getTime()
          console.log(data.remark)
          if (!data.instName) {
            this.$message.error('实例名称不能为空！')
          } else if (!data.instLogo) {
            this.$message.error('实例Logo不能为空！')
          } else if (!data.instBackdrop) {
            this.$message.error('实例背景图不能为空！')
          } else if (!data.startDate) {
            this.$message.error('实例开始时间不能为空！')
          } else if (!data.endDate) {
            this.$message.error('实例结束时间不能为空！')
          } else if (!data.publishType) {
            this.$message.error('实例发布类型不能为空！')
          } else if (data.publishType == 2 && !data.publishTime) {
            this.$message.error('定时发布时间不能为空！')
          } else if (data.publishType == 2 && publishTime >= startTime) {
            this.$message.error('定时发布时间要早于开始时间！')
          } else if (!data.instStyle) {
            this.$message.error('实例样式不能为空！')
          } else if (!data.totalActCount) {
            this.$message.error('实例期数不能为空！')
          } else if (!data.instWay) {
            this.$message.error('参与方式不能为空！')
          } else if (!data.personCount) {
            this.$message.error('开奖人数上限不能为空！')
          } else if (!data.lowerPersonLimit) {
            this.$message.error('最低的开奖人数不能为空！')
          } else if (!data.remark) {
            this.$message.error('描述不能为空！')
          } else if (startTime < this.activityStartTime) {
            this.$message.error('开始时间不得早于活动开始时间')
          } else if (endTime > this.activityEndTime) {
            this.$message.error('结束时间不得晚于活动结束时间')
          } else if (this.$route.query.allActType == 20 && !data.boxAmount) {
            this.$message.error('开盒金额不能为空！')
          } else if (this.$route.query.allActType == 20 && !data.boxRecoverAmount) {
            this.$message.error('回收比例不能为空！')
          } else {
            this.stepNextBtnLoading = true
            let temp = null
            if (this.step1Data.id) {
              temp = editStep1
            } else {
              temp = createStep1
            }
            temp(data)
              .then((res) => {
                const { data } = res
                if (data) {
                  this.step2Data.instId = data.id
                  this.step1Data.id = data.id
                  this.instId = data.id
                }
                this.fetchStep1().then(() => this.activeIndex++)
              })
              .finally(() => {
                this.stepNextBtnLoading = false
              })
          }
        } else if (this.activeIndex == 1) {
          const data = this.step2Data
          if (!data.instId) {
            this.$message.error('实例ID不能为空！')
          } else if (!data.partType) {
            this.$message.error('参与用户设置不能为空！')
          } else if (data.partType == 2 && !data.limitUserLvl) {
            this.$message.error('参与用户的最低身份不能为空！')
          } else if (!data.consumeIntegral && !data.consumeGoldenBean && !data.consumeBalance && !data.consumeCash) {
            this.$message.error('消耗积分、金豆、余额、现金必须选填一个')
          } else if (!data.shareLimit) {
            this.$message.error('每人最多购买的份额不能为空！')
          } else {
            this.stepNextBtnLoading = true
            let temp = null
            if (this.step1Data.instStatus == 11) {
              temp = createStep2
            } else {
              temp = editStep2
            }
            if (this.pageStatus == 2) {
              await copyPrizes({
                instId: this.instId,
                oldInstId: this.oldInstId,
              })
            }
            Promise.all([temp(data), this.fetchPrizeData()])
              .then(() => {
                this.fetchStep1().then(() => this.activeIndex++)
              })
              .finally(() => {
                this.stepNextBtnLoading = false
              })
          }
        }
      },
      handlePrizeSizeChange(val) {
        this.prizeQueryForm.pageSize = val
        this.fetchPrizeData()
      },
      handlePrizeCurrentChange(val) {
        this.prizeQueryForm.currentPage = val
        this.fetchPrizeData()
      },
      handlePrizeDetailSizeChange(val) {
        this.prizeDetailQueryForm.pageSize = val
        this.fetchPrizeDetailData(this.selectItemId)
      },
      handlePrizeDetailCurrentChange(val) {
        this.prizeDetailQueryForm.currentPage = val
        this.fetchPrizeDetailData(this.selectItemId)
      },
      fetchPrizeData() {
        return new Promise((resolve, reject) => {
          getPrizeList({ ...this.prizeQueryForm, instanceId: this.instId })
            .then((res) => {
              const { data } = res
              this.prizeTotal = data.total
              this.prizeList = data.records
              resolve(data.records)
            })
            .catch((err) => {
              reject(err)
            })
        })
      },
      fetchPrizeDetailData(itemId) {
        return new Promise((resolve, reject) => {
          getPrizeDetailList({ ...this.prizeDetailQueryForm, itemId })
            .then((res) => {
              const { data } = res
              this.prizeDetailTotal = data.total
              this.prizeDetailList = data.records
              resolve(data.records)
            })
            .catch((err) => {
              reject(err)
            })
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .table-wrapper {
    .input {
      height: 30px;
      width: 200px;
      border: 1px solid #ddd;
      padding-left: 10px;
    }

    .input::placeholder {
      color: #bbb;
    }
  }

  .steps {
    margin-bottom: 20px;
  }

  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }

  .list-item {
    .list-title {
      display: flex;
      align-items: center;

      .line {
        height: 20px;
        width: 4px;
        background-color: rgb(41, 121, 255);
        border-radius: 3px;
      }

      .title-text {
        margin-left: 10px;
        font-size: 18px;
      }
    }

    .item-line {
      display: flex;
      align-items: center;
      margin: 20px;

      .line-left {
        width: 80px;
        color: #bbb;
      }

      .right-list {
        .right-item {
          margin-bottom: 10px;

          .input {
            height: 30px;
            width: 400px;
            border: 1px solid #ddd;
            padding-left: 10px;
          }

          .input::placeholder {
            color: #bbb;
          }
        }
      }

      .line-right {
        display: flex;
        align-items: center;

        .input {
          height: 30px;
          width: 400px;
          border: 1px solid #ddd;
          padding-left: 10px;
        }

        .input::placeholder {
          color: #bbb;
        }

        .right-item {
          margin-right: 20px;
        }

        .img {
          width: 80px;
          height: 80px;
          cursor: pointer;
        }
      }
    }
  }

  .second-item {
    display: flex;
    align-items: center;
    // margin-left: 100px;
    margin: 10px 0 10px 100px;

    .item-title {
      width: 78px;
      text-align: right;
      margin-right: 10px;
    }

    .input {
      margin: 0 10px;
      height: 30px;
      width: 80px;
      border: 1px solid #ddd;
      padding-left: 10px;
    }

    .input::placeholder {
      color: #bbb;
    }

    .desc {
      margin-left: 10px;
      color: #bbb;
    }
  }
</style>
