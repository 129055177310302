<template>
  <div>
    <el-dialog
      append-to-body
      :before-close="handleClose"
      :modal-append-to-body="true"
      title="徽标设置"
      :visible.sync="dialogVisible"
      width="60%">
      <span>
        <el-form ref="form" label-width="80px" :model="form">
          <el-form-item label="徽标状态">
            <el-radio-group v-model="form.control">
              <el-radio :label="true">开启</el-radio>
              <el-radio :label="false">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
         <div v-if="form.control">
          <el-form-item label="徽标内容">
            <el-input v-model="form.text"  maxlength="3" style="max-width: 200px;"/>
          </el-form-item>
          <el-form-item label="文字颜色">
            <div style="display: flex; align-items: center;">
              <div style="width: 70px;margin: 0 20px;">{{ form.textColor }}</div>
              <div style="margin: 0 5px;color: #1780ff;" @click="form.textColor='#FFFFFF'">重置</div>
              <input v-model="form.textColor" type="color"/>
            </div>
          </el-form-item>
          <el-form-item label="背景颜色">
            <div style="display: flex; align-items: center;">
              <div style="width: 230px;margin: 0 20px;">渐变1：{{ form.bgColorStart }}&nbsp;&nbsp;渐变2：{{ form.bgColorEnd }}</div>
              <div style="margin: 0 5px;color: #1780ff;" @click="form.bgColorStart='#FE8681',form.bgColorEnd='#FF5754'">重置</div>
              <input v-model="form.bgColorStart" type="color"/>
              &nbsp;
              <input v-model="form.bgColorEnd" type="color"/>
            </div>
          </el-form-item>
         </div>
        </el-form>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props:{
    showstyle:{
      type:Boolean,
      default:()=>{}
    },
    listrow:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
    return {
      form:'',
      dialogVisible:false,
    }
  },
  watch:{
    showstyle(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisible=newValue
      }
    },
    listrow(newValue,oldValue){
      if(newValue!=oldValue){
        this.form=newValue
      }
    }
  },
  methods:{
    handleClose(){
      this.$emit('closehide',false)
    },
    save(){
      this.$emit('closehide',this.form)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-dialog__header{
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>