<template>
  <div class="three-container">
    <div class="ym_header">控糖管理</div>
    <el-form>
      <el-row>
        <el-form-item label="">
          <el-col :md="{ span: 6 }" :xs="{ span: 24 }">
            <!-- <BaseEditor v-model="dl" /> -->
            <WangEditor ref="editor" :value="articleContent" @wang="ck" />
          </el-col>
        </el-form-item>
        <el-form-item label="">
          <el-col class="text-c" :md="{ span: 6 }" :xs="{ span: 24 }">
            <el-button type="primary" @click="save">保存</el-button>
          </el-col>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import WangEditor from '@/components/editor/WangEditor.vue'
  import {
    getStpPlatformProtocol,
    stpPlatformProtocoledit,
  } from '@/api/mall/admin/sysstting'
  export default {
    components: {
      WangEditor,
    },
    data() {
      return {
        articleContent: '',
        logId:0,
      }
    },
    created() {
      this.table()
    },
    methods: {
      table() {
        getStpPlatformProtocol({modelType:18}).then((res) => {
          // console.log(res.data.articleContent)
          this.articleContent = res.data.content
          this.logId=res.data.logId
        })
      },
      ck(e) {
        this.articleContent = e
      },
      save() {
        const date = this.articleContent
        const parms = {
          content: date,
          logId:this.logId,
        }
        stpPlatformProtocoledit(parms).then((res) => {
          console.log(res.data)
          this.MS('保存成功')
        })
        console.log('Three表单数据', this.form)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .ym_header {
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #ececec;
  }
  ::v-deep .el-button {
    padding: 10px 30px;
  }
</style>
