var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u(
        [
          !_vm.allDisable
            ? {
                key: "footer",
                fn: function () {
                  return [
                    _c("el-button", { on: { click: _vm.close } }, [
                      _vm._v("取 消"),
                    ]),
                    _vm.title != "编辑"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.save },
                          },
                          [_vm._v(" 确 定 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.update },
                          },
                          [_vm._v("保 存")]
                        ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            disabled: _vm.allDisable,
            "label-width": _vm.config.labelWidth,
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: _vm.config.gutter || 10 } },
            _vm._l(_vm.column, function (item) {
              return _c(
                "el-col",
                { key: item.prop, attrs: { span: item.span || 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: item.label,
                        "label-width": item.labelWidth
                          ? item.labelWidth
                          : item.label
                          ? _vm.config.labelWidth
                          : "0px",
                        prop: item.prop,
                      },
                    },
                    [
                      item.slot && item.prop
                        ? _vm._t(item.prop)
                        : !item.type || item.type == "input"
                        ? _c("el-input", {
                            attrs: { placeholder: item.label },
                            model: {
                              value: _vm.form[item.prop],
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  item.prop,
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form[item.prop]",
                            },
                          })
                        : item.type == "radio"
                        ? _vm._l(item.optionData, function (i) {
                            return _c(
                              "el-radio",
                              {
                                key: i.value,
                                attrs: { label: i.value },
                                model: {
                                  value: _vm.form[item.prop],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, item.prop, $$v)
                                  },
                                  expression: "form[item.prop]",
                                },
                              },
                              [_vm._v(" " + _vm._s(i.label) + " ")]
                            )
                          })
                        : item.type == "textarea"
                        ? _c("el-input", {
                            attrs: {
                              placeholder: item.label,
                              rows: item.rows || 1,
                              type: "textarea",
                            },
                            model: {
                              value: _vm.form[item.prop],
                              callback: function ($$v) {
                                _vm.$set(_vm.form, item.prop, $$v)
                              },
                              expression: "form[item.prop]",
                            },
                          })
                        : item.type == "upload"
                        ? _c(
                            "el-upload",
                            {
                              attrs: {
                                action:
                                  "upms/file/upload?fileType=image&dir=material/",
                                "before-upload": _vm.beforeUpload,
                                "file-list": _vm.fileList,
                                headers: _vm.headers,
                                "list-type": "picture-card",
                                "on-error": _vm.handleError,
                                "on-success": _vm.handleSuccess,
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          )
                        : item.type == "select"
                        ? [
                            _c(
                              "el-select",
                              {
                                staticClass: "w100s",
                                attrs: {
                                  clearable: "",
                                  placeholder: `请选择 ${item.label}`,
                                },
                                model: {
                                  value: _vm.form[item.prop],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, item.prop, $$v)
                                  },
                                  expression: "form[item.prop]",
                                },
                              },
                              _vm._l(item.optionData, function (i) {
                                return _c("el-option", {
                                  key: item.key ? i[item.key] : i.label,
                                  attrs: {
                                    label: item.key ? i[item.key] : i.label,
                                    value: item.value ? i[item.value] : i.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ]
                        : item.type == "number"
                        ? [
                            _c("el-input-number", {
                              staticClass: "w100s",
                              attrs: {
                                "controls-position": "right",
                                max: 9999,
                                min: 1,
                                placeholder: item.label,
                              },
                              model: {
                                value: _vm.form[item.prop],
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, item.prop, $$v)
                                },
                                expression: "form[item.prop]",
                              },
                            }),
                          ]
                        : item.type == "checkbox"
                        ? [
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: _vm.form[item.prop],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, item.prop, $$v)
                                  },
                                  expression: "form[item.prop]",
                                },
                              },
                              _vm._l(item.optionData, function (i) {
                                return _c("el-checkbox", {
                                  key: i.value,
                                  attrs: { label: i.label },
                                })
                              }),
                              1
                            ),
                          ]
                        : item.type == "BaseEditor"
                        ? [
                            _c("BaseEditor", {
                              model: {
                                value: _vm.form[item.prop],
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, item.prop, $$v)
                                },
                                expression: "form[item.prop]",
                              },
                            }),
                          ]
                        : item.type == "operation"
                        ? [_vm._t("operation")]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }