<template>
  <div class="custom-table-container">
    <div class="header_eitd">
      <div class="lef">
        <el-steps :active="num" finish-status="success" simple style="">
          <!-- <el-step title="待分配"/> -->
          <el-step title="处理中 "/>
          <el-step title="待审核"/>
          <el-step title="已完成"/>
        </el-steps>
      </div>
      <!-- <div v-if="designate"  class="ref">
        <el-button type="primary" @click="distrtionshow=true">指派</el-button>
       
          <el-button v-if="designates" type="" @click="passuser">驳回</el-button>
      
        
      </div> -->
    </div>
    <div class="thead_cont">
      <div class="lefCont">
        <aftersalt/>
      </div>
      <div class="lefCont">
        <beforcont :showhand="num"/>
      </div>
    </div>
    <distrtion :shodist="distrtionshow" :userid="listID" @closedistr="closdistr" />
  </div>
</template>

<script>
import{uuUserSugarRecordAfterturnDown} from '@/api/mall/seas/index'
import aftersalt from './components/aftesaletrcont/index.vue'
import beforcont from './components/beforcont/index.vue'
import distrtion from './components/distribution/distribution.vue'
export default {
  components:{
    aftersalt,
    beforcont,
    distrtion
  },
  data(){
    return{
      num:1,
      listID:[],
      distrtionshow:false,
      designate:true,
      designates:true,
    }
  },
  created(){
    if(this.$route.query.date){
      let numx=JSON.parse(this.$route.query.date).afterType
      console.log(numx,'numx');
      // lebalName
      let numxx=JSON.parse(this.$route.query.date).lebalName
      if(numxx == "退款"){
        this.designates = false
      }
      //如果驳回====等于完成
      //      console.log("核流程 1：待分配 2：待处理 3：待审核 4：已完成", numx)
      this.$baseEventBus.$emit('shwoFrom',numx)
      if(numx==4){
       this.num=numx;
      } 
       if(numx>1){
        this.num=numx - 1
       this.designate=false
      } if(numx==1){
        this.num=numx
        if(this.$route.query.switch!=''){
          if(this.$route.query.switch=='false'){
            this.designate=false
          }else{
            this.designate=true
          }
        }else{
          this.designate=true
        }
      }
      this.listID=JSON.parse(this.$route.query.date)
    }
  },
  methods:{
    closdistr(e){
      this.distrtionshow=e
    },
    passuser(){
      if(this.listID!=null){
        this.$prompt('请输入驳回缘由', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          })
            .then((da) => {
              if (da.value) {
                let ids=[this.listID.id]
                uuUserSugarRecordAfterturnDown({ ids, content: da.value }).then((res) => {
                  if (res.status == 200) {
                    this.$message.success('驳回成功')
                    this.$router.push('afterSaleData')
                  } else {
                    this.$message.error(res.msg)
                  }
                })
              } else {
                this.$message.error('请填写驳回缘由')
              }
            })
            .catch(() => {
              this.$message('您已取消驳回')
            })
      }else{
        this.$message.error("暂无用户信息")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-table-container{
  width: 100%;
  .header_eitd{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 63px;
    .lef{
      ::v-deep{
        .el-step{
          width: 140px;
          white-space: nowrap;
        }
        .el-step__title{
          max-width: 100%;
        }
      }
    }
  }
  .thead_cont{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
    width: 100%;
    margin: 10px 0;
    .lefCont{
    }
  }
}
</style>