import request from '@/utils/request'
import { getToken } from '@/utils/token'
// 订单列表
export function getOrderList(data) {
  return request({
    url: '/mall/orderinfo/page',
    method: 'get',
    params: data,
  })
}

// 订单详情
// export function getOrderDetail(data) {
//   return request({
//     url: '/order/ordOrder/details',
//     method: 'post',
//     data,
//   })
// }

export function getOrderDetail(data) {
  return request({
    url: '/mall/orderinfo/' + data.id,
    method: 'get',
  })
}

// 订单发货
export function getDelivery(data) {
  return request({
    url: '/order/ordOrderItem/delivery',
    method: 'post',
    data,
  })
}
// 修改收货地址
export function getOrderUpdatelogistics(data) {
  return request({
    url: '/mall/orderlogistics/updateLogins',
    method: 'post',
    data,
  })
}
//查询物流
export function getLogisticsList(data) {
  return request({
    url: '/mall/logistics/list',
    method: 'get',
    data,
  })
}

// 云打印快递单
export function addLogisticsOrder(data) {
  return request({
    url: '/wx/logistics/addLogisticsOrder',
    method: 'post',
    data,
  })
}
// 取消运单
export function cancelOrder(data) {
  return request({
    url: '/wx/logistics/cancelOrder',
    method: 'post',
    data,
  })
}

// 订单导出
export function exportMallOrder(data) {
  return request({
    url: '/mall/home/exportMallOrder',
    headers: {
      'Switch-Tenant-Id': '1',
      'Content-Type': 'application/vnd.ms-excel; charset=UTF-8',
      Authorization: `Bearer ${getToken()}`,
    },
    method: 'post',
    responseType: 'blob',
    data,
  })
}

//导出订单
export function orderExport(data) {
  return request({
    url: '/mall/home/exportOrder',
    headers: {
      'Switch-Tenant-Id': '1',
      Authorization: `Bearer ${getToken()}`,
    },
    method: 'post',
    data,
    responseType: 'blob',
  })
}

//导出员工订单排行榜
export function exportEmpOrder(data) {
  return request({
    url: '/mall/home/exportEmpOrder',
    headers: {
      'Switch-Tenant-Id': '1',
      Authorization: `Bearer ${getToken()}`,
    },
    method: 'post',
    data,
    responseType: 'blob',
  })
}

export function statisticsOrderCount(data) {
  return request({
    url: '/mall/orderinfo/statisticsOrderCount',
    method: 'post',
    data,
  })
}

export function putdelivery(data) {
  return request({
    url: '/mall/orderinfo/delivery',
    method: 'post',
    data: data,
  })
}

//淘宝客订单
export function publioder(data) {
  return request({
    url: '/mall/tbk/publisherOrderDtoBean/queryPage',
    method: 'post',
    data,
  })
}

//退款列表
export function orderrefundspage(query) {
  return request({
    url: '/mall/orderrefunds/page',
    method: 'get',
    params: query,
  })
}
