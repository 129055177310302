<template>
  <div class="comprehensive-table-container">
    <common-form v-model="searchForm" :options="searchOptions">
      <template slot="operation">
        <el-button type="primary">搜索</el-button>
        <el-button>重置</el-button>
      </template>
    </common-form>
    <el-col :span="6">
      <el-date-picker
        v-model="searchForm.date"
        align="right"
        class="m-b"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        range-separator="至"
        start-placeholder="开始日期"
        type="datetimerange"
      />
    </el-col>
    <!-- <el-button class="m-b" icon="el-icon-plus" type="primary">新增</el-button> -->

    <el-table border :data="data">
      <el-table-column align="center" label="名称" sortable />
      <el-table-column align="center" label="是否显示" sortable />
      <el-table-column align="center" label="排序" />
      <el-table-column align="center" label="操作" />
    </el-table>
  </div>
</template>

<script>
  import { searchOptions } from './const/goodsappraises'
  export default {
    data() {
      return {
        searchOptions,
        searchForm: {},
        pickerOptions: {
          shortcuts: [
            {
              text: '今日',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '昨日',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
        options: [
          {
            value: '选项1',
            label: 'Apple正品-上海官方旗舰店',
          },
          {
            value: '选项2',
            label: '双皮奶',
          },
          {
            value: '选项3',
            label: '蚵仔煎',
          },
          {
            value: '选项4',
            label: '龙须面',
          },
          {
            value: '选项5',
            label: '北京烤鸭',
          },
        ],
        value: '选项1',
        data: [],
      }
    },
  }
</script>

<style lang="scss" scoped>
  $gap: 10px;
  .m-b {
    margin-bottom: $gap;
  }
  .w100s {
    width: 100%;
  }
</style>
