<template>
  <div class="textTool">
    <div class="toolTit">
      <div class="lef">图文中心</div>
      <div class="ref">
        <div class="switch">
          <div :class="checkIndex == 1 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(1)">内容</div>
          <div :class="checkIndex == 2 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(2)">样式</div>
        </div>
      </div>
    </div>
    <div v-if="checkIndex == 1" class="content_msg">
      <div class="ym_line"></div>
      <div class="ym_title">图片设置</div>
      <div class="alert-msg" style="margin: top 20px; text-align: left">建议上传尺寸相同的图片，推荐尺寸750*350</div>
      <div v-for="(ym, index) in activeComponent[indexnumer].list" :key="index" class="itemBox">
        <div class="card-cont">
          <div class="close_card-item" @click="deletelist(index)">
            <i class="el-icon-close"></i>
          </div>
          <div class="left_icon" style="overflow: hidden">
            <uploadpic :forindex="index" :picurl="[{ url: ym.imageUrl }]" @backkuck="check($event, index)" />
          </div>
          <div class="ref_cont">
            <div class="ref_thead">
              <div>链接地址</div>
              <div class="ref_txt" @click="checklink(index)">
                <span v-if="ym.link.name != ''" style="color: #1780ff">{{ ym.link.title }}</span>
                <span v-else style="color: #1780ff">请选择链接</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="ref_thead">
              <div>图像高度</div>
              <div class="ref_txt">
                <!-- <span  v-if="ym.link.name!=''"  style="color: #1780ff">{{ ym.link.title }}</span><span  v-else  style="color: #1780ff">请选择链接</span> -->
                <el-input v-model="ym.imgHeight" style="width: 60px" />
                <!-- <i class="el-icon-arrow-right"></i> -->
              </div>
            </div>
            <div class="ref_thead">
              <div>缩放模式</div>
              <div class="ref_txt" style="color: #1780ff" @click="imgmodel(index, ym.imageMode)">
                <span v-if="ym.imageMode == 'aspectFill'">填充</span>
                <span v-if="ym.imageMode == 'aspectFit'">缩放</span>
                <span v-if="ym.imageMode == 'scaleToFill'">拉伸</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="add-ba" @click="addlist">
          <i class="el-icon-plus"></i>
          增加一个
        </div>
      </div>
    </div>
    <div v-if="checkIndex == 2" class="content_msg">
      <div class="ym_line"></div>
      <div class="ym_title">组件样式</div>
      <div class="itemBox">
        <div class="Tit">底部背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponent[indexnumer].pageBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponent[indexnumer].pageBgColor" />
            <div class="resect" @click="activeComponent[indexnumer].pageBgColor = '#FFFFFF'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="alert-msg">底部背景包含边框圆角</div>
      <div class="itemBox">
        <div class="Tit">组件背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponent[indexnumer].componentBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponent[indexnumer].componentBgColor" />
            <div class="resect" @click="activeComponent[indexnumer].componentBgColor = '#FFFFFF'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <!-- <div class="itemBox">
        <div class="Tit">图像高度</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="bannerHeight" :max="500"  :min="1" size="mini" style="width: 150px" @change="banerHei"/>
          <el-input v-model="bannerHeight"  :max="500"  style="width: 80px; margin-left: 10px" type="number" />
        </div>
      </div> -->
      <div class="alert-msg">此处是统一设置图像高度</div>
      <div class="ym_line" style="margin: 10px 0"></div>
      <div class="itemBox">
        <div class="Tit">上边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponent[indexnumer].margin.top" :max="100" :min="0" size="mini" style="width: 150px" @change="demo($event, indexnumer)" />
          <el-input-number v-model="activeComponent[indexnumer].margin.top" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponent[indexnumer].margin.bottom" :max="100" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponent[indexnumer].margin.bottom" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">左右边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponent[indexnumer].margin.both" :max="20" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponent[indexnumer].margin.both" :max="20" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponent[indexnumer].topAroundRadius" :max="50" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponent[indexnumer].topAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponent[indexnumer].bottomAroundRadius" :max="50" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponent[indexnumer].bottomAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
    </div>
    <stydialog :listrow="listcheck" :showstyle="showhide" @closehide="cloeshow" />
    <linkdialog :linkshow="linkurl" @closeurl="closelink" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import uploadpic from '../../../upload-pic/upload.vue'
import stydialog from './components/stydialog.vue'
import linkdialog from '../../../link-dialog/index.vue'
export default {
  components: {
    uploadpic,
    stydialog,
    linkdialog,
  },
  props: {
    indexnumer: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      checkIndex: 1,
      num: this.indexnumer,
      styleshow: false, //风格选择
      showhide: false,
      listcheck: '',
      Records: null,
      linkurl: false,
      checklinknum: null,
      bannerHeight: 0,
    }
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.checkvalue,
    }),
  },
  watch: {
    indexnumer(newValue, oldValue) {
      if (newValue != oldValue) {
        this.num = newValue
        this.$forceUpdate()
      }
    },
  },
  methods: {
    banerHei(e) {
      const Imghe = this.activeComponent[this.indexnumer].list
      Imghe.forEach((element) => {
        element.imgHeight = e
        console.log(element)
      })
    },
    checklink(Index) {
      this.checklinknum = Index
      this.linkurl = true
    },
    closelink(lisr) {
      if (lisr != false) {
        this.activeComponent[this.indexnumer].list[this.checklinknum].link = lisr
        this.linkurl = false
      } else {
        this.linkurl = false
      }
    },
    //回传关闭
    cloeshow(e) {
      if (e == false) {
        this.showhide = false
      } else {
        this.activeComponent[this.indexnumer].list[this.Records].imageMode = e
        this.showhide = false
      }
    },
    imgmodel(Index, row) {
      this.Records = Index
      this.showhide = true
      this.listcheck = row
    },
    check(event, Index) {
      this.activeComponent[this.indexnumer].list[Index].imageUrl = event
    },
    //切换item
    changeChecknum(type) {
      this.checkIndex = type
    },
    addlist() {
      let addlist = {
        imageUrl: 'https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png',
        link: {
          name: '',
        },
        imgWidth: '1176',
        imgHeight: '7561',
        id: '23xnjfameiw00',
        imageMode: 'scaleToFill',
      }
      this.activeComponent[this.indexnumer].list.push(addlist)
    },
    deletelist(Index) {
      this.$confirm('是否删除该数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.activeComponent[this.indexnumer].list.splice(Index, 1)
        })
        .catch(() => {
          this.$message('您已取消删除')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.textTool {
  width: 100%;
  padding: 20px 20px 0 20px;
  .toolTit {
    display: flex;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
    color: #333333;
    .switch {
      display: flex;
      align-items: center;
      overflow: hidden;
      font-size: 14px;
      cursor: pointer;
      background: #f5f5f5;
      border-radius: 50px;
      .swit-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 29px;
        border-radius: 50px;
      }
      .active {
        color: white;
        background: #1780ff;
      }
    }
  }
  .ym_line {
    width: 100%;
    height: 3px;
    background: #f2f4f6;
  }
  .ym_title {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .itemBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    min-height: 40px;
    margin-left: 20px;
    .add-ba {
      align-items: center;
      width: 100%;
      min-height: 40px;
      margin: 20px 0;
      line-height: 40px;
      color: white;
      text-align: center;
      cursor: pointer;
      background: #1780ff;
      border: 1px dashed transparent;
    }
    .card-cont {
      position: relative;
      display: grid;
      grid-template-columns: 80px 160px;
      gap: 10px;
      justify-content: space-between;
      width: 100%;
      height: 80px;
      padding: 0 4px;
      margin: 5px 0;
      border: 1px dashed #e5e5e5;
      .close_card-item {
        position: absolute;
        top: -9px;
        right: -9px;
        width: 18px;
        height: 18px;
        line-height: 18px;
        color: white;
        text-align: center;
        cursor: pointer;
        background: #424242;
        border-radius: 100%;
      }
      .left_icon {
        padding-top: 4px;
      }
      .ref_cont {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: 100%;
        .ref_thead {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          font-size: 13px;
        }
      }
    }
    .Tit {
      margin-right: 15px;
      color: gray;
      text-align: right;
      white-space: nowrap;
    }
    .check-tit {
      display: flex;
      justify-content: right;
      width: calc(100% - 56px);
      color: #105cfb;
      .resect {
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }
  .alert-msg {
    margin-bottom: 15px;
    font-size: 12px;
    color: rgb(210, 210, 210);
    text-align: center;
  }
  :deep(.el-color-picker__trigger) {
    width: 45px;
    height: 26px;
  }
  :deep(.el-icon-arrow-down:before) {
    display: none;
  }
  ::v-deep {
    .el-input-number {
      width: 80px;
      .el-input__inner {
        padding: 0;
        text-align: center;
      }
    }

    .el-input-number__decrease,
    .el-input-number__increase {
      display: none;
    }
  }
}
</style>
