<template>
  <div class="container">
    <el-dialog :before-close="handleClose" title="录音信息" :visible.sync="dialogVisible" width="60%">
      <span>
        <el-radio-group v-model="radio4" size="mini" style="margin-bottom: 10px" @change="checktags">
          <el-radio-button label="1">电话1</el-radio-button>
          <el-radio-button label="2">电话2</el-radio-button>
        </el-radio-group>
        <el-table border :data="tablist" max-height="300">
          <!-- <el-table-column align="center" type="selection" width="55" /> -->
          <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="50" />
          <el-table-column align="center" label="开始时间" prop="startTime" width="200" />
          <el-table-column align="center" label="结束时间" prop="endTime" width="200" />
          <el-table-column align="center" label="坐席工号" prop="agent" show-overflow-tooltip width="120" />
          <el-table-column align="center" label="坐席姓名" prop="agentName" show-overflow-tooltip width="120" />
          <el-table-column align="center" label="主叫号码" prop="callingPhone" show-overflow-tooltip width="160" />
          <el-table-column align="center" label="被叫号码" prop="calledPhone" show-overflow-tooltip width="160" />
          <el-table-column align="center" label="应答时长(秒)" prop="answerDuration" show-overflow-tooltip />
          <el-table-column align="center" label="操作">
            <template #default="scope">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link" style="color: #1780ff">
                  操作
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <div @click="audiourl(scope.row.playUrl, 1)">试听</div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div @click="audiourl(scope.row.playUrl, 2)">下载</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :current-page="1"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { selectRecordList } from '@/api/mall/health/casemy/index'
  import { stpCxccConfig } from '@/api/mall/common'
  export default {
    props: {
      sound: {
        type: Object,
        default: () => {},
      },
      recordedshow: {
        type: Boolean,
        default: () => {},
      },
    },
    data() {
      return {
        total: null,
        radio4: '1',
        tablist: [],
        showlist: false,
        showtblist: null,
        form: {
          size: 10,
          current: 1,
          calledPhone: this.userinfo,
        },
        domainUrl: '',
        dialogVisible: false,
      }
    },
    watch: {
      sound(newValue, oldValue) {
        if (newValue != oldValue) {
          // this.form.calledPhone = newValue.phone
          console.log(newValue.phone)
          console.log(newValue)
        }
      },
      recordedshow(newValue, oldValue) {
        if (newValue != oldValue) {
          this.uuUserSugarRecordLog()
          this.dialogVisible = newValue
          this.stpCxccConfig()
        }
      },
    },
    created() {
      this.form.calledPhone = this.sound.phone
      this.uuUserSugarRecordLog()
    },
    methods: {
      //关闭对话框
      handleClose() {
        this.tablist = []
        this.$emit('closesound', false)
        this.dialogVisible = false
      },
      //电话1-2切换
      checktags(e) {
        if (e == 1) {
          this.form.calledPhone = this.sound.phone
          this.uuUserSugarRecordLog()
        } else {
          this.form.calledPhone = this.sound.phonev
          this.uuUserSugarRecordLog()
        }
      },
      //关闭回传
      closeshow() {
        this.showlist = false
      },
      showhide(row) {
        this.showtblist = row
        this.showlist = true
        console.log(this.showlist)
      },
      handleCurrentChange(e) {
        this.form.current = e
        this.uuUserSugarRecordLog()
      },
      handleSizeChange(e) {
        this.form.size = e
        this.uuUserSugarRecordLog()
      },
      //列表请求
      uuUserSugarRecordLog() {
        selectRecordList({ ...this.form }).then((res) => {
          this.total = res.total
          this.form.size = res.size
          this.form.current = res.current
          this.tablist = res.records
        })
      },

      //		当客户通过录音清单接口获取到录音文件路径，便可以下载或者试听录音。
      //		下载地址:
      //		http(s)://ip:port/om/calldetailList/exportRecordingFile?playUrl=<playUrl>
      //		试听地址:
      //		http(s)://ip:port/om/calldetailList/playRecordingOnline?playUrl=<playUrl>
      stpCxccConfig() {
        stpCxccConfig({}).then((res) => {
          this.domainUrl = res.data.domainUrl
          console.log(this.domainUrl)
        })
      },
      //音频播放or播放
      audiourl(audiourl, type) {
        let add_url = this.domainUrl + audiourl
        // let add_url = 'https://139.9.95.3:9526' + audiourl
        console.log(add_url)
        if (add_url) {
          if (type == 1) {
            window.open(add_url, '_blank', 'height=260, width=260, top=1 00, left=100,toolbar=no, menubar=no, scrollbars=no, resizable=no, loca tion=no, status=no')
          } else {
            fetch(add_url)
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(blob) //编译下载url
                const link = document.createElement('a') //创建a标签
                link.download = 'file.wav' // 设置下载的文件名
                link.href = url // 设置链接的URL
                link.click() // 模拟点击下载链接
              })
              .catch((error) => console.error(error))
          }
        } else {
          this.$message.error('播放地址错误')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__header {
      border-bottom: 1px solid #e5e5e5;
    }
  }
</style>
