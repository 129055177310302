<template>
  <div class="container">
    <el-dialog
      append-to-body
      :before-close="handleClose"
      title="预存金额"
      :visible.sync="dialogVisible"	
      width="50%">
      <span>
        <div class="thead">
          当前余额：{{ logAmount }}
        </div>
        <el-table
          ref="multipleTable"  
          border 
          :data="tablist"  
          default-expand-all
          fit
          highlight-current-row
          max-height="300" 
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"/>
          <el-table-column align="center" label="序号" prop="id" show-overflow-tooltip width="50" />
      <el-table-column align="center" label="交易类型" prop="optType" width="100px">
        <template #default="scope">
          <div v-if="scope.row.optType == 0">汇入</div>
          <div v-if="scope.row.optType == 1">支出</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="交易金额" prop="logAmount"   width="100px"  />
      <el-table-column align="center" label="交易渠道"   width="120px"  >
        <template #default="scope">
          <!-- 交易类型 1:微信零钱 2：支付宝 3：银行卡 -->
          <div v-if="scope.row.bussType == 1">微信</div>
          <div v-if="scope.row.bussType == 2">支付宝</div>
          <div v-if="scope.row.bussType == 3">银行卡</div>
        </template>
       </el-table-column>
      <el-table-column align="center" label="账号信息"  show-overflow-tooltip >
        <template #default="scope"> {{ scope.row.bankName }} {{ scope.row.bankNumber != null ? "(" +scope.row.bankNumber + ")": ''  }}</template>
      </el-table-column>
      <el-table-column align="center" label="交易备注" prop="content" show-overflow-tooltip  />
  
      <el-table-column align="center" label="交易状态" prop="preStatus" width="160px" >
        <template #default="scope">
          <el-tag v-if="scope.row.preStatus == 1" type="succes">已到款</el-tag>
          <el-tag v-if="scope.row.preStatus == 2" type="info">未到款</el-tag>
          <el-tag v-if="scope.row.preStatus == 3" type="warning">已经取消</el-tag>
          <el-tag v-if="scope.row.preStatus == 4"  >已关联</el-tag>
        </template>
      </el-table-column>
        </el-table>
        <el-pagination background :current-page="1" layout="total, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {merRecordPrestorequeryPage,statistics} from '@/api/mall/setting/stplabel'
  export default {
    props: {
      sound: {
        type: Object,
        default: () => {},
      },
      logamounthide:{
        type:Boolean,
        default:()=>{}
      }
    },
    data() {
      return {
        total: 0,
        tablist: [],
        showlist: false,
        showtblist: null,
        logAmount:null,//预存余额
        form: {
          size: 10,
          current: 1,
          recordId: null,
          preStatus:1,   //类型 1-已到款 2-未到款 3-已经取消.
        },
        dialogVisible:false,
        selectedRows:null,//选中条数
      }
    },
    watch: {
      sound(newValue, oldValue) {
        if (newValue != oldValue) {
          this.form.recordId = newValue.id
          this.userinfo=newValue
        }
      },
      logamounthide(newValue, oldValue) {
        if (newValue != oldValue) {
          this.dialogVisible=newValue
        }
      },
    },
    created() {
      this.form.recordId = this.sound.id
      this.uuUserSugarRecordLog()
      this.statistics()
    },
    methods: {
      //选中监听
      handleSelectionChange(val){
       console.log(val[val.length-1])
       if (val.length > 1) {
          this.$refs.multipleTable.clearSelection()
          this.$refs.multipleTable.toggleRowSelection(val.pop())
        } else {
          this.selectedRows=val
        }
      },
      handleClose(){
        this.selectedRows=null
        this.$emit('closeloagmount',false)
      },
      handleCurrentChange(e) {
        this.form.current = e
        this.uuUserSugarRecordLog()
      },
      handleSizeChange(e) {
        this.form.size = e
        this.uuUserSugarRecordLog()
      },
      //列表请求
      uuUserSugarRecordLog() {
        merRecordPrestorequeryPage({ ...this.form }).then((res) => {
          console.log(res.data)
          this.total = res.data.total
          this.form.size = res.data.size
          this.form.current = res.data.current
          this.tablist = res.data.records
        })
      },
      //余额请求
      statistics(){
        statistics({recordId:this.form.recordId}).then(res=>{
          this.logAmount=res.data.logAmount
        })
      },
      //回传已选值
      save(){
        if(this.selectedRows){
          this.$emit('closeloagmount',this.selectedRows)
          this.selectedRows=null
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.thead{
  margin-bottom: 10px;
}
::v-deep{
  .el-dialog__header{
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>