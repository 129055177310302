<template>
  <div class="cy-container">
    <!-- 查询 -->
    <el-form
      ref="queryForm"
      :inline="true"
      :model="queryForm"
      @submit.native.prevent
    >
      <el-form-item label="主题名称" prop="actName">
        <el-input
          v-model.trim="queryForm.actName"
          class="filterItems"
          clearable
          placeholder="请输入主题名称"
        />
      </el-form-item>
      <el-form-item label="主题状态" prop="actStatus">
        <el-select
          v-model="queryForm.actStatus"
          class="filterItems"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in themeStateList"
            :key="item.dictKey"
            :label="item.dictValue"
            :value="item.dictKey"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="主题时间">
        <el-date-picker
          v-model="datePicker"
          align="center"
          style="width: 300px"
          type="daterange"
          value-format="yyyy-MM-dd"
          @change="dateChange"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          icon="el-icon-search"
          native-type="submit"
          type="primary"
          @click="handleQuery"
        >
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          native-type="submit"
          type="primary"
          @click="handleReset"
        >
          重置
        </el-button>
      </el-form-item>
      <el-form-item style="float: right">
        <!-- <el-button icon="el-icon-plus" type="primary" @click="handelAdd">
          添加主题活动
        </el-button>
        <el-button type="primary" @click="handelExport">导出</el-button> -->
        <el-button
          v-for="item in searchButton"
          :key="item.id"
          :plain="item.cssType && item.cssType == 'plain' ? true : false"
          :type="item.buttonCss"
          @click.native="handleClickBtn(item)"
        >
          <span v-if="!item.buttonIcon"></span>
          <vab-icon v-else :icon="item.buttonIcon" />
          {{ item.buttonName }}
        </el-button>
      </el-form-item>
    </el-form>
    <!-- table表格 -->
    <el-table
      v-loading="listLoading"
      border
      :data="tableData"
      default-expand-all
    >
      <el-table-column
        align="center"
        label="主题名称"
        prop="actName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="主题封面"
        prop="coverPicture"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <div>
            <el-image
              fit="fill"
              :src="row.coverPicture"
              style="width: 35px; height: 35px; cursor: pointer"
              @click="handleImgClick(row.coverPicture)"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        :formatter="formatterPlatform"
        label="参与平台"
        prop="actPlatform"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <div v-if="row.actPlatform">
            <span
              v-for="(item, index) in row.actPlatform.split(',')"
              :key="index"
            >
              {{ formatterPlatform(item) + ',' }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="开始时间"
        prop="startTime"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <div>
            {{ row.startTime | formatDate }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="结束时间"
        prop="endTime"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <div>
            {{ row.endTime | formatDate }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="发布时间"
        prop="publishTime"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <div v-if="row.publishTime">
            {{ row.publishTime | formatDate }}
          </div>
          <div v-else>{{ row.updateTime | formatDate }}</div>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        :formatter="formatterThemeState"
        label="主题状态"
        prop="actStatus"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作人"
        prop="username"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="操作" min-width="120px">
        <template #default="{ row }">
          <template v-for="item in listButton">
            <el-button
              v-if="showListButton(row, item)"
              :key="item.id"
              :plain="item.cssType && item.cssType == 'plain' ? true : false"
              size="mini"
              :type="item.buttonCss"
              @click.native="handleClickBtn(item, row)"
            >
              <span v-if="!item.buttonIcon"></span>
              <vab-icon v-else :icon="item.buttonIcon" />
              {{ item.buttonName }}
            </el-button>
          </template>
          <!-- <el-button type="text" @click="handleLook(row)">查看</el-button>
          <el-button type="text" @click="handleCopy(row, 1)">复制</el-button>
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button
            v-if="row.actStatus == 1"
            type="text"
            @click="handleDelete(row)"
          >
            关闭
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      :current-page="forms.currentPage"
      :layout="layout"
      :page-size="forms.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 图片预览 -->
    <el-dialog :visible.sync="imgDialog">
      <img :src="imagesUrl" width="100%" />
    </el-dialog>
  </div>
</template>
<script>
  import { queryPage, deletes } from '@/api/activity/theme'
  import { selectSysDict, selectRoleMenuButtonList } from '@/api/mall/common'
  export default {
    name: 'ThemeList',
    data() {
      return {
        imgDialog: false,
        imagesUrl: '',
        datePicker: '',
        themeStateList: [],
        operationShow: false,
        listButtonParent: [],
        searchButton: [],
        listButton: [],
        listLoading: false,
        forms: { currentPage: 1, pageSize: 10 },
        isShow: true,
        queryForm: {
          actName: '',
          actStatus: '',
        },
        tableData: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,

        actPlatformLsit: [],
      }
    },
    created() {
      this.fetchData()
      this.selectSysDict()
      this.selectRoleMenuButtonList()
    },
    activated() {
      this.fetchData()
      this.selectSysDict()
      this.selectRoleMenuButtonList()
    },
    methods: {
      // 按钮状态显示判断
      showListButton(row, item) {
        if (row.actStatus == 2 && item.buttonUrl == 'handleDelete') {
          return false
        } else if (
          item.buttonUrl == 'handleLook' ||
          item.buttonUrl == 'handleCopy' ||
          item.buttonUrl == 'handleEdit' ||
          item.buttonUrl == 'handleDelete'
        ) {
          return true
        }
      },
      // 按钮点击
      handleClickBtn(item, row) {
        if (item.buttonUrl && item.buttonUrl == 'handleCopy') {
          this[item.buttonUrl](row, 1)
        } else {
          this[item.buttonUrl](row)
        }
      },

      // 按钮权限
      selectRoleMenuButtonList() {
        selectRoleMenuButtonList({
          roleIdStrList: this.$store.getters['acl/roleList'],
          menuId: this.$route.meta.id,
        })
          .then((res) => {
            if (res.data) {
              this.searchButton = res.data.filter(function (item) {
                return item.buttonType == 1
              })
              this.listButton = res.data.filter(function (item) {
                return item.buttonType == 2
              })
              if (this.listButton.length > 0) {
                this.operationShow = true
              } else {
                this.operationShow = false
              }
            }
          })
          .catch(() => {})
      },
      // 图片预览
      handleImgClick(url) {
        this.imagesUrl = url
        this.imgDialog = true
      },
      // 导出
      handelExport() {},
      // 添加主题活动
      handelAdd() {
        this.$router.push({
          path: '/theme-add',
          query: {
            // publishUserId: row.publishUserId,
          },
        })
      },
      // 关闭
      handleDelete(row) {
        this.$confirm('是否要关闭?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deletes({ id: row.id })
              .then(() => {
                this.MS('删除成功')
                this.fetchData()
              })
              .catch(() => {})
          })
          .catch(() => {})
      },
      // 复制
      handleCopy(row, tip) {
        console.log(tip)
        this.$router.push({
          path: '/theme-add',
          query: {
            id: row.id,
            publishUserId: row.publishUserId,
            tip: tip,
          },
        })
      },
      // 编辑
      handleEdit(row) {
        this.$router.push({
          path: '/theme-add',
          query: {
            id: row.id,
            publishUserId: row.publishUserId,
          },
        })
      },
      // 主题时间
      dateChange(date) {
        if (date && date.length) {
          this.queryForm.startActTime = date[0]
          this.queryForm.endActTime = date[1]
        } else {
          this.queryForm.startActTime = ''
          this.queryForm.endActTime = ''
        }
      },
      // 查看
      handleLook(row) {
        this.$router.push({
          path: '/theme-detail',
          query: {
            id: row.id,
          },
        })
      },

      // 主题列表
      async fetchData(options) {
        this.listLoading = true
        await queryPage({
          ...options,
          ...this.forms,
        })
          .then((res) => {
            if (res.data) {
              this.tableData = res.data.records
              this.total = res.data.total
            }
          })
          .catch(() => {})
        this.listLoading = false
      },

      // 字典
      selectSysDict() {
        selectSysDict({
          dictTypeList: ['themeState', 'actPlatform'],
        })
          .then((res) => {
            if (res.data) {
              this.themeStateList = res.data[0].dictInfoVoList
              this.actPlatformLsit = res.data[1].dictInfoVoList
            }
          })
          .catch(() => {})
      },
      //参与平台
      formatterPlatform(row) {
        // const rowA = row.actPlatform.split(',')
        // console.log(rowA)
        for (let i = 0; i < this.actPlatformLsit.length; i++) {
          if (this.actPlatformLsit[i].dictKey == row) {
            return this.actPlatformLsit[i].dictValue
          }
        }
      },
      //主题状态
      formatterThemeState(row) {
        for (let i = 0; i < this.themeStateList.length; i++) {
          if (this.themeStateList[i].dictKey == row.actStatus) {
            return this.themeStateList[i].dictValue
          }
        }
      },
      // 重置
      handleReset() {
        this.datePicker = ''
        this.forms.currentPage = 1
        this.forms.pageSize = 10
        this.queryForm = {}
        this.handleQuery()
      },
      // 搜索
      async handleQuery() {
        this.forms.currentPage = 1
        await this.fetchData({ ...this.queryForm }, false)
        let temp = false
        Object.keys(this.queryForm).forEach((key) => {
          this.queryForm[key] && (temp = true)
        })
        this.isShow = temp
      },
      // 分页
      handleSizeChange(val) {
        this.forms.pageSize = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
      // 分页
      handleCurrentChange(val) {
        this.forms.currentPage = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .filterItems {
    width: 150px;
  }
</style>
