<template>
  <div class="container">
    <el-radio-group v-model="radio4" size="mini" style="margin-bottom: 10px" @change="checktags">
      <el-radio-button label="1">电话1</el-radio-button>
      <el-radio-button label="2">电话2</el-radio-button>
    </el-radio-group>
    <el-table border :data="tablist" max-height="300">
      <!-- <el-table-column align="center" type="selection" width="55" /> -->
      <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="50" />
      <el-table-column align="center" label="开始时间" prop="startTime" width="170px" />
      <el-table-column align="center" label="结束时间" prop="endTime" width="170px" />
      <el-table-column align="center" label="坐席工号" prop="agent" show-overflow-tooltip />
      <el-table-column align="center" label="主叫号码" prop="callingPhone" />
      <el-table-column align="center" label="被叫号码" prop="calledPhone" />
      <!-- <el-table-column align="center" label="呼叫时长(秒)" prop="callDuration" /> -->
      <el-table-column align="center" label="振铃时长(秒)" prop="ringingDuration" />
      <el-table-column align="center" label="应答时长(秒)" prop="answerDuration" />
      <el-table-column align="center" label="呼叫状态" prop="callState">
        <template #default="scope">
          <div v-if="scope.row.callState == 0">未接</div>
          <div v-if="scope.row.callState == 1">未转接</div>
          <div v-if="scope.row.callState == 2">呼损</div>
          <div v-if="scope.row.callState == 3">接通后挂断</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="1" layout="total, prev, pager, next, jumper" :total="total"
      @current-change="handleCurrentChange" @size-change="handleSizeChange" />
  </div>
</template>

<script>
import { agentrecordList } from '@/api/mall/seas/index'
export default {
  props: {
    sound: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      total: 0,
      radio4: '1',
      tablist: [],
      showlist: false,
      showtblist: null,
      form: {
        size: 10,
        current: 1,
        calledPhone: this.userinfo,
      },
    }
  },
  watch: {
    sound(newValue, oldValue) {
      if (newValue != oldValue) {
        this.form.calledPhone = newValue.phone
        console.log(newValue)
      }
    },
  },
  created() {
    this.form.calledPhone = this.sound.phone
    this.uuUserSugarRecordLog()
  },
  methods: {
    checktags(e) {
      if (e == 1) {
        this.form.calledPhone = this.sound.phone
        this.uuUserSugarRecordLog()
      } else {
        this.form.calledPhone = this.sound.phonev
        this.uuUserSugarRecordLog()
      }
    },
    closeshow() {
      this.showlist = false
    },
    showhide(row) {
      console.log('12')
      this.showtblist = row
      this.showlist = true
      console.log(this.showlist)
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.uuUserSugarRecordLog()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.uuUserSugarRecordLog()
    },
    uuUserSugarRecordLog() {
      agentrecordList({ ...this.form }).then((res) => {
        console.log(res.data)
        this.total = res.data.total
        this.form.size = res.data.size
        this.form.current = res.data.current
        this.tablist = res.data.records
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
