<template>
  <div class="comprehensive-table-container">
    <div class="swtitch">
      <div class="user_info" style="flex-wrap: wrap; border: none">
        <div class="user_info" :style="{ borderColor: selectauthor == 1 ? '#1786ff' : '#e5e5e5' }" @click="selectuser(authority, 1)">
          <div class="user_picurl">
            <el-image :src="authority.headimgUrl" style="width: 40px; height: 40px; border-radius: 100%" />
          </div>
          <div class="user_name" style="margin: 2px 10px">官方:{{ authority.nickName }}</div>
          <!-- <div class="user_name" style="margin: 2px 10px">编号:{{ authority.userCode }}</div>
          <div class="userphone" style="margin: 2px 10px; white-space: nowrap">手机号:{{ authority.phone }}</div> -->
        </div>
        <div v-if="showswitch" class="user_info" :style="{ borderColor: selectauthor == 2 ? '#1786ff' : '#e5e5e5' }" @click="selectuser(checkuser, 2)">
          <div class="user_picurl">
            <el-image :src="checkuser.headimgUrl" style="width: 40px; height: 40px; border-radius: 100%" />
          </div>
          <div class="user_name" style="margin: 2px 10px">姓名:{{ checkuser.nickName }}</div>
          <div class="user_name" style="margin: 2px 10px">编号:{{ checkuser.userCode }}</div>
          <div class="userphone" style="margin: 2px 10px; white-space: nowrap">手机号:{{ checkuser.phone }}</div>
        </div>
        <div v-if="showswitch == false" class="user_info" :style="{ borderColor: selectauthor == 2 ? '#1786ff' : '#e5e5e5' }">
          <div class="user_picurl">
            <el-image :src="require('@/assets/img/Style.png')" style="width: 40px; height: 40px; border-radius: 100%" />
          </div>
          <div class="user_name" style="margin: 2px 10px">此用户未注册,如需代客下单请先前往代客注册！</div>
        </div>
      </div>
      <!-- <div v-if="showswitch == false">
        <el-button type="primary" @click="checkswitch">请前往代客注册</el-button>
      </div> -->
    </div>
    <div class="content_container">
      <div class="container">
        <div class="shopall">
          <div class="cont_title">全部商品</div>
          <div class="cont_al">
            <div class="shop_content">
              <div class="search">
                <el-form :model="form">
                  <el-form-item label="">
                    <el-input v-model="form.name" placeholder="请输入商品名称" suffix-icon="el-icon-search" @change="searchsubmit" />
                  </el-form-item>
                </el-form>
              </div>
              <el-table :data="tabData" height="500px">
                <el-table-column label="" prop="demo" width="60">
                  <template #default="scope">
                    <el-image :src="scope.row.picUrls[0]" style="width: 50px; height: 50px" />
                  </template>
                </el-table-column>
                <el-table-column label="" prop="">
                  <template #default="scope">
                    <div class="shop_title">
                      <el-tooltip class="item" :content="scope.row.name" effect="dark" placement="top-start">
                        <div class="shop_title">{{ scope.row.name }}</div>
                      </el-tooltip>
                    </div>
                    <div class="shop_info">
                      <div class="shop_much">销量：{{ scope.row.saleNum }}</div>
                      <!-- <div class="shop_stock">
                        库存：
                        <span>{{ scope.row.stock }}</span>
                      </div> -->
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="" prop="" width="70">
                  <template #default="scope">
                    <el-button type="primary" @click="seltion(scope.row.id)">选择</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="sholt">
                <el-pagination background :current-page="1" layout=" prev, pager, next" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
              </div>
            </div>
          </div>
        </div>
        <div class="shopall">
          <div class="cont_title">已选择</div>
          <div class="cont_al">
            <confirmsku :checkus="checksku" :listinfo="userdate" :userinfo="selectuserinfo" />
          </div>
        </div>
      </div>
    </div>
    <selectsku :showtab="boelan" :spuid="spu" @selsku="selku" />
  </div>
</template>

<script>
import { getPage } from '@/api/mall/goodspu/goodspu'
import { getUserInfo, getOfficialAccount } from '@/api/mall/users/userLevel'
import selectsku from './components/selectsku.vue'
import confirmsku from '../confirm-order/index.vue'
export default {
  components: {
    selectsku,
    confirmsku,
  },
  props: {
    uslideo: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      form: {
        size: 10,
        current: 1,
        name: null,
        verifyStatus: 1, //审核状态（0审核中 1审核通过 2审核不通过）
        shelf: '1', //是否上架（0否 1是）
      },
      total: 0,
      searchData: {},
      tabData: [],
      checkout: [],
      menubar: false,
      checkuser: {},
      checksku: null,
      spu: null,
      boelan: false,
      userdate: this.uslideo,
      showswitch: true,
      authority: null, //官方
      selectauthor: 2, //选择账户
      selectuserinfo: null, //默认选择官方
    }
  },
  watch: {
    uslideo(newValue, oldValue) {
      if (newValue != oldValue) {
        this.userdate = newValue
        this.seleuser(newValue)
      }
    },
  },
  created() {
    // this.seleuser(this.uslideo.userId)
    // const checkuser=this.$route.query.date
    // const userlist=this.$route.query.userlist
    // if(userlist){
    //  this.seleuser(userlist.userId)
    // }
    // if(checkuser){
    //   this.checkuser=JSON.parse(JSON.stringify(checkuser))
    // }
    this.getPage()
    // this.getOfficialAccount()
    this.getOfficialOrUser()
  },
  methods: {
    //获取官方 or 用户信息
    getOfficialOrUser() {
      //官方账户
      getOfficialAccount({}).then((res) => {
        console.log(res.data)
        this.authority = res.data
      }).finally(() => {
        this.seleuser(this.uslideo.userId)
      })
    },

    searchsubmit(e) {
      if (e == '') {
        this.form.name = null
        this.getPage()
      }
      this.getPage()
    },
    //切换选择下单用户
    selectuser(row, type) {
      this.selectuserinfo = row
      this.selectauthor = type
    },
    //官方账户
    // getOfficialAccount() {
    //   getOfficialAccount({}).then((res) => {
    //     console.log(res.data)
    //     this.authority = res.data

    //   })
    // },
    checkswitch() {
      this.$emit('switch', '11')
    },
    seleuser(e) {
      getUserInfo({ userCode: e }).then((res) => {
        if (res.data != null) {
          this.checkuser = res.data
          this.selectuserinfo = res.data
        } else {
          this.showswitch = false
          this.selectuserinfo = this.authority
        }
      })
    },
    getPage() {
      getPage({ ...this.form }).then((res) => {
        this.form.size = res.data.size
        this.form.current = res.data.current
        this.total = res.data.total
        this.tabData = res.data.records
      })
    },
    handleCurrentChange() { },
    handleSizeChange() { },
    checklink() {
      console.log(this.checkuser)
      // if (this.checkuser.nickName) {
      let date = {
        userinfo: this.checkuser,
        // checkout: this.checkout,
      }
      this.checksku = date
      //   // this.$router.push({ path: '/activity/confirm-order/confirm', query: { date } })
      // } else {
      //   this.$message.error('请选择代客信息')
      // }
    },
    sel() {
      this.menubar = true
    },
    trows(row) {
      this.menubar = false
      if (row) {
        this.checkuser = row
      }
    },
    deleteRow(index, rows) {
      rows.splice(index, 1)
    },
    seltion(e) {
      this.spu = e
      this.boelan = true
    },
    selku(row) {
      console.log(row, 'row');
      this.boelan = false
      if (row == null) {
        this.$message.error('请选择商品')
      } else {
        if (this.checkout.length < 1) {
          this.checkout.push(row)
          this.checksku = {
            checkout: this.checkout,
          }
        } else {
          let bolean = this.checkout.findIndex((item) => item.spuId === row.spuId && item.specsId === row.specsId)
          if (bolean > -1) {
            const matchedItem = this.checkout[bolean]
            this.$set(matchedItem, 'quantity', matchedItem.quantity + Number(row.quantity))
          } else {
            this.checkout.push(row)
            this.checksku = {
              checkout: this.checkout,
            }
          }
        }
      }

    },
  },
}
</script>

<style lang="scss" scoped>
.comprehensive-table-container {
  .content_container {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
  }
  .container {
    display: grid;
    grid-template-columns: 28% auto;
    min-width: 1480px;
    padding-bottom: 40px;
  }
  .shopall {
    max-height: 500px;
    padding: 10px;
    .cont_title {
      font-size: 12px;
      color: #fb6638;
    }
    .shop_title {
      max-width: 225px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .cont_al {
      display: flex;
      justify-content: center;
      width: calc(100% - 10px);
      height: calc(100% + 10px);
      padding: 15px 0;
      margin-top: 10px;
      border: 1px solid #e8e8e8;
      .shop_content {
        position: relative;
        width: calc(100% - 30px);
        .shop_info {
          display: flex;
          justify-content: space-between;
          .shop_stock {
            span {
              color: #ff6633;
            }
          }
        }
        .sholt {
          position: absolute;
          bottom: 0px;
        }
      }
    }
  }
}
::v-deep {
  .el-tooltip {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .has-gutter {
    display: none;
  }
}

.user_info {
  display: flex;
  align-items: center;
  width: calc(100% - 16px);
  padding: 5px 0;
  margin: 10px auto;
  margin-top: 10px;
  font-size: 12px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  .user_picurl {
    margin-left: 10px;
  }
}
.search {
  ::v-deep {
    .el-input__inner {
      border: 1px solid #e5e5e5;
    }
  }
}
</style>
