var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.checknum == "-1"
        ? _c(
            "div",
            [
              _c(_vm.componentMap.get(_vm.activeComponent), {
                tag: "component",
                attrs: { indexnumer: _vm.checknum },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.controls, function (ym, index) {
        return _c(
          "div",
          { key: index },
          [
            _vm.checknum == index
              ? _c(_vm.componentMap.get(ym.type), {
                  tag: "component",
                  attrs: { indexnumer: _vm.checknum },
                })
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }