var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.getTitle, visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上级分类：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "20px" } },
                    [
                      _c("el-alert", {
                        attrs: {
                          closable: false,
                          title: _vm.form.parentName,
                          type: "info",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分类名称：",
                    "label-width": _vm.formLabelWidth,
                    prop: "catName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入分类名称" },
                    model: {
                      value: _vm.form.catName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "catName", $$v)
                      },
                      expression: "form.catName",
                    },
                  }),
                ],
                1
              ),
              _vm.getTitle == "添加一级分类"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分类编码",
                        "label-width": _vm.formLabelWidth,
                        prop: "catCode",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入分类编码" },
                        model: {
                          value: _vm.form.catCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "catCode", $$v)
                          },
                          expression: "form.catCode",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分类Logo图片：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.uploadParams.uploadURL,
                        "file-list": _vm.uploadParams.fileList,
                        headers: { Authorization: _vm.uploadParams.token },
                        limit: 1,
                        "list-type": "picture-card",
                        "on-exceed": _vm.handleExceed,
                        "on-preview": _vm.handlePictureCardPreview,
                        "on-remove": _vm.handleRemove,
                        "on-success": _vm.handleUploadCatLogoSuccess,
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        "append-to-body": "",
                        visible: _vm.dialogVisible,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogVisible = $event
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.uploadParams.dialogImageUrl,
                          width: "100%",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分类排序：",
                    "label-width": _vm.formLabelWidth,
                    prop: "sortNumber",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入分类排序" },
                    model: {
                      value: _vm.form.sortNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sortNumber", $$v)
                      },
                      expression: "form.sortNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否启用：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-switch", {
                    attrs: { "active-color": "#13ce66" },
                    model: {
                      value: _vm.form.isShow,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isShow", $$v)
                      },
                      expression: "form.isShow",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否支持客情：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-switch", {
                    attrs: { "active-color": "#13ce66" },
                    model: {
                      value: _vm.form.isAfterSales,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isAfterSales", $$v)
                      },
                      expression: "form.isAfterSales",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分类备注：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入分类备注", type: "textarea" },
                    model: {
                      value: _vm.form.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }