var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        title: "查看用药记录",
        visible: _vm.dialogVisible,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                "label-width": "100px",
                model: _vm.ruleForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "div",
                { staticClass: "demo-ruleForms" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开始时间", prop: "phyTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          align: "center",
                          disabled: "",
                          placeholder: "选择开始时间",
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.ruleForm.phyTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "phyTime", $$v)
                          },
                          expression: "ruleForm.phyTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "结束时间", prop: "leaveTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          align: "center",
                          disabled: "",
                          placeholder: "选择结束时间",
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.ruleForm.leaveTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "leaveTime", $$v)
                          },
                          expression: "ruleForm.leaveTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "针对情况", prop: "precept" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "", placeholder: "请输入 针对情况" },
                    model: {
                      value: _vm.ruleForm.precept,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "precept", $$v)
                      },
                      expression: "ruleForm.precept",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { border: "", data: _vm.ruleForm.fitness },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", label: "药品名称", prop: "name" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "药瓶规格",
                      prop: "title",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "每天次数",
                      prop: "freNum",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "每天用量",
                      prop: "fitDosage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }