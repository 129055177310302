var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "录音信息",
            visible: _vm.dialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { size: "mini" },
                  on: { change: _vm.checktags },
                  model: {
                    value: _vm.radio4,
                    callback: function ($$v) {
                      _vm.radio4 = $$v
                    },
                    expression: "radio4",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("电话1"),
                  ]),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("电话2"),
                  ]),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  attrs: { border: "", data: _vm.tablist, "max-height": "300" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "序号",
                      "show-overflow-tooltip": "",
                      type: "index",
                      width: "50",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "开始时间",
                      prop: "startTime",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "结束时间",
                      prop: "endTime",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "坐席工号",
                      prop: "agent",
                      "show-overflow-tooltip": "",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "坐席姓名",
                      prop: "agentName",
                      "show-overflow-tooltip": "",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "主叫号码",
                      prop: "callingPhone",
                      "show-overflow-tooltip": "",
                      width: "160",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "被叫号码",
                      prop: "calledPhone",
                      "show-overflow-tooltip": "",
                      width: "160",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "应答时长(秒)",
                      prop: "answerDuration",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-dropdown",
                              { attrs: { trigger: "click" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "el-dropdown-link",
                                    staticStyle: { color: "#1780ff" },
                                  },
                                  [
                                    _vm._v(" 操作 "),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right",
                                    }),
                                  ]
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c("el-dropdown-item", [
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.audiourl(
                                                scope.row.playUrl,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("试听")]
                                      ),
                                    ]),
                                    _c("el-dropdown-item", [
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.audiourl(
                                                scope.row.playUrl,
                                                2
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("下载")]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": 1,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }