<template>
  <el-dialog :before-close="handleClose" title="新增体检记录" :visible.sync="dialogVisible" width="50%">
    <span>
      <el-form ref="ruleForm" class="demo-ruleForm" label-width="100px" :model="ruleForm" :rules="rules">
        <el-form-item label="体检编号" prop="fitNum">
          <el-input v-model="ruleForm.fitNum" placeholder="请输入 体检编号" />
        </el-form-item>
        <el-form-item label="体检类型" prop="fitType">
          <el-select v-model="ruleForm.fitType" placeholder="请选择 体检类型">
            <el-option v-for="(ym, index) in labType" :key="index" :label="ym.labelName" :value="ym.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="体检机构" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入 体检机构" />
        </el-form-item>
        <el-form-item label="体检日期" prop="phyTime">
          <el-date-picker v-model="ruleForm.phyTime" align="center" placeholder="选择日期时间" type="date" value-format="yyyy-MM-dd" />
        </el-form-item>
        <el-form-item label="体检报告单" prop="fitUrl">
          <Upload v-if="uphide" :picurl="ruleForm.fitUrl" @backkuck="check" />
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Upload from '../../upload-pic/index.vue'
import { userFitnessInfoadd } from '@/api/mall/users/usermore'
export default {
  components: {
    Upload,
  },
  props: {
    show: {
      type: Boolean,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ruleForm: {
        fitUrl: [],
      },
      rules: {
        fitNum: [{ required: true, message: '请输入 体检编号', trigger: 'blur' }],
        phyTime: [{ type: 'string', required: true, message: '请选择 体检日期', trigger: 'change' }],
        fitType: [{ required: true, message: '请选择 体检类型', trigger: 'change' }],
        name: [{ required: true, message: '请输入 体检机构', trigger: 'blur' }],
      },
      dialogVisible: false,
      uphide: false,
      labType: [
        {
          id: 1,
          labelName: '入职体检',
        },
        {
          id: 2,
          labelName: '自行体检',
        },
      ],
    }
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue != oldValue) {
        this.dialogVisible = newValue
        this.uphide = newValue
        this.$forceUpdate()
      }
    },
  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          userFitnessInfoadd({ userId: this.value.userId, fitStatus: 2, ...this.ruleForm }).then((res) => {
            if (res.status == 200) {
              this.ruleForm = {
                fitUrl: [],
              }
              this.$emit('addshows', false)
              this.ruleForm = {};
            }
          })
        } else {
          return false
        }
      })
    },
    handleClose() {
      this.$refs.ruleForm.resetFields()
      this.$emit('addshows', false)
      this.ruleForm = {};
     
    },
    check(e) {
      console.log(e,'eeeeeeeee');
      if (e.url) {
        this.ruleForm.fitUrl.forEach((item, index) => {
          if (item == e.url) {
            this.ruleForm.fitUrl.splice(index, 1)
          }
        })
      } else {
        this.ruleForm.fitUrl.push(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
  .el-select,
  .el-date-editor,
  .input__inner {
    width: 100%;
  }
}
</style>