<template>
  <div
    class="container"
    :style="{
      padding: `0 ${ controls[index].margin.both}px`,
      marginTop:`${ controls[index].margin.top}px`,
      marginBottom:`${ controls[index].margin.bottom}px`,
    }"
  >
    <div
      class="ym_swiper"
      :style="{
        display:controls[index].list.length>3?'flex':''
      }"
    >
      <div v-for="(ym,ind) in controls[index].list" :key="ind" :class="cur==ind?'active item':'item'">
        <div 
          class="span"
          :style="{
            color:ind==0?`${controls[index].headStyle.titleColor}`:'#131313'
          }"
        >{{ym.title}}</div>
        <div 
          class="span_txt"
          :style="{
            background: ind==0?`${controls[index].headStyle.selectBackground}`:''
          }"
        >{{ym.desc}}</div>
      </div>
    </div>
    <div 
      class="card_multion"
      :style="
        flexTYpe( controls[index].template)[0]
      "
    >
      <div
        v-for="(yms,nus) in 6" 
        :key="nus" 
        :class="flexTYpe(controls[index].template)[2]?'card-item cia':'card-item'"
        :style="
        comStyle(
          controls[index].ornament.type,
          controls[index].ornament.color,
          controls[index].elementBgColor,
          controls[index].topElementAroundRadius,
          controls[index].bottomElementAroundRadius
        )
        "
      >
        <div class="cia" :class="flexTYpe(controls[index].template)[3]?'bigpic':''" :style="flexTYpe( controls[index].template)[1]">
          <div
            class="lef_picUrl"
          >
            <img
              alt="" 
              src="https://uniapp.v5.niuteam.cn/public/static/img/default_img/square.png"  
              :style="{
                borderRadius:`${controls[index].imgAroundRadius}px`,
              }"
            />
          </div>
          <div class="ref_listgroup">
            <div class="thead" :style="{fontWeight:controls[index].goodsNameStyle.fontWeight?'bold':''}">商品名称</div>
            <div class="footer">
              <div class="left">
                <div 
                  class="much"
                  :style="{
                    color:`${controls[index].priceStyle.mainColor}`
                  }"
                >¥13.00</div>
                <s 
                  class="Original-price"
                  :style="{
                    color:controls[index].priceStyle.lineColor
                  }"
                >¥25.00</s>
                <div 
                  class="Original-price"
                  :style="{
                    color:`${controls[index].saleStyle.color}`
                  }"
                >已售出10,0件</div>
              </div>
              <div class="rig">
                <div
                  class="ym_btn" :style="{
                    color:controls[index].btnStyle.textColor==''?'#ffffff':controls[index].btnStyle.textColor,
                    borderRadius:`${controls[index].btnStyle.aroundRadius}px`,
                    background:`${controls[index].btnStyle.bgColor}`,
                    fontWeight:controls[index].btnStyle.fontWeight?'bold':'normal'
                  }"
                >{{controls[index].btnStyle.text}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
export default {
  props: {
    index: {
      type: Number,
      default: () => {},
    },
  },
  data(){
    return{
      cur:0
    }
  },
  computed: {
    ...mapState({
      controls: state => state.checkvalue,
    }),
    comStyle(){
      return (item,color,bgColor,toparound,bomaround) => {
        if (item === 'default') {
          return `
            background: ${bgColor};
            border-top-left-radius: ${toparound}px;
            border-top-right-radius: ${toparound}px;
            border-bottom-left-radius: ${bomaround}px;
            border-bottom-right-radius: ${bomaround}px;
          `
        } 
        else if(item==='shadow'){
          return `
            box-shadow: ${color} 0px 0px 5px;
            background: ${bgColor};
            border-top-left-radius: ${toparound}px;
            border-top-right-radius: ${toparound}px;
            border-bottom-left-radius: ${bomaround}px;
            border-bottom-right-radius: ${bomaround}px;
          `
        }
        else {
          return `
            border: 1px solid ${color};
            background: ${bgColor};
            border-top-left-radius: ${toparound}px;
            border-top-right-radius: ${toparound}px;
            border-bottom-left-radius: ${bomaround}px;
            border-bottom-right-radius: ${bomaround}px;
          `
        }
      }
    },
    flexTYpe(){
      return (item) => {
        if (item === 'row1-of1') {
          const styinfo=[
            ``,
            `
            width: 100%;
            min-height: 146px;
            align-items: center;
            display: grid;
            grid-template-columns: 150px auto;
            `,
            false
          ]
          return styinfo
        } 
        else if(item==='row1-of2'){
          const styinfo=[
            ` display: grid;
              grid-template-columns: repeat(2,1fr);
              gap: 10px;`,
            `
              display: grid;
              gap: 0px;
              padding-bottom: 28px;
            `,
            true
          ]
          return styinfo
        }
        else if(item==='row1-of3'){
          const styinfo=[
            ` display: grid;
              grid-template-columns: repeat(3,1fr);
              gap:0px;

            `,
            `
              display: grid;
              gap: 0px;
              padding-bottom: 28px;
            `,
            true
          ]
          return styinfo
        }
        else if(item==='row1-of4'){
          const styinfo=[
            ` display: flex;
              width: 700px;
              gap: 0px;
              height: 221px;
              overflow: hidden;
            `,
            `
              display: grid;
              gap: 0px;
              padding-bottom: 28px;
            `,
            true
          ]
          return styinfo
        }
        else {
          const styinfo=[
            ` 
            `,
            `
              display: grid;
              gap: 0px;
              padding-bottom: 28px;
            `,
            true,
            true
          ]
          return styinfo
        }
      }
    },
    elempageBg(item){
      return item
    },
    showbtn(){
      return (item) => {
        if (item == 'row1-of1' || item =='row1-of5') {
          return true
        } else{
          return false
        }
      }
    }
  },
  watch:{
    index(newValue,oldValue){
      if(newValue!=oldValue){
        this.terminal=newValue
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  .ym_swiper{
    display: flex;
    height: 59px;
    overflow: hidden;
    .item{
      flex-grow: 1;
      width: 80px;
      min-width: 80px;
      height: 80px;
      min-height: 80px;
      margin-right: 12px;
      text-align: center;
      .span{
        width: 80px;
        margin: auto;
        overflow: hidden;
        font-size: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .span_txt{
        width: 100%;
        padding: 2px 5px;
        margin: auto;
        overflow: hidden;
        font-size: 12px;
        line-height: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 20px;
      }
    }
    .active{
      font-weight: bold;
      .span_txt{
        color: white;
        background: #1780ff;
      }
    }
  }
 
  .card_multion{
    width: 100%;
    height: auto;
    .card-item{
      display: grid;
      align-items: center;
      width: 100%;
      min-height: 146px;
      margin-bottom: 10px;
      overflow: hidden;
      background: white;
      
      .lef_picUrl{
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(146px - 20px);
        overflow: hidden;
        img{
          max-width: calc(100% - 20px);
          max-height: 110px;
        }
      }
      .ref_listgroup{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        margin: auto;
        .thead{
          font-size: 14px;
        }
        .footer{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .much{
            font-size: 16px;
            font-weight: bold;
            color: #FF6A00;
          }
          .Original-price{
            font-size: 12px;
            color: #999ca7;
          }
          .rig{
            .ym_btn{
              display: flex;
              align-items: center;
              justify-content: center;
              width: 56px;
              height: 26px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .cia{
    gap: 0;
    width: 100%;
    .lef_picUrl{
      width: 100%;
      height: 110px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        max-height: none;
        margin-left: 0;
        overflow: hidden;
      }
    }
    .ref_listgroup{
      width: 100%;
      margin-top: -10px;
    }
    .bigpic{
      width: 100%;
      .ref_listgroup{
        width: calc(100% - 20px);
        margin-left: 15px;
      }
      .lef_picUrl{
        width: 100%;
        height: 160px;
        img{
          width: 100%;
          min-height: 160px;
          margin-left: 0;
        }
      }
      .rig{
        margin-right: 10px;
      }
    }
  }
  
}
</style>