const options = [
  { label: '店铺Logo', span: 24, prop: 'imgUrl', type: 'upload' },
  { label: '店铺名', span: 12, prop: 'name' },
  { label: '电话号码', span: 12, prop: 'phone' },
  { label: '地址', span: 12, prop: 'address' },
  {
    label: '是否启用',
    span: 12,
    prop: 'enable',
    type: 'radio',
    optionData: [
      { label: '关闭', value: '0' },
      { label: '开启', value: '1' },
    ],
  },
  { label: '详细介绍', span: 24, prop: 'detail' },
  { label: '微信特约商户号', span: 12, prop: 'wxMchId' },
  { label: '支付宝Key', span: 12, prop: 'aliAuthToken' },
]

const rules = {
  c: [{ required: true, trigger: 'blur', message: '请输入标题' }],
  b: [{ required: true, trigger: 'blur', message: '请输入作者' }],
}
const config = { labelWidth: '8em' }
export { options, rules, config }
