var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-radio-group",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { size: "mini" },
          on: { change: _vm.checktags },
          model: {
            value: _vm.radio4,
            callback: function ($$v) {
              _vm.radio4 = $$v
            },
            expression: "radio4",
          },
        },
        [
          _c("el-radio-button", { attrs: { label: "1" } }, [_vm._v("电话1")]),
          _c("el-radio-button", { attrs: { label: "2" } }, [_vm._v("电话2")]),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { border: "", data: _vm.tablist, "max-height": "300" } },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              "show-overflow-tooltip": "",
              type: "index",
              width: "50",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "开始时间",
              prop: "startTime",
              width: "170px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "结束时间",
              prop: "endTime",
              width: "170px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "坐席工号",
              prop: "agent",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "主叫号码", prop: "callingPhone" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "被叫号码", prop: "calledPhone" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "振铃时长(秒)",
              prop: "ringingDuration",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "应答时长(秒)",
              prop: "answerDuration",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "呼叫状态", prop: "callState" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.callState == 0
                      ? _c("div", [_vm._v("未接")])
                      : _vm._e(),
                    scope.row.callState == 1
                      ? _c("div", [_vm._v("未转接")])
                      : _vm._e(),
                    scope.row.callState == 2
                      ? _c("div", [_vm._v("呼损")])
                      : _vm._e(),
                    scope.row.callState == 3
                      ? _c("div", [_vm._v("接通后挂断")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": 1,
          layout: "total, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }