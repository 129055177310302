import request from '@/utils/request'

export function getActivityList(data) {
  return request({
    url: `/mall/activity/acActivity/queryPage`,
    method: 'post',
    data,
  })
}

export function addActivity(data) {
  return request({
    url: `/mall/activity/acActivity/add`,
    method: 'post',
    data,
  })
}

export function deleteActivity(data) {
  return request({
    url: `/mall/activity/acActivity/delete`,
    method: 'post',
    data,
  })
}

export function closeActivity(data) {
  return request({
    url: `/mall/activity/acActivity/close`,
    method: 'post',
    data,
  })
}

export function editActivity(data) {
  return request({
    url: `/mall/activity/acActivity/edit`,
    method: 'post',
    data,
  })
}
