import request from '@/utils/request'

export function getPage(query) {
  return request({
    url: '/mall/userwithdrawrecord/page',
    method: 'get',
    params: query,
  })
}

export function addObj(obj) {
  return request({
    url: '/mall/userwithdrawrecord',
    method: 'post',
    data: obj,
  })
}

export function getObj(id) {
  return request({
    url: '/mall/userwithdrawrecord/' + id,
    method: 'get',
  })
}

export function delObj(id) {
  return request({
    url: '/mall/userwithdrawrecord/' + id,
    method: 'delete',
  })
}

export function putObj(obj) {
  return request({
    url: '/mall/userwithdrawrecord',
    method: 'put',
    data: obj,
  })
}

export function putStatus(obj) {
  return request({
    url: '/mall/userwithdrawrecord/status',
    method: 'put',
    data: obj,
  })
}

export function getWithdrawList(data) {
  return request({
    url: '/mall/userwithdrawrecord/queryPage',
    method: 'post',
    data,
  })
}

export function getWithdrawDetail(data) {
  return request({
    url: '/mall/userwithdrawrecord/getWithdrawStatistical',
    method: 'post',
    data,
  })
}

export function updateRefuse(data) {
  return request({
    url: '/mall/userwithdrawrecord/updatePass',
    method: 'post',
    data,
  })
}

export function updatePass(data) {
  return request({
    url: '/mall/userwithdrawrecord/updatePass',
    method: 'post',
    data,
  })
}

export function doPayment(data) {
  return request({
    url: '/mall/userwithdrawrecord/doPayment',
    method: 'post',
    data,
  })
}
