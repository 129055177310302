<template>
  <div
    class="siper"
    :style="{
      background: controls[terminal].pageBgColor,
    }"
  >
  <div>
  </div>
    <div
      class="banner"
      :style="{
        margin: `0 ${controls[terminal].margin.both}px`,
        paddingTop: `${controls[terminal].margin.top}px`,
        paddingBottom: `${controls[terminal].margin.bottom}px`,
        background: controls[terminal].componentBgColor,
      }"
    >
      <el-carousel
        height="132px"
        :style="{
          borderRadius: `${controls[terminal].topAroundRadius}px ${controls[terminal].topAroundRadius}px ${controls[terminal].bottomAroundRadius}px ${controls[terminal].bottomAroundRadius}px`,
        }"
      >
        <el-carousel-item v-for="(ym, ind) in controls[terminal].list" :key="ind">
          <el-image 
            :fit="cover(ym.imageMode)" 
            :src="ym.imageUrl"
            style="width:100%;height: 180px;"
          />
        </el-carousel-item>
      </el-carousel>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    props: {
      checkrow: {
        type: Object,
        default: () => {},
      },
      index: {
        type: Number,
        default: () => {},
      },
    },
    data() {
      return {
        activeComponent: '',
        terminal: this.index,
      }
    },
    computed: {
      ...mapState({
        controls: state => state.checkvalue,
      }),
      cover(){
        return (item) => {
        if (item === 'scaleToFill') {
          return `cover`
        } 
        else if(item==='aspectFit'){
          return `scale-down`
        }
        else {
          return `fill`
        }
      }
      }
    },
    watch:{
      index(newValue,oldValue){
        if(newValue!=oldValue){
          this.terminal=newValue
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
  .banner {
    .demonstration {
      color: var(--el-text-color-secondary);
    }

    .el-carousel__item h3 {
      margin: 0;
      line-height: 150px;
      color: #475669;
      text-align: center;
      opacity: 0.75;
    }
    .banner-item {
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &.terminal4 {
      :deep(.el-carousel) {
        height: 100%;
        .el-carousel__container {
          height: 100%;
        }
      }
      .banner-item {
        img {
          display: none;
        }
      }
    }
    .swiper-pagination {
      bottom: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
      :deep(.swiper-pagination-bullet) {
        width: 30px;
        height: 4px;
        margin: 0 7.5px;
        background: #fff;
        border-radius: 2px;
        opacity: 0.5;
      }
      :deep(.swiper-pagination-bullet-active) {
        width: 58px;
        opacity: 1;
      }
    }
  }
</style>
