<template>
  <div class="comprehensive-table-container">
    <el-button
      class="addBtn"
      icon="el-icon-plus"
      type="primary"
      @click="handleAdd"
    >
      新增
    </el-button>
    <el-table border :data="data">
      <el-table-column
        align="center"
        label="店铺名"
        prop="name"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="电话号码"
        prop="phone"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="详细地址"
        prop="address"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="店长昵称"
        prop="userNickname"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="店长手机号"
        prop="userPhone"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="更新时间"
        prop="updateTime"
        show-overflow-tooltip
        sortable
      />
      <el-table-column align="center" label="审核状态" prop="status" sortable>
        <template #default="{ row }">
          <el-tag
            :type="
              row.status == 0
                ? 'warning'
                : row.status == 2
                ? 'danger'
                : 'success'
            "
          >
            {{
              row.status == 0
                ? '审核中'
                : row.status == 2
                ? '审核不通过'
                : '通过'
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="showTabs(scope.row)">查看</el-link>
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="edittab(scope.row)">编辑</el-link>
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="delobj(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <applydialog :applerow="applist" :dbshow="showbloa" @resolve="rejoct"/>
    <applyeditdialog :editrow="editabs" :edshow="edihide" @closeedit="closedit"/>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"/>
  </div>
</template>

<script>
  import { getPage, addObj, putObj, delObj } from '@/api/mall/shop/apply'
  import applydialog from './components/applydialog.vue'
  import applyeditdialog from './components/applyeditdialog.vue'
  export default {
  components: { applydialog,applyeditdialog },
    data() {
      return {
        data: [],
        searchForm: {
          current: 1,
          size: 100,
        },
        showbloa:false,
        applist:{},
        edihide:false,
        editabs:{},
        total:1,
      }
    },
    created() {
      this.list()
    },
    methods: {
      handleAdd() {
        this.$router.replace('ShopapplyAdd')
      },
      list() {
        getPage(this.searchForm).then((res) => {
          const {
            data: { total,records,size,current},
          } = res
          this.data = records
          this.total=total
          this.searchForm.size=size
          this.searchForm.current=current
        })
      },
      /**
       * @title 数据删除
       * @param row 为当前的数据
       * @param index 为当前删除数据的行数
       *
       **/
      handleDel(row) {
        let _this = this
        this.$confirm('是否确认删除此数据', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(function () {
            return delObj(row.id)
          })
          .then(() => {
            _this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success',
            })
            this.list()
          })
      },
      /**
       * @title 数据更新
       * @param row 为当前的数据
       * @param index 为当前更新数据的行数
       * @param done 为表单关闭函数
       *
       **/
      handleUpdate(row) {
        row.imgUrl = row.imgUrl.length > 0 ? row.imgUrl[0] : ''
        row.userAvatar = row.userAvatar.length > 0 ? row.userAvatar[0] : ''
        let addressInfo = this.addressInfo
        row.longitude = addressInfo[0]
        row.latitude = addressInfo[1]
        row.address = addressInfo[2]
        putObj(row).then(() => {
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success',
          })
          this.$refs['edit'] = false
          this.list()
        })
      },
      /**
       * @title 数据添加
       * @param row 为当前的数据
       * @param done 为表单关闭函数
       *
       **/
      handleSave(row) {
        row.imgUrl = row.imgUrl.length > 0 ? row.imgUrl[0] : ''
        row.userAvatar = row.userAvatar.length > 0 ? row.userAvatar[0] : ''
        let addressInfo = this.addressInfo
        row.longitude = addressInfo[0]
        row.latitude = addressInfo[1]
        row.address = addressInfo[2]
        addObj(row).then(() => {
          this.$message({
            showClose: true,
            message: '添加成功',
            type: 'success',
          })
          this.$refs['edit'] = false
          this.list()
        })
      },
      showTabs(row){
        this.showbloa=true
        console.log(row)
        this.applist=row
      },
      edittab(row){
        console.log(row)
        this.edihide=true
        this.editabs=row
      },
      rejoct(e){
        this.showbloa=e
      },
      closedit(e){
        this.edihide=e
      },
      delobj(id){
        delObj(id).then(()=>{
          this.$message.success("删除成功")
        })
      },
      handleCurrentChange(e){
        this.searchForm.current=e
        this.list()
      },
      handleSizeChange(e){
        this.searchForm.size=e
        this.list()
      }
    },
  }
</script>

<style></style>
