<template>
  <div class="comprehensive-table-container">
    <el-tabs v-model="act" type="card">
      <el-tab-pane label="客户详细" name="1"/>
      <el-tab-pane label="日志记录" name="2"/>
    </el-tabs>
    <div v-if="act==1" class="content">
      <el-form :model="dormdate">
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark">体检时间</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              <el-date-picker v-model="dormdate.userYear" placeholder=" 年 月 日" type="date" />
            </div>
          </el-col>
          <el-col :span="6"><div class="grid-content bg-purple-dark">手机号</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.phone" placeholder="手机号" /></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3"><div class="grid-content bg-purple-dark">姓名</div></el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userName" placeholder="姓名" /></div>
          </el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">性别</div></el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userSex" placeholder="性别" /></div>
          </el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">年龄</div></el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userAge" placeholder="年龄" /></div>
          </el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">患病年限</div></el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.fattyIlness" placeholder="患病年限" /></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3"><div class="grid-content bg-purple-dark">身高 cm</div></el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">体重 kg</div></el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">BMI</div></el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">腰围</div></el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">血压</div></el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">心率</div></el-col>
          <el-col :span="6"><div class="grid-content bg-purple-dark">是否规律用药</div></el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userHeight" placeholder="身高" /></div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userWeight" placeholder="体重" /></div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userMbi" placeholder="BMI" /></div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userWaist" placeholder="腰围" /></div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userPressure" placeholder="血压" /></div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userHeart" placeholder="心率" /></div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              <el-radio-group v-model="dormdate.medRegularly">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark" style="height: 72px; line-height: 72px">是否打针</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              胰岛素
              <el-checkbox label="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              药名：
              <el-input v-model="dormdate.casePos1[0].insulinTitle" placeholder="药名" />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              剂量：
              <el-input v-model="dormdate.casePos1[0].caseDose" placeholder="剂量" />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              GLP-1 类
              <el-checkbox label="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              药名：
              <el-input v-model="dormdate.casePos1[1].insulinTitle" placeholder="药名" />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              剂量：
              <el-input v-model="dormdate.casePos1[1].caseDose" placeholder="剂量" />
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark" style="height: 146px; line-height: 146px">是否口服药</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              药名：
              <el-input v-model="dormdate.casePos2[0].insulinTitle" placeholder="药名" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-dark">
              一天
              <div class="textundeline"><el-input v-model="dormdate.casePos2[0].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次,一次
              <div class="textundeline"><el-input v-model="dormdate.casePos2[0].casePian" placeholder="" style="max-width: 55px" /></div>
              片，一片
              <div class="textundeline"><el-input v-model="dormdate.casePos2[0].caseMg" placeholder="" style="max-width: 55px" /></div>
              mg
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              药名：
              <el-input v-model="dormdate.casePos2[1].insulinTitle" placeholder="药名" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-dark">
              一天
              <div class="textundeline"><el-input v-model="dormdate.casePos2[1].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次,一次
              <div class="textundeline"><el-input v-model="dormdate.casePos2[1].casePian" placeholder="" style="max-width: 55px" /></div>
              片，一片
              <div class="textundeline"><el-input v-model="dormdate.casePos2[1].caseMg" placeholder="" style="max-width: 55px" /></div>
              mg
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              药名：
              <el-input v-model="dormdate.casePos2[2].insulinTitle" placeholder="药名" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-dark">
              一天
              <div class="textundeline"><el-input v-model="dormdate.casePos2[2].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次,一次
              <div class="textundeline"><el-input v-model="dormdate.casePos2[2].casePian" placeholder="" style="max-width: 55px" /></div>
              片，一片
              <div class="textundeline"><el-input v-model="dormdate.casePos2[2].caseMg" placeholder="" style="max-width: 55px" /></div>
              mg
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              药名：
              <el-input v-model="dormdate.casePos2[3].insulinTitle" placeholder="药名" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-dark">
              一天
              <div class="textundeline"><el-input v-model="dormdate.casePos2[3].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次,一次
              <div class="textundeline"><el-input v-model="dormdate.casePos2[3].casePian" placeholder="" style="max-width: 55px" /></div>
              片，一片
              <div class="textundeline"><el-input v-model="dormdate.casePos2[3].caseMg" placeholder="" style="max-width: 55px" /></div>
              mg
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark">是否会监测血糖</div></el-col>
          <el-col :span="18">
            <div class="grid-content bg-purple-dark">
              <el-radio-group v-model="dormdate.sugarStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              <div v-if="dormdate.sugarStatus == 1">
                (空腹：
                <el-input v-model="dormdate.sugarStomach" placeholder="" style="max-width: 100px" />
                餐后2小时:
                <el-input v-model="dormdate.sugarMeal" placeholder="" style="max-width: 100px" />
                )
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark">是否出现低血糖</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              <el-radio-group v-model="dormdate.sugarLowStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="6">
            <div v-if="dormdate.sugarLowStatus == 1" class="grid-content bg-purple-dark">出现时间和频率</div>
            <div v-else class="grid-content bg-purple-dark">出现时间和频率</div>
          </el-col>
          <el-col :span="6">
            <div v-if="dormdate.sugarLowStatus == 1" class="grid-content bg-purple-dark">
              <el-input v-model="dormdate.sugarLowMeal" placeholder="出现时间和频率" />
            </div>
            <div v-else class="grid-content bg-purple-dark"></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark" style="height: 100px">既往病史</div></el-col>
          <el-col :span="18">
            <div class="grid-content bg-purple-dark" style="height: 100px;flex-wrap: wrap">
              <el-checkbox-group v-model="dormdate.casePos3" style="white-space: wrap;">
                <el-checkbox v-for="(yn,index) in ca" :key="index" :label="yn.caseName"/>
              </el-checkbox-group>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark">既往史是否用药</div></el-col>
          <el-col :span="18">
            <div class="grid-content bg-purple-dark" style="white-space: nowrap">
              <el-radio-group v-model="dormdate.drugStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              <div v-if="dormdate.drugStatus == 1" style="margin-left: 20px">药物名称：</div>
              <el-input v-if="dormdate.drugStatus == 1" v-model="dormdate.drugName" placeholder="药物名称" />
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark">住院史</div></el-col>
          <el-col :span="18">
            <div class="grid-content bg-purple-dark" style="white-space: nowrap">
              <el-input v-model="dormdate.addressDetail" placeholder="住院史地址" />
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark">脂肪肝</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark" style="white-space: nowrap">
              <el-radio-group v-model="dormdate.fattyStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="6"><div class="grid-content bg-purple-dark">家族史</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              <el-input v-model="dormdate.fattyName" placeholder="" />
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark" style="height: 45px">最希望改善的地方</div></el-col>
          <el-col :span="18">
            <div class="grid-content bg-purple-dark" style="flex-wrap: wrap; height: 45px">
              <el-checkbox-group v-model="dormdate.casePos4" style="white-space: wrap;">
                <el-checkbox v-for="(yn,index) in cas" :key="index" :label="yn.caseName"/>
              </el-checkbox-group>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3"><div class="grid-content bg-purple-dark" style="height: 257px">身体症状</div></el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              1、有无手麻、脚麻或皮肤瘙痒等症状？
              <el-radio-group v-model="dormdate.casePos5[0].caseStatus">
                <el-radio  :label="1">是</el-radio>
                <el-radio  :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              2、有无失眠、多梦或便秘等症状？
              <el-radio-group v-model="dormdate.casePos5[1].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              3、是否有便秘、腹泻等症状？
              <el-radio-group v-model="dormdate.casePos5[2].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              4、有无心慌、胸闷、气短、头晕头痛等心脑血管症状？
              <el-radio-group v-model="dormdate.casePos5[3].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              5、有无视物模糊？
              <el-radio-group v-model="dormdate.casePos5[4].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              6、有无尿液泡沫增多？
              <el-radio-group v-model="dormdate.casePos5[5].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              7、有无糖尿病足部病变（如损伤后不容易愈合）？
              <el-radio-group v-model="dormdate.casePos5[6].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24"><div class="grid-content bg-purple-dark">饮食生活调查问卷</div></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">1、平时吃一顿饭用时</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              大概
              <div class="textundeline"><el-input v-model="dormdate.caseMg" placeholder="" style="max-width: 55px" /></div>
              分钟
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">2、晚上 8 点后会吃食物吗？</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              <el-radio-group v-model="dormdate.casePos6[1].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              一周
              <div class="textundeline"><el-input v-model="dormdate.casePos6[1].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">3、三餐以外会吃食物/零食吗？</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              <el-radio-group v-model="dormdate.casePos6[2].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              一周
              <div class="textundeline"><el-input v-model="dormdate.casePos6[2].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">4、是否常喝饮料/酒</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              <el-radio-group v-model="dormdate.casePos6[3].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              一周
              <div class="textundeline"><el-input v-model="dormdate.casePos6[3].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">5、平时会运动吗？</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              <el-radio-group v-model="dormdate.casePos6[4].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              一周
              <div class="textundeline"><el-input v-model="dormdate.casePos6[4].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">6、是否 12 点之前入睡？</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              <el-radio-group v-model="dormdate.casePos6[5].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">7、睡眠质量好吗？</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              <el-radio-group v-model="dormdate.casePos6[6].caseStatus">
                <el-radio :label="1">好</el-radio>
                <el-radio :label="0">不好</el-radio>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class="solt">
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    </div>
    <tablelogs v-else :userinfo="usId"/>
  </div>
</template>

<script>
  import tablelogs from './tablelogs.vue'
  import { addObj,stpCxccConfig } from '@/api/mall/health/casemy/index'
  export default {
    components:{
      tablelogs
    },
    data() {
      return {
        act:'1',
        usId:'',
        dormdate: {
          sugarStatus: '1',
          medRegularly: '1',
          sugarLowStatus: '1',
          drugStatus: '1',
          fattyStatus: '1',
          casePos1: [
            {
              caseName: '胰岛素',
            },
            {
              caseName: 'GLP-1 类',
            },
          ],
          casePos2: [{}, {}, {}, {}],
          casePos3: [],
          casePos4: [],
          casePos5: [
            { caseClassify: '1', caseStatus: '0' },
            { caseClassify: '2', caseStatus: '0' },
            { caseClassify: '3', caseStatus: '0' },
            { caseClassify: '4', caseStatus: '0' },
            { caseClassify: '5', caseStatus: '0' },
            { caseClassify: '6', caseStatus: '0' },
            { caseClassify: '7', caseStatus: '0' },
          ],
          casePos6: [
            { caseClassify: '1' },
            { caseClassify: '2', caseStatus: '0' },
            { caseClassify: '3', caseStatus: '0' },
            { caseClassify: '4', caseStatus: '0' },
            { caseClassify: '5', caseStatus: '0' },
            { caseClassify: '6', caseStatus: '0' },
            { caseClassify: '7', caseStatus: '0' },
          ],
        },
        cas:[
          {selected:false,caseStatus:0,caseName:'血糖平稳'},
          {selected:false,caseStatus:0,caseName:'减重'},
          {selected:false,caseStatus:0,caseName:'减药'},
          {selected:false,caseStatus:0,caseName:'停药'},
          {selected:false,caseStatus:0,caseName:'预防/延缓并发症'},
        ],
        ca:[
          { selected:false,caseStatus:0,caseName:'以下均无'},
          { selected:false,caseStatus:0,caseName:'高脂血症'},
          { selected:false,caseStatus:0,caseName:'高血压'},
          { selected:false,caseStatus:0,caseName:'肾病'},
          { selected:false,caseStatus:0,caseName:'胆囊炎'},
          { selected:false,caseStatus:0,caseName:'尿蛋白/尿微量白蛋白异常'},
          { selected:false,caseStatus:0,caseName:'其他'},
        ],
      }
    },
    created() {
      if(this.act==1){
        const date = this.$route.query.date
        if(date){
          this.stpCxccConfig(date.id)
          this.usId=date.id
        }
      }
    },
    methods: {
      stpCxccConfig(NUMbitd){
        stpCxccConfig({id:NUMbitd}).then(res=>{
          let date=res.data
          let date1=this.dormdate
          const tab=[]
          const tabs=[]
          if(date){
            if(date.casePos3.length>0){
              date.casePos3.forEach(element => {
                tab.push(element.caseName)
              });
              this.$set(date,'casePos3',tab)
            }
            if(date.casePos4.length>0){
              date.casePos4.forEach(element => {
                tabs.push(element.caseName)
              });
              this.$set(date,'casePos4',tabs)
            }
            const mergedData = Object.keys(date).reduce((acc, key) => {
              const value = date[key];
              if (value || key === 'city' || key === 'country') {
                acc[key] = value;
              }
              return acc;
            }, Object.assign({}, date1));
            this.dormdate=mergedData
          }
        })
      },
      submit() {
        addObj({ ...this.dormdate }).then((res) => {
          if (res.status == 200) {
            this.$message.success('提交成功')
          } else {
            this.$message.error(res.msg)
          }
        })
        console.log(this.dormdate)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    width: 60%;
    margin: auto;
  }
  .ym_header {
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px dashed #ececec;
  }
  ::v-deep {
    .el-col {
      border: 1px solid #b4b4b4;
    }
    .el-input__inner {
      border: none;
    }
  }
  .bg-purple-dark {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    padding: 0 10px;
    white-space: nowrap;
  }
  .textundeline {
    min-width: 20px;
    border-bottom: 1px solid #a3a3a3;
  }
  .lef_ym {
    justify-content: left;
  }
  .solt {
    display: flex;
    justify-content: center;
    margin: 20px auto;
  }
  .tage{
    font-size: 14px;
  }
  .activ{
    font-size: 16px;
  }
  
</style>
