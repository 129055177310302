var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comprehensive-table-container" }, [
    _vm._v("shebei"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }