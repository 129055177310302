import request from '@/utils/request'
// 打卡类型列表
export function queryPage(data) {
  return request({
    url: `/mall/swm/vitPunchCardType/queryPage`,
    method: 'post',
    data,
  })
}
// 打卡类型编辑
export function edit(data) {
  return request({
    url: `/mall/swm/vitPunchCardType/edit`,
    method: 'post',
    data,
  })
}
// 打卡类型添加
export function addObj(data) {
  return request({
    url: `/mall/swm/vitPunchCardType/add`,
    method: 'post',
    data,
  })
}
// 打卡类型删除
export function delObj(data) {
  return request({
    url: `/mall/swm/vitPunchCardType/delete`,
    method: 'post',
    data,
  })
}
// 打卡类型状态
export function disableColumn(data) {
  return request({
    url: `/mall/swm/vitPunchCardType/disable`,
    method: 'post',
    data,
  })
}
// 配置类型（所有文章类型）
export function queryListForList(data) {
  return request({
    url: `/mall/bbs/bbsArticleType/queryListForList`,
    method: 'post',
    data,
  })
}
// 配置类型（所有文章类型）
export function addColumnType(data) {
  return request({
    url: `/user/bbsArticleColumnType/add`,
    method: 'post',
    data,
  })
}
