<template>
  <div class="textTool">
    <div class="toolTit">
      <div class="lef">头部设置</div>
      <div class="ref">
        <div class="switch">
          <div :class="checkIndex == 1 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(1)">内容</div>
          <div :class="checkIndex == 2 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(2)">样式</div>
        </div>
      </div>
    </div>
    <div v-if="checkIndex == 1" class="content_msg">
      <div class="ym_line"></div>
      <div class="ym_title">页面内容</div>
      <div class="itemBox">
        <div class="Tit">页面名称</div>
        <el-input v-model="activeComponent.title" class="item-input" maxlength="20" placeholder="请输入内容" />
      </div>
      <div class="itemBox">
        <div class="Tit">顶部开关</div>
        <div class="check-tit" style="display: flex; align-items: center; color: #3371f7">
          <el-checkbox v-if="activeComponent.navBarSwitch" v-model="activeComponent.navBarSwitch" label="true">显示</el-checkbox>
          <el-checkbox v-else v-model="activeComponent.navBarSwitch" label="true">隐藏</el-checkbox>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">选择风格</div>
        <div class="check-tit" style="display: flex; align-items: center; color: #3371f7" @click="styleshow = true">
          风格{{ activeComponent.navStyle }}
          <i class="el-icon-arrow-right" style="font-size: 14px; color: gray"></i>
        </div>
      </div>
      <div v-if="activeComponent.navStyle == 2 || activeComponent.navStyle == 3" class="itemBox">
        <div class="Tit">图片</div>
        <div class="check-tit" style="display: flex; align-items: center; color: #3371f7">
          <uploadpic :picurl="[{ url: activeComponent.topNavImg }]" @backkuck="backkick" />
        </div>
      </div>
      <div v-if="activeComponent.navStyle == 2 || activeComponent.navStyle == 3" class="alert-msg">宽度自适应（85px），高度30px</div>
      <div class="itemBox">
        <div class="Tit">链接地址</div>
        <div class="check-tit" style="display: flex; align-items: center; color: #3371f7" @click="linkurl = true">
          <span v-if="activeComponent.moreLink.name != ''">{{ activeComponent.moreLink.title }}</span>
          <span v-else>请选择链接地址</span>
          <i class="el-icon-arrow-right" style="font-size: 14px; color: gray"></i>
        </div>
      </div>
      <!-- <div v-if="activeComponent.navStyle == 1" class="itemBox">
        <div class="Tit">展示位置</div>
        <div class="check-tit">
          <el-radio-group v-model="activeComponent.textImgPosLink" size="mini">
            <el-radio-button label="left">居左</el-radio-button>
            <el-radio-button label="center">居中</el-radio-button>
          </el-radio-group>
        </div>
      </div> -->
      <div class="itemBox">
        <div class="Tit">导航栏</div>
        <div class="check-tit">
          <el-checkbox v-if="activeComponent.topNavBg" v-model="activeComponent.topNavBg">显示</el-checkbox>
          <el-checkbox v-else v-model="activeComponent.topNavBg">关闭</el-checkbox>
        </div>
      </div>
      <div class="alert-msg">此处控制当前页面导航栏是否显示</div>
      <div class="ym_line"></div>
      <div class="ym_title">底部导航</div>
      <div class="itemBox">
        <div class="Tit">底部导航</div>
        <div class="check-tit">
          <el-checkbox v-if="activeComponent.openBottomNav" v-model="activeComponent.openBottomNav">显示</el-checkbox>
          <el-checkbox v-else v-model="activeComponent.openBottomNav">隐藏</el-checkbox>
        </div>
      </div>
    </div>
    <div v-if="checkIndex == 2" class="content_msg">
      <div class="ym_line"></div>
      <div class="ym_title">标题样式</div>
      <div class="itemBox">
        <div class="Tit">页面颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponent.pageBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponent.pageBgColor" />
            <div class="resect" @click="activeComponent.pageBgColor = '#FFFFFF'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">顶部颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponent.topNavColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponent.topNavColor" />
            <div class="resect" @click="activeComponent.topNavColor = '#FFFFFF'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">标题颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponent.textNavColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponent.textNavColor" />
            <div class="resect" @click="activeComponent.textNavColor = '#333333'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">顶部透明</div>
        <div class="check-tit">
          <el-checkbox v-if="activeComponent.navBarSwitch" v-model="activeComponent.navBarSwitch">显示</el-checkbox>
          <el-checkbox v-else v-model="activeComponent.navBarSwitch">隐藏</el-checkbox>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">背景图片</div>
        <div class="check-tit">
          <div class="check-tit" style="display: flex; align-items: center; color: #3371f7">
            <uploadpic :picurl="[{ url: activeComponent.bgUrl }]" @backkuck="backbgurl" />
          </div>
        </div>
      </div>
      <div class="ym_line"></div>
      <div class="ym_title">边距设置</div>
      <div class="itemBox">
        <div class="Tit">左右边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponent.template.margin.both" :max="20" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponent.template.margin.both" :max="20" :min="0" style="margin-left: 20px" />
        </div>
      </div>
    </div>
    <styledialog :stylehide="styleshow" @closestyle="closestyledialog" />
    <linkdialog :linkshow="linkurl" @closeurl="closelink" />
  </div>
</template>

<script>
import linkdialog from '../../../link-dialog/index.vue'
import { mapGetters } from 'vuex'
import styledialog from './components/dialog.vue'
import uploadpic from '../../../upload-pic/index.vue'
export default {
  components: {
    styledialog,
    uploadpic,
    linkdialog,
  },
  data() {
    return {
      checkIndex: 1,
      radio4: 'left',
      styleshow: false, //风格选择
      a: '#1780ff',
      linkurl: false,
      checklinknum: null,
    }
  },
  computed: {
    ...mapGetters['state'],
    activeComponent() {
      return this.$store.state.checkdiy.thead
    },
  },
  methods: {
    closelink(lisr) {
      if (lisr != false) {
        this.activeComponent.moreLink = lisr
        this.linkurl = false
      } else {
        this.linkurl = false
      }
    },
    backkick(row) {
      if (row) {
        this.activeComponent.topNavImg = row
      } else {
        this.activeComponent.topNavImg = ''
      }
    },
    backbgurl(row) {
      if (row) {
        this.activeComponent.bgUrl = row
      } else {
        this.activeComponent.bgUrl = ''
      }
    },
    //关闭风格选择
    closestyledialog(e) {
      if (e == false) {
        this.styleshow = e
      } else {
        this.activeComponent.navStyle = e
        this.styleshow = false
      }
    },
    //切换item
    changeChecknum(type) {
      this.checkIndex = type
    },
  },
}
</script>

<style lang="scss" scoped>
.textTool {
  width: 100%;
  padding: 20px 20px 0 20px;
  .toolTit {
    display: flex;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
    color: #333333;
    .switch {
      display: flex;
      align-items: center;
      overflow: hidden;
      font-size: 14px;
      cursor: pointer;
      background: #f5f5f5;
      border-radius: 50px;
      .swit-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 29px;
        border-radius: 50px;
      }
      .active {
        color: white;
        background: #1780ff;
      }
    }
  }
  .ym_line {
    width: 100%;
    height: 3px;
    background: #f2f4f6;
  }
  .ym_title {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .itemBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    min-height: 40px;
    margin-left: 20px;
    .Tit {
      margin-right: 15px;
      color: gray;
      text-align: right;
      white-space: nowrap;
    }
    .check-tit {
      display: flex;
      justify-content: right;
      width: calc(100% - 56px);
      color: #105cfb;
      .resect {
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }
  .alert-msg {
    margin-bottom: 15px;
    font-size: 12px;
    color: rgb(210, 210, 210);
    text-align: center;
  }
  :deep(.el-color-picker__trigger) {
    width: 45px;
    height: 26px;
  }
  :deep(.el-icon-arrow-down:before) {
    display: none;
  }
  ::v-deep {
    .el-input-number {
      width: 80px;
      .el-input__inner {
        padding: 0;
        text-align: center;
      }
    }

    .el-input-number__decrease,
    .el-input-number__increase {
      display: none;
    }
  }
}
</style>
