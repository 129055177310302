const options = [
  // { label: '用户初始积分', span: 12, prop: 'nickName1', type: 'number' },
  // { label: '订单满', span: 12, prop: 'nickName2', type: 'number' },
]

const rules = {
  a: [{ required: true, trigger: 'blur', message: '请输入标题' }],
  b: [{ required: true, trigger: 'blur', message: '请输入作者' }],
}

const searchOptions = [
  { label: '用户', span: 4, prop: 'userCode', labelWidth: '3em' },
  {
    label: '店铺',
    span: 4,
    prop: 'shopId',
    type: 'select',
    key: 'name',
    value: 'id',
    url: '/mall/shopinfo/list',
    optionData: [],
    labelWidth: '3em',
  },
  { type: 'operation', span: 4 },
]
const config = { labelWidth: '3em' }
export { options, rules, config, searchOptions }
