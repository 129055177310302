import { selectSysDict, selectRoleMenuButtonList } from '@/api/mall/common'
// 字典相关混入方法
export const checkDic = {
  data() {
    return {
      dicValues: {},
    }
  },
  methods: {
    // 获取字典value对应的key值
    getDicKey(key, val) {
      if (this.dicValues[key]) {
        const obj = this.dicValues[key].find((item) => item.dictKey == val)
        return obj ? obj.dictValue : ''
      }
      return ''
    },
    // 请求对应的字典列表
    setDicKeys(keysArr) {
      const keys = keysArr || this.dicKeys
      selectSysDict({
        dictTypeList: keys,
      }).then((res) => {
        res.data.forEach((item) => {
          const index = keys.findIndex((key) => key === item.dictType)
          if (index !== -1) {
            const k = keys[index]
            const v = item.dictInfoVoList
            this.$set(this.dicValues, k, v)
          }
        })
      })
    },
  },
}

export const checkButton = {
  data() {
    return {
      btnRolrs: {},
    }
  },
  async created() {
    const res = await selectRoleMenuButtonList({
      // roleIdStrList: this.$store.getters['acl/roleList'],
      // // roleId: 1,
      menuId: this.$route.meta.id,
      roleIdStrList: 1,
    })
    const { data } = res
    data.forEach((item) => {
      if (!this.btnRolrs[item.modelType]) {
        this.$set(this.btnRolrs, item.modelType, {})
        this.$set(this.btnRolrs[item.modelType], 'searchBarButtons', [])
        this.$set(this.btnRolrs[item.modelType], 'tableListButtons', [])
        this.$set(this.btnRolrs[item.modelType], 'fixedBarButtons', [])
        this.$set(this.btnRolrs[item.modelType], 'pageListButtons', [])
      }
      switch (item.buttonType) {
        case 1:
          this.btnRolrs[item.modelType].searchBarButtons.push(item)
          break
        case 2:
          this.btnRolrs[item.modelType].tableListButtons.push(item)
          break
        case 3:
          this.btnRolrs[item.modelType].fixedBarButtons.push(item)
          break
        case 4:
          this.btnRolrs[item.modelType].pageListButtons.push(item)
          break
      }
    })
    // console.log(this.btnRolrs)
  },
  methods: {
    /**
     * @param methodName <String> 挂载到当前vue实例上的某个方法名称
     * @description 点击按钮触发的方法执行
     * */
    handleClickBtn(methodName, ...params) {
      console.log(methodName, params)
      if (params.length === 1 && typeof params[0] === 'function') {
        this[methodName](...params[0]())
      } else {
        this[methodName](...params)
      }
    },
    /**
     * @param list<Array>
     * @param funcList
     * @param modelType<Number>
     */

    handleAddTableButtonRole(list, funcList = null, modelType = 1) {
      if (list.length) {
        list.forEach((item) => {
          item.btns = []
          // 每隔四毫秒去访问tablebtns有值与否
          const timer = setInterval(() => {
            item.btns = this.btnRolrs[modelType]?.tableListButtons
              ? JSON.parse(
                  JSON.stringify(this.btnRolrs[modelType].tableListButtons)
                )
              : []
            if (item.btns.length) {
              clearInterval(timer)
              item.btns.forEach((btn) => {
                if (!funcList[btn.buttonUrl]) {
                  this.$set(btn, 'isShow', true)
                } else {
                  this.$set(btn, 'isShow', funcList[btn.buttonUrl](item, btn))
                }
              })
            }
          }, 10)
        })
      }
    },
    handleSearchBarButtonRole(funcList = null, modelType = 1) {
      const list = this.btnRolrs[modelType]?.searchBarButtons
      if (list.length) {
        list.forEach((btn) => {
          if (!funcList[btn.id]) {
            btn.isShow = true
          } else {
            btn.isShow = funcList[btn.id]['methods'](btn)
          }
        })
      }
    },
    handleFixedBarButtonRole(funcList = {}, modelType = 1) {
      const list = this.btnRolrs[modelType]?.fixedBarButtons
      if (list.length) {
        list.forEach((btn) => {
          if (!funcList[btn.id]) {
            btn.isShow = true
          } else {
            btn.isShow = funcList[btn.id]['methods'](btn)
          }
        })
      }
    },
  },
}
