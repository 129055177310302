import request from '@/utils/request'

// export function getList(params) {
//   return request({
//     url: '/router/getList',
//     method: 'get',
//     params,
//   })
// }
export function getList(data) {
  return request({
    url: `/upms/auth/menuTree`,
    method: 'post',
    data,
  })
}
