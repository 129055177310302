<template>
  <div class="Left-nav">
    <div class="item">
      <el-collapse v-model="activeNames">
        <el-collapse-item v-for="(ym, index) in panelshowlist" :key="index" :name="index + 1" :title="ym.title">
          <div class="ym_grid">
            <div v-for="(item, ind) in ym.classList" :key="ind" class="grid-item" @click="checkitem(item)">
              <div class="grid-center">
                <vab-icon :icon="item.iconClass" />
                <div class="item-title">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    props:{
      navlist:{
        type:Array,
        default:()=>{}
      }
    },
    data() {
      return {
        //展开菜单拓展
        activeNames: [1, 2, 3, 4, 5, 6],
        panelshowlist: this.navlist,
        checknav:null,
      }
    },
    watch:{
      navlist(newValue,oldValue){
        if(newValue!=oldValue){
          this.panelshowlist=newValue
        }
      }
    },
    //响应式api渲染diy菜单项
    // computed: {
    //   panelshowlist() {
    //     //条件判断逻辑更替
    //     return assemble
    //   },
    // },
    methods: {
      //点击选择添加diy组件
      ...mapGetters(['state']),
      checkitem(item) {
        console.log(item,'chuaenr')
        console.log(this.$store.state.checkvalue)
       if(item.type=='header'){
        this.$message.error("已有顶部组件")
       }else{
         // 写入公共缓存diyselect当前选中项
         this.panelshowlist.forEach((element) => {
          element.classList.forEach((el) => {
            if (el.type == item.type) {
              this.checknav=Object.assign({},el.componentContent)
            }
          })
        })
        this.$store.commit('addarray',JSON.parse(JSON.stringify( this.checknav)))
        // this.$store.commit('checklist',JSON.parse(JSON.stringify( this.checknav)))
        this.$store.state.checknumber = this.$store.state.checkcanvas.length 
        this.$store.state.checkcomtorls = item
        this.$store.state.checkcanvas.push(item)
       }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .Left-nav {
    width: 100%;
    .item {
      margin: 0 10px;
      margin-left: 20px;
      .ym_grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        .grid-item {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 80px;
          text-align: center;
          border-radius: 3px;
          &:hover {
            color: white;
            cursor: pointer;
            background: #1780ff;
          }
          i {
            font-size: 25px;
          }
        }
      }
    }
  }
  ::v-deep {
    .el-collapse {
      border: none;
    }
    .el-collapse-item__header {
      position: relative;
      font-size: 14px;
      text-indent: 1em;
      border-bottom: none;
      &::before {
        position: absolute;
        top: 50%;
        left: -0px;
        content: '';
        border: 5px solid;
        border-color: transparent transparent transparent #1780ff;
        transform: translateY(-60%);
      }
      i {
        text-indent: 0;
      }
    }
    .el-collapse-item__wrap {
      border-bottom: none;
    }
  }
</style>
