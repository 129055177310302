<template>
  <div class="container">
    <el-table border :data="tablist" max-height="300">
      <el-table-column align="center" label="序号" type="index" width="55" />
      <el-table-column align="center" label="员工工号" prop="profile"  />
      <el-table-column align="center" label="通话类型" prop="interType"/>
      <el-table-column align="center" label="交流时间" prop="createTime"/>
      <el-table-column align="center" label="交流类型" prop="interTitle" show-overflow-tooltip />
      <el-table-column align="center" label="交流内容" prop="interContent" show-overflow-tooltip />
    </el-table>
    <el-pagination background :current-page="1" layout="total, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
  </div>
</template>

<script>
  import { Communication } from '@/api/mall/health/casemy/index'
  export default {
    props: {
      userinfo: {
        type: Number,
        default: () => {},
      },
    },
    data() {
      return {
        total: 0,
        tablist: [],
        showlist: false,
        showtblist: null,
        form: {
          size: 10,
          current: 1,
          recordId: this.userinfo,
        },
      }
    },
    watch: {
      userinfo(newValue, oldValue) {
        if (newValue != oldValue) {
          this.form.id = newValue
          console.log(newValue)
        }
      },
    },
    created() {
      this.uuUserSugarRecordLog()
      this.$EventBus.$on('reploads', () => {
      //需要执行的代码
      this.uuUserSugarRecordLog()
    })
    },
    methods: {
      closeshow() {
        this.showlist = false
      },
      handleCurrentChange(e) {
        this.form.current = e
        this.uuUserSugarRecordLog()
      },
      handleSizeChange(e) {
        this.form.size = e
        this.uuUserSugarRecordLog()
      },
      uuUserSugarRecordLog() {
        Communication({ ...this.form }).then((res) => {
          this.total = res.data.total
          this.form.size = res.data.size
          this.form.current = res.data.current
          this.tablist = res.data.records
        })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
