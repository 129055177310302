import request from '@/utils/request'

export function queryPage(query) {
  return request({
    url: '/mall/vitMealPlan/page',
    method: 'post',
    data: query,
  })
}

export function queryPagea(query) {
  return request({
    url: '/mall/vitMealPlan/page',
    method: 'post',
    data: query,
  })
}
//计划提交列表
export function jhlb(data) {
  return request({
    url: '/mall/swm/vitMealUserPlan/add',
    method: 'post',
    data,
  })
}
export function addObj(obj) {
  return request({
    url: '/mall/vitMealPlan',
    method: 'post',
    data: obj,
  })
}

export function getObj(id) {
  return request({
    url: '/mall/vitMealPlan/' + id,
    method: 'get',
  })
}

export function editObj(data) {
  return request({
    url: `/mall/vitMealPlan/edit`,
    method: 'post',
    data,
  })
}
export function delObj(id) {
  return request({
    url: '/mall/vitMealPlan/' + id,
    method: 'delete',
  })
}

export function putObj(obj) {
  return request({
    url: '/mall/vitMealPlan',
    method: 'put',
    data: obj,
  })
}
