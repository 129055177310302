import request from '@/utils/request'
// 岗位列表
export function queryPostPage(data) {
  return request({
    url: '/upms/admin/comSysPost/queryPostPage',
    method: 'post',
    data,
  })
}
// 新增岗位
export function queryPostAdd(data) {
  return request({
    url: '/upms/admin/comSysPost/add',
    method: 'post',
    data,
  })
}
// 编辑岗位
export function queryPostUpdate(data) {
  return request({
    // url: '/upms/admin/comSysPost/update',
    url: '/upms/admin/comSysPost/edit',
    method: 'post',
    data,
  })
}
// 删除
export function queryPostDelete(data) {
  return request({
    url: '/upms/admin/comSysPost/delete',
    method: 'post',
    data,
  })
}
// 根据机构ID查询机构对应的职位信息

export function selectOrgPost(data) {
  return request({
    url: '/upms/admin/comSysOrgPost/selectOrgPost',
    method: 'post',
    data,
  })
}
