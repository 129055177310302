import request from '@/utils/request'

/**
 *公海管理分配客户
 */
export function uuUserSugarRecord(obj) {
  return request({
    url: '/mall/user/uuUserSugarRecord/allot',
    method: 'post',
    data: obj,
  })
}

//回收公海
export function Recordrecycle(obj) {
  return request({
    url: '/mall/user/uuUserSugarRecord/recycle',
    method: 'post',
    data: obj,
  })
}

//质检通过公海
export function uuUserSugarRecordQualitypass(obj) {
  return request({
    url: '/mall/swm/uuUserSugarRecordQuality/pass',
    method: 'post',
    data: obj,
  })
}

//质检驳回公海
export function uuUserSugarRecordQualitybohui(obj) {
  return request({
    url: '/mall/swm/uuUserSugarRecordQuality/turnDown',
    method: 'post',
    data: obj,
  })
}

//客情列表驳回
export function uuUserSugarRecordAfterturnDown(obj) {
  return request({
    url: '/mall/uuUserSugarRecordAfter/turnDown',
    method: 'post',
    data: obj,
  })
}

//电话详情历史
export function agentrecordList(obj) {
  return request({
    url: '/mall/cxcc/agentrecordList',
    method: 'post',
    data: obj,
  })
}

//客户数据合并日志服务
export function querySugarCombPage(obj) {
  return request({
    url: '/mall//user/uuUserSugarCombineLog/queryPage',
    method: 'post',
    data: obj,
  })
}
// 公海质检分配部门
export function distributiondepartment(data) {
  return request({
    url: '/mall/swm/uuUserSugarRecordQuality/allot',
    method: 'post',
    data,
  })
}
// 客情分配部门
export function uuUserSugarRecordAfterallot(data) {
  return request({
    url: '/mall/uuUserSugarRecordAfter/allot',
    method: 'post',
    data,
  })
}

//客情数据-客情审核-通过
export function uuUserSugarRecordAftercheckPass(data) {
  return request({
    url: '/mall/uuUserSugarRecordAfter/checkPass',
    method: 'post',
    data,
  })
}

//客情数据-客情审核-驳回
export function uuUserSugarRecordAftercheckTurnDown(data) {
  return request({
    url: '/mall/uuUserSugarRecordAfter/checkTurnDown',
    method: 'post',
    data,
  })
}
