var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.dialogFormVisible },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "120px",
            model: _vm.form,
            rules: _vm.rulesform,
          },
        },
        [
          _c("el-form-item", { attrs: { label: "收货人名字：" } }, [
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.deliverys.orderLogistics.userName
                      ? _vm.deliverys.orderLogistics.userName
                      : ""
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("el-form-item", { attrs: { label: "电话号码：" } }, [
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.deliverys.orderLogistics.telNum
                      ? _vm.deliverys.orderLogistics.telNum
                      : ""
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("el-form-item", { attrs: { label: "收货地址：" } }, [
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.deliverys.orderLogistics.address
                      ? _vm.deliverys.orderLogistics.address
                      : ""
                  ) +
                  " "
              ),
            ]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "发货方式：", prop: "deliveryType" } },
            _vm._l(_vm.delieryTypyList, function (item) {
              return _c(
                "el-radio",
                {
                  key: item.id,
                  attrs: { label: item.id },
                  on: { change: _vm.changRadio },
                  model: {
                    value: _vm.form.deliveryType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "deliveryType", $$v)
                    },
                    expression: "form.deliveryType",
                  },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            1
          ),
          _vm.form.deliveryType === 1
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "物流公司：", prop: "deliveryCompany" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "mb20 query-item query-input",
                          attrs: { placeholder: "请选择物流公司" },
                          on: { change: _vm.onSelect },
                          model: {
                            value: _vm.form.deliveryCompany,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "deliveryCompany", $$v)
                            },
                            expression: "form.deliveryCompany",
                          },
                        },
                        _vm._l(_vm.deliveryCompanyList, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递单号：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.codeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "codeType", $$v)
                            },
                            expression: "form.codeType",
                          },
                        },
                        _vm._l(_vm.codeTypeList, function (item) {
                          return _c(
                            "el-radio",
                            {
                              key: item.dictKey,
                              attrs: { label: item.dictKey },
                            },
                            [_vm._v(" " + _vm._s(item.dictValue) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.codeType == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "deliveryCode" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "物流单号" },
                            model: {
                              value: _vm.form.deliveryCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "deliveryCode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.deliveryCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.codeType == 2
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否保价：",
                                prop: "useInsured",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.useInsured,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "useInsured", $$v)
                                    },
                                    expression: "form.useInsured",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "0" } }, [
                                    _vm._v("不保价"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("保价"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.form.useInsured == 1
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "保价金额(￥)：",
                                    prop: "price",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.form.price,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "price",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.price",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "50%" },
                              attrs: { label: "备注：", prop: "remark" },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea" },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "remark",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.form.deliveryType === 20
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "预计取货时间：",
                        prop: "expectReceiveTime",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          align: "center",
                          "picker-options": _vm.pickerOptions,
                          placeholder: "选择时间",
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.form.expectReceiveTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "expectReceiveTime", $$v)
                          },
                          expression: "form.expectReceiveTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.expectReceiveRemark,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "expectReceiveRemark",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.expectReceiveRemark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.goodsMsgTable },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { label: "商品名称", prop: "spuName" },
          }),
          _c("el-table-column", { attrs: { label: "数量", prop: "quantity" } }),
          _c("el-table-column", {
            attrs: { label: "发货店铺" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({}) {
                  return [_c("div", [_vm._v(_vm._s(_vm.deliverys.shopName))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "物流单号", prop: "orderId" },
          }),
          _c("el-table-column", {
            attrs: { label: "状态", prop: "statusDesc" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }