<template>
  <el-dialog
  title="查看"
  :visible.sync="dialogVisible"
  width="60%"
  @close="handleClose">
  <span>
    <el-descriptions border :column="2"  title="" >
        <el-descriptions-item label="店铺：">{{deate.shopId}}</el-descriptions-item>
        <el-descriptions-item label="创建时间：">{{deate.createTime}}</el-descriptions-item>
        <el-descriptions-item label="名称：">{{deate.name}}</el-descriptions-item>
        <el-descriptions-item label="排序：">{{ deate.sort }}</el-descriptions-item>
        <el-descriptions-item label="库存：">{{deate.stock}} </el-descriptions-item>
        <el-descriptions-item label="订单金额：">{{deate.premiseAmount}} </el-descriptions-item>
        <el-descriptions-item label="类型：">
          <el-radio-group v-model="deate.type" disabled>
            <el-radio  label="1">代金券</el-radio>
            <el-radio  label="2">折扣券</el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item label="减免金额：">{{deate.reduceAmount}} </el-descriptions-item>
        <el-descriptions-item label="到期类型：">
          <el-radio-group v-model="deate.expireType" disabled>
            <el-radio  label="1">领券后生效</el-radio>
            <el-radio  label="2">固定时间段</el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item label="有效天数：">{{deate.validDays}} </el-descriptions-item>
        <el-descriptions-item label="是否启用：">
          <el-radio-group v-model="deate.enable" disabled>
            <el-radio  label="0">关闭</el-radio>
            <el-radio  label="1">启用</el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item label="适用商品">
          <el-radio-group v-model="deate.suitType" disabled>
            <el-radio  label="1">全部商品</el-radio>
            <el-radio  label="2">指定商品</el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item label="指定商品：">{{deate.listGoodsSpu}} </el-descriptions-item>
    </el-descriptions>
  </span>
</el-dialog>

</template>

<script>
export default {
  props:{
    hideview:{
      type:Boolean,
      default:()=>{}
    },
    viewrow:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
    return{
      dialogVisible:this.hideview,
      deate:this.viewrow
    }
  },  
  watch:{
    hideview(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisible=newValue
      }
    },
    viewrow(newValue,oldValue){
      if(newValue!=oldValue){
        this.deate=newValue
      }
    },
  },
  created(){
    console.log(this.viewrow)
  },
  methods:{
    handleClose(){
      this.$emit('closeview',false)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__header{
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>