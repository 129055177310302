import request from '@/utils/request'
// 菜单列表
export function queryPage(data) {
  return request({
    url: '/upms/admin/comSysMenu/queryPage',
    method: 'post',
    data,
  })
}
// 新增菜单
export function menuAdd(data) {
  return request({
    url: '/upms/admin/comSysMenu/add',
    method: 'post',
    data,
  })
}

// 编辑菜单
export function menuUpdate(data) {
  return request({
    url: '/upms/admin/comSysMenu/edit',
    method: 'post',
    data,
  })
}
// 删除
export function menuDelete(data) {
  return request({
    url: '/upms/admin/comSysMenu/isDelete',
    method: 'post',
    data,
  })
}
// 编辑菜单详情
export function loadUpdate(data) {
  return request({
    url: '/upms/admin/comSysMenu/loadUpdate',
    method: 'post',
    data,
  })
}
