var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "common-form",
        {
          attrs: { options: _vm.searchOptions },
          model: {
            value: _vm.searchForm,
            callback: function ($$v) {
              _vm.searchForm = $$v
            },
            expression: "searchForm",
          },
        },
        [
          _c(
            "template",
            { slot: "operation" },
            [
              _c("el-button", { attrs: { type: "primary" } }, [_vm._v("搜索")]),
              _c("el-button", [_vm._v("重置")]),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-col",
        { attrs: { span: 6 } },
        [
          _c("el-date-picker", {
            staticClass: "m-b",
            attrs: {
              align: "right",
              "end-placeholder": "结束日期",
              "picker-options": _vm.pickerOptions,
              "range-separator": "至",
              "start-placeholder": "开始日期",
              type: "datetimerange",
            },
            model: {
              value: _vm.searchForm.date,
              callback: function ($$v) {
                _vm.$set(_vm.searchForm, "date", $$v)
              },
              expression: "searchForm.date",
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { border: "", data: _vm.data } },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "名称", sortable: "" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "是否显示", sortable: "" },
          }),
          _c("el-table-column", { attrs: { align: "center", label: "排序" } }),
          _c("el-table-column", { attrs: { align: "center", label: "操作" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }