import request from '@/utils/request'
export function queryPage(data) {
  return request({
    url: `/mall/user/sysRoute/queryPage`,
    method: 'post',
    data,
  })
}

export function sysRouteadd(data) {
  return request({
    url: `/mall/user/sysRoute/add`,
    method: 'post',
    data,
  })
}

export function sysRouteedit(data) {
  return request({
    url: `/mall/user/sysRoute/edit`,
    method: 'post',
    data,
  })
}

export function sysRoutedel(data) {
  return request({
    url: `/mall/user/sysRoute/delete`,
    method: 'POST',
    data,
  })
}