<template>
  <div class="ym_container">
    <el-form ref="basicform" :model="actionscomponents" :rules="rules">
      <el-form-item label="销售价(元)">
        <el-input-number v-model="actionscomponents.salesPrice" label="描述文字" :min="0" />
      </el-form-item>
      <el-form-item label="市场价(元)">
        <el-input-number v-model="actionscomponents.marketPrice" label="描述文字" :min="1" />
      </el-form-item>
      <el-form-item label="成本价(元)">
        <el-input-number v-model="actionscomponents.costPrice" label="描述文字" :min="1" />
      </el-form-item>
      <el-form-item label="库存">
        <el-input-number v-model="actionscomponents.stock" label="描述文字" :min="1" />
      </el-form-item>
      <el-form-item label="重量(Kg)">
        <el-input-number v-model="actionscomponents.weight" label="描述文字" :min="1" />
      </el-form-item>
      <el-form-item label="体积(m²)">
        <el-input-number v-model="actionscomponents.volume" label="描述文字" :min="1" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {mapState} from 'vuex'
  export default {
    data() {
      return {
        form: {
          firstRebate: 50,
          secondRebate: 13,
          enable: 1,
        },
        rules: {
          // firstRebate: [{ required: true, message: '请填写一级返佣金额', trigger: 'blur' }],
          // secondRebate: [{ required: true, message: '请填写二级返佣金额', trigger: 'blur' }],
        },
      }
    },
    computed:{
      ...mapState(['state']),
      actionscomponents(){
        const list= this.$store.state.goodssku.sku
        if(list!=null&&list!=''){
          return this.$store.state.goodssku.sku
        }else{
          const info={
            salesPrice:null,
            marketPrice:null,
            costPrice:null,
            stock:null,
            weight:null,
            volume:null,
          }
          this.$store.commit('adinfo',info)
          return  this.$store.state.goodssku.sku
        }
      }
    },
  }
</script>

<style lang="scss" scope>
  .ym_container {
    width: 180%;
    margin-left: 20px;
  }
</style>
