<template>
  <div>
    <!-- 查询 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <vab-query-form>
          <vab-query-form-top-panel>
            <el-form :inline="true" label-width="0" @submit.native.prevent>
              <el-form-item label="">
                <el-input v-model="queryForm.iconKey" />
              </el-form-item>
              <el-form-item label-width="0">
                <el-button
                  native-type="submit"
                  type="primary"
                  @click="handleQuery"
                >
                  查询
                </el-button>
                <el-button
                  native-type="submit"
                  type=""
                  @click="handleReset"
                >
                  重置
                </el-button>
                <el-button
                  native-type="submit"
                  type=""
                  @click="save"
                >
                  确定
                </el-button>
              </el-form-item>
            </el-form>
          </vab-query-form-top-panel>
        </vab-query-form>
      </el-col>
    </el-row>
    <!-- icon列表 -->
    <el-row :gutter="20" style="max-height: 500px; overflow: auto">
      <el-col v-for="(item, index) in queryIcon" :key="index" :span="6">
        <el-card :class="curr==index?'active check':'check'" shadow="hover" @click.native="handleIcon(item,index)">
          <vab-icon
            :icon="item.iconKey"
            style="font-size: 28px; text-align: center"
          />
          <div class="ml10" @click="copyText(item.iconKey)">{{ item.iconKey }}</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 分页 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <el-pagination
          :background="background"
          :current-page="forms.current"
          :layout="layout"
          :page-size="forms.size"
          :page-sizes="[20, 40, 60, 80, 100, 1000, 2000]"
          style="margin-bottom: 30px !important"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import { getIconInfo } from '@/api/mall/common'
  export default {
    name: 'VabIconSelector',
    data() {
      return {
        icon: '24-hours-fill',
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        queryIcon: [],
        forms: { current: 1, size: 2000 },
        isShow: true,
        queryForm: {
          iconKey: '',
        },
        curr:null,
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      // 列表
      async fetchData(options) {
        await getIconInfo({
          ...options,
          ...this.forms,
        })
          .then((res) => {
            if (res.data && res.data != null) {
              this.queryIcon = res.data.records
              this.total = res.data.total
            }
          })
          .catch(() => {})
      },
      // 点击选中Icon
      handleIcon(item,Index) {
        this.curr=Index
        this.icon = item
        // this.$emit('handle-icon', item)
      },
      //copyText获取选中icon名称
      async copyText(item){
        try {
          await navigator.clipboard.writeText(item);
          this.$message.success('文本已复制到剪贴板')
        } catch (err) {
          this.$message.success('无法复制文本',err)
        }
      },
      //确定选中
      save(){
        if(this.icon!=''){
          this.$message.success("修改成功")
          this.$emit('handle-icon', this.icon)
        }
      },
      // 重置
      handleReset() {
        this.forms.current = 1
        this.forms.size = 10
        this.queryForm = {}
        this.handleQuery()
      },
      // 搜索
      async handleQuery() {
        this.forms.current = 1
        await this.fetchData({ ...this.queryForm }, false)
        let temp = false
        Object.keys(this.queryForm).forEach((key) => {
          this.queryForm[key] && (temp = true)
        })
        this.isShow = temp
      },
      // 分页
      handleSizeChange(val) {
        this.forms.size = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
      // 分页
      handleCurrentChange(val) {
        this.forms.current = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep(.el-card__body) {
    text-align: center !important;
  }
  ::v-deep(.el-card) {
    .el-card__body {
      padding: 10px;
    }
  }
  .icon-selector-popper {
    .el-card__body {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 20px;
      cursor: pointer;
      i {
        font-size: 28px !important;
        color: $base-color-grey;
        text-align: center;
        vertical-align: middle;
        pointer-events: none;
        cursor: pointer;
      }
    }

    .el-pagination {
      margin: 0;
    }
   
  }
  ::v-deep {
    .check{
      cursor: pointer;
    }
    .active{
      // background: #1780ff;
      border: 1px solid #1780ff;
    }
  }
</style>
