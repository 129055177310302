import { render, staticRenderFns } from "./addressdialog.vue?vue&type=template&id=63f2bf7e&scoped=true"
import script from "./addressdialog.vue?vue&type=script&lang=js"
export * from "./addressdialog.vue?vue&type=script&lang=js"
import style0 from "./addressdialog.vue?vue&type=style&index=0&id=63f2bf7e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63f2bf7e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\健康约-后管-前端\\health-front-backend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63f2bf7e')) {
      api.createRecord('63f2bf7e', component.options)
    } else {
      api.reload('63f2bf7e', component.options)
    }
    module.hot.accept("./addressdialog.vue?vue&type=template&id=63f2bf7e&scoped=true", function () {
      api.rerender('63f2bf7e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mall/health/openSea/components/confirm-order/components/addressdialog.vue"
export default component.exports