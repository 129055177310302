<template>
  <div class="comprehensive-table-container">
    <div class="search" style="margin-bottom: 10px">
      <el-form class="demo-form-inline" :inline="true" :model="formInline">
        <el-form-item label="部门">
          <el-cascader v-model="formInline.postIds" :options="dataSourceLists" :props="{
            checkStrictly: true,
            label: 'orgName', value: 'id'
          }" @focus="fetchData">
            <template slot-scope="{ node, data }">
              <span v-if="!node.isLeaf">{{ data.orgName }}({{ data.children.length }})</span>
            </template>
          </el-cascader>
        </el-form-item>
        <el-form-item label="员工">
          <el-input v-model.trim="formInline.realName" placeholder="请输入搜索人姓名" />
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker v-model="timer" align="right" end-placeholder="结束日期" format="yyyy-MM-dd" range-separator="至"
            start-placeholder="开始日期" type="datetimerange" value-format="yyyy-MM-dd" />
        </el-form-item>
        <el-form-item label="下单类型">
          <el-input v-model.trim="formInline.realName" placeholder="请输入搜索人姓名" />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="onSubmit">搜索</el-button>
          <el-button icon="el-icon-refresh" type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-table border :data="tableData" show-summary style="width: 100%" :summary-method="getSummaries">
        <el-table-column align="center" label="员工名称" prop="realName" width="120" />
        <el-table-column align="center" label="所属部门" prop="postName" />
        <el-table-column align="center" label="总订单" prop="profile" />
        <el-table-column align="center" label="已签收" prop="sunCount" />
        <el-table-column align="center" label="待客情" prop="todayCount" />
        <el-table-column align="center" label="已打单" prop="yesDayCount" />
        <el-table-column align="center" label="运输中" prop="weekCount" />
        <el-table-column align="center" label="已收货" prop="mouthCount" />
      </el-table>
    </div>
    <el-pagination background :current-page="1" layout="total, sizes, prev, pager, next, jumper"
      :page-size="formInline.size" :total="total" @current-change="handleCurrentChange"
      @size-change="handleSizeChange" />
  </div>
</template>

<script>
import { orgTrees } from '@/api/mall/role/orgManage'
import { uuUserSugarStaBranchqueryUserEmpPage } from '@/api/mall/health/teamdata/index'
export default {
  data() {
    return {
      dialogVisible: false,
      dataSourceLists: null,
      formInline: {
        size: 10,
        current: 1,
      },
      timer: null,
      total: 0,
      tableData: [],
      showData: [],
      rowList: []
    }
  },
  watch: {
    timer(nVal) {
      if (nVal) {
        this.formInline.StartTimer = nVal[0]
        this.formInline.EndTimer = nVal[1]
      } else {
        delete this.formInline.StartTimer
        delete this.formInline.EndTimer
      }
    },
  },
  created() {
    this.getUserList()
  },
  methods: {
    async fetchData() {
      const parms = {
        current: this.current,
        size: this.size,
      }
      await orgTrees(parms)
        .then((res) => {
          console.log(res.data)
          if (res.data) {
            this.menuListTable = res.data.records
            this.dataSourceLists = res.data.records
            this.dataSourceLists.map((obj) => {
              obj.label = obj.orgName
              obj.value = obj.id
              const fieldArr = obj.children
              const newFieldArr = fieldArr.map((element) => {
                element.label = element.orgName
                element.value = element.id
                element.children.forEach((item) => {
                  item.label = item.orgName
                  item.value = item.id
                  if (item.children != null) {
                    item.children.forEach((el) => {
                      el.label = el.orgName
                      el.value = el.id
                    })
                  }
                })
                return element
              })
              obj.fieldArr = newFieldArr
              return obj
            })
          }
        })
        .catch(() => { })
      this.listLoading = false
    },
    showMore(row) {
      this.dialogVisible = true
      this.showData.push(row)
    },
    closeDialog() {
      this.dialogVisible = false;
      this.showData = []; // 清空showData数组
    },
    reset() {
      this.timer = []
      this.formInline = {}
      this.formInline.size = 10
      this.formInline.current = 1
      this.getUserList()
    },
    onSubmit() {
      this.formInline.current = 1
      this.getUserList()
    },
    handleCurrentChange(e) {
      this.formInline.current = e
      this.getUserList()
    },
    handleSizeChange(e) {
      this.formInline.size = e
      this.getUserList()
    },
    getUserList() {
      uuUserSugarStaBranchqueryUserEmpPage({ ...this.formInline }).then((res) => {
        this.tableData = res.data.records
        this.formInline.size = res.data.size
        this.formInline.current = res.data.current
        this.total = res.data.total
        if (res != false) {
          this.data = res.data.records
          for (let i = 0; i < this.data.length; i++) {
            const obj = this.data[i]
            const keys = Object.keys(obj)
            for (let key of keys) {
              if (typeof obj[key] === 'number') {
                obj[key] = String(obj[key])
              }
            }
            this.data[i] = obj
          }
          this.rowList.push(this.data[this.data.length - 1])
          this.data.splice(this.data.length - 1, 1)
        }
      })
    },
    getSummaries(param) {
      const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总价';
            return;
          }
          if (index === 1) {
            sums[index] = '';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += ' 单';
          } else {
            sums[index] = '';
          }
        });

        return sums;
    }
  },
}
</script>

<style></style>
