/**
 * 电销-客服中心-客服复购
 *
 */
import request from '@/utils/request'

export function getUserList(params) {
  return request({
    url: `/mall/userinfo/querRepurPage`,
    method: 'post',
    data: params,
  })
}

export function disableUser(data) {
  return request({
    url: `/user/uUserLogin/disable`,
    method: 'post',
    data,
  })
}
