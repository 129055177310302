<template>
  <div class="cy-container">
    <!-- 查询 -->
    <el-form
      ref="queryForm"
      :inline="true"
      :model="queryForm"
      @submit.native.prevent
    >
      <el-form-item label="名称" prop="articleTitle">
        <el-input
          v-model="queryForm.articleTitle"
          class="filterItems"
          clearable
          placeholder="请输入课程名称"
        />
      </el-form-item>
      <el-form-item label="状态" prop="colStatus">
        <el-select
          v-model="queryForm.colStatus"
          class="filterItems"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in colStatusList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
          icon="el-icon-search"
          native-type="submit"
          type="warning"
          @click="handleQuery"
        >
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          native-type="submit"
          type="primary"
          @click="handleReset"
        >
          重置
        </el-button>
      </el-form-item>
      <!-- <el-form-item style="float: right">
        <el-button icon="el-icon-plus" type="primary" @click="handelEdit">
          添加分类
        </el-button>
        <el-button
          v-for="item in searchButton"
          :key="item.id"
          :plain="item.cssType && item.cssType == 'plain' ? true : false"
          :type="item.buttonCss"
          @click.native="handleClickBtn(item)"
        >
          <span v-if="!item.buttonIcon"></span>
          <vab-icon v-else :icon="item.buttonIcon" />
          {{ item.buttonName }}
        </el-button>
      </el-form-item> -->
    </el-form>

    <!-- 添加 -->
    <el-button
      v-for="item in searchButton"
      :key="item.id"
      class="m-b"
      :plain="item.cssType && item.cssType == 'plain' ? true : false"
      :type="item.buttonCss"
      @click.native="handleClickBtn(item)"
    >
      <span v-if="!item.buttonIcon"></span>
      <vab-icon v-else :icon="item.buttonIcon" />
      {{ item.buttonName }}
    </el-button>
    <!-- 表格 -->
    <el-table
      ref="multipleTable"
      v-loading="listLoading"
      border
      :data="tableData"
      default-expand-all
      max-height="600"
      @selection-change="handleSelectionChange"
    >
      <el-table-column align="center" show-overflow-tooltip type="selection" />
      <el-table-column
        align="center"
        label="课程名称"
        prop="articleTitle"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="课程图片"
        prop="picUrl"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <div>
            <el-image
              alt=""
              fit="fill"
              :src="row.picUrl"
              srcset=""
              style="width: 60px; height: 60px"
              @click="handleImgClick(row.picUrl)"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="作者"
        prop="authorName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="课程次数"
        prop="freqNun"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="课程分钟"
        prop="articleMinutes"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="课程简介"
        prop="articleIntroduction"
        show-overflow-tooltip
      />

      <el-table-column
        align="center"
        label="是否开启"
        prop="enable"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-switch
            v-model="row.enable"
            :active-value="1"
            :disabled="row.enable == 1 ? listShows == true : listShows == false"
            :inactive-value="0"
            @change="handleUpdate('是否开启', row)"
          />
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        show-overflow-tooltip
      />
      <el-table-column
        v-if="operationShow"
        align="center"
        fixed="right"
        label="操作"
        min-width="100px"
      >
        <template #default="{ row }">
          <template v-for="item in listButton">
            <el-button
              v-if="showListButton(row, item)"
              :key="item.id"
              :plain="item.cssType && item.cssType == 'plain' ? true : false"
              size="default"
              :type="item.buttonCss"
              @click.native="handleClickBtn(item, row)"
            >
              <span v-if="!item.buttonIcon"></span>
              <!-- <vab-icon v-else :icon="item.buttonIcon" /> -->
              {{ item.buttonName }}
            </el-button>
          </template>
          <!-- <el-button type="text" @click="handleLook(row)">查看</el-button>
          <el-button type="text" @click="handleDisable(row)">
            {{ row.colStatus == 1 ? '禁用' : '开启' }}
          </el-button>
          <el-button type="text" @click="handelEdit(row)">编辑</el-button>
          <el-button type="text" @click="handelDelete(row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      :current-page="forms.current"
      :layout="layout"
      :page-size="forms.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 新增(编辑) -->
    <el-dialog
      class="addDialog"
      :title="dialogTitle"
      top="2vh"
      :visible.sync="addVisible"
      width="60%"
      @close="close"
    >
      <el-form
        ref="addForm"
        label-width="100px"
        :model="addForm"
        :rules="rules"
      >
        <el-form-item label="课程名称" prop="articleTitle">
          <el-input
            v-model.trim="addForm.articleTitle"
            autocomplete="off"
            clearable
            placeholder="请输入课程名称"
          />
        </el-form-item>
        <el-form-item label="作者" prop="authorName">
          <el-input
            v-model="addForm.authorName"
            :min="0"
            placeholder="请输入作者"
            size="small"
            style="width: 100%"
          />
        </el-form-item>

        <el-form-item label="课程次数" prop="sort">
          <el-input-number
            v-model="addForm.freqNun"
            :min="0"
            placeholder="请输入"
            size="small"
            style="width: 100%"
          />
        </el-form-item>

        <el-form-item label="课程分钟" prop="articleMinutes">
          <el-input-number
            v-model="addForm.articleMinutes"
            :min="0"
            placeholder="请输入课程分钟"
            size="small"
            style="width: 100%"
          />
        </el-form-item>
        <el-form-item label="课程LOGO" prop="picUrl" required>
          <el-upload
            ref="upload"
            :action="action"
            :before-upload="bfUpload"
            :class="{
              hide: hideUpload,
            }"
            :file-list="fileList"
            :headers="headers"
            :limit="limitCount"
            list-type="picture-card"
            :on-change="handleChange"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog append-to-body :visible.sync="dialogVisible">
            <img alt="" :src="dialogImageUrl" width="100%" />
          </el-dialog>
        </el-form-item>
        <el-form-item label="课程分类" prop="categoryId">
          <el-select
            v-model="addForm.categoryId"
            clearable
            placeholder="请选择课程分类"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="简介" prop="articleIntroduction">
          <el-input
            v-model.trim="addForm.articleIntroduction"
            autocomplete="off"
            clearable
            placeholder="请输入"
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="课程步骤" prop="articleContent">
          <el-col :offset="0" :span="16">
            <BaseEditor v-model="addForm.articleContent" />
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="imgDialog">
      <img :src="imagesUrl" width="100%" />
    </el-dialog>
    <!-- 查看 -->
    <el-dialog title="查看" :visible.sync="lookVisible" width="40%">
      <el-form ref="addForm" label-width="100px" :model="lookForm">
        <el-form-item label="课程名称：">
          <el-row class="color117">{{ lookForm.articleTitle }}</el-row>
        </el-form-item>
        <el-form-item label="作者：">
          <el-row class="color117">{{ lookForm.authorName }}</el-row>
        </el-form-item>

        <el-form-item label="课程次数：">
          <el-row class="color117">{{ lookForm.freqNun }}</el-row>
        </el-form-item>

        <el-form-item label="课程分钟：">
          <el-row class="color117">{{ lookForm.articleMinutes }}</el-row>
        </el-form-item>

        <el-form-item label="排序：">
          <el-row class="color117">{{ lookForm.sort }}</el-row>
        </el-form-item>

        <el-form-item label="分类简介：">
          <el-row class="color117">{{ lookForm.ctgIntro }}</el-row>
        </el-form-item>
        <el-form-item label="列表图片">
          <el-row class="color117">
            <el-image
              alt=""
              fit="fill"
              :src="lookForm.picUrl"
              srcset=""
              style="width: 110px; height: 100px; border: 1px solid #ddd"
              @click="handleImgClick(lookForm.picUrl)"
            />
          </el-row>
        </el-form-item>
        <el-form-item label="课程步骤：">
          <el-row class="color117">{{ lookForm.articleContent }}</el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="lookVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 配置类型 -->
    <el-dialog
      v-if="authorizeRoleisible"
      class="authorizePoles"
      :close-on-click-modal="false"
      title="配置类型"
      :visible.sync="authorizeRoleisible"
      width="50%"
      @close="closeRole"
    >
      <el-row>
        <el-col
          style="padding: 15px; margin-bottom: 15px; border: 1px solid #ddd"
        >
          {{ selectarticleTitle }}
        </el-col>
      </el-row>

      <el-transfer
        v-model="grantRoleList"
        :data="roleList"
        filterable
        :props="{ label: 'typeName', key: 'id' }"
        :titles="treeTitle"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeRole">取 消</el-button>
        <el-button type="primary" @click="createAuthorizeRoleButton">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import {
    queryPage,
    queryArticlecategory,
    editObj,
    addObj,
    delObj,
    disableColumn,
    addColumnType,
  } from '@/api/mall/health/coursemanage/column'
  import { uploadURL } from '@/config'
  import { selectRoleMenuButtonList } from '@/api/mall/common'
  import BaseEditor from '@/components/editor/BaseEditor.vue'
  export default {
    name: 'ArtColumn',
    components: {
      BaseEditor,
    },
    data() {
      return {
        selectarticleTitle: '',
        multipleSelection: [],
        temp: {},
        postCodeOptions: [],
        treeTitle: ['未配置类型', '已配置类型'],
        grantRoleList: [],
        roleList: [],
        authorizeRoleisible: false,
        operationShow: false,
        lookForm: {},
        lookVisible: false,
        imagesUrl: '',
        imgDialog: false,
        hideUpload: false,
        limitCount: 1,
        dialogImageUrl: '',
        dialogVisible: false,
        fileList: [],
        headers: {},
        action: uploadURL,
        rules: {
          articleTitle: [
            {
              required: true,
              message: '课程名称不能为空',
              trigger: ['blur', 'change'],
            },
          ],
          authorName: [
            {
              required: true,
              message: '作者不能为空',
              trigger: ['blur', 'change'],
            },
          ],
          articleIntroduction: [
            {
              required: true,
              message: '简介不能为空',
              trigger: ['blur', 'change'],
            },
          ],
        },
        addForm: {
          picUrl: '',
          articleContent: '',
        },
        addVisible: false,
        dialogTitle: '',
        colStatusList: [
          {
            id: '1',
            name: '启用',
          },
          {
            id: '0',
            name: '关闭',
          },
        ],
        listLoading: false,
        forms: { current: 1, size: 10, ctgType: 1 },
        isShow: true,
        queryForm: {
          colStatus: '',
          articleTitle: '',
        },
        tableData: [],
        current: 1,
        size: 10,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        searchButton: [],
        listButton: [],
        options: [],
      }
    },
    computed: {
      ...mapGetters({
        token: 'user/token',
        tenantId : 'user/tenantId',
      }),
    },
    created() {
      this.headers['Authorization'] = `Bearer ${this.token}`
      this.headers['Switch-Tenant-Id'] = `${this.tenantId}`
      this.fetchData()
      this.selectRoleMenuButtonList()
    },
    methods: {
      // 配置类型保存
      createAuthorizeRoleButton() {
        if (this.grantRoleList.length === 0) {
          this.ME('已配置类型不能为空')
          return
        }
        const tempData = {}
        tempData.typeIds = this.grantRoleList
        tempData.colId = this.multipleSelection[0].id
        addColumnType(tempData).then(() => {
          this.authorizeRoleisible = false
          this.MS('成功')
          this.closeRole()
          this.fetchData()
        })
      },

      // 配置类型
      handleType() {
        if (this.multipleSelection.length === 0) {
          this.ME('请勾选记录')
          return
        }
        this.authorizeRoleisible = true
      },
      // 表格操作
      handleSelectionChange(val) {
        if (val.length > 1) {
          this.$refs.multipleTable.clearSelection()
          this.$refs.multipleTable.toggleRowSelection(val.pop())
        } else {
          this.multipleSelection = val
          if (val.length != 0) {
            this.selectarticleTitle = val[0].articleTitle
          }
        }
      },

      // 配置类型(取消)
      closeRole() {
        this.grantRoleList = []
        this.roleList = []
        this.authorizeRoleisible = false
      },
      // 按钮状态显示判断
      showListButton(row, item) {
        if (
          item.buttonUrl == 'handleLook' ||
          item.buttonUrl == 'handelEdit' ||
          item.buttonUrl == 'handelDelete'
        ) {
          return true
        }
        // if (row.enable == 1 && item.buttonName == '开启') {
        //   return true
        // } else if (row.enable == 0 && item.buttonName == '禁用') {
        //   return true
        // }
      },
      // 按钮点击
      handleClickBtn(item, row) {
        if (item.buttonUrl) {
          this[item.buttonUrl](row)
        }
        this.fetchDatas()
      },
      // 按钮权限
      selectRoleMenuButtonList() {
        selectRoleMenuButtonList({
          roleIdStrList: this.$store.getters['acl/roleList'],
          menuId: this.$route.meta.id,
        })
          .then((res) => {
            if (res.data) {
              this.searchButton = res.data.filter(function (item) {
                return item.buttonType == 1
              })
              this.listButton = res.data.filter(function (item) {
                return item.buttonType == 2
              })
              if (this.listButton.length > 0) {
                this.operationShow = true
              } else {
                this.operationShow = false
              }
            }
          })
          .catch(() => {})
      },
      // 图片上传
      bfUpload(file) {
        console.log(file)
        if (
          'image/png' == file.type ||
          'image/jpeg' == file.type ||
          'image/jpg' == file.type
        ) {
          console.log()
        } else {
          this.ME('图片上传失败,请上传png,jpeg,jpg格式')
          return false
        }
      },
      // 图片预览
      handleImgClick(url) {
        this.imagesUrl = url
        this.imgDialog = true
      },
      // 图片
      handleChange(file, fileList) {
        console.log(file, fileList)
        this.hideUpload = fileList.length >= this.limitCount
      },
      // 分类Logo
      handleSuccess(response, file) {
        console.log(response, file)
        this.addForm.picUrl = response.data.filePath
        this.fileList.push({
          url: response.data.filePath,
        })
      },
      // 删除图片
      handleRemove(fileList) {
        this.fileList = []
        this.addForm.picUrl = ''
        this.hideUpload = fileList.length >= this.limitCount
      },
      // 图片显示
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      // 编辑
      handelEdit(row) {
        if (row) {
          this.showEdit(row)
        } else {
          this.showEdit()
        }
      },
      // 查看
      handleLook(row) {
        this.lookForm = Object.assign({}, row)
        this.lookVisible = true
      },
      // 添加编辑title
      showEdit(row) {
        this.row = row
        if (!row) {
          this.dialogTitle = '添加'
          this.hideUpload = this.fileList.length >= this.limitCount
        } else {
          this.dialogTitle = '编辑'
          this.rowId = row.id
          this.addForm.picUrl = row.picUrl
          this.fileList = []
          if (row.picUrl && row.picUrl != null) {
            this.fileList.push({
              url: this.addForm.picUrl,
            })
            this.hideUpload = this.fileList.length >= this.limitCount
          }
          this.hideUpload = this.fileList.length >= this.limitCount
          this.addForm = Object.assign({}, row)
        }
        this.addVisible = true
      },
      // 关闭
      close() {
        this.fileList = []
        this.$refs['addForm'].resetFields()
        this.addForm = this.$options.data().addForm
        this.addVisible = false
      },
      // 保存
      save() {
        console.log(this.addForm.picUrl)
        if (this.addForm.picUrl == '' || this.addForm.picUrl == null) {
          return this.ME('请上传分类LOGO')
        }
        this.$refs['addForm'].validate((valid) => {
          if (valid) {
            const parms = {
              articleTitle: this.addForm.articleTitle,
              categoryId: this.addForm.categoryId,
              authorName: this.addForm.authorName,
              freqNun: this.addForm.freqNun,
              articleMinutes: this.addForm.articleMinutes,
              articleIntroduction: this.addForm.articleIntroduction,
              picUrl: this.addForm.picUrl,
              articleType: 1,
              enable: 1,
              articleContent: this.addForm.articleContent,
            }
            if (!this.row) {
              addObj(parms)
                .then(() => {
                  this.MS('新增成功')
                  this.fetchData()
                  this.close()
                })
                .catch(() => {})
            } else {
              editObj({ ...parms, id: this.rowId })
                .then(() => {
                  this.MS('修改成功')
                  this.fetchData()
                  this.close()
                })
                .catch(() => {})
            }
          }
        })
      },
      // 列表
      async fetchDatas(options) {
        this.listLoading = true
        await queryArticlecategory({
          ...options,
          ...this.forms,
        })
          .then((res) => {
            res.data.records.forEach((element) => {
              this.options.push({ value: element.id, label: element.name })
            })
            console.log(this.options)
            console.log(res.data.records)
            // this.options=res.data
          })
          .catch(() => {})
        this.listLoading = false
      },
      // 列表
      async fetchData(options) {
        this.listLoading = true
        await queryPage({
          ...options,
          ...this.forms,
          // current: this.current,
          // size: this.size,
          // colStatus: this.queryForm.colStatus,
          // articleTitle: this.queryForm.articleTitle,
        })
          .then((res) => {
            if (res.data) {
              this.tableData = res.data.records
              this.total = res.data.total
            }
          })
          .catch(() => {})
        this.listLoading = false
      },

      // 禁用开启
      handleDisable(row) {
        this.$confirm('是否要更新此条数据的状态?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            disableColumn({ id: row.id, colStatus: row.colStatus == 1 ? 0 : 1 })
              .then(() => {
                this.MS('成功')
                this.fetchData()
              })
              .catch(() => {})
          })
          .catch(() => {})
      },
      // 删除
      handelDelete(row) {
        this.$confirm('是否要删除此行数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            delObj({ id: row.id })
              .then(() => {
                this.MS('删除成功')
                this.fetchData()
              })
              .catch(() => {})
          })
          .catch(() => {})
      },
      // 重置
      handleReset() {
        this.forms.current = 1
        this.forms.size = 10
        this.queryForm = {}
        this.handleQuery()
      },
      // 搜索
      async handleQuery() {
        this.forms.current = 1
        await this.fetchData({ ...this.queryForm }, false)
        let temp = false
        Object.keys(this.queryForm).forEach((key) => {
          this.queryForm[key] && (temp = true)
        })
        this.isShow = temp
      },
      // 分页
      handleSizeChange(val) {
        this.forms.size = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
      // 分页
      handleCurrentChange(val) {
        this.forms.current = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .authorizePoles {
    ::v-deep(.el-transfer-panel) {
      width: 40%;
    }
  }

  ::v-deep(.hide .el-upload--picture-card) {
    display: none;
  }
  .filterItems {
    width: 150px;
  }
</style>
