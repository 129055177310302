import request from '@/utils/request'

// 删除接口
export function deleteCompany(data) {
  return request({
    url: '/upms/admin/comSysComputer/delete',
    method: 'post',
    data,
  })
}

// 增加公司
export function addCompany(data) {
  return request({
    url: '/upms/admin/comSysComputer/addCompany',
    method: 'post',
    data,
  })
}

// 修改公司信息
export function updateCompany(data) {
  return request({
    url: '/upms/admin/comSysComputer/update',
    method: 'post',
    data,
  })
}

// 公司列表
export function getCompany() {
  return request({
    url: '/upms/admin/comSysComputer/computerList',
    method: 'get',
  })
}

// 分页查询
export function getpageCompany() {
  return request({
    url: '/upms/admin/comSysComputer/page',
    method: 'get',
  })
}

// 根据ID查询
export function getComSysComputer() {
  return request({
    url: '/upms/admin/comSysComputer/getComSysComputer',
    method: 'get',
  })
}

// 员工添加到公司接口
export function addCompany_user(data) {
  return request({
    url: '/upms/admin/comSysComputer/addCompany_user',
    method: 'post',
    data,
  })
}

// 员工修该公司接口
export function updateCompany_user(data) {
  return request({
    url: '/upms/admin/comSysComputer/updateCompany_user',
    method: 'post',
    data,
  })
}

// 员工删除公司接口
export function deleteCompany_user(data) {
  return request({
    url: '/upms/admin/comSysComputer/deleteCompany_user',
    method: 'post',
    data,
  })
}

// 员工所属公司查询接口
export function selectUserCompany() {
  return request({
    url: '/upms/admin/comSysComputer/selectUserCompany',
    method: 'get',
  })
}
