<template>
  <div class="cy-container">
    <!-- 查询 -->
    <el-form ref="queryForm" :inline="true" :model="queryForm" @submit.native.prevent>
      <el-form-item label="店铺" prop="shopId">
        <el-select v-model="queryForm.shopId" class="filterItems" clearable placeholder="请选择店铺">
          <el-option v-for="item in colStatusList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="手机号码" prop="mobile">
        <el-input v-model="queryForm.mobile" class="filterItems" clearable placeholder="请输入手机号码" />
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" native-type="submit" type="warning" @click="search">搜索</el-button>
        <el-button icon="el-icon-refresh-left" native-type="submit" type="primary" @click="$refs.queryForm.resetFields()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button icon="el-icon-plus" style="margin-bottom: 20px" type="primary" @click="addTable">新增</el-button>
    <el-table ref="singleTable" border :data="tableData" style="width: 100%">
      <el-table-column align="center" fixed="left" label="序号" :show-overflow-tooltip="true" type="index" width="50" />
      <!-- <el-table-column align="center" label="昵称" property="username" :show-overflow-tooltip="true" width="120" /> -->
      <el-table-column align="center" label="账号" property="username" :show-overflow-tooltip="true" />
      <el-table-column align="center" label="姓名" property="realName" :show-overflow-tooltip="true" />
      <!-- <el-table-column align="center" label="店铺" property="" :show-overflow-tooltip="true" width="120" /> -->
      <el-table-column align="center" label="手机号" property="phone" :show-overflow-tooltip="true" />
      <el-table-column align="center" label="邮箱" property="email" :show-overflow-tooltip="true" />
      <el-table-column align="center" label="部门" property="orgIdCn" :show-overflow-tooltip="true" />
      <el-table-column align="center" label="职位" property="postName" :show-overflow-tooltip="true" />
      <el-table-column align="center" label="状态" prop="status" show-overflow-tooltip>
        <template #default="{ row }">
          <el-tag :type="row.status === 2 ? 'danger' : 'success'">
            {{ row.status === 2 ? '禁用' : '正常' }}
          </el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="头像" property="address">
        <template #default="scope">
          <el-image :src="scope.row.avatar" style="width: 50px; height: 50px" />
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="创建时间" property="createTime" :show-overflow-tooltip="true" />
      <el-table-column align="center" fixed="right" label="操作" property="address">
        <template #default="scope">
          <el-link style="margin: 0 5px" type="primary" :underline="false" @click="showtb(scope.row)">查看</el-link>
          <el-link style="margin: 0 5px" type="primary" :underline="false" @click="editrow(scope.row)">编辑</el-link>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link" style="margin: 0 5px; color: #1890ff; cursor: pointer">更多</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-link style="margin: 0 5px" type="primary" :underline="false" @click="settings(scope.row)">设置拨号</el-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-link style="margin: 0 5px" type="primary" :underline="false" @click="editpswd(scope.row)">修改密码</el-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-link style="margin: 0 5px" type="primary" :underline="false" @click="disabledRow(scope.row.id)">禁用</el-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-link style="margin: 0 5px" type="primary" :underline="false" @click="deltrow(scope.row.id)">删除</el-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <showdialog :datrow="darows" :dishide="hide" @closedia="closedia" />
    <shopdialog :showadd="addhide" @hideadd="hidead" />
    <editdialog :edithid="edihid" :editrow="edidate" @closedit="closeedt" />
    <el-dialog :before-close="handleClose" :title="edidle" :visible.sync="editpass" width="40%">
      <span>
        <el-form label-width="80px" :model="fomrPasd" :rules="rules">
          <el-form-item label="新密码：" prop="password">
            <el-input v-model="fomrPasd.password" placeholder="请输入新密码" />
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editpass = false">取 消</el-button>
        <el-button type="primary" @click="subedit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :before-close="handleClose" title="设置坐席工号" :visible.sync="profi" width="40%">
      <span>
        <el-form label-width="80px" :model="fomrPasd" :rules="rules">
          <el-form-item label="坐席号码：" prop="profile">
            <el-input v-model="fomrPasd.profile" placeholder="请输入坐席号码" />
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="profi = false">取 消</el-button>
        <el-button type="primary" @click="subpro">确 定</el-button>
      </span>
    </el-dialog>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
import { ddeleteSysUser, retrieveUserCxcc, disabledSysUser, sysdeleteUser } from '@/api/mall/users/user'
import { resetPwd } from '@/api/mall/role/userManage'
import { queryShopUserPage } from '@/api/mall/shop/info'
import showdialog from './components/showdialog'
import shopdialog from './components/shopdialog'
import editdialog from './components/editdialog'
export default {
  name: 'ArtColumn',
  components: {
    showdialog,
    shopdialog,
    editdialog,
  },
  data() {
    return {
      queryForm: {},
      colStatusList: '',
      tableData: [],
      form: {
        size: 10,
        current: 1,
      },
      total: 0,
      hide: false,
      darows: {},
      addhide: false,
      editpass: false,
      edidle: '',
      fomrPasd: {},
      rules: {
        password: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
      },
      edihid: false,
      profi: false,
      edidate: {},
    }
  },
  created() {
    this.queryPage(this.form.size, this.form.current)
    // queryShopUserPage().then((res) => {
    //   this.colStatusList = res.data
    // })
  },
  methods: {
    queryPage(size, cur) {
      const lst = {
        size: size,
        current: cur,
        type: 2,
        notifyTodo: 1, //2:服务商 3:专家
      }
      queryShopUserPage({ ...lst }).then((res) => {
        this.tableData = res.data.records
        this.form.size = res.data.size
        this.form.current = res.data.current
        this.total = res.data.total
      })
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.queryPage(this.form.size, this.form.current)
      console.log(this.form.size, this.form.current)
    },
    handleSizeChange(e) {
      this.form.size = e
      this.queryPage(this.form.size, this.form.current)
    },
    showtb(row) {
      this.hide = true
      this.darows = row
    },
    closedia(bola) {
      this.hide = bola
      this.queryPage(this.form.size, this.form.current)
    },
    addTable() {
      this.addhide = true
    },
    hidead(e) {
      this.addhide = e
      this.queryPage(this.form.size, this.form.current)
    },
    //删除
    deltrow(id) {
      console.log(id)
      ddeleteSysUser({ id: id, status: 3 }).then(() => {
        this.$message.success('删除成功')
        this.queryPage(this.form.size, this.form.current)
      })
      //
    },
    sysdeleteUser() {
      sysdeleteUser({ sysUserId: '1674327297003958274,1674327297003958274' }).then(() => {
        this.$message.success('删除成功')
        this.queryPage(this.form.size, this.form.current)
      })
    },
    //禁用
    disabledRow(id) {
      disabledSysUser({ id: id, status: 2 }).then(() => {
        this.$message.success('禁用成功')
        this.queryPage(this.form.size, this.form.current)
      })
    },
    editpswd(row) {
      this.editpass = true
      this.edidle = '修改用户【' + row.nickName + '】密码'
      this.fomrPasd.id = row.id
    },
    settings(e) {
      this.profi = true
      this.fomrPasd.id = e.id
      console.log(e)
    },
    handleClose() {
      this.editpass = false
      this.profi = false
      this.fomrPasd = {}
    },
    subpro(row) {
      console.log(this.fomrPasd, row.id)
      retrieveUserCxcc({ ...this.fomrPasd }).then((res) => {
        if (res.status == 0 || res.status == 200) {
          this.$message.success('设置成功')
          this.handleClose()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    subedit() {
      resetPwd({ ...this.fomrPasd }).then(() => {
        this.$message.success('修改成功')
        this.editpass = false
        this.fomrPasd = {}
      })
    },
    editrow(row) {
      this.edihid = true
      this.edidate = row
    },
    closeedt(e) {
      this.edihid = e
      this.queryPage(this.form.size, this.form.current)
    },
    search() {
      if (this.queryPage != '') {
        const lst = {
          size: this.form.size,
          current: this.form.current,
          type: 2,
          mobile: this.queryForm.mobile,
          shopId: this.queryForm.shopId,
        }
        queryShopUserPage({ ...lst }).then((res) => {
          this.tableData = res.data.records
          this.form.size = res.data.size
          this.form.current = res.data.current
          this.total = res.data.total
        })
      } else {
        this.queryPage(this.form.size, this.form.current)
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
