<template>
  <div style="height: 79vh; padding: 10px">
    <div style="display: flex; flex-wrap: wrap">
      <div class="title-item">
        <span>活动名称</span>
        <el-input
          v-model.trim="formInline.actName"
          class="title-input"
          placeholder="请输入活动名称"
        />
      </div>
      <div class="title-item">
        <span>活动类型</span>
        <el-select
          v-model.trim="formInline.actType"
          class="title-input"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in actType"
            :key="index"
            :label="item.dictValue"
            :value="item.dictKey"
          />
        </el-select>
      </div>
      <div class="title-item">
        <span>活动时间</span>
        <el-date-picker
          v-model="datePicker"
          align="center"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="dateChange"
        />
      </div>

      <div class="title-item">
        <span>参与平台</span>
        <el-select
          v-model.trim="formInline.actPlatform"
          class="title-input"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in plateForm"
            :key="index"
            :label="item.dictValue"
            :value="item.dictKey"
          />
        </el-select>
      </div>
      <div class="title-item">
        <span>活动状态</span>
        <el-select
          v-model.trim="formInline.actStatus"
          class="title-input"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in actStatus"
            :key="index"
            :label="item.dictValue"
            :value="item.dictKey"
          />
        </el-select>
      </div>

      <div class="title-item">
        <el-button icon="el-icon-search" type="warning" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          type="primary"
          @click="handleReset"
        >
          重置
        </el-button>
      </div>
    </div>
    <div style="margin: 20px 0">
      <div v-if="btnRolrs[1] && btnRolrs[1].fixedBarButtons">
        <el-button
          v-for="item in btnRolrs[1].fixedBarButtons"
          v-show="item.buttonStatus == 0"
          :key="item.id"
          :icon="item.buttonIcon"
          :type="item.buttonCss"
          @click="handleClickBtn(item.buttonUrl)"
        >
          {{ item.buttonName }}
        </el-button>
      </div>
    </div>
    <el-table border :data="list" :max-height="500" style="width: 100%">
      <el-table-column align="center" label="活动名称" prop="actName" />
      <el-table-column align="center" label="活动类型" prop="actType">
        <template #default="{ row }">
          <div>{{ getDicValue('actType', row.actType) }}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="参与平台" prop="actPlatform">
        <template #default="{ row }">
          <div>{{ getPlateForm(row.actPlatform) }}</div>
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="开始时间" prop="startTime" />
      <el-table-column align="center" label="结束时间" prop="endTime" />
      <el-table-column align="center" label="发布时间" prop="publishTime" />
      <el-table-column align="center" label="活动状态" prop="actStatus">
        <template #default="{ row }">
          <div>{{ getDicValue('actStatus', row.actStatus) }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="发布者" prop="publishUserName" />
      <el-table-column align="center" label="操作" width="220px">
        <template #default="{ row }">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div
              v-for="item in row.btns"
              v-show="item.isShow && item.buttonStatus == 0"
              :key="item.id"
            >
              <el-dropdown
                v-if="item.children && item.children.length"
                style="margin: 0 5px"
                @command="
                  (index) => {
                    handleClickBtn(item.buttonUrl, index, item.children, row)
                  }
                "
              >
                <el-button :type="item.buttonCss">
                  {{ item.buttonName }}
                  <vab-icon :icon="item.buttonIcon || ''" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item2 in item.children"
                    :key="item2.id"
                    :command="item2.id"
                  >
                    {{ item2.buttonName }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                v-else
                size="mini"
                style="margin: 0 5px"
                :type="item.buttonCss"
                @click="handleClickBtn(item.buttonUrl, row)"
              >
                <vab-icon :icon="item.buttonIcon || ''" />
                {{ item.buttonName }}
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.currentPage"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { selectSysDict } from '@/api/mall/common'
  import { checkButton } from '@/utils/mixins'
  import { getActivityList, closeActivity } from '@/api/activity/activity'
  export default {
    name: 'ActivityList',
    filters: {},
    mixins: [checkButton],
    data() {
      return {
        listLoading: true,
        list: [],
        datePicker: [],
        plateForm: [],
        actType: [],
        actStatus: [],
        isSearch: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        formInline: {
          actName: '',
          actType: '',
          startTime: '',
          endTime: '',
          actPlatform: '',
          actStatus: '',
        },
        total: 0,
        queryForm: {
          currentPage: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData(this.formInline)
    },
    activated() {
      this.fetchData(this.formInline)
    },
    methods: {
      getPlateForm(val) {
        let arr = []
        let result = []
        if (val) {
          arr = val.split(',')
        }
        for (let i = 0; i < arr.length; i++) {
          let plate = arr[i]
          for (let j = 0; j < this.plateForm.length; j++) {
            if (this.plateForm[j].dictKey === plate) {
              result.push(this.plateForm[j].dictValue)
            }
          }
        }
        return result.join('，')
      },
      getDicValue(key, val) {
        let str = ''
        let obj = this[key].find((item) => item.dictKey == val)
        if (obj) {
          str = obj.dictValue
        }
        return str
      },
      handleCopy(row) {
        this.$router.push({
          path: '/activity/add',
          query: {
            ...row,
            id: null,
            status: 2,
          },
        })
      },
      handleEdit(row) {
        this.$router.push({
          path: '/activity/add',
          query: {
            ...row,
            status: 1,
          },
        })
      },
      handleClose(row) {
        const { id } = row
        this.$confirm('确定要关闭此活动吗？', '提示', {
          type: 'warning',
        }).then(() => {
          closeActivity({ id }).then(() => {
            this.$message.success('关闭成功！')
            this.handleReset()
          })
        })
      },
      // 查看
      openLuckydraw(row) {
        if (row.actType == 20) {
          this.$router.push({
            path: '/activity/box-list/list',
            query: {
              id: row.id,
              endTime: row.endTime,
              startTime: row.startTime,
              allActType: row.actType,
            },
          })
        } else {
          this.$router.push({
            path: '/activity/indiana/list',
            query: {
              id: row.id,
              endTime: row.endTime,
              startTime: row.startTime,
              allActType: row.actType,
            },
          })
        }
      },
      // 新增活动
      openAddActive() {
        this.$router.push({
          path: '/activity/add',
          query: {
            status: 0,
          },
        })
      },
      handleReset() {
        this.formInline = {
          actName: '',
          actType: '',
          startTime: '',
          endTime: '',
          actPlatform: '',
          actStatus: '',
        }
        this.datePicker = []
        this.handleSearch()
      },
      async fetchData(options = {}) {
        this.listLoading = true
        const {
          data: { records: list, total },
        } = await getActivityList({ ...this.queryForm, ...options })
        this.handleAddTableButtonRole(list, {
          handleClose: (row) => row.actStatus != 4,
        })
        const dicData = await selectSysDict({
          dictTypeList: ['actPlatform', 'actType', 'activityStatus'],
        })
        this.plateForm = dicData.data[0].dictInfoVoList
        this.actType = dicData.data[1].dictInfoVoList
        this.actStatus = dicData.data[2].dictInfoVoList
        this.list = list
        this.total = total
      },
      async handleSearch() {
        this.queryForm.currentPage = 1
        await this.fetchData({ ...this.formInline }, false)
        let temp = false
        Object.keys(this.formInline).forEach((key) => {
          this.formInline[key] && (temp = true)
        })
        this.isSearch = temp
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData({ ...data }, false)
      },
      handleCurrentChange(val) {
        this.queryForm.currentPage = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData({ ...data }, false)
      },
      dateChange(date) {
        if (date && date.length) {
          this.formInline.startTime = date[0]
          this.formInline.endTime = date[1]
        } else {
          this.formInline.startTime = ''
          this.formInline.endTime = ''
        }
      },
    },
  }
</script>

<style scoped>
  .title-input {
    width: 220px;
    margin-left: 5px;
  }
  .title-item {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title-item span {
    width: 100px;
    text-align: center;
  }
</style>
