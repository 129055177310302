<template>
  <div class="cy-container quill-box">
    <el-form ref="articleForm" label-width="100px" :model="articleForm" :rules="rules" style="width: 60%">
      <el-form-item label="文章标题" prop="artTitle">
        <el-input v-model.trim="articleForm.artTitle" placeholder="请输入文章标题" />
      </el-form-item>
      <el-form-item label="文章摘要" prop="artShort">
        <el-input v-model.trim="articleForm.artShort" placeholder="请输入文章摘要" />
      </el-form-item>
      <el-form-item label="文章内容" prop="artContent" style="height: 350px">
        <quill-editor
          ref="myQuillEditor"
          v-model="articleForm.artContent"
          v-screen
          class="quilleditor"
          :options="editorOption"
          style="height: 265px"
          @blur="onEditorBlur($event)"
          @change="onEditorChange($event)"
          @focus="onEditorFocus($event)"
          @ready="onEditorReady($event)"
        />
        <!-- 图片上传 -->
        <el-upload
          ref="Quillupload"
          :action="action"
          :before-upload="bfUpload"
          class="pictureQuill"
          :file-list="fileListQuill"
          :headers="headers"
          list-type="picture-card"
          :on-success="handleSuccessQuill"
          style="display: none"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <!-- 视频上传 -->
        <el-upload
          :action="action"
          :before-upload="beforeUploadVideo"
          class="pictureQuillVideo"
          :headers="headers"
          :on-progress="uploadVideoProcess"
          :on-success="handleVideoSuccess"
          :show-file-list="false"
          style="display: none"
        >
          <video v-if="videoForm.showVideoPath != '' && !videoFlag" controls height="100" :src="videoForm.showVideoPath" width="400">您的浏览器不支持视频播放</video>
          <i v-else-if="videoForm.showVideoPath == '' && !videoFlag" class="el-icon-plus avatar-uploader-icon"></i>
          <el-progress v-if="videoFlag == true" :percentage="videoUploadPercent" style="margin-top: 7px" type="circle" />
        </el-upload>
      </el-form-item>
      <el-form-item class="artImg" label="文章封面" required>
        <div style="display: flex">
          <!-- <div>
            <el-button v-if="openDialogs" type="primary" @click="openDialog">点击上传长方形封面</el-button>
            <div v-if="openDialogshide">
              <el-upload
                ref="upload"
                :action="action"
                :before-upload="bfUpload"
                :class="{
                  hide: hideUpload,
                }"
                :file-list="fileList"
                :headers="headers"
                :limit="limitCount"
                list-type="picture-card"
                :on-change="handleChange"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog append-to-body :visible.sync="dialogVisible">
                <img alt="" :src="dialogImageUrl" width="100%" />
              </el-dialog>
            </div>
          </div> -->
          <div class="ml10">
            <div v-if="fileList2.length" style="margin-top: 10px">
              <el-upload
                ref="upload"
                :action="action"
                :before-upload="bfUpload"
                :class="{
                  hide: hideUpload2,
                }"
                :file-list="fileList2"
                :headers="headers"
                :limit="limitCount"
                list-type="picture-card"
                :on-change="handleChange2"
                :on-preview="handlePictureCardPreview2"
                :on-remove="handleRemove2"
                :on-success="handleSuccess2"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog append-to-body :visible.sync="dialogVisible2">
                <img alt="" :src="dialogImageUrl2" width="100%" />
              </el-dialog>
            </div>
            <el-button v-else type="primary" @click="openDialog2">点击上传正方形封面</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item class="artTags" label="文章标签" prop="artTags">
        <el-tag v-for="tag in artTagsList" :key="tag" closable :disable-transitions="false" @close="handleClose(tag)">
          {{ tag }}
        </el-tag>
        <el-input
          v-if="inputVisible"
          ref="saveTagInput"
          v-model.trim="articleForm.artTags"
          class="input-new-tag"
          size="small"
          @blur="handleInputConfirm"
          @keyup.enter.native="handleInputConfirm"
        />
        <el-button v-if="!artTagsList.length" class="button-new-tag" icon="el-icon-plus" size="small" @click="showInput" />
      </el-form-item>
      <el-form-item label="文章类型" prop="artType">
        <el-select v-model="articleForm.artType" multiple placeholder="请选择文章类型">
          <el-option v-for="(item, index) in articleTypelist" :key="index" :label="item.typeName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="分类专栏" prop="artColumn">
        <el-select v-model="articleForm.artColumn" multiple placeholder="请选择分类专栏" style="width: 30%">
          <el-option v-for="item in classifyList" :key="item.id" :label="item.colName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="发布形式" prop="artScope">
        <el-radio-group v-model="articleForm.artScope">
          <el-radio v-for="item in artScopeList" :key="item.dictKey" :label="item.dictKey">
            {{ item.dictValue }}
          </el-radio>
          <!-- <el-radio :label="2">翻译</el-radio>
          <el-radio :label="3">转载</el-radio> -->
        </el-radio-group>
      </el-form-item>
      <el-form-item label="附件上传" prop="name">
        <el-upload
          :action="action"
          class="upload-demo"
          :file-list="fileListHontent"
          :headers="headers"
          :limit="10"
          multiple
          :on-remove="
            (file, index) => {
              return handleRemoveHontent(file, index)
            }
          "
          :on-success="handleSuccessHontent"
        >
          <el-button icon="el-icon-upload2" size="small">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <!-- <el-button type="primary" @click="handleRelease">发布博文</el-button> -->
        <el-button type="primary" @click="saveDraft">保存草稿</el-button>
      </el-form-item>
    </el-form>

    <el-dialog class="cropperDialog" :close-on-click-modal="false" title="文章封面裁剪" :visible.sync="dialogVisibleImgs" width="40%" @close="close">
      <el-row>
        <el-col v-if="option.img == ''" :span="24" style="padding: 30px 0; font-size: 16px; color: red; text-align: center">请先选择图片</el-col>
        <el-col v-else :span="24">
          <div class="cropper-content">
            <div class="cropper" style="text-align: center">
              <vueCropper
                ref="cropper"
                :auto-crop="option.autoCrop"
                :can-move="option.canMove"
                :can-move-box="option.canMoveBox"
                :center-box="option.centerBox"
                :fixed="option.fixed"
                :fixed-box="option.fixedBox"
                :fixed-number="option.fixedNumber"
                :full="option.full"
                :img="option.img"
                :info="true"
                :info-true="option.infoTrue"
                :original="option.original"
                :output-size="option.size"
                :output-type="option.outputType"
              />
            </div>
          </div>
        </el-col>
      </el-row>
      <template #footer>
        <div>
          <el-button :loading="loading" type="primary" @click="upload">上传并保存</el-button>
          <el-upload accept="image/jpeg,image/gif,image/png,image/jpg" :action="action" :auto-upload="false" :headers="headers" list-type="picture-card" :on-change="onChange">
            <template #default>
              <el-button type="primary">选择图片</el-button>
            </template>
          </el-upload>
        </div>
      </template>
    </el-dialog>
    <!-- 点击上传正方形封面 -->
    <el-dialog class="cropperDialog" :close-on-click-modal="false" title="文章封面裁剪" :visible.sync="dialogVisibleImgs2" width="40%" @close="close2">
      <el-row>
        <el-col v-if="option2.img == ''" :span="24" style="padding: 30px 0; font-size: 16px; color: red; text-align: center">请先选择图片</el-col>
        <el-col v-else :span="24">
          <div class="cropper-content">
            <div class="cropper" style="text-align: center">
              <vueCropper
                ref="cropper"
                :auto-crop="option2.autoCrop"
                :can-move="option2.canMove"
                :can-move-box="option2.canMoveBox"
                :center-box="option2.centerBox"
                :fixed="option2.fixed"
                :fixed-box="option2.fixedBox"
                :fixed-number="option2.fixedNumber"
                :full="option2.full"
                :img="option2.img"
                :info="true"
                :info-true="option2.infoTrue"
                :original="option2.original"
                :output-size="option2.size"
                :output-type="option2.outputType"
              />
            </div>
          </div>
        </el-col>
      </el-row>
      <template #footer>
        <div>
          <el-button :loading="loading" type="primary" @click="upload2">上传并保存</el-button>
          <el-upload accept="image/jpeg,image/gif,image/png,image/jpg" :action="action" :auto-upload="false" :headers="headers" list-type="picture-card" :on-change="onChange2">
            <template #default>
              <el-button type="primary">选择图片</el-button>
            </template>
          </el-upload>
        </div>
      </template>
    </el-dialog>
    <!-- 秀米组件弹框 -->
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :modal-append-to-body="false" title="秀米" top="50px" :visible.sync="visible" width="90%" z-index="99999999">
      <div v-if="visible">
        <iframe id="xiumiIframe" frameborder="0" :height="fullheight - 150 + 'px'" src="./static/xiumi-ue-dialog-v5_new.html" width="100%"></iframe>
      </div>
    </el-dialog>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="135编辑器"
      top="50px"
      :visible.sync="visible2"
      width="90%"
      z-index="99999999"
    >
      <div v-if="visible2">
        <iframe id="xiumiIframe" frameborder="0" :height="fullheight - 150 + 'px'" src="./static/135EditorDialogPage.html" width="100%"></iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { queryListType, queryListColumn } from '@/api/mall/article/list'
import {
  addArticle,
  details,
  edit,
  uploadGoodsImage,
} from '@/api/mall/article/release'
import { mapGetters } from 'vuex'
import { uploadURL } from '@/config'
import { quillEditor } from 'vue-quill-editor'
import * as Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import '../../../../assets/css/quillEditor.css'
import { selectSysDict, uploadUrlImgs } from '@/api/mall/common'
import { addQuillTitle } from './components/quill-title.js'
import screen from '@/directive/screen'
// 秀米引入
import blotSelect from './components/blot.js'
blotSelect(Quill)
// 字体
var fonts = [
  'SimHei',
  'SimSun',
  'Microsoft-YaHei',
  'KaiTi',
  'FangSong',
  'Arial',
  'sans-serif',
]
var Font = Quill.import('formats/font')
Font.whitelist = fonts
Quill.register(Font, true)
// 字体大小
var sizes = [false, '14px', '16px', '18px', '20px', '22px', '24px', '26px']
var Size = Quill.import('formats/size')
Size.whitelist = sizes
// 行高
import { lineHeightStyle } from './components/lineHeight.js'
Quill.register({ 'formats/lineHeight': lineHeightStyle }, true)

// 行间距
import { letterSpacingStyle } from './components/letterSpacing.js'
Quill.register({ 'formats/letterSpacing': letterSpacingStyle }, true)
// 链接
var Link = Quill.import('formats/link')
class MyLink extends Link {
  static create(value) {
    let node = super.create(value)
    value = this.sanitize(value)
    if (!value.startsWith('http')) {
      value = 'http://' + value
    }
    node.setAttribute('href', value)
    return node
  }
}
Quill.register(MyLink)
// 视频
import Video from './components/video.js'
Quill.register(Video, true)
// 工具栏配置
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  // [{ header: '1' }, { header: '2' }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
  ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
  [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ script: 'sub' }, { script: 'super' }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  [{ align: [] }], // 对齐方式-----[{ align: [] }]
  [{ indent: '-1' }, { indent: '+1' }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [{ direction: 'rtl' }], // 文本方向-----[{'direction': 'rtl'}]
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{ size: sizes }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  [{ header: [false, 1, 2, 3, 4, 5, 6] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
  [{ font: fonts }], // 字体种类-----[{ font: [] }]
  [{ lineheight: ['initial', '1', '1.5', '1.75', '2', '3', '4', '5'] }], //行高
  [
    {
      letterSpacing: [
        'initial',
        '2px',
        '4px',
        '6px',
        '8px',
        '10px',
        '12px',
        '14px',
        '16px',
      ],
    },
  ], //行间距
  ['link', 'image', 'video'], // 链接、图片、视频-----['link', 'image', 'video']
  ['clean'], // 清除文本格式-----['clean']
  ['insertMetric'], //秀米
  ['otEdit'], //135编辑器
]
import { VueCropper } from 'vue-cropper'
export default {
  components: {
    quillEditor,
    VueCropper,
  },
  directives: { screen },
  data() {
    return {
      floag: true,
      msg: undefined,
      imgFile: undefined,
      visible: false,
      visible2: false,
      selection: {}, // 光标位置
      fullheight: document.documentElement.clientHeight, // 给quill容器设置了个高度
      quill: null, // 待初始化的编辑器
      hideUpload2: false,
      dialogImageUrl2: '',
      dialogVisible2: false,
      dialogVisibleImgs2: false,
      openDialogshide2: false,
      openDialogs2: true,
      fileList2: [],
      option2: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        canMove: true,
      },
      // 上传长方形封面
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 460, // 默认生成截图框宽度
        autoCropHeight: 240, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [23, 12], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        canMove: true,
        enlarge: 1,
      },
      openDialogshide: false,
      openDialogs: true,
      loading: false,
      dialogVisibleImgs: false,
      hideUpload: false,
      limitCount: 1,
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      headers: {},
      action: uploadURL,
      value: '',
      slide: {
        oriUrl: '',
        preUrl: '',
      },
      timer: null,
      fileListQuillVideo: [],
      fileListQuill: [],
      dialogVideoVisible: false,
      fileListHontent: [],
      artScopeList: [],
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions, //自定义工具栏
            handlers: {
              that: this,
              letterSpacing: function (value) {
                if (value) {
                  this.quill.format('letterSpacing', value)
                }
              },
              lineheight: function (value) {
                if (value) {
                  this.quill.format('lineHeight', value)
                }
              },
              image: function (value) {
                if (value) {
                  document.querySelector('.pictureQuill input').click()
                } else {
                  this.quill.format('image', false)
                }
              },
              video: function (value) {
                if (value) {
                  document.querySelector('.pictureQuillVideo input').click()
                } else {
                  this.quill.format('video', false)
                }
              },
              // 秀米
              insertMetric: function () {
                let self = this.handlers.that
                self.visible = true
              },
              // 135编辑器
              otEdit: function () {
                let self = this.handlers.that
                self.visible2 = true
              },
            },
          },
        },
        //主题
        theme: 'snow',
        placeholder: '请输入正文',
      },
      inputVisible: false,
      artTagsList: [],
      artTag: '',
      articleForm: {
        artTags: '',
        artScope: '1',
        artContent: '',
        artImg: '',
        artLogo: '',
        artColumn: [],
        artType: [],
        artHontent: '',
      },
      rules: {
        artTitle: [
          {
            required: true,
            message: '请输入文章标题',
            trigger: ['blur', 'change'],
          },
        ],
        artType: [
          {
            required: true,
            message: '请选择文章类型',
            trigger: ['blur', 'change'],
          },
        ],
        artColumn: [
          {
            required: true,
            message: '请选择专栏分类',
            trigger: ['blur', 'change'],
          },
        ],
        artScope: [
          {
            required: true,
            message: '请选择发布形式',
            trigger: ['blur', 'change'],
          },
        ],
        artTags: [
          {
            required: true,
            message: '请输入文章标签',
            trigger: ['blur', 'change'],
          },
        ],
      },
      classifyList: [],
      articleTypelist: [],
      fileVoList: [],
      //视频
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: '',
      //进度条的进度，
      isShowUploadVideo: false,
      //显示上传按钮
      videoForm: {
        showVideoPath: '',
      },
      base64Datas: [],
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/token',
    }),
    // editor() {
    //   return this.$refs.myQuillEditor.quill
    // },
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.articleForm.artContent = newVal
      } else if (!newVal) {
        this.articleForm.artContent = ''
      }
    },
  },

  created() {
    this.articleForm.artContent = this.value
    this.headers['Authorization'] = `Bearer ${this.token}`
    this.queryListColumn()
    this.selectSysDict()
    this.queryListType()
  },
  mounted() {
    this._initEditor()
    this.initButton()
    // 富文本工具栏提示
    addQuillTitle()
    if (this.$route.query.id) {
      this.details()
    }
    // 暴露方法绑定到window上，给public\pluging\xiumi-ue-dialog-v5.html使用
    window.setRichText_xm = this.setRichText_xm
    window.setRichText_135 = this.setRichText_135
    // 调用135页面的时候 带入数据 getHtml()
    window.getHtml = this.getHtml
  },
  methods: {
    onEditorBlur(e) {
      console.log(e)
    },
    onEditorFocus(e) {
      console.log(e)
      this.floag = true
    },
    onEditorChange(e) {
      console.log(e)
    },
    onEditorReady(e) {
      console.log(e)
    },
    // 初始化编辑器
    _initEditor() {
      // 初始化编辑器
      this.quill = this.$refs.myQuillEditor.quill
      // 监听光标位置
      this.quill.on('selection-change', (range) => {
        this.selection = this.quill.getSelection()
        if (!range) {
          this.$emit('blur', this.quill)
        } else {
          this.$emit('focus', this.quill)
        }
      })
      // 双向绑定代码 v-model
      this.quill.on('text-change', () => {
        this.emitChange()
        this.selection = this.quill.getSelection()
      })
      // 插入内容
      this.firstSetHtml()
      // 粘贴板监听
      this.listenPaste()
      this.$emit('ready', this.quill)
    },
    setRichText_xm(e) {
      const index = this.selection ? this.selection.index : 0
      // console.log('光标位置',index)
      this.quill.insertEmbed(index || 0, 'AppPanelEmbed', e)
      this.visible = false
    },
    setRichText_135(e) {
      const index = this.selection ? this.selection.index : 0
      //这个主要是用来处理在135编辑器添加导出到quill再点击135编辑器返回到quill的重复内容
      this.quill.setContents([
        { insert: '', attributes: { bold: true } },
        { insert: '\n' },
      ])
      this.quill.insertEmbed(index || 0, 'AppPanelEmbed', e)
      this.visible2 = false
    },
    emitChange() {
      var that = this
      var imageArr = []
      // 获取到quill 根dom中的html
      let htmls = this.articleForm.artContent
      const quill = this.quill
      const text = this.quill.getText()
      that.msg = htmls
      // //匹配图片
      var imgReg = /<img.*?(?:>|\/>)/gi // eslint-disable-line
      // //匹配src属性
      var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // eslint-disable-line
      if (that.msg) {
        if (
          that.msg.indexOf('xiumi.us') > -1 ||
          that.msg.indexOf('_135editor') > -1
        ) {
          that.msg.replace(imgReg, function (txt) {
            return txt.replace(srcReg, function (src) {
              var img_src = src.match(srcReg)[1]
              //正则把?x-oss-process后面的都去掉
              img_src = img_src.replace(/\?.*/i, '')
              imageArr.push(img_src)
            })
          })

          const parmas = {
            urlList: imageArr,
          }
          if (!that.floag) {
            return
          }
          if (imageArr.length != 0) {
            //    如果有图片则 请求接口上传图片
            uploadUrlImgs(parmas)
              .then((res) => {
                if (res.data && res.data.length) {
                  var index = 0
                  that.floag = false
                  while (index < res.data.length) {
                    //接口返回图片根据index替换
                    that.msg = that.msg.replace(
                      imageArr[index],
                      res.data[index]
                    )
                    index++
                    that.$emit('change', that.msg)
                    // 富文本
                    const value = new DOMParser().parseFromString(
                      that.msg,
                      'text/html'
                    ).body.childNodes // 获取nodes
                    // window.event.preventDefault() // 阻止
                    // window.event.stopPropagation() //冒泡
                    that.nodesInQuill(value) // 根据不同标签使用不同的插入方法
                    let html = that.msg
                    if (html === '<p><br></p>') html = ''
                    that.$emit('input', html)
                    that.$emit('change', { html, text, quill })
                    // 返回quill中文本长度(无法计算秀米代码的中的文字长度)
                    that.$emit('getConetntLength', that.quill.getLength())
                  }
                }
              })
              .catch(() => { })
          } else {
            // 富文本
            const value = new DOMParser().parseFromString(
              that.msg,
              'text/html'
            ).body.childNodes // 获取nodes

            // window.event.preventDefault() // 阻止
            // window.event.stopPropagation()
            that.nodesInQuill(value) // 根据不同标签，使用不同的插入方法
            let html = that.msg
            if (html === '<p><br></p>') html = ''
            that.$emit('input', html)
            that.$emit('change', { html, text, quill })
            // 返回quill中文本长度(无法计算秀米代码的中的文字长度)
            that.$emit('getConetntLength', that.quill.getLength())
          }
        }
      }
      // setTimeout(() => {
      //   that.floag = true
      // }, 5000)

      // // 获取到quill 根dom中的html
      // let html = this.articleForm.artContent
      // const quill = this.quill
      // const text = this.quill.getText()
      // console.log(html, 'htmlhtmlhtmlhtmlhtmlhtmlhtml')
      // if (html === '<p><br></p>') html = ''
      // // v-model相关
      // this.$emit('input', html)
      // this.$emit('change', { html, text, quill })
      // // 返回quill中文本长度
      // // bug注意：这个方法无法计算秀米代码的中的文字长度！
      // this.$emit('getConetntLength', this.quill.getLength())
    },
    // 回显内容时检查秀米代码
    firstSetHtml() {
      // value 为回显内容
      if (this.value) {
        // 判断是否有秀米和或135元素
        if (
          this.value.indexOf('xiumi.us') > -1 ||
          this.value.indexOf('135editor.com') > -1
        ) {
          const originNode = new DOMParser().parseFromString(
            this.value,
            'text/html'
          ).body.childNodes
          this.nodesInQuill(originNode)
        } else {
          // 正常插入
          this.quill.clipboard.dangerouslyPasteHTML(this.value)
        }
      }
    },
    // 根据node类型分发处理
    nodesInQuill(originNode) {
      for (let i = originNode.length - 1; i >= 0; i--) {
        if (originNode[i].localName === 'section') {
          // 秀米类型代码，走新blot
          this.setRichText_xm(originNode[i].outerHTML, 0)
          this.setRichText_135(originNode[i].outerHTML, 0)
        } else {
          // 正常插入
          this.quill.clipboard.dangerouslyPasteHTML(
            0,
            originNode[i].outerHTML
          )
        }
      }
    },
    // 监听粘贴板
    listenPaste() {
      // var that = this
      // var imageArr = []
      // this.quill.root.addEventListener('paste', (e) => {
      //   that.msg = (e.clipboardData || window.clipboardData).getData(
      //     'text/html'
      //   )
      //   // //匹配图片
      //   var imgReg = /<img.*?(?:>|\/>)/gi // eslint-disable-line
      //   // //匹配src属性
      //   var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // eslint-disable-line
      //   if (that.msg) {
      //     if (
      //       that.msg.indexOf('xiumi.us') > -1 ||
      //       that.msg.indexOf('_135editor') > -1
      //     ) {
      //       that.msg.replace(imgReg, function (txt) {
      //         return txt.replace(srcReg, function (src) {
      //           var img_src = src.match(srcReg)[1]
      //           //正则把?x-oss-process后面的都去掉
      //           img_src = img_src.replace(/\?.*/i, '')
      //           imageArr.push(img_src)
      //         })
      //       })

      //       const parmas = {
      //         urlList: imageArr,
      //       }
      //       if (imageArr.length != 0) {
      //         //    如果有图片则 请求接口上传图片
      //         uploadUrlImgs(parmas)
      //           .then((res) => {
      //             if (res.data && res.data.length) {
      //               var index = 0
      //               while (index < res.data.length) {
      //                 //接口返回图片根据index替换
      //                 that.msg = that.msg.replace(
      //                   imageArr[index],
      //                   res.data[index]
      //                 )
      //                 index++
      //                 that.$emit('change', that.msg)
      //                 // 富文本
      //                 const value = new DOMParser().parseFromString(
      //                   that.msg,
      //                   'text/html'
      //                 ).body.childNodes // 获取nodes
      //                 e.preventDefault() // 阻止复制动作
      //                 e.stopPropagation() // 阻止冒泡
      //                 that.nodesInQuill(value) // 根据不同标签，使用不同的插入方法
      //               }
      //             } else {
      //               // 富文本
      //               const value1 = new DOMParser().parseFromString(
      //                 that.msg,
      //                 'text/html'
      //               ).body.childNodes // 获取nodes
      //               e.preventDefault() // 阻止复制动作
      //               e.stopPropagation() // 阻止冒泡
      //               that.nodesInQuill(value1) // 根据不同标签，使用不同的插入方法
      //             }
      //           })
      //           .catch(() => {})
      //       } else {
      //         // 富文本
      //         const value1 = new DOMParser().parseFromString(
      //           that.msg,
      //           'text/html'
      //         ).body.childNodes // 获取nodes
      //         e.preventDefault() // 阻止复制动作
      //         e.stopPropagation() // 阻止冒泡
      //         that.nodesInQuill(value1) // 根据不同标签，使用不同的插入方法
      //       }
      //     }
      //   }
      // })
      this.quill.root.addEventListener('paste', (e) => {
        let msg = (e.clipboardData || window.clipboardData).getData(
          'text/html'
        ) // 获取粘贴板文本
        if (msg) {
          if (
            msg.indexOf('xiumi.us') > -1 ||
            msg.indexOf('_135editor') > -1
          ) {
            let value = new DOMParser().parseFromString(msg, 'text/html').body
              .childNodes // 获取nodes
            e.preventDefault() // 阻止复制动作
            e.stopPropagation() // 阻止冒泡
            this.nodesInQuill(value) // 根据不同标签，使用不同的插入方法
          }
        }
      })
    },

    //  自定义图标（秀米，135）
    initButton() {
      const sourceEditorButton = document.querySelector('.ql-insertMetric')
      sourceEditorButton.setAttribute('id', 'custom-button-xiumi')
      const sourceEditorButtonotEdit = document.querySelector('.ql-otEdit')
      sourceEditorButtonotEdit.setAttribute('id', 'custom-button-135')
    },

    // 获取html内容
    getHtml() {
      return this.articleForm.artContent
    },

    //将base64转换为file文件流
    base64toFile(dataurl, filename = 'file') {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let suffix = mime.split('/')[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      })
    },

    // 选择图片
    onChange(file, fileList) {
      console.log(file, fileList)
      this.$nextTick(() => {
        this.option.img = URL.createObjectURL(file.raw)
      })
    },
    // 关闭弹框
    close() {
      this.dialogVisibleImgs = false
      this.option.img = ''
    },
    // 上传保存
    upload() {
      if (!this.option.img && this.option.img == '')
        return this.ME('请先选择图片')
      this.loading = true
      this.$refs.cropper.getCropData((data) => {
        const optionImg = this.base64toFile(data)
        console.log(optionImg)
        // this.option.img = this.base64toFile(data)
        const formData = new FormData()
        formData.append('file', optionImg)
        console.log(formData)
        //请求
        uploadGoodsImage(formData)
          .then((res) => {
            this.MS('上传成功')
            this.articleForm.artImg = res.data.filePath
            this.fileList.push({
              url: res.data.filePath,
            })
            this.hideUpload = this.fileList.length >= this.limitCount
            this.openDialogs = false
            this.openDialogshide = true
            this.loading = false

            this.close()
          })
          .catch(() => {
            this.loading = false
          })
      })
    },
    // 点击上传封面
    openDialog() {
      this.dialogVisibleImgs = true
    },
    // 点击上传（正方形）
    openDialog2() {
      this.dialogVisibleImgs2 = true
    },

    // 选择图片（正方形）
    onChange2(file, fileList) {
      console.log(file, fileList)
      this.$nextTick(() => {
        this.option2.img = URL.createObjectURL(file.raw)
      })
    },
    // 关闭弹框（正方形）
    close2() {
      this.dialogVisibleImgs2 = false
      this.option2.img = ''
    },
    // 上传保存（正方形）
    upload2() {
      if (!this.option2.img && this.option2.img == '')
        return this.ME('请先选择图片')
      this.loading = true
      this.$refs.cropper.getCropData((data) => {
        // this.option2.img = this.base64toFile(data)
        const optionImg = this.base64toFile(data)
        const formData = new FormData()
        formData.append('file', optionImg)
        //请求
        uploadGoodsImage(formData)
          .then((res) => {
            this.MS('上传成功')

            this.articleForm.artLogo = res.data.filePath
            this.articleForm.artImg = res.data.filePath
            this.fileList2.push({
              url: res.data.filePath,
            })
            this.hideUpload2 = this.fileList2.length >= this.limitCount
            this.openDialogs2 = false
            this.openDialogshide2 = true
            this.loading = false
            this.close2()
          })
          .catch(() => {
            this.loading = false
          })
      })
    },
    // 富文本框上传图片回掉
    bfUpload(file) {
      if (
        'image/png' == file.type ||
        'image/jpeg' == file.type ||
        'image/jpg' == file.type
      ) {
        console.log()
      } else {
        this.ME('图片上传失败,请上传png,jpeg,jpg格式')
        return false
      }
    },
    // 富文本框上传图片
    handleSuccessQuill(response, file) {
      console.log(response, file)
      this.fileListQuill.push({ url: response.data.filePath })
      let quill = this.$refs.myQuillEditor.quill
      if (response.data.filePath != null) {
        let length = quill.getSelection().index
        quill.insertEmbed(length, 'image', response.data.filePath)
        quill.setSelection(length + 1)
      }
    },
    //富文本上传视频回调
    beforeUploadVideo(file) {
      var fileSize = file.size / 1024 / 1024 < 50
      if (
        [
          'video/mp4',
          'video/ogg',
          'video/flv',
          'video/avi',
          'video/wmv',
          'video/rmvb',
          'video/mov',
        ].indexOf(file.type) == -1
      ) {
        this.$message.error('请上传正确的视频格式')
        return false
      }
      if (!fileSize) {
        this.$message.error('视频大小不能超过50MB')
        return false
      }
      this.isShowUploadVideo = false
    },
    //进度条
    uploadVideoProcess(event, file, fileList) {
      console.log(fileList)
      this.videoFlag = true
      this.videoUploadPercent = file.percentage.toFixed(0) * 1
    },
    //富文本上传视频
    handleVideoSuccess(res, file) {
      console.log(res, file)
      this.isShowUploadVideo = true
      this.videoFlag = false
      this.videoUploadPercent = 0
      let quill = this.$refs.myQuillEditor.quill
      this.videoForm.showVideoPath = res.data.filePath
      if (res.data.filePath != null) {
        let length = quill.getSelection().index
        quill.insertEmbed(length, 'video', res.data.filePath)
        quill.setSelection(length + 1)
      }
    },
    // 发布
    handleRelease() {
      this.$refs['articleForm'].validate((valid) => {
        if (valid) {
          if (!this.articleForm.artContent) {
            this.ME('请输入文章内容')
            return false
          }
          // if (!this.articleForm.artLogo) return this.ME('请上传正方形封面')
          if (!this.articleForm.artImg) return this.ME('请上传长方形封面')
          const pamars = {
            artTitle: this.articleForm.artTitle,
            artShort: this.articleForm.artShort,
            artContent: this.articleForm.artContent, //内容
            artTags: this.artTagsList.toString(), //标签
            artColumn: this.articleForm.artColumn.toString(),
            artType: this.articleForm.artType.toString(),
            artScope: this.articleForm.artScope, //发布形式
            artImg: this.articleForm.artImg, //封面长方形
            artLogo: this.articleForm.artLogo, //封面正方形
            artFlag: this.articleForm.artFlag,
            artStatus: 1,
            fileVoList: this.fileVoList, //附件
          }
          if (this.$route.query.id) {
            edit({ ...pamars, id: this.$route.query.id })
              .then(() => {
                this.MS('保存成功')
              })
              .catch(() => { })
          } else {
            addArticle(pamars)
              .then(() => {
                this.MS('发布成功')
                this.$router.push({
                  path: '/artLlist',
                })
              })
              .catch(() => { })
          }
        }
      })
    },
    // 保存草稿
    saveDraft() {
      this.$refs['articleForm'].validate((valid) => {
        if (valid) {
          if (!this.articleForm.artContent) {
            this.ME('请输入文章内容')
            return false
          }
          if (!this.articleForm.artLogo) return this.ME('请上传正方形封面')
          // if (!this.articleForm.artImg) return this.ME('请上传长方形封面')

          const pamars = {
            artTitle: this.articleForm.artTitle,
            artShort: this.articleForm.artShort,
            artContent: this.articleForm.artContent, //内容
            artTags: this.artTagsList.toString(), //标签
            artColumn: this.articleForm.artColumn.toString(),
            artType: this.articleForm.artType.toString(),
            artScope: this.articleForm.artScope, //发布形式

            artImg: this.articleForm.artImg, //封面长方形
            artLogo: this.articleForm.artLogo, //封面正方形
            artFlag: this.articleForm.artFlag,
            artStatus: 2,
            fileVoList: this.fileVoList, //附件
          }
          if (this.$route.query.id) {
            edit({ ...pamars, id: this.$route.query.id })
              .then(() => {
                this.MS('保存成功')
              })
              .catch(() => { })
          } else {
            addArticle(pamars)
              .then(() => {
                this.MS('保存成功')
                this.$router.push({
                  path: '/artLlist',
                })
                this.articleForm.artContent = ''
              })
              .catch(() => { })
          }
        }
      })
    },
    // 字典
    selectSysDict() {
      selectSysDict({ dictTypeList: ['articlePublish'] })
        .then((res) => {
          if (res.data) {
            this.artScopeList = res.data[0].dictInfoVoList
          }
        })
        .catch(() => { })
    },
    // 编辑详情
    details() {
      details({ id: this.$route.query.id })
        .then((res) => {
          if (res.data) {
            // this.articleForm = res.data
            // this.artTagsList =
            //   res.data.artTags == '' ? [] : res.data.artTags.split(',')
            // this.articleForm.artScope = res.data.artScope.toString()
            // this.articleForm.artColumn = res.data.artColumn
            //   .split(',')
            //   .map(Number)
            // this.fileList = []
            // if (res.data.artImg) {
            //   this.fileList.push({
            //     url: res.data.artImg,
            //   })
            // }
            // const fileListv = res.data.fileVoList
            // for (var i = 0; i < fileListv.length; i++) {
            //   this.fileListHontent.push({
            //     name: fileListv[i].fileName,
            //     url: fileListv[i].fileUrl,
            //   })
            // }
            // this.fileVoList = []
            // this.fileVoList = fileListv
            // this.hideUpload = this.fileList.length >= this.limitCount
            // this.hideUpload2 = this.fileList2.length >= this.limitCount
          }
        })
        .catch(() => { })
    },
    // 文章类型
    queryListType() {
      queryListType()
        .then((res) => {
          if (res.data) {
            this.articleTypelist = res.data
          }
        })
        .catch(() => { })
    },
    // 专栏
    queryListColumn() {
      queryListColumn()
        .then((res) => {
          if (res.data) {
            this.classifyList = res.data
          }
        })
        .catch(() => { })
    },
    // 附件上传
    handleSuccessHontent(response, file, fileList) {
      console.log(response, file, fileList)
      this.fileVoList.push({
        fileName: response.data.fileName + '.' + response.data.fileExtension,
        fileUrl: response.data.filePath,
      })
    },
    // 附件删除
    handleRemoveHontent(file, index) {
      console.log(file, index)
      for (var i = 0; i < this.fileListHontent.length; i++) {
        if (this.fileListHontent[i].uid == file.uid) {
          this.fileListHontent.splice(i, 1)
        }
      }
      for (var j = 0; j < this.fileVoList.length; j++) {
        if (this.fileVoList[j].fileUrl == file.url) {
          this.fileVoList.splice(j, 1)
        }
      }
    },
    // 封面图片上传( 正方形)
    handleChange2(file, fileList) {
      console.log(file, fileList)
      this.hideUpload2 = fileList.length >= this.limitCount
    },
    // 封面图片上传成功( 正方形)
    handleSuccess2(response, file) {
      console.log(response, file)
      this.articleForm.artLogo = response.data.filePath
      this.fileList2.push({
        url: response.data.filePath,
      })
    },
    // 封面删除图片( 正方形)
    handleRemove2(fileList) {
      this.articleForm.artLogo = ''
      this.fileList2 = []
      this.option2.img = ''
      this.hideUpload2 = fileList.length >= this.limitCount
      this.openDialogs2 = true
      this.openDialogshide2 = false
    },
    // 封面图片显示( 正方形)
    handlePictureCardPreview2(file) {
      this.dialogImageUrl2 = file.url
      this.dialogVisible2 = true
    },
    // 封面图片上传
    handleChange(file, fileList) {
      console.log(file, fileList)
      this.hideUpload = fileList.length >= this.limitCount
    },
    //  封面图片上传成功
    handleSuccess(response, file) {
      console.log(response, file)

      this.articleForm.artImg = response.data.filePath
      this.fileList.push({
        url: response.data.filePath,
      })
    },
    // 封面删除图片
    handleRemove(fileList) {
      this.articleForm.artImg = ''
      this.fileList = []
      this.option.img = ''
      this.hideUpload = fileList.length >= this.limitCount
      this.openDialogs = true
      this.openDialogshide = false
    },
    // 封面图片显示
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    // 删除文章标签
    handleClose(tag) {
      this.artTagsList.splice(this.artTagsList.indexOf(tag), 1)
      this.articleForm.artTags = ''
    },
    // 添加文章标签
    showInput() {
      this.inputVisible = true
      this.articleForm.artTags = ''
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    //  添加文章标签
    handleInputConfirm() {
      let inputValue = this.articleForm.artTags
      if (inputValue) {
        this.artTagsList.map((item) => {
          if (inputValue == item) {
            this.ME('不能输入相同的标签')
            this.artTagsList.splice(this.artTagsList.indexOf(item), 1)
            return false
          }
        })
        this.artTagsList.push(inputValue)
      }
      this.inputVisible = false
      // this.articleForm.artTags = ''
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep(#custom-button-xiumi) {
  width: 33px;
  height: 16px;
  background-image: url('../../../../assets/img/xiumi-connect-icon.png');
  background-repeat: no-repeat;
  background-size: contain;
}
::v-deep(#custom-button-135) {
  width: 33px;
  height: 16px;
  background-image: url('../../../../assets/img/editor-135-icon.png');
  background-repeat: no-repeat;
  background-size: contain;
}
::v-deep() {
  @media only screen and (max-width: 1280px) {
    .quilleditor {
      height: 240px !important;
    }
  }
  @media only screen and (max-width: 900px) {
    .quilleditor {
      height: 240px !important;
    }
    .ql-container {
      height: 85%;
    }
  }
}
// 截图
::v-deep(.cropper-content) {
  .cropper {
    width: auto;
    height: 400px;
  }
}
// ::v-deep(.cropperDialog ){
//   .cropper-content {
//     width: 500px;
//     height: 500px;
//     // background: pink;
//   }
//   .cropper {
//     width: 500px;
//     height: 500px;
//     // background: yellow;
//   }
// }
::v-deep(.vab-cropper-canvas) {
  display: block;
  float: none;
  margin: 0 auto !important;
}
::v-deep() {
  @media only screen and (max-width: 767px) {
    .vab-cropper-canvas {
      display: block;
      float: none;
      margin: 0 auto !important;
    }
    .vab-cropper-preview {
      display: none;
    }
  }

  .el-textarea {
    margin-top: $base-margin;
  }

  .el-dialog__footer {
    height: 72px;

    &:before {
      display: block;
      clear: both;
      content: '';
    }

    > div {
      > div {
        display: inline;

        .el-upload-list {
          display: none;
        }

        .el-upload--picture-card {
          float: right;
          width: auto;
          height: $base-input-height;
          line-height: $base-input-height;
          vertical-align: middle;
          background-color: transparent;
          border: 0;
          border-radius: 0;
        }
      }

      .el-button {
        float: right;
        margin-left: 10px;
      }
    }
  }
}
//
.artImg {
  ::v-deep(.hide .el-upload--picture-card) {
    display: none;
  }
}
::v-deep(.ql-video) {
  width: 20% !important;
  height: 200px;
}
::v-deep(.ql-toolbar.ql-snow .ql-picker-label) {
  margin-top: -2px !important;
}
::v-deep(.ql-toolbar.ql-snow .ql-formats) {
  margin-right: 0px;
}
::v-deep(.ql-formats) {
  button,
  span {
    margin-right: 10px;
  }
  .ql-color {
    margin-top: 1px !important;
  }
  .ql-icon-picker,
  .ql-color-picker {
    border: none !important;
  }
  .ql-align {
    margin-top: 4px;
  }
  .ql-picker {
    border: 1px solid #ddd;
    .ql-picker-label {
      display: flex;
      align-items: center;
    }
  }
}

.artTags {
  .el-tag {
    height: 32px;
    margin-bottom: 5px;
    line-height: 30px;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    height: 32px;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 10px;
    line-height: 30px;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;

    // vertical-align: bottom;
  }
}
.art_tags {
  color: #ff4d4f;
  font-size: 12px;
}
</style>
