var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "div",
        { staticClass: "m-b" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-plus", type: "primary" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { border: "", data: _vm.data, "max-height": "600" } },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "排序字段",
              prop: "sort",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "名字",
              prop: "name",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "积分数",
              prop: "posts",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建时间",
              prop: "createTime",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 5px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.showtab(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 5px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.edi(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 5px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.delobj(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("table-edit", {
        ref: "edit",
        attrs: { config: _vm.config, options: _vm.options, rules: _vm.rules },
        on: { submit: _vm.submit },
        model: {
          value: _vm.form,
          callback: function ($$v) {
            _vm.form = $$v
          },
          expression: "form",
        },
      }),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": 1,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: "查看", visible: _vm.dialogVisible, width: "60%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-descriptions",
                { attrs: { border: "", column: 2, title: "" } },
                [
                  _c("el-descriptions-item", { attrs: { label: "排序字段" } }, [
                    _vm._v(_vm._s(_vm.showrows.sort)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "名字" } }, [
                    _vm._v(_vm._s(_vm.showrows.name)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "积分数" } }, [
                    _vm._v(_vm._s(_vm.showrows.posts)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.dialogshow, width: "60%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogshow = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  attrs: {
                    inline: true,
                    "label-width": "88px",
                    model: _vm.editform,
                    rules: _vm.rule,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序字段", prop: "sort" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { "text-align": "left", width: "200px" },
                        attrs: {
                          "controls-position": "right",
                          placeholder: "请输入排序字段",
                          size: "small",
                        },
                        model: {
                          value: _vm.editform.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.editform, "sort", $$v)
                          },
                          expression: "editform.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: _vm.editform.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editform, "name", $$v)
                          },
                          expression: "editform.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "积分数", prop: "posts" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入积分数" },
                        model: {
                          value: _vm.editform.posts,
                          callback: function ($$v) {
                            _vm.$set(_vm.editform, "posts", $$v)
                          },
                          expression: "editform.posts",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogshow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.savesub } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }