<template>
  <el-form ref="ruleForm" :inline="true" label-position="right" label-width="80px" :model="ruleForm" size="mini">
    <!-- <el-form-item label="店铺：" prop="shopId">
      <el-select v-model="ruleForm.value" placeholder="请选择 店铺">
        <el-option v-for="item in ruleForm.shopId" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </el-form-item> -->
    <el-form-item label="商品名称：" prop="name">
      <el-select v-model="ruleForm.value" placeholder="请选择 商品名称">
        <el-option v-for="item in ruleForm.name" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </el-form-item>
    <!-- <el-form-item label="商城类目：" prop="categoryId">
      <el-select v-model="ruleForm.value" placeholder="请选择 商城类目">
        <el-option v-for="item in ruleForm.categoryId" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </el-form-item> -->
    <el-form-item label="商城编码：" prop="spuCode">
      <el-input v-model="ruleForm.spuCode" placeholder="请输入 商城编码" />
    </el-form-item>
    <el-form-item label="规格类型：" prop="specType">
      <el-select v-model="ruleForm.specType" placeholder="请选择 规格类型">
        <el-option v-for="item in specType" :key="item.id" :label="item.labelName" :value="item.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="是否上架：" prop="shelf">
      <el-select v-model="ruleForm.shelf" placeholder="请选择 是否上架">
        <el-option v-for="item in shelf" :key="item.id" :label="item.labelName" :value="item.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="积分赠送：" prop="pointsGiveSwitch">
      <el-select v-model="ruleForm.info" placeholder="请选择 积分赠送">
        <el-option v-for="item in pointsGiveSwitch" :key="item.id" :label="item.labelName" :value="item.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="积分抵扣：" prop="pointsGiveSwitch">
      <el-select v-model="ruleForm.pointsGiveSwitch" placeholder="请选择 积分赠送">
        <el-option v-for="item in pointsGiveSwitch" :key="item.id" :label="item.labelName" :value="item.id" />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button icon="el-icon-search" native-type="submit" type="warning" @click="getPage">搜索</el-button>
      <el-button icon="el-icon-refresh-left" native-type="submit" type="primary" @click="reset">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getPage } from '@/api/mall/goodspu/goodspu'

export default {
  data() {
    return {
      ruleForm: {
        colStatus: '',
        merName: '',
        size: 10,
        current: 1,
      },
      specType: [
        {
          id: 0,
          labelName: '统一规格',
        },
        {
          id: 1,
          labelName: '多规格',
        },
      ],
      shelf: [
        {
          id: 0,
          labelName: '否',
        },
        {
          id: 1,
          labelName: '是',
        },
      ],
      pointsGiveSwitch: [
        {
          id: 0,
          labelName: '关闭',
        },
        {
          id: 1,
          labelName: '开启',
        },
      ],
    }
  },
  methods: {
    reset() {
      this.resetForm('ruleForm')
      this.$emit('datelist', false)
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    getPage() {
      getPage({ ...this.ruleForm }).then((res) => {
        this.$emit('datelist', res.data)
      })
    },
  },
}
</script>

<style>
</style>