<template>
  <div class="cy-container">
    <el-form
      ref="queryForm"
      :inline="true"
      :model="queryForm"
      @submit.native.prevent
    >
      <el-form-item label="用户信息">
        <el-input
          v-model="queryForm.userName"
          clearable
          placeholder="请输入用户名"
        />
      </el-form-item>

      <el-form-item>
        <el-button
          icon="el-icon-search"
          native-type="submit"
          type="primary"
          @click="handleQuery"
        >
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          native-type="submit"
          type="primary"
          @click="handleReset"
        >
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="listLoading"
      border
      :data="tableData"
      default-expand-all
    >
      <el-table-column
        align="center"
        label="用户信息"
        prop="userName"
        show-overflow-tooltip
      />

      <el-table-column
        align="center"
        label="参与次数"
        prop="partNumb"
        show-overflow-tooltip
      />

      <el-table-column align="center" label="奖品明细" show-overflow-tooltip>
        <template #default="{ row }">
          <div>
            <el-button
              plain
              size="small"
              type="primary"
              @click="handleDetail(row)"
            >
              查看
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="forms.currentPage"
      :layout="layout"
      :page-size="forms.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 弹框 -->
    <el-dialog
      v-if="dialogVisible"
      append-to-body
      center
      class="PayMassage"
      :close-on-click-modal="false"
      style="padding-top: 0px; text-align: left"
      title="奖品明细"
      :visible.sync="dialogVisible"
      width="70%"
      @close="close"
    >
      <el-row style="padding: 0px 0px 25px 0; font-size: 16px; color: #1890ff">
        <el-col :span="4" style="color: black; font-weight: 700">
          累计奖品 {{ general.partNumb || 0 }}
        </el-col>
        <el-col :span="4">已取货 {{ general.pickupNumb || 0 }}</el-col>
        <el-col :span="4">已回收 {{ general.recoveredNumb || 0 }}</el-col>
        <el-col :span="4">待开盒 {{ general.beOpenedNumb || 0 }}</el-col>
        <el-col :span="4">待取货 {{ general.bePickupNumb || 0 }}</el-col>
      </el-row>
      <el-table border :data="tableDataDetail" style="width: 100%">
        <el-table-column
          align="center"
          label="奖品等级"
          prop="itemName"
          show-overflow-tooltip
        />

        <el-table-column
          align="center"
          label="图片"
          prop="goodsImage"
          show-overflow-tooltip
          width="180"
        >
          <template #default="{ row }">
            <div>
              <img :src="row.goodsImage" style="width: 60px; height: 60px" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="奖品信息"
          prop="goodsName"
          show-overflow-tooltip
          width="180"
        />
        <el-table-column
          align="center"
          :formatter="formatPrizeState"
          label="奖品状态"
          prop="prizeState"
          show-overflow-tooltip
          width="180"
        />
        <el-table-column
          align="center"
          label="取货单号"
          prop="orderCode"
          show-overflow-tooltip
          width="180"
        />
        <el-table-column
          align="center"
          label="回收单号"
          prop="recoverOrderCode"
          show-overflow-tooltip
          width="180"
        />
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        :current-page="currentPage"
        :layout="layout1"
        :page-size="pageSize"
        :total="total1"
        @current-change="handleCurrentChange1"
        @size-change="handleSizeChange1"
      />
    </el-dialog>
  </div>
</template>
<script>
  import {
    userParticipateList,
    loadUserPartPage,
    loadUserPartSurvey,
  } from '@/api/activity/box'
  import { selectSysDict } from '@/api/mall/common'
  export default {
    data() {
      return {
        instId: 0,
        userId: '',
        listLoading: false,
        tableData: [],
        forms: { currentPage: 1, pageSize: 10 },
        isShow: true,
        queryForm: {
          userName: '',
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        tableDataDetail: [],
        dialogVisible: false,
        currentPage: 1,
        pageSize: 10,
        layout1: 'total, sizes, prev, pager, next, jumper',
        total1: 0,
        general: {},
        prizeStateList: [], //奖品状态
      }
    },
    created() {
      this.instId = this.$route.query.instId
      this.fetchData()
    },
    methods: {
      // 字典
      selectSysDict() {
        selectSysDict({
          dictTypeList: ['prizeState'],
        })
          .then((res) => {
            if (res.data) {
              this.prizeStateList = res.data[0].dictInfoVoList
            }
          })
          .catch(() => {})
      },
      // 奖品状态
      formatPrizeState(row) {
        for (let i = 0; i < this.prizeStateList.length; i++) {
          if (this.prizeStateList[i].dictKey == row.prizeState) {
            return this.prizeStateList[i].dictValue
          }
        }
      },
      // 查看
      handleDetail(row) {
        console.log(row)
        this.userId = row.userId
        this.dialogVisible = true
        this.fetchDataDetail()
        this.selectSysDict()
        // 概况
        loadUserPartSurvey({
          instId: this.$route.query.instId,
          userId: row.userId,
        })
          .then((res) => {
            this.general = res.data
          })
          .catch(() => {})
      },
      close() {
        this.dialogVisible = false
      },
      // 参与明细列表
      async fetchData(options) {
        this.listLoading = true
        await userParticipateList({
          ...options,
          ...this.forms,
          instId: Number(this.instId),
        })
          .then((res) => {
            if (res.data) {
              this.tableData = res.data
              this.total = res.data.total
            }
          })
          .catch(() => {})
        this.listLoading = false
      },
      // 奖品明细列表
      async fetchDataDetail() {
        await loadUserPartPage({
          currentPage: this.currentPage,
          pageSize: this.pageSize,
          instId: Number(this.instId),
          userId: this.userId,
        })
          .then((res) => {
            if (res.data) {
              this.tableDataDetail = res.data.records
              this.total1 = res.data.total
            }
          })
          .catch(() => {})
      },
      // 重置
      handleReset() {
        this.datePicker = []
        this.forms.currentPage = 1
        this.forms.pageSize = 10
        this.queryForm = {}
        this.handleQuery()
      },
      // 搜索
      async handleQuery() {
        this.forms.currentPage = 1
        await this.fetchData({ ...this.queryForm }, false)
        let temp = false
        Object.keys(this.queryForm).forEach((key) => {
          this.queryForm[key] && (temp = true)
        })
        this.isShow = temp
      },
      // 分页
      handleSizeChange(val) {
        this.forms.pageSize = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
      // 分页
      handleCurrentChange(val) {
        this.forms.currentPage = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
      // 分页
      handleSizeChange1(val) {
        this.pageSize = val
        this.fetchDataDetail()
      },
      handleCurrentChange1(val) {
        this.currentPage = val
        this.fetchDataDetail()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .filterItems {
    width: 150px;
  }
</style>
