/**
 * @description 店铺入驻
 * @author
 */
import request from '@/utils/request'

export function getPage(query) {
  return request({
    url: '/mall/shopapply/page',
    method: 'get',
    params: query,
  })
}

export function addObj(obj) {
  return request({
    url: '/mall/shopapply',
    method: 'post',
    data: obj,
  })
}

export function getObj(id) {
  return request({
    url: '/mall/shopapply/' + id,
    method: 'get',
  })
}

export function delObj(id) {
  return request({
    url: '/mall/shopapply/' + id,
    method: 'delete',
  })
}

export function putObj(obj) {
  return request({
    url: '/mall/shopapply/addedit',
    method: 'put',
    data: obj,
  })
}

export function verifyObj(obj) {
  return request({
    url: '/mall/shopapply/verify',
    method: 'put',
    data: obj,
  })
}

export function getOne(query) {
  return request({
    url: '/mall/shopapply/one',
    method: 'get',
    params: query,
  })
}
export function addObjrow(obj) {
  return request({
    url: '/mall/shopapply/addedit',
    method: 'put',
    data: obj,
  })
}