<template>
  <div ref="custom-table" class="custom-table-container" :class="{ 'vab-fullscreen': isFullscreen }">
    <SearchForm @receive="receiverow" />
    <!-- <searchform @receive="receiverow" /> -->
    <div class="flexk">
      <div style="float: left; margin-bottom: 15px">
        <!-- <el-button class="addBtn" type="primary" @click="$router.push({ path: '/call/batch', query: { type: 1 } })">批量导入</el-button> -->
        <!-- <el-button type="primary" @click="exportUuUserSugar">批量导出</el-button> -->
        <!-- <el-button type="" @click="distrtions">分配</el-button>
        <el-button type="" @click="recyclea">回收</el-button> -->
      </div>
      <div class="ref" style="float: right">
        <el-button style="margin: 0 10px 10px 0 !important" @click="clickFullScreen">
          <vab-icon :icon="isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'" />
          表格全屏
        </el-button>
        <el-popover ref="popover" popper-class="custom-table-checkbox" trigger="hover">
          <el-radio-group v-model="lineHeight">
            <el-radio label="medium">大</el-radio>
            <el-radio label="small">中</el-radio>
            <el-radio label="mini">小</el-radio>
          </el-radio-group>
          <template #reference>
            <el-button style="margin: 0 10px 10px 0 !important">
              <vab-icon icon="line-height" />
              表格尺寸
            </el-button>
          </template>
        </el-popover>
        <!-- <el-popover popper-class="custom-table-checkbox" trigger="hover">
          <el-checkbox-group v-model="checkList">
            <vab-draggable v-bind="dragOptions" :list="columns" @change="handleChange">
              <div v-for="(item, index) in columns" :key="item + index">
                <vab-icon icon="drag-drop-line" />
                <el-checkbox :disabled="item.disableCheck === true" :label="item.label" @change="handleChange">
                  {{ item.label }}
                </el-checkbox>
              </div>
            </vab-draggable>
          </el-checkbox-group>
          <template #reference>
            <el-button icon="el-icon-setting" style="margin: 0 0 10px 0 !important">可拖拽列设置</el-button>
          </template>
        </el-popover> -->
      </div>
    </div>
    <el-table ref="tableSort" border :data="data" max-height="600" show-summary :size="lineHeight" :summary-method="getSummaries" @selection-change="selectd">
      <el-table-column align="center" type="selection" width="55" />
      <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="50" />
      <el-table-column
        v-for="(item, index) in listContent"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :show-overflow-tooltip="item.tooltip"
        :width="item.width"
      >
        <template #default="{ row }">
          <el-tooltip class="item" :content="row[item.prop]" :disabled="item.tooltip" effect="dark" placement="top-end" :value="item.tooltip">
            <span style="white-space: nowrap">{{ row[item.prop] }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" fixed="right" label="操作" width="180">
        <template #default="{ row }">
          <el-button size="default" type="text" @click="showedis(row)">回访</el-button>
          <el-button size="medium" type="text" @click="appoint(row)">移交</el-button>
       </template>
      </el-table-column> -->
    </el-table>
    <table-edit ref="edit" v-model="form" :config="config" :options="options" :rules="rules" @fileStatus="fileupload" @submit="submit" />
    <el-pagination
      background
      :current-page="queryForm.current"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="queryForm.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- <handover :hanuser="handeuserinfo" :showtab="appointDialog" @closover="colsove" />
    <distrtion :shodist="distrtionshow" :userid="selecuserid" @closedistr="closdistr" />
    <histroy :showhislist="historylist" :showhist="historyshow" @closehis="colsehistory" />
    <ediform :edilist="shotablist" :edirow="edisrow" :showedit="showedi" @clearedi="cleareit" /> -->
  </div>
</template>
<script>
import { shopinfo, exportUuUserEmp } from '@/api/mall/health/seas/seasManger'
import { getUserListS } from '@/api/mall/health/teamdata/index'

import { options, rules, config } from '@/const/seas/seasManger'
import { Recordrecycle } from '@/api/mall/seas/index'
import { deepClone } from '@/utils/util'
import { mapGetters } from 'vuex'
// import distrtion from './components/distribution.vue'
// import histroy from './components/history.vue'
// import handover from './components/handover.vue'
// import searchform from './components/search.vue'
// import ediform from './components/index.vue'
//表格内容
// import VabDraggable from 'vuedraggable'
import SearchForm from './components/search.vue'
import { crmColumnDropadd, crmColumnDropqueryPage } from '@/api/mall/tableMove/index'
// const sdk = require('./components/js/sdk.min.js')
// let sdk = require('./components/js/sdk.min.js')
export default {
  components: {
    // distrtion,
    SearchForm,
    // histroy,
    // handover,
    // searchform,
    // ediform,
    // VabDraggable,
  },
  filters: {
    //性别（1：男，2：女，0：未知）
    getSex(val) {
      let str = ''
      switch (val) {
        case 1:
          str = '男'
          break
        case 2:
          str = '女'
          break
        case 0:
          str = '未知'
          break
      }
      return str
    },
  },
  data() {
    return {
      listContent: [],
      listNew: [], //暂存list，用于多选时赋值
      searchForm: { current: 1, size: 10 },
      total: 0,
      data: [],
      isSearch: false,
      options,
      rules,
      config,
      form: {},
      // 当前点击的指定id
      sysId: '', //系统ID
      queryForm: {
        current: 1,
        size: 10,
      },
      checkdate: null,
      appointDialog: false,
      handeuserinfo: null,
      distrtionshow: false,
      historyshow: false,
      historylist: null,
      selecuserid: null, //用户选中id
      phoneUa: null,
      showedi: false,
      shotablist: null,
      edisrow: null,

      //增加操作
      isFullscreen: false,
      height: this.$baseTableHeight(1),
      lineHeight: 'mini',
      checkList: [
        '姓名',
        '工号',
        '部门',
        '总单数',
        '总金额',
        '一复发单',
        '一复签收',
        '一复单额',
        '一复签额',
        '二复发单',
        '二复签收',
        '二复单额',
        '二复签额',
        '多复发单',
        '多复签收',
        '多复单额',
        '多复签额',
        '活动单',
        '活动单额',
        'MDT',
        'MDT额',
        '回访',
        '回访额',
        '一复签单达率',
        '订单类型',
        '复购次数'
      ],
      columns: [
        {
          label: '姓名',
          width: '160',
          prop: 'realName',
          tooltip: true,
        },
        {
          label: '工号',
          width: '180',
          prop: 'profile',
          tooltip: true,
        },
        {
          label: '部门',
          width: '300',
          prop: 'postName',
          tooltip: false,
        },
        {
          label: '总单数',
          width: '160',
          prop: 'orderCount',
          tooltip: false,
        },
        {
          label: '总金额',
          width: '160',
          prop: 'orderSumAmount',
          tooltip: false,
        },
        {
          label: '复购次数',
          width: '160',
          prop: 'repNumName',
          tooltip: false,
        },
        {
          label: '订单类型',
          width: '160',
          prop: 'orderTypeName',
          tooltip: false,
        },
        // {
        //   label: '总定金/预存',
        //   width: '100',
        //   prop: 'grossAmount',
        //   tooltip: false,
        // },
        // {
        //   label: '一复发单',
        //   width: '90',
        //   prop: 'oneBillOrderCount',
        //   tooltip: false,
        // },
        // {
        //   label: '一复签收',
        //   width: '90',
        //   prop: 'oneSignOrderCount',
        //   tooltip: false,
        // },
        // {
        //   label: '一复单额',
        //   width: '90',
        //   prop: 'oneSingleOrderAmount',
        //   tooltip: false,
        // },
        // {
        //   label: '一复签额',
        //   width: '90',
        //   prop: 'oneQianOrderAmount',
        //   tooltip: false,
        // },
        // {
        //   label: '二复发单',
        //   width: '90',
        //   prop: 'twoBillOrderCount',
        //   tooltip: false,
        // },
        // {
        //   label: '二复签收',
        //   width: '90',
        //   prop: 'twoSignOrderCount',
        //   tooltip: false,
        // },
        // {
        //   label: '二复单额',
        //   width: '90',
        //   prop: 'twoSingleOrderAmount',
        //   tooltip: false,
        // },
        // {
        //   label: '二复签额',
        //   width: '90',
        //   prop: 'twoQianOrderAmount',
        //   tooltip: false,
        // },
        // {
        //   label: '多复发单',
        //   width: '100',
        //   prop: 'duoBillOrderCount',
        //   tooltip: false,
        // },
        // {
        //   label: '多复签收',
        //   width: '120',
        //   prop: 'duoSignOrderCount',
        //   tooltip: false,
        // },
        // {
        //   label: '多复单额',
        //   width: '90',
        //   prop: 'duoSingleOrderAmount',
        //   tooltip: false,
        // },
        // {
        //   label: '多复签额',
        //   width: '90',
        //   prop: 'duoQianOrderAmount',
        //   tooltip: false,
        // },
        // {
        //   label: '活动单',
        //   width: '100',
        //   prop: 'actOrderCount',
        //   tooltip: false,
        // },
        // {
        //   label: '活动单额',
        //   width: '100',
        //   prop: 'actOrderAmount',
        //   tooltip: false,
        // },
        // {
        //   label: 'MDT',
        //   width: '100',
        //   prop: 'mdtOrderCount',
        //   tooltip: false,
        // },
        // {
        //   label: 'MDT额',
        //   width: '100',
        //   prop: 'mdtOrderAmount',
        //   tooltip: false,
        // },
        // {
        //   label: '回访',
        //   width: '100',
        //   prop: 'vistOrderCount',
        //   tooltip: false,
        // },
        // {
        //   label: '回访额',
        //   width: '100',
        //   prop: 'vistOrderAmount',
        //   tooltip: false,
        // },
        // {
        //   label: '一复签单达率',
        //   width: '100',
        //   prop: 'oneRateOrderAmount',
        //   tooltip: false,
        // },
      ],
      rowList: [],
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 600,
        group: 'description',
      }
    },
    ...mapGetters({
      userId: 'user/userId',
      type: 'user/type',
      userinfo: 'user/userinfo',
    }),
    // finallyColumns() {
    //   return this.columns.filter((item) => this.checkList.includes(item.label))
    // },
  },
  created() {
    this.list()
    this.getUserList()
    this.crmColumnDropqueryPage()
    this.getContent()
    // this.callPhone()
  },
  mounted() {
    this.$baseEventBus.$on('Qualityinspection', () => {
      this.list()
      this.getUserList()

    })
    this.$baseEventBus.$on('receive', (e) => {
      console.log(e, 'chuaner')
    })
  },
  methods: {
   
    getContent() {
      let result = this.columns.filter((column) => this.checkList.includes(column.label))
      this.listContent = result
    },
    receiverow(row) {
      console.log(row,'ojggjdgjjgdjjdjg');
      if (row != false) {
        this.data = row.records
        for (let i = 0; i < this.data.length; i++) {
          const obj = this.data[i]
          const keys = Object.keys(obj)
          for (let key of keys) {
            if (typeof obj[key] === 'number') {
              obj[key] = String(obj[key])
            }
          }
          this.data[i] = obj
        }
        this.rowList.push(this.data[this.data.length - 1])
        this.data.splice(this.data.length - 1, 1)
        this.total = row.total
      } else {
        console.log('0')
      }
    },
    handleChange() {
      this.crmColumnDropadd()
    },
    crmColumnDropadd() {
      let columnDropPoList = this.columns.map((item, index) => {
        return Object.assign({}, item, { sort: index })
      })
      let comId = localStorage.getItem('menuinfo')
      crmColumnDropadd({ columnDropPoList, comId, type: '0' }).then(() => {})
    },
    crmColumnDropqueryPage() {
      let comId = localStorage.getItem('menuinfo')
      crmColumnDropqueryPage({ current: 1, size: 30, comId }).then((res) => {
        if (res.data.records != '' && res.data.records.length != 0) {
          this.columns = res.data.records
        }
      })
    },
    //表格尾行计算
    getSummaries(param) {

      console.log(param,'param');
      const { columns } = param
      const sums = []
      const units = ['','','','','' ,'单', '元', '', '', '', '', '', '', '']
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计'
          return
        }
        if (index === 2) {
      sums[index] = ''
      return
    }
    if (index === 3) {
      sums[index] = '' 
      return
    }
    if (index === 4) {
      sums[index] = '' 
      return
    }
    if (index === 7) {
      sums[index] = '' 
      return
    }
    if (index === 8) {
      sums[index] = '' 
      return
    }
        const values = this.rowList.map((item) => Number(item[column.property]))
        console.log(values,'values');
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values[values.length - 1] + ' ' + units[index]
         
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    //表格全屏操作
    clickFullScreen() {
      this.isFullscreen = !this.isFullscreen
      this.handleHeight()
    },
    handleHeight() {
      if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
      else this.height = this.$baseTableHeight(1)
    },

    showedis(row) {
      this.edisrow = row.id
      this.shotablist = row
      this.showedi = true
    },
    cleareit() {
      this.showedi = false
    },
    // 导出客户列表
    exportUuUserSugar() {
      if (this.selecuserid != null && this.selecuserid.length > 0) {
        let ids = []
        this.selecuserid.forEach((item) => {
          if(item.id != null){
            ids.push(item.id)
          }
         
        })
        exportUuUserEmp({ ids }).then((res) => {
          console.log(res.data)
          this.saveBlod(res)
        })
      } else {
        this.$message.error('请选择导出用户')
      }
    },
    saveBlod(res) {
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      const fileName = '员工统计数据表.xls'
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const elink = document.createElement('a')
        elink.download = fileName
        elink.href = URL.createObjectURL(blob)
        elink.style.display = 'none'
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName)
      }
    },
    // 导出客户根据查询条件导出(导出客户)
    async handleExUuUserSugarxc() {
      this.downloadLoading = true
      const listQueryData = {}
      listQueryData.status = 1
      const res = await exportUuUserEmp(listQueryData)
      if (!res) {
        return
      }
      console.log(res)
      this.saveBlod(res)
      this.downloadLoading = false
    },
    place(row) {
      if (row.bindStatus == 1) {
        this.$router.push({ path: '/activity/activity-customers/customers', query: { userlist: row } })
      } else {
        this.$message.error('该数据未注册请注册后下单')
      }
    },
    reigset(row) {
      if (row.bindStatus == 1) {
        this.$message.error('该数据已绑定会员')
      } else {
        this.$router.push({ path: '/drag/registration', query: { date: row } })
      }
    },
    callPhone() {},
    selectd(e) {
      this.selecuserid = e
    },
    history(row) {
      this.historylist = [JSON.parse(JSON.stringify(row))]
      this.historyshow = true
    },
    colsehistory() {
      this.historyshow = false
    },
    recyclea() {
      let allot = []
      if (this.selecuserid) {
        this.selecuserid.forEach((item) => {
          allot.push(item.id)
        })
      }
      if (this.selecuserid != null) {
        this.$confirm('是否回收该用户', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            Recordrecycle({ allot }).then((res) => {
              if (res.status == 200) {
                this.$message.success('回收成功')
                this.list()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            this.$message('您已取消回收')
          })
      } else {
        this.$message.error('请选择回收用户')
      }
    },
    distrtions() {
      if (this.selecuserid != null && this.selecuserid.length > 0) {
        this.distrtionshow = true
      } else {
        this.$message.error('请选择分配用户')
      }
    },
    closdistr() {
      this.selecuserid = null
      this.distrtionshow = false
      this.list()
    },
    handleCurrentChange(val) {
      this.queryForm.current = val
      this.list()
    },
    handleSizeChange(val) {
      this.queryForm.size = val
      this.list()
    },
    async getUserList() {
      // console.log('mall/shopinfo/page')
      shopinfo().then((res) => {
        console.log(res)
        const {
          data: { records },
        } = res
        console.log(records)
        this.appointData = records
      })
    },
    list() {
      // type: 0, //账号类型：-1、系统管理员；1、租户管理员；2、店铺管理员 3服务商
      // sysId: '', //系统ID
      getUserListS({
        ...options,
        ...this.queryForm,
        // type: this.type,
        // sysId: this.userId,
      }).then((res) => {
        const {
          data: { records, total },
        } = res
        this.data = records
        for (let i = 0; i < this.data.length; i++) {
          const obj = this.data[i]
          const keys = Object.keys(obj)
          for (let key of keys) {
            if (typeof obj[key] === 'number') {
              obj[key] = String(obj[key])
            }
          }
          this.data[i] = obj
        }
        this.total = total
        this.rowList.push(this.data[this.data.length - 1])
        this.data.splice(this.data.length - 1, 1)
      })
    },
    appoint(row) {
      this.handeuserinfo = row
      this.appointDialog = true
    },
    colsove() {
      this.appointDialog = false
    },
    handleAdd() {
      this.$refs['edit'].showEdit()
    },
    fileupload(msg, result) {
      console.log('成功回调')
      console.log(msg, result)
    },
    submit() {
      console.log('表单数据user', this.form)
    },
    show(row) {
      this.form = deepClone(row)
      this.$nextTick(() => {
        this.$refs['edit'].showEdit('show')
      })
    },
    edit(row) {
      this.form = deepClone(row)
      this.$nextTick(() => {
        this.$refs['edit'].showEdit('edit')
      })
    },
  },
}
</script>

<style scoped></style>
