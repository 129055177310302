const searchOptions = [
  {
    label: '店铺',
    type: 'select',
    prop: 'isopen',
    span: 4,
    labelWidth: '3em',
  },
  {
    label: '商品',
    type: 'select',
    prop: 'isopen2',
    span: 4,
    labelWidth: '3em',
  },
  { type: 'operation', span: 4 },
]

export { searchOptions }
