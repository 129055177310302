var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OrderDetail cy-container" },
    [
      _c(
        "el-row",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            background: "#f0f0f0",
            padding: "20px",
          },
        },
        [
          _vm.form.tip
            ? _c("el-col", { staticClass: "fs16" }, [_vm._v("复制主题")])
            : _vm.form.id
            ? _c("el-col", { staticClass: "fs16" }, [_vm._v("编辑主题")])
            : _c("el-col", { staticClass: "fs16" }, [_vm._v("添加主题")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt30" },
        [
          _c(
            "el-steps",
            { attrs: { active: _vm.active, "align-center": "" } },
            [
              _vm.form.tip
                ? _c("el-step", {
                    attrs: { description: "复制主题" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.updateData()
                      },
                    },
                  })
                : _vm.form.id
                ? _c("el-step", {
                    attrs: { description: "编辑主题" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handelClick(1)
                      },
                    },
                  })
                : _c("el-step", {
                    attrs: { description: "添加主题" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.updateData()
                      },
                    },
                  }),
              _vm.form.tip
                ? _c("el-step", {
                    attrs: { description: "选择活动" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.updateData()
                      },
                    },
                  })
                : _vm.form.id
                ? _c("el-step", {
                    attrs: { description: "选择活动" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handelClick(2)
                      },
                    },
                  })
                : _c("el-step", {
                    attrs: { description: "选择活动" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.updateData()
                      },
                    },
                  }),
            ],
            1
          ),
          _vm.active === 1
            ? _c(
                "step-one",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { infos: _vm.form },
                      on: { "change-step": _vm.handleSetStep },
                    },
                    "step-one",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              )
            : _vm._e(),
          _vm.active === 2
            ? _c(
                "step-two",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { infos: _vm.form },
                      on: { "change-step": _vm.handleSetStep },
                    },
                    "step-two",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }