var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px 20px 30px 20px", height: "79vh" } },
    [
      _vm.btnRolrs[1] && _vm.btnRolrs[1].fixedBarButtons
        ? _c(
            "div",
            { staticStyle: { margin: "10px" } },
            _vm._l(_vm.btnRolrs[1].fixedBarButtons, function (item) {
              return _c(
                "el-button",
                {
                  key: item.id,
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickBtn(item.buttonUrl, null)
                    },
                  },
                },
                [
                  _c("vab-icon", { attrs: { icon: item.buttonIcon || "" } }),
                  _vm._v(" " + _vm._s(item.buttonName) + " "),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-bottom": "20px" },
          attrs: {
            border: "",
            data: _vm.list,
            lazy: "",
            load: _vm.load,
            "max-height": 600,
            "row-key": "id",
            "tree-props": { children: "children", hasChildren: "hasChildren" },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "排序",
              type: "index",
              width: "50",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "分类名称", prop: "catName" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "分类图标", "min-width": "30px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.deep === 3
                      ? _c("el-image", {
                          attrs: { fit: "cover", src: row.catLogo },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", "min-width": "20px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("span", {
                          style: `width: 15px;
              height: 15px;
              background-color: ${row.isShow === 1 ? "rgb(19,206,102)" : "red"};
              border-radius: 50%;
              margin-right: 5px;`,
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(row.isShow === 1 ? "正常显示" : "禁用")
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", "min-width": "50px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          "align-items": "center",
                        },
                      },
                      _vm._l(row.btns, function (item) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.isShow && item.buttonStatus == 0,
                                expression:
                                  "item.isShow && item.buttonStatus == 0",
                              },
                            ],
                            key: item.id,
                          },
                          [
                            item.children && item.children.length
                              ? _c(
                                  "el-dropdown",
                                  {
                                    staticStyle: { margin: "0 5px" },
                                    on: {
                                      command: (index) => {
                                        _vm.handleClickBtn(
                                          item.buttonUrl,
                                          index,
                                          item.children,
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      { attrs: { type: item.buttonCss } },
                                      [
                                        _c("vab-icon", {
                                          attrs: {
                                            icon: item.buttonIcon || "",
                                          },
                                        }),
                                        _vm._v(
                                          " " + _vm._s(item.buttonName) + " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      _vm._l(item.children, function (item2) {
                                        return _c(
                                          "el-dropdown-item",
                                          {
                                            key: item2.id,
                                            attrs: { command: item2.id },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item2.buttonName) +
                                                " "
                                            ),
                                            _c("vab-icon", {
                                              attrs: {
                                                icon: item.buttonIcon || "",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "0 5px" },
                                    attrs: {
                                      size: "mini",
                                      type: item.buttonCss,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickBtn(
                                          item.buttonUrl,
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("vab-icon", {
                                      attrs: { icon: item.buttonIcon || "" },
                                    }),
                                    _vm._v(" " + _vm._s(item.buttonName) + " "),
                                  ],
                                  1
                                ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.currentPage,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("form-dialog", {
        ref: "formDialog",
        attrs: { status: _vm.dialogStatus },
        on: { fetch: _vm.fetchData },
      }),
      _c("add-cat-image", { ref: "addCatImage", on: { fetch: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }