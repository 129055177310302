var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "添加海报", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "商品分类：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.form.parentName) +
                        " > " +
                        _vm._s(_vm.form.catName)
                    ),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "海报图片：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            accept: ".png,.jpg,.jpeg",
                            action: _vm.uploadParams.uploadURL,
                            "file-list": _vm.uploadParams.fileList,
                            headers: { Authorization: _vm.uploadParams.token },
                            limit: 1,
                            "list-type": "picture-card",
                            "on-exceed": _vm.handleExceed,
                            "on-preview": _vm.handlePictureCardPreview,
                            "on-remove": _vm.handleRemove,
                            "on-success": _vm.handleUploadCatImageSuccess,
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            "append-to-body": "",
                            visible: _vm.dialogVisible,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogVisible = $event
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.uploadParams.dialogImageUrl,
                              width: "100%",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "商品链接：",
                    "label-width": _vm.formLabelWidth,
                    prop: "relateUrl",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: {
                      placeholder: "请输入商品链接",
                      rows: 10,
                      type: "textarea",
                    },
                    model: {
                      value: _vm.form.relateUrl,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "relateUrl",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.relateUrl",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.confirmBtnLoading, type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }