<template>
  <div class="container">
    <div class="cont-value">
      <div class="misr-title">计划名称</div>
      <div class="value-content">{{ Projectdetails.planName }}</div>
    </div>
    <div class="cont-value">
      <div class="misr-title">计划类型</div>
      <div class="value-content">{{ Projectdetails.planType }}</div>
    </div>
    <div class="cont-value">
      <div class="misr-title">计划图片</div>
      <div class="value-content">
        <el-image v-for="(ym, index) in Projectdetails.planLogo" :key="index" :preview-src-list="Projectdetails.planLogo" :src="ym" style="width: 100px; height: 100px" />
      </div>
    </div>
    <div class="cont-value">
      <div class="misr-title">计划类型执行方案</div>
      <div class="value-content" style="width: 100%">
        <el-table border :data="Projectdetails.vitMealPlanOptPos" style="width: 100%">
          <el-table-column align="center" label="天数" prop="optDay" width="120">
            <template slot-scope="scope">
              <span>第{{ scope.row.optDay }}天</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="时间" prop="optTime" width="160">
            <template slot-scope="scope">
              <el-time-select
                v-for="(item, ind) in scope.row.optionVos"
                :key="ind"
                v-model="item.optTime"
                disabled
                :picker-options="{
                  start: '00:00',
                  end: '23:59',
                }"
                placeholder="选择时间"
                :value="item"
              />
            </template>
          </el-table-column>
          <el-table-column align="center" label="预计执行时长（分）" prop="optNum" width="200">
            <template slot-scope="scope">
              <el-input v-for="(item, ind) in scope.row.optionVos" :key="ind" v-model="item.optNum" disabled placeholder="请输入预计执行时长" />
            </template>
          </el-table-column>
          <el-table-column align="center" label="执行内容" prop="optInfo">
            <template slot-scope="scope">
              <div class="add-tablea">
                <el-input v-for="(item, ind) in scope.row.optionVos" :key="ind" v-model="item.optInfo" disabled placeholder="请输入执行内容" type="textarea" />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="cont-value">
      <div class="misr-title">计划类型执行说明</div>
      <div class="value-content" style="width: 100%">
        <el-table border :data="Projectdetails.vitMealPlanDesPos" style="width: 100%">
          <el-table-column align="center" label="标题" prop="desTitle" show-overflow-tooltip width="200" />
          <el-table-column align="center" label="标题内容" prop="desContent" show-overflow-tooltip />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      Projectdetails: this.value,
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.Projectdetails = newValue
      }
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 18px;
  .cont-value {
    display: flex;
    flex-wrap: wrap;
    min-height: 40px;
    align-items: flex-start;
    margin-bottom: 15px;
    .misr-title {
      color: #909090;
      font-size: 14px;
      margin-right: 9px;
      margin-bottom: 10px;
    }
    .value-content {
      font-size: 14px;
      color: #000;
    }
  }
  ::v-deep {
    .el-date-editor {
      width: 100px;
    }
    .el-input__inner,
    .el-textarea__inner {
      margin: 10px 0;
    }
  }
}
</style>