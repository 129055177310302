var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container",
      style: {
        background: `${_vm.controls[_vm.index].pageBgColor}`,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "content",
          style: {
            background: `${_vm.controls[_vm.index].componentBgColor}`,
            margin: `${_vm.controls[_vm.index].margin.top}px ${
              _vm.controls[_vm.index].margin.both
            }px ${_vm.controls[_vm.index].margin.bottom}px ${
              _vm.controls[_vm.index].margin.both
            }px `,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "health-card",
              style: {
                background: _vm.controls[_vm.index].elementBgColor,
                borderRadius: `${
                  _vm.controls[_vm.index].topElementAroundRadius
                }px ${_vm.controls[_vm.index].topElementAroundRadius}px ${
                  _vm.controls[_vm.index].bottomElementAroundRadius
                }px ${_vm.controls[_vm.index].bottomElementAroundRadius}px`,
              },
            },
            [
              _c("div", { staticClass: "thead_list" }, [
                _c("div", { staticClass: "lef_info" }, [
                  _c("img", { attrs: { alt: "", src: _vm.picUrl } }),
                ]),
                _vm._m(0),
              ]),
              _c("div", { staticClass: "thead_bottom" }, [
                _vm._v(
                  "擅长: 慢性疾病检查诊断，尤其控长治疗糖尿病、心脑血管疾病等各种身休问题。"
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "health-card",
              style: {
                background: _vm.controls[_vm.index].elementBgColor,
                borderRadius: `${
                  _vm.controls[_vm.index].topElementAroundRadius
                }px ${_vm.controls[_vm.index].topElementAroundRadius}px ${
                  _vm.controls[_vm.index].bottomElementAroundRadius
                }px ${_vm.controls[_vm.index].bottomElementAroundRadius}px`,
              },
            },
            [
              _c("div", { staticClass: "thead_list" }, [
                _c("div", { staticClass: "lef_info" }, [
                  _c("img", { attrs: { alt: "", src: _vm.picUrl } }),
                ]),
                _vm._m(1),
              ]),
              _c("div", { staticClass: "thead_bottom" }, [
                _vm._v(
                  "擅长: 慢性疾病检查诊断，尤其控长治疗糖尿病、心脑血管疾病等各种身休问题。"
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ref_info" }, [
      _c("div", { staticClass: "health_info" }, [
        _c("div", { staticClass: "lef" }, [
          _c("div", { staticClass: "title" }, [_vm._v("刘医生")]),
          _c("div", { staticClass: "mian_info" }, [_vm._v("主治医师")]),
        ]),
        _c("div", { staticClass: "ref", staticStyle: { color: "red" } }, [
          _vm._v("忙碌"),
        ]),
      ]),
      _c("div", { staticClass: "Administrative" }, [_vm._v("慢性病科")]),
      _c("div", { staticClass: "nosocomium" }, [_vm._v("重庆第一人民医院")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ref_info" }, [
      _c("div", { staticClass: "health_info" }, [
        _c("div", { staticClass: "lef" }, [
          _c("div", { staticClass: "title" }, [_vm._v("刘医生")]),
          _c("div", { staticClass: "mian_info" }, [_vm._v("主治医师")]),
        ]),
        _c("div", { staticClass: "ref", staticStyle: { color: "red" } }, [
          _vm._v("忙碌"),
        ]),
      ]),
      _c("div", { staticClass: "Administrative" }, [_vm._v("慢性病科")]),
      _c("div", { staticClass: "nosocomium" }, [_vm._v("重庆第一人民医院")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }