var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container page-contaier" },
    [
      _c(
        "div",
        { staticClass: "steps" },
        [
          _c(
            "el-steps",
            { attrs: { active: _vm.activeIndex, "finish-status": "success" } },
            [
              _c("el-step", { attrs: { title: "基本设置" } }),
              _c("el-step", { attrs: { title: "参与设置" } }),
              _c("el-step", { attrs: { title: "奖品设置" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeIndex === 0,
              expression: "activeIndex === 0",
            },
          ],
        },
        [
          _c("div", { staticClass: "list-item" }, [
            _vm._m(0),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("实例名称：")]),
              _c("div", { staticClass: "line-right" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.step1Data.instName,
                      expression: "step1Data.instName",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "input",
                  attrs: { placeholder: "请输入实例名称", type: "text" },
                  domProps: { value: _vm.step1Data.instName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.step1Data,
                        "instName",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("实例图片：")]),
              _c(
                "div",
                { staticClass: "line-right" },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        accept: ".jpg,.png,.jpeg,.mp4",
                        action: _vm.uploadParams.uploadURL,
                        "file-list": _vm.instLogoList,
                        headers: { Authorization: _vm.uploadParams.token },
                        limit: 1,
                        "list-type": "picture-card",
                        multiple: false,
                        "on-preview": _vm.handlePreView,
                        "on-remove": _vm.handleActLogoRemove,
                        "on-success": _vm.handleActLogoSuccess,
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [
                _vm._v("实例背景图片："),
              ]),
              _c(
                "div",
                { staticClass: "line-right" },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        accept: ".jpg,.png,.jpeg,.mp4",
                        action: _vm.uploadParams.uploadURL,
                        "file-list": _vm.instBgList,
                        headers: { Authorization: _vm.uploadParams.token },
                        limit: 1,
                        "list-type": "picture-card",
                        multiple: false,
                        "on-preview": _vm.handlePreView,
                        "on-remove": _vm.handleActBgRemove,
                        "on-success": _vm.handleActBgSuccess,
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("实例时间：")]),
              _c(
                "div",
                { staticClass: "line-right" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      align: "center",
                      "end-placeholder": "请选择活动结束时间",
                      "picker-options": _vm.pickerOptions,
                      "range-separator": "至",
                      "start-placeholder": "请选择活动开始时间",
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    on: { change: _vm.dateChange },
                    model: {
                      value: _vm.instTimeList,
                      callback: function ($$v) {
                        _vm.instTimeList = $$v
                      },
                      expression: "instTimeList",
                    },
                  }),
                ],
                1
              ),
              _c(
                "span",
                { staticStyle: { "margin-left": "20px", color: "#ccc" } },
                [_vm._v("不得早于或晚于活动时间")]
              ),
            ]),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("发布时间：")]),
              _c(
                "div",
                { staticClass: "line-right" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.step1Data.publishType,
                        callback: function ($$v) {
                          _vm.$set(_vm.step1Data, "publishType", $$v)
                        },
                        expression: "step1Data.publishType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("立即发布"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("定时发布"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm.step1Data.publishType == 2
              ? _c(
                  "div",
                  { staticClass: "second-item" },
                  [
                    _c("div", { staticClass: "item-title" }, [
                      _vm._v("选择时间："),
                    ]),
                    _c("el-date-picker", {
                      attrs: {
                        align: "center",
                        placeholder: "选择日期时间",
                        type: "datetime",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                      },
                      model: {
                        value: _vm.step1Data.publishTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.step1Data, "publishTime", $$v)
                        },
                        expression: "step1Data.publishTime",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("实例样式：")]),
              _vm.allActType == 10
                ? _c(
                    "div",
                    { staticClass: "line-right" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.step1Data.instStyle,
                            callback: function ($$v) {
                              _vm.$set(_vm.step1Data, "instStyle", $$v)
                            },
                            expression: "step1Data.instStyle",
                          },
                        },
                        _vm._l(_vm.instStyle, function (item, index) {
                          return _c(
                            "el-radio",
                            {
                              key: index,
                              attrs: { label: item.dictKey.toString() },
                            },
                            [_vm._v(" " + _vm._s(item.dictValue) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.allActType == 20
                ? _c(
                    "div",
                    { staticClass: "line-right" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.step1Data.instStyle,
                            callback: function ($$v) {
                              _vm.$set(_vm.step1Data, "instStyle", $$v)
                            },
                            expression: "step1Data.instStyle",
                          },
                        },
                        _vm._l(_vm.instStyle2, function (item, index) {
                          return _c(
                            "el-radio",
                            {
                              key: index,
                              attrs: { label: item.dictKey.toString() },
                            },
                            [_vm._v(" " + _vm._s(item.dictValue) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("实例期数：")]),
              _c(
                "div",
                { staticClass: "line-right" },
                [
                  _c("el-input-number", {
                    attrs: {
                      label: "请输入实例期数",
                      min: 1,
                      step: 1,
                      "step-strictly": true,
                    },
                    model: {
                      value: _vm.step1Data.totalActCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.step1Data, "totalActCount", $$v)
                      },
                      expression: "step1Data.totalActCount",
                    },
                  }),
                  _vm._v("  期 "),
                ],
                1
              ),
            ]),
            _vm.$route.query.allActType == 20
              ? _c("div", { staticClass: "item-line" }, [
                  _c("div", { staticClass: "line-left" }, [
                    _vm._v("开盒金额："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "line-right" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        on: {
                          change: function ($event) {
                            return _vm.checkInput($event)
                          },
                        },
                        model: {
                          value: _vm.step1Data.boxAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.step1Data, "boxAmount", $$v)
                          },
                          expression: "step1Data.boxAmount",
                        },
                      }),
                      _vm._v("   元 "),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.$route.query.allActType == 20
              ? _c("div", { staticClass: "item-line" }, [
                  _c("div", { staticClass: "line-left" }, [
                    _vm._v("回收金额："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "line-right" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        on: {
                          change: function ($event) {
                            return _vm.checkInput($event)
                          },
                        },
                        model: {
                          value: _vm.step1Data.boxRecoverAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.step1Data, "boxRecoverAmount", $$v)
                          },
                          expression: "step1Data.boxRecoverAmount",
                        },
                      }),
                      _vm._v("   元 "),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("参与方式：")]),
              _c(
                "div",
                { staticClass: "line-right" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.step1Data.instWay,
                        callback: function ($$v) {
                          _vm.$set(_vm.step1Data, "instWay", $$v)
                        },
                        expression: "step1Data.instWay",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("线上参与"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("线下参与"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "second-item" },
              [
                _c("div", [_vm._v("参与份数达到 ")]),
                _c("el-input-number", {
                  attrs: { min: 1, step: 1, "step-strictly": true },
                  model: {
                    value: _vm.step1Data.personCount,
                    callback: function ($$v) {
                      _vm.$set(_vm.step1Data, "personCount", $$v)
                    },
                    expression: "step1Data.personCount",
                  },
                }),
                _c("div", [_vm._v(" 份")]),
                _c("span", [_vm._v("达到开奖时间参与份数满开奖")]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "second-item" },
              [
                _c("div", [_vm._v("最低参与份数 ")]),
                _c("el-input-number", {
                  attrs: { min: 1, step: 1, "step-strictly": true },
                  model: {
                    value: _vm.step1Data.lowerPersonLimit,
                    callback: function ($$v) {
                      _vm.$set(_vm.step1Data, "lowerPersonLimit", $$v)
                    },
                    expression: "step1Data.lowerPersonLimit",
                  },
                }),
                _c("div", [_vm._v(" 份")]),
                _c("span", [_vm._v("参与份数满足最低开奖份数开奖")]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "item-line",
                staticStyle: { "align-items": "stretch" },
              },
              [
                _c("div", { staticClass: "line-left" }, [_vm._v("实例说明：")]),
                _c("div", { staticClass: "line-right" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.remark,
                        expression: "remark",
                      },
                    ],
                    staticStyle: { border: "1px solid #ccc" },
                    attrs: { cols: "50", rows: "10" },
                    domProps: { value: _vm.remark },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.remark = $event.target.value
                        },
                        _vm.handleInput,
                      ],
                    },
                  }),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeIndex === 1,
              expression: "activeIndex === 1",
            },
          ],
        },
        [
          _c("div", { staticClass: "list-item" }, [
            _vm._m(1),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("参与用户：")]),
              _c(
                "div",
                { staticClass: "line-right" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.step2Data.partType,
                        callback: function ($$v) {
                          _vm.$set(_vm.step2Data, "partType", $$v)
                        },
                        expression: "step2Data.partType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("全部用户"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("部分用户"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm.step2Data.partType == 2
              ? _c(
                  "div",
                  { staticClass: "second-item" },
                  [
                    _c("div", { staticClass: "item-title" }, [
                      _vm._v("最低身份 "),
                    ]),
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.step2Data.limitUserLvl,
                          callback: function ($$v) {
                            _vm.$set(_vm.step2Data, "limitUserLvl", $$v)
                          },
                          expression: "step2Data.limitUserLvl",
                        },
                      },
                      _vm._l(_vm.limitUserLvl, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.dictValue, value: item.dictKey },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "item-line",
                staticStyle: { "align-items": "stretch" },
              },
              [
                _c("div", { staticClass: "line-left" }, [_vm._v("参与条件：")]),
                _c("div", { staticClass: "right-list" }, [
                  _c(
                    "div",
                    { staticClass: "right-item" },
                    [
                      _c("span", [_vm._v("每次抽奖消耗积分： ")]),
                      _c("el-input-number", {
                        attrs: { min: 0, step: 1, "step-strictly": "" },
                        model: {
                          value: _vm.step2Data.consumeIntegral,
                          callback: function ($$v) {
                            _vm.$set(_vm.step2Data, "consumeIntegral", $$v)
                          },
                          expression: "step2Data.consumeIntegral",
                        },
                      }),
                      _c("span", [_vm._v(" 分")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right-item" },
                    [
                      _c("span", [_vm._v("每次抽奖消耗金豆： ")]),
                      _c("el-input-number", {
                        attrs: { min: 0, step: 1, "step-strictly": "" },
                        model: {
                          value: _vm.step2Data.consumeGoldenBean,
                          callback: function ($$v) {
                            _vm.$set(_vm.step2Data, "consumeGoldenBean", $$v)
                          },
                          expression: "step2Data.consumeGoldenBean",
                        },
                      }),
                      _c("span", [_vm._v(" 颗")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right-item" },
                    [
                      _c("span", [_vm._v("每次抽奖消耗余额： ")]),
                      _c("el-input-number", {
                        attrs: { min: 0, step: 0.1, "step-strictly": "" },
                        model: {
                          value: _vm.step2Data.consumeBalance,
                          callback: function ($$v) {
                            _vm.$set(_vm.step2Data, "consumeBalance", $$v)
                          },
                          expression: "step2Data.consumeBalance",
                        },
                      }),
                      _c("span", [_vm._v(" 元")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right-item" },
                    [
                      _c("span", [_vm._v("每次抽奖消耗现金： ")]),
                      _c("el-input-number", {
                        attrs: { min: 0, step: 0.1, "step-strictly": "" },
                        model: {
                          value: _vm.step2Data.consumeCash,
                          callback: function ($$v) {
                            _vm.$set(_vm.step2Data, "consumeCash", $$v)
                          },
                          expression: "step2Data.consumeCash",
                        },
                      }),
                      _c("span", [_vm._v(" 元")]),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("参与份数：")]),
              _c(
                "div",
                { staticClass: "line-right" },
                [
                  _c(
                    "el-radio-group",
                    { attrs: { value: "1" } },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("全部"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "second-item" },
              [
                _c("div", { staticClass: "item-title" }, [_vm._v("每人一共")]),
                _c("el-input-number", {
                  attrs: { min: 1, step: 1, "step-strictly": "" },
                  model: {
                    value: _vm.step2Data.shareLimit,
                    callback: function ($$v) {
                      _vm.$set(_vm.step2Data, "shareLimit", $$v)
                    },
                    expression: "step2Data.shareLimit",
                  },
                }),
                _c("span", [_vm._v(" 份")]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "20px",
                  "margin-left": "100px",
                  color: "#bbb",
                  "font-size": "14px",
                },
              },
              [_vm._v("参与份数可用于增加抽奖概率")]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeIndex === 2,
              expression: "activeIndex === 2",
            },
          ],
        },
        [
          _c("div", { staticClass: "list-item" }, [
            _vm._m(2),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("奖品列表：")]),
              _c(
                "div",
                { staticClass: "line-right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.addLevel },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "table-wrapper" },
              [
                _c(
                  "el-table",
                  { attrs: { border: "", data: _vm.prizeList, stripe: "" } },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "奖品名称",
                        prop: "itemName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "奖品等级",
                        prop: "itemLevel",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "排序",
                        prop: "itemSort",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "中奖概率",
                        prop: "itemRate",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "奖品状态",
                        prop: "itemStatus",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getItemStatus(row.itemStatus)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "创建时间",
                        prop: "createTime",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm._f("parseTime")(row.createTime))
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "操作" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditPrize(row)
                                        },
                                      },
                                    },
                                    [_vm._v("奖品明细")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEditPrizeDetailLevel(
                                            row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDeletePrize(row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    "current-page": _vm.prizeQueryForm.currentPage,
                    layout: _vm.layout,
                    "page-size": _vm.prizeQueryForm.pageSize,
                    total: _vm.prizeTotal,
                  },
                  on: {
                    "current-change": _vm.handlePrizeCurrentChange,
                    "size-change": _vm.handlePrizeSizeChange,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "btns" },
        [
          _vm.activeIndex == 0
            ? _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.back } },
                [_vm._v("返回")]
              )
            : _vm._e(),
          _vm.activeIndex != 0
            ? _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.lastStep } },
                [_vm._v("上一步")]
              )
            : _vm._e(),
          _vm.activeIndex != 2
            ? _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.stepNextBtnLoading,
                    size: "small",
                    type: "primary",
                  },
                  on: { click: _vm.nextStep },
                },
                [_vm._v("下一步")]
              )
            : _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.createBtnLoading,
                    size: "small",
                    type: "primary",
                  },
                  on: { click: _vm.handleCreate },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.pageStatus == 1 ? "保存" : "创建") + " "
                  ),
                ]
              ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${
              _vm.prizeLevelData.isEdit == 1 ? "编辑" : "添加"
            }奖品等级`,
            visible: _vm.addLevelVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addLevelVisible = $event
            },
            close: _vm.coloseAddLevelDialog,
          },
        },
        [
          _c("div", { staticClass: "list-item" }, [
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("等级名称：")]),
              _c(
                "div",
                { staticClass: "line-right" },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入等级名称",
                      size: "normal",
                    },
                    model: {
                      value: _vm.prizeLevelData.itemName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.prizeLevelData,
                          "itemName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "prizeLevelData.itemName",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("排序：")]),
              _c(
                "div",
                { staticClass: "line-right" },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, step: 1, "step-strictly": "" },
                    model: {
                      value: _vm.prizeLevelData.itemSort,
                      callback: function ($$v) {
                        _vm.$set(_vm.prizeLevelData, "itemSort", $$v)
                      },
                      expression: "prizeLevelData.itemSort",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("奖品等级：")]),
              _c(
                "div",
                { staticClass: "line-right" },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, step: 1, "step-strictly": "" },
                    model: {
                      value: _vm.prizeLevelData.itemLevel,
                      callback: function ($$v) {
                        _vm.$set(_vm.prizeLevelData, "itemLevel", $$v)
                      },
                      expression: "prizeLevelData.itemLevel",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "item-line" }, [
              _c("div", { staticClass: "line-left" }, [_vm._v("中奖概率：")]),
              _c(
                "div",
                { staticClass: "line-right" },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, step: 1, "step-strictly": "" },
                    model: {
                      value: _vm.prizeLevelData.itemRate,
                      callback: function ($$v) {
                        _vm.$set(_vm.prizeLevelData, "itemRate", $$v)
                      },
                      expression: "prizeLevelData.itemRate",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.addLevelBtnLoading, type: "primary" },
                  on: { click: _vm.handleAddPrize },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "奖品明细",
            visible: _vm.prizeDetailVisible,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.prizeDetailVisible = $event
            },
            close: _vm.colosePrizeDetailDialog,
          },
        },
        [
          _c(
            "div",
            { staticClass: "list-item" },
            [
              _c("div", { staticClass: "item-line" }, [
                _c("div", { staticClass: "line-left" }, [_vm._v("奖品列表：")]),
                _c(
                  "div",
                  { staticClass: "line-right" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.handleAddGoodsDetail },
                      },
                      [_vm._v("添加")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-table",
                {
                  attrs: { border: "", data: _vm.prizeDetailList, stripe: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", label: "图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "div",
                              [
                                _c("el-image", {
                                  attrs: { fit: "fill", src: row.goodsImage },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "奖品信息",
                      prop: "goodsName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "奖品类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.getDetailGoodsType(row.goodsType))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "提示语",
                      prop: "winGoodsTips",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "价值",
                      prop: "goodsAmount",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm._f("numFormat")(row.goodsAmount))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "数量",
                      prop: "goodsCount",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "奖项权重",
                      prop: "goodsRate",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEditPrizeDetail(row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDeletePrizeDetail(row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.prizeDetailQueryForm.currentPage,
                  layout: _vm.layout,
                  "page-size": _vm.prizeDetailQueryForm.pageSize,
                  total: _vm.prizeDetailTotal,
                },
                on: {
                  "current-change": _vm.handlePrizeDetailCurrentChange,
                  "size-change": _vm.handlePrizeDetailSizeChange,
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.prizeDetailVisible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": "",
                title: `${
                  _vm.addPrizeDetailDialogStatus == 1 ? "编辑" : "添加"
                }奖品`,
                visible: _vm.addPrizeDetailVisible,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.addPrizeDetailVisible = $event
                },
                close: _vm.addPrizeDetailClose,
              },
            },
            [
              _c("div", { staticClass: "list-item" }, [
                _c("div", { staticClass: "item-line" }, [
                  _c("div", { staticClass: "line-left" }, [_vm._v("奖品：")]),
                  _c(
                    "div",
                    { staticClass: "line-right" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.goodsDetail.goodsType,
                            callback: function ($$v) {
                              _vm.$set(_vm.goodsDetail, "goodsType", $$v)
                            },
                            expression: "goodsDetail.goodsType",
                          },
                        },
                        _vm._l(_vm.goodsType, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.dictValue,
                              value: item.dictKey.toString(),
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.allActType == 20
                  ? _c("div", { staticClass: "list-item" }, [
                      _c("div", { staticClass: "item-line" }, [
                        _c("div", { staticClass: "line-left" }),
                        _c(
                          "div",
                          { staticClass: "line-right" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { plain: "", type: "primary" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleGoods($event)
                                  },
                                },
                              },
                              [_vm._v("选择商品")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.allActType == 20
                  ? _c("div", { staticClass: "item-line" }, [
                      _c("div", { staticClass: "line-left" }, [
                        _vm._v("奖品名称："),
                      ]),
                      _c("div", { staticClass: "line-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.goodsDetail.goodsName,
                              expression: "goodsDetail.goodsName",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "input",
                          attrs: { disabled: "", type: "text" },
                          domProps: { value: _vm.goodsDetail.goodsName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.goodsDetail,
                                "goodsName",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _vm.allActType == 20
                  ? _c("div", { staticClass: "item-line" }, [
                      _c("div", { staticClass: "line-left" }, [
                        _vm._v("奖品图片："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "line-right" },
                        [
                          _c("el-image", {
                            staticStyle: {
                              width: "100px",
                              height: "100px",
                              cursor: "pointer",
                            },
                            attrs: {
                              fit: "fill",
                              src: _vm.goodsDetail.goodsImage,
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.allActType != 20
                  ? _c("div", { staticClass: "item-line" }, [
                      _c("div", { staticClass: "line-left" }, [
                        _vm._v("奖品名称："),
                      ]),
                      _c("div", { staticClass: "line-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.goodsDetail.goodsName,
                              expression: "goodsDetail.goodsName",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "input",
                          attrs: { type: "text" },
                          domProps: { value: _vm.goodsDetail.goodsName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.goodsDetail,
                                "goodsName",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _vm.allActType != 20
                  ? _c("div", { staticClass: "item-line" }, [
                      _c("div", { staticClass: "line-left" }, [
                        _vm._v("奖品图片："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "line-right" },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                accept: ".jpg,.png,.jpeg,.mp4",
                                action: _vm.uploadParams.uploadURL,
                                "file-list": _vm.goodsImg,
                                headers: {
                                  Authorization: _vm.uploadParams.token,
                                },
                                limit: 1,
                                "list-type": "picture-card",
                                multiple: false,
                                "on-preview": _vm.handlePreView,
                                "on-remove": _vm.handleGoodsImgRemove,
                                "on-success": _vm.handleGoodsImgSuccess,
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "item-line" }, [
                  _c("div", { staticClass: "line-left" }, [
                    _vm._v("奖品价值："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "line-right" },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, step: 0.1 },
                        model: {
                          value: _vm.goodsDetail.goodsAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.goodsDetail, "goodsAmount", $$v)
                          },
                          expression: "goodsDetail.goodsAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm.goodsDetail.goodsType == 1 && _vm.allActType != 20
                  ? _c("div", { staticClass: "item-line" }, [
                      _c("div", { staticClass: "line-left" }, [
                        _vm._v("额外支付金额："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "line-right" },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, step: 0.1 },
                            model: {
                              value: _vm.goodsDetail.needPayAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.goodsDetail, "needPayAmount", $$v)
                              },
                              expression: "goodsDetail.needPayAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "item-line" }, [
                  _c("div", { staticClass: "line-left" }, [
                    _vm._v("奖品数量："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "line-right" },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, step: 1, "step-strictly": "" },
                        model: {
                          value: _vm.goodsDetail.goodsCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.goodsDetail, "goodsCount", $$v)
                          },
                          expression: "goodsDetail.goodsCount",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "item-line" }, [
                  _c("div", { staticClass: "line-left" }, [
                    _vm._v("奖品权重："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "line-right" },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1, step: 1, "step-strictly": "" },
                        model: {
                          value: _vm.goodsDetail.goodsRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.goodsDetail, "goodsRate", $$v)
                          },
                          expression: "goodsDetail.goodsRate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "item-line" }, [
                  _c("div", { staticClass: "line-left" }, [_vm._v("提示语：")]),
                  _c("div", { staticClass: "line-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.goodsDetail.winGoodsTips,
                          expression: "goodsDetail.winGoodsTips",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text" },
                      domProps: { value: _vm.goodsDetail.winGoodsTips },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.goodsDetail,
                            "winGoodsTips",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm.goodsDetail.goodsType != 1
                  ? _c("div", { staticClass: "item-line" }, [
                      _c("div", { staticClass: "line-left" }, [
                        _vm._v("发放方式："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "line-right" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.goodsDetail.disburseMent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.goodsDetail, "disburseMent", $$v)
                                },
                                expression: "goodsDetail.disburseMent",
                              },
                            },
                            _vm._l(_vm.disburseMent, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.dictValue,
                                  value: item.dictKey.toString(),
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _c("div", { staticClass: "item-line" }, [
                      _c("div", { staticClass: "line-left" }, [
                        _vm._v("发放方式："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "line-right" },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              on: { change: _vm.handleDeliveryTypeChange },
                              model: {
                                value: _vm.deliveryTypeList,
                                callback: function ($$v) {
                                  _vm.deliveryTypeList = $$v
                                },
                                expression: "deliveryTypeList",
                              },
                            },
                            _vm._l(_vm.deliveryType, function (item, index) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: index,
                                  attrs: { label: item.dictKey.toString() },
                                },
                                [_vm._v(" " + _vm._s(item.dictValue) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                _vm.goodsDetail.goodsType == 1
                  ? _c("div", { staticClass: "item-line" }, [
                      _c("div", { staticClass: "line-left" }, [
                        _vm._v("是否包邮："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "line-right" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "normal" },
                              model: {
                                value: _vm.goodsDetail.postalPayType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.goodsDetail,
                                    "postalPayType",
                                    $$v
                                  )
                                },
                                expression: "goodsDetail.postalPayType",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "1", value: "1" } },
                                [_vm._v("包邮")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "2", value: "2" } },
                                [_vm._v("固定邮费")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.goodsDetail.goodsType == 1 &&
                _vm.goodsDetail.postalPayType == 2
                  ? _c(
                      "div",
                      { staticClass: "second-item" },
                      [
                        _c("el-input-number", {
                          attrs: { min: 0, step: 0.1, "step-strictly": "" },
                          model: {
                            value: _vm.goodsDetail.postageAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.goodsDetail, "postageAmount", $$v)
                            },
                            expression: "goodsDetail.postageAmount",
                          },
                        }),
                        _c("span", [_vm._v(" 元")]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.addPrizeDetailVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSubmitPrizeDetail },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片详情",
            visible: _vm.imgDetailVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.imgDetailVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                height: "100%",
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
              },
            },
            [_c("el-image", { attrs: { fit: "fill", src: _vm.imgUrl } })],
            1
          ),
        ]
      ),
      _c("change-goods", {
        ref: "changeGoods",
        on: { change: _vm.changeGood },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-title" }, [
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "title-text" }, [_vm._v("实例设置")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-title" }, [
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "title-text" }, [_vm._v("参与设置")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-title" }, [
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "title-text" }, [_vm._v("奖品设置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }