var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改收货地址",
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            "label-width": "80px",
            model: _vm.postForm,
            rules: _vm.postFormRules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "收货人", prop: "consignee" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.postForm.consignee,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.postForm,
                      "consignee",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "postForm.consignee",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话", prop: "phone" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "11",
                  oninput: "value=value.replace(/[^\\d]/g,'')",
                },
                model: {
                  value: _vm.postForm.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "phone", _vm._n($$v))
                  },
                  expression: "postForm.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收货地址", prop: "provinces" } },
            [
              _c("el-cascader", {
                staticClass: "full-width",
                staticStyle: { width: "80%" },
                attrs: {
                  options: _vm.areaSelectData,
                  placeholder: "请选择地址",
                  props: {
                    children: "children",
                    label: "areaName",
                    value: "areaName",
                  },
                  size: "large",
                },
                model: {
                  value: _vm.postForm.provinces,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.postForm,
                      "provinces",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "postForm.provinces",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "receivingAddress" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入详细地址" },
                model: {
                  value: _vm.postForm.receivingAddress,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.postForm,
                      "receivingAddress",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "postForm.receivingAddress",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }