<template>
  <div class="register-container">
    <el-table border :data="tableData" style="width: 100%">
      <el-table-column align="center" label="用户信息" prop="goodsSpu">
        <template #default="scope">
          <div class="ym_userinfo">
            <div class="ym_lefpic">
              <el-image 
                :src="scope.row.userInfo.headimgUrl" 
                style="width: 50px; height: 50px"/>
            </div>
            <div class="ym_ref_user">
              <div class="ym_username">昵称：{{ scope.row.userInfo.nickName }}</div>
              <div class="ym_code">编号：{{ scope.row.userInfo.userCode }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="商品信息" prop="name">
        <template #default="scope">
          <div class="ym_userinfo">
            <div class="ym_lefpic">
              <el-image 
                :src="scope.row.goodsSpu.picUrls[0]" 
                style="width: 50px; height: 50px"/>
            </div>
            <div class="ym_ref_user">
              <div class="ym_username">{{ scope.row.goodsSpu.name }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="createTime" />
    </el-table>
    <div class="pant">
      <el-pagination 
      background 
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange" 
      @size-change="handleSizeChange" />
    </div>
  </div>
</template>

<script>
  import { getObj } from '@/api/mall/users/userfootprint'
  export default {
    data() {
      return {
        tableData: [],
        total:0,
        form: {
          size: 10,
          current: 1,
        },
      }
    },
    created() {
      this.getObj()
    },
    methods: {
      getObj() {
        const lts={
          size:this.form.size,
          current:this.form.current
        }
        getObj({ ...lts }).then((res) => {
          this.tableData = res.data.records
          this.form.size=res.data.size
          this.form.current=res.data.current
          this.total=res.data.total
        })
      },
      handleSizeChange(e){
        this.form.size=e
        this.getObj()
      },
      handleCurrentChange(e){
        this.form.current=e
        this.getObj()
      }
    },
  }
</script>

<style lang="scss" scoped>
.pant{
  display: flex;
  justify-content: center;
}
.ym_userinfo{
  display: flex;
  .ym_lefpic{
    margin-right: 10px;
    font-size: 14px;
  }
  .ym_ref_user{
    text-align: left;
  }
}
</style>
