<template>
  <div class="container">
    <el-dialog :before-close="handleClose" :title="labname + '血压'" :visible.sync="dialogVisible" width="50%">
      <span>
        <el-form ref="formName" label-width="120px" :model="form">
          <el-form-item label="会员名称">
            <el-input v-model="form.name" placeholder="请输入 会员名称" />
          </el-form-item>
          <el-form-item label="设备类型">
            <el-input v-model="form.name" placeholder="请选择 设备类型" />
          </el-form-item>
          <el-form-item label="设备SN码">
            <el-input v-model="form.name" placeholder="请输入 设备SN码" />
          </el-form-item>
          <el-form-item label="测量日期">
            <el-input v-model="form.name" placeholder="请输入 测量日期" />
          </el-form-item>
          <el-form-item label="测量时间">
            <el-input v-model="form.name" placeholder="请输入 测量时间" />
          </el-form-item>
          <el-form-item label="收缩压mmHg">
            <el-input v-model="form.name" placeholder="请输入 收缩压mmHg" />
          </el-form-item>
          <el-form-item label="舒张压mmHg">
            <el-input v-model="form.name" placeholder="请输入 舒张压mmHg" />
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    labname: {
      type: String,
    },
  },
  data() {
    return {
      form: {},
      dialogVisible: false,
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.dialogVisible = true
      }
    },
  },
  methods: {
    handleClose() {
      // this.$refs.formName.resetFields()
      this.dialogVisible = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #f2f2f2;
  }
}
</style>