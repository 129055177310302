var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "info_listuser" }, [
          _c("div", { staticClass: "lef" }, [_vm._v("基本信息")]),
          _c(
            "div",
            { staticClass: "ref", on: { click: _vm.ediinfo } },
            [
              _c("vab-icon", {
                staticStyle: { "font-size": "20px" },
                attrs: { icon: "edit-2-line" },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-descriptions",
              { attrs: { title: "" } },
              [
                _c("el-descriptions-item", { attrs: { label: "姓名" } }, [
                  _vm._v(_vm._s(_vm.value.userName)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "性别" } }, [
                  _vm._v(_vm._s(_vm.value.userSex)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "出生日期" } }, [
                  _vm._v(_vm._s(_vm.value.userYear)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "年龄" } }, [
                  _vm._v(_vm._s(_vm.value.age)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "手机号码" } }, [
                  _vm._v(_vm._s(_vm.value.userPhone)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "身高（cm）" } }, [
                  _vm._v(_vm._s(_vm.value.userHeight)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "体重（KG）" } }, [
                  _vm._v(_vm._s(_vm.value.userWeight)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "BMI" } }, [
                  _vm._v(_vm._s(_vm.value.bmi)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "臀围（cm）" } }, [
                  _vm._v(_vm._s(_vm.value.circumference)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "腰围（cm）" } }, [
                  _vm._v(_vm._s(_vm.value.waistline)),
                ]),
                _c(
                  "el-descriptions-item",
                  { attrs: { label: "会员标签" } },
                  _vm._l(_vm.value.tags, function (ym, index) {
                    return _c("el-tag", { key: index }, [
                      _vm._v(
                        " " + _vm._s(ym && ym.tagName ? ym.tagName : "") + " "
                      ),
                    ])
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "info_listuser" }, [
          _c("div", { staticClass: "lef" }, [_vm._v("详细信息")]),
          _c(
            "div",
            { staticClass: "ref", on: { click: _vm.editdatil } },
            [
              _c("vab-icon", {
                staticStyle: { "font-size": "20px" },
                attrs: { icon: "edit-2-line" },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-descriptions",
              { attrs: { title: "" } },
              [
                _c("el-descriptions-item", { attrs: { label: "身份证号码" } }, [
                  _vm._v(_vm._s(_vm.value.idCard)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "民族" } }, [
                  _vm._v(_vm._s(_vm.value.familyName)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "血型" } }, [
                  _vm._v(_vm._s(_vm.getBloodType(_vm.value.bloodType))),
                ]),
                _c("el-descriptions-item", { attrs: { label: "职业" } }, [
                  _vm._v(_vm._s(_vm.value.profession)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "婚姻状况" } }, [
                  _vm._v(_vm._s(_vm.getMar(_vm.value.marStatus))),
                ]),
                _c("el-descriptions-item", { attrs: { label: "电子邮件" } }, [
                  _vm._v(_vm._s(_vm.value.email)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "紧急联系人" } }, [
                  _vm._v(_vm._s(_vm.value.emerName)),
                ]),
                _c(
                  "el-descriptions-item",
                  { attrs: { label: "紧急联系人电话" } },
                  [_vm._v(_vm._s(_vm.value.emerPhone))]
                ),
                _c("el-descriptions-item", { attrs: { label: "工作单位" } }, [
                  _vm._v(_vm._s(_vm.value.workUnit)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "所在地区" } }, [
                  _vm._v(
                    _vm._s(_vm.value.province) +
                      _vm._s(_vm.value.city) +
                      _vm._s(_vm.value.county)
                  ),
                ]),
                _c("el-descriptions-item", { attrs: { label: "详细地址" } }, [
                  _vm._v(_vm._s(_vm.value.addressDetail)),
                ]),
                _c("el-descriptions-item", { attrs: { label: "备注" } }, [
                  _vm._v(" " + _vm._s(_vm.value.remark) + " "),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("editeditiing", {
        attrs: { show: _vm.editeditiingshow, userlist: _vm.userinfo },
        on: { cloeedit: _vm.editinit },
      }),
      _c("editDetails", {
        attrs: { show: _vm.editdetailshow, userlist: _vm.userinfo },
        on: { cloeeditdia: _vm.editdatilsclose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }