var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "siper",
      style: {
        background: _vm.controls[_vm.terminal].pageBgColor,
      },
    },
    [
      _c("div"),
      _c(
        "div",
        {
          staticClass: "banner",
          style: {
            margin: `0 ${_vm.controls[_vm.terminal].margin.both}px`,
            paddingTop: `${_vm.controls[_vm.terminal].margin.top}px`,
            paddingBottom: `${_vm.controls[_vm.terminal].margin.bottom}px`,
            background: _vm.controls[_vm.terminal].componentBgColor,
          },
        },
        [
          _c(
            "el-carousel",
            {
              style: {
                borderRadius: `${
                  _vm.controls[_vm.terminal].topAroundRadius
                }px ${_vm.controls[_vm.terminal].topAroundRadius}px ${
                  _vm.controls[_vm.terminal].bottomAroundRadius
                }px ${_vm.controls[_vm.terminal].bottomAroundRadius}px`,
              },
              attrs: { height: "132px" },
            },
            _vm._l(_vm.controls[_vm.terminal].list, function (ym, ind) {
              return _c(
                "el-carousel-item",
                { key: ind },
                [
                  _c("el-image", {
                    staticStyle: { width: "100%", height: "180px" },
                    attrs: { fit: _vm.cover(ym.imageMode), src: ym.imageUrl },
                  }),
                ],
                1
              )
            }),
            1
          ),
          _c("div", { staticClass: "swiper-pagination" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }