var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "el-form",
        { ref: "queryForm", attrs: { inline: true, model: _vm.queryForm } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "店铺：", prop: "shopId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择 店铺" },
                  model: {
                    value: _vm.queryForm.shopId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "shopId", $$v)
                    },
                    expression: "queryForm.shopId",
                  },
                },
                _vm._l(_vm.selection, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "类型：", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择 类型" },
                  model: {
                    value: _vm.queryForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "type", $$v)
                    },
                    expression: "queryForm.type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "代金券", value: "1" } }),
                  _c("el-option", { attrs: { label: "折扣券", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "到期类型：", prop: "expireType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择 到期类型" },
                  model: {
                    value: _vm.queryForm.expireType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "expireType", $$v)
                    },
                    expression: "queryForm.expireType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "领券后生效", value: "1" },
                  }),
                  _c("el-option", {
                    attrs: { label: "固定时间段", value: "2" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "适用商品：", prop: "suitType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择 适用商品" },
                  model: {
                    value: _vm.queryForm.suitType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "suitType", $$v)
                    },
                    expression: "queryForm.suitType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "全部商品", value: "1" } }),
                  _c("el-option", {
                    attrs: { label: "指定商品可用", value: "2" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    "native-type": "submit",
                    type: "warning",
                  },
                  on: { click: _vm.searchrow },
                },
                [_vm._v(" 搜索 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-refresh-left",
                    "native-type": "submit",
                    type: "primary",
                  },
                },
                [_vm._v(" 重置 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "m-b", staticStyle: { "margin-bottom": "5px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-plus",
                        "native-type": "submit",
                        type: "primary",
                      },
                      on: { click: _vm.addtab },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tableData },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              type: "index",
              width: "55",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "店铺", prop: "shopId" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", prop: "createTime" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "名称", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "排序", prop: "sort" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "库存", prop: "stock" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单金额",
              prop: "premiseAmount",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "类型", prop: "type" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 1
                      ? _c("el-tag", { attrs: { effect: "dark", type: "" } }, [
                          _vm._v("代金券"),
                        ])
                      : _vm._e(),
                    scope.row.type == 2
                      ? _c(
                          "el-tag",
                          { attrs: { effect: "dark", type: "danger" } },
                          [_vm._v("折扣券")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "到期类型", prop: "expireType" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.expireType == 1
                      ? _c("el-tag", { attrs: { effect: "dark", type: "" } }, [
                          _vm._v("领券后生效"),
                        ])
                      : _vm._e(),
                    scope.row.expireType == 2
                      ? _c(
                          "el-tag",
                          { attrs: { effect: "dark", type: "danger" } },
                          [_vm._v("固定时间段")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "是否启用", prop: "enable" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "active-value": "1",
                        "inactive-color": "#ff4949",
                      },
                      model: {
                        value: scope.row.enable,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "enable", $$v)
                        },
                        expression: "scope.row.enable",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "适用商品", prop: "suitType" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.suitType == 1
                      ? _c("el-tag", { attrs: { effect: "dark", type: "" } }, [
                          _vm._v("全部商品"),
                        ])
                      : _vm._e(),
                    scope.row.suitType == 2
                      ? _c(
                          "el-tag",
                          { attrs: { effect: "dark", type: "danger" } },
                          [_vm._v("指定商品可用")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", fixed: "right", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 5px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.view(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 5px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.view(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 5px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.del(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForms.current,
          layout: "total, sizes, prev, pager, next, jumper",
          "page-size": _vm.queryForms.size,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("Newcoupon", {
        attrs: { hideadd: _vm.hide, shoprow: _vm.selection },
        on: { closehide: _vm.closea },
      }),
      _c("viewdialog", {
        attrs: { hideview: _vm.hideView, viewrow: _vm.viewRow },
        on: { closeview: _vm.closeVa },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }