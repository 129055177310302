<template>
  <div>
    <el-dialog :before-close="handleClose" title="提示" :visible.sync="dialogVisible" width="40%">
      <span>
        <el-form label-width="100px" :model="addForm" :rules="rules">
          <el-form-item label="分配部门：" prop="mallcategory">
            <el-cascader
              v-model="addForm.mallcategory"
              :options="dataSourceLists"
              :props="{
                checkStrictly: true,
              }"
              @change="changemall"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.label }}</span>
                <span v-if="!node.isLeaf">({{ data.children.length }})</span>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item label="分配部门：">
            <el-radio-group v-model="chooseType">
              <el-radio label="1">分配部门</el-radio>
              <el-radio label="2">平均分配部门每个人</el-radio>
              <el-radio label="3">分配具体员工</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="指定员工：">
            <el-transfer
              v-model="appointUser"
              :data="appointData"
              filterable
              :props="{
                key: 'id',
                label: 'realName',
              }"
              :titles="['员工列表', '指定员工']"
              @change="teaChange"
              @left-check-change="leftChange"
            />
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { distrtionshow } from '@/api/mall/health/seas/seasManger'
// import { distributiondepartment } from '@/api/mall/seas/index'
import { uuUserSugarRecord } from '@/api/mall/seas/index'
import { orgTree } from '@/api/mall/role/orgManage'
export default {
  props: {
    shodist: {
      type: Boolean,
      default: () => {},
    },
    userid: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      appointUser: [],
      appointData: [],
      dataSourceLists: [],
      value: [],
      addForm: { mallcategory: '' },
      dialogVisible: this.showbloan,
      userinfo: this.userid,
      sysId: null,
      rules: {
        mallcategory: [{ required: true, message: '请选择部门', trigger: 'change' }],
      },
      chooseType: '1',
    }
  },
  watch: {
    shodist(newValue, oldValue) {
      if (newValue != oldValue) {
        this.dialogVisible = newValue
        this.fetchData()
      }
    },
    userid(newValue, oldValue) {
      if (newValue != oldValue) {
        this.userinfo = newValue
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    //公海管理分配客户(目前接口是正确的)
    uuUserSugarRecord() {
      let usid = []
      this.userinfo.forEach((item) => {
        usid.push(item.id)
      })
      const lts = {
        allot: usid,
        sysId: this.appointUser[0],
        chooseType: this.chooseType,
        orgId: this.sysId,
      }
      uuUserSugarRecord({ ...lts }).then((res) => {
        console.log(res.data)
      })
    },
    changemall(e) {
      this.getUserList(e[e.length - 1])
      this.sysId = e[e.length - 1]
    },
    async fetchData() {
      this.listLoading = true
      const parms = {
        current: this.current,
        size: this.size,
      }
      await orgTree(parms)
        .then((res) => {
          console.log(res.data)
          if (res.data) {
            this.menuListTable = res.data.records
            this.dataSourceLists = res.data.records
            this.dataSourceLists.map((obj) => {
              obj.label = obj.orgName
              obj.value = obj.id
              const fieldArr = obj.children
              const newFieldArr = fieldArr.map((element) => {
                element.label = element.orgName
                element.value = element.id
                element.children.forEach((item) => {
                  item.label = item.orgName
                  item.value = item.id
                  if (item.children != null) {
                    item.children.forEach((el) => {
                      el.label = el.orgName
                      el.value = el.id
                    })
                  }
                })
                return element
              })
              obj.fieldArr = newFieldArr
              return obj
            })
          }
        })
        .catch(() => {})
      this.listLoading = false
    },
    submit() {
      // if(this.chooseType=='分配部门'){
      //   let usid = []
      //   this.userinfo.forEach((item) => {
      //     usid.push(item.id)
      //   })
      //   const lts = {
      //     allot: usid,
      //     sysId: this.appointUser[0],
      //     orgId: this.sysId,
      //   }
      //   uuUserSugarRecord({ ...lts }).then((res) => {
      //     if (res.status == 200) {
      //       this.$message.success('分配成功')
      //       this.appointUser = []
      //       this.appointData=[]
      //       // this.getUserList()
      //       this.chooseType='分配部门'
      //       this.addForm = { mallcategory: '' }
      //       this.$emit('closedistr', false)
      //     } else {
      //       this.$message.error(res.msg)
      //       this.appointUser = []
      //       this.appointData=[]
      //       this.chooseType='分配部门'
      //       this.getUserList()
      //       this.$emit('closedistr', false)
      //     }
      //   })
      // }else{
      this.uuUserSugarRecord()
      // }
    },
    leftChange(e) {
      this.appointData.forEach((item) => {
        if (e[0] == item.id) item.disabled = false
        else item.disabled = true
      })
    },
    teaChange(e) {
      if (!e.length) {
        this.appointData.forEach((item) => {
          item.disabled = false
        })
      }
    },
    getUserList(e) {
      distrtionshow({ size: 10, current: 1, type: 2, orgId: e }).then((res) => {
        this.appointData = res.data.records
      })
    },
    handleClose() {
      this.addForm = { mallcategory: '' }
      this.appointData = []
      this.$emit('closedistr', false)
    },
    clear() {
      this.addForm = { mallcategory: '' }
      this.appointData = []
      this.$emit('closedistr', false)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-cascader .el-input {
  width: 500px;
}
</style>
