var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "custom-table",
      staticClass: "custom-table-container",
      class: { "vab-fullscreen": _vm.isFullscreen },
    },
    [
      _c("searchform", { on: { receive: _vm.receiverow } }),
      _c("div", { staticClass: "flexk" }, [
        _c("div", { staticStyle: { float: "left", "margin-bottom": "15px" } }),
        _c(
          "div",
          { staticClass: "ref", staticStyle: { float: "right" } },
          [
            _c(
              "el-button",
              {
                staticStyle: { margin: "0 10px 10px 0 !important" },
                on: { click: _vm.clickFullScreen },
              },
              [
                _c("vab-icon", {
                  attrs: {
                    icon: _vm.isFullscreen
                      ? "fullscreen-exit-fill"
                      : "fullscreen-fill",
                  },
                }),
                _vm._v(" 表格全屏 "),
              ],
              1
            ),
            _c(
              "el-popover",
              {
                ref: "popover",
                attrs: {
                  "popper-class": "custom-table-checkbox",
                  trigger: "hover",
                },
                scopedSlots: _vm._u([
                  {
                    key: "reference",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 10px 10px 0 !important" },
                          },
                          [
                            _c("vab-icon", { attrs: { icon: "line-height" } }),
                            _vm._v(" 表格尺寸 "),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.lineHeight,
                      callback: function ($$v) {
                        _vm.lineHeight = $$v
                      },
                      expression: "lineHeight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "medium" } }, [
                      _vm._v("大"),
                    ]),
                    _c("el-radio", { attrs: { label: "small" } }, [
                      _vm._v("中"),
                    ]),
                    _c("el-radio", { attrs: { label: "mini" } }, [
                      _vm._v("小"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-button",
        {
          attrs: { icon: "el-icon-plus", type: "primary" },
          on: { click: _vm.handleAdd },
        },
        [_vm._v("新增")]
      ),
      _c(
        "el-table",
        {
          ref: "tableSort",
          attrs: {
            border: "",
            data: _vm.data,
            "max-height": "600",
            size: _vm.lineHeight,
          },
          on: { "selection-change": _vm.selectd },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "55" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              "show-overflow-tooltip": "",
              type: "index",
              width: "50",
            },
          }),
          _vm._l(_vm.listContent, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                "show-overflow-tooltip": item.tooltip,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              content: row[item.prop],
                              disabled: item.tooltip,
                              effect: "dark",
                              placement: "top-end",
                              value: item.tooltip,
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "white-space": "nowrap" } },
                              [_vm._v(_vm._s(row[item.prop]))]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "分配时间",
              prop: "allotTime",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "default", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showedis(row)
                          },
                        },
                      },
                      [_vm._v("回访")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "medium", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.appoint(row)
                          },
                        },
                      },
                      [_vm._v("移交")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("table-edit", {
        ref: "edit",
        attrs: { config: _vm.config, options: _vm.options },
        on: { fileStatus: _vm.fileupload, submit: _vm.submit },
        model: {
          value: _vm.form,
          callback: function ($$v) {
            _vm.form = $$v
          },
          expression: "form",
        },
      }),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.current,
          layout: "total, sizes, prev, pager, next, jumper",
          "page-size": _vm.queryForm.size,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("handover", {
        attrs: { hanuser: _vm.handeuserinfo, showtab: _vm.appointDialog },
        on: { closoo: _vm.colsover, closover: _vm.colsove },
      }),
      _c("distrtion", {
        attrs: { shodist: _vm.distrtionshow, userid: _vm.selecuserid },
        on: { closedistr: _vm.closdistr },
      }),
      _c("histroy", {
        attrs: { showhislist: _vm.historylist, showhist: _vm.historyshow },
        on: { closehis: _vm.colsehistory },
      }),
      _c("ediform", {
        attrs: {
          edilist: _vm.shotablist,
          edirow: _vm.edisrow,
          showedit: _vm.showedi,
        },
        on: { clearedi: _vm.cleareit },
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: "新增", visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    "label-width": "100px",
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名：", prop: "userName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: _vm.ruleForm.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "userName", $$v)
                          },
                          expression: "ruleForm.userName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话1：", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入电话1" },
                        model: {
                          value: _vm.ruleForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "phone", $$v)
                          },
                          expression: "ruleForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话2：", prop: "phonev" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入电话2" },
                        model: {
                          value: _vm.ruleForm.phonev,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "phonev", $$v)
                          },
                          expression: "ruleForm.phonev",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "渠道：", prop: "channelId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择渠道" },
                          model: {
                            value: _vm.ruleForm.channelId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "channelId", $$v)
                            },
                            expression: "ruleForm.channelId",
                          },
                        },
                        _vm._l(_vm.changeOptions, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.labelName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址：", prop: "addressDetail" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入地址" },
                        model: {
                          value: _vm.ruleForm.addressDetail,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "addressDetail", $$v)
                          },
                          expression: "ruleForm.addressDetail",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注1：", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入备注1" },
                        model: {
                          value: _vm.ruleForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "remark", $$v)
                          },
                          expression: "ruleForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注2：", prop: "remarkContent" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入备注2" },
                        model: {
                          value: _vm.ruleForm.remarkContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "remarkContent", $$v)
                          },
                          expression: "ruleForm.remarkContent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handlecancel("ruleForm")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleOk("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }