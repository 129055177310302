var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      style: {
        background: _vm.controls[_vm.index].pageBgColor,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "container",
          style: {
            gridTemplateColumns: ` repeat(${
              _vm.Typeflex(_vm.controls[_vm.index].articletype)[1]
            }, 1fr)`,
            background: _vm.controls[_vm.index].componentBgColor,
            margin: `${_vm.controls[_vm.index].margin.top}px ${
              _vm.controls[_vm.index].margin.both
            }px ${_vm.controls[_vm.index].margin.bottom}px ${
              _vm.controls[_vm.index].margin.both
            }px`,
            borderRadius: `${
              _vm.controls[_vm.index].topElementAroundRadius
            }px ${_vm.controls[_vm.index].topElementAroundRadius}px ${
              _vm.controls[_vm.index].bottomElementAroundRadius
            }px ${_vm.controls[_vm.index].bottomElementAroundRadius}px`,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "item",
              style: {
                display: _vm.Typeflex(_vm.controls[_vm.index].articletype),
                background: _vm.controls[_vm.index].elementBgColor,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "item_picUrl",
                  style: {
                    width:
                      _vm.controls[_vm.index].articletype == 2
                        ? "80px"
                        : "100%",
                  },
                },
                [_c("img", { attrs: { alt: "", src: _vm.intoPic } })]
              ),
              _c(
                "div",
                {
                  staticClass: "ref_contrl",
                  style: {
                    width:
                      _vm.controls[_vm.index].articletype == 2
                        ? "calc(100% - 80px)"
                        : "100%",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "Article-title",
                      style: {
                        margin:
                          _vm.controls[_vm.index].articletype == 2
                            ? " 0"
                            : "10px 0",
                      },
                    },
                    [_vm._v(" 文章标题 ")]
                  ),
                  _c("div", { staticClass: "Article-bottom" }, [
                    _vm.controls[_vm.index].articletype == 2
                      ? _c("div", { staticClass: "Article-tag" }, [
                          _c("div", { staticClass: "tag_item" }, [
                            _vm._v("慢病管理"),
                          ]),
                          _c("div", { staticClass: "tag_item" }, [
                            _vm._v("饮食运动"),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "Article-type" }, [
                      _vm._v("12345浏览"),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "item",
              style: {
                display: _vm.Typeflex(_vm.controls[_vm.index].articletype),
                background: _vm.controls[_vm.index].elementBgColor,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "item_picUrl",
                  style: {
                    width:
                      _vm.controls[_vm.index].articletype == 2
                        ? "80px"
                        : "100%",
                  },
                },
                [_c("img", { attrs: { alt: "", src: _vm.intoPic } })]
              ),
              _c(
                "div",
                {
                  staticClass: "ref_contrl",
                  style: {
                    width:
                      _vm.controls[_vm.index].articletype == 2
                        ? "calc(100% - 80px)"
                        : "100%",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "Article-title",
                      style: {
                        margin:
                          _vm.controls[_vm.index].articletype == 2
                            ? " 0"
                            : "10px 0",
                      },
                    },
                    [_vm._v(" 文章标题 ")]
                  ),
                  _c("div", { staticClass: "Article-bottom" }, [
                    _vm.controls[_vm.index].articletype == 2
                      ? _c("div", { staticClass: "Article-tag" }, [
                          _c("div", { staticClass: "tag_item" }, [
                            _vm._v("慢病管理"),
                          ]),
                          _c("div", { staticClass: "tag_item" }, [
                            _vm._v("饮食运动"),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "Article-type" }, [
                      _vm._v("12345浏览"),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }