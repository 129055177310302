var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "textTool" }, [
    _c("div", { staticClass: "toolTit" }, [
      _c("div", { staticClass: "lef" }, [_vm._v("我的订单")]),
      _c("div", { staticClass: "ref" }, [
        _c("div", { staticClass: "switch" }, [
          _c(
            "div",
            {
              class: _vm.checknum == 1 ? "swit-item active" : "swit-item",
              on: {
                click: function ($event) {
                  return _vm.changeChecknum(1)
                },
              },
            },
            [_vm._v("内容")]
          ),
          _c(
            "div",
            {
              class: _vm.checknum == 2 ? "swit-item active" : "swit-item",
              on: {
                click: function ($event) {
                  return _vm.changeChecknum(2)
                },
              },
            },
            [_vm._v("样式")]
          ),
        ]),
      ]),
    ]),
    _vm.checknum == 1
      ? _c("div", { staticClass: "content_msg" }, [
          _c("div", { staticClass: "ym_line" }),
          _c(
            "div",
            { staticClass: "itemBox", staticStyle: { padding: "30px 0" } },
            [
              _c("div", { staticClass: "Tit" }, [_vm._v("风格")]),
              _c(
                "div",
                { staticClass: "check-tit" },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.activeComponents[_vm.indexnumer].style,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.activeComponents[_vm.indexnumer],
                            "style",
                            $$v
                          )
                        },
                        expression: "activeComponents[indexnumer].style",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("样式1"),
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("样式2"),
                      ]),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("样式3"),
                      ]),
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v("样式4"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm.checknum == 2
      ? _c("div", { staticClass: "content_msg" }, [
          _c("div", { staticClass: "ym_line" }),
          _c(
            "div",
            { staticClass: "itemBox", staticStyle: { padding: "30px 0" } },
            [
              _c("div", { staticClass: "Tit" }, [_vm._v("底部背景")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(_vm.activeComponents[_vm.indexnumer].pageBgColor)
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer].pageBgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "pageBgColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].pageBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[_vm.indexnumer].pageBgColor =
                                ""
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "alert-msg" }, [
            _vm._v("底部背景包含边距和圆角"),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("组件背景")]),
            _c(
              "div",
              {
                staticClass: "check-tit",
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "space-between",
                },
              },
              [
                _c("div", { staticClass: "lef" }, [
                  _vm._v(
                    _vm._s(
                      _vm.activeComponents[_vm.indexnumer].componentBgColor
                    )
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "ref",
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      color: "#7aa4ff",
                    },
                  },
                  [
                    _c("el-color-picker", {
                      model: {
                        value:
                          _vm.activeComponents[_vm.indexnumer].componentBgColor,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.activeComponents[_vm.indexnumer],
                            "componentBgColor",
                            $$v
                          )
                        },
                        expression:
                          "activeComponents[indexnumer].componentBgColor",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "resect",
                        on: {
                          click: function ($event) {
                            _vm.activeComponents[
                              _vm.indexnumer
                            ].componentBgColor = ""
                          },
                        },
                      },
                      [_vm._v("重置")]
                    ),
                    _c("i", {
                      staticClass: "iconfont icon-arrow-right",
                      staticStyle: { "font-size": "12px", color: "gray" },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("上边距")]),
            _c(
              "div",
              {
                staticClass: "check-tit",
                staticStyle: { display: "flex", width: "260px" },
              },
              [
                _c("el-slider", {
                  staticStyle: { width: "150px" },
                  attrs: { max: 100, size: "mini" },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].margin.top,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].margin,
                        "top",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].margin.top",
                  },
                }),
                _c("el-input-number", {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { max: 100, min: 0 },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].margin.top,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].margin,
                        "top",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].margin.top",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("下边距")]),
            _c(
              "div",
              {
                staticClass: "check-tit",
                staticStyle: { display: "flex", width: "260px" },
              },
              [
                _c("el-slider", {
                  staticStyle: { width: "150px" },
                  attrs: { max: 100, size: "mini" },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].margin,
                        "bottom",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].margin.bottom",
                  },
                }),
                _c("el-input-number", {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { max: 100, min: 0 },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].margin,
                        "bottom",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].margin.bottom",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("左右边距")]),
            _c(
              "div",
              {
                staticClass: "check-tit",
                staticStyle: { display: "flex", width: "260px" },
              },
              [
                _c("el-slider", {
                  staticStyle: { width: "150px" },
                  attrs: { max: 20, size: "mini" },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].margin.both,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].margin,
                        "both",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].margin.both",
                  },
                }),
                _c("el-input-number", {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { max: 20, min: 0 },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].margin.both,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].margin,
                        "both",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].margin.both",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("上圆角")]),
            _c(
              "div",
              {
                staticClass: "check-tit",
                staticStyle: { display: "flex", width: "260px" },
              },
              [
                _c("el-slider", {
                  staticStyle: { width: "150px" },
                  attrs: { max: 50, min: 0, size: "mini" },
                  model: {
                    value:
                      _vm.activeComponents[_vm.indexnumer]
                        .topElementAroundRadius,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer],
                        "topElementAroundRadius",
                        $$v
                      )
                    },
                    expression:
                      "activeComponents[indexnumer].topElementAroundRadius",
                  },
                }),
                _c("el-input-number", {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { max: 50, min: 0 },
                  model: {
                    value:
                      _vm.activeComponents[_vm.indexnumer]
                        .topElementAroundRadius,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer],
                        "topElementAroundRadius",
                        $$v
                      )
                    },
                    expression:
                      "activeComponents[indexnumer].topElementAroundRadius",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("下圆角")]),
            _c(
              "div",
              {
                staticClass: "check-tit",
                staticStyle: { display: "flex", width: "260px" },
              },
              [
                _c("el-slider", {
                  staticStyle: { width: "150px" },
                  attrs: { max: 50, min: 0, size: "mini" },
                  model: {
                    value:
                      _vm.activeComponents[_vm.indexnumer]
                        .bottomElementAroundRadius,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer],
                        "bottomElementAroundRadius",
                        $$v
                      )
                    },
                    expression:
                      "activeComponents[indexnumer].bottomElementAroundRadius",
                  },
                }),
                _c("el-input-number", {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { max: 50, min: 0 },
                  model: {
                    value:
                      _vm.activeComponents[_vm.indexnumer]
                        .bottomElementAroundRadius,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer],
                        "bottomElementAroundRadius",
                        $$v
                      )
                    },
                    expression:
                      "activeComponents[indexnumer].bottomElementAroundRadius",
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }