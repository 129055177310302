<template>
  <el-dialog title="修改收货地址" :visible.sync="dialogFormVisible" width="500px" @close="close">
    <el-form ref="postForm" label-width="80px" :model="postForm" :rules="postFormRules">
      <el-form-item label="收货人" prop="consignee">
        <el-input v-model.trim="postForm.consignee" />
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model.number="postForm.phone" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" />
      </el-form-item>
      <el-form-item label="收货地址" prop="provinces">
        <el-cascader
          v-model.trim="postForm.provinces"
          class="full-width"
          :options="areaSelectData"
          placeholder="请选择地址"
          :props="{
            children: 'children',
            label: 'areaName',
            value: 'areaName',
          }"
          size="large"
          style="width: 80%"
        />
      </el-form-item>
      <el-form-item prop="receivingAddress">
        <el-input v-model.trim="postForm.receivingAddress" placeholder="请输入详细地址" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { getAreaThree } from '@/api/mall/common'
  import { hasPhone } from '@/utils/validate'
  import { getOrderUpdatelogistics, getOrderDetail } from '@/api/mall/order/order'
  export default {
    name: 'EidtAddress',
    props: {
      infos: {
        type: Object,
        default: null,
      },
    },
    data() {
      let checkConsignee = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入收货人'))
        } else {
          callback()
        }
      }
      let checkPhone = (rule, value, callback) => {
        if (!hasPhone(value)) {
          callback(new Error('请输入正确的手机号码'))
        }
        if (!value) {
          callback(new Error('请输入手机号'))
        } else {
          callback()
        }
      }
      let checkProvinces = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请选择收货地址'))
        } else {
          callback()
        }
      }
      let checkReceivingAddress = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入详细地址'))
        } else {
          callback()
        }
      }
      return {
        lists: {
          consignee: '',
          phone: '',
          receivingAddress: '',
          city: '',
          county: '',
          province: '',
          provinces: '',
        },
        orderDetails: {},
        provinces: '',
        orderCodes: '',
        rowIds: '',
        orgCodes: '',
        dialogFormVisible: false,
        postForm: {
          geoCode: '',
          id: '',
        },
        areaSelectData: [],
        postFormRules: {
          consignee: [
            {
              required: true,
              validator: checkConsignee,
              trigger: ['blur', 'change'],
            },
          ],
          phone: [
            {
              required: true,
              validator: checkPhone,
              trigger: ['blur', 'change'],
            },
          ],
          provinces: [
            {
              required: true,
              validator: checkProvinces,
              trigger: ['blur', 'change'],
            },
          ],
          receivingAddress: [
            {
              required: true,
              validator: checkReceivingAddress,
              trigger: ['blur', 'change'],
            },
          ],
        },
      }
    },

    created() {},
    methods: {
      // 区域地址
      async getAreaThree() {
        await getAreaThree()
          .then((res) => {
            if (res.data) {
              this.areaSelectData = res.data
            }
          })
          .catch(() => {})
      },
      // 详情收货人信息
      // async selectConsignee() {
      //   if (this.rowIds) {
      //     this.ids = this.rowIds
      //   } else if (this.infos != null) {
      //     console.log(this.infos.orderId)
      //     this.ids = this.infos.orderId
      //   }
      //   await selectConsignee({ id: this.ids })
      //     .then((res) => {
      //       if (res.data) {
      //         this.postForm = res.data
      //         const areaCodes = res.data.areaCode.split(',').splice(1)
      //         const areaCodesARR = areaCodes.concat()
      //         const province = areaCodesARR[0]
      //         const city = areaCodesARR[1]
      //         const adr = [province, city, res.data.geoCode]
      //         this.postForm.provinces = adr
      //         console.log(this.postForm.provinces)
      //       }
      //     })
      //     .catch(() => {})
      // },
      // 选择收货地址
      areaSelectChange(val) {
        this.provinces = val
      },

      // 弹框
      showEdit(row) {
        // 详情数据
        getOrderDetail({ id: row.id })
          .then((res) => {
            if (res.data) {
              this.orderDetails = res.data
              const citys = this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.city : '',
                countys = this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.county : '',
                provinces = this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.province : ''
              const adr = [provinces, citys, countys]
              this.lists = {
                consignee: this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.consignee : '',
                phone: this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.phone : '',
                provinces: adr,
                receivingAddress: this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.receivingAddress : '',
              }
              this.postForm = this.lists
            }
          })
          .catch(() => {})

        // if (row) {
        //   this.rowIds = row.id
        //   this.orderCodes = row.orderCode
        //   this.orgCodes = row.orgCode
        // }
        this.dialogFormVisible = true
        this.getAreaThree()
      },
      // 修改收货地址关闭
      close() {
        this.$refs['postForm'].resetFields()
        this.dialogFormVisible = false
      },
      // 修改收货地址保存
      save() {
        this.$refs['postForm'].validate(async (valid) => {
          if (valid) {
            const parms = {
              id: this.orderDetails.orderConsignee != null ? this.orderDetails.orderConsignee.id : '',
              geoCode: this.orderDetails.geoCode,
              consignee: this.postForm.consignee,
              phone: this.postForm.phone,
              province: this.postForm.provinces[0],
              city: this.postForm.provinces[1],
              county: this.postForm.provinces[2],
              receivingAddress: this.postForm.receivingAddress,
            }
            await getOrderUpdatelogistics(parms)
              .then((res) => {
                this.MS(res.message)
                this.$emit('getorder')
                this.close()
              })
              .catch(() => {})
          }
        })
      },
      // 修改收货地址回显
      handleEditAdr() {
        const citys = this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.city : '',
          countys = this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.county : '',
          provinces = this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.province : ''
        const adr = [provinces, citys, countys]
        this.lists = {
          consignee: this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.consignee : '',
          phone: this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.phone : '',
          provinces: adr,
          receivingAddress: this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.receivingAddress : '',
        }
        this.postForm = this.lists
        this.dialogFormVisible = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-select {
    width: 80% !important;
    .el-input {
      width: 100%;
    }
  }
  .el-input {
    width: 80%;
  }
  .el-textarea__inner {
    min-height: 100px;
  }
</style>
