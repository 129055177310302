<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" @close="close">
    <el-table border :data="orderLogTable" style="width: 100%">
      <el-table-column label="操作者" />
      <el-table-column label="操作类型" />
      <el-table-column label="备注" />
      <el-table-column label="操作时间" />
    </el-table>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'OrderLogo',
    data() {
      return {
        dialogFormVisible: false,
        title: '订单日志',
        orderLogTable: [],
      }
    },

    created() {},
    methods: {
      // 弹框
      showEdit() {
        this.dialogFormVisible = true
      },
      // 弹框关闭
      close() {
        this.dialogFormVisible = false
      },
      // 确定
      save() {},
    },
  }
</script>
