<template>
  <div>
    <el-dialog :before-close="clear" title="移交部门" :visible.sync="appointDialog" width="60%">
      <el-form ref="addForm" label-width="80px" :model="addForm" :rules="rules">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="uslist.userName" autocomplete="off" disabled placeholder="请输入用户名" />
        </el-form-item>
        <el-form-item class="mobilePhone" label="手机号" prop="mobile">
          <el-input v-model="uslist.phone" class="input-with-select" disabled oninput="value=value.replace(/[^\d]/g,'')"
            placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item class="postPare" label="所属部门" prop="mallcategory">
          <el-cascader v-model="addForm.mallcategory" change-on-select :options="dataSourceLists"
            :props="{ checkStrictly: true, label: 'orgName', value: 'id' }" @change="getcasc">
            <template slot-scope="{ node, data }">
              <span v-if="!node.isLeaf">{{ data.orgName }}({{ data.children.length }})</span>
            </template>
          </el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateTurnOver } from '@/api/mall/health/seas/seasManger'
import { orgTree } from '@/api/mall/role/orgManage'
export default {
  props: {
    showtab: {
      type: Boolean,
      default: () => { },
    },
    hanuser: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      appointDialog: true,
      dataSourceLists: [],
      addForm: {},
      uslist: null,
      props: {
        checkStrictly: true,
        multiple: true,
        label: 'orgName',
        value: 'id',
      },
      rules: {
        mallcategory: [
          { required: true, message: '请选择 所属部门', trigger: 'change' },
        ],
      }
    }
  },
  watch: {
    showtab(newValue, oldValue) {
      if (newValue != oldValue) {
        this.appointDialog = newValue
      }
    },
    hanuser(newValue, oldValue) {
      if (newValue != oldValue) {
        this.uslist = newValue
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    getcasc(e) {
      console.log(e)
      console.log(this.dataSourceLists)
    },
    async fetchData() {
      this.listLoading = true
      const parms = {
        current: this.current,
        size: this.size,
      }
      await orgTree(parms)
        .then((res) => {
          if (res.data) {
            this.menuListTable = res.data.records
            this.dataSourceLists = res.data.records
            this.dataSourceLists.map((obj) => {
              obj.label = obj.orgName
              obj.value = obj.id
              const fieldArr = obj.children
              const newFieldArr = fieldArr.map((element) => {
                element.label = element.orgName
                element.value = element.id
                element.children.forEach((item) => {
                  item.label = item.orgName
                  item.value = item.id
                  if (item.children != null) {
                    item.children.forEach((el) => {
                      el.label = el.orgName
                      el.value = el.id
                    })
                  }
                })
                return element
              })
              obj.fieldArr = newFieldArr
              return obj
            })
          }
        })
        .catch(() => { })
      this.listLoading = false
    },
    clear() {
      this.$emit('closover', false)
    },
    teaChange(e) {
      if (!e.length) {
        this.appointData.forEach((item) => {
          item.disabled = false
        })
      }
    },
    leftChange(e) {
      this.appointData.forEach((item) => {
        if (e[0] == item.id) item.disabled = false
        else item.disabled = true
      })
    },
    sure() {
      if (this.userId == 0) {
        return this.$baseMessage('error', 'error', 'vab-hey-message-success')
      }
      updateTurnOver({ orgIds: this.addForm.mallcategory, id: this.uslist.id }).then((res) => {
        if (res.status == 0 || res.status == 200) {
          this.$message.success('移交成功')
          this.$emit('closover')
          this.$emit('closoo')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },
}
</script>

<style></style>
