var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cy-container" },
    [
      _c(
        "div",
        { ref: "contantRef" },
        [
          _c(
            "div",
            {},
            [
              _c(
                "el-form",
                {
                  ref: "refGeneral",
                  attrs: { inline: true, model: _vm.queryForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "lef" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "员工姓名" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-input",
                            attrs: {
                              clearable: "",
                              placeholder: "昵称",
                              type: "text",
                            },
                            model: {
                              value: _vm.queryForm.nickname,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "nickname",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.nickname",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "员工手机" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-input",
                            attrs: {
                              clearable: "",
                              placeholder: "手机号",
                              type: "text",
                            },
                            model: {
                              value: _vm.queryForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "mobile",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "员工工号" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-input",
                            attrs: {
                              clearable: "",
                              placeholder: "工号",
                              type: "text",
                            },
                            model: {
                              value: _vm.queryForm.profile,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "profile",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.profile",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "员工角色" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-input",
                              attrs: { clearable: "", placeholder: "角色" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.handleRoleSelect.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                              model: {
                                value: _vm.queryForm.roleId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm,
                                    "roleId",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "queryForm.roleId",
                              },
                            },
                            _vm._l(_vm.roleSelectList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "员工状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-input",
                              attrs: { clearable: "", placeholder: "状态" },
                              model: {
                                value: _vm.queryForm.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "status", $$v)
                                },
                                expression: "queryForm.status",
                              },
                            },
                            _vm._l(_vm.stateSelect, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "公司" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-input",
                            attrs: {
                              clearable: "",
                              placeholder: "公司",
                              type: "text",
                            },
                            model: {
                              value: _vm.queryForm.fullName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "fullName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.fullName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-search",
                                type: "primary",
                              },
                              on: { click: _vm.handleQuery },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-refresh-right",
                                type: "warning",
                              },
                              on: { click: _vm.handleReset },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "ref" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _vm._l(_vm.searchButton, function (item) {
                            return _c(
                              "el-button",
                              {
                                key: item.id,
                                attrs: {
                                  plain:
                                    item.cssType && item.cssType == "plain"
                                      ? true
                                      : false,
                                  type: "primary",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleClickBtn(item)
                                  },
                                },
                              },
                              [
                                !item.buttonIcon
                                  ? _c("span")
                                  : _c("vab-icon", {
                                      attrs: { icon: item.buttonIcon },
                                    }),
                                _vm._v(" " + _vm._s(item.buttonName) + " "),
                              ],
                              1
                            )
                          }),
                          _vm._l(_vm.listButtonParent, function (items) {
                            return [
                              items.buttonType == 1 && items.children
                                ? _vm._l(items.children, function (itemss) {
                                    return _c(
                                      "el-button",
                                      {
                                        key: itemss.id,
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.handleClickBtn(itemss)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(itemss.buttonName))]
                                    )
                                  })
                                : _vm._e(),
                            ]
                          }),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              on: { click: _vm.relatedCompanies },
                            },
                            [_vm._v("关联公司")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "filter-btn" }),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.list,
                "default-expand-all": "",
                fit: "",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "show-overflow-tooltip": "",
                  type: "selection",
                  width: "55",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "姓名",
                  prop: "realName",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "手机号",
                  prop: "mobile",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "工号",
                  prop: "profile",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "账号",
                  prop: "username",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "角色",
                  prop: "roleIdCn",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "公司",
                  prop: "fullName",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "所属部门",
                  prop: "orgIdCn",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "状态",
                  prop: "status",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type: row.status === 0 ? "danger" : "success",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(row.status === 0 ? "禁用" : "正常") +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "创建时间",
                  prop: "createTime",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm.operationShow
                ? _c("el-table-column", {
                    attrs: { align: "center", label: "操作", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return _vm._l(_vm.listButton, function (item) {
                              return _c(
                                "el-button",
                                {
                                  key: item.id,
                                  attrs: {
                                    plain:
                                      item.cssType && item.cssType == "plain"
                                        ? true
                                        : false,
                                    size: "default",
                                    type: item.buttonCss,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleClickBtn(item, row)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.buttonName) + " ")]
                              )
                            })
                          },
                        },
                      ],
                      null,
                      false,
                      1114956035
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.forms.current,
              layout: _vm.layout,
              "page-size": _vm.forms.size,
              "page-sizes": [5, 10, 15, 20, 25, 30],
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pwdDialog",
          attrs: { title: "重置密码", visible: _vm.pwdDialog },
          on: {
            "update:visible": function ($event) {
              _vm.pwdDialog = $event
            },
            close: _vm.closePwd,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "pwdForm",
              attrs: {
                "label-width": "80px",
                model: _vm.pwdForm,
                rules: _vm.pwdFormRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      clearable: "",
                      placeholder: "请输入密码",
                    },
                    model: {
                      value: _vm.pwdForm.password,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.pwdForm,
                          "password",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "pwdForm.password",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closePwd } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.savePwd } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "关联公司",
            visible: _vm.dialogCompany,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogCompany = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: { border: "", data: _vm.tableCompanyData },
              on: { "row-click": _vm.showRowCompany },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "选择", width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            staticClass: "radio",
                            attrs: { label: scope.row },
                            model: {
                              value: _vm.radioCompany,
                              callback: function ($$v) {
                                _vm.radioCompany = $$v
                              },
                              expression: "radioCompany",
                            },
                          },
                          [_vm._v(" ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "全称",
                  prop: "fullname",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "简称",
                  prop: "shortname",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "创建时间",
                  prop: "createTime",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCompanycancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleCompanyOk },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "addDialog",
          attrs: {
            "close-on-click-modal": false,
            title: _vm.dialogTitle,
            visible: _vm.addVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                "label-width": "80px",
                model: _vm.addForm,
                rules: _vm.addFormRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账号", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      clearable: "",
                      disabled: _vm.dialogTitle == "编辑",
                      placeholder: "请输入用户名",
                    },
                    model: {
                      value: _vm.addForm.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "username", $$v)
                      },
                      expression: "addForm.username",
                    },
                  }),
                ],
                1
              ),
              _vm.dialogTitle === "新增"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "登录密码", prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          clearable: "",
                          placeholder: "请输入密码",
                        },
                        model: {
                          value: _vm.addForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "password", $$v)
                          },
                          expression: "addForm.password",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "真实姓名", prop: "realName" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      clearable: "",
                      disabled: _vm.dialogTitle == "编辑",
                      placeholder: "请输入真实姓名",
                    },
                    model: {
                      value: _vm.addForm.realName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "realName", $$v)
                      },
                      expression: "addForm.realName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱", prop: "email" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      clearable: "",
                      placeholder: "请输入邮箱",
                    },
                    model: {
                      value: _vm.addForm.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "email", $$v)
                      },
                      expression: "addForm.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "mobilePhone",
                  attrs: { label: "手机号", prop: "mobile" },
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "input-with-select",
                      attrs: {
                        clearable: "",
                        maxlength: "11",
                        oninput: "value=value.replace(/[^\\d]/g,'')",
                        placeholder: "请输入手机号",
                      },
                      model: {
                        value: _vm.addForm.mobile,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "mobile", $$v)
                        },
                        expression: "addForm.mobile",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { slot: "prepend", placeholder: "请选择区号" },
                          slot: "prepend",
                          model: {
                            value: _vm.addForm.prefix,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "prefix", $$v)
                            },
                            expression: "addForm.prefix",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "+86", value: "86" },
                          }),
                          _c("el-option", {
                            attrs: { label: "+87", value: "87" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "坐席工号", prop: "profile" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      clearable: "",
                      placeholder: "请输入员工坐席",
                    },
                    model: {
                      value: _vm.addForm.profile,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "profile", $$v)
                      },
                      expression: "addForm.profile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "postPare",
                  attrs: { label: "所属部门", prop: "orgIds" },
                },
                [
                  _c("el-cascader", {
                    ref: "cascaderMallCatergory",
                    staticStyle: { width: "90%" },
                    attrs: {
                      clearable: "",
                      options: _vm.dataSourceLists,
                      props: _vm.props,
                    },
                    model: {
                      value: _vm.addForm.orgIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "orgIds", $$v)
                      },
                      expression: "addForm.orgIds",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.addForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "status", $$v)
                        },
                        expression: "addForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("正常")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("禁用")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogTitle === "新增"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.authorizeRoleisible
        ? _c(
            "el-dialog",
            {
              staticClass: "authorizePoles",
              attrs: {
                "close-on-click-modal": false,
                title: "授权角色",
                visible: _vm.authorizeRoleisible,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.authorizeRoleisible = $event
                },
                close: _vm.closeRole,
              },
            },
            [
              _c("el-transfer", {
                attrs: {
                  data: _vm.roleList,
                  filterable: "",
                  props: { label: "name", key: "value" },
                  titles: _vm.treeTitle,
                },
                model: {
                  value: _vm.grantRoleList,
                  callback: function ($$v) {
                    _vm.grantRoleList = $$v
                  },
                  expression: "grantRoleList",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.closeRole } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createAuthorizeRoleButton },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }