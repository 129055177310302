<template>
  <div class="cy-container">
    <el-container style="width: 100%">
      <!-- aside侧边菜单 -->
      <div
        ref="asideRef"
        class="asideAuthorizeUrl"
        style="
          width: 200px;
          height: 600px;
          margin-right: 5px;
          overflow: scroll;
          border: 1px solid #c0d4f0;
        "
      >
        <el-aside>
          <!-- <el-input
            v-model="menuName"
            clearable
            placeholder="菜单名称"
            type="text"
          /> -->
          <el-table
            v-loading="listLoading"
            class="aside-table"
            :data="menuListTable"
            :highlight-current-row="true"
            row-key="id"
            style="width: 100%; margin-bottom: 20px"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @row-click="datas"
          >
            <el-table-column label="菜单名称" prop="menuName" />
          </el-table>
        </el-aside>
      </div>
      <div class="clickLine">
        <i
          v-if="leftIcon"
          class="el-icon-caret-left clickLineLeft"
          @click="handleLeftIcon"
        />
        <i
          v-if="rightIcon"
          class="el-icon-caret-right clickLineRight"
          @click="handleRightIcon"
        />
      </div>
      <!-- container内容容器-->
      <div ref="contantRef" style="width: 87%; overflow: scroll">
        <div class="mb10 overflow">
          <div class="filter-searchs fl">
            <el-form
              ref="refGeneral"
              :inline="true"
              :model="queryForm"
              @submit.native.prevent
            >
              <el-form-item>
                <el-input
                  v-model="queryForm.url"
                  class="filter-input mr10"
                  placeholder="URL"
                  type="text"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="queryForm.description"
                  class="filter-input mr10"
                  placeholder="描述"
                  type="text"
                />
              </el-form-item>
              <el-form-item>
                <el-button
                  class="filter-items"
                  icon="el-icon-search"
                  type="primary"
                  @click="handleQuery"
                >
                  查询
                </el-button>
                <el-button
                  class="filter-items"
                  icon="el-icon-refresh-right"
                  type="primary"
                  @click="handleReset"
                >
                  重置
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="showsed.shows != 1" class="filter-btn">
            <el-button
              v-for="item in searchButton"
              :key="item.id"
              :plain="item.cssType && item.cssType == 'plain' ? true : false"
              :type="item.buttonCss"
              @click.native="handleClickBtn(item)"
            >
              <span v-if="!item.buttonIcon"></span>
              <vab-icon v-else :icon="item.buttonIcon" />
              {{ item.buttonName }}
            </el-button>
            <!-- <el-button
              class="filter-items"
              icon="el-icon-plus"
              type="primary"
              @click="handleEdit"
            >
              新增
            </el-button>
            <el-button
              class="filter-items"
              type="primary"
              @click="handleExport"
            >
              <vab-icon icon="file-copy-2-fill" />
              导出
            </el-button> -->
          </div>
        </div>
        <div :class="showsed.shows != 1 ? '' : 'classShows'">
          <el-table
            ref="orgTableDataList"
            v-loading="listLoading"
            border
            :data="orgTableDataList"
            :row-key="getRowKeys"
            style="width: 100%"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              align="center"
              prop="id"
              :reserve-selection="true"
              show-overflow-tooltip
              type="selection"
              width="55"
            />
            <el-table-column
              label="URL"
              prop="url"
              show-overflow-tooltip
              width="340px"
            />

            <el-table-column
              label="描述"
              prop="description"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              label="接口状态"
              prop="isDelete"
              show-overflow-tooltip
              width="100px"
            >
              <template #default="{ row }">
                <el-tag :type="row.isDelete === 0 ? 'success' : 'danger'">
                  {{ row.isDelete === 0 ? '正常' : '删除' }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="创建时间"
              prop="createTime"
              show-overflow-tooltip
              sortable
              width="150px"
            >
              <template #default="{ row }">
                <div>{{ row.createTime | formatDate }}</div>
              </template>
            </el-table-column>
            <template v-if="operationShow">
              <el-table-column
                v-if="showsed.shows != 1"
                align="center"
                label="操作"
                width="150px"
              >
                <template #default="{ row }">
                  <!-- <el-button
                  icon="el-icon-edit-outline"
                  style="margin-right: 10px"
                  type="text"
                  @click="handleEdit(scope.row)"
                >
                  编辑
                </el-button>

                <el-button
                  icon="el-icon-delete"
                  size="small"
                  type="text"
                  @click="handleDelete(scope.row)"
                >
                  删除
                </el-button> -->
                  <el-button
                    v-for="item in listButton"
                    :key="item.id"
                    :plain="
                      item.cssType && item.cssType == 'plain' ? true : false
                    "
                    :type="item.buttonCss"
                    @click.native="handleClickBtn(item, row)"
                  >
                    <span v-if="!item.buttonIcon"></span>
                    <vab-icon v-else :icon="item.buttonIcon" />
                    {{ item.buttonName }}
                  </el-button>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
        <!-- 分页 -->
        <el-pagination
          v-if="showsed.shows != 1"
          background
          :current-page="forms.currentPage"
          :layout="layout"
          :page-size="forms.pageSize"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </el-container>
    <!-- 新增(编辑) -->
    <el-dialog
      class="addDialog"
      :close-on-click-modal="false"
      :title="dialogTitle"
      :visible.sync="addVisible"
      @close="close"
    >
      <el-form ref="addForm" label-width="80px" :model="addForm" :rules="rules">
        <el-form-item label="url" prop="url" required>
          <el-input
            v-model.trim="addForm.url"
            autocomplete="off"
            clearable
            maxlength="64"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="菜单" prop="menuIdList">
          <el-cascader
            ref="cascaderMallCatergory"
            v-model="addForm.menuIdList"
            clearable
            :options="dataSourceLists"
            :props="props"
            style="width: 90%"
            @change="changeDataSource"
          />
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input
            v-model.trim="addForm.description"
            autocomplete="off"
            clearable
            maxlength="256"
            placeholder="请输入"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogTitle === '新增' ? createData() : updateData()"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 授权 -->
    <!-- <el-dialog class="addDialog" title="授权" :visible.sync="authorityVisible">
      <el-checkbox-group v-model="checkListModel">
        <el-checkbox
          v-for="(item, index) in checkList"
          :key="index"
          :label="item.name"
        />
      </el-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <el-button @click="authorityVisible = false">取 消</el-button>
        <el-button type="primary" @click="authorityVisible = false">
          确 定
        </el-button>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
  import { selectMenuTree } from '@/api/mall/role/roleManage'
  import { selectRoleMenuButtonList } from '@/api/mall/common'
  import {
    selectMenuUrl,
    urlAdd,
    urlUpdate,
    UrlDelete,
    selectRoleUrlId,
    queryMenuIdList,
  } from '@/api/mall/role/urlManage'
  export default {
    props: {
      showsed: {
        type: Object,
        default() {
          return {}
        },
      },
    },
    data() {
      // let menuIdList = (rule, value, callback) => {
      //   if (!value) {
      //     callback(new Error('请选择菜单'))
      //   } else {
      //     callback()
      //   }
      // }
      return {
        operationShow: false,
        searchButton: [],
        listButton: [],
        listSelect: [],
        listSelectId: [],
        selectAllCheck: 1, //奇非全偶已全
        checkedUrlList: [],
        dataSourceLists: [],
        props: {
          checkStrictly: true,
          multiple: true,
          label: 'menuName',
          value: 'id',
        },
        // dataSourceProps: {
        //   value: 'id',
        //   label: 'menuName',
        //   multiple: true,
        //   checkStrictly: true,
        // },
        rowId: '',
        listLoading: false,
        forms: { currentPage: 1, pageSize: 10 },
        isShow: true,
        queryForm: {
          menuId: '',
          url: '',
          description: '',
        },
        menuName: '',
        menuListTable: [],
        orgTableDataList: [],
        currentPage: 1,
        pageSize: 10,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        orgUserName: '',
        orgDsc: '',
        addForm: {
          url: '',
          description: '',
          menuIdList: [],
        },
        addVisible: false,
        multipleSelection: [],
        authorityVisible: false,
        checkListModel: {},
        checkList: [
          {
            id: 1,
            name: '超级管理员',
          },
          {
            id: 2,
            name: '管理员',
          },
        ],
        leftIcon: true,
        rightIcon: false,
        dialogTitle: '',
        queryTableDataList: [],
        rules: {
          description: [
            {
              required: true,
              message: '请输入描述',
              trigger: ['blur', 'change'],
            },
          ],
          menuIdList: [
            {
              required: true,
              message: '请选择菜单',
              trigger: ['blur', 'change'],
            },
          ],
          url: [
            {
              required: true,
              message: '请输入URL',
              trigger: ['blur', 'change'],
            },
          ],
        },
        departmentNames: [],
      }
    },
    watch: {
      // 监听数据变化时候，改变选择
      // patentList(val) {
      //   val.forEach((item, i) => {
      //     console.log(i)
      //     this.multipleSelection.forEach((multItem, j) => {
      //       console.log(j)
      //       if (item.id == multItem.id) {
      //         this.$refs.multipleTable.toggleRowSelection(item, true) //这步操作是让  页面显示选中的数据
      //       }
      //     })
      //   })
      // },
    },
    created() {
      this.selectMenuTree()
      this.fetchData()
      this.selectRoleMenuButtonList()
    },
    methods: {
      // 按钮点击
      handleClickBtn(item, row) {
        if (item.buttonUrl) {
          this[item.buttonUrl](row)
        }
      },
      // 按钮权限
      selectRoleMenuButtonList() {
        selectRoleMenuButtonList({
          roleIdStrList: this.$store.getters['acl/roleList'],
          menuId: this.$route.meta.id,
        })
          .then((res) => {
            if (res.data) {
              this.searchButton = res.data.filter(function (item) {
                return item.buttonType == 1
              })
              this.listButton = res.data.filter(function (item) {
                return item.buttonType == 2
              })
              if (this.listButton.length > 0) {
                this.operationShow = true
              } else {
                this.operationShow = false
              }
            }
          })
          .catch(() => {})
      },
      changeDataSource() {
        const checkedNodesList =
          this.$refs['cascaderMallCatergory'].getCheckedNodes()
        const pathLabel = checkedNodesList.map((item) => {
          return item.pathLabels
        })
        this.addForm.description = pathLabel.toString().replace(/,/g, '-') + '-'
      },
      // 读取菜单Tree
      async selectMenuTree() {
        this.listLoading = true
        await selectMenuTree()
          .then((res) => {
            if (res.data) {
              this.menuListTable = res.data
              this.dataSourceLists = res.data
            }
          })
          .catch(() => {})
        this.listLoading = false
      },
      // 查询已授权接口
      // async selectRoleUrlId() {
      //   await selectRoleUrlId({ roleId: this.showsed.multipleSelection[0].id })
      //     .then((res) => {
      //       if (res.data ) {
      //         this.checkedUrlList = res.data
      //       }
      //     })
      //     .catch(() => {})
      // },
      // 接口列表
      async fetchData(options) {
        this.listLoading = true
        await selectMenuUrl({
          ...options,
          // ...this.forms,
          currentPage: this.showsed.shows == 1 ? 1 : this.forms.currentPage,
          pageSize: this.showsed.shows == 1 ? 1000 : this.forms.pageSize,
          menuId: this.queryForm.menuId,
          // url: this.queryForm.url,
          // description: this.queryForm.description,
        })
          .then((res) => {
            if (res.data) {
              this.orgTableDataList = res.data.records
              this.total = res.data.total
              if (this.showsed.shows == 1) {
                selectRoleUrlId({
                  roleId: this.showsed.multipleSelection[0].id,
                })
                  .then((res) => {
                    if (res.data) {
                      this.$nextTick(function () {
                        this.checkedUrlList = res.data
                        this.orgTableDataList.forEach((row) => {
                          const checkedUrlList = this.checkedUrlList.map(String)
                          if (
                            checkedUrlList.includes(row.id.toString()) == true
                          ) {
                            this.$refs.orgTableDataList.toggleRowSelection(
                              row,
                              true
                            )
                          }
                        })
                      })
                    }
                  })
                  .catch(() => {})
              }
            }
          })
          .catch(() => {})
        this.listLoading = false
      },

      getRowKeys(row) {
        return row.id
      },

      // 表格操作
      handleSelectionChange(val) {
        this.multipleSelection = val
        this.$emit('multiple', val)
      },
      // 点击菜单查询
      datas(row) {
        this.forms.currentPage = 1
        this.queryForm.menuId = row.id
        this.fetchData()
        this.$emit('datas', this.queryForm.menuId)
      },

      // 新增
      handleCreate() {
        this.addVisible = true
      },
      // 关闭
      close() {
        this.$refs['addForm'].resetFields()
        this.addForm = this.$options.data().addForm
        this.addVisible = false
      },
      menuChange(dataSourceLists) {
        if (dataSourceLists && dataSourceLists.length) {
          const arr = dataSourceLists.map((item) => {
            return item[item.length - 1]
          })
          // const str = arr.join(',')
          return arr
        } else {
          return ''
        }
      },
      // this.menuChange(this.addForm.menuIdList)
      // 新增保存
      createData() {
        this.$refs['addForm'].validate((valid) => {
          if (valid) {
            const res = [...this.addForm.menuIdList]
            const orgIds = res.map((item) => {
              return item.toString()
            })
            const parms = {
              url: this.addForm.url,
              description: this.addForm.description,
              menuTreeList: orgIds,
              menuIdList: this.menuChange(this.addForm.menuIdList),
            }
            urlAdd(parms).then(() => {
              this.MS('新增成功')
              this.close()
              this.fetchData()
            })
          }
        })
      },
      // 编辑保存
      updateData() {
        this.$refs['addForm'].validate((valid) => {
          if (valid) {
            const res = [...this.addForm.menuIdList]
            const orgIds = res.map((item) => {
              return item.toString()
            })
            const menuIdList = this.addForm.menuIdList.map((item) => {
              const itemstring = item.toString()
              const itemed = itemstring.split(',')
              return itemed[itemed.length - 1]
            })
            const parms = {
              id: this.rowId,
              url: this.addForm.url,
              description: this.addForm.description,
              menuTreeList: orgIds,
              menuIdList: menuIdList,
            }
            urlUpdate(parms).then(() => {
              this.MS('修改成功')
              this.close()
              this.fetchData()
            })
          }
        })
      },
      // 新增/编辑
      handleEdit(row) {
        this.selectMenuTree()
        if (row) {
          this.showEdit(row)
        } else {
          this.showEdit()
        }
      },
      // 添加编辑title
      showEdit(row) {
        if (!row) {
          this.dialogTitle = '新增'
        } else {
          queryMenuIdList({ id: row.id })
            .then((res) => {
              if (res.data) {
                this.addForm.menuIdList = res.data
              }
            })
            .catch(() => {})
          this.rowId = row.id
          this.dialogTitle = '编辑'
          // this.addForm = Object.assign({}, row)
          this.addForm.url = row.url
          this.addForm.description = row.description
        }
        this.addVisible = true
      },
      // 删除
      handleDelete(row) {
        if (row.id) {
          this.$confirm('是否要删除此行数据?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              UrlDelete({ id: row.id })
                .then(() => {
                  this.MS('删除成功')
                  this.fetchData()
                })
                .catch(() => {})
            })
            .catch(() => {})
        } else {
          if (this.multipleSelection.length === 0) {
            this.handleWarning('请勾选需要删除的记录')
            return
          }
          this.$confirm('是否要删除此行?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              //   this.multipleSelection.map((item) => item.id).join(','),
            })
            .catch(() => {})
        }
      },
      handleWarning(response) {
        this.$message({
          message: response.message || response,
          type: 'warning',
          duration: 2000,
        })
      },

      // 授权
      handleAuthor() {
        if (this.multipleSelection.length === 0) {
          this.handleWarning('请勾选记录')
          return
        }
        if (this.multipleSelection.length > 1) {
          this.handleWarning('只能勾选一条数据')
          return
        }
        this.authorityVisible = true
      },
      handleLeftIcon() {
        this.$refs.asideRef.style.width = '10px'
        this.$refs.asideRef.style.minWidth = '10px'
        this.$refs.contantRef.style.width = '98%'
        this.leftIcon = false
        this.rightIcon = true
      },
      handleRightIcon() {
        this.$refs.asideRef.style.width = '200px'
        this.$refs.asideRef.style.minWidth = '200px'
        this.$refs.contantRef.style.width = '88%'
        this.leftIcon = true
        this.rightIcon = false
      },
      // 重置
      handleReset() {
        this.forms.currentPage = 1
        this.forms.pageSize = 10
        this.queryForm = {}
        this.handleQuery()
      },
      // 搜索
      async handleQuery() {
        this.forms.currentPage = 1
        await this.fetchData({ ...this.queryForm }, false)
        let temp = false
        Object.keys(this.queryForm).forEach((key) => {
          this.queryForm[key] && (temp = true)
        })
        this.isShow = temp
      },
      // 分页
      handleSizeChange(val) {
        this.forms.pageSize = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
      // 分页
      handleCurrentChange(val) {
        this.forms.currentPage = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .classShows {
    max-height: 540px;
    overflow: auto;
  }
  .filter-btn {
    float: right;
  }
  .el-aside {
    width: 190px !important;
  }
  .asideAuthorizeUrl {
    ::v-deep(.el-table) {
      th.is-leaf {
        padding: 15px 0;
        background: #fff;
      }
    }
    ::v-deep(.el-input) {
      width: 140px;
      padding: 10px;
    }
    ::v-deep .aside-table td,
    .aside-table th.is-leaf {
      // border: none;
      cursor: pointer;
    }
    ::v-deep(.aside-table th) {
      .cell {
        font-weight: 700 !important;
        color: #909399 !important;
      }
    }
    ::v-deep(.aside-table::before) {
      width: 0;
    }
  }
  .clickLine {
    width: 8px;
    height: 600px;
    margin-right: 5px;
    margin-left: 5px;
    background-color: #c0d4f0;
    .clickLineLeft {
      position: relative;
      top: 50%;
      left: -3px;
      color: #97a8be;
      cursor: pointer;
    }
    .clickLineRight {
      position: relative;
      top: 50%;
      left: -5px;
      color: #97a8be;
      cursor: pointer;
    }
  }
  .filter-input {
    width: 150px;
  }
  .addDialog {
    .el-form-item__label {
      width: 90px;
      font-size: 14px;
      font-weight: 700;
    }
    .el-input {
      width: 90%;
    }
    .el-textarea {
      width: 90%;
    }

    ::v-deep(.el-form-item) {
      // width: 80%;
    }
  }
  .contantAuthorizeUrl {
    width: 100%;
    padding: 0px 10px;
  }
</style>
