<template>
  <div class="container">
    <el-dialog :before-close="handleClose" title="质检信息" :visible.sync="dialogVisible" width="75%">
      <span>
        <div class="theader_title">
          <div class="txt">订单信息</div>
        </div>
        <el-table border :data="rowlist" max-height="200" style="margin-bottom: 15px">
          <el-table-column align="center" label="序号" type="index" width="55" />
          <el-table-column align="center" label="产品名称" prop="spuName" show-overflow-tooltip width="320" />
          <el-table-column align="center" label="规格信息" prop="specInfo" show-overflow-tooltip width="220" />
          <el-table-column align="center" label="数量" prop="quantity" width="100" />
          <el-table-column align="center" label="产品单价" prop="salesPrice" width="120" />
          <el-table-column align="center" label="支付金额" prop="paymentPrice" show-overflow-tooltip width="120" />
          <el-table-column align="center" label="商品说明" prop="remark" show-overflow-tooltip />
            <!-- <template #default="scope">
              <el-input v-model="scope.row.remark" placeholder="" />
            </template>
          </el-table-column> -->
        </el-table>
        <el-form :inline="true" label-width="100px" :model="savform">
          <!-- <el-form-item label="产品名称：">
            <el-input v-model="savform.interContent" placeholder="请输入产品名称" style="width: 200px" />
          </el-form-item>
          <el-form-item label="产品单价：">
            <el-input v-model="savform.interContent" placeholder="请输入产品单价" style="width: 200px" />
          </el-form-item>
          <el-form-item label="购买类型：">
            <el-input v-model="savform.interContent" placeholder="请输入购买类型" style="width: 200px" />
          </el-form-item>
          <el-form-item label="成交单价：">
            <el-input v-model="savform.interContent" placeholder="请输入成交单价" style="width: 200px" />
          </el-form-item>
          <el-form-item label="商品数量：">
            <el-input v-model="savform.interContent" placeholder="请输入商品数量" style="width: 200px" />
          </el-form-item>
          <el-form-item label="商品金额：">
            <el-input v-model="savform.interContent" placeholder="请输入商品金额" style="width: 200px" />
          </el-form-item>
          <el-form-item label="商品说明：">
            <el-input v-model="savform.interContent" placeholder="请输入商品说明" style="width: 200px" />
          </el-form-item> -->
          <!-- <el-form-item label="付款方式：">
            <el-select v-model="savform.paymentWay" placeholder="请选择付款方式" style="width: 200px">
              <el-option v-for="item in Paymentmethod" :key="item.id" :label="item.labelName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="使用预存：">
            <el-input v-model="savform.prestoreAmount" placeholder="请输入使用预存/定金" style="width: 200px" />
          </el-form-item>
          <el-form-item label="是否到款：">
            <el-select v-model="savform.interId" placeholder="请选择是否到款" style="width: 200px" @focus="queryList(1)">
              <el-option v-for="item in optionsinter" :key="item.id" :label="item.labelName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="是否带票：">
            <el-select v-model="savform.interId" placeholder="请选择是否带票" style="width: 200px" @focus="queryList(1)">
              <el-option v-for="item in optionsinter" :key="item.id" :label="item.labelName" :value="item.id" />
            </el-select>
          </el-form-item> -->
          <div class="theader_title">
            <div class="txt">订单信息</div>
          </div>
          <el-form-item label="收件人：">
            <el-input v-model="savform.userName" placeholder="请输入收件人" style="width: 200px" />
          </el-form-item>
          <el-form-item label="初联日期：">
            <el-date-picker v-model="savform.initialTime" placeholder="请选择选择日期" style="width: 200px" type="date" />
          </el-form-item>
          <el-form-item label="联系电话：">
            <el-input v-model="savform.telNum" placeholder="请输入联系电话" style="width: 200px">
              <template slot="append">
                <div class="cal" @click="callphone(savform.telNum)">拨打</div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="核单说明：">
            <el-input v-model="savform.approval" placeholder="请输入核单说明" style="width: 200px" />
          </el-form-item>
          <!-- <el-form-item label="收件省份：">
            <el-select v-model="savform.channelId" placeholder="请选择收件省份" style="width: 200px" @focus="queryList(2)">
              <el-option v-for="item in areaSelectData" :key="item.id" :label="item.areaName" :value="item.id" />
            </el-select>
          </el-form-item> -->
          <el-form-item label="收件地区：">
            <!-- <el-select v-model="savform.goodId" placeholder="请选择收件地区" style="width: 200px" @focus="queryList(6)">
              <el-option v-for="item in getAreas" :key="item.id" :label="item.areaName" :value="item.id" />
            </el-select> -->
            <el-cascader
              v-model.trim="provinces"
              class="full-width"
              :options="areaSelectData"
              placeholder="请选择地址"
              :props="{
                children: 'children',
                label: 'areaName',
                value: 'areaName',
              }"
              size="large"
              @change="addres"
            />
          </el-form-item>
          <el-form-item label="收件地址：">
            <el-input v-model="savform.address" placeholder="请输入收件地址" style="width: 200px" />
          </el-form-item>
          <el-form-item label="收件邮编：">
            <el-input v-model="savform.postalCode" placeholder="请输入收件邮编" style="width: 200px" />
          </el-form-item>
          <el-form-item label="送货说明：">
            <el-input v-model="savform.delivery" placeholder="请输入送货说明" style="width: 200px" />
          </el-form-item>
          <el-form-item label="复购次数：">
            <el-select v-model="savform.repNum" placeholder="请选择复购次数" style="width: 200px" @focus="queryList(8)">
              <el-option v-for="item in Repurchase" :key="item.id" :label="item.labelName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="订单类型：">
            <el-select v-model="savform.orderType" placeholder="请选择订单类型" style="width: 200px" @focus="queryList(3)">
              <el-option v-for="item in Ordertype" :key="item.id" :label="item.labelName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="备注信息：">
            <el-input v-model="savform.remark" placeholder="请输入备注信息" style="width: 200px" />
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" @click="save">保存</el-button>
            <el-button type="" @click="passeid">通过</el-button>
            <el-button type="" @click="nopass">驳回</el-button>
          </el-form-item>
        </el-form>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="历史订单" name="1" />
          <el-tab-pane label="电话录音" name="2" />
        </el-tabs>
        <el-table v-if="activeName == '1'" border :data="tablist" max-height="300">
          <el-table-column align="center" label="序号" type="index" width="55" />
          <el-table-column align="center" label="订单编号" prop="orderNo" width="220" />
          <el-table-column align="center" label="订单日期" prop="createTime" width="200" />
          <el-table-column align="center" label="订单金额" prop="paymentPrice" show-overflow-tooltip width="140" />
          <el-table-column align="center" label="下单员工" prop="profile" show-overflow-tooltip width="140">
            <template #default="scope">{{ scope.row.realName }}{{ scope.row.profile == '' ? '' :'(' + scope.row.profile + ')'  }}</template>
          </el-table-column>
          <el-table-column align="center" label="状态" prop="statusDesc" show-overflow-tooltip width="80" />
          <el-table-column align="center" label="所购产品" prop="product" show-overflow-tooltip />
          <el-table-column align="center" label="下单地址" prop="address" show-overflow-tooltip />
        </el-table>
        <el-pagination
          v-if="activeName == '1'"
          background
          :current-page="1"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </span>
      <soundrecording v-if="activeName == '2'" :soundinfo="sound" />
    </el-dialog>
  </div>
</template>

<script>
import { orderrecord, orderinfoupdateQuality, getQualityDetails, uuUserSugarRecordQualityqueryVeryOrder } from '@/api/mall/health/casemy/index'
import { queryPage } from '@/api/mall/setting/stplabel'
import { getAreaThree } from '@/api/mall/common'
import soundrecording from '../Soundrecording/index.vue'
import { uuUserSugarRecordQualitypass, uuUserSugarRecordQualitybohui } from '@/api/mall/seas/index'
export default {
  components: {
    soundrecording,
  },
  props: {
    sound: {
      type: Object,
      default: () => {},
    },
    recordedshow: {
      type: Boolean,
      default: () => {},
    },
  },
  data() {
    return {
      total: 0,
      tablist: [],
      showlist: false,
      activeName: '1',
      showtblist: null,
      savform: {},
      form: {
        size: 10,
        current: 1,
        recordId: 0,
      },
      domainUrl: '',
      dialogVisible: false,
      optionsgoodId: null,
      optionsinter: null,
      Repurchase: null,
      Ordertype: null,
      areaSelectData: [],
      Paymentmethod: [
        {
          id: 1,
          labelName: '货到付款',
        },
        {
          id: 2,
          labelName: '在线支付',
        },
        {
          id: 3,
          labelName: '款到发货',
        },
      ],
      optionschannelId: null,
      getAreas: [],
      provinces: '',
      rowlist: null,
    }
  },
  watch: {
    sound(newValue, oldValue) {
      if (newValue != oldValue) {
        this.form.recordId = newValue.recordId
        this.form.id = newValue.id
        this.form.recordQualityId = newValue.id
      }
    },
    recordedshow(newValue, oldValue) {
      if (newValue != oldValue) {
        this.dialogVisible = newValue
        this.uuUserSugarRecordLog()
        this.getQualityDetails()
        this.uuUserSugarRecordQualityqueryVeryOrder(this.sound.orderId)
        //首单
        this.stplabelOrder()
      }
    },
  },
  created() {
    // this.form.recordId = this.sound.recordId
    this.uuUserSugarRecordLog()
    this.getAreaThree()
    // this.getArea()
  },
  methods: {
    callphone(e) {
      this.$EventBus.$emit('eventName', e)
    },
    passeid() {
      this.$confirm('是否通过该用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let ids = [this.sound.id]
          uuUserSugarRecordQualitypass({ ids }).then((res) => {
            if (res.status == 200) {
              this.$message.success('通过成功')
              this.$emit('getlist')
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message('您已取消通过')
        })
    },
    nopass() {
      this.$prompt('请输入驳回缘由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then((da) => {
          if (da.value) {
            let ids = [this.sound.id]
            uuUserSugarRecordQualitybohui({ ids, content: da.value }).then((res) => {
              if (res.status == 200) {
                this.$message.success('驳回成功')
                this.$emit('getlist')
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            this.$message.error('请填写驳回缘由')
          }
        })
        .catch(() => {
          this.$message('您已取消驳回')
        })
    },
    uuUserSugarRecordQualityqueryVeryOrder(e) {
      uuUserSugarRecordQualityqueryVeryOrder({ orderId: e }).then((res) => {
        console.log(res.data, '564-544-465-')
        this.rowlist = res.data
      })
    },
    handleClick() {},
    save() {
      orderinfoupdateQuality({ ...this.savform }).then((res) => {
        console.log(res.data)
        this.MS('保存成功!')
      })
    },
    async getAreaThree() {
      await getAreaThree()
        .then((res) => {
          if (res.data) {
            this.areaSelectData = res.data
          }
        })
        .catch(() => {})
    },
    addres(e) {
      this.savform.province = e[0]
      this.savform.city = e[1]
      this.savform.county = e[2]
    },
    // async getArea() {
    //   await getAreaThree()
    //     .then((res) => {
    //       if (res.data) {
    //         let adress=[]
    //         res.data.forEach(element => {
    //           element.children.forEach(item=>{
    //              adress.push(item)
    //           })
    //         });
    //         this.getAreas=adress
    //       }
    //     })
    //     .catch(() => {})
    // },
    queryList(e) {
      queryPage({ labelType: e }).then((res) => {
        if (e == 8) {
          this.Repurchase = res.data.records
        }
        if (e == 3) {
          this.Ordertype = res.data.records
        }
        // console.log(res.data)
        // this.optionsinter=res.data.records
      })
    },

    //首单查询数据
    stplabelOrder() {
      queryPage({ labelType: 8 }).then((res) => {
        this.Repurchase = res.data.records
      })
      queryPage({ labelType: 3 }).then((res) => {
        this.Ordertype = res.data.records
      })
    },
    //关闭对话框
    handleClose() {
      this.activeName = '1'
      this.tablist = []
      this.$emit('closeshowtab', false)
      this.dialogVisible = false
    },
    //关闭回传
    closeshow() {
      this.showlist = false
    },
    showhide(row) {
      this.showtblist = row
      this.showlist = true
      console.log(this.showlist)
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.uuUserSugarRecordLog()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.uuUserSugarRecordLog()
    },

    //质检数据查看查询--lyj
    getQualityDetails() {
      getQualityDetails({ ...this.form }).then((res) => {
        console.log(res)
        this.savform = res.data
        if (res.data.orderType != '0') {
          this.savform.orderType = res.data.orderType
        }
        if (res.data.orderType == '0') {
          this.savform.orderType = null
        }
        if (res.data.repNum != '0') {
          this.savform.repNum = res.data.repNum
        }
        if (res.data.repNum == '0') {
          this.savform.repNum = null
        }
        const citys = res.data.city ? res.data.city : '',
          countys = res.data.county ? res.data.county : '',
          provinces = res.data.province ? res.data.province : ''
        const adr = [provinces, citys, countys]
        this.lists = {
          provinces: adr,
        }
        this.provinces = adr
        // this.savform = this.lists
        console.log('查询地址', this.lists)
      })
    },

    //列表请求
    uuUserSugarRecordLog() {
      orderrecord({ ...this.form }).then((res) => {
        this.total = res.data.total
        this.form.size = res.data.size
        this.form.current = res.data.current
        this.tablist = res.data.records
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.theader_title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
  background: rgb(237, 237, 237);
  .txt {
    margin-left: 10px;
    text-indent: 1em;
    border-left: 5px solid #3782ff;
  }
}
::v-deep {
  // .el-input__inner {
  //   width: 200px;
  // }
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
  .full-width {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 32px;
    .el-input__inner {
      height: 32px;
    }
  }
}
</style>
