var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "custom-table",
      staticClass: "custom-table-container",
      class: { "vab-fullscreen": _vm.isFullscreen },
    },
    [
      _c("searchform", { on: { receive: _vm.receiverow } }),
      _c("div", { staticClass: "flexk" }, [
        _c(
          "div",
          { staticStyle: { float: "left", "margin-bottom": "15px" } },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.distrtions } },
              [_vm._v("分配")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "" },
                on: {
                  click: function ($event) {
                    return _vm.recyclea("1")
                  },
                },
              },
              [_vm._v("通过")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "" },
                on: {
                  click: function ($event) {
                    return _vm.recyclea("2")
                  },
                },
              },
              [_vm._v("驳回")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "ref", staticStyle: { float: "right" } },
          [
            _c(
              "el-button",
              {
                staticStyle: { margin: "0 10px 10px 0 !important" },
                on: { click: _vm.clickFullScreen },
              },
              [
                _c("vab-icon", {
                  attrs: {
                    icon: _vm.isFullscreen
                      ? "fullscreen-exit-fill"
                      : "fullscreen-fill",
                  },
                }),
                _vm._v(" 表格全屏 "),
              ],
              1
            ),
            _c(
              "el-popover",
              {
                ref: "popover",
                attrs: {
                  "popper-class": "custom-table-checkbox",
                  trigger: "hover",
                },
                scopedSlots: _vm._u([
                  {
                    key: "reference",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 10px 10px 0 !important" },
                          },
                          [
                            _c("vab-icon", { attrs: { icon: "line-height" } }),
                            _vm._v(" 表格尺寸 "),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.lineHeight,
                      callback: function ($$v) {
                        _vm.lineHeight = $$v
                      },
                      expression: "lineHeight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "medium" } }, [
                      _vm._v("大"),
                    ]),
                    _c("el-radio", { attrs: { label: "small" } }, [
                      _vm._v("中"),
                    ]),
                    _c("el-radio", { attrs: { label: "mini" } }, [
                      _vm._v("小"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          ref: "tableSort",
          attrs: {
            border: "",
            data: _vm.data,
            "max-height": "600",
            size: _vm.lineHeight,
          },
          on: { "selection-change": _vm.selectd },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "55" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              "show-overflow-tooltip": "",
              type: "index",
              width: "50",
            },
          }),
          _vm._l(_vm.listContent, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                "show-overflow-tooltip": item.tooltip,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              content: _vm.getContent(row[item.prop]),
                              disabled: item.tooltip,
                              effect: "dark",
                              placement: "top-end",
                              value: item.tooltip,
                            },
                          },
                          [
                            _vm.columns[index].prop == "orderNo"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.ordeNoClick(row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row[item.prop]))]
                                )
                              : _vm._e(),
                            _vm.columns[index].prop == "quaState"
                              ? _c(
                                  "span",
                                  [
                                    row[item.prop] == 1
                                      ? _c(
                                          "el-tag",
                                          { attrs: { type: "succes" } },
                                          [_vm._v("待质检")]
                                        )
                                      : _vm._e(),
                                    row[item.prop] == 2
                                      ? _c(
                                          "el-tag",
                                          { attrs: { type: "danger" } },
                                          [_vm._v("已通过")]
                                        )
                                      : _vm._e(),
                                    row[item.prop] == 3
                                      ? _c(
                                          "el-tag",
                                          { attrs: { type: "info" } },
                                          [_vm._v("已驳回")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.columns[index].prop == "urgState"
                              ? _c("span", [
                                  row[item.prop] == "0"
                                    ? _c("span", [_vm._v("是")])
                                    : _vm._e(),
                                  row[item.prop] == "1"
                                    ? _c("span", [_vm._v("否")])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm.columns[index].prop == "paymentWay"
                              ? _c("span", [
                                  row[item.prop] == "1"
                                    ? _c("span", [_vm._v("货到付款")])
                                    : _vm._e(),
                                  row[item.prop] == "2"
                                    ? _c("span", [_vm._v("在线支付")])
                                    : _vm._e(),
                                  row[item.prop] == "3"
                                    ? _c("span", [_vm._v("付款发货")])
                                    : _vm._e(),
                                ])
                              : _c(
                                  "span",
                                  { staticStyle: { "white-space": "nowrap" } },
                                  [_vm._v(_vm._s(row[item.prop]))]
                                ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "default", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.operation(row)
                          },
                        },
                      },
                      [_vm._v("录音")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "default", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showhi(row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "default", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showedis(row)
                          },
                        },
                      },
                      [_vm._v("审核")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("table-edit", {
        ref: "edit",
        attrs: { config: _vm.config, options: _vm.options, rules: _vm.rules },
        on: { fileStatus: _vm.fileupload, submit: _vm.submit },
        model: {
          value: _vm.form,
          callback: function ($$v) {
            _vm.form = $$v
          },
          expression: "form",
        },
      }),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.current,
          layout: "total, sizes, prev, pager, next, jumper",
          "page-size": _vm.queryForm.size,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("distrtion", {
        attrs: { shodist: _vm.distrtionshow, userid: _vm.selecuserid },
        on: { closedistr: _vm.closdistr },
      }),
      _c("soundrecirding", {
        attrs: { recordedshow: _vm.Recordedhide, sound: _vm.recorduserinfo },
        on: { closesound: _vm.closesounhide },
      }),
      _c("shwotables", {
        attrs: { recordedshow: _vm.shwohidetab, sound: _vm.recorduserinfo },
        on: { closeshowtab: _vm.hidelog, getlist: _vm.getlists },
      }),
      _c("examinedialog", {
        attrs: { edirow: _vm.edisrow, showedit: _vm.showedi },
        on: { clearedi: _vm.cleareit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }