<template>
  <div>
    <el-dialog v-if="dialogFormVisible" append-to-body :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="60%" @close="close">
      <el-form class="searchForm" :model="searchFrom" size="small" style="margin-left: 20px">
        <el-row :gutter="5">
          <el-col :span="10">
            <el-form-item prop="name">
              <el-input v-model.trim="searchFrom.name" clearable name="name" placeholder="请输入搜索位置信息" @keyup.enter.native="searchAddress" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button icon="el-icon-search" type="primary" @click="searchAddress">搜索位置</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div id="mapContainer" style="width: 100%; height: 400px"></div>
      <el-table :data="nearPointList" highlight-current-row max-height="300" style="width: 100%">
        <el-table-column label="附近地点名称" prop="name" />
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click.native.prevent="selectAddress(scope.row)">确认选择</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
  // import maps from 'qqmap'
  export default {
    name: 'Maps',
    props: {
      infos: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        dialogFormVisible: false,
        rootAddress: '',
        searchFrom: { name: '' },
        nearPointList: [],
        map: '',
        data: '',
        markersArray: [],
        detailaddress: '',
        pois: '',
        resultdetail: '',
        latClick: '',
        lngClick: '',
      }
    },

    methods: {
      close() {
        this.dialogFormVisible = false
        this.searchFrom.name = ''
        this.nearPointList = []
      },
      // 搜索地址
      searchAddress() {
        if (!this.searchFrom.name) {
          this.ME('请输入搜索内容')
          return
        }
        // this.setLocationByAddress(this.searchFrom.name)
        // this.geocoderLocation.getLocation(this.searchFrom.name)
        // setTimeout(() => {
        //   console.log(this.resultdetail)
        //   if (this.resultdetail) {
        this.searchService.setLocation(this.searchFrom.name)
        this.searchService.search(this.searchFrom.name)
        //   }
        // }, 100)
      },

      initMap() {
        let that = this
        let latLon = new window.qq.maps.LatLng(29.57, 106.55)
        that.map = new window.qq.maps.Map('mapContainer', {
          zoom: 10,
          center: latLon,
          mapTypeId: window.qq.maps.MapTypeId.ROADMAP,
        })
        // 周边服务
        var ap = new window.qq.maps.place.Autocomplete(document.getElementById('place'))
        var keyword = ''
        that.searchService = new window.qq.maps.SearchService()
        that.searchService.setComplete(function (result) {
          if (result.type === 'CITY_LIST') {
            that.searchService.setLocation(result.detail.cities[0].cityName)
            that.searchService.search(keyword)
            return
          }
          that.nearPointList = result.detail.pois
          var pois = result.detail.pois
          var latlngBounds = new window.qq.maps.LatLngBounds()
          latlngBounds.extend(pois[0].latLng)
          that.map.fitBounds(latlngBounds)
          //对搜索的地址添加标注
          let searchCenter = null
          searchCenter = new window.qq.maps.LatLng(pois[0].latLng.lat, pois[0].latLng.lng)
          let marker = new window.qq.maps.Marker({
            position: searchCenter,
            map: that.map,
          })
          that.markersArray.push(marker)
          if (that.markersArray.length > 1) {
            for (let i = 0; i < that.markersArray.length - 1; i++) {
              that.markersArray[i].setMap(null) // 清除标记
            }
          }
          // var pois = result.detail.pois
          // var latlngBounds = new window.qq.maps.LatLngBounds()
          // for (var i = 0, l = pois.length; i < l; i++) {
          //   that.pois = pois[0].name
          //   var poi = pois[i]
          //   latlngBounds.extend(poi.latLng)
          //   var marker = new window.qq.maps.Marker({
          //     map: that.map,
          //     position: poi.latLng,
          //   })
          //   that.markersArray.push(marker)
          //   if (that.markersArray.length > 1) {
          //     for (let i = 0; i < that.markersArray.length - 1; i++) {
          //       that.markersArray[i].setMap(null) // 清除标记
          //     }
          //   }
          //   marker.setTitle(poi.name)
          // }
          // that.map.fitBounds(latlngBounds)
        })
        // 点击地图
        window.qq.maps.event.addListener(that.map, 'click', function (event) {
          console.log(event.latLng)
          that.latClick = event.latLng.lat
          that.lngClick = event.latLng.Lng
          let latLng = new window.qq.maps.LatLng(event.latLng.getLat(), event.latLng.getLng())
          that.geocoder.getAddress(latLng)

          setTimeout(() => {
            that.searchService.setLocation(that.detailaddress)
            that.searchService.search(that.detailaddress)
          }, 100)
          // that.searchService.setComplete(function (result) {
          //   console.log(result)
          //   that.nearPointList = result.detail.pois
          //   // var pois = result.detail.pois
          //   var latlngBounds = new window.qq.maps.LatLngBounds()
          //   latlngBounds.extend(that.lngClick)
          //   that.map.fitBounds(latlngBounds)
          //   //对搜索的地址添加标注
          //   let marker = new window.qq.maps.Marker({
          //     position: latLng,
          //     map: that.map,
          //   })
          //   that.markersArray.push(marker)
          //   if (that.markersArray.length > 1) {
          //     for (let i = 0; i < that.markersArray.length - 1; i++) {
          //       that.markersArray[i].setMap(null) // 清除标记
          //     }
          //   }
          // })
        })

        //添加监听事件
        window.qq.maps.event.addListener(ap, 'confirm', function (res) {
          keyword = res.value
          that.searchService.search(keyword)
        })
        // 经纬度解析
        that.geocoder = new window.qq.maps.Geocoder()
        that.geocoder.setComplete(function (result) {
          console.log(result)
          that.detailaddress = result.detail.address
          that.map.setCenter(result.detail.location)
          // let marker = new window.qq.maps.Marker({
          //   map: that.map,
          //   position: result.detail.location,
          // })
          // that.markersArray.push(marker)
          // if (that.markersArray.length > 1) {
          //   for (let i = 0; i < that.markersArray.length - 1; i++) {
          //     that.markersArray[i].setMap(null) // 清除标记
          //   }
          // }
        })
        that.geocoder.setError(function () {
          that.ME('网络错误，请刷新重试')
        })
        // 地址解析
        that.geocoderLocation = new window.qq.maps.Geocoder()
        that.geocoderLocation.setComplete(function (result) {
          console.log(result.detail.address)
          that.resultdetail = result.detail.address
          let latLng = new window.qq.maps.LatLng(result.detail.location.lat, result.detail.location.lng)
          that.geocoder.getAddress(latLng)
        })
        that.geocoderLocation.setError(function () {
          that.ME('出错了,请输入正确的地址')
        })
      },
      // 选择地址
      selectAddress(row) {
        console.log(row)
        this.$emit('on-select', row.name, this.rootAddress, row.latLng.lat, row.latLng.lng, row.address ? row.address : '')
        this.dialogFormVisible = false
      },
      // 弹框
      shows() {
        this.dialogFormVisible = true
        this.$nextTick(function () {
          this.initMap()
          if (this.infos) {
            // eslint-disable-next-line no-useless-escape
            this.searchFrom.name = this.infos.replace(/\s+|[,\/]/g, '')
            if (this.searchFrom.name) {
              // this.setLocationByAddress(this.searchFrom.name)
              this.searchService.setLocation(this.searchFrom.name)
              this.searchService.search(this.searchFrom.name)
            }
          }
        })
      },
      // 地址解析
      setLocationByAddress(address) {
        this.rootAddress = address
        this.geocoderLocation.getLocation(address)
      },
      // 经纬度解析
      setLocationByLatLng(lat, lng) {
        let latLng = new window.qq.maps.LatLng(lat, lng)
        this.geocoder.getAddress(latLng)
      },
    },
  }
</script>
