<template>
  <div>
    <quill-editor
      ref="myQuillEditor"
      v-model="articleForm.artContent"
      v-screen
      class="quilleditor"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @change="onEditorChange($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
    />
    <!-- 富文本框 图片上传 -->
    <el-upload
      ref="Quillupload"
      :action="action"
      :before-upload="bfUpload"
      class="pictureQuill"
      :file-list="fileListQuill"
      :headers="headers"
      list-type="picture-card"
      :on-success="handleSuccessQuill"
      style="display: none"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <!-- 富文本框 视频上传 -->
    <el-upload
      :action="action"
      :before-upload="beforeUploadVideo"
      class="pictureQuillVideo"
      :headers="headers"
      :on-progress="uploadVideoProcess"
      :on-success="handleVideoSuccess"
      :show-file-list="false"
      style="display: none"
    >
      <video v-if="videoForm.showVideoPath != '' && !videoFlag" controls height="100" :src="videoForm.showVideoPath" width="400">您的浏览器不支持视频播放</video>
      <i v-else-if="videoForm.showVideoPath == '' && !videoFlag" class="el-icon-plus avatar-uploader-icon"></i>
      <el-progress v-if="videoFlag == true" :percentage="videoUploadPercent" style="margin-top: 7px" type="circle" />
    </el-upload>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import * as Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import '@/assets/css/quillEditor.css'
var fonts = ['SimHei', 'SimSun', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'sans-serif']
import blotSelect from './components/blot'
blotSelect(Quill)
// 字体大小
var sizes = [false, '14px', '16px', '18px', '20px', '22px', '24px', '26px']
var Size = Quill.import('formats/size')
Size.whitelist = sizes
// 行高
import { lineHeightStyle } from '@/views/mall/article/release/components/lineHeight'
Quill.register({ 'formats/lineHeight': lineHeightStyle }, true)
// 行间距
import { letterSpacingStyle } from '@/views/mall/article/release/components/letterSpacing'
Quill.register({ 'formats/letterSpacing': letterSpacingStyle }, true)
// 字体
var Font = Quill.import('formats/font')
Font.whitelist = fonts
Quill.register(Font, true)
// 链接
var Link = Quill.import('formats/link')
class MyLink extends Link {
  static create(value) {
    let node = super.create(value)
    value = this.sanitize(value)
    if (!value.startsWith('http')) {
      value = 'http://' + value
    }
    node.setAttribute('href', value)
    return node
  }
}
Quill.register(MyLink)
// 视频
import Video from '@/views/mall/article/release/components/video.js'
Quill.register(Video, true)
// 工具栏配置
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
  [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ script: 'sub' }, { script: 'super' }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  [{ align: [] }], // 对齐方式-----[{ align: [] }]
  [{ indent: '-1' }, { indent: '+1' }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [{ direction: 'rtl' }], // 文本方向-----[{'direction': 'rtl'}]
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{ size: sizes }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  [{ header: [false, 1, 2, 3, 4, 5, 6] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
  [{ font: fonts }], // 字体种类-----[{ font: [] }]
  [{ lineheight: ['initial', '1', '1.5', '1.75', '2', '3', '4', '5'] }], //行高
  [
    {
      letterSpacing: ['initial', '2px', '4px', '6px', '8px', '10px', '12px', '14px', '16px'],
    },
  ], //行间距
  ['link', 'image', 'video'], // 链接、图片、视频-----['link', 'image', 'video']
  ['clean'], // 清除文本格式-----['clean']
  ['insertMetric'], //秀米
  ['otEdit'], //135编辑器
]
import { uploadURL } from '@/config'
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/token'
export default {
  components: {
    quillEditor,
  },
  directives: { screen }, //富文本框放大
  props: {
    editcontent: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      action: uploadURL,
      fileListQuill: [],
      headers: {},
      floag: true,
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 460, // 默认生成截图框宽度
        autoCropHeight: 240, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [23, 12], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        canMove: true,
        enlarge: 1,
      },
      option2: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        canMove: true,
      },
      editorOption: {
        modules: {
          //工具栏定义的
          toolbar: {
            container: toolbarOptions, //自定义工具栏，略
            handlers: {
              that: this,
              // 行间距
              letterSpacing: function (value) {
                if (value) {
                  this.quill.format('letterSpacing', value)
                } else {
                  console.log(value)
                }
              },
              // 行高
              lineheight: function (value) {
                if (value) {
                  this.quill.format('lineHeight', value)
                } else {
                  console.log(value)
                }
              },
              // 图片
              image: function (value) {
                //替换原上传功能
                if (value) {
                  document.querySelector('.pictureQuill input').click() //核心
                } else {
                  this.quill.format('image', false)
                }
              },
              // 视频
              video: function (value) {
                //替换原上传功能
                if (value) {
                  document.querySelector('.pictureQuillVideo input').click() //核心
                  console.log(111111)
                } else {
                  this.quill.format('video', false)
                }
              },
              // 秀米
              insertMetric: function () {
                let self = this.handlers.that
                self.visible = true
              },
              // 135编辑器
              otEdit: function () {
                let self = this.handlers.that
                self.visible2 = true
              },
            },
          },
        },
        //主题
        theme: 'snow',
        placeholder: '请输入正文',
      },
      //视频
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: '',
      //进度条的进度，
      isShowUploadVideo: false,
      //显示上传按钮
      videoForm: {
        showVideoPath: '',
      },
      value: this.editcontent,
      articleForm: {
        artTags: '',
        artScope: '1',
        artContent: '',
        artImg: '',
        artColumn: [],
        artType: [],
        artHontent: '',
        artLogo: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/token',
      tenantId: 'user/tenantId',
    }),
    editor() {
      return this.$refs.myQuillEditor.quill
    },
  },
  watch: {
    editcontent(newValue, oldValue) {
      if (newValue != oldValue) {
        this.articleForm.artContent = newValue
      }
    },
  },
  created() {
    this.articleForm.artContent = this.value
    const db = getToken()
    this.headers['Authorization'] = `Bearer ` + `${db}`
    this.headers['Switch-Tenant-Id'] = `${this.tenantId}`
    // this.queryListColumn()
    // this.selectSysDict()
    // this.queryListType()
  },
  methods: {
    onEditorBlur(e) {
      console.log(e)
    },
    onEditorChange(e) {
      this.$emit('editrow', e)
    },
    onEditorFocus(e) {
      console.log(e)
      this.floag = true
    },
    onEditorReady(e) {
      console.log(e)
    },
    bfUpload(file) {
      console.log(file)
      if ('image/png' == file.type || 'image/jpeg' == file.type || 'image/jpg' == file.type) {
        console.log()
      } else {
        this.ME('图片上传失败,请上传png,jpeg,jpg格式')
        return false
      }
    },
    // 富文本框上传图片
    handleSuccessQuill(response, file) {
      console.log(response, file)
      this.fileListQuill.push({ url: response.data.filePath })
      let quill = this.$refs.myQuillEditor.quill
      console.log(response.data.filePath)
      if (response.data.filePath != null) {
        let length = quill.getSelection().index
        quill.insertEmbed(length, 'image', response.data.filePath)
        quill.setSelection(length + 1)
      }
    },
    beforeUploadVideo(file) {
      var fileSize = file.size / 1024 / 1024 < 50
      if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) == -1) {
        this.$message.error('请上传正确的视频格式')
        return false
      }
      if (!fileSize) {
        this.$message.error('视频大小不能超过50MB')
        return false
      }
      this.isShowUploadVideo = false
    },
    //进度条
    uploadVideoProcess(event, file, fileList) {
      console.log(fileList)
      this.videoFlag = true
      this.videoUploadPercent = file.percentage.toFixed(0) * 1
    },
    //富文本上传视频
    handleVideoSuccess(res, file) {
      console.log(res, file)
      this.isShowUploadVideo = true
      this.videoFlag = false
      this.videoUploadPercent = 0
      let quill = this.$refs.myQuillEditor.quill
      this.videoForm.showVideoPath = res.data.filePath
      if (res.data.filePath != null) {
        let length = quill.getSelection().index
        quill.insertEmbed(length, 'video', res.data.filePath)
        quill.setSelection(length + 1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ql-toolbar.ql-snow {
    display: flex;
    flex-wrap: wrap;
  }
  .ql-editor{
    min-height: 450px;
  }
}
</style>