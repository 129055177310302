<template>
  <el-dialog
  :before-close="handleClose"
  title="编辑"
  :visible.sync="dialogVisible"
  width="40%">
  <div>
    <el-form ref="addForm" label-width="100px" :model="addForm" :rules="rules">
      <div class="dialogForm">
        <el-form-item class="demo-form-inline" :inline="true"  label="店铺" prop="shopId">
          <el-select v-model="addForm.shopId" placeholder="请选择 店铺">
            <el-option v-for="(ym,index) in options" :key="index" :label="ym.name" :value="ym.id"/>
          </el-select>
          </el-form-item>
          <el-form-item class="demo-form-inline"  :inline="true" label="名称" prop="name">
            <el-input v-model="addForm.name" placeholder="请输入 名称"/>
          </el-form-item>
          <el-form-item class="demo-form-inline" :inline="true" label="排序" prop="sort">
            <el-input-number v-model="addForm.sort"  controls-position="right"  placeholder="排序"/>
          </el-form-item>
          <el-form-item class="demo-form-inline" :inline="true"  label="模板类型" prop="type">
            <el-radio-group v-model="addForm.type">
              <el-radio label="1">卖家承担运费</el-radio>
              <el-radio label="2">卖家包邮</el-radio>
            </el-radio-group>
          </el-form-item>
      </div>
      <div v-if="addForm.type==1" class="showhand">
        <el-form-item class="demo-form-inline" :inline="true"  label="计费方式" prop="chargeType">
          <el-radio-group v-model="addForm.chargeType" @input="contName">
            <el-radio label="1">按件数</el-radio>
            <el-radio label="2">按重量</el-radio>
            <el-radio label="3">按体积</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="demo-form-inline" :inline="true" :label="`首`+labelName" prop="firstNum">
          <el-input-number v-model="addForm.firstNum"  controls-position="right"  :placeholder="`请输入 首`+labelName"/>
        </el-form-item>
        <el-form-item class="demo-form-inline" :inline="true" label="首运费" prop="firstFreight">
          <el-input-number v-model="addForm.firstFreight"  controls-position="right"  placeholder="首运费"/>
        </el-form-item>
        <el-form-item class="demo-form-inline" :inline="true" :label="`续`+labelName" prop="continueNum">
          <el-input-number v-model="addForm.continueNum"  controls-position="right" :placeholder="`请输入 续`+labelName"/>
        </el-form-item>
        <el-form-item class="demo-form-inline" :inline="true" label="续运费" prop="continueFreight">
          <el-input-number v-model="addForm.continueFreight"  controls-position="right"  placeholder="续运费"/>
        </el-form-item>
      </div>
    </el-form>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="save">确 定</el-button>
  </span>
</el-dialog>
</template>

<script>
  import {getList} from '@/api/mall/shop/info'
  import { putObj} from '@/api/mall/setting/freighttemplat'
  export default {
    props:{
      shoehand:{
        type:Boolean,
        default:()=>{}
      },
      rowlist:{
        type:Object,
        default:()=>{}
      }
    },
    data() {
      return {
        dialogVisible: this.shoehand,
        addForm:{
          type:'1',
          chargeType:'1',
        },
        options:[],
        labelName:'件(个)',
        rules:{
          shopId: [
            { required: true, message: '请选择 店铺', trigger: 'change' },
          ],
          name: [
            { required: true, message: '请输入 名称', trigger: 'blur' },
          ],
          type: [
            { required: true, message: '请选择 计费方式', trigger: 'blur' },
          ],
          chargeType: [
            { required: true, message: '请输入内容', trigger: 'blur' },
          ],
          firstNum: [
            { required: true, message: '请输入内容', trigger: 'blur' },
          ],
          firstFreight: [
            { required: true, message: '请输入内容', trigger: 'blur' },
          ],
          continueNum: [
            { required: true, message: '请输入内容', trigger: 'blur' },
          ],
          continueFreight: [
            { required: true, message: '请输入内容', trigger: 'blur' },
          ],
        }
      };
    },
    watch:{
      shoehand(newValue,oldValue){
        if(newValue!=oldValue){
          this.dialogVisible=newValue
          this.getList()
        }
      },
      rowlist(newValue,oldValue){
        if(newValue!=oldValue){
          this.addForm=newValue
          console.log("123")
        }
      }
    },
    methods: {
      contName(item){
        console.log(item,'chuaner')
        if(item==1){
          this.labelName= '件(个)'
        }else if(item==2){
          this.labelName=  '重(KG)'
        }else{
          this.labelName=  '体积(m³)'
        }
      },
      getList(){
        getList({}).then(res=>{
          console.log(res.data)
          this.options=res.data
        })
      },
      handleClose() {
        this.$refs.addForm.resetFields()
        this.$emit('closeshow',false)
      },
      save(){
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            putObj({...this.addForm}).then(()=>{
              this.$emit('closeshow',false)
              this.$message.success('编辑成功')
            })
          } else {
            return false;
          }
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
.dialogForm,.showhand{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  width: 100%;
  ::v-deep{
    .el-dialog__header{
      border-bottom: 1px solid #e5e5e5;
    }
    .el-input,.el-select,.el-input-number{
      width: 100%;
    }
  }
}
</style>