var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cy-container quill-box" },
    [
      _c(
        "el-form",
        {
          ref: "articleForm",
          staticStyle: { width: "60%" },
          attrs: {
            "label-width": "100px",
            model: _vm.articleForm,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "文章标题", prop: "artTitle" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入文章标题" },
                model: {
                  value: _vm.articleForm.artTitle,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.articleForm,
                      "artTitle",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "articleForm.artTitle",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文章摘要", prop: "artShort" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入文章摘要" },
                model: {
                  value: _vm.articleForm.artShort,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.articleForm,
                      "artShort",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "articleForm.artShort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { height: "350px" },
              attrs: { label: "文章内容", prop: "artContent" },
            },
            [
              _c("quill-editor", {
                directives: [{ name: "screen", rawName: "v-screen" }],
                ref: "myQuillEditor",
                staticClass: "quilleditor",
                staticStyle: { height: "265px" },
                attrs: { options: _vm.editorOption },
                on: {
                  blur: function ($event) {
                    return _vm.onEditorBlur($event)
                  },
                  change: function ($event) {
                    return _vm.onEditorChange($event)
                  },
                  focus: function ($event) {
                    return _vm.onEditorFocus($event)
                  },
                  ready: function ($event) {
                    return _vm.onEditorReady($event)
                  },
                },
                model: {
                  value: _vm.articleForm.artContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.articleForm, "artContent", $$v)
                  },
                  expression: "articleForm.artContent",
                },
              }),
              _c(
                "el-upload",
                {
                  ref: "Quillupload",
                  staticClass: "pictureQuill",
                  staticStyle: { display: "none" },
                  attrs: {
                    action: _vm.action,
                    "before-upload": _vm.bfUpload,
                    "file-list": _vm.fileListQuill,
                    headers: _vm.headers,
                    "list-type": "picture-card",
                    "on-success": _vm.handleSuccessQuill,
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
              _c(
                "el-upload",
                {
                  staticClass: "pictureQuillVideo",
                  staticStyle: { display: "none" },
                  attrs: {
                    action: _vm.action,
                    "before-upload": _vm.beforeUploadVideo,
                    headers: _vm.headers,
                    "on-progress": _vm.uploadVideoProcess,
                    "on-success": _vm.handleVideoSuccess,
                    "show-file-list": false,
                  },
                },
                [
                  _vm.videoForm.showVideoPath != "" && !_vm.videoFlag
                    ? _c(
                        "video",
                        {
                          attrs: {
                            controls: "",
                            height: "100",
                            src: _vm.videoForm.showVideoPath,
                            width: "400",
                          },
                        },
                        [_vm._v("您的浏览器不支持视频播放")]
                      )
                    : _vm.videoForm.showVideoPath == "" && !_vm.videoFlag
                    ? _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      })
                    : _vm._e(),
                  _vm.videoFlag == true
                    ? _c("el-progress", {
                        staticStyle: { "margin-top": "7px" },
                        attrs: {
                          percentage: _vm.videoUploadPercent,
                          type: "circle",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "artImg",
              attrs: { label: "文章封面", required: "" },
            },
            [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "div",
                  { staticClass: "ml10" },
                  [
                    _vm.fileList2.length
                      ? _c(
                          "div",
                          { staticStyle: { "margin-top": "10px" } },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "upload",
                                class: {
                                  hide: _vm.hideUpload2,
                                },
                                attrs: {
                                  action: _vm.action,
                                  "before-upload": _vm.bfUpload,
                                  "file-list": _vm.fileList2,
                                  headers: _vm.headers,
                                  limit: _vm.limitCount,
                                  "list-type": "picture-card",
                                  "on-change": _vm.handleChange2,
                                  "on-preview": _vm.handlePictureCardPreview2,
                                  "on-remove": _vm.handleRemove2,
                                  "on-success": _vm.handleSuccess2,
                                },
                              },
                              [_c("i", { staticClass: "el-icon-plus" })]
                            ),
                            _c(
                              "el-dialog",
                              {
                                attrs: {
                                  "append-to-body": "",
                                  visible: _vm.dialogVisible2,
                                },
                                on: {
                                  "update:visible": function ($event) {
                                    _vm.dialogVisible2 = $event
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    alt: "",
                                    src: _vm.dialogImageUrl2,
                                    width: "100%",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.openDialog2 },
                          },
                          [_vm._v("点击上传正方形封面")]
                        ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "el-form-item",
            {
              staticClass: "artTags",
              attrs: { label: "文章标签", prop: "artTags" },
            },
            [
              _vm._l(_vm.artTagsList, function (tag) {
                return _c(
                  "el-tag",
                  {
                    key: tag,
                    attrs: { closable: "", "disable-transitions": false },
                    on: {
                      close: function ($event) {
                        return _vm.handleClose(tag)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(tag) + " ")]
                )
              }),
              _vm.inputVisible
                ? _c("el-input", {
                    ref: "saveTagInput",
                    staticClass: "input-new-tag",
                    attrs: { size: "small" },
                    on: { blur: _vm.handleInputConfirm },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleInputConfirm.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.articleForm.artTags,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.articleForm,
                          "artTags",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "articleForm.artTags",
                    },
                  })
                : _vm._e(),
              !_vm.artTagsList.length
                ? _c("el-button", {
                    staticClass: "button-new-tag",
                    attrs: { icon: "el-icon-plus", size: "small" },
                    on: { click: _vm.showInput },
                  })
                : _vm._e(),
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文章类型", prop: "artType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", placeholder: "请选择文章类型" },
                  model: {
                    value: _vm.articleForm.artType,
                    callback: function ($$v) {
                      _vm.$set(_vm.articleForm, "artType", $$v)
                    },
                    expression: "articleForm.artType",
                  },
                },
                _vm._l(_vm.articleTypelist, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.typeName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "分类专栏", prop: "artColumn" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "30%" },
                  attrs: { multiple: "", placeholder: "请选择分类专栏" },
                  model: {
                    value: _vm.articleForm.artColumn,
                    callback: function ($$v) {
                      _vm.$set(_vm.articleForm, "artColumn", $$v)
                    },
                    expression: "articleForm.artColumn",
                  },
                },
                _vm._l(_vm.classifyList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.colName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发布形式", prop: "artScope" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.articleForm.artScope,
                    callback: function ($$v) {
                      _vm.$set(_vm.articleForm, "artScope", $$v)
                    },
                    expression: "articleForm.artScope",
                  },
                },
                _vm._l(_vm.artScopeList, function (item) {
                  return _c(
                    "el-radio",
                    { key: item.dictKey, attrs: { label: item.dictKey } },
                    [_vm._v(" " + _vm._s(item.dictValue) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "附件上传", prop: "name" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    action: _vm.action,
                    "file-list": _vm.fileListHontent,
                    headers: _vm.headers,
                    limit: 10,
                    multiple: "",
                    "on-remove": (file, index) => {
                      return _vm.handleRemoveHontent(file, index)
                    },
                    "on-success": _vm.handleSuccessHontent,
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { icon: "el-icon-upload2", size: "small" } },
                    [_vm._v("点击上传")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveDraft } },
                [_vm._v("保存草稿")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "cropperDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "文章封面裁剪",
            visible: _vm.dialogVisibleImgs,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleImgs = $event
            },
            close: _vm.close,
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.loading, type: "primary" },
                          on: { click: _vm.upload },
                        },
                        [_vm._v("上传并保存")]
                      ),
                      _c("el-upload", {
                        attrs: {
                          accept: "image/jpeg,image/gif,image/png,image/jpg",
                          action: _vm.action,
                          "auto-upload": false,
                          headers: _vm.headers,
                          "list-type": "picture-card",
                          "on-change": _vm.onChange,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c(
                                  "el-button",
                                  { attrs: { type: "primary" } },
                                  [_vm._v("选择图片")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-row",
            [
              _vm.option.img == ""
                ? _c(
                    "el-col",
                    {
                      staticStyle: {
                        padding: "30px 0",
                        "font-size": "16px",
                        color: "red",
                        "text-align": "center",
                      },
                      attrs: { span: 24 },
                    },
                    [_vm._v("请先选择图片")]
                  )
                : _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "cropper-content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "cropper",
                          staticStyle: { "text-align": "center" },
                        },
                        [
                          _c("vueCropper", {
                            ref: "cropper",
                            attrs: {
                              "auto-crop": _vm.option.autoCrop,
                              "can-move": _vm.option.canMove,
                              "can-move-box": _vm.option.canMoveBox,
                              "center-box": _vm.option.centerBox,
                              fixed: _vm.option.fixed,
                              "fixed-box": _vm.option.fixedBox,
                              "fixed-number": _vm.option.fixedNumber,
                              full: _vm.option.full,
                              img: _vm.option.img,
                              info: true,
                              "info-true": _vm.option.infoTrue,
                              original: _vm.option.original,
                              "output-size": _vm.option.size,
                              "output-type": _vm.option.outputType,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "cropperDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "文章封面裁剪",
            visible: _vm.dialogVisibleImgs2,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleImgs2 = $event
            },
            close: _vm.close2,
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.loading, type: "primary" },
                          on: { click: _vm.upload2 },
                        },
                        [_vm._v("上传并保存")]
                      ),
                      _c("el-upload", {
                        attrs: {
                          accept: "image/jpeg,image/gif,image/png,image/jpg",
                          action: _vm.action,
                          "auto-upload": false,
                          headers: _vm.headers,
                          "list-type": "picture-card",
                          "on-change": _vm.onChange2,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c(
                                  "el-button",
                                  { attrs: { type: "primary" } },
                                  [_vm._v("选择图片")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-row",
            [
              _vm.option2.img == ""
                ? _c(
                    "el-col",
                    {
                      staticStyle: {
                        padding: "30px 0",
                        "font-size": "16px",
                        color: "red",
                        "text-align": "center",
                      },
                      attrs: { span: 24 },
                    },
                    [_vm._v("请先选择图片")]
                  )
                : _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "cropper-content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "cropper",
                          staticStyle: { "text-align": "center" },
                        },
                        [
                          _c("vueCropper", {
                            ref: "cropper",
                            attrs: {
                              "auto-crop": _vm.option2.autoCrop,
                              "can-move": _vm.option2.canMove,
                              "can-move-box": _vm.option2.canMoveBox,
                              "center-box": _vm.option2.centerBox,
                              fixed: _vm.option2.fixed,
                              "fixed-box": _vm.option2.fixedBox,
                              "fixed-number": _vm.option2.fixedNumber,
                              full: _vm.option2.full,
                              img: _vm.option2.img,
                              info: true,
                              "info-true": _vm.option2.infoTrue,
                              original: _vm.option2.original,
                              "output-size": _vm.option2.size,
                              "output-type": _vm.option2.outputType,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "modal-append-to-body": false,
            title: "秀米",
            top: "50px",
            visible: _vm.visible,
            width: "90%",
            "z-index": "99999999",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.visible
            ? _c("div", [
                _c("iframe", {
                  attrs: {
                    id: "xiumiIframe",
                    frameborder: "0",
                    height: _vm.fullheight - 150 + "px",
                    src: "./static/xiumi-ue-dialog-v5_new.html",
                    width: "100%",
                  },
                }),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "modal-append-to-body": false,
            title: "135编辑器",
            top: "50px",
            visible: _vm.visible2,
            width: "90%",
            "z-index": "99999999",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible2 = $event
            },
          },
        },
        [
          _vm.visible2
            ? _c("div", [
                _c("iframe", {
                  attrs: {
                    id: "xiumiIframe",
                    frameborder: "0",
                    height: _vm.fullheight - 150 + "px",
                    src: "./static/135EditorDialogPage.html",
                    width: "100%",
                  },
                }),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }