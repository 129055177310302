<template>
  <div style="height: 79vh; padding: 10px">
    <div style="display: flex; flex-wrap: wrap; margin-bottom: 50px">
      <div class="title-item">
        <span>参与用户</span>
        <el-input
          v-model.trim="formInline.userName"
          class="title-input"
          placeholder="请输入用户名"
        />
      </div>
      <div class="title-item">
        <span>中奖状态</span>
        <el-select
          v-model.trim="formInline.lotterState"
          class="title-input"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in lotterState"
            :key="index"
            :label="item.dictValue"
            :value="item.dictKey"
          />
        </el-select>
      </div>
      <div class="title-item">
        <span>中奖时间</span>
        <el-date-picker
          v-model="datePicker"
          align="center"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="dateChange"
        />
      </div>
      <div class="title-item">
        <el-button icon="el-icon-search" type="warning" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          type="primary"
          @click="handleReset"
        >
          重置
        </el-button>
      </div>
    </div>
    <el-table border :data="list" :max-height="600" style="width: 100%">
      <el-table-column align="center" label="用户信息" prop="account" />
      <el-table-column align="center" label="中奖状态" prop="lotterState">
        <template #default="{ row }">
          {{ getDicValue('lotterState', row.lotterState) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="奖品等级" prop="itemLevel" />
      <el-table-column align="center" label="奖品名称" prop="goodsName" />
      <el-table-column
        align="center"
        label="抽奖时间"
        min-width="100px"
        prop="startDate"
      >
        <template #default="{ row }">
          <div>{{ row.startDate ? parseTime(row.startDate) : '' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="中奖时间"
        min-width="100px"
        prop="lotteryTime"
      >
        <template #default="{ row }">
          <div>
            {{ row.lotterState == 3 ? parseTime(row.lotteryTime) : '' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="奖品状态" prop="isRedem">
        <template #default="{ row }">
          {{ getDicValue('isRedem', row.isRedem) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="发放方式">
        <template #default="{ row }">
          <div v-if="row.goodsType != 1">
            {{ getDicValue('disburseMent', row.disburseMent) }}
          </div>
          <div v-else>{{ getDicValue('deliveryType', row.deliveryType) }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="领取状态" prop="linState">
        <template #default="{ row }">
          <div>{{ getDicValue('linState', row.linState) }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="220px">
        <template #default="{ row }">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div
              v-for="item in row.btns"
              v-show="item.isShow && item.buttonStatus == 0"
              :key="item.id"
            >
              <el-dropdown
                v-if="item.children && item.children.length"
                style="margin: 0 5px"
                @command="
                  (index) => {
                    handleClickBtn(item.buttonUrl, index, item.children, row)
                  }
                "
              >
                <el-button :type="item.buttonCss">
                  {{ item.buttonName }}
                  <vab-icon :icon="item.buttonIcon || ''" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item2 in item.children"
                    :key="item2.id"
                    :command="item2.id"
                  >
                    {{ item2.buttonName }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                v-else
                size="mini"
                style="margin: 0 5px"
                :type="item.buttonCss"
                @click="handleClickBtn(item.buttonUrl, row)"
              >
                <vab-icon :icon="item.buttonIcon || ''" />
                {{ item.buttonName }}
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.currentPage"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  // import { selectSysDict } from '@/api/mall/common'
  import { checkButton } from '@/utils/mixins'
  import { getLuckyList } from '@/api/activity/lucky'
  import { parseTime } from '@/utils'
  export default {
    name: 'LuckList',
    filters: {
      parseTime,
    },
    mixins: [checkButton],
    data() {
      return {
        activeName: '0',
        listLoading: true,
        instId: 0,
        list: [],
        datePicker: [],
        plateForm: [],
        linState: [
          {
            dictValue: '已领取',
            dictKey: 1,
          },
          {
            dictValue: '未兑换',
            dictKey: 2,
          },
          {
            dictValue: '待核销',
            dictKey: 3,
          },
          {
            dictValue: '已兑换',
            dictKey: 4,
          },
        ],
        lotterState: [
          {
            dictValue: '全部',
            dictKey: '',
          },
          {
            dictValue: '待开奖',
            dictKey: 1,
          },
          {
            dictValue: '未中奖',
            dictKey: 2,
          },
          {
            dictValue: '已开奖',
            dictKey: 3,
          },
        ],
        disburseMent: [
          {
            dictValue: '立即到账',
            dictKey: 1,
          },
          {
            dictValue: '24小时到账',
            dictKey: 2,
          },
        ],
        deliveryType: [
          {
            dictValue: '门店自提',
            dictKey: 12,
          },
          {
            dictValue: '同城速达',
            dictKey: 11,
          },
          {
            dictValue: '快递发送',
            dictKey: 10,
          },
        ],
        isRedem: [
          {
            dictValue: '已领取',
            dictKey: 1,
          },
          {
            dictValue: '',
            dictKey: 0,
          },
        ],
        isSearch: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        formInline: {
          lotterState: '',
          userName: '',
          startTime: '',
          endTime: '',
        },
        total: 0,
        queryForm: {
          currentPage: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.instId = this.$route.query.instId
      this.fetchData(this.formInline)
    },
    activated() {
      this.fetchData(this.formInline)
    },
    methods: {
      parseTime,
      getDicValue(key, val) {
        let str = ''
        let obj = this[key].find((item) => item.dictKey == val)
        if (obj) {
          str = obj.dictValue
        }
        return str
      },
      handleOpenDetail(row) {
        this.$router.push({
          path: '/activity/lucky/detail',
          query: {
            id: row.id,
          },
        })
      },
      handleCopy(row) {
        this.$router.push({
          path: '/activity/add',
          query: {
            ...row,
            id: null,
            status: 2,
          },
        })
      },
      handleEdit(row) {
        this.$router.push({
          path: '/activity/add',
          query: {
            ...row,
            status: 1,
          },
        })
      },
      openLuckydraw(row) {
        this.$router.push({
          path: '/activity/indiana/list',
          query: {
            id: row.id,
          },
        })
      },
      // 新增活动
      openAddActive() {
        this.$router.push({
          path: '/activity/add',
          query: {
            status: 0,
          },
        })
      },
      handleReset() {
        this.formInline = {
          actName: '',
          actType: '',
          startTime: '',
          endTime: '',
          actPlatform: '',
          actStatus: '',
        }
        this.datePicker = []
        this.handleSearch()
      },
      async fetchData(options = {}) {
        this.listLoading = true
        const {
          data: { records: list, total },
        } = await getLuckyList({
          ...this.queryForm,
          ...options,
          instId: this.instId,
        })
        this.handleAddTableButtonRole(list, {
          handleOpenDetail: (row) => row.lotterState == 3,
        })
        // const dicData = await selectSysDict({
        //   dictTypeList: ['actPlatform'],
        // })
        // this.plateForm = dicData.data[0].dictInfoVoList
        this.list = list
        this.total = total
      },
      async handleSearch() {
        this.queryForm.currentPage = 1
        await this.fetchData({ ...this.formInline }, false)
        let temp = false
        Object.keys(this.formInline).forEach((key) => {
          this.formInline[key] && (temp = true)
        })
        this.isSearch = temp
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData({ ...data }, false)
      },
      handleCurrentChange(val) {
        this.queryForm.currentPage = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData({ ...data }, false)
      },
      dateChange(date) {
        if (date && date.length) {
          this.formInline.startTime = date[0]
          this.formInline.endTime = date[1]
        } else {
          this.formInline.startTime = ''
          this.formInline.endTime = ''
        }
      },
    },
  }
</script>

<style scoped>
  .title-input {
    width: 220px;
    margin-left: 5px;
  }
  .title-item {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title-item span {
    width: 100px;
    text-align: center;
  }
</style>
