var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        [
          _c(
            "el-row",
            { staticStyle: { "padding-left": "35px", display: "flex" } },
            [
              _c("el-col", [_vm._v("物流公司：")]),
              _c("el-col", [_vm._v("快递单号：")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt30" },
        [
          _c(
            "el-timeline",
            { attrs: { reverse: _vm.reverse } },
            _vm._l(_vm.activities, function (activity, index) {
              return _c(
                "el-timeline-item",
                { key: index, attrs: { timestamp: activity.timestamp } },
                [_vm._v(" " + _vm._s(activity.content) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }