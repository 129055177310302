<template>
  <div class="cy-container">
    <el-button icon="el-icon-plus" type="primary" @click="; (addnew = true), (fileList = [])">新增</el-button>
    <el-tabs v-model="form.labelType" @tab-click="handleClick">
      <el-tab-pane v-for="(ym, index) in tag" :key="index" :label="ym.labeltype" :name="ym.key" />
    </el-tabs>
    <el-table border :data="tableData" style="width: 100%">
      <el-table-column align="center" label="编号" type="index" width="50" />
      <el-table-column align="center" label="标签名称" prop="labelName" />
      <el-table-column v-if="form.labelType == 7" align="center" label="户名" prop="bankName" />
      <el-table-column v-if="form.labelType == 7" align="center" label="帐号" prop="bankNumber" />
      <!-- <el-table-column v-if="form.labelType == 7" align="center" label="汇款标签	" prop="remBank" /> -->
      <el-table-column align="center" label="显示状态" prop="isEnable">
        <template #default="scope">
          <el-tag v-if="scope.row.isEnable == 1" type="danger">隐藏</el-tag>
          <el-tag v-else type="success">显示</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="createTime" />
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <el-link style="margin: 0 5px" type="primary" :underline="false" @click="editro(scope.row)">编辑</el-link>
          <el-link style="margin: 0 5px" type="primary" :underline="false" @click="del(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="1" layout="total, sizes, prev, pager, next, jumper" :total="total"
      @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <el-dialog :before-close="handleClose" title="编辑" :visible.sync="dialogVisible" width="40%">
      <span>
        <el-form ref="formEdit" label-width="100px" :model="editrow" :rules="rules">
          <el-form-item label="标签类型：" prop="labelType">
            <el-select v-model="typelist" disabled placeholder="请选择 标签类型：" style="width: 100%">
              <el-option v-for="item in tag" :key="item.key" :label="item.labeltype" :value="item.key" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="editrow.labelType == 7" label="交易类型：">
            <el-select v-model="typelists" disabled placeholder="" style="width: 100%">
              <el-option v-for="(ym, index) in tradeType" :key="index" :label="ym.tradeName" :value="ym.key" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="editrow.labelType != 7" label="标签名称：" prop="labelName">
            <el-input v-model="editrow.labelName" placeholder="请输入 标签名称" />
          </el-form-item>
          <el-form-item v-if="editrow.labelType != 7" label="排序：" prop="sort">
            <el-input-number v-model="editrow.sort" :max="10" :min="1" />
          </el-form-item>
          <el-form-item v-if="editrow.labelType == 7 && editrow.bussType != 1" label="汇入银行：" prop="labelName">
            <el-input v-model="editrow.labelName" placeholder="请输入 汇入银行" />
          </el-form-item>
          <el-form-item v-if="editrow.labelType == 7" :label="`${claNames}户名：`" prop="bankName">
            <el-input v-model="editrow.bankName" :placeholder="`请输入${claNames}户名`" />
          </el-form-item>
          <el-form-item v-if="editrow.labelType == 7" :label="`${claNames}帐号：`" prop="bankNumber">
            <el-input v-model="editrow.bankNumber" :placeholder="`请输入${claNames}帐号`" />
          </el-form-item>
          <!-- <el-form-item v-if="form.labelType == 7" label="汇款标签：" prop="remBank">
            <el-input v-model="editrow.remBank" placeholder="请输入 汇款标签" style="width: 200px" />
          </el-form-item> -->
          <el-form-item label="显示状态：" prop="actState">
            <el-radio-group v-model="editrow.isEnable">
              <el-radio :label="1">隐藏</el-radio>
              <el-radio :label="0">显示</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="edisave">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :before-close="handleCloseadd" title="新增" :visible.sync="addnew" width="40%">
      <span>
        <el-form ref="formAddlist" label-width="120px" :model="newrows" :rules="rsult">
          <el-form-item label="标签类型：" prop="labelType">
            <el-select v-model="newrows.labelType" placeholder="请选择 标签类型：" style="width: 100%">
              <el-option v-for="item in tag" :key="item.key" :label="item.labeltype" :value="item.key" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="newrows.labelType == 7" label="交易类型：">
            <el-select v-model="newrows.bussType" placeholder="" style="width: 100%">
              <el-option v-for="(ym, index) in tradeType" :key="index" :label="ym.tradeName" :value="ym.key" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="newrows.labelType != 7" label="标签名称：" prop="labelName">
            <el-input v-model="newrows.labelName" placeholder="请输入 标签名称" />
          </el-form-item>
          <el-form-item v-if="editrow.labelType != 7" label="排序：" prop="sort">
            <el-input-number v-model="editrow.sort" :max="10" :min="1" />
          </el-form-item>
          <el-form-item v-if="newrows.labelType == 7 && newrows.bussType == 3" label="汇入银行：" prop="labelName">
            <el-input v-model="newrows.labelName" placeholder="请输入 汇入银行" />
          </el-form-item>
          <el-form-item v-if="newrows.labelType == 7" :label="`${claName}户名：`" prop="bankName">
            <el-input v-model="newrows.bankName" :placeholder="`请输入${claName}户名`" />
          </el-form-item>
          <el-form-item v-if="newrows.labelType == 7" :label="`${claName}帐号：`" prop="bankNumber">
            <el-input v-model="newrows.bankNumber" :placeholder="`请输入${claName}帐号`" />
          </el-form-item>
          <!-- <el-form-item v-if="newrows.labelType == 7" label="汇款标签：" prop="remBank">
            <el-input v-model="newrows.remBank" placeholder="请输入 汇款标签"  />
          </el-form-item> -->
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clears">取 消</el-button>
        <el-button type="primary" @click="addsave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryPage, queryPageadd, queryPageedit, stpLabelDelete } from '@/api/mall/setting/stplabel'
import { MessageBox } from 'element-ui';
export default {
  data() {
    return {
      //交易类型 1:微信零钱 2：支付宝 3：银行卡
      tradeType: [
        {
          tradeName: '微信',
          key: '1',
        },
        {
          tradeName: '支付宝',
          key: '2',
        },
        {
          tradeName: '银行卡',
          key: '3',
        },
      ],

      tag: [
        {
          labeltype: '交流类型',
          key: '1',
        },
        {
          labeltype: '渠道来源',
          key: '2',
        },
        {
          labeltype: '订单分类',
          key: '3',
        },
        {
          labeltype: '客户类型',
          key: '5',
        },
        {
          labeltype: '咨询产品',
          key: '6',
        },
        {
          labeltype: '交易渠道',
          key: '7',
        },
        {
          labeltype: '复购次数',
          key: '8',
        },
        {
          labeltype: '客情类型',
          key: '9',
        },
        {
          labeltype: '退款类型',
          key: '10',
        },
      ],
      tableData: [],
      total: 0,
      dialogVisible: false,
      addnew: false,
      form: {
        current: 1,
        size: 10,
        labelType: '1',
      },
      editrow: {},
      rules: {
        labelName: [{ required: true, message: '请输入 标签', trigger: 'blur' }],
        bankName: [{ required: true, message: '请输入 户名', trigger: 'blur' }],
        bankNumber: [{ required: true, message: '请输入 帐号', trigger: 'blur' }],
        remBank: [{ required: true, message: '请输入 汇款标签	', trigger: 'blur' }],
        labelType: [{ required: true, message: '请选择 类型', trigger: 'blur' }],
      },
      rsult: {
        labelName: [{ required: true, message: '请输入 标签', trigger: 'blur' }],
        bankName: [{ required: true, message: '请输入 户名', trigger: 'blur' }],
        bankNumber: [{ required: true, message: '请输入 帐号', trigger: 'blur' }],
        remBank: [{ required: true, message: '请输入 汇款标签	', trigger: 'blur' }],
        labelType: [{ required: true, message: '请选择 类型', trigger: 'blur' }],
      },
      newrows: {
        isEnable: 0,
        tenantId: 1,
        isDelete: 0,
      },
    }
  },
  computed: {
    claName() {
      let claName = this.newrows.bussType
      if (claName == '1') {
        return `微信`
      } else if (claName == '2') {
        return `支付宝`
      } else {
        return `银行`
      }
    },
    claNames() {
      let claName = this.editrow.bussType
      if (claName == '1') {
        return `微信`
      } else if (claName == '2') {
        return `支付宝`
      } else {
        return `银行`
      }
    },
    typelist() {
      let li = null
      let list = this.editrow.labelType
      this.tag.forEach((item) => {
        if (list == item.key) {
          li = item
        }
      })
      return li
    },
    typelists() {
      let li = null
      let list = this.editrow.bussType
      this.tradeType.forEach((item) => {
        if (list == item.key) {
          console.log(item)
          li = item
        }
      })
      return li
    },
  },
  created() {
    this.stpPlatformProtocol()
  },
  methods: {
    stpPlatformProtocol() {
      queryPage({ ...this.form }).then((res) => {
        this.tableData = res.data.records
        this.form.size = res.data.size
        this.form.current = res.data.current
        this.total = res.data.total
        console.log(res.data)
      })
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.stpPlatformProtocol()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.stpPlatformProtocol()
    },
    handleClick() {
      this.stpPlatformProtocol()
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleCloseadd() {
      this.addnew = false
    },
    editro(row) {
      console.log(row)
      this.dialogVisible = true
      this.editrow = row
      this.fileList = []
      this.fileList.push({ url: row.imageUrl })
    },
    edisave() {
      this.$refs.formEdit.validate((valid) => {
        if (valid) {
          this.putpage()
        } else {
          return false
        }
      })
    },
    putpage() {
      queryPageedit({ ...this.editrow }).then(() => {
        this.$message.success('编辑成功')
        this.fileList = []
        this.dialogVisible = false
        this.stpPlatformProtocol()
      })
    },
    clears() {
      this.addnew = false
      this.newrows = {}
      this.fileList = []
    },
    addsave() {
      console.log(this.newrows)
      this.$refs.formAddlist.validate((valid) => {
        if (valid) {
          this.addpages()
        } else {
          return false
        }
      })
    },
    addpages() {
      queryPageadd({ ...this.newrows }).then(() => {
        this.$message.success('新增成功')
        this.addnew = false
        this.fileList = []
        this.newrows = {}
        this.stpPlatformProtocol()
      })
    },
    del(userId) {

      MessageBox.confirm('确定要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        stpLabelDelete({ id: userId }).then(() => {
          this.$message.success('删除成功')
          this.stpPlatformProtocol()
        })
      }).catch(() => {
        this.$message.success('您已取消删除')
      });


    },
    descr(row) {
      this.fileList = []
      this.newrows.content = row
    },
    eidttab(row) {
      this.fileList = []
      this.editrow.content = row
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  border-bottom: 1px solid #e8e8e8;
}
</style>
