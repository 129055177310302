<template>
  <div v-loading.sync="listLoading" style="padding: 10px">
    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <div>
        <div style="display: flex">
          <h3 style="margin-right: 10px">当前优惠券（可使用）</h3>
          <h3 style="color: rgb(37, 115, 217)">{{ detail.couponsCount }}张</h3>
        </div>
        <div style="display: flex; margin: 10px 0">
          <div style="margin-right: 20px">
            <span>累计优惠券：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.sumCouponsCount }}张
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>已使用：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.usedCouponsCount }}张
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>已过期：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.overdueCouponsCount }}张
            </span>
          </div>
        </div>
      </div>
      <div>
        <div
          v-if="
            btnRolrs[5] &&
            btnRolrs[5]['searchBarButtons'] &&
            btnRolrs[5]['searchBarButtons'].length
          "
        >
          <div v-if="btnRolrs[5] && btnRolrs[5].searchBarButtons">
            <el-button
              v-for="item in btnRolrs[5].searchBarButtons"
              :key="item.id"
              :icon="item.buttonIcon"
              size="small"
              :type="item.buttonCss"
              @click="handleClickBtn(item.buttonUrl)"
            >
              <vab-icon :icon="item.buttonIcon || ''" />
              {{ item.buttonName }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-table border :data="list" max-height="450" style="width: 100%">
        <el-table-column align="center" label="优惠券名称" prop="couponName" />
        <el-table-column align="center" label="优惠券类型" prop="couponType">
          <template #default="{ row }">
            {{ row.couponType | initCouponType }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="面额/折扣" prop="faceValue" />
        <el-table-column
          align="center"
          label="获取方式"
          prop="couponAcquireApproach"
        >
          <template #default="{ row }">
            <div>{{ row.couponAcquireApproach | initAcquire }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="couponState">
          <template #default="{ row }">
            <div>{{ row.couponState | initState }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="获取时间" prop="receivedTime" />
        <el-table-column align="center" label="到期时间" prop="usableEndTime" />
        <el-table-column align="center" label="使用时间" prop="useTime" />
      </el-table>
      <el-pagination
        background
        :current-page="queryForm.current"
        :layout="layout"
        :page-size="queryForm.size"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
  import { getCoupon, getCouponDetail } from '@/api/mall/users/userDetail'
  import { checkButton } from '@/utils/mixins'
  export default {
    filters: {
      initCouponType(val) {
        let str = ''
        switch (val) {
          case 10:
            str = '平台券'
            break
          case 20:
            str = '店铺券'
            break
        }
        return str
      },
      initAcquire(val) {
        let str = ''
        switch (val) {
          case 10:
            str = '领取'
            break
          case 20:
            str = '发放'
            break
          case 30:
            str = '赠送'
            break
        }
        return str
      },
      initState(val) {
        let str = ''
        switch (val) {
          case 10:
            str = '未使用'
            break
          case 20:
            str = '已使用'
            break
          case 30:
            str = '已过期'
            break
        }
        return str
      },
    },
    mixins: [checkButton],
    data() {
      return {
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        userId: 0,
        balanceAmount: 0,
        queryForm: {
          current: 1,
          size: 10,
        },
        total: 0,
        accId: '',
        list: [],
        detail: {
          userId: 0,
          couponsCount: 0,
          sumCouponsCount: 0,
          usedCouponsCount: 0,
          overdueCouponsCount: 0,
        },
      }
    },
    created() {
      const { balanceAmount, accId } = this.$route.query
      this.balanceAmount = balanceAmount
      this.accId = accId
    },
    methods: {
      async fetchData(id) {
        this.listLoading = true
        this.userId = id
        const {
          data: { records, total },
        } = await getCoupon({ userId: this.userId, ...this.queryForm })
        const { data: detail } = await getCouponDetail({ userId: this.userId })
        this.detail = detail
        this.total = total
        this.list = records
        this.listLoading = false
      },
      handleSizeChange(val) {
        this.queryForm.size = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.current = val
        this.fetchData()
      },
      handleExport() {
        // exportIntegral({
        //   accId: this.accId,
        //   userId: this.userId,
        // })
        //   .then((res) => {
        //     const url = window.URL.createObjectURL(new Blob([res]))
        //     const link = document.createElement('a')
        //     link.setAttribute('href', url)
        //     link.setAttribute('download', new Date().getTime() + '.xlsx')
        //     link.click()
        //   })
        //   .catch(() => {
        //     this.$message.error('网络错误，请稍后重试！')
        //   })
      },
    },
  }
</script>
