var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-radio-group",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { size: "mini" },
          on: { change: _vm.checktags },
          model: {
            value: _vm.form.classType,
            callback: function ($$v) {
              _vm.$set(_vm.form, "classType", $$v)
            },
            expression: "form.classType",
          },
        },
        [
          _c("el-radio-button", { attrs: { label: "2" } }, [_vm._v("血压")]),
          _c("el-radio-button", { attrs: { label: "3" } }, [_vm._v("血糖")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "each" },
        [_c("target", { attrs: { requ: _vm.form } })],
        1
      ),
      _c(
        "div",
        { staticClass: "e-tab" },
        [
          _vm.form.classType == 2
            ? _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { border: "", data: _vm.tableData, height: "300" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "序号",
                      "show-overflow-tooltip": "",
                      type: "index",
                      width: "50",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "高压",
                      prop: "sysValue",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "低压",
                      prop: "divValue",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "心率",
                      prop: "pulValue",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "设备序号",
                      prop: "serialNumber",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "采集时间",
                      prop: "createTime",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.classType == 3
            ? _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { border: "", data: _vm.tableData, height: "300" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "序号",
                      "show-overflow-tooltip": "",
                      type: "index",
                      width: "50",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "血糖",
                      prop: "sugarValue",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "设备序号",
                      prop: "serialNumber",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "采集时间",
                      prop: "createTime",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": 1,
              layout: "total, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }