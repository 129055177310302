<template>
  <div>
    <el-dialog :title="getTitle" :visible.sync="dialogFormVisible" @close="close">
      <el-form :model="form" :rules="rules">
        <el-form-item label="上级分类：" :label-width="formLabelWidth">
          <div style="height: 20px">
            <el-alert :closable="false" :title="form.parentName" type="info" />
          </div>
        </el-form-item>
        <el-form-item label="分类名称：" :label-width="formLabelWidth" prop="catName">
          <el-input v-model="form.catName" placeholder="请输入分类名称" />
        </el-form-item>
        <el-form-item v-if="getTitle == '添加一级分类'" label="分类编码" :label-width="formLabelWidth" prop="catCode">
          <el-input v-model="form.catCode" placeholder="请输入分类编码" />
        </el-form-item>
        <el-form-item label="分类Logo图片：" :label-width="formLabelWidth">
          <el-upload
            :action="uploadParams.uploadURL"
            :file-list="uploadParams.fileList"
            :headers="{ Authorization: uploadParams.token }"
            :limit="1"
            list-type="picture-card"
            :on-exceed="handleExceed"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleUploadCatLogoSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog append-to-body :visible.sync="dialogVisible">
            <img :src="uploadParams.dialogImageUrl" width="100%" />
          </el-dialog>
        </el-form-item>
        <el-form-item label="分类排序：" :label-width="formLabelWidth" prop="sortNumber">
          <el-input v-model="form.sortNumber" placeholder="请输入分类排序" />
        </el-form-item>
        <el-form-item label="是否启用：" :label-width="formLabelWidth">
          <el-switch v-model="form.isShow" active-color="#13ce66" />
        </el-form-item>
        <el-form-item label="是否支持客情：" :label-width="formLabelWidth">
          <el-switch v-model="form.isAfterSales" active-color="#13ce66" />
        </el-form-item>
        <el-form-item label="分类备注：" :label-width="formLabelWidth">
          <el-input v-model="form.remarks" placeholder="请输入分类备注" type="textarea" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { categoryAdd, categoryUpdate } from '@/api/mall/shop/shopCategory'

import { getToken } from '@/utils/token'
import { uploadURL } from '@/config'
export default {
  props: {
    status: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      if (!Number.isInteger(Number(value))) {
        callback(new Error('请输入数字值!'))
      }
    }
    return {
      dialogFormVisible: false,
      dialogVisible: false,
      form: {},
      parentData: {},
      uploadParams: {
        token: `Bearer ${getToken()}`,
        uploadURL,
        fileList: [],
        dialogImageUrl: '',
      },
      rules: {
        catName: [{ required: true, message: '分类名称必填！', trigger: 'blur' }],
        catCode: [{ required: true, message: '分类编码必填！', trigger: 'blur' }],
        sortNumber: [
          { required: true, message: '分类排序必填！', trigger: 'blur' },
          { validator: checkNumber, trigger: 'blur' },
        ],
      },
      formLabelWidth: '120px',
    }
  },
  computed: {
    getTitle() {
      let str = ''
      switch (this.status) {
        case 0:
          str = '编辑分类'
          break
        case 1:
          str = '添加一级分类'
          break
        case 2:
          str = '添加子分类'
          break
      }
      return str
    },
  },
  methods: {
    show(data, row) {
      this.form = data
      this.parentData = row
      if (this.form.catLogo) {
        this.uploadParams.fileList.push({
          name: 'logo',
          url: this.form.catLogo,
        })
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$emit('fetch')
      this.resetForm()
      this.dialogFormVisible = false
    },
    handleConfirm() {
      const res = {
        ...this.form,
        catCode: this.form.catCode,
        parentId: this.form.parentId,
        isShow: this.form.isShow === true ? 1 : 0,
        isAfterSales: this.form.isAfterSales === true ? 1 : 0,
      }
      console.log(this.status)
      let argumentsError = false
      let temp = null
      let str = ''
      delete res.parentName
      Object.keys(res).forEach((key) => {
        console.log(key)
        if ((key === 'catLogo' && this.parentData?.deep == 1) || key === 'catName' || key === 'remarks') {
          !res[key] && (argumentsError = true)
        }
        if (key === 'isAfterSales' || key === 'isShow' || key === 'sortNumber' || key === 'parentId') {
          !Number.isInteger(Number(res[key])) && (argumentsError = true)
        }
      })
      if (argumentsError) {
        return this.$message.error('参数错误或缺少必要参数！请重新配置')
      }
      if (this.status === 0) {
        temp = categoryUpdate
        str = '编辑'
      } else {
        temp = categoryAdd
        str = '新增'
      }
      temp(res)
        .then(() => {
          this.$message.success(`${str}分类成功！`)
          this.resetForm()
          this.close()
        })
        .catch(() => {
          this.$message.error('网络错误！配置失败')
        })
    },
    handleRemove() {
      this.form.catLogo = ''
    },
    handlePictureCardPreview(file) {
      this.uploadParams.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleUploadCatLogoSuccess(response) {
      const {
        data: { filePath: path },
      } = response
      this.form.catLogo = path
    },
    // handleUploadCatImageSuccess(response) {
    //   const {
    //     data: { filePath: path },
    //   } = response
    //   this.form.catImage = path
    // },
    handleExceed() {
      this.$message.error('只允许有一张Logo！')
    },
    resetForm() {
      this.uploadParams.fileList = []
      this.form = {
        catName: '',
        catLogo: '',
        parentId: -1,
        sortNumber: 0,
        isShow: 0,
        remarks: '',
        isAfterSales: '',
        catCode: '',
      }
    },
  },
}
</script>

<style scoped>
.el-alert {
  padding: 0 !important;
}
</style>
