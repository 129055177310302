<template>
  <div class="demo">
    <div
      class="container"
      :style="{
        background: `${controls[index].pageBgColor}`,
        padding: `${controls[index].margin.top}px ${controls[index].margin.both}px ${controls[index].margin.bottom}px ${controls[index].margin.both}px`,
      }"
    >
      <div
        class="ym-dbline"
        :style="{
          background: `${controls[index].componentBgColor}`,
          borderTopLeftRadius: `${controls[index].topAroundRadius}px `,
          borderTopRightRadius: `${controls[index].topAroundRadius}px `,
          borderBottomRightRadius: `${controls[index].bottomAroundRadius}px `,
          borderBottomLeftRadius: `${controls[index].bottomAroundRadius}px `,
        }"
      >
        <div
          v-if="controls[index].mode == 'row1-of2'"
          :class="controls[index].mode"
          :style="{
            gap: controls[index].imageGap + 'px',
          }"
        >
          <div
            v-for="(ym, ind) in controls[index].list"
            :key="ind"
            class="mode_pic"
            :style="{
              borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
            }"
          >
            <el-image
              fit="cover "
              :src="ym.imageUrl == '' ? initPic : ym.imageUrl"
              :style="{
                height: ym.imgHeight + 'px',
              }"
            />
          </div>
        </div>
        <div
          v-else-if="controls[index].mode == 'row1-of3'"
          :class="controls[index].mode"
          :style="{
            gap: controls[index].imageGap + 'px',
          }"
        >
          <div
            v-for="(ym, ind) in controls[index].list"
            :key="ind"
            class="mode_pic"
            :style="{
              borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
            }"
          >
            <el-image
              fit="cover "
              :src="ym.imageUrl == '' ? initPic : ym.imageUrl"
              :style="{
                height: ym.imgHeight + 'px',
              }"
            />
          </div>
        </div>
        <div
          v-else-if="controls[index].mode == 'row1-of4'"
          :class="controls[index].mode"
          :style="{
            gap: controls[index].imageGap + 'px',
          }"
        >
          <div
            v-for="(ym, ind) in controls[index].list"
            :key="ind"
            class="mode_pic"
            :style="{
              borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
            }"
          >
            <el-image
              fit="cover "
              :src="ym.imageUrl == '' ? initPic : ym.imageUrl"
              :style="{
                width: '100%',
                height: ym.imgHeight + 'px',
              }"
            />
          </div>
        </div>
        <div
          v-else-if="controls[index].mode == 'row2-lt-of2-rt'"
          :class="controls[index].mode"
          :style="{
            gap: controls[index].imageGap + 'px',
          }"
        >
          <div
            v-for="(ym, ind) in controls[index].list"
            :key="ind"
            class="mode_pic"
            :style="{
              borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
            }"
          >
            <el-image
              fit="cover "
              :src="ym.imageUrl == '' ? initPic : ym.imageUrl"
              :style="{
                width: '100%',
                height: ym.imgHeight / 2 + 'px',
              }"
            />
          </div>
        </div>
        <div
          v-else-if="controls[index].mode == 'row1-lt-of2-rt'"
          :class="controls[index].mode"
          :style="{
            gap: controls[index].imageGap + 'px',
          }"
        >
          <div class="mode_pic" :style="{}">
            <el-image
              fit="cover "
              :src="controls[index].list[0].imageUrl == '' ? initPic : controls[index].list[0].imageUrl"
              :style="{
                width: '100%',
                height: controls[index].list[0].imgHeight / 2 + 'px',
              }"
            />
          </div>
          <div class="mode_pics">
            <el-image
              fit="cover "
              :src="controls[index].list[1].imageUrl == '' ? initPic : controls[index].list[1].imageUrl"
              :style="{
                width: '100%',
                height: controls[index].list[1].imgHeight / 2 + 'px',
                borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
              }"
            />
            <el-image
              fit="cover "
              :src="controls[index].list[2].imageUrl == '' ? initPic : controls[index].list[2].imageUrl"
              :style="{
                width: '100%',
                height: controls[index].list[2].imgHeight / 2 + 'px',
                borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
              }"
            />
          </div>
        </div>
        <div
          v-else-if="controls[index].mode == 'row1-tp-of2-bm'"
          :class="controls[index].mode"
          :style="{
            gap: controls[index].imageGap + 'px',
          }"
        >
          <div class="mode_pic">
            <el-image
              fit="cover "
              :src="controls[index].list[0].imageUrl == '' ? initPic : controls[index].list[0].imageUrl"
              :style="{
                width: '100%',
                height: controls[index].list[0].imgHeight / 2 + 'px',
                borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
              }"
            />
          </div>
          <div class="mode_pics">
            <el-image
              fit="cover "
              :src="controls[index].list[1].imageUrl == '' ? initPic : controls[index].list[1].imageUrl"
              :style="{
                width: '100%',
                height: controls[index].list[1].imgHeight / 2 + 'px',
                borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
              }"
            />
            <el-image
              fit="cover "
              :src="controls[index].list[2].imageUrl == '' ? initPic : controls[index].list[2].imageUrl"
              :style="{
                width: '100%',
                height: controls[index].list[2].imgHeight / 2 + 'px',
                borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
              }"
            />
          </div>
        </div>
        <div
          v-else-if="controls[index].mode == 'row1-lt-of1-tp-of2-bm'"
          :class="controls[index].mode"
          :style="{
            gap: controls[index].imageGap + 'px',
          }"
        >
          <div class="mode_pic">
            <el-image
              fit="cover "
              :src="controls[index].list[0].imageUrl"
              :style="{
                width: '100%',
                height: controls[index].list[0].imgHeight / 2 + 'px',
                borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
              }"
            />
          </div>
          <div class="mode_pics">
            <el-image
              fit="cover "
              :src="controls[index].list[1].imageUrl"
              :style="{
                width: '100%',
                height: controls[index].list[1].imgHeight / 2 + 'px',
                borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
              }"
            />
            <div class="mode_picl">
              <el-image
                fit="cover "
                :src="controls[index].list[2].imageUrl"
                :style="{
                  width: '100%',
                  height: controls[index].list[2].imgHeight / 2 + 'px',
                  borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
                }"
              />
              <el-image
                fit="cover "
                :src="controls[index].list[3].imageUrl"
                :style="{
                  width: '100%',
                  height: controls[index].list[3].imgHeight / 2 + 'px',
                  borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    index: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      initPic: require('@/assets/img/blp/figure.png'),
    }
  },
  computed: {
    ...mapState({
      controls: (state) => state.checkvalue,
    }),
  },
}
</script>

<style lang="scss" scoped>
.container {
  .ym-dbline {
    .mode_pic {
      overflow: hidden;
    }
  }
  .row1-of2,
  .row2-lt-of2-rt,
  .row1-lt-of2-rt {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .row1-of3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .row1-of4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .row1-tp-of2-bm {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    .mode_pics {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .row1-lt-of1-tp-of2-bm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .mode_picl {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>