<template>
  <div>
    <el-dialog
      :before-close="handleClose"
      title="查看"
      :visible.sync="showdemo"
      width="60%">
      <span>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark">体检时间</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              <el-date-picker v-model="tablist.userYear" placeholder=" 年 月 日" type="date" />
            </div>
          </el-col>
          <el-col :span="6"><div class="grid-content bg-purple-dark">手机号</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark"><el-input v-model="tablist.phone" placeholder="手机号" /></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3"><div class="grid-content bg-purple-dark">姓名</div></el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="tablist.userName" placeholder="姓名" /></div>
          </el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">性别</div></el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="tablist.userSex" placeholder="性别" /></div>
          </el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">年龄</div></el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="tablist.userAge" placeholder="年龄" /></div>
          </el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">患病年限</div></el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="tablist.fattyIlness" placeholder="患病年限" /></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3"><div class="grid-content bg-purple-dark">身高 cm</div></el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">体重 kg</div></el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">BMI</div></el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">腰围</div></el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">血压</div></el-col>
          <el-col :span="3"><div class="grid-content bg-purple-dark">心率</div></el-col>
          <el-col :span="6"><div class="grid-content bg-purple-dark">是否规律用药</div></el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="tablist.userHeight" placeholder="身高" /></div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="tablist.userWeight" placeholder="体重" /></div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="tablist.userMbi" placeholder="BMI" /></div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="tablist.userWaist" placeholder="腰围" /></div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="tablist.userPressure" placeholder="血压" /></div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-dark"><el-input v-model="tablist.userHeart" placeholder="心率" /></div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              <el-radio-group v-model="tablist.medRegularly">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark" style="height: 72px; line-height: 72px">是否打针</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              胰岛素
              <el-checkbox label="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              药名：
              <el-input v-model="tablist.casePos1[0].insulinTitle" placeholder="药名" />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              剂量：
              <el-input v-model="tablist.casePos1[0].caseDose" placeholder="剂量" />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              GLP-1 类
              <el-checkbox label="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              药名：
              <el-input v-model="tablist.casePos1[1].insulinTitle" placeholder="药名" />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              剂量：
              <el-input v-model="tablist.casePos1[1].caseDose" placeholder="剂量" />
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark" style="height: 146px; line-height: 146px">是否口服药</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              药名：
              <el-input v-model="tablist.casePos2[0].insulinTitle" placeholder="药名" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-dark">
              一天
              <div class="textundeline"><el-input v-model="tablist.casePos2[0].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次,一次
              <div class="textundeline"><el-input v-model="tablist.casePos2[0].casePian" placeholder="" style="max-width: 55px" /></div>
              片，一片
              <div class="textundeline"><el-input v-model="tablist.casePos2[0].caseMg" placeholder="" style="max-width: 55px" /></div>
              mg
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              药名：
              <el-input v-model="tablist.casePos2[1].insulinTitle" placeholder="药名" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-dark">
              一天
              <div class="textundeline"><el-input v-model="tablist.casePos2[1].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次,一次
              <div class="textundeline"><el-input v-model="tablist.casePos2[1].casePian" placeholder="" style="max-width: 55px" /></div>
              片，一片
              <div class="textundeline"><el-input v-model="tablist.casePos2[1].caseMg" placeholder="" style="max-width: 55px" /></div>
              mg
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              药名：
              <el-input v-model="tablist.casePos2[2].insulinTitle" placeholder="药名" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-dark">
              一天
              <div class="textundeline"><el-input v-model="tablist.casePos2[2].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次,一次
              <div class="textundeline"><el-input v-model="tablist.casePos2[2].casePian" placeholder="" style="max-width: 55px" /></div>
              片，一片
              <div class="textundeline"><el-input v-model="tablist.casePos2[2].caseMg" placeholder="" style="max-width: 55px" /></div>
              mg
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              药名：
              <el-input v-model="tablist.casePos2[3].insulinTitle" placeholder="药名" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-dark">
              一天
              <div class="textundeline"><el-input v-model="tablist.casePos2[3].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次,一次
              <div class="textundeline"><el-input v-model="tablist.casePos2[3].casePian" placeholder="" style="max-width: 55px" /></div>
              片，一片
              <div class="textundeline"><el-input v-model="tablist.casePos2[3].caseMg" placeholder="" style="max-width: 55px" /></div>
              mg
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark">是否会监测血糖</div></el-col>
          <el-col :span="18">
            <div class="grid-content bg-purple-dark">
              <el-radio-group v-model="tablist.sugarStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              <div v-if="tablist.sugarStatus == 1">
                (空腹：
                <el-input v-model="tablist.sugarStomach" placeholder="" style="max-width: 100px" />
                餐后2小时:
                <el-input v-model="tablist.sugarMeal" placeholder="" style="max-width: 100px" />
                )
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark">是否出现低血糖</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              <el-radio-group v-model="tablist.sugarLowStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="6">
            <div v-if="tablist.sugarLowStatus == 1" class="grid-content bg-purple-dark">出现时间和频率</div>
            <div v-else class="grid-content bg-purple-dark">出现时间和频率</div>
          </el-col>
          <el-col :span="6">
            <div v-if="tablist.sugarLowStatus == 1" class="grid-content bg-purple-dark">
              <el-input v-model="tablist.sugarLowMeal" placeholder="出现时间和频率" />
            </div>
            <div v-else class="grid-content bg-purple-dark"></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark" style="height: 100px">既往病史</div></el-col>
          <el-col :span="18">
            <div class="grid-content bg-purple-dark" style="height: 100px;flex-wrap: wrap">
              <el-tag v-for="(item,index) in tablist.casePos3" :key="index" type="info" v>{{ item.caseName }}</el-tag>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark">既往史是否用药</div></el-col>
          <el-col :span="18">
            <div class="grid-content bg-purple-dark" style="white-space: nowrap">
              <el-radio-group v-model="tablist.drugStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              <div v-if="tablist.drugStatus == 1" style="margin-left: 20px">药物名称：</div>
              <el-input v-if="tablist.drugStatus == 1" v-model="tablist.drugName" placeholder="药物名称" />
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark">住院史</div></el-col>
          <el-col :span="18">
            <div class="grid-content bg-purple-dark" style="white-space: nowrap">
              <el-input v-model="tablist.addressDetail" placeholder="住院史地址" />
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark">脂肪肝</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark" style="white-space: nowrap">
              <el-radio-group v-model="tablist.fattyStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="6"><div class="grid-content bg-purple-dark">家族史</div></el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-dark">
              <el-input v-model="tablist.fattyName" placeholder="" />
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple-dark" style="height: 45px">最希望改善的地方</div></el-col>
          <el-col :span="18">
            <div class="grid-content bg-purple-dark" style="flex-wrap: wrap; height: 45px">
              <el-tag v-for="(item,index) in tablist.casePos4" :key="index" type="info" v>{{ item.caseName }}</el-tag>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3"><div class="grid-content bg-purple-dark" style="height: 257px">身体症状</div></el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              1、有无手麻、脚麻或皮肤瘙痒等症状？
              <el-radio-group v-model="tablist.casePos5[0].caseStatus">
                <el-radio  :label="1">是</el-radio>
                <el-radio  :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              2、有无失眠、多梦或便秘等症状？
              <el-radio-group v-model="tablist.casePos5[1].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              3、是否有便秘、腹泻等症状？
              <el-radio-group v-model="tablist.casePos5[2].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              4、有无心慌、胸闷、气短、头晕头痛等心脑血管症状？
              <el-radio-group v-model="tablist.casePos5[3].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              5、有无视物模糊？
              <el-radio-group v-model="tablist.casePos5[4].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              6、有无尿液泡沫增多？
              <el-radio-group v-model="tablist.casePos5[5].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="21">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              7、有无糖尿病足部病变（如损伤后不容易愈合）？
              <el-radio-group v-model="tablist.casePos5[6].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24"><div class="grid-content bg-purple-dark">饮食生活调查问卷</div></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">1、平时吃一顿饭用时</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              大概
              <div class="textundeline"><el-input v-model="tablist.caseMg" placeholder="" style="max-width: 55px" /></div>
              分钟
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">2、晚上 8 点后会吃食物吗？</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              <el-radio-group v-model="tablist.casePos6[1].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              一周
              <div class="textundeline"><el-input v-model="tablist.casePos6[1].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">3、三餐以外会吃食物/零食吗？</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              <el-radio-group v-model="tablist.casePos6[2].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              一周
              <div class="textundeline"><el-input v-model="tablist.casePos6[2].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">4、是否常喝饮料/酒</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              <el-radio-group v-model="tablist.casePos6[3].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              一周
              <div class="textundeline"><el-input v-model="tablist.casePos6[3].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">5、平时会运动吗？</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              <el-radio-group v-model="tablist.casePos6[4].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              一周
              <div class="textundeline"><el-input v-model="tablist.casePos6[4].caseCishu" placeholder="" style="max-width: 55px" /></div>
              次
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">6、是否 12 点之前入睡？</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              <el-radio-group v-model="tablist.casePos6[5].caseStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><div class="grid-content bg-purple-dark lef_ym">7、睡眠质量好吗？</div></el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
              <el-radio-group v-model="tablist.casePos6[6].caseStatus">
                <el-radio :label="1">好</el-radio>
                <el-radio :label="0">不好</el-radio>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {uuUserSugarRecordLogdetails} from "@/api/mall/health/casemy/index"
export default {
  props:{
    showbole:{
      type:Boolean,
      default:()=>{}
    },
    showtdm:{
      type:Object,
      default:()=>{}
    },
  },
  data(){
    return{
      showdemo:'',
      useId:'',
      tablist:null,
    }
  },
  watch:{
    showbole(newValue,oldValue){
      if(newValue!=oldValue){
        this.showdemo=newValue
      }
    },
    showtdm(newValue,oldValue){
      if(newValue!=oldValue){
        this.useId=newValue.id
        this.uuUserSugarRecordLogdetails()
      }
    },
  },
  created(){
    console.log(this.showtdm,'---------',this.useId)
  },
  methods:{
    claer(){
      this.$emit('closetb',false)
    },
    handleClose(){
      this.$emit('closetb',false)
    },
    uuUserSugarRecordLogdetails(){
      uuUserSugarRecordLogdetails({id:this.useId}).then(res=>{
        console.log(res.data)
        this.tablist=res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
 ::v-deep {
    .el-col {
      border: 1px solid #b4b4b4;
    }
    .el-input__inner {
      border: none;
    }
  }
  .bg-purple-dark {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    padding: 0 10px;
    white-space: nowrap;
  }
  .textundeline {
    min-width: 20px;
    border-bottom: 1px solid #a3a3a3;
  }
  .lef_ym {
    justify-content: left;
  }
</style>