var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs" },
    [
      _c(
        "el-card",
        { staticClass: "tabs-card", attrs: { shadow: "hover" } },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "订单", name: "first" } },
                [
                  _c(
                    "el-table",
                    {
                      attrs: { border: "", data: _vm.tableData, height: "280" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "订单编号",
                          prop: "orderNo",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "下单用户",
                          prop: "phone",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "订单日期",
                          prop: "createTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "订单金额",
                          prop: "paymentPrice",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "下单员工",
                          prop: "profile",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(scope.row.realName) +
                                    _vm._s(
                                      scope.row.profile == ""
                                        ? ""
                                        : "(" + scope.row.profile + ")"
                                    )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "订单状态 / 物流状态 / 质检审核",
                          width: "240",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.statusDesc
                                  ? _c("span", [
                                      _vm._v(
                                        " " + _vm._s(row.statusDesc) + "/"
                                      ),
                                    ])
                                  : _vm._e(),
                                row.logisticsStatus == 0
                                  ? _c("span", [_vm._v(" 在途")])
                                  : _vm._e(),
                                row.logisticsStatus == 1
                                  ? _c("span", [_vm._v(" 揽收/")])
                                  : _vm._e(),
                                row.logisticsStatus == 2
                                  ? _c("span", [_vm._v(" 疑难/")])
                                  : _vm._e(),
                                row.logisticsStatus == 3
                                  ? _c("span", [_vm._v(" 签收/")])
                                  : _vm._e(),
                                row.logisticsStatus == 4
                                  ? _c("span", [_vm._v(" 退签/")])
                                  : _vm._e(),
                                row.logisticsStatus == 5
                                  ? _c("span", [_vm._v(" 派件/")])
                                  : _vm._e(),
                                row.logisticsStatus == 6
                                  ? _c("span", [_vm._v(" 退回/")])
                                  : _vm._e(),
                                row.logisticsStatus == 7
                                  ? _c("span", [_vm._v(" 转投/")])
                                  : _vm._e(),
                                row.logisticsStatus == 8
                                  ? _c("span", [_vm._v(" 清关/")])
                                  : _vm._e(),
                                row.logisticsStatus == 14
                                  ? _c("span", [_vm._v(" 拒签/")])
                                  : _vm._e(),
                                row.logisticsStatus == null
                                  ? _c("span", [_vm._v(" - /")])
                                  : _vm._e(),
                                row.quaState == 1
                                  ? _c("span", [_vm._v(" 待质检")])
                                  : _vm._e(),
                                row.quaState == 2
                                  ? _c("span", [_vm._v(" 已通过")])
                                  : _vm._e(),
                                row.quaState == 3
                                  ? _c("span", [_vm._v(" 已驳回")])
                                  : _vm._e(),
                                row.quaState == null
                                  ? _c("span", [_vm._v(" - ")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "所购产品",
                          prop: "product",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }