<template>
  <div class="cy-container">
    <!-- 查询 -->
    <el-form ref="queryForm" :inline="true" :model="queryForm" @submit.native.prevent>
      <el-form-item label="打卡名称" prop="articleTitle">
        <el-input v-model="queryForm.articleTitle" class="filterItems" clearable placeholder="请输入打卡名称" />
      </el-form-item>
      <el-form-item label="打卡类型" prop="cardClass">
        <el-select v-model="queryForm.cardClass" class="filterItems" clearable placeholder="请选择">
          <el-option v-for="item in colStatusList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button icon="el-icon-search" native-type="submit" type="warning" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh-left" native-type="submit" type="primary" @click="handleReset">重置</el-button>
      </el-form-item>
      <el-form-item style="float: right" />
    </el-form>

    <el-button
      v-for="item in searchButton"
      :key="item.id"
      class="m-b"
      :plain="item.cssType && item.cssType == 'plain' ? true : false"
      :type="item.buttonCss"
      @click.native="handleClickBtn(item)"
    >
      <span v-if="!item.buttonIcon"></span>
      <vab-icon v-else :icon="item.buttonIcon" />
      {{ item.buttonName }}
    </el-button>
    <!-- 表格 -->
    <el-table ref="multipleTable" v-loading="listLoading" border :data="tableData" default-expand-all max-height="600" @selection-change="handleSelectionChange">
      <el-table-column align="center" show-overflow-tooltip type="selection" />
      <el-table-column align="center" label="打卡名称" prop="cardName" show-overflow-tooltip />
      <el-table-column align="center" label="打卡图标" prop="cardImg" show-overflow-tooltip>
        <template #default="{ row }">
          <div>
            <el-image alt="" fit="fill" :src="row.cardImg" srcset="" style="width: 60px; height: 60px" @click="handleImgClick(row.cardImg)" />
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        label="背景颜色"
        prop="bgColor"
        show-overflow-tooltip
      /> -->
      <!-- <el-table-column
        align="center"
        label="运动卡路里"
        prop="cardCalories"
        show-overflow-tooltip
      /> -->
      <el-table-column align="center" label="打卡类型" prop="enable" show-overflow-tooltip>
        <template #default="{ row }">
          <el-tag :type="row.cardClass === 1 ? 'success' : 'danger'">
            {{ row.cardClass === 1 ? '运行打卡' : '日常打卡' }}
          </el-tag>
        </template>
      </el-table-column>

      <!-- <el-table-column align="center" label="是否开启" prop="isHot" show-overflow-tooltip width="80px">
        <template #default="{ row }">
          <el-switch
            v-model="row.cardStatus"
            :active-value="1"
            :disabled="row.cardStatus == 1 ? cardStatus == true : cardStatus == false"
            :inactive-value="0"
            @change="handleUpdate('是否开启', row)"
          />
        </template>
      </el-table-column> -->

      <el-table-column align="center" label="创建时间" prop="createTime" show-overflow-tooltip />
      <el-table-column v-if="operationShow" align="center" label="操作" min-width="100px">
        <template #default="{ row }">
          <el-button type="text" @click="handleLook(row)">查看</el-button>
          <el-button type="text" @click="handelEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      :current-page="forms.current"
      :layout="layout"
      :page-size="forms.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 新增(编辑) -->
    <el-dialog class="addDialog" :title="dialogTitle" top="2vh" :visible.sync="addVisible" width="60%" @close="close">
      <el-form ref="addForm" label-width="100px" :model="addForm" :rules="rules">
        <el-form-item label="打卡名称" prop="cardName">
          <el-input v-model.trim="addForm.cardName" autocomplete="off" clearable placeholder="请输入打卡名称" />
        </el-form-item>
        <el-form-item label="打卡类型" prop="cardClass">
          <el-select v-model="queryForm.cardClass" class="filterItems" clearable placeholder="请选择">
            <el-option v-for="item in colStatusList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="添加标签" prop="cardSort">
          <el-tag v-for="tag in dynamicTags" :key="tag" closable :disable-transitions="false" @close="handleClose(tag)">
            {{ tag }}
          </el-tag>
          <el-input
            v-if="inputVisible"
            ref="saveTagInput"
            v-model="inputValue"
            class="input-new-tag"
            maxlength="4"
            size="small"
            @blur="handleInputConfirm"
            @keyup.enter.native="handleInputConfirm"
          />
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+添加标签</el-button>
        </el-form-item>
        <el-form-item label="打卡排序" prop="cardSort">
          <el-input-number v-model="addForm.cardSort" :min="0" placeholder="请输入" size="small" style="width: 100%" />
        </el-form-item>
        <el-form-item label="计划图标" prop="cardImg" required>
          <el-upload
            ref="upload"
            :action="action"
            :before-upload="bfUpload"
            :class="{
              hide: hideUpload,
            }"
            :file-list="fileList"
            :headers="headers"
            :limit="limitCount"
            list-type="picture-card"
            :on-change="handleChange"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog append-to-body :visible.sync="dialogVisible">
            <img alt="" :src="dialogImageUrl" width="100%" />
          </el-dialog>
        </el-form-item>
        <el-form-item label="背景颜色" prop="bgColor">
          <el-input v-model="addForm.bgColor" placeholder="请输入" size="small" style="width: 100%" />
        </el-form-item>
        <el-form-item label="运动卡路里" prop="cardCalories">
          <el-input v-model.trim="addForm.cardCalories" autocomplete="off" clearable placeholder="请输入" type="textarea" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="imgDialog">
      <img :src="imagesUrl" width="100%" />
    </el-dialog>
    <!-- 查看 -->
    <el-dialog title="查看" :visible.sync="lookVisible" width="40%">
      <el-form ref="addForm" label-width="100px" :model="lookForm">
        <el-form-item label="打卡名称：">
          <el-row class="color117">{{ lookForm.cardName }}</el-row>
        </el-form-item>
        <el-form-item label="排序：">
          <el-row class="color117">{{ lookForm.cardSort }}</el-row>
        </el-form-item>
        <el-form-item label="背景颜色：">
          <el-row class="color117">{{ lookForm.bgColor }}</el-row>
        </el-form-item>
        <el-form-item label="运动卡路里：">
          <el-row class="color117">{{ lookForm.cardCalories }}</el-row>
        </el-form-item>
        <el-form-item label="列表图片">
          <el-row class="color117">
            <el-image alt="" fit="fill" :src="lookForm.cardImg" srcset="" style="width: 110px; height: 100px; border: 1px solid #ddd" @click="handleImgClick(lookForm.cardImg)" />
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="lookVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 配置类型 -->
    <el-dialog v-if="authorizeRoleisible" class="authorizePoles" :close-on-click-modal="false" title="配置类型" :visible.sync="authorizeRoleisible" width="50%" @close="closeRole">
      <el-row>
        <el-col style="padding: 15px; margin-bottom: 15px; border: 1px solid #ddd">
          {{ selectarticleTitle }}
        </el-col>
      </el-row>

      <el-transfer v-model="grantRoleList" :data="roleList" filterable :props="{ label: 'typeName', key: 'id' }" :titles="treeTitle" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeRole">取 消</el-button>
        <el-button type="primary" @click="createAuthorizeRoleButton">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  queryPage,
  edit,
  addObj,
  delObj,
  // disableColumn,
  // queryListForList,
  // addColumnType,
} from '@/api/mall/health/cardType/index'
import { uploadURL } from '@/config'
import { selectRoleMenuButtonList } from '@/api/mall/common'
// import BaseEditor from '@/components/editor/BaseEditor.vue'
export default {
  name: 'ArtColumn',
  // components: {
  //   BaseEditor,
  // },
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      selectarticleTitle: '',
      multipleSelection: [],
      temp: {},
      postCodeOptions: [],
      treeTitle: ['未配置类型', '已配置类型'],
      grantRoleList: [],
      roleList: [],
      authorizeRoleisible: false,
      operationShow: false,
      lookForm: {},
      lookVisible: false,
      imagesUrl: '',
      imgDialog: false,
      hideUpload: false,
      limitCount: 1,
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      headers: {},
      action: uploadURL,
      rules: {
        cardName: [
          {
            required: true,
            message: '动作名称不能为空',
            trigger: ['blur', 'change'],
          },
        ],
      },
      addForm: {
        picUrl: '',
      },
      addVisible: false,
      dialogTitle: '',
      colStatusList: [
        {
          id: '0',
          name: '日常打卡',
        },
        {
          id: '1',
          name: '运行打卡',
        },
        {
          id: '3',
          name: '健康打卡',
        },
      ],
      listLoading: false,
      forms: { current: 1, size: 10 },
      isShow: true,
      queryForm: {
        colStatus: '',
        cardName: '',
      },
      tableData: [],
      current: 1,
      size: 10,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      searchButton: [],
      listButton: [],
      optrow: null,
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/token',
      tenantId: 'user/tenantId',
    }),
  },
  created() {
    this.headers['Authorization'] = `Bearer ${this.token}`
    this.headers['Switch-Tenant-Id'] = `${this.tenantId}`
    this.fetchData()
    this.selectRoleMenuButtonList()
  },
  methods: {
    //tag标签添加方法
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.dynamicTags.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    // 配置类型保存
    createAuthorizeRoleButton() {
      if (this.grantRoleList.length === 0) {
        this.ME('已配置类型不能为空')
        return
      }
      const tempData = {}
      tempData.typeIds = this.grantRoleList
      tempData.colId = this.multipleSelection[0].id
      // addColumnType(tempData).then(() => {
      //   this.authorizeRoleisible = false
      //   this.MS('成功')
      //   this.closeRole()
      //   this.fetchData()
      // })
    },
    // 查询配置类型
    // queryListForList() {
    //   queryListForList({})
    //     .then((res) => {
    //       if (res.data) {
    //         this.roleList = res.data
    //       }
    //     })
    //     .catch(() => {})
    // },
    // 配置类型
    handleType() {
      if (this.multipleSelection.length === 0) {
        this.ME('请勾选记录')
        return
      }
      this.authorizeRoleisible = true
      this.queryListForList()
    },
    // 表格操作
    handleSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable.toggleRowSelection(val.pop())
      } else {
        this.multipleSelection = val
        if (val.length != 0) {
          this.selectarticleTitle = val[0].articleTitle
        }
      }
    },

    // 配置类型(取消)
    closeRole() {
      this.grantRoleList = []
      this.roleList = []
      this.authorizeRoleisible = false
    },
    // 按钮状态显示判断
    showListButton(row, item) {
      if (item.buttonUrl == 'handleLook' || item.buttonUrl == 'handelEdit' || item.buttonUrl == 'handelDelete') {
        return true
      }
      if (row.enable == 1 && item.buttonName == '开启') {
        return true
      } else if (row.enable == 0 && item.buttonName == '禁用') {
        return true
      }
    },
    // 按钮点击
    handleClickBtn(item, row) {
      this.dynamicTags = []
      if (item.buttonUrl) {
        this[item.buttonUrl](row)
      }
    },
    // 按钮权限
    selectRoleMenuButtonList() {
      selectRoleMenuButtonList({
        // roleIdStrList: this.$store.getters['acl/roleList'],
        // menuId: this.$route.meta.id,
        roleIdStrList: 1,
        menuId: 91,
      })
        .then((res) => {
          if (res.data) {
            this.searchButton = res.data.filter(function (item) {
              return item.buttonType == 1
            })
            this.listButton = res.data.filter(function (item) {
              return item.buttonType == 2
            })
            if (this.listButton.length > 0) {
              this.operationShow = true
            } else {
              this.operationShow = false
            }
          }
        })
        .catch(() => {})
    },
    // 图片上传
    bfUpload(file) {
      console.log(file)
      if ('image/png' == file.type || 'image/jpeg' == file.type || 'image/jpg' == file.type) {
        console.log()
      } else {
        this.ME('图片上传失败,请上传png,jpeg,jpg格式')
        return false
      }
    },
    // 图片预览
    handleImgClick(url) {
      this.imagesUrl = url
      this.imgDialog = true
    },
    // 图片
    handleChange(file, fileList) {
      console.log(file, fileList)
      this.hideUpload = fileList.length >= this.limitCount
    },
    // 分类Logo
    handleSuccess(response, file) {
      console.log(response, file)
      this.addForm.cardImg = response.data.filePath
      this.fileList.push({
        url: response.data.filePath,
      })
    },
    // 删除图片
    handleRemove(fileList) {
      this.fileList = []
      this.addForm.cardImg = ''
      this.hideUpload = fileList.length >= this.limitCount
    },
    // 图片显示
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 编辑
    handelEdit(row) {
      if (row) {
        this.showEdit(row)
        this.dynamicTags = []
        row.vitPunchCardTypeValueDos.forEach((item, index) => {
          this.dynamicTags.push(row.vitPunchCardTypeValueDos[index].cardName)
        })
      } else {
        this.showEdit()
      }
    },
    // 查看
    handleLook(row) {
      this.lookForm = Object.assign({}, row)
      this.lookVisible = true
    },
    // 添加编辑title
    showEdit(row) {
      this.row = row
      if (!row) {
        this.dialogTitle = '添加'
        this.hideUpload = this.fileList.length >= this.limitCount
      } else {
        this.dialogTitle = '编辑'
        this.rowId = row.id
        this.addForm.cardImg = row.cardImg
        this.fileList = []
        if (row.cardImg && row.cardImg != null) {
          this.fileList.push({
            url: this.addForm.cardImg,
          })
          this.hideUpload = this.fileList.length >= this.limitCount
        }
        this.hideUpload = this.fileList.length >= this.limitCount
        this.addForm = Object.assign({}, row)
      }
      this.addVisible = true
    },
    // 关闭
    close() {
      this.fileList = []
      this.$refs['addForm'].resetFields()
      this.addForm = this.$options.data().addForm
      this.addVisible = false
    },
    // 保存
    save() {
      console.log(this.addForm.cardImg)
      if (this.addForm.cardImg == '' || this.addForm.cardImg == null) {
        return this.ME('请上传分类LOGO')
      }
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          const parms = {
            cardName: this.addForm.cardName,
            bgColor: this.addForm.bgColor,
            cardSort: this.addForm.cardSort,
            cardImg: this.addForm.cardImg,
            cardClass: this.addForm.cardClass,
            vitPunchCardTypeValueDos: this.dynamicTags,
            cardCalories: this.addForm.cardCalories,
          }
          if (!this.row) {
            addObj(parms)
              .then(() => {
                this.MS('新增成功')
                this.fetchData()
                this.close()
              })
              .catch(() => {})
          } else {
            edit({ ...parms, id: this.rowId })
              .then(() => {
                this.MS('修改成功')
                this.fetchData()
                this.close()
              })
              .catch(() => {})
          }
        }
      })
    },
    // 列表
    async fetchData(options) {
      if (options) {
        this.optrow = options
      }
      console.log(this.forms, '12')
      this.listLoading = true
      await queryPage({
        ...this.optrow,
        ...this.forms,
        // current: this.current,
        // size: this.size,
        // colStatus: this.queryForm.colStatus,
        // articleTitle: this.queryForm.articleTitle,
      })
        .then((res) => {
          if (res.data) {
            this.tableData = res.data.records
            this.total = res.data.total
          }
        })
        .catch(() => {})
      this.listLoading = false
    },

    // 禁用开启
    handleDisable(row) {
      this.$confirm('是否要更新此条数据的状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          console.log(row)
          // disableColumn({ id: row.id, colStatus: row.colStatus == 1 ? 0 : 1 })
          //   .then(() => {
          //     this.MS('成功')
          //     this.fetchData()
          //   })
          //   .catch(() => {})
        })
        .catch(() => {})
    },
    // 删除
    handelDelete(row) {
      this.$confirm('是否要删除此行数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delObj({ id: row.id })
            .then(() => {
              this.MS('删除成功')
              this.fetchData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 重置
    handleReset() {
      this.forms.current = 1
      this.forms.size = 10
      this.queryForm = {}
      this.handleQuery()
    },
    // 搜索
    async handleQuery() {
      this.forms.current = 1
      await this.fetchData({ ...this.queryForm }, false)
      let temp = false
      Object.keys(this.queryForm).forEach((key) => {
        this.queryForm[key] && (temp = true)
      })
      this.isShow = temp
    },
    // 分页
    handleSizeChange(val) {
      this.forms.size = val
      let data = this.isShow === true ? this.queryForm : {}
      this.fetchData({ ...data }, false)
    },
    // 分页
    handleCurrentChange(val) {
      this.forms.current = val
      let data = this.isShow === true ? this.queryForm : {}
      this.fetchData({ ...data }, false)
    },
  },
}
</script>
<style lang="scss" scoped>
.authorizePoles {
  ::v-deep(.el-transfer-panel) {
    width: 40%;
  }
}

::v-deep(.hide .el-upload--picture-card) {
  display: none;
}
.filterItems {
  width: 150px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  height: 32px;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 10px;
  line-height: 30px;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
