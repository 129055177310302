const options = [
  { label: '父分类', span: 12, prop: 'parent', type: 'select' },
  { label: '名称', span: 12, prop: 'name' },
  {
    label: '是否显示',
    span: 12,
    prop: 'radio',
    optionData: [
      { label: '关闭', value: 1 },
      { label: '启用', vlaue: 2 },
    ],
  },
  { label: '排序', span: 12, prop: 'sort', type: 'number' },
  { label: '图片', span: 12, prop: 'img', type: 'upload' },
]

const rules = {
  c: [{ required: true, trigger: 'blur', message: '请输入标题' }],
  b: [{ required: true, trigger: 'blur', message: '请输入作者' }],
}
const config = { labelWidth: '8em' }
export { options, rules, config }
