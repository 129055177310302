var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ComplateMessges" },
    [
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                center: "",
                "close-on-click-modal": false,
                title: _vm.title,
                visible: _vm.dialogFormVisible,
                width: "70%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
                close: _vm.close,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.close },
                          },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.save },
                          },
                          [_vm._v("确认")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2351209663
              ),
            },
            [
              _c("el-container", { staticStyle: { width: "100%" } }, [
                _c(
                  "div",
                  {
                    ref: "asideRef",
                    staticClass: "asideAuthorizeUrl",
                    staticStyle: {
                      width: "200px",
                      height: "800px",
                      overflow: "scroll",
                      "margin-right": "5px",
                      border: "1px solid #c0d4f0",
                    },
                  },
                  [
                    _c(
                      "el-aside",
                      { staticStyle: { width: "auto" } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: {
                              width: "100%",
                              "margin-bottom": "20px",
                            },
                            attrs: {
                              border: "",
                              data: _vm.list,
                              "highlight-current-row": true,
                              lazy: "",
                              load: _vm.load,
                              "max-height": 800,
                              "row-key": "id",
                              "tree-props": {
                                children: "children",
                                hasChildren: "hasChildren",
                              },
                            },
                            on: { "row-click": _vm.clickAsideData },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "分类", prop: "catName" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    ref: "contantRef",
                    staticStyle: { width: "87%", overflow: "scroll" },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: { inline: true, model: _vm.queryForm },
                        nativeOn: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品编号" } },
                          [
                            _c("el-input", {
                              attrs: { clearable: "", placeholder: "商品编号" },
                              model: {
                                value: _vm.queryForm.skuCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm,
                                    "skuCode",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "queryForm.skuCode",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品名称" } },
                          [
                            _c("el-input", {
                              attrs: { clearable: "", placeholder: "商品名称" },
                              model: {
                                value: _vm.queryForm.goodsName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm,
                                    "goodsName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "queryForm.goodsName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-search",
                                  type: "primary",
                                },
                                on: { click: _vm.handleQuery },
                              },
                              [_vm._v(" 查询 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-refresh-right",
                                  type: "primary",
                                },
                                on: { click: _vm.handleReset },
                              },
                              [_vm._v(" 重置 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading",
                          },
                        ],
                        ref: "multipleTable",
                        attrs: {
                          border: "",
                          data: _vm.tableData,
                          "row-key": "id",
                          "tooltip-effect": "dark",
                          "tree-props": { children: "children" },
                        },
                        on: { "selection-change": _vm.setSelectRows },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "id",
                            "reserve-selection": true,
                            "show-overflow-tooltip": "",
                            type: "selection",
                            width: "40",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品编号",
                            prop: "skuCode",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品名称",
                            prop: "goodsName",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "库存",
                            prop: "goodsStock",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "计量单位",
                            prop: "unitName",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "商品品牌",
                            prop: "brandName",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "商品标签",
                            prop: "labelName",
                            "show-overflow-tooltip": "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "current-page": _vm.forms.currentPage,
                        layout: _vm.layout,
                        "page-size": _vm.forms.pageSize,
                        total: _vm.total,
                      },
                      on: {
                        "current-change": _vm.handleCurrentChange,
                        "size-change": _vm.handleSizeChange,
                      },
                    }),
                    _c(
                      "el-table",
                      {
                        ref: "tableDataSize",
                        staticClass: "mt20",
                        attrs: {
                          border: "",
                          data: _vm.tableDataSize,
                          "row-key": "id",
                        },
                        on: { "selection-change": _vm.setSelectSize },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "id",
                            "reserve-selection": true,
                            "show-overflow-tooltip": "",
                            type: "selection",
                            width: "40",
                          },
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "规格类型",
                              prop: "specsTitle",
                              "show-overflow-tooltip": "",
                            },
                          },
                          [_c("div", [_vm._v(_vm._s(_vm.specsTitle))])]
                        ),
                        _c("el-table-column", {
                          attrs: {
                            label: "规格名称",
                            prop: "specsName",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "单位",
                            prop: "unitName",
                            "show-overflow-tooltip": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }