<template>
  <div class="YmDialog">
    <div class="ym_search">
      <el-form :inline="true" :v-model="form">
        <el-form-item label="">
          <el-input
            v-model="form.user"
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            width="100%"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="ym_healthy">
      <el-card class="always" shadow="always">
        <div class="ym_con">
          <div v-for="(ym,index) in card" :key="index" :class="ym.sk?'ym_card_item':'ym_card_item active'" @click="add(ym.id)">
            <div class="ym_content">
              <div class="ym_lef_pic">
                <img alt="" :src="ym.planLogo">
              </div>
              <div class="ym_ref">
                <div class="ym_name">
                  {{ ym.planName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </div>
</template>

<script>
import {queryPagea} from "@/api/mall/health/planDiet/index"
  export default {
    props:{
      yms:{
        type: Number,
        required: true
      }
    },
    data() {
      return {
        form: '',
        card: [],
        date:[]
      }
    },
    created() {
      console.log(this.yms)
      this.queryPage()
    },
    methods: {
      // 请求接口
      queryPage(){
        const parms={
          current:1,
          size:1000
        }
        queryPagea({parms}).then((res)=>{
          res.data.records.forEach(item=>{
            Object.assign(item,{sk:true})
          })
          this.card=res.data.records
          console.log(res.data.records)
        })
      },
      close() {},
      // 保存事件
      save() {
        const show={
          planItems:this.date,
          d:this.yms,
          ifshow:false
        }
        this.$emit('transfer',show)
      },
      onSubmit() {},
      //点击选择事件
      add(e){
        //选中状态
        if(this.card[e-1].sk){
          const sl={
            id:this.card[e-1].id,
            planName:this.card[e-1].planName
          }
          this.date.push(sl)
          // this.date.push({id:this.card[e-1].id})
          // this.date.push({planName:this.card[e-1].planName})
        }
        else{
          this.card[e-1].sk==this.card[e-1].sk
          this.date.splice(e-1)
        }
        this.card[e-1].sk=!this.card[e-1].sk
        console.log(this.date)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .YmDialog {
    width: 100%;
    .dialog-footer {
      display: flex;
      justify-content: right;
    }
    .ym-search-icon {
      display: flex;
    }
    .ym_healthy {
      width: 100%;
      height: 400px;
      padding: 10px 0;
      margin: 20px auto;
      overflow: hidden;
      overflow-y: scroll;
    }
    .always{
      width: 100%;
      overflow: auto;
      height: 300px;
      .ym_con{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }
    .ym_card_item{
      width: calc(33% - 10px);
      height: 150px;
      // background: red;
      border:1px dashed #c7c7c7;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
      .ym_content{
        display: flex;
        align-items: center;
        .ym_lef_pic{
          width: 50px;
          height: 50px;
          overflow: hidden;
          border-radius: 100%;
          margin-right: 10px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .ym_ref{
          .ym_name{
            color: #44485f;
          }
        }
      }
    }
    .active{
      background: #f1f1f1;
    }
  }
</style>
