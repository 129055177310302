<template>
  <div>
    <el-badge :value="errorLogs.length" @click.native="dialogTableVisible = true">
      <vab-icon icon="chat-3-line" />
    </el-badge>

    <el-dialog append-to-body title="当前未处理信息" :visible.sync="dialogTableVisible" width="70%">
      <el-table border :data="errorLogs" height="250">
        <el-table-column label="用户姓名" prop="userName" />
        <el-table-column label="信息内容" prop="content" />
        <el-table-column label="时间" prop="vistTime" />
      </el-table>
      <template #footer>
        <el-button @click="dialogTableVisible = false">取 消</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getList } from '@/api/websoket'
export default {
  name: 'VabErrorLog',
  data() {
    return {
      dialogTableVisible: false,
      errorLogs: []
    }
  },
  watch: {
    dialogTableVisible: {
      deep: true,
      // immediate: true,
      handler(newVal) {
        if(newVal){
          getList({ status: 0 }).then((res) => {
      this.errorLogs = res.data
    })
        }
      },
    },
  },
  created() {
    getList({ status: 0 }).then((res) => {
      this.errorLogs = res.data
    })
  },
  methods: {
    // this.dialogTableVisible = false
  },
}
</script>

<style lang="scss" scoped>
:deep() {
  .el-badge {
    .el-button {
      display: flex;
      align-items: center;
      justify-items: center;
      height: 28px;
    }
  }
}
</style>