import request from '@/utils/request'

export function getList(query) {
  return request({
    url: '/mall/freighttemplat/list',
    method: 'get',
    params: query,
  })
}
/**
 *
 * @param {运费模板管理-分页列表} query
 * @returns
 */
export function getPage(query) {
  return request({
    url: '/mall/freighttemplat/page',
    method: 'get',
    params: query,
  })
}
/**
 *
 * @param {运费模板管理-新增运费模板} query
 * @returns
 */
export function addObj(obj) {
  return request({
    url: '/mall/freighttemplat',
    method: 'post',
    data: obj,
  })
}
/**
 *
 * @param {运费模板管理-运费模板查询} query
 * @returns
 */
export function getObj(id) {
  return request({
    url: '/mall/freighttemplat/' + id,
    method: 'get',
  })
}
/**
 *
 * @param {运费模板管理-删除运费模板} query
 * @returns
 */
export function delObj(id) {
  return request({
    url: '/mall/freighttemplat/' + id,
    method: 'delete',
  })
}
/**
 *
 * @param {运费模板管理-修改运费模板} query
 * @returns
 */
export function putObj(obj) {
  return request({
    url: '/mall/freighttemplat',
    method: 'put',
    data: obj,
  })
}
