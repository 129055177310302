<template>
  <div class="register-container">
    <!-- <div class="ym_header"><div v-if="ruleForm.bindStatus==1">绑定</div><div v-else>注册</div></div> -->
    <div v-if="shdemo" class="c">
      <div class="ym_form">
        <el-form ref="formName" label-width="100px" :model="ruleForm" :rules="rules">
          <el-form-item label="姓名：" prop="userName">
            <el-input v-model="ruleForm.userName" placeholder="请输入 姓名" />
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            <el-input v-model="ruleForm.phone" onfocus="this.removeAttribute('readonly')" placeholder="请输入手机号" readonly />
          </el-form-item>

          <el-form-item label="密码：" prop="password">
            <el-input v-model="ruleForm.password" placeholder="请输入 密码" show-password />
          </el-form-item>
          <el-form-item label="确认密码：" prop="passwords">
            <el-input v-model="ruleForm.passwords" autocomplete="off" placeholder="请输入 确认密码" show-password />
          </el-form-item>
          <el-form-item label="会员等级：" prop="userGrade">
            <el-select v-model="ruleForm.userGrade" placeholder="请选择 会员等级">
              <el-option v-for="item in options" :key="item.levelId" :label="item.merName" :value="item.levelId" />
            </el-select>
          </el-form-item>
          <el-form-item label="邀请人：" prop="sharerUserCode">
            <div class="share" style="display: flex">
              <el-input v-model="ruleForm.sharerUserCode" autocomplete="off" placeholder="请输入 邀请人" />
              <!-- <el-button type="primary" @click="showhide=true">选择</el-button> -->
            </div>
          </el-form-item>
          <!-- <el-form-item label="会员标签：" >
              <el-select v-model="ruleForm.value" placeholder="请选择 会员标签">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"za
                  :value="item.value"/>
              </el-select>
            </el-form-item>
            <el-form-item label="会员卡：" >
              <el-select v-model="ruleForm.value" placeholder="请选择 会员卡">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"/>
              </el-select>
            </el-form-item> -->
          <!-- <el-form-item label="余额：">
              <el-input v-model="ruleForm.enable" placeholder="请输入 余额"/>
            </el-form-item> -->
          <!-- <el-form-item label="积分：" prop="pointsCurrent">
              <el-input v-model="ruleForm.pointsCurrent" placeholder="请输入 积分" />
            </el-form-item> -->
          <!-- <el-form-item label="性别：" prop="sex">
              <el-radio v-model="ruleForm.sex" label="1">男</el-radio>
              <el-radio v-model="ruleForm.sex" label="2">女</el-radio>
              <el-radio v-model="ruleForm.sex" label="0">未知</el-radio>
            </el-form-item> -->
          <!-- <el-form-item label="头像：" prop="headimgUrl">
              <el-upload
                ref="upload"
                :action="action"
                :before-upload="bfUpload"
                :class="{
                  hide: hideUpload,
                }"
                :file-list="fileList"
                :headers="headers"
                :limit="1"
                list-type="picture-card"
                :on-change="handleChange"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog append-to-body :visible.sync="dialogVisible">
                <img alt="" :src="picUrls" width="100%" />
              </el-dialog>
            </el-form-item> -->
          <!-- <el-form-item label="昵称：" prop="nickName">
              <el-input v-model="ruleForm.nickName" placeholder="请输入 昵称" />
            </el-form-item> -->
          <!-- <el-form-item label="生日：" prop="userYear">
            <el-date-picker v-model="ruleForm.userYear" placeholder="选择生日" type="date" />
          </el-form-item> -->
          <!-- <el-form-item label="备注：" prop="withdrawBank">
            <el-input v-model="ruleForm.withdrawBank" placeholder="请输入 备注" type="textarea"/>
          </el-form-item> -->
        </el-form>
      </div>
      <shareuser :hidemenber="showhide" @menbarrow="userinfo" />
      <div class="ym_checkbtn">
        <el-button icon="el-icon-check" type="primary" @click="submit">提交</el-button>
      </div>
    </div>
    <div v-else class="useinfo">
      <div class="lef">
        <div class="cen userinfo_pic">
          <img :src="userGrade.headimgUrl" />
          <div v-if="userGrade.sex == 1" class="sex" style="color: white; background-color: rgb(74, 172, 253)"><i class="ri-men-line"></i></div>
          <div v-if="userGrade.sex == 0" class="sex" style="background-color: pink"><i class="ri-women-line"></i></div>
        </div>
        <div class="cen usname">{{ userdate.userName }}</div>
      </div>
      <div class="ref">
        <div class="cen">
          <i class="el-icon-medal"></i>
          &nbsp; 会员等级： {{ userGrade.userGradeDesc }}
        </div>
        <div class="cen">
          <i class="el-icon-phone-outline"></i>
          &nbsp; 电话号码：{{ userdate.phone }}
        </div>
        <div class="cen">
          <i class="el-icon-date"></i>
          &nbsp; 注册时间：{{ userdate.createTime }}
        </div>
        <!-- <div class="cen">
          <i class="ri-cake-2-line"></i>
          &nbsp; 生日日期：{{ userdate.userYear }}
        </div> -->
      </div>
      <div class="cen">
        <el-button type="">已注册</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { uploadURL } from '@/config'
import { queryPage, getUserInfo } from '@/api/mall/users/userLevel'
import { adminRegister } from '@/api/user'
import shareuser from './components/addmember.vue'
export default {
  components: {
    shareuser,
  },
  props: {
    uslist: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      listLoading: true,
      dialogVisible: false,
      options: [],
      shdemo: false,
      userdate: null,
      showhide: false,
      userGrade: null,
      ruleForm: {
        code: '9999', //验证码
        logoType: 5, //1-微信注册 2->密码注册 3->验证码注册 4 一键登录注册 5->邀请注册
        invitNumber: '', //邀请码
        sharerUserCode: '', //分销吗
        sex: '1',
        bindStatus: 0,
        userId: '0',
        id: this.uslist.id, //列表里面的ID
        recordId: this.uslist.id, //列表里面的ID
        phone: this.uslist.phone,
        password: '',
        userName: this.uslist.userName
      },
      action: uploadURL,
      hideUpload: false,
      headers: {},
      picUrls: '',
      fileList: [],
      hide: false,
      rules: {
        userName: [{ required: true, message: '请输入 姓名', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入 手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确' },
        ],
        password: [{ required: true, message: '请输入 密码', trigger: 'blur' }],
        passwords: [
          { required: true, message: '请输入 确认密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' },
        ],
        userGrade: [{ required: true, message: '请输入 会员等级', trigger: 'blur' }],
        pointsCurrent: [{ required: true, message: '请输入 积分', trigger: 'blur' }],
        withdrawBank: [{ required: true, message: '请输入提现银行', trigger: 'blur' }],
        nickName: [{ required: true, message: '请输入 昵称', trigger: 'blur' }],
        userYear: [{ required: true, message: '请选择 生日', trigger: 'blur' }],
      },
    }
  },

  computed: {
    ...mapGetters({
      token: 'user/token',
      tenantId: 'user/tenantId',
    }),
  },
  watch: {
    uslist(newValue, oldValue) {
      if (newValue != oldValue) {
        console.log(newValue)
        this.userdate = newValue
      }
    },
  },
  created() {
    console.log(this.uslist, '代客注册created')
    //====默认填充数据-lyj
    // this.ruleForm.userName = this.uslist.userName
    // this.ruleForm.phone=this.uslist.phone;
    //======
    if (this.uslist.bindStatus == 1) {
      this.shdemo = false
      this.userdate = this.uslist
      this.selectuser(this.uslist)
    } else {
      this.shdemo = true
    }
    this.headers['Authorization'] = `Bearer ${this.token}`
    this.headers['Switch-Tenant-Id'] = `${this.tenantId}`
    const user = this.$route.query.date
    if (user) {
      this.ruleForm.userName = user.userName
      this.ruleForm.phone = user.phone
      this.selectuser(user)
    }
    this.queryPage()
  },
  methods: {
    selectuser(row) {
      getUserInfo({ userCode: row.userId }).then((res) => {
        this.userGrade = res.data
        if (res.data != null) {
          this.ruleForm.userName = row.userName
          this.ruleForm.phone = row.phone
          this.ruleForm.userId = row.userId
          this.ruleForm.bindStatus = true
        }
        this.ruleForm.id = row.id
      })
    },
    userinfo(row) {
      this.showhide = false
      if (row) {
        this.$set(this.ruleForm, 'sharerUserCode', row.phone)
      }
    },
    queryPage() {
      queryPage({ size: 10, current: 1 }).then((res) => {
        this.options = res.data.records
      })
    },
    bfUpload(file) {
      console.log(file)
      if ('image/png' == file.type || 'image/jpeg' == file.type || 'image/jpg' == file.type) {
        console.log()
      } else {
        this.ME('图片上传失败,请上传png,jpeg,jpg格式')
        return false
      }
    },
    handleChange(file, fileList) {
      this.hideUpload = fileList.length >= this.limitCount
    },
    handlePictureCardPreview(file) {
      this.picUrls = file.url
      this.dialogVisible = true
    },
    handleRemove(file, fileList) {
      this.ruleForm.picUrls = []
      fileList.forEach((item) => {
        this.ruleForm.picUrls.push(item.url)
      })
    },
    handleSuccess(response) {
      this.$set(this.ruleForm, 'picUrls', response.data.filePath)
    },
    confirmPasswordValidator(rule, value, callback) {
      if (this.form.password === this.form.confirmPassword) {
        callback(true)
      } else {
        callback(false, '两次输入的密码不一致，请重新输入！')
      }
    },
    submit() {
      this.$refs.formName.validate((valid) => {
        if (valid) {
          delete this.ruleForm.passwords
          adminRegister({ ...this.ruleForm }).then((res) => {
            if (res.code == 0) {
              this.$baseEventBus.$emit('Qualityinspection')
              this.$baseEventBus.$emit('checktage', 1)
              this.$message.success('注册成功')
            } else {
              this.$message.error(res.msg)
            }
            this.ruleForm = {}
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ym_checkbtn {
  display: flex;
  justify-content: center;
}
::v-deep {
  .el-form {
    display: flex;
    flex-wrap: wrap;
  }
  .el-input--small {
    border: 1px solid #e5e5e5;
  }
  .el-select--small {
    width: 198px;
  }
}
.useinfo {
  width: 100%;
  display: flex;
  align-items: center;
  .lef,
  .ref {
    margin: 0 16px;
  }
  .ref {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: left;
    .cen {
      margin: 5px 0;
      justify-content: left;
    }
  }
  .cen {
    display: flex;
    justify-content: center;
  }
  .userinfo_pic {
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 10px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
    .sex {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 25px;
      height: 15px;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }
  }
  .usname {
    font-weight: bold;
    font-size: 1.25rem;
    width: 4em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
