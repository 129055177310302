var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "重置密码", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                "label-width": "100px",
                model: _vm.ruleForm,
                rules: _vm.rules,
                "status-icon": "",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "pass" } },
                [
                  _c("el-input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.ruleForm.pass,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "pass", $$v)
                      },
                      expression: "ruleForm.pass",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码", prop: "checkPass" } },
                [
                  _c("el-input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.ruleForm.checkPass,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "checkPass", $$v)
                      },
                      expression: "ruleForm.checkPass",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfirm("ruleForm")
                        },
                      },
                    },
                    [_vm._v(" 提交 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }