var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "div",
        {
          staticStyle: {
            height: "70vh",
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            "flex-direction": "column",
          },
        },
        [
          _c("h2", {
            staticClass: "el-icon-check",
            staticStyle: {
              color: "green",
              "font-size": "132px",
              padding: "20px",
              margin: "0",
            },
          }),
          _c("h2", [_vm._v(_vm._s(_vm.getStatus) + "成功！")]),
          _c("div", { staticStyle: { color: "#bbb" } }, [
            _vm._v("立即推广，获取更多流量"),
          ]),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "large", type: "primary" },
                  on: { click: _vm.handleExtension },
                },
                [_vm._v(" 立即推广 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "20px",
                display: "flex",
                "justify-content": "space-between",
                width: "150px",
                cursor: "pointer",
              },
            },
            [
              _c("div", { on: { click: _vm.continueCreate } }, [
                _vm._v("继续新建"),
              ]),
              _c("div", [_vm._v("|")]),
              _c("div", { on: { click: _vm.backToList } }, [
                _vm._v("返回列表"),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "推广二维码",
            visible: _vm.extensionVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.extensionVisible = $event
            },
            close: _vm.handleExtensionClose,
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "align-items": "center",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center",
                  },
                },
                [
                  _c("div", { ref: "qrCodeUrl", staticClass: "qrcode" }, [
                    _c("img", { attrs: { src: "" } }),
                  ]),
                ]
              ),
            ]
          ),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "align-items": "center",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.downloadQR },
                  },
                  [_vm._v(" 下载二维码 ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }