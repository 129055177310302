<template>
  <div class="comprehensive-table-container">
    <div
      style="
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      "
    >
      <h2
        class="el-icon-check"
        style="color: green; font-size: 132px; padding: 20px; margin: 0"
      ></h2>
      <h2>{{ getStatus }}成功！</h2>
      <div style="color: #bbb">立即推广，获取更多流量</div>
      <div style="margin-top: 20px">
        <el-button size="large" type="primary" @click="handleExtension">
          立即推广
        </el-button>
      </div>
      <div
        style="
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          width: 150px;
          cursor: pointer;
        "
      >
        <div @click="continueCreate">继续新建</div>
        <div>|</div>
        <div @click="backToList">返回列表</div>
      </div>
    </div>
    <el-dialog
      title="推广二维码"
      :visible.sync="extensionVisible"
      width="30%"
      @close="handleExtensionClose"
    >
      <div style="display: flex; flex-direction: column; align-items: center">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div ref="qrCodeUrl" class="qrcode">
            <img src="" />
          </div>
        </div>
      </div>
      <span slot="footer">
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <el-button size="small" type="primary" @click="downloadQR">
            下载二维码
          </el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import QRCode from 'qrcodejs2'
  export default {
    name: 'CreateRes',
    data() {
      return {
        actId: 0,
        instId: 0,
        goodsName: '',
        isAdd: false,
        status: '0',
        qrCodeUrl: '',
        extensionVisible: false,
        allActType: '',
      }
    },
    computed: {
      getStatus() {
        let str = ''
        if (this.status == 0 || this.status == 2) {
          str = '新增'
        } else if (this.status == 1) {
          str = '编辑'
        }
        return str
      },
    },
    created() {
      this.allActType = this.$route.query.allActType
      const { actId, status, instId } = this.$route.query
      this.actId = actId
      this.instId = instId
      this.status = status
    },
    methods: {
      handleExtensionClose() {},
      creatQrCode() {
        this.$refs.qrCodeUrl.innerHTML = '' //清除二维码方法一
        const text = this.qrCodeUrl
        new QRCode(this.$refs.qrCodeUrl, {
          text, //页面地址 ,如果页面需要参数传递请注意哈希模式#
          width: 200,
          height: 200,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
        // qrcode.clear() // 清除二维码方法二
      },
      downloadQR() {
        const img = document.querySelector('.qrcode img')
        const src = img.getAttribute('src')
        const a = document.createElement('a')
        a.setAttribute('href', src)
        a.setAttribute('download', 'qrcode.png')
        a.click()
        // console.log(img)
      },
      handleExtension() {
        // this.qrCodeUrl = `https://api.tjxun.com/wxm/instShare/index?instId=${this.instId}`

        this.qrCodeUrl =
          this.allActType == 20
            ? `https://api.tjxun.com/wxm/instShare/box/index?instId=${this.instId}`
            : `https://api.tjxun.com/wxm/instShare/index?instId=${this.instId}`
        this.extensionVisible = true
        this.$nextTick(() => {
          this.creatQrCode()
        })
      },
      continueCreate() {
        if (this.allActType == 20) {
          this.$router.replace({
            path: '/activity/indiana/add',
            query: {
              actId: this.$route.query.actId,
              status: 0,
              allActType: this.allActType,
            },
          })
        } else {
          this.$router.replace({
            path: '/activity/indiana/add',
            query: {
              actId: this.$route.query.actId,
              status: 0,
            },
          })
        }
      },
      backToList() {
        if (this.allActType == 20) {
          this.$router.replace({
            path: '/activity/box-list/list',
            query: {
              id: this.$route.query.actId,
              allActType: this.allActType,
            },
          })
        } else {
          this.$router.replace({
            path: '/activity/indiana/list',
            query: {
              id: this.$route.query.actId,
            },
          })
        }
      },
      openAddGoods() {
        this.$router.push({
          path: '/goods/add',
        })
      },
      openGoodsDetail() {
        if (this.id) {
          this.$router.push({
            path: '/goods/detail',
            query: { id: this.id, goodsName: this.goodsName },
          })
        }
      },
    },
  }
</script>
