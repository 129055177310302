<template>
  <div class="cy-container">
    <el-container style="width: 100%">
      <!-- 字典名称列表 -->
      <div ref="asideRef" class="asideAuthorizeUrl" style="
          width: 200px;
          height: 600px;
          margin-right: 5px;
          overflow: scroll;
          border: 1px solid #c0d4f0;
        ">
        <el-aside>
          <el-table class="aside-table" :data="menuTableData" row-key="id" style="width: 100%; margin-bottom: 20px"
            @row-click="clickAsideData">
            <el-table-column label="字典名称" prop="dictValue" />
          </el-table>
        </el-aside>
      </div>
      <!-- 列表收缩 -->
      <div class="clickLine">
        <i v-if="leftIcon" class="el-icon-caret-left clickLineLeft" @click="handleLeftIcon" />
        <i v-if="rightIcon" class="el-icon-caret-right clickLineRight" @click="handleRightIcon" />
      </div>
      <div ref="contantRef" style="width: 87%; overflow: scroll">
        <!-- 查询 -->
        <div class="mb10">
          <el-form :inline="true" :model="queryForm" @submit.native.prevent>
            <el-form-item>
              <el-input v-model.trim="queryForm.dictValue" clearable placeholder="字典名称" />
            </el-form-item>
            <el-form-item>
              <el-input v-model.trim="queryForm.dictType" clearable placeholder="字典类型" />
            </el-form-item>
            <el-form-item>
              <el-select v-model="queryForm.isDelete" clearable placeholder="字典状态">
                <el-option v-for="item in roleSelectList" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button icon="el-icon-search" type="primary" @click="handleQuery">
                查询
              </el-button>
              <el-button icon="el-icon-refresh-right" type="primary" @click="handleReset">
                重置
              </el-button>
            </el-form-item>
            <el-form-item v-if="showsed.shows != 1" style="float: right">
              <!-- <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
                新增
              </el-button>
              <el-button type="primary" @click="handleExport">导出</el-button>
              <el-button
                icon="el-icon-delete"
                plain
                type="danger"
                @click="handleDelete"
              >
                删除
              </el-button> -->
              <el-button v-for="item in searchButton" :key="item.id"
                :plain="item.cssType && item.cssType == 'plain' ? true : false" :type="item.buttonCss"
                @click.native="handleClickBtn(item)">
                <span v-if="!item.buttonIcon"></span>
                <vab-icon v-else :icon="item.buttonIcon" />
                {{ item.buttonName }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <!-- 列表table -->
        <div>
          <el-table ref="orgTableDataList" v-loading="listLoading" border :data="list" row-key="id"
            :tree-props="{ children: 'children' }" @selection-change="setSelectRows">
            <el-table-column show-overflow-tooltip type="selection" />
            <el-table-column label="字典名称" prop="dictValue" show-overflow-tooltip />
            <el-table-column align="center" label="字典值" prop="dictKey" show-overflow-tooltip />
            <el-table-column align="center" label="字典类型" prop="dictType" show-overflow-tooltip />
            <el-table-column align="center" label="字典状态" show-overflow-tooltip>
              <template #default="{ row }">
                <el-tag :type="row.isDelete === 0 ? 'success' : 'danger'">
                  {{ row.isDelete === 0 ? '正常' : '删除' }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="排序" prop="dictSort" show-overflow-tooltip width="80px" />
            <el-table-column align="center" label="创建时间" prop="createTime" show-overflow-tooltip>
              <template #default="{ row }">
                <div>
                  {{ row.createTime | formatDate }}
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="operationShow" label="操作" width="230px">
              <template #default="{ row }">
                <template v-for="item in listButton">
                  <el-button v-if="showListButton(row, item)" :key="item.id" :plain="item.cssType && item.cssType == 'plain' ? true : false
                    " size="mini" :type="item.buttonCss" @click.native="handleClickBtn(item, row)">
                    <span v-if="!item.buttonIcon"></span>
                    <vab-icon v-else :icon="item.buttonIcon" />
                    {{ item.buttonName }}
                  </el-button>
                </template>
                <!-- <el-button
                  icon="el-icon-edit-outline"
                  style="margin-right: 10px"
                  type="text"
                  @click="handleEdit(row)"
                >
                  编辑
                </el-button>
                <el-button
                  icon="el-icon-delete"
                  size="small"
                  style="margin-right: 10px"
                  type="text"
                  @click="handleDelete(row)"
                >
                  删除
                </el-button>
                <el-button
                  v-if="row.parentId == 0"
                  icon="el-icon-plus"
                  type="text"
                  @click="handleEditSon(row)"
                >
                  新增子级
                </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <el-pagination background :current-page="forms.current" :layout="layout" :page-size="forms.size" :total="total"
          @current-change="handleCurrentChange" @size-change="handleSizeChange" />
      </div>
    </el-container>
    <!-- 编辑、添加弹框 -->
    <el-dialog v-if="dialogFormVisible" :close-on-click-modal="false" :title="title" :visible.sync="dialogFormVisible"
      @close="close">
      <el-form ref="postForm" class="postForm" label-width="80px" :model="postForm" :rules="rules">
        <el-form-item label="字典名称" prop="dictValue">
          <el-input v-model.trim="postForm.dictValue" />
        </el-form-item>
        <el-form-item label="字典值" prop="dictKey">
          <el-input v-model.trim="postForm.dictKey" />
        </el-form-item>
        <el-form-item label="字典类型" prop="dictType">
          <el-input v-model.trim="postForm.dictType" :disabled="title === '编辑'" />
        </el-form-item>

        <el-form-item label="字典排序" prop="dictSort">
          <el-input-number v-model="postForm.dictSort" :min="1" placeholder="请输入" size="small" style="width: 100%" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="title === '新增' ? createData() : updateData()">
          确 定
        </el-button>
      </template>
    </el-dialog>
    <!-- 添加子级 -->
    <el-dialog v-if="dialogFormVisibleSon" :close-on-click-modal="false" title="添加子级"
      :visible.sync="dialogFormVisibleSon" @close="closeSon">
      <el-form ref="postFormSon" class="postForm" label-width="80px" :model="postFormSon" :rules="rules">
        <el-form-item label="字典名称" prop="dictValue">
          <el-input v-model.trim="postFormSon.dictValue" />
        </el-form-item>
        <el-form-item label="字典值" prop="dictKey">
          <el-input v-model.trim="postFormSon.dictKey" />
        </el-form-item>
        <!-- <el-form-item label="字典类型" prop="dictType">
          <el-input v-model.trim="postFormSon.dictType" />
        </el-form-item> -->

        <el-form-item label="字典排序" prop="dictSort">
          <el-input-number v-model="postFormSon.dictSort" :min="1" placeholder="请输入" size="small" style="width: 100%" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="closeSon">取 消</el-button>
        <el-button type="primary" @click="createDataSon()">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectSysDictMenu,
  queryPage,
  add,
  edit,
  isDelete,
} from '@/api/mall/role/dictionaryManage'
import { isNmberLetter } from '@/utils/validate'
import { selectRoleMenuButtonList } from '@/api/mall/common'
export default {
  props: {
    showsed: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    let checkKey = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('字典值不能为空'))
      } else if (!isNmberLetter(value)) {
        return callback(new Error('字典值只能输入英文或者数字'))
      } else {
        callback()
      }
    }
    return {
      operationShow: false,
      btnStatus: {
        0: ['handleEditSon'],
      },
      searchButton: [],
      listButton: [],
      dialogFormVisibleSon: false,
      postFormSon: {
        dictType: '',
      },
      listSlis: [],
      menuIdlist: [],
      leftIcon: true,
      rightIcon: false,
      menuTableData: [],
      roleSelectList: this.$dict.roleSelectList,
      buttonTypeList: this.$dict.buttonTypeList,
      actionTypeList: this.$dict.actionTypeList,
      rowId: '',
      orgsListTable: [],
      parentName: '',
      list: [
        {
          id: 1,
        },
      ],
      listLoading: false,
      current: 1,
      size: 10,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      selectRows: [],
      forms: { current: 1, size: 10 },
      isShow: true,
      queryForm: {
        menuId: '',
        parentId: '',
        dictType: '',
        dictKey: '',
        dictValue: '',
        isDelete: '',
      },
      title: '',
      dialogFormVisible: false,
      postForm: {},
      rules: {
        dictValue: [
          {
            required: true,
            message: '请输入字典名称',
            trigger: ['blur', 'change'],
          },
        ],
        dictKey: [
          {
            required: true,
            validator: checkKey,
            trigger: ['blur', 'change'],
          },
        ],
        dictType: [
          {
            required: true,
            message: '请输入字典类型',
            trigger: ['blur', 'change'],
          },
        ],
        dictSort: [
          {
            required: true,
            message: '请输入排序',
            trigger: ['blur', 'change'],
          },
        ],
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      checkedUrlList: [],
      rowIdSon: '',
      rowIdParent: '',
    }
  },
  created() {
    this.selectMenuTree()
    this.fetchData()
    this.selectRoleMenuButtonList()
  },
  methods: {
    // 按钮状态显示判断
    showListButton(row, item) {
      if (
        row.parentId != 0 &&
        this.btnStatus[0].indexOf(item.buttonUrl) != -1
      ) {
        return false
      } else {
        return true
      }
    },
    // 按钮点击
    handleClickBtn(item, row) {
      if (item.buttonUrl) {
        this[item.buttonUrl](row)
      }
    },
    // 按钮权限
    selectRoleMenuButtonList() {
      selectRoleMenuButtonList({
        roleIdStrList: this.$store.getters['acl/roleList'].length === 0 ? "" : this.$store.getters['acl/roleList'],
        menuId: this.$route.meta.id,
      })
        .then((res) => {
          if (res.data) {
            this.searchButton = res.data.filter(function (item) {
              return item.buttonType == 1
            })
            this.listButton = res.data.filter(function (item) {
              return item.buttonType == 2
            })
            if (this.listButton.length > 0) {
              this.operationShow = true
            } else {
              this.operationShow = false
            }
          }
        })
        .catch(() => { })
    },
    // 新增子级按钮
    handleEditSon(row) {
      this.rowIdSon = row.id
      this.postFormSon.dictType = row.dictType
      this.dialogFormVisibleSon = true
    },
    // 点击收缩
    handleLeftIcon() {
      this.$refs.asideRef.style.width = '10px'
      this.$refs.asideRef.style.minWidth = '10px'
      this.$refs.contantRef.style.width = '98%'
      this.leftIcon = false
      this.rightIcon = true
    },
    // 点击展开
    handleRightIcon() {
      this.$refs.asideRef.style.width = '200px'
      this.$refs.asideRef.style.minWidth = '200px'
      this.$refs.contantRef.style.width = '88%'
      this.leftIcon = true
      this.rightIcon = false
    },
    // 点击字典菜单查询
    clickAsideData(row) {
      this.forms.current = 1
      this.queryForm.parentId = row.id
      this.fetchData()
    },
    //读取字典菜单
    selectMenuTree() {
      this.listLoading = true
      selectSysDictMenu()
        .then((res) => {
          if (res.data) {
            this.menuTableData = res.data
          }
        })
        .catch(() => { })
      this.listLoading = false
    },
    // 字典列表
    async fetchData(options) {
      this.listLoading = true
      await queryPage({
        ...options,
        ...this.forms,
        parentId: this.queryForm.parentId,
      })
        .then((res) => {
          if (res.data) {
            this.list = res.data.records
            this.total = res.data.total
          }
        })
        .catch(() => { })
      this.listLoading = false
    },
    // 新增子级
    createDataSon() {
      this.$refs['postFormSon'].validate((valid) => {
        if (valid) {
          const parms = {
            dictType: this.postFormSon.dictType,
            dictKey: this.postFormSon.dictKey,
            dictValue: this.postFormSon.dictValue,
            dictSort: this.postFormSon.dictSort,
            parentId: this.rowIdSon,
          }
          add(parms).then(() => {
            this.MS('新增成功')
            this.closeSon()
            this.fetchData()
          })
        }
      })
    },
    // 新增
    createData() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          const parms = {
            dictType: this.postForm.dictType,
            dictKey: this.postForm.dictKey,
            dictValue: this.postForm.dictValue,
            dictSort: this.postForm.dictSort,
            parentId: 0,
          }
          add(parms).then(() => {
            this.MS('新增成功')
            this.close()
            this.fetchData()
          })
        }
      })
    },
    // 编辑
    updateData() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          const tempData = {}
          tempData.id = this.rowId
          tempData.dictType = this.postForm.dictType
          tempData.dictKey = this.postForm.dictKey
          tempData.dictValue = this.postForm.dictValue
          tempData.dictSort = this.postForm.dictSort
          tempData.parentId = this.rowIdParent
          edit(tempData)
            .then(() => {
              this.MS('编辑成功')
              this.close()
              this.fetchData()
            })
            .catch(() => { })
        }
      })
    },

    // 导出
    handleExport() { },
    // 新增/编辑
    handleEdit(row) {
      if (row) {
        this.showEdit(row)
      } else {
        this.showEdit()
      }
    },
    // 添加编辑title
    showEdit(row) {
      if (!row) {
        this.title = '新增'
      } else {
        this.rowId = row.id
        this.rowIdParent = row.parentId
        this.title = '编辑'
        this.postForm = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    // 关闭子级弹框
    closeSon() {
      this.dialogFormVisibleSon = false
      this.$refs['postFormSon'].resetFields()
      this.postFormSon = {}
    },
    // 关闭
    close() {
      this.dialogFormVisible = false
      this.$refs['postForm'].resetFields()
      this.postForm = {}
    },
    // 删除
    handleDelete(row) {
      if (row) {
        if (row.children != null) {
          return this.ME('此数据下有子级不可删除')
        }
        if (row.id) {
          this.$confirm(
            `${row.children == null
              ? '是否要删除此行?'
              : '删除此数据将删除整个下级岗位的数据,是否继续?'
            }`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
          )

            .then(() => {
              isDelete({ id: row.id, isDelete: row.isDelete })
                .then(() => {
                  this.MS('删除成功')
                  this.fetchData()
                  this.dialogFormVisible = false
                })
                .catch(() => { })
            })
            .catch(() => { })
        }
      } else {
        if (this.selectRows.length > 0) {
          const ids = this.selectRows.map((item) => item.id).join(',')
          console.log(ids)
          this.$confirm('是否要删除此行?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => { })
            .catch(() => { })
        } else {
          if (this.selectRows.length === 0) {
            this.ME('请勾选需要删除的记录')
            return
          }
        }
      }
    },
    // 列表选中
    setSelectRows(val) {
      this.selectRows = val
      this.$emit('multiple', val)
    },
    // 重置
    handleReset() {
      this.queryForm.parentId = ''
      this.forms.current = 1
      this.forms.size = 10
      this.queryForm = {}
      this.handleQuery()
    },
    // 搜索
    async handleQuery() {
      this.forms.current = 1
      await this.fetchData({ ...this.queryForm }, false)
      let temp = false
      Object.keys(this.queryForm).forEach((key) => {
        this.queryForm[key] && (temp = true)
      })
      this.isShow = temp
    },
    // 分页
    handleSizeChange(val) {
      this.forms.size = val
      let data = this.isShow === true ? this.queryForm : {}
      this.fetchData({ ...data }, false)
    },
    // 分页
    handleCurrentChange(val) {
      this.forms.current = val
      let data = this.isShow === true ? this.queryForm : {}
      this.fetchData({ ...data }, false)
    },
  },
}
</script>
<style lang="scss" scoped>
.classShows {
  max-height: 540px;
  overflow: auto;
}

.filter-btn {
  float: right;
}

.el-aside {
  width: 190px !important;
}

.asideAuthorizeUrl {

  // width: 200px;
  // height: 600px;
  // overflow: scroll;
  // border: 1px solid #c0d4f0;
  ::v-deep(.el-table) {
    th.is-leaf {
      padding: 15px 0;
      background: #fff;
    }
  }

  ::v-deep(.el-input) {
    width: 140px;
    padding: 10px;
  }

  ::v-deep .aside-table td,
  .aside-table th.is-leaf {
    // border: none;
    cursor: pointer;
  }

  ::v-deep(.aside-table th) {
    .cell {
      font-weight: 700 !important;
      color: #909399 !important;
    }
  }

  ::v-deep(.aside-table::before) {
    width: 0;
  }
}

.clickLine {
  width: 8px;
  height: 600px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: #c0d4f0;

  .clickLineLeft {
    position: relative;
    top: 50%;
    left: -3px;
    color: #97a8be;
    cursor: pointer;
  }

  .clickLineRight {
    position: relative;
    top: 50%;
    left: -5px;
    color: #97a8be;
    cursor: pointer;
  }
}

.filter-input {
  width: 150px;
}

.addDialog {
  .el-form-item__label {
    width: 90px;
    font-size: 14px;
    font-weight: 700;
  }

  .el-input {
    width: 90%;
  }

  .el-textarea {
    width: 90%;
  }

  ::v-deep(.el-form-item) {
    // width: 80%;
  }
}

.postForm {

  .el-select,
  .el-cascader {
    width: 100%;
  }
}

.contantAuthorizeUrl {
  width: 100%;
  padding: 0px 10px;
}
</style>
