<template>
  <div>
    <div style="border: 1px solid #ccc">
      <!-- 工具栏 -->
      <Toolbar
        :default-config="toolbarConfig"
        :editor="editor"
        style="border-bottom: 1px solid #ccc"
      />
      <!-- 编辑器 -->
      <Editor
        v-model="html"
        :default-config="editorConfig"
        :editor="editor"
        style="height: 500px"
        @onChange="onChange"
        @onCreated="onCreated"
      />
    </div>
  </div>
</template>

<script>
  import { Boot } from '@wangeditor/editor'
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  import formulaModule from '@wangeditor/plugin-formula'
  import { getToken } from '@/utils/token'
  import { baseURL } from '@/config'
  Boot.registerModule(formulaModule)

  export default {
    name: 'MyEditor',
    components: { Editor, Toolbar },
    props: {
      value: {
        type: String,
        default: () => {},
      },
    },
    data() {
      return {
        editor: null,
        html: '',
        toolbarConfig: {
          // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
          excludeKeys: [
            /* 隐藏哪些菜单 */
            // 'headerSelect',
            'fontSize',
            // 'insertImage','insertVideo'
          ],
          insertKeys: {
            index: 0,
            keys: [
              'insertFormula', // “插入公式”菜单
              'fullScreen'
              // 'editFormula' // “编辑公式”菜单
            ],
          },
        },
        editorConfig: {
          MENU_CONF: {
            uploadImage: {
              fieldName: 'file',
              server: baseURL+'/upms/file/uploadWangEditorImage',
              maxFileSize: 1 * 1024 * 1024, // 1M
              maxNumberOfFiles: 10,
              allowedFileTypes: ['image/*'],
              meta: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${getToken()}`,
                'Switch-Tenant-Id': '1',
                dir: 'editor/',
                fileType: 'file',
              },
              metaWithUrl: false,
              headers: {
                Authorization: `Bearer ${getToken()}`,
                'Switch-Tenant-Id': '1',
                'Access-Control-Allow-Origin':'*',
              },
              withCredentials: true,
              timeout: 5 * 1000, // 5 秒
              //进度
              onProgress(progress) {
                // JS 语法
                // progress 是 0-100 的数字
                console.log('progress', progress)
              },
              //成功
              onSuccess(file, res) {
                // JS 语法
                console.log(`${file.name} 上传成功`, res)
              },
              //单文件上传错误
              onFailed(file, res) {
                // JS 语法
                console.log(`${file.name} 上传失败`, res)
              },
              //上传拦截
              onError(file, err, res) {
                // JS 语法
                console.log(`${file.name} 上传出错`, err, res)
              },
            },
          },
        },
        // hoverbarKeys: {
        //   formula: {
        //     menuKeys: ['editFormula'], // “编辑公式”菜单
        //   },
        // },
        // },
      }
    },
    watch: {
      value(newValue) {
        this.html = newValue
      },
    },
    created() {
      // this.header['Switch-Tenant-Id'] = `1`
      this.html=this.value
    },
    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁 editor ，重要！！！
    },
    methods: {
      onCreated(editor) {
        this.editor = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
      },
      onChange(editor) {
        this.$emit('wang', editor.getHtml())
      },
      getEditorText() {
        const editor = this.editor
        if (editor == null) return
        console.log(editor.getText()) // 执行 editor API
      },
    },
  }
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
