<template>
  <div
    class="container"
    :style="{
      background: controls[index].pageBgColor,
    }"
  >
    <div
      class="list-navigation"
      :style="{
        background: controls[index].componentBgColor,
        borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
        padding: `${controls[index].margin.top}px ${controls[index].margin.both}px ${controls[index].margin.bottom}px  ${controls[index].margin.both}px`,
      }"
    >
      <div
        v-for="(ym, ind) in controls[index].data"
        :key="ind"
        class="navigation-item"
        :style="{
          background: controls[index].style.bgColor,
        }"
      >
        <div
          class="list"
          :style="{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 'auto',
            height: controls[index].style.contHeight + 'px',
            borderBottom: '1px solid' + controls[index].style.lineColor,
            width: `calc(100% - ${controls[index].style.pgboth * 2}px)`,
          }"
        >
          <div class="lef">
            <div v-if="controls[index].isShow" class="navigation-icon">
              <img alt="" :src="ym.iconUrl" />
            </div>
            <div
              class="navigation-txt"
              :style="{
                fontSize: controls[index].style.fontSize + 'px',
                color: controls[index].style.textColor,
                fontWeight: controls[index].style.fontWeight ? 'bold' : 'normal',
              }"
            >
              {{ ym.text }}
            </div>
          </div>
          <div class="ref">
            <vab-icon icon="arrow-right-s-line" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    index: {
      type: Number,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      controls: (state) => state.checkvalue,
    }),
  },
}
</script>

<style lang="scss" scoped>
.container {
  .list-navigation {
    overflow: hidden;
    .navigation-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      &:last-of-type {
        border-bottom: none;
      }
      .lef {
        display: flex;
        align-items: center;
        .navigation-icon {
          width: 16px;
          height: 16px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>