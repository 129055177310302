<template>
  <div class="ComplateMessges">
    <el-dialog
      v-if="dialogFormVisible"
      center
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="70%"
      @close="close"
    >
      <el-container style="width: 100%">
        <!-- 分类 -->
        <div
          ref="asideRef"
          class="asideAuthorizeUrl"
          style="
            width: 200px;
            height: 800px;
            overflow: scroll;
            margin-right: 5px;
            border: 1px solid #c0d4f0;
          "
        >
          <el-aside style="width: auto">
            <el-table
              border
              :data="list"
              :highlight-current-row="true"
              lazy
              :load="load"
              :max-height="800"
              row-key="id"
              style="width: 100%; margin-bottom: 20px"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @row-click="clickAsideData"
            >
              <el-table-column label="分类" prop="catName" />
            </el-table>
          </el-aside>
        </div>
        <div ref="contantRef" style="width: 87%; overflow: scroll">
          <!-- 查询 -->
          <el-form :inline="true" :model="queryForm" @submit.native.prevent>
            <el-form-item label="商品编号">
              <el-input
                v-model.trim="queryForm.skuCode"
                clearable
                placeholder="商品编号"
              />
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input
                v-model.trim="queryForm.goodsName"
                clearable
                placeholder="商品名称"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-search"
                type="primary"
                @click="handleQuery"
              >
                查询
              </el-button>
              <el-button
                icon="el-icon-refresh-right"
                type="primary"
                @click="handleReset"
              >
                重置
              </el-button>
            </el-form-item>
          </el-form>
          <!-- 商品列表 -->
          <el-table
            ref="multipleTable"
            v-loading="listLoading"
            border
            :data="tableData"
            row-key="id"
            tooltip-effect="dark"
            :tree-props="{ children: 'children' }"
            @selection-change="setSelectRows"
          >
            <el-table-column
              prop="id"
              :reserve-selection="true"
              show-overflow-tooltip
              type="selection"
              width="40"
            />
            <el-table-column
              label="商品编号"
              prop="skuCode"
              show-overflow-tooltip
            />

            <el-table-column
              label="商品名称"
              prop="goodsName"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              label="库存"
              prop="goodsStock"
              show-overflow-tooltip
            />

            <el-table-column
              align="center"
              label="计量单位"
              prop="unitName"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              label="商品品牌"
              prop="brandName"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              label="商品标签"
              prop="labelName"
              show-overflow-tooltip
            />
          </el-table>
          <!-- 分页 -->
          <el-pagination
            background
            :current-page="forms.currentPage"
            :layout="layout"
            :page-size="forms.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
          <!-- 商品规格 -->
          <el-table
            ref="tableDataSize"
            border
            class="mt20"
            :data="tableDataSize"
            row-key="id"
            @selection-change="setSelectSize"
          >
            <el-table-column
              prop="id"
              :reserve-selection="true"
              show-overflow-tooltip
              type="selection"
              width="40"
            />
            <el-table-column
              label="规格类型"
              prop="specsTitle"
              show-overflow-tooltip
            >
              <div>{{ specsTitle }}</div>
            </el-table-column>

            <el-table-column
              label="规格名称"
              prop="specsName"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              label="单位"
              prop="unitName"
              show-overflow-tooltip
            />
          </el-table>
        </div>
      </el-container>
      <template #footer>
        <el-button type="primary" @click="close">取消</el-button>
        <el-button type="primary" @click="save">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import {
    queryDepotPage,
    goodsSpecs,
  } from '@/api/mall/outin/storage/inputStorage'
  import { getGoodsList, getGoodsSubList } from '@/api/mall/goods/goodsCategory'
  export default {
    name: 'Add',
    data() {
      return {
        specsTitle: '', //规格类型
        tableDataSize: [], //规格列表
        list: [], //分类列表
        title: '选择商品',
        form: {},
        dialogFormVisible: false,
        queryFormTop: {
          currentPage: 1,
          pageSize: 100,
        },
        listLoading: false,
        forms: { currentPage: 1, pageSize: 10 },
        isShow: true,
        queryForm: {
          skuCode: '',
          goodsName: '',
          categoryId: '',
        },
        tableData: [], //商品列表
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        multipleSelection: [], //商品列表选中
        multipleSize: [], //规格列表选中
        goodsIndex: '',
      }
    },
    created() {},
    methods: {
      // 点击分类列表查询商品
      async clickAsideData(row) {
        this.forms.currentPage = 1
        this.queryForm.categoryId = row.id
        // this.fetchData()
        this.tableDataSize = []
        this.specsTitle = ''
        await this.fetchData({ ...this.queryForm })
      },
      // 规格选中
      setSelectSize(val) {
        if (val && val.length > 1) {
          this.$refs.tableDataSize.clearSelection()
          this.$refs.tableDataSize.toggleRowSelection(val.pop())
        } else {
          this.multipleSize = val
        }
      },
      // 商品列表选中
      setSelectRows(val) {
        if (val && val.length > 1) {
          this.$refs.multipleTable.clearSelection()
          this.$refs.multipleTable.toggleRowSelection(val.pop())
        } else {
          this.multipleSelection = val
          this.fetchDataSpecs()
        }
      },
      // 分类列表
      async fetchDataLeft() {
        this.listLoading = true
        let {
          data: { records },
        } = await getGoodsList(this.queryFormTop)
        records = records
          .sort((a, b) => {
            return b.sortNumber - a.sortNumber
          })
          .map((item) => {
            return {
              ...item,
              deep: 1,
              hasChildren: true,
              children: [],
            }
          })
        // this.total = total
        this.list = records
        this.listLoading = false
      },
      load(tree, treeNode, resolve) {
        const id = tree.id
        const deep = tree.deep + 1
        const parentName = tree.catName
        let data = []
        if (typeof id === 'number' && id > 0) {
          getGoodsSubList({ parentId: id })
            .then((res) => {
              data = res.data.map((item) => ({
                ...item,
                deep,
                hasChildren: deep === 3 ? false : true,
                children: [],
                parentName,
              }))

              resolve(data)
            })
            .catch(() => {})
        }
      },
      // 弹框
      showEdit(row) {
        // this.goodsIndex = index
        console.log(row)
        this.$nextTick(() => {
          this.fetchDataLeft()
        })
        this.fetchData()
        this.dialogFormVisible = true
      },
      // 弹框关闭
      close() {
        this.dialogFormVisible = false
      },
      // 确认
      save() {
        if (this.multipleSelection.length == 0) return this.ME('请选择商品')
        if (this.multipleSize.length == 0) return this.ME('请选择商品规格')
        this.$emit(
          'change',
          this.multipleSelection,
          this.multipleSize
          // this.goodsIndex
        )
        this.dialogFormVisible = false
      },
      // 规格列表
      async fetchDataSpecs() {
        await goodsSpecs({
          proGoodsId: this.multipleSelection[0].id,
        })
          .then((res) => {
            if (res.data) {
              this.tableDataSize = res.data.goodsStockSpecsVos
              this.specsTitle = res.data.specsTitle
            }
          })
          .catch(() => {})
      },
      // 商品列表
      async fetchData(options) {
        this.listLoading = true
        await queryDepotPage({
          ...options,
          ...this.forms,
        })
          .then((res) => {
            if (res.data) {
              this.tableData = res.data.records
              this.total = res.data.total
            }
          })
          .catch(() => {})
        this.listLoading = false
      },
      // 重置
      handleReset() {
        this.forms.currentPage = 1
        this.forms.pageSize = 10
        this.queryForm = {}
        this.handleQuery()
      },
      // 搜索
      async handleQuery() {
        this.forms.currentPage = 1
        await this.fetchData({ ...this.queryForm }, false)
        let temp = false
        Object.keys(this.queryForm).forEach((key) => {
          this.queryForm[key] && (temp = true)
        })
        this.isShow = temp
      },
      // 分页
      handleSizeChange(val) {
        this.forms.pageSize = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
      // 分页
      handleCurrentChange(val) {
        this.forms.currentPage = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-form {
    width: 80%;
  }
</style>
