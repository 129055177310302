var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "thead_tree" },
      [
        _c(
          "el-timeline",
          _vm._l(_vm.list, function (ym, index) {
            return _c(
              "el-timeline-item",
              {
                key: index,
                attrs: { placement: "top", timestamp: ym.createTime },
              },
              [
                _c(
                  "el-card",
                  [
                    _c("h4", [
                      _c("span", { staticStyle: { color: "#1786ff" } }, [
                        _vm._v(_vm._s(ym.realName)),
                      ]),
                      _vm._v(" " + _vm._s(ym.title) + " "),
                    ]),
                    _c("p", [_vm._v(_vm._s(ym.remark))]),
                    ym.fileUrl.length > 0
                      ? _c("el-image", {
                          staticStyle: { width: "60px", height: "60px" },
                          attrs: {
                            "preview-src-list": ym.fileUrl,
                            src: ym.fileUrl[0],
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
    _vm.showhide != 4
      ? _c(
          "div",
          { staticClass: "t-body" },
          [
            _c(
              "el-form",
              { attrs: { model: _vm.subform } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "描述备注" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入内容", type: "textarea" },
                      model: {
                        value: _vm.subform.remark,
                        callback: function ($$v) {
                          _vm.$set(_vm.subform, "remark", $$v)
                        },
                        expression: "subform.remark",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submit(3)
                          },
                        },
                      },
                      [_vm._v("提交")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }