<template>
  <div class="comprehensive-table-container">
    <el-table border :data="data" max-height="600">
      <el-table-column
        align="center"
        label="序号"
        type="index"
        width="55"
      />
      <el-table-column
        align="center"
        label="用户"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="连续天数"
        prop="continuDays"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="累计天数"
        prop="cumulateDays"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="最后签到时间"
        prop="updateTime"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="操作">
        <template #default="scope">
        <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="showview(scope.row)">查看</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"/>
      <el-dialog
        :before-close="handleClose"
        title="查看"
        :visible.sync="dialogVisible"
        width="60%">
        <span>
          <el-descriptions border :column="2" title="">
            <el-descriptions-item label="用户：">kooriookami</el-descriptions-item>
            <el-descriptions-item label="连续天数：">{{dialorow.continuDays}}</el-descriptions-item>
            <el-descriptions-item label="累计天数：">{{dialorow.cumulateDays}}</el-descriptions-item>
            <el-descriptions-item label="创建时间：">{{dialorow.createTime}}</el-descriptions-item>
            <el-descriptions-item label="最后签到时间：">{{dialorow.updateTime}}</el-descriptions-item>
          </el-descriptions>
        </span>
      </el-dialog>
  </div>
</template>

<script>
  import {getDetail} from "@/api/mall/users/signRecord"
  export default {
    data() {
      return {
        data: [],
        total:0,
        dialogVisible:false,
        form:{
          size:10,
          current:1
        },
        dialorow:{}
      }
    },
    created(){
      this.getDetail()
    },
    methods:{
      getDetail(){
        getDetail({...this.form}).then(res=>{
          console.log(res.data.records)
          this.data=res.data.records
          console.log(res.data)
        })
      },
      handleCurrentChange(e){
        this.form.current=e
        this.getDetail()
      },
      handleSizeChange(e){
        this.form.size=e
        this.getDetail()
      },
      showview(row){
        this.dialorow=row
        this.dialogVisible=true
      }
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header{
  border-bottom: 1px solid #e5e5e5;
}
</style>
