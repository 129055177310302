<template>
  <el-dialog :before-close="handleClose" title="新增家族病史" :visible.sync="dialogVisible" width="50%">
    <span>
      <el-form ref="ruleForm" class="demo-ruleForm" label-width="100px" :model="ruleForm" :rules="rules">
        <el-form-item label="疾病名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入 疾病名称" />
        </el-form-item>
        <el-form-item label="亲属关系" prop="fitType">
          <el-select v-model="ruleForm.fitType" placeholder="请选择 亲属关系">
            <el-option v-for="(ym, index) in labType" :key="index" :label="ym.labelName" :value="ym.id" />
          </el-select>
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { userFitnessInfoadd } from '@/api/mall/users/usermore'
export default {
  props: {
    show: {
      type: Boolean,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        fitNum: [{ required: true, message: '请输入 体检编号', trigger: 'blur' }],
        phyTime: [{ type: 'string', required: true, message: '请选择 确诊时间', trigger: 'change' }],
        fitType: [{ required: true, message: '请选择 亲属关系', trigger: 'change' }],
        name: [{ required: true, message: '请输入 疾病名称', trigger: 'blur' }],
      },
      dialogVisible: false,
      uphide: false,
      labType: [
        {
          id: 1,
          labelName: '配偶',
        },
        {
          id: 2,
          labelName: '儿子',
        },
        {
          id: 3,
          labelName: '女儿',
        },
        {
          id: 4,
          labelName: '孙子',
        },
        {
          id: 5,
          labelName: '孙女',
        },
        {
          id: 6,
          labelName: '外孙子',
        },
        {
          id: 7,
          labelName: '外孙女',
        },
        {
          id: 8,
          labelName: '父母',
        },
        {
          id: 9,
          labelName: '兄长',
        },
        {
          id: 10,
          labelName: '弟弟',
        },
        {
          id: 11,
          labelName: '姐姐',
        },
        {
          id: 12,
          labelName: '妹妹',
        },
        {
          id: 13,
          labelName: '其他',
        },
      ],
    }
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue != oldValue) {
        this.dialogVisible = newValue
        this.uphide = newValue
        this.$forceUpdate()
      }
    },
  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          userFitnessInfoadd({ userId: this.value.userId, fitStatus: 4, ...this.ruleForm }).then((res) => {
            if (res.status == 200) {
              this.ruleForm = {
                fitUrl: [],
              }
              this.$emit('addshows', false)
            }
          })
        } else {
          return false
        }
      })
    },
    handleClose() {
      this.$emit('addshows', false)
      this.$refs.ruleForm.resetFields()
    },
    check(e) {
      if (e.url) {
        this.ruleForm.fitUrl.forEach((item, index) => {
          if (item == e.url) {
            this.ruleForm.fitUrl.splice(index, 1)
          }
        })
      } else {
        this.ruleForm.fitUrl.push(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
  .el-select,
  .el-date-editor,
  .input__inner {
    width: 100%;
  }
}
</style>