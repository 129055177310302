<template>
  <div class="cy-container">
    <!-- 搜索 -->
    <div style="display: flex; flex-wrap: wrap">
      <div class="title-item">
        <span>实例名称</span>
        <el-input
          v-model.trim="formInline.instName"
          class="title-input"
          placeholder="请输入活动名称"
        />
      </div>
      <div class="title-item">
        <span>实例状态</span>
        <el-select
          v-model.trim="formInline.instStatus"
          class="title-input"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in indianaStatus"
            :key="index"
            :label="item.dictValue"
            :value="item.dictKey.toString()"
          />
        </el-select>
      </div>
      <div class="title-item">
        <span>实例时间</span>
        <el-date-picker
          v-model="datePicker"
          align="center"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="dateChange"
        />
      </div>
      <div class="title-item">
        <el-button icon="el-icon-search" type="warning" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          type="primary"
          @click="handleReset"
        >
          重置
        </el-button>
      </div>
    </div>
    <div style="margin: 20px 0">
      <el-button size="small" type="primary" @click="handleAdd">
        创建实例
      </el-button>
    </div>
    <!-- 表格 -->
    <el-table border :data="list" :max-height="500" style="width: 100%">
      <el-table-column align="center" label="实例名称" prop="instName" />
      <el-table-column align="center" label="实例封面" prop="instLogo">
        <template #default="{ row }">
          <el-image
            fit="fill"
            :src="row.instLogo"
            style="cursor: pointer"
            @click="handlePicPreview(row.instLogo)"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="实例样式" prop="instStyle">
        <template #default="{ row }">
          <div>{{ getInstStyle(row.instStyle) }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="实例期数" prop="totalActCount" />
      <el-table-column align="center" label="当前期数" prop="actCount" />
      <el-table-column align="center" label="实例时间" min-width="150px">
        <template #default="{ row }">
          <div>开始：{{ row.startDate | parseTime }}</div>
          <div>结束：{{ row.endDate | parseTime }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="发布时间" prop="publishTime">
        <template #default="{ row }">
          <div>{{ row.publishTime | parseTime }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="参与人数" prop="totalActCount" />
      <el-table-column align="center" label="状态" prop="instStatus">
        <template #default="{ row }">
          <div>{{ getIndianaStatus(row.instStatus) }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="220px">
        <template #default="{ row }">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div
              v-for="item in row.btns"
              v-show="item.isShow && item.buttonStatus == 0"
              :key="item.id"
            >
              <el-dropdown
                v-if="item.children && item.children.length"
                style="margin: 0 5px"
                @command="
                  (index) => {
                    handleClickBtn(item.buttonUrl, index, item.children, row)
                  }
                "
              >
                <el-button :type="item.buttonCss">
                  {{ item.buttonName }}
                  <vab-icon :icon="item.buttonIcon || ''" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <div v-for="item2 in item.children" :key="item2.id">
                    <el-dropdown-item
                      v-if="
                        !(
                          item2.buttonUrl === 'handleClose' &&
                          row.instStatus == 60
                        )
                      "
                      :command="item2.id"
                    >
                      {{ item2.buttonName }}
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                v-else
                size="mini"
                style="margin: 0 5px"
                :type="item.buttonCss"
                @click="handleClickBtn(item.buttonUrl, row)"
              >
                <vab-icon :icon="item.buttonIcon || ''" />
                {{ item.buttonName }}
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      :current-page="queryForm.currentPage"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 预览 -->
    <el-dialog title="图片详情" :visible.sync="imgDetailVisible" width="50%">
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <el-image fit="fill" :src="imgUrl" />
      </div>
    </el-dialog>
    <!-- 推广二维码 -->
    <el-dialog
      title="推广二维码"
      :visible.sync="extensionVisible"
      width="30%"
      @close="handleExtensionClose"
    >
      <div style="display: flex; flex-direction: column; align-items: center">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div ref="qrCodeUrl" class="qrcode">
            <img src="" />
          </div>
        </div>
      </div>
      <span slot="footer">
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <el-button size="small" type="primary" @click="downloadQR">
            下载二维码
          </el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { selectSysDict } from '@/api/mall/common'
  import { checkButton } from '@/utils/mixins'
  import QRCode from 'qrcodejs2'
  import { getIndianaList, closeIndiana } from '@/api/activity/indiana'
  import { parseTime } from '@/utils'
  export default {
    name: 'BoxList',
    filters: {
      parseTime,
    },
    mixins: [checkButton],
    data() {
      return {
        activeName: '0',
        listLoading: true,
        instStyle: [
          {
            dictValue: '盲盒',
            dictKey: 20,
          },
        ],
        indianaStatus: [],
        datePicker: [],
        id: 0,
        isSearch: false,
        userIsSearch: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        imgUrl: '',
        imgDetailVisible: false,
        extensionVisible: false,
        formInline: {
          instName: '',
          instStatus: '',
          startDate: '',
          endDate: '',
        },
        total: 0,
        queryForm: {
          currentPage: 1,
          pageSize: 10,
        },
        list: [],
        allActType: '', //活动类型/实例样式
      }
    },

    created() {
      this.id = this.$route.query.id
      this.allActType = this.$route.query.allActType
      this.fetchData(this.formInline)
    },
    activated() {
      this.id = this.$route.query.id
      this.allActType = this.$route.query.allActType
      this.fetchData(this.formInline)
    },
    methods: {
      creatQrCode() {
        this.$refs.qrCodeUrl.innerHTML = '' //清除二维码方法一
        const text = this.qrCodeUrl
        new QRCode(this.$refs.qrCodeUrl, {
          text, //页面地址 ,如果页面需要参数传递请注意哈希模式#
          width: 200,
          height: 200,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
        // qrcode.clear() // 清除二维码方法二
      },
      downloadQR() {
        const img = document.querySelector('.qrcode img')
        const src = img.getAttribute('src')
        const a = document.createElement('a')
        a.setAttribute('href', src)
        a.setAttribute('download', 'qrcode.png')
        a.click()
        // console.log(img)
      },
      // 推广
      handleExtension(row) {
        // this.qrCodeUrl = `https://api.tjxun.com/wxm/instShare/index?instId=${row.id}`
        this.qrCodeUrl =
          this.allActType == 20
            ? `https://api.tjxun.com/wxm/instShare/box/index?instId=${row.id}`
            : `https://api.tjxun.com/wxm/instShare/index?instId=${row.id}`
        this.extensionVisible = true
        this.$nextTick(() => {
          this.creatQrCode()
        })
      },
      handleExtensionClose() {},
      // 实例封面预览
      handlePicPreview(url) {
        this.imgUrl = url
        this.imgDetailVisible = true
      },
      getDicValue(key, val) {
        let str = ''
        let obj = this[key].find((item) => item.dictKey == val)
        if (obj) {
          str = obj.dictValue
        }
        return str
      },
      // 开盒记录
      handleOpenRecord(row) {
        this.$router.push({
          path: '/activity/openbox-record/record',
          query: {
            actId: this.id,
            instId: row.id,
          },
        })
      },

      // 参与明细
      handleJoinDetail(row) {
        this.$router.push({
          path: '/activity/boxjoin-detail/detail',
          query: {
            actId: this.id,
            instId: row.id,
          },
        })
      },
      // 查看详情
      handleDetail(row) {
        // this.$router.push({
        //   path: '/activity/box-detail/detail',
        //   query: {
        //     actId: this.id,
        //     instId: row.id,
        //   },
        // })
        this.$router.push({
          path: '/activity/indiana/detail',
          query: {
            actId: this.id,
            instId: row.id,
          },
        })
      },
      // 更多
      handleMore(id, btnList, row) {
        let obj = btnList.find((item) => item.id == id)
        this[obj.buttonUrl](row)
      },
      // 实例样式
      getInstStyle(val) {
        let str = ''
        let obj = this.instStyle.find((item) => item.dictKey == val)
        if (obj) {
          str = obj.dictValue
        }
        return str
      },
      // 状态
      getIndianaStatus(val) {
        let str = ''
        let obj = this.indianaStatus.find((item) => item.dictKey == val)
        if (obj) {
          str = obj.dictValue
        }
        return str
      },
      // 创建实例
      handleAdd() {
        this.$router.push({
          path: '/activity/indiana/add',
          query: {
            actId: this.id,
            status: 0,
            endTime: this.$route.query.endTime,
            startTime: this.$route.query.startTime,
            allActType: this.allActType,
          },
        })
      },
      // 复制
      handleCopy(row) {
        this.$router.push({
          path: '/activity/indiana/add',
          query: {
            actId: this.id,
            status: 2,
            instId: row.id,
            endTime: this.$route.query.endTime,
            startTime: this.$route.query.startTime,
            allActType: this.allActType,
          },
        })
      },
      // 编辑
      handleEdit(row) {
        this.$router.push({
          path: '/activity/indiana/add',
          query: {
            actId: this.id,
            status: 1,
            instId: row.id,
            endTime: this.$route.query.endTime,
            startTime: this.$route.query.startTime,
            instStatus: row.instStatus,
            allActType: this.allActType,
          },
        })
      },
      // 关闭
      handleClose(row) {
        const { id } = row
        this.$confirm('确定要关闭此活动吗？', '提示', {
          type: 'warning',
        }).then(() => {
          closeIndiana({ id }).then(() => {
            this.$message.success('关闭成功！')
            this.handleReset()
          })
        })
      },
      openLuckydraw(row) {
        this.$router.push({
          path: '/activity/indiana/list',
          query: {
            id: row.id,
          },
        })
      },
      // 新增活动
      openAddActive() {
        this.$router.push({
          path: '/activity/add',
          query: {
            status: 0,
          },
        })
      },
      // 重置
      handleReset() {
        this.formInline = {
          instName: '',
          instStatus: '',
          startDate: '',
          endDate: '',
        }
        this.datePicker = []
        this.handleSearch()
      },
      // 搜索
      async handleSearch() {
        let queryForm = this.queryForm
        let formInline = this.formInline
        let temp = this.fetchData
        let tag = 'isSearch'
        queryForm.currentPage = 1
        await temp({ ...formInline }, false)
        let t = false
        Object.keys(formInline).forEach((key) => {
          formInline[key] && (t = true)
        })
        this[tag] = t
      },
      // 列表
      async fetchData(options = {}) {
        this.listLoading = true
        const {
          data: { records: list, total },
        } = await getIndianaList({
          ...this.queryForm,
          ...options,
          actId: this.id,
        })
        this.handleAddTableButtonRole(list, {})
        const dicData = await selectSysDict({
          dictTypeList: ['instanceStatus'],
        })
        this.indianaStatus = dicData.data[0].dictInfoVoList
        this.list = list
        this.total = total
      },
      // 分頁
      handleSizeChange(val) {
        let queryForm = this.queryForm
        let formInline = this.formInline
        let temp = this.fetchData
        let tag = 'isSearch'
        queryForm.pageSize = val
        let data = this[tag] === true ? formInline : {}
        temp({ ...data }, false)
      },
      // 分頁
      handleCurrentChange(val) {
        let queryForm = this.queryForm
        let formInline = this.formInline
        let temp = this.fetchData
        let tag = 'isSearch'
        queryForm.currentPage = val
        let data = this[tag] === true ? formInline : {}
        temp({ ...data }, false)
      },
      // 時間
      dateChange(date) {
        let obj = null
        obj = this.formInline
        if (date && date.length) {
          obj.startDate = date[0]
          obj.endDate = date[1]
        } else {
          obj.startDate = ''
          obj.endDate = ''
        }
      },
    },
  }
</script>

<style scoped>
  .title-input {
    width: 220px;
    margin-left: 5px;
  }
  .title-item {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title-item span {
    width: 100px;
    text-align: center;
  }
  .qrcode {
    display: inline-block;
  }
  .qrcode img {
    width: 132px;
    height: 132px;
    background-color: #fff;
    padding: 6px;
    box-sizing: border-box;
  }
</style>
