<template>
  <div class="container">
    <el-form ref="form" :inline="true" label-width="80px" :model="form">
      <el-form-item label="会员信息">
        <el-input v-model="form.phone" placeholder="请输入 会员信息" />
      </el-form-item>
      <el-form-item label="计划状态">
        <el-select v-model="form.mealStatus" placeholder="请选择 计划状态">
          <el-option v-for="(ym, index) in sel" :key="index" :label="ym.labelName" :value="ym.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-button icon="el-icon-search" type="primary" @click="queryInfo">搜索</el-button>
        <el-button icon="el-icon-refresh" type="warning" @click="resetinfo">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tableData" style="width: 100%">
      <el-table-column align="center" label="会员名称" prop="nickName" />
      <el-table-column align="center" label="手机号码" prop="phone" />
      <el-table-column align="center" label="开始时间" prop="createTime" />
      <el-table-column align="center" label="执行状况" prop="mealStatus">
        <template #default="scope">
          <span v-if="scope.row.mealStatus == 0">进行中</span>
          <span v-if="scope.row.mealStatus == 1">未执行</span>
          <span v-if="scope.row.mealStatus == 2">已结束</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" prop="address">
        <template #default="scope">
          <el-button type="text" @click="handleStop(scope.row)">停止</el-button>
          <el-button type="text" @click="handshow(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="form.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <el-dialog :before-close="handleClose" title="执行详情" :visible.sync="dialogVisible" width="50%">
      <span>
        <div class="headertop">
          计划名称：{{ moreshow.planName }}&nbsp;&nbsp;&nbsp;&nbsp;计划类型：{{ moreshow.planTypeDesc }}&nbsp;&nbsp;&nbsp;&nbsp;状态：
          <span :style="{ color: moreshow.mealStatus == 0 ? '#007aff' : 'rgb(249,174,79)' }">{{ fleType(moreshow.mealStatus) }}</span>
          &nbsp;&nbsp;&nbsp;&nbsp; 完成度：{{ moreshow.percentage }}%
        </div>
        <el-table border :data="moretal" style="width: 100%">
          <el-table-column align="center" label="日期" prop="createTime" />
          <el-table-column align="center" label="时间" prop="optTime" />
          <el-table-column align="center" label="执行内容" prop="optInfo" />
          <el-table-column align="center" label="状态" prop="address">
            <template #default="scope">
              <span v-if="scope.row.dietStatus == 0" :style="{ color: '#007AFF' }">进行中</span>
              <span v-if="scope.row.dietStatus == 1" :style="{ color: '#ff0000' }">未执行</span>
              <span v-if="scope.row.dietStatus == 2" :style="{ color: '#1FC030' }">已结束</span>
            </template>
          </el-table-column>
        </el-table>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { vitMealUserPlanDayqueryPage, vitMealPlanuserMallDetails, vitMealUserPlanDayeditStop, vitMealUserPlanDayqueryList } from '@/api/mall/health/healthplantype/index'
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      Projectdetails: this.value,
      dialogVisible: false,
      moretal: [],
      form: {
        planId: null,
        size: 10,
        current: 1,
      },
      moreshow: [],
      sel: [
        {
          labelName: '进行中',
          id: 0,
        },
        {
          labelName: '待执行',
          id: 1,
        },
        {
          labelName: '已结束',
          id: 2,
        },
      ],
      total: 0,
      tableData: [],
    }
  },
  computed: {
    fleType() {
      return (item) => {
        if (item == 0) {
          return '进行中'
        } else if (item == 1) {
          return '未执行'
        } else if (item == 2) {
          return '已结束'
        }
      }
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.Projectdetails = newValue
      }
    },
  },
  created() {
    const num = this.$route.query.date
    if (num) {
      this.form.planId = num
    }
    this.queryInfo()
  },
  methods: {
    handleStop(row) {
      vitMealUserPlanDayeditStop({ id: row.planId, mealStatus: 2, isStatus: 1 }).then((res) => {
        if (res.code == 0 || res.status) {
          this.$message({
            message: '停止成功',
            type: 'success',
          })
          this.queryInfo()
        }
      })
    },
    quermore(Id, userId) {
      vitMealPlanuserMallDetails({ id: Id, userId: userId }).then((res) => {
        this.moreshow = res.data
      })
      vitMealUserPlanDayqueryList({ id: Id, userId: userId }).then((res) => {
        console.log(res.data, '1234')
        this.moretal = res.data
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    handshow(row) {
      this.dialogVisible = true
      this.quermore(row.planId, row.userId)
    },
    resetinfo() {
      this.form.mealStatus = ''
      this.form.phone = ''
      delete this.form.phone
      delete this.form.mealStatus
      this.queryInfo()
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.queryInfo()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.queryInfo()
    },
    queryInfo() {
      vitMealUserPlanDayqueryPage({ ...this.form }).then((res) => {
        console.log(res.data)
        this.tableData = res.data.records
        this.total = res.data.total
        this.form.size = res.data.size
        this.form.current = res.data.current
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 18px;
  .headertop {
    height: 72px;
    background: #fbfbfb;
    font-size: 14px;
    line-height: 72px;
    font-weight: bold;
    color: #333;
    text-indent: 2em;
  }
}
</style>