<template>
  <div>
    <el-form ref="form" label-width="80px" :model="actionscomponents" :rules="rules" size="small ">
      <el-form-item label="商品名称" prop="name">
        <el-input v-model="actionscomponents.name" placeholder="请输入商品名称" type="textarea" />
      </el-form-item>
      <el-form-item label="标签:" prop="titleTag">
        <el-select v-model="actionscomponents.titleTag" placeholder="请选择商品标签" style="width: 500px">
          <!-- <el-option v-for="(ym, fre) in titleTags" :key="fre" :label="ym.dictValue" :value="ym.dictKey" /> -->
          <el-option v-for="item in titleTags" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey" />
        </el-select>
      </el-form-item>
      <el-form-item label="店铺:" prop="shopId">
        <el-select v-model="actionscomponents.shopId" placeholder="请选择 店铺" size="medium" style="width: 500px">
          <el-option v-for="(ym, num) in shopdat" :key="num" :label="ym.name" :value="ym.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="运费模板:" prop="freightTemplatId">
        <el-select v-model="actionscomponents.freightTemplatId" placeholder="请选择 运费模板" style="width: 500px">
          <el-option v-for="(ym, fre) in freighttemplateli" :key="fre" :label="ym.name" :value="ym.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="商品图片:" prop="picUrls">
        <el-upload ref="upload" :action="action" :before-upload="bfUpload" :class="{
          hide: hideUpload,
        }" :file-list="fileList" :headers="headers" list-type="picture-card" multiple :on-change="handleChange"
          :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :on-success="handleSuccess">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog append-to-body :visible.sync="dialogVisible">
          <img alt="" :src="dialogImageUrl" width="100%" />
        </el-dialog>
      </el-form-item>
      <el-form-item label="商城类目:" prop="mallcategory">
        <el-cascader v-model="actionscomponents.mallcategory" :options="mallcategoryli"
          :props="{ checkStrictly: true, label: 'name', value: 'id' }" @change="getcasc">
          <template slot-scope="{ node, data }">
            <span v-if="!node.isLeaf">{{ data.name }}({{ data.children.length }})</span>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item label="店铺类目:">
        <el-cascader v-model="shoptp" :options="shopcategoryli" @change="getshoptype" @focus="goodscategoryshop">
          <template slot-scope="{ node, data }">
            <span>{{ data.label }}</span>
            <span v-if="!node.isLeaf">({{ data.children.length }})</span>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item class="listing" label="是否上架:" prop="shelf">
        <el-radio-group v-model="actionscomponents.shelf" style="margin-right: 180px">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="商品编码:">
        <el-input v-model="actionscomponents.spuCode" placeholder="商品编码" />
      </el-form-item>
      <el-form-item label="虚拟数量:">
        <el-input-number v-model="actionscomponents.saleNum" controls-position="right" :min="1" placeholder="虚拟数量" />
      </el-form-item>
      <el-form-item label="卖点:">
        <el-input v-model="actionscomponents.sellPoint" placeholder="卖点" />
      </el-form-item>
      <el-form-item label="保障服务:" prop="ensureIds">
        <el-checkbox-group v-model="actionscomponents.ensureIds">
          <el-checkbox v-for="(ym, index) in cities" :key="index" :label="ym.id" name="type">
            {{ ym.labelName }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { uploadURL } from '@/config'
import { getList } from '@/api/mall/shop/info'
import { getToken } from '@/utils/token'
import { getObj, getObjtype, goodscategoryshop } from '@/api/mall/goods/goodsSpecs'
import { selectSysDict } from '@/api/mall/common'
export default {
  props: {
    basicinfo: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      dialogImageUrl: '',
      headers: {},
      action: uploadURL,
      shopdat: '',
      // fileList: [],
      addForm: {
        headers: '',
      },
      hideUpload: '',
      freighttemplateli: '',
      mallcategoryli: [],
      shopcategoryli: [],
      dialogVisible: false,
      cities: [
        { labelName: '假一赔十', id: 1 },
        { labelName: '七天无理由', id: 2 },
        { labelName: '24小时发货', id: 3 },
        { labelName: '5年质量保障', id: 4 },
      ],
      rules: {
        name: [{ required: true, message: '请填写商品名称', trigger: 'change' }],
        shopId: [
          {
            type: 'string',
            required: true,
            message: '请选择店铺',
            trigger: 'blur',
          },
        ],
        freightTemplatId: [
          {
            required: true,
            message: '请选择运费模板',
            trigger: 'blur',
          },
        ],
        titleTag: [
          {
            required: true,
            message: '请选择商品标签',
            trigger: 'blur',
          },
        ],
        picUrls: [
          {
            type: 'Array',
            required: true,
            message: '请上传图片',
            trigger: 'change',
          },
        ],
        mallcategory: [
          {
            required: true,
            message: '请选择商城类目',
            trigger: 'blur',
          },
        ],
        shelf: [{ required: true, message: '请选择上架状态', trigger: 'change' }],
        titleTags: [], // 标题标签
      },
      titleTags: [],
    }
  },

  computed: {
    ...mapGetters({
      token: 'user/token',
      tenantId: 'user/tenantId',
    }),
    //标签定义
    dicTypes() {
      const arr = ['titleTag']
      return arr
    },
    actionscomponents() {
      return this.$store.state.goodssku
    },
    fileList() {
      const list = []
      const infolist = this.$store.state.goodssku.picUrls
      if (infolist != null && infolist.length != 0) {
        infolist.forEach((item) => {
          list.push({
            url: item,
          })
        })
      }
      return list
    },
    shoptp() {
      const num = []
      num[0] = this.$store.state.goodssku.categoryShopFirst
      num[1] = this.$store.state.goodssku.categoryShopSecond
      return num
    },
  },
  created() {
    const db = getToken()
    this.headers['Authorization'] = `Bearer ` + `${db}`
    this.headers['Switch-Tenant-Id'] = `${this.tenantId}`
    this.getObj()
    this.shopinfo()
    this.getObjtype()
    this.fetchDicData()
  },
  methods: {
    handleSuccess(response) {
      this.actionscomponents.picUrls.push(response.data.filePath)
    },
    handleRemove(fileList) {
      this.fileList = []
      this.hideUpload = fileList.length >= this.limitCount
    },
    bfUpload(file) {
      console.log(file)
      if ('image/png' == file.type || 'image/jpeg' == file.type || 'image/jpg' == file.type) {
        this.addForm.headers = this.headers
      } else {
        this.ME('图片上传失败,请上传png,jpeg,jpg格式')
        return false
      }
    },
    goodscategoryshop(e) {
      console.log(this.actionscomponents.shopId, 'chuaner')
      if (e) {
        goodscategoryshop({ shopid: e }).then((res) => {
          this.shopcategoryli = res.data
          this.shopcategoryli.map((obj) => {
            obj.label = obj.name
            obj.value = obj.id
            if (obj.children) {
              const fieldArr = obj.children
              const newFieldArr = fieldArr.map((element) => {
                element.label = element.name
                element.value = element.id
                return element
              })
              obj.fieldArr = newFieldArr
              return obj
            }
          })
        })
      } else {
        this.$message.error('请选择店铺')
      }
    },
    handlePictureCardPreviewaction(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getObjtype() {
      const shopid = {
        shopId: this.actionscomponents.shop,
      }
      getObjtype({ ...shopid }).then((res) => {
        this.mallcategoryli = res.data
        this.mallcategoryli.map((obj) => {
          obj.label = obj.name
          obj.value = obj.id
          const fieldArr = obj.children
          const newFieldArr = fieldArr.map((element) => {
            element.label = element.name
            element.value = element.id
            element.children.forEach((item) => {
              item.label = item.name
              item.value = item.id
            })
            return element
          })
          obj.fieldArr = newFieldArr
          return obj
        })
      })
    },
    getObj() {
      const shopid = {
        shopId: this.actionscomponents.shop,
      }
      getObj({ ...shopid }).then((res) => {
        this.freighttemplateli = res.data
        // const template = res.data
        this.freighttemplateli.forEach(item => {
          item.id = String(item.id)
        })
        // this.freighttemplateli=template
      })
    },
    shopinfo() {
      console.log(this.actionscomponents.shopId, 'cancelButtonText')
      getList({}).then((res) => {
        this.shopdat = res.data
      })
    },
    handleChange(file, fileList) {
      this.hideUpload = fileList.length >= this.limitCount
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getcasc(e) {
      this.actionscomponents.categoryFirst = e[0]
      this.actionscomponents.categorySecond = e[1]
    },
    getshoptype(e) {
      this.actionscomponents.categoryShopFirst = e[0]
      this.actionscomponents.categoryShopSecond = e[1]
    },

    //标题标签查询
    fetchDicData() {
      selectSysDict({
        dictTypeList: ['titleTag'],
      }).then((res) => {
        const dictList = res.data[0].dictInfoVoList
        dictList.forEach((item) => {
          item.dictKey = Number(item.dictKey)
        });
      })
    },
  },
}
</script>

<style></style>
