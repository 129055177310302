import request from '@/utils/request'

//会员标签列表
export function userTagqueryPage(data) {
  return request({
    url: '/mall/user/userTag/queryPage',
    method: 'post',
    data: data,
  })
}

//会员标签-添加
export function userTagadd(data) {
  return request({
    url: '/mall/user/userTag/add',
    method: 'post',
    data: data,
  })
}

//会员标签-编辑 删除
export function userTagedit(data) {
  return request({
    url: '/mall/user/userTag/edit',
    method: 'post',
    data: data,
  })
}
