var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowToast
    ? _c("div", { staticClass: "toast" }, [
        _c("p", [
          _vm._v("您一共有"),
          _c("span", { staticClass: "num" }, [_vm._v(_vm._s(_vm.num))]),
          _vm._v("条未处理的信息"),
        ]),
        _c("button", { on: { click: _vm.closeToast } }, [_vm._v("×")]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }