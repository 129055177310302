var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container",
      style: {
        background: `${_vm.controls[_vm.index].pageBgColor}`,
        padding: `${_vm.controls[_vm.index].margin.top}px ${
          _vm.controls[_vm.index].margin.both
        }px ${_vm.controls[_vm.index].margin.bottom}px ${
          _vm.controls[_vm.index].margin.both
        }px `,
      },
    },
    [
      _c(
        "div",
        {
          class:
            _vm.controls[_vm.index].style == 2
              ? "user_thead borRadius"
              : "user_thead",
          style: _vm.bgColr(
            _vm.controls[_vm.index].theme,
            _vm.controls[_vm.index].gradientAngle,
            _vm.controls[_vm.index].style
          ),
        },
        [
          _c("div", { staticClass: "th_banhead" }, [
            _c("div", { staticClass: "lef" }, [
              _vm._m(0),
              _c("div", { staticClass: "center_info" }, [
                _c("div", { staticClass: "club" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("勇敢的某某")]),
                  _c(
                    "div",
                    { staticClass: "vip_picUrl" },
                    [
                      _c("vab-icon", { attrs: { icon: "vip-crown-2-fill" } }),
                      _c("span", [_vm._v("会员")]),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "alert_msg" }, [
                  _vm._v("点击登录享受更多精彩信息"),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "ref" },
              [
                _c("vab-icon", {
                  staticStyle: { "font-size": "20px" },
                  attrs: { icon: "qr-code-line" },
                }),
              ],
              1
            ),
          ]),
          _vm.controls[_vm.index].style != 2 &&
          _vm.controls[_vm.index].style != 4
            ? _c(
                "div",
                {
                  staticClass: "account-info",
                  style: {
                    padding: `0 ${_vm.controls[_vm.index].margin.both}px`,
                    marginBottom: `10px`,
                  },
                },
                _vm._l(
                  _vm.fity(_vm.controls[_vm.index].isBtnshow),
                  function (item, ind) {
                    return _c("div", { key: ind, staticClass: "items" }, [
                      _c("div", { staticClass: "numb" }, [
                        _vm._v(_vm._s(item.isNumber)),
                      ]),
                      _c("div", { staticClass: "numbs" }, [
                        _vm._v(_vm._s(item.isTitle)),
                      ]),
                    ])
                  }
                ),
                0
              )
            : _vm._e(),
          _c(
            "div",
            {
              style: {
                padding: `0 ${_vm.controls[_vm.index].margin.both}px`,
              },
            },
            [
              _vm.controls[_vm.index].style != 4
                ? _c(
                    "div",
                    {
                      staticClass: "super_member",
                      style: _vm.bGurl(_vm.controls[_vm.index].style),
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "lef",
                          style: {
                            display:
                              _vm.controls[_vm.index].style == 3
                                ? "grid"
                                : "flex",
                          },
                        },
                        [
                          _vm.controls[_vm.index].style == 3
                            ? _c("img", {
                                staticStyle: { height: "18px" },
                                attrs: {
                                  alt: "",
                                  src: "https://uniapp.v5.niuteam.cn/public/uniapp/member/open_member.png",
                                },
                              })
                            : _c("vab-icon", {
                                staticStyle: { "font-size": "18px" },
                                attrs: { icon: "vip-crown-2-fill" },
                              }),
                          _c(
                            "span",
                            {
                              style: {
                                fontSize:
                                  _vm.controls[_vm.index].style == 3
                                    ? "12px"
                                    : "18px",
                              },
                            },
                            [_vm._v("超级会员")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "ref" }, [
                        _vm.controls[_vm.index].style == 3
                          ? _c("div", { staticClass: "btn" }, [
                              _vm._v("查看特权"),
                            ])
                          : _c(
                              "div",
                              { staticClass: "ref" },
                              [
                                _c("span", [_vm._v("会员可享受更多权益")]),
                                _c("vab-icon", {
                                  staticStyle: { "font-size": "18px" },
                                  attrs: { icon: "arrow-right-s-line" },
                                }),
                              ],
                              1
                            ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          style: {
            padding: `0 ${_vm.controls[_vm.index].margin.both}px`,
          },
        },
        [
          _vm.controls[_vm.index].style == 2
            ? _c(
                "div",
                {
                  staticClass: "account-info bold_ym",
                  staticStyle: {
                    width: "calc(100% - 26px)",
                    maring: "0 auto",
                    "margin-top": "10px",
                    color: "#333",
                    background: "white",
                  },
                },
                _vm._l(
                  _vm.fity(_vm.controls[_vm.index].isBtnshow),
                  function (item, ind) {
                    return _c("div", { key: ind, staticClass: "items" }, [
                      _c("div", { staticClass: "numb" }, [
                        _vm._v(_vm._s(item.isNumber)),
                      ]),
                      _c("div", { staticClass: "numbs" }, [
                        _vm._v(_vm._s(item.isTitle)),
                      ]),
                    ])
                  }
                ),
                0
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          style: {
            padding: `0 ${_vm.controls[_vm.index].margin.both}px`,
          },
        },
        [
          _vm.controls[_vm.index].style == 4
            ? _c(
                "div",
                {
                  style: {
                    background: "white",
                    padding: "15px",
                    margin: "15px",
                    marginTop: "-100px",
                    borderRadius: "10px",
                  },
                },
                [
                  _vm.controls[_vm.index].style == 4
                    ? _c(
                        "div",
                        {
                          staticClass: "super_member",
                          style: _vm.bGurl(_vm.controls[_vm.index].style),
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "lef",
                              style: {
                                display:
                                  _vm.controls[_vm.index].style == 4
                                    ? "flex"
                                    : "grid",
                                alignItems: `center`,
                              },
                            },
                            [
                              _vm.controls[_vm.index].style == 4
                                ? _c("img", {
                                    staticStyle: {
                                      height: "40px",
                                      "margin-right": "10px",
                                    },
                                    attrs: {
                                      alt: "",
                                      src: "https://uniapp.v5.niuteam.cn/app/component/view/member_info/img/style_4_vip_huangguan.png",
                                    },
                                  })
                                : _c("vab-icon", {
                                    staticStyle: { "font-size": "18px" },
                                    attrs: { icon: "vip-crown-2-fill" },
                                  }),
                              _c(
                                "span",
                                {
                                  style: {
                                    fontSize:
                                      _vm.controls[_vm.index].style == 3
                                        ? "12px"
                                        : "15px",
                                    fontWeight: "bold",
                                    color:
                                      _vm.controls[_vm.index].style == 3
                                        ? "#333"
                                        : "#333",
                                  },
                                },
                                [_vm._v(" 开通会员 享六大权益 ")]
                              ),
                            ],
                            1
                          ),
                          _vm._m(1),
                        ]
                      )
                    : _vm._e(),
                  _vm.controls[_vm.index].style == 4
                    ? _c(
                        "div",
                        {
                          staticClass: "account-info",
                          style: {
                            margin: `10px ${
                              _vm.controls[_vm.index].margin.both
                            }px`,
                          },
                        },
                        _vm._l(
                          _vm.fity(_vm.controls[_vm.index].isBtnshow),
                          function (item, ind) {
                            return _c(
                              "div",
                              {
                                key: ind,
                                staticClass: "items",
                                style: {
                                  borderRight: ind < 2 ? "1px solid black" : "",
                                  color: "black",
                                },
                              },
                              [
                                _c("div", { staticClass: "numb" }, [
                                  _vm._v(_vm._s(item.isNumber)),
                                ]),
                                _c("div", { staticClass: "numbs" }, [
                                  _vm._v(_vm._s(item.isTitle)),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm.controls[_vm.index].style == 4
                    ? _c("div", { staticClass: "setting-card" }, [
                        _vm._m(2),
                        _vm._m(3),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tehad_userimg" }, [
      _c("img", {
        attrs: {
          alt: "",
          src: "https://uniapp.v5.niuteam.cn/public/static/img/default_img/head.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ref" }, [
      _c("div", { staticClass: "super-text" }, [_vm._v("立即开通")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lef" }, [
      _c("div", { staticClass: "balance" }, [_vm._v("余额充值")]),
      _c("div", { staticClass: "balance" }, [_vm._v("专属顾问")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ref" }, [
      _c("img", {
        attrs: {
          alt: "",
          src: "https://uniapp.v5.niuteam.cn/app/component/view/member_info/img/style_4_code.png",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }