<template>
  <div class="container">
    <div class="thead">剩余积分：{{ logAmount }}</div>
    <el-table border :data="tablist" max-height="600" size="medium">
      <el-table-column align="center" label="用户名称" prop="userName" />
      <el-table-column align="center" label="积分余额" prop="pointsCurrent" />
      <el-table-column align="center" label="抵扣积分" prop="amount" />
      <el-table-column align="center" label="描述" prop="description" show-overflow-tooltip width="260" />
      <el-table-column align="center" label="创建时间" prop="createTime" />
    </el-table>
    <el-pagination background :current-page="1" layout="total, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
  </div>
</template>

<script>
import { integralList } from '@/api/mall/health/integral'
import { getUserInfo } from '@/api/mall/users/userLevel'
export default {
  components: {
  },
  props: {
    sound: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showl: false,
      rolis: null,
      total: 0,
      tablist: [],
      showlist: false,
      showtblist: null,
      form: {
        size: 10,
        current: 1,
        recordId: this.sound.id,
      },
      logAmount:''

    }
  },
  watch: {
    sound(newValue, oldValue) {
      if (newValue != oldValue) {
        this.form.recordId = newValue
        console.log(this.sound,'sound')
      }
    },
  },
  created() {
    // this.form.userId=this.userinfo.id
    this.uuUserSugarRecordLog()
  },
  methods: {
    hand(row) {
      this.rolis = row
      this.showl = true
    },
    closeh() {
      this.showl = false
    },
    closeshow() {
      this.showlist = false
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.uuUserSugarRecordLog()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.uuUserSugarRecordLog()
    },
    //客情处理接口请求
    uuUserSugarRecordLog() {
      console.log(this.sound,'this.sound')
      getUserInfo({userCode:this.sound.userId}).then((res)=>{
        console.log(res,'res')
        this.logAmount = res.data.pointsCurrent
      })
      integralList({ ...this.form }).then((res) => {
        this.total = res.data.total
        this.form.size = res.data.size
        this.form.current = res.data.current
        this.tablist = res.data.records
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.thead {
  margin-bottom: 10px;
}</style>
