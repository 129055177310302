<template>
  <div>
    <el-form label-position="top" :model="form">
      <el-form-item label="处理结果">
        <el-input v-model="form.resultRemark" placeholder="请输入 处理结果"/>
      </el-form-item>
      <el-form-item label="客户建议">
        <el-input v-model="form.suggRemark" placeholder="请输入 客户建议"/>
      </el-form-item>
      <el-form-item label="情况备注">
        <el-input v-model="form.remark" placeholder="请输入 情况备注"/>
      </el-form-item>
      <el-form-item label="附件上传">
        <uploadpic  @backkuck="check($event)"/>
        <!-- <el-upload
          action="https://jsonplaceholder.typicode.com/posts/"
          :before-remove="beforeRemove"
          class="upload-demo"
          :file-list="fileList"
          :limit="3"
          multiple
          :on-exceed="handleExceed"
          :on-preview="handlePreview"
          :on-remove="handleRemove">
          <el-button size="small" type="">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">上传文件贴合规则，且不超过4MB</div>
        </el-upload> -->
      </el-form-item>
      <el-form-item label="">
        <el-button style="margin-right: 15px;" type="primary" @click="save(1)">暂存</el-button>
        <el-button type="primary" @click="save(2)">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {uuUserSugarRecordAfterLogadd} from '@/api/mall/aftersaledata/index'
import uploadpic from '../../../upload-pic/upload.vue'
export default {
  components:{
    uploadpic
  },
  data(){
    return{
      form:{},
      fileList: [
        // {name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}
      ],
      listID:null,
      comid:null
    }
  },
  created(){
    if(this.$route.query.date){
      this.listID=JSON.parse(this.$route.query.date)
    }
  },
  methods:{
    check(event){
      this.comid=event.comId
    },
    save(tp){
      this.form.type=tp
      this.form.afterId=this.listID.id
      this.form.afterState=this.listID.afterState
      if(this.comid!=null){
        this.form.comId=this.comid
      }else{
        this.form.comId=0
      }
      uuUserSugarRecordAfterLogadd({...this.form}).then(res=>{
        if(res.status==200){
         if(tp==1){
          this.$message.success("暂存成功")
         }else{
          this.$message.success("存储成功")
         }
          this.form={}
        }
        else{
          this.$message.error(res.msg)
        }
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, ) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-input{
    width: 200px;
  }
}
</style>