var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "查看", visible: _vm.dialogVisible, width: "60%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-descriptions",
            { attrs: { border: "", column: 2, title: "" } },
            [
              _c("el-descriptions-item", { attrs: { label: "店铺：" } }, [
                _vm._v(_vm._s(_vm.deate.shopId)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "创建时间：" } }, [
                _vm._v(_vm._s(_vm.deate.createTime)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "名称：" } }, [
                _vm._v(_vm._s(_vm.deate.name)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "排序：" } }, [
                _vm._v(_vm._s(_vm.deate.sort)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "库存：" } }, [
                _vm._v(_vm._s(_vm.deate.stock) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "订单金额：" } }, [
                _vm._v(_vm._s(_vm.deate.premiseAmount) + " "),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.deate.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.deate, "type", $$v)
                        },
                        expression: "deate.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("代金券"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("折扣券"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "减免金额：" } }, [
                _vm._v(_vm._s(_vm.deate.reduceAmount) + " "),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "到期类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.deate.expireType,
                        callback: function ($$v) {
                          _vm.$set(_vm.deate, "expireType", $$v)
                        },
                        expression: "deate.expireType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("领券后生效"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("固定时间段"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "有效天数：" } }, [
                _vm._v(_vm._s(_vm.deate.validDays) + " "),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "是否启用：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.deate.enable,
                        callback: function ($$v) {
                          _vm.$set(_vm.deate, "enable", $$v)
                        },
                        expression: "deate.enable",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("关闭"),
                      ]),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("启用"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "适用商品" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.deate.suitType,
                        callback: function ($$v) {
                          _vm.$set(_vm.deate, "suitType", $$v)
                        },
                        expression: "deate.suitType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("全部商品"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("指定商品"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "指定商品：" } }, [
                _vm._v(_vm._s(_vm.deate.listGoodsSpu) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }