var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-table",
        { attrs: { border: "", data: _vm.tablist, "max-height": "300" } },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              type: "index",
              width: "55",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "员工工号", prop: "profile" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "通话类型", prop: "interType" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "交流时间", prop: "createTime" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "交流类型",
              prop: "interTitle",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "交流内容",
              prop: "interContent",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": 1,
          layout: "total, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }