<template>
  <div class="comprehensive-table-container">
    <div class="list-item">
      <div class="list-title">
        <div class="line"></div>
        <div class="title-text">实例设置</div>
      </div>
      <div class="item-line">
        <div class="line-left">实例名称：</div>
        <div class="line-right">{{ step1Data.instName }}</div>
      </div>
      <div class="item-line">
        <div class="line-left">实例图片：</div>
        <div class="line-right">
          <img
            class="img"
            :src="step1Data.instLogo"
            @click="handlePreView(step1Data.instLogo)"
          />
          <!-- <el-image
            class="img"
            fit="fill"
            :src="step1Data.instLogo"
            @click="handlePreView(step1Data.instLogo)"
          /> -->
        </div>
      </div>
      <div class="item-line">
        <div class="line-left">实例时间：</div>
        <div class="line-right">
          {{ step1Data.startDate }} 至 {{ step1Data.endDate }}
        </div>
      </div>
      <div class="item-line">
        <div class="line-left">发布时间：</div>
        <div class="line-right">{{ step1Data.publishTime }}</div>
      </div>
      <!-- <div class="item-line">
        <div class="line-left">开奖条件：</div>
        <div class="line-right">
          满员抽奖（每期抽奖人数满100自动开奖，共10期）
        </div>
      </div> -->
      <div class="item-line">
        <div class="line-left">实例说明：</div>
        <div class="line-right" v-html="step1Data.remark"></div>
      </div>
    </div>

    <div class="list-item">
      <div class="list-title">
        <div class="line"></div>
        <div class="title-text">参与设置</div>
      </div>
      <div class="item-line">
        <div class="line-left">参与用户：</div>
        <div class="line-right">
          <div class="right-item">
            最低身份（{{ getLimitUserLvl(step2Data.limitUserLvl) }}）
          </div>
          <!-- <div class="right-item">区域：重庆市，北京市</div> -->
        </div>
      </div>
      <div class="item-line">
        <div class="line-left">参与条件：</div>
        <div class="line-right">
          每次抽奖消耗
          <span v-show="step2Data.consumeIntegral">
            {{ step2Data.consumeIntegral }}积分；
          </span>
          <span v-show="step2Data.consumeGoldenBean">
            {{ step2Data.consumeGoldenBean }}金豆；
          </span>
          <span v-show="step2Data.consumeBalance">
            余额{{ step2Data.consumeBalance }}
          </span>
        </div>
      </div>
      <div class="item-line">
        <div class="line-left">参与份数：</div>
        <div class="line-right">每人一共可抽{{ step2Data.shareLimit }}份</div>
      </div>
    </div>

    <div class="list-item">
      <div class="list-title">
        <div class="line"></div>
        <div class="title-text">奖品设置</div>
      </div>
      <div class="item-line" style="align-items: stretch">
        <div class="line-left">奖品规则：</div>
        <div class="line-right">
          <div>
            <el-table border :data="prizeList" stripe>
              <el-table-column label="奖品名称" prop="itemName" />
              <el-table-column label="奖品等级" prop="itemLevel" />
              <el-table-column label="排序" prop="itemSort" />
              <el-table-column label="中奖概率" prop="itemRate" />
              <el-table-column
                align="center"
                label="奖品状态"
                prop="itemStatus"
              >
                <template #default="{ row }">
                  {{ getItemStatus(row.itemStatus) }}
                </template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                min-width="180px"
                prop="createTime"
              >
                <template #default="{ row }">
                  {{ row.createTime | parseTime }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" min-width="120px">
                <template #default="{ row }">
                  <el-button size="mini" @click="openPrizeDetail(row)">
                    奖品明细
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              background
              :current-page="prizeQueryForm.currentPage"
              :layout="layout"
              :page-size="prizeQueryForm.pageSize"
              :total="prizeTotal"
              @current-change="handlePrizeCurrentChange"
              @size-change="handlePrizeSizeChange"
            />
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="奖品明细"
      :visible.sync="prizeDetailVisible"
      width="70%"
      @close="colosePrizeDetailDialog"
    >
      <div class="list-item">
        <el-table border :data="prizeDetailList" stripe>
          <el-table-column align="center" label="图片">
            <template #default="{ row }">
              <div>
                <el-image
                  fit="fill"
                  :src="row.goodsImage"
                  style="cursor: pointer"
                  @click="handlePreView(row.goodsImage)"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="奖品信息" prop="goodsName" />
          <el-table-column align="center" label="奖品类型">
            <template #default="{ row }">
              <div>{{ getDetailGoodsType(row.goodsType) }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="提示语" prop="winGoodsTips" />
          <el-table-column align="center" label="价值" prop="goodsAmount">
            <template #default="{ row }">
              <div>{{ row.goodsAmount | numFormat }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="数量" prop="goodsCount" />
          <el-table-column align="center" label="奖项权重" prop="goodsRate" />
        </el-table>
        <el-pagination
          background
          :current-page="prizeDetailQueryForm.currentPage"
          :layout="layout"
          :page-size="prizeDetailQueryForm.pageSize"
          :total="prizeDetailTotal"
          @current-change="handlePrizeDetailCurrentChange"
          @size-change="handlePrizeDetailSizeChange"
        />
      </div>
    </el-dialog>
    <el-dialog
      append-to-body
      title="图片详情"
      :visible.sync="imgDetailVisible"
      width="50%"
    >
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <el-image fit="fill" :src="imgUrl" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getStep1,
    getStep2,
    getPrizeList,
    getPrizeDetailList,
  } from '@/api/activity/indiana'
  import { parseTime } from '@/utils'
  export default {
    filters: {
      parseTime,
    },
    data() {
      return {
        list: [],
        instId: 0,
        actId: 0,
        step1Data: {},
        step2Data: {},
        prizeList: [],
        prizeTotal: 0,
        prizeDetailList: [],
        prizeDetailTotal: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        prizeDetailVisible: false,
        selectItemId: 0,
        imgUrl: '',
        imgDetailVisible: false,
        itemStatus: [
          {
            dictValue: '禁用',
            dictKey: 0,
          },
          {
            dictValue: '正常',
            dictKey: 1,
          },
        ],
        goodsType: [
          {
            dictValue: '实物',
            dictKey: 1,
          },
          {
            dictValue: '积分',
            dictKey: 2,
          },
          {
            dictValue: '金豆',
            dictKey: 3,
          },
          {
            dictValue: '余额（现金）',
            dictKey: 4,
          },
          {
            dictValue: 'V卡',
            dictKey: 5,
          },
          {
            dictValue: '礼品卡',
            dictKey: 6,
          },
          {
            dictValue: '优惠券',
            dictKey: 7,
          },
          {
            dictValue: '未中奖',
            dictKey: 8,
          },
        ],
        limitUserLvl: [
          {
            dictValue: '普通会员',
            dictKey: 1,
          },
          {
            dictValue: '高级会员',
            dictKey: 2,
          },
          {
            dictValue: '淘侠',
            dictKey: 20,
          },
          {
            dictValue: '讲师',
            dictKey: 30,
          },
          {
            dictValue: '门主',
            dictKey: 40,
          },
        ],
        prizeQueryForm: {
          currentPage: 1,
          pageSize: 10,
        },
        prizeDetailQueryForm: {
          pageSize: 10,
          currentPage: 1,
        },
      }
    },
    created() {
      const { instId, actId } = this.$route.query
      this.instId = instId
      this.actId = actId
      Promise.all([
        getStep1({ id: instId }),
        getStep2({ instId }),
        this.fetchPrizeData(),
      ]).then((res) => {
        const [{ data: res1 }, { data: res2 }] = res
        this.step1Data = res1
        this.step2Data = res2
      })
    },
    methods: {
      handlePreView(url) {
        this.imgUrl = url
        this.imgDetailVisible = true
      },
      getDetailGoodsType(key) {
        let str = ''
        const obj = this.goodsType.find((item) => item.dictKey == key)
        if (obj) {
          str = obj.dictValue
        }
        return str
      },
      colosePrizeDetailDialog() {
        this.prizeDetailList = []
        this.prizeDetailTotal = 0
        this.prizeDetailQueryForm = {
          pageSize: 10,
          currentPage: 1,
        }
      },
      openPrizeDetail(row) {
        this.fetchPrizeDetailData(row.id).then(() => {
          this.prizeDetailVisible = true
        })
        console.log(row)
      },
      handlePrizeSizeChange(val) {
        this.prizeQueryForm.pageSize = val
        this.fetchPrizeData()
      },
      handlePrizeCurrentChange(val) {
        this.prizeQueryForm.currentPage = val
        this.fetchPrizeData()
      },
      getItemStatus(val) {
        let str = ''
        let obj = this.itemStatus.find((item) => item.dictKey == val)
        if (obj) {
          str = obj.dictValue
        }
        return str
      },
      getLimitUserLvl(val) {
        let str = ''
        let obj = this.limitUserLvl.find((item) => item.dictKey == val)
        if (obj) {
          str = obj.dictValue
        }
        return str
      },
      fetchPrizeData() {
        return new Promise((resolve, reject) => {
          getPrizeList({ ...this.prizeQueryForm, instanceId: this.instId })
            .then((res) => {
              const { data } = res
              this.prizeTotal = data.total
              this.prizeList = data.records
              resolve(data.records)
            })
            .catch((err) => {
              reject(err)
            })
        })
      },
      handlePrizeDetailSizeChange(val) {
        this.prizeDetailQueryForm.pageSize = val
        this.fetchPrizeDetailData(this.selectItemId)
      },
      handlePrizeDetailCurrentChange(val) {
        this.prizeDetailQueryForm.currentPage = val
        this.fetchPrizeDetailData(this.selectItemId)
      },
      fetchPrizeDetailData(itemId) {
        return new Promise((resolve, reject) => {
          getPrizeDetailList({ ...this.prizeDetailQueryForm, itemId })
            .then((res) => {
              const { data } = res
              this.prizeDetailTotal = data.total
              this.prizeDetailList = data.records
              resolve(data.records)
            })
            .catch((err) => {
              reject(err)
            })
        })
      },
      dateChange(date) {
        if (date && date.length) {
          this.formInline.startTime = date[0]
          this.formInline.endTime = date[1]
        } else {
          this.formInline.startTime = ''
          this.formInline.endTime = ''
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .list-item {
    .list-title {
      display: flex;
      align-items: center;
      .line {
        height: 20px;
        width: 4px;
        background-color: rgb(41, 121, 255);
        border-radius: 3px;
      }
      .title-text {
        margin-left: 10px;
        font-size: 18px;
      }
    }
    .item-line {
      display: flex;
      align-items: center;
      margin: 20px;
      .line-left {
        width: 80px;
        color: #bbb;
      }
      .line-right {
        display: flex;
        align-items: center;
        .right-item {
          margin-right: 20px;
        }
        .img {
          width: 80px;
          height: 80px;
          cursor: pointer;
        }
      }
    }
  }
</style>
