<template>
  <div>
    <div v-if="checknum=='-1'">
      <component :is="componentMap.get(activeComponent)"  :indexnumer="checknum" />
    </div>
    <div v-for="(ym,index) in controls" :key="index">
      <component :is="componentMap.get(ym.type)" v-if="checknum==index"  :indexnumer="checknum" />
    </div>
  </div>
</template>

<script>
  import componentMap from '../right_content/index'
  import { mapGetters } from 'vuex'
  export default {
    data() {
      return {
        componentMap: componentMap,
        activeComponent: 'header',
      }
    },
    computed: {
      ...mapGetters['state'],
      checknum() {
        return this.$store.state.checknumber
      },
      controls() {
        return this.$store.state.checkcanvas
      },
    },
  }
</script>

<style></style>
