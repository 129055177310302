import request from '@/utils/request'

export function getGoodsCategogy(data) {
  return request({
    url: `/goods/proGoodsCategory/subList`,
    method: 'post',
    data,
  })
}

export function specification(data) {
  return request({
    url: `mall/goodsspec/list`,
    method: 'GET',
    data,
  })
}

export function getList(query) {
  return request({
    url: '/mall/goodsspecvalue/list',
    method: 'get',
    params: query
  })
}
