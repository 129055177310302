<template>
  <div class="mt40 stepOne">
    <el-form
      ref="form"
      label-width="150px"
      :model="form"
      :rules="rules"
      style="margin: 0 auto; width: 60%"
    >
      <el-form-item label="活动主题：" prop="actName">
        <el-input
          v-model.trim="form.actName"
          class="fromInput"
          placeholder="请输入活动主题"
        />
      </el-form-item>
      <el-form-item label="主题时间：" prop="datePicker">
        <el-date-picker
          v-model="form.datePicker"
          align="center"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="dateChange"
        />
      </el-form-item>
      <el-form-item label="发布时间：" prop="publishType">
        <el-radio-group v-model="form.publishType">
          <el-radio :label="'1'">立即发布</el-radio>
          <el-radio :label="'2'">定时发布</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.publishType == 2" prop="publishTime">
        <el-date-picker
          v-model="form.publishTime"
          align="center"
          placeholder="选择日期时间"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>
      <el-form-item label="参与平台：" prop="actPlatform">
        <el-checkbox-group v-model="form.actPlatform">
          <el-checkbox
            v-for="item in actPlatformList"
            :key="item.dictKey"
            :label="item.dictKey"
          >
            {{ item.dictValue }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item class="themeLogo" label="主题封面：" prop="coverPicture">
        <el-upload
          ref="upload"
          :action="action"
          :before-upload="bfUpload"
          :class="{
            hide: hideUpload,
          }"
          :file-list="fileList"
          :headers="headers"
          :limit="limitCount"
          list-type="picture-card"
          :on-change="handleChange"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog append-to-body :visible.sync="dialogVisible">
          <img alt="" :src="dialogImageUrl" width="100%" />
        </el-dialog>
      </el-form-item>
      <el-form-item class="themeLogo" label="主题背景：" prop="backgPicture">
        <el-upload
          ref="upload"
          :action="action"
          :before-upload="bfUpload"
          :class="{
            hide: hideUpload2,
          }"
          :file-list="fileList2"
          :headers="headers"
          :limit="limitCount2"
          list-type="picture-card"
          :on-change="handleChange2"
          :on-preview="handlePictureCardPreview2"
          :on-remove="handleRemove2"
          :on-success="handleSuccess2"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog append-to-body :visible.sync="dialogVisible2">
          <img alt="" :src="dialogImageUrl2" width="100%" />
        </el-dialog>
      </el-form-item>
    </el-form>
    <div class="pay-button-group mt20">
      <el-button :loading="loading" type="primary" @click="handleSubmit">
        下一步
      </el-button>
    </div>
  </div>
</template>
<script>
  import { selectSysDict } from '@/api/mall/common'
  import { addAct, details, edit } from '@/api/activity/theme'
  import { mapGetters } from 'vuex'
  import { uploadURL } from '@/config'
  import { parseTime } from '@/utils'
  export default {
    name: 'StepOne',
    props: {
      infos: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7
          },
        },
        hideUpload2: false,
        limitCount2: 1,
        dialogImageUrl2: '',
        dialogVisible2: false,
        fileList2: [],
        hideUpload: false,
        limitCount: 1,
        dialogImageUrl: '',
        dialogVisible: false,
        fileList: [],
        headers: {},
        action: uploadURL,
        actPlatformList: [],
        datePicker: '',
        loading: false,
        form: {
          actName: '',
          actInfo: '',
          endTime: '',
          startTime: '',
          publishType: '1',
          publishTime: '',
          actPlatform: [],
          coverPicture: '',
          backgPicture: '',
          datePicker: [],
        },
        rules: {
          actName: [
            {
              required: true,
              message: '请输入主题名称',
              trigger: ['blur', 'change'],
            },
          ],

          actPlatform: [
            {
              required: true,
              message: '请选择参与平台',
              trigger: ['blur', 'change'],
            },
          ],
          datePicker: [
            {
              required: true,
              message: '请选择主题时间',
              trigger: ['blur', 'change'],
            },
          ],
          publishType: [
            {
              required: true,
              message: '请选择发布时间',
              trigger: ['blur', 'change'],
            },
          ],
          publishTime: [
            {
              required: true,
              message: '请选择时间',
              trigger: ['blur', 'change'],
            },
          ],
        },
      }
    },
    computed: {
      ...mapGetters({
        token: 'user/token',
        userId: 'user/userId',
      }),
    },
    created() {
      this.headers['Authorization'] = `Bearer ${this.token}`
      this.selectSysDict()
      if (this.infos.id || this.infos.tip) {
        this.detailsd()
      }
    },

    methods: {
      handleActPlatform(val) {
        this.form.actPlatform.push(val)
      },

      // 主题时间
      dateChange(date) {
        if (date && date.length) {
          this.form.startTime = date[0]
          this.form.endTime = date[1]
        } else {
          this.form.startTime = ''
          this.form.endTime = ''
        }
      },

      // 详情
      async detailsd() {
        await details({
          id: this.infos.id,
        })
          .then((res) => {
            if (res.data) {
              this.form.actName = res.data.actName
              this.form.actPlatform = res.data.actPlatform.split(',')
              this.form.publishType = String(res.data.publishType)
              this.form.datePicker = [
                parseTime(res.data.startTime),
                parseTime(res.data.endTime),
              ]
              this.form.actInfo = res.data.actInfo
              this.form.coverPicture = res.data.coverPicture
              this.form.backgPicture = res.data.backgPicture

              //背景
              this.fileList2.push({
                url: res.data.backgPicture,
              })
              console.log(this.fileList2)
              this.hideUpload2 = this.fileList2.length >= this.limitCount2
              //封面
              this.fileList.push({
                url: res.data.coverPicture,
              })
              this.hideUpload = this.fileList.length >= this.limitCount
              this.form.startTime = parseTime(res.data.startTime)
              this.form.endTime = parseTime(res.data.endTime)
              this.form.publishTime = parseTime(res.data.publishTime)
            }
          })
          .catch(() => {})
      },

      // 下一步
      handleSubmit() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (!this.form.backgPicture) return this.ME('请上传主题封面')
            if (!this.form.backgPicture) return this.ME('请上传主题背景')
            this.loading = false
            const pamas = {
              actName: this.form.actName,
              actInfo: this.form.actInfo,
              startTime: this.form.startTime,
              endTime: this.form.endTime,
              publishType: this.form.publishType,
              publishTime: this.form.publishTime
                ? String(this.form.publishTime)
                : '',
              actPlatform: String(this.form.actPlatform),
              coverPicture: this.form.coverPicture,
              backgPicture: this.form.backgPicture,
            }
            if (this.infos.id && !this.infos.tip) {
              // 编辑
              await edit({
                ...pamas,
                publishUserId: this.userId, //登录时的userId
                id: this.infos.id, //主题id
              })
                .then((res) => {
                  this.MS(res.message)
                  this.$emit('change-step', 2)
                  this.loading = false
                })
                .catch(() => {
                  this.loading = false
                })
            } else if (this.infos.tip && this.infos.id) {
              // 复制
              await addAct({
                ...pamas,
                publishUserId: this.userId, //登录时的userId
              })
                .then((res) => {
                  this.MS(res.message)
                  this.$emit('change-step', 2, res.data.id)
                  this.loading = false
                })
                .catch(() => {
                  this.loading = false
                })
            } else if (!this.infos.id && !this.infos.tip) {
              // 新增
              await addAct({
                ...pamas,
                publishUserId: this.userId, //登录时的userId
              })
                .then((res) => {
                  this.MS(res.message)
                  this.$emit('change-step', 2, res.data.id)
                  this.loading = false
                })
                .catch(() => {
                  this.loading = false
                })
            }
          }
        })
      },

      // 字典
      selectSysDict() {
        selectSysDict({ dictTypeList: ['actPlatform'] })
          .then((res) => {
            if (res.data) {
              this.actPlatformList = res.data[0].dictInfoVoList
            }
          })
          .catch(() => {})
      },
      // 图片上传
      bfUpload(file) {
        console.log(file)
        if (
          'image/png' == file.type ||
          'image/jpeg' == file.type ||
          'image/jpg' == file.type
        ) {
          console.log()
        } else {
          this.ME('图片上传失败,请上传png,jpeg,jpg格式')
          return false
        }
      },
      // 图片预览
      handleImgClick(url) {
        this.imagesUrl = url
        this.imgDialog = true
      },

      // 主题背景图片
      handleChange2(file, fileList) {
        this.fileList2 = fileList
        this.hideUpload2 = fileList.length >= this.limitCount2
      },
      //主题背景
      handleSuccess2(response, file) {
        console.log(response, file)
        this.form.backgPicture = response.data.filePath
        this.fileList2.push({
          url: response.data.filePath,
        })
      },

      // 主题背景删除图片
      handleRemove2(fileList) {
        this.fileList2 = []
        this.form.backgPicture = ''
        this.hideUpload2 = fileList.length >= this.limitCount2
      },
      // 主题背景图片显示
      handlePictureCardPreview2(file) {
        this.dialogImageUrl2 = file.url
        this.dialogVisible2 = true
      },

      // 主题封面图片
      handleChange(file, fileList) {
        console.log(file, fileList)
        this.hideUpload = fileList.length >= this.limitCount
      },
      // 主题封面成功

      handleSuccess(response, file) {
        console.log(response, file)
        this.form.coverPicture = response.data.filePath
        this.fileList.push({
          url: response.data.filePath,
        })
      },
      // 主题封面删除图片
      handleRemove(fileList) {
        this.fileList = []
        this.form.coverPicture = ''
        this.hideUpload = fileList.length >= this.limitCount
      },
      // 主题封面图片显示
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .stepOne {
    .el-form-item {
      width: 80%;
    }
    .pay-button-group {
      display: flex;
      justify-content: center;
    }
    .themeLogo {
      ::v-deep(.hide .el-upload--picture-card) {
        display: none;
      }
    }
  }
</style>
