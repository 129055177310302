<template>
  <div class="comprehensive-table-container">
    <div class="grid-form">
      <div class="lef">
        <div class="thader-title">
          <div class="tit">血糖（mmol/L）</div>
          <i class="el-icon-edit-outline editicon"></i>
        </div>
        <el-form :model="form">
          <el-form-item label="">
            <div class="labe-qustion">
              <div class="labe">空腹</div>
              <div class="grd">
                <el-input v-model="form.empty" placeholder="请输入" />
                <el-input v-model="form.empty" placeholder="请输入" />
              </div>
            </div>
          </el-form-item>
          <el-form-item label="">
            <div class="labe-qustion">
              <div class="labe">饭前</div>
              <div class="grd">
                <el-input v-model="form.empty" placeholder="请输入" />
                <el-input v-model="form.empty" placeholder="请输入" />
              </div>
            </div>
          </el-form-item>
          <el-form-item label="">
            <div class="labe-qustion">
              <div class="labe">饭后</div>
              <div class="grd">
                <el-input v-model="form.empty" placeholder="请输入" />
                <el-input v-model="form.empty" placeholder="请输入" />
              </div>
            </div>
          </el-form-item>
          <el-form-item label="">
            <div class="labe-qustion">
              <div class="labe">睡前</div>
              <div class="grd">
                <el-input v-model="form.empty" placeholder="请输入" />
                <el-input v-model="form.empty" placeholder="请输入" />
              </div>
            </div>
          </el-form-item>
          <el-form-item label="">
            <div class="labe-qustion">
              <div class="labe">凌晨</div>
              <div class="grd">
                <el-input v-model="form.empty" placeholder="请输入" />
                <el-input v-model="form.empty" placeholder="请输入" />
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="ref">
        <div class="thader-title">
          <div class="tit">血糖（mmol/L）</div>
          <i class="el-icon-edit-outline editicon"></i>
        </div>
        <el-form :model="form">
          <el-form-item label="">
            <div class="labe-qustion">
              <div class="labe">收缩压</div>
              <div class="grd">
                <el-input v-model="form.empty" placeholder="请输入" />
                <el-input v-model="form.empty" placeholder="请输入" />
              </div>
            </div>
          </el-form-item>
          <el-form-item label="">
            <div class="labe-qustion">
              <div class="labe">舒张压</div>
              <div class="grd">
                <el-input v-model="form.empty" placeholder="请输入" />
                <el-input v-model="form.empty" placeholder="请输入" />
              </div>
            </div>
          </el-form-item>
          <el-form-item label="">
            <div class="labe-qustion">
              <div class="labe">心率（次/分）</div>
              <div class="grd">
                <el-input v-model="form.empty" placeholder="请输入" />
                <el-input v-model="form.empty" placeholder="请输入" />
              </div>
            </div>
          </el-form-item>
          <el-form-item label="">
            <div class="labe-qustion">
              <div class="labe">血氧</div>
              <div class="grd">
                <el-input v-model="form.empty" placeholder="请输入" />
                <el-input v-model="form.empty" placeholder="请输入" />
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
    }
  },
}
</script>

<style lang="scss" scoped>
.grid-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  .lef,
  .ref {
    min-height: 100px;
    .thader-title {
      width: calc(100% - 32px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tit {
        font-size: 20px;
        color: #333;
        font-weight: bold;
      }
      .editicon {
        color: #007aff;
        font-size: 32px;
      }
    }
  }
  .labe-qustion {
    .grd {
      width: calc(100% - 32px);
      display: grid;
      gap: 32px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>