var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "编辑基础信息",
            visible: _vm.dialogVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    "label-width": "130px",
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号码", prop: "idCard" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入身份证号码" },
                        model: {
                          value: _vm.ruleForm.idCard,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "idCard", $$v)
                          },
                          expression: "ruleForm.idCard",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "民族", prop: "familyName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入民族" },
                        model: {
                          value: _vm.ruleForm.familyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "familyName", $$v)
                          },
                          expression: "ruleForm.familyName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "血型", prop: "bloodType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择血型" },
                          model: {
                            value: _vm.ruleForm.bloodType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "bloodType", $$v)
                            },
                            expression: "ruleForm.bloodType",
                          },
                        },
                        _vm._l(_vm.bloodTypes, function (ym, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: ym.labelName, value: ym.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "职业", prop: "profession" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入职业" },
                        model: {
                          value: _vm.ruleForm.profession,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "profession", $$v)
                          },
                          expression: "ruleForm.profession",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "婚姻状况", prop: "marStatus" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择婚姻状况" },
                          model: {
                            value: _vm.ruleForm.marStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "marStatus", $$v)
                            },
                            expression: "ruleForm.marStatus",
                          },
                        },
                        _vm._l(_vm.marStatus, function (ym, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: ym.labelName, value: ym.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电子邮件", prop: "email" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入电子邮件" },
                        model: {
                          value: _vm.ruleForm.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "email", $$v)
                          },
                          expression: "ruleForm.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "紧急联系人", prop: "emerName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入紧急联系人" },
                        model: {
                          value: _vm.ruleForm.emerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "emerName", $$v)
                          },
                          expression: "ruleForm.emerName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "紧急联系人电话", prop: "emerPhone" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入紧急联系人电话" },
                        model: {
                          value: _vm.ruleForm.emerPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "emerPhone", $$v)
                          },
                          expression: "ruleForm.emerPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "工作单位", prop: "workUnit" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入工作单位" },
                        model: {
                          value: _vm.ruleForm.workUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "workUnit", $$v)
                          },
                          expression: "ruleForm.workUnit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所在地区", prop: "name" } },
                    [
                      _c("el-cascader", {
                        staticClass: "full-width",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center",
                          width: "215px",
                          height: "32px",
                        },
                        attrs: {
                          options: _vm.areaSelectData,
                          placeholder: "请选择地址",
                          props: {
                            children: "children",
                            label: "areaName",
                            value: "areaName",
                          },
                          size: "large",
                        },
                        on: { change: _vm.addres },
                        model: {
                          value: _vm.provinces,
                          callback: function ($$v) {
                            _vm.provinces =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "provinces",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "详细地址", prop: "addressDetail" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入详细地址" },
                        model: {
                          value: _vm.ruleForm.addressDetail,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "addressDetail", $$v)
                          },
                          expression: "ruleForm.addressDetail",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "" } }),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入备注", type: "textarea" },
                        model: {
                          value: _vm.ruleForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "remark", $$v)
                          },
                          expression: "ruleForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }