<template>
  <div class="YmTkx">
    <el-button class="ym_btns" type="primary" @click="addForm">新增</el-button>
    <el-form label-width="80px" :model="forms">
      <el-form-item label="计划主题">
        <el-input
          v-model="forms.mealName"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入内容"
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="计划类型">
        <el-radio v-model="forms.mealType" label="1">七天计划</el-radio>
        <el-radio v-model="forms.mealType" label="2">十五天计划</el-radio>
      </el-form-item>

      <div
        v-for="(ymc, index) in forms.plans"
        :key="index"
        class="YmForm active"
      >
        <el-form-item label="计划时间">
          <el-date-picker
            v-model="forms.plans[index].timer"
            :picker-options="pickerOptions"
            placeholder="选择计划时间"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="计划名称">
          <div class="ym_tag">
            <div
              v-for="(cc, indexs) in ymc.planItems"
              :key="indexs"
              class="div"
            >
              {{ cc.planName }}
            </div>
          </div>
          <el-button type="primary" @click="gets(index)">
            添加计划名称
          </el-button>
          <el-dialog
            append-to-body
            :center="true"
            title="计划列表"
            :visible.sync="dik"
          >
            <DialogYm :yms="current" @transfer="chekc" />
          </el-dialog>
        </el-form-item>
        <el-form-item label="饮食搭配">
          <div class="ym_tag">
            <div
              v-for="(ymjg, indexs) in ymc.planmatch"
              :key="indexs"
              class="div"
            >
              {{ ymjg.subName }}
            </div>
          </div>
          <el-button type="primary" @click="getsa(index)">
            添加饮食搭配
          </el-button>
          <el-dialog append-to-body border title="" :visible.sync="diks">
            <div
              class="ym_thead"
              style="
                padding-bottom: 20px;
                margin: -20px 0 20px 0;
                font-size: 18px;
                border-bottom: 1px dashed #e9e9e9;
              "
            >
              饮食搭配
            </div>
            <FoodPairing :match="currents" @transfers="chekcs" />
          </el-dialog>
        </el-form-item>
        <i
          class="el-icon-circle-close ymmk"
          :size="24"
          @click="deleteForm(index)"
        ></i>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </div>
</template>

<script>
  import DialogYm from './dialogs.vue'
  import FoodPairing from './FoodPiring.vue'
  import { addVitMealUserPlan } from '@/api/mall/health/userDict/digan'
  export default {
    name: 'DialogDemo',
    components: {
      DialogYm,
      FoodPairing,
    },
    props: {
      ymdialog: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        forms: {
          userId: '',
          mealName: '',
          mealType: '',
          plans: [],
        }, // 存储表单数据的数组
        ym: 0,
        dik: false,
        current: '',
        diks: false,
        currents: '',
        pickerOptions:{
          disabledDate(time) {
              return time.getTime() < Date.now() - 8.64e7;
          }
        }
      }
    },
    created() {},
    methods: {
      // 取消事件
      close() {
        ;(this.forms = {
          userId: '',
          mealName: '',
          mealType: '',
          plans: [],
        }),
          this.$emit('transfer', false)
      },
      save() {
        const pamars = {
          userId: this.ymdialog.userCode,
          mealName: this.forms.mealName,
          mealType: this.forms.mealType,
          plans: this.forms.plans,
        }
        addVitMealUserPlan(pamars)
          .then(() => {
            this.MS('发布成功')
          })
          .catch(() => {})
      },

      addForm() {
        this.forms.plans.push({})
      },
      gets(e) {
        this.dik = true
        this.current = e
      },
      getsa(e) {
        this.diks = true
        this.currents = e
      },
      chekc(e) {
        this.dik = e.ifshow
        this.forms.plans[this.current].planItems = e.planItems
      },
      chekcs(e) {
        this.diks = false
        let obj = e.id.map((value,index)=>{
          return {
            id:value,
            subName:e.subName[index]
          }
        })
        this.forms.plans[this.currents].planmatch = obj
      },
      deleteForm(index) {
        this.forms.plans.splice(index, 1) 
    },
  }}
</script>

<style lang="scss" scoped>
  .YmTkx {
    width: 100%;
    .ym_btns {
      margin-bottom: 30px;
      margin-left: 15px;
    }
    .YmForm {
      position: relative;
      padding: 10px;
      margin: 20px 0;
    }
    .active {
      background: #f8f8f9;
    }
    .dialog-footer {
      display: flex;
      justify-content: right;
    }
    .ym_tag {
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;
      .ym_delete {
        color: #3782ff;
        cursor: pointer;
      }
    }
    .ymmk {
      position: absolute;
      top: 10px;
      right: 20px;
    }
    .ym_tag {
      display: flex;
      .div {
        margin-right: 5px;
      }
    }
  }
</style>
