import request from '@/utils/request'
// 版本列表
export function queryPage(data) {
  return request({
    url: '/mall/setup/stpVersionLog/queryPage',
    method: 'post',
    data,
  })
}
// 添加
export function addVersion(data) {
  return request({
    url: '/mall/setup/stpVersionLog/add',
    method: 'post',
    data,
  })
}
// 编辑
export function editVersion(data) {
  return request({
    url: '/mall/setup/stpVersionLog/edit',
    method: 'post',
    data,
  })
}
// 删除
export function isDelete(data) {
  return request({
    url: '/mall/setup/stpVersionLog/isDelete',
    method: 'post',
    data,
  })
}
// 版本列表
export function queryListVer() {
  return request({
    url: '/mall/setup/stpVersionLog/queryList',
    method: 'post',
  })
}
