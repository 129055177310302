var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "before-close": _vm.claer,
            title: "客情部门",
            visible: _vm.appointDialog,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.appointDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                "label-width": "130px",
                model: _vm.addForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户姓名", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      disabled: "",
                      placeholder: "请输入用户名",
                    },
                    model: {
                      value: _vm.uslist.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.uslist, "userName", $$v)
                      },
                      expression: "uslist.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "postPare", attrs: { label: "指派部门" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: "", placeholder: "" },
                      model: {
                        value: _vm.addForm.mallcategory,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "mallcategory", $$v)
                        },
                        expression: "addForm.mallcategory",
                      },
                    },
                    _vm._l(_vm.dataSourceLists, function (ym, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: ym.orgName, value: ym.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客情类型", prop: "afterTypeId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择 客情类型" },
                      model: {
                        value: _vm.addForm.afterTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "afterTypeId", $$v)
                        },
                        expression: "addForm.afterTypeId",
                      },
                    },
                    _vm._l(_vm.optionslist, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.labelName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.addForm.afterTypeId == 1
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户客情时间", prop: "afterTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.afterTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "afterTime", $$v)
                              },
                              expression: "addForm.afterTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户签收金额" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "客户签收金额" },
                            model: {
                              value: _vm.addForm.signAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "signAmount", $$v)
                              },
                              expression: "addForm.signAmount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户反馈情况", prop: "issueMark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "客户反馈情况",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.addForm.issueMark,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "issueMark", $$v)
                              },
                              expression: "addForm.issueMark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.addForm.afterTypeId == 2
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户签收时间", prop: "signTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.signTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "signTime", $$v)
                              },
                              expression: "addForm.signTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户反馈时间", prop: "feedTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.feedTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "feedTime", $$v)
                              },
                              expression: "addForm.feedTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品问题描述", prop: "issueMark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "产品问题描述",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.addForm.issueMark,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "issueMark", $$v)
                              },
                              expression: "addForm.issueMark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.addForm.afterTypeId == 3
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户签收时间", prop: "signTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.signTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "signTime", $$v)
                              },
                              expression: "addForm.signTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户反馈时间", prop: "feedTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.feedTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "feedTime", $$v)
                              },
                              expression: "addForm.feedTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品问题描述", prop: "issueMark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "产品问题描述",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.addForm.issueMark,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "issueMark", $$v)
                              },
                              expression: "addForm.issueMark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.addForm.afterTypeId == 4
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户签收时间", prop: "signTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.signTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "signTime", $$v)
                              },
                              expression: "addForm.signTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户反馈时间", prop: "feedTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.feedTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "feedTime", $$v)
                              },
                              expression: "addForm.feedTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品问题描述", prop: "issueMark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "产品问题描述",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.addForm.issueMark,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "issueMark", $$v)
                              },
                              expression: "addForm.issueMark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "客情备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "备注信息", type: "textarea" },
                    model: {
                      value: _vm.addForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "remark", $$v)
                      },
                      expression: "addForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clear } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }