<template>
  <div id="app">
    <router-view />
    <Websoket />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
<style lang="scss">
@import './styles/cyindex.scss';
@import './styles/cc.css';

::v-deep(.el-tabs__item) {
  font-size: 13px !important;
}
</style>
