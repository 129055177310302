<template>
  <div>
    <el-dialog :before-close="handleClose" title="处理记录" :visible.sync="appointDialog" width="60%">
      <el-table border :data="uslist" :max-height="580" style="width: 100%">
      <el-table-column  align="center"  label="日期"  prop="createTime" width="150" />
      <el-table-column align="center" :formatter="getShowInfo" label="血压" prop="indicatorType" width="100" />
          <el-table-column align="center" :formatter="getShowInfo" label="血糖" prop="indicatorType" width="100" />
          <el-table-column align="center" :formatter="getShowInfo" label="心率" prop="indicatorType" width="100" />
      <el-table-column align="center" label="备注" prop="note"/>
      <!-- <el-table-column align="center" label="状态" prop="status"/> -->
      <el-table-column align="center" label="状态" prop="status">
    <template slot-scope="scope">
      <span v-if="scope.row.status === 0">未处理</span>
      <span v-else-if="scope.row.status === 1">已处理</span>
    </template>
  </el-table-column>
    </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { orgTree } from '@/api/mall/role/orgManage'
export default {
  props:{
    showtab:{
      type:Boolean,
      default:()=>{}
    },
    hanuser:{
      type:[Object, Array],
      default:()=>{}
    },
  },
  data(){
    return{
      appointDialog: false,
      dataSourceLists: [],
      addForm: {
      },
      uslist: this.hanuser,
    }
  },
  watch:{
    showtab(newValue,oldValue){
      if(newValue!=oldValue){
        this.appointDialog=newValue
      }
    },
    hanuser(newValue,oldValue){
      if(newValue!=oldValue){
        this.uslist=newValue
      }
    }
  },
  created(){
    // this.fetchData()
  },
  methods:{
    getcasc(e){
      console.log(e)
      console.log(this.dataSourceLists)
    },
    getShowInfo(row, cellValue) {
      const abnormalTypeMap = {
    0: { text: '正常', color: 'green' },   // 正常 - 绿色
    1: { text: '偏小', color: 'orange' },  // 偏小 - 橙色
    2: { text: '偏大', color: 'red' }      // 偏大 - 红色
  };
      if (row.indicatorType == 1 && cellValue.label == '血压') {
        return  abnormalTypeMap[row.abnormalType] || '';
        // abnormalTypeMap[row.abnormalType] || ''
      }
      if (row.indicatorType == 2 && cellValue.label == '血糖') {
        return abnormalTypeMap[row.abnormalType] || '';
        // abnormalTypeMap[row.abnormalType] || ''
      }
      if (row.indicatorType == 3 && cellValue.label == '心率') {
        return abnormalTypeMap[row.abnormalType] || '';
        // abnormalTypeMap[row.abnormalType] || ''
      }
    },
    async fetchData() {
      this.listLoading = true
      const parms = {
        current: this.current,
        size: this.size,
      }
      await orgTree(parms)
        .then((res) => {
          if (res.data) {
            this.menuListTable = res.data.records
            this.dataSourceLists = res.data.records
            this.dataSourceLists.map((obj) => {
            obj.label = obj.orgName
            obj.value = obj.id
            const fieldArr = obj.children
            const newFieldArr = fieldArr.map((element) => {
              element.label = element.orgName
              element.value = element.id
              element.children.forEach((item) => {
                item.label = item.orgName
                item.value = item.id
                if(item.children!=null){
                  item.children.forEach(el=>{
                    el.label = el.orgName
                    el.value = el.id
                  })
                }
              })
              return element
            })
            obj.fieldArr = newFieldArr
            return obj
          })
          }
        })
        .catch(() => {})
      this.listLoading = false
    },
    clear(){
      this.$emit('closover',false)
      // this.appointDialog = false
    },
    handleClose(){
      this.$emit('closover',false)
    },
    teaChange(e) {
      if (!e.length) {
        this.appointData.forEach((item) => {
          item.disabled = false
        })
      }
    },
    leftChange(e) {
      this.appointData.forEach((item) => {
        if (e[0] == item.id) item.disabled = false
        else item.disabled = true
      })
    },
    sure() {
        this.appointDialog = false,
        this.$emit('closover',false)
      },
  }
}
</script>

<style>

</style>