<template>
  <el-dialog :before-close="handleClose" title="" :visible.sync="dialogVisible" width="60%">
    <span>
      <div class="content">
        <div class="search">
          <el-form :model="search" style="display: grid;grid-template-columns: repeat(2,1fr);">
            <el-form-item label="" style="width: 100%;">
              <el-input v-model="search.phone"  placeholder="请输入手机号"  style="width: 100%;">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </el-form-item>
            <el-form-item label="">
              <el-button type="primary" @click="searchdate">搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="user_list">
          <div v-for="(ym, index) in userlist" :key="index" class="user_info" :class="cur == index ? 'active' : ''" @click="check(index)">
            <div class="user_picurl">
              <el-image :src="ym.headimgUrl" style="width: 40px; height: 40px; border-radius: 100%" />
            </div>
            <div class="user_msg">
              <div class="user_name" style="width: 100px; margin: 2px 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                {{ ym.nickName }}
              </div>
              <div class="user_name" style="margin: 2px 0">编号:{{ ym.userCode }}</div>
              <div class="userphone" style="margin: 2px 0">手机号:{{ ym.phone }}</div>
            </div>
          </div>
        </div>
      </div>
      <el-pagination :current-page="1" layout="total, sizes, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="clear">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { userinfoselect } from '@/api/mall/distUsers/list'
  export default {
    props: {
      hidemenber: {
        type: Boolean,
        default: () => {},
      },
    },
    data() {
      return {
        dialogVisible: this.hidemenber,
        search: {},
        form: {
          size: 10,
          current: 1,
        },
        total: 0,
        userlist: [],
        cur: null,
      }
    },
    watch: {
      hidemenber(newValue, oldValue) {
        if (newValue != oldValue) {
          this.dialogVisible = newValue
        }
      },
    },
    created() {
      this.userinfoselect()
    },
    methods: {
      searchdate(){
        userinfoselect({ ...this.search }).then((res) => {
          this.form.size = res.data.size
          this.form.current = res.data.current
          this.total = res.data.total
          this.userlist = res.data.records
        })
      },
      userinfoselect() {
        userinfoselect({ ...this.form }).then((res) => {
          this.form.size = res.data.size
          this.form.current = res.data.current
          this.total = res.data.total
          this.userlist = res.data.records
        })
      },
      handleClose() {
        this.$emit('menbarrow', null)
      },
      handleCurrentChange(e) {
        this.form.current = e
        console.log(e)
        this.userinfoselect()
      },
      handleSizeChange(e) {
        console.log(e)
        this.form.size = e
        this.userinfoselect()
      },
      check(e) {
        this.cur = e
      },
      clear() {
        this.$emit('menbarrow', null)
      },
      save() {
        this.$emit('menbarrow', this.userlist[this.cur])
      },
    },
  }
</script>

<style lang="scss" scoped>
  .user_list {
    display: flex;
    // grid-template-columns: repeat(5,1fr);
    flex-wrap: wrap;
  }
  .user_info {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 200px;
    height: 95px;
    margin: 10px;
    margin-top: 20px;
    font-size: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    .user_picurl {
      margin-left: 10px;
    }
  }
  .active {
    border: 1px solid #3782ff;
  }
</style>
