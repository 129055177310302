<template>
  <div>
    <el-dialog append-to-body :before-close="handleClosex" title="上传附件" :visible.sync="dialogVisible" width="50%">
      <el-form class="demo-ruleForm" label-width="100px">
        <el-form-item label="文件上传：">
          <!-- 上传 -->
          <el-upload :action="action" :before-remove="beforeRemove" class="upload-demo" :data="additionalData"
            :file-list="fileList" :headers="{ Authorization: uploadParams.token }" :limit="limitnum" multiple
             :on-preview="handlePreview" :on-remove="handleRemove" :on-success="handleSuccess">
            <el-button size="small" type="primary" @click="upload">点击上传</el-button>
          </el-upload>
          <p v-if="fileLength === 0 && handleType" style="font-size: 12px;color:red">请上传文件</p>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleClose()">取 消</el-button>
          <el-button :disabled="fileType" type="primary" @click="handleOk()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import { uploadURLfill } from '@/config'
import { getToken } from '@/utils/token'
import store from '@/store'
import { getCancelUploadFile } from '@/api/mall/health/upload/index'
export default {
  name: 'Upload',
  props: {
    visible: {
      type: Boolean,
      default: () => { }
    },
    comid: {
      type: Number,
      default: () => { }
    },
    limitnum:{
      type: Number,
      default: () => { }
    }
  },
  data() {
    var validateFile = (rule, value, callback) => {
      console.log(rule, 'rule')
      console.log(value, 'value')
      console.log(callback, 'callback')
      if (value == []) {
        callback(new Error('请上传文件'));
      } else {
        callback();
      }
    };
    return {
      action: uploadURLfill,
      loading: false,
      fileList: [],
      fileData: [],
      links: [],
      fileLength: 0,
      fileType:true,
      rules: {
        fileList: [{ validator: validateFile, trigger: ['change', 'blur'] }]
      },
      dialogVisible: false,
      allDisable: false,
      title: '',
      dialogFormVisible: false,
      for: {},
      column: this.options,
      handleType: false,
      headers: {
        Authorization: 'Bearer ' + store.getters['user/token'],
        'switch-tenant-id': 1,
      },
      uploadParams: {
        token: `Bearer ${getToken()}`,
        uploadURLfill,
        fileList: [],
        dialogImageUrl: '',
      },
      additionalData: {
        dir: 'mall/order/',
      }
    };
  },
  watch: {
    visible(newValue, oldValue) {
      if (newValue != oldValue) {
        this.dialogVisible = newValue
      }
      if (!newValue) {
        this.fileList = []
      }
    },
    comid(newValue) {
      if (newValue) {
        this.additionalData.comId = newValue
      }
    },
  },
  created() {
    console.log(this.fileData, 'this.fileData')
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList, 'file, fileList')
      console.log(fileList.length)
      if (fileList.length === 0) {
        this.handleType = true
        this.fileLength = 0
        this.fileType = true
      } else {
        this.fileType = false
        this.handleType = false
        this.fileLength = fileList.length
      }
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeRemove(file, fileList) {
      console.log(fileList, 'fileList')
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 取消
    handleClose() {
      this.handleType = false
      this.$emit('closeUpload', false)
      this.fileList = []
      console.log(this.fileData, 'this.fileData')
      this.fileData.forEach((item) => {
        this.links.push(item.response.link)
      })
      getCancelUploadFile({ links: this.links }).then((res) => {
        this.links = []
        this.fileData = []
        console.log(res, 'resres')
      })
      console.log(this.links, 'this.links')
    },
    handleClosex() {
      this.$emit('closeUpload', false)
    },
    handleSuccess(response, file, fileList) {
      this.fileData = fileList
      this.fileLength = fileList.length
      console.log(response, file, fileList, 'response, file, fileList')
      this.fileType = false
    },
    handleOk() {
      this.handleType = true
      console.log(this.fileList, 'fileList147')
      if (this.fileLength != 0) {
        this.$emit('fillData', this.fileData)
        this.$emit('closeUpload', false)
        this.dialogVisible = false
        this.fileData = []
      }
    }
  }
}
</script>

<style scoped></style>