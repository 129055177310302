<template>
  <div>
    <el-dialog
      :before-close="handleClose"
      title="新增"
      :visible.sync="dialogVisible"
      width="60%">
      <span>
        <el-form ref="addForm" label-width="95px" :model="formData" :rules="rules">
          <el-form-item label="店铺：" prop="shopId">
            <el-select v-model="formData.shopId" placeholder="请选择店铺" @change="shop">
              <el-option v-for="(ym,index) in selction" :key="index" :label="ym.name" :value="ym.id"/>
            </el-select>
          </el-form-item>
          <el-form-item label="名称：" prop="name">
            <el-input v-model="formData.name" placeholder="请输入名称"/>
          </el-form-item>
          <el-form-item label="排序：" prop="sort">
            <el-input-number v-model="formData.sort" controls-position="right" :min="1" />
          </el-form-item>
          <el-form-item label="库存：" prop="stock">
            <el-input-number v-model="formData.stock" controls-position="right" :min="1" />
          </el-form-item>
          <el-form-item label="订单金额：" prop="premiseAmount">
            <el-input-number v-model="formData.premiseAmount" controls-position="right" :min="1" />
          </el-form-item>
          <el-form-item label="类型：" prop="type">
            <el-radio-group v-model="formData.type">
              <el-radio label="1">代金券</el-radio>
              <el-radio label="2">折扣券</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="formData.type==1" label="减免金额：" prop="reduceAmount">
            <el-input-number v-model="formData.reduceAmount" controls-position="right" :min="1" />
          </el-form-item>
          <el-form-item v-if="formData.type==2" label="折扣率：" prop="discount">
            <el-input-number v-model="formData.discount" controls-position="right" :min="1" />
          </el-form-item>
          <el-form-item label="到期类型：" prop="expireType">
            <el-radio-group v-model="formData.expireType">
              <el-radio label="1">领券后生效</el-radio>
              <el-radio label="2">固定时间段</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="formData.expireType==1" label="有效天数：" prop="validDays">
            <el-input-number v-model="formData.validDays" controls-position="right" :min="1" />
          </el-form-item>
          <el-form-item v-if="formData.expireType==2" label="时间范围："  >
            <el-date-picker
              v-model="day"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="datetimerange"
              @change="changeDate"/>
          </el-form-item>
          <el-form-item label="是否启用：" prop="enable">
            <el-radio-group v-model="formData.enable">
              <el-radio label="0">关闭</el-radio>
              <el-radio label="1">启用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="适用商品：" prop="suitType">
            <el-radio-group v-model="formData.suitType" @input="changesuit">
              <el-radio label="1">全部商品</el-radio>
              <el-radio label="2">指定商品</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="formData.suitType==2" label="指定商品：" prop="listGoodsSpu">
            <el-button icon="el-icon-plus" type="primary" @click="addrow">添加</el-button>
          </el-form-item>
            
        </el-form>
        <el-row>
          <el-col v-for="(ym, index) in listGoodsSpu" :key="index" :offset="1" :span="4">
            <el-card :body-style="{ padding: '5px' }">
              <img class="image" :src="ym.picUrls" style="width: 80px; height: 100px;">
              <div style="">
                <el-tooltip class="item" :content="ym.name" effect="dark" placement="top-start">
                  <span style="display:block; width: 80px;text-align: left;  white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ym.name}}</span>
                </el-tooltip>
                <div class="bottom clearfix">
                  <el-button style="color: red;" type="text" @click="remove(index)">移除</el-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clears">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
    <shoplist :shopis="ships" :showadd="hide" @closerows="daterow"/>
  </div>
</template>

<script>
import {addPage} from "@/api/mall/markiting/electronicmaking"
import shoplist from "./shoplist.vue"
export default {
  name:'Newcoupon',
  components:{shoplist},
  props:{
    shoprow:{
      type:Array,
      default:()=>{}
    },
    hideadd:{
      type:Boolean,
      default:()=>{}
    }
  },
  data(){
    return{
      selction:this.shoprow,
      dialogVisible:this.hideadd,
      formData:{},
      ships:null,
      day:[],
      hide:false,
      listGoodsSpu:[],
      rules:{
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        shopId: [
          { required: true, message: '请选择店铺', trigger: 'change' }
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' },
        ],
        stock: [
          { required: true, message: '请输入库存', trigger: 'blur' },
        ],
        premiseAmount: [
          { required: true, message: '请输入订单金额', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'change' },
        ],
        reduceAmount: [
          { required: true, message: '请输入减免金额', trigger: 'blur' },
        ],
        discount: [
          { required: true, message: '请输入折扣率', trigger: 'blur' },
        ],
        expireType: [
          { required: true, message: '请选择到期类型', trigger: 'blur' },
        ],
        validDays: [
          { required: true, message: '请输入有效天数', trigger: 'blur' },
        ],
        enable: [
          { required: true, message: '请选择是否启用', trigger: 'blur' },
        ],
        suitType: [
          { required: true, message: '请选择适用商品', trigger: 'blur' },
        ],
      }
    }
  },
  watch:{
    shoprow(newValue,oldValue){
      if(newValue!=oldValue){
        this.selction=newValue
      }
    },
    hideadd(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisible=newValue
      }
    }
  },
  methods:{
    changeDate(){
      this.formData.validBeginTime=this.day[0]
      this.formData.validEndTime=this.day[1]
      console.log(this.formData)
    },
    handleClose(){
      this.formData={}
      this.$emit('closehide',false)
    },
    save(){
      if(this.formData.type==1){
        delete this.formData.discount
      }
      if(this.formData.type==2){
        delete this.formData.reduceAmount
      }
      if(this.formData.expireType==1){
        delete this.formData.validBeginTime
        delete this.formData.validEndTime
      }
      if(this.formData.expireType==2){
        delete this.formData.validDays
      }
      if(this.formData.suitType==1){
        delete this.formData.listGoodsSpu
        this.listGoodsSpu=[]
      }
      this.formData.listGoodsSpu=[]
      this.listGoodsSpu.forEach(item=>{
        this.formData.listGoodsSpu.push({id:item.id})
      })
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.addPage()
        } else {
          return false;
        }
      });
    },
    addPage(){
      addPage({...this.formData}).then(()=>{
        this.$message.success("新增成功")
        this.formData={}
        this.$emit('closehide',false)
      })
    },
    changesuit(e){
     if(e==2){
      if(this.formData.shopId==undefined){
        this.$message.error("请选择店铺")
        this.formData.suitType=''
      }
     }
     else{
      delete this.formData.listGoodsSpu
      this.listGoodsSpu=[]
     }
    },
    addrow(){
      this.hide=true
    },
    daterow(e){
      this.listGoodsSpu=e
      this.hide=false
    },
    shop(e){
      this.ships=e
    },
    remove(e){
      this.listGoodsSpu.splice(e,1)
    },
    clears(){
      this.formData={}
      this.$emit('closehide',false)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-input-number--small{
    width: 215px;
  }
  .el-form{
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  .el-dialog__header{
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>