
<template>
  <div>
    <!-- 文章详情 -->
    <el-dialog v-if="detailDialog" class="detailDialog" :visible.sync="detailDialog">
      <div class="waper">
        <div>
          <p class="artTitle">{{ articleForm.artTitle }}</p>
          <div class="artdiv">
            <p class="artli">
              <span>{{ articleForm.createTime | formatDate }}</span>
              <span class="ml30 mr5">{{ articleForm.artTypeName }}</span>
              <span>/</span>
              <span class="ml5 mr5">
                {{ articleForm.artColumnName }}
              </span>
              <span v-if="articleForm.artTags">/</span>
              <span class="ml5">{{ articleForm.artTags }}</span>
            </p>
          </div>
        </div>
        <div style="padding: 10px 60px">
          <div class="mt30">
            <span>摘要：</span>
            <span>{{ articleForm.artShort }}</span>
          </div>
          <div v-if="articleForm.artLogo" class="mt20 articlelis" style=" width: 40%; height: 40%;margin: 20px auto">
            <img
alt="" fit="fill" :src="articleForm.artLogo" srcset=""
              style=" width: 100%; height: 100%;margin: 0px auto" />
          </div>
          <div v-if="articleForm.artImg" class="mt20 articlelis" style=" width: 63%; height: 52%;margin: 20px auto">
            <img
alt="" fit="fill" :src="articleForm.artImg" srcset=""
              style=" width: 100%; height: 100%;margin: 0px auto" />
          </div>

          <div class="mt30 articlelis" style="text-indent: 30px" v-html="articleForm.artContent"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { details } from '@/api/mall/article/release'
export default {
  data() {
    return {
      detailDialog: false,
      articleForm: {},
      rowId: '',
    }
  },
  methods: {
    details() {
      details({ id: this.rowId })
        .then((res) => {
          if (res.data) {
            this.articleForm = res.data
            let reg = /,$/gi
            this.articleForm.artColumnName =
              this.articleForm.artColumnName.replace(reg, '')
          }
        })
        .catch(() => { })
    },
    shows(row) {
      this.rowId = row.id
      this.detailDialog = true
      this.details()
    },
  },
}
</script>
<style lang="scss" scoped>
.articlelis {
  ::v-deep(img) {
    width: 100%;
    height: 400px;
  }
}

::v-deep(.ql-align-center) {
  text-align: center;
}

.detailDialog {
  p {
    padding: 0;
    margin: 0;
  }

  .artTitle {
    padding: 0px 10px 10px 10px;
    margin: 0px;
    margin-top: 0px;
    font-size: 24px;
    color: #000;
    text-align: center;
  }

  .artli {
    padding-top: 10px;
    font-size: 14px;
    line-height: 28px;
    color: #666;
    text-indent: 2em;
  }

  .artdiv {
    padding: 0 0 26px;
    overflow: hidden;
    font-size: 12px;
    line-height: 20px;
    color: #999;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
}
</style>
