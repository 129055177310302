export const contentCanvas = [
  // 小程序
  new Map([
    ['MemberMyOrder', () => import('./MemberMyOrder/index.vue')], // 我的订单
    ['Search', () => import('./search/index.vue')], // 自定义头部
    ['Graphicnavigation', () => import('./Graphic-navigation/index.vue')], // 自定义头部
    ['brandList', () => import('./brandList/index.vue')], // 会员个人页面
    ['ManyGoodsList', () => import('./Multi-commodity-group/index.vue')], // 多商品组
    ['Text', () => import('./Text/index.vue')], // 标题
    // ['topheader', () => import('./basics/header/app-copy/index.vue')], // 自定义头部
    // ['header', () => import('./basics/header/app/index.vue')], // 首页头部
    ['banner', () => import('./banner/index.vue')], // 轮播图
    ['Article', () => import('./Article/index.vue')], // 文章
    ['Healthconsultant', () => import('./Healthconsultant/index.vue')], // 健康顾问
    ['Listnavigation', () => import('./Listnavigation/index.vue')], // 列表导航
    ['RubikCube', () => import('./RubikCube/index.vue')], // 魔方
    ['GoodsLiss', () => import('./Commoditylist/index.vue')], // 魔方
    // ['text', () => import('./basics/text.vue')], // 文本
    // ['imageText', () => import('./basics/imageText.vue')], // 图文
    // ['brandList', () => import('./basics/brandList.vue')], // 品牌列表
    // ['categoryList', () => import('./basics/categoryList.vue')], // 品牌列表
    // ['imageTextList', () => import('./basics/imageTextList.vue')], // 图文列表
    // ['assistDiv', () => import('./basics/assistDiv.vue')], // 铺助分割
    // ['imageTextNav', () => import('./basics/imageTextNav.vue')], // 图文导航
    // ['productList', () => import('./basics/product/app/index.vue')], // 商品列表
    // ['videoBox', () => import('./basics/video.vue')], // 视频
    // ['coupon', () => import('./basics/coupon/app/index.vue')], // 优惠券
    // ['custom', () => import('./basics/custom.vue')], // 自定义
    // ['notice', () => import('./basics/notice.vue')], // 公告
    // ['vip', () => import('./basics/vip/app/index.vue')], // 会员专区
    // ['groupList', () => import('./basics/group/app/index.vue')], // 拼团专区
    // ['spikeList', () => import('./basics/spike/app/index.vue')], // 秒杀专区
    // ['priceList', () => import('./basics/price/app/index.vue')], // 定价捆绑
    // ['discountList', () => import('./basics/discount/app/index.vue')], // 限时折扣
    // ['newProduct', () => import('./basics/newProduct/app/index.vue')], // 每日上新
    // ['live', () => import('./basics/live/app/index.vue')], // 直播
    // ['shop', () => import('./basics/shop.vue')], // 每日好店
  ]),
  // // H5
  // new Map([
  //   ['topheader', () => import('./basics/header/app-copy/index.vue')], // 自定义头部
  //   ['header', () => import('./basics/header/app/index.vue')], // 首页头部
  //   ['banner', () => import('./basics/banner.vue')], // 轮播图
  //   ['text', () => import('./basics/text.vue')], // 文本
  //   ['imageText', () => import('./basics/imageText.vue')], // 图文
  //   ['brandList', () => import('./basics/brandList.vue')], // 品牌列表
  //   ['categoryList', () => import('./basics/categoryList.vue')], // 品牌列表
  //   ['imageTextList', () => import('./basics/imageTextList.vue')], // 图文列表
  //   ['assistDiv', () => import('./basics/assistDiv.vue')], // 铺助分割
  //   ['imageTextNav', () => import('./basics/imageTextNav.vue')], // 图文导航
  //   ['productList', () => import('./basics/product/app/index.vue')], // 商品列表
  //   ['videoBox', () => import('./basics/video.vue')], // 视频
  //   ['coupon', () => import('./basics/coupon/app/index.vue')], // 优惠券
  //   ['custom', () => import('./basics/custom.vue')], // 自定义
  //   ['notice', () => import('./basics/notice.vue')], // 公告
  //   ['vip', () => import('./basics/vip/app/index.vue')], // 会员专区
  //   ['groupList', () => import('./basics/group/app/index.vue')], // 拼团专区
  //   ['spikeList', () => import('./basics/spike/app/index.vue')], // 秒杀专区
  //   ['priceList', () => import('./basics/price/app/index.vue')], // 定价捆绑
  //   ['discountList', () => import('./basics/discount/app/index.vue')], // 限时折扣
  //   ['newProduct', () => import('./basics/newProduct/app/index.vue')], // 每日上新
  //   ['live', () => import('./basics/live/app/index.vue')], // 直播
  //   ['shop', () => import('./basics/shop.vue')], // 每日好店
  // ]),
  // // APP
  // new Map([
  //   ['topheader', () => import('./basics/header/app-copy/index.vue')], // 自定义头部
  //   ['header', () => import('./basics/header/app/index.vue')], // 首页头部
  //   ['banner', () => import('./basics/banner.vue')], // 轮播图
  //   ['text', () => import('./basics/text.vue')], // 文本
  //   ['imageText', () => import('./basics/imageText.vue')], // 图文
  //   ['brandList', () => import('./basics/brandList.vue')], // 品牌列表
  //   ['categoryList', () => import('./basics/categoryList.vue')], // 品牌列表
  //   ['imageTextList', () => import('./basics/imageTextList.vue')], // 图文列表
  //   ['assistDiv', () => import('./basics/assistDiv.vue')], // 铺助分割
  //   ['imageTextNav', () => import('./basics/imageTextNav.vue')], // 图文导航
  //   ['productList', () => import('./basics/product/app/index.vue')], // 商品列表
  //   ['videoBox', () => import('./basics/video.vue')], // 视频
  //   ['coupon', () => import('./basics/coupon/app/index.vue')], // 优惠券
  //   ['custom', () => import('./basics/custom.vue')], // 自定义
  //   ['notice', () => import('./basics/notice.vue')], // 公告
  //   ['vip', () => import('./basics/vip/app/index.vue')], // 会员专区
  //   ['groupList', () => import('./basics/group/app/index.vue')], // 拼团专区
  //   ['spikeList', () => import('./basics/spike/app/index.vue')], // 秒杀专区
  //   ['priceList', () => import('./basics/price/app/index.vue')], // 定价捆绑
  //   ['discountList', () => import('./basics/discount/app/index.vue')], // 限时折扣
  //   ['newProduct', () => import('./basics/newProduct/app/index.vue')], // 每日上新
  //   ['live', () => import('./basics/live/app/index.vue')], // 直播
  //   ['shop', () => import('./basics/shop.vue')], // 每日好店
  // ]),
  // // PC
  // new Map([
  //   ['topheader', () => import('./basics/header/app-copy/index.vue')], // 自定义头部
  //   ['header', () => import('./basics/header/pc/index.vue')], // 首页头部
  //   ['banner', () => import('./basics/banner.vue')], // 轮播图
  //   ['text', () => import('./basics/text.vue')], // 文本
  //   ['imageText', () => import('./basics/imageText.vue')], // 图文
  //   ['brandList', () => import('./basics/brandList.vue')], // 品牌列表
  //   ['categoryList', () => import('./basics/categoryList.vue')], // 品牌列表
  //   ['imageTextList', () => import('./basics/imageTextList.vue')], // 图文列表
  //   ['assistDiv', () => import('./basics/assistDiv.vue')], // 铺助分割
  //   ['imageTextNav', () => import('./basics/imageTextNav.vue')], // 图文导航
  //   ['productList', () => import('./basics/product/pc/index.vue')], // 商品列表
  //   ['videoBox', () => import('./basics/video.vue')], // 视频
  //   ['coupon', () => import('./basics/coupon/pc/index.vue')], // 优惠券
  //   ['custom', () => import('./basics/custom.vue')], // 自定义
  //   ['notice', () => import('./basics/notice.vue')], // 公告
  //   ['vip', () => import('./basics/vip/pc/index.vue')], // 会员专区
  //   ['groupList', () => import('./basics/group/pc/index.vue')], // 拼团专区
  //   ['spikeList', () => import('./basics/spike/pc/index.vue')], // 秒杀专区
  //   ['priceList', () => import('./basics/price/pc/index.vue')], // 定价捆绑
  //   ['discountList', () => import('./basics/discount/pc/index.vue')], // 限时折扣
  //   ['newProduct', () => import('./basics/newProduct/app/index.vue')], // 每日上新
  //   ['shop', () => import('./basics/shop.vue')], // 每日好店
  // ]),
]
export default contentCanvas
