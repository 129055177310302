import request from '@/utils/request'

export function queryPage(data) {
  return request({
    url: '/mall/gsm/vitMealSubSubjoin/queryPage',
    method: 'post',
    data,
  })
}

export function addObj(data) {
  return request({
    url: '/mall/gsm/vitMealSubSubjoin/add',
    method: 'post',
    data,
  })
}

export function getObj(data) {
  return request({
    url: '/mall/gsm/vitMealSubSubjoin/details',
    method: 'get',
    data,
  })
}

export function editObj(data) {
  return request({
    url: `/mall/gsm/vitMealSubSubjoin/edit`,
    method: 'post',
    data,
  })
}
export function delObj(data) {
  return request({
    url: '/mall/gsm/vitMealSubSubjoin/delete',
    method: 'delete',
    data,
  })
}
