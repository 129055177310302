var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "div",
        { staticClass: "list-item" },
        [
          _vm._m(0),
          _c(
            "el-table",
            { attrs: { border: "", data: _vm.list, stripe: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "奖品等级",
                  prop: "itemLevel",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "奖品名称",
                  prop: "goodsName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "奖品图片",
                  prop: "goodsImage",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-image", {
                          staticStyle: { cursor: "pointer" },
                          attrs: { fit: "fill", src: row.goodsImage },
                          on: {
                            click: function ($event) {
                              return _vm.handlePreView(row.goodsImage)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "抽奖时间",
                  prop: "startDate",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              row.startDate ? _vm.parseTime(row.startDate) : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "中奖时间",
                  prop: "lotteryTime",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              row.lotteryTime
                                ? _vm.parseTime(row.lotteryTime)
                                : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm.list[0] && _vm.list[0].goodsType != 1
                ? _c("el-table-column", {
                    attrs: { align: "center", label: "发放方式" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDicValue(
                                      "disburseMent",
                                      row.disburseMent
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1888920673
                    ),
                  })
                : _c("el-table-column", {
                    attrs: { align: "center", label: "发放方式" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getDicValue(
                                    "deliveryType",
                                    row.deliveryType
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "支付金额",
                  prop: "paidAmount",
                },
              }),
              _vm.list[0] && _vm.list[0].isRedem == 1
                ? _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "领取时间",
                      prop: "receiveTime",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    row.receiveTime
                                      ? _vm.parseTime(row.receiveTime)
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      838400243
                    ),
                  })
                : _vm._e(),
              _vm.list[0] &&
              _vm.list[0].goodsType == 1 &&
              _vm.list[0].deliveryType == 12
                ? _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "核销码",
                      prop: "redemCode",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.list[0] && _vm.list[0].goodsType == 1
        ? _c(
            "div",
            {
              staticClass: "list-item",
              staticStyle: { "margin-top": "100px" },
            },
            [
              _vm._m(1),
              _c(
                "el-table",
                { attrs: { border: "", data: _vm.list, stripe: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "订单编号",
                      prop: "orderCode",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商品名称",
                      prop: "goodsName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "收货人",
                      prop: "consignee",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "电话号码",
                      prop: "phone",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "收货地址",
                      prop: "receivingAddress",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "下单时间",
                      prop: "orderTime",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    row.orderTime
                                      ? _vm.parseTime(row.orderTime)
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      752531571
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "发货店铺",
                      prop: "shopName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "订单状态",
                      prop: "orderState",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDicValue(
                                      "orderState",
                                      row.orderState
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1686089037
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "图片详情",
            visible: _vm.imgDetailVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.imgDetailVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                height: "100%",
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
              },
            },
            [_c("el-image", { attrs: { fit: "fill", src: _vm.imgUrl } })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-title" }, [
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "title-text" }, [_vm._v("领取详情")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-title" }, [
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "title-text" }, [_vm._v("订单详情")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }