var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-table",
        { attrs: { border: "", data: _vm.tablist, "max-height": "300" } },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              type: "index",
              width: "55",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单编号",
              prop: "orderNo",
              width: "190",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "下单用户",
              prop: "nickName",
              width: "140",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单金额",
              prop: "paymentPrice",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "下单员工",
              prop: "profile",
              "show-overflow-tooltip": "",
              width: "140",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      _vm._s(scope.row.realName) +
                        _vm._s(
                          scope.row.profile == ""
                            ? ""
                            : "(" + scope.row.profile + ")"
                        )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "所购产品",
              prop: "product",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "下单地址",
              prop: "address",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单状态", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.fuseState == 0
                      ? _c("span", [_vm._v("待质检")])
                      : _vm._e(),
                    row.fuseState == 1
                      ? _c("span", [_vm._v("待发货")])
                      : _vm._e(),
                    row.fuseState == 2
                      ? _c("span", [_vm._v("质检驳回")])
                      : _vm._e(),
                    row.fuseState == 3
                      ? _c("span", [_vm._v("待收货")])
                      : _vm._e(),
                    row.fuseState == 4
                      ? _c("span", [_vm._v("已完成")])
                      : _vm._e(),
                    row.fuseState == 5
                      ? _c("span", [_vm._v("待退款")])
                      : _vm._e(),
                    row.fuseState == 6
                      ? _c("span", [_vm._v("已退款")])
                      : _vm._e(),
                    row.fuseState == 7
                      ? _c("span", [_vm._v("退款驳回")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "质检信息",
              prop: "quaContent",
              "show-overflow-tooltip": "",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "预存金额",
              prop: "presAmount",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单日期",
              prop: "createTime",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              prop: "createTime",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.selectUpload(scope.row)
                          },
                        },
                      },
                      [_vm._v("上传")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.selectDownload(scope.row)
                          },
                        },
                      },
                      [_vm._v("预览")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": 1,
          layout: "total, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("Upload", {
        attrs: { comid: _vm.comId, limitnum: 99, visible: _vm.uploadVisible },
        on: {
          closeUpload: _vm.closeUpload,
          fillData: function ($event) {
            return _vm.fillData()
          },
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "请选择您要预览的文件",
            visible: _vm.downLoadVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.downLoadVisible = $event
            },
          },
        },
        _vm._l(_vm.stpFileDosData, function (item, index) {
          return _c("div", { key: index }, [
            _c(
              "a",
              {
                staticStyle: { display: "block", margin: "10px 0" },
                attrs: { download: "", href: item.fileUrl },
              },
              [_vm._v(_vm._s(item.fileName) + _vm._s(item.fileExtension))]
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }