<template>
  <el-dialog
  :before-close="handleClose"
  title="选择地址"
  :visible.sync="dialogVisible"
  width="30%">
  <span>
    <div class="contaner">
        <div v-for="(ym,index) in userlist" :key="index" class="crad_item" :class="cur==index?'acti':''" @click="activ(index)">
          <div class="card_thead_top">
            <div class="item-thead name">姓名:{{ ym.userName }}</div>
            <div class="item-thead phone">手机号:{{ ym.telNum }}</div>
            <div v-if="ym.isDefault==1" class="item-thead acquiesce">默认</div>
          </div>
          <div class="card_thead_center">
            <div class="item-address ">{{ym.provinceName}}</div>-
            <div class="item-address ">{{ym.cityName}}</div>-
            <div class="item-address ">{{ym.countyName}}</div>
          </div>
          <div class="card_thead_center" style="color: gray;font-size: 13px;">
            <div class="add" style="margin: 0 5px;">详细地址：{{ym.detailInfo}}</div> 
          </div>
        </div>
    </div>
  </span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="clearImmediate">取 消</el-button>
    <el-button type="primary" @click="save">确 定</el-button>
  </span>
</el-dialog>
</template>

<script>
import {useraddress } from "@/api/mall/article/addressuserinfo"
export default {
  props:{
    showhide:{
      type:Boolean,
      default:()=>{}
    },
    userid:{
      type:Object ,
      default:()=>{}
    }
  },
  data(){
    return{
      dialogVisible:this.showhide,
      useId:this.userid,
      userlist:[],
      cur:null,
      checkout:null,
    }
  },
  watch:{
    showhide(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisible=newValue
        this.useId=this.userid
        this.useraddress()
      }
    }
  },
  created(){
    this.useraddress()
    // console.log(this.useId.userinfo.id)
  },
  methods:{
    useraddress(){
      let lts={
        size:10,
        current:1,
        userId:this.useId.userinfo.id
      }
      useraddress({...lts}).then(res=>{
        console.log(res.data)
        this.userlist=res.data.records
      })
    },
    activ(e){
      this.checkout=this.userlist[e]
      this.cur=e
    },
    handleClose(){},
    clearImmediate(){
      this.$emit("tranhdie",false)
    },
    save(){
      this.$emit("tranhdie",this.checkout)
    }
  }
}
</script>

<style lang="scss" scoped>
.contaner{
  width: 90%;
  margin: auto;
  .crad_item{
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    .card_thead_top{
      margin: 15px 0;
      display: flex;
      align-items: center;
      font-size: .95rem;
      .item-thead{
        margin: 0px 5px;
        font-weight: bold;
      }
      .acquiesce{
        font-weight: 500;
        font-size: 12px;
        height: 20px;
        padding: 0 5px;
        line-height: 20px;
        color: #3782ff;
        background: #e2ebfa;
      }
    }
    .card_thead_center{
      display: flex;
      margin: 5px 0;
      align-items: center;
      .item-address{
        margin: 0 5px;
        font-size: 13px;
        color: gray;
      }
    }
  }
  .acti{
    border: 1px solid #3782ff;
  }
}
::v-deep .el-dialog__header{
  border-bottom: 1px solid #e5e5e5;
}
</style>