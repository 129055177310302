var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "canvas_cont",
      style: {
        background: `${_vm.styleinfo.componentBgColor}`,
        borderTopLeftRadius: `${_vm.styleinfo.topElementAroundRadius}px `,
        borderTopRightRadius: `${_vm.styleinfo.topElementAroundRadius}px `,
        borderBottomRightRadius: `${_vm.styleinfo.bottomElementAroundRadius}px `,
        borderBottomLeftRadius: `${_vm.styleinfo.bottomElementAroundRadius}px `,
      },
    },
    [
      _c("div", { staticClass: "lef" }, [
        _c(
          "div",
          {
            staticClass: "thead_title",
            style: {
              color: `${_vm.styleinfo.textColor}`,
              fontSize: `${_vm.styleinfo.fontSize}px`,
              fontWeight: `${_vm.styleinfo.fontWeight}`,
            },
          },
          [_vm._v(" " + _vm._s(_vm.styleinfo.text) + " ")]
        ),
        _c(
          "div",
          {
            staticClass: "subheading",
            style: {
              color: `${_vm.styleinfo.subTitle.color}`,
              fontSize: `${_vm.styleinfo.subTitle.fontSize}px`,
              fontWeight: `${_vm.styleinfo.subTitle.fontWeight}`,
            },
          },
          [_vm._v(" " + _vm._s(_vm.styleinfo.subTitle.text) + " ")]
        ),
      ]),
      _vm.styleinfo.more.isShow
        ? _c(
            "div",
            {
              staticClass: "lef ref",
              style: {
                color: `${_vm.styleinfo.more.color}`,
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.styleinfo.more.text) + " "),
              _c("vab-icon", {
                staticStyle: { "font-size": "12px" },
                attrs: { icon: "arrow-right-s-line" },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }