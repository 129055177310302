var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        title: "编辑既往病史",
        visible: _vm.dialogVisible,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                "label-width": "100px",
                model: _vm.ruleForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "疾病名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入 疾病名称" },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "确诊时间", prop: "phyTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      align: "center",
                      placeholder: "选择确诊时间",
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.ruleForm.phyTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "phyTime", $$v)
                      },
                      expression: "ruleForm.phyTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否治愈", prop: "fitEnable" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择 是否治愈" },
                      model: {
                        value: _vm.ruleForm.fitEnable,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "fitEnable", $$v)
                        },
                        expression: "ruleForm.fitEnable",
                      },
                    },
                    _vm._l(_vm.labType, function (ym, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: ym.labelName, value: ym.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "体检报告单", prop: "fitUrl" } },
                [
                  _vm.uphide
                    ? _c("Upload", {
                        attrs: { picurl: _vm.ruleForm.fitUrl },
                        on: { backkuck: _vm.check },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }