<template>
  <div class="comprehensive-table-container">
    <div class="search_form">
      <el-form  class="demo-form-inline"  :inline="true" :model="searchform">
        <el-form-item label="模板类型">
          <el-select v-model="searchform.template" placeholder="请选择 模板类型">
            <el-option v-for="(ym,index) in options" :key="index" label="" value=""/>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button icon="el-icon-search" type="primary">搜索</el-button>
          <el-button icon="el-icon-refresh-right" type="warning">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button icon="el-icon-plus" type="primary" @click="showadd=true">新增</el-button>
    </div>
    <el-table
      border
      :data="tablDate"
      style="width: 100%">
      <el-table-column
        align="center"
        label="序号"
        type="index"
        width="50"/>
      <el-table-column
        align="center"
        label="店铺"
        prop="shopId"
      />
      <el-table-column
        align="center"
        label="名称"
        prop="name"/>
      <el-table-column
        align="center"
        label="排序"
        prop="sort"/>
      <el-table-column
        align="center"
        label="模板类型"
        prop="type"/>
      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        prop="address">
        <template #default="scope">
          <el-button style="font-size: 14px;" type="text"  @click="showTab(scope.row)">查看</el-button>
          <el-button style="font-size: 14px;" type="text" @click="editTab(scope.row)">编辑</el-button>
          <el-button style="font-size: 14px;" type="text" @click="delTab(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="total, sizes, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <addDialog :shoehand="showadd" @closeadd="closehand"/>
    <showDialog :rowlist="shoelist" :shoehand="shodhide" @closeshow="closeshows"/>
    <hideDialog :hiderow="showrow" :shoehand="hidelist" @closehide="closehi"/></div>
</template>

<script>
import { getPage} from '@/api/mall/setting/freighttemplat'
import {getList} from '@/api/mall/shop/info'
import { delObj} from '@/api/mall/setting/freighttemplat'
import addDialog from './components/addDialog/addedialog.vue'
import showDialog from './components/editDialog/addedialog.vue'
import hideDialog from './components/showDialog/addedialog.vue'
export default {
  components:{
    addDialog,
    showDialog,
    hideDialog
  },
  data(){
    return{
      searchform:{},
      options:[],
      tablDate:[],
      queryFrom:{
        size:10,
        current:1
      },
      total:0,
      showadd:false,
      shodhide:false,
      shoelist:null,
      hidelist:false,
    }
  },
  created(){
    this.getPage()
    this.getList()
  },
  methods:{
    showTab(row){
      this.showrow=row
      this.hidelist=true
    },
    closehi(){
      this.hidelist=false
    },
    delTab(Id){
      delObj(Id).then(()=>{
        this.$message.success('删除成功')
        this.getPage()
      })
    },
    editTab(row){
      this.shodhide=true
      this.shoelist=row
    },
    getPage(){
      getPage({...this.queryFrom}).then(res=>{
        this.tablDate=res.data.records
      })
    },
    getList(){
      getList({shopId:'1'}).then(res=>{
        console.log(res,'846464')
      })
    },
    handleCurrentChange(e){
      this.queryFrom.current=e
      this.getPage()
    },
    closehand(e){
      if(e==false){
        this.showadd=false
        this.getPage()
      }
    },
    handleSizeChange(e){
      this.queryFrom.size=e
      this.getPage()
    },
    closeshows(){
      this.shodhide=false
      this.getPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.search_form{
  margin-bottom: 15px;
}
</style>