<template>
  <div class="cy-container">
    <el-button icon="el-icon-plus" type="primary" @click="addnew=true">新增</el-button>
    <el-tabs v-model="form.modelType"  @tab-click="handleClick">
      <el-tab-pane v-for="(ym,index) in tag" :key="index" :label="ym.dictValue" :name="ym.dictKey"/>
    </el-tabs>
    <el-table
      border
      :data="tableData"
      style="width: 100%">
      <el-table-column
      align="center"
      label="编号"
      type="index"
      width="50"/>
      <el-table-column
        align="center"
        label="标题"
        prop="title"
        width="180"/>
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"/>
      <el-table-column
        align="center"
        label="操作">
        <template #default="scope">
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="editro(scope.row)">编辑</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"/>
      <el-dialog
        :before-close="handleClose"
        title="编辑"
        :visible.sync="dialogVisible"
        width="60%">
        <span>
          <el-form ref="formEdit" label-width="80px" :model="editrow" :rules="rules">
            <el-form-item label="标题：" prop="title">
              <el-input v-model="editrow.title" placeholder="请输入 标题"/>
            </el-form-item>
            <el-form-item label="类别：" prop="modelType">
              <el-select v-model="editrow.dictValue" disabled placeholder="">
                <el-option
                  v-for="item in tag"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"/>
              </el-select>
            </el-form-item>
            <el-form-item label="内容	:" prop="conditionFrom">
              <WangEditor :value="editrow.content"  @wang="eidttab"/>
            </el-form-item>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="edisave">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :before-close="handleCloseadd"
        title="新增"
        :visible.sync="addnew"
        width="60%">
        <span>
          <el-form ref="formAddlist" label-width="120px" :model="newrows" :rules="rsult">
            <el-form-item label="标题	:" prop="title">
              <el-input v-model="newrows.title" placeholder="请输入 标题	"/>
            </el-form-item>
            <el-form-item label="类别：" prop="modelType">
              <el-select v-model="newrows.modelType" placeholder="请选择 类别">
                <el-option
                  v-for="item in tag"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"/>
              </el-select>
            </el-form-item>
            <el-form-item label="内容	:" prop="content">
              <WangEditor @wang="descr"/>
            </el-form-item>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clears">取 消</el-button>
          <el-button type="primary" @click="addsave">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import WangEditor from '@/components/editor/WangEditor.vue'
import {getPage,stpPlatformProtocol,stpPlatformProtocoladd,stpPlatformProtocoledit} from "@/api/mall/admin/sysstting"
export default {
  components:{
    WangEditor
  },
  data(){
    return {
      tope:[{name:"1",id:1},{name:"2",id:2},{name:"1",id:3},{name:"1",id:4}],
      tag:[],
      tableData: [],
      total:0,
      dialogVisible:false,
      addnew:false,
      form:{
        current:1,
        size:10,
        modelType:'2'
      },
      editrow:{},
      rules:{
        title:[
          { required: true, message: '请输入 标题', trigger: 'blur' },
        ],
        modelType:[
            { required: true, message: '请选择 类别', trigger: 'blur' },
        ],
      },
      rsult:{
        title:[
          { required: true, message: '请输入 标题', trigger: 'blur' },
        ],
        modelType:[
            { required: true, message: '请选择 类别', trigger: 'blur' },
        ],
      },
      newrows:{}
    }
  },
  created(){
    this.getPage()
    this.stpPlatformProtocol()
  },
  methods:{
    stpPlatformProtocol(){
      stpPlatformProtocol({...this.form}).then(res=>{
        this.tableData=res.data.records
        this.form.size=res.data.size
        this.form.current=res.data.current
        this.total=res.data.total
        console.log(res.data)
      })
    },
    getPage(){
      getPage({parentId:'834'}).then(res=>{
        this.tag=res.data.records
        console.log(res.data.records)
      })
    },
    handleCurrentChange(e){
      this.form.current=e
      this.stpPlatformProtocol()
    },
    handleSizeChange(e){
      this.form.size=e
      this.stpPlatformProtocol()
    },
    handleClick(){
      this.stpPlatformProtocol()
    },
    handleClose(){
      this.dialogVisible=false
    },
    handleCloseadd(){
      this.addnew=false
    },
    editro(row){
      this.dialogVisible=true
      this.editrow=row
    },
    edisave(){
      this.$refs.formEdit.validate((valid) => {
          if (valid) {
           this.putpage()
          } else {
            return false;
          }
        });
    },
    putpage(){
      stpPlatformProtocoledit({...this.editrow}).then(()=>{
        this.$message.success("编辑成功")
        this.dialogVisible=false
        this.stpPlatformProtocol()
      })
    },
    clears(){
      this.addnew=false
      this.newrows={}
    },
    addsave(){
      this.$refs.formAddlist.validate((valid) => {
          if (valid) {
           this.addpages()
          } else {
            return false;
          }
        });
    },
    addpages(){
      stpPlatformProtocoladd({...this.newrows}).then(()=>{
        this.$message.success("新增成功")
        this.addnew=false
        this.newrows={}
        this.stpPlatformProtocol()
      })
    },
    descr(row){
      this.newrows.content=row
    },
    eidttab(row){
      this.editrow.content=row
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header{
  border-bottom: 1px solid #e8e8e8;
}
</style>