<template>
  <div class="comprehensive-table-container">
    <div class="add-btn">
      <el-button icon="el-icon-plus" type="primary" @click="add">新增</el-button>
    </div>
    <div class="table-Date">
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column align="center" label="类型名称" prop="planName" />
        <el-table-column align="center" label="类型数量" prop="planSort" />
        <el-table-column align="center" label="创建时间" prop="createTime" />
        <el-table-column align="center" label="操作" prop="address">
          <template #default="scope">
            <el-button type="text" @click="edit(scope.row, 'del')">删除</el-button>
            <el-button type="text" @click="edit(scope.row, 'edit')">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="1"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="form.size"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <el-dialog :before-close="handleClose" :title="cur == 'add' ? '新增类型' : '编辑类型'" :visible.sync="dialogVisible" width="30%">
      <span>
        <el-form ref="addForm" label-width="100px" :model="subForm" :rules="rules">
          <el-form-item label="类型名称" prop="planName">
            <el-input v-model="subForm.planName" placeholder="请输入类型名称" />
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addSubform">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { vitMealPlanTypequeryPage, vitMealPlanTypeadd, vitMealPlanTypedelete, vitMealPlanTypeedit } from '@/api/mall/health/healthplantype/index'
export default {
  data() {
    return {
      total: 0,
      form: {
        size: 10,
        current: 1,
      },
      pages: 0,
      tableData: [],
      dialogVisible: false,
      subForm: {
        planStatus: 2,
        isDelete: 0,
      },
      rules: {
        planName: [{ required: true, message: '请输入类型名称', trigger: 'blur' }],
      },
      cur: 'add',
    }
  },
  created() {
    this.queryPage()
  },
  methods: {
    add() {
      this.dialogVisible = true
      this.cur = 'add'
      this.subForm = {
        planStatus: 2,
        isDelete: 0,
      }
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.queryPage()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.queryPage()
    },
    queryPage() {
      vitMealPlanTypequeryPage({ ...this.form }).then((res) => {
        this.tableData = res.data.records
        this.total = res.data.total
        this.form.size = res.data.size
        this.form.current = res.data.current
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.addForm.resetFields()
    },
    addSubform() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (this.cur == 'add') {
            vitMealPlanTypeadd({ ...this.subForm }).then((res) => {
              if (res.code == 0 || res.status == 200) {
                this.$message.success('新增成功')
                this.handleClose()
                this.queryPage()
              }
            })
          } else {
            vitMealPlanTypeedit({ ...this.subForm }).then((res) => {
              if (res.code == 0 || res.status == 200) {
                this.$message.success('编辑成功')
                this.handleClose()
                this.queryPage()
              }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    edit(row, Type) {
      if (Type == 'del') {
        vitMealPlanTypedelete({ id: row.id, isDelete: 1 }).then((res) => {
          if (res.code == 0 || res.status == 200) {
            this.$message.success('删除成功')
            this.queryPage()
          }
        })
      } else {
        this.subForm = row
        this.dialogVisible = true
        this.cur = 'edit'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.comprehensive-table-container {
  .add-btn {
    margin-bottom: 20px;
  }
  .table-Date {
    margin-bottom: 20px;
  }
}
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>
