import request from '@/utils/request'

// 页面管理列表
export function queryPage(data) {
  return request({
    url: `/mall/themeDiyCanvas/queryPageCanvasPage`,
    method: 'post',
    data,
  })
}
