<template>
  <div class="comprehensive-table-container">
    <div class="top-headerUserinfo">
      <div class="lef_userinfo">
        <div class="lef_userPicUrl">
          <img alt="" :src="usermodel.headImage" />
        </div>
        <div class="ref_listinfo">
          <div class="name">{{ usermodel.userName }}</div>
          <div class="userCode">会员编号:{{ usermodel.userId }}</div>
          <div class="userCode">来源渠道:</div>
          <div class="userCode">注册时间:{{ usermodel.createTime }}</div>
        </div>
      </div>
      <!-- <div class="ref_echart">
        <div class="body_status">
          <div class="line-echart" style="margin: auto; margin-bottom: 22px">亚健康</div>
          <div class="txt">身体健康状态</div>
        </div>
        <div v-for="(ym, index) in opts" :key="index" class="body_status">
          <div class="lin-e">
            <qiun-vue-ucharts :chart-data="chartData[index]" :opts="ym" type="arcbar" />
          </div>
          <div class="txt">{{ chartData[index].series[0].name }}</div>
        </div>
      </div> -->
    </div>
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(ym, index) in tabs" :key="index" :label="ym.labelName" :name="ym.id">
          <span slot="label">
            <vab-icon :icon="ym.icon" />
            {{ ym.labelName }}
          </span>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="content-value">
      <BasicInformation v-if="activeName == 1" :value="usermodel" />
      <HealthRecord v-if="activeName == 2" :value="usermodel" />
      <HealthData v-if="activeName == 3" :value="usermodel" />
    </div>
  </div>
</template>

<script>
// import qiunVueUcharts from '@qiun/vue-ucharts'

import { userFitnessdetails } from '@/api/mall/users/usermore'
import * as echarts from 'echarts'
import BasicInformation from './components/Basic-information/index.vue'
import HealthRecord from './components/Health-record/index.vue'
import HealthData from './components/Health-data/index.vue'
export default {
  components: {
    BasicInformation,
    HealthRecord,
    // qiunVueUcharts,
    HealthData,
  },
  data() {
    return {
      chartInstance: null,
      tabs: [
        {
          id: '1',
          labelName: '基础信息',
          icon: 'home-smile-line',
        },
        {
          id: '2',
          labelName: '健康档案',
          icon: 'user-line',
        },
        {
          id: '3',
          labelName: '健康数据',
          icon: 'archive-line',
        },
        // {
        //   id: '4',
        //   labelName: '订单数据',
        //   icon: 'user-line',
        // },
        // {
        //   id: '5',
        //   labelName: '会员资产',
        //   icon: 'user-line',
        // },
        // {
        //   id: '6',
        //   labelName: '打卡记录',
        //   icon: 'user-line',
        // },
      ],
      activeName: '1',
      usermodel: null,
      list: null,
      chartData: [
        {
          series: [
            {
              name: '健康综合指数',
              color: '#2fc25b',
              data: 0.5,
            },
          ],
        },
        {
          series: [
            {
              name: '数据指数',
              color: '#2fc25b',
              data: 0.8,
            },
          ],
        },
        {
          series: [
            {
              name: '饮食指数',
              color: '#2fc25b',
              data: 0.64,
            },
          ],
        },
        {
          series: [
            {
              name: '运动指数',
              color: '#2fc25b',
              data: 0.8,
            },
          ],
        },
      ],
      opts: [
        {
          color: ['#1890FF', '#91CB74', '#FAC858', '#EE6666', '#73C0DE', '#3CA272', '#FC8452', '#9A60B4', '#ea7ccc'],
          padding: undefined,
          title: {
            name: '80%',
            fontSize: 14,
            color: '#2fc25b',
          },
          subtitle: {
            name: '',
            fontSize: 25,
            color: '#666666',
          },
          extra: {
            arcbar: {
              type: 'circle',
              width: 10,
              backgroundColor: '#E9E9E9',
              startAngle: 1.5,
              endAngle: 0.25,
              gap: 2,
              lineCap: 'butt',
            },
          },
        },
        {
          color: ['#1890FF', '#91CB74', '#FAC858', '#EE6666', '#73C0DE', '#3CA272', '#FC8452', '#9A60B4', '#ea7ccc'],
          padding: undefined,
          title: {
            name: '80%',
            fontSize: 14,
            color: '#2fc25b',
          },
          subtitle: {
            name: '',
            fontSize: 25,
            color: '#666666',
          },
          extra: {
            arcbar: {
              type: 'circle',
              width: 10,
              backgroundColor: '#E9E9E9',
              startAngle: 1.5,
              endAngle: 0.25,
              gap: 2,
              lineCap: 'butt',
            },
          },
        },
        {
          color: ['#1890FF', '#91CB74', '#FAC858', '#EE6666', '#73C0DE', '#3CA272', '#FC8452', '#9A60B4', '#ea7ccc'],
          padding: undefined,
          title: {
            name: '80%',
            fontSize: 14,
            color: '#2fc25b',
          },
          subtitle: {
            name: '',
            fontSize: 25,
            color: '#666666',
          },
          extra: {
            arcbar: {
              type: 'circle',
              width: 10,
              backgroundColor: '#E9E9E9',
              startAngle: 1.5,
              endAngle: 0.25,
              gap: 2,
              lineCap: 'butt',
            },
          },
        },
        {
          color: ['#1890FF', '#91CB74', '#FAC858', '#EE6666', '#73C0DE', '#3CA272', '#FC8452', '#9A60B4', '#ea7ccc'],
          padding: undefined,
          title: {
            name: '80%',
            fontSize: 14,
            color: '#2fc25b',
          },
          subtitle: {
            name: '',
            fontSize: 25,
            color: '#666666',
          },
          extra: {
            arcbar: {
              type: 'circle',
              width: 10,
              backgroundColor: '#E9E9E9',
              startAngle: 1.5,
              endAngle: 0.25,
              gap: 2,
              lineCap: 'butt',
            },
          },
        },
      ],
    }
  },
  created() {
    if (this.$route.query) {
      const list = this.$route.query
      this.list = list
      this.userFitnessdetails(list.userinfo)
    } else {
      this.$router.push('*')
    }
  },
  mounted() {
    this.$EventBus.$on('repload', () => {
      this.userFitnessdetails(this.list.userinfo)
    })
    // this.initChart()
  },
  methods: {
    userFitnessdetails(e) {
      userFitnessdetails({ userId: e }).then((res) => {
        this.usermodel = res.data
      })
    },
    handleClick(tab) {
      console.log(tab)
      //  this.activeName
    },
    initChart() {
      // 创建图表实例
      this.chartInstance = echarts.init(document.getElementById('chart-container'))
      // 指定图表的配置项和数据
      const option = {
        title: {
          text: '',
          subtext: '85',
          left: 'center',
          top: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '',
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: ['A', 'B', 'C', 'D', 'E'],
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '95%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [{ value: 335, name: '' }],
          },
        ],
      }
      this.chartInstance.setOption(option)
    },
  },
}
</script>

<style lang="scss" scoped>
.comprehensive-table-container {
  .top-headerUserinfo {
    height: 132px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .lef_userinfo {
    display: flex;
    align-items: center;
    .lef_userPicUrl {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      overflow: hidden;
      box-shadow: 0px 0px 10px 0px rgba(33, 33, 33, 0.3);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .ref_listinfo {
      margin-left: 23px;
      .name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 7px;
        color: #333;
      }
      .userCode {
        font-size: 12px;
        color: gray;
        margin: 8px 0;
      }
    }
  }
  .ref_echart {
    display: flex;
    .body_status {
      text-align: center;
      width: 91px;
      height: 91px;
      margin: 0 20px;
      .line-echart {
        width: 68px;
        height: 68px;
        border-radius: 100%;
        background: red;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
      }
      #chart-container {
        width: 75px;
        height: 75px;
      }
      .txt {
        margin-top: 15px;
      }
    }
  }
  .lin-e {
    width: 75px;
    height: 75px;
    margin: auto;
  }
}
::v-deep {
  .el-tabs__nav-wrap::after {
    background: none;
  }
}
</style>