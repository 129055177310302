<template>
  <div class="target">
    <el-card class="target-card2" shadow="hover">
      <!-- <template #header>
        <span>
          <vab-icon icon="money-cny-box-line" />
          销售额
        </span>
      </template> -->
      <vab-chart
        class="target-echart2"
        :init-options="initOptions"
        :option="option2"
        theme="vab-echarts-theme"
      />
    </el-card>
  </div>
</template>

<script>
  import VabChart from '@/extra/VabChart'
  import {detailsByXoryInfo} from '@/api/mall/health/appdata/index'
  export default {
    components: {
      VabChart,
    },
    props:{
      requ:{
        type:Object,
        default:()=>{}
      }
    },
    data() {
      const colorList = ['#9E87FF', '#73DDFF', '#fe9a8b', '#F56948', '#9E87FF']
      return {
        from:{
          classType:this.requ.classType,
          userId:this.requ.userId
        },
        initOptions: {
          renderer: 'svg',
        },
        option2: {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'z-index:1',
          },
          grid: {
            top: '4%',
            left: '2%',
            right: '2%',
            bottom: '0%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              data: [
                '1月',
                '2月',
                '3月',
                '4月',
                '5月',
                '6月',
                '7月',
                '8月',
                '9月',
                '10月',
                '11月',
                '12月',
              ],
              axisLine: {
                lineStyle: {
                  color: '#DCE2E8',
                },
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                interval: 0,
                color: '#556677',
                fontSize: 12,
                margin: 15,
              },
              axisPointer: {
                label: {
                  padding: [0, 0, 10, 0],
                  margin: 15,
                  fontSize: 12,
                  backgroundColor: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#fff',
                      },
                      {
                        offset: 0.86,
                        color: '#fff',
                      },
                      {
                        offset: 0.86,
                        color: '#33c0cd',
                      },
                      {
                        offset: 1,
                        color: '#33c0cd',
                      },
                    ],
                    global: false,
                  },
                },
              },
              boundaryGap: false,
            },
          ],
          yAxis: [
            {
              type: 'value',
              axisTick: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#DCE2E8',
                },
              },
              axisLabel: {
                color: '#556677',
              },
              splitLine: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: '销售额',
              type: 'line',
              data: [
                1905, 1020, 3330, 512, 4463, 2214, 3330, 5412, 1205, 820, 5330,
                1112,
              ],
              symbolSize: 1,
              symbol: 'circle',
              smooth: true,
              yAxisIndex: 0,
              showSymbol: false,
              lineStyle: {
                width: 5,
                color: new VabChart.graphic.LinearGradient(1, 1, 0, 0, [
                  {
                    offset: 0,
                    color: '#73DD39',
                  },
                  {
                    offset: 1,
                    color: '#73DDFF',
                  },
                ]),
                shadowColor: 'rgba(115,221,255, 0.3)',
                shadowBlur: 10,
                shadowOffsetY: 20,
              },
              itemStyle: {
                color: colorList[1],
                borderColor: colorList[1],
              },
            },
          ],
        },
      }
    },
    watch:{
      requ(newValue,oldValue){
        if(newValue!=oldValue){
          console.log(newValue)
          this.form={
            classType:newValue.classType,
            userId:newValue.userId
          }
          this.detailsByXoryInfo()
        }
      }
    },
    mounted(){
      this.$baseEventBus.$on('checkappdate', (e) => {
        this.form.classType=e
        this.detailsByXoryInfo()
      })
    },
    created(){
      this.form={
        classType:this.requ.classType,
        userId:this.requ.userId
      }
      this.detailsByXoryInfo()
    },
    methods:{
      detailsByXoryInfo(){
        detailsByXoryInfo({...this.form}).then(res=>{
          console.log(res.data)
          this.option2.series[0].data=res.data.xdata
          this.option2.xAxis[0].data=res.data.ydata
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .target {
    margin-bottom: $base-margin;
  }

  :deep() {
    .target-echart1 {
      width: 100%;
      height: 290px;
    }

    .target-echart2 {
      width: 100%;
      height: 205px;
    }
  }
</style>
