var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "left" },
          on: { "tab-click": _vm.confignum },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "1" } },
            [
              _c("basicinformation", {
                attrs: { basicinfo: _vm.actionscomponents },
                on: { information: _vm.basic },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              staticStyle: { width: "100%" },
              attrs: { label: "规格信息", name: "2" },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.actionscomponents } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "listing",
                      attrs: {
                        label: "规格信息:",
                        prop: "Specificationinformation",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "margin-right": "180px" },
                          model: {
                            value: _vm.actionscomponents.specType,
                            callback: function ($$v) {
                              _vm.$set(_vm.actionscomponents, "specType", $$v)
                            },
                            expression: "actionscomponents.specType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "0" } }, [
                            _vm._v("统一规格"),
                          ]),
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("多规格"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("MultispecificationVue", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.actionscomponents.specType == 1,
                        expression: "actionscomponents.specType == 1",
                      },
                    ],
                    attrs: { mushow: _vm.actionscomponents },
                    on: { Multiple: _vm.multirow },
                  }),
                  _c("specificationVue", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.actionscomponents.specType == 0,
                        expression: "actionscomponents.specType == 0",
                      },
                    ],
                    attrs: { mushow: _vm.actionscomponents },
                    on: { specifications: _vm.multi },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "积分赠送", name: "3" } },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.actionscomponents } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "listing", attrs: { label: "积分赠送:" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "margin-right": "180px" },
                          model: {
                            value: _vm.actionscomponents.pointsGiveSwitch,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.actionscomponents,
                                "pointsGiveSwitch",
                                $$v
                              )
                            },
                            expression: "actionscomponents.pointsGiveSwitch",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("开启赠送"),
                          ]),
                          _c("el-radio", { attrs: { label: "0" } }, [
                            _vm._v("不开启"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.actionscomponents.pointsGiveSwitch == "1",
                          expression:
                            "actionscomponents.pointsGiveSwitch == '1'",
                        },
                      ],
                      attrs: { label: "赠送数量:" },
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          "controls-position": "right",
                          placeholder: "请输入 赠送数量",
                        },
                        model: {
                          value: _vm.actionscomponents.pointsGiveNum,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.actionscomponents,
                              "pointsGiveNum",
                              $$v
                            )
                          },
                          expression: "actionscomponents.pointsGiveNum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "积分抵扣", name: "4" } },
            [
              _c(
                "el-form",
                {
                  ref: "refPoints",
                  attrs: { model: _vm.actionscomponents, rules: _vm.rules },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "listing", attrs: { label: "积分抵扣:" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "margin-right": "180px" },
                          model: {
                            value: _vm.actionscomponents.pointsDeductSwitch,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.actionscomponents,
                                "pointsDeductSwitch",
                                $$v
                              )
                            },
                            expression: "actionscomponents.pointsDeductSwitch",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("开启抵扣"),
                          ]),
                          _c("el-radio", { attrs: { label: "0" } }, [
                            _vm._v("不开启"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.actionscomponents.pointsDeductSwitch == "1",
                          expression:
                            "actionscomponents.pointsDeductSwitch == '1'",
                        },
                      ],
                      attrs: { label: "抵扣比例:", prop: "pointsDeductScale" },
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          "controls-position": "right",
                          placeholder: "请输入 抵扣比例",
                        },
                        model: {
                          value: _vm.actionscomponents.pointsDeductScale,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.actionscomponents,
                              "pointsDeductScale",
                              $$v
                            )
                          },
                          expression: "actionscomponents.pointsDeductScale",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.actionscomponents.pointsDeductSwitch == "1",
                          expression:
                            "actionscomponents.pointsDeductSwitch == '1'",
                        },
                      ],
                      attrs: { label: "抵扣规则:", prop: "pointsDeductAmount" },
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "300px", "margin-right": "20px" },
                        attrs: {
                          "controls-position": "right",
                          placeholder: "请输入 抵扣规则",
                        },
                        model: {
                          value: _vm.actionscomponents.pointsDeductAmount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.actionscomponents,
                              "pointsDeductAmount",
                              $$v
                            )
                          },
                          expression: "actionscomponents.pointsDeductAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-tab-pane", { attrs: { label: "商品详情", name: "5" } }),
          _vm.activeName == 5
            ? _c(
                "div",
                {
                  staticStyle: {
                    "min-height": "500px",
                    display: "flex",
                    width: "calc(100% - 110px)",
                    "margin-top": "45px",
                    "margin-left": "110px",
                    "white-space": "nowrap",
                  },
                },
                [_vm._v("描述： "), _c("wangeditors")],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.activeName == 5
        ? _c(
            "div",
            { staticClass: "text-c m-t" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c("el-button", [_vm._v("取消")]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }