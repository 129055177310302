<template>
  <div class="textTool">
    <div class="toolTit">
      <div class="lef">多商品组</div>
      <div class="ref">
        <div class="switch">
          <div :class="checknum == 1 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(1)">内容</div>
          <div :class="checknum == 2 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(2)">样式</div>
        </div>
      </div>
    </div>
    <div v-if="checknum == 1" class="content_msg">
      <div class="ym_line"></div>
      <div class="ym_title">商品风格</div>
      <div class="itemBox" style="align-items: flex-start; margin-top: 20px; margin-bottom: 20px">
        <div class="Tit">风格</div>
        <div class="check-tit" style="display: flex; flex-wrap: wrap; align-items: flex-start; justify-content: left">
          <div style="width: 100%">{{ rowcolType(activeComponents[indexnumer].template) }}</div>
          <el-radio-group v-model="activeComponents[indexnumer].template" size="mini">
            <el-radio-button label="row1-of1">样式1</el-radio-button>
            <el-radio-button label="row1-of2">样式2</el-radio-button>
            <el-radio-button label="row1-of5">样式3</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">商品排序</div>
        <div class="check-tit" style="margin-right: 30px">
          <el-radio-group v-model="activeComponents[indexnumer].sortWay" size="mini" style="display: flex">
            <el-radio label="default" style="width: 28px">综合</el-radio>
            <el-radio label="news" style="width: 28px">新品</el-radio>
            <el-radio label="sales" style="width: 28px">热销</el-radio>
            <el-radio label="price" style="width: 28px">价格</el-radio>
          </el-radio-group>
        </div>
      </div>
      <draggable v-model="activeComponents[indexnumer].list" animation="1000" chosen-class="chosen" force-fallback="true" group="people" scroll-speed="1000">
        <div v-for="(ym, index) in activeComponents[indexnumer].list" :key="index" class="itemBox" style="height: auto">
          <div class="card_menu">
            <div class="close_card-item" @click="deletelist(index)">
              <i class="el-icon-close"></i>
            </div>
            <div class="card_span">
              <span>分类名称</span>
              <div class="sacanner">
                <el-input v-model="ym.title" placeholder="请输入 分类名称" />
              </div>
            </div>
            <div class="card_span">
              <span>副标题</span>
              <div class="sacanner">
                <el-input v-model="ym.desc" placeholder="请输入 副标题" />
              </div>
            </div>
            <div class="card_span">
              <span>数据渠道</span>
              <div class="sacanner" style="display: flex; justify-content: right" @click="checklink(index)">
                <span v-if="ym.goodsId.name != ''" style="color: #1780ff">{{ ym.goodsId.title }}</span>
                <span v-else>请选择链接</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </draggable>
      <div class="itemBox" style="margin: 20px auto">
        <div class="add-ba" @click="addlist">
          <i class="el-icon-plus"></i>
          增加一个
        </div>
      </div>
    </div>
    <div v-if="checknum == 2" class="content_msg">
      <div class="ym_line"></div>
      <div class="ym_title">标题样式</div>
      <div class="itemBox">
        <div class="Tit">文字颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].headStyle.titleColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].headStyle.titleColor" />
            <div class="resect" @click="activeComponents[indexnumer].headStyle.titleColor = '#333333'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">背景颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].headStyle.selectBackground }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].headStyle.selectBackground" />
            <div class="resect" @click="activeComponents[indexnumer].headStyle.selectBackground = '#1780ff'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="alert-msg">选中类别背景颜色</div>
      <div class="ym_line"></div>
      <div class="ym_title">商品样式</div>
      <div class="itemBox">
        <div class="Tit">边框样式</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <el-radio-group v-model="activeComponents[indexnumer].ornament.type">
            <el-radio label="default">默认</el-radio>
            <el-radio label="shadow">投影</el-radio>
            <el-radio label="stroke">描边</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">
          <span>{{ bordeColor(activeComponents[indexnumer].ornament.type) }}</span>
          颜色
        </div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].ornament.color }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].ornament.color" />
            <div class="resect" @click="activeComponents[indexnumer].ornament.color = '#FFFFFF'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">图片圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].imgAroundRadius" :max="100" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].imgAroundRadius" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">商品名称</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: right">
          <el-checkbox-group v-model="activeComponents[indexnumer].goodsNameStyle.fontWeight">
            <el-checkbox label="true" name="type">加粗</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">名称行数</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: right">
          <el-radio-group v-model="activeComponents[indexnumer].nameLineMode">
            <el-radio label="single">单行</el-radio>
            <el-radio label="multiple">多行</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">价格颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].priceStyle.mainColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].priceStyle.mainColor" />
            <div class="resect" @click="activeComponents[indexnumer].priceStyle.mainColor = '#FF6A00'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">商品背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].elementBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].elementBgColor" />
            <div class="resect" @click="activeComponents[indexnumer].elementBgColor = '#FFFFFF'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">原价颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].priceStyle.lineColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].priceStyle.lineColor" />
            <div class="resect" @click="activeComponents[indexnumer].priceStyle.lineColor = '#000cA7'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">已售颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].saleStyle.color }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].saleStyle.color" />
            <div class="resect" @click="activeComponents[indexnumer].saleStyle.color = '#999ca7'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="ym_line"></div>
      <div class="ym_title">购买按钮样式</div>
      <div class="itemBox">
        <div class="Tit">底部背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].btnStyle.bgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].btnStyle.bgColor" />
            <div class="resect" @click="activeComponents[indexnumer].btnStyle.bgColor = '#FF6A00'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">文字颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].btnStyle.textColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].btnStyle.textColor" />
            <div class="resect" @click="activeComponents[indexnumer].btnStyle.textColor = '#FFFFFF'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">文字加粗</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: right">
          <el-checkbox-group v-model="activeComponents[indexnumer].btnStyle.fontWeight">
            <el-checkbox label="true" name="type">加粗</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="ym_line"></div>
      <div class="ym_title">组件样式</div>
      <div class="itemBox">
        <div class="Tit">背景颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].pageBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].pageBgColor" />
            <div class="resect">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="alert-msg">底部背景包含边距和圆角</div>
      <div class="itemBox">
        <div class="Tit">组件背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].componentBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].componentBgColor" />
            <div class="resect" @click="activeComponents[indexnumer].componentBgColor = ''">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.top" :max="100" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.top" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.bottom" :max="100" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.bottom" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">左右边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.both" :max="20" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.both" :max="20" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].topElementAroundRadius" :max="50" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].topElementAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].bottomElementAroundRadius" :max="50" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].bottomElementAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
    </div>
    <goodsidialog :linkshow="linkurl" @closeurl="closelink" />
  </div>
</template>
<script>
import draggable from 'vuedraggable'
// import uploadpic from '../../../upload-pic/index.vue'
import goodsidialog from './components/goodsIddialog.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    // uploadpic
    goodsidialog,
    draggable,
  },
  props: {
    indexnumer: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      checknum: 1,
      linkurl: false,
      checklinknum: null,
    }
  },
  computed: {
    ...mapGetters['state'],
    activeComponents() {
      return this.$store.state.checkvalue
    },
    imageurl() {
      let imgUrl = this.activeComponents[this.indexnumer].imageUrl
      if (imgUrl) {
        return imgUrl
      } else {
        return ''
      }
    },
    bordeColor() {
      return (item) => {
        if (item === 'default') {
          return `默认`
        } else if (item === 'shadow') {
          return `投影`
        } else {
          return `描边`
        }
      }
    },
    rowcolType() {
      return (item) => {
        if (item === 'row1-of1') {
          return `单列`
        } else if (item === 'row1-of2') {
          return `两栏`
        } else if (item === 'row1-of3') {
          return `三栏`
        } else if (item === 'row1-of4') {
          return `横向滚动`
        } else {
          return `大图`
        }
      }
    },
  },
  methods: {
    checklink(Index) {
      this.checklinknum = Index
      this.linkurl = true
    },
    closelink(lisr) {
      console.log(lisr, '1234')
      if (lisr != false) {
        console.log('1234')
        this.activeComponents[this.indexnumer].list[this.checklinknum].goodsId = lisr
        this.linkurl = false
      } else {
        this.linkurl = false
      }
    },
    addlist() {
      let addlist = {
        title: '',
        desc: '',
        sources: 'diy',
        categoryId: 0,
        categoryName: '请选择',
        goodsId: ['66', '61', '60', '59', '57', '114', '113', '112'],
      }
      this.activeComponents[this.indexnumer].list.push(addlist)
    },
    check(e) {
      this.activeComponents[this.indexnumer].imageUrl = e
    },
    changeChecknum(type) {
      this.checknum = type
    },
    deletelist(Index) {
      this.$confirm('是否删除该数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.activeComponents[this.indexnumer].list.splice(Index, 1)
        })
        .catch(() => {
          this.$message('您已取消删除')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.textTool {
  width: 100%;
  padding: 20px 20px 0 20px;
  .toolTit {
    display: flex;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
    color: #333333;
    .switch {
      display: flex;
      align-items: center;
      overflow: hidden;
      font-size: 14px;
      cursor: pointer;
      background: #f5f5f5;
      border-radius: 50px;
      .swit-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 29px;
        border-radius: 50px;
      }
      .active {
        color: white;
        background: #1780ff;
      }
    }
  }
  .ym_line {
    width: 100%;
    height: 3px;
    background: #f2f4f6;
  }
  .ym_title {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .itemBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 40px;
    margin-left: 20px;
    .add-ba {
      align-items: center;
      width: 100%;
      min-height: 40px;
      margin: 20px 0;
      margin-bottom: 40px;
      line-height: 40px;
      color: white;
      text-align: center;
      cursor: pointer;
      background: #1780ff;
      border: 1px dashed transparent;
    }
    .Tit {
      margin-right: 15px;
      color: gray;
      text-align: right;
      white-space: nowrap;
    }
    .check-tit {
      display: flex;
      justify-content: right;
      width: calc(100% - 56px);
      color: #105cfb;
      .resect {
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }
  .card_menu {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 95%;
    padding: 20px 0;
    margin: 15px 0;
    border: 1px dashed #e5e5e5;
    .close_card-item {
      position: absolute;
      top: -9px;
      right: -9px;
      width: 18px;
      height: 18px;
      line-height: 18px;
      color: white;
      text-align: center;
      cursor: pointer;
      background: #424242;
      border-radius: 100%;
    }
    .card_span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 10px 0;
      span {
        display: inline-block;
        width: 80px;
        margin-right: 10px;
        text-align: right;
      }
      .sacanner {
        width: calc(100% - 130px);
        margin-right: 10px;
      }
    }
  }
  .alert-msg {
    margin-bottom: 15px;
    font-size: 12px;
    color: rgb(210, 210, 210);
    text-align: center;
  }
  ::v-deep(.el-color-picker__trigger) {
    width: 45px;
    height: 26px;
  }
  ::v-deep(.el-icon-arrow-down:before) {
    display: none;
  }
  ::v-deep {
    .el-input-number {
      width: 80px;
      .el-input__inner {
        padding: 0;
        text-align: center;
      }
    }

    .el-input-number__decrease,
    .el-input-number__increase {
      display: none;
    }
  }
}
</style>
