import request from '@/utils/request'

// export function getUserList(data) {
//   return request({
//     url: `/user/uUser/queryPage`,
//     method: 'post',
//     data,
//   })
// }

export function getUserList(params) {
  return request({
    url: `/mall/userinfo/page`,
    method: 'get',
    params: params,
  })
}

export function disableUser(data) {
  return request({
    url: `/user/uUserLogin/disable`,
    method: 'post',
    data,
  })
}

export function getCoupon(data) {
  return request({
    url: `/coupons/cpnCouponConfig/page`,
    method: 'post',
    data,
  })
}

export function addCoupon(data) {
  return request({
    url: `/coupons/cpnCouponUser/addCoupons`,
    method: 'post',
    data,
  })
}

export function resetPwd(data) {
  return request({
    url: `/user/uUserLogin/resetPwd`,
    method: 'post',
    data,
  })
}

export function getProfit(data) {
  return request({
    url: `/merchant/merProfitAmountLog/survey`,
    method: 'post',
    data,
  })
}

export function updateProfit(data) {
  return request({
    url: `/merchant/merCapitalAccount/frozenProfit`,
    method: 'post',
    data,
  })
}
