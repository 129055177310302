var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "three-container" },
    [
      _c("div", { staticClass: "ym_header" }, [_vm._v("控糖管理")]),
      _c(
        "el-form",
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-col",
                    { attrs: { md: { span: 6 }, xs: { span: 24 } } },
                    [
                      _c("WangEditor", {
                        ref: "editor",
                        attrs: { value: _vm.articleContent },
                        on: { wang: _vm.ck },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "text-c",
                      attrs: { md: { span: 6 }, xs: { span: 24 } },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "primary" }, on: { click: _vm.save } },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }