var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "电话通话历史记录",
            visible: _vm.dialogVisible,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "电话1", name: "phone" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "电话2", name: "phonv" },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { border: "", data: _vm.tablist, "max-height": 560 },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "开始时间",
                      prop: "startTime",
                      width: "170px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "结束时间",
                      prop: "endTime",
                      width: "170px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "坐席工号",
                      prop: "agent",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "主叫号码",
                      prop: "callingPhone",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "被叫号码\t",
                      prop: "calledPhone",
                      width: "130px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "呼叫时长(秒)\t",
                      prop: "callDuration",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "振铃时长(秒)\t",
                      prop: "ringingDuration",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "应答时长(秒)\t",
                      prop: "answerDuration",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "叫答方式\t",
                      prop: "hangUp",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type:
                                    row.bloodStatus === 0
                                      ? "success"
                                      : "danger",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.bloodStatus === 0 ? "主叫" : "被叫"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "呼叫状态",
                      prop: "callState",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.callState == 0
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("未接"),
                                ])
                              : _vm._e(),
                            row.callState == 1
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("未转接"),
                                ])
                              : _vm._e(),
                            row.callState == 2
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("呼损"),
                                ])
                              : _vm._e(),
                            row.callState == 3
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("接听后挂断"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": 1,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clear } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }