/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import Vue from 'vue'
import { getUserInfo, login, logout } from '@/api/user'
import { getToken, removeToken, setToken } from '@/utils/token'
// import { removeToken, setToken } from '@/utils/token'
import { resetRouter } from '@/router'
import { isArray, isString } from '@/utils/validate'
import { title, tokenName } from '@/config'

const state = () => ({
  token: getToken(),
  // token: '_kg7fMtokFn0-0mB0AhrGwXGb00',
  nickname: '',
  userinfo: '',
  username: '',
  avatar: 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif',
  userId: '',
  ip: '',
  createTime: '',
  type: '',
  tenantId: '',
})
const getters = {
  token: (state) => state.token,
  username: (state) => state.username,
  nickname: (state) => state.nickname,
  avatar: (state) => state.avatar,
  userId: (state) => state.userId,
  ip: (state) => state.ip,
  createTime: (state) => state.createTime,
  type: (state) => state.type,
  tenantId: (state) => state.tenantId,
  userinfo: (state) => state.userinfo,
}
const mutations = {
  /**
   * @description 设置token
   * @param {*} state
   * @param {*} token
   */
  setToken(state, token) {
    state.token = token
    setToken(token)
  },
  /**
   * @description 设置用户名
   * @param {*} state
   * @param {*} username
   */
  setUsername(state, username) {
    state.username = username
  },
  /**
   * @description 设置昵称
   * @param {*} state
   * @param {*} nickname
   */
  setNickname(state, nickname) {
    state.nickname = nickname
  },
  /**
   * @description 用户信息
   * @param {*} state
   * @param {*} userinfo
   */
  setUserinfo(state, userinfo) {
    state.userinfo = userinfo
  },
  /**
   * @description 设置头像
   * @param {*} state
   * @param {*} avatar
   */
  setAvatar(state, avatar) {
    state.avatar = avatar
  },
  /**
   * @description 设置userId
   * @param {*} state
   * @param {*} avatar
   */
  setUserId(state, userId) {
    state.userId = userId
  },
  /**
   * @description 设置ip
   * @param {*} state
   * @param {*} ip
   */
  setIp(state, ip) {
    state.ip = ip
  },
  /**
   * @description 设置时间
   * @param {*} state
   * @param {*} avatar
   */
  setCreateTime(state, createTime) {
    state.createTime = createTime
  },
  /**
   * @description 账号类型：-1、系统管理员；1、租户管理员；2、店铺管理员 3服务商
   * @param {*} { commit }
   * @param {*} type
   */
  setType(state, type) {
    state.type = type
  },

  /**
   * @description 租户ID
   * @param {*} { commit }
   * @param {*} type
   */
  setTenantId(state, tenantId) {
    // state.tenantId = tenantId
    console.log(tenantId)
    state.tenantId = 1
  },
}
const actions = {
  /**
   * @description 登录拦截放行时，设置虚拟角色
   * @param {*} { commit, dispatch }
   */
  setVirtualRoles({ commit, dispatch }) {
    dispatch('acl/setFull', true, { root: true })
    commit('setAvatar', 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
    commit('setUsername', 'admin(未开启登录拦截)')
  },
  /**
   * @description 登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async login({ commit }, userInfo) {
    // const {data: { [tokenName]: token },
    // } = await login(userInfo)
    const loginData = await login(userInfo)
    const token = loginData[tokenName]
    // console.log('============token============' + token)
    if (token) {
      commit('setToken', token)
      const hour = new Date().getHours()
      const thisTime = hour < 8 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 18 ? '下午好' : '晚上好'
      Vue.prototype.$baseNotify(`欢迎登录${title}`, `${thisTime}！`)
    } else {
      const err = `登录接口异常，未正确返回${tokenName}...`
      Vue.prototype.$baseMessage(err, 'error', 'vab-hey-message-error')
      throw err
    }
  },
  /**
   * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  async getUserInfo({ commit, dispatch }) {
    const {
      data: {
        sysUser: {
          username,
          avatar,
          roles,
          permissions,
          address,
          email,
          geographic,
          phone,
          mobile,
          id,
          type, ////账号类型：-1、系统管理员；1、租户管理员；2、店铺管理员 3服务商
          tenantId,
        },
        nickname,
        ip,
        createTime,
        roleList,
        postList,
        userId,
        profile,
        realName,
        orgId
      },
    } = await getUserInfo()

    let userInfo = {
      username,
      nickname,
      avatar,
      roles,
      permissions,
      address,
      email,
      geographic,
      phone,
      profile,
      mobile,
      id,
      roleList,
      userId,
      postList,
      ip,
      createTime,
      type, //账号类型：-1、系统管理员；1、租户管理员；2、店铺管理员 3服务商
      tenantId, //租户ID
      realName,
      orgId
    }
    console.log('登录成功==JSON.stringify(=====####=>：{}', userInfo)
    console.log(userInfo.userId, 'plikjhg');
    localStorage.setItem('userId', userInfo.userId);
    if (userInfo?.orgId) sessionStorage.setItem('userInfoOrgId', userInfo.orgId);
    if (userInfo) commit('setUserinfo', userInfo)
    /**
     * 检验返回数据是否正常，无对应参数，将使用默认用户名,头像,Roles和Permissions
     * username {String}
     * avatar {String}
     * roles {List}
     * ability {List}
     */
    // console.log('=============会员数据==========================')
    if (
      (username && !isString(username)) ||
      (nickname && !isString(nickname)) ||
      (avatar && !isString(avatar)) ||
      (ip && !isString(ip)) ||
      // (type && !isString(type)) ||
      // (userId && !isString(userId)) ||
      (roles && !isArray(roles)) ||
      (permissions && !isArray(permissions)) ||
      (roleList && !isArray(roleList))
    ) {
      const err = 'getUserInfo核心接口异常，请检查返回JSON格式是否正确'
      Vue.prototype.$baseMessage(err, 'error', 'vab-hey-message-error')
      throw err
    } else {
      // 如不使用username用户名,可删除以下代码
      if (username) commit('setUsername', username)
      if (nickname) commit('setNickname', nickname)
      // 如不使用avatar头像,可删除以下代码
      if (avatar) commit('setAvatar', avatar)
      if (ip) commit('setIp', ip)
      if (createTime) commit('setCreateTime', createTime)

      if (userId) commit('setUserId', userId)
      if (type) commit('setType', type)
      if (tenantId) commit('setTenantId', tenantId)
      // 如不使用roles权限控制,可删除以下代码
      if (roles) dispatch('acl/setRole', roles, { root: true })
      if (roleList) dispatch('acl/setRoleList', roleList, { root: true })
      // 如不使用permissions权限控制,可删除以下代码
      if (permissions) dispatch('acl/setPermission', permissions, { root: true })
    }
  },
  /**
   * @description 退出登录
   * @param {*} { dispatch }
   */
  async logout({ dispatch }) {
    await logout()
    await dispatch('resetAll')
  },
  /**
   * @description 重置token、roles、permission、router、tabsBar等
   * @param {*} { commit, dispatch }
   */
  async resetAll({ commit, dispatch }) {
    commit('setUsername', '游客')
    commit('setUserId', 0)
    commit('setAvatar', 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
    commit('routes/setRoutes', [], { root: true })
    await dispatch('setToken', '')
    await dispatch('acl/setFull', false, { root: true })
    await dispatch('acl/setRole', [], { root: true })
    await dispatch('acl/setRoleList', [], { root: true })
    await dispatch('acl/setPermission', [], { root: true })
    await dispatch('tabs/delAllVisitedRoutes', null, { root: true })
    await resetRouter()
    removeToken()
  },
  /**
   * @description 设置token
   * @param {*} { commit }
   * @param {*} token
   */
  setToken({ commit }, token) {
    commit('setToken', token)
  },

  /**
   * @description 设置登录会员信息
   * @param {*} { commit }
   * @param {*} token
   */
  setUserinfo({ commit }, userinfo) {
    commit('setUserinfo', userinfo)
  },
  /**
   * @description 设置头像
   * @param {*} { commit }
   * @param {*} avatar
   */
  setAvatar({ commit }, avatar) {
    commit('setAvatar', avatar)
  },
  /**
   * @description 设置token
   * @param {*} { commit }
   * @param {*} token
   */
  setUserId({ commit }, userId) {
    commit('setUserId', userId)
  },
  /**
   * @description 设置ip
   * @param {*} { commit }
   * @param {*} ip
   */
  setIp({ commit }, ip) {
    commit('setIp', ip)
  },

  /**
   * @description 设置createTime
   * @param {*} { commit }
   * @param {*} createTime
   */
  setCreateTime({ commit }, createTime) {
    commit('setCreateTime', createTime)
  },
  /**
   * @description 账号类型：-1、系统管理员；1、租户管理员；2、店铺管理员 3服务商
   * @param {*} { commit }
   * @param {*} type
   */
  setType({ commit }, type) {
    commit('setType', type)
  },
  /**
   * @description 租户ID
   * @param {*} { commit }
   * @param {*} type
   */
  setTenantId({ commit }, tenantId) {
    commit('setTenantId', tenantId)
  },
}
export default { state, getters, mutations, actions }
