<template>
  <div class="comprehensive-table-container">
    <div class="GeneralOrder">
      <el-tabs v-model="form.fromInfo" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in tabgs" :key="index" :label="item.label" :name="item.name" />
      </el-tabs>
      <div class="search">
        <el-form class="demo-form-inline" :inline="true" :model="formInline">
          <el-form-item label="订单状态：">
            <el-select v-model="formInline.region" placeholder="请选择 订单状态">
              <el-option label="区域一" value="shanghai" />
              <el-option label="区域二" value="beijing" />
            </el-select>
          </el-form-item>
          <el-form-item label="结算状态：">
            <el-select v-model="formInline.region" placeholder="请选择 结算状态">
              <el-option label="区域一" value="shanghai" />
              <el-option label="区域二" value="beijing" />
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="formInline.vip" placeholder="输入会员号查询" />
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="formInline.vip" placeholder="输入订单号查询" />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="onSubmit">查询</el-button>
            <el-button icon="el-icon-refresh-left" type="primary">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table border :data="tabldata">
        <el-table-column align="center" label="序号" prop="id" width="60px" />
        <el-table-column align="center" label="商品图片">
          <template #default="scope">
            <el-image :src="scope.row.itemImg" />
          </template>
        </el-table-column>
        <el-table-column align="center" class="shopName" label="商品名称" prop="itemTitle" show-overflow-tooltip width="166px" />
        <el-table-column align="center" label="会员号" />
        <el-table-column align="center" label="订单号" prop="tradeId" />
        <el-table-column align="center" label="商品单价" prop="itemPrice" />
        <el-table-column align="center" label="实付金额" prop="alipayTotalPrice" />
        <el-table-column align="center" label="商品总佣金" prop="pubSharePreFee" />
        <el-table-column align="center" label="会员佣金" />
        <el-table-column align="center" label="平台佣金" />
        <el-table-column align="center" label="支付时间" prop="tbPaidTime" />
        <el-table-column align="center" label="来源渠道" prop="orderType" />
        <el-table-column align="center" fixed="right" label="订单状态" />
        <el-table-column align="center" fixed="right" label="平台结算状态" />
      </el-table>
      <el-pagination
        background
        :current-page="1"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
  import { publioder } from '@/api/mall/order/order'
  export default {
    name: 'OrderList',
    data() {
      return {
        form: {
          size: 10,
          current: 1,
          fromInfo: '0',
          orderNo: '',
        },
        total: null,
        tabgs: [
          { label: '全部订单', name: '0' },
          { label: '淘宝订单', name: '1' },
          { label: '拼多多订单', name: '2' },
          { label: '京东订单', name: '3' },
          { label: '唯品会订单', name: '4' },
          { label: '波萝评订单', name: '5' },
          { label: '苏宁订单', name: '6' },
          { label: '美团订单', name: '7' },
          { label: '饿了么订单', name: '8' },
          { label: '携程订单', name: '9' },
          { label: '话费', name: '10' },
          { label: '多麦', name: '11' },
        ],
        formInline: {},
        tabldata: [],
      }
    },
    created() {
      this.publisherOrderDtoBeanc()
    },
    methods: {
      publisherOrderDtoBeanc() {
        publioder({ ...this.form }).then((res) => {
          console.log(res.data)
          this.tabldata = res.data.records
          this.total = res.data.records.length
          this.form.size = res.data.size
          this.form.current = res.data.current
        })
      },
      handleClick() {
        this.publisherOrderDtoBeanc()
      },
      handleCurrentChange(e) {
        this.form.current = e
        this.publisherOrderDtoBeanc()
      },
      handleSizeChange(e) {
        this.form.size = e
        this.publisherOrderDtoBeanc()
      },
      onSubmit() {},
    },
  }
</script>
<style lang="scss" scoped></style>
