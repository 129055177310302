<template>
  <div class="cy-container">
    <!-- 查询 -->
    <el-form ref="queryForm" :inline="true" :model="queryForm" @submit.native.prevent>
      <el-form-item label="分类名称" prop="colName">
        <el-input v-model="queryForm.colName" class="filterItems" clearable placeholder="请输入分类名称" />
      </el-form-item>
      <el-form-item label="分类状态" prop="colStatus">
        <el-select v-model="queryForm.colStatus" class="filterItems" clearable placeholder="请选择">
          <el-option v-for="item in colStatusList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button icon="el-icon-search" native-type="submit" type="warning" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh-left" native-type="submit" type="primary" @click="handleReset">重置</el-button>
      </el-form-item>
      <div class="m-b" style="margin-bottom: 0">
        <el-form-item>
          <!-- <el-button icon="el-icon-plus" type="primary" @click="handelEdit">
          添加分类
        </el-button> -->
          <el-button
            v-for="item in searchButton"
            :key="item.id"
            :plain="item.cssType && item.cssType == 'plain' ? true : false"
            :type="item.buttonCss"
            @click.native="handleClickBtn(item)"
          >
            <span v-if="!item.buttonIcon"></span>
            <vab-icon v-else :icon="item.buttonIcon" />
            {{ item.buttonName }}
          </el-button>
        </el-form-item>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-table ref="multipleTable" v-loading="listLoading" border :data="tableData" default-expand-all max-height="600" @selection-change="handleSelectionChange">
      <el-table-column align="center" show-overflow-tooltip type="selection" />

      <el-table-column align="center" label="老师名称" prop="ctgName" show-overflow-tooltip />

      <el-table-column align="center" label="老师头像" prop="ctgImg" show-overflow-tooltip>
        <template #default="{ row }">
          <div>
            <el-image alt="" fit="fill" :src="row.ctgHead" srcset="" style="width: 60px; height: 60px" @click="handleImgClick(row.ctgHead)" />
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="分类名称" prop="name" show-overflow-tooltip />

      <el-table-column align="center" label="分类图片" prop="ctgImg" show-overflow-tooltip>
        <template #default="{ row }">
          <div>
            <el-image alt="" fit="fill" :src="row.ctgImg" srcset="" style="width: 60px; height: 60px" @click="handleImgClick(row.ctgImg)" />
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="运动时长" prop="ctgDur" show-overflow-tooltip />

      <el-table-column align="center" label="分类简介" prop="ctgIntro" show-overflow-tooltip />
      <el-table-column align="center" label="是否开启" prop="enable" show-overflow-tooltip>
  
        <template #default="{ row }">
          <el-switch v-model="row.enable"  active-value="1" disabled inactive-value="0"/>
        </template>
      </el-table-column>
      <el-table-column align="center" label="排序" prop="sort" show-overflow-tooltip />

      <el-table-column align="center" label="创建时间" prop="createTime" show-overflow-tooltip />
      <el-table-column v-if="operationShow" align="center" label="操作" min-width="100px">
        <template #default="{ row }">
          <template v-for="item in listButton">
            <el-button
              v-if="showListButton(row, item)"
              :key="item.id"
              :plain="item.cssType && item.cssType == 'plain' ? true : false"
              size="default"
              :type="item.buttonCss"
              @click.native="handleClickBtn(item, row)"
            >
              <span v-if="!item.buttonIcon"></span>
              <!-- <vab-icon v-else :icon="item.buttonIcon" /> -->
              {{ item.buttonName }}
            </el-button>
          </template>
          <!-- <el-button type="text" @click="handleLook(row)">查看</el-button>
          <el-button type="text" @click="handleDisable(row)">
            {{ row.colStatus == 1 ? '禁用' : '开启' }}
          </el-button>
          <el-button type="text" @click="handelEdit(row)">编辑</el-button>
          <el-button type="text" @click="handelDelete(row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      :current-page="forms.current"
      :layout="layout"
      :page-size="forms.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 新增(编辑) -->
    <el-dialog class="addDialog" :title="dialogTitle" :visible.sync="addVisible" width="40%" @close="close">
      <el-form ref="addForm" label-width="100px" :model="addForm" :rules="rules">
        <el-form-item label="老师名称" prop="ctgName">
          <el-input v-model="addForm.ctgName" autocomplete="off" clearable placeholder="请输入" />
        </el-form-item>

        <el-form-item label="老师头像" prop="ctgHead" required>
          <el-upload
            ref="upload"
            :action="action"
            :before-upload="bfUpload"
            :class="{
              hide: hideUpload,
            }"
            :file-list="fileList"
            :headers="headers"
            :limit="limitCount"
            list-type="picture-card"
            :on-change="handleChange"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog append-to-body :visible.sync="dialogVisible">
            <img alt="" :src="dialogImageUrl" width="100%" />
          </el-dialog>
        </el-form-item>

        <el-form-item label="分类名称" prop="name">
          <el-input v-model.trim="addForm.name" placeholder="请输入" />
        </el-form-item>

        <el-form-item label="分类图片" prop="ctgImg" required>
          <el-upload
            ref="upload"
            :action="action"
            :before-upload="bfUpload"
            :class="{
              hide: hideUploads,
            }"
            :file-list="fileLists"
            :headers="headers"
            :limit="limitCount"
            list-type="picture-card"
            :on-change="handleChanges"
            :on-preview="handlePictureCardPreviews"
            :on-remove="handleRemoves"
            :on-success="handleSuccesss"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog append-to-body :visible.sync="dialogVisibles">
            <img alt="" :src="dialogImageUrlss" width="100%" />
          </el-dialog>
        </el-form-item>
        <el-form-item label="分类简介" prop="ctgIntro">
          <el-input v-model="addForm.ctgIntro" autocomplete="off" clearable maxlength="50" placeholder="请输入" show-word-limit type="textarea" />
        </el-form-item>
        <el-form-item label="运动时长" prop="ctgDur">
          <el-input-number v-model="addForm.ctgDur" :min="0" placeholder="请输入" size="small" style="width: 100%" />
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="addForm.sort" :min="0" placeholder="请输入" size="small" style="width: 100%" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="imgDialog">
      <img :src="imagesUrl" width="100%" />
    </el-dialog>
    <!-- 查看 -->
    <el-dialog class="addDialog" title="查看" :visible.sync="lookVisible" width="40%">
      <el-form ref="addForm" label-width="100px" :model="lookForm">
        <el-form-item label="老师名称：">
          <el-row class="color117">{{ lookForm.ctgName }}</el-row>
        </el-form-item>
        <el-form-item label="老师头像">
          <el-row class="color117">
            <el-image alt="" fit="fill" :src="lookForm.ctgHead" srcset="" style="width: 110px; height: 100px; border: 1px solid #ddd" @click="handleImgClick(lookForm.ctgHead)" />
          </el-row>
        </el-form-item>

        <el-form-item label="分类名称：">
          <el-row class="color117">{{ lookForm.name }}</el-row>
        </el-form-item>
        <el-form-item label="排序：">
          <el-row class="color117">{{ lookForm.sort }}</el-row>
        </el-form-item>
        <el-form-item label="分类简介：">
          <el-row class="color117">{{ lookForm.ctgIntro }}</el-row>
        </el-form-item>
        <el-form-item label="分类Logo：">
          <el-row class="color117">
            <el-image alt="" fit="fill" :src="lookForm.ctgImg" srcset="" style="width: 110px; height: 100px; border: 1px solid #ddd" @click="handleImgClick(lookForm.ctgImg)" />
          </el-row>
        </el-form-item>
        <el-form-item label="运动时长：">
          <el-row class="color117">{{ lookForm.ctgDur }}</el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="lookVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 配置类型 -->
    <el-dialog v-if="authorizeRoleisible" class="authorizePoles" :close-on-click-modal="false" title="配置类型" :visible.sync="authorizeRoleisible" width="50%" @close="closeRole">
      <el-row>
        <el-col style="padding: 15px; margin-bottom: 15px; border: 1px solid #ddd">
          {{ selectColName }}
        </el-col>
      </el-row>

      <el-transfer v-model="grantRoleList" :data="roleList" filterable :props="{ label: 'typeName', key: 'id' }" :titles="treeTitle" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeRole">取 消</el-button>
        <el-button type="primary" @click="createAuthorizeRoleButton">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { queryPage, addObj, editColumn, delObj, disableColumn, queryListForList, addColumnType } from '@/api/mall/health/coursecate/column'
import { uploadURL } from '@/config'
import { getToken } from '@/utils/token'
import { selectRoleMenuButtonList } from '@/api/mall/common'
export default {
  name: 'ArtColumn',
  data() {
    return {
      selectColName: '',
      multipleSelection: [],
      temp: {},
      postCodeOptions: [],
      treeTitle: ['未配置类型', '已配置类型'],
      grantRoleList: [],
      roleList: [],
      authorizeRoleisible: false,
      operationShow: false,
      lookForm: {},
      lookVisible: false,
      imagesUrl: '',
      imgDialog: false,
      hideUpload: false,
      hideUploads: false,
      limitCount: 1,
      dialogImageUrl: '',
      dialogVisible: false,
      dialogVisibles: false,
      dialogImageUrlss: '',
      fileList: [],
      fileLists: [],
      headers: {},
      action: uploadURL,
      rules: {
        name: [
          {
            required: true,
            message: '分类名称不能为空',
            trigger: ['blur', 'change'],
          },
        ],
        sort: [
          {
            required: true,
            message: '排序不能为空',
            trigger: ['blur', 'change'],
          },
        ],
        ctgIntro: [
          {
            required: true,
            message: '分类简介不能为空',
            trigger: ['blur', 'change'],
          },
        ],
      },
      addForm: {
        ctgImg: '',
        ctgHead: '',
      },
      addVisible: false,
      dialogTitle: '',
      colStatusList: [
        {
          id: '1',
          name: '开启',
        },
        {
          id: '0',
          name: '禁用',
        },
      ],
      listLoading: false,
      forms: { current: 1, size: 10, ctgType: 1 },
      isShow: true,
      queryForm: {
        colStatus: '',
        colName: '',
      },
      tableData: [],
      current: 1,
      size: 10,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      searchButton: [],
      listButton: [],
      imageUrl: '',
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/token',
      tenantId: 'user/tenantId',
    }),
  },
  created() {
    const db = getToken()
    this.headers['Authorization'] = `Bearer ` + `${db}`
    this.headers['Switch-Tenant-Id'] = `${this.tenantId}`
    this.fetchData()
    this.selectRoleMenuButtonList()
  },
  methods: {
    // 配置类型保存
    createAuthorizeRoleButton() {
      if (this.grantRoleList.length === 0) {
        this.ME('已配置类型不能为空')
        return
      }
      const tempData = {}
      tempData.typeIds = this.grantRoleList
      tempData.colId = this.multipleSelection[0].id
      addColumnType(tempData).then(() => {
        this.authorizeRoleisible = false
        this.MS('成功')
        this.closeRole()
        this.fetchData()
      })
    },
    // 查询配置类型
    queryListForList() {
      queryListForList({})
        .then((res) => {
          if (res.data) {
            this.roleList = res.data
          }
        })
        .catch(() => {})
    },
    // 配置类型
    handleType() {
      if (this.multipleSelection.length === 0) {
        this.ME('请勾选记录')
        return
      }
      this.authorizeRoleisible = true
      this.queryListForList()
    },
    // 表格操作
    handleSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable.toggleRowSelection(val.pop())
      } else {
        this.multipleSelection = val
        if (val.length != 0) {
          this.selectColName = val[0].colName
        }
      }
    },

    // 配置类型(取消)
    closeRole() {
      this.grantRoleList = []
      this.roleList = []
      this.authorizeRoleisible = false
    },
    // 按钮状态显示判断
    showListButton(row, item) {
      if (item.buttonUrl == 'handleLook' || item.buttonUrl == 'handelEdit' || item.buttonUrl == 'handelDelete') {
        return true
      }
      if (row.enable == 1 && item.buttonName == '禁用') {
        return true
      } else if (row.enable == 0 && item.buttonName == '开启') {
        return true
      }
    },
    // 按钮点击
    handleClickBtn(item, row) {
      console.log(item,'---------------')
      if (item.buttonUrl) {
        this[item.buttonUrl](row)
      }
    },
    // 按钮权限
    selectRoleMenuButtonList() {
      selectRoleMenuButtonList({
        roleIdStrList: this.$store.getters['acl/roleList'],
        menuId: this.$route.meta.id,
      })
        .then((res) => {
          if (res.data) {
            this.searchButton = res.data.filter(function (item) {
              return item.buttonType == 1
            })
            this.listButton = res.data.filter(function (item) {
              return item.buttonType == 2
            })
            if (this.listButton.length > 0) {
              this.operationShow = true
            } else {
              this.operationShow = false
            }
          }
        })
        .catch(() => {})
    },
    // 图片上传
    bfUpload(file) {
      console.log(file)
      if ('image/png' == file.type || 'image/jpeg' == file.type || 'image/jpg' == file.type) {
        console.log()
        this.addForm.headers = this.headers
      } else {
        this.ME('图片上传失败,请上传png,jpeg,jpg格式')
        return false
      }
    },
    // 图片预览
    handleImgClick(url) {
      this.imagesUrl = url
      this.imgDialog = true
    },
    // 图片
    handleChange(file, fileList) {
      this.hideUpload = fileList.length >= this.limitCount
    },
    handleChanges(file, fileList) {
      this.hideUploads = fileList.length >= this.limitCount
    },
    // 分类Logo
    handleSuccess(response) {
      this.addForm.ctgHead = response.data.filePath
      this.fileList.push({
        url: response.data.filePath,
      })
    },
    handleSuccesss(response) {
      this.addForm.ctgImg = response.data.filePath
      this.fileLists.push({
        url: response.data.filePath,
      })
    },
    // 删除图片
    handleRemove(fileList) {
      this.fileList = []
      this.addForm.ctgHead = ''
      this.hideUpload = fileList.length >= this.limitCount
    },
    handleRemoves(fileList) {
      this.fileLists = []
      this.addForm.ctgImg = ''
      this.hideUploasd = fileList.length >= this.limitCount
    },
    // 图片显示
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handlePictureCardPreviews(file) {
      this.dialogImageUrlss = file.url
      this.dialogVisibles = true
    },
    // 编辑
    handelEdit(row) {
      if (row) {
        this.showEdit(row)
      } else {
        this.showEdit()
      }
    },
    // 查看
    handleLook(row) {
      this.lookForm = Object.assign({}, row)
      this.lookVisible = true
    },
    // 添加编辑title
    showEdit(row) {
      this.row = row
      if (!row) {
        this.dialogTitle = '添加'
        this.hideUpload = this.fileList.length >= this.limitCount
      } else {
        this.dialogTitle = '编辑'
        this.rowId = row.id
        this.addForm.ctgImg = row.ctgImg
        this.fileList = []
        if (row.ctgImg && row.ctgImg != null) {
          this.fileList.push({
            url: this.addForm.ctgImg,
          })
          this.hideUpload = this.fileList.length >= this.limitCount
        }
        this.hideUpload = this.fileList.length >= this.limitCount
        this.addForm = Object.assign({}, row)
      }
      this.addVisible = true
    },
    // 关闭
    close() {
      this.fileList = []
      this.$refs['addForm'].resetFields()
      this.addForm = this.$options.data().addForm
      this.addVisible = false
      this.fileList = []
    },
    // 保存
    save() {
      if (this.addForm.ctgImg == '' || this.addForm.ctgImg == null) {
        return this.ME('请上传分类LOGO')
      }
      if (this.addForm.ctgHead == '' || this.addForm.ctgHead == null) {
        return this.ME('请上传头像')
      }
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          const parms = {
            name: this.addForm.name,
            sort: this.addForm.sort,
            ctgIntro: this.addForm.ctgIntro,
            ctgHead: this.addForm.ctgHead,
            ctgImg: this.addForm.ctgImg,
            ctgName: this.addForm.ctgName,
            ctgDur: this.addForm.ctgDur,
            ctgType: 1,
            enable: 1,
            delFlag: 0,
          }
          if (!this.row) {
            addObj(parms)
              .then(() => {
                this.MS('新增成功')
                this.fetchData()
                this.close()
              })
              .catch(() => {})
          } else {
            editColumn({ ...parms, id: this.rowId })
              .then(() => {
                this.MS('修改成功')
                this.fetchData()
                this.close()
              })
              .catch(() => {})
          }
        }
      })
    },
    // 列表
    async fetchData(options) {
      this.listLoading = true
      await queryPage({
        ...options,
        ...this.forms,
        // current: this.current,
        // size: this.size,
        // colStatus: this.queryForm.colStatus,
        // colName: this.queryForm.colName,
      })
        .then((res) => {
          console.log(res.data,'-----------------===================')
          if (res.data) {
            console.log(this.tableData,'===================');
            this.tableData = res.data.records
            this.total = res.data.total
          }
        })
        .catch(() => {})
      this.listLoading = false
    },

    // 禁用开启
    handleDisable(row) {
      this.$confirm('是否要更新此条数据的状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          disableColumn({ id: row.id, enable: row.enable == 1 ? 0 : 1})
            .then(() => {
              this.MS('成功')
              this.fetchData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 删除
    handelDelete(row) {
      this.$confirm('是否要删除此行数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delObj({ id: row.id })
            .then(() => {
              this.MS('删除成功')
              this.fetchData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 重置
    handleReset() {
      this.forms.current = 1
      this.forms.size = 10
      this.queryForm = {}
      this.handleQuery()
    },
    // 搜索
    async handleQuery() {
      this.forms.current = 1
      await this.fetchData({ ...this.queryForm }, false)
      let temp = false
      Object.keys(this.queryForm).forEach((key) => {
        this.queryForm[key] && (temp = true)
      })
      this.isShow = temp
    },
    // 分页
    handleSizeChange(val) {
      this.forms.size = val
      let data = this.isShow === true ? this.queryForm : {}
      this.fetchData({ ...data }, false)
    },
    // 分页
    handleCurrentChange(val) {
      this.forms.current = val
      let data = this.isShow === true ? this.queryForm : {}
      this.fetchData({ ...data }, false)
    },
  },
}
</script>
<style lang="scss" scoped>
.authorizePoles {
  ::v-deep(.el-transfer-panel) {
    width: 40%;
  }
}

::v-deep(.hide .el-upload--picture-card) {
  display: none;
}
.filterItems {
  width: 150px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
