<template>
  <div>
    <el-dialog :before-close="clear" title="历史数据" :visible.sync="appointDialog" width="75%">
      <el-tabs v-model="activeTab">
        <el-tab-pane v-for="(item, index) in forms" :key="index" :label="`数据 ${index + 1}`">
          <div :class="screen ? 'contents' : 'content'">
            <el-form>
              <el-row>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">体检时间</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    <el-date-picker v-model="item.userYear" forma="yyyy-MM-dd" placeholder=" 年 月 日" type="datetime"
                      value-format="yyyy-MM-dd" />
                    <!-- <el-date-picker v-model="dormdate.userYear" placeholder=" 年 月 日" type="date" /> -->
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">手机号</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark"><el-input v-model="item.phone" placeholder="手机号" /></div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark">姓名</div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark"><el-input v-model="item.userName" placeholder="姓名" /></div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark">性别</div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark">
                    <el-select v-model="item.userSex" placeholder="请选择客户性别">
                      <el-option v-for="ite in options" :key="ite.value" :label="ite.label" :value="ite.value" />
                    </el-select>

                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark">年龄</div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark"><el-input v-model="item.userAge" placeholder="年龄" /></div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark">患病年限</div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark"><el-input v-model="item.fattyIlness" placeholder="患病年限" />
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark">身高 cm</div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark">体重 kg</div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark">BMI</div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark">腰围</div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark">血压</div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark">心率</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">是否规律用药</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark"><el-input v-model="item.userHeight" placeholder="身高" /></div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark"><el-input v-model="item.userWeight" placeholder="体重" /></div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark"><el-input v-model="item.userMbi" placeholder="BMI" /></div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark"><el-input v-model="item.userWaist" placeholder="腰围" /></div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark"><el-input v-model="item.userPressure" placeholder="血压" />
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark"><el-input v-model="item.userHeart" placeholder="心率" /></div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    <el-radio-group v-model="item.medRegularly">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark" style="height: 72px; line-height: 72px">是否打针</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    胰岛素
                    <el-checkbox label="" />
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    药名：
                    <el-input v-model="item.casePos1[0].insulinTitle" placeholder="药名" />
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    剂量：
                    <el-input v-model="item.casePos1[0].caseDose" placeholder="剂量" />
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    GLP-1 类
                    <el-checkbox label="" />
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    药名：
                    <el-input v-model="item.casePos1[1].insulinTitle" placeholder="药名" />
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    剂量：
                    <el-input v-model="item.casePos1[1].caseDose" placeholder="剂量" />
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark" style="height: 146px; line-height: 146px">是否口服药</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    药名：
                    <el-input v-model="item.casePos2[0].insulinTitle" placeholder="药名" />
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple-dark">
                    一天
                    <div class="textundeline"><el-input v-model="item.casePos2[0].caseCishu" placeholder=""
                        style="max-width: 55px" /></div>
                    次,一次
                    <div class="textundeline"><el-input v-model="item.casePos2[0].casePian" placeholder=""
                        style="max-width: 55px" /></div>
                    片，一片
                    <div class="textundeline"><el-input v-model="item.casePos2[0].caseMg" placeholder=""
                        style="max-width: 55px" /></div>
                    mg
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    药名：
                    <el-input v-model="item.casePos2[1].insulinTitle" placeholder="药名" />
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple-dark">
                    一天
                    <div class="textundeline"><el-input v-model="item.casePos2[1].caseCishu" placeholder=""
                        style="max-width: 55px" /></div>
                    次,一次
                    <div class="textundeline"><el-input v-model="item.casePos2[1].casePian" placeholder=""
                        style="max-width: 55px" /></div>
                    片，一片
                    <div class="textundeline"><el-input v-model="item.casePos2[1].caseMg" placeholder=""
                        style="max-width: 55px" /></div>
                    mg
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    药名：
                    <el-input v-model="item.casePos2[2].insulinTitle" placeholder="药名" />
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple-dark">
                    一天
                    <div class="textundeline"><el-input v-model="item.casePos2[2].caseCishu" placeholder=""
                        style="max-width: 55px" /></div>
                    次,一次
                    <div class="textundeline"><el-input v-model="item.casePos2[2].casePian" placeholder=""
                        style="max-width: 55px" /></div>
                    片，一片
                    <div class="textundeline"><el-input v-model="item.casePos2[2].caseMg" placeholder=""
                        style="max-width: 55px" /></div>
                    mg
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    药名：
                    <el-input v-model="item.casePos2[3].insulinTitle" placeholder="药名" />
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple-dark">
                    一天
                    <div class="textundeline"><el-input v-model="item.casePos2[3].caseCishu" placeholder=""
                        style="max-width: 55px" /></div>
                    次,一次
                    <div class="textundeline"><el-input v-model="item.casePos2[3].casePian" placeholder=""
                        style="max-width: 55px" /></div>
                    片，一片
                    <div class="textundeline"><el-input v-model="item.casePos2[3].caseMg" placeholder=""
                        style="max-width: 55px" /></div>
                    mg
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">是否会监测血糖</div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple-dark">
                    <el-radio-group v-model="item.sugarStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                    <div v-if="item.sugarStatus == 1">
                      (空腹：
                      <el-input v-model="item.sugarStomach" placeholder="" style="max-width: 100px" />
                      餐后2小时:
                      <el-input v-model="item.sugarMeal" placeholder="" style="max-width: 100px" />
                      )
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">是否出现低血糖</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    <el-radio-group v-model="item.sugarLowStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div v-if="item.sugarLowStatus == 1" class="grid-content bg-purple-dark">出现时间和频率</div>
                  <div v-else class="grid-content bg-purple-dark">出现时间和频率</div>
                </el-col>
                <el-col :span="6">
                  <div v-if="item.sugarLowStatus == 1" class="grid-content bg-purple-dark">
                    <el-input v-model="item.sugarLowMeal" placeholder="出现时间和频率" />
                  </div>
                  <div v-else class="grid-content bg-purple-dark"></div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark" style="height: 100px">既往病史</div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple-dark" style="flex-wrap: wrap; height: 100px">
                    <el-checkbox-group v-model="item.casePos3" style="white-space: wrap">
                      <el-checkbox v-for="(yn, ind) in ca" :key="ind" :label="yn.caseName" />
                    </el-checkbox-group>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">既往史是否用药</div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple-dark" style="white-space: nowrap">
                    <el-radio-group v-model="item.drugStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                    <div v-if="item.drugStatus == 1" style="margin-left: 20px">药物名称：</div>
                    <el-input v-if="item.drugStatus == 1" v-model="item.drugName" placeholder="药物名称" />
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">住院史</div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple-dark" style="white-space: nowrap">
                    <el-input v-model="item.addressDetail" placeholder="住院史地址" />
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">脂肪肝</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark" style="white-space: nowrap">
                    <el-radio-group v-model="item.fattyStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">家族史</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark">
                    <el-input v-model="item.fattyName" placeholder="" />
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <div class="grid-content bg-purple-dark" style="height: 45px">最希望改善的地方</div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple-dark" style="flex-wrap: wrap; height: 45px">
                    <el-checkbox-group v-model="item.casePos4" style="white-space: wrap">
                      <el-checkbox v-for="(yn, inde) in cas" :key="inde" :label="yn.caseName" />
                    </el-checkbox-group>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="3">
                  <div class="grid-content bg-purple-dark" style="height: 257px">身体症状</div>
                </el-col>
                <el-col :span="21">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    1、有无手麻、脚麻或皮肤瘙痒等症状？
                    <el-radio-group v-model="item.casePos5[0].caseStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
                <el-col :span="21">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    2、有无失眠、多梦或便秘等症状？
                    <el-radio-group v-model="item.casePos5[1].caseStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
                <el-col :span="21">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    3、是否有便秘、腹泻等症状？
                    <el-radio-group v-model="item.casePos5[2].caseStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
                <el-col :span="21">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    4、有无心慌、胸闷、气短、头晕头痛等心脑血管症状？
                    <el-radio-group v-model="item.casePos5[3].caseStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
                <el-col :span="21">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    5、有无视物模糊？
                    <el-radio-group v-model="item.casePos5[4].caseStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
                <el-col :span="21">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    6、有无尿液泡沫增多？
                    <el-radio-group v-model="item.casePos5[5].caseStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
                <el-col :span="21">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    7、有无糖尿病足部病变（如损伤后不容易愈合）？
                    <el-radio-group v-model="item.casePos5[6].caseStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="grid-content bg-purple-dark">饮食生活调查问卷</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <div class="grid-content bg-purple-dark lef_ym">1、平时吃一顿饭用时</div>
                </el-col>
                <el-col :span="16">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    大概
                    <div class="textundeline"><el-input v-model="item.caseMg" placeholder="" style="max-width: 55px" />
                    </div>
                    分钟
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <div class="grid-content bg-purple-dark lef_ym">2、晚上 8 点后会吃食物吗？</div>
                </el-col>
                <el-col :span="16">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    <el-radio-group v-model="item.casePos6[1].caseStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                    一周
                    <div class="textundeline"><el-input v-model="item.casePos6[1].caseCishu" placeholder=""
                        style="max-width: 55px" /></div>
                    次
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <div class="grid-content bg-purple-dark lef_ym">3、三餐以外会吃食物/零食吗？</div>
                </el-col>
                <el-col :span="16">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    <el-radio-group v-model="item.casePos6[2].caseStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                    一周
                    <div class="textundeline"><el-input v-model="item.casePos6[2].caseCishu" placeholder=""
                        style="max-width: 55px" /></div>
                    次
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <div class="grid-content bg-purple-dark lef_ym">4、是否常喝饮料/酒</div>
                </el-col>
                <el-col :span="16">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    <el-radio-group v-model="item.casePos6[3].caseStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                    一周
                    <div class="textundeline"><el-input v-model="item.casePos6[3].caseCishu" placeholder=""
                        style="max-width: 55px" /></div>
                    次
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <div class="grid-content bg-purple-dark lef_ym">5、平时会运动吗？</div>
                </el-col>
                <el-col :span="16">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    <el-radio-group v-model="item.casePos6[4].caseStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                    一周
                    <div class="textundeline"><el-input v-model="item.casePos6[4].caseCishu" placeholder=""
                        style="max-width: 55px" /></div>
                    次
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <div class="grid-content bg-purple-dark lef_ym">6、是否 12 点之前入睡？</div>
                </el-col>
                <el-col :span="16">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    <el-radio-group v-model="item.casePos6[5].caseStatus">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <div class="grid-content bg-purple-dark lef_ym">7、睡眠质量好吗？</div>
                </el-col>
                <el-col :span="16">
                  <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                    <el-radio-group v-model="item.casePos6[6].caseStatus">
                      <el-radio :label="1">好</el-radio>
                      <el-radio :label="0">不好</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getHistury } from '@/api/mall/health/casemy/index'
export default {
  props: {
    showtab: {
      type: Boolean,
      default: () => { },
    },
    hanuser: {
      type: Number,
      default: () => { },
    },
  },
  data() {
    return {
      activeTab: '0',
      appointDialog: false,
      selectId: '',
      screen: false,
      forms: [],
      options: [
        {
          label: '男',
          value: '1',
        },
        {
          label: '女',
          value: '0',
        },
      ],
      dormdate: {
        sugarStatus: '1',
        medRegularly: '1',
        sugarLowStatus: '1',
        drugStatus: '1',
        fattyStatus: '1',
        casePos1: [
          {
            caseName: '胰岛素',
            insulinTitle: ""
          },
          {
            caseName: 'GLP-1 类',
            insulinTitle: ""
          },
        ],
        casePos2: [{ insulinTitle: "" }, { insulinTitle: "" }, { insulinTitle: "" }, { insulinTitle: "" }],
        casePos3: [],
        casePos4: [],
        casePos5: [
          { caseClassify: '1', caseStatus: 0 },
          { caseClassify: '2', caseStatus: 0 },
          { caseClassify: '3', caseStatus: 0 },
          { caseClassify: '4', caseStatus: 0 },
          { caseClassify: '5', caseStatus: 0 },
          { caseClassify: '6', caseStatus: 0 },
          { caseClassify: '7', caseStatus: 0 },
        ],
        casePos6: [
          { caseClassify: '1' },
          { caseClassify: '2', caseStatus: 0 },
          { caseClassify: '3', caseStatus: 0 },
          { caseClassify: '4', caseStatus: 0 },
          { caseClassify: '5', caseStatus: 0 },
          { caseClassify: '6', caseStatus: 0 },
          { caseClassify: '7', caseStatus: 0 },
        ],
      },
      cas: [
        { selected: false, caseStatus: 0, caseName: '血糖平稳' },
        { selected: false, caseStatus: 0, caseName: '减重' },
        { selected: false, caseStatus: 0, caseName: '减药' },
        { selected: false, caseStatus: 0, caseName: '停药' },
        { selected: false, caseStatus: 0, caseName: '预防/延缓并发症' },
      ],
      ca: [
        { selected: false, caseStatus: 0, caseName: '以下均无' },
        { selected: false, caseStatus: 0, caseName: '高脂血症' },
        { selected: false, caseStatus: 0, caseName: '高血压' },
        { selected: false, caseStatus: 0, caseName: '肾病' },
        { selected: false, caseStatus: 0, caseName: '胆囊炎' },
        { selected: false, caseStatus: 0, caseName: '尿蛋白/尿微量白蛋白异常' },
        { selected: false, caseStatus: 0, caseName: '其他' },
      ],
    }
  },
  watch: {
    showtab(newValue, oldValue) {
      if (newValue != oldValue) {
        this.appointDialog = newValue
      }
    },
    hanuser(newValue, oldValue) {
      if (newValue != oldValue) {
        this.selectId = newValue
        this.getList(newValue)
      }
    },
  },

  created() {
    this.activeTab = '0'
  },
  mounted() {
    this.activeTab = '0'
  },
  methods: {

    getList(e) {
      getHistury({ id: e }).then(res => {
        console.log(res, 'res');
        this.forms = res.data
        this.forms.forEach((item) => {
          if (item.casePos1.length === 0) {
            item.casePos1 = this.dormdate.casePos1
          }
          if (item.casePos2.length === 0) {
            item.casePos2 = this.dormdate.casePos2
          }
          if (item.casePos5.length === 0) {
            item.casePos5 = this.dormdate.casePos5
          }
          if (item.casePos6.length === 0) {
            item.casePos6 = this.dormdate.casePos6
          }
        })
      })
    },
    clear() {
      this.$emit('closover')
    },
    sure() {
      this.$emit('closover')
      this.activeTab = '0';

    },
  }
}

</script>

<style lang="scss" scoped>
::v-deep {
  .el-col {
    border: 1px solid #b4b4b4;
  }

  .el-input__inner {
    border: none;
  }
}

.content {
  width: 90%;
  max-height: 350px;
  margin: auto;
  overflow-y: scroll;
}

.bg-purple-dark {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 0 10px;
  white-space: nowrap;

}

.textundeline {
  min-width: 20px;
  border-bottom: 1px solid #a3a3a3;
}

.lef_ym {
  justify-content: left;
}

.solt {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

.tage {
  font-size: 14px;
}

.activ {
  font-size: 16px;
}

.sc {
  width: 100%;
  height: 100%;
  margin-top: 0;
}

.contents {
  width: 70%;
  height: calc(100vh - 450px);
  margin: auto;
  overflow: hidden;
  overflow-y: scroll;
}
</style>