var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        "before-close": _vm.clearclose,
        title: "预存金额",
        visible: _vm.dialogVisible,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-form",
            {
              ref: "formName",
              attrs: {
                inline: true,
                "label-width": "120px",
                model: _vm.amount,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "交易类型：", prop: "optType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { disabled: "", placeholder: "请选择交易类型" },
                      model: {
                        value: _vm.amount.optType,
                        callback: function ($$v) {
                          _vm.$set(_vm.amount, "optType", $$v)
                        },
                        expression: "amount.optType",
                      },
                    },
                    [_c("el-option", { attrs: { label: "汇入", value: "0" } })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "交易渠道：", prop: "bussType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { placeholder: "请选择交易渠道" },
                      on: { change: _vm.getquery },
                      model: {
                        value: _vm.amount.bussType,
                        callback: function ($$v) {
                          _vm.$set(_vm.amount, "bussType", $$v)
                        },
                        expression: "amount.bussType",
                      },
                    },
                    _vm._l(_vm.tradeType, function (ym, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: ym.tradeName, value: ym.key },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "交易金额(元)：", prop: "logAmount" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { placeholder: "请输入 交易金额" },
                    model: {
                      value: _vm.amount.logAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.amount, "logAmount", $$v)
                      },
                      expression: "amount.logAmount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: `${_vm.claName}：`, prop: "labelId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { placeholder: `请选择${_vm.claName}` },
                      on: { change: _vm.selected, focus: _vm.selgetter },
                      model: {
                        value: _vm.amount.labelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.amount, "labelId", $$v)
                        },
                        expression: "amount.labelId",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.labelName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: `${_vm.claName}账号：`, prop: "bankNumber" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { placeholder: `请输入${_vm.claName} 账号` },
                    model: {
                      value: _vm.amount.bankNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.amount, "bankNumber", $$v)
                      },
                      expression: "amount.bankNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: `${_vm.claName}户名：`, prop: "labelName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { placeholder: `请输入${_vm.claName} 户名` },
                    model: {
                      value: _vm.amount.labelName,
                      callback: function ($$v) {
                        _vm.$set(_vm.amount, "labelName", $$v)
                      },
                      expression: "amount.labelName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收款时间：", prop: "processTime" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "180px" },
                    attrs: {
                      format: "yyyy-MM-dd ",
                      placeholder: "请选择 收款时间",
                      type: "date",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.amount.processTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.amount, "processTime", $$v)
                      },
                      expression: "amount.processTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "交易状态：", prop: "preStatus" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { placeholder: "请选择 交易状态" },
                      model: {
                        value: _vm.amount.preStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.amount, "preStatus", $$v)
                        },
                        expression: "amount.preStatus",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "已到款", value: "1" },
                      }),
                      _c("el-option", {
                        attrs: { label: "未到款", value: "2" },
                      }),
                      _c("el-option", {
                        attrs: { label: "已经取消", value: "3" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "交易备注：", prop: "content" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { placeholder: "请输入 交易备注", type: "textarea" },
                    model: {
                      value: _vm.amount.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.amount, "content", $$v)
                      },
                      expression: "amount.content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.clearclose } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }