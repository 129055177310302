var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "addBtn",
          attrs: { icon: "el-icon-plus", type: "primary" },
          on: {
            click: function ($event) {
              _vm.dialogFormVisible = true
            },
          },
        },
        [_vm._v(" 新增 ")]
      ),
      _c(
        "el-table",
        { attrs: { border: "", data: _vm.data } },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "名称", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", prop: "createTime" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "更新时间", prop: "updateTime" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", prop: "id" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.edita(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.del(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.list(scope.row)
                          },
                        },
                      },
                      [_vm._v("规格值列表")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": 1,
              layout: "total,sizes, prev, pager, next,jumper",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            rules: _vm.rules,
            title: "新增",
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: function ($event) {
              return _vm.Cancel(1, false)
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.Cancel(0, false)
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.Cancel(1, false)
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ym_adnew" },
        [
          _c(
            "el-dialog",
            {
              attrs: { rules: _vm.rules, title: "编辑", visible: _vm.hide },
              on: {
                "update:visible": function ($event) {
                  _vm.hide = $event
                },
                close: function ($event) {
                  return _vm.editb()
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.adfile } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入修改的名称" },
                        model: {
                          value: _vm.adfile.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.adfile, "name", $$v)
                          },
                          expression: "adfile.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.edi(0, false)
                        },
                      },
                    },
                    [_vm._v("修改")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cacels()
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("specifications", {
            attrs: { cheknum: _vm.number, dat: _vm.sear, hide: _vm.showtab },
            on: { transhow: _vm.tabhide },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }