<template>
  <div
    class="header"
    :style="{
      background: componentContent[terminal].pageBgColor,
    }"
  >
    <div
      class="search"
      style="display: flex; align-items: center"
      :style="{
        paddingTop: componentContent[terminal].margin.top + 'px',
        paddingBottom: componentContent[terminal].margin.bottom + 'px',
      }"
    >
      <div
        class="search-content"
        style="display: flex; width: 100%"
        :style="{
          margin: `0 ${componentContent[terminal].margin.both}` + 'px',
          marginTop: componentContent[terminal].margin.top + 'px',
          marginBottom: componentContent[terminal].margin.bottom + 'px',
        }"
      >
        <img v-if="componentContent[terminal].searchStyle == 2" alt="" :src="componentContent[terminal].imageUrl" style="max-width: 85px; height: 30px; margin-right: 10px" />
        <div
          class="search-sacnner"
          style="position: relative; width: 100%; height: 30px"
          :style="{
            color: componentContent[terminal].textColor,
            background: componentContent[terminal].componentBgColor,
          }"
        >
          <div class="searchcon">
            <div
              class="sear-txt"
              style="line-height: 30px"
              :style="{
                'text-indent': componentContent[terminal].textAlign == 'left' ? '2em' : '',
                textAlign: componentContent[terminal].textAlign == 'center' ? 'center' : '',
                marginLeft: componentContent[terminal].textAlign == 'left' ? '0' : '',
                borderRadius: componentContent[terminal].borderType == '1' ? '0' : '50px',
                background: componentContent[terminal].elementBgColor,
              }"
            >
              {{ componentContent[terminal].title }}
            </div>
          </div>
          <i
            class="iconfont icon-sousuo"
            style="position: absolute; top: 50%; transform: translateY(-50%)"
            :style="{
              right: componentContent[terminal].searchStyle < 3 ? '10px' : '',
              left: componentContent[terminal].searchStyle == 3 ? '10px' : '',
            }"
          ></i>
        </div>
        <div v-if="componentContent[terminal].searchStyle == 3" class="search-icon">
          <img alt="" :src="componentContent[terminal].imageUrl" style="max-width: 85px; height: 30px; margin-left: 10px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    props:{
      index: {
        type: Number,
        default: () => {},
      },
    },
    data() {
      return {
        activeComponent: '',
        terminal: this.index,
      }
    },
    computed: {
      ...mapState({
        // activeComponent: state => state.checklist,
        componentContent: state=>state.checkvalue
      }),
    },
    watch:{
      index(newValue,oldValue){
        if(newValue!=oldValue){
          this.terminal=newValue
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .header {
    .search {
      width: 100%;
    }
  }
</style>
