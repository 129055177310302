<template>
  <div style="height: 79vh; padding: 10px">
    <div style="display: flex; flex-wrap: wrap;margin-bottom: 15px;">
      <div class="title-item">
        <span>手机号</span>
        <el-input
          v-model.trim="formInline.phone"
          class="title-input"
          placeholder="请输入手机号码"
        />
      </div>
      <div class="title-item">
        <el-button icon="el-icon-search" type="warning" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          type="primary"
          @click="handleReset"
        >
          重置
        </el-button>
      </div>
    </div>
    <el-table border :data="userList" :max-height="610" style="width: 100%">
      <el-table-column align="center" label="用户编码" prop="userCode" />
      <el-table-column
        align="center"
        label="头像"
        prop="headimgUrl"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <div>
            <el-image
              alt=""
              :fit="fill"
              :src="row.headimgUrl"
              srcset=""
              style="width: 50px; height: 50px"
              @click="handleImgClick(row.headimgUrl)"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="手机号" prop="phone" />
      <el-table-column align="center" label="用户来源" prop="userSource">
        <template #default="{ row }">
          <div>{{ row.appType | getSource }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="昵称" prop="nickName" />
      <el-table-column align="center" label="所在城市" prop="city" />
      <el-table-column align="center" label="创建时间" prop="createTime" />
      <el-table-column align="center" label="操作" width="220px">
        <template #default="{ row }">
          <el-button type="text" @click="handleLook(row)">设置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.current"
      :layout="layout"
      :page-size="queryForm.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <el-dialog title="会员计划" :visible.sync="dialogFormVisible">
      <DialogDemo :ymdialog="DateRow" @transfer="dm" />
    </el-dialog>
  </div>
</template>

<script>
  import { getUserList, disableUser } from '@/api/mall/users/list'
  import { selectSysDict } from '@/api/mall/common'
  import { checkButton } from '@/utils/mixins'
  import DialogDemo from './componets/digan.vue'
  export default {
    name: 'UserList',
    components: {
      DialogDemo,
    },
    filters: {
      getSource(val) {
        let str = ''
        switch (val) {
          case 'H5':
            str = '普通H5'
            break
          case 'MA':
            str = '小程序'
            break
          case 'WX':
            str = '公众号H5'
            break
          case 'APP':
            str = 'APP'
            break
          case 'PC':
            str = 'PC端H5'
            break
        }
        return str
      },
      getLevel(val) {
        let str = ''
        switch (val) {
          case 1:
            str = '1'
            break
          case 23:
            str = ''
            break
        }
        return str
      },
    },
    mixins: [checkButton],
    data() {
      return {
        listLoading: true,
        userList: [],
        datePicker: [],
        userSource: [],
        userIdentity: [],
        personnelIdentity: [],
        isSearch: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        formInline: {
          id: '',
          nickname: '',
          userSource: '',
          phone: '',
          memberLevel: '',
          userType: '',
          beginTime: '',
          endTime: '',
        },
        total: 0,
        queryForm: {
          current: 1,
          size: 10,
        },
        dialogFormVisible: false,
        DateRow: '',
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleReset() {
        this.formInline = {
          id: '',
          nickname: '',
          userSource: '',
          phone: '',
          memberLevel: '',
          userType: '',
          beginTime: '',
          endTime: '',
        }
        this.datePicker = []
        // this.handleSearch()
        this.fetchData({},false)
      },
      disableUser(row) {
        this.$confirm('此操作将禁用此用户，是否继续？', '提示', {
          confirmButtonText: '禁用',
          type: 'warning',
        }).then(() => {
          const { id } = row
          disableUser({ userId: id }).then(() => {
            this.fetchData(this.formInline)
            this.$message.success('禁用成功！')
          })
        })
      },
      openDialog(e, btns, row) {
        const obj = btns.find((item) => item.id == e)
        obj && this.$refs[obj.buttonUrl].show(row)
      },
      async fetchData(options = {}) {
        this.listLoading = true
        const {
          data: { records: userList, total },
        } = await getUserList({ ...this.queryForm, ...options })
        this.handleAddTableButtonRole(userList, {})
        const dicData = await selectSysDict({
          dictTypeList: ['userFrom', 'userIdentity', 'personnelIdentity'],
        })
        this.userSource = dicData.data[0].dictInfoVoList
        this.userIdentity = dicData.data[1].dictInfoVoList
        this.personnelIdentity = dicData.data[2].dictInfoVoList
        this.userList = userList
        this.total = total
      },
      async handleSearch() {
        this.queryForm.current = 1
      
  if (Object.values(this.formInline).every(value => !value)) { 
    if (this.isSearch) {
      console.log('显示全部数据');
    } else {
      console.log('第一次点击搜索，输入框为空');
    }
  } else {
    await this.fetchData({ ...this.formInline }, false);
  }
        // await this.fetchData({ ...this.formInline }, false)
        let temp = false
        Object.keys(this.formInline).forEach((key) => {
          this.formInline[key] && (temp = true)
        })
        this.isSearch = temp
      },
      openDetail(row) {
        this.$router.push({
          path: '/user/detail',
          query: row,
        })
      },
      handleSizeChange(val) {
        this.queryForm.size = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData({ ...data }, false)
      },
      handleCurrentChange(val) {
        this.queryForm.current = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData({ ...data }, false)
      },
      dateChange(date) {
        if (date && date.length) {
          this.formInline.beginTime = date[0]
          this.formInline.endTime = date[1]
        } else {
          this.formInline.beginTime = ''
          this.formInline.endTime = ''
        }
      },
      handleLook(e) {
        this.DateRow = e
        this.dialogFormVisible = true
      },
      dm(e) {
        this.dialogFormVisible = e
      },
    },
  }
</script>

<style scoped>
  .title-input {
    width: 220px;
    margin-left: 5px;
  }

  .title-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }

  .title-item span {
    width: 100px;
    text-align: center;
  }
</style>
