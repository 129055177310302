import request from '@/utils/request'

export function getPage(query) {
  return request({
      url: '/mall/couponinfo/page',
      method: 'get',
      params: query
  })
}
export function addPage(query) {
  return request({
      url: '/mall/couponinfo',
      method: 'post',
      data: query
  })
}
export function delPage(query) {
  return request({
      url: '/mall/couponinfo/'+query,
      method: 'delete',
  })
}