<template>
  <div class="textTool">
    <div class="toolTit">
      <div class="lef">图文导航</div>
      <div class="ref">
        <div class="switch">
          <div :class="checknum == 1 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(1)">内容</div>
          <div :class="checknum == 2 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(2)">样式</div>
        </div>
      </div>
    </div>
    <div v-if="checknum == 1" class="content_msg">
      <div class="ym_line"></div>
      <div class="ym_title">图文样式</div>
      <div class="itemBox">
        <div class="Tit">图文样式</div>
        <div class="check-tit">
          <el-radio-group v-model="activeComponents[indexnumer].mode" size="mini">
            <el-radio-button label="graphic">图文导航</el-radio-button>
            <el-radio-button label="img">图片导航</el-radio-button>
            <el-radio-button label="text">文字导航</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">显示类型</div>
        <div class="check-tit">
          <el-radio-group v-model="activeComponents[indexnumer].showStyle" size="mini">
            <el-radio-button label="fixed">默认固定</el-radio-button>
            <el-radio-button label="singleSlide">单行滚动</el-radio-button>
            <el-radio-button label="pageSlide">分页滚动</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">显示列数</div>
        <div class="check-tit">
          <el-radio-group v-model="activeComponents[indexnumer].rowCount" size="mini">
            <el-radio-button label="3">3个</el-radio-button>
            <el-radio-button label="4">4个</el-radio-button>
            <el-radio-button label="5">5个</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div v-if="activeComponents[indexnumer].showStyle != 'singleSlide' && activeComponents[indexnumer].showStyle != 'fixed'" class="itemBox">
        <div class="Tit">每页行数</div>
        <div class="check-tit">
          <el-radio-group v-model="activeComponents[indexnumer].pageCount" size="mini">
            <el-radio-button label="1">1行</el-radio-button>
            <el-radio-button label="2">2行</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="ym_line"></div>
      <div class="ym_title">图文导航项</div>
      <div class="alert-msg">建议上传尺寸相同的图片(60px * 60px)</div>
      <draggable v-model="activeComponents[indexnumer].list" animation="300" chosen-class="chosen" ghost-class="ghost" group="itxst" :move="onMove">
        <div v-for="(ym, index) in activeComponents[indexnumer].list" :key="index" class="itemBox" style="height: auto">
          <div class="card_list">
            <div class="ref_check">
              <div class="delclose" @click="deletelist(index)">
                <i class="el-icon-close"></i>
              </div>
              <div class="top_headpic">
                <span>图片</span>
                <div class="hide_pic">
                  <!-- <img alt="" :src="ym.imageUrl" style="width: 100%;height: 100%;" @click="uploadfile"> -->
                  <uploadpic :picurl="[{ url: ym.imageUrl }]" @backkuck="check($event, index)" />
                </div>
                <div class="link_nav" @click="checklist(index, ym.label)">
                  <i class="el-icon-link"></i>
                </div>
              </div>
              <div class="conten_sacnner">
                <span>标题</span>
                <el-input v-model="ym.title" style="max-width: 200px" />
              </div>
              <div class="conten_sacnner" style="justify-content: space-between; margin-top: 5px">
                <span>链接</span>
                <div class="link_navlists" style="display: flex; align-items: center; margin-right: 30px" @click="checklink(index)">
                  <span v-if="ym.link.name != ''" style="color: #1780ff">{{ ym.link.title }}</span>
                  <span v-else>请选择链接</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </draggable>
      <div class="add_list" @click="addlist">
        <i class="el-icon-plus"></i>
        增加一个
      </div>
    </div>
    <div v-if="checknum == 2" class="content_msg">
      <div class="ym_line"></div>
      <div class="ym_title">图文样式</div>
      <div class="itemBox">
        <div class="Tit">整体规范</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-radio-group v-model="activeComponents[indexnumer].ornament.type" size="mini">
              <el-radio label="default">默认</el-radio>
              <el-radio label="shadow">投影</el-radio>
              <el-radio label="circle">描边</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div v-if="activeComponents[indexnumer].ornament.type != 'default'" class="itemBox">
        <div class="Tit">
          <span v-if="activeComponents[indexnumer].ornament.type == 'shadow'">投影</span>
          <span v-else-if="activeComponents[indexnumer].ornament.type == 'circle'">描边</span>
          颜色
        </div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].ornament.color }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].ornament.color" />
            <div class="resect" @click="activeComponents[indexnumer].ornament.color = '#FFFFFF'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="ym_line" style="padding: 2px 0"></div>
      <div class="ym_title">图片设置</div>
      <div class="itemBox">
        <div class="Tit">图片圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].aroundRadius" :max="50" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].aroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">图片大小</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].imageSize" :max="60" :min="30" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].imageSize" :max="60" :min="30" style="margin-left: 20px" />
        </div>
      </div>
      <div class="ym_line" style="padding: 2px 0"></div>
      <div class="ym_title">文字设置</div>
      <div class="itemBox">
        <div class="Tit">文字大小</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].font.size" :max="16" :min="12" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].font.size" :max="16" :min="12" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">文字加粗</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-radio-group v-model="activeComponents[indexnumer].font.weight" size="mini">
            <el-radio-button label="bold">加粗</el-radio-button>
            <el-radio-button label="normal">常规</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">文字颜色</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
            <div class="lef">{{ activeComponents[indexnumer].font.color }}</div>
            <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
              <el-color-picker v-model="activeComponents[indexnumer].font.color" />
              <div class="resect" @click="activeComponents[indexnumer].font.color = '#333333'">重置</div>
              <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="ym_line" style="padding: 2px 0"></div>
      <div class="ym_title">组件样式</div>
      <div class="itemBox">
        <div class="Tit">底部背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].pageBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].pageBgColor" />
            <div class="resect" @click="activeComponents[indexnumer].pageBgColor = ''">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">组件背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].componentBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].componentBgColor" />
            <div class="resect" @click="activeComponents[indexnumer].componentBgColor = ''">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.top" :max="100" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.top" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.bottom" :max="100" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.bottom" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">左右边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.both" :max="20" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.both" :max="20" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].topAroundRadius" :max="50" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].topAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].bottomAroundRadius" :max="50" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].bottomAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
    </div>
    <stydialog :listrow="listcheck" :showstyle="showhide" @closehide="cloeshow" />
    <linkdialog :linkshow="linkurl" @closeurl="closelink" />
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import uploadpic from '../../../upload-pic/upload.vue'
import stydialog from './components/stydialog.vue'
import linkdialog from '../../../link-dialog/index.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    uploadpic,
    draggable,
    stydialog,
    linkdialog,
  },
  props: {
    indexnumer: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      checknum: 1,
      showhide: false,
      listcheck: {},
      checkIndex: null,
      linkurl: false,
      checklinknum: null,
    }
  },
  computed: {
    ...mapGetters['state'],
    activeComponents() {
      return this.$store.state.checkvalue
    },
  },
  methods: {
    checklink(Index) {
      this.checklinknum = Index
      this.linkurl = true
    },
    closelink(lisr) {
      if (lisr != false) {
        this.activeComponents[this.indexnumer].list[this.checklinknum].link = lisr
        this.linkurl = false
      } else {
        this.linkurl = false
      }
    },
    deletelist(Index) {
      this.$confirm('是否删除该数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.activeComponents[this.indexnumer].list.splice(Index, 1)
        })
        .catch(() => {
          this.$message('您已取消删除')
        })
    },
    check(event, Index) {
      this.activeComponents[this.indexnumer].list[Index].imageUrl = event
    },
    changeChecknum(type) {
      this.checknum = type
    },
    onMove(e) {
      console.log(e)
      console.log(e.draggedContext)
      this.$forceUpdate()
      console.log(this.activeComponents[this.indexnumer].list)
    },
    checklist(e, row) {
      this.showhide = true
      this.checkIndex = e
      this.listcheck = row
    },
    cloeshow(e) {
      if (e != false) {
        this.activeComponents[this.indexnumer].list[this.checkIndex].label = e
        this.showhide = false
      } else {
        this.showhide = false
      }
    },
    addlist() {
      let list = {
        title: '',
        imageUrl: 'https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png',
        iconType: 'img',
        style: {
          fontSize: '60',
          iconBgColor: [],
          iconBgColorDeg: 0,
          iconBgImg: '',
          bgRadius: 0,
          iconColor: ['#000000'],
          iconColorDeg: 0,
        },
        link: {
          name: '',
        },
        label: {
          control: false,
          text: '',
          textColor: '#FFFFFF',
          bgColorStart: '#FE8681',
          bgColorEnd: '#FF5754',
        },
        icon: '',
        id: '1v99h14wqhr40',
        imgWidth: '605',
        imgHeight: '605',
      }
      this.activeComponents[this.indexnumer].list.push(list)
    },
  },
}
</script>

<style lang="scss" scoped>
.textTool {
  width: 100%;
  padding: 20px 20px 0 20px;
  .toolTit {
    display: flex;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
    color: #333333;
    .switch {
      display: flex;
      align-items: center;
      overflow: hidden;
      font-size: 14px;
      cursor: pointer;
      background: #f5f5f5;
      border-radius: 50px;
      .swit-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 29px;
        border-radius: 50px;
      }
      .active {
        color: white;
        background: #1780ff;
      }
    }
  }
  .ym_line {
    width: 100%;
    height: 3px;
    background: #f2f4f6;
  }
  .ym_title {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .itemBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 40px;
    margin-left: 20px;
    .Tit {
      margin-right: 15px;
      color: gray;
      text-align: right;
      white-space: nowrap;
    }
    .check-tit {
      display: flex;
      justify-content: right;
      width: calc(100% - 56px);
      color: #105cfb;
      .resect {
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }
  .alert-msg {
    margin-bottom: 15px;
    font-size: 12px;
    color: rgb(210, 210, 210);
    text-align: center;
  }
  ::v-deep(.el-color-picker__trigger) {
    width: 45px;
    height: 26px;
  }
  ::v-deep(.el-icon-arrow-down:before) {
    display: none;
  }
  .add_list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 42px;
    margin: 20px auto;
    color: white;
    cursor: pointer;
    background: #1780ff;
    border: 1px solid transparent;
  }
  .card_list {
    width: 95%;
    min-height: 144px;
    max-height: 188px;
    padding: 10px;
    margin: 10px auto;
    border: 1px dashed #d5d5d5;
    .ref_check {
      position: relative;
      margin-left: 30px;
      .delclose {
        position: absolute;
        top: -23px;
        right: -15px;
        width: 18px;
        height: 18px;
        line-height: 18px;
        color: white;
        text-align: center;
        cursor: pointer;
        background: #424242;
        border-radius: 100%;
      }
      .top_headpic,
      .conten_sacnner {
        display: flex;
        align-items: center;
        margin: 3px 0;
        white-space: nowrap;
        span {
          margin-right: 10px;
        }
        .hide_pic {
          width: 70px;
          height: 70px;
          margin-right: 10px;
          margin-bottom: 10px;
          overflow: hidden;
        }
        .link_nav {
          padding: 3px 10px;
          font-size: 18px;
          cursor: pointer;
          border: 1px solid #e5e5e5;
        }
      }
    }
  }
  ::v-deep {
    .el-input-number {
      width: 80px;
      .el-input__inner {
        padding: 0;
        text-align: center;
      }
    }

    .el-input-number__decrease,
    .el-input-number__increase {
      display: none;
    }
  }
}
</style>
