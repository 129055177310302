<template>
  <div class="link_dialog">
    <el-dialog
      append-to-body
      :before-close="handleClose"
      title="选择链接"
      :visible.sync="dialogVisible"
      width="50%">
      <div class="cont">
        <div class="left_side">
          <el-tree :data="list" :default-expand-all="true" :props="defaultProps" style="width: 200px;" @node-click="handleNodeClick"/>
        </div>
        <div class="rig_cont">
          <div v-for="(ym,index) in childrens" :key="index" class="lis_goods">
            <div class="thead_title">{{ ym.title }}</div>
            <div class="cont_card">
              <div 
                v-for="(yms,num) in ym.child_list"
                :key="num" class="list_cards" 
                :style="
                  rules(index,num)
                "
                @click="checklist(yms,index,num)"
                >
                {{ yms.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {themeDiyCanvasdivSelector} from '@/api/mall/shop/templateset'
export default {
  props:{
    linkshow:{
      type:Boolean,
      default:()=>{}
    }
  },
  data(){
    return{
      dialogVisible:this.linkshow,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      list:[],
      childrens:[],
      checklink:[],
      cur:[],
    }
  },
  computed:{
    rules(){
      return((item,inde)=>{
        if(item==this.cur[0]&&inde==this.cur[1]){
          return `border:  1px solid #1780ff; color: #1780ff`
        }else{
          return ' border:  1px solid #e5e5e5;'
        }
      })
    }
  },
  watch:{
    linkshow(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisible=this.linkshow
        this.goodscategoryfirstList()
      }
    }
  },
  methods:{
    checklist(row,num,index){
      this.checklink=row
      console.log(row)
      this.cur=[num,index]
    },
    handleNodeClick(data) {
      console.log(data);
      this.childrens=data.child_list
    },
    goodscategoryfirstList(){
      themeDiyCanvasdivSelector({}).then(res=>{
        this.data=JSON.parse(res.data)
        this.list=this.data.map(item=>{
          return {
            ...item,
            title: item.title,
            children: item.child_list
          };
        })
        this.list.forEach(ite=>{
          console.log(ite,'')
        })
      })
    },
    handleClose(){
      this.$emit("closeurl",false)
      this.checklink=[]
      this.cur=[]
    },
    save(){
      this.$emit("closeurl",this.checklink)
      this.checklink=[]
      this.cur=[]
    }
  }
}
</script>

<style lang="scss" scoped>
.cont{
  display: flex;
  width: 100%;
  .left_side{
    width: 201px;
    margin-right: 10px;
    border-right: 1px solid #e5e5e5;
  }
  .rig_cont{
    width: 100%;
    .thead_title{
      width: 100%;
      margin-bottom: 10px;
    }
    .cont_card{
      display: flex;
      flex-wrap: wrap;
      .list_cards{
        padding: 5px 8px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        border:  1px solid #e5e5e5;
      }
      .act{
        color: #1780ff;
        border:  1px solid #1780ff;
      }
    }
  }
}
::v-deep{
  .el-dialog__header{
    border-bottom: 1px solid #e5e5e5;
  }
  .el-tree{
    border-right: 1px solid #e5e5e5;
  }
}
</style>