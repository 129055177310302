var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        "before-close": _vm.claer,
        title: "客情部门",
        visible: _vm.appointDialog,
        width: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.appointDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "el-table",
            {
              ref: "myTable",
              attrs: { border: "", data: _vm.tablist, "max-height": "300" },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "序号",
                  type: "index",
                  width: "55",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "订单编号",
                  prop: "orderNo",
                  width: "190",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "下单用户",
                  prop: "nickName",
                  width: "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "订单金额",
                  prop: "paymentPrice",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "下单员工",
                  prop: "profile",
                  "show-overflow-tooltip": "",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(scope.row.realName) +
                            _vm._s(
                              scope.row.profile == ""
                                ? ""
                                : "(" + scope.row.profile + ")"
                            )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "状态",
                  prop: "statusDesc",
                  "show-overflow-tooltip": "",
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "所购产品",
                  prop: "product",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "下单地址",
                  prop: "address",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "质检审核",
                  prop: "quaStateDesc",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            row.quaState == 1
                              ? _c("el-tag", { attrs: { type: "succes" } }, [
                                  _vm._v("待质检"),
                                ])
                              : _vm._e(),
                            row.quaState == 2
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("已通过"),
                                ])
                              : _vm._e(),
                            row.quaState == 3
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("已驳回"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "质检信息",
                  prop: "quaContent",
                  "show-overflow-tooltip": "",
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "预存金额",
                  prop: "presAmount",
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "订单日期",
                  prop: "createTime",
                  width: "200",
                },
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": 1,
              layout: "total, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.clear } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.sure } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }