<template>
  <el-dialog :before-close="handleClose" title="查看体检记录" :visible.sync="dialogVisible" width="50%">
    <span>
      <el-form ref="ruleForm" class="demo-ruleForm" label-width="100px" :model="ruleForm" :rules="rules">
   
        <el-form-item label="体检编号" prop="fitNum">
          <el-input v-model="ruleForm.fitNum" disabled placeholder="请输入 体检编号" />
        </el-form-item>
        <el-form-item label="体检类型" prop="fitType">
          <el-select v-model="ruleForm.fitType" disabled placeholder="请选择 体检类型">
            <el-option v-for="(ym, index) in labType" :key="index" disabled :label="ym.labelName" :value="ym.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="体检机构" prop="name">
          <el-input v-model="ruleForm.name" disabled placeholder="请输入 体检机构" />
        </el-form-item>
        <el-form-item label="体检日期" prop="phyTime">
          <el-date-picker v-model="ruleForm.phyTime" align="center" disabled placeholder="选择日期时间" type="date" value-format="yyyy-MM-dd" />
        </el-form-item>
        <el-form-item label="体检报告单" prop="fitUrl">
          <el-image v-for="(ym, index) in ruleForm.fitUrl" :key="index" :preview-src-list="ruleForm.fitUrl" :src="ym" style="width: 60px; height: 60px" />
        </el-form-item>
      </el-form>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ruleForm: {
        fitUrl: [],
      },
      rules: {
        fitNum: [{ required: true, message: '请输入 体检编号', trigger: 'blur' }],
        phyTime: [{ type: 'string', required: true, message: '请选择 体检日期', trigger: 'change' }],
        fitType: [{ required: true, message: '请选择 体检类型', trigger: 'change' }],
        name: [{ required: true, message: '请输入 体检机构', trigger: 'blur' }],
      },
      dialogVisible: false,
      uphide: false,
      labType: [
        {
          id: 1,
          labelName: '入职体检',
        },
        {
          id: 2,
          labelName: '自行体检',
        },
      ],
    }
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue != oldValue) {
        this.dialogVisible = newValue
        this.uphide = newValue
        this.$forceUpdate()
      }
    },
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.ruleForm = newValue
      }
    },
  },
  created() {},
  methods: {
    handleClose() {
      this.$emit('addshows', false)
      this.$refs.ruleForm.resetFields()
    },
    check(e) {
      if (e.url) {
        this.ruleForm.fitUrl.forEach((item, index) => {
          if (item == e.url) {
            this.ruleForm.fitUrl.splice(index, 1)
          }
        })
      } else {
        this.ruleForm.fitUrl.push(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
  .el-select,
  .el-date-editor,
  .input__inner {
    width: 100%;
  }
}
</style>