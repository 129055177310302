var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "deviceImport-container" },
    [
      _c(
        "el-alert",
        { attrs: { closable: false, effect: "dark", title: "" } },
        [
          _c("p", { staticClass: "tip" }, [
            _vm._v(
              "温情提示：请尽量在服务器空闲时间操作，获取商品会占用大量内存与带宽，在获取过程中，请不要进行任何操作!"
            ),
          ]),
          _c("div", { staticClass: "flex-top tip" }, [
            _c("div", [_vm._v("使用方法：")]),
            _c("div", [
              _c(
                "p",
                { staticStyle: { "margin-top": "0" } },
                [
                  _vm._v(" 1.将您获取到的文件转存为Excel格式 "),
                  _c(
                    "el-link",
                    {
                      attrs: { type: "primary", underline: true },
                      on: { click: _vm.downloadTemplate },
                    },
                    [_vm._v("Excel示例文件下载")]
                  ),
                ],
                1
              ),
              _c("p", [_vm._v("2.立即上传")]),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        [
          _c(
            "el-form",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "设备类型：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择设备类型" },
                              on: { focus: _vm.selemodeType },
                              model: {
                                value: _vm.form.modeltype,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "modeltype", $$v)
                                },
                                expression: "form.modeltype",
                              },
                            },
                            _vm._l(_vm.modeltype, function (ym, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: ym.modelName, value: ym.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "导入Excel文件：" } },
                        [
                          _c(
                            "div",
                            { staticClass: "uploadDialog" },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    accept: ".xlsx, .xls",
                                    "auto-upload": false,
                                    "before-upload": _vm.beforeUploadFile,
                                    drag: "",
                                    "file-list": _vm.batchFileList,
                                    headers: _vm.headers,
                                    limit: 1,
                                    "on-change": _vm.fileChange,
                                    "on-error": _vm.handleError,
                                    "on-exceed": _vm.exceedFile,
                                    "on-remove": _vm.batchRemove,
                                    "on-success": _vm.handleSuccess,
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-upload" }),
                                  _c(
                                    "div",
                                    { staticClass: "el-upload__text" },
                                    [_c("em", [_vm._v("点击上传")])]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [_vm._v("只能上传xlsx文件，且不超过10M")]
                                  ),
                                ]
                              ),
                              _c("br"),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: { click: _vm.uploadFile },
                                },
                                [_vm._v("立即上传")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }