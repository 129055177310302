<template>
  <div class="configa">
    <div class="theader_title">
      <div class="txt">基础信息</div>
    </div>
    <el-form :inline="true" label-width="100px" :model="savform">
      <el-form-item label="交流信息：" :rules="[{ required: true, message: '请输入交流信息', trigger: 'blur' }]">
        <el-select v-model="savform.interId" placeholder="请选择交流信息" @focus="queryList(1)">
          <el-option v-for="item in optionsinter" :key="item.id" :label="item.labelName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="交流内容：" :rules="[{ required: true, message: '请输入交流内容', trigger: 'blur' }]">
        <el-input v-model="savform.interContent" placeholder="请输入交流内容" />
      </el-form-item>
      <el-form-item label="渠道来源：">
        <el-select v-model="savform.channelId" :disabled="true" placeholder="请选择渠道来源" @change="sel()" @focus="queryList(2)">
          <el-option v-for="item in optionschannelId" :key="item.id" :label="item.labelName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="咨询产品：">
        <el-select v-model="savform.goodId" placeholder="请选择咨询产品" @change="sel" @focus="queryList(6)">
          <el-option v-for="item in optionsgoodId" :key="item.id" :label="item.labelName" :value="item.id" />
        </el-select>
      </el-form-item>
    </el-form>
    <div class="theader_title fix">
      <div class="txt">客户信息</div>
    </div>
    <el-form :inline="true" label-width="100px" :model="savform">
      <el-form-item label="客户姓名：" :rules="[{ required: true, message: '请输入客户姓名', trigger: 'blur' }]">
        <el-input v-model="savform.userName" placeholder="请输入客户姓名" />
      </el-form-item>
      <el-form-item label="客户性别：">
        <el-select v-model="savform.userSex" placeholder="请选择客户性别">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="客户职业：">
        <el-input v-model="savform.clientOccupa" placeholder="请输入客户职业" />
      </el-form-item>
      <el-form-item label="客户类型：">
        <el-select v-model="savform.clientId" placeholder="请选择客户类型" @focus="queryList(5)">
          <el-option v-for="item in optionsclientId" :key="item.id" :label="item.labelName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="区域选择：">
        <el-cascader
          v-model.trim="provinces"
          class="full-width"
          :options="areaSelectData"
          placeholder="请选择地址"
          :props="{
            children: 'children',
            label: 'areaName',
            value: 'areaName',
          }"
          size="large"
          style="display: flex; align-items: center; justify-content: center; width: 215px; height: 32px"
          @change="addres"
        />
      </el-form-item>
      <el-form-item label="详细地址：">
        <el-input v-model="savform.addressDetail" placeholder="请输入详细地址" />
      </el-form-item>
      <el-form-item label="客户电话：" :rules="[{ required: true, message: '请输入客户电话', trigger: 'blur' }]">
        <el-input v-model="savform.phone" placeholder="请输入详细电话">
          <template slot="append">
            <div class="cal" @click="callphone(savform.phone)">拨打</div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="客户电话2：">
        <el-input v-model="savform.phonev" placeholder="请输入详细电话">
          <template slot="append">
            <div class="cal" @click="callphone(savform.phonev)">拨打</div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="客户备注1：">
        <el-input v-model="savform.remark" placeholder="请输入客户备注" />
      </el-form-item>
      <el-form-item label="客户备注2：">
        <el-input v-model="savform.remarkContent" placeholder="请输入客户备注" />
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="submit">全局保存</el-button>
        <el-button type="" @click="amoutshow = true">预存金额</el-button>
        <el-button type="" @click="aftershow = true">客情处理</el-button>
        <el-button type="" @click="Return = true">回访设置</el-button>
        <el-button type="" @click="ToUser">会员信息</el-button>
      </el-form-item>
    </el-form>
    <amoutdialog :amoutswitch="amoutshow" :savforms="savform" @closeswitch="closedia" />
    <Aftersaletreatment :hanuser="savform" :showtab="aftershow" :usmglist="usmgdate" @closeswitch="aftersaletrclose" />
    <ReturnVisit :hanuser="savform" :showtab="Return" :usmglist="usmgdate" @closedemo="alclose" />
  </div>
</template>

<script>
import { queryList } from '@/api/mall/health/casemy/index'
import { getAreaThree } from '@/api/mall/common'
import { addObj } from '@/api/mall/health/casemy/index'
import amoutdialog from './Amount-deposited/dialog.vue'
import Aftersaletreatment from './After-sale-treatment/index.vue'
import ReturnVisit from './Return-visit/index.vue'
export default {
  components: {
    amoutdialog,
    Aftersaletreatment,
    ReturnVisit,
  },
  props: {
    dlrow: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      aftershow: false, //客情
      amoutshow: false, //预存金额
      Return: false,
      areaSelectData: [],
      savform: {},
      dlr: null,
      optionsinter: null,
      optionschannelId: null,
      optionsclientId: null,
      provinces: null,
      optionsgoodId: null,
      usmgdate: this.usmginfo,
      options: [
        {
          label: '男',
          value: '1',
        },
        {
          label: '女',
          value: '0',
        },
      ],
    }
  },
  watch: {
    dlrow(newValue, oldValue) {
      //地址赋值
      const citys = newValue.city ? newValue.city : '',
        countys = newValue.county ? newValue.county : '',
        provinces = newValue.province ? newValue.province : ''
      const adr = [provinces, citys, countys]
      this.lists = { provinces: adr }
      this.provinces = adr
      //===如果是0 默认为空值选择
      if (newValue != oldValue) {
        this.savform = newValue
        if (newValue.channelId == 0) {
          this.savform.channelId = ''
        }
        if (newValue.goodId == 0) {
          this.savform.goodId = ''
        }
        if (newValue.clientId == 0) {
          this.savform.clientId = ''
        }
        this.savform.interId = ''
        // this.savform.channelId = ''
        // this.savform.goodId = ''
        // this.savform.clientId = ''
      }

      this.getlabelTypeList()
    },
    usmginfo(newValue, oldValue) {
      if (newValue != oldValue) {
        this.usmgdate = newValue
      }
    },
  },
  created() {
    this.savform = this.dlrow
    this.queryList('1')
    this.getAreaThree()
  },
  methods: {
    ToUser() {
      // const url = this.$router.resolve({
      //   path: '/user/details',
      //   query: { userinfo: this.savform.userId },
      // })
      // window.open(url.href, '_blank')
      if (this.savform.userId) {
        const url = this.$router.resolve({
          path: '/user/details',
          query: { userinfo: this.savform.userId },
        })
        window.open(url.href, '_blank')
      } else {
        this.$message.error('该用户还未注册')
      }
    },
    alclose(r) {
      this.Return = r
    },
    sel(e) {
      console.log(e, '---------------4')
    },
    aftersaletrclose() {
      this.aftershow = false
    },
    closedia() {
      this.amoutshow = false
    },
    callphone(e) {
      // eslint-disable-next-line
      this.$EventBus.$emit('eventName', e)
    },
    addres(e) {
      if (e) {
        this.savform.province = e[0]
        this.savform.city = e[1]
        this.savform.county = e[2]
      }
      console.log(this.savform)
    },
    async getAreaThree() {
      await getAreaThree()
        .then((res) => {
          if (res.data) {
            this.areaSelectData = res.data
          }
        })
        .catch(() => {})
    },
    queryList(e) {
      queryList({ labelType: e }).then((res) => {
        if (e == 1) {
          this.optionsinter = res.data
        }
        if (e == 2) {
          this.optionschannelId = res.data
        }
        if (e == 5) {
          this.optionsclientId = res.data
        }
        if (e == 6) {
          this.optionsgoodId = res.data
        }
      })
    },
    submit() {
      const regex = /^(?:[\u4e00-\u9fa5·]{2,16})$/
      const phone = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (this.savform.phone == '') {
        this.$message.warning('请输入客户电话')
      } else if (!regex.test(this.savform.userName)) {
        this.$message.warning('请输入正确的姓名')
      } else if (this.savform.phone != '' && !phone.test(this.savform.phone)) {
        this.$message.warning('请输入正确的客户电话')
      } else if (this.savform.phonev != '' && !phone.test(this.savform.phonev)) {
        this.$message.warning('请输入正确的客户电话')
      } else {
        addObj({ ...this.savform }).then((res) => {
          if (res.status == 200 || res.code == 1) {
            this.$message.success('提交成功')
            this.$emit('closelist', false)
            this.$baseEventBus.$emit('Qualityinspection')
            this.savform.interId = ''
            this.savform.interContent = ''
            this.$EventBus.$emit('reploads')
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },

    //查询渠道来源 咨询产品 客户类型-aj
    getlabelTypeList() {
      queryList({ labelType: 2 }).then((res) => {
        this.optionschannelId = res.data
      })
      queryList({ labelType: 5 }).then((res) => {
        this.optionsclientId = res.data
      })
      queryList({ labelType: 6 }).then((res) => {
        this.optionsgoodId = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.theader_title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
  background: rgb(237, 237, 237);
  .txt {
    margin-left: 10px;
    text-indent: 1em;
    border-left: 5px solid #3782ff;
  }
}
.fix:nth-child(2) {
  margin-top: -5px;
}
.configa {
  ::v-deep {
    .el-input--small {
      width: 215px;
      border: 1px solid #e2e2e2;
    }
    .el-input-group__append {
      border: none;
    }
  }
}
.full-width {
  ::v-deep {
    .el-input__inner {
      height: 32px;
      border: 1px solid #e5e5e5;
    }
  }
}
</style>