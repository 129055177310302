var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "addBtn",
          attrs: { icon: "el-icon-plus", type: "primary" },
          on: { click: _vm.handleAdd },
        },
        [_vm._v(" 新增 ")]
      ),
      _c(
        "el-table",
        { attrs: { border: "", data: _vm.data } },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "店铺名",
              prop: "name",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "电话号码",
              prop: "phone",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "详细地址",
              prop: "address",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "店长昵称",
              prop: "userNickname",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "店长手机号",
              prop: "userPhone",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "更新时间",
              prop: "updateTime",
              "show-overflow-tooltip": "",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "审核状态",
              prop: "status",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            row.status == 0
                              ? "warning"
                              : row.status == 2
                              ? "danger"
                              : "success",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.status == 0
                                ? "审核中"
                                : row.status == 2
                                ? "审核不通过"
                                : "通过"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 5px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.showTabs(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 5px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.edittab(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 5px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.delobj(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("applydialog", {
        attrs: { applerow: _vm.applist, dbshow: _vm.showbloa },
        on: { resolve: _vm.rejoct },
      }),
      _c("applyeditdialog", {
        attrs: { editrow: _vm.editabs, edshow: _vm.edihide },
        on: { closeedit: _vm.closedit },
      }),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": 1,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }