<template>
  <div class="textTool">
    <div class="toolTit">
      <div class="lef">文章中枢</div>
      <div class="ref">
        <div class="switch">
          <div :class="checknum == 1 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(1)">内容</div>
          <div :class="checknum == 2 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(2)">样式</div>
        </div>
      </div>
    </div>
    <div v-if="checknum == 1" class="content_msg">
      <div class="ym_line"></div>
      <div class="itemBox" style="padding: 30px 0">
        <div class="Tit">风格</div>
        <div class="check-tit">
          <el-radio-group v-model="activeComponents[indexnumer].articletype" size="mini">
            <el-radio :label="1">样式1</el-radio>
            <el-radio :label="2">样式2</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">文章渠道</div>
        <div class="check-tit" style="display: flex; width: 260px" @click="linkurl = true">
          <span v-if="activeComponents[indexnumer].articlesecords.name != ''" style="color: #1780ff">{{ activeComponents[indexnumer].articlesecords.title }}</span>
          <span v-else>请选择链接</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">文章数量</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].count" :max="30" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].count" :max="30" :min="0" style="margin-left: 20px" />
        </div>
      </div>
    </div>
    <div v-if="checknum == 2" class="content_msg">
      <div class="ym_line"></div>
      <div class="itemBox" style="padding: 30px 0">
        <div class="Tit">底部背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].pageBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].pageBgColor" />
            <div class="resect" @click="activeComponents[indexnumer].pageBgColor = ''">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="alert-msg">底部背景包含边距和圆角</div>
      <div class="itemBox">
        <div class="Tit">组件背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].componentBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].componentBgColor" />
            <div class="resect" @click="activeComponents[indexnumer].componentBgColor = ''">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">文章背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].elementBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].elementBgColor" />
            <div class="resect" @click="activeComponents[indexnumer].elementBgColor = ''">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.top" :max="100" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.top" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.bottom" :max="100" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.bottom" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">左右边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.both" :max="20" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.both" :max="20" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].topElementAroundRadius" :max="50" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].topElementAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].bottomElementAroundRadius" :max="50" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].bottomElementAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
    </div>
    <goodsidialog :linkshow="linkurl" @closeurl="closelink" />
  </div>
</template>
<script>
import goodsidialog from '../../../goodsdialog/goodsIddialog.vue'
// import uploadpic from '../../../upload-pic/index.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    // uploadpic
    goodsidialog,
  },
  props: {
    indexnumer: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      checknum: 1,
      linkurl: false,
    }
  },
  computed: {
    ...mapGetters['state'],
    activeComponents() {
      return this.$store.state.checkvalue
    },
    imageurl() {
      let imgUrl = this.activeComponents[this.indexnumer].imageUrl
      if (imgUrl) {
        return imgUrl
      } else {
        return ''
      }
    },
  },
  methods: {
    closelink(row) {
      if (row != false) {
        this.activeComponents[this.indexnumer].articlesecords = row
        this.linkurl = false
      } else {
        this.linkurl = false
      }
    },
    check(e) {
      this.activeComponents[this.indexnumer].imageUrl = e
    },
    changeChecknum(type) {
      this.checknum = type
    },
  },
}
</script>

<style lang="scss" scoped>
.textTool {
  width: 100%;
  padding: 20px 20px 0 20px;
  .toolTit {
    display: flex;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
    color: #333333;
    .switch {
      display: flex;
      align-items: center;
      overflow: hidden;
      font-size: 14px;
      cursor: pointer;
      background: #f5f5f5;
      border-radius: 50px;
      .swit-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 29px;
        border-radius: 50px;
      }
      .active {
        color: white;
        background: #1780ff;
      }
    }
  }
  .ym_line {
    width: 100%;
    height: 3px;
    background: #f2f4f6;
  }
  .ym_title {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .itemBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 40px;
    margin-left: 20px;
    .Tit {
      margin-right: 15px;
      color: gray;
      text-align: right;
      white-space: nowrap;
    }
    .check-tit {
      display: flex;
      justify-content: right;
      width: calc(100% - 56px);
      color: #105cfb;
      .resect {
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }
  .alert-msg {
    margin-bottom: 15px;
    font-size: 12px;
    color: rgb(210, 210, 210);
    text-align: center;
  }
  ::v-deep(.el-color-picker__trigger) {
    width: 45px;
    height: 26px;
  }
  ::v-deep(.el-icon-arrow-down:before) {
    display: none;
  }
  ::v-deep {
    .el-input-number {
      width: 80px;
      .el-input__inner {
        padding: 0;
        text-align: center;
      }
    }

    .el-input-number__decrease,
    .el-input-number__increase {
      display: none;
    }
  }
}
</style>
