var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "div",
        { staticClass: "search", staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.formInline },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "坐席工号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入坐席工号" },
                    model: {
                      value: _vm.formInline.profile,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "profile",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.profile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-search", type: "primary" },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", type: "warning" },
                      on: { click: _vm.reset },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.addClick },
            },
            [_vm._v("新增")]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableData },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "坐席id",
                  prop: "id",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "坐席工号", prop: "profile" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "创建时间",
                  prop: "createTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "更新时间",
                  prop: "updateTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "right",
                  label: "操作",
                  prop: "rateValue",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: scope.row.status === 0 ? true : false,
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.recoveryClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("回收")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: scope.row.status === 0 ? false : true,
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.distributionClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("分配")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": 1,
          layout: "total, sizes, prev, pager, next, jumper",
          "page-size": _vm.formInline.size,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: { data: _vm.gridData, height: "500" },
              on: { "row-click": _vm.singleElection },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "请选择", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            staticClass: "radio",
                            attrs: { label: scope.row },
                            model: {
                              value: _vm.templateSelection,
                              callback: function ($$v) {
                                _vm.templateSelection = $$v
                              },
                              expression: "templateSelection",
                            },
                          },
                          [_vm._v(_vm._s(_vm.none))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "姓名", prop: "realName" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "手机号", prop: "mobile" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "工号", prop: "profile" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "账号", prop: "username" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "角色", prop: "roleIdCn" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "所属部门", prop: "orgIdCn" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "创建时间",
                  prop: "createTime",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: _vm.addType ? "新增" : "编辑",
            visible: _vm.AddVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.AddVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                "label-width": "100px",
                model: _vm.ruleForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "坐席工号：", prop: "profile" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.profile,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "profile", $$v)
                      },
                      expression: "ruleForm.profile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }