<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="全部订单" name="-1" />
    <el-tab-pane name="0">
      <span slot="label">
        <div class="tit">
          待付款
          <div class="storing">({{ dtfrom.obligationNumber }})</div>
        </div>
      </span>
    </el-tab-pane>
    <el-tab-pane name="1">
      <span slot="label">
        <div class="tit">
          待发货
          <div class="storing">({{ dtfrom.deliveryOrderNumber }})</div>
        </div>
      </span>
    </el-tab-pane>
    <el-tab-pane label="待收货" name="2">
      <span slot="label">
        <div class="tit">
          待收货
          <div class="storing">({{ dtfrom.takeOrderNumber }})</div>
        </div>
      </span>
    </el-tab-pane>
    <el-tab-pane label="已完成" name="3" />
    <el-tab-pane name="4">
      <span slot="label">
        <div class="tit">
          待评价
          <div class="storing">({{ dtfrom.sucessOrderNumber }})</div>
        </div>
      </span>
    </el-tab-pane>
    <el-tab-pane label="已取消" name="7" />
  </el-tabs>
</template>

<script>
import { statisticsOrderCount } from '@/api/mall/order/order'
export default {
  data() {
    return {
      activeName: '1',
      dtfrom: {},
    }
  },
  created() {
    this.statisticsOrderCount()
  },
  methods: {
    statisticsOrderCount() {
      statisticsOrderCount({}).then((res) => {
        this.dtfrom = res.data
      })
    },
    handleClick(tab) {
      this.$emit('tannumerb', tab.name)
    },
  },
}
</script>

<style lang="scss" scoped>
.tit {
  display: flex;
  align-items: center;
  font-size: 15px;
  .storing {
    margin-top: -2px;
    margin-left: 3px;
    font-weight: bold;
    color: red;
  }
}
::v-deep .el-tabs__item {
  font-size: 15px;
}
</style>