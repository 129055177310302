/**
 * @description 导入所有 vuex 模块，自动加入namespaced:true，用于解决vuex命名冲突，请勿修改。
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const modules = {}
const files = require.context('./modules', false, /\.js$/)
files.keys().forEach((key) => {
  modules[key.replace(/(modules|\/|\.|js)/g, '')] = {
    ...files(key).default,
    namespaced: true,
  }
})
const state = {
  orgId: '',
  userId: '',
  diyselect: {},
  diytype: 0, //0代表小程序1代表H5 ---2代表App
  // 选中循环index
  checknumber: -1,
  // diy已选中
  checkdiy: {
    thead: {
      //默认模板标头a
    },
  },
  checkvalue: [],
  //当前选中子组件
  checkcanvas: [],
  checklist: {},
  editcanvas: 1, //diy编辑权限or新增 :0新增1:编辑
  editcontent: {}, //diy编辑暂存
  preview: {}, //预览diy
  goodssku: {}, //sku
}
const mutations = {
  chengeOrgId(state, val) {
    state.orgId = val
  },
  chengeUserId(state, val) {
    state.userId = val
  },
  addarray(state, val) {
    state.checkvalue.push(val)
  },
  checklist(state, val) {
    state.checklist = Object.assign({}, val)
  },
  upsetThead(state, val) {
    state.diytype = val
  },
  setthead(state, val) {
    state.checkdiy.thead = Object.assign({}, val)
  },
  setGoods(state, val) {
    state.goodssku = Object.assign({}, val)
  },
  UpSdeat(state, val) {
    state.description = val
  },
  question(state, val) {
    state.goodssku.description = val
  },
  adinfo(state, val) {
    state.goodssku.sku = val
  },
  setmuc(state, val) {
    console.log(state, val, 'chuaner===1=1==1')
    state.checkvalue[val.Index].list = val.row
    console.log(state.checkvalue[val.Index].list, '909')
    // state.goodssku.sku = val
  },
}

// const getters = {
//   token: (state) => state.token,
// }
// const actions = {
//   setToken({ commit }, token) {
//     return new Promise((resolve) => {
//       commit('setToken', token)
//       resolve()
//     })
//   },
// }

const store = new Vuex.Store({
  modules,
  state,
  mutations,
  // getters,
  // actions,
})
export default store
