var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "custom-table",
      staticClass: "custom-table-container",
      staticStyle: { "min-height": "100vh", padding: "0", overflow: "hidden" },
    },
    [
      _c("div", { staticClass: "top_thead" }, [_c("Topthead")], 1),
      _c("div", { staticClass: "content_main" }, [_c("contentmain")], 1),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }