<template>
  <div class="OrderDetail cy-container">
    <el-row style="display: flex; align-items: center; padding: 20px; background: #f0f0f0">
      <el-col class="fs16">订单详情</el-col>
      <!-- <el-button type="primary" @click="handelOrderLogo">订单日志</el-button> -->
    </el-row>
    <!-- 订单详情 -->
    <div class="detaileMag">
      <el-row class="ym_row">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-descriptions :column="1" title="订单信息">
              <el-descriptions-item label="订单编号">
                {{ orderDetails.orderNo }}
              </el-descriptions-item>
              <el-descriptions-item label="订单类型">
                {{ orderDetails.orderType }}
              </el-descriptions-item>
              <!-- <el-descriptions-item label="订单来源">
                {{ orderDetails.orderSummary ? orderDetails.orderSummary.tradeType : '' }}
              </el-descriptions-item> -->
              <el-descriptions-item label="支付方式">
                <span v-if="orderDetails.paymentWay == 1">微信支付</span>
                <span v-if="orderDetails.paymentWay == 2">支付宝支付</span>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-descriptions :column="2" direction="horizontal" title="付款信息">
              <el-descriptions-item label="订单金额">
                <span v-if="orderDetails" style="color: red">
                  {{ orderDetails.salesPrice | numFormat }}
                </span>
              </el-descriptions-item>
              <el-descriptions-item label="支付金额">
                <span v-if="orderDetails" style="color: red">
                  {{ orderDetails.paymentPrice | numFormat }}
                </span>
              </el-descriptions-item>
              <el-descriptions-item label="支付积分">
                <span v-if="orderDetails">
                  {{ orderDetails.paymentPoints | numFormat }}
                </span>
              </el-descriptions-item>
              <el-descriptions-item label="运费金额">
                <span v-if="orderDetails">
                  {{ orderDetails.freightPrice | numFormat }}
                </span>
              </el-descriptions-item>
              <el-descriptions-item label="优惠券抵扣金额">
                <span v-if="orderDetails">
                  {{ orderDetails.paymentCouponPrice | numFormat }}
                </span>
              </el-descriptions-item>
              <el-descriptions-item label="积分抵扣金额">
                <span v-if="orderDetails">
                  {{ orderDetails.paymentPointsPrice | numFormat }}
                </span>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-descriptions :column="1" title="收货人信息">
              <el-descriptions-item label="收货人">
                {{ orderDetails.orderLogistics ? orderDetails.orderLogistics.userName : '' }}
              </el-descriptions-item>
              <el-descriptions-item label="联系电话">
                {{ orderDetails.orderLogistics ? orderDetails.orderLogistics.telNum : '' }}
              </el-descriptions-item>
              <el-descriptions-item label="收货地址">
                <div v-if="orderDetails.orderLogistics">
                  <span>{{ orderDetails.orderLogistics.province }}</span>
                  <span>{{ orderDetails.orderLogistics.city }}</span>
                  <span>{{ orderDetails.orderLogistics.county }}</span>
                  <span>
                    {{ orderDetails.orderLogistics.address }}
                  </span>
                </div>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-descriptions :column="1" title="买家信息">
              <el-descriptions-item label="买家名称">
                {{ orderDetails.userInfo.nickName || '' }}
              </el-descriptions-item>
              <el-descriptions-item label="买家手机号">
                {{ orderDetails.userInfo.phone }}
              </el-descriptions-item>
              <el-descriptions-item class="buyMas" label="买家留言">
                {{ orderDetails.userRemark }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 配送信息 -->
    <el-descriptions class="mt10" title="配送信息">
      <el-descriptions-item label="配送方式">
        {{ deliveryWayDesc }}
      </el-descriptions-item>
    </el-descriptions>
    <!-- 订单状态 -->
    <el-descriptions class="mt10" :colon="false" :column="1" title="订单状态">
      <el-descriptions-item label="订单状态:">
        <div>
          <div style="display: flex">
            <span class="ml20 colorRed">{{ orderDetails.status }}</span>
            <span v-if="orderStateCheck == 0" class="ml20 colorRed">
              剩余付款时间:
              <count-down color="#ff0000" font-size="14px" separator-color="#ff0000" :timestamp="parseInt(endTime - serverTime) / 1000" @end="timeEnd" />
            </span>
          </div>
        </div>
      </el-descriptions-item>
      <!-- 待发货 btn-->
      <el-descriptions-item v-if="orderStateCheck == 1" class="adjustPrice">
        <el-button
          v-for="item in pageButton"
          :key="item.id"
          :plain="item.cssType && item.cssType == 'plain' ? true : false"
          size="mini"
          :type="item.buttonCss"
          @click.native="handleClickBtn(item)"
        >
          <span v-if="!item.buttonIcon"></span>
          <vab-icon v-else :icon="item.buttonIcon" />
          {{ item.buttonName }}
        </el-button>
      </el-descriptions-item>
      <!-- 已取消 原因-->
      <el-descriptions-item v-if="orderStateCheck == 5" label="取消原因:">
        <span v-if="orderDetails.orderSummary" class="ml20">
          {{ orderDetails.orderSummary.cancelRemark }}
        </span>
      </el-descriptions-item>
      <!-- 客情中  客情类型-->
      <el-descriptions-item v-if="orderStateCheck == -1" label="客情类型:">
        <span class="ml20">退货退款</span>
        <el-button plain type="primary" @click="handelAfterSales">查看详情</el-button>
      </el-descriptions-item>
      <!-- 待付款 -->
      <el-descriptions-item v-if="orderStateCheck == 10" class="adjustPrice" />
    </el-descriptions>
    <!-- 商品信息 -->
    <div>
      <el-descriptions class="mt10 goodsmsgs" :colon="false" title="商品信息">
        <el-descriptions-item class="adjustPrice" style="width: 100%">
          <el-table ref="tables" border :data="goodsMsgTable" show-summary :summary-method="getSummaries">
            <el-table-column v-if="comOrderType != 70" key="1" label="子订单号" prop="orderId" width="200px" />
            <el-table-column label="商品名称" prop="spuName" show-overflow-tooltip width="200px" />
            <!-- <el-table-column key="2" label="商品名称" prop="picUrl" /> -->
            <el-table-column label="商品图片">
              <!--   @click="handleImgClick(row.picUrl)" -->
              <template #default="{ row }">
                <el-image alt="" fit="fill" :preview-src-list="[row.picUrl]" :src="row.picUrl" srcset="" />
              </template>
            </el-table-column>
            <el-table-column key="3" :formatter="isRealType" label="商品类型" prop="isReal" />
            <el-table-column key="4" label="商品售价" prop="salesPrice">
              <template #default="{ row }">
                <div>{{ row.salesPrice | numFormat }}</div>
              </template>
            </el-table-column>

            <el-table-column v-if="comOrderType != 70" key="6" label="折扣" prop="discountRatio">
              <template #default="{ row }">
                <div>{{ row.discountRatio }}%</div>
              </template>
            </el-table-column>
            <el-table-column key="7" label="付款金额">
              <template #default="{ row }">
                <div>
                  {{ row.paymentPrice | numFormat }}
                </div>
              </template>
            </el-table-column>
            <el-table-column key="8" label="数量" prop="quantity" />
            <el-table-column key="9" label="商品规格" prop="specInfo" />
            <el-table-column v-if="comOrderType != 70" key="10" label="金豆抵扣" prop="goldenBeanPayment" />
            <el-table-column v-if="comOrderType == 70" key="11" label="小计（元）">
              <template #default="{ row }">
                <div>
                  {{ (row.balancePayment * row.goodsNumber) | numFormat }}
                </div>
              </template>
            </el-table-column>
            <el-table-column v-else key="12" label="小计（元）" prop="balancePayment">
              <template #default="{ row }">
                <div>
                  {{ (row.balancePayment + row.paidPrice) | numFormat }}
                </div>
              </template>
            </el-table-column>
            <el-table-column key="13" label="发货店铺">
              <template #default="{}">
                <div>{{ orderDetails.shopId }}</div>
              </template>
            </el-table-column>
            <el-table-column key="14" label="物流单号" prop="deliveryCode" />
            <el-table-column key="15" :formatter="formatterOrder" label="状态" prop="status" />
            <el-table-column align="center" label="操作">
              <template #default="{ row }">
                <template v-if="row.deliveryCode">
                  <el-button
                    v-for="item in ListButton"
                    :key="item.id"
                    :plain="item.cssType && item.cssType == 'plain' ? true : false"
                    size="mini"
                    :type="item.buttonCss"
                    @click.native="handleClickBtn(item, row)"
                  >
                    <span v-if="!item.buttonIcon"></span>
                    <vab-icon v-else :icon="item.buttonIcon" />
                    {{ item.buttonName }}
                  </el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </el-descriptions-item>
      </el-descriptions>
      <div class="orderMoney">
        订单共{{ orderDetails.goodsNumber }}件商品
        <span v-if="orderDetails.orderSummary">
          ，共计
          <i class="colorRed">
            {{ (orderDetails.balancePayment + orderDetails.paidAmount) | numFormat }}
          </i>
        </span>
      </div>
    </div>
    <!-- 分润信息 -->
    <div v-if="comOrderType == 10 || comOrderType == 20 || comOrderType == 21 || comOrderType == 22">
      <el-descriptions class="mt10" title="分润信息" />
      <div class="ml20 mb10">
        <span>累计分润</span>
        <span v-if="orderDetails.profitSurveyVo" class="colorRed">
          {{ orderDetails.profitSurveyVo.totalProfit | numFormat }}
        </span>
      </div>
      <div class="purpleMsg fs12 ml20 mt20">
        <el-row :gutter="24">
          <el-col :span="5">
            <div v-if="orderDetails.profitSurveyVo && orderDetails.profitSurveyVo.txGroup != null">
              <div class="purpleMsgTitel">销售分销</div>
              <div v-for="(item, index) in orderDetails.profitSurveyVo.txGroup" :key="index">
                <div class="purList">
                  <span class="purpleMsgName">用户名称:</span>
                  <span v-if="orderDetails.profitSurveyVo && orderDetails.profitSurveyVo.txGroup != null">
                    {{ item.name }}
                  </span>
                </div>
                <div class="purList">
                  <span class="purpleMsgName">分润金额:</span>
                  <span v-if="orderDetails.profitSurveyVo && orderDetails.profitSurveyVo.txGroup != null">
                    {{ item.profit | numFormat }}
                  </span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="purpleMsgTitel">管道分润</div>
              <div class="purList">
                <span class="purpleMsgName">区代用户名称:</span>
                <span v-if="orderDetails.profitSurveyVo && orderDetails.profitSurveyVo.serProfit != null">
                  {{ orderDetails.profitSurveyVo.serProfit[0].name }}
                </span>
              </div>
              <div class="purList">
                <span class="purpleMsgName">区代分润金额:</span>
                <span v-if="orderDetails.profitSurveyVo && orderDetails.profitSurveyVo.serProfit != null">
                  {{ orderDetails.profitSurveyVo.serProfit[0].profit | numFormat }}
                </span>
              </div>
              <div class="purList">
                <span class="purpleMsgName">省代用户名称:</span>
                <span v-if="orderDetails.profitSurveyVo && orderDetails.profitSurveyVo.opeProfit != null">
                  {{ orderDetails.profitSurveyVo.opeProfit[0].name }}
                </span>
              </div>
              <div class="purList">
                <span class="purpleMsgName">省代分润金额:</span>
                <span v-if="orderDetails.profitSurveyVo && orderDetails.profitSurveyVo.opeProfit != null">
                  {{ orderDetails.profitSurveyVo.opeProfit[0].profit | numFormat }}
                </span>
              </div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple">
              <div class="purpleMsgTitel">门店</div>
              <div class="purList">
                <span class="purpleMsgName">门店名称:</span>
                <span v-if="orderDetails.profitSurveyVo && orderDetails.profitSurveyVo.shopProfit">
                  {{ orderDetails.profitSurveyVo.shopProfit[0].name }}
                </span>
              </div>
              <div class="purList">
                <span class="purpleMsgName">门店货款:</span>
                <span v-if="orderDetails.profitSurveyVo && orderDetails.profitSurveyVo.shopProfit">
                  {{ orderDetails.profitSurveyVo.shopProfit[0].goodsCost | numFormat }}
                </span>
              </div>
              <div class="purList">
                <span class="purpleMsgName">门店收益:</span>
                <span v-if="orderDetails.profitSurveyVo && orderDetails.profitSurveyVo.shopProfit">
                  {{ orderDetails.profitSurveyVo.shopProfit[0].profit | numFormat }}
                </span>
              </div>
            </div>
          </el-col>
          <el-col :span="5">
            <div v-if="orderDetails.profitSurveyVo && orderDetails.profitSurveyVo.txPlatform" class="grid-content bg-purple">
              <div class="purpleMsgTitel">平台</div>
              <div class="purList">
                <span class="purpleMsgName">{{ orderDetails.profitSurveyVo.txPlatform[0].name }}:</span>
                <span>
                  {{ orderDetails.profitSurveyVo.txPlatform[0].profit | numFormat }}
                </span>
              </div>
            </div>
          </el-col>
          <el-col :span="5">
            <div v-if="orderDetails.profitSurveyVo && orderDetails.profitSurveyVo.originTxProfit" class="grid-content bg-purple">
              <div class="purpleMsgTitel">发起人</div>
              <div class="purList">
                <span class="purpleMsgName">{{ orderDetails.profitSurveyVo.originTxProfit[0].name }}:</span>
                <span>
                  {{ orderDetails.profitSurveyVo.originTxProfit[0].profit | numFormat }}
                </span>
              </div>
            </div>
            <div v-if="orderDetails.profitSurveyVo && orderDetails.profitSurveyVo.shopConsumerProfit" class="grid-content bg-purple">
              <div class="purpleMsgTitel">顾客直推门店</div>
              <div class="purList">
                <span class="purpleMsgName">{{ orderDetails.profitSurveyVo.shopConsumerProfit[0].name }}:</span>
                <span>
                  {{ orderDetails.profitSurveyVo.shopConsumerProfit[0].profit | numFormat }}
                </span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 发货弹框 -->
    <delivery ref="delivery" :deliverys="orderDetails" @getorder="getorder" />
    <!-- 订单日志弹框 -->
    <order-logo ref="orderlogo" />
    <!-- 修改物流 -->
    <edit-logistics ref="editlogistics" />
    <!-- 查看物流详情 -->
    <details-logistics ref="detailslogistics" />
    <!-- 回复评价弹框 -->
    <reply-evaluate ref="replyevaluate" />
    <!-- 图片弹框 -->
    <el-dialog :visible.sync="imgDialog">
      <img :src="imagesUrl" width="100%" />
    </el-dialog>
    <!-- 修改收货地址弹框 -->
    <el-dialog title="修改收货地址" :visible.sync="dialogFormVisible" width="500px" @close="close">
      <el-form ref="postForm" label-width="80px" :model="postForm" :rules="postFormRules">
        <el-form-item label="收货人" prop="consignee">
          <el-input v-model.trim="postForm.consignee" />
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model.number="postForm.phone" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" />
        </el-form-item>
        <el-form-item label="收货地址" prop="provinces">
          <el-cascader
            v-model.trim="postForm.provinces"
            class="full-width"
            :options="areaSelectData"
            placeholder="请选择地址"
            :props="{
              children: 'children',
              label: 'areaName',
              value: 'areaName',
            }"
            size="large"
            style="width: 80%"
          />
        </el-form-item>
        <el-form-item prop="receivingAddress">
          <el-input v-model.trim="postForm.receivingAddress" placeholder="请输入详细地址" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { getOrderDetail, getOrderUpdatelogistics } from '@/api/mall/order/order'
import CountDown from '@/components/CountDown'
import { selectAreaTree } from '@/api/mall/organization/organization'
import { selectSysDict, selectRoleMenuButtonList } from '@/api/mall/common'
import { timestamp } from '@/utils/index'
import { hasPhone } from '@/utils/validate'
import Delivery from './components/delivery'
import OrderLogo from './components/orderlogo'
import EditLogistics from './components/editLogistics'
import DetailsLogistics from './components/detailsLogistics'
import ReplyEvaluate from './components/replyEvaluate'
export default {
  components: {
    CountDown,
    Delivery,
    OrderLogo,
    EditLogistics,
    DetailsLogistics,
    ReplyEvaluate,
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      if (!hasPhone(value)) {
        callback(new Error('请输入正确的手机号码'))
      }
      if (!value) {
        callback(new Error('请输入手机号'))
      } else {
        callback()
      }
    }
    return {
      imagesUrl: '',
      imgDialog: false,
      pageButton: [],
      ListButton: [],
      orderStateCheck: '',
      serverTime: '',
      evaluteList: [
        {
          id: 1,
          goodsName: '贵州飞天茅台',
          img: '',
          evaluteTime: '2012-10-12',
          evalut: '喝起真的安逸，巴适得很，不信你来整两瓶，爽歪歪',
        },
      ],
      values: 3.4,
      active: 0,
      goodListMsg: [
        {
          id: 1,
          img: '',
          title: '贵州飞天茅台',
          numbers: 12,
        },
      ],
      orderListStateList: [],
      areaSelectData: [],
      goodsMsgTable: [],
      orderDetails: {},
      dialogFormVisible: false,
      postForm: {
        provinces: '',
      },
      postFormRules: {
        consignee: [
          {
            required: true,
            message: '请输入收货人',
            trigger: ['blur', 'change'],
          },
        ],
        phone: [
          {
            required: true,
            validator: checkPhone,
            trigger: ['blur', 'change'],
          },
        ],
        provinces: [
          {
            required: true,
            message: '请选择收货地址',
            trigger: ['blur', 'change'],
          },
        ],
        receivingAddress: [
          {
            required: true,
            message: '请输入详细地址',
            trigger: ['blur', 'change'],
          },
        ],
      },
      lists: {
        consignee: '',
        phone: '',
        receivingAddress: '',
        city: '',
        county: '',
        province: '',
        provinces: '',
      },
      endTime: '',
      paymentTime: '',
      originalSumAmount: '',
      payableAmount: '',
      packageTag: ['包裹1', '无需物流'],
      goodsType: [],
      comOrderTypeList: [],
      comOrderType: '',
      orderDeliveryWayList: [], //配送方式
      deliveryWayDesc: '',
    }
  },

  created() {
    this.selectSysDict()
    setTimeout(() => {
      this.getorder()
      this.selectAreaTree()
      this.selectRoleMenuButtonList()
    })
  },
  methods: {
    // 按钮点击
    handleClickBtn(item, row) {
      if (item.buttonUrl) {
        this[item.buttonUrl](row)
      }
    },
    // 按钮权限
    selectRoleMenuButtonList() {
      selectRoleMenuButtonList({
        roleIdStrList: 1, // this.$store.getters['acl/roleList'],
        menuId: this.$route.meta.id,
      })
        .then((res) => {
          if (res.data) {
            this.pageButton = res.data.filter(function (item) {
              return item.buttonType == 4
            })
            this.ListButton = res.data.filter(function (item) {
              return item.buttonType == 2
            })
          }
        })
        .catch(() => {})
    },
    // 订单推送
    handlePush() {},
    // 打印
    handlePrint() {},
    // 剩余付款时间倒计时结束
    timeEnd() {
      this.getorder()
    },
    // 地址
    selectAreaTree() {
      selectAreaTree()
        .then((res) => {
          if (res.data) {
            this.areaSelectData = res.data
          }
        })
        .catch(() => {})
    },
    // 回复弹框
    handelReply() {
      this.$refs['replyevaluate'].showEdit()
    },
    // 包裹切换
    handleTag(index) {
      this.active = index
    },
    // 商品信息表格
    getSummaries(param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = `商品总额：${this.$options.filters.numFormat(this.payableAmount)}`
          return
        }
        if (index === 2) {
          sums[index] = '平台优惠券：'
          return
        }
        if (index === 4) {
          sums[index] = '店铺优惠劵：'
          return
        }
        if (index === 7) {
          sums[index] = '配送费用：'
          return
        }
      })
      return sums
    },
    // 退货退款详情
    handelAfterSales() {
      this.$router.push({ path: '/detailOrderAfter' })
    },
    // 订单日志
    handelOrderLogo() {
      this.$refs['orderlogo'].showEdit()
    },
    // 修改物流
    editLogistics() {
      this.$refs['editlogistics'].showEdit()
    },
    // 查看物流详情
    handleLookDsc() {
      this.$refs['detailslogistics'].showEdit()
    },
    // 详情数据
    async getorder() {
      await getOrderDetail({ id: this.$route.query.orderId })
        .then((res) => {
          if (res.data) {
            this.orderDetails = res.data
            this.comOrderType = res.data.orderType
            this.orderStateCheck = res.data.status
            for (let i = 0; i < this.orderListStateList.length; i++) {
              if (this.orderListStateList[i].dictKey == res.data.status) {
                this.orderDetails.status = this.orderListStateList[i].dictValue
              }
            }
            for (let i = 0; i < this.comOrderTypeList.length; i++) {
              if (this.comOrderTypeList[i].dictKey == res.data.orderType) {
                this.orderDetails.orderType = this.comOrderTypeList[i].dictValue
              }
            }

            //配送方式
            for (let i = 0; i < this.orderDeliveryWayList.length; i++) {
              if (this.orderDeliveryWayList[i].dictKey == res.data.deliveryWay) {
                this.deliveryWayDesc = this.orderDeliveryWayList[i].dictValue
                console.log('cosnslo==========>:{}', this.orderDeliveryWayList[i].dictValue)
              }
            }

            this.goodsMsgTable = res.data.listOrderItem != null ? res.data.listOrderItem : null //商品列表
            console.log('==================')
            console.log(res.data.listOrderItem)
            this.payableAmount = res.data.payableAmount
            let serverDate = res.data.serverTime != null ? new Date(res.data.serverTime) : ''
            this.serverTime = serverDate.getTime()
            if (res.data.orderSummary != null && res.data.orderSummary) {
              let endTimeDate = res.data.orderSummary.endTime != null ? new Date(res.data.orderSummary.endTime) : ''
              this.endTime = endTimeDate.getTime()
              this.paymentTime = res.data.createTime != null ? timestamp(res.data.createTime) : ''
              this.originalSumAmount = res.data.orderSummary.originalSumAmount
            }
          } else {
            this.ME('暂无数据')
          }
        })
        .catch(() => {})
    },
    // 图片预览
    handleImgClick(url) {
      this.imagesUrl = url
      this.imgDialog = true
    },
    // 商品信息状态过滤
    formatterOrder(row) {
      for (let i = 0; i < this.orderListStateList.length; i++) {
        if (this.orderListStateList[i].dictKey == row.status) {
          return this.orderListStateList[i].dictValue
        }
      }
    },
    //字典(订单状态、商品类型)
    selectSysDict() {
      selectSysDict({
        dictTypeList: ['orderStatus', 'goodsType', 'comOrderType', 'mallPostType'],
      })
        .then((res) => {
          if (res.data) {
            this.orderListStateList = res.data[0].dictInfoVoList
            this.goodsType = res.data[1].dictInfoVoList
            this.comOrderTypeList = res.data[2].dictInfoVoList
            this.orderDeliveryWayList = res.data[3].dictInfoVoList
          }
        })
        .catch(() => {})
    },
    // 商品类型
    isRealType(row) {
      for (let i = 0; i < this.goodsType.length; i++) {
        if (this.goodsType[i].dictKey == row.isReal) {
          return this.goodsType[i].dictValue
        }
      }
    },
    // 发货弹框
    handelDelivery() {
      this.$refs['delivery'].showEdit()
    },
    // 修改收货地址关闭
    close() {
      this.$refs['postForm'].resetFields()
      this.dialogFormVisible = false
    },
    // 修改收货地址保存
    save() {
      this.$refs['postForm'].validate(async (valid) => {
        if (valid) {
          const parms = {
            id: this.orderDetails.logisticsId != null ? this.orderDetails.logisticsId : '',
            geoCode: this.orderDetails.geoCode,
            userName: this.postForm.consignee,
            telNum: this.postForm.phone,
            province: this.postForm.provinces[0],
            city: this.postForm.provinces[1],
            county: this.postForm.provinces[2],
            address: this.postForm.provinces[0] + this.postForm.provinces[1] + this.postForm.provinces[2] + this.postForm.receivingAddress,
          }
          await getOrderUpdatelogistics(parms)
            .then((res) => {
              console.log(res)
              this.MS('修改成功')
              this.getorder()
              this.close()
            })
            .catch(() => {})
        }
      })
    },
    // 修改收货地址回显
    handleEditAdr() {
      const citys = this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.city : '',
        countys = this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.county : '',
        provinces = this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.province : ''
      const adr = [provinces, citys, countys]
      this.lists = {
        consignee: this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.consignee : '',
        phone: this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.phone : '',
        provinces: adr,
        receivingAddress: this.orderDetails.orderConsignee ? this.orderDetails.orderConsignee.receivingAddress : '',
      }
      this.postForm = this.lists
      this.dialogFormVisible = true
    },
  },
}
</script>
<style lang="scss" scoped>
.purpleMsgName {
  margin-right: 5px;
  color: rgb(142, 142, 147);
}
.purpleMsgTitel {
  margin-bottom: 20px;
  color: #1890ff;
}
.purList {
  margin-bottom: 15px;
}
.evaluteUl {
  padding-bottom: 12px;
  border-bottom: 1px solid #ddd;
}
.evaluteUl:last-of-type {
  border: none !important;
}
.OrderDetail {
  padding: 10px;
  .el-descriptions {
    padding: 10px;
    .el-descriptions__header {
      margin-bottom: 15px;
    }
    ::v-deep(.el-descriptions__body) {
      margin-left: 10px;

      .el-descriptions-item__label {
        margin-right: 5px;
        color: rgb(142, 142, 147);
      }
      .el-descriptions-item__content {
        color: #000;
      }
    }
  }
  .vab-query-form {
    padding: 0px 10px 0 10px;
    .left-panel .el-page-header {
      margin: 0px;
    }
    .left-panel,
    .right-panel {
      min-height: 50px;
      margin: 0px;
      .el-button {
        margin: 0px !important;
      }
    }
  }
  .orderMoney {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    // padding: 20px 0 0;
    color: #606266;
  }
  .detailTitile {
    font-size: 15px;
    color: #000;
  }
  ::v-deep(.el-descriptions__title) {
    padding-left: 6px;
    font-size: 14px;
    border-left: 4px solid rgb(0, 122, 255);
  }
  .detaileMag {
    ::v-deep(.el-descriptions-item__content) {
      width: 77% !important;
    }
    .el-col {
      margin-right: 10px;
      // width: 24%;
      background: rgba(240, 240, 240, 0.69);
    }
    ::v-deep(.el-descriptions__body) {
      margin-left: 10px;
      background: none;
    }
  }
  .purpleMsg {
    margin-bottom: 20px;
  }
  .goodsmsgs {
    ::v-deep(.el-descriptions-item__label) {
      margin-right: 0px !important;
    }
    ::v-deep(.el-descriptions-item__content) {
      width: 100%;
    }
  }
}
.isActive {
  color: #fff !important;
  background-color: #1890ff !important;
}
.deliveryMsg {
  ::v-deep(.el-descriptions-item__content) {
    width: 100%;
  }
}
.evaluate {
  ::v-deep(.el-descriptions__header) {
    margin-bottom: 0px;
  }
}
.evaluteImg {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.list-item {
  list-style: none;
  &-meta {
    display: flex;
    flex: 1 1;
    align-items: flex-start;
    &-avatar {
      margin-right: 16px;
      ::v-deep() {
        .el-image {
          width: 61px;
          height: 61px;
        }
      }
    }
    &-content {
      flex: 1 0;
      width: 0;
      color: rgba(0, 0, 0, 0.85);
    }
    &-item {
      display: inline-block;
      height: 61px;
      margin-left: 40px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      vertical-align: middle;
    }
  }
}
::v-deep .ym_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
::v-deep .el-col {
  margin-right: 0;
}
</style>
