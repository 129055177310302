var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "查看",
            visible: _vm.showdemo,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showdemo = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("体检时间"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c("el-date-picker", {
                          attrs: { placeholder: " 年 月 日", type: "date" },
                          model: {
                            value: _vm.tablist.userYear,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "userYear", $$v)
                            },
                            expression: "tablist.userYear",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("手机号"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "手机号" },
                          model: {
                            value: _vm.tablist.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "phone", $$v)
                            },
                            expression: "tablist.phone",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("姓名"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "姓名" },
                          model: {
                            value: _vm.tablist.userName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "userName", $$v)
                            },
                            expression: "tablist.userName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("性别"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "性别" },
                          model: {
                            value: _vm.tablist.userSex,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "userSex", $$v)
                            },
                            expression: "tablist.userSex",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("年龄"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "年龄" },
                          model: {
                            value: _vm.tablist.userAge,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "userAge", $$v)
                            },
                            expression: "tablist.userAge",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("患病年限"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "患病年限" },
                          model: {
                            value: _vm.tablist.fattyIlness,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "fattyIlness", $$v)
                            },
                            expression: "tablist.fattyIlness",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("身高 cm"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("体重 kg"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("BMI"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("腰围"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("血压"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("心率"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("是否规律用药"),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "身高" },
                          model: {
                            value: _vm.tablist.userHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "userHeight", $$v)
                            },
                            expression: "tablist.userHeight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "体重" },
                          model: {
                            value: _vm.tablist.userWeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "userWeight", $$v)
                            },
                            expression: "tablist.userWeight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "BMI" },
                          model: {
                            value: _vm.tablist.userMbi,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "userMbi", $$v)
                            },
                            expression: "tablist.userMbi",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "腰围" },
                          model: {
                            value: _vm.tablist.userWaist,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "userWaist", $$v)
                            },
                            expression: "tablist.userWaist",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "血压" },
                          model: {
                            value: _vm.tablist.userPressure,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "userPressure", $$v)
                            },
                            expression: "tablist.userPressure",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "心率" },
                          model: {
                            value: _vm.tablist.userHeart,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "userHeart", $$v)
                            },
                            expression: "tablist.userHeart",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.medRegularly,
                              callback: function ($$v) {
                                _vm.$set(_vm.tablist, "medRegularly", $$v)
                              },
                              expression: "tablist.medRegularly",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark",
                        staticStyle: { height: "72px", "line-height": "72px" },
                      },
                      [_vm._v("是否打针")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _vm._v(" 胰岛素 "),
                        _c("el-checkbox", { attrs: { label: "" } }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _vm._v(" 药名： "),
                        _c("el-input", {
                          attrs: { placeholder: "药名" },
                          model: {
                            value: _vm.tablist.casePos1[0].insulinTitle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tablist.casePos1[0],
                                "insulinTitle",
                                $$v
                              )
                            },
                            expression: "tablist.casePos1[0].insulinTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _vm._v(" 剂量： "),
                        _c("el-input", {
                          attrs: { placeholder: "剂量" },
                          model: {
                            value: _vm.tablist.casePos1[0].caseDose,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist.casePos1[0], "caseDose", $$v)
                            },
                            expression: "tablist.casePos1[0].caseDose",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _vm._v(" GLP-1 类 "),
                        _c("el-checkbox", { attrs: { label: "" } }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _vm._v(" 药名： "),
                        _c("el-input", {
                          attrs: { placeholder: "药名" },
                          model: {
                            value: _vm.tablist.casePos1[1].insulinTitle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tablist.casePos1[1],
                                "insulinTitle",
                                $$v
                              )
                            },
                            expression: "tablist.casePos1[1].insulinTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _vm._v(" 剂量： "),
                        _c("el-input", {
                          attrs: { placeholder: "剂量" },
                          model: {
                            value: _vm.tablist.casePos1[1].caseDose,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist.casePos1[1], "caseDose", $$v)
                            },
                            expression: "tablist.casePos1[1].caseDose",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark",
                        staticStyle: {
                          height: "146px",
                          "line-height": "146px",
                        },
                      },
                      [_vm._v("是否口服药")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _vm._v(" 药名： "),
                        _c("el-input", {
                          attrs: { placeholder: "药名" },
                          model: {
                            value: _vm.tablist.casePos2[0].insulinTitle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tablist.casePos2[0],
                                "insulinTitle",
                                $$v
                              )
                            },
                            expression: "tablist.casePos2[0].insulinTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v(" 一天 "),
                      _c(
                        "div",
                        { staticClass: "textundeline" },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "55px" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.tablist.casePos2[0].caseCishu,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos2[0],
                                  "caseCishu",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos2[0].caseCishu",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" 次,一次 "),
                      _c(
                        "div",
                        { staticClass: "textundeline" },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "55px" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.tablist.casePos2[0].casePian,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos2[0],
                                  "casePian",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos2[0].casePian",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" 片，一片 "),
                      _c(
                        "div",
                        { staticClass: "textundeline" },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "55px" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.tablist.casePos2[0].caseMg,
                              callback: function ($$v) {
                                _vm.$set(_vm.tablist.casePos2[0], "caseMg", $$v)
                              },
                              expression: "tablist.casePos2[0].caseMg",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" mg "),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _vm._v(" 药名： "),
                        _c("el-input", {
                          attrs: { placeholder: "药名" },
                          model: {
                            value: _vm.tablist.casePos2[1].insulinTitle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tablist.casePos2[1],
                                "insulinTitle",
                                $$v
                              )
                            },
                            expression: "tablist.casePos2[1].insulinTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v(" 一天 "),
                      _c(
                        "div",
                        { staticClass: "textundeline" },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "55px" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.tablist.casePos2[1].caseCishu,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos2[1],
                                  "caseCishu",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos2[1].caseCishu",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" 次,一次 "),
                      _c(
                        "div",
                        { staticClass: "textundeline" },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "55px" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.tablist.casePos2[1].casePian,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos2[1],
                                  "casePian",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos2[1].casePian",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" 片，一片 "),
                      _c(
                        "div",
                        { staticClass: "textundeline" },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "55px" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.tablist.casePos2[1].caseMg,
                              callback: function ($$v) {
                                _vm.$set(_vm.tablist.casePos2[1], "caseMg", $$v)
                              },
                              expression: "tablist.casePos2[1].caseMg",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" mg "),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _vm._v(" 药名： "),
                        _c("el-input", {
                          attrs: { placeholder: "药名" },
                          model: {
                            value: _vm.tablist.casePos2[2].insulinTitle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tablist.casePos2[2],
                                "insulinTitle",
                                $$v
                              )
                            },
                            expression: "tablist.casePos2[2].insulinTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v(" 一天 "),
                      _c(
                        "div",
                        { staticClass: "textundeline" },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "55px" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.tablist.casePos2[2].caseCishu,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos2[2],
                                  "caseCishu",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos2[2].caseCishu",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" 次,一次 "),
                      _c(
                        "div",
                        { staticClass: "textundeline" },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "55px" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.tablist.casePos2[2].casePian,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos2[2],
                                  "casePian",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos2[2].casePian",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" 片，一片 "),
                      _c(
                        "div",
                        { staticClass: "textundeline" },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "55px" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.tablist.casePos2[2].caseMg,
                              callback: function ($$v) {
                                _vm.$set(_vm.tablist.casePos2[2], "caseMg", $$v)
                              },
                              expression: "tablist.casePos2[2].caseMg",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" mg "),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _vm._v(" 药名： "),
                        _c("el-input", {
                          attrs: { placeholder: "药名" },
                          model: {
                            value: _vm.tablist.casePos2[3].insulinTitle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tablist.casePos2[3],
                                "insulinTitle",
                                $$v
                              )
                            },
                            expression: "tablist.casePos2[3].insulinTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v(" 一天 "),
                      _c(
                        "div",
                        { staticClass: "textundeline" },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "55px" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.tablist.casePos2[3].caseCishu,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos2[3],
                                  "caseCishu",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos2[3].caseCishu",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" 次,一次 "),
                      _c(
                        "div",
                        { staticClass: "textundeline" },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "55px" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.tablist.casePos2[3].casePian,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos2[3],
                                  "casePian",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos2[3].casePian",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" 片，一片 "),
                      _c(
                        "div",
                        { staticClass: "textundeline" },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "55px" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.tablist.casePos2[3].caseMg,
                              callback: function ($$v) {
                                _vm.$set(_vm.tablist.casePos2[3], "caseMg", $$v)
                              },
                              expression: "tablist.casePos2[3].caseMg",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" mg "),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("是否会监测血糖"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.sugarStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.tablist, "sugarStatus", $$v)
                              },
                              expression: "tablist.sugarStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                        _vm.tablist.sugarStatus == 1
                          ? _c(
                              "div",
                              [
                                _vm._v(" (空腹： "),
                                _c("el-input", {
                                  staticStyle: { "max-width": "100px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.tablist.sugarStomach,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tablist, "sugarStomach", $$v)
                                    },
                                    expression: "tablist.sugarStomach",
                                  },
                                }),
                                _vm._v(" 餐后2小时: "),
                                _c("el-input", {
                                  staticStyle: { "max-width": "100px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.tablist.sugarMeal,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tablist, "sugarMeal", $$v)
                                    },
                                    expression: "tablist.sugarMeal",
                                  },
                                }),
                                _vm._v(" ) "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("是否出现低血糖"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.sugarLowStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.tablist, "sugarLowStatus", $$v)
                              },
                              expression: "tablist.sugarLowStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm.tablist.sugarLowStatus == 1
                      ? _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("出现时间和频率")]
                        )
                      : _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("出现时间和频率")]
                        ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm.tablist.sugarLowStatus == 1
                      ? _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "出现时间和频率" },
                              model: {
                                value: _vm.tablist.sugarLowMeal,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tablist, "sugarLowMeal", $$v)
                                },
                                expression: "tablist.sugarLowMeal",
                              },
                            }),
                          ],
                          1
                        )
                      : _c("div", {
                          staticClass: "grid-content bg-purple-dark",
                        }),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark",
                        staticStyle: { height: "100px" },
                      },
                      [_vm._v("既往病史")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark",
                        staticStyle: { height: "100px", "flex-wrap": "wrap" },
                      },
                      _vm._l(_vm.tablist.casePos3, function (item, index) {
                        return _c(
                          "el-tag",
                          { key: index, attrs: { type: "info", v: "" } },
                          [_vm._v(_vm._s(item.caseName))]
                        )
                      }),
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("既往史是否用药"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.drugStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.tablist, "drugStatus", $$v)
                              },
                              expression: "tablist.drugStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                        _vm.tablist.drugStatus == 1
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "20px" } },
                              [_vm._v("药物名称：")]
                            )
                          : _vm._e(),
                        _vm.tablist.drugStatus == 1
                          ? _c("el-input", {
                              attrs: { placeholder: "药物名称" },
                              model: {
                                value: _vm.tablist.drugName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tablist, "drugName", $$v)
                                },
                                expression: "tablist.drugName",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("住院史"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "住院史地址" },
                          model: {
                            value: _vm.tablist.addressDetail,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "addressDetail", $$v)
                            },
                            expression: "tablist.addressDetail",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("脂肪肝"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.fattyStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.tablist, "fattyStatus", $$v)
                              },
                              expression: "tablist.fattyStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("家族史"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "" },
                          model: {
                            value: _vm.tablist.fattyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tablist, "fattyName", $$v)
                            },
                            expression: "tablist.fattyName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark",
                        staticStyle: { height: "45px" },
                      },
                      [_vm._v("最希望改善的地方")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark",
                        staticStyle: { "flex-wrap": "wrap", height: "45px" },
                      },
                      _vm._l(_vm.tablist.casePos4, function (item, index) {
                        return _c(
                          "el-tag",
                          { key: index, attrs: { type: "info", v: "" } },
                          [_vm._v(_vm._s(item.caseName))]
                        )
                      }),
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark",
                        staticStyle: { height: "257px" },
                      },
                      [_vm._v("身体症状")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _vm._v(" 1、有无手麻、脚麻或皮肤瘙痒等症状？ "),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.casePos5[0].caseStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos5[0],
                                  "caseStatus",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos5[0].caseStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _vm._v(" 2、有无失眠、多梦或便秘等症状？ "),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.casePos5[1].caseStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos5[1],
                                  "caseStatus",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos5[1].caseStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _vm._v(" 3、是否有便秘、腹泻等症状？ "),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.casePos5[2].caseStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos5[2],
                                  "caseStatus",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos5[2].caseStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _vm._v(
                          " 4、有无心慌、胸闷、气短、头晕头痛等心脑血管症状？ "
                        ),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.casePos5[3].caseStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos5[3],
                                  "caseStatus",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos5[3].caseStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _vm._v(" 5、有无视物模糊？ "),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.casePos5[4].caseStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos5[4],
                                  "caseStatus",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos5[4].caseStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _vm._v(" 6、有无尿液泡沫增多？ "),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.casePos5[5].caseStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos5[5],
                                  "caseStatus",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos5[5].caseStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _vm._v(
                          " 7、有无糖尿病足部病变（如损伤后不容易愈合）？ "
                        ),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.casePos5[6].caseStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos5[6],
                                  "caseStatus",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos5[6].caseStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                      _vm._v("饮食生活调查问卷"),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark lef_ym" },
                      [_vm._v("1、平时吃一顿饭用时")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _vm._v(" 大概 "),
                        _c(
                          "div",
                          { staticClass: "textundeline" },
                          [
                            _c("el-input", {
                              staticStyle: { "max-width": "55px" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.tablist.caseMg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tablist, "caseMg", $$v)
                                },
                                expression: "tablist.caseMg",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" 分钟 "),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark lef_ym" },
                      [_vm._v("2、晚上 8 点后会吃食物吗？")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.casePos6[1].caseStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos6[1],
                                  "caseStatus",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos6[1].caseStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" 一周 "),
                        _c(
                          "div",
                          { staticClass: "textundeline" },
                          [
                            _c("el-input", {
                              staticStyle: { "max-width": "55px" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.tablist.casePos6[1].caseCishu,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tablist.casePos6[1],
                                    "caseCishu",
                                    $$v
                                  )
                                },
                                expression: "tablist.casePos6[1].caseCishu",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" 次 "),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark lef_ym" },
                      [_vm._v("3、三餐以外会吃食物/零食吗？")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.casePos6[2].caseStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos6[2],
                                  "caseStatus",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos6[2].caseStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" 一周 "),
                        _c(
                          "div",
                          { staticClass: "textundeline" },
                          [
                            _c("el-input", {
                              staticStyle: { "max-width": "55px" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.tablist.casePos6[2].caseCishu,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tablist.casePos6[2],
                                    "caseCishu",
                                    $$v
                                  )
                                },
                                expression: "tablist.casePos6[2].caseCishu",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" 次 "),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark lef_ym" },
                      [_vm._v("4、是否常喝饮料/酒")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.casePos6[3].caseStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos6[3],
                                  "caseStatus",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos6[3].caseStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" 一周 "),
                        _c(
                          "div",
                          { staticClass: "textundeline" },
                          [
                            _c("el-input", {
                              staticStyle: { "max-width": "55px" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.tablist.casePos6[3].caseCishu,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tablist.casePos6[3],
                                    "caseCishu",
                                    $$v
                                  )
                                },
                                expression: "tablist.casePos6[3].caseCishu",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" 次 "),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark lef_ym" },
                      [_vm._v("5、平时会运动吗？")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.casePos6[4].caseStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos6[4],
                                  "caseStatus",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos6[4].caseStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" 一周 "),
                        _c(
                          "div",
                          { staticClass: "textundeline" },
                          [
                            _c("el-input", {
                              staticStyle: { "max-width": "55px" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.tablist.casePos6[4].caseCishu,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tablist.casePos6[4],
                                    "caseCishu",
                                    $$v
                                  )
                                },
                                expression: "tablist.casePos6[4].caseCishu",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" 次 "),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark lef_ym" },
                      [_vm._v("6、是否 12 点之前入睡？")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.casePos6[5].caseStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos6[5],
                                  "caseStatus",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos6[5].caseStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple-dark lef_ym" },
                      [_vm._v("7、睡眠质量好吗？")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple-dark lef_ym",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.tablist.casePos6[6].caseStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tablist.casePos6[6],
                                  "caseStatus",
                                  $$v
                                )
                              },
                              expression: "tablist.casePos6[6].caseStatus",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("好"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("不好"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }