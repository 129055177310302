<template>
  <div>
    <el-upload
      ref="upload"
      :action="action"
      :before-upload="bfUpload"
      :class="{
        hide: hideUploasd,
      }"
      :file-list="fileLists"
      :headers="headers"
      :limit="limit"
      list-type="picture-card"
      :on-change="handleChanges"
      :on-preview="handlePictureCardPreviews"
      :on-remove="handleRemoves"
      :on-success="handleSuccess"
      :show-file-list="true"
    >
      <i class="el-icon-plus"></i>
      <!-- <el-button size="small" type="">点击上传</el-button> -->
    </el-upload>
    <el-dialog  append-to-body :visible.sync="dialogVisible">
      <img alt="" :src="dialogImageUrl" width="100%" />
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getToken } from '@/utils/token'
  import { uploadURL } from '@/config'
  export default {
    props: {
      forindex:{
        type:Number,
        default:()=>{}
      },
      picurl: {
        type: Array,
        default: () => {},
      },
    },
    data() {
      return {
        action: uploadURL,
        hideUploasd: '',
        fileLists: [],
        headers: {},
        limit: 1,
        dialogVisible: false,
        addForm: {
          headers: '',
        },
        dialogImageUrl: '',
      }
    },
    computed: {
      ...mapGetters({
        token: 'user/token',
        tenantId : 'user/tenantId',
      }),
    },
    created() {
      // console.log(this.forindex,'123',this.picurl[0].url)
      // if(this.picurl[0].url!='https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png'){
      //   this.fileLists = this.picurl
      // }
      const db = getToken()
      // this.fileLists = this.picurl
      this.headers['Authorization'] = `Bearer ` + `${db}`
      this.headers['Switch-Tenant-Id'] = `${this.tenantId}`
    },
    methods: {
      bfUpload(file) {
        if ('image/png' == file.type || 'image/jpeg' == file.type || 'image/jpg' == file.type) {
          this.addForm.headers = this.headers
        } else {
          this.ME('图片上传失败,请上传png,jpeg,jpg格式')
          return false
        }
      },
      handleChanges(file, fileList) {
        this.hideUpload = fileList.length >= this.limit
      },
      handlePictureCardPreviews(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      handleRemoves(fileList) {
        this.fileLists = []
        this.hideUploasd = fileList.length >= this.limit
        this.$emit('backkuck','https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png')
      },
      handleSuccess(response) {
        this.fileLists = [{ name: 'food.jpg', url: response.data.filePath }]
        this.$emit('backkuck', response.data)
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-upload--picture-card,
    .el-upload-list__item-actions,
    .el-upload-list__item {
      width: 70px;
      height: 70px;
    }
    .el-upload--picture-card {
      position: relative;
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
</style>
