<template>
  <div>
    <el-dialog
      title="重置密码"
      :visible.sync="dialogFormVisible"
      @close="close"
    >
      <el-form
        ref="ruleForm"
        class="demo-ruleForm"
        label-width="100px"
        :model="ruleForm"
        :rules="rules"
        status-icon
      >
        <el-form-item label="密码" prop="pass">
          <el-input v-model="ruleForm.pass" type="password" />
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input v-model="ruleForm.checkPass" type="password" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleConfirm('ruleForm')">
            提交
          </el-button>
          <el-button @click="resetForm()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
  import { resetPwd } from '@/api/mall/users/list'
  export default {
    data() {
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'))
        } else if (value.length < 6) {
          callback(new Error('密码长度不得小于6位'))
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass')
          }
          callback()
        }
      }
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        ruleForm: {
          pass: '',
          checkPass: '',
        },
        rules: {
          pass: [{ validator: validatePass, trigger: 'blur' }],
          checkPass: [{ validator: validatePass2, trigger: 'blur' }],
        },
        dialogFormVisible: false,
        userDetail: {},
      }
    },
    methods: {
      async show(data) {
        this.userDetail = data
        this.dialogFormVisible = true
      },
      close() {
        this.$emit('fetch')
        this.resetForm()
        this.dialogFormVisible = false
      },
      handleConfirm(formName) {
        const { id: userId } = this.userDetail
        this.$refs[formName].validate((valid) => {
          if (valid) {
            resetPwd({
              userId,
              password: this.pass,
              surePassword: this.checkPass,
            }).then(() => {
              this.$message.success('修改密码成功！')
              this.close()
            })
          } else {
            this.$message.error('非法参数！')
            return false
          }
        })
      },
      resetForm() {
        this.ruleForm = {
          pass: '',
          checkPass: '',
        }
      },
    },
  }
</script>
