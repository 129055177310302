<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    title="查看"
    :visible.sync="dialogVisible"
    width="60%">
    <span>
      <el-descriptions border class="margin-top" :column="2">
        <el-descriptions-item label="昵称：">{{date.nickName}}</el-descriptions-item>
        <el-descriptions-item label="店铺：">18100000000</el-descriptions-item>
        <el-descriptions-item label="账号：">{{date.username}}</el-descriptions-item>
        <el-descriptions-item label="手机号：">{{ date.phone }} </el-descriptions-item>
        <el-descriptions-item label="邮箱：">{{ date.email }}</el-descriptions-item>
        <el-descriptions-item label="头像">
          <el-image 
          :src="date.avatar" 
          style="width: 100px; height: 100px"/>
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">{{ date.createTime }}</el-descriptions-item>
      </el-descriptions>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props:{
    dishide:{
      type:Boolean,
      default:()=>{}
    },
    datrow:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
    return{
      dialogVisible:this.dishide,
      date:{}
    }
  },
  watch:{
    dishide(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisible=newValue
      }
    },
    datrow(newValue,oldValue){
      if(newValue!=oldValue){
        this.date=newValue
      }
    }
  },
  methods:{
    handleClose(){
      console.log('11')
      this.$emit("closedia",false)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header{
  border-bottom: 1px solid #edeaea;
}
</style>