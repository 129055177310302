var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "thead_tree" },
      [
        _c(
          "el-timeline",
          _vm._l(_vm.list, function (ym, index) {
            return _c(
              "el-timeline-item",
              {
                key: index,
                attrs: { placement: "top", timestamp: ym.createTime },
              },
              [
                _c("el-card", [
                  _c("h4", [
                    _c("span", { staticStyle: { color: "#1786ff" } }, [
                      _vm._v(_vm._s(ym.realName)),
                    ]),
                    _vm._v(" " + _vm._s(ym.title)),
                  ]),
                  _c("p", [_vm._v(_vm._s(ym.remark))]),
                ]),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }