//app表格数据
import request from '@/utils/request'

export function queryPressPage(data) {
  return request({
    url: `/mall/swm/wsmUserPressureInfo/queryPressPage`,
    method: 'post',
    data,
  })
}

export function detailsByXoryInfo(data) {
  return request({
    url: `/mall/swm/wsmUserPressureInfo/detailsByXoryInfo`,
    method: 'post',
    data,
  })
}