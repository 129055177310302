<template>
  <div
    class="container"
    :style="{
      background: `${controls[index].pageBgColor}`,
      padding: `${controls[index].margin.top}px ${controls[index].margin.both}px ${controls[index].margin.bottom}px ${controls[index].margin.both}px`,
    }"
  >
    <div
      v-if="controls[index].style == 'style-12'"
      class="canvas_cont"
      :style="{
        background: `${controls[index].componentBgColor}`,
        borderTopLeftRadius: `${controls[index].topElementAroundRadius}px `,
        borderTopRightRadius: `${controls[index].topElementAroundRadius}px `,
        borderBottomRightRadius: `${controls[index].bottomElementAroundRadius}px `,
        borderBottomLeftRadius: `${controls[index].bottomElementAroundRadius}px `,
      }"
    >
      <div class="lef">
        <div
          class="thead_title"
          :style="{
            color: `${controls[index].textColor}`,
            fontSize: `${controls[index].fontSize}px`,
            fontWeight: `${controls[index].fontWeight}`,
          }"
        >
          {{ controls[index].text }}
        </div>
        <div
          class="subheading"
          :style="{
            color: `${controls[index].subTitle.color}`,
            fontSize: `${controls[index].subTitle.fontSize}px`,
            fontWeight: `${controls[index].subTitle.fontWeight}`,
          }"
        >
          {{ controls[index].subTitle.text }}
        </div>
      </div>
      <div
        v-if="controls[index].more.isShow"
        class="lef"
        :style="{
          color: `${controls[index].more.color}`,
        }"
      >
        {{ controls[index].more.text }}
        <vab-icon icon="arrow-right-s-line" style="font-size: 12px" />
      </div>
    </div>
    <stylefouteen v-if="controls[index].style == 'style-14'" :styleinfo="controls[index]" />
  </div>
</template>

<script>
import stylefouteen from './components/style-fourteen.vue'
import { mapState } from 'vuex'
export default {
  components: {
    stylefouteen,
  },
  props: {
    index: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      cur: 0,
    }
  },
  computed: {
    ...mapState({
      controls: (state) => state.checkvalue,
    }),
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        this.terminal = newValue
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .canvas_cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    min-height: 60px;
    padding: 0 15px;
    .lef {
      display: flex;
      align-items: center;
    }
  }
}
</style>