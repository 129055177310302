var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vab-query-form-top-panel",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: true, "label-width": "89px", model: _vm.queryForm },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客户姓名" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入客户姓名" },
                model: {
                  value: _vm.queryForm.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "userName", $$v)
                  },
                  expression: "queryForm.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户电话" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入客户电话" },
                model: {
                  value: _vm.queryForm.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "phone", $$v)
                  },
                  expression: "queryForm.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户性别" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择客户性别" },
                  model: {
                    value: _vm.queryForm.sex,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "sex", $$v)
                    },
                    expression: "queryForm.sex",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "交流类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择交流类型" },
                  on: {
                    focus: function ($event) {
                      return _vm.queryPages(1)
                    },
                  },
                  model: {
                    value: _vm.queryForm.interId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "interId", $$v)
                    },
                    expression: "queryForm.interId",
                  },
                },
                _vm._l(_vm.communication, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.labelName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fold,
                  expression: "!fold",
                },
              ],
              attrs: { label: "渠道类型" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择渠道类型" },
                  on: {
                    focus: function ($event) {
                      return _vm.queryPages(2)
                    },
                  },
                  model: {
                    value: _vm.queryForm.channelId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "channelId", $$v)
                    },
                    expression: "queryForm.channelId",
                  },
                },
                _vm._l(_vm.Channel, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.labelName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择订单类型" },
                  on: {
                    focus: function ($event) {
                      return _vm.queryPages(3)
                    },
                  },
                  model: {
                    value: _vm.queryForm.orderKey,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "orderKey", $$v)
                    },
                    expression: "queryForm.orderKey",
                  },
                },
                _vm._l(_vm.Ordertype, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.labelName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fold,
                  expression: "!fold",
                },
              ],
              attrs: { label: "物流类型" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择物流类型" },
                  on: {
                    focus: function ($event) {
                      return _vm.queryPages(3)
                    },
                  },
                  model: {
                    value: _vm.queryForm.logisticsKey,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "logisticsKey", $$v)
                    },
                    expression: "queryForm.logisticsKey",
                  },
                },
                _vm._l(_vm.logisticsKey, function (item) {
                  return _c("el-option", {
                    key: item.dictKey,
                    attrs: { label: item.dictValue, value: item.dictKey },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "注册状态" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择注册状态" },
                  model: {
                    value: _vm.queryForm.bindStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "bindStatus", $$v)
                    },
                    expression: "queryForm.bindStatus",
                  },
                },
                _vm._l(_vm.rigest, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择会员类型" },
                  on: {
                    focus: function ($event) {
                      return _vm.queryPages(5)
                    },
                  },
                  model: {
                    value: _vm.queryForm.clientId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "clientId", $$v)
                    },
                    expression: "queryForm.clientId",
                  },
                },
                _vm._l(_vm.Membership, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.labelName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "导入时间" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "default-time": ["00:00:00", "23:59:59"],
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                on: { change: _vm.changedate },
                model: {
                  value: _vm.value1,
                  callback: function ($$v) {
                    _vm.value1 = $$v
                  },
                  expression: "value1",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fold,
                  expression: "!fold",
                },
              ],
              attrs: { label: "复购次数" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择复购次数" },
                  model: {
                    value: _vm.queryForm.repNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "repNum", $$v)
                    },
                    expression: "queryForm.repNum",
                  },
                },
                _vm._l(_vm.repurchases, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fold,
                  expression: "!fold",
                },
              ],
              attrs: { label: "客户区域" },
            },
            [
              _c("el-cascader", {
                attrs: {
                  clearable: "",
                  options: _vm.areaSelectData,
                  props: {
                    children: "children",
                    label: "areaName",
                    value: "areaName",
                    checkStrictly: true,
                  },
                },
                on: { change: _vm.addres },
                model: {
                  value: _vm.provinces,
                  callback: function ($$v) {
                    _vm.provinces = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "provinces",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fold,
                  expression: "!fold",
                },
              ],
              attrs: { label: "分配时间" },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  "default-time": ["00:00:00", "23:59:59"],
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                on: { change: _vm.changedates },
                model: {
                  value: _vm.value2,
                  callback: function ($$v) {
                    _vm.value2 = $$v
                  },
                  expression: "value2",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fold,
                  expression: "!fold",
                },
              ],
              attrs: { label: "购买时间" },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  "default-time": ["00:00:00", "23:59:59"],
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                on: { change: _vm.daterange },
                model: {
                  value: _vm.value3,
                  callback: function ($$v) {
                    _vm.value3 = $$v
                  },
                  expression: "value3",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fold,
                  expression: "!fold",
                },
              ],
              attrs: { label: "初次联系" },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  "default-time": ["00:00:00", "23:59:59"],
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                on: { change: _vm.firstStartTime },
                model: {
                  value: _vm.value4,
                  callback: function ($$v) {
                    _vm.value4 = $$v
                  },
                  expression: "value4",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fold,
                  expression: "!fold",
                },
              ],
              attrs: { label: "末次联系" },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  "default-time": ["00:00:00", "23:59:59"],
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                on: { change: _vm.lastStartTime },
                model: {
                  value: _vm.value5,
                  callback: function ($$v) {
                    _vm.value5 = $$v
                  },
                  expression: "value5",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fold,
                  expression: "!fold",
                },
              ],
              attrs: { label: "风险状态" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择风险状态" },
                  model: {
                    value: _vm.queryForm.riskState,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "riskState", $$v)
                    },
                    expression: "queryForm.riskState",
                  },
                },
                _vm._l(_vm.risktatus, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    "native-type": "submit",
                    type: "primary",
                  },
                  on: { click: _vm.searchquery },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-refresh-right",
                    "native-type": "submit",
                    type: "warning",
                  },
                  on: { click: _vm.clearcont },
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.handleFold } },
                [
                  _vm.fold
                    ? _c("span", [_vm._v("展开")])
                    : _c("span", [_vm._v("合并")]),
                  _c("vab-icon", {
                    staticClass: "vab-dropdown",
                    class: { "vab-dropdown-active": _vm.fold },
                    attrs: { icon: "arrow-up-s-line" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }