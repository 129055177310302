<template>
  <div class="comprehensive-table-container">
    <el-button class="addBtn" icon="el-icon-plus" type="primary" @click="handleAdd">新增</el-button>
    <el-table border :data="tableData" style="width: 100%">
      <el-table-column align="center" label="所属租户" prop="tenantId" />
      <el-table-column align="center" label="公司" prop="companyName" :show-overflow-tooltip="true" />
      <el-table-column align="center" label="用户名" prop="username" :show-overflow-tooltip="true" />
      <el-table-column align="center" label="密码" :show-overflow-tooltip="true">
        <template slot="">********</template>
      </el-table-column>
      <el-table-column align="center" label="域名地址	" prop="domainUrl" :show-overflow-tooltip="true" />
      <el-table-column align="center" label="创建时间	" prop="createTime" :show-overflow-tooltip="true" />
      <el-table-column align="center" fixed="right" label="操作" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-link type="primary" :underline="false" @click="eddit(scope.row)">编辑</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="ym_page">
      <el-pagination
        background
        :current-page="1"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="1"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <el-dialog title="新增" :visible.sync="dialogVisible" width="60%">
      <span class="rlfrom">
        <el-form ref="ruleForm" class="demo-ruleForm" label-width="100px" :model="ruleForm" :rules="rules">
          <el-form-item label="公司名称:" prop="companyName">
            <el-input v-model="ruleForm.companyName" placeholder="请输入 公司名称" />
          </el-form-item>
          <el-form-item label="密码	:" prop="password">
            <el-input v-model="ruleForm.password" placeholder="请输入 密码" />
          </el-form-item>
          <el-form-item label="用户名 :" prop="username">
            <el-input v-model="ruleForm.username" placeholder="请输入 用户名" />
          </el-form-item>
          <el-form-item label="域名地址	:" prop="domainUrl">
            <el-input v-model="ruleForm.domainUrl" placeholder="请输入 域名地址" />
          </el-form-item>
          <el-form-item label="是否删除 :" prop="isDelete">
            <el-radio-group v-model="ruleForm.isDelete">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="编辑" :visible.sync="edita" width="60%">
      <span class="rlfrom">
        <el-form ref="editForm" class="demo-ruleForm" label-width="100px" :model="editaForm" :rules="rules">
          <el-form-item label="公司名称:" prop="companyName">
            <el-input v-model="editaForm.companyName" placeholder="请输入 公司名称" />
          </el-form-item>
          <el-form-item label="密码	:" prop="password">
            <el-input v-model="editaForm.password" placeholder="请输入 密码" />
          </el-form-item>
          <el-form-item label="用户名 :" prop="username">
            <el-input v-model="editaForm.username" placeholder="请输入 用户名" />
          </el-form-item>
          <el-form-item label="域名地址	:" prop="domainUrl">
            <el-input v-model="editaForm.domainUrl" placeholder="请输入 域名地址" />
          </el-form-item>
          <el-form-item label="是否删除 :" prop="isDelete">
            <el-radio-group v-model="editaForm.isDelete">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="edita = false">取 消</el-button>
        <el-button type="primary" @click="eit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { queryPage, addColumn, editColumn } from '@/api/mall/setting/call'
  export default {
    data() {
      return {
        tableData: [],
        total: 0,
        size: 10,
        dialogVisible: false,
        edita: false,
        current: 1,
        ruleForm: {
          isDelete: 0,
        },
        editaForm: {
          tenantId: '',
          appId: '',
          companyName:'',
          password:'',
          username:'',
          domainUrl:'',
          isDelete:'',
        },
        rules: {
          companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
          password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
          username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
          domainUrl: [{ required: true, message: '请输入域名地址', trigger: 'blur' }],
        },
      }
    },
    created() {
      this.queryPage(this.size, this.current)
    },
    methods: {
      queryPage(size, cur) {
        const lst = {
          size: size,
          current: cur,
        }
        queryPage({ ...lst }).then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
          this.size = res.data.size
          this.current = res.data.current
        })
      },
      handleCurrentChange(e) {
        this.current = e
        this.queryPage(this.size, this.current)
      },
      handleSizeChange(e) {
        this.size = e
        this.queryPage(this.size, this.current)
      },
      handleAdd() {
        this.dialogVisible = true
      },
      save() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.addColumn()
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      addColumn() {
        addColumn({ ...this.ruleForm }).then(() => {
          this.dialogVisible = false
          this.$message.success('添加成功')
          this.queryPage(this.size, this.current)
        })
      },
      eit() {
        this.$refs.editForm.validate((valid) => {
          if (valid) {
            this.editColumn()
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      eddit(row) {
        // this.editaForm = {}
        this.editaForm = { ...row }
        this.edita = true
      },
      editColumn() {
        const lts = {
          id: this.editaForm.id,
          tenantId: this.editaForm.tenantId,
          companyName:this.editaForm.companyName,
          password:this.editaForm.password,
          username:this.editaForm.username,
          domainUrl:this.editaForm.domainUrl,
          isDelete:this.editaForm.isDelete
          
        }
        editColumn({ ...lts }).then(() => {
          this.edita = false
          this.$message.success('编辑成功')
          this.queryPage(this.size, this.current)
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $gap: 10px;
  .addBtn {
    margin-bottom: $gap;
  }
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #d8d8d8;
  }
  .ym_page {
    display: flex;
    justify-content: center;
  }
  ::v-deep .rlfrom .el-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
</style>
