import request from '@/utils/request'
import { getToken } from '@/utils/token'

export function queryPage(data) {
  return request({
    url: `/mall/user/stpLabel/queryPage`,
    method: 'post',
    data,
  })
}
// 标签
export function queryPages(data) {
  return request({
    url: `/mall/user/stpLabel/queryList`,
    method: 'post',
    data,
  })
}
//预存管理添加
export function merRecordPrestore(obj) {
  return request({
    url: '/mall/merRecordPrestore/add',
    method: 'post',
    data: obj,
  })
}

// 预存款充值列表
export function merRecordPrestoreList(obj) {
  return request({
    url: '/mall/merRecordPrestore/queryTaskMerRecordPrestorePage',
    method: 'post',
    data: obj,
  })
}
export function DoneTaskMerRecordPrestorePage(obj) {
  return request({
    url: '/mall/merRecordPrestore/queryDoneTaskMerRecordPrestorePage',
    method: 'post',
    data: obj,
  })
}

// 预存款充值列表 - 审批
export function getApproveTaskMer(obj) {
  return request({
    url: '/mall/merRecordPrestore/approveTaskMer',
    method: 'post',
    data: obj,
  })
}

// 预存款充值列表 - 拒绝
export function getRejectTaskMer(obj) {
  return request({
    url: '/mall/merRecordPrestore/rejectTaskMer',
    method: 'post',
    data: obj,
  })
}

//预存管理列表
export function merRecordPrestorequeryPage(obj) {
  return request({
    url: '/mall/merRecordPrestore/queryPage',
    method: 'post',
    data: obj,
  })
}
export function queryPageadd(data) {
  return request({
    url: `/mall/user/stpLabel/add`,
    method: 'post',
    data,
  })
}

export function queryPageedit(data) {
  return request({
    url: `/mall/user/stpLabel/edit`,
    method: 'post',
    data,
  })
}

/**
 * 删除信息
 *
 */
export function stpLabelDelete(data) {
  return request({
    url: `/mall/user/stpLabel/delete`,
    method: 'post',
    data,
  })
}
/**
 * 是否显示，1是；0否
 *
 */
export function isEnable(data) {
  return request({
    url: `/mall/user/stpLabel/isEnable`,
    method: 'post',
    data,
  })
}

/**
 * 预存余额
 *
 */
export function statistics(data) {
  return request({
    url: `/mall/merRecordPrestore/statistics`,
    method: 'post',
    data,
  })
}

/**
 * 预存余额状态修改
 *
 */
export function editstatistics(data) {
  return request({
    url: `/mall/merRecordPrestore/edit`,
    method: 'post',
    data,
  })
}

//预存余额精确查询
export function queryPrestoreListstpLabel(data) {
  return request({
    url: `/mall/user/stpLabel/queryPrestoreList`,
    method: 'post',
    data,
  })
}
// 导出
export function Export(data) {
  return request({
    url: 'mall/merRecordPrestore/exportTaskMerRecordPrestorePage',
    headers: {
      'Switch-Tenant-Id': '1',
      Authorization: `Bearer ${getToken()}`,
      // 'Content-Type': 'application/vnd.ms-excel; charset=UTF-8',
    },
    method: 'post',
    data,
    responseType: 'blob',
  })
}
