var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "common-form",
        {
          ref: "commonForm",
          attrs: { options: _vm.searchOptions },
          model: {
            value: _vm.searchForm,
            callback: function ($$v) {
              _vm.searchForm = $$v
            },
            expression: "searchForm",
          },
        },
        [
          _c(
            "template",
            { slot: "operation" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("搜索")]
              ),
              _c("el-button", { on: { click: _vm.reseta } }, [_vm._v("重置")]),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-table",
        { attrs: { border: "", data: _vm.data } },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              type: "index",
              width: "50",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "用户",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "数量",
              prop: "amount",
              "show-overflow-tooltip": "",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "描述",
              prop: "description",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "店铺",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建时间",
              prop: "createTime",
              "show-overflow-tooltip": "",
              sortable: "",
            },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": 1,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }