import request from '@/utils/request'

export function queryPage(data) {
  return request({
    url: `/mall/home/queryOrderBoardPage`,
    method: 'post',
    data,
  })
}


export function selectEmpOrderList(data) {
  return request({
    url: `/mall/home/selectEmpOrderList`,
    method: 'post',
    data,
  })
}

export function selectorder(data) {
  return request({
    url: `/mall/home/selectEmpOrderPage`,
    method: 'post',
    data,
  })
}


export function statisticsOrderCount(data) {
  return request({
    url: `/mall/orderinfo/statisticsOrderCount`,
    method: 'post',
    data,
  })
}