<template>
  <div class="cy-container">
    <!-- 表单头部filter -->
    <div class="filter-container" style="display: flex; flex-wrap: wrap">
      <div class="fl" style="width: 100%; margin-bottom: -10px">
        <el-form ref="refGeneral" :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item label="角色名称">
            <el-input v-model="queryForm.roleName" class="filter-queryInput filter-items" clearable
              placeholder="角色名称" />
          </el-form-item>
          <el-form-item label="角色编码">
            <el-input v-model="queryForm.roleCode" class="filter-queryInput filter-item" clearable placeholder="角色编码" />
          </el-form-item>
          <el-form-item label="角色描述">
            <el-input v-model="queryForm.roleDescription" class="filter-queryInput filter-item" clearable
              placeholder="角色描述" />
          </el-form-item>
          <el-form-item>
            <el-button class="filter-items" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
            <el-button class="filter-items" icon="el-icon-refresh-right" type="warning"
              @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button v-for="item in searchButton" :key="item.id"
          :plain="item.cssType && item.cssType == 'plain' ? true : false" :type="item.buttonCss"
          @click.native="handleClickBtn(item)">
          <span v-if="!item.buttonIcon"></span>
          <vab-icon v-else :icon="item.buttonIcon" />
          {{ item.buttonName }}
        </el-button>

        <template v-for="items in listButtonParent">
          <template v-if="items.buttonType == 1 && items.children">
            <el-button v-for="itemss in items.children" :key="itemss.id" @click.native="handleClickBtn(itemss)">{{
              itemss.buttonName }}</el-button>
          </template>
        </template>
        <!-- <el-button
          class="filter-items"
          icon="el-icon-plus"
          type="primary"
          @click="handleCreate"
        >
          新增
        </el-button>

        <el-dropdown
          class="filter-items"
          split-button
          style="padding-left: 10px; padding-right: 10px"
          trigger="click"
          type="primary"
          @command="handleCommand"
        >
          <i class="el-icon-postcard" style="margin-right: 5px" />
          授权
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="authorizeMenu" icon="el-icon-postcard">
              授权菜单
            </el-dropdown-item>
            <el-dropdown-item command="authorizeButton" icon="el-icon-postcard">
              授权按钮
            </el-dropdown-item>
            <el-dropdown-item command="authorizeUrl" icon="el-icon-postcard">
              授权接口
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button class="filter-items" type="primary" @click="handleDownload">
          <vab-icon icon="file-copy-2-fill" />
          导出
        </el-button>
        <el-button
          class="filter-items"
          icon="el-icon-delete"
          plain
          type="danger"
          @click="handleDelete"
        >
          删除
        </el-button> -->
      </div>
    </div>
    <!-- 表格table -->
    <el-table ref="roletableData" v-loading="listLoading" border :data="roletableData" default-expand-all
      :default-sort="{ prop: 'createTime' }" max-height="600" row-key="id" style="width: 100%; margin-top: 10px"
      @selection-change="handleSelectionChange">
      <el-table-column align="center" show-overflow-tooltip type="selection" width="55" />
      <el-table-column label="角色编码" prop="roleCode" show-overflow-tooltip />
      <el-table-column label="角色名称" prop="roleName" show-overflow-tooltip />
      <el-table-column label="角色描述" prop="roleDescription" show-overflow-tooltip />
      <el-table-column align="center" label="角色状态" prop="isDelete" show-overflow-tooltip width="100px">
        <template #default="{ row }">
          <el-tag v-if="row.isDelete != null" :type="row.isDelete === 1 ? 'danger' : 'success'">
            {{ row.isDelete === 1 ? '删除' : '正常' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="createTime" show-overflow-tooltip sortable width="150px">
        <template #default="{ row }">
          <div>{{ row.createTime | formatDate }}</div>
        </template>
      </el-table-column>

      <el-table-column v-if="operationShow" align="center" label="操作" width="150px">
        <template #default="{ row }">
          <el-button v-for="item in listButton" :key="item.id"
            :plain="item.cssType && item.cssType == 'plain' ? true : false" size="default" :type="item.buttonCss"
            @click.native="handleClickBtn(item, row)">
            <!-- <span v-if="!item.buttonIcon"></span>
            <vab-icon v-else :icon="item.buttonIcon" /> -->
            {{ item.buttonName }}
          </el-button>
          <!-- <el-button
            icon="el-icon-edit-outline"
            style="margin-right: 10px"
            type="text"
            @click="handleCreate(row)"
          >
            编辑
          </el-button>
          <el-button
            icon="el-icon-delete"
            size="small"
            type="text"
            @click="handleDelete(row)"
          >
            删除
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- pagination -->
    <el-pagination background :current-page="forms.current" :layout="layout" :page-size="forms.size" :total="total"
      @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <!-- 授权菜单-->
    <el-dialog v-if="authorizeMenuVisible" :close-on-click-modal="false" title="授权菜单1"
      :visible.sync="authorizeMenuVisible">
      <!-- {{ menuTree }} -->
      <!-- {{ menuKey }} -->
      <el-tree ref="menuTreeRef" v-loading="listLoading" :data="menuTree" :default-checked-keys="menuKey"
        highlight-current node-key="id" :props="defaultProps" show-checkbox />
      <div slot="footer" class="dialog-footer">
        <el-button @click="authorizeMenuVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleMenu">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 授权按钮 -->
    <el-dialog v-if="authorizeBtnVisible" :close-on-click-modal="false" title="授权按钮" :visible.sync="authorizeBtnVisible"
      width="75%">
      <sys-button :showsed="showslist" @datas="datas" @multiple="mulSelection" />

      <div slot="footer" class="dialog-footer">
        <el-button @click="authorizeBtnVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitButton">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 授权接口 -->
    <el-dialog v-if="authorizeUrlVisible" :close-on-click-modal="false" title="授权接口" :visible.sync="authorizeUrlVisible"
      width="65%">
      <sys-url ref="clickdata" :showsed="showslist" @datas="datas" @multiple="mulSelection" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="authorizeUrlVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUrl">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 添加编辑 -->
    <add-role ref="addRole" @fetch-data="fetchData" />
  </div>
</template>
<script>
import { rolePage, rolePageExport, selectMenuTrees, selectRoleMenuId, accreditMenu, selectMenuButton, roleDelete, submitUrl, submitButton } from '@/api/mall/role/roleManage'
import { timestamp } from '@/utils/index'
import AddRole from './components/addRole.vue'
import SysUrl from '../authority/sysurl'
import SysButton from '../admin/sysbutton'
import { selectRoleMenuButtonList } from '@/api/mall/common'
export default {
  components: {
    AddRole,
    SysButton,
    SysUrl,
  },
  data() {
    return {
      clickmenuId: '',
      roleSelectList: this.$dict.roleSelectList,
      buttonTypeList: this.$dict.buttonTypeList,
      actionTypeList: this.$dict.actionTypeList,
      authorizeBtnList: [],
      authorizeBtn: {
        menuId: '',
      },
      showslist: {
        shows: '1',
        multipleSelection: [],
      },
      menuTreeBtn: [],
      forms: { current: 1, size: 10 },
      isShow: true,
      queryForm: {
        roleName: '',
        roleCode: '',
        roleDescription: '',
      },
      menuTree: [],
      menuKey: [],
      defaultProps: { label: 'menuName', children: 'children' },
      multipleSelection: [],
      searchShow: false,
      addVisible: false,
      authorizeMenuVisible: false,
      authorizeBtnVisible: false,
      authorizeUrlVisible: false,
      menuTableData: [],
      lookMenuTableData: [
        {
          id: 1,
          orgName: '首页',
          children: [{ id: 2, orgName: '工作台' }],
        },
        {
          id: 3,
          orgName: '权限管理',
          children: [{ id: 4, orgName: '菜单管理' }],
        },
      ],
      tableDataUrlList: [],
      authorizeDescM: {},
      menuNameM: '',
      authorizeUrlM: '',
      leftIcon: true,
      rightIcon: false,
      dialogTitle: '',
      listLoading: false,
      current: 1,
      size: 10,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      roletableData: [],
      urlSelList: [],
      checkedUrlList: [],
      arrRepeat: [],
      searchButton: [],
      listButton: [],
      listButtonParent: [],
      operationShow: false,
    }
  },
  created() {
    this.fetchData()
    this.selectRoleMenuButtonList()
  },
  methods: {
    // 按钮点击
    handleClickBtn(item, row) {
      if (item.buttonUrl) {
        this[item.buttonUrl](row)
      }
    },
    // 按钮权限
    selectRoleMenuButtonList() {
      selectRoleMenuButtonList({
        roleIdStrList: 1, // this.$store.getters['acl/roleList'],
        menuId: this.$route.meta.id,
      })
        .then((res) => {
          if (res.data) {
            this.searchButton = res.data.filter(function (item) {
              if (item.children == null) {
                return item.buttonType == 1
              }
            })
            this.listButton = res.data.filter(function (item) {
              return item.buttonType == 2
            })
            if (this.listButton.length > 0) {
              this.operationShow = true
            } else {
              this.operationShow = false
            }
            let finds = res.data.filter(function (item) {
              return item.buttonType == 1 && item.children
            })
            this.listButtonParent = finds
          }
        })
        .catch(() => { })
    },
    // 授权菜单
    authorizeMenu() {
      if (this.multipleSelection.length === 0) {
        this.ME('请勾选记录')
        return
      }
      if (this.multipleSelection.length > 1) {
        this.ME('只能勾选一条数据')
        return
      }
      this.selectMenuTree()
      this.authorizeMenuVisible = true
    },
    // 授权按钮
    authorizeButton() {
      if (this.multipleSelection.length === 0) {
        this.ME('请勾选记录')
        return
      }
      if (this.multipleSelection.length > 1) {
        this.ME('只能勾选一条数据')
        return
      }
      this.authorizeBtnVisible = true
    },
    // 授权接口
    authorizeUrl() {
      if (this.multipleSelection.length === 0) {
        this.ME('请勾选记录')
        return
      }
      if (this.multipleSelection.length > 1) {
        this.ME('只能勾选一条数据')
        return
      }
      this.authorizeUrlVisible = true
    },
    // handleCommand(command) {
    //   if (this.multipleSelection.length === 0) {
    //     this.ME('请勾选记录')
    //     return
    //   }
    //   if (this.multipleSelection.length > 1) {
    //     this.ME('只能勾选一条数据')
    //     return
    //   }
    //   if (command === 'authorizeMenu') {
    //     this.selectMenuTree()
    //     this.authorizeMenuVisible = true
    //   } else if (command === 'authorizeButton') {
    //     this.authorizeBtnVisible = true
    //   } else if (command === 'authorizeUrl') {
    //     this.authorizeUrlVisible = true
    //   }
    // },
    datas(menuId) {
      this.clickmenuId = menuId
    },
    // 点击菜单查询
    // clickAsideData(row) {
    //   this.authorizeBtn.menuId = row.id
    //   selectMenuButton({ menuId: row.id })
    //     .then((res) => {
    //       if (res.data) {
    //         console.log(res)
    //         this.authorizeBtnList = res.data.records
    //         // this.$refs.menuTreeRefBtn.setCheckedKeys(res.data)
    //       }
    //     })
    //     .catch(() => {})
    // },

    // 时间格式化
    dateFormat(row, colum, cellValue) {
      return timestamp(cellValue)
    },
    // 角色列表读取数据
    async fetchData(options) {
      this.listLoading = true
      await rolePage({
        ...options,
        ...this.forms,
      })
        .then((res) => {
          if (res.data) {
            this.roletableData = res.data.records
            this.total = res.data.total
          }
        })
        .catch(() => { })
      this.listLoading = false
    },
    // 授权菜单读取数据
    selectMenuTree() {
      this.listLoading = true
      selectMenuTrees()
        .then((res) => {
          if (res.data) {
            this.menuTree = res.data
            // console.log('==menuTree==')
            // console.log(this.menuTree)
            this.menuTableData = res.data
          }
          this.$nextTick(() => {
            selectRoleMenuId({ roleId: this.multipleSelection[0].id })
              .then((res) => {
                if (res.data) {
                  // console.log(res.data)
                  this.menuKey = res.data
                  this.$refs.menuTreeRef.setCheckedKeys(res.data)
                  const arr = []
                  res.data.forEach((item) => {
                    if (!this.$refs.menuTreeRef.getNode(item).childNodes || !this.$refs.menuTreeRef.getNode(item).childNodes.length) {
                      arr.push(item)
                    }
                    //  arr.push(item)
                  })
                  this.menuKey = arr
                  this.$nextTick(() => {
                    this.$refs.menuTreeRef.setCheckedKeys(arr)
                  })
                }
              })
              .catch(() => {
                this.listLoading = false
              })
          })
        })
        .catch(() => {
          this.listLoading = false
        })

      this.listLoading = false
    },

    // 授权菜单保存
    handleMenu() {
      const menuIdList = this.$refs.menuTreeRef.getCheckedKeys().concat(this.$refs.menuTreeRef.getHalfCheckedKeys())
      accreditMenu({
        roleId: this.multipleSelection[0].id,
        menuIdList: menuIdList,
      })
        .then(() => {
          this.MS('授权菜单成功')
          this.authorizeMenuVisible = false
        })
        .catch(() => { })
    },
    // 授权按钮保存
    submitButton() {
      if (this.urlSelList.length === 0) return this.ME('请勾选记录')
      submitButton({
        roleId: this.multipleSelection[0].id,
        buttonIdList: this.urlSelList,
      })
        .then(() => {
          this.MS('授权成功')
          this.authorizeBtnVisible = false
        })
        .catch(() => { })
    },
    // 授权接口保存
    submitUrl() {
      if (this.urlSelList.length === 0) return this.ME('请勾选记录')
      // selectRoleUrlId({
      //   roleId: this.multipleSelection[0].id,
      // })
      //   .then((res) => {
      //     if (res.data) {
      //       this.checkedUrlList = res.data
      //       console.log(this.checkedUrlList, '初始化接口获取全部选中的')
      //       console.log(this.urlSelList, '搜索选中的')
      //       // 合并
      //       let arrALL = [...this.checkedUrlList, ...this.urlSelList]
      //       this.arrRepeat = new Set(arrALL)
      //       this.arrRepeat = [...this.arrRepeat]
      //       console.log(this.arrRepeat)
      //       // this.orgTableDataList.forEach((row) => {
      //       //   this.$nextTick(function () {
      //       //     const checkedUrlList = this.checkedUrlList.map(String)
      //       //     if (checkedUrlList.includes(row.id.toString()) == true) {
      //       //       this.$refs.orgTableDataList.toggleRowSelection(row)
      //       //     }
      //       //   })
      //       // })
      //     }
      //   })
      //   .catch(() => {})
      submitUrl({
        roleId: this.multipleSelection[0].id,
        urlIdList: this.urlSelList,
        menuId: this.clickmenuId,
      })
        .then(() => {
          this.MS('授权成功')
          this.authorizeUrlVisible = false
        })
        .catch(() => { })
    },
    mulSelection(val) {
      this.urlSelList = val.map((item) => {
        return item.id
      })
    },
    // 授权按钮点击
    handleClick(data) {
      // 授权按钮（查询菜单code对应的全部按钮）
      selectMenuButton({ menuCode: data.menuCode })
        .then((res) => {
          if (res.data) {
            console.log(res)
          }
        })
        .catch(() => { })
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate)
    },
    // 查询
    handleFilter() {
      this.isShow = !this.isShow
      this.forms.current = 1
      this.fetchData(this.queryForm)
    },

    // 新增/编辑
    handleCreate(row) {
      if (row) {
        this.$refs.addRole.showEdit(row)
      } else {
        this.$refs.addRole.showEdit()
      }
    },

    // 删除
    handleDelete(row) {
      if (row) {
        if (row.id) {
          this.$confirm('是否要删除此行?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              roleDelete({
                id: row.id,
              })
                .then(() => {
                  this.MS('删除成功')
                  this.fetchData()
                })
                .catch(() => { })
            })
            .catch(() => { })
        }
      } else {
        if (this.multipleSelection.length === 0) {
          this.ME('请勾选记录')
          return
        }
        if (this.multipleSelection.length > 1) {
          this.ME('只能勾选一条数据')
          return
        }
        const ids = this.multipleSelection.map((item) => item.id)
        this.$confirm('此操作将删除选中的角色，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            roleDelete({
              id: ids.toString(),
            })
              .then(() => {
                this.MS('删除成功')
                this.fetchData()
              })
              .catch(() => { })
          })
          .catch(() => { })
      }
    },

    handleLeftIcon() {
      this.$refs.asideRef.style.width = '10px'
      this.$refs.asideRef.style.minWidth = '10px'
      this.$refs.contantRef.style.width = '98%'
      this.leftIcon = false
      this.rightIcon = true
    },
    handleRightIcon() {
      this.$refs.asideRef.style.width = '160px'
      this.$refs.asideRef.style.minWidth = '160px'
      this.$refs.contantRef.style.width = '82.5%'
      this.leftIcon = true
      this.rightIcon = false
    },
    // 导出
    handleDownload() {
      console.log(rolePageExport)
      // const params = {}
      // rolePageExport(params)
      //   .then((res) => {
      //     const url = window.URL.createObjectURL(new Blob([res]))
      //     const link = document.createElement('a')
      //     link.setAttribute('href', url)
      //     link.setAttribute('download', new Date().getTime() + '.xlsx')
      //     link.click()
      //   })
      //   .catch(() => {})
    },

    // 表格操作
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.showslist.multipleSelection = val
    },

    // 重置
    handleReset() {
      this.forms.current = 1
      this.forms.size = 10
      this.queryForm = {}
      this.handleQuery()
    },
    // 搜索
    async handleQuery() {
      this.forms.current = 1
      await this.fetchData({ ...this.queryForm }, false)
      let temp = false
      Object.keys(this.queryForm).forEach((key) => {
        this.queryForm[key] && (temp = true)
      })
      this.isShow = temp
    },
    // 分页
    handleSizeChange(val) {
      this.forms.size = val
      let data = this.isShow === true ? this.queryForm : {}
      this.fetchData({ ...data }, false)
    },
    // 分页
    handleCurrentChange(val) {
      this.forms.current = val
      let data = this.isShow === true ? this.queryForm : {}
      this.fetchData({ ...data }, false)
    },
  },
}
</script>
<style lang="scss" scoped>
.filter-container {
  padding-bottom: 10px;
  overflow: hidden;

  .filter-queryInput {
    float: left;
    width: 180px;
    margin-right: 10px;
  }

  .filter-btn {
    float: right;
  }
}

::v-deep(.el-dialog__body) {
  padding: 10px 20px;
}

.asideAuthorizeUrl {
  width: 160px;
  height: 525px;
  overflow: scroll;
  border: 1px solid #c0d4f0;

  ::v-deep(.el-table) {
    th.is-leaf {
      font-weight: 700 !important;
      background: #fff !important;
    }

    ::v-deep td,
    td.el-table__cell {
      // border: none;
      cursor: pointer;
    }
  }

  .authorizeBtn {
    .el-form-item {
      width: 22%;
    }
  }

  .el-input {
    width: 130px;
    padding: 10px;
  }

  ::v-deep .aside-table td,
  .aside-table th.is-leaf {
    // border: none;
    cursor: pointer;
  }

  ::v-deep(.aside-table th) {
    .cell {
      font-weight: 700 !important;
      color: #909399 !important;
    }
  }

  .aside-table::before {
    width: 0;
  }
}

.clickLine {
  width: 8px;
  height: 525px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: #c0d4f0;

  .clickLineLeft {
    position: relative;
    top: 50%;
    left: -3px;
    color: #97a8be;
    cursor: pointer;
  }

  .clickLineRight {
    position: relative;
    top: 50%;
    left: -5px;
    color: #97a8be;
    cursor: pointer;
  }
}

.urlListTable {
  width: 100%;
  height: 480px;
}

.filter-input {
  width: 150px;
}

.contantAuthorizeUrl {
  width: 88%;
  padding: 0px 10px;
}

.userAllocation {
  .transfer-title {
    margin-top: 0px;
  }
}
</style>
