<template>
  <div class="cy-container">
    <el-button icon="el-icon-plus" type="primary" @click="addnew=true">新增</el-button>
    <el-tabs v-model="form.routeType"  @tab-click="handleClick">
      <el-tab-pane v-for="(ym,index) in tag" :key="index" :label="ym.dictValue" :name="ym.dictKey"/>
    </el-tabs>
    <el-table
      border
      :data="tableData"
      style="width: 100%">
      <el-table-column
      align="center"
      label="编号"
      type="index"
      width="50"/>
      <el-table-column align="center" label="标题" prop="routName" width="180"/>
      <el-table-column align="center" label="图片" prop="imageAddr" width="180">
        <template #default="scope">
          <el-image :src="scope.row.imageAddr" style="width: 80px;height: 80px;"/>
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否开启" prop="routStatus">
        <template #default="scope">
          <el-tag v-if="scope.row.routStatus==1" type="success">开启</el-tag>
          <el-tag v-else type="danger">关闭</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="路由" prop="routAddr" width="180"/>
      <el-table-column align="center" label="排序" prop="routSort"/>
      <el-table-column align="center" label="备注" prop="routeMaker" width="180"/>
      <el-table-column align="center" label="创建时间" prop="createTime"  width="180"/>
      <el-table-column
        align="center"
        label="操作">
        <template #default="scope">
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="editro(scope.row)">编辑</el-link>
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="del(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"/>
      <el-dialog
        :before-close="handleClose"
        title="编辑"
        :visible.sync="dialogVisible"
        width="40%">
        <span>
          <el-form ref="formEdit" label-width="80px" :model="editrow" :rules="rules">
            <el-form-item label="标题：" prop="routName">
              <el-input v-model="editrow.routName" placeholder="请输入 标题	" style="width: 200px;"/>
            </el-form-item>
            <el-form-item label="所属类别：" prop="routeType">
              <el-select v-model="editrow.routeType" placeholder="请选择 所属类别" style="width: 200px;">
                <el-option
                  v-for="item in tag"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"/>
              </el-select>
            </el-form-item>
            <el-form-item label="是否开启">
              <el-radio-group v-model="editrow.routStatus">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="2">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="图片：">
              <el-upload
                ref="upload"
                :action="action"
                :before-upload="bfUpload"
                :class="{
                  hide: hideUpload,
                }"
                :file-list="fileList"
                :headers="headers"
                :limit="1"
                list-type="picture-card"
                :on-change="handleChange"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleSucces"
              >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog append-to-body :visible.sync="dialogVisibleshow">
            <img alt="" :src="dialogImageUrl" width="100%" />
          </el-dialog>
            </el-form-item>
            <el-form-item label="路由：" prop="routAddr">
              <el-input v-model="editrow.routAddr" placeholder="请输入 路由" style="width: 200px;"/>
            </el-form-item>
            <el-form-item label="排序：" prop="routSort">
              <el-input-number v-model="editrow.routSort" placeholder="请输入 排序" style="width: 200px;"/>
            </el-form-item>
            <el-form-item label="备注：" prop="routeMaker">
              <el-input v-model="editrow.routeMaker" placeholder="请输入 备注" style="width: 200px;"/>
            </el-form-item>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="edisave">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :before-close="handleCloseadd"
        title="新增"
        :visible.sync="addnew"
        width="40%">
        <span>
          <el-form ref="formAddlist" label-width="120px" :model="newrows" :rules="rsult">
            <el-form-item label="标题：" prop="routName">
              <el-input v-model="newrows.routName" placeholder="请输入 标题	" style="width: 200px;"/>
            </el-form-item>
            <el-form-item label="所属类别：" prop="routeType">
              <el-select v-model="newrows.routeType" placeholder="请选择 所属类别" style="width: 200px;">
                <el-option
                  v-for="item in tag"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"/>
              </el-select>
            </el-form-item>
            <el-form-item label="是否开启">
              <el-radio-group v-model="newrows.routStatus">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="2">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="图片：">
              <el-upload
                ref="upload"
                :action="action"
                :before-upload="bfUpload"
                :class="{
                  hide: hideUpload,
                }"
                :file-list="fileList"
                :headers="headers"
                :limit="1"
                list-type="picture-card"
                :on-change="handleChange"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
              >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog append-to-body :visible.sync="dialogVisibleshow">
            <img alt="" :src="dialogImageUrl" width="100%" />
          </el-dialog>
            </el-form-item>
            <el-form-item label="路由：" prop="routAddr">
              <el-input v-model="newrows.routAddr" placeholder="请输入 路由" style="width: 200px;"/>
            </el-form-item>
            <el-form-item label="排序：" prop="routSort">
              <el-input-number v-model="newrows.routSort" placeholder="请输入 排序" style="width: 200px;"/>
            </el-form-item>
            <el-form-item label="备注：" prop="routeMaker">
              <el-input v-model="newrows.routeMaker" placeholder="请输入 备注" style="width: 200px;"/>
            </el-form-item>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clears">取 消</el-button>
          <el-button type="primary" @click="addsave">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { uploadURL } from '@/config'
import {queryPage,sysRouteadd,sysRouteedit,sysRoutedel} from "@/api/mall/management/index"
import {getPage} from "@/api/mall/admin/sysstting"
export default {
  data(){
    return {
      action: uploadURL,
      hideUpload: false,
      fileList: [],
      headers:{},
      dialogImageUrl:"",
      dialogVisibleshow:false,
      addForm:{headers:''},
      tag:[],
      tableData: [],
      total:0,
      dialogVisible:false,
      addnew:false,
      form:{
        current:1,
        size:10,
        routeType:'2'
      },
      editrow:{},
      rules:{
        title:[
          { required: true, message: '请输入 标题', trigger: 'blur' },
        ],
        modelType:[
            { required: true, message: '请选择 类别', trigger: 'blur' },
        ],
      },
      rsult:{
        routName:[
          { required: true, message: '请输入 标题', trigger: 'blur' },
        ],
        routeType:[
            { required: true, message: '请选择 类别', trigger: 'blur' },
        ],
        routAddr:[
            { required: true, message: '请输入 内容', trigger: 'blur' },
        ],
        routSort:[
            { required: true, message: '请输入 排序', trigger: 'blur' },
        ],
      },
      newrows:{
        routStatus:1
      }
    }
  },
  computed: {
      ...mapGetters({
        token: 'user/token',
        tenantId : 'user/tenantId',
      }),
    },
  created(){
    this.headers['Authorization'] = `Bearer ${this.token}`
    this.headers['Switch-Tenant-Id'] = `${this.tenantId}`
    this.getPage()
    this.stpPlatformProtocol()
  },
  methods:{
    bfUpload(file) {
      if (
        'image/png' == file.type ||
        'image/jpeg' == file.type ||
        'image/jpg' == file.type
      ) {
        this.addForm.headers = this.headers
      } else {
        this.ME('图片上传失败,请上传png,jpeg,jpg格式')
        return false
      }
    },
    handleChange(file, fileList) {
      this.hideUpload = fileList.length >= this.limitCount
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisibleshow = true
    },
    handleRemove(fileList) {
      this.fileList = []
      this.addForm.ctgHead = ''
      this.hideUpload = fileList.length >= this.limitCount
    },
    handleSuccess(response) {
      this.newrows.imageAddr=response.data.filePath
      this.fileList.push({
        url: response.data.filePath,
      })
    },
    handleSucces(response){
      this.editrow.imageAddr=response.data.filePath
      this.fileList.push({
        url: response.data.filePath,
      })
    },
    stpPlatformProtocol(){
      queryPage({...this.form}).then(res=>{
        this.tableData=res.data.records
        this.form.size=res.data.size
        this.form.current=res.data.current
        this.total=res.data.total
        console.log(res.data)
      })
    },
    getPage(){
      getPage({parentId:'838'}).then(res=>{
        this.tag=res.data.records
        console.log(res.data.records)
      })
    },
    handleCurrentChange(e){
      this.form.current=e
      this.stpPlatformProtocol()
    },
    handleSizeChange(e){
      this.form.size=e
      this.stpPlatformProtocol()
    },
    handleClick(){
      this.stpPlatformProtocol()
    },
    handleClose(){
      this.dialogVisible=false
    },
    handleCloseadd(){
      this.addnew=false
    },
    editro(row){
      console.log(row)
      this.dialogVisible=true
      this.editrow=row
      this.fileList=[]
      this.fileList.push({url:row.imageAddr})
    },
    edisave(){
      this.$refs.formEdit.validate((valid) => {
          if (valid) {
           this.putpage()
          } else {
            return false;
          }
        });
    },
    putpage(){
      sysRouteedit({...this.editrow}).then(()=>{
        this.$message.success("编辑成功")
        this.fileList=[]
        this.dialogVisible=false
        this.stpPlatformProtocol()
      })
    },
    clears(){
      this.addnew=false
      this.newrows={}
      this.fileList=[]
    },
    addsave(){
      console.log(this.newrows)
      this.$refs.formAddlist.validate((valid) => {
        if (valid) {
         this.addpages()
        } else {
          return false;
        }
      });
    },
    addpages(){
      sysRouteadd({...this.newrows}).then(()=>{
        this.$message.success("新增成功")
        this.addnew=false
        this.fileList=[]
        this.newrows={}
        this.stpPlatformProtocol()
      })
    },
    del(userId){
      sysRoutedel({id:userId}).then(()=>{
        this.$message.success("删除成功")
        this.stpPlatformProtocol()
      })
    },
    descr(row){
      this.fileList=[]
      this.newrows.content=row
    },
    eidttab(row){
      this.fileList=[]
      this.editrow.content=row
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header{
  border-bottom: 1px solid #e8e8e8;
}
</style>