<template>
  <div class="OrderDetail cy-container">
    <el-row
      style="
        display: flex;
        align-items: center;
        background: #f0f0f0;
        padding: 20px;
      "
    >
      <el-col class="fs16">主题详情</el-col>
    </el-row>
    <div class="refund">
      <!-- <div class="refundTitel mt20 mb20">主题设置</div> -->
      <el-row class="mt30">
        <el-col :span="12">
          <span class="themeTitel">活动主题：</span>
          <span>{{ details.actName }}</span>
        </el-col>
        <el-col :span="12">
          <span class="themeTitel">主题时间：</span>
          <span>
            {{ details.startTime | formatDate }}-{{
              details.endTime | formatDate
            }}
          </span>
        </el-col>
      </el-row>
      <el-row class="mt30">
        <el-col :span="12">
          <span class="themeTitel">发布时间：</span>
          <span v-if="details.publishTime">
            {{ details.publishTime | formatDate }}
          </span>
          <span v-else>{{ details.updateTime | formatDate }}</span>
        </el-col>
        <el-col :span="12">
          <span class="themeTitel">参与平台：</span>

          <span v-if="details.actPlatform">
            <span
              v-for="(item, index) in details.actPlatform.split(',')"
              :key="index"
            >
              {{ formatterActPlatform(item) + ',' }}
            </span>
          </span>
          <span></span>
        </el-col>
      </el-row>
      <el-row class="mt30">
        <el-col :span="12" style="display: flex">
          <div class="themeTitel">主题封面：</div>

          <img
            alt=""
            :src="details.coverPicture"
            width="100px"
            @click="handleImgClick(details.coverPicture)"
          />
        </el-col>
        <el-col :span="12" style="display: flex">
          <div class="themeTitel">主题背景：</div>
          <img
            alt=""
            :src="details.backgPicture"
            width="100px"
            @click="handleImgClick(details.backgPicture)"
          />
        </el-col>
      </el-row>
      <el-row class="mt30">
        <el-col class="mb20">
          <span class="themeTitel">活动列表：</span>
        </el-col>
        <el-table border :data="actList" default-expand-all>
          <el-table-column
            align="center"
            :formatter="formatterActType"
            label="活动类型"
            prop="actType"
            show-overflow-tooltip
          />
          <el-table-column
            align="center"
            label="实例名称"
            prop="instName"
            show-overflow-tooltip
          />
          <el-table-column
            align="center"
            label="实例封面"
            prop="instLogo"
            show-overflow-tooltip
          >
            <template #default="{ row }">
              <div>
                <img
                  alt=""
                  :src="row.instLogo"
                  width="50px"
                  @click="handleImgClick(row.instLogo)"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="活动时间"
            prop="startDate"
            show-overflow-tooltip
          >
            <template #default="{ row }">
              <div>
                <div>开始：{{ row.startTime | formatDate }}</div>
                <div>结束：{{ row.endTime | formatDate }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="发布时间"
            prop="publishTime"
            show-overflow-tooltip
          >
            <template #default="{ row }">
              <div v-if="row.publishTime">
                {{ row.publishTime | formatDate }}
              </div>
              <div v-else>{{ row.updateTime | formatDate }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            :formatter="formatterActState"
            label="状态"
            prop="instStatus"
            show-overflow-tooltip
          />
          <el-table-column
            align="center"
            label="排序"
            prop="instSort"
            show-overflow-tooltip
          />
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          :current-page="forms.currentPage"
          :layout="layout"
          :page-size="forms.pageSize"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-row>
    </div>
    <!-- 图片预览 -->
    <el-dialog :visible.sync="imgDialog">
      <img :src="imagesUrl" width="100%" />
    </el-dialog>
  </div>
</template>
<script>
  import { details, detailsActList } from '@/api/activity/theme'
  import { selectSysDict } from '@/api/mall/common'
  export default {
    components: {},
    data() {
      return {
        imgDialog: false,
        imagesUrl: '',
        actList: [],
        details: {},
        actTypeList: [],
        actPlatformLsit: [],
        forms: { currentPage: 1, pageSize: 10 },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
      }
    },
    created() {
      this.selectSysDict()
      // 详情
      this.fetchData()
      this.detailsd()
    },
    methods: {
      // 图片预览
      handleImgClick(url) {
        this.imagesUrl = url
        this.imgDialog = true
      },
      // 参与平台
      formatterActPlatform(row) {
        for (let i = 0; i < this.actPlatformLsit.length; i++) {
          if (this.actPlatformLsit[i].dictKey == row) {
            return this.actPlatformLsit[i].dictValue
          }
        }
      },
      // 活动类型
      formatterActType(row) {
        for (let i = 0; i < this.actTypeList.length; i++) {
          if (this.actTypeList[i].dictKey == row.actType) {
            return this.actTypeList[i].dictValue
          }
        }
      },
      // 活动状态
      formatterActState(row) {
        for (let i = 0; i < this.actStateList.length; i++) {
          if (this.actStateList[i].dictKey == row.instStatus) {
            return this.actStateList[i].dictValue
          }
        }
      },

      // 字典
      async selectSysDict() {
        await selectSysDict({
          dictTypeList: ['actType', 'actPlatform', 'instanceStatus'],
        })
          .then((res) => {
            if (res.data) {
              this.actTypeList = res.data[0].dictInfoVoList
              this.actPlatformLsit = res.data[1].dictInfoVoList
              this.actStateList = res.data[2].dictInfoVoList
            }
          })
          .catch(() => {})
      },

      // 详情
      async detailsd() {
        await details({
          id: this.$route.query.id,
        })
          .then((res) => {
            if (res.data) {
              this.details = res.data
            }
          })
          .catch(() => {})
      },
      // 详情(活动列表)
      async fetchData() {
        await detailsActList({
          themeId: this.$route.query.id,
          ...this.forms,
        }).then((res) => {
          if (res.data) {
            this.actList = res.data.records
            this.total = res.data.total
          }
        })
      },

      // 分页
      handleSizeChange(val) {
        this.forms.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.forms.currentPage = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .OrderDetail {
    padding: 20px;
    color: #000;
    .refund {
      .refundTitel {
        margin-top: 30px;
        font-weight: 700;
        padding-left: 5px;
        border-left: 4px solid rgb(0, 122, 255);
      }
    }
    .themeTitel {
      color: #9f9f9f;
    }
  }
</style>
