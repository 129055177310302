<template>
  <div class="container">
    <div class="list">
      <div class="info_listuser">
        <div class="lef">基本信息</div>
        <div class="ref" @click="ediinfo">
          <vab-icon icon="edit-2-line" style="font-size: 20px" />
        </div>
      </div>
      <div class="content">
        <el-descriptions title="">
          <el-descriptions-item label="姓名">{{ value.userName }}</el-descriptions-item>
          <el-descriptions-item label="性别">{{ value.userSex }}</el-descriptions-item>
          <el-descriptions-item label="出生日期">{{ value.userYear }}</el-descriptions-item>
          <el-descriptions-item label="年龄">{{ value.age }}</el-descriptions-item>
          <el-descriptions-item label="手机号码">{{ value.userPhone }}</el-descriptions-item>
          <el-descriptions-item label="身高（cm）">{{ value.userHeight }}</el-descriptions-item>
          <el-descriptions-item label="体重（KG）">{{ value.userWeight }}</el-descriptions-item>
          <el-descriptions-item label="BMI">{{ value.bmi }}</el-descriptions-item>
          <el-descriptions-item label="臀围（cm）">{{ value.circumference }}</el-descriptions-item>
          <el-descriptions-item label="腰围（cm）">{{ value.waistline }}</el-descriptions-item>
          <!-- <el-descriptions-item label="责任医生">江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item> -->
          <el-descriptions-item label="会员标签">
            <!-- 待定 -->
            <!-- <el-tag v-if="value.tags">{{ ym.tagName }}</el-tag> -->
          
            <el-tag v-for="(ym, index) in value.tags" :key="index">
  {{ ym && ym.tagName ? ym.tagName : '' }}
</el-tag>
         
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="list">
      <div class="info_listuser">
        <div class="lef">详细信息</div>
        <div class="ref" @click="editdatil">
          <vab-icon icon="edit-2-line" style="font-size: 20px" />
        </div>
      </div>
      <div class="content">
        <el-descriptions title="">
          <el-descriptions-item label="身份证号码">{{ value.idCard }}</el-descriptions-item>
          <el-descriptions-item label="民族">{{ value.familyName }}</el-descriptions-item>
          <el-descriptions-item label="血型">{{ getBloodType(value.bloodType) }}</el-descriptions-item>
          <el-descriptions-item label="职业">{{ value.profession }}</el-descriptions-item>
          <el-descriptions-item label="婚姻状况">{{getMar( value.marStatus) }}</el-descriptions-item>
          <el-descriptions-item label="电子邮件">{{ value.email }}</el-descriptions-item>
          <el-descriptions-item label="紧急联系人">{{ value.emerName }}</el-descriptions-item>
          <el-descriptions-item label="紧急联系人电话">{{ value.emerPhone }}</el-descriptions-item>
          <el-descriptions-item label="工作单位">{{ value.workUnit }}</el-descriptions-item>
          <el-descriptions-item label="所在地区">{{ value.province }}{{ value.city }}{{ value.county }}</el-descriptions-item>
          <el-descriptions-item label="详细地址">{{ value.addressDetail }}</el-descriptions-item>
         
          <el-descriptions-item label="备注">
            {{ value.remark }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <editeditiing :show="editeditiingshow" :userlist="userinfo" @cloeedit="editinit" />
    <editDetails :show="editdetailshow" :userlist="userinfo" @cloeeditdia="editdatilsclose" />
  </div>
</template>

<script>
import editeditiing from './components/editediting.vue'
import editDetails from './components/editDetails.vue'
export default {
  components: {
    editeditiing,
    editDetails,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editeditiingshow: false,
      editinits: null,
      editdetailshow: false,
      userinfo: this.value,
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.userinfo = newValue
      }
    },
  },
  methods: {
    ediinfo() {
      // this.editinits = row
      this.editeditiingshow = true
    },
    editdatil() {
      this.editdetailshow = true
    },
    editinit() {
      this.editeditiingshow = false
    },
    editdatilsclose() {
      this.editdetailshow = false
    },
    getBloodType(type) {
    switch (type) {
      case 1:
        return 'A型';
      case 2:
        return 'B型';
      case 3:
        return 'O型';
      case 4:
        return 'AB型';
      case 5:
        return 'RH型';
      default:
        return '';
    }
  },
  getMar(type){
    switch (type) {
      case 1:
        return '已婚';
        case 2 :
          return '未婚';
          default:
        return '';
      }
  }
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .info_listuser {
    width: 100%;
    display: flex;
    background-color: rgba(0, 122, 255, 0.02);
    height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .lef {
      font-weight: bold;
      color: #333;
    }
    .ref {
      color: #1780ff;
    }
  }
  .content {
    width: 90%;
    margin: 0 auto;
    padding: 30px 0;
  }
  ::v-deep {
    .el-descriptions-row {
      height: 50px;
      font-size: 14px;
    }
    .el-descriptions-item__content {
      color: black;
    }
    .el-descriptions-item__label {
      display: inline-block;
      width: 110px;
      text-align: right;
      color: gray;
    }
  }
}
</style>