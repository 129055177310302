<template>
  <div class="comprehensive-table-container">
    <div class="search">
      <!-- <el-form ref="ruleForm" :inline="true" :model="from">
        <el-form-item label="模板类型">
          <el-select v-model="from.type" placeholder="请选择 模板类型">
            <el-option v-for="(ym, index) in typeoptions" :key="index" :label="ym.dictValue" :value="ym.dictKey" />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button icon="el-icon-search" type="primary" @click="search">查询</el-button>
          <el-button icon="el-icon-refresh" type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form> -->
    </div>
    <div class="content">
      <div class="temp">
        <!-- <div  class="add_btn">
            <el-button icon="el-icon-plus" type="primary" @click="navTo(false)">增加模板</el-button>
          </div>s -->
        <el-row class="container-tem" :gutter="10">
          <el-col v-for="(ym, index) in template" :key="index" class="bg-purple" :lg="4" :md="4" :sm="1" :span="6" :xl="5">
            <div class="bg_headerstatus">
              <el-tag v-if="ym.diyStatus == 1" type="success">已发布</el-tag>
              <el-tag v-else type="error">未发布</el-tag>
              <div class="template-picUrl">
                <img v-if="ym.terminal == 1" alt="" src="../../../../assets/img/1.png" />
                <img v-if="ym.terminal == 2" alt="" src="../../../../assets/img/2.png" />
                <img v-if="ym.terminal == 3" alt="" src="../../../../assets/img/3.png" />
                <!-- <img v-if="ym.type == 4" alt="" src="../../../../assets/img/4.png" /> -->
              </div>
            </div>
            <div class="mov">
              <div class="bg_tempale_url">
                <img :src="ym.diyImg" />
              </div>
              <div class="hvoer">
                <div class="item" @click="navTo(ym)">去装修</div>
              </div>
              <div class="ym_setting">
                <el-dropdown trigger="click">
                  <el-button type="">
                    更多操作
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item style="width: 180px; text-align: center"><el-link :underline="false" @click="submitTab(ym)">发布</el-link></el-dropdown-item>
                    <!-- <el-dropdown-item style="width: 180px; text-align: center"><el-link :underline="false" @click="copRow(ym)">复制</el-link></el-dropdown-item> -->
                    <el-dropdown-item style="width: 180px; text-align: center"><el-link :underline="false" @click="del(ym)">删除</el-link></el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="ym_flex">
              <div class="ym_lef">
                <span class="templateName">{{ ym.name }}</span>
                <span class="updateTiemr">更新时间:{{ ym.updateTime }}</span>
                <span class="updateTiemr">到期时间:</span>
              </div>
              <div class="ref_code">
                <img alt="" src="" />
              </div>
            </div>
          </el-col>
        </el-row>
        <el-pagination
          background
          :current-page="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { queryDiyCanvasPage, themeDiyCanvasdeleteDiyCanvas, themeDiyCanvaspushDiyCanvas } from '@/api/mall/shop/templateset'
import { selectSysDict } from '@/api/mall/common'

export default {
  data() {
    return {
      template: [{}],
      from: {
        size: 10,
        current: 1,
        type: "1"
      },
      total: null,
      typeoptions: [],
    }
  },
  created() {
    this.queryDiyCanvasPage()
    this.selectSysDict()
  },
  mounted() {
    window.addEventListener('storage', (e) => {
      if (e.key === 'inswitch') {
        if (e.newValue) {
          this.queryDiyCanvasPage()
          localStorage.setItem('inswitch', false)
        }
      }
    })
  },
  methods: {
    reset() {
      this.$refs.ruleForm.resetFields()
      delete this.from.type
      this.queryDiyCanvasPage()
    },
    search() {
      this.queryDiyCanvasPage()
    },
    selectSysDict() {
      selectSysDict({ dictTypeList: ['themeDiyType'] }).then((res) => {
        this.typeoptions = res.data[0].dictInfoVoList
      })
    },
    submitTab(row) {
      console.log(row, '14')
      themeDiyCanvaspushDiyCanvas({ id: row.id, diyStatus: 1, terminal: row.terminal, type: row.type }).then((res) => {
        if (res.status == 200) {
          this.$message.success('发布成功')
        }
      })
    },
    del(ym) {
      themeDiyCanvasdeleteDiyCanvas({ id: ym.id }).then((res) => {
        this.queryDiyCanvasPage()
        if (res.status == 200) {
          this.$message.success('删除成功')
        }
      })
    },
    navTo(row) {
      console.log(row, '1234-123')
      if (row != false) {
        const url = this.$router.resolve({
          path: 'fitment',
          query: { date: JSON.stringify(row) },
        })
        window.open(url.href, '_blank')
      }
      const url = this.$router.resolve({
        path: 'fitment',
      })
      window.open(url.href, '_blank')
    },
    copRow(list) {
      if (list != false) {
        const url = this.$router.resolve({
          path: 'fitment',
          query: { date: JSON.stringify(list), copy: true },
        })
        window.open(url.href, '_blank')
      }
    },
    handleSizeChange(e) {
      this.from.size = e
      this.queryDiyCanvasPage()
    },
    handleCurrentChange(e) {
      this.from.current = e
      this.queryDiyCanvasPage()
    },
    //模板列表
    queryDiyCanvasPage() {
      queryDiyCanvasPage({ ...this.from }).then((res) => {
        this.from.size = res.data.size
        this.from.current = res.data.current
        this.template = res.data.records
        this.total = res.data.total
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  margin-top: -10px;
  .bg_headerstatus {
    width: 80%;
    margin: auto;
  }
  .container-tem {
    .bg_headerstatus {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .template-picUrl {
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  .bg-purple {
    position: relative;
    min-width: 372px;
    min-height: 563px;
    padding: 20px 0;
    margin: 20px 20px 20px 0;
    background: rgb(248, 250, 255);
    border: 1px solid #e5e5e5;
    border-radius: 16px;
    &:hover .mov::before {
      display: block;
    }
    .mov {
      position: relative;
      min-height: 350px;
      &:hover .hvoer,
      &:hover .ym_setting {
        display: block;
        display: flex;
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        content: '';
        background: rgba(255, 255, 255, 0.5);
      }
      .bg_tempale_url {
        width: 60%;
        margin: 20px auto;
        img {
          width: 100%;
          height: 350px;
        }
      }
      .ym_setting {
        position: absolute;
        bottom: 90px;
        left: 50%;
        display: none;
        width: 180px;
        background: white;
        height: 45px;
        transform: translateX(-50%);
        ::v-deep {
          .el-dropdown,
          .el-button--small {
            width: 180px;
            height: 100%;
            font-size: 18px;
            color: #1780ff;
            text-align: center;
            background: transparent;
            border-radius: 5px;
          }
        }
      }
      .hvoer {
        position: absolute;
        top: 0;
        display: flex;
        display: none;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(240, 240, 240, 0.2);
        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: 45px;
          margin: auto;
          font-size: 18px;
          color: white;
          cursor: pointer;
          background: #1780ff;
          border-radius: 5px;
        }
      }
    }
    .ym_flex {
      position: absolute;
      bottom: 30px;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      transform: translateX(-50%);
      .ym_lef {
        display: grid;
        align-items: center;
        width: calc(100% - 93px);
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 3px 0;
        }
        .templateName {
          margin: 10px 0;
          font-size: 20px;
          font-weight: bold;
        }
      }
      .ref_code {
        width: 83px;
        height: 83px;
        background: #fdfdfd;
      }
    }
  }
}
</style>