<template>
  <div class="promoter">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="一级推广人" name="parentSecondId"/>
      <el-tab-pane label="二级推广人" name="parentId"/>
    </el-tabs>
    <el-form ref="searchForm" :inline="true" :model="search">
      <el-form-item label="用户编码：">
        <el-input v-model="search.userCode" placeholder="请输入 用户编码"/>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input v-model="search.phone" placeholder="请输入 手机号"/>
      </el-form-item>
      <el-form-item label="">
        <el-button icon="el-icon-search" type="warning" @click="searchcont">搜索</el-button>
        <el-button icon="el-icon-refresh-left" type="primary" @click=" $refs.searchForm.resetFields();">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabldata">
      <el-table-column align="center" label="用户编码" prop="userCode"/>
      <el-table-column align="center" label="手机号" prop="phone"/>
      <el-table-column align="center" label="来源类型" prop="appType">
        <template #default="scope">
          <div v-if="scope.row.appType=='H5'">普通H5</div>
          <div v-if="scope.row.appType=='MA'">小程序</div>
          <div v-if="scope.row.appType=='H5-WX'">公众号H5</div>
          <div v-if="scope.row.appType=='APP'">APP</div>
          <div v-if="scope.row.appType=='H5-PC'">PC端H5</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="来源应用" prop="appId"/>
      <el-table-column align="center" label="用户信息" prop="parentUserInfo" width="300px">
        <template #default="scope">
          <el-row :gutter="20">
            <el-col :span="4"><div class="grid-content bg-purple"> <el-image  :src="scope.row.parentUserInfo.headimgUrl"  style="width: 50px; height: 50px"/></div></el-col>
            <el-col :span="20">
              <div class="grid-content bg-purple" style="margin-left: 5px;">
                <el-row :gutter="20">
                  <el-col :span="24"><div class="grid-content bg-purple" style="font-size: 14px; text-align: left;">昵称：{{ scope.row.parentUserInfo.nickName }}</div></el-col>
                  <el-col :span="24"><div class="grid-content bg-purple" style="font-size: 14px; text-align: left;">编号：{{ scope.row.parentUserInfo.userCode }}</div></el-col>
                  <el-col :span="24"><div class="grid-content bg-purple" style="font-size: 14px; text-align: left;">电话：{{ scope.row.parentUserInfo.phone }}</div></el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否是分销员" prop="distributionUser">
        <template #default="scope">
          <div v-if="scope.row.distributionUser"><el-tag effect="dark" type="success">是</el-tag></div>
          <div v-else><el-tag effect="dark" type="danger">否</el-tag></div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="累计佣金金额" prop="commissionTotal"/>
    </el-table>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"/>
  </div>
</template>

<script>
import {userinfoselect} from "@/api/mall/distUsers/list"
export default {
  props:{
    userid:{
      type:String,
      default:()=>{}
    }
  },
  data(){
    return{
      search:{},
      activeName: 'parentSecondId',
      tabldata:[],
      total:0,
      form:{
        size:10,
        current:1
      },
      infoId:this.userid
    }
  },
  watch:{
    userid(newValue,oldValue){
      if(newValue!=oldValue){
        this.infoId=newValue
      }
    }
  },
  created(){
    this.userinfoselect()
  },
  methods:{
    userinfoselect(){
      let list={}
      if(this.activeName!='parentId'){
        list={
          ...this.form,
          ...this.search,
          parentSecondId:this.infoId
        }
      }
      else{
        list={
          ...this.form,
          ...this.search,
          parentId:this.infoId
        }
      }
      userinfoselect({...list}).then(res=>{
        this.tabldata=res.data.records
        this.form.size=res.data.size
        this.form.current=res.data.current
        this.total=res.data.total
      })
    },
    handleClick() {
      this.userinfoselect()
    },
    handleCurrentChange(e){
      this.form.current=e
      this.userinfoselect()
    },
    handleSizeChange(e){
      this.form.size=e
      this.userinfoselect()
    },
    searchcont(){
      this.userinfoselect()
    }
  }
}
</script>

<style>

</style>