var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.clear,
            title: "移交部门",
            visible: _vm.appointDialog,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.appointDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                "label-width": "80px",
                model: _vm.addForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "postPare",
                  attrs: { label: "所属部门", prop: "mallcategory" },
                },
                [
                  _c("el-cascader", {
                    attrs: {
                      "change-on-select": "",
                      options: _vm.dataSourceLists,
                      props: {
                        checkStrictly: true,
                        label: "orgName",
                        value: "id",
                      },
                    },
                    on: { change: _vm.getcasc },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ node, data }) {
                          return [
                            !node.isLeaf
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(data.orgName) +
                                      "(" +
                                      _vm._s(data.children.length) +
                                      ")"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.addForm.mallcategory,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "mallcategory", $$v)
                      },
                      expression: "addForm.mallcategory",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clear } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }