var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      attrs: {
        inline: true,
        "label-position": "right",
        "label-width": "80px",
        model: _vm.ruleForm,
        size: "mini",
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "商品名称：", prop: "name" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择 商品名称" },
              model: {
                value: _vm.ruleForm.value,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "value", $$v)
                },
                expression: "ruleForm.value",
              },
            },
            _vm._l(_vm.ruleForm.name, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "商城编码：", prop: "spuCode" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入 商城编码" },
            model: {
              value: _vm.ruleForm.spuCode,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "spuCode", $$v)
              },
              expression: "ruleForm.spuCode",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "规格类型：", prop: "specType" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择 规格类型" },
              model: {
                value: _vm.ruleForm.specType,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "specType", $$v)
                },
                expression: "ruleForm.specType",
              },
            },
            _vm._l(_vm.specType, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.labelName, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否上架：", prop: "shelf" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择 是否上架" },
              model: {
                value: _vm.ruleForm.shelf,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "shelf", $$v)
                },
                expression: "ruleForm.shelf",
              },
            },
            _vm._l(_vm.shelf, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.labelName, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "积分赠送：", prop: "pointsGiveSwitch" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择 积分赠送" },
              model: {
                value: _vm.ruleForm.info,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "info", $$v)
                },
                expression: "ruleForm.info",
              },
            },
            _vm._l(_vm.pointsGiveSwitch, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.labelName, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "积分抵扣：", prop: "pointsGiveSwitch" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择 积分赠送" },
              model: {
                value: _vm.ruleForm.pointsGiveSwitch,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "pointsGiveSwitch", $$v)
                },
                expression: "ruleForm.pointsGiveSwitch",
              },
            },
            _vm._l(_vm.pointsGiveSwitch, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.labelName, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-search",
                "native-type": "submit",
                type: "warning",
              },
              on: { click: _vm.getPage },
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-refresh-left",
                "native-type": "submit",
                type: "primary",
              },
              on: { click: _vm.reset },
            },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }