import request from '@/utils/request'
// 开盒记录
export function openRecordPage(data) {
  return request({
    url: `/mall/activity/acActInstUserBox/openRecordPage`,
    method: 'post',
    data,
  })
}
// 参与明细列表
export function userParticipateList(data) {
  return request({
    url: `/mall/activity/acActInstUserBox/userParticipateList`,
    method: 'post',
    data,
  })
}
// 盲盒奖品明细-概况
export function loadUserPartSurvey(data) {
  return request({
    url: `/mall/activity/acActInstUserBox/loadUserPartSurvey`,
    method: 'post',
    data,
  })
}
// 盲盒奖品明细-列表
export function loadUserPartPage(data) {
  return request({
    url: `/mall/activity/acActInstUserBox/loadUserPartPage`,
    method: 'post',
    data,
  })
}
