<template>
  <div>
    <el-dialog
  :before-close="handleClose"
  title="状态选择"
  :visible.sync="dialogVisible"
  width="30%">
    <span>
      <el-select v-model="value"  placeholder="请选择"  style="width: 100%">
        <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value" />
    
   
  </el-select>
    </span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="clear">取 消</el-button>
    <el-button type="primary" @click="btn">确 定</el-button>
  </span>
</el-dialog>
  
  </div>
</template>

<script>
import { editHealthStatus} from '@/api/mall/health/riskPool/riskPool'
export default {
  props:{
    showhist:{
      type:Boolean,
      default:()=>{}
    },
    showhislist:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
    return{
      dialogVisible:this.showhist,
      List:this.showhislist,
      value:'',
      options: [{
          value: '0',
          label: '未处理'
        }, {
          value: '1',
          label: '已处理'
        }],
       
    }
  },
 
  watch:{
    showhist(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisible = newValue
      }
    },
    showhislist(newValue,oldValue){
      if(newValue!=oldValue){
        this.list=newValue
        if(newValue.status == '0'){
          this.value = '0'
        }else{
          this.value = '1'
        }
       
      
      }
    }
  },
  methods:{
    handleClose(){
      this.$emit('closehis',false)
    },
    btn(){
      this.dialogVisible = false;
      console.log(this.value,'5555555555555555555555555555555');
      editHealthStatus({
        id:this.list.id,
        status:this.value
      }).then(res => {
        console.log(res,'88888888888888888888888888');
        this.$emit('status-updated');
       
      })
      this.$emit('closehis',false)
    },
    clear(){
      this.dialogVisible = false;
      this.$emit('closehis',false)
    }
  },
}
</script>

<style>

</style>