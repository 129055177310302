var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "top" },
    [
      _c(
        "vab-card",
        {
          staticClass: "top-card",
          class: "top-card-" + _vm.background,
          attrs: { shadow: "hover" },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _c(
            "p",
            [
              _c("vab-count", {
                attrs: {
                  decimals: _vm.countConfig.decimals,
                  duration: _vm.countConfig.duration,
                  "end-val": _vm.ordermoney,
                  prefix: _vm.countConfig.prefix,
                  separator: _vm.countConfig.separator,
                  "start-val": _vm.countConfig.startVal,
                  suffix: _vm.countConfig.suffix,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "right-icon" },
            [_c("vab-icon", { attrs: { icon: _vm.icon } })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }