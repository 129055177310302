<template>
  <div class="comprehensive-table-container">
    <div class="ym_thead_title">积分设置</div>
    <el-form
      class="demo-form-inline"
      :inline="false"
      label-position="right"
      label-width="120px"
      :model="formInline"
    >
      <el-form-item label="用户初始积分">
        <el-input-number
          v-model="formInline.initPosts"
          controls-position="right"
          :min="1"
        />
      </el-form-item>
      <el-form-item label="订单满">
        <el-input-number
          v-model="formInline.premiseAmount"
          controls-position="right"
          :min="1"
        />
      </el-form-item>
      <el-form-item label="抵扣比例">
        <el-input-number
          v-model="formInline.defaultDeductScale"
          controls-position="right"
          :min="1"
        />
      </el-form-item>
      <el-form-item label="抵扣规则">
        <el-input-number
          v-model="formInline.defaultDeductAmount"
          controls-position="right"
          :min="1"
        />
      </el-form-item>
      <el-form-item label="打卡积分">
        <el-input-number
          v-model="formInline.clockAmount"
          controls-position="right"
          :min="1"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交</el-button>
      </el-form-item>
    </el-form>
    <div class="text-c"></div>
  </div>
</template>

<script>
  import { pointsconfig, details } from '@/api/mall/points/pointsConfig'
  // import store from '@/store'
  export default {
    data() {
      return {
        formInline: {
          user: '',
          region: '',
        },
      }
    },
    created() {
      this.details()
    },
    methods: {
      details() {
        // const s=store.getters['user/tenantId']
        details({ tenantId: '1' }).then((res) => {
          this.formInline = res.data
        })
      },
      onSubmit() {
        pointsconfig({ ...this.formInline }).then((res) => {
          console.log(res)
          this.MS('保存成功')
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .ym_thead_title {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  ::v-deep {
    .el-form-item__label {
      margin-right: 10px;
    }
    .el-input-number--small {
      width: 300px;
    }
    .el-form-item__content {
      .el-button {
        position: absolute;
        left: 5%;
      }
    }
  }
</style>
