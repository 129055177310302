import request from '@/utils/request'

//会员详情-基础信息
export function userFitnessdetails(data) {
  return request({
    url: `/mall/user/userFitness/details`,
    method: 'post',
    data,
  })
}

//会员详情-基础信息_编辑
export function userFitnessedit(data) {
  return request({
    url: `/mall/user/userFitness/edit`,
    method: 'post',
    data,
  })
}

//会员详情-基础信息_健康档案
export function userFitnessInfoqueryPage(data) {
  return request({
    url: `/mall/user/userFitnessInfo/queryPage`,
    method: 'post',
    data,
  })
}

//会员详情-基础信息_删除健康档案_copy
export function userFitnessInfodelete(data) {
  return request({
    url: `/mall/user/userFitnessInfo/delete`,
    method: 'post',
    data,
  })
}

//会员详情-基础信息_添加健康档案_copy
export function userFitnessInfoadd(data) {
  return request({
    url: `/mall/user/userFitnessInfo/add`,
    method: 'post',
    data,
  })
}

//会员详情-基础信息_编辑健康档案_copy_copy
export function userFitnessInfoedit(data) {
  return request({
    url: `/mall/user/userFitnessInfo/edit`,
    method: 'post',
    data,
  })
}

//会员详情-基础信息_健康档案_详情
export function userFitnessInfodetails(data) {
  return request({
    url: `/mall/user/userFitnessInfo/details`,
    method: 'post',
    data,
  })
}

// 会员详情-基础信息_健康数据
export function userInfoHealthList(data) {
  return request({
    url: `/mall/user/pressure/queryPage`,
    method: 'post',
    data,
  })
}