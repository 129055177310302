<template>
  <div>
    <el-radio-group v-model="form.classType" size="mini" style="margin-bottom: 10px;" @change="checktags">
      <el-radio-button label="2">血压</el-radio-button>
      <el-radio-button label="3">血糖</el-radio-button>
    </el-radio-group>
    <div class="each">
        <target :requ="form"/>
    </div>
    <div class="e-tab">
      <el-table
        v-if="form.classType==2"
        border
        :data="tableData"
        height="300"
        style="width: 100%">
      <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="50" />
      <el-table-column align="center" label="高压" prop="sysValue" show-overflow-tooltip/>
      <el-table-column align="center" label="低压" prop="divValue" show-overflow-tooltip />
      <el-table-column align="center" label="心率" prop="pulValue" show-overflow-tooltip />
      <el-table-column align="center" label="设备序号" prop="serialNumber" show-overflow-tooltip/>
      <el-table-column align="center" label="采集时间" prop="createTime" show-overflow-tooltip/>
      </el-table>
      <el-table
        v-if="form.classType==3"
        border
        :data="tableData"
        height="300"
        style="width: 100%">
      <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="50" />
      <el-table-column align="center" label="血糖" prop="sugarValue" show-overflow-tooltip/>
      <el-table-column align="center" label="设备序号" prop="serialNumber" show-overflow-tooltip/>
      <el-table-column align="center" label="采集时间" prop="createTime" show-overflow-tooltip/>
      </el-table>
      <el-pagination background :current-page="1" layout="total, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    </div>
  </div>
</template>

<script>
  import Target from './comnponents/Target.vue'
  import {queryPressPage} from '@/api/mall/health/appdata/index'
export default {
  components: {
      // Order,
      Target,
    },
    props: {
      sound: {
        type: Object,
        default: () => {},
      },
    },
  data(){
    return{
      form:{
        size:10,
        current:1,
        classType:'2',
        userId:this.sound.userId
      },
      tableData:[],
      total:0,
    }
  },
    watch: {
      sound(newValue, oldValue) {
        if (newValue != oldValue) {
          this.form.userId = newValue.userId
          this.queryPressPage()
        }
      },
    },
    created(){
      this.queryPressPage()
    },
  methods:{
    checktags(e){
      this.queryPressPage()
      this.$baseEventBus.$emit('checkappdate',e)
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.queryPressPage()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.queryPressPage()
    },
    queryPressPage(){
      queryPressPage({...this.form}).then(res=>{
        console.log(res.data)
        this.tableData=res.data.records
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep{
    .el-col{
      border: transparent;
    }
  }
</style>