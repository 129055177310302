var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "max-width": "500px" } },
    [
      _c("BaseEditor", {
        attrs: { editcontent: _vm.actionscomponents.description },
        on: { editrow: _vm.descr },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }