var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "before-close": _vm.handleClose,
            "modal-append-to-body": true,
            title: "徽标设置",
            visible: _vm.dialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { "label-width": "80px", model: _vm.form },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "徽标状态" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.control,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "control", $$v)
                            },
                            expression: "form.control",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("开启"),
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("关闭"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.control
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "徽标内容" } },
                            [
                              _c("el-input", {
                                staticStyle: { "max-width": "200px" },
                                attrs: { maxlength: "3" },
                                model: {
                                  value: _vm.form.text,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "text", $$v)
                                  },
                                  expression: "form.text",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("el-form-item", { attrs: { label: "文字颜色" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "70px",
                                      margin: "0 20px",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.form.textColor))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      margin: "0 5px",
                                      color: "#1780ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.form.textColor = "#FFFFFF"
                                      },
                                    },
                                  },
                                  [_vm._v("重置")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.textColor,
                                      expression: "form.textColor",
                                    },
                                  ],
                                  attrs: { type: "color" },
                                  domProps: { value: _vm.form.textColor },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.form,
                                        "textColor",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("el-form-item", { attrs: { label: "背景颜色" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "230px",
                                      margin: "0 20px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "渐变1：" +
                                        _vm._s(_vm.form.bgColorStart) +
                                        "  渐变2：" +
                                        _vm._s(_vm.form.bgColorEnd)
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      margin: "0 5px",
                                      color: "#1780ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        ;(_vm.form.bgColorStart = "#FE8681"),
                                          (_vm.form.bgColorEnd = "#FF5754")
                                      },
                                    },
                                  },
                                  [_vm._v("重置")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.bgColorStart,
                                      expression: "form.bgColorStart",
                                    },
                                  ],
                                  attrs: { type: "color" },
                                  domProps: { value: _vm.form.bgColorStart },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.form,
                                        "bgColorStart",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v("   "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.bgColorEnd,
                                      expression: "form.bgColorEnd",
                                    },
                                  ],
                                  attrs: { type: "color" },
                                  domProps: { value: _vm.form.bgColorEnd },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.form,
                                        "bgColorEnd",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }