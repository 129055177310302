<template>
  <div v-loading.sync="listLoading" style="padding: 10px">
    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <div style="display: flex; align-items: center">
        <h3 style="margin-right: 10px">当前扩展团队</h3>
        <h3 style="color: rgb(37, 115, 217)">
          {{ equipaDetail.totalExpendNum }}人
        </h3>
      </div>
      <div>
        <div
          v-if="
            btnRolrs[9] &&
            btnRolrs[9]['searchBarButtons'] &&
            btnRolrs[9]['searchBarButtons'].length
          "
        >
          <div v-if="btnRolrs[9] && btnRolrs[9].searchBarButtons">
            <el-button
              v-for="item in btnRolrs[9].searchBarButtons"
              :key="item.id"
              :icon="item.buttonIcon"
              size="small"
              :type="item.buttonCss"
              @click="handleClickBtn(item.buttonUrl)"
            >
              <vab-icon :icon="item.buttonIcon || ''" />
              {{ item.buttonName }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        :label="`一级团员（${equipaDetail.oneLvlExpendNum}人）`"
        name="0"
      >
        <div></div>
      </el-tab-pane>
      <el-tab-pane
        :label="`二级团员（${equipaDetail.twoLvlExpendNum}人）`"
        name="1"
      >
        <div></div>
      </el-tab-pane>
    </el-tabs>
    <div>
      <el-table border :data="list" max-height="450" style="width: 100%">
        <el-table-column align="center" label="团员ID" prop="userId" />
        <el-table-column align="center" label="团员名称" prop="nickname" />
        <el-table-column align="center" label="团员身份">
          <template #default="{ row }">
            <div>{{ row.memberLevel | initLevel }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="绑定时间" prop="createTime" />
      </el-table>
      <el-pagination
        background
        :current-page="queryForm.current"
        :layout="layout"
        :page-size="queryForm.size"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
  import { getEquipa, getEquipaDetail } from '@/api/mall/users/userDetail'
  import { checkButton } from '@/utils/mixins'
  export default {
    filters: {
      initLevel(val) {
        let str = ''
        switch (val) {
          case 1:
            str = '普通会员'
            break
          case 2:
            str = '淘侠'
            break
          case 3:
            str = '讲师'
            break
          case 4:
            str = '门主'
            break
        }
        return str
      },
    },
    mixins: [checkButton],
    data() {
      return {
        activeName: '0',
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        userId: 0,
        goldenBeanAccount: 0,
        queryForm: {
          current: 1,
          size: 10,
        },
        total: 0,
        accId: '',
        list: [],
        equipaDetail: {},
      }
    },
    created() {
      const { goldenBeanAccount, accId } = this.$route.query
      this.goldenBeanAccount = goldenBeanAccount
      this.accId = accId
    },
    methods: {
      async fetchData(id, groupLvl = 1) {
        this.listLoading = true
        this.userId = id
        const { data: equipaDetail } = await getEquipaDetail({
          userId: this.userId,
        })
        this.equipaDetail = equipaDetail
        const {
          data: { records, total },
        } = await getEquipa({
          userId: this.userId,
          ...this.queryForm,
          groupLvl,
        })
        this.total = total
        this.list = records
        this.listLoading = false
      },
      handleClick(e) {
        const { index } = e
        if (index == 0) {
          console.log('aaa')
          this.fetchData(this.userId)
        } else {
          console.log('bbb')
          this.fetchData(this.userId, 2)
        }
        this.queryForm = {
          current: 1,
          size: 10,
        }
      },
      handleSizeChange(val) {
        this.queryForm.size = val
        if (this.activeName == 0) {
          this.fetchData(this.userId)
        } else {
          this.fetchData(this.userId, 2)
        }
      },
      handleCurrentChange(val) {
        this.queryForm.current = val
        if (this.activeName == 0) {
          this.fetchData(this.userId)
        } else {
          this.fetchData(this.userId, 2)
        }
      },
      // handleExport() {
      //   exportIntegral({
      //     accId: this.accId,
      //     userId: this.userId,
      //   })
      //     .then((res) => {
      //       const url = window.URL.createObjectURL(new Blob([res]))
      //       const link = document.createElement('a')
      //       link.setAttribute('href', url)
      //       link.setAttribute('download', new Date().getTime() + '.xlsx')
      //       link.click()
      //     })
      //     .catch(() => {
      //       this.$message.error('网络错误，请稍后重试！')
      //     })
      // },
    },
  }
</script>
