<template>
  <div class="thead">
    <div class="center">
      <div v-for="(ym, index) in nav" :key="index" :class="tops == index + 1 ? 'item active-item' : 'item'" @click="navcheck(index)">
        <vab-icon :icon="ym.icon" />
      </div>
    </div>
    <div class="save_canvas">
      <el-button type="primary" @click="save">保存画布</el-button>
      <el-button type="" @click="preview">预览</el-button>
    </div>
  </div>
</template>

<script>
import { themeDiyCanvasaddDiyCanvas, themeDiyCanvaseditDiyCanvas } from '@/api/mall/shop/templateset'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      check: 1,
      nav: [
        {
          icon: 'links-fill',
          name: 'Weixin',
        },
        {
          icon: 'h-5',
          name: 'H5',
        },
        {
          icon: 'smartphone-line',
          name: 'App',
        },
      ],
      thebolean: true,
    }
  },
  computed: {
    //公共缓存信息列
    ...mapGetters(['state']),
    tops() {
      return this.$store.state.diytype
    },
  },
  methods: {
    preview() {
      let list = {
        terminal: this.$store.state.diytype, //终端选择小程序  APP
        json: {
          global: this.$store.state.checkdiy.thead,
          // value:this.$store.state.checkvalue   //内容diy
          value: this.$store.state.checkvalue, //内容diy
        },
        type: 1, //类型
        shopId: 1, //店铺
        customId: 0, //自定义页面id
        name: '官方模板1',
      }
      const url = this.$router.resolve({
        // path:'preview',
        // query:{date:list}
        path: 'preview',
        query: { ...list },
      })
      window.open(url.href, '_blank')
    },
    save() {
      const switchbtn = this.$store.state.editcanvas
      // console.log(switchbtn,'1')
      // this.$baseEventBus.$emit('canvaspicUrl-view')
      if (switchbtn == 0) {
        const info = this.$store.state.editcontent
        let list = {
          terminal: this.$store.state.diytype, //终端选择小程序  APP
          value: {
            global: this.$store.state.checkdiy.thead,
            // value:this.$store.state.checkvalue   //内容diy
            value: this.$store.state.checkvalue, //内容diy
          },
          type: info.type, //类型
          shopId: info.shopId, //店铺
          customId: info.customId, //自定义页面id
          name: info.name,
        }
        themeDiyCanvasaddDiyCanvas({ ...list }).then((res) => {
          if (res.status == 200) {
            this.$message.success('新增成功')
            localStorage.setItem('inswitch', true)
            setTimeout(() => {
              window.close()
            }, 2000)
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        const list = this.$store.state.editcontent
        console.log(list)
        const date = {
          terminal: list.terminal,
          value: {
            global: this.$store.state.checkdiy.thead,
            value: this.$store.state.checkvalue, //内容diy
          },
          type: list.type,
          shopId: list.shopId,
          customId: list.customId,
          name: list.name,
          id: list.id,
        }
        themeDiyCanvaseditDiyCanvas({ ...date }).then((res) => {
          if (res.status == 200) {
            this.$message.success('编辑成功')
            localStorage.setItem('inswitch', true)
            setTimeout(() => {
              window.close()
            }, 2000)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    //切换canvas画布
    navcheck(Index) {
      this.$store.commit('upsetThead', Index + 1)
      this.check = Index + 1
    },
  },
}
</script>

<style lang="scss" scoped>
.thead {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 52px;
  border-bottom: 1px solid #f1f1f1;
  .center {
    display: flex;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 52px;
      font-size: 18px;
      color: gray;
      cursor: pointer;
      &:hover {
        color: white;
        background: #1890ff;
      }
      i {
        font-size: 18px;
      }
    }
    .active-item {
      color: white;
      background: #1890ff;
    }
  }
  .save_canvas {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
  }
}
</style>
