var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c("div", { staticClass: "swtitch" }, [
        _c(
          "div",
          {
            staticClass: "user_info",
            staticStyle: { "flex-wrap": "wrap", border: "none" },
          },
          [
            _c(
              "div",
              {
                staticClass: "user_info",
                style: {
                  borderColor: _vm.selectauthor == 1 ? "#1786ff" : "#e5e5e5",
                },
                on: {
                  click: function ($event) {
                    return _vm.selectuser(_vm.authority, 1)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "user_picurl" },
                  [
                    _c("el-image", {
                      staticStyle: {
                        width: "40px",
                        height: "40px",
                        "border-radius": "100%",
                      },
                      attrs: { src: _vm.authority.headimgUrl },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "user_name",
                    staticStyle: { margin: "2px 10px" },
                  },
                  [_vm._v("官方:" + _vm._s(_vm.authority.nickName))]
                ),
              ]
            ),
            _vm.showswitch
              ? _c(
                  "div",
                  {
                    staticClass: "user_info",
                    style: {
                      borderColor:
                        _vm.selectauthor == 2 ? "#1786ff" : "#e5e5e5",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectuser(_vm.checkuser, 2)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "user_picurl" },
                      [
                        _c("el-image", {
                          staticStyle: {
                            width: "40px",
                            height: "40px",
                            "border-radius": "100%",
                          },
                          attrs: { src: _vm.checkuser.headimgUrl },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "user_name",
                        staticStyle: { margin: "2px 10px" },
                      },
                      [_vm._v("姓名:" + _vm._s(_vm.checkuser.nickName))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "user_name",
                        staticStyle: { margin: "2px 10px" },
                      },
                      [_vm._v("编号:" + _vm._s(_vm.checkuser.userCode))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "userphone",
                        staticStyle: {
                          margin: "2px 10px",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v("手机号:" + _vm._s(_vm.checkuser.phone))]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.showswitch == false
              ? _c(
                  "div",
                  {
                    staticClass: "user_info",
                    style: {
                      borderColor:
                        _vm.selectauthor == 2 ? "#1786ff" : "#e5e5e5",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "user_picurl" },
                      [
                        _c("el-image", {
                          staticStyle: {
                            width: "40px",
                            height: "40px",
                            "border-radius": "100%",
                          },
                          attrs: { src: require("@/assets/img/Style.png") },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "user_name",
                        staticStyle: { margin: "2px 10px" },
                      },
                      [_vm._v("此用户未注册,如需代客下单请先前往代客注册！")]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]),
      _c("div", { staticClass: "content_container" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "shopall" }, [
            _c("div", { staticClass: "cont_title" }, [_vm._v("全部商品")]),
            _c("div", { staticClass: "cont_al" }, [
              _c(
                "div",
                { staticClass: "shop_content" },
                [
                  _c(
                    "div",
                    { staticClass: "search" },
                    [
                      _c(
                        "el-form",
                        { attrs: { model: _vm.form } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入商品名称",
                                  "suffix-icon": "el-icon-search",
                                },
                                on: { change: _vm.searchsubmit },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tabData, height: "300px" } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "", prop: "demo", width: "60" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                  },
                                  attrs: { src: scope.row.picUrls[0] },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "", prop: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "shop_title" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          content: scope.row.name,
                                          effect: "dark",
                                          placement: "top-start",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "shop_title" },
                                          [_vm._v(_vm._s(scope.row.name))]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "shop_info" }, [
                                  _c("div", { staticClass: "shop_much" }, [
                                    _vm._v(
                                      "销量：" + _vm._s(scope.row.saleNum)
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "", prop: "", width: "70" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.seltion(scope.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "sholt" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          "current-page": 1,
                          layout: " prev, pager, next",
                          total: _vm.total,
                        },
                        on: {
                          "current-change": _vm.handleCurrentChange,
                          "size-change": _vm.handleSizeChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "shopall" }, [
            _c("div", { staticClass: "cont_title" }, [_vm._v("已选择")]),
            _c(
              "div",
              { staticClass: "cont_al" },
              [
                _c("confirmsku", {
                  attrs: {
                    checkus: _vm.checksku,
                    listinfo: _vm.userdate,
                    userinfo: _vm.selectuserinfo,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("selectsku", {
        attrs: { showtab: _vm.boelan, spuid: _vm.spu },
        on: { selsku: _vm.selku },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }