import request from '@/utils/request'
// 入库列表
export function queryDepotInListForPage(data) {
  return request({
    url: `/in/wmsDepotIn/queryDepotInListForPage`,
    method: 'post',
    data,
  })
}
// 商品列表
export function queryDepotPage(data) {
  return request({
    url: `/goods/wms/queryDepotPage`,
    method: 'post',
    data,
  })
}
// 规格列表
export function goodsSpecs(data) {
  return request({
    url: `/goods/proGoodsBasicsSpecs/goodsSpecs`,
    method: 'post',
    data,
  })
}
// 入库添加

export function add(data) {
  return request({
    url: `/in/wmsDepotIn/add`,
    method: 'post',
    data,
  })
}
// 查看入库单

export function queryDepotInGoodsListForPage(data) {
  return request({
    url: `/depot/wmsDepotInGoods/queryDepotInGoodsListForPage`,
    method: 'post',
    data,
  })
}
// 条形码查商品

export function queryGoodsSpaceBarCode(data) {
  return request({
    url: `/depot/wmsDepotInGoods/queryGoodsSpaceBarCode`,
    method: 'post',
    data,
  })
}
