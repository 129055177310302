var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "textTool" },
    [
      _c("div", { staticClass: "toolTit" }, [
        _c("div", { staticClass: "lef" }, [_vm._v("列表导航")]),
        _c("div", { staticClass: "ref" }, [
          _c("div", { staticClass: "switch" }, [
            _c(
              "div",
              {
                class: _vm.checknum == 1 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(1)
                  },
                },
              },
              [_vm._v("内容")]
            ),
            _c(
              "div",
              {
                class: _vm.checknum == 2 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(2)
                  },
                },
              },
              [_vm._v("样式")]
            ),
          ]),
        ]),
      ]),
      _vm.checknum == 1
        ? _c("div", { staticClass: "content_msg" }, [
            _c("div", { staticClass: "ym_line" }),
            _c(
              "div",
              { staticClass: "itemBox", staticStyle: { "margin-top": "10px" } },
              [
                _c("div", { staticClass: "Tit" }, [_vm._v("图标状态")]),
                _c(
                  "div",
                  {
                    staticClass: "check-tit",
                    staticStyle: { display: "flex", width: "260px" },
                  },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.activeComponents[_vm.indexnumer].isShow,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "isShow",
                              $$v
                            )
                          },
                          expression: "activeComponents[indexnumer].isShow",
                        },
                      },
                      [
                        _vm.activeComponents[_vm.indexnumer].isShow
                          ? _c("el-checkbox", { attrs: { label: true } }, [
                              _vm._v("显示"),
                            ])
                          : _c("el-checkbox", { attrs: { label: false } }, [
                              _vm._v("隐藏"),
                            ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("文字大小")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 15, min: 12, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].style.fontSize,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].style,
                          "fontSize",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].style.fontSize",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 15, min: 12 },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].style.fontSize,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].style,
                          "fontSize",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].style.fontSize",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("是否加粗")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value:
                          _vm.activeComponents[_vm.indexnumer].style.fontWeight,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.activeComponents[_vm.indexnumer].style,
                            "fontWeight",
                            $$v
                          )
                        },
                        expression:
                          "activeComponents[indexnumer].style.fontWeight",
                      },
                    },
                    [
                      _vm.activeComponents[_vm.indexnumer].style.fontWeight
                        ? _c("el-checkbox", { attrs: { label: true } }, [
                            _vm._v("加粗"),
                          ])
                        : _c("el-checkbox", { attrs: { label: false } }, [
                            _vm._v("常规"),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "ym_title" }, [_vm._v("列表设置")]),
            _c(
              "div",
              {
                staticClass: "alert-msg",
                staticStyle: { margin: "top 20px", "text-align": "left" },
              },
              [_vm._v("请按照规范设置列表内容")]
            ),
            _c(
              "div",
              { staticClass: "itemBox", staticStyle: { height: "auto" } },
              [
                _c(
                  "div",
                  { staticClass: "list-navgation" },
                  _vm._l(
                    _vm.activeComponents[_vm.indexnumer].data,
                    function (ym, ind) {
                      return _c("div", { key: ind, staticClass: "card_list" }, [
                        _c(
                          "div",
                          {
                            staticClass: "close_card-item",
                            on: {
                              click: function ($event) {
                                return _vm.deletelist(ind)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        ),
                        _c("div", { staticClass: "list-title" }, [
                          _c("span", { staticClass: "tit" }, [_vm._v("图片")]),
                          _c(
                            "div",
                            { staticClass: "list-picUrl" },
                            [
                              _c("uploadpic", {
                                attrs: { picurl: [{ url: ym.iconUrl }] },
                                on: {
                                  backkuck: function ($event) {
                                    return _vm.check($event, ind)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "list-title" },
                          [
                            _c("span", { staticClass: "tit" }, [
                              _vm._v("标题"),
                            ]),
                            _c("el-input", {
                              attrs: { placeholder: "请输入列表标题" },
                              model: {
                                value: ym.text,
                                callback: function ($$v) {
                                  _vm.$set(ym, "text", $$v)
                                },
                                expression: "ym.text",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "list-title" }, [
                          _c("span", { staticClass: "tit" }, [_vm._v("链接")]),
                          _c(
                            "div",
                            {
                              staticClass: "check-tit",
                              staticStyle: { display: "flex", width: "260px" },
                              on: {
                                click: function ($event) {
                                  return _vm.checklink(ind)
                                },
                              },
                            },
                            [
                              ym.linkUrl.name != ""
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#1780ff" } },
                                    [_vm._v(_vm._s(ym.linkUrl.title))]
                                  )
                                : _c("span", [_vm._v("请选择链接")]),
                              _c("i", { staticClass: "el-icon-arrow-right" }),
                            ]
                          ),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]
            ),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "add-ba", on: { click: _vm.addlist } }, [
                _c("i", { staticClass: "el-icon-plus" }),
                _vm._v(" 增加一个 "),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.checknum == 2
        ? _c("div", { staticClass: "content_msg" }, [
            _c("div", { staticClass: "ym_line" }),
            _c(
              "div",
              { staticClass: "itemBox", staticStyle: { padding: "30px 0" } },
              [
                _c("div", { staticClass: "Tit" }, [_vm._v("底部背景")]),
                _c(
                  "div",
                  {
                    staticClass: "check-tit",
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c("div", { staticClass: "lef" }, [
                      _vm._v(
                        _vm._s(_vm.activeComponents[_vm.indexnumer].pageBgColor)
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "ref",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          color: "#7aa4ff",
                        },
                      },
                      [
                        _c("el-color-picker", {
                          model: {
                            value:
                              _vm.activeComponents[_vm.indexnumer].pageBgColor,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.activeComponents[_vm.indexnumer],
                                "pageBgColor",
                                $$v
                              )
                            },
                            expression:
                              "activeComponents[indexnumer].pageBgColor",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "resect",
                            on: {
                              click: function ($event) {
                                _vm.activeComponents[
                                  _vm.indexnumer
                                ].pageBgColor = ""
                              },
                            },
                          },
                          [_vm._v("重置")]
                        ),
                        _c("i", {
                          staticClass: "iconfont icon-arrow-right",
                          staticStyle: { "font-size": "12px", color: "gray" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "alert-msg" }, [
              _vm._v("底部背景包含边距和圆角"),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("组件背景")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(
                        _vm.activeComponents[_vm.indexnumer].componentBgColor
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer]
                              .componentBgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "componentBgColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].componentBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[
                                _vm.indexnumer
                              ].componentBgColor = ""
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("底线颜色")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(
                        _vm.activeComponents[_vm.indexnumer].style.lineColor
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer].style
                              .lineColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer].style,
                              "lineColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].style.lineColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[
                                _vm.indexnumer
                              ].style.lineColor = ""
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("列表背景")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(_vm.activeComponents[_vm.indexnumer].style.bgColor)
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer].style.bgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer].style,
                              "bgColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].style.bgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[
                                _vm.indexnumer
                              ].style.bgColor = "#FFFFFF"
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("列表高度")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 100, min: 0, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].style.contHeight,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].style,
                          "contHeight",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].style.contHeight",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 100, min: 40 },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].style.contHeight,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].style,
                          "contHeight",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].style.contHeight",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("内撑边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].style.pgboth,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].style,
                          "pgboth",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].style.pgboth",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].style.pgboth,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].style,
                          "pgboth",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].style.pgboth",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("上边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 100, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.top,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "top",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.top",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 100, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.top,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "top",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.top",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("下边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 100, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "bottom",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.bottom",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 100, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "bottom",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.bottom",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("左右边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 20, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.both",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 20, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.both",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("上圆角")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, min: 0, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer]
                          .topElementAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "topElementAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].topElementAroundRadius",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer]
                          .topElementAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "topElementAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].topElementAroundRadius",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("下圆角")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, min: 0, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer]
                          .bottomElementAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "bottomElementAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].bottomElementAroundRadius",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer]
                          .bottomElementAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "bottomElementAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].bottomElementAroundRadius",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("linkdialog", {
        attrs: { linkshow: _vm.linkurl },
        on: { closeurl: _vm.closelink },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }