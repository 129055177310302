var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { inline: "", model: _vm.formInline } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户I:" } },
            [
              _c("el-input", {
                staticClass: "title-input",
                attrs: { placeholder: "请输入用户ID" },
                model: {
                  value: _vm.formInline.id,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formInline,
                      "id",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formInline.id",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户名称:" } },
            [
              _c("el-input", {
                staticClass: "title-input",
                attrs: { placeholder: "用户名称" },
                model: {
                  value: _vm.formInline.nickname,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formInline,
                      "nickname",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formInline.nickname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间:" } },
            [
              _c("el-date-picker", {
                attrs: {
                  align: "center",
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                model: {
                  value: _vm.datePicker,
                  callback: function ($$v) {
                    _vm.datePicker = $$v
                  },
                  expression: "datePicker",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                { attrs: { icon: "el-icon-search", type: "warning" } },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                { attrs: { icon: "el-icon-refresh-left", type: "primary" } },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "15px" },
          attrs: { icon: "el-icon-plus", type: "primary" },
          on: { click: _vm.addnew },
        },
        [_vm._v("新增")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tableData },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              type: "index",
              width: "50",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "用户信息",
              prop: "name",
              width: "300",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-row",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                        attrs: { align: "middle", gutter: 20 },
                      },
                      [
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple" },
                            [
                              _c("el-image", {
                                attrs: { src: scope.row.userInfo.headimgUrl },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("el-col", { attrs: { offset: 1, span: 19 } }, [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple" },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "el-tyx",
                                      attrs: { span: 24 },
                                    },
                                    [
                                      _vm._v(
                                        "昵称：" +
                                          _vm._s(scope.row.userInfo.nickName)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "el-tyx",
                                      attrs: { span: 24 },
                                    },
                                    [
                                      _vm._v(
                                        "编号：" +
                                          _vm._s(scope.row.userInfo.userCode)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "el-tyx",
                                      attrs: { span: 24 },
                                    },
                                    [
                                      _vm._v(
                                        "电话：" +
                                          _vm._s(scope.row.userInfo.phone)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "累计佣金金额",
              prop: "commissionTotal",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "已提现佣金金额",
              prop: "commissionWithdrawal",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", prop: "createTime" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 5px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.showview(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 5px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.editrow(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-dropdown",
                      { attrs: { trigger: "click" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "el-dropdown-link",
                            staticStyle: {
                              margin: "5px",
                              "line-height": "23px",
                              color: "#1890ff",
                            },
                          },
                          [_vm._v(" 更多 ")]
                        ),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticStyle: { "text-align": "center" },
                            attrs: { slot: "dropdown" },
                            slot: "dropdown",
                          },
                          [
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delobj(scope.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "DistriBution",
                                            query: {
                                              id: scope.row.userId,
                                              unfold: "promoter",
                                            },
                                          },
                                        },
                                      },
                                      [_vm._v("推广人")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "DistriBution",
                                        query: {
                                          id: scope.row.userId,
                                          unfold: "Promotionorder",
                                        },
                                      },
                                    },
                                  },
                                  [_vm._v("推广订单")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": 1,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("Newdistribution", {
        attrs: { rowhide: _vm.rowshow },
        on: { closeadd: _vm.clsoehide },
      }),
      _c("Distributioneditor", {
        attrs: { edirow: _vm.edirow, showform: _vm.bole, size: "small" },
        on: { closeedit: _vm.closedit },
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: "查看", visible: _vm.dialogVisible, width: "60%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-descriptions",
                { staticClass: "margin-top", attrs: { border: "", column: 4 } },
                [
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v(" 累计佣金金额: "),
                      ]),
                      _vm._v(" " + _vm._s(_vm.dialorow.commissionTotal) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v(" 已提现佣金金额: "),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.dialorow.commissionWithdrawal) + " "
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }