<template>
  <div class="cy-container">
    <el-form
      ref="queryForm"
      :inline="true"
      :model="queryForm"
      @submit.native.prevent
    >
      <el-form-item label="用户信息">
        <el-input
          v-model="queryForm.userName"
          clearable
          placeholder="请输入用户名"
        />
      </el-form-item>
      <el-form-item label="奖品状态">
        <el-select
          v-model="queryForm.prizeState"
          class="filterItems"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in prizeStateList"
            :key="item.dictKey"
            :label="item.dictValue"
            :value="item.dictKey"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="orderCreatTime" label="开盒时间">
        <el-date-picker
          v-model="datePicker"
          align="center"
          :default-time="['00:00:00', '23:59:59']"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="dateChange"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          icon="el-icon-search"
          native-type="submit"
          type="primary"
          @click="handleQuery"
        >
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          native-type="submit"
          type="primary"
          @click="handleReset"
        >
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="listLoading"
      border
      :data="tableData"
      default-expand-all
    >
      <el-table-column
        align="center"
        label="用户信息"
        prop="userName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="奖品等级"
        prop="itemName"
        show-overflow-tooltip
      />

      <el-table-column
        align="center"
        label="奖品名称"
        prop="goodsName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="购买时间"
        prop="buyTime"
        show-overflow-tooltip
      />

      <el-table-column
        align="center"
        label="开盒时间"
        prop="openTime"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <div>
            {{ row.newAmount | numFormat }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="取货时间"
        prop="pickupTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="回收时间"
        prop="recoverTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="formatPrizeState"
        label="奖品状态"
        prop="prizeState"
        show-overflow-tooltip
      />
    </el-table>
    <el-pagination
      background
      :current-page="forms.currentPage"
      :layout="layout"
      :page-size="forms.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
  import { openRecordPage } from '@/api/activity/box'
  import { selectSysDict } from '@/api/mall/common'
  export default {
    data() {
      return {
        instId: 0,
        datePicker: [],
        colStatusList: [],
        listLoading: false,
        tableData: [],
        forms: { currentPage: 1, pageSize: 10 },
        isShow: true,
        queryForm: {
          userName: '',
          prizeState: '',
          openBeginTime: '',
          openEndTime: '',
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        prizeStateList: [], //奖品状态
      }
    },
    created() {
      this.instId = this.$route.query.instId
      this.selectSysDict()
      this.fetchData()
    },
    methods: {
      // 字典
      selectSysDict() {
        selectSysDict({
          dictTypeList: ['prizeState'],
        })
          .then((res) => {
            if (res.data) {
              this.prizeStateList = res.data[0].dictInfoVoList
            }
          })
          .catch(() => {})
      },
      // 奖品状态
      formatPrizeState(row) {
        for (let i = 0; i < this.prizeStateList.length; i++) {
          if (this.prizeStateList[i].dictKey == row.prizeState) {
            return this.prizeStateList[i].dictValue
          }
        }
      },

      // 变更时间
      dateChange(date) {
        if (date && date.length) {
          this.queryForm.openBeginTime = date[0]
          this.queryForm.openEndTime = date[1]
        } else {
          this.queryForm.openBeginTime = ''
          this.queryForm.openEndTime = ''
        }
      },

      async fetchData(options) {
        this.listLoading = true
        await openRecordPage({
          ...options,
          ...this.forms,
          instId: Number(this.instId),
        })
          .then((res) => {
            if (res.data) {
              this.tableData = res.data.records
              this.total = res.data.total
            }
          })
          .catch(() => {})
        this.listLoading = false
      },

      // 重置
      handleReset() {
        this.datePicker = []
        this.forms.currentPage = 1
        this.forms.pageSize = 10
        this.queryForm = {}
        this.handleQuery()
      },
      // 搜索
      async handleQuery() {
        this.forms.currentPage = 1
        await this.fetchData({ ...this.queryForm }, false)
        let temp = false
        Object.keys(this.queryForm).forEach((key) => {
          this.queryForm[key] && (temp = true)
        })
        this.isShow = temp
      },
      // 分页
      handleSizeChange(val) {
        this.forms.pageSize = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
      // 分页
      handleCurrentChange(val) {
        this.forms.currentPage = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .filterItems {
    width: 150px;
  }
</style>
