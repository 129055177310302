<template>
  <div class="cy-container">
    <!-- 查询 -->
    <el-form
      ref="queryForm"
      :inline="true"
      :model="queryForm"
      @submit.native.prevent
    >
      <el-form-item label="设备编号" prop="colName">
        <el-input
          v-model="queryForm.colName"
          class="filterItems"
          clearable
          placeholder="请输入设备名称"
        />
      </el-form-item>
      <el-form-item label="设备状态" prop="colStatus">
        <el-select
          v-model="queryForm.modelStatus"
          class="filterItems"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in colStatusList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
          icon="el-icon-search"
          native-type="submit"
          type="warning"
          @click="handleQuery"
        >
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          native-type="submit"
          type="primary"
          @click="handleReset"
        >
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <div class="m-b">
      <el-button type="primary" @click="importFile">导入</el-button>
      <el-button type="warning" @click="importSerice">指定服务商</el-button>
    </div>
    <!-- 表格 -->
    <el-table
      ref="multipleTable"
      v-loading="listLoading"
      border
      :data="tableData"
      max-height="600"
      @selection-change="handleSelectionChange"
    >
      <el-table-column show-overflow-tooltip type="selection" width="60px" />
      <el-table-column
        align="center"
        label="ID"
        prop="id"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="品牌"
        prop="modelBrand"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="SN码"
        prop="modelCode"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="设备状态"
        prop="modelStatus"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tag :type="row.modelStatus === 1 ? 'success' : 'danger'">
            {{ row.modelStatus === 1 ? '已绑定' : '未绑定' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="所属服务商"
        prop="realName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="操作" min-width="100px">
        <template #default="{ row }">
          <el-button type="text" @click="handleLook(row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      :current-page="forms.currentPage"
      :layout="layout"
      :page-size="forms.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 新增(编辑) -->
    <el-dialog
      class="addDialog"
      :title="dialogTitle"
      :visible.sync="addVisible"
      width="40%"
      @close="close"
    >
      <el-form
        ref="addForm"
        label-width="100px"
        :model="addForm"
        :rules="rules"
      >
        <el-form-item label="设备名称" prop="colName">
          <el-input
            v-model.trim="addForm.colName"
            autocomplete="off"
            clearable
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="排序" prop="colSort">
          <el-input-number
            v-model="addForm.colSort"
            :min="0"
            placeholder="请输入"
            size="small"
            style="width: 100%"
          />
        </el-form-item>
        <el-form-item label="设备简介" prop="colShort">
          <el-input
            v-model.trim="addForm.colShort"
            autocomplete="off"
            clearable
            maxlength="50"
            placeholder="请输入"
            show-word-limit
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="设备详情" prop="colInfo">
          <el-input
            v-model.trim="addForm.colInfo"
            autocomplete="off"
            clearable
            placeholder="请输入"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="imgDialog">
      <img :src="imagesUrl" width="100%" />
    </el-dialog>
    <!-- 查看 -->
    <el-dialog
      class="addDialog"
      title="查看"
      :visible.sync="lookVisible"
      width="40%"
    >
      <el-form ref="addForm" label-width="100px" :model="lookForm">
        <el-form-item label="品牌名称：">
          <el-row class="color117">{{ lookForm.modelBrand }}</el-row>
        </el-form-item>
        <el-form-item label="排序：">
          <el-row class="color117">{{ lookForm.id }}</el-row>
        </el-form-item>
        <el-form-item label="设备SN码：">
          <el-row class="color117">{{ lookForm.machineType }}</el-row>
        </el-form-item>
        <el-form-item label="设备状态：">
          <el-tag :type="lookForm.modelStatus === 1 ? 'success' : 'danger'">
            {{ lookForm.modelStatus === 1 ? '已绑定' : '未绑定' }}
          </el-tag>
        </el-form-item>
        <el-form-item label="服务商：">
          <el-row class="color117">{{ lookForm.realName }}</el-row>
        </el-form-item>
        <el-form-item label="创建时间：">
          <el-row class="color117">{{ lookForm.createTime }}</el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="lookVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 配置类型 -->
    <el-dialog
      v-if="authorizeRoleisible"
      class="authorizePoles"
      :close-on-click-modal="false"
      title="配置类型"
      :visible.sync="authorizeRoleisible"
      width="50%"
      @close="closeRole"
    >
      <el-row>
        <el-col
          style="padding: 15px; margin-bottom: 15px; border: 1px solid #ddd"
        >
          {{ selectColName }}
        </el-col>
      </el-row>

      <el-transfer
        v-model="grantRoleList"
        :data="roleList"
        filterable
        :props="{ label: 'typeName', key: 'id' }"
        :titles="treeTitle"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeRole">取 消</el-button>
        <el-button type="primary" @click="createAuthorizeRoleButton">
          确 定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="指定服务商" :visible.sync="dialogFormVisible">
      <el-transfer
        v-model="value"
        :data="data"
        filter-placeholder="请输入服务商昵称"
        :filterable="false"
        :left-default-checked="[1]"
        :right-default-checked="[1]"
        :titles="['服务商', '指定服务商']"
        @left-check-change="denm"
        @right-check-change="ym"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="tkxqx">取 消</el-button>
        <el-button type="primary" @click="tkxadd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import {
    queryPage,
    editColumn,
    addColumn,
    deleteColumn,
    disableColumn,
  } from '@/api/mall/health/device/glueDevice'
  import { queryPages, zdfws } from '@/api/mall/health/facilitator/facilitator'
  import { uploadURL } from '@/config'
  import { selectRoleMenuButtonList } from '@/api/mall/common'
  export default {
    name: 'ArtColumn',
    data() {
      return {
        selectColName: '',
        dialogFormVisible: false,
        multipleSelection: [],
        temp: {},
        postCodeOptions: [],
        treeTitle: ['未配置类型', '已配置类型'],
        grantRoleList: [],
        roleList: [],
        authorizeRoleisible: false,
        operationShow: false,
        lookForm: {},
        lookVisible: false,
        imagesUrl: '',
        imgDialog: false,
        hideUpload: false,
        limitCount: 1,
        dialogImageUrl: '',
        dialogVisible: false,
        fileList: [],
        headers: {},
        action: uploadURL,
        rules: {
          colName: [
            {
              required: true,
              message: '设备名称不能为空',
              trigger: ['blur', 'change'],
            },
          ],
          colSort: [
            {
              required: true,
              message: '排序不能为空',
              trigger: ['blur', 'change'],
            },
          ],
          colShort: [
            {
              required: true,
              message: '设备简介不能为空',
              trigger: ['blur', 'change'],
            },
          ],
        },
        addForm: {
          colLogo: '',
        },
        addVisible: false,
        dialogTitle: '',
        colStatusList: [
          {
            id: '1',
            name: '正常',
          },
          {
            id: '2',
            name: '删除',
          },
        ],
        listLoading: false,
        forms: { currentPage: 1, pageSize: 10 },
        isShow: true,
        queryForm: {
          colStatus: '',
          colName: '',
        },
        tableData: [],
        currentPage: 1,
        pageSize: 10,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        searchButton: [],
        listButton: [],
        data: [
          {
            key: '1',
            label: '1',
            disabled: false,
          },
        ],
        value: [],
        checked: 1,
        userid: '',
        show: '',
      }
    },
    computed: {
      ...mapGetters({
        token: 'user/token',
      }),
    },
    created() {
      this.headers['Authorization'] = `Bearer ${this.token}`
      this.fetchData()
      this.selectRoleMenuButtonList()
    },
    methods: {
      importFile() {
        this.$router.replace('DeviceImport')
      },
      // 配置类型保存
      createAuthorizeRoleButton() {
        if (this.grantRoleList.length === 0) {
          this.ME('已配置类型不能为空')
          return
        }
        const tempData = {}
        tempData.typeIds = this.grantRoleList
        tempData.colId = this.multipleSelection[0].id
      },

      // 配置类型
      handleType() {
        if (this.multipleSelection.length === 0) {
          this.ME('请勾选记录')
          return
        }
        this.authorizeRoleisible = true
      },
      // 表格操作
      handleSelectionChange(val) {
        // if (val.length > 1) {
        //   this.$refs.multipleTable.clearSelection()
        //   this.$refs.multipleTable.toggleRowSelection(val.pop())
        // } else {
        this.multipleSelection = val
        if (val.length != 0) {
          this.selectColName = val[0].colName
        }
        // }
      },

      // 配置类型(取消)
      closeRole() {
        this.grantRoleList = []
        this.roleList = []
        this.authorizeRoleisible = false
      },
      // 按钮状态显示判断
      showListButton(row, item) {
        if (
          item.buttonUrl == 'handleLook' ||
          item.buttonUrl == 'handelEdit' ||
          item.buttonUrl == 'handelDelete'
        ) {
          return true
        }
        if (row.colStatus == 1 && item.buttonName == '禁用') {
          return true
        } else if (row.colStatus == 0 && item.buttonName == '开启') {
          return true
        }
      },
      // 按钮点击
      handleClickBtn(item, row) {
        if (item.buttonUrl) {
          this[item.buttonUrl](row)
        }
      },
      // 按钮权限
      selectRoleMenuButtonList() {
        selectRoleMenuButtonList({
          roleIdStrList: this.$store.getters['acl/roleList'],
          menuId: this.$route.meta.id,
        })
          .then((res) => {
            if (res.data) {
              this.searchButton = res.data.filter(function (item) {
                return item.buttonType == 1
              })
              this.listButton = res.data.filter(function (item) {
                return item.buttonType == 2
              })
              if (this.listButton.length > 0) {
                this.operationShow = true
              } else {
                this.operationShow = false
              }
            }
          })
          .catch(() => {})
      },
      // 图片上传
      bfUpload(file) {
        console.log(file)
        if (
          'image/png' == file.type ||
          'image/jpeg' == file.type ||
          'image/jpg' == file.type
        ) {
          console.log()
        } else {
          this.ME('图片上传失败,请上传png,jpeg,jpg格式')
          return false
        }
      },
      // 图片预览
      handleImgClick(url) {
        this.imagesUrl = url
        this.imgDialog = true
      },
      // 图片
      handleChange(file, fileList) {
        console.log(file, fileList)
        this.hideUpload = fileList.length >= this.limitCount
      },
      // 设备Logo
      handleSuccess(response, file) {
        console.log(response, file)
        this.addForm.colLogo = response.data.filePath
        this.fileList.push({
          url: response.data.filePath,
        })
      },
      // 删除图片
      handleRemove(fileList) {
        this.fileList = []
        this.addForm.colLogo = ''
        this.hideUpload = fileList.length >= this.limitCount
      },
      // 图片显示
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      // 编辑
      handelEdit(row) {
        if (row) {
          this.showEdit(row)
        } else {
          this.showEdit()
        }
      },
      // 查看
      handleLook(row) {
        this.lookForm = Object.assign({}, row)
        this.lookVisible = true
        console.log(row)
      },
      // 添加编辑title
      showEdit(row) {
        this.row = row
        if (!row) {
          this.dialogTitle = '添加'
          this.hideUpload = this.fileList.length >= this.limitCount
        } else {
          this.dialogTitle = '编辑'
          this.rowId = row.id
          this.addForm.colLogo = row.colLogo
          this.fileList = []
          if (row.colLogo && row.colLogo != null) {
            this.fileList.push({
              url: this.addForm.colLogo,
            })
            this.hideUpload = this.fileList.length >= this.limitCount
          }
          this.hideUpload = this.fileList.length >= this.limitCount
          this.addForm = Object.assign({}, row)
        }
        this.addVisible = true
      },
      // 关闭
      close() {
        this.fileList = []
        this.$refs['addForm'].resetFields()
        this.addForm = this.$options.data().addForm
        this.addVisible = false
      },
      // 保存
      save() {
        console.log(this.addForm.colLogo)
        if (this.addForm.colLogo == '' || this.addForm.colLogo == null) {
          return this.ME('请上传设备LOGO')
        }
        this.$refs['addForm'].validate((valid) => {
          if (valid) {
            const parms = {
              colName: this.addForm.colName,
              colShort: this.addForm.colShort,
              colInfo: this.addForm.colInfo,
              colLogo: this.addForm.colLogo,
              colSort: this.addForm.colSort,
            }
            if (!this.row) {
              addColumn(parms)
                .then(() => {
                  this.MS('新增成功')
                  this.fetchData()
                  this.close()
                })
                .catch(() => {})
            } else {
              editColumn({ ...parms, id: this.rowId })
                .then(() => {
                  this.MS('修改成功')
                  this.fetchData()
                  this.close()
                })
                .catch(() => {})
            }
          }
        })
      },
      // 列表
      async fetchData(options) {
        this.listLoading = true
        await queryPage({
          ...options,
          ...this.forms,
          // currentPage: this.currentPage,
          // pageSize: this.pageSize,
          // colStatus: this.queryForm.colStatus,
          // colName: this.queryForm.colName,
        })
          .then((res) => {
            if (res.data) {
              this.tableData = res.data.records
              this.total = res.data.total
            }
          })
          .catch(() => {})
        this.listLoading = false
      },

      // 禁用开启
      handleDisable(row) {
        this.$confirm('是否要更新此条数据的状态?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            disableColumn({ id: row.id, colStatus: row.colStatus == 1 ? 0 : 1 })
              .then(() => {
                this.MS('成功')
                this.fetchData()
              })
              .catch(() => {})
          })
          .catch(() => {})
      },
      // 删除
      handelDelete(row) {
        this.$confirm('是否要删除此行数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteColumn({ id: row.id })
              .then(() => {
                this.MS('删除成功')
                this.fetchData()
              })
              .catch(() => {})
          })
          .catch(() => {})
      },
      // 重置
      handleReset() {
        this.forms.currentPage = 1
        this.forms.pageSize = 10
        this.queryForm = {}
        this.handleQuery()
      },
      // 搜索
      async handleQuery() {
        this.forms.currentPage = 1
        await this.fetchData({ ...this.queryForm }, false)
        let temp = false
        Object.keys(this.queryForm).forEach((key) => {
          this.queryForm[key] && (temp = true)
        })
        this.isShow = temp
      },
      // 分页
      handleSizeChange(val) {
        this.forms.pageSize = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
      // 分页
      handleCurrentChange(val) {
        this.forms.currentPage = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
      // 添加服务商
      importSerice() {
        let userid = []
        queryPages({}).then((res) => {
          let formdata = []
          for (let i = 0; i < res.data.records.length; i++) {
            formdata.push({
              label: res.data.records[i].realName,
              key: res.data.records[i].id,
              disabled: Boolean(0),
            })
          }
          this.data = formdata
        })
        const number = this.multipleSelection.length
        for (let i = 0; i < number; i++) {
          userid.push({ id: this.multipleSelection[i].id })
        }
        this.userid = userid
        if (this.multipleSelection.length == 0) {
          this.$message.error('请选择设备')
        } else {
          this.dialogFormVisible = true
        }
      },
      tkxqx() {
        this.value = ''
        this.data = ''
      },
      //服务商发送
      tkxadd() {
        this.dialogFormVisible = false
        this.value = ''
        const parms = {
          sysId: this.show,
          gsmDevicePos: this.userid,
        }
        zdfws({ ...parms }).then((res) => {
          console.log(res.data)
        })
      },
      denm(newValue) {
        if (newValue[0] != undefined) {
          this.data.forEach((item) => {
            if (item.key == newValue[0]) {
              item.disabled = false
              this.show = newValue[0]
            } else {
              item.disabled = true
            }
          })
        } else {
          this.data.forEach((item) => {
            item.disabled = false
          })
        }
      },
      ym(newValue) {
        if (newValue[0] == undefined) {
          this.data.forEach((item) => {
            item.disabled = false
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .authorizePoles {
    ::v-deep(.el-transfer-panel) {
      width: 40%;
    }
  }

  ::v-deep(.hide .el-upload--picture-card) {
    display: none;
  }
  .filterItems {
    width: 150px;
  }
</style>
