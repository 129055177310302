<template>
  <div class="comprehensive-table-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="(ym, index) in tags" :key="index" :label="ym.labelName" :name="ym.id" />
    </el-tabs>
    <Bloodpressure v-if="activeName == 'Bloodpressure'" />
    <BloodSugar v-if="activeName == 'BloodSugar'" />
    <Heartrate v-if="activeName == 'Heartrate'" />
  </div>
</template>

<script>
import Bloodpressure from './components/Bloodpressure/index.vue'
import BloodSugar from './components/BloodSugar/index.vue'
import Heartrate from './components/Heartrate/index.vue'
export default {
  components: {
    Bloodpressure,
    BloodSugar,
    Heartrate,
  },
  data() {
    return {
      activeName: 'Bloodpressure',
      // tags: [
      //   {
      //     id: 'Bloodpressure',
      //     labelName: '血压',
      //   },
      //   {
      //     id: 'BloodSugar',
      //     labelName: '血糖',
      //   },
      //   {
      //     id: 'Bloodoxygen',
      //     labelName: '血氧',
      //   },
      //   {
      //     id: 'Heartrate',
      //     labelName: '心率',
      //   },
      //   {
      //     id: 'weight',
      //     labelName: '体重',
      //   },
      //   {
      //     id: 'height',
      //     labelName: '身高',
      //   },
      //   {
      //     id: 'running',
      //     labelName: '跑步',
      //   },
      // ],
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
  },
}
</script>

<style>
</style>