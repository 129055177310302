import request from '@/utils/request'

// 商品统计列表
export function getList(params) {
  return request({
    url: `/mall/goodsspu/queryGoodsSpuReport`,
    method: 'post',
    data: params,
  })
}
