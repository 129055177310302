var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "containers" },
    [
      _c(
        "div",
        { staticClass: "add-table" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.addshow = true
                },
              },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tableData },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "手术名称", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "手术时间", prop: "phyTime" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              prop: "address",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "font-size": "14px" },
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.edittab(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "font-size": "14px" },
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.del(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.from.current,
          layout: "total, sizes, prev, pager, next, jumper",
          "page-size": _vm.from.size,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("adddialog", {
        attrs: { show: _vm.addshow, value: _vm.lis },
        on: { addshows: _vm.listinfo },
      }),
      _c("editdialog", {
        attrs: { show: _vm.addshows, value: _vm.editlist },
        on: { addshows: _vm.listinfoa },
      }),
      _c("showdialog", {
        attrs: { show: _vm.showtab, value: _vm.showlist },
        on: { addshows: _vm.shotabclose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }