import request from '@/utils/request'

export function getGoodsList(data) {
  return request({
    url: `/goods/proGoodsCategory/page`,
    method: 'post',
    data,
  })
}

export function getGoodsSubList(data) {
  return request({
    url: `/goods/proGoodsCategory/subList`,
    method: 'post',
    data,
  })
}

export function categoryDisable(data) {
  return request({
    url: `/goods/proGoodsCategory/disable`,
    method: 'post',
    data,
  })
}

export function categoryRecovery(data) {
  return request({
    url: `/goods/proGoodsCategory/recovery`,
    method: 'post',
    data,
  })
}

export function categoryDelete(data) {
  return request({
    url: `/goods/proGoodsCategory/delete`,
    method: 'post',
    data,
  })
}

export function categoryAdd(data) {
  return request({
    url: `/goods/proGoodsCategory/add`,
    method: 'post',
    data,
  })
}

export function categoryUpdate(data) {
  return request({
    url: `/goods/proGoodsCategory/update`,
    method: 'post',
    data,
  })
}
