var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("Search"),
      _c(
        "div",
        { staticClass: "add-new", staticStyle: { "margin-bottom": "15px" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.add } },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tableData },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "会员名称", prop: "date" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "性别", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "年龄", prop: "address" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "设备类型", prop: "address" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "设备SN码", prop: "address" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "检测时间", prop: "address" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "收缩压mmHg", prop: "address" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "舒张压mmHg", prop: "address" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "上传方式", prop: "address" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", prop: "address" },
          }),
          _c(
            "el-table-column",
            { attrs: { align: "center", fixed: "right", label: "操作" } },
            [
              [
                _c(
                  "el-button",
                  { attrs: { type: "text" }, on: { click: _vm.edit } },
                  [_vm._v("编辑")]
                ),
                _c("el-button", { attrs: { type: "text" } }, [_vm._v("删除")]),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": 1,
          layout: "total, sizes, prev, pager, next, jumper",
          "page-size": _vm.form.size,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("AddTa", {
        attrs: {
          content: _vm.infocheck,
          labname: _vm.info,
          show: _vm.shoahand,
        },
        on: { close: _vm.closedia },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }