<template>
  <div class="Dinage">
    <el-dialog
      :before-close="handleClose"
      title="详细信息"
      :visible.sync="hides"
      width="50%">
      <div class="ym_container">
        <div v-for="(ym,index) in list " :key="index" class="ym_question">
          <div class="ym_qustions-title">{{ym.title}}</div>
          <div class="ym_question-list">
            <el-tag v-for="(qustion,number) in ym.caseVos" :key="number">{{qustion.caseName}}</el-tag>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryList } from '@/api/mall/health/healthRecordArchive/healthRecordArchive'
export default {
  name:"DialogBox",
  props:{
    show:{
      type:Boolean,
      required:true,
      default:()=>{}
    },
    date:{
      type:Object,
      required:true,
      default:()=>{}
    }
  },
  data(){
    return{
      hides:this.show,
      datelist:this.date,
      list:''
    }
  },
  watch:{
    show(oldValue,newValue){
      if(oldValue!=newValue){
        this.hides=oldValue 
      }
    },
    date(newValue,oldValue){
      if(oldValue!=newValue){
        this.datelist=newValue 
        // console.log(this.datelist)
        this.queryList()
      }
    }
  },
  methods:{
    handleClose(){
      this.$emit("showbox",false)
    },
    queryList(){
      queryList({userId:this.datelist.userId,id:this.datelist.Id}).then(res=>{
        // console.log(res)
        this.list=res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    .ym_question{
      margin: 10px 0 ;
      margin-left: 10px;
      padding: 10px 0;
      .ym_qustions-title{
        width: auto;
        position: relative;
        margin: 10px 0;
        margin-left: 10px;
        &::before{
          content: "";
          width: 3px;
          height: 100%;
          background: #3782ff;
          position: absolute;
          left: -10px;
        }
      }
      .ym_question-list{
        margin-left: 10px;
      }
    }
    ::v-deep .el-dialog__header{
      border-bottom: 1px dashed #d8d8d8;
    }
</style>