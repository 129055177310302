var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OrderDetail cy-container" },
    [
      _c(
        "el-row",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            padding: "20px",
            background: "#f0f0f0",
          },
        },
        [_c("el-col", { staticClass: "fs16" }, [_vm._v("订单详情")])],
        1
      ),
      _c(
        "div",
        { staticClass: "detaileMag" },
        [
          _c(
            "el-row",
            { staticClass: "ym_row" },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1, title: "订单信息" } },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "订单编号" } },
                          [_vm._v(" " + _vm._s(_vm.orderDetails.orderNo) + " ")]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "订单类型" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.orderDetails.orderType) + " "
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "支付方式" } },
                          [
                            _vm.orderDetails.paymentWay == 1
                              ? _c("span", [_vm._v("微信支付")])
                              : _vm._e(),
                            _vm.orderDetails.paymentWay == 2
                              ? _c("span", [_vm._v("支付宝支付")])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-descriptions",
                      {
                        attrs: {
                          column: 2,
                          direction: "horizontal",
                          title: "付款信息",
                        },
                      },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "订单金额" } },
                          [
                            _vm.orderDetails
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.orderDetails.salesPrice
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "支付金额" } },
                          [
                            _vm.orderDetails
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.orderDetails.paymentPrice
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "支付积分" } },
                          [
                            _vm.orderDetails
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.orderDetails.paymentPoints
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "运费金额" } },
                          [
                            _vm.orderDetails
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.orderDetails.freightPrice
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "优惠券抵扣金额" } },
                          [
                            _vm.orderDetails
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.orderDetails.paymentCouponPrice
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "积分抵扣金额" } },
                          [
                            _vm.orderDetails
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.orderDetails.paymentPointsPrice
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1, title: "收货人信息" } },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "收货人" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.orderDetails.orderLogistics
                                    ? _vm.orderDetails.orderLogistics.userName
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "联系电话" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.orderDetails.orderLogistics
                                    ? _vm.orderDetails.orderLogistics.telNum
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "收货地址" } },
                          [
                            _vm.orderDetails.orderLogistics
                              ? _c("div", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetails.orderLogistics.province
                                      )
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetails.orderLogistics.city
                                      )
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetails.orderLogistics.county
                                      )
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.orderDetails.orderLogistics
                                            .address
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1, title: "买家信息" } },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "买家名称" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.orderDetails.userInfo.nickName || ""
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "买家手机号" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.orderDetails.userInfo.phone) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          {
                            staticClass: "buyMas",
                            attrs: { label: "买家留言" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.orderDetails.userRemark) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-descriptions",
        { staticClass: "mt10", attrs: { title: "配送信息" } },
        [
          _c("el-descriptions-item", { attrs: { label: "配送方式" } }, [
            _vm._v(" " + _vm._s(_vm.deliveryWayDesc) + " "),
          ]),
        ],
        1
      ),
      _c(
        "el-descriptions",
        {
          staticClass: "mt10",
          attrs: { colon: false, column: 1, title: "订单状态" },
        },
        [
          _c("el-descriptions-item", { attrs: { label: "订单状态:" } }, [
            _c("div", [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c("span", { staticClass: "ml20 colorRed" }, [
                  _vm._v(_vm._s(_vm.orderDetails.status)),
                ]),
                _vm.orderStateCheck == 0
                  ? _c(
                      "span",
                      { staticClass: "ml20 colorRed" },
                      [
                        _vm._v(" 剩余付款时间: "),
                        _c("count-down", {
                          attrs: {
                            color: "#ff0000",
                            "font-size": "14px",
                            "separator-color": "#ff0000",
                            timestamp:
                              parseInt(_vm.endTime - _vm.serverTime) / 1000,
                          },
                          on: { end: _vm.timeEnd },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm.orderStateCheck == 1
            ? _c(
                "el-descriptions-item",
                { staticClass: "adjustPrice" },
                _vm._l(_vm.pageButton, function (item) {
                  return _c(
                    "el-button",
                    {
                      key: item.id,
                      attrs: {
                        plain:
                          item.cssType && item.cssType == "plain"
                            ? true
                            : false,
                        size: "mini",
                        type: item.buttonCss,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleClickBtn(item)
                        },
                      },
                    },
                    [
                      !item.buttonIcon
                        ? _c("span")
                        : _c("vab-icon", { attrs: { icon: item.buttonIcon } }),
                      _vm._v(" " + _vm._s(item.buttonName) + " "),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.orderStateCheck == 5
            ? _c("el-descriptions-item", { attrs: { label: "取消原因:" } }, [
                _vm.orderDetails.orderSummary
                  ? _c("span", { staticClass: "ml20" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.orderDetails.orderSummary.cancelRemark) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.orderStateCheck == -1
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "客情类型:" } },
                [
                  _c("span", { staticClass: "ml20" }, [_vm._v("退货退款")]),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "primary" },
                      on: { click: _vm.handelAfterSales },
                    },
                    [_vm._v("查看详情")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.orderStateCheck == 10
            ? _c("el-descriptions-item", { staticClass: "adjustPrice" })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-descriptions",
            {
              staticClass: "mt10 goodsmsgs",
              attrs: { colon: false, title: "商品信息" },
            },
            [
              _c(
                "el-descriptions-item",
                { staticClass: "adjustPrice", staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "tables",
                      attrs: {
                        border: "",
                        data: _vm.goodsMsgTable,
                        "show-summary": "",
                        "summary-method": _vm.getSummaries,
                      },
                    },
                    [
                      _vm.comOrderType != 70
                        ? _c("el-table-column", {
                            key: "1",
                            attrs: {
                              label: "子订单号",
                              prop: "orderId",
                              width: "200px",
                            },
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名称",
                          prop: "spuName",
                          "show-overflow-tooltip": "",
                          width: "200px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "商品图片" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("el-image", {
                                  attrs: {
                                    alt: "",
                                    fit: "fill",
                                    "preview-src-list": [row.picUrl],
                                    src: row.picUrl,
                                    srcset: "",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        key: "3",
                        attrs: {
                          formatter: _vm.isRealType,
                          label: "商品类型",
                          prop: "isReal",
                        },
                      }),
                      _c("el-table-column", {
                        key: "4",
                        attrs: { label: "商品售价", prop: "salesPrice" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm._f("numFormat")(row.salesPrice))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm.comOrderType != 70
                        ? _c("el-table-column", {
                            key: "6",
                            attrs: { label: "折扣", prop: "discountRatio" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c("div", [
                                        _vm._v(_vm._s(row.discountRatio) + "%"),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3099620673
                            ),
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        key: "7",
                        attrs: { label: "付款金额" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("numFormat")(row.paymentPrice)
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        key: "8",
                        attrs: { label: "数量", prop: "quantity" },
                      }),
                      _c("el-table-column", {
                        key: "9",
                        attrs: { label: "商品规格", prop: "specInfo" },
                      }),
                      _vm.comOrderType != 70
                        ? _c("el-table-column", {
                            key: "10",
                            attrs: {
                              label: "金豆抵扣",
                              prop: "goldenBeanPayment",
                            },
                          })
                        : _vm._e(),
                      _vm.comOrderType == 70
                        ? _c("el-table-column", {
                            key: "11",
                            attrs: { label: "小计（元）" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("numFormat")(
                                                row.balancePayment *
                                                  row.goodsNumber
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3182377631
                            ),
                          })
                        : _c("el-table-column", {
                            key: "12",
                            attrs: {
                              label: "小计（元）",
                              prop: "balancePayment",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("numFormat")(
                                              row.balancePayment + row.paidPrice
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                      _c("el-table-column", {
                        key: "13",
                        attrs: { label: "发货店铺" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({}) {
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(_vm.orderDetails.shopId)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        key: "14",
                        attrs: { label: "物流单号", prop: "deliveryCode" },
                      }),
                      _c("el-table-column", {
                        key: "15",
                        attrs: {
                          formatter: _vm.formatterOrder,
                          label: "状态",
                          prop: "status",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.deliveryCode
                                  ? _vm._l(_vm.ListButton, function (item) {
                                      return _c(
                                        "el-button",
                                        {
                                          key: item.id,
                                          attrs: {
                                            plain:
                                              item.cssType &&
                                              item.cssType == "plain"
                                                ? true
                                                : false,
                                            size: "mini",
                                            type: item.buttonCss,
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.handleClickBtn(
                                                item,
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          !item.buttonIcon
                                            ? _c("span")
                                            : _c("vab-icon", {
                                                attrs: {
                                                  icon: item.buttonIcon,
                                                },
                                              }),
                                          _vm._v(
                                            " " + _vm._s(item.buttonName) + " "
                                          ),
                                        ],
                                        1
                                      )
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "orderMoney" }, [
            _vm._v(
              " 订单共" + _vm._s(_vm.orderDetails.goodsNumber) + "件商品 "
            ),
            _vm.orderDetails.orderSummary
              ? _c("span", [
                  _vm._v(" ，共计 "),
                  _c("i", { staticClass: "colorRed" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("numFormat")(
                            _vm.orderDetails.balancePayment +
                              _vm.orderDetails.paidAmount
                          )
                        ) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm.comOrderType == 10 ||
      _vm.comOrderType == 20 ||
      _vm.comOrderType == 21 ||
      _vm.comOrderType == 22
        ? _c(
            "div",
            [
              _c("el-descriptions", {
                staticClass: "mt10",
                attrs: { title: "分润信息" },
              }),
              _c("div", { staticClass: "ml20 mb10" }, [
                _c("span", [_vm._v("累计分润")]),
                _vm.orderDetails.profitSurveyVo
                  ? _c("span", { staticClass: "colorRed" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("numFormat")(
                              _vm.orderDetails.profitSurveyVo.totalProfit
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "purpleMsg fs12 ml20 mt20" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c("el-col", { attrs: { span: 5 } }, [
                        _vm.orderDetails.profitSurveyVo &&
                        _vm.orderDetails.profitSurveyVo.txGroup != null
                          ? _c(
                              "div",
                              [
                                _c("div", { staticClass: "purpleMsgTitel" }, [
                                  _vm._v("销售分销"),
                                ]),
                                _vm._l(
                                  _vm.orderDetails.profitSurveyVo.txGroup,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _c("div", { staticClass: "purList" }, [
                                        _c(
                                          "span",
                                          { staticClass: "purpleMsgName" },
                                          [_vm._v("用户名称:")]
                                        ),
                                        _vm.orderDetails.profitSurveyVo &&
                                        _vm.orderDetails.profitSurveyVo
                                          .txGroup != null
                                          ? _c("span", [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _c("div", { staticClass: "purList" }, [
                                        _c(
                                          "span",
                                          { staticClass: "purpleMsgName" },
                                          [_vm._v("分润金额:")]
                                        ),
                                        _vm.orderDetails.profitSurveyVo &&
                                        _vm.orderDetails.profitSurveyVo
                                          .txGroup != null
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("numFormat")(
                                                      item.profit
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                    ])
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c("div", { staticClass: "grid-content bg-purple" }, [
                          _c("div", { staticClass: "purpleMsgTitel" }, [
                            _vm._v("管道分润"),
                          ]),
                          _c("div", { staticClass: "purList" }, [
                            _c("span", { staticClass: "purpleMsgName" }, [
                              _vm._v("区代用户名称:"),
                            ]),
                            _vm.orderDetails.profitSurveyVo &&
                            _vm.orderDetails.profitSurveyVo.serProfit != null
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.orderDetails.profitSurveyVo
                                          .serProfit[0].name
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "purList" }, [
                            _c("span", { staticClass: "purpleMsgName" }, [
                              _vm._v("区代分润金额:"),
                            ]),
                            _vm.orderDetails.profitSurveyVo &&
                            _vm.orderDetails.profitSurveyVo.serProfit != null
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.orderDetails.profitSurveyVo
                                            .serProfit[0].profit
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "purList" }, [
                            _c("span", { staticClass: "purpleMsgName" }, [
                              _vm._v("省代用户名称:"),
                            ]),
                            _vm.orderDetails.profitSurveyVo &&
                            _vm.orderDetails.profitSurveyVo.opeProfit != null
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.orderDetails.profitSurveyVo
                                          .opeProfit[0].name
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "purList" }, [
                            _c("span", { staticClass: "purpleMsgName" }, [
                              _vm._v("省代分润金额:"),
                            ]),
                            _vm.orderDetails.profitSurveyVo &&
                            _vm.orderDetails.profitSurveyVo.opeProfit != null
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.orderDetails.profitSurveyVo
                                            .opeProfit[0].profit
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c("div", { staticClass: "grid-content bg-purple" }, [
                          _c("div", { staticClass: "purpleMsgTitel" }, [
                            _vm._v("门店"),
                          ]),
                          _c("div", { staticClass: "purList" }, [
                            _c("span", { staticClass: "purpleMsgName" }, [
                              _vm._v("门店名称:"),
                            ]),
                            _vm.orderDetails.profitSurveyVo &&
                            _vm.orderDetails.profitSurveyVo.shopProfit
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.orderDetails.profitSurveyVo
                                          .shopProfit[0].name
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "purList" }, [
                            _c("span", { staticClass: "purpleMsgName" }, [
                              _vm._v("门店货款:"),
                            ]),
                            _vm.orderDetails.profitSurveyVo &&
                            _vm.orderDetails.profitSurveyVo.shopProfit
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.orderDetails.profitSurveyVo
                                            .shopProfit[0].goodsCost
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "purList" }, [
                            _c("span", { staticClass: "purpleMsgName" }, [
                              _vm._v("门店收益:"),
                            ]),
                            _vm.orderDetails.profitSurveyVo &&
                            _vm.orderDetails.profitSurveyVo.shopProfit
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.orderDetails.profitSurveyVo
                                            .shopProfit[0].profit
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _vm.orderDetails.profitSurveyVo &&
                        _vm.orderDetails.profitSurveyVo.txPlatform
                          ? _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c("div", { staticClass: "purpleMsgTitel" }, [
                                  _vm._v("平台"),
                                ]),
                                _c("div", { staticClass: "purList" }, [
                                  _c("span", { staticClass: "purpleMsgName" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetails.profitSurveyVo
                                          .txPlatform[0].name
                                      ) + ":"
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("numFormat")(
                                            _vm.orderDetails.profitSurveyVo
                                              .txPlatform[0].profit
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _vm.orderDetails.profitSurveyVo &&
                        _vm.orderDetails.profitSurveyVo.originTxProfit
                          ? _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c("div", { staticClass: "purpleMsgTitel" }, [
                                  _vm._v("发起人"),
                                ]),
                                _c("div", { staticClass: "purList" }, [
                                  _c("span", { staticClass: "purpleMsgName" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetails.profitSurveyVo
                                          .originTxProfit[0].name
                                      ) + ":"
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("numFormat")(
                                            _vm.orderDetails.profitSurveyVo
                                              .originTxProfit[0].profit
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.orderDetails.profitSurveyVo &&
                        _vm.orderDetails.profitSurveyVo.shopConsumerProfit
                          ? _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c("div", { staticClass: "purpleMsgTitel" }, [
                                  _vm._v("顾客直推门店"),
                                ]),
                                _c("div", { staticClass: "purList" }, [
                                  _c("span", { staticClass: "purpleMsgName" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetails.profitSurveyVo
                                          .shopConsumerProfit[0].name
                                      ) + ":"
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("numFormat")(
                                            _vm.orderDetails.profitSurveyVo
                                              .shopConsumerProfit[0].profit
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("delivery", {
        ref: "delivery",
        attrs: { deliverys: _vm.orderDetails },
        on: { getorder: _vm.getorder },
      }),
      _c("order-logo", { ref: "orderlogo" }),
      _c("edit-logistics", { ref: "editlogistics" }),
      _c("details-logistics", { ref: "detailslogistics" }),
      _c("reply-evaluate", { ref: "replyevaluate" }),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.imgDialog },
          on: {
            "update:visible": function ($event) {
              _vm.imgDialog = $event
            },
          },
        },
        [_c("img", { attrs: { src: _vm.imagesUrl, width: "100%" } })]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改收货地址",
            visible: _vm.dialogFormVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v("确 定")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-form",
            {
              ref: "postForm",
              attrs: {
                "label-width": "80px",
                model: _vm.postForm,
                rules: _vm.postFormRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "收货人", prop: "consignee" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.postForm.consignee,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.postForm,
                          "consignee",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "postForm.consignee",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "11",
                      oninput: "value=value.replace(/[^\\d]/g,'')",
                    },
                    model: {
                      value: _vm.postForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "phone", _vm._n($$v))
                      },
                      expression: "postForm.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收货地址", prop: "provinces" } },
                [
                  _c("el-cascader", {
                    staticClass: "full-width",
                    staticStyle: { width: "80%" },
                    attrs: {
                      options: _vm.areaSelectData,
                      placeholder: "请选择地址",
                      props: {
                        children: "children",
                        label: "areaName",
                        value: "areaName",
                      },
                      size: "large",
                    },
                    model: {
                      value: _vm.postForm.provinces,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.postForm,
                          "provinces",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "postForm.provinces",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "receivingAddress" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入详细地址" },
                    model: {
                      value: _vm.postForm.receivingAddress,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.postForm,
                          "receivingAddress",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "postForm.receivingAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }