var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "custom-table", staticClass: "custom-table-container" },
    [
      _c("searchform", { on: { receive: _vm.receiverow } }),
      _c(
        "el-table",
        {
          attrs: {
            border: "",
            data: _vm.dataList,
            "max-height": "600",
            size: "medium",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              "show-overflow-tooltip": "",
              type: "index",
              width: "50",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "客户姓名",
              prop: "userName",
              width: "190",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.userNameClick(scope.row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(scope.row.userName) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "客户id", prop: "userId" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "部门",
              prop: "postName",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "员工id", prop: "sysId" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "员工姓名", prop: "realName" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "累计积分", prop: "totalPoint" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "使用积分", prop: "usePoint" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "积分余额",
              prop: "pointsCurrent",
            },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.current,
          layout: "total, sizes, prev, pager, next, jumper",
          "page-size": _vm.queryForm.size,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "积分明细",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.detailData } },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "客户id", prop: "userId" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "客户姓名", prop: "userName" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "抵扣积分", prop: "amount" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "描述",
                  prop: "description",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "创建时间",
                  prop: "createTime",
                  width: "300",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }