var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "查看",
            visible: _vm.dialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-collapse",
                {
                  staticStyle: { border: "1px solid #ebeef5" },
                  model: {
                    value: _vm.shownumber,
                    callback: function ($$v) {
                      _vm.shownumber = $$v
                    },
                    expression: "shownumber",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    {
                      staticStyle: { "text-indent": "1em" },
                      attrs: { name: "1", title: "店铺信息" },
                    },
                    [
                      _c(
                        "el-descriptions",
                        {
                          staticClass: "margin-top",
                          attrs: { border: "", column: 2, title: "" },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 店铺名: "),
                              ]),
                              _vm._v(" " + _vm._s(_vm.activeNames.name) + " "),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 电话: "),
                              ]),
                              _vm._v(" " + _vm._s(_vm.activeNames.phone) + " "),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 详细地址: "),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.activeNames.address) + " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 详细介绍: "),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.activeNames.detail) + " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 店铺Logo: "),
                              ]),
                              _c("el-image", {
                                staticStyle: { width: "60px", height: "60px" },
                                attrs: { src: _vm.activeNames.imgUrl },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-collapse-item",
                    {
                      staticStyle: { "text-indent": "1em" },
                      attrs: { name: "2", title: "店长信息" },
                    },
                    [
                      _c(
                        "el-descriptions",
                        {
                          staticClass: "margin-top",
                          attrs: { border: "", column: 2, title: "" },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 昵称: "),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.activeNames.userNickname) + " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 登陆账号: "),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.activeNames.userUsername) + " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 手机号: "),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.activeNames.userPhone) + " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 邮箱: "),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.activeNames.userEmail) + " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 店长头像: "),
                              ]),
                              _c("el-image", {
                                staticStyle: { width: "60px", height: "60px" },
                                attrs: { src: _vm.activeNames.userAvatar },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-collapse-item",
                    {
                      staticStyle: { "text-indent": "1em" },
                      attrs: { name: "3", title: "审核信息" },
                    },
                    [
                      _c(
                        "el-descriptions",
                        {
                          staticClass: "margin-top",
                          attrs: { border: "", column: 2, title: "" },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 编号: "),
                              ]),
                              _vm._v(" " + _vm._s(_vm.activeNames.id) + " "),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 更新时间: "),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.activeNames.updateTime) + " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 审核状态: "),
                              ]),
                              _vm.activeNames.status == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("通过"),
                                  ])
                                : _vm._e(),
                              _vm.activeNames.status == 2
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("审核不通过"),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 审核意见: "),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.activeNames.applyDetail) + " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 店铺: "),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.activeNames.shopId) + " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 创建时间: "),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.activeNames.createTime) + " "
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }