<template>
  <div>
    <el-dialog
      title="添加海报"
      :visible.sync="dialogFormVisible"
      @close="close"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules">
        <el-form-item label="商品分类：" :label-width="formLabelWidth">
          <span>{{ form.parentName }} &gt; {{ form.catName }}</span>
        </el-form-item>
        <el-form-item label="海报图片：" :label-width="formLabelWidth">
          <div>
            <el-upload
              accept=".png,.jpg,.jpeg"
              :action="uploadParams.uploadURL"
              :file-list="uploadParams.fileList"
              :headers="{ Authorization: uploadParams.token }"
              :limit="1"
              list-type="picture-card"
              :on-exceed="handleExceed"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="handleUploadCatImageSuccess"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog append-to-body :visible.sync="dialogVisible">
              <img :src="uploadParams.dialogImageUrl" width="100%" />
            </el-dialog>
          </div>
        </el-form-item>
        <el-form-item
          label="商品链接："
          :label-width="formLabelWidth"
          prop="relateUrl"
        >
          <el-input
            v-model.trim="form.relateUrl"
            placeholder="请输入商品链接"
            :rows="10"
            style="width: 500px"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          :loading="confirmBtnLoading"
          type="primary"
          @click="handleConfirm"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { categoryUpdate } from '@/api/mall/shop/shopCategory'
  
  import { getToken } from '@/utils/token'
  import { uploadURL } from '@/config'
  import { isUrl } from '@/utils/validate'
  export default {
    data() {
      const checkNumber = (rule, value, callback) => {
        if (!Number.isInteger(Number(value))) {
          callback(new Error('请输入数字值!'))
        }
      }
      const isRealUrl = (rule, value, callback) => {
        if (!isUrl(value)) {
          callback(new Error('请输入正确的网站！'))
        } else {
          callback()
        }
      }
      return {
        dialogFormVisible: false,
        dialogVisible: false,
        confirmBtnLoading: false,
        form: {},
        uploadParams: {
          fileList: [],
          token: `Bearer ${getToken()}`,
          uploadURL,
          dialogImageUrl: '',
        },

        rules: {
          catName: [
            { required: true, message: '分类名称必填！', trigger: 'blur' },
          ],
          relateUrl: [
            { required: true, message: '商品链接必填！', trigger: 'blur' },
            { validator: isRealUrl, trigger: 'blur' },
          ],
          sortNumber: [
            { required: true, message: '分类排序必填！', trigger: 'blur' },
            { validator: checkNumber, trigger: 'blur' },
          ],
        },
        formLabelWidth: '120px',
      }
    },
    methods: {
      show(data) {
        this.form = data
        if (this.form.catImage) {
          this.uploadParams.fileList.push({
            url: this.form.catImage,
          })
        }
        console.log(data)
        this.dialogFormVisible = true
      },
      close() {
        this.$emit('fetch')
        this.resetForm()
        this.dialogFormVisible = false
      },
      handleConfirm() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid && this.form.catImage) {
            this.confirmBtnLoading = true
            categoryUpdate({
              id: this.form.id,
              isShow: this.form.isShow,
              isAfterSales: this.form.isAfterSales,
              catImage: this.form.catImage,
              relateUrl: this.form.relateUrl,
            })
              .then(() => {
                this.$message.success('编辑海报成功！')
              })
              .catch(() => {
                this.$message.error('编辑海报失败！')
              })
              .finally(() => {
                this.confirmBtnLoading = false
                this.close()
              })
          } else {
            this.$message.error('参数错误！请重新配置')
          }
        })
      },
      handleRemove() {
        this.form.catImage = ''
      },
      handlePictureCardPreview(file) {
        this.uploadParams.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      handleUploadCatImageSuccess(response) {
        const {
          data: { filePath: path },
        } = response
        this.form.catImage = path
      },
      // handleUploadCatImageSuccess(response) {
      //   const {
      //     data: { filePath: path },
      //   } = response
      //   this.form.catImage = path
      // },
      handleExceed() {
        this.$message.error('只允许有一张海报图片！')
      },
      resetForm() {
        this.uploadParams.fileList = []
        this.form = {
          catName: '',
          catLogo: '',
          parentId: -1,
          sortNumber: 0,
          isShow: 0,
          remarks: '',
          isAfterSales: '',
        }
      },
    },
  }
</script>

<style scoped>
  .el-alert {
    padding: 0 !important;
  }
</style>
