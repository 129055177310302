<template>
  <div class="comprehensive-table-container">
    <div class="ym_head_title" style="border-bottom: 1px dashed #e8e8e8">选择会员</div>
    <div class="header_banner">
      <div class="content">
        <div v-for="(ym, index) in banner" :key="index" class="txt">{{ ym.txt }}</div>
      </div>
    </div>
    <el-button v-if="!checkuser.nickName" type="primary" @click="sel">选择下单会员</el-button>
    <el-button v-else type="primary" @click="sel">重新选择</el-button>
    <div v-show="checkuser.nickName" class="user_info">
      <div class="user_picurl">
        <el-image lazy :src="checkuser.headimgUrl"  style="width: 40px; height: 40px; border-radius: 100%" />
      </div>
      <div class="user_msg">
        <div class="user_name" style="margin: 2px 0">姓名:{{ checkuser.nickName }}</div>
        <div class="user_name" style="margin: 2px 0">编号:{{ checkuser.userCode }}</div>
        <div class="userphone" style="margin: 2px 0; white-space: nowrap">手机号:{{ checkuser.phone }}</div>
      </div>
    </div>
    <div class="ym_head_title" style="margin-top: 50px">选择会员</div>
    <div class="container">
      <div class="shopall">
        <div class="cont_title">全部商品</div>
        <div class="cont_al">
          <div class="shop_content">
            <div class="search">
              <el-form :model="searchData">
                <el-form-item label="">
                  <el-input v-model="searchData.searcont" placeholder="请输入商品名称" suffix-icon="el-icon-search" />
                </el-form-item>
              </el-form>
            </div>
            <el-table :data="tabData" max-height="500px">
              <el-table-column label="" prop="demo" width="60">
                <template #default="scope">
                  <el-image :src="scope.row.picUrls[0]" style="width: 50px; height: 50px" />
                </template>
              </el-table-column>
              <el-table-column label="" prop="">
                <template #default="scope">
                  <div class="shop_title">{{ scope.row.name }}</div>
                  <div class="shop_info">
                    <div class="shop_much">销量：{{ scope.row.saleNum }}</div>
                    <!-- <div class="shop_stock">
                      库存：
                      <span>{{ scope.row.stock }}</span>
                    </div> -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="" prop="" width="70">
                <template #default="scope">
                  <el-button type="primary" @click="seltion(scope.row.id)">选择</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="sholt">
              <el-pagination background :current-page="1" layout=" prev, pager, next" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
            </div>
          </div>
        </div>
      </div>
      <div class="shopall">
        <div class="cont_title">已选择</div>
        <div class="cont_al">
          <div class="shop_content">
            <el-table :data="checkout" max-height="600px">
              <el-table-column label="" prop="picUrl" width="60">
                <template #default="scope">
                  <el-image :src="scope.row.picUrl" style="width: 50px; height: 50px" />
                </template>
              </el-table-column>
              <el-table-column label="" prop="">
                <template #default="scope">
                  <div class="shop_title">{{ scope.row.name }}</div>
                  <div class="msg_info" style="font-size: 12px; color: gray">{{ scope.row.specValueNames }}</div>
                  <div class="shop_info">
                    <div class="shop_stock">
                      <span>￥{{ scope.row.salesPrice }}</span>
                    </div>
                    <el-input-number v-model="scope.row.quantity" label="数量" size="mini" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="" prop="" width="70">
                <template #default="scope">
                  <el-button type="primary" @click.native.prevent="deleteRow(scope.$index, checkout)">移除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <div class="solt" style="display: flex; justify-content: center"><el-button type="primary" @click="checklink">去结算</el-button></div>
    <addmember :hidemenber="menubar" @menbarrow="trows" />
    <selectsku :showtab="boelan" :spuid="spu" @selsku="selku" />
  </div>
</template>

<script>
  import { getPage } from '@/api/mall/goodspu/goodspu'
  import { getUserInfo } from '@/api/mall/users/userLevel'
  import addmember from './components/addmember.vue'
  import selectsku from './components/selectsku.vue'
  export default {
    components: {
      addmember,
      selectsku,
    },
    data() {
      return {
        form: {
          size: 10,
          current: 1,
        },
        total: 0,
        banner: [
          { txt: '用前须知：' },
          { txt: '①代客下单中，没有支付流程，生成的订单支付方式为“后台确认”，订单来源为“代客下单”；' },
          { txt: '②只能生成“普通订单”，无法创建“秒杀”“砍价”“拼团”“定金膨胀”等活动订单类型；' },
          { txt: '③订单金额按商品原价计算，会员价、会员卡折扣可以在订单确认界面进行批量计算。' },
        ],
        searchData: {},
        tabData: [],
        checkout: [],
        menubar: false,
        checkuser: {},
        spu: null,
        boelan: false,
      }
    },
    created() {
      const checkuser=this.$route.query.date
      const userlist=this.$route.query.userlist
      if(userlist){
       this.seleuser(userlist.userId)
      }
      if(checkuser){
        this.checkuser=JSON.parse(JSON.stringify(checkuser))
      }
      this.getPage()
    },
    methods: {
      seleuser(e){
        getUserInfo({userCode:e}).then(res=>{
          this.checkuser=res.data
        })
      },
      getPage() {
        getPage({ ...this.form }).then((res) => {
          this.form.size = res.data.size
          this.form.current = res.data.current
          this.total = res.data.total
          this.tabData = res.data.records
        })
      },
      handleCurrentChange() {},
      handleSizeChange() {},
      checklink() {
        console.log(this.checkuser)
        if (this.checkuser.nickName) {
          let date = {
            userinfo: this.checkuser,
            checkout: this.checkout,
          }
          this.$router.push({ path: '/activity/confirm-order/confirm', query: { date } })
        } else {
          this.$message.error('请选择代客信息')
        }
      },
      sel() {
        this.menubar = true
      },
      trows(row) {
        this.menubar = false
        if (row) {
          this.checkuser = row
        }
      },
      deleteRow(index, rows) {
        rows.splice(index, 1)
      },
      seltion(e) {
        this.spu = e
        this.boelan = true
      },
      selku(row) {
        this.boelan = false
        if (this.checkout.length < 1) {
          this.checkout.push(row)
        } else {
          let bolean = this.checkout.findIndex((item) => item.spuId === row.spuId && item.specsId === row.specsId)
          if (bolean > -1) {
            const matchedItem = this.checkout[bolean]
            this.$set(matchedItem, 'quantity', matchedItem.quantity + Number(row.quantity))
          } else {
            this.checkout.push(row)
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .comprehensive-table-container {
    .ym_head_title {
      height: 40px;
      font-size: 14px;
      font-weight: bold;
      line-height: 40px;
      color: #333;
      border-bottom: 1px solid #e8e8e8;
    }
    .header_banner {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 10px 0;
      margin: 10px auto;
      margin-top: 30px;
      background: #e5f0fe;
      border: 1px solid #d8e7ff;
      border-radius: 16px;
      .txt {
        margin-left: 20px;
        font-size: 12px;
        line-height: 25px;
        color: #666;
      }
    }
    .container {
      display: flex;
      width: 100%;
      padding: 30px 0;
    }
    .shopall {
      width: 35%;
      padding: 10px;
      margin-right: 20px;
      .cont_title {
        font-size: 12px;
        color: #fb6638;
      }
      .cont_al {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        padding: 15px 0;
        margin-top: 10px;
        border: 1px solid #e8e8e8;
        .shop_content {
          position: relative;
          width: calc(100% - 30px);
          height: 600px;
          .shop_info {
            display: flex;
            justify-content: space-between;
            .shop_stock {
              span {
                color: #ff6633;
              }
            }
          }
          .sholt {
            position: absolute;
            bottom: 5px;
          }
        }
      }
    }
  }
  ::v-deep .has-gutter {
    display: none;
  }
  .user_info {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    align-items: center;
    width: 200px;
    height: 95px;
    margin: 10px 0;
    margin-top: 20px;
    font-size: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    .user_picurl {
      margin-left: 10px;
    }
  }
</style>
