import request from '@/utils/request'

export function getGoodsSpecsList(data) {
  return request({
    url: `/goods/proGoodsSpecsTemplate/page`,
    method: 'post',
    data,
  })
}

export function addGoodsSpecs(data) {
  return request({
    url: `/goods/proGoodsSpecsTemplate/add`,
    method: 'post',
    data,
  })
}

export function deleteGoodsSpecs(data) {
  return request({
    url: `/goods/proGoodsSpecsTemplate/delete`,
    method: 'post',
    data,
  })
}

export function updateGoodsSpecs(data) {
  return request({
    url: `/goods/proGoodsSpecsTemplate/update`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}

export function getPage(query) {
  return request({
    url: '/mall/goodsspec/page',
    method: 'get',
    params: query
  })
}
export function addObj(obj) {
  return request({
    url: '/mall/goodsspec',
    method: 'post',
    data: obj
  })
}
export function putObj(obj) {
  return request({
    url: '/mall/goodsspec',
    method: 'put',
    data: obj
  })
}

export function delObj(id) {
  return request({
    url: '/mall/goodsspec/' + id,
    method: 'delete'
  })
}
export function getObj(id) {
  return request({
    url: '/mall/freighttemplat/getlist',
    method: 'get',
    params: id
  })
}

export function getObjtype(id) {
  return request({
    url: '/mall/goodscategory/tree',
    method: 'get',
    params: id
  })
}

export function goodscategoryshop(id) {
  return request({
    url: '/mall/goodscategoryshop/tree',
    method: 'get',
    params: id
  })
}

export function getlistmore(id) {
  return request({
    url: '/mall/goodsspecvalue/page',
    method: 'get',
    params: id
  })
}

export function addnewobj(id) {
  return request({
    url: '/mall/goodsspecvalue',
    method: 'POST',
    data: id
  })
}
export function updateput(id) {
  return request({
    url: '/mall/goodsspecvalue',
    method: 'PUT',
    data: id
  })
}
export function deleteobj(id) {
  return request({
    url: '/mall/goodsspecvalue/'+id.id,
    method: 'delete',
  })
}
