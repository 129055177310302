<template>
  <div class="comprehensive-table-container">
    <div class="content">
      <div class="add-temp">
        <el-button icon="el-icon-plus" type="primary" @click="dialogVisible = true">创建标签</el-button>
      </div>
      <div class="temp">
        <el-table ref="singleTable" border :data="template" style="width: 100%">
          <el-table-column align="center" fixed="left" label="序号" :show-overflow-tooltip="true" type="index" width="200" />
          <el-table-column align="center" label="标签名称" property="tagName" :show-overflow-tooltip="true" />
          <el-table-column align="center" label="会员数" property="memberCount" :show-overflow-tooltip="true" />
          <el-table-column align="center" fixed="right" label="操作" property="address">
            <template #default="scope">
              <el-link style="margin: 0 5px" type="primary" :underline="false" @click="editrow(scope.row)">编辑</el-link>
              <el-link style="margin: 0 5px" type="primary" :underline="false" @click="deltab(scope.row.id)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :current-page="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
    <el-dialog :before-close="handleClose" title="添加标签" :visible.sync="dialogVisible" width="30%">
      <span>
        <el-form ref="formName" :inline="true" :model="addForm" :rules="rules">
          <el-form-item label="标签名称:" prop="tagName">
            <el-input v-model="addForm.tagName" placeholder="请输入标签名称" style="width: 100%" />
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addsubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :before-close="handleClose" title="编辑标签" :visible.sync="dialogVisibles" width="30%">
      <span>
        <el-form ref="editName" :inline="true" :model="editForm" :rules="rules">
          <el-form-item label="标签名称:" prop="tagName">
            <el-input v-model="editForm.tagName" placeholder="请输入标签名称" style="width: 100%" />
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="editsubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { userTagqueryPage, userTagadd, userTagedit } from '@/api/mall/users/usertag'
export default {
  data() {
    return {
      template: [{}],
      from: {
        size: 10,
        current: 1,
        isDelete: 0,
      },
      total: null,
      dialogVisible: false,
      dialogVisibles: false,
      addForm: {},
      editForm: {},
      rules: {
        tagName: [{ required: true, message: '请输入标签名称', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.queryDiyCanvasPage()
  },
  methods: {
    deltab(e) {
      userTagedit({ id: e, isDelete: 1 }).then((res) => {
        if (res.status == 200) {
          this.queryDiyCanvasPage()
          this.$message.success('删除成功')
        }
      })
    },
    editrow(row) {
      this.dialogVisibles = true
      this.editForm = row
    },
    addsubmit() {
      this.$refs.formName.validate((valid) => {
        if (valid) {
          userTagadd({ ...this.addForm }).then(() => {
            this.addForm = {}
            this.queryDiyCanvasPage()
            this.handleClose()
          })
        } else {
          return false
        }
      })
    },
    editsubmit() {
      this.$refs.editName.validate((valid) => {
        if (valid) {
          userTagedit({ ...this.editForm }).then(() => {
            this.addForm = {}
            this.queryDiyCanvasPage()
            this.handleClose(1)
          })
        } else {
          return false
        }
      })
    },
    handleClose(e) {
      if (e != 1) {
        this.dialogVisible = false
        this.$refs.formName.resetFields()
      } else {
        this.dialogVisibles = false
        this.$refs.editName.resetFields()
      }
    },
    handleSizeChange(e) {
      this.from.size = e
      this.queryDiyCanvasPage()
    },
    handleCurrentChange(e) {
      this.from.current = e
      this.queryDiyCanvasPage()
    },
    queryDiyCanvasPage() {
      userTagqueryPage({ ...this.from }).then((res) => {
        this.from.size = res.data.size
        this.from.current = res.data.current
        this.template = res.data.records
        this.total = res.data.total
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.add-temp {
  margin-bottom: 15px;
}
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
  .el-input__inner {
    width: 100%;
  }
}
</style>