import request from '@/utils/request'

export function getPage(data) {
  return request({
    url: '/upms/sys/sysDict/queryPage',
    method: 'post',
    data: data,
  })
}
export function getPagelist(data) {
  return request({
    url: '/mall/stp/stpPlatformConfig/queryPage',
    method: 'post',
    data: data,
  })
}
export function putpage(data) {
  return request({
    url: '/mall/stp/stpPlatformConfig/edit',
    method: 'post',
    data: data,
  })
}

export function addpages(data) {
  return request({
    url: '/mall/stp/stpPlatformConfig/add',
    method: 'post',
    data: data,
  })
}

export function stpPlatformProtocol(data) {
  return request({
    url: '/mall/user/stpPlatformProtocol/queryPage',
    method: 'post',
    data: data,
  })
}

export function stpPlatformProtocoladd(data) {
  return request({
    url: '/mall/user/stpPlatformProtocol/add',
    method: 'post',
    data: data,
  })
}

export function stpPlatformProtocoledit(data) {
  return request({
    url: '/mall/user/stpPlatformProtocol/edit',
    method: 'post',
    data: data,
  })
}

export function getStpPlatformProtocol(data) {
  return request({
    url: '/mall/user/stpPlatformProtocol/details',
    method: 'post',
    data: data,
  })
}
