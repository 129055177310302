<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" @close="close">
    <el-form ref="form" label-width="120px" :model="form" :rules="rulesform">
      <el-form-item label="收货人名字：">
        <span>
          {{ deliverys.orderLogistics.userName ? deliverys.orderLogistics.userName : '' }}
        </span>
      </el-form-item>
      <el-form-item label="电话号码：">
        <span>
          {{ deliverys.orderLogistics.telNum ? deliverys.orderLogistics.telNum : '' }}
        </span>
      </el-form-item>
      <el-form-item label="收货地址：">
        <!-- <span>
          {{ deliverys.orderConsignee && deliverys.orderConsignee.province ? deliverys.orderConsignee.province : '' }}
        </span>
        <span>
          {{ deliverys.orderConsignee && deliverys.orderConsignee.city ? deliverys.orderConsignee.city : '' }}
        </span>
        <span>
          {{ deliverys.orderConsignee && deliverys.orderConsignee.county ? deliverys.orderConsignee.county : '' }}
        </span> -->
        <span>
          {{ deliverys.orderLogistics.address ? deliverys.orderLogistics.address : '' }}
        </span>
      </el-form-item>
      <el-form-item label="发货方式：" prop="deliveryType">
        <el-radio v-for="item in delieryTypyList" :key="item.id" v-model="form.deliveryType" :label="item.id" @change="changRadio">
          {{ item.name }}
        </el-radio>
      </el-form-item>
      <div v-if="form.deliveryType === 1">
        <el-form-item label="物流公司：" prop="deliveryCompany">
          <el-select v-model="form.deliveryCompany" class="mb20 query-item query-input" placeholder="请选择物流公司" @change="onSelect">
            <el-option v-for="item in deliveryCompanyList" :key="item.code" :label="item.name" :value="item.code" />
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号：">
          <el-radio-group v-model="form.codeType">
            <el-radio v-for="item in codeTypeList" :key="item.dictKey" :label="item.dictKey">
              {{ item.dictValue }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 自动生成 -->
        <!-- <el-form-item v-if="form.codeType == 2">
          <el-input v-model.trim="form.deliveryCode" disabled placeholder="物流单号" />
        </el-form-item> -->
        <!-- 手动录入 -->
        <el-form-item v-if="form.codeType == 1" prop="deliveryCode">
          <el-input v-model.trim="form.deliveryCode" placeholder="物流单号" />
        </el-form-item>
        <div v-if="form.codeType == 2">
          <el-form-item label="是否保价：" prop="useInsured">
            <el-radio-group v-model="form.useInsured">
              <el-radio :label="'0'">不保价</el-radio>
              <el-radio :label="'1'">保价</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.useInsured == 1" label="保价金额(￥)：" prop="price">
            <el-input v-model.trim="form.price" />
          </el-form-item>
          <el-form-item label="备注：" prop="remark" style="width: 50%">
            <el-input v-model.trim="form.remark" type="textarea" />
          </el-form-item>
        </div>
      </div>
      <div v-if="form.deliveryType === 20">
        <el-form-item label="预计取货时间：" prop="expectReceiveTime">
          <el-date-picker
            v-model="form.expectReceiveTime"
            align="center"
            :picker-options="pickerOptions"
            placeholder="选择时间"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model.trim="form.expectReceiveRemark" type="textarea" />
        </el-form-item>
      </div>
    </el-form>
    <!-- 商品列表 -->
    <el-table border :data="goodsMsgTable" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column label="商品名称" prop="spuName" />
      <el-table-column label="数量" prop="quantity" />
      <el-table-column label="发货店铺">
        <template #default="{}">
          <div>{{ deliverys.shopName }}</div>
        </template>
      </el-table-column>
      <el-table-column label="物流单号" prop="orderId" />
      <el-table-column label="状态" prop="statusDesc" />
    </el-table>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getLogisticsList, putdelivery } from '@/api/mall/order/order'
let Base64 = require('js-base64').Base64
import { selectSysDict } from '@/api/mall/common'
export default {
  name: 'Delivery',
  props: {
    deliverys: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      codeTypeList: [
        // {
        //   dictKey: '2',
        //   dictValue: '自动生成',
        // },
        {
          dictKey: '1',
          dictValue: '手动录入',
        },
      ],
      htmlCode: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        },
      },
      goodsMsgTable: [],
      form: {
        deliveryCode: '',
        remark: '易碎商品',
        price: '',
        deliveryCompany: '',
        deliveryType: 1,
        useInsured: '0',
        expectReceiveTime: '',
        expectReceiveRemark: '',
        codeType: '1',
      },
      orderListStateList: [],
      deliveryCompanyList: [
        {
          type: 'JDL',
          name: '京东快递',
        },
      ],
      deliveryCompanyListLable: '',
      title: '',
      dialogFormVisible: false,
      delieryTypyList: [
        {
          id: 1,
          name: '物流发货',
        },
        {
          id: 2,
          name: '商家配送',
        },
        {
          id: 3,
          name: '虚拟发货',
        },
      ],
      multipleSelection: [],
      rulesform: {
        // deliveryCode: [
        //   {
        //     required: true,
        //      message: '请输入快递单号！',
        //     trigger: ['blur', 'change'],
        //   },
        // ],
        deliveryType: [
          {
            required: true,
            message: '请选择发货方式',
            trigger: ['blur', 'change'],
          },
        ],
        expectReceiveTime: [
          {
            required: true,
            message: '请选择预计取货时间',
            trigger: ['blur', 'change'],
          },
        ],
        deliveryCompany: [{ required: true, message: '请选择物流公司', trigger: 'change' }],
        price: [
          {
            required: true,
            message: '请输入保价金额',
            trigger: ['blur', 'change'],
          },
        ],
      },
      stateVal: '',
    }
  },
  watch: {
    deliverys: {
      handler: function (newVal) {
        console.log(newVal)
        this.goodsMsgTable = newVal.listOrderItem
        // this.$nextTick(function () {
        //   console.log(newVal.orderItems)
        //   this.goodsMsgTable = newVal.orderItems.slice(0)
        // })
      },
    },
  },
  created() {
    console.log(this.deliverys)
    this.selectSysDict()
    this.getLogistics()
  },
  methods: {
    // 商品列表订单状态过滤
    formatterOrder(row) {
      for (let i = 0; i < this.orderListStateList.length; i++) {
        // if (this.orderListStateList[i].dictKey == row.orderState) {
        //   return this.orderListStateList[i].dictValue
        // }
        if (row.orderState == 20) {
          if (!row.expectReceiveTime && !row.deliveryCode) {
            return (this.orderListStateList[i].dictValue = '待发货')
          } else if (!row.expectReceiveTime && row.deliveryCode) {
            return (this.orderListStateList[i].dictValue = '待收货')
          } else if (row.expectReceiveTime && !row.deliveryCode) {
            return (this.orderListStateList[i].dictValue = '待收货')
          }
        } else {
          if (this.orderListStateList[i].dictKey == row.orderState) {
            return this.orderListStateList[i].dictValue
          }
        }
      }
    },
    // 商品状态为已发货（待收货）禁用 expectReceiveTime时间 deliveryCode物流单号
    checkboxTable(row) {
      console.log(row)
      // if (
      //   row.orderState == 20 &&
      //   !row.expectReceiveTime &&
      //   !row.deliveryCode
      // ) {
      //   return 1 //显示
      // } else if (
      //   row.orderState == 20 &&
      //   row.expectReceiveTime &&
      //   !row.deliveryCode
      // ) {
      //   return 0
      // } else {
      //   return 0
      // }
      if (row.orderState == 20) {
        if (row.expectReceiveTime && row.deliveryCode) {
          return 0
        } else if (!row.expectReceiveTime && row.deliveryCode) {
          return 0
        } else if (row.expectReceiveTime && !row.deliveryCode) {
          return 0
        } else {
          return 1
        }
      }
      // if (row.orderState == 20 && !row.deliveryCode) {
      //   return 1
      // } else {
      //   return 0
      // }
    },
    // 字典(订单状态)
    selectSysDict() {
      selectSysDict({ dictTypeList: ['orderStatus'] })
        .then((res) => {
          if (res.data) {
            this.orderListStateList = res.data[0].dictInfoVoList
          }
        })
        .catch(() => {})
    },

    // 商品表格操作
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 弹框
    showEdit() {
      this.title = '订单发货'
      this.dialogFormVisible = true
      // console.log(this.deliverys)
      // this.goodsMsgTable = this.deliverys.orderItems.slice(0)
      // console.log(this.goodsMsgTable)
    },
    // 发货方式
    changRadio() {
      this.$refs['form'].clearValidate()
    },
    // 关闭弹框
    close() {
      this.form = {
        deliveryCode: '',
        remark: '易碎商品',
        price: '',
        deliveryCompany: '',
        deliveryType: 10,
        useInsured: '0',
        expectReceiveTime: '',
        expectReceiveRemark: '',
        codeType: '1',
      }
      this.dialogFormVisible = false
    },

    //查询物流
    getLogistics() {
      const parms = {
        orderCode: this.deliverys.orderCode,
      }
      getLogisticsList(parms)
        .then((res) => {
          this.deliveryCompanyList = res.data
        })
        .catch(() => {})
    },

    // 选择物流公司(获取name)
    onSelect(val) {
      // console.log(this.deliveryCompanyList)
      // console.log('选中值'+val)
      var obj = {}
      obj = this.deliveryCompanyList.find((item) => {
        return item.code === val
      })
      this.deliveryCompanyListLable = obj.name
    },
    // 发货保存订单
    async save() {
      // if (this.multipleSelection && this.multipleSelection.length === 0) {
      //   this.ME('请勾选商品')
      //   return
      // }
      console.log(this.form)
      var multipleId = []
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          if (this.multipleSelection.length > 0) {
            multipleId = this.multipleSelection.map((item) => item.id)
          }
          if (this.form.useInsured == 1 && !this.form.price) {
            this.ME('请输入保价金额')
            return false
          }
          const parms2 = {
            orderSummaryCode: this.deliverys.orderSummaryCode,
            orderCode: this.deliverys.orderCode,
            userId: this.deliverys.userId,
            isSource: 1,
            logisticsPlan: this.form.deliveryCompany,
            remark: this.form.remark,
            useInsured: this.form.useInsured.toString(),
            price: this.form.price == '' ? 0 : this.form.price,
            itemIds: multipleId,
          }
          // 物流发货方式，自动生成快递单号
          if (this.form.deliveryType == 10 && this.form.codeType == 2) {
            await putdelivery(parms2)
              .then((res) => {
                if (res.data) {
                  if (res.data.errmsg || res.data.msg) {
                    this.ME('请选择手动录入物流单号或者无需物流发货')
                    return false
                  }
                  this.form.deliveryCode = res.data.deliveryCode
                  this.htmlCode = Base64.decode(res.data.html)
                  const newWin = window.open('')
                  newWin.document.write(this.htmlCode)
                  newWin.document.close() //在IE浏览器中使用
                  newWin.focus() //在IE浏览器中使用
                  setTimeout(function () {
                    newWin.print() //打印
                    newWin.close() //关闭窗口
                  }, 10)
                  // 打印成功发货
                  const parms = {
                    orderCode: this.deliverys.orderCode,
                    itemIds: multipleId,
                    // deliveryName: this.form.deliveryCompany, //物流公司
                    deliveryName: '京东物流',
                    deliveryCode: this.form.deliveryCode,
                    deliveryType: this.form.deliveryType,
                    expectReceiveTime: this.form.expectReceiveTime ? new Date(this.form.expectReceiveTime) : '',
                    expectReceiveRemark: this.form.expectReceiveRemark,
                  }
                  putdelivery(parms)
                    .then((res) => {
                      this.MS(res.message)
                      this.$nextTick(() => {
                        this.$emit('getorder')
                      })
                      this.close()
                      this.dialogFormVisible = false
                    })
                    .catch(() => {})
                }
              })
              .catch(() => {})
          } else {
            if (this.form.deliveryType == 10 && !this.form.deliveryCode) {
              this.ME('请输入快递单号!')
              return false
            }
            // 物流发货手动输入或者无需物流
            const parms = {
              orderCode: this.deliverys.orderCode,
              itemIds: multipleId,
              // deliveryName: this.form.deliveryCompany,//物流公司
              deliveryName: this.form.deliveryType == 10 ? '京东物流' : '',
              deliveryCode: this.form.deliveryCode,
              deliveryType: this.form.deliveryType,
              logisticsNo: this.form.deliveryCode,
              logisticsId: this.deliverys.logisticsId,
              logistics: this.form.deliveryCompany,
              expectReceiveTime: this.form.expectReceiveTime ? new Date(this.form.expectReceiveTime) : '',
              expectReceiveRemark: this.form.expectReceiveRemark,
            }
            putdelivery(parms)
              .then((res) => {
                this.MS(res.message)
                this.$nextTick(() => {
                  this.$emit('getorder')
                })
                this.close()
                this.dialogFormVisible = false
              })
              .catch(() => {})
          }
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.el-select {
  width: 40% !important;
  .el-input {
    width: 100%;
  }
}
.el-input {
  width: 40%;
}
.el-textarea__inner {
  min-height: 100px;
}
</style>
