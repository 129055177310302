<template>
  <div class="comprehensive-table-container">
    <el-button
      class="addBtn"
      icon="el-icon-plus"
      type="primary"
      @click="dialogFormVisible=true"
    >
      新增
    </el-button>
    <el-table border :data="data">
      <el-table-column align="center" label="名称" prop="name"/>
      <el-table-column align="center" label="创建时间" prop="createTime" />
      <el-table-column align="center" label="更新时间" prop="updateTime" />
      <el-table-column align="center" label="操作" prop="id">
        <template slot-scope="scope">
          <el-link style="margin: 0 10px;" type="primary" :underline="false" @click="edita(scope.row)">编辑</el-link>
          <el-link style="margin: 0 10px;" type="primary" :underline="false" @click="del(scope.row.id)">删除</el-link>
          <el-link style="margin: 0 10px;" type="primary" :underline="false" @click="list(scope.row)">规格值列表</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
      background
      :current-page="1"
      layout="total,sizes, prev, pager, next,jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"/>
    </div>
    <el-dialog :rules="rules" title="新增" :visible.sync="dialogFormVisible" @close="Cancel(1,false)">
      <el-form :model="form">
        <el-form-item label="名称：">
          <el-input v-model="form.name"  placeholder="请输入名称" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="Cancel(0,false)">保存</el-button>
    <el-button @click="Cancel(1,false)">取 消</el-button>
      </div>
    </el-dialog>
   <div class="ym_adnew">
    <el-dialog :rules="rules" title="编辑" :visible.sync="hide" @close="editb()">
      <el-form :model="adfile">
        <el-form-item label="名称：">
          <el-input v-model="adfile.name"  placeholder="请输入修改的名称" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="edi(0,false)">修改</el-button>
    <el-button @click="cacels()">取 消</el-button>
      </div>
    </el-dialog>
    <specifications :cheknum="number" :dat="sear"  :hide="showtab"   @transhow="tabhide"/>
   </div>
  </div>
</template>

<script>
import {getPage,addObj,putObj,delObj} from '@/api/mall/goods/goodsSpecs'
import specifications from './components/specifications'
export default {
    components:{
      specifications
    },
    data() {
      return {
        data: [],
        dialogFormVisible:false,
        currentPage2: 5,
        hide:false,
        pagesize:'',
        total:0,
        form: {
          name:''
        }, adfile: {
          name:'',
          id:''
        },
        options: [
          {
            label: '名称',
            prop: 'name',
          },
        ],
        size:10,
        current:1,
        number:'',
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
        },
        sear:"",
        showtab:false
      }
    },
    created(){
      this.getPageList(this.size,this.current)
    },
    methods: {
      getPageList(size,cur){
        const date={
          size:size,
          current:cur
        }
        getPage({...date}).then(res=>{
          this.data=res.data.records
          this.total=res.data.total
        })
      },
      show(){},
      edi(){
        putObj({id:this.adfile.id,name:this.adfile.name}).then(()=>{
          this.$message.success('修改成功')
          this.adfile={
            id:'',
            name:''
          },
          this.hide=false
        })
      },
      del(e){
        delObj({id:e}).then(()=>{
          this.$message.success('删除成功')
        })
      },
      list(e){
        this.showtab=true
        this.number=e.id
        this.sear=e.name
      },
      Cancel(tap,bolea){
        if(tap==0){
          addObj({name: this.form.name, createTime: "", updateTime: ""}).then(()=>{
            this.$message.success('创建成功')
          }).catch(()=>{
            this.$message.error('创建失败')
          })
          this.form={name:''}
          this.dialogFormVisible=bolea
        }
        else{
          this.form={name:''}
          this.dialogFormVisible=bolea
        }
      },
      cacels(){
          this.adfile={name:'',id:''}
          this.hide=false
      },
      edita(tap){
          this.adfile.name=tap.name
          this.adfile.id=tap.id
          this.hide=true
      },
      editb(){
        this.hide=false
      },
      tabhide(e){
        this.showtab=e
      },
      handleCurrentChange(e){
        this.current=e
        this.getPageList(this.size,this.current)
      },
      handleSizeChange(e){
        this.size=e
        this.getPageList(this.size,this.current)
      }
    },
  }
</script>

<style lang="scss" scoped>
.block{
display: flex;
justify-content: right;
}
::v-deep .el-dialog__header{
  border-bottom: 1px solid #f3f3f3;
}
</style>
