<template>
  <div class="comprehensive-table-container">
    <common-form ref="commonForm" v-model="searchForm" :options="searchOptions">
      <template slot="operation">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </template>
    </common-form>
    <el-button class="m-b" icon="el-icon-plus" type="primary" @click="handleAdd">新增</el-button>
    <el-table border :data="data">
      <el-table-column align="center" label="店铺Logo">
        <template #default="{ row }">
          <el-image :lazy="true" :preview-src-list="[row.imgUrl]" :src="row.imgUrl" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="店铺名" prop="name" show-overflow-tooltip />
      <el-table-column align="center" label="电话号码" prop="phone" show-overflow-tooltip sortable />
      <el-table-column align="center" label="地址" prop="address" show-overflow-tooltip />
      <el-table-column align="center" label="是否启用" sortable>
        <template #default="{ row }">
          <el-switch v-model="row.enable" active-color="#13ce66" active-value="1" inactive-color="#ff4949" inactive-value="0" @change="changeEnable(row)" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="详细介绍" prop="detail" show-overflow-tooltip />
      <el-table-column align="center" label="创建时间" prop="createTime" show-overflow-tooltip />
      <el-table-column align="center" label="操作">
        <template #default="{ row }">
          <el-button size="default" type="text" @click="show(row)">查看</el-button>
          <el-button size="default" type="text" @click="edit(row)">编辑</el-button>
          <el-button size="default" type="text" @click="handleDel(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :layout="pageLayout" :total="total" @current-change="currentChange" @size-change="sizeChange" />
    <table-edit ref="edit" v-model="form" :config="config" :options="options" :rules="rules" @fileStatus="fileupload" @submit="submit" @update="update" />
  </div>
</template>

<script>
  // import { getPage, getObj, addObj, putObj, delObj } from '@/api/mall/shop/info'
  import { getPage, putObj, addObj, delObj } from '@/api/mall/shop/info'
  import { options, rules, config } from './const/info'
  import { deepClone } from '@/utils/util'
  export default {
    data() {
      return {
        searchForm: {
          current: 1,
          size: 10,
        },
        data: [],
        total: 0,
        options,
        rules,
        config,
        form: {},
        searchOptions: [
          {
            label: '是否启用',
            type: 'select',
            prop: 'enable',
            span: 4,
            labelWidth: '5em',
            optionData: [
              { label: '关闭', value: '0' },
              { label: '开启', value: '1' },
            ],
          },
          { type: 'operation', span: 6 },
        ],
      }
    },
    created() {
      console.log(this.options)
      this.list()
    },
    methods: {
      list() {
        getPage(this.searchForm).then((res) => {
          console.log(res.data.records)
          const {
            data: { records, total },
          } = res
          this.data = records
          this.total = total
        })
      },
      handleAdd() {
        this.form = {}
        this.$refs['edit'].showEdit()
      },
      show(row) {
        this.form = deepClone(row)
        this.$nextTick(() => {
          this.$refs['edit'].showEdit('show')
        })
      },
      edit(row) {
        this.form = deepClone(row)
        this.$nextTick(() => {
          this.$refs['edit'].showEdit('edit')
        })
      },
      fileupload(msg, result) {
        this.form.imgUrl = result.link
        console.log(msg, result)
      },
      changeEnable(row) {
        putObj({
          id: row.id,
          enable: row.enable,
        }).then((data) => {
          this.$baseMessage('操作成功', 'success', 'vab-hey-message-success')
          console.log(data)
          this.list()
        })
      },
      handleDel(row) {
        let _this = this
        this.$confirm('是否确认删除此数据', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(function () {
            return delObj(row.id)
          })
          .then(() => {
            _this.$baseMessage('删除成功', 'success', 'vab-hey-message-success')
            _this.list(this.searchForm)
          })
      },
      search() {
        if (this.searchForm.enable == '') delete this.searchForm.enable
        this.searchForm.current = 1
        this.list()
      },
      reset() {
        this.$refs['commonForm'].reset()
      },
      submit() {
        this.form.$enable = this.form.enable == '0' ? '关闭' : '开启'
        this.form.latitude = '40.03204213893878'
        this.form.longitude = '115.91800564885136'
        addObj(this.form).then(() => {
          this.$baseMessage('添加成功', 'success', 'vab-hey-message-success')
          this.$refs['edit'].close()
          this.list(this.searchForm)
        })
      },
      update() {
        this.form.$enable = this.form.enable == '0' ? '关闭' : '开启'
        putObj(this.form).then(() => {
          this.$baseMessage('修改成功', 'success', 'vab-hey-message-success')
          this.$refs['edit'].close()
          this.list(this.searchForm)
        })
      },
      currentChange(e) {
        this.searchForm.current = e
        this.list()
        console.log(e)
      },
      sizeChange(e) {
        this.searchForm.current = 1
        this.searchForm.size = e
        this.list()
        console.log(e)
      },
    },
  }
</script>

<style></style>
