import request from '@/utils/request'

export function getDetail(data) {
  return request({
    url: '/mall/userinfo/' + data.id,
    method: 'get',
  })
}

export function disableUser(data) {
  return request({
    url: `/user/uUserLogin/disable`,
    method: 'post',
    data,
  })
}
export function recoveryUser(data) {
  return request({
    url: `/user/uUserLogin/recovery`,
    method: 'post',
    data,
  })
}

export function getIntegral(data) {
  return request({
    url: `/mall/pointsrecord/page`,
    method: 'get',
    params: data,
  })
}

export function exportIntegral(data) {
  return request({
    url: `/merchant/merIntegralAmountLog/export`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}

export function exportProfit(data) {
  return request({
    url: `/merchant/merProfitAmountLog/export`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}

export function getGoldenBean(data) {
  return request({
    url: `/merchant/merGoldenBeanAmountLog/page`,
    method: 'post',
    data,
  })
}

export function getBalance(data) {
  return request({
    url: `/merchant/merBalanceAmountLog/page`,
    method: 'post',
    data,
  })
}

export function getProfit(data) {
  return request({
    url: `/merchant/merProfitAmountLog/page`,
    method: 'post',
    data,
  })
}
export function getProfitDetail(data) {
  return request({
    url: `/merchant/merProfitAmountLog/survey`,
    method: 'post',
    data,
  })
}

export function getCoupon(data) {
  return request({
    url: `/coupons/cpnCouponUser/page`,
    method: 'post',
    data,
  })
}

export function getCouponDetail(data) {
  return request({
    url: `/coupons/cpnCouponUser/survey`,
    method: 'post',
    data,
  })
}

export function getReceivingAddress(data) {
  return request({
    url: `/user/uUserReceivingAddress/page`,
    method: 'post',
    data,
  })
}

export function getOrder(data) {
  return request({
    url: `/order/ordOrder/page`,
    method: 'post',
    data,
  })
}

export function getOrderDetail(data) {
  return request({
    url: `/order/ordOrder/survey`,
    method: 'post',
    data,
  })
}

export function getOrderInvoice(data) {
  return request({
    url: `/order/ordOrderInvoice/page`,
    method: 'post',
    data,
  })
}

export function getOrderInvoiceDetail(data) {
  return request({
    url: `/order/ordOrderInvoice/survey`,
    method: 'post',
    data,
  })
}

export function getEquipaDetail(data) {
  return request({
    url: `/user/uUserEquipa/survey`,
    method: 'post',
    data,
  })
}
export function getEquipa(data) {
  return request({
    url: `/user/uUserEquipa/page`,
    method: 'post',
    data,
  })
}

export function getCollect(data) {
  return request({
    url: `/goods/proGoodsCollect/page`,
    method: 'post',
    data,
  })
}

export function getCollectDetail(data) {
  return request({
    url: `/goods/proGoodsCollect/survey`,
    method: 'post',
    data,
  })
}

export function getGoodsBrowse(data) {
  return request({
    url: `/goods/proGoodsBrowse/page`,
    method: 'post',
    data,
  })
}

export function getGoodsBrowseDetail(data) {
  return request({
    url: `/goods/proGoodsBrowse/survey`,
    method: 'post',
    data,
  })
}

export function getUserQuery(data) {
  return request({
    url: `/user/uUserQuery/page`,
    method: 'post',
    data,
  })
}

export function getUserQueryDetail(data) {
  return request({
    url: `/user/uUserQuery/survey`,
    method: 'post',
    data,
  })
}

export function getUserOrg(data) {
  return request({
    url: `/user/userOrg/queryOrgList`,
    method: 'post',
    data,
  })
}
