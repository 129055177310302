<template>
  <vab-query-form-top-panel>
    <el-form ref="form" :inline="true" label-width="89px" :model="queryForm" @submit.native.prevent>
      <el-form-item label="订单编号">
        <el-input v-model.trim="queryForm.orderNo" placeholder="请输入订单编号" />
      </el-form-item>
      <!-- <el-form-item label="下单员工">
        <el-input v-model.trim="queryForm.realName" placeholder="请输入员工姓名" />
      </el-form-item> -->
      <el-form-item label="订单状态">
        <!-- 核流程 1：待分配 2：待处理 3：待审核 4：已完成 -->
        <el-select v-model.trim="queryForm.status" placeholder="请选择订单状态">
          <el-option label="待付款" value="0" />
          <el-option label="待发货" value="1" />
          <el-option label="待收货" value="2" />
          <el-option label="已完成" value="3" />
          <!-- <el-option label="已关闭" value="4" /> -->
          <el-option label="已取消" value="5" />
        </el-select>
      </el-form-item>
      <el-form-item label="质检状态">
        <el-select v-model.trim="queryForm.quaState" placeholder="请选择质检状态">
          <el-option label="待质检" value="1" />
          <el-option label="已通过" value="2" />
          <el-option label="已驳回" value="3" />
        </el-select>
      </el-form-item>
      <el-form-item label="物流状态">
        <el-select v-model.trim="queryForm.logisticsStatus" placeholder="请选择物流状态">
          <el-option label="在途" value="0" />
          <el-option label="揽收" value="1" />
          <el-option label="疑难" value="2" />
          <el-option label="签收" value="3" />
          <el-option label="退签" value="4" />
          <el-option label="派件" value="5" />
          <el-option label="退回" value="6" />
          <el-option label="转投" value="7" />
          <el-option label="清关" value="8" />
          <el-option label="拒签" value="14" />
        </el-select>
      </el-form-item>
      <el-form-item label="订单日期">
        <el-date-picker v-model="value1" :default-time="['00:00:00', '23:59:59']" end-placeholder="结束日期"
          range-separator="至" start-placeholder="开始日期" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
          @change="changedate" />
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" native-type="submit" type="primary" @click="searchquery">查询</el-button>
        <el-button icon="el-icon-refresh-right" native-type="submit" type="warning" @click="clearcont">重置</el-button>
      </el-form-item>
    </el-form>
  </vab-query-form-top-panel>
</template>

<script>
import { getAreaThree } from '@/api/mall/common'
import { queryPage } from '@/api/mall/health/call/myClient'
import { queryPages } from '@/api/mall/setting/stplabel'
import { selectSysDict } from '@/api/mall/common'
import { orderrecord } from '@/api/mall/health/casemy/index'
export default {
  props: {
    hh: {
      type: Number,
      default: () => { },
    },
  },
  data() {
    return {
      areaSelectData: [],
      provinces: null,
      queryForm: {
        // menuType: 2, //1:公海管理  2：部门公海 3：我的客户 , 4:客情数据 5： 我的客情  6：客情审核 7：质检数据 8 我的质检
        sysId: '',
      },
      fold: true,
      value1: null,

      communication: null,
      Channel: null,
      Ordertype: null,
      Membership: null,
      logisticsKey: null,
    }
  },
  created() {
    this.getAreaThree()
    this.selectSysDict()

    this.queryForm.sysId = this.hh
    console.log(this.queryForm.sysId, '123456lio')
    // console.log('本地存儲的 userId:', this.form.userId);
  },
  methods: {
    clearcont() {
      this.queryForm = {}
      this.value1 = []
      orderrecord({ ...this.queryForm, size: 10, current: 1 }).then((res) => {
        if (res.data.records) {
          this.$emit('receive', res.data)
        }
      })
      this.$baseEventBus.$emit('Qualityinspection')
    },
    searchquery() {
      orderrecord({ ...this.queryForm, size: 10, current: 1 }).then((res) => {
        if (res.data.records) {
          this.$emit('receive', res.data)
        } else {
          this.$message.error('查询为空')
        }
      })
    },
    selectSysDict() {
      selectSysDict({ dictTypeList: ['orderStatus'] }).then((res) => {
        this.logisticsKey = res.data[0].dictInfoVoList
      })
    },
    changedate() {
      this.queryForm.startTime = this.value1[0]
      this.queryForm.endTime = this.value1[1]
      console.log(this.queryForm)
    },
    changedates() {
      this.queryForm.allotStartTime = this.value2[0]
      this.queryForm.allotEndTime = this.value2[1]
    },
    daterange() {
      this.queryForm.buyStartTime = this.value3[0]
      this.queryForm.buyEndTime = this.value3[1]
    },
    firstStartTime() {
      this.queryForm.firstStartTime = this.value4[0]
      this.queryForm.firstEndTime = this.value4[1]
    },
    lastStartTime() {
      this.queryForm.lastStartTime = this.value5[0]
      this.queryForm.lastEndTime = this.value5[1]
    },
    addres(e) {
      this.queryForm.province = e[0]
      this.queryForm.city = e[1]
      this.queryForm.county = e[2]
    },
    async getAreaThree() {
      await getAreaThree()
        .then((res) => {
          if (res.data) {
            this.areaSelectData = res.data
          }
        })
        .catch(() => { })
    },
    queryPages(e) {
      queryPages({ labelType: e }).then((res) => {
        if (e == 1) {
          this.communication = res.data
        }
        if (e == 2) {
          this.Channel = res.data
        }
        if (e == 3) {
          this.Ordertype = res.data
        }
        if (e == 5) {
          this.Membership = res.data
        }
      })
    },
    handleFold() {
      this.fold = !this.fold
      this.handleHeight()
    },
    reset() {
      this.$emit('receive', false)
    },
    searchbtn() {
      if (this.value1) {
        console.log(this.value1)
        this.form.startTime = this.value1[0]
        this.form.endTime = this.value1[1]
      }
      if (this.value2) {
        this.form.allotStartTime = this.value2[0]
        this.form.allotEndTime = this.value2[1]
      }
      queryPage({ ...this.form }).then((res) => {
        this.$emit('receive', res.data)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {

  .el-input,
  .el-date-editor {
    width: 210px;
  }

  .el-range-input,
  .el-range-separator {
    font-size: 12px;
  }

  .el-range-separator {
    width: 20px;
  }
}
</style>
