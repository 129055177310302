<template>
  <div 
    class="container"
    :style="{
      background:`${controls[index].pageBgColor}`,
      padding:`${controls[index].margin.top}px ${controls[index].margin.both}px ${controls[index].margin.bottom}px ${controls[index].margin.both}px`,
    }"
  >
    <div 
      class="container_cont"
      :style="{
        background:`${controls[index].componentBgColor}`,
        borderTopLeftRadius:`${controls[index].topElementAroundRadius}px`,
        borderTopRightRadius:`${controls[index].topElementAroundRadius}px`,
        borderBottomLeftRadius:`${controls[index].bottomElementAroundRadius}px`,
        borderBottomRightRadius:`${controls[index].bottomElementAroundRadius}px`,
      }"
    >
       <div v-for="(ym,sun) in navlist" :key="sun" class="item">
        <div class="icon">
          <vab-icon :icon="ym.icon" style="font-size: 40px;"/>
        </div>
        <div class="span">{{ym.txt}}</div>
       </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    index: {
      type: Number,
      default: () => {},
    },
  },
  data(){
    return{
      cur:0,
      navlist:[
        {
          icon:'shopping-basket-line',
          txt:'待付款'
        },
        {
          icon:'shopping-basket-2-fill',
          txt:'待发货'
        },
        {
          icon:'shopping-basket-line',
          txt:'待收货'
        },
        {
          icon:'shopping-basket-2-fill',
          txt:'待评价'
        },
        {
          icon:'shopping-basket-line',
          txt:'退换货'
        },
      ]
    }
  },
  computed: {
    ...mapState({
      controls: state => state.checkvalue,
    }),
  },
  watch:{
    index(newValue,oldValue){
      if(newValue!=oldValue){
        this.terminal=newValue
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.container{
   .container_cont{
    padding: 15px 0;
    display: grid;
    align-items: center;
    gap: 10px;
    grid-template-columns: repeat(5,1fr);
    margin: auto;
    .item{
      text-align: center;
      margin: auto;
      .icon{
      }
    }
   }
}
</style>