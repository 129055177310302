var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "configa" },
    [
      _vm._m(0),
      _c(
        "el-form",
        { attrs: { inline: true, "label-width": "100px", model: _vm.savform } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "交流信息：",
                rules: [
                  {
                    required: true,
                    message: "请输入交流信息",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择交流信息" },
                  on: {
                    focus: function ($event) {
                      return _vm.queryList(1)
                    },
                  },
                  model: {
                    value: _vm.savform.interId,
                    callback: function ($$v) {
                      _vm.$set(_vm.savform, "interId", $$v)
                    },
                    expression: "savform.interId",
                  },
                },
                _vm._l(_vm.optionsinter, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.labelName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "交流内容：",
                rules: [
                  {
                    required: true,
                    message: "请输入交流内容",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入交流内容" },
                model: {
                  value: _vm.savform.interContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.savform, "interContent", $$v)
                  },
                  expression: "savform.interContent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "渠道来源：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择渠道来源" },
                  on: {
                    change: function ($event) {
                      return _vm.sel()
                    },
                    focus: function ($event) {
                      return _vm.queryList(2)
                    },
                  },
                  model: {
                    value: _vm.savform.channelId,
                    callback: function ($$v) {
                      _vm.$set(_vm.savform, "channelId", $$v)
                    },
                    expression: "savform.channelId",
                  },
                },
                _vm._l(_vm.optionschannelId, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.labelName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "咨询产品：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择咨询产品" },
                  on: {
                    change: _vm.sel,
                    focus: function ($event) {
                      return _vm.queryList(6)
                    },
                  },
                  model: {
                    value: _vm.savform.goodId,
                    callback: function ($$v) {
                      _vm.$set(_vm.savform, "goodId", $$v)
                    },
                    expression: "savform.goodId",
                  },
                },
                _vm._l(_vm.optionsgoodId, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.labelName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(1),
      _c(
        "el-form",
        { attrs: { inline: true, "label-width": "100px", model: _vm.savform } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "客户姓名：",
                rules: [
                  {
                    required: true,
                    message: "请输入客户姓名",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入客户姓名" },
                model: {
                  value: _vm.savform.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.savform, "userName", $$v)
                  },
                  expression: "savform.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户性别：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择客户性别" },
                  model: {
                    value: _vm.savform.userSex,
                    callback: function ($$v) {
                      _vm.$set(_vm.savform, "userSex", $$v)
                    },
                    expression: "savform.userSex",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户职业：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入客户职业" },
                model: {
                  value: _vm.savform.clientOccupa,
                  callback: function ($$v) {
                    _vm.$set(_vm.savform, "clientOccupa", $$v)
                  },
                  expression: "savform.clientOccupa",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户类型：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择客户类型" },
                  on: {
                    focus: function ($event) {
                      return _vm.queryList(5)
                    },
                  },
                  model: {
                    value: _vm.savform.clientId,
                    callback: function ($$v) {
                      _vm.$set(_vm.savform, "clientId", $$v)
                    },
                    expression: "savform.clientId",
                  },
                },
                _vm._l(_vm.optionsclientId, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.labelName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "区域选择：" } },
            [
              _c("el-cascader", {
                staticClass: "full-width",
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                  width: "215px",
                  height: "32px",
                },
                attrs: {
                  options: _vm.areaSelectData,
                  placeholder: "请选择地址",
                  props: {
                    children: "children",
                    label: "areaName",
                    value: "areaName",
                  },
                  size: "large",
                },
                on: { change: _vm.addres },
                model: {
                  value: _vm.provinces,
                  callback: function ($$v) {
                    _vm.provinces = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "provinces",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "详细地址：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入详细地址" },
                model: {
                  value: _vm.savform.addressDetail,
                  callback: function ($$v) {
                    _vm.$set(_vm.savform, "addressDetail", $$v)
                  },
                  expression: "savform.addressDetail",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "客户电话：",
                rules: [
                  {
                    required: true,
                    message: "请输入客户电话",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入详细电话" },
                  model: {
                    value: _vm.savform.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.savform, "phone", $$v)
                    },
                    expression: "savform.phone",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cal",
                        on: {
                          click: function ($event) {
                            return _vm.callphone(_vm.savform.phone)
                          },
                        },
                      },
                      [_vm._v("拨打")]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户电话2：" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入详细电话" },
                  model: {
                    value: _vm.savform.phonev,
                    callback: function ($$v) {
                      _vm.$set(_vm.savform, "phonev", $$v)
                    },
                    expression: "savform.phonev",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cal",
                        on: {
                          click: function ($event) {
                            return _vm.callphone(_vm.savform.phonev)
                          },
                        },
                      },
                      [_vm._v("拨打")]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户备注1：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入客户备注" },
                model: {
                  value: _vm.savform.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.savform, "remark", $$v)
                  },
                  expression: "savform.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户备注2：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入客户备注" },
                model: {
                  value: _vm.savform.remarkContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.savform, "remarkContent", $$v)
                  },
                  expression: "savform.remarkContent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("全局保存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "" },
                  on: {
                    click: function ($event) {
                      _vm.amoutshow = true
                    },
                  },
                },
                [_vm._v("预存金额")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "" },
                  on: {
                    click: function ($event) {
                      _vm.aftershow = true
                    },
                  },
                },
                [_vm._v("客情处理")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "" },
                  on: {
                    click: function ($event) {
                      _vm.Return = true
                    },
                  },
                },
                [_vm._v("回访设置")]
              ),
              _c(
                "el-button",
                { attrs: { type: "" }, on: { click: _vm.ToUser } },
                [_vm._v("会员信息")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("amoutdialog", {
        attrs: { amoutswitch: _vm.amoutshow, savforms: _vm.savform },
        on: { closeswitch: _vm.closedia },
      }),
      _c("Aftersaletreatment", {
        attrs: { hanuser: _vm.savform, showtab: _vm.aftershow },
        on: { closeswitch: _vm.aftersaletrclose },
      }),
      _c("ReturnVisit", {
        attrs: {
          hanuser: _vm.savform,
          showtab: _vm.Return,
          usmglist: _vm.usmgdate,
        },
        on: { closedemo: _vm.alclose },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "theader_title" }, [
      _c("div", { staticClass: "txt" }, [_vm._v("基础信息")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "theader_title fix" }, [
      _c("div", { staticClass: "txt" }, [_vm._v("客户信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }