<template>
  <div class="promoter">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="一级推广订单" name="parentSecondId"/>
      <el-tab-pane label="二级推广订单" name="parentId"/>
    </el-tabs>
    <el-form ref="searchForm" :inline="true" :model="search">
      <el-form-item label="佣金状态：">
        <el-select v-model="search.commissionStatus" placeholder="请选择状态">
          <el-option label="冻结" :value="1"/>
          <el-option label="解冻" :value="2"/>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="search.value1"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"/>
      </el-form-item>
      <el-form-item label="">
        <el-button icon="el-icon-search" type="warning" @click="searchcont">搜索</el-button>
        <el-button icon="el-icon-refresh-left" type="primary" @click=" $refs.searchForm.resetFields();">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="tabldata">
      <el-table-column align="center" label="创建时间" prop="createTime"/>
      <el-table-column align="center" label="订单编号" prop="orderId"/>
      <el-table-column align="center" label="用户信息" prop="appType">
        <template #default="scope">
          <div v-if="scope.row.appType=='H5'">普通H5</div>
          <div v-if="scope.row.appType=='MA'">小程序</div>
          <div v-if="scope.row.appType=='H5-WX'">公众号H5</div>
          <div v-if="scope.row.appType=='APP'">APP</div>
          <div v-if="scope.row.appType=='H5-PC'">PC端H5</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="返佣金额" prop="commission"/>
      <el-table-column align="center" label="佣金状态" prop="commissionStatus" width="300px">
          <template #default="scope">
            <el-tag v-if="scope.row.commissionStatus=='1'" type="danger">冻结</el-tag>
            <el-tag v-else type="success">解冻</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" prop="distributionUser">
        <template #default="scope">
          <div v-if="scope.row.distributionUser"><el-tag effect="dark" type="success">是</el-tag></div>
          <div v-else><el-tag effect="dark" type="danger">否</el-tag></div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"/>
  </div>
</template>

<script>
import {userinfoselect} from "@/api/mall/distUsers/list"
export default {
  props:{
    userid:{
      type:String,
      default:()=>{}
    }
  },
  data(){
    return{
      search:{},
      activeName: 'parentSecondId',
      tabldata:[],
      total:0,
      form:{
        size:10,
        current:1
      },
      infoId:this.userid
    }
  },
  watch:{
    userid(newValue,oldValue){
      if(newValue!=oldValue){
        this.infoId=newValue
      }
    }
  },
  created(){
    this.userinfoselect()
  },
  methods:{
    userinfoselect(){
      let list={}
      if(this.activeName!='parentId'){
        list={
          ...this.form,
          ...this.search,
          parentSecondId:this.infoId
        }
      }
      else{
        list={
          ...this.form,
          ...this.search,
          parentId:this.infoId
        }
      }
      userinfoselect({...list}).then(res=>{
        this.tabldata=res.data.records
        this.form.size=res.data.size
        this.form.current=res.data.current
        this.total=res.data.total
      })
    },
    handleClick() {
      this.userinfoselect()
    },
    handleCurrentChange(e){
      this.form.current=e
      this.userinfoselect()
    },
    handleSizeChange(e){
      this.form.size=e
      this.userinfoselect()
    },
    searchcont(){
      // this.userinfoselect()
      console.log(this.search)
    }
  }
}
</script>

<style>

</style>