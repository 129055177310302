import request from '@/utils/request'
// cxcc列表
export function queryPage(data) {
  return request({
    url: `/mall/user/stpCxccConfig/queryPage`,
    method: 'post',
    data,
  })
}

// cxcc编辑
export function editColumn(data) {
  return request({
    url: `/mall/user/stpCxccConfig/edit`,
    method: 'post',
    data,
  })
}
// cxcc添加
export function addColumn(data) {
  return request({
    url: `/mall/user/stpCxccConfig/add`,
    method: 'post',
    data,
  })
}

// 设置状态
export function disableColumn(data) {
  return request({
    url: `/mall/stp/stpPlatformConfig/disable`,
    method: 'post',
    data,
  })
}
