<template>
  <div class="cy-container">
    <el-container style="width: 100%">
      <!-- 菜单列表 -->
      <div
        ref="asideRef"
        class="asideAuthorizeUrl"
        style="
          width: 200px;
          height: 600px;
          margin-right: 5px;
          overflow: scroll;
          border: 1px solid #c0d4f0;
        "
      >
        <el-aside>
          <el-table
            class="aside-table"
            :data="menuTableData"
            :highlight-current-row="true"
            row-key="id"
            style="width: 100%; margin-bottom: 20px"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @row-click="clickAsideData"
          >
            <el-table-column label="菜单名称" prop="menuName" />
          </el-table>
        </el-aside>
      </div>
      <!-- 点击收缩 -->
      <div class="clickLine">
        <i
          v-if="leftIcon"
          class="el-icon-caret-left clickLineLeft"
          @click="handleLeftIcon"
        />
        <i
          v-if="rightIcon"
          class="el-icon-caret-right clickLineRight"
          @click="handleRightIcon"
        />
      </div>
      <div ref="contantRef" style="width: 87%; overflow: scroll">
        <!-- 查询 -->
        <div class="mb10">
          <el-form :inline="true" :model="queryForm" @submit.native.prevent>
            <el-form-item>
              <el-input
                v-model.trim="queryForm.buttonName"
                clearable
                placeholder="按钮名称"
              />
            </el-form-item>
            <el-form-item>
              <el-input
                v-model.trim="queryForm.buttonInfo"
                clearable
                placeholder="按钮信息"
              />
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="queryForm.buttonStatus"
                clearable
                placeholder="按钮状态"
              >
                <el-option
                  v-for="item in roleSelectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="queryForm.buttonType"
                clearable
                placeholder="按钮类型"
              >
                <el-option
                  v-for="item in buttonTypeList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-search"
                type="primary"
                @click="handleQuery"
              >
                查询
              </el-button>
              <el-button
                icon="el-icon-refresh-right"
                type="primary"
                @click="handleReset"
              >
                重置
              </el-button>
            </el-form-item>
            <el-form-item v-if="showsed.shows != 1" style="float: right">
              <el-button
                v-for="item in searchButton"
                :key="item.id"
                :plain="item.cssType && item.cssType == 'plain' ? true : false"
                :type="item.buttonCss"
                @click.native="handleClickBtn(item)"
              >
                <span v-if="!item.buttonIcon"></span>
                <vab-icon v-else :icon="item.buttonIcon" />
                {{ item.buttonName }}
              </el-button>
              <!-- <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
                新增
              </el-button>
              <el-button type="primary" @click="handleExport">
                <vab-icon icon="file-copy-2-fill" />
                导出
              </el-button> -->
            </el-form-item>
          </el-form>
        </div>
        <!-- 列表 -->
        <div :class="showsed.shows != 1 ? '' : 'classShows'">
          <el-table
            ref="orgTableDataList"
            v-loading="listLoading"
            border
            :data="list"
            :row-key="getRowKeys"
            tooltip-effect="dark"
            :tree-props="{ children: 'children' }"
            @selection-change="setSelectRows"
          >
            <el-table-column
              prop="id"
              :reserve-selection="true"
              show-overflow-tooltip
              type="selection"
              width="40"
            />
            <el-table-column
              label="按钮名称"
              min-width="160px"
              prop="buttonName"
              show-overflow-tooltip
            />

            <el-table-column
              label="按钮信息"
              min-width="220px"
              prop="buttonInfo"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              :formatter="formatterOrder"
              label="按钮类型"
              prop="buttonType"
              show-overflow-tooltip
              width="80px"
            />
            <el-table-column
              align="center"
              label="触发类型"
              prop="actionType"
              show-overflow-tooltip
              width="80px"
            >
              <template #default="{ row }">
                <div v-if="row.actionType != null">
                  {{ row.actionType === 1 ? 'onClick' : 'url' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="按钮路径"
              prop="buttonUrl"
              show-overflow-tooltip
              width="110px"
            />
            <el-table-column
              align="center"
              label="模块"
              prop="modelType"
              show-overflow-tooltip
              width="50px"
            />
            <el-table-column
              align="center"
              label="按钮图标"
              prop="buttonIcon"
              show-overflow-tooltip
              :width="showsed.shows != 1 ? '140px' : ''"
            >
              <template #default="{ row }">
                <div v-if="showsed.shows != 1">
                  <div style="position: relative; align-items: center">
                    <div>
                      <vab-icon v-if="!row.buttonIcon" icon="" />
                      <vab-icon v-else :icon="row.buttonIcon" />
                      <div>{{ row.buttonIcon }}</div>
                    </div>
                    <i
                      v-if="listShows"
                      class="el-icon-edit"
                      style="
                        position: absolute;
                        top: 10px;
                        right: 0px;
                        color: #1890ff;
                        cursor: pointer;
                      "
                      @click="handleIconedit(row)"
                    ></i>
                  </div>
                </div>
                <div v-else>
                  <i v-if="!row.buttonIcon" />
                  <vab-icon v-else :icon="row.buttonIcon" />
                  <div>{{ row.buttonIcon }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="按钮颜色"
              prop="buttonCss"
              show-overflow-tooltip
              :width="showsed.shows != 1 ? '110px' : '100px'"
            >
              <template #default="{ row }">
                <!-- <div v-if="showsed.shows != 1"> -->
                <div style="position: relative; align-items: center">
                  <div>
                    <el-button
                      :plain="row.cssType == 'plain' ? true : false"
                      style="padding: 3px; font-size: 12px"
                      :type="row.buttonCss"
                    >
                      {{ formatterbuttonCss(row.buttonCss) }}
                    </el-button>
                  </div>
                  <i
                    v-if="listShows"
                    class="el-icon-edit"
                    style="
                      position: absolute;
                      top: 5px;
                      right: 0px;
                      color: #1890ff;
                      cursor: pointer;
                    "
                    @click="handleCssedit(row)"
                  ></i>
                </div>
                <!-- </div> -->
                <!-- <div v-else>
                  <div v-html="formatterbuttonCss(row.buttonCss)"></div>
                </div> -->
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="状态"
              show-overflow-tooltip
              width="70px"
            >
              <template #default="{ row }">
                <el-tag
                  v-if="row.buttonStatus != null"
                  :type="row.buttonStatus === 1 ? 'danger' : 'success'"
                >
                  {{ row.buttonStatus === 1 ? '删除' : '正常' }}
                </el-tag>
              </template>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              label="排序"
              prop="buttonSort"
              show-overflow-tooltip
              width="50px"
            /> -->
            <el-table-column
              align="center"
              label="排序"
              prop="buttonSort"
              show-overflow-tooltip
            >
              <template #default="{ row }">
                <div>
                  <el-input
                    v-if="row.inputVisible"
                    v-model.trim="row.buttonSort"
                    :max="0"
                    :maxlength="2"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    style="width: 50px"
                    @blur="handleInputConfirm(row)"
                    @keyup.enter.native="handleInputConfirm(row)"
                  />
                  <span v-else>{{ row.buttonSort }}</span>
                  <i
                    v-if="listShows"
                    class="el-icon-edit"
                    style="
                      position: absolute;
                      top: 40%;
                      right: 1px;
                      color: #1890ff;
                      cursor: pointer;
                    "
                    @click="handleSortdit(row)"
                  ></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="创建时间"
              min-width="140px"
              prop="createTime"
              show-overflow-tooltip
            >
              <template #default="{ row }">
                <div>
                  {{ row.createTime | formatDate }}
                </div>
              </template>
            </el-table-column>
            <template v-if="operationShow">
              <el-table-column
                v-if="showsed.shows != 1"
                align="center"
                fixed="right"
                label="操作"
                min-width="210px"
              >
                <template #default="{ row }">
                  <template v-for="item in listButton">
                    <el-button
                      v-if="showListButton(row, item)"
                      :key="item.id"
                      :plain="
                        item.cssType && item.cssType == 'plain' ? true : false
                      "
                      size="mini"
                      :type="item.buttonCss"
                      @click.native="handleClickBtn(item, row)"
                    >
                      <span v-if="!item.buttonIcon"></span>
                      <vab-icon v-else :icon="item.buttonIcon" />
                      {{ item.buttonName }}
                    </el-button>
                  </template>
                  <!-- <el-button
                  style="margin-right: 5px"
                  type="text"
                  @click="handleEdit(row)"
                >
                  编辑
                </el-button>
                <el-button
                  v-if="row.parentId == 0"
                  style="margin-right: 5px"
                  type="text"
                  @click="handleAddson(row)"
                >
                  添加子级
                </el-button>
                <el-button size="small" type="text" @click="handleDelete(row)">
                  删除
                </el-button> -->
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
        <!-- 分页 -->
        <el-pagination
          v-if="showsed.shows != 1"
          background
          :current-page="forms.current"
          :layout="layout"
          :page-size="forms.size"
          :page-sizes="[8, 10, 20, 30, 40, 60, 80, 100]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </el-container>
    <!-- 按钮图标弹框 -->
    <el-dialog class="dialogMenu" :visible.sync="dialogFormVisibleIcon">
      <vab-icon-selector @handle-icon="handleIcon" />
    </el-dialog>
    <!-- 编辑按钮颜色弹框 -->
    <el-dialog
      v-if="dialogFormVisibleCss"
      :close-on-click-modal="false"
      title="编辑按钮颜色"
      :visible.sync="dialogFormVisibleCss"
      width="40%"
      @close="closeCss"
    >
      <el-form
        ref="postForm"
        class="postForm"
        label-width="80px"
        :model="postFormcss"
        :rules="rulescss"
      >
        <el-form-item label="按钮颜色" prop="buttonCss">
          <el-select
            v-model="postFormcss.buttonCss"
            clearable
            placeholder="请选择"
            style="width: 50%"
          >
            <el-option
              v-for="item in buttonCssList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            />
          </el-select>
          <el-checkbox v-model="postFormcss.cssType" class="ml30">
            透明按钮
          </el-checkbox>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="closeCss">取 消</el-button>
        <el-button type="primary" @click="updateDataCSS()">确 定</el-button>
      </template>
    </el-dialog>
    <!-- 添加编辑弹框 -->
    <el-dialog
      v-if="dialogFormVisible"
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="close"
    >
      <el-form
        ref="postForm"
        class="postForm"
        label-width="80px"
        :model="postForm"
        :rules="rules"
      >
        <el-form-item label="按钮名称" prop="buttonName">
          <el-input v-model.trim="postForm.buttonName" />
        </el-form-item>
        <el-form-item label="按钮模块" prop="modelType">
          <el-input-number
            v-model="postForm.modelType"
            :min="1"
            placeholder="请输入"
            size="small"
            style="width: 100%"
          />
        </el-form-item>
        <el-form-item label="按钮类型" prop="buttonType">
          <el-select
            v-model="postForm.buttonType"
            clearable
            placeholder="请选择"
            @change="selectChanged"
          >
            <el-option
              v-for="item in buttonTypeList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="触发类型" prop="actionType">
          <el-select
            v-model="postForm.actionType"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in actionTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="按钮路径" prop="buttonUrl">
          <el-input v-model.trim="postForm.buttonUrl" />
        </el-form-item>
        <el-form-item v-if="title === '新增'" label="菜单" prop="menuId">
          <el-cascader
            ref="cascaderMallCatergory"
            v-model="postForm.menuId"
            clearable
            :options="menuIdlist"
            :props="{ checkStrictly: true, label: 'menuName', value: 'id' }"
            style="width: 90%"
            @change="changeMenuId"
          />
        </el-form-item>
        <el-form-item label="按钮颜色" prop="buttonCss">
          <el-select
            v-model="postForm.buttonCss"
            clearable
            placeholder="请选择"
            style="width: 30%"
          >
            <el-option
              v-for="item in buttonCssList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            />
          </el-select>
          <el-checkbox v-model="postForm.cssType" class="ml30">
            透明按钮
          </el-checkbox>
        </el-form-item>
        <el-form-item label="按钮排序" prop="buttonSort">
          <el-input-number
            v-model="postForm.buttonSort"
            :min="1"
            placeholder="请输入"
            size="small"
            style="width: 100%"
          />
        </el-form-item>
        <el-form-item label="按钮状态" prop="buttonStatus">
          <el-radio-group v-model="postForm.buttonStatus">
            <el-radio :label="0">正常</el-radio>
            <el-radio :label="1">删除</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="按钮信息" prop="buttonInfo">
          <el-input v-model.trim="postForm.buttonInfo" type="textarea" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="title === '新增' ? createData() : updateData()"
        >
          确 定
        </el-button>
      </template>
    </el-dialog>
    <!-- 添加子级弹框-->
    <el-dialog
      v-if="dialogFormVisibleSon"
      :close-on-click-modal="false"
      :title="titleSon"
      :visible.sync="dialogFormVisibleSon"
      @close="closeSon"
    >
      <el-form
        ref="postFormSon"
        class="postForm"
        label-width="80px"
        :model="postFormSon"
        :rules="rulesSon"
      >
        <el-form-item label="按钮名称" prop="buttonName">
          <el-input v-model.trim="postFormSon.buttonName" />
        </el-form-item>
        <el-form-item label="按钮模块" prop="modelType">
          <el-input-number
            v-model="postForm.modelType"
            :min="1"
            placeholder="请输入"
            size="small"
            style="width: 100%"
          />
        </el-form-item>
        <el-form-item label="按钮类型" prop="buttonType">
          <el-select
            v-model="postFormSon.buttonType"
            clearable
            placeholder="请选择"
            @change="selectChanged"
          >
            <el-option
              v-for="item in buttonTypeList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="触发类型" prop="actionType">
          <el-select
            v-model="postFormSon.actionType"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in actionTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="按钮路径" prop="buttonUrl">
          <el-input v-model.trim="postFormSon.buttonUrl" />
        </el-form-item>
        <!-- <el-form-item label="菜单" prop="menuId">
          <el-cascader
            ref="cascaderMallCatergory"
            v-model="postFormSon.menuId"
            clearable
            :options="menuIdlist"
            :props="{ checkStrictly: true, label: 'menuName', value: 'id' }"
            style="width: 90%"
          />
        </el-form-item> -->
        <el-form-item label="按钮颜色" prop="buttonCss">
          <el-select
            v-model="postFormSon.buttonCss"
            clearable
            placeholder="请选择"
            style="width: 30%"
          >
            <el-option
              v-for="item in buttonCssList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            />
          </el-select>
          <el-checkbox v-model="postForm.cssType" class="ml30">
            透明按钮
          </el-checkbox>
        </el-form-item>
        <el-form-item label="按钮排序" prop="buttonSort">
          <el-input-number
            v-model="postFormSon.buttonSort"
            :min="1"
            placeholder="请输入"
            size="small"
            style="width: 100%"
          />
        </el-form-item>
        <el-form-item label="按钮状态" prop="buttonStatus">
          <el-radio-group v-model="postFormSon.buttonStatus">
            <el-radio :label="0">正常</el-radio>
            <el-radio :label="1">删除</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="按钮信息" prop="buttonInfo">
          <el-input v-model.trim="postFormSon.buttonInfo" type="textarea" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="closeSon">取 消</el-button>
        <el-button type="primary" @click="createDataSon()">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
  import {
    buttonQueryPage,
    buttonEdit,
    buttonAdd,
    buttonDelete,
    selectMenuButtonId,
  } from '@/api/mall/role/buttonManage'
  import { selectMenuTree } from '@/api/mall/role/roleManage'
  import { selectSysDict, selectRoleMenuButtonList } from '@/api/mall/common'
  import VabIconSelector from '@/components/VabIconSelector/index.vue'
  export default {
    name: 'Sysbutton',
    components: { VabIconSelector },
    props: {
      showsed: {
        type: Object,
        default() {
          return {}
        },
      },
    },
    data() {
      return {
        listShows: false,
        operationShow: false,
        btnStatus: {
          0: ['handleAddson'],
        },
        searchButton: [],
        listButton: [],
        Sort: '',
        rulescss: {
          buttonCss: [
            {
              required: true,
              message: '请选择按钮颜色',
              trigger: ['blur', 'change'],
            },
          ],
        },
        postFormcss: {},
        dialogFormVisibleCss: false,
        menuIds: '',
        buttonCssList: [],
        rowIdson: '',
        rowPareIdson: '',
        dialogFormVisibleIcon: false,
        listSlis: [],
        menuIdlist: [],
        leftIcon: true,
        rightIcon: false,
        menuTableData: [],
        roleSelectList: this.$dict.roleSelectList,
        buttonTypeList: [],
        actionTypeList: this.$dict.actionTypeList,
        rowId: '',
        orgsListTable: [],
        parentName: '',
        list: [],
        listLoading: false,
        current: 1,
        size: 8,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: [],
        forms: { current: 1, size: 10 },
        isShow: true,
        queryForm: {
          buttonInfo: '',
          menuId: '',
          buttonType: '',
          buttonStatus: '',
          buttonName: '',
        },
        title: '',
        dialogFormVisible: false,
        postForm: {
          buttonName: '',
          buttonInfo: '',
          actionType: '1',
          buttonType: '1',
          buttonUrl: '',
          buttonSort: '',
          buttonIcon: '',
          buttonStatus: 0,
          buttonCss: 'primary',
          modelType: '1',
        },
        rules: {
          modelType: [
            {
              required: true,
              message: '请选择按钮模块',
              trigger: ['blur', 'change'],
            },
          ],
          buttonStatus: [
            {
              required: true,
              message: '请选择按钮状态',
              trigger: ['blur', 'change'],
            },
          ],
          menuId: [
            {
              required: true,
              message: '请选择按钮对应的菜单',
              trigger: ['blur', 'change'],
            },
          ],
          buttonName: [
            {
              required: true,
              message: '请输入按钮名称',
              trigger: ['blur', 'change'],
            },
          ],
          buttonType: [
            {
              required: true,
              message: '请选择按钮类型',
              trigger: ['blur', 'change'],
            },
          ],
          actionType: [
            {
              required: true,
              message: '请选择触发类型',
              trigger: ['blur', 'change'],
            },
          ],
        },

        titleSon: '添加子级',
        dialogFormVisibleSon: false,
        postFormSon: {
          buttonName: '',
          buttonInfo: '',
          actionType: '1',
          buttonType: '1',
          buttonUrl: '',
          buttonSort: '',
          buttonIcon: '',
          buttonStatus: 0,
          buttonCss: 'primary',
          modelType: '1',
        },
        rulesSon: {
          buttonCss: [
            {
              required: true,
              message: '请选择按钮颜色',
              trigger: ['blur', 'change'],
            },
          ],
          modelType: [
            {
              required: true,
              message: '请选择按钮模块',
              trigger: ['blur', 'change'],
            },
          ],
          buttonStatus: [
            {
              required: true,
              message: '请选择按钮状态',
              trigger: ['blur', 'change'],
            },
          ],
          menuId: [
            {
              required: true,
              message: '请选择按钮对应的菜单',
              trigger: ['blur', 'change'],
            },
          ],
          buttonName: [
            {
              required: true,
              message: '请输入按钮名称',
              trigger: ['blur', 'change'],
            },
          ],
          buttonType: [
            {
              required: true,
              message: '请选择按钮类型',
              trigger: ['blur', 'change'],
            },
          ],
          actionType: [
            {
              required: true,
              message: '请选择触发类型',
              trigger: ['blur', 'change'],
            },
          ],
        },
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'label',
        },
        checkedUrlList: [],
        iconId: '',
      }
    },
    created() {
      this.selectMenuTree()
      this.fetchData()
      this.selectSysDict()
      this.selectRoleMenuButtonList()
    },
    methods: {
      // 按钮状态显示判断
      showListButton(row, item) {
        if (
          row.parentId != 0 &&
          this.btnStatus[0].indexOf(item.buttonUrl) != -1
        ) {
          return false
        } else {
          return true
        }
      },
      // 按钮点击
      handleClickBtn(item, row) {
        if (item.buttonUrl) {
          this[item.buttonUrl](row)
        }
      },
      // 按钮权限
      selectRoleMenuButtonList() {
        selectRoleMenuButtonList({
          roleIdStrList: this.$store.getters['acl/roleList'],
          menuId: this.$route.meta.id,
        })
          .then((res) => {
            if (res.data) {
              this.searchButton = res.data.filter(function (item) {
                return item.buttonType == 1
              })
              this.listButton = res.data.filter(function (item) {
                return item.buttonType == 2
              })
              if (this.listButton.length > 0) {
                this.operationShow = true
              } else {
                this.operationShow = false
              }
              const buttonUrl = this.listButton.map((item) => {
                return item.buttonUrl
              })
              if (buttonUrl.includes('handleEdit')) {
                this.listShows = true
              } else {
                this.listShows = false
              }
            }
          })
          .catch(() => {})
      },
      // 排序
      handleInputConfirm(row) {
        const tempData = {}
        tempData.id = this.Sort
        tempData.buttonSort = row.buttonSort
        buttonEdit(tempData)
          .then(() => {
            row.inputVisible = false
            this.fetchData()
          })
          .catch(() => {})
      },
      // 排序编辑
      handleSortdit(row) {
        this.$set(row, 'inputVisible', true)
        row.inputVisible = true
        this.Sort = row.id
      },
      // 按钮类型
      selectChanged(val) {
        if (val == 2) {
          this.postForm.buttonCss = 'text'
          this.postFormSon.buttonCss = 'text'
        }
        if (val == 1 || val == 3) {
          this.postForm.buttonCss = 'primary'
          this.postFormSon.buttonCss = 'primary'
        }
      },
      // 菜单选中
      changeMenuId() {
        this.postForm.buttonInfo = this.$refs['cascaderMallCatergory']
          .getCheckedNodes()[0]
          .pathLabels.toString()
          .replace(/,/g, '-')
      },
      // 列表key
      getRowKeys(row) {
        return row.id
      },

      // 列表选中
      setSelectRows(val) {
        this.selectRows = val
        this.$emit('multiple', val)
      },
      // 按钮颜色
      handleCssedit(row) {
        this.postFormcss = Object.assign({}, row)
        if (row.cssType == 'plain') {
          this.postFormcss.cssType = true
        }
        this.dialogFormVisibleCss = true
        this.iconId = row.id
      },
      // 按钮颜色
      closeCss() {
        this.dialogFormVisibleCss = false
      },
      // 按钮颜色
      updateDataCSS() {
        const tempData = {}
        tempData.id = this.iconId
        tempData.buttonCss = this.postFormcss.buttonCss
        tempData.cssType = this.postFormcss.cssType == true ? 'plain' : ''
        buttonEdit(tempData)
          .then((res) => {
            this.MS(res.message)
            this.closeCss()
            this.fetchData()
          })
          .catch(() => {})
      },
      // 按钮icon弹框
      handleIconedit(row) {
        this.dialogFormVisibleIcon = true
        this.iconId = row.id
      },
      // 按钮icon
      handleIcon(item) {
        const tempData = {}
        tempData.id = this.iconId
        tempData.buttonIcon = item.iconKey
        buttonEdit(tempData)
          .then(() => {
            this.dialogFormVisibleIcon = false
            this.fetchData()
          })
          .catch(() => {})
      },
      // 查询已授权按钮
      async selectRoleUrlId() {
        await selectMenuButtonId({
          roleId: this.showsed.multipleSelection[0].id,
        })
          .then((res) => {
            if (res.data) {
              this.checkedUrlList = res.data
            }
          })
          .catch(() => {})
      },
      // 按钮类型
      formatterOrder(row) {
        for (let i = 0; i < this.buttonTypeList.length; i++) {
          if (this.buttonTypeList[i].dictKey == row.buttonType) {
            return this.buttonTypeList[i].dictValue
          }
        }
      },
      // 按钮颜色
      formatterbuttonCss(row) {
        for (let i = 0; i < this.buttonCssList.length; i++) {
          if (this.buttonCssList[i].dictKey == row) {
            return this.buttonCssList[i].dictValue
          }
        }
      },
      // 按钮类型字典
      async selectSysDict() {
        await selectSysDict({ dictTypeList: ['buttonType', 'buttonCss'] })
          .then((res) => {
            if (res.data) {
              this.buttonTypeList = res.data[0].dictInfoVoList
              this.buttonCssList = res.data[1].dictInfoVoList
            }
          })
          .catch(() => {})
      },
      // 点击左侧收缩
      handleLeftIcon() {
        this.$refs.asideRef.style.width = '10px'
        this.$refs.asideRef.style.minWidth = '10px'
        this.$refs.contantRef.style.width = '98%'
        this.leftIcon = false
        this.rightIcon = true
      },
      // 点击左侧展开
      handleRightIcon() {
        this.$refs.asideRef.style.width = '200px'
        this.$refs.asideRef.style.minWidth = '200px'
        this.$refs.contantRef.style.width = '88%'
        this.leftIcon = true
        this.rightIcon = false
      },
      // 点击菜单查询
      clickAsideData(row) {
        this.current = 1
        this.queryForm.menuId = row.id
        this.fetchData()
        this.$emit('datas', this.queryForm.menuId)
      },
      // 授权菜单读取数据
      selectMenuTree() {
        this.listLoading = true
        selectMenuTree()
          .then((res) => {
            if (res.data) {
              this.menuTableData = res.data
              this.menuIdlist = res.data
            }
          })
          .catch(() => {})
        this.listLoading = false
      },
      // 按钮列表
      async fetchData(options) {
        this.listLoading = true
        await buttonQueryPage({
          ...options,
          menuId: this.queryForm.menuId,
          current: this.showsed.shows == 1 ? 1 : this.forms.current,
          size: this.showsed.shows == 1 ? 1000 : this.forms.size,
        })
          .then((res) => {
            if (res.data) {
              this.list = res.data.records
              this.total = res.data.total
              this.orgsListTable = res.data.records
              // 授权按钮弹框
              if (this.showsed.shows == 1) {
                selectMenuButtonId({
                  roleId: this.showsed.multipleSelection[0].id,
                })
                  .then((res) => {
                    if (res.data) {
                      this.$nextTick(function () {
                        this.checkedUrlList = res.data
                        const checkedUrlList = this.checkedUrlList.map(String)
                        this.list.forEach((row) => {
                          if (
                            checkedUrlList.includes(row.id.toString()) == true
                          ) {
                            this.$refs.orgTableDataList.toggleRowSelection(
                              row,
                              true
                            )
                          }
                          if (row.children != null) {
                            row.children.forEach((item) => {
                              if (
                                checkedUrlList.includes(item.id.toString()) ==
                                true
                              ) {
                                this.$refs.orgTableDataList.toggleRowSelection(
                                  item,
                                  true
                                )
                              }
                            })
                          }
                        })
                      })
                    }
                  })
                  .catch(() => {})
              }
            }
          })
          .catch(() => {})
        this.listLoading = false
      },
      // 方法（选择最后一位）
      menuChange(menuIdlist) {
        if (menuIdlist && menuIdlist.length) {
          const arrList = menuIdlist[menuIdlist.length - 1]
          return arrList
        } else {
          return ''
        }
      },
      // 子级关闭
      closeSon() {
        this.dialogFormVisibleSon = false
        this.$refs['postFormSon'].resetFields()
        this.postFormSon = {
          buttonName: '',
          buttonInfo: '',
          actionType: '1',
          buttonType: '1',
          buttonUrl: '',
          buttonSort: '',
          buttonIcon: '',
          buttonStatus: 0,
          buttonCss: 'primary',
          modelType: '1',
        }
      },
      // 子级新增
      createDataSon() {
        this.$refs['postFormSon'].validate((valid) => {
          if (valid) {
            const parms = {
              parentId: this.rowIdson,
              buttonCss: this.postFormSon.buttonCss,
              menuId: this.menuIds,
              buttonName: this.postFormSon.buttonName,
              buttonInfo: this.postFormSon.buttonInfo,
              buttonType: this.postFormSon.buttonType,
              actionType: this.postFormSon.actionType,
              buttonUrl: this.postFormSon.buttonUrl,
              buttonSort: this.postFormSon.buttonSort,
              buttonIcon: this.postFormSon.buttonIcon,
              buttonStatus: this.postFormSon.buttonStatus,
              modelType: this.postFormSon.modelType,
              cssType: this.postFormSon.cssType == true ? 'plain' : '',
            }
            buttonAdd(parms).then(() => {
              this.MS('新增成功')
              this.closeSon()
              this.fetchData()
            })
          }
        })
      },
      // 子级添加
      handleAddson(row) {
        this.menuIds = row.menuId
        this.rowIdson = row.id
        this.rowPareIdson = row.parentId
        this.dialogFormVisibleSon = true
      },
      // 新增
      createData() {
        this.$refs['postForm'].validate((valid) => {
          if (valid) {
            const parms = {
              parentId: 0,
              buttonCss: this.postForm.buttonCss,
              menuId: this.menuChange(this.postForm.menuId),
              buttonName: this.postForm.buttonName,
              buttonInfo: this.postForm.buttonInfo,
              buttonType: this.postForm.buttonType,
              actionType: this.postForm.actionType,
              buttonUrl: this.postForm.buttonUrl,
              buttonSort: this.postForm.buttonSort,
              buttonIcon: this.postForm.buttonIcon,
              buttonStatus: this.postForm.buttonStatus,
              modelType: this.postForm.modelType,
              cssType: this.postForm.cssType == true ? 'plain' : '',
            }
            buttonAdd(parms).then(() => {
              this.MS('新增成功')
              this.close()
              this.fetchData()
            })
          }
        })
      },
      // 编辑
      updateData() {
        this.$refs['postForm'].validate((valid) => {
          if (valid) {
            for (let i = 0; i < this.buttonTypeList.length; i++) {
              if (
                this.buttonTypeList[i].dictValue == this.postForm.buttonType
              ) {
                this.postForm.buttonType = this.buttonTypeList[i].dictKey
              }
            }
            const tempData = {}
            tempData.buttonCss = this.postForm.buttonCss
            tempData.id = this.rowId
            tempData.buttonName = this.postForm.buttonName
            tempData.buttonInfo = this.postForm.buttonInfo
            tempData.buttonType = this.postForm.buttonType
            tempData.actionType = this.postForm.actionType
            tempData.buttonIcon = this.postForm.buttonIcon
            tempData.buttonStatus = this.postForm.buttonStatus
            tempData.buttonUrl = this.postForm.buttonUrl
            tempData.buttonSort = this.postForm.buttonSort
            tempData.modelType = this.postForm.modelType
            tempData.cssType = this.postForm.cssType == true ? 'plain' : ''
            buttonEdit(tempData)
              .then(() => {
                this.MS('编辑成功')
                this.close()
                this.fetchData()
              })
              .catch(() => {})
          }
        })
      },
      // 导出
      handleExport() {},
      // 新增/编辑
      handleEdit(row) {
        if (row) {
          this.showEdit(row)
        } else {
          this.showEdit()
        }
      },
      // 添加编辑title
      showEdit(row) {
        if (row) {
          this.rowId = row.id
          this.title = '编辑'
          this.postForm = Object.assign({}, row)
          this.postForm.actionType = row.actionType.toString()
          if (row.cssType == 'plain') {
            this.postForm.cssType = true
          }
          for (let i = 0; i < this.buttonTypeList.length; i++) {
            if (this.buttonTypeList[i].dictKey == row.buttonType) {
              this.postForm.buttonType = this.buttonTypeList[i].dictValue
            }
          }
        } else {
          this.title = '新增'
        }
        this.dialogFormVisible = true
      },
      // 关闭
      close() {
        this.dialogFormVisible = false
        this.$refs['postForm'].resetFields()
        this.postForm = {
          buttonName: '',
          buttonInfo: '',
          actionType: '1',
          buttonType: '1',
          buttonUrl: '',
          buttonSort: '',
          buttonIcon: '',
          buttonStatus: 0,
          buttonCss: 'primary',
          modelType: '1',
        }
      },
      // 删除
      handleDelete(row) {
        if (row) {
          if (row.id) {
            this.$confirm(
              `${
                row.children == null
                  ? '是否要删除此行?'
                  : '删除此数据将删除整个下级的数据,是否继续?'
              }`,
              '提示',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              }
            )
              .then(() => {
                buttonDelete({ id: row.id, isDelete: row.isDelete })
                  .then(() => {
                    this.MS('删除成功')
                    this.fetchData()
                    this.dialogFormVisible = false
                  })
                  .catch(() => {})
              })
              .catch(() => {})
          }
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join(',')
            console.log(ids)
            this.$confirm('是否要删除此行?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {})
              .catch(() => {})
          } else {
            if (this.selectRows.length === 0) {
              this.ME('请勾选需要删除的记录')
              return
            }
          }
        }
      },

      // 重置
      handleReset() {
        this.forms.current = 1
        this.forms.size = 10
        this.queryForm = {}
        this.handleQuery()
      },
      // 搜索
      async handleQuery() {
        this.forms.current = 1
        await this.fetchData({ ...this.queryForm }, false)
        let temp = false
        Object.keys(this.queryForm).forEach((key) => {
          this.queryForm[key] && (temp = true)
        })
        this.isShow = temp
      },
      // 分页
      handleSizeChange(val) {
        this.forms.size = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
      // 分页
      handleCurrentChange(val) {
        this.forms.current = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .classShows {
    max-height: 540px;
    overflow: auto;
  }
  .filter-btn {
    float: right;
  }
  .el-aside {
    width: 190px !important;
  }
  .asideAuthorizeUrl {
    // width: 200px;
    // height: 600px;
    // overflow: scroll;
    // border: 1px solid #c0d4f0;
    ::v-deep(.el-table) {
      th.is-leaf {
        padding: 15px 0;
        background: #fff;
      }
    }
    ::v-deep(.el-input) {
      width: 140px;
      padding: 10px;
    }
    ::v-deep .aside-table td,
    .aside-table th.is-leaf {
      // border: none;
      cursor: pointer;
    }
    ::v-deep(.aside-table th) {
      .cell {
        font-weight: 700 !important;
        color: #909399 !important;
      }
    }
    ::v-deep(.aside-table::before) {
      width: 0;
    }
  }
  .clickLine {
    width: 8px;
    height: 600px;
    margin-right: 5px;
    margin-left: 5px;
    background-color: #c0d4f0;
    .clickLineLeft {
      position: relative;
      top: 50%;
      left: -3px;
      color: #97a8be;
      cursor: pointer;
    }
    .clickLineRight {
      position: relative;
      top: 50%;
      left: -5px;
      color: #97a8be;
      cursor: pointer;
    }
  }
  .filter-input {
    width: 150px;
  }
  .addDialog {
    .el-form-item__label {
      width: 90px;
      font-size: 14px;
      font-weight: 700;
    }
    .el-input {
      width: 90%;
    }
    .el-textarea {
      width: 90%;
    }

    ::v-deep(.el-form-item) {
      // width: 80%;
    }
  }
  .postForm {
    .el-select,
    .el-cascader {
      width: 100%;
    }
  }
  .contantAuthorizeUrl {
    width: 100%;
    padding: 0px 10px;
  }
</style>
