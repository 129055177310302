var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-table-container" },
    [
      _c("div", { staticClass: "header_eitd" }, [
        _c(
          "div",
          { staticClass: "lef" },
          [
            _c(
              "el-steps",
              {
                attrs: {
                  active: _vm.num,
                  "finish-status": "success",
                  simple: "",
                },
              },
              [
                _c("el-step", { attrs: { title: "处理中 " } }),
                _c("el-step", { attrs: { title: "待审核" } }),
                _c("el-step", { attrs: { title: "已完成" } }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "thead_cont" }, [
        _c("div", { staticClass: "lefCont" }, [_c("aftersalt")], 1),
        _c(
          "div",
          { staticClass: "lefCont" },
          [_c("beforcont", { attrs: { showhand: _vm.num } })],
          1
        ),
      ]),
      _c("distrtion", {
        attrs: { shodist: _vm.distrtionshow, userid: _vm.listID },
        on: { closedistr: _vm.closdistr },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }