var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register-container" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tableData },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "用户信息", prop: "goodsSpu" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "ym_userinfo" }, [
                      _c(
                        "div",
                        { staticClass: "ym_lefpic" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "50px", height: "50px" },
                            attrs: { src: scope.row.userInfo.headimgUrl },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "ym_ref_user" }, [
                        _c("div", { staticClass: "ym_username" }, [
                          _vm._v(
                            "昵称：" + _vm._s(scope.row.userInfo.nickName)
                          ),
                        ]),
                        _c("div", { staticClass: "ym_code" }, [
                          _vm._v(
                            "编号：" + _vm._s(scope.row.userInfo.userCode)
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "商品信息", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "ym_userinfo" }, [
                      _c(
                        "div",
                        { staticClass: "ym_lefpic" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "50px", height: "50px" },
                            attrs: { src: scope.row.goodsSpu.picUrls[0] },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "ym_ref_user" }, [
                        _c("div", { staticClass: "ym_username" }, [
                          _vm._v(_vm._s(scope.row.goodsSpu.name)),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", prop: "createTime" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pant" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": 1,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }