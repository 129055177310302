import request from '@/utils/request'

export function getIndianaList(data) {
  return request({
    url: `/mall/activity/acActInstance/queryPage`,
    method: 'post',
    data,
  })
}

export function getIndianaUserList(data) {
  return request({
    url: `/mall/activity/acActInstUser/queryPage`,
    method: 'post',
    data,
  })
}

export function closeIndiana(data) {
  return request({
    url: `/mall/activity/acActInstance/close`,
    method: 'post',
    data,
  })
}

export function extensionIndiana(data) {
  return request({
    url: `/mall/activity/acActInstance/extension`,
    method: 'post',
    data,
  })
}

export function createStep1(data) {
  return request({
    url: `/mall/activity/acActInstance/add`,
    method: 'post',
    data,
  })
}

export function createStep2(data) {
  return request({
    url: `/mall/activity/acActInstSetting/add`,
    method: 'post',
    data,
  })
}

export function createStep3(data) {
  return request({
    url: `/mall/activity/acActInstance/addPrizes`,
    method: 'post',
    data,
  })
}

export function getStep1(data) {
  return request({
    url: `/mall/activity/acActInstance/details`,
    method: 'post',
    data,
  })
}

export function getStep2(data) {
  return request({
    url: `/mall/activity/acActInstSetting/details`,
    method: 'post',
    data,
  })
}

export function getStep3(data) {
  return request({
    url: `/mall/activity/acActInstance/loadPrizes`,
    method: 'post',
    data,
  })
}

export function editStep1(data) {
  return request({
    url: `/mall/activity/acActInstance/edit`,
    method: 'post',
    data,
  })
}

export function editStep2(data) {
  return request({
    url: `/mall/activity/acActInstSetting/edit`,
    method: 'post',
    data,
  })
}

export function editStep3(data) {
  return request({
    url: `/mall/activity/acActInstance/editPrizes`,
    method: 'post',
    data,
  })
}

export function copyPrizes(data) {
  return request({
    url: `/mall/activity/acActInstance/copyPrizes`,
    method: 'post',
    data,
  })
}

export function addPrizeLevel(data) {
  return request({
    url: `/mall/activity/acActInstItem/addPrizeSettingInfo`,
    method: 'post',
    data,
  })
}

export function editPrizeLevel(data) {
  return request({
    url: `/mall/activity/acActInstItem/edit`,
    method: 'post',
    data,
  })
}

export function deletePrizeLevel(data) {
  return request({
    url: `/mall/activity/acActInstItem/delete`,
    method: 'post',
    data,
  })
}

export function getPrizeList(data) {
  return request({
    url: `/mall/activity/acActInstItem/queryPage`,
    method: 'post',
    data,
  })
}

export function getPrizeDetailList(data) {
  return request({
    url: `/mall/activity/acActGoods/queryGoodsListForPage`,
    method: 'post',
    data,
  })
}

export function deletePrizeDetail(data) {
  return request({
    url: `/mall/activity/acActGoods/deleteUpInfo`,
    method: 'post',
    data,
  })
}

export function addGoodsDetail(data) {
  return request({
    url: `/mall/activity/acActGoods/add`,
    method: 'post',
    data,
  })
}

export function editGoodsDetail(data) {
  return request({
    url: `/mall/activity/acActGoods/edit`,
    method: 'post',
    data,
  })
}
