<template>
  <div class="comprehensive-table-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="计划详情" name="Projectdetails">
        <Projectdetails :value="Projectdetails" />
      </el-tab-pane>
      <el-tab-pane label="执行人员" name="executive">
        <Executive :value="Projectdetails" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Projectdetails from './components/Projectdetails.vue'
import Executive from './components/Executive.vue'
import { vitMealPlandetailsInfo } from '@/api/mall/health/healthplantype/index'
export default {
  components: {
    Projectdetails,
    Executive,
  },
  data() {
    return {
      activeName: 'Projectdetails',
      Projectdetails: null,
    }
  },
  created() {
    const num = this.$route.query.date
    if (num) {
      this.quaryPage(num)
    }
  },
  methods: {
    quaryPage(num) {
      vitMealPlandetailsInfo({ id: num }).then((res) => {
        this.Projectdetails = res.data
      })
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
  },
}
</script>

<style></style>
