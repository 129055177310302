<template>
  <div class="cy-container">
    <el-button icon="el-icon-plus" type="primary" @click="addnew = true">新增</el-button>
    <el-tabs v-model="form.conditionFrom" @tab-click="handleClick">
      <el-tab-pane v-for="(ym, index) in tag" :key="index" :label="ym.dictValue" :name="ym.dictType" />
    </el-tabs>
    <el-table border :data="tableData" style="width: 100%">
      <el-table-column align="center" label="编号" type="index" width="50" />
      <el-table-column align="center" label="标题" prop="platTitle" width="230" />
      <el-table-column align="center" label="内容" prop="platContent" />
      <el-table-column align="center" label="类型" prop="type" />
      <el-table-column align="center" label="分类" prop="conditionFrom" />
      <el-table-column align="center" label="创建时间" prop="createTime" width="180" />
      <el-table-column align="center" label="操作" width="180">
        <template #default="scope">
          <el-link style="margin: 0 5px" type="primary" :underline="false" @click="editro(scope.row)">编辑</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <el-dialog :before-close="handleClose" title="编辑" :visible.sync="dialogVisible" width="60%">
      <span>
        <el-form ref="formEdit" label-width="80px" :model="editrow" :rules="rules">
          <el-form-item label="类型：" prop="platTitle">
            <el-input v-model="editrow.platTitle" placeholder="请输入 类型" />
          </el-form-item>
          <el-form-item label="内容：" prop="platContent">
            <el-input v-model="editrow.platContent" placeholder="请输入 内容" type="textarea" />
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="edisave">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :before-close="handleCloseadd" title="新增" :visible.sync="addnew" width="60%">
      <span>
        <el-form ref="formAddlist" label-width="120px" :model="newrows" :rules="rsult">
          <el-form-item label="所属租户	:" prop="tenantId">
            <el-input v-model="newrows.tenantId" placeholder="请输入 所属租户	" />
          </el-form-item>
          <el-form-item label="类型：" prop="platTitle">
            <el-input v-model="newrows.platTitle" placeholder="请输入 类型" type="textarea" />
          </el-form-item>
          <el-form-item label="app_key" prop="platContent">
            <el-input v-model="newrows.platContent" placeholder="请输入 app_key" />
          </el-form-item>
          <el-form-item label="type	:" prop="type">
            <el-input v-model="newrows.type" placeholder="请输入 type	" />
          </el-form-item>
          <el-form-item label="conditionFrom	:" prop="conditionFrom">
            <el-select v-model="newrows.conditionFrom" placeholder="请选择 conditionFrom">
              <el-option v-for="(ym, index) in tag" :key="index" :label="ym.dictValue" :value="ym.dictType" />
            </el-select>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clears">取 消</el-button>
        <el-button type="primary" @click="addsave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getPage, getPagelist, putpage, addpages } from '@/api/mall/admin/sysstting'
  export default {
    data() {
      return {
        tag: [],
        tableData: [],
        total: 0,
        dialogVisible: false,
        addnew: false,
        form: {
          current: 1,
          size: 10,
          conditionFrom: 'taobao',
        },
        editrow: {},
        rules: {
          platTitle: [{ required: true, message: '请输入 所属租户', trigger: 'blur' }],
          platContent: [{ required: true, message: '请输入 app_key', trigger: 'blur' }],
        },
        rsult: {
          tenantId: [{ required: true, message: '请输入 类型', trigger: 'blur' }],
          platTitle: [{ required: true, message: '请输入 类型', trigger: 'blur' }],
          platContent: [{ required: true, message: '请输入 app_key', trigger: 'blur' }],
          type: [{ required: true, message: '请输入 type', trigger: 'blur' }],
          conditionFrom: [{ required: true, message: '请输入 conditionFrom', trigger: 'blur' }],
        },
        newrows: {},
      }
    },
    created() {
      this.getPage()
      this.getPagelist()
    },
    methods: {
      getPage() {
        getPage({ parentId: '831' }).then((res) => {
          this.tag = res.data.records
        })
      },
      getPagelist() {
        getPagelist({ ...this.form }).then((res) => {
          this.tableData = res.data.records
          this.form.size = res.data.size
          this.form.current = res.data.current
          this.total = res.data.total
        })
      },
      handleCurrentChange(e) {
        this.getPagelist()
        this.form.current = e
      },
      handleSizeChange(e) {
        this.getPagelist()
        this.form.size = e
      },
      handleClick() {
        this.getPagelist()
      },
      handleClose() {
        this.dialogVisible = false
      },
      handleCloseadd() {
        this.addnew = false
      },
      editro(row) {
        this.dialogVisible = true
        this.editrow = row
      },
      edisave() {
        this.$refs.formEdit.validate((valid) => {
          if (valid) {
            this.putpage()
          } else {
            return false
          }
        })
      },
      putpage() {
        putpage({ ...this.editrow }).then(() => {
          this.$message.success('编辑成功')
          this.dialogVisible = false
          this.getPagelist()
        })
      },
      clears() {
        this.addnew = false
        this.newrows = {}
      },
      addsave() {
        this.$refs.formAddlist.validate((valid) => {
          if (valid) {
            this.addpages()
          } else {
            return false
          }
        })
      },
      addpages() {
        addpages({ ...this.newrows }).then(() => {
          this.$message.success('新增成功')
          this.addnew = false
          this.newrows = {}
          this.getPagelist()
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #e8e8e8;
  }
</style>
