<template>
  <div class="cy-container">
    <!-- aside侧边菜单（商城列表）-->
    <!-- <div
        ref="asideRef"
        class="asideAuthorizeUrl"
        style="
          width: 200px;
          height: 700px;
          margin-right: 5px;
          overflow: scroll;
          border: 1px solid #c0d4f0;
        "
      >
        <el-aside>
          <el-table
            v-loading="listLoading"
            class="aside-table"
            :data="menuListTable"
            :highlight-current-row="true"
            row-key="id"
            style="width: 100%; margin-bottom: 20px"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @row-click="clickAsideData"
          >
            <el-table-column label="商城名称" prop="orgName" />
          </el-table>
        </el-aside>
      </div> -->
    <!-- 点击收缩 -->
    <!-- <div class="clickLine">
        <i
          v-if="leftIcon"
          class="el-icon-caret-left"
          style="
            position: relative;
            top: 50%;
            left: -3px;
            color: #97a8be;
            cursor: pointer;
          "
          @click="handleLeftIcon"
        />
        <i
          v-if="rightIcon"
          class="el-icon-caret-right"
          style="
            position: relative;
            top: 50%;
            left: -5px;
            color: #97a8be;
            cursor: pointer;
          "
          @click="handleRightIcon"
        />
      </div> -->

    <div ref="contantRef">
      <!-- 查询/ -->
      <div class="">
        <el-form ref="refGeneral" :inline="true" :model="queryForm" @submit.native.prevent>
          <!-- <el-form-item>
              <el-input
                v-model.trim="queryForm.username"
                class="filter-input"
                clearable
                placeholder="用户名"
                type="text"
              />
            </el-form-item> -->
          <div class="lef">
            <el-form-item label="员工姓名">
              <el-input v-model.trim="queryForm.nickname" class="filter-input" clearable placeholder="昵称" type="text" />
            </el-form-item>
            <el-form-item label="员工手机">
              <el-input v-model.trim="queryForm.mobile" class="filter-input" clearable placeholder="手机号" type="text" />
            </el-form-item>
            <el-form-item label="员工工号">
              <el-input v-model.trim="queryForm.profile" class="filter-input" clearable placeholder="工号" type="text" />
            </el-form-item>
            <el-form-item label="员工角色">
              <el-select v-model.trim="queryForm.roleId" class="filter-input" clearable placeholder="角色" @click.native="handleRoleSelect">
                <el-option v-for="item in roleSelectList" :key="item.value" :label="item.name" :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="员工状态">
              <el-select v-model="queryForm.status" class="filter-input" clearable placeholder="状态">
                <el-option v-for="item in stateSelect" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="公司">
              <el-input v-model.trim="queryForm.fullName" class="filter-input" clearable placeholder="公司" type="text" />
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" @click="handleQuery">查询</el-button>
              <el-button icon="el-icon-refresh-right" type="warning" @click="handleReset">重置</el-button>
            </el-form-item>
          </div>
          <div class="ref">
            <el-form-item>
              <el-button
                v-for="item in searchButton"
                :key="item.id"
                :plain="item.cssType && item.cssType == 'plain' ? true : false"
                type="primary"
                @click.native="handleClickBtn(item)"
              >
                <span v-if="!item.buttonIcon"></span>
                <vab-icon v-else :icon="item.buttonIcon" />
                {{ item.buttonName }}
              </el-button>
              <template v-for="items in listButtonParent">
                <template v-if="items.buttonType == 1 && items.children">
                  <el-button v-for="itemss in items.children" :key="itemss.id" @click.native="handleClickBtn(itemss)">{{ itemss.buttonName }}</el-button>
                </template>
              </template>
              <!-- <el-button
                class="filter-items"
                icon="el-icon-plus"
                type="primary"
                @click="handleEdit"
              >
                新增
              </el-button>

              <el-dropdown
                class="filter-items"
                split-button
                style="padding-right: 10px; padding-left: 10px"
                trigger="click"
                type="primary"
                @command="handleCommand"
              >
                <i class="el-icon-postcard" style="margin-right: 5px" />
                角色接口
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="authorizeRole"
                    icon="el-icon-postcard"
                  >
                    授权角色
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> -->
              <el-button style="margin-left: 10px" @click="relatedCompanies">关联公司</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="filter-btn"></div>
      <!-- table列表 -->
      <el-table
        :key="tableKey"
        ref="multipleTable"
        v-loading="listLoading"
        border
        :data="list"
        default-expand-all
        fit
        highlight-current-row
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column align="center" show-overflow-tooltip type="selection" width="55" />
        <el-table-column align="center" label="姓名" prop="realName" show-overflow-tooltip />
        <el-table-column align="center" label="手机号" prop="mobile" show-overflow-tooltip />
        <el-table-column align="center" label="工号" prop="profile" show-overflow-tooltip />
        <el-table-column align="center" label="账号" prop="username" show-overflow-tooltip />
        <el-table-column align="center" label="角色" prop="roleIdCn" show-overflow-tooltip />
        <el-table-column align="center" label="公司" prop="fullName" show-overflow-tooltip />
        <el-table-column align="center" label="所属部门" prop="orgIdCn" show-overflow-tooltip />
        <el-table-column align="center" label="状态" prop="status" show-overflow-tooltip>
          <template #default="{ row }">
            <el-tag :type="row.status === 0 ? 'danger' : 'success'">
              {{ row.status === 0 ? '禁用' : '正常' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" show-overflow-tooltip>
          <!-- <template slot-scope="">
              <div></div>
            </template> -->
        </el-table-column>
        <el-table-column v-if="operationShow" align="center" label="操作" width="180">
          <template #default="{ row }">
            <el-button
              v-for="item in listButton"
              :key="item.id"
              :plain="item.cssType && item.cssType == 'plain' ? true : false"
              size="default"
              :type="item.buttonCss"
              @click.native="handleClickBtn(item, row)"
            >
              <!-- <span v-if="!item.buttonIcon"></span>
              <vab-icon v-else :icon="item.buttonIcon" /> -->
              {{ item.buttonName }}
            </el-button>
            <!-- <el-button
                icon="el-icon-edit-outline"
                style="margin-right: 5px"
                type="text"
                @click="handleEdit(row)"
              >
                编辑
              </el-button>
              <el-button
                icon="el-icon-refresh-right"
                type="text"
                @click="handleRePwd(row)"
              >
                重置密码
              </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        :current-page="forms.current"
        :layout="layout"
        :page-size="forms.size"
        :page-sizes="[5, 10, 15, 20, 25, 30]"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
      <!-- 岗位列表 -->
      <!-- <el-tabs v-model="activeName" style="width: 100%; margin-top: 20px" type="border-card">
        <el-tab-pane v-for="item in tabMapOptions" :key="item.key" :label="item.label" :name="item.key">
          <keep-alive>
            <sysuserpost v-if="activeName == item.key" :msg="parentMsg" :type="item.key" />
          </keep-alive>
        </el-tab-pane>
      </el-tabs> -->
    </div>
    <!-- 重置密码弹框-->
    <el-dialog class="pwdDialog" title="重置密码" :visible.sync="pwdDialog" @close="closePwd">
      <el-form ref="pwdForm" label-width="80px" :model="pwdForm" :rules="pwdFormRules">
        <el-form-item label="密码" prop="password">
          <el-input v-model.trim="pwdForm.password" autocomplete="off" clearable placeholder="请输入密码" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closePwd">取 消</el-button>
        <el-button type="primary" @click="savePwd">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="关联公司" :visible.sync="dialogCompany" width="80%">
      <el-table border :data="tableCompanyData" @row-click="showRowCompany">
        <el-table-column align="center" label="选择" width="50">
          <template #default="scope">
            <el-radio v-model="radioCompany" class="radio" :label="scope.row">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column align="center" label="全称" prop="fullname" show-overflow-tooltip />
        <el-table-column align="center" label="简称" prop="shortname" show-overflow-tooltip />
        <el-table-column align="center" label="创建时间" prop="createTime" show-overflow-tooltip />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCompanycancel">取 消</el-button>
        <el-button type="primary" @click="handleCompanyOk">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新增(编辑) -->
    <el-dialog class="addDialog" :close-on-click-modal="false" :title="dialogTitle" :visible.sync="addVisible" @close="close">
      <el-form ref="addForm" label-width="80px" :model="addForm" :rules="addFormRules">
        <el-form-item label="账号" prop="username">
          <el-input v-model="addForm.username" autocomplete="off" clearable :disabled="dialogTitle == '编辑'" placeholder="请输入用户名" />
        </el-form-item>
        <el-form-item v-if="dialogTitle === '新增'" label="登录密码" prop="password">
          <el-input v-model="addForm.password" autocomplete="off" clearable placeholder="请输入密码" />
        </el-form-item>
        <el-form-item label="真实姓名" prop="realName">
          <el-input v-model="addForm.realName" autocomplete="off" clearable :disabled="dialogTitle == '编辑'" placeholder="请输入真实姓名" />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="addForm.email" autocomplete="off" clearable placeholder="请输入邮箱" />
        </el-form-item>
        <el-form-item class="mobilePhone" label="手机号" prop="mobile">
          <el-input v-model="addForm.mobile" class="input-with-select" clearable maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入手机号">
            <el-select v-model="addForm.prefix" placeholder="请选择区号" style="width: 120px" slot="prepend">
              <el-option label="+86" value="86" />
              <el-option label="+87" value="87" />
            </el-select>
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="昵称" prop="nickname">
          <el-input v-model="addForm.nickname" clearable placeholder="请输入昵称" />
        </el-form-item> -->
        <el-form-item label="坐席工号" prop="profile">
          <el-input v-model="addForm.profile" autocomplete="off" clearable placeholder="请输入员工坐席" />
        </el-form-item>
        <el-form-item class="postPare" label="所属部门" prop="orgIds">
          <el-cascader ref="cascaderMallCatergory" v-model="addForm.orgIds" clearable :options="dataSourceLists" :props="props" style="width: 90%" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="addForm.status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="dialogTitle === '新增' ? createData() : updateData()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 授权角色 -->
    <el-dialog v-if="authorizeRoleisible" class="authorizePoles" :close-on-click-modal="false" title="授权角色" :visible.sync="authorizeRoleisible" width="50%" @close="closeRole">
      <!-- <el-select v-model="temp.postCode" class="mb20" clearable placeholder="选择岗位" style="width: 98%"
        @change="handleNodeClickParentId">
        <el-option v-for="item in postCodeOptions" :key="item.id" :label="item.postName" :value="item.id" />
      </el-select> -->

      <el-transfer v-model="grantRoleList" :data="roleList" filterable :props="{ label: 'name', key: 'value' }" :titles="treeTitle" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeRole">取 消</el-button>
        <el-button type="primary" @click="createAuthorizeRoleButton">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 查看接口 -->
    <!-- <el-dialog
      v-if="lookApiVisible"
      title="查看接口"
      :visible.sync="lookApiVisible"
    >
      <sys-url :showsed="showslist" @multiple="mulSelection" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="lookApiVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUrl">确 定</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
// import treeTransfer from 'el-tree-transfer'
import { orgTree } from '@/api/mall/role/orgManage'
// import { submitUrl } from '@/api/mall/role/roleManage'
import { queryRoles, userPage, userAdd, userUpdate, loadDetails, resetPwd, userPost, allotRole, userPhone } from '@/api/mall/role/userManage'
import { selectSysDict, selectRoleMenuButtonList } from '@/api/mall/common'
// import sysuserpost from './components/sysuserpost'
// import SysUrl from '../authority/sysurl'
import { isEmail, hasPhone, hasChinese } from '@/utils/validate'
import { getCompany, addCompany_user } from '@/api/mall/admin/companyManagement'
export default {
  // treeTransfer
  // components: { sysuserpost },
  data() {
    let checkPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('手机号不能为空'))
      } else if (!hasPhone(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    let checkPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('密码不能为空'))
      } else if (!hasChinese(value)) {
        callback(new Error('密码不能包含中文'))
      } else {
        callback()
      }
    }
    return {
      operationShow: false,
      dialogCompany: false,
      tableCompanyData: [], // 公司列表数据
      radioCompany: {}, //选择关联公司
      radioType: false,
      searchButton: [],
      listButton: [],
      listButtonParent: [],
      handleId: '',
      temp: {},
      postCodeOptions: [],
      roleList: [],
      grantRoleList: [],
      companyData: [],
      showslist: {
        shows: '1',
        multipleSelection: [],
      },
      rowIdPwd: '',
      pwdFormRules: {
        password: [
          {
            required: true,
            validator: checkPassword,
            trigger: ['blur', 'change'],
          },
        ],
      },
      pwdForm: {},
      pwdDialog: false,
      dataSourceLists: [],
      props: {
        checkStrictly: true,
        multiple: true,
        label: 'orgName',
        value: 'id',
      },
      parentMsg: [],
      activeName: 'sysuserpost',
      tabMapOptions: [{ label: '岗位信息', key: 'sysuserpost' }],
      tableKey: 0,
      current: 1,
      size: 5,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      listLoading: false,
      forms: { current: 1, size: 5 },
      isShow: true,

      queryForm: {
        nickname: '',
        username: '',
        mobile: '',
        status: '',
        orgId: '',
        roleId: '',
      },
      states: '1',
      menuName: '',
      menuListTable: [],
      orgTypeList: [],
      list: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      orgUserName: '',
      orgName: '',
      addForm: {
        prefix: '86',
        status: 1,
      },
      addVisible: false,
      multipleSelection: [],
      authorityVisible: false,
      checkListModel: {},
      checkList: [],
      leftIcon: true,
      rightIcon: false,
      leftIconApi: true,
      rightIconApi: false,
      dialogTitle: '',
      authorizeRoleisible: false,
      lookApiVisible: false,
      menuNameM: '',
      menuTableData: [],
      authorizeUrlM: '',
      authorizeDescM: '',
      tableDataUrlList: [],
      treeTitle: ['未授权角色', '已授权角色'],
      selectPost: {},
      roleSelectName: '',
      roleSelectList: [],
      stateSelect: [
        {
          id: 0,
          name: '禁用',
        },
        {
          id: 1,
          name: '正常',
        },
      ],
      searchShow: false,
      stateSelectName: '',
      pinkName: '',
      mobilePhones: '',
      addFormRules: {
        prefix: [
          {
            required: true,
            message: '请选择手机区号',
            trigger: ['blur', 'change'],
          },
        ],
        orgIds: [
          {
            required: true,
            message: '请选择所属部门',
            trigger: ['blur', 'change'],
          },
        ],
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: ['blur', 'change'],
          },
        ],

        realName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          {
            required: true,
            validator: checkPassword,
            trigger: ['blur', 'change'],
          },
        ],
        mobile: [
          {
            required: true,
            validator: checkPhone,
            trigger: ['blur', 'change'],
          },
        ],
        status: [
          {
            required: true,
            message: '请选择状态',
            trigger: ['blur', 'change'],
          },
        ],
        // email: [
        //   {
        //     validator: checkEmail,
        //     trigger: ['blur', 'change'],
        //   },
        // ],
      },
      rowId: '',
      urlSelList: [],
      orgsId: '',
    }
  },
  watch: {
    authorizeRoleisible(newVal) {
      if (!newVal) {
        this.grantRoleList = []
      }
    },
  },
  created() {
    this.fetchData()
    this.userPage()
    this.selectRoleMenuButtonList()
  },
  methods: {
    // 按钮点击
    handleClickBtn(item, row) {
      if (item.buttonUrl) {
        this[item.buttonUrl](row)
      }
    },
    // 关联公司
    relatedCompanies() {
      if (this.multipleSelection.length === 0) {
        this.ME('请勾选记录')
        return
      }
      if (this.multipleSelection.length > 1) {
        this.ME('只能勾选一条数据')
        return
      }
      // 公司列表数据
      getCompany().then((reslist) => {
        this.tableCompanyData = reslist
      })
      this.dialogCompany = true
    },
    // 选择关联公司
    showRowCompany(e) {
      console.log(e, 'e')
      this.radioCompany = e
      this.radioType = true
    },
    // 确定关联公司
    handleCompanyOk() {
      const data = {
        userId: this.multipleSelection[0].id,
        companyId: this.radioCompany.id,
      }
      if (this.radioType) {
        addCompany_user(data).then((res) => {
          this.$message({
            type: res.ok ? 'success' : 'error',
            message: res.ok ? '绑定公司成功' : res.msg,
          })
          if (res.ok) {
            this.dialogCompany = false
            this.radioCompany = {}
            this.radioType = false
          }
        })
      } else {
        this.ME('请勾选您需要关联的公司')
      }
    },
    // 取消关联公司
    handleCompanycancel() {
      this.dialogCompany = false
      this.radioCompany = {}
      this.radioType = false
    },

    // 按钮权限
    selectRoleMenuButtonList() {
      selectRoleMenuButtonList({
        roleIdStrList: this.$store.getters['acl/roleList'].length === 0 ? '' : this.$store.getters['acl/roleList'],
        menuId: this.$route.meta.id,
      })
        .then((res) => {
          if (res.data) {
            this.searchButton = res.data.filter(function (item) {
              if (item.children == null) {
                return item.buttonType == 1 && item.modelType == 1
              }
            })
            this.listButton = res.data.filter(function (item) {
              return item.buttonType == 2 && item.modelType == 1
            })
            if (this.listButton.length > 0) {
              this.operationShow = true
            } else {
              this.operationShow = false
            }
            let finds = res.data.filter(function (item) {
              return item.buttonType == 1 && item.modelType == 1 && item.children
            })
            this.listButtonParent = finds
          }
        })
        .catch(() => {})
    },
    // 角色接口
    authorizeRole() {
      if (this.multipleSelection.length === 0) {
        this.ME('请勾选记录')
        return
      }
      if (this.multipleSelection.length > 1) {
        this.ME('只能勾选一条数据')
        return
      }
      this.queryRoles()
      this.authorizeRoleisible = true
    },
    // handleCommand(command) {
    //   if (this.multipleSelection.length === 0) {
    //     this.ME('请勾选记录')
    //     return
    //   }
    //   if (this.multipleSelection.length > 1) {
    //     this.ME('只能勾选一条数据')
    //     return
    //   }
    //   if (command === 'authorizeRole') {
    //     this.queryRoles()
    //     this.authorizeRoleisible = true
    //   }

    // },
    // 导出
    handleExport() {},
    changeDataSource() {},
    // 选择岗位查询角色
    handleNodeClickParentId(data) {
      this.handleId = data
      queryRoles({
        upoId: this.handleId,
        assign: 1,
      }).then((assignResponse) => {
        const assignData = []
        assignResponse.data.map((item) => {
          assignData.push(item.value)
        })
        this.grantRoleList = assignData
      })
    },
    // 角色列表
    queryRolesList() {
      queryRoles({ upoId: '', assign: '' })
        .then((res) => {
          if (res.data) {
            this.roleSelectList = res.data
          }
        })
        .catch(() => {})
    },
    // 用户查询角色信息
    queryRoles() {
      userPost({
        userId: this.multipleSelection[0].id,
        orgId: this.multipleSelection[0].orgId,
      }).then((res) => {
        this.postCodeOptions = res.data
        const postCodeOptions = this.postCodeOptions.map((item) => {
          return item
        })
        // this.temp.postCode = postCodeOptions[0].postName
        queryRoles({ upoId: '', assign: '' })
          .then((res) => {
            if (res.data) {
              this.roleList = res.data
              queryRoles({
                upoId: postCodeOptions[0].id,
                assign: 1,
              }).then((assignResponse) => {
                const assignData = []
                assignResponse.data.map((item) => {
                  assignData.push(item.value)
                })
                this.grantRoleList = assignData
              })
            }
          })
          .catch(() => {})
      })
    },
    // 授权角色保存
    createAuthorizeRoleButton() {
      // if (!this.temp.postCode) {
      //   this.ME('请选择岗位')
      //   return
      // }
      if (this.grantRoleList.length === 0) {
        this.ME('已授权角色不能为空')
        return
      }
      const tempData = {}
      tempData.upoId = this.handleId
      tempData.roleIds = this.grantRoleList
      tempData.userId = this.multipleSelection[0].id
      tempData.orgId = this.multipleSelection[0].orgId
      // tempData.postCode = this.temp.postCode
      allotRole(tempData).then(() => {
        this.authorizeRoleisible = false
        this.MS('授权成功')
        // this.handleReset()
        this.userPage()
      })
    },
    // 查询用户的岗位
    async getList() {
      const parms = {
        userId: this.multipleSelection[0].id,
        orgId: this.multipleSelection[0].orgId,
      }
      await userPost({ ...parms }).then((res) => {
        this.postCodeOptions = res.data
      })
    },
    // 授权接口保存
    // submitUrl() {
    //   if (this.urlSelList.length === 0) return this.ME('请勾选记录')
    //   submitUrl({
    //     roleId: this.multipleSelection[0].id,
    //     urlIdList: this.urlSelList,
    //   })
    //     .then(() => {
    //       this.MS('授权成功')
    //       this.lookApiVisible = false
    //     })
    //     .catch(() => {})
    // },
    // 接口
    mulSelection(val) {
      this.urlSelList = val.map((item) => {
        return item.id
      })
    },
    // 新增
    createData() {
      if (this.addForm.email && this.addForm.email != undefined) {
        if (!isEmail(this.addForm.email)) {
          this.ME('请输入正确的邮箱格式')
          return false
        }
      }
      this.$refs['addForm'].validate(async (valid) => {
        if (valid) {
          const parms = {
            username: this.addForm.username,
            password: this.addForm.password,
            realName: this.addForm.realName,
            email: this.addForm.email,
            prefix: this.addForm.prefix,
            mobile: this.addForm.mobile,
            phone: this.addForm.mobile,
            avatar: 'https://i.gtimg.cn/club/item/face/img/3/15643_100.gif',
            nickname: this.addForm.realName,
            nickName: this.addForm.realName,
            status: this.addForm.status,
            orgIds: this.menuChange(this.addForm.orgIds),
            tenantId: '1', //角色 租户ID
            postId: 0, //0->其它 1->专家
            delFlag: 0, //0-正常，1-删除
            organId: '1', //机构ID
            type: 1, //账号类型：-1、系统管理员；1、租户管理员；2、店铺管理员 3:服务商
            shopId: '1',
            roleIds: ['3'], //角色权限
            lockFlag: 0,
            notifyTodo: 1, // 1:员工  //2:服务商 3:专家
            profile: this.addForm.profile,
          }
          console.log(this.addForm.mobile, '手机号')
          userPhone({ phone: this.addForm.mobile }).then((res) => {
            if (!res.data) {
              userAdd(parms)
                .then(() => {
                  this.MS('新增成功')
                  this.close()
                  this.userPage()
                })
                .catch(() => {})
            } else {
              this.$message.error('手机号已存在')
            }
          })
        }
      })
    },
    // 保存重置密码
    savePwd() {
      this.$refs['pwdForm'].validate((valid) => {
        if (valid) {
          const parms = {
            id: this.rowIdPwd,
            password: this.pwdForm.password,
          }
          resetPwd(parms).then(() => {
            this.MS('重置成功')
            this.closePwd()
            this.userPage()
          })
        }
      })
    },
    // 重置密码
    handleRePwd(row) {
      this.rowIdPwd = row.id
      this.pwdDialog = true
    },
    // 编辑
    updateData() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          if (this.addForm.email && this.addForm.email != undefined) {
            if (!isEmail(this.addForm.email)) {
              this.ME('请输入正确的邮箱格式')
              return false
            }
          }
          const res = [...this.addForm.orgIds]
          const orgIds = res.map((item) => {
            let items = item.toString().split(',')
            return items[items.length - 1]
          })
          const parms = {
            id: this.rowId,
            username: this.addForm.username,
            email: this.addForm.email,
            prefix: this.addForm.prefix,
            mobile: this.addForm.mobile,
            profile: this.addForm.profile,
            nickname: this.addForm.realName,
            status: this.addForm.status,
            orgIds: orgIds,
          }
          userPhone({ phone: this.addForm.mobile, id: this.rowId }).then((res) => {
            if (!res.data) {
              userUpdate(parms).then(() => {
                this.MS('修改成功')
                this.close()
                this.userPage()
              })
            } else {
              this.$message.error('手机号已存在')
            }
          })
        }
      })
    },
    // 所属商城
    menuChange(dataSourceLists) {
      if (dataSourceLists && dataSourceLists.length) {
        const arr = dataSourceLists.map((item) => {
          return item[item.length - 1]
        })
        // const str = arr.join(',')
        return arr
      } else {
        return ''
      }
    },
    // 用户列表
    userPage(options) {
      // const parms = {
      //   username: this.queryForm.username,
      //   mobile: this.queryForm.mobile,
      //   status: this.queryForm.status,
      //   orgId: this.queryForm.orgId,
      //   roleId: this.queryForm.roleId,
      //   current: this.current,
      //   size: this.size,
      // }
      userPage({ ...options, ...this.forms, orgId: this.queryForm.orgId })
        .then((res) => {
          if (res.data) {
            this.list = res.data.records
            this.total = res.data.total
          }
        })
        .catch(() => {})
    },
    // 商城列表
    async fetchData() {
      this.listLoading = true
      const parms = {
        current: this.current,
        size: this.size,
      }
      await orgTree(parms)
        .then((res) => {
          if (res.data) {
            this.menuListTable = res.data.records
            this.dataSourceLists = res.data.records
          }
        })
        .catch(() => {})
      this.listLoading = false
    },
    // 点击商城列表
    clickAsideData(row, event, column) {
      console.log(row, event, column)
      this.forms.current = 1
      this.queryForm.orgId = row.id
      this.queryForm.orgName = row.orgName
      this.userPage()
      // querySysUser(this.listQuery).then((response) => {
      //   this.list = response.data.list
      //   this.total = response.data.pagination.total
      //   this.listLoading = false
      // })
    },
    // 列表角色 选择
    handleRoleSelect() {
      this.queryRolesList()
    },
    // 用户状态查询
    async selectSysDict() {
      await selectSysDict({ dictTypeList: ['userStatus'] })
        .then((res) => {
          if (res.data) {
            this.stateSelect = res.data[0].dictInfoVoList
          }
        })
        .catch(() => {})
    },
    // 切换模式
    changeMode() {
      if (this.mode == 'transfer') {
        this.mode = 'addressList'
      } else {
        this.mode = 'transfer'
      }
    },
    // 监听穿梭框组件添加
    addTransfer(fromData, toData, obj) {
      console.log(fromData, toData, obj)
    },
    // 监听穿梭框组件移除
    removeTransfer(fromData, toData, obj) {
      console.log(fromData, toData, obj)
    },
    // 所属商城
    handleNodeClick(node) {
      this.addForm.parentName = node.name
      this.addForm.parentId = node.id
    },
    // 关闭密码弹框
    closePwd() {
      this.$refs['pwdForm'].resetFields()
      this.pwdForm = this.$options.data().pwdForm
      this.pwdDialog = false
    },
    // 关闭
    close() {
      this.$refs['addForm'].resetFields()
      this.addForm = this.$options.data().addForm
      this.addVisible = false
    },
    // 关闭角色
    closeRole() {
      this.temp.postCode = ''
      this.authorizeRoleisible = false
    },
    // 保存
    save() {
      this.$refs['addForm'].validate(async (valid) => {
        if (valid) {
          // this.$emit('fetch-data')
          this.close()
        }
      })
    },

    // 查看接口重置
    handleResetApi() {
      this.authorizeUrlM = ''
      this.authorizeDescM = ''
    },
    // 编辑用户读取数据
    async loadDetails() {
      await loadDetails({ id: this.rowId })
        .then((res) => {
          if (res.data) {
            this.addForm = res.data
          }
        })
        .catch(() => {})
    },
    // 新增/编辑
    handleEdit(row) {
      if (row) {
        this.rowId = row.id
        this.showEdit(row)
      } else {
        this.showEdit()
      }
    },
    // 添加编辑title
    showEdit(row) {
      if (!row) {
        this.dialogTitle = '新增'
      } else {
        this.dialogTitle = '编辑'
        this.loadDetails()
        // this.addForm = Object.assign({}, row)
      }
      this.addVisible = true
    },
    // 表格操作
    handleSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable.toggleRowSelection(val.pop())
      } else {
        this.multipleSelection = val
        this.showslist.multipleSelection = val
        this.parentMsg = val
      }
    },

    // 授权
    handleAuthor() {
      if (this.multipleSelection.length === 0) {
        this.ME('请勾选记录')
        return
      }
      if (this.multipleSelection.length > 1) {
        this.ME('只能勾选一条数据')
        return
      }
      this.authorityVisible = true
    },
    // 点击收缩
    handleLeftIcon() {
      this.$refs.asideRef.style.width = '10px'
      this.$refs.asideRef.style.minWidth = '10px'
      this.$refs.contantRef.style.width = '98%'
      this.leftIcon = false
      this.rightIcon = true
    },
    // 点击展开
    handleRightIcon() {
      this.$refs.asideRef.style.width = '200px'
      this.$refs.asideRef.style.minWidth = '200px'
      this.$refs.contantRef.style.width = '88%'
      this.leftIcon = true
      this.rightIcon = false
    },
    // 重置
    handleReset() {
      this.forms.current = 1
      this.forms.size = 10
      this.queryForm = {}
      this.handleQuery()
    },
    // 搜索
    async handleQuery() {
      this.forms.current = 1
      await this.userPage({ ...this.queryForm }, false)
      let temp = false
      Object.keys(this.queryForm).forEach((key) => {
        this.queryForm[key] && (temp = true)
      })
      this.isShow = temp
    },
    // 分页
    handleSizeChange(val) {
      this.forms.size = val
      let data = this.isShow === true ? this.queryForm : {}
      this.userPage({ ...data }, false)
    },
    // 分页
    handleCurrentChange(val) {
      this.forms.current = val
      let data = this.isShow === true ? this.queryForm : {}
      this.userPage({ ...data }, false)
    },
  },
}
</script>

<style lang="scss" scoped>
.authorizePoles {
  ::v-deep(.el-transfer-panel) {
    width: 40%;
  }
}

.filter-btn {
  float: right;
}

.mobilePhone {
  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background: #fff;
  }
}

.el-aside {
  width: 230px !important;
}

.asideAuthorizeUrl {
  // width: 210px;
  // height: 600px;
  // overflow: scroll;
  // border: 1px solid #c0d4f0;
  ::v-deep(.el-table) {
    th.is-leaf {
      padding: 15px 0;
      background: #fff;
    }
  }

  ::v-deep(.el-input) {
    width: 130px;
    padding: 10px;
  }

  ::v-deep .aside-table td,
  .aside-table th.is-leaf {
    // border: none;
    cursor: pointer;
  }

  ::v-deep(.aside-table th) {
    .cell {
      font-weight: 700 !important;
      color: #909399 !important;
    }
  }

  ::v-deep(.aside-table::before) {
    width: 0;
  }
}

.clickLine {
  width: 8px;
  height: 700px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: #c0d4f0;

  .clickLineLeft {
    position: relative;
    top: 50%;
    left: -3px;
    color: #97a8be;
    cursor: pointer;
  }

  .clickLineRight {
    position: relative;
    top: 50%;
    left: -5px;
    color: #97a8be;
    cursor: pointer;
  }
}

.filter-input {
  width: 140px;
}

.addDialog {
  .el-form-item__label {
    width: 90px;
    font-size: 14px;
    font-weight: 700;
  }

  .el-input {
    width: 90%;
  }

  .el-textarea {
    width: 90%;
  }

  .el-form-item {
    margin-bottom: 22px;
  }
}

.contantAuthorizeUrl {
  width: 100%;
  padding: 0px 10px;
}

.transfer-title {
  margin-top: 0px;
}
</style>