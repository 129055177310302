<template>
  <div style="max-width: 500px;">
    <BaseEditor :editcontent="actionscomponents.description" @editrow='descr'/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import BaseEditor from '@/components/editor/quilledit.vue'
export default {
  components:{
    BaseEditor
  },
  computed:{
    ...mapState(['state']),
    actionscomponents(){
      return this.$store.state.goodssku
    }
  },
  methods:{
    descr(row){
      this.$store.commit("question",row.html)
    }
  }
}
</script>

<style>

</style>