var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "before-close": _vm.claer,
            title: "客情部门",
            visible: _vm.appointDialog,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.appointDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                "label-width": "120px",
                model: _vm.addForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户姓名", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      disabled: "",
                      placeholder: "请输入用户名",
                    },
                    model: {
                      value: _vm.uslist.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.uslist, "userName", $$v)
                      },
                      expression: "uslist.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户电话", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "", placeholder: "客户电话" },
                    model: {
                      value: _vm.uslist.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.uslist, "phone", $$v)
                      },
                      expression: "uslist.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "postPare", attrs: { label: "指派部门" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: "", placeholder: "" },
                      model: {
                        value: _vm.addForm.mallcategory,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "mallcategory", $$v)
                        },
                        expression: "addForm.mallcategory",
                      },
                    },
                    _vm._l(_vm.dataSourceLists, function (ym, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: ym.orgName, value: ym.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客情类型", prop: "afterTypeId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择 客情类型" },
                      model: {
                        value: _vm.addForm.afterTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "afterTypeId", $$v)
                        },
                        expression: "addForm.afterTypeId",
                      },
                    },
                    _vm._l(_vm.optionslist, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.labelName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.addForm.afterTypeId == 1
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户客情时间", prop: "afterTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.afterTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "afterTime", $$v)
                              },
                              expression: "addForm.afterTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户签收金额" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "客户签收金额" },
                            model: {
                              value: _vm.addForm.signAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "signAmount", $$v)
                              },
                              expression: "addForm.signAmount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户反馈情况", prop: "issueMark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "客户反馈情况",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.addForm.issueMark,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "issueMark", $$v)
                              },
                              expression: "addForm.issueMark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.addForm.afterTypeId == 2
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户签收时间", prop: "signTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.signTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "signTime", $$v)
                              },
                              expression: "addForm.signTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户反馈时间", prop: "feedTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.feedTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "feedTime", $$v)
                              },
                              expression: "addForm.feedTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品问题描述", prop: "issueMark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "产品问题描述",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.addForm.issueMark,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "issueMark", $$v)
                              },
                              expression: "addForm.issueMark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.addForm.afterTypeId == 3
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户签收时间", prop: "signTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.signTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "signTime", $$v)
                              },
                              expression: "addForm.signTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户反馈时间", prop: "feedTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.feedTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "feedTime", $$v)
                              },
                              expression: "addForm.feedTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品问题描述", prop: "issueMark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "产品问题描述",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.addForm.issueMark,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "issueMark", $$v)
                              },
                              expression: "addForm.issueMark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.addForm.afterTypeId == 4
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户签收时间", prop: "signTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.signTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "signTime", $$v)
                              },
                              expression: "addForm.signTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户反馈时间", prop: "feedTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.feedTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "feedTime", $$v)
                              },
                              expression: "addForm.feedTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品问题描述", prop: "issueMark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "产品问题描述",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.addForm.issueMark,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "issueMark", $$v)
                              },
                              expression: "addForm.issueMark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.addForm.afterTypeId == 5
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户客情时间", prop: "afterTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.afterTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "afterTime", $$v)
                              },
                              expression: "addForm.afterTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客户退定金金额",
                            prop: "refundAmount",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "客户签收金额" },
                            model: {
                              value: _vm.addForm.refundAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "refundAmount", $$v)
                              },
                              expression: "addForm.refundAmount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户反馈情况", prop: "issueMark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "客户反馈情况",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.addForm.issueMark,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "issueMark", $$v)
                              },
                              expression: "addForm.issueMark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.addForm.afterTypeId == 6
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户客情时间", prop: "afterTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                            },
                            model: {
                              value: _vm.addForm.afterTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "afterTime", $$v)
                              },
                              expression: "addForm.afterTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户反馈情况", prop: "issueMark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "客户反馈情况",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.addForm.issueMark,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "issueMark", $$v)
                              },
                              expression: "addForm.issueMark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "客情备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "备注信息", type: "textarea" },
                    model: {
                      value: _vm.addForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "remark", $$v)
                      },
                      expression: "addForm.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.aftershowOrder = true
                    },
                  },
                },
                [_vm._v("关联订单")]
              ),
              _vm.tablist.length > 0
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            border: "",
                            data: _vm.tablist,
                            "max-height": "300",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "序号",
                              type: "index",
                              width: "55",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "订单编号",
                              prop: "orderNo",
                              width: "190",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "下单用户",
                              prop: "nickName",
                              width: "140",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "订单金额",
                              prop: "paymentPrice",
                              width: "100",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "下单员工",
                              prop: "profile",
                              "show-overflow-tooltip": "",
                              width: "140",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        _vm._s(scope.row.realName) +
                                          _vm._s(
                                            scope.row.profile == ""
                                              ? ""
                                              : "(" + scope.row.profile + ")"
                                          )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3150344290
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "状态",
                              prop: "statusDesc",
                              "show-overflow-tooltip": "",
                              width: "80",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "所购产品",
                              prop: "product",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "下单地址",
                              prop: "address",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "质检审核",
                              prop: "quaStateDesc",
                              width: "80",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          row.quaState == 1
                                            ? _c(
                                                "el-tag",
                                                { attrs: { type: "succes" } },
                                                [_vm._v("待质检")]
                                              )
                                            : _vm._e(),
                                          row.quaState == 2
                                            ? _c(
                                                "el-tag",
                                                { attrs: { type: "danger" } },
                                                [_vm._v("已通过")]
                                              )
                                            : _vm._e(),
                                          row.quaState == 3
                                            ? _c(
                                                "el-tag",
                                                { attrs: { type: "info" } },
                                                [_vm._v("已驳回")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2263900595
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "质检信息",
                              prop: "quaContent",
                              "show-overflow-tooltip": "",
                              width: "80",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "预存金额",
                              prop: "presAmount",
                              width: "80",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "订单日期",
                              prop: "createTime",
                              width: "200",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clear } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("relatedOrders", {
        attrs: { showtablist: _vm.aftershowOrder, userlist: _vm.uslist },
        on: { closeshow: _vm.aftersaletrclose, passUserid: _vm.handleUserId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }