var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "提示",
            visible: _vm.dialogVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "rueFrom",
                  attrs: {
                    "label-width": "100px",
                    model: _vm.addForm,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分配部门：", prop: "mallcategory" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.dataSourceLists,
                          props: {
                            checkStrictly: true,
                            label: "orgName",
                            value: "id",
                          },
                        },
                        on: { change: _vm.changemall },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ node, data }) {
                              return [
                                !node.isLeaf
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(data.orgName) +
                                          "(" +
                                          _vm._s(data.children.length) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.addForm.mallcategory,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "mallcategory", $$v)
                          },
                          expression: "addForm.mallcategory",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分配部门：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.chooseType,
                            callback: function ($$v) {
                              _vm.chooseType = $$v
                            },
                            expression: "chooseType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "3" } }, [
                            _vm._v("分配具体员工"),
                          ]),
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("分配部门"),
                          ]),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("平均分配部门每个人"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.chooseType == 3
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "指定员工：" } },
                        [
                          _c("el-transfer", {
                            attrs: {
                              data: _vm.appointData,
                              filterable: "",
                              props: {
                                key: "id",
                                label: "realName",
                              },
                              titles: ["员工列表", "指定员工"],
                            },
                            on: {
                              change: _vm.teaChange,
                              "left-check-change": _vm.leftChange,
                            },
                            model: {
                              value: _vm.appointUser,
                              callback: function ($$v) {
                                _vm.appointUser = $$v
                              },
                              expression: "appointUser",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clear } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }