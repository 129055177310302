<template>
  <div>
    <el-dialog append-to-body :before-close="claer" title="客情部门" :visible.sync="appointDialog" width="60%">
      <el-form ref="addForm" label-width="120px" :model="addForm" :rules="rules">
        <el-form-item label="客户姓名" prop="username">
          <el-input v-model="uslist.userName" autocomplete="off" disabled placeholder="请输入用户名" />
        </el-form-item>
        <el-form-item label="客户电话" prop="phone">
          <el-input v-model="uslist.phone" disabled placeholder="客户电话" />
        </el-form-item>

        <el-form-item class="postPare" label="指派部门">
          <el-select v-model="addForm.mallcategory" disabled placeholder="">
            <el-option v-for="(ym, index) in dataSourceLists" :key="index" :label="ym.orgName" :value="ym.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="客情类型" prop="afterTypeId">
          <el-select v-model="addForm.afterTypeId" placeholder="请选择 客情类型">
            <el-option v-for="item in optionslist" :key="item.id" :label="item.labelName" :value="item.id" />
          </el-select>
        </el-form-item>
        <div v-if="addForm.afterTypeId == 1">
          <el-form-item label="客户客情时间" prop="afterTime">
            <el-date-picker v-model="addForm.afterTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="客户签收金额">
            <el-input v-model="addForm.signAmount" placeholder="客户签收金额" />
          </el-form-item>
          <el-form-item label="客户反馈情况" prop="issueMark">
            <el-input v-model="addForm.issueMark" placeholder="客户反馈情况" type="textarea" />
          </el-form-item>
        </div>
        <div v-if="addForm.afterTypeId == 2">
          <el-form-item label="客户签收时间" prop="signTime">
            <el-date-picker v-model="addForm.signTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="客户反馈时间" prop="feedTime">
            <el-date-picker v-model="addForm.feedTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="产品问题描述" prop="issueMark">
            <el-input v-model="addForm.issueMark" placeholder="产品问题描述" type="textarea" />
          </el-form-item>
        </div>
        <div v-if="addForm.afterTypeId == 3">
          <el-form-item label="客户签收时间" prop="signTime">
            <el-date-picker v-model="addForm.signTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="客户反馈时间" prop="feedTime">
            <el-date-picker v-model="addForm.feedTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="产品问题描述" prop="issueMark">
            <el-input v-model="addForm.issueMark" placeholder="产品问题描述" type="textarea" />
          </el-form-item>
        </div>
        <div v-if="addForm.afterTypeId == 4">
          <el-form-item label="客户签收时间" prop="signTime">
            <el-date-picker v-model="addForm.signTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="客户反馈时间" prop="feedTime">
            <el-date-picker v-model="addForm.feedTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="产品问题描述" prop="issueMark">
            <el-input v-model="addForm.issueMark" placeholder="产品问题描述" type="textarea" />
          </el-form-item>
        </div>
        <div v-if="addForm.afterTypeId == 5">
          <el-form-item label="客户客情时间" prop="afterTime">
            <el-date-picker v-model="addForm.afterTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="客户退定金金额" prop="refundAmount">
            <el-input v-model="addForm.refundAmount" placeholder="客户签收金额" />
          </el-form-item>
          <el-form-item label="客户反馈情况" prop="issueMark">
            <el-input v-model="addForm.issueMark" placeholder="客户反馈情况" type="textarea" />
          </el-form-item>
        </div>
        <div v-if="addForm.afterTypeId == 6">
          <el-form-item label="客户客情时间" prop="afterTime">
            <el-date-picker v-model="addForm.afterTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="客户反馈情况" prop="issueMark">
            <el-input v-model="addForm.issueMark" placeholder="客户反馈情况" type="textarea" />
          </el-form-item>
        </div>
        <el-form-item label="客情备注" prop="remark">
          <el-input v-model="addForm.remark" placeholder="备注信息" type="textarea" />
        </el-form-item>

        <el-button @click="aftershowOrder = true">关联订单</el-button>
        <div v-if="tablist.length > 0" style="margin-top: 20px">
          <el-table border :data="tablist" max-height="300">
            <el-table-column align="center" label="序号" type="index" width="55" />
            <el-table-column align="center" label="订单编号" prop="orderNo" width="190" />

            <el-table-column align="center" label="下单用户" prop="nickName" width="140" />
            <el-table-column align="center" label="订单金额" prop="paymentPrice" width="100" />
            <el-table-column align="center" label="下单员工" prop="profile" show-overflow-tooltip width="140">
              <template #default="scope">{{ scope.row.realName }}{{ scope.row.profile == '' ? '' : '(' + scope.row.profile + ')' }}</template>
            </el-table-column>
            <el-table-column align="center" label="状态" prop="statusDesc" show-overflow-tooltip width="80" />

            <el-table-column align="center" label="所购产品" prop="product" show-overflow-tooltip />
            <el-table-column align="center" label="下单地址" prop="address" show-overflow-tooltip />
            <el-table-column align="center" label="质检审核" prop="quaStateDesc" width="80">
              <template #default="{ row }">
                <div>
                  <el-tag v-if="row.quaState == 1" type="succes">待质检</el-tag>
                  <el-tag v-if="row.quaState == 2" type="danger">已通过</el-tag>
                  <el-tag v-if="row.quaState == 3" type="info">已驳回</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="质检信息" prop="quaContent" show-overflow-tooltip width="80" />
            <el-table-column align="center" label="预存金额" prop="presAmount" width="80" />
            <el-table-column align="center" label="订单日期" prop="createTime" width="200" />
          </el-table>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
    <relatedOrders :showtablist="aftershowOrder" :userlist="uslist" @closeshow="aftersaletrclose" @passUserid="handleUserId" />
  </div>
</template>

<script>
import { afterSale } from '@/api/mall/health/seas/seasManger'
import { queryDateisOrgorgTree } from '@/api/mall/role/orgManage'
import dayjs from 'dayjs'
import { queryPage } from '@/api/mall/setting/stplabel'
import relatedOrders from '../Related-orders/index.vue'
export default {
  components: {
    relatedOrders,
  },
  props: {
    showtab: {
      type: Boolean,
      default: () => {},
    },
    hanuser: {
      type: Object,
      default: () => {},
    },
    usmglist: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      aftershowOrder: false, //关联订单
      appointDialog: false,
      dataSourceLists: [],
      addForm: {},
      uslist: null,
      usmsginfo: this.usmglist,
      relevanceId: '',
      tablist: [],
      props: {
        checkStrictly: true,
        multiple: true,
        label: 'orgName',
        value: 'id',
      },

      optionslist: [
        {
          labelName: '退货退款',
          id: '1',
        },
        {
          labelName: '维修',
          id: '2',
        },
        {
          labelName: '换新',
          id: '3',
        },
        {
          labelName: '补发',
          id: '4',
        },
        {
          labelName: '退定金',
          id: '5',
        },
        {
          labelName: '退款',
          id: '6',
        },
      ],
      rules: {
        afterTypeId: [{ required: true, message: '请选择 客情类型', trigger: 'change' }],
        mallcategory: [{ required: true, message: '请选择 所属部门', trigger: 'change' }],
        remark: [{ required: true, message: '请输入 客情备注', trigger: 'blur' }],
        signTime: [{ required: true, message: '请选择 客户签收时间', trigger: 'change' }],
        signAmount: [
          { required: true, message: '请输入 正确的金额', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!/^\d+(\.\d+)?$/.test(value)) {
                callback(new Error('金额必须是数字'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        refundAmount: [
          { required: true, message: '请输入 正确的金额', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!/^\d+(\.\d+)?$/.test(value)) {
                callback(new Error('金额必须是数字'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        afterTime: [{ required: true, message: '请选择 客户客情时间', trigger: 'change' }],
        feedTime: [{ required: true, message: '请选择 客户反馈时间', trigger: 'change' }],
        issueMark: [{ required: true, message: '请输入 产品问题描述', trigger: 'blur' }],
      },
    }
  },
  watch: {
    showtab(newValue, oldValue) {
      if (newValue != oldValue) {
        this.appointDialog = newValue
      }
    },
    hanuser(newValue, oldValue) {
      if (newValue != oldValue) {
        this.uslist = newValue
      }
    },
    usmglist(newValue, oldValue) {
      if (newValue != oldValue) {
        this.usmsginfo = newValue
      }
    },
  },
  created() {
    this.fetchData()
    // this.queryPage()
    this.handleUserId()
  },

  methods: {
    queryPage() {
      queryPage({ labelType: 9 }).then((res) => {
        console.log(res.data)
        this.optionslist = res.data.records
      })
    },
    handleUserId(e) {
      this.relevanceId = e[0].id
      this.tablist = e
    },
    getcasc(e) {
      console.log(e)
      console.log(this.dataSourceLists)
    },
    aftersaletrclose() {
      this.aftershowOrder = false
    },
    async fetchData() {
      this.listLoading = true
      const parms = {
        current: this.current,
        size: this.size,
      }
      await queryDateisOrgorgTree(parms)
        .then((res) => {
          if (res.data) {
            this.menuListTable = res.data.records
            this.dataSourceLists = res.data.records
            this.addForm.mallcategory = this.dataSourceLists[0].id
            this.dataSourceLists.map((obj) => {
              obj.label = obj.orgName
              obj.value = obj.id
              const fieldArr = obj.children
              const newFieldArr = fieldArr.map((element) => {
                element.label = element.orgName
                element.value = element.id
                element.children.forEach((item) => {
                  item.label = item.orgName
                  item.value = item.id
                  if (item.children != null) {
                    item.children.forEach((el) => {
                      el.label = el.orgName
                      el.value = el.id
                    })
                  }
                })
                return element
              })
              obj.fieldArr = newFieldArr
              return obj
            })
          }
        })
        .catch(() => {})
      this.listLoading = false
    },
    clear() {
      this.$emit('closeswitch', false)
      this.fetchData()
      this.addForm = {}
      this.tablist = []
    },
    teaChange(e) {
      if (!e.length) {
        this.appointData.forEach((item) => {
          item.disabled = false
        })
      }
    },
    claer() {
      this.$emit('closeswitch', false)
      this.addForm = {}
      this.tablist = []
    },
    leftChange(e) {
      this.appointData.forEach((item) => {
        if (e[0] == item.id) item.disabled = false
        else item.disabled = true
      })
    },

    sure() {
      if (!this.relevanceId) {
        this.$message.error('请关联订单！！！')
        return
      }
      const date = new Date()
      const timer =
        this.addForm.signTime +
        ` ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        }`
      const timers =
        this.addForm.afterTime +
        ` ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        }`
      const timerday =
        this.addForm.feedTime +
        ` ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        }`
      if (this.addForm.signTime) {
        this.addForm.signTime = dayjs(timer).format('YYYY-MM-DD HH:mm:ss')
      }
      if (this.addForm.afterTime) {
        this.addForm.afterTime = dayjs(timers).format('YYYY-MM-DD HH:mm:ss')
      }
      if (this.addForm.feedTime) {
        this.addForm.feedTime = dayjs(timerday).format('YYYY-MM-DD HH:mm:ss')
      }
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          afterSale({
            ...this.addForm,
            orgIds: [this.dataSourceLists[0].id],
            id: this.uslist.id,
            remark: this.addForm.remark,
            afterTypeId: this.addForm.afterTypeId,
            refundAmount: this.addForm.refundAmount,
            orderId: this.relevanceId,
            mallcategory: this.dataSourceLists[0].id,
          })
            .then((res) => {
              if (res.status == 0 || res.status == 200) {
                this.addForm = {}
                this.$emit('closeswitch', false)
                this.$message.success('提交成功')
                this.tablist = []
              } else {
                this.$message.error(res.msg)
                this.appointDialog = false
              }
            })
            .catch((err) => {
              this.$emit('closeswitch', false)
              console.log(err)
              this.appointDialog = false
              this.tablist = []
              this.addForm = {}
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-select,
  .el-date-editor {
    width: 100%;
  }
}
</style>