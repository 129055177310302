var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "div",
        { staticClass: "GeneralOrder" },
        [
          _c("odertable", { on: { tannumerb: _vm.gettab } }),
          _c(
            "div",
            { staticClass: "filterOrderFrom" },
            [
              _c(
                "vab-query-form",
                [
                  _c(
                    "vab-query-form-top-panel",
                    [
                      _c(
                        "el-form",
                        {
                          ref: "refGeneral",
                          attrs: { inline: true, model: _vm.queryForm },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单编号" } },
                            [
                              _c("el-input", {
                                staticClass: "filterItems",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入订单编号",
                                },
                                model: {
                                  value: _vm.queryForm.orderNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm,
                                      "orderNo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "queryForm.orderNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "店铺名称" } },
                            [
                              _c("el-input", {
                                staticClass: "filterItems",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入店铺名称",
                                },
                                model: {
                                  value: _vm.queryForm.shopName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm,
                                      "shopName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "queryForm.shopName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filterItems",
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.queryForm.orderState,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryForm, "orderState", $$v)
                                    },
                                    expression: "queryForm.orderState",
                                  },
                                },
                                _vm._l(
                                  _vm.orderListStateList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dictValue,
                                        value: item.dictKey,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filterItems",
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.queryForm.orderType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryForm, "orderType", $$v)
                                    },
                                    expression: "queryForm.orderType",
                                  },
                                },
                                _vm._l(
                                  _vm.comOrderTypeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dictValue,
                                        value: item.dictKey,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "买家手机号" } },
                            [
                              _c("el-input", {
                                staticClass: "filterItems",
                                attrs: {
                                  clearable: "",
                                  maxlength: "11",
                                  oninput: "value=value.replace(/[^\\d]/g,'')",
                                  placeholder: "请输入买家手机号",
                                },
                                model: {
                                  value: _vm.queryForm.phone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm,
                                      "phone",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "queryForm.phone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "下单时间" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  align: "center",
                                  "default-time": ["00:00:00", "23:59:59"],
                                  type: "datetimerange",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                },
                                on: { change: _vm.dateChange },
                                model: {
                                  value: _vm.queryForm.orderTimes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "orderTimes", $$v)
                                  },
                                  expression: "queryForm.orderTimes",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-right": "0px !important" },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-search",
                                    "native-type": "submit",
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleQuery()
                                    },
                                  },
                                },
                                [_vm._v("搜索")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-refresh-left",
                                    "native-type": "submit",
                                    type: "primary",
                                  },
                                  on: { click: _vm.handleref },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "m-b" },
            [
              _vm._l(_vm.searchButton, function (item) {
                return _c(
                  "el-button",
                  {
                    key: item.id,
                    attrs: { type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handleClickBtn(item)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.buttonName) + " ")]
                )
              }),
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: { click: _vm.orderDataExport },
                },
                [_vm._v("导出代客发货订单")]
              ),
            ],
            2
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                border: "",
                data: _vm.orderList,
                "default-expand-all": "",
                "max-height": "600",
                "row-key": "id",
              },
              on: { "selection-change": _vm.setSelectRows },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "show-overflow-tooltip": "",
                  type: "selection",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "订单编号",
                  prop: "orderNo",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  formatter: _vm.formatterShop,
                  label: "店铺名称",
                  prop: "shopId",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "买家",
                  prop: "phone",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  formatter: _vm.formatterOrderType,
                  label: "订单类型",
                  prop: "orderType",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "订单金额",
                  prop: "salesPrice",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("numFormat")(row.salesPrice))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  formatter: _vm.formatterOrder,
                  label: "订单状态",
                  prop: "status",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  formatter: _vm.formatterOrderDeliveryWay,
                  label: "配送方式",
                  prop: "deliveryWay",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "下单时间",
                  prop: "createTime",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm.operationShow
                ? _c("el-table-column", {
                    attrs: { align: "center", label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._l(_vm.listButton, function (item) {
                                return [
                                  _vm.showListButton(row.status, item)
                                    ? _c(
                                        "el-button",
                                        {
                                          key: item.id,
                                          attrs: {
                                            plain:
                                              item.cssType &&
                                              item.cssType == "plain"
                                                ? true
                                                : false,
                                            size: "mini",
                                            type: item.buttonCss,
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.handleClickBtn(
                                                item,
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.buttonName) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1899924203
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.forms.current,
              layout: _vm.layout,
              "page-size": _vm.forms.size,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c("delivery", {
        ref: "delivery",
        attrs: { deliverys: _vm.orderDetails },
        on: { getorder: _vm.getorder },
      }),
      _c("eidt-address", {
        ref: "eidtAddress",
        on: { getorder: _vm.getorder },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }