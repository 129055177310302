import request from '@/utils/request'

// 坐席管理列表
export function getPage(data) {
  return request({
    url: '/upms/admin/comProfileInfo/queryAllComProfileInfo',
    method: 'post',
    data,
  })
}

// 坐席管理--回收
export function getRetrievingProfile(data) {
  return request({
    url: '/upms/admin/comProfileInfo/retrievingProfile',
    method: 'post',
    data,
  })
}

// 坐席管理--分配
export function getDistributionProfile(data) {
  return request({
    url: '/upms/admin/comProfileInfo/distributionProfile',
    method: 'post',
    data,
  })
}

// 坐席管理--员工接口

export function getStaffList(data) {
  return request({
    url: '/upms/admin/comSysUser/queryAllComUserList',
    method: 'post',
    data,
  })
}

// 坐席管理--新增保存
export function saveComProfileInfo(data) {
  return request({
    url: '/upms/admin/comProfileInfo/saveComProfileInfo',
    method: 'post',
    data,
  })
}
