var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cy-container" },
    [
      _c(
        "el-form",
        {
          ref: "queryForm",
          attrs: { inline: true, model: _vm.queryForm },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            _vm._l(_vm.fixedButton, function (item) {
              return _c(
                "el-button",
                {
                  key: item.id,
                  attrs: {
                    plain:
                      item.cssType && item.cssType == "plain" ? true : false,
                    type: item.buttonCss,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleClickBtn(item)
                    },
                  },
                },
                [
                  !item.buttonIcon
                    ? _c("span")
                    : _c("vab-icon", { attrs: { icon: item.buttonIcon } }),
                  _vm._v(" " + _vm._s(item.buttonName) + " "),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.tableData, "default-expand-all": "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "版本名称",
              prop: "versionName",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "版本号",
              prop: "versionNo",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "强制更新",
              prop: "isUpdate",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.isUpdate == 1
                      ? _c("span", [_vm._v("强制更新")])
                      : _vm._e(),
                    row.isUpdate == 2
                      ? _c("span", [_vm._v("非强制更新")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "类型",
              prop: "appType",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.appType == 1 ? _c("span", [_vm._v("全部")]) : _vm._e(),
                    row.appType == 2 ? _c("span", [_vm._v("苹果")]) : _vm._e(),
                    row.appType == 3 ? _c("span", [_vm._v("安卓")]) : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "更新时间",
              prop: "updateOnline",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(row.updateOnline))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
              prop: "isDelete",
              "show-overflow-tooltip": "",
              width: "100px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: row.isDelete === 0 ? "success" : "danger",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.isDelete === 0 ? "正常" : "删除") +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.operationShow
            ? _c("el-table-column", {
                attrs: { align: "center", label: "操作" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return _vm._l(_vm.listButton, function (item) {
                          return _c(
                            "el-button",
                            {
                              key: item.id,
                              attrs: {
                                plain:
                                  item.cssType && item.cssType == "plain"
                                    ? true
                                    : false,
                                type: item.buttonCss,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.handleClickBtn(item, row)
                                },
                              },
                            },
                            [
                              !item.buttonIcon
                                ? _c("span")
                                : _c("vab-icon", {
                                    attrs: { icon: item.buttonIcon },
                                  }),
                              _vm._v(" " + _vm._s(item.buttonName) + " "),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  1943753687
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.current,
          layout: _vm.layout,
          "page-size": _vm.size,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "addDialog",
          attrs: {
            "close-on-click-modal": false,
            title: _vm.dialogTitle,
            visible: _vm.addVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                "label-width": "100px",
                model: _vm.addForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "版本名称", prop: "versionName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      autocomplete: "off",
                      clearable: "",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.addForm.versionName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "versionName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.versionName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "版本号", prop: "versionNo" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      autocomplete: "off",
                      clearable: "",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.addForm.versionNo,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "versionNo",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.versionNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "强制更新", prop: "isUpdate" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.addForm.isUpdate,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "isUpdate", $$v)
                        },
                        expression: "addForm.isUpdate",
                      },
                    },
                    _vm._l(_vm.isUpdateList, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.dictKey, attrs: { label: item.dictKey } },
                        [_vm._v(" " + _vm._s(item.dictValue) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "更新类型", prop: "appType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.addForm.appType,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "appType", $$v)
                        },
                        expression: "addForm.appType",
                      },
                    },
                    _vm._l(_vm.appTypeList, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.dictKey, attrs: { label: item.dictKey } },
                        [_vm._v(" " + _vm._s(item.dictValue) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "苹果下载地址", prop: "iosUrl" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "40%" },
                    attrs: {
                      autocomplete: "off",
                      clearable: "",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.addForm.iosUrl,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "iosUrl",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.iosUrl",
                    },
                  }),
                  _c(
                    "span",
                    { staticStyle: { "font-size": "12px", color: "#7f7f7f" } },
                    [
                      _vm._v(
                        "AppStore中App项目下载目录，可从下载App页面点击分享，拷贝链接"
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "安卓下载地址", prop: "androidUrl" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "40%" },
                    attrs: {
                      autocomplete: "off",
                      clearable: "",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.addForm.androidUrl,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "androidUrl",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.androidUrl",
                    },
                  }),
                  _c(
                    "span",
                    { staticStyle: { "font-size": "12px", color: "#7f7f7f" } },
                    [_vm._v("安卓链接为应用的下载地址")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "更新内容", prop: "versionContent" } },
                [
                  _c("quill-editor", {
                    ref: "myQuillEditor",
                    staticClass: "quilleditor",
                    staticStyle: { height: "150px" },
                    attrs: { options: _vm.editorOption },
                    model: {
                      value: _vm.addForm.versionContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "versionContent", $$v)
                      },
                      expression: "addForm.versionContent",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "addDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "查看",
            visible: _vm.lookVisible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.lookVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: { "label-width": "150px", model: _vm.lookForm },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "版本名称：" } },
                [
                  _c("el-row", { staticClass: "color117" }, [
                    _vm._v(_vm._s(_vm.lookForm.versionName)),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "版本号：" } },
                [
                  _c("el-row", { staticClass: "color117" }, [
                    _vm._v(_vm._s(_vm.lookForm.versionNo)),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "更新时间：" } },
                [
                  _c("el-row", { staticClass: "color117" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatDate")(_vm.lookForm.updateOnline)
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "强制更新：" } },
                [
                  _c("el-row", { staticClass: "color117" }, [
                    _vm.lookForm.isUpdate == 1
                      ? _c("span", [_vm._v("强制更新")])
                      : _vm._e(),
                    _vm.lookForm.isUpdate == 2
                      ? _c("span", [_vm._v("非强制更新")])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "更新类型：" } },
                [
                  _c("el-row", { staticClass: "color117" }, [
                    _vm.lookForm.appType == 1
                      ? _c("span", [_vm._v("全部")])
                      : _vm._e(),
                    _vm.lookForm.appType == 2
                      ? _c("span", [_vm._v("苹果")])
                      : _vm._e(),
                    _vm.lookForm.appType == 3
                      ? _c("span", [_vm._v("安卓")])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "苹果下载地址：" } },
                [
                  _c("el-row", { staticClass: "color117" }, [
                    _vm._v(_vm._s(_vm.lookForm.iosUrl)),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "安卓下载地址：" } },
                [
                  _c("el-row", { staticClass: "color117" }, [
                    _vm._v(_vm._s(_vm.lookForm.androidUrl)),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "constans", attrs: { label: "更新内容：" } },
                [
                  _c("el-row", { staticClass: "color117" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.lookForm.versionContent),
                      },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.lookVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }