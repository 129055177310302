<template>
  <vab-query-form-top-panel>
    <el-form ref="form" :inline="true" :model="queryForm" @submit.native.prevent>
      <el-form-item label="客户姓名">
        <el-input v-model="queryForm.userName" placeholder="请输入客户姓名" />
      </el-form-item>
      <el-form-item v-show="!fold" label="部门">
        <el-cascader v-model.trim="queryForm.idOrg" change-on-select :options="dataSourceLists"
          :props="{ checkStrictly: true, label: 'orgName', value: 'id' }">
          <template slot-scope="{ node, data }">
            <span v-if="!node.isLeaf">{{ data.orgName }}({{ data.children.length }})</span>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item v-show="!fold" label="积分使用时间" label-width="120px">
        <el-date-picker v-model.trim="value1" :default-time="['00:00:00', '23:59:59']" end-placeholder="结束日期"
          format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss" @change="signChangedates" />
      </el-form-item>

      <el-form-item>
        <el-button icon="el-icon-search" native-type="submit" type="primary" @click="searchquery">查询</el-button>
        <el-button icon="el-icon-refresh-right" native-type="submit" type="warning" @click="clearcont">重置</el-button>
        <el-button type="text" @click="handleFold">
          <span v-if="fold">展开</span>
          <span v-else>合并</span>
          <vab-icon class="vab-dropdown" :class="{ 'vab-dropdown-active': fold }" icon="arrow-up-s-line" />
        </el-button>
      </el-form-item>
    </el-form>
  </vab-query-form-top-panel>
</template>

<script>
import { orgTreeId } from '@/api/mall/role/orgManage'
export default {
  data() {
    return {
      queryForm: {
        idOrg: [],
        createStartTime: '',
        createEndTime: ''
      },
      fold: false,
      value1: [],
      dataSourceLists: [], //部门
    }
  },
  created() {
    this.orgTreeData()
  },
  methods: {

    // 获取当前账号的部门
    async orgTreeData() {
      let data = {
        orgId: sessionStorage.getItem('userInfoOrgId') || ''
      }
      orgTreeId(data).then(res => {
        if (res && res.data) {
          this.menuListTable = res.data.records
          this.dataSourceLists = res.data.records
          this.dataSourceLists.map((obj) => {
            obj.label = obj.orgName
            obj.value = obj.id
            const fieldArr = obj.children
            const newFieldArr = fieldArr.map((element) => {
              element.label = element.orgName
              element.value = element.id
              element.children.forEach((item) => {
                item.label = item.orgName
                item.value = item.id
                if (item.children != null) {
                  item.children.forEach((el) => {
                    el.label = el.orgName
                    el.value = el.id
                  })
                }
              })
              return element
            })
            obj.fieldArr = newFieldArr
            return obj
          })
        }
      })
    },

    // 重置
    clearcont() {
      Object.assign(this.$data, this.$options.data())
      this.searchquery()
      this.orgTreeData()
    },

    // 查询
    searchquery() {
      this.$emit('receive', this.queryForm)
    },

    signChangedates() {
      this.queryForm.createStartTime = this.value1[0]
      this.queryForm.createEndTime = this.value1[1]
    },

    handleFold() {
      this.fold = !this.fold
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {

  .el-input,
  .el-date-editor {
    width: 210px;
  }

  .el-range-input,
  .el-range-separator {
    font-size: 12px;
  }

  .el-range-separator {
    width: 20px;
  }
}
</style>
