import request from '@/utils/request'
import { encryption } from '@/utils/util'
import { securityKey } from '@/config/env'
// import qs from 'qs'
export async function login(data) {
  console.log('-----------------:::' + data)
  console.log(data)
  const user = encryption({
    data: data,
    key: securityKey,
    param: ['password'],
  })

  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      Authorization: 'Basic YWRtaW46YWRtaW4=',
    },
    method: 'post',
    params: user,
  })
}

export function getUserInfo() {
  return request({
    url: '/upms/user/info',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/auth/token/logout',
    method: 'delete',
  })
}

export function register(data) {
  return request({
    url: '/register',
    method: 'post',
    data,
  })
}

// 验证图形验证码
export function compareCaptcha(data) {
  // data = qs.stringify(data)
  return request({
    url: '/mall/common/compareCaptcha',
    method: 'post',
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    //   Authorization: 'Basic YnJvd3Nlcjo=',
    // },
    data,
  })
}
export function sendPhoneCode(data) {
  return request({
    url: '/captcha/sendCode',
    method: 'post',
    data,
  })
}

export function adminRegister(data) {
  return request({
    url: '/mall/userinfo/adminRegister',
    method: 'post',
    data,
  })
}