const state = () => ({
  admin: false,
  role: [],
  permission: [],
  roleList: [],
})
const getters = {
  admin: (state) => state.admin,
  role: (state) => state.role,
  permission: (state) => state.permission,
  roleList: (state) => state.roleList,
}
const mutations = {
  setFull(state, admin) {
    state.admin = admin
  },
  setRole(state, role) {
    state.role = role
  },
  setPermission(state, permission) {
    state.permission = permission
  },
  setRoleList(state, roleList) {
    const rols = roleList.map((item) => {
      return item.id
    })
    state.roleList = rols.toString()
  },
}
const actions = {
  setFull({ commit }, admin) {
    commit('setFull', admin)
  },
  setRole({ commit }, role) {
    commit('setRole', role)
  },
  setPermission({ commit }, permission) {
    commit('setPermission', permission)
  },
  setRoleList({ commit }, roleList) {
    commit('setRoleList', roleList)
  },
}
export default { state, getters, mutations, actions }
