var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "min-height": "79vh", padding: "10px" } },
    [
      _c("div", { staticClass: "ym_header" }, [_vm._v("分销设置")]),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "ym_form" },
        [
          _c(
            "el-form",
            {
              ref: "formName",
              attrs: {
                "label-width": "150px",
                model: _vm.ruleForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分销启用：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.enable,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "enable", $$v)
                        },
                        expression: "ruleForm.enable",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("开启"),
                      ]),
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分销关系绑定：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.bindType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "bindType", $$v)
                        },
                        expression: "ruleForm.bindType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("所有用户"),
                      ]),
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("新用户"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分销模式：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.distributionModel,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "distributionModel", $$v)
                        },
                        expression: "ruleForm.distributionModel",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("指定分销"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("人人分销"),
                      ]),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("满额分销"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "一级返佣比例：", prop: "firstRebate" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1 },
                    model: {
                      value: _vm.ruleForm.firstRebate,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "firstRebate", $$v)
                      },
                      expression: "ruleForm.firstRebate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: " 二级返佣比例：", prop: "secondRebate" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1 },
                    model: {
                      value: _vm.ruleForm.secondRebate,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "secondRebate", $$v)
                      },
                      expression: "ruleForm.secondRebate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "冻结时间：", prop: "frozenTime" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1 },
                    model: {
                      value: _vm.ruleForm.frozenTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "frozenTime", $$v)
                      },
                      expression: "ruleForm.frozenTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "提现最低金额：", prop: "withdrawMin" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1 },
                    model: {
                      value: _vm.ruleForm.withdrawMin,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "withdrawMin", $$v)
                      },
                      expression: "ruleForm.withdrawMin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "提现银行：", prop: "withdrawBank" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.withdrawBank,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "withdrawBank", $$v)
                      },
                      expression: "ruleForm.withdrawBank",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分销图片", prop: "picUrls" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      class: {
                        hide: _vm.hideUpload,
                      },
                      attrs: {
                        action: _vm.action,
                        "before-upload": _vm.bfUpload,
                        "file-list": _vm.fileList,
                        headers: _vm.headers,
                        "list-type": "picture-card",
                        "on-change": _vm.handleChange,
                        "on-preview": _vm.handlePictureCardPreview,
                        "on-remove": _vm.handleRemove,
                        "on-success": _vm.handleSuccess,
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        "append-to-body": "",
                        visible: _vm.dialogVisible,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogVisible = $event
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: { alt: "", src: _vm.picUrls, width: "100%" },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ym_checkbtn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-check", type: "primary" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }