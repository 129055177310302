var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "计划详情", name: "Projectdetails" } },
            [_c("Projectdetails", { attrs: { value: _vm.Projectdetails } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "执行人员", name: "executive" } },
            [_c("Executive", { attrs: { value: _vm.Projectdetails } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }