<template>
  <div class="cy-container" style="height: 79vh;">
    <el-form
      ref="queryForm"
      :inline="true"
      :model="queryForm"
    >
      <el-form-item label="店铺：" prop="merName">
        <el-select v-model="value" placeholder="请选择 店铺">
          <el-option
            v-for="item in queryForm.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item label="电子券：" prop="merName">
        <el-select v-model="value" placeholder="请选择 电子券">
          <el-option
            v-for="item in queryForm.type"
            :key="item.value"
            :label="item.label"
            :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item label="电子券码：" prop="merName">
        <el-input v-model="queryForm.number" placeholder="请输入 电子券码"/>
      </el-form-item>
      <el-form-item label="状态" prop="merName">
        <el-select v-model="value" placeholder="请选择 状态 ">
          <el-option
            v-for="item in queryForm.type"
            :key="item.value"
            :label="item.label"
            :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          icon="el-icon-search"
          native-type="submit"
          type="warning"
        >
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          native-type="submit"
          type="primary"
        >
          重置
        </el-button>
      </el-form-item>
      <div class="m-b" style="margin-bottom: 5px;">
        <el-form-item label="">
            <el-button
              icon="el-icon-plus"
              native-type="submit"
              type="primary"
            >
              添加
            </el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
    border
    :data="tableData"
    max-height="500"
    style="width: 100%">
    <el-table-column align="center" label="序号" type="selection" width="55"/>
    <el-table-column align="center" label="店铺" prop="shop" />
    <el-table-column align="center" label="领券时间" prop="creationtime" />
    <el-table-column align="center" label="用户信息" prop="name"/>
    <el-table-column align="center" label="电子券" prop="sort"/>
    <el-table-column align="center" label="电子券码" prop="inventory"/>
    <el-table-column align="center" label="有效期" prop="orderamount"/>
    <el-table-column align="center" label="状态" prop="type"/>
    <el-table-column align="center" label="使用情况" prop="maturitytype"/>
  </el-table>
  <el-pagination
      background
      :current-page="queryForms.current"
      :layout="layout"
      :page-size="queryForms.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
export default {
  data(){
    return{
      queryForm: {
          colStatus: '',
          merName: '',
      },
      tableData:[
        {
          id:'1',
          shop:'ceshi',
          creationtime:'202-7-28',
          name:'cv',
          sort:'1',
          inventory:'100',
          orderamount:'3300',
          type:'代金券',
          maturitytype:'领券后生效',
          enableornot:true,
          applicablegoods:'指定商品可用'
        }
      ],
      queryForms:{size:1,current:10},
      layout:'total, sizes, prev, pager, next, jumper',
      total:20
    }
  },
  methods:{
    handleCurrentChange(){

    },
    handleSizeChange(){
      
    },
    editor(e){
      console.log(e)
    }
  }
}
</script>

<style lang="scss">
</style>