var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "custom-table",
      staticClass: "custom-table-container",
      class: { "vab-fullscreen": _vm.isFullscreen },
    },
    [
      _c("searchform", { on: { receive: _vm.receiverow } }),
      _c("div", { staticClass: "flexk" }, [
        _c("div", { staticStyle: { float: "left", "margin-bottom": "15px" } }),
        _c(
          "div",
          { staticClass: "ref", staticStyle: { float: "right" } },
          [
            _c(
              "el-button",
              {
                staticStyle: { margin: "0 10px 10px 0 !important" },
                on: { click: _vm.clickFullScreen },
              },
              [
                _c("vab-icon", {
                  attrs: {
                    icon: _vm.isFullscreen
                      ? "fullscreen-exit-fill"
                      : "fullscreen-fill",
                  },
                }),
                _vm._v(" 表格全屏 "),
              ],
              1
            ),
            _c(
              "el-popover",
              {
                ref: "popover",
                attrs: {
                  "popper-class": "custom-table-checkbox",
                  trigger: "hover",
                },
                scopedSlots: _vm._u([
                  {
                    key: "reference",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 10px 10px 0 !important" },
                          },
                          [
                            _c("vab-icon", { attrs: { icon: "line-height" } }),
                            _vm._v(" 表格尺寸 "),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.lineHeight,
                      callback: function ($$v) {
                        _vm.lineHeight = $$v
                      },
                      expression: "lineHeight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "medium" } }, [
                      _vm._v("大"),
                    ]),
                    _c("el-radio", { attrs: { label: "small" } }, [
                      _vm._v("中"),
                    ]),
                    _c("el-radio", { attrs: { label: "mini" } }, [
                      _vm._v("小"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-popover",
              {
                attrs: {
                  "popper-class": "custom-table-checkbox",
                  trigger: "hover",
                },
                scopedSlots: _vm._u([
                  {
                    key: "reference",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 0 10px 0 !important" },
                            attrs: { icon: "el-icon-setting" },
                          },
                          [_vm._v("可拖拽列设置")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "el-checkbox-group",
                  {
                    model: {
                      value: _vm.checkList,
                      callback: function ($$v) {
                        _vm.checkList = $$v
                      },
                      expression: "checkList",
                    },
                  },
                  [
                    _c(
                      "vab-draggable",
                      _vm._b(
                        {
                          attrs: { list: _vm.columns },
                          on: { change: _vm.handleChange },
                        },
                        "vab-draggable",
                        _vm.dragOptions,
                        false
                      ),
                      _vm._l(_vm.columns, function (item, index) {
                        return _c(
                          "div",
                          { key: item + index },
                          [
                            _c("vab-icon", {
                              attrs: { icon: "drag-drop-line" },
                            }),
                            _c(
                              "el-checkbox",
                              {
                                attrs: {
                                  disabled: item.disableCheck === true,
                                  label: item.label,
                                },
                                on: { change: _vm.handleChange },
                              },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          ref: "tableSort",
          attrs: {
            border: "",
            data: _vm.data,
            "max-height": "600",
            size: _vm.lineHeight,
          },
          on: { "selection-change": _vm.selectd },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "55" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              "show-overflow-tooltip": "",
              type: "index",
              width: "50",
            },
          }),
          _vm._l(_vm.finallyColumns, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                "show-overflow-tooltip": item.tooltip,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              content: row[item.prop],
                              disabled: item.tooltip,
                              effect: "dark",
                              placement: "top-end",
                              value: item.tooltip,
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "white-space": "nowrap" } },
                              [_vm._v(_vm._s(row[item.prop]))]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "default", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showedis(row)
                          },
                        },
                      },
                      [_vm._v("回访")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "medium", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.appoint(row)
                          },
                        },
                      },
                      [_vm._v("移交")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("table-edit", {
        ref: "edit",
        attrs: { config: _vm.config, options: _vm.options, rules: _vm.rules },
        on: { fileStatus: _vm.fileupload, submit: _vm.submit },
        model: {
          value: _vm.form,
          callback: function ($$v) {
            _vm.form = $$v
          },
          expression: "form",
        },
      }),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.current,
          layout: "total, sizes, prev, pager, next, jumper",
          "page-size": _vm.queryForm.size,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("handover", {
        attrs: { hanuser: _vm.handeuserinfo, showtab: _vm.appointDialog },
        on: { closover: _vm.colsove },
      }),
      _c("distrtion", {
        attrs: { shodist: _vm.distrtionshow, userid: _vm.selecuserid },
        on: { closedistr: _vm.closdistr },
      }),
      _c("histroy", {
        attrs: { showhislist: _vm.historylist, showhist: _vm.historyshow },
        on: { closehis: _vm.colsehistory },
      }),
      _c("ediform", {
        attrs: {
          edilist: _vm.shotablist,
          edirow: _vm.edisrow,
          showedit: _vm.showedi,
        },
        on: { clearedi: _vm.cleareit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }