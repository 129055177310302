<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="客情事件" name="Aftersaleevent"/>
    </el-tabs>
    <Aftersaleevent v-if="activeName=='Aftersaleevent'"/>
  </div>
</template>

<script>
import Aftersaleevent from './components/After-sale-event/index.vue'
export default {
  components:{
    Aftersaleevent,
  },
 
  
  data(){
    return {
      activeName:'Aftersaleevent'
    }
  },

  methods:{
    handleClick(){}
  }
}
</script>

<style>

</style>