<template>
    <div class="adlogin">
      <el-dialog
        :before-close="handleClose"
        title="新增"
        :visible.sync="addshow"
        width="60%">
        <span>
          <el-form :inline="true" :model="formlist" >
            <el-form-item label="请输入用户编码:">
              <el-input v-model="formlist.userCode" placeholder="请输入用户编码"/>
            </el-form-item>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearei">取 消</el-button>
          <el-button type="primary" @click="saveadd">确 定</el-button>
        </span>
      </el-dialog>
    </div>
</template>

<script>
import {getUserList,userinfoselect,distributionuser} from "@/api/mall/distUsers/list"
  export default {
    props:{
      rowhide:{
        type:Boolean,
        default:()=>{}
      }
    },
    data() {
      return {
        formlist:{},
        addshow:this.rowhide
      };
    },
    watch:{
      rowhide(newValue,oldValue){
        if(newValue!=oldValue){
          this.addshow=newValue
        }
      }
    },
    methods: {
      handleClose() {
        this.formlist={}
        this.addshow=false
        this.$emit("closeadd",false)
      },
      clearei(){
        this.addshow=false
        this.formlist={}
        this.$emit("closeadd",false)
      },
      saveadd(){
        userinfoselect({userCode:this.formlist.userCode}).then(res=>{
          if(res.data.records.length>0){
            getUserList({userId:res.data.records[0].id}).then(result=>{
              if(result.data.records.length>0){
                this.$message.error("已是分销员")
                this.addshow=false
                this.formlist={}
                this.$emit("closeadd",false)
              }
              else{
                console.log('0')
                distributionuser({userId:res.data.records[0].id}).then(()=>{
                  this.$message.success("添加成功")
                  this.addshow=false
                  this.formlist={}
                  this.$emit("closeadd",false)
                })
              }
            })
          }
          else{
            this.$message.error("未查询到此用户")
            this.addshow=false
            this.formlist={}
            this.$emit("closeadd",false)
          }
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header{
  border-bottom: 1px solid #e5e5e5;
}
</style>