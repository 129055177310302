import request from '@/utils/request'

export function getLuckyList(data) {
  return request({
    url: `/mall/activity/acActInstUser/queryLuckyDrawPage`,
    method: 'post',
    data,
  })
}

export function getLuckyDetail(data) {
  return request({
    url: `/mall/activity/acActInstUser/queryLuckyDrawDetail`,
    method: 'post',
    data,
  })
}
