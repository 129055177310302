<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="30%"
    @close="close"
  >
    <el-form ref="form" label-width="120px" :model="form" :rules="rulesform">
      <el-form-item label="发货方式：" prop="deliveryType">
        <el-radio
          v-for="item in delieryTypyList"
          :key="item.id"
          v-model="form.delieryTypy"
          :label="item.id"
        >
          {{ item.name }}
        </el-radio>
      </el-form-item>
      <el-form-item
        v-if="form.delieryTypy === 10"
        label="物流公司："
        prop="deliveryCompany"
      >
        <el-select
          v-model="form.deliveryCompany"
          class="mb20 query-item query-input"
          clearable
          placeholder="请选择物流公司"
        >
          <el-option
            v-for="item in deliveryCompanyList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.delieryTypy === 10"
        label="快递单号："
        prop="deliveryCode"
      >
        <el-input v-model="form.deliveryCode" />
      </el-form-item>
      <el-form-item
        v-if="form.delieryTypy === 20"
        label="预计取货时间："
        prop="expectReceiveTime"
      >
        <el-date-picker
          v-model="form.expectReceiveTime"
          align="center"
          :picker-options="pickerOptions"
          placeholder="选择时间"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>
      <el-form-item v-if="form.delieryTypy === 20" label="备注：">
        <el-input v-model="form.expectReceiveRemark" type="textarea" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'EditLogistics',
    data() {
      return {
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7
          },
        },
        rulesform: {},
        dialogFormVisible: false,
        title: '修改物流',
        form: {
          delieryTypy: 10,
        },
        delieryTypyList: [
          {
            id: 10,
            name: '物流发货',
          },
          {
            id: 20,
            name: '无需物流',
          },
        ],
        deliveryCompanyList: [
          {
            id: 1,
            name: '中通',
          },
          {
            id: 2,
            name: '顺丰',
          },
        ],
      }
    },

    created() {},
    methods: {
      // 弹框
      showEdit() {
        this.dialogFormVisible = true
      },
      // 弹框关闭
      close() {
        this.dialogFormVisible = false
      },
      // 确定
      save() {},
    },
  }
</script>
<style lang="scss" scoped>
  .el-select {
    width: 80% !important;
    .el-input {
      width: 100%;
    }
  }
  .el-input {
    width: 80%;
  }
  .el-textarea__inner {
    min-height: 100px;
  }
</style>
