<template>
  <div class="cy-container">
    <!-- 添加类型 -->
    <el-form
      ref="queryForm"
      :inline="true"
      :model="queryForm"
      @submit.native.prevent
    >
      <el-form-item>
        <!-- <el-button icon="el-icon-plus" type="primary" @click="handelEdit">
          添加类型
        </el-button> -->
        <el-button
          v-for="item in fixedButton"
          :key="item.id"
          :plain="item.cssType && item.cssType == 'plain' ? true : false"
          :type="item.buttonCss"
          @click.native="handleClickBtn(item)"
        >
          <span v-if="!item.buttonIcon"></span>
          <vab-icon v-else :icon="item.buttonIcon" />
          {{ item.buttonName }}
        </el-button>
      </el-form-item>
    </el-form>
    <!-- table列表 -->
    <el-table
      v-loading="listLoading"
      border
      :data="tableData"
      default-expand-all
    >
      <el-table-column
        align="center"
        label="类型名称"
        prop="typeName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="排序"
        prop="sortNumber"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="状态" show-overflow-tooltip>
        <template #default="{ row }">
          <el-tag :type="row.isDelete === 0 ? 'success' : 'danger'">
            {{ row.isDelete === 0 ? '正常' : '删除' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        show-overflow-tooltip
      />

      <el-table-column v-if="operationShow" align="center" label="操作">
        <template #default="{ row }">
          <el-button
            v-for="item in listButton"
            :key="item.id"
            :plain="item.cssType && item.cssType == 'plain' ? true : false"
            size="default"
            :type="item.buttonCss"
            @click.native="handleClickBtn(item, row)"
          >
            <span v-if="!item.buttonIcon"></span>
            <!-- <vab-icon v-else :icon="item.buttonIcon" /> -->
            {{ item.buttonName }}
          </el-button>
          <!-- <el-button icon="el-icon-edit" type="text" @click="handelEdit(row)">
            编辑
          </el-button>
          <el-button
            icon="el-icon-delete"
            type="text"
            @click="handelDelete(row)"
          >
            删除
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="currentPage"
      :layout="layout"
      :page-size="pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 新增(编辑) -->
    <el-dialog
      class="addDialog"
      :title="dialogTitle"
      :visible.sync="addVisible"
      width="40%"
      @close="close"
    >
      <el-form
        ref="addForm"
        label-width="100px"
        :model="addForm"
        :rules="rules"
      >
        <el-form-item label="类型名称" prop="typeName">
          <el-input
            v-model.trim="addForm.typeName"
            autocomplete="off"
            clearable
            placeholder="请输入"
          />
        </el-form-item>

        <el-form-item label="排序" prop="sortNumber">
          <el-input-number
            v-model="addForm.sortNumber"
            :min="0"
            placeholder="请输入"
            size="small"
            style="width: 100%"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {
    queryPage,
    editType,
    addType,
    deleteType,
  } from '@/api/mall/article/manages'
  import { selectRoleMenuButtonList } from '@/api/mall/common'
  export default {
    name: 'ArtType',
    data() {
      return {
        operationShow: false,
        fixedButton: [],
        listButton: [],
        listLoading: false,
        tableData: [],
        currentPage: 1,
        queryForm: {},
        pageSize: 10,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        dialogTitle: '',
        addForm: {},
        rules: {
          typeName: [
            {
              required: true,
              message: '请输入类型名称',
              trigger: ['blur', 'change'],
            },
          ],
          sortNumber: [
            {
              required: true,
              message: '请输入排序',
              trigger: ['blur', 'change'],
            },
          ],
        },
        rowId: '',
        addVisible: false,
      }
    },
    created() {
      this.fetchData()
      this.selectRoleMenuButtonList()
    },
    methods: {
      // 按钮点击
      handleClickBtn(item, row) {
        if (item.buttonUrl) {
          this[item.buttonUrl](row)
        }
      },
      // 按钮权限
      selectRoleMenuButtonList() {
        selectRoleMenuButtonList({
          roleIdStrList: this.$store.getters['acl/roleList'],
          menuId: this.$route.meta.id,
        })
          .then((res) => {
            if (res.data) {
              this.fixedButton = res.data.filter(function (item) {
                return item.buttonType == 3
              })
              this.listButton = res.data.filter(function (item) {
                return item.buttonType == 2
              })
              if (this.listButton.length > 0) {
                this.operationShow = true
              } else {
                this.operationShow = false
              }
            }
          })
          .catch(() => {})
      },
      // 列表数据
      async fetchData() {
        this.listLoading = true
        await queryPage({
          currentPage: this.currentPage,
          pageSize: this.pageSize,
        })
          .then((res) => {
            if (res.data) {
              this.tableData = res.data.records
              this.total = res.data.total
            }
          })
          .catch(() => {})
        this.listLoading = false
      },
      // 编辑
      handelEdit(row) {
        if (row) {
          this.showEdit(row)
        } else {
          this.showEdit()
        }
      },
      // 添加编辑title
      showEdit(row) {
        this.row = row
        if (!row) {
          this.dialogTitle = '添加'
        } else {
          this.dialogTitle = '编辑'
          this.rowId = row.id
          this.addForm = Object.assign({}, row)
        }
        this.addVisible = true
      },
      // 关闭
      close() {
        this.$refs['addForm'].resetFields()
        this.addForm = this.$options.data().addForm
        this.addVisible = false
      },
      // 保存
      save() {
        this.$refs['addForm'].validate(async (valid) => {
          if (valid) {
            const parms = {
              typeName: this.addForm.typeName,
              sortNumber: this.addForm.sortNumber,
            }
            if (!this.row) {
              await addType(parms)
                .then(() => {
                  this.MS('新增成功')
                  this.fetchData()
                  this.close()
                })
                .catch(() => {})
            } else {
              await editType({ ...parms, id: this.rowId })
                .then(() => {
                  this.MS('修改成功')
                  this.fetchData()
                  this.close()
                })
                .catch(() => {})
            }
          }
        })
      },
      // 删除
      handelDelete(row) {
        this.$confirm('是否要删除此行?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteType({ id: row.id })
              .then(() => {
                this.MS('删除成功')
                this.fetchData()
                this.close()
              })
              .catch(() => {})
          })
          .catch(() => {})
      },
      // 分页
      handleSizeChange(val) {
        this.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .filterItems {
    width: 150px;
  }
</style>
