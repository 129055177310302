var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "80px",
            model: _vm.actionscomponents,
            rules: _vm.rules,
            size: "small ",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商品名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入商品名称", type: "textarea" },
                model: {
                  value: _vm.actionscomponents.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.actionscomponents, "name", $$v)
                  },
                  expression: "actionscomponents.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标签:", prop: "titleTag" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "500px" },
                  attrs: { placeholder: "请选择商品标签" },
                  model: {
                    value: _vm.actionscomponents.titleTag,
                    callback: function ($$v) {
                      _vm.$set(_vm.actionscomponents, "titleTag", $$v)
                    },
                    expression: "actionscomponents.titleTag",
                  },
                },
                _vm._l(_vm.titleTags, function (item) {
                  return _c("el-option", {
                    key: item.dictKey,
                    attrs: { label: item.dictValue, value: item.dictKey },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "店铺:", prop: "shopId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "500px" },
                  attrs: { placeholder: "请选择 店铺", size: "medium" },
                  model: {
                    value: _vm.actionscomponents.shopId,
                    callback: function ($$v) {
                      _vm.$set(_vm.actionscomponents, "shopId", $$v)
                    },
                    expression: "actionscomponents.shopId",
                  },
                },
                _vm._l(_vm.shopdat, function (ym, num) {
                  return _c("el-option", {
                    key: num,
                    attrs: { label: ym.name, value: ym.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "运费模板:", prop: "freightTemplatId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "500px" },
                  attrs: { placeholder: "请选择 运费模板" },
                  model: {
                    value: _vm.actionscomponents.freightTemplatId,
                    callback: function ($$v) {
                      _vm.$set(_vm.actionscomponents, "freightTemplatId", $$v)
                    },
                    expression: "actionscomponents.freightTemplatId",
                  },
                },
                _vm._l(_vm.freighttemplateli, function (ym, fre) {
                  return _c("el-option", {
                    key: fre,
                    attrs: { label: ym.name, value: ym.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品图片:", prop: "picUrls" } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  class: {
                    hide: _vm.hideUpload,
                  },
                  attrs: {
                    action: _vm.action,
                    "before-upload": _vm.bfUpload,
                    "file-list": _vm.fileList,
                    headers: _vm.headers,
                    "list-type": "picture-card",
                    multiple: "",
                    "on-change": _vm.handleChange,
                    "on-preview": _vm.handlePictureCardPreview,
                    "on-remove": _vm.handleRemove,
                    "on-success": _vm.handleSuccess,
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
              _c(
                "el-dialog",
                {
                  attrs: { "append-to-body": "", visible: _vm.dialogVisible },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { alt: "", src: _vm.dialogImageUrl, width: "100%" },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商城类目:", prop: "mallcategory" } },
            [
              _c("el-cascader", {
                attrs: {
                  options: _vm.mallcategoryli,
                  props: { checkStrictly: true, label: "name", value: "id" },
                },
                on: { change: _vm.getcasc },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ node, data }) {
                      return [
                        !node.isLeaf
                          ? _c("span", [
                              _vm._v(
                                _vm._s(data.name) +
                                  "(" +
                                  _vm._s(data.children.length) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.actionscomponents.mallcategory,
                  callback: function ($$v) {
                    _vm.$set(_vm.actionscomponents, "mallcategory", $$v)
                  },
                  expression: "actionscomponents.mallcategory",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "店铺类目:" } },
            [
              _c("el-cascader", {
                attrs: { options: _vm.shopcategoryli },
                on: { change: _vm.getshoptype, focus: _vm.goodscategoryshop },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ node, data }) {
                      return [
                        _c("span", [_vm._v(_vm._s(data.label))]),
                        !node.isLeaf
                          ? _c("span", [
                              _vm._v("(" + _vm._s(data.children.length) + ")"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.shoptp,
                  callback: function ($$v) {
                    _vm.shoptp = $$v
                  },
                  expression: "shoptp",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "listing",
              attrs: { label: "是否上架:", prop: "shelf" },
            },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-right": "180px" },
                  model: {
                    value: _vm.actionscomponents.shelf,
                    callback: function ($$v) {
                      _vm.$set(_vm.actionscomponents, "shelf", $$v)
                    },
                    expression: "actionscomponents.shelf",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: "0" } }, [_vm._v("否")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品编码:" } },
            [
              _c("el-input", {
                attrs: { placeholder: "商品编码" },
                model: {
                  value: _vm.actionscomponents.spuCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.actionscomponents, "spuCode", $$v)
                  },
                  expression: "actionscomponents.spuCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "虚拟数量:" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  placeholder: "虚拟数量",
                },
                model: {
                  value: _vm.actionscomponents.saleNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.actionscomponents, "saleNum", $$v)
                  },
                  expression: "actionscomponents.saleNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "卖点:" } },
            [
              _c("el-input", {
                attrs: { placeholder: "卖点" },
                model: {
                  value: _vm.actionscomponents.sellPoint,
                  callback: function ($$v) {
                    _vm.$set(_vm.actionscomponents, "sellPoint", $$v)
                  },
                  expression: "actionscomponents.sellPoint",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "保障服务:", prop: "ensureIds" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.actionscomponents.ensureIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.actionscomponents, "ensureIds", $$v)
                    },
                    expression: "actionscomponents.ensureIds",
                  },
                },
                _vm._l(_vm.cities, function (ym, index) {
                  return _c(
                    "el-checkbox",
                    { key: index, attrs: { label: ym.id, name: "type" } },
                    [_vm._v(" " + _vm._s(ym.labelName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }