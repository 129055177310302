var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container",
      style: {
        background: `${_vm.controls[_vm.index].pageBgColor}`,
        padding: `${_vm.controls[_vm.index].margin.top}px ${
          _vm.controls[_vm.index].margin.both
        }px ${_vm.controls[_vm.index].margin.bottom}px ${
          _vm.controls[_vm.index].margin.both
        }px`,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "container_cont",
          style: {
            background: `${_vm.controls[_vm.index].componentBgColor}`,
            borderTopLeftRadius: `${
              _vm.controls[_vm.index].topElementAroundRadius
            }px`,
            borderTopRightRadius: `${
              _vm.controls[_vm.index].topElementAroundRadius
            }px`,
            borderBottomLeftRadius: `${
              _vm.controls[_vm.index].bottomElementAroundRadius
            }px`,
            borderBottomRightRadius: `${
              _vm.controls[_vm.index].bottomElementAroundRadius
            }px`,
          },
        },
        _vm._l(_vm.navlist, function (ym, sun) {
          return _c("div", { key: sun, staticClass: "item" }, [
            _c(
              "div",
              { staticClass: "icon" },
              [
                _c("vab-icon", {
                  staticStyle: { "font-size": "40px" },
                  attrs: { icon: ym.icon },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "span" }, [_vm._v(_vm._s(ym.txt))]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }