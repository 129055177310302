const options = [
  { label: '排序字段', span: 12, prop: 'sort', type: 'number' },
  { label: '名字', span: 12, prop: 'name'},
  { label: '积分数', span: 12, prop: 'posts', type: 'number' },
]

const rules = {
  sort: [{ required: true, trigger: 'blur', message: '请输入排序字段' }],
  name: [{ required: true, trigger: 'blur', message: '请输入名字' }],
  posts: [{ required: true, trigger: 'blur', message: '请输入积分数' }],
}

const searchOptions = []
const config = { labelWidth: '6em' }
export { options, rules, config, searchOptions }
