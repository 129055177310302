<template>
  <div>
    <el-dialog :before-close="handleClose" title="电话通话历史记录" :visible.sync="dialogVisible" width="70%">
      <span>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="电话1" name="phone" />
          <el-tab-pane label="电话2" name="phonv" />
        </el-tabs>
        <el-table border :data="tablist" :max-height="560" style="width: 100%">
          <!-- <el-table-column align="center" label="通话 uuid" prop="uuid" /> -->
          <el-table-column align="center" label="开始时间" prop="startTime" width="170px" />
          <el-table-column align="center" label="结束时间" prop="endTime" width="170px" />
          <!-- <el-table-column align="center" label="公司 ID" prop="companyId" /> -->
          <el-table-column align="center" label="坐席工号" prop="agent" />
          <!-- <el-table-column align="center" label="体重" prop="userWeight" /> -->
          <el-table-column align="center" label="主叫号码" prop="callingPhone" show-overflow-tooltip />
          <el-table-column align="center" label="被叫号码	" prop="calledPhone" width="130px" />
          <el-table-column align="center" label="呼叫时长(秒)	" prop="callDuration" />
          <el-table-column align="center" label="振铃时长(秒)	" prop="ringingDuration" />
          <el-table-column align="center" label="应答时长(秒)	" prop="answerDuration" />
          <el-table-column align="center" label="叫答方式	" prop="hangUp">
            <template #default="{ row }">
              <el-tag :type="row.bloodStatus === 0 ? 'success' : 'danger'">
                {{ row.bloodStatus === 0 ? '主叫' : '被叫' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="呼叫状态" prop="callState" show-overflow-tooltip>
            <template #default="{ row }">
              <!-- <el-tag :type="row.bloodStatus === 0 ? 'success' : 'danger'">
                {{ row.bloodStatus === 0 ? '无' : '有' }}
              </el-tag> -->
              <el-tag v-if="row.callState == 0" type="danger">未接</el-tag>
              <el-tag v-if="row.callState == 1" type="danger">未转接</el-tag>
              <el-tag v-if="row.callState == 2" type="warning">呼损</el-tag>
              <el-tag v-if="row.callState == 3" type="danger">接听后挂断</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :current-page="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { agentrecordList } from '@/api/mall/seas/index'
  export default {
    props: {
      showhist: {
        type: Boolean,
        default: () => {},
      },
      showhislist: {
        type: Array,
        default: () => {},
      },
    },
    data() {
      return {
        dialogVisible: this.showhist,
        userList: this.showhislist,
        activeName: 'phone',
        tablist: [],
        total: 0,
        form: {
          size: 10,
          current: 1,
        },
      }
    },
    watch: {
      showhist(newValue, oldValue) {
        if (newValue != oldValue) {
          this.dialogVisible = newValue
          this.agentrecordList()
        }
      },
      showhislist(newValue, oldValue) {
        if (newValue != oldValue) {
          this.userList = newValue
        }
      },
    },
    created() {
      this.agentrecordList()
    },
    methods: {
      handleCurrentChange(e) {
        this.form.current = e
        this.agentrecordList()
      },
      handleSizeChange(e) {
        this.form.size = e
        this.agentrecordList()
      },
      handleClick() {
        this.agentrecordList()
      },
      agentrecordList() {
        // let num = this.activeName == 'phone' ? this.userList[0].phone : this.userList[0].phonev
        let num = []
        if (this.activeName == 'phone') {
          num = this.showhislist[0].phone
        } else {
          num = this.showhislist[0].phonev
        }
        agentrecordList({ ...this.form, calledPhone: num }).then((res) => {
          this.total = res.data.total
          this.form.size = res.data.size
          this.form.current = res.data.current
          this.tablist = res.data.records
        })
      },
      handleClose() {
        this.$emit('closehis', false)
      },
      clear() {
        this.$emit('closehis', false)
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__body {
      padding: 0px 20px;
    }
  }
</style>
