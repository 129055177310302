var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "编辑基础信息",
            visible: _vm.dialogVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForms",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    "label-width": "100px",
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "userName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: _vm.ruleForm.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "userName", $$v)
                          },
                          expression: "ruleForm.userName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别", prop: "userSex" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入性别" },
                        model: {
                          value: _vm.ruleForm.userSex,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "userSex", $$v)
                          },
                          expression: "ruleForm.userSex",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "出生日期", prop: "userYear" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          placeholder: "请选择出生日期",
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.ruleForm.userYear,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "userYear", $$v)
                          },
                          expression: "ruleForm.userYear",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号码", prop: "userPhone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入手机号码",
                          type: "number",
                        },
                        model: {
                          value: _vm.ruleForm.userPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "userPhone", $$v)
                          },
                          expression: "ruleForm.userPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "身高(cm)", prop: "userHeight" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入身高", type: "number" },
                        on: { change: _vm.bmiNumber },
                        model: {
                          value: _vm.ruleForm.userHeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "userHeight", $$v)
                          },
                          expression: "ruleForm.userHeight",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "体重(cm)", prop: "userWeight" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入体重", type: "number" },
                        on: { change: _vm.bmiNumber },
                        model: {
                          value: _vm.ruleForm.userWeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "userWeight", $$v)
                          },
                          expression: "ruleForm.userWeight",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "BMI", prop: "bmi" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "请输入BMI" },
                        model: {
                          value: _vm.ruleForm.bmi,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "bmi", $$v)
                          },
                          expression: "ruleForm.bmi",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "臀围(cm)", prop: "circumference" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入臀围", type: "number" },
                        model: {
                          value: _vm.ruleForm.circumference,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "circumference", $$v)
                          },
                          expression: "ruleForm.circumference",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "腰围(cm)", prop: "waistline" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入腰围", type: "number" },
                        model: {
                          value: _vm.ruleForm.waistline,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "waistline", $$v)
                          },
                          expression: "ruleForm.waistline",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", {
                    attrs: { label: "责任医生", prop: "" },
                  }),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  staticStyle: { "grid-template-columns": "repeat(1, 1fr)" },
                  attrs: {
                    "label-width": "100px",
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "会员标签", prop: "tags" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "flex-wrap": "wrap",
                          },
                        },
                        [
                          _vm._l(_vm.ruleForm.tags, function (tag) {
                            return _c(
                              "el-tag",
                              {
                                key: tag.name,
                                attrs: { closable: "", type: "success" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleCloses(tag)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(tag.tagName) + " ")]
                            )
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "add-tags",
                              on: { click: _vm.istagshow },
                            },
                            [_vm._v("选择标签")]
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("tagdialog", {
        attrs: { show: _vm.tagshow },
        on: { closelist: _vm.infotag, dialogVisible: _vm.changeTagshow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }