<template>
  <div class="container">
    <el-table border :data="tablist" max-height="300">
      <el-table-column align="center" label="客户姓名" prop="userName" width="120" />
        <!-- <el-table-column align="center" label="所属部门" prop="postName" /> -->
        <el-table-column align="center" label="预存余额" prop="remainingAmount" />
        <el-table-column align="center" label="存入总金额" prop="enterAmount" />
        <el-table-column align="center" label="支出总金额" prop="outAmount" />
    </el-table>
    <el-pagination background :current-page="1" layout="total, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
  </div>
</template>

<script>
import { getQuery } from '@/api/mall/health/DepositStatistics/depositStatistics'
export default {
  components: {
  },
  props: {
    sound: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showl: false,
      rolis: null,
      total: 0,
      tablist: [],
      showlist: false,
      showtblist: null,
      form: {
        size: 10,
        current: 1,
        userId: this.sound.id,
      },
    }
  },
  watch: {
    sound(newValue, oldValue) {
      if (newValue != oldValue) {
        this.form.userId = newValue
      }
    },
  },
  created() {
    // this.form.userId=this.userinfo.id
    this.uuUserSugarRecordLog()
  },
  methods: {
    hand(row) {
      this.rolis = row
      this.showl = true
    },
    closeh() {
      this.showl = false
    },
    closeshow() {
      this.showlist = false
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.uuUserSugarRecordLog()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.uuUserSugarRecordLog()
    },
    //客情处理接口请求
    uuUserSugarRecordLog() {
      getQuery({ ...this.form }).then((res) => {
        console.log(res,'res')
        this.total = res.data.total
        this.form.size = res.data.size
        this.form.current = res.data.current
        this.tablist = res.data.records
        console.log(this.tablist,'this.tablist')
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>