var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.tags, function (ym, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { label: ym.labelName, name: ym.id },
          })
        }),
        1
      ),
      _vm.activeName == "Bloodpressure" ? _c("Bloodpressure") : _vm._e(),
      _vm.activeName == "BloodSugar" ? _c("BloodSugar") : _vm._e(),
      _vm.activeName == "Heartrate" ? _c("Heartrate") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }