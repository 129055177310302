import request from '@/utils/request'
// 文章类型列表
export function queryPage(data) {
  return request({
    url: `/mall/bbs/bbsArticleType/queryPage`,
    method: 'post',
    data,
  })
}
// 文章类型编辑
export function editType(data) {
  return request({
    url: `/mall/bbs/bbsArticleType/edit`,
    method: 'post',
    data,
  })
}
// 文章类型添加
export function addType(data) {
  return request({
    url: `/mall/bbs/bbsArticleType/add`,
    method: 'post',
    data,
  })
}
// 文章类型删除
export function deleteType(data) {
  return request({
    url: `/mall/bbs/bbsArticleType/delete`,
    method: 'post',
    data,
  })
}
