<template>
  <el-tabs v-model="activeName" style="margin-left: 5px" @tab-click="handleClick">
    <el-tab-pane label="未回访" name="first">
      <div class="cy-container">
        <div style="display: flex; flex-wrap: wrap; margin-bottom: 15px">
          <div class="title-item">
            <span>日期</span>
            <el-date-picker
      v-model="formInline.date"
      placeholder="选择日期"
      type="date"
      value-format='yyyy-MM-dd'
      />
          </div>
          <div class="title-item">
            <span>姓名</span>
            <el-input v-model.trim="formInline.name" class="title-input" placeholder="请输入姓名" />
          </div>
          <div class="title-item">
            <el-button icon="el-icon-search" type="warning" @click="handleSearch">搜索</el-button>
            <el-button icon="el-icon-refresh-left" type="primary" @click="handleReset">重置</el-button>
          </div>
        </div>
        <el-table border :data="userList" :max-height="580" style="width: 100%">
          <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="70" />
          <el-table-column align="center" label="回访时间" prop="vistTime" width="250" />
          <el-table-column align="center" label="姓名" prop="userName" width="200" />
          <el-table-column align="center" label="备注" width="600">
            <template slot-scope="scope">
              <el-input v-model="scope.row.note" class="no-border" clearable placeholder="请输入备注" @blur="saveEdit(scope.row)" />
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作" width="300">
            <template #default="{ row }">
              <el-button size="default" type="text" @click="showedis(row)">回访</el-button>
              <el-button size="default" type="text" @click="history(row)" >状态</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :current-page="queryForm.current"
          :layout="layout"
          :page-size="queryForm.size"
          :total="totall"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
        <!-- 状态 -->
        <histroy :showhislist="historylist" :showhist="historyshow" @closehis="colsehistory" @status-updated="reloadData"/>
        <!-- 回访 -->
        <ediform :edilist="shotablist" :edirow="edisrow" :showedit="showedi" @clearedi="cleareit" />
      </div>
    </el-tab-pane>
    <el-tab-pane label="已回访" name="second">
     
      <div class="cy-container">
        <div style="display: flex; flex-wrap: wrap; margin-bottom: 15px">
          <div class="title-item">
            <span>日期</span>
            <el-date-picker
      v-model="formInline2.date"
      placeholder="选择日期"
      type="date"
      value-format='yyyy-MM-dd'
      />
   
          </div>
          <div class="title-item">
            <span>姓名</span>
            <el-input v-model.trim="formInline2.name" class="title-input" placeholder="请输入姓名" />
          </div>
          <div class="title-item">
            <el-button icon="el-icon-search" type="warning" @click="handleSearchh">搜索</el-button>
            <el-button icon="el-icon-refresh-left" type="primary" @click="handleResett">重置</el-button>
          </div>
        </div>
        <el-table border :data="userList" :max-height="580" style="width: 100%">
          <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="100" />
          <el-table-column align="center" label="回访时间" prop="vistTime" width="250" />
          <el-table-column align="center" label="姓名" prop="userName" width="250" />
          <el-table-column align="center" label="备注" width="700">
            <template slot-scope="scope">
              <el-input v-model="scope.row.note" class="no-border" clearable placeholder="请输入备注" @blur="saveEdit(scope.row)" />
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作" width="260">
            <template #default="{ row }">
              <el-button size="default" type="text" @click="showedist(row)">回访</el-button>
              <el-button size="default" type="text" @click="historyy(row)">状态</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :current-page="queryForm.current"
          :layout="layout"
          :page-size="queryForm.size"
          :total="total"
          @current-change="handleCurrentChanges"
          @size-change="handleSizeChange"
        />
        <!-- 状态 -->
        <status :showhislist="statuslist" :showhist="statusshow" @closehis="statushistory" @status-updated="statusData"/>
        <!-- 回访 -->
        <ediformUp :edilist="shotablists" :edirow="edisrows" :showedit="showed" @clearedi="cleareits" />
      </div>
      
      
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import {  getUserList,editnotes} from '@/api/mall/health/followup/followUp.js'
// import { queryRiskPoolPage } from '@/api/mall/health/seas/seasManger'
import { selectSysDict } from '@/api/mall/common'
import { checkButton } from '@/utils/mixins'
import { mapGetters } from 'vuex'
// import recycle from './components/recycle.vue'
import histroy from './components/history.vue'
import ediform from './components/index.vue'
import status from './components/status.vue'
import ediformUp from './components/index2.vue'
export default {
  name: 'UserList',
  components: {
    // recycle,
    histroy,
    // handover,
    ediform,
    status,
    // handoverr,
    ediformUp
  },
  filters: {
    getSource(val) {
      let str = ''
      switch (val) {
        case 'H5':
          str = '普通H5'
          break
        case 'MA':
          str = '小程序'
          break
        case 'WX':
          str = '公众号H5'
          break
        case 'APP':
          str = 'APP'
          break
        case 'PC':
          str = 'PC端H5'
          break
      }
      return str
    },
    getLevel(val) {
      let str = ''
      switch (val) {
        case 1:
          str = '1'
          break
        case 23:
          str = ''
          break
      }
      return str
    },
  },
  mixins: [checkButton],
  data() {
    return {
      
      activeName: 'first',
      listLoading: true,
      indicatorType: null,
      shotablist: null,
      shotablists: null,
      edisrow: null,
      edisrows: null,
      showedi: false,
      showed: false,
      userList: [],
      datePicker: [],
      datePickerr: [],
      userSource: [],
      userIdentity: [],
      personnelIdentity: [],
      isSearch: false,
      layout: 'total, sizes, prev, pager, next, jumper',
      formInline: {
        name: '',
        date:'',
      },
      formInline2: {
        name: '',
       date:'',
      },
      total: 0,
      totall: 0,
      queryForm: {
        current: 1,
        size: 10,
      },
      handeuserinfo: null,
      handeruserinfo: null,
      appointDialog: false,
      opentDialog:false,
      recycleblean: false,
      historyshow: false,
      historylist: null,
      statusshow: false,
      statuslist: null,

    }
  },
  computed: {
    ...mapGetters({
      userId: 'user/userId',
      type: 'user/type',
    }),
    getIndicatorLabel() {
      return this.userList.map((item) => {
        switch (item.indicatorType) {
          case 1:
            return '血压'
          case 2:
            return '血糖'
          case 3:
            return '心率'
          default:
            return ''
        }
      })
    },
  },
  watch:{
   
    userList(newValue,oldValue){
      if(newValue!=oldValue){
        this.userList=newValue
      }
    }
   
  },
  mounted() {
    this.loadUnprocessedData();
  },
  methods: {
    loadUnprocessedData(){
      getUserList({
          ...this.queryForm,
          ...this.formInline,
          status: 0,
        }).then((res) => {
          
          this.userList = res.data.records
          this.totall = res.data.total
        })
    },
   
    handleClick(tab) {
      if (tab.name === 'first') {
        getUserList({
          ...this.queryForm,
          ...this.formInline,
          status: 0,
        }).then((res) => {
          this.userList = res.data.records
          this.totall = res.data.total
        })
      } else {
        this.queryForm.current = 1
        getUserList({
          ...this.queryForm,
          ...this.formInline2,
          status: 1,
        }).then((res) => {
          this.userList = res.data.records
          this.total = res.data.total
        })
      }
    },
    reloadData(){
      getUserList({
          ...this.queryForm,
          ...this.formInline,
          status: 0,
        }).then((res) => {
          this.userList = res.data.records
          this.totall = res.data.total
        })
    },
    statusData(){
      console.log('44444')
      getUserList({
          ...this.queryForm,
          ...this.formInline,
          status: 1,
        }).then((res) => {
          this.userList = res.data.records
          this.totall = res.data.total
        })
    },
    async fetchData(options = {}) {
      this.listLoading = true
      const {
        data: { records: userList, total },
      } = await getUserList({ ...this.queryForm, ...options })
      this.handleAddTableButtonRole(userList, {})
      const dicData = await selectSysDict({
        dictTypeList: ['userFrom', 'userIdentity', 'personnelIdentity'],
      })
      this.userSource = dicData.data[0].dictInfoVoList
      this.userIdentity = dicData.data[1].dictInfoVoList
      this.personnelIdentity = dicData.data[2].dictInfoVoList
      this.userList = userList
      this.total = total
    },

    saveEdit(row){
      editnotes({id:row.id,note:row.note}).then(res=>{
          console.log(res,'编辑成功');
      })
    },
    // 状态
    history(row) {
      console.log(row,'状态');
      this.historyshow = true
      this.historylist = row
    
      
    },
    historyy(row) {
      console.log(row,'状态');
      this.statusshow = true
      this.statuslist = row
    
      
    },
    colsehistory() {
      this.historyshow = false
    
    },
    statushistory() {
      this.statusshow = false
    },
    recyclea() {
      this.recycleblean = true
    },
    cloerecy() {
      this.recycleblean = false
    },
    showedis(row) {
      // findCustomRecordId({userId:row.userId}).then(res => {
      //   if(res.data == null){
      //   alert('该用户尚未和公海管理里的客户数据关联')
      // }else{
       
      // }
      this.edisrow = row.recordId
      this.shotablist = row
      this.showedi = true
      // })
     
    },
    showedist(row) {
      // findCustomRecordId({userId:row.userId}).then(res => {
      //   if(res.data == null){
      //   alert('该用户尚未和公海管理里的客户数据关联')
      // }else{
      //    this.edisrows = res.data
      // this.shotablists = row
      // this.showed = true
      // }
      // })
      this.edisrows = row.recordId
      this.shotablists = row
      this.showed = true
     
    },
    cleareit() {
      this.showedi = false
    },
    cleareits() {
      this.showed = false
    },
    colsove() {
      this.appointDialog = false
    },
    colsover() {
      this.opentDialog = false
    },
    handleReset() {
      this.formInline = {
        name: '',
        date:''
      }
      this.datePicker = []
      this.handleSearch()
      // this.fetchData({},false)
    },
    handleResett() {
      this.formInline2 = {
        name: '',
        date:''
      }
      this.datePickerr = []
      this.handleSearchh()
      // this.fetchData({},false)
    },
    // disableUser(row) {
    //   this.$confirm('此操作将禁用此用户，是否继续？', '提示', {
    //     confirmButtonText: '禁用',
    //     type: 'warning',
    //   }).then(() => {
    //     const { id } = row
    //     disableUser({ userId: id }).then(() => {
    //       this.fetchData(this.formInline)
    //       this.$message.success('禁用成功！')
    //     })
    //   })
    // },
 
    dateChange(date) {
      if (date && date.length) {
        this.formInline.startTime = date[0]
        this.formInline.endTime = date[1]
      } else {
        this.formInline.startTime = ''
        this.formInline.endTime = ''
      }
    },
    dateChanges(date) {
      if (date && date.length) {
        this.formInline.startTime = date[0]
        this.formInline.endTime = date[1]
      } else {
        this.formInline.startTime = ''
        this.formInline.endTime = ''
      }
    },
    handleSearch() {
    getUserList({
          ...this.queryForm,
          ...this.formInline,
          status: 0,
        }).then((res) => {
          this.userList = res.data.records
          this.total = res.data.total
        })
    },
    handleSearchh() {
    getUserList({
          ...this.queryForm,
          ...this.formInline2,
          status: 1,
        }).then((res) => {
          this.userList = res.data.records
          this.total = res.data.total
        })
    },
    openDetail(row) {
      this.$router.push({
        path: '/user/detail',
        query: row,
      })
    },
    handleSizeChange(val) {
      console.log(val,'22222222222222222222222222222222');
      this.queryForm.size = val
      let data = this.isSearch === true ? this.formInline : {}
      this.fetchData({ ...data }, false)
    },
    handleCurrentChange(val) {
    
      this.queryForm.current = val
      getUserList({
          ...this.queryForm,
          ...this.formInline,
          status: 0,
        }).then((res) => {
          this.userList = res.data.records
          this.totall = res.data.total
        })
    },
    handleCurrentChanges(val) {
    
    this.queryForm.current = val
    getUserList({
        ...this.queryForm,
        ...this.formInline,
        status: 1,
      }).then((res) => {
        this.userList = res.data.records
        this.totall = res.data.total
      })
  },
    
  },
}
</script>

<style scoped>
.title-input {
  width: 200px;
  margin-left: 5px;
}

.title-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  margin-right: 10px;
}
.title-item:last-of-type {
  margin-left: 10px;
}
.title-item span {
  margin-right: 10px;
}

.el-input--small >>> .el-input__inner{
  /* border: none; */
  background: none;
}

</style>
