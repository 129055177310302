var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "textTool" },
    [
      _c("div", { staticClass: "toolTit" }, [
        _c("div", { staticClass: "lef" }, [_vm._v("魔方")]),
        _c("div", { staticClass: "ref" }, [
          _c("div", { staticClass: "switch" }, [
            _c(
              "div",
              {
                class: _vm.checknum == 1 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(1)
                  },
                },
              },
              [_vm._v("内容")]
            ),
            _c(
              "div",
              {
                class: _vm.checknum == 2 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(2)
                  },
                },
              },
              [_vm._v("样式")]
            ),
          ]),
        ]),
      ]),
      _vm.checknum == 1
        ? _c("div", { staticClass: "content_msg" }, [
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("选择风格")]),
            _c(
              "div",
              { staticClass: "itemBox", staticStyle: { height: "auto" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "check-tit",
                    staticStyle: { "flex-wrap": "wrap", width: "100%" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "alert",
                        staticStyle: {
                          width: "100%",
                          margin: "10px 0",
                          "text-indent": "2em",
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "gray" } }, [
                          _vm._v("模板："),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.modeleType(
                                _vm.activeComponents[_vm.indexnumer].mode
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "el-radio-group",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-wrap": "wrap",
                          "justify-content": "center",
                          margin: "auto",
                        },
                        attrs: { size: "mini" },
                        on: { change: _vm.pringtmode },
                        model: {
                          value: _vm.activeComponents[_vm.indexnumer].mode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "mode",
                              $$v
                            )
                          },
                          expression: "activeComponents[indexnumer].mode",
                        },
                      },
                      [
                        _c(
                          "el-radio-button",
                          { attrs: { label: "row1-of2" } },
                          [_vm._v("样式1")]
                        ),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "row1-of3" } },
                          [_vm._v("样式2")]
                        ),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "row1-of4" } },
                          [_vm._v("样式3")]
                        ),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "row2-lt-of2-rt" } },
                          [_vm._v("样式4")]
                        ),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "row1-lt-of2-rt" } },
                          [_vm._v("样式5")]
                        ),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "row1-tp-of2-bm" } },
                          [_vm._v("样式6")]
                        ),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "row1-lt-of1-tp-of2-bm" } },
                          [_vm._v("样式7")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "ym_title" }, [_vm._v("魔方布局")]),
            _c(
              "div",
              { staticClass: "itemBox", staticStyle: { height: "auto" } },
              [
                _c(
                  "div",
                  { staticClass: "card-mode" },
                  _vm._l(
                    _vm.activeComponents[_vm.indexnumer].list,
                    function (ym, ind) {
                      return _c(
                        "div",
                        { key: ind, staticClass: "card_modetype" },
                        [
                          _c(
                            "div",
                            { staticClass: "lef_Pic" },
                            [
                              _c("uploadpic", {
                                attrs: { picurl: [{ url: `${ym.imageUrl}` }] },
                                on: {
                                  backkuck: function ($event) {
                                    return _vm.check($event, ind)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ref" }, [
                            _c("div", { staticClass: "thand thead_top" }, [
                              _c("div", { staticClass: "row-span" }, [
                                _vm._v("链接地址"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "ref_txt",
                                  on: {
                                    click: function ($event) {
                                      return _vm.checklink(ind)
                                    },
                                  },
                                },
                                [
                                  ym.link.name != ""
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "#1780ff" } },
                                        [_vm._v(_vm._s(ym.link.title))]
                                      )
                                    : _c(
                                        "span",
                                        { staticStyle: { color: "#1780ff" } },
                                        [_vm._v("请选择链接")]
                                      ),
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "thand thead_top",
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row-span",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [_vm._v("图像高度")]
                                ),
                                _c("div", { staticClass: "ref_txt" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "check-tit",
                                      staticStyle: {
                                        display: "flex",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { "margin-left": "20px" },
                                        attrs: { min: 216 },
                                        model: {
                                          value: ym.imgHeight,
                                          callback: function ($$v) {
                                            _vm.$set(ym, "imgHeight", $$v)
                                          },
                                          expression: "ym.imgHeight",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "thand thead-bottom" }, [
                              _c("div", { staticClass: "row-span" }, [
                                _vm._v("缩放模式"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "ref_txt",
                                  staticStyle: { color: "#1780ff" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.imgmodel(ind, ym.imageMode)
                                    },
                                  },
                                },
                                [
                                  ym.imageMode == "aspectFill"
                                    ? _c("span", [_vm._v("填充")])
                                    : _vm._e(),
                                  ym.imageMode == "aspectFit"
                                    ? _c("span", [_vm._v("缩放")])
                                    : _vm._e(),
                                  ym.imageMode == "scaleToFill"
                                    ? _c("span", [_vm._v("拉伸")])
                                    : _vm._e(),
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm.checknum == 2
        ? _c("div", { staticClass: "content_msg" }, [
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("魔方样式")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("图片间隙")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 30, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].imageGap,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "imageGap",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].imageGap",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 30, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].imageGap,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "imageGap",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].imageGap",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("上圆角")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer]
                          .topElementAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "topElementAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].topElementAroundRadius",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer]
                          .topElementAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "topElementAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].topElementAroundRadius",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("下圆角")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer]
                          .bottomElementAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "bottomElementAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].bottomElementAroundRadius",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer]
                          .bottomElementAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "bottomElementAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].bottomElementAroundRadius",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("组件样式")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("底部背景")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(_vm.activeComponents[_vm.indexnumer].pageBgColor)
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer].pageBgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "pageBgColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].pageBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[_vm.indexnumer].pageBgColor =
                                ""
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("组件背景")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(
                        _vm.activeComponents[_vm.indexnumer].componentBgColor
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer]
                              .componentBgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "componentBgColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].componentBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[
                                _vm.indexnumer
                              ].componentBgColor = ""
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("上边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 100, min: 0, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.top,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "top",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.top",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 100, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.top,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "top",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.top",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("下边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 100, min: 0, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "bottom",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.bottom",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 100, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "bottom",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.bottom",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("左右边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 20, min: 0, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.both",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 20, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.both",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("上圆角")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].topAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "topAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].topAroundRadius",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].topAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "topAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].topAroundRadius",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("下圆角")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].bottomAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "bottomAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].bottomAroundRadius",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].bottomAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "bottomAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].bottomAroundRadius",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("linkdialog", {
        attrs: { linkshow: _vm.linkurl },
        on: { closeurl: _vm.closelink },
      }),
      _c("stydialog", {
        attrs: { listrow: _vm.listcheck, showstyle: _vm.showhide },
        on: { closehide: _vm.cloeshow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }