<template>
  <div class="container">
    <el-table border :data="tablist" max-height="300">
      <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="50" />
      <!-- <el-table-column align="center" label="姓名" prop="userName" show-overflow-tooltip />
      <el-table-column align="center" label="电话1" prop="phone">
        <template #default="scope">
          <div class="solt" style="display: flex; align-items: center" @click="callphones(scope.row.phone)">
            {{ scope.row.phone }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="电话2" prop="phonev">
        <template #default="scope">
          <div class="solt" style="display: flex; align-items: center" @click="callphones(scope.row.phonev)">
            {{ scope.row.phonev }}
          </div>
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="日志内容" prop="logTitle" show-overflow-tooltip />
      <el-table-column align="center" label="操作员工" prop="sysName" show-overflow-tooltip />
      <el-table-column align="center" label="日志时间" prop="createTime" />
      <!-- <el-table-column align="center" fixed="right" label="操作">
        <template #default="{ row }">
          <el-button size="default" type="text" @click="showhide(row)">查看</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination background :current-page="1" layout="total, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <showdialog :showbole="showlist" :showtdm="showtblist" @closetb="closeshow" />
  </div>
</template>

<script>
  import { queryListLogForPage } from '@/api/mall/health/casemy/index'
  import showdialog from './showdialog.vue'
  export default {
    components: {
      showdialog,
    },
    props: {
      userinfo: {
        type: Number,
        default: () => {},
      },
    },
    data() {
      return {
        total: 0,
        tablist: [],
        showlist: false,
        showtblist: null,
        form: {
          size: 10,
          current: 1,
          id: this.userinfo,
        },
      }
    },
    watch: {
      userinfo(newValue, oldValue) {
        if (newValue != oldValue) {
          this.form.id = newValue
        }
      },
    },
    created() {
      this.queryListLogForPage()
    },
    methods: {
      closeshow() {
        this.showlist = false
      },
      showhide(row) {
        console.log('12')
        this.showtblist = row
        this.showlist = true
        console.log(this.showlist)
      },
      handleCurrentChange(e) {
        this.form.current = e
        this.queryListLogForPage()
      },
      handleSizeChange(e) {
        this.form.size = e
        this.queryListLogForPage()
      },
      queryListLogForPage() {
        queryListLogForPage({ ...this.form }).then((res) => {
          console.log(res.data)
          this.total = res.data.total
          this.form.size = res.data.size
          this.form.current = res.data.current
          this.tablist = res.data.records
        })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
