import request from '@/utils/request'
// import qs from 'qs'
// export function getUserList(data) {
//   return request({
//     url: `/user/uUser/queryPage`,
//     method: 'post',
//     data,
//   })
// }

export function getUserList(params) {
  return request({
    url: `/mall/distributionuser/page`,
    method: 'get',
    params: params,
  })
}

export function userinfoselect(params) {
  return request({
    url: `/mall/userinfo/page`,
    method: 'get',
    params,
  })
}

export function distributionuser(data) {
  return request({
    url: `/mall/distributionuser`,
    method: 'post',
    data,
  })
}
export function putobj(data) {
  return request({
    url: `/mall/distributionuser`,
    method: 'PUT',
    data: data,
  })
}
export function disableUser(data) {
  return request({
    url: `/user/uUserLogin/disable`,
    method: 'post',
    data,
  })
}

export function getCoupon(data) {
  return request({
    url: `/coupons/cpnCouponConfig/page`,
    method: 'post',
    data,
  })
}

export function addCoupon(data) {
  return request({
    url: `/coupons/cpnCouponUser/addCoupons`,
    method: 'post',
    data,
  })
}

export function resetPwd(data) {
  return request({
    url: `/user/uUserLogin/resetPwd`,
    method: 'post',
    data,
  })
}

export function getProfit(data) {
  return request({
    url: `/merchant/merProfitAmountLog/survey`,
    method: 'post',
    data,
  })
}

export function updateProfit(data) {
  return request({
    url: `/merchant/merCapitalAccount/frozenProfit`,
    method: 'post',
    data,
  })
}

export function deldistributionuser(params) {
  return request({
    url: `/mall/distributionuser/`,
    method: 'DELETE',
    params,
  })
}