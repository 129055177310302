import request from '@/utils/request'
// 设备列表
export function queryPage(data) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/queryPage`,
    method: 'post',
    data,
  })
}

// 导入设备
export function importDevice(data) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/importDevice`,
    method: 'post',
    data,
    headers: {
      'Content-type': 'multipart/form-data',
    },
  })
}

// 导入糖脂管家客户基础信息调查
export function importuUserSugarRecord(data, type) {
  return request({
    url: `/mall/user/uuUserSugarRecord/importuUserSugarRecord/` + type,
    method: 'post',
    data,
    headers: {
      'Content-type': 'multipart/form-data',
    },
  })
}

// 设备编辑
export function editColumn(data) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/edit`,
    method: 'post',
    data,
  })
}
// 设备添加
export function addColumn(data) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/add`,
    method: 'post',
    data,
  })
}
// 设备删除
export function deleteColumn(data) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/delete`,
    method: 'post',
    data,
  })
}
// 设备状态
export function disableColumn(data) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/disable`,
    method: 'post',
    data,
  })
}
// 配置类型（所有文章类型）
export function queryListForList(data) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/queryListForList`,
    method: 'post',
    data,
  })
}
// 配置类型（所有文章类型）
export function addColumnType(data) {
  return request({
    url: `/user/uuUserGsmDevice/add`,
    method: 'post',
    data,
  })
}

// 查询所有的设备
export function uuUserGlueDevicequeryList(data) {
  return request({
    url: `/mall/swm/uuUserGlueDevice/queryList`,
    method: 'post',
    data,
  })
}

// 设备-批量导入
export function uuUserGsmDeviceimportDevice(data, type) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/importDevice/` + type,
    method: 'post',
    data,
    headers: {
      'Content-type': 'multipart/form-data',
    },
  })
}
