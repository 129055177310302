<template>
  <div class="textTool">
    <div class="toolTit">
      <div class="lef">列表导航</div>
      <div class="ref">
        <div class="switch">
          <div :class="checknum == 1 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(1)">内容</div>
          <div :class="checknum == 2 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(2)">样式</div>
        </div>
      </div>
    </div>
    <div v-if="checknum == 1" class="content_msg">
      <div class="ym_line"></div>
      <div class="itemBox" style="margin-top: 10px">
        <div class="Tit">图标状态</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-checkbox-group v-model="activeComponents[indexnumer].isShow">
            <el-checkbox v-if="activeComponents[indexnumer].isShow" :label="true">显示</el-checkbox>
            <el-checkbox v-else :label="false">隐藏</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">文字大小</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].style.fontSize" :max="15" :min="12" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].style.fontSize" :max="15" :min="12" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">是否加粗</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-checkbox-group v-model="activeComponents[indexnumer].style.fontWeight">
            <el-checkbox v-if="activeComponents[indexnumer].style.fontWeight" :label="true">加粗</el-checkbox>
            <el-checkbox v-else :label="false">常规</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="ym_title">列表设置</div>
      <div class="alert-msg" style="margin: top 20px; text-align: left">请按照规范设置列表内容</div>
      <div class="itemBox" style="height: auto">
        <div class="list-navgation">
          <div v-for="(ym, ind) in activeComponents[indexnumer].data" :key="ind" class="card_list">
            <div class="close_card-item" @click="deletelist(ind)">
              <i class="el-icon-close"></i>
            </div>
            <div class="list-title">
              <span class="tit">图片</span>
              <div class="list-picUrl">
                <uploadpic :picurl="[{ url: ym.iconUrl }]" @backkuck="check($event, ind)" />
              </div>
            </div>
            <div class="list-title">
              <span class="tit">标题</span>
              <el-input v-model="ym.text" placeholder="请输入列表标题" />
            </div>
            <div class="list-title">
              <span class="tit">链接</span>
              <div class="check-tit" style="display: flex; width: 260px" @click="checklink(ind)">
                <span v-if="ym.linkUrl.name != ''" style="color: #1780ff">{{ ym.linkUrl.title }}</span>
                <span v-else>请选择链接</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="add-ba" @click="addlist">
          <i class="el-icon-plus"></i>
          增加一个
        </div>
      </div>
    </div>
    <div v-if="checknum == 2" class="content_msg">
      <div class="ym_line"></div>
      <div class="itemBox" style="padding: 30px 0">
        <div class="Tit">底部背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].pageBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].pageBgColor" />
            <div class="resect" @click="activeComponents[indexnumer].pageBgColor = ''">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="alert-msg">底部背景包含边距和圆角</div>
      <div class="itemBox">
        <div class="Tit">组件背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].componentBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].componentBgColor" />
            <div class="resect" @click="activeComponents[indexnumer].componentBgColor = ''">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">底线颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].style.lineColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].style.lineColor" />
            <div class="resect" @click="activeComponents[indexnumer].style.lineColor = ''">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">列表背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].style.bgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].style.bgColor" />
            <div class="resect" @click="activeComponents[indexnumer].style.bgColor = '#FFFFFF'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">列表高度</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].style.contHeight" :max="100" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].style.contHeight" :max="100" :min="40" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">内撑边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].style.pgboth" :max="50" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].style.pgboth" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.top" :max="100" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.top" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.bottom" :max="100" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.bottom" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">左右边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.both" :max="20" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.both" :max="20" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].topElementAroundRadius" :max="50" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].topElementAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].bottomElementAroundRadius" :max="50" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].bottomElementAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
    </div>
    <linkdialog :linkshow="linkurl" @closeurl="closelink" />
  </div>
</template>
<script>
import linkdialog from '../../../link-dialog/index.vue'
import uploadpic from '../../../upload-pic/index.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    uploadpic,
    linkdialog,
  },
  props: {
    indexnumer: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      checknum: 1,
      linkurl: false,
      info: null,
    }
  },
  computed: {
    ...mapGetters['state'],
    activeComponents() {
      return this.$store.state.checkvalue
    },
    imageurl() {
      let imgUrl = this.activeComponents[this.indexnumer].imageUrl
      if (imgUrl) {
        return imgUrl
      } else {
        return ''
      }
    },
  },
  methods: {
    checklink(row) {
      this.info = row
      this.linkurl = true
    },
    addlist() {
      const list = {
        text: '文字',
        linkUrl: {
          name: '',
        },
        linkName: '',
        linkType: '',
        iconUrl: 'https://uniapp.v5.niuteam.cn/public/static/img/default_img/figure.png',
        unicode: '&#xe602',
      }
      this.activeComponents[this.indexnumer].data.push(list)
    },
    deletelist(Index) {
      this.$confirm('是否删除该数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.activeComponents[this.indexnumer].data.splice(Index, 1)
        })
        .catch(() => {
          this.$message('您已取消删除')
        })
    },
    closelink(lisr) {
      if (lisr != false) {
        this.activeComponents[this.indexnumer].data[this.info].linkUrl = lisr
        this.linkurl = false
      } else {
        this.linkurl = false
      }
    },
    check(e, Index) {
      this.activeComponents[this.indexnumer].data[Index].iconUrl = e
    },
    changeChecknum(type) {
      this.checknum = type
    },
  },
}
</script>

<style lang="scss" scoped>
.textTool {
  width: 100%;
  padding: 20px 20px 20px 20px;
  .toolTit {
    display: flex;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
    color: #333333;
    .switch {
      display: flex;
      align-items: center;
      overflow: hidden;
      font-size: 14px;
      cursor: pointer;
      background: #f5f5f5;
      border-radius: 50px;
      .swit-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 29px;
        border-radius: 50px;
      }
      .active {
        color: white;
        background: #1780ff;
      }
    }
  }
  .ym_line {
    width: 100%;
    height: 3px;
    background: #f2f4f6;
  }
  .ym_title {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .itemBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 40px;
    margin-left: 20px;
    .add-ba {
      align-items: center;
      width: 100%;
      min-height: 40px;
      margin: 20px 0;
      line-height: 40px;
      color: white;
      text-align: center;
      cursor: pointer;
      background: #1780ff;
      border: 1px dashed transparent;
    }
    .list-navgation {
      width: 100%;
      .card_list {
        width: 100%;
        padding: 10px;
        min-height: 120px;
        border: 1px dashed #e5e5e5;
        margin: 20px 0;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .list-title {
          width: 100%;
          white-space: nowrap;
          display: flex;
          margin: 10px 0;
          align-items: center;
          .list-picUrl {
            width: 70px;
            height: 70px;
            overflow: hidden;
          }
          .tit {
            margin-right: 20px;
          }
        }
        .close_card-item {
          position: absolute;
          top: -9px;
          right: -9px;
          width: 18px;
          height: 18px;
          line-height: 18px;
          color: white;
          text-align: center;
          cursor: pointer;
          background: #424242;
          border-radius: 100%;
        }
      }
    }
    .Tit {
      margin-right: 15px;
      color: gray;
      text-align: right;
      white-space: nowrap;
    }
    .check-tit {
      display: flex;
      justify-content: right;
      width: calc(100% - 56px);
      color: #105cfb;
      .resect {
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }
  .alert-msg {
    margin-bottom: 15px;
    font-size: 12px;
    color: rgb(210, 210, 210);
    text-align: center;
  }
  ::v-deep(.el-color-picker__trigger) {
    width: 45px;
    height: 26px;
  }
  ::v-deep(.el-icon-arrow-down:before) {
    display: none;
  }
  ::v-deep {
    .el-input-number {
      width: 80px;
      .el-input__inner {
        padding: 0;
        text-align: center;
      }
    }

    .el-input-number__decrease,
    .el-input-number__increase {
      display: none;
    }
  }
}
</style>
