var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "user_info",
        style: { borderColor: _vm.selectauthor == 2 ? "#1786ff" : "#e5e5e5" },
        on: {
          click: function ($event) {
            return _vm.selectuser(_vm.checkuser, 2)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "user_picurl" },
          [
            _c("el-image", {
              staticStyle: {
                width: "40px",
                height: "40px",
                "border-radius": "100%",
              },
              attrs: { src: _vm.checkuser.headimgUrl },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "user_name", staticStyle: { margin: "2px 10px" } },
          [_vm._v("姓名:" + _vm._s(_vm.checkuser.nickName))]
        ),
        _c(
          "div",
          { staticClass: "user_name", staticStyle: { margin: "2px 10px" } },
          [_vm._v("编号:" + _vm._s(_vm.checkuser.userCode))]
        ),
        _c(
          "div",
          {
            staticClass: "userphone",
            staticStyle: { margin: "2px 10px", "white-space": "nowrap" },
          },
          [_vm._v("手机号:" + _vm._s(_vm.checkuser.phone))]
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "return" },
      [
        _c("p", [_vm._v("回访提醒")]),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { border: "", data: _vm.tableData },
          },
          [
            _c("el-table-column", {
              attrs: { align: "center", label: "提醒日期", prop: "date" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "回访内容", prop: "name" },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "return" },
      [
        _c("p", [_vm._v("交流记录")]),
        _c(
          "el-table",
          { attrs: { border: "", data: _vm.tablist, "max-height": "300" } },
          [
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "序号",
                type: "index",
                width: "55",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "员工工号", prop: "profile" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "通话类型", prop: "interType" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "交流时间", prop: "createTime" },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "交流类型",
                prop: "interTitle",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "交流内容",
                prop: "interContent",
                "show-overflow-tooltip": "",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "return" },
      [
        _c("p", [_vm._v("通话记录")]),
        _c(
          "el-table",
          { attrs: { border: "", data: _vm.tablist1, "max-height": "300" } },
          [
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "序号",
                "show-overflow-tooltip": "",
                type: "index",
                width: "50",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "开始时间",
                prop: "startTime",
                width: "170px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "结束时间",
                prop: "endTime",
                width: "170px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "坐席工号",
                prop: "agent",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "主叫号码",
                prop: "callingPhone",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "被叫号码",
                prop: "calledPhone",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "振铃时长(秒)",
                prop: "ringingDuration",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "应答时长(秒)",
                prop: "answerDuration",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "呼叫状态", prop: "callState" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.callState == 0
                        ? _c("div", [_vm._v("未接")])
                        : _vm._e(),
                      scope.row.callState == 1
                        ? _c("div", [_vm._v("未转接")])
                        : _vm._e(),
                      scope.row.callState == 2
                        ? _c("div", [_vm._v("呼损")])
                        : _vm._e(),
                      scope.row.callState == 3
                        ? _c("div", [_vm._v("接通后挂断")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "return" },
      [
        _c("p", [_vm._v("购物记录")]),
        _c(
          "el-table",
          { attrs: { border: "", data: _vm.tablist2, "max-height": "300" } },
          [
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "序号",
                type: "index",
                width: "55",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "订单编号",
                prop: "orderNo",
                width: "190",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "下单用户",
                prop: "nickName",
                width: "140",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "订单金额",
                prop: "paymentPrice",
                width: "100",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "下单员工",
                prop: "profile",
                "show-overflow-tooltip": "",
                width: "140",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        _vm._s(scope.row.realName) +
                          _vm._s(
                            scope.row.profile == ""
                              ? ""
                              : "(" + scope.row.profile + ")"
                          )
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "状态",
                prop: "statusDesc",
                "show-overflow-tooltip": "",
                width: "80",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "所购产品",
                prop: "product",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "下单地址",
                prop: "address",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "质检审核",
                prop: "quaStateDesc",
                width: "80",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "div",
                        [
                          row.quaState == 1
                            ? _c("el-tag", { attrs: { type: "succes" } }, [
                                _vm._v("待质检"),
                              ])
                            : _vm._e(),
                          row.quaState == 2
                            ? _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v("已通过"),
                              ])
                            : _vm._e(),
                          row.quaState == 3
                            ? _c("el-tag", { attrs: { type: "info" } }, [
                                _vm._v("已驳回"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "质检信息",
                prop: "quaContent",
                "show-overflow-tooltip": "",
                width: "80",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "预存金额",
                prop: "presAmount",
                width: "80",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "订单日期",
                prop: "createTime",
                width: "200",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }