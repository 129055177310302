<template>
  <div>
    <el-dialog append-to-body :before-close="claer" title="客情部门" :visible.sync="appointDialog" width="30%">
      <el-form ref="addForm" label-width="130px" :model="addForm" :rules="rules">
        <el-form-item label="客户姓名" prop="username">
          <el-input v-model="uslist.userName" autocomplete="off" disabled placeholder="请输入用户名" />
        </el-form-item>
        <!-- <el-form-item class="mobilePhone" label="手机号" prop="mobile">
          <el-input v-model="uslist.phone" class="input-with-select" disabled oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入手机号" />
        </el-form-item> -->
        <el-form-item class="postPare" label="指派部门">
          <el-select v-model="addForm.mallcategory" disabled placeholder="">
            <el-option v-for="(ym, index) in dataSourceLists" :key="index" :label="ym.orgName" :value="ym.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="客情类型" prop="afterTypeId">
          <el-select v-model="addForm.afterTypeId" placeholder="请选择 客情类型">
            <el-option v-for="item in optionslist" :key="item.id" :label="item.labelName" :value="item.id" />
          </el-select>
        </el-form-item>
        <div v-if="addForm.afterTypeId == 1">
          <el-form-item label="客户客情时间" prop="afterTime">
            <el-date-picker v-model="addForm.afterTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="客户签收金额">
            <el-input v-model="addForm.signAmount" placeholder="客户签收金额" />
          </el-form-item>
          <el-form-item label="客户反馈情况" prop="issueMark">
            <el-input v-model="addForm.issueMark" placeholder="客户反馈情况" type="textarea" />
          </el-form-item>
        </div>
        <div v-if="addForm.afterTypeId == 2">
          <el-form-item label="客户签收时间" prop="signTime">
            <el-date-picker v-model="addForm.signTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="客户反馈时间" prop="feedTime">
            <el-date-picker v-model="addForm.feedTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="产品问题描述" prop="issueMark">
            <el-input v-model="addForm.issueMark" placeholder="产品问题描述" type="textarea" />
          </el-form-item>
        </div>
        <div v-if="addForm.afterTypeId == 3">
          <el-form-item label="客户签收时间" prop="signTime">
            <el-date-picker v-model="addForm.signTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="客户反馈时间" prop="feedTime">
            <el-date-picker v-model="addForm.feedTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="产品问题描述" prop="issueMark">
            <el-input v-model="addForm.issueMark" placeholder="产品问题描述" type="textarea" />
          </el-form-item>
        </div>
        <div v-if="addForm.afterTypeId == 4">
          <el-form-item label="客户签收时间" prop="signTime">
            <el-date-picker v-model="addForm.signTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="客户反馈时间" prop="feedTime">
            <el-date-picker v-model="addForm.feedTime" align="right" format="yyyy-MM-dd" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd " />
          </el-form-item>
          <el-form-item label="产品问题描述" prop="issueMark">
            <el-input v-model="addForm.issueMark" placeholder="产品问题描述" type="textarea" />
          </el-form-item>
        </div>
        <el-form-item label="客情备注" prop="remark">
          <el-input v-model="addForm.remark" placeholder="备注信息" type="textarea" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { afterSale } from '@/api/mall/health/seas/seasManger'
import { queryDateisOrgorgTree } from '@/api/mall/role/orgManage'
import dayjs from 'dayjs'
import { queryPage } from '@/api/mall/setting/stplabel'
export default {
  props: {
    showtab: {
      type: Boolean,
      default: () => {},
    },
    hanuser: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      appointDialog: false,
      dataSourceLists: [],
      addForm: {},
      uslist: null,
      props: {
        checkStrictly: true,
        multiple: true,
        label: 'orgName',
        value: 'id',
      },
      optionslist: null,
      rules: {
        afterTypeId: [{ required: true, message: '请选择 客情类型', trigger: 'change' }],
        mallcategory: [{ required: true, message: '请选择 所属部门', trigger: 'change' }],
        remark: [{ required: true, message: '请输入 客情备注', trigger: 'blur' }],
        signAmount: [
          { required: true, message: '请输入 正确的金额', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!/^\d+(\.\d+)?$/.test(value)) {
                callback(new Error('金额必须是数字'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        refundAmount: [
          { required: true, message: '请输入 正确的金额', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!/^\d+(\.\d+)?$/.test(value)) {
                callback(new Error('金额必须是数字'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        signTime: [{ required: true, message: '请选择 客户签收时间', trigger: 'change' }],
        afterTime: [{ required: true, message: '请选择 客户客情时间', trigger: 'change' }],
        feedTime: [{ required: true, message: '请选择 客户反馈时间', trigger: 'change' }],
        issueMark: [{ required: true, message: '请输入 产品问题描述', trigger: 'blur' }],
      },
    }
  },
  watch: {
    showtab(newValue, oldValue) {
      if (newValue != oldValue) {
        this.appointDialog = newValue
      }
    },
    hanuser(newValue, oldValue) {
      if (newValue != oldValue) {
        this.uslist = newValue
      }
    },
  },
  created() {
    this.fetchData()
    this.queryPage()
  },
  methods: {
    queryPage() {
      queryPage({ labelType: 9 }).then((res) => {
        console.log(res.data)
        this.optionslist = res.data.records
      })
    },
    getcasc(e) {
      console.log(e)
      console.log(this.dataSourceLists)
    },
    async fetchData() {
      this.listLoading = true
      const parms = {
        current: this.current,
        size: this.size,
      }
      await queryDateisOrgorgTree(parms)
        .then((res) => {
          if (res.data) {
            this.menuListTable = res.data.records
            this.dataSourceLists = res.data.records
            this.addForm.mallcategory = this.dataSourceLists[0].id
            this.dataSourceLists.map((obj) => {
              obj.label = obj.orgName
              obj.value = obj.id
              const fieldArr = obj.children
              const newFieldArr = fieldArr.map((element) => {
                element.label = element.orgName
                element.value = element.id
                element.children.forEach((item) => {
                  item.label = item.orgName
                  item.value = item.id
                  if (item.children != null) {
                    item.children.forEach((el) => {
                      el.label = el.orgName
                      el.value = el.id
                    })
                  }
                })
                return element
              })
              obj.fieldArr = newFieldArr
              return obj
            })
          }
        })
        .catch(() => {})
      this.listLoading = false
    },
    clear() {
      this.$emit('closeswitch', false)
      this.fetchData()
    },
    teaChange(e) {
      if (!e.length) {
        this.appointData.forEach((item) => {
          item.disabled = false
        })
      }
    },
    claer() {
      this.$emit('closeswitch', false)
    },
    leftChange(e) {
      this.appointData.forEach((item) => {
        if (e[0] == item.id) item.disabled = false
        else item.disabled = true
      })
    },
    sure() {
      const date = new Date()
      const timer =
        this.addForm.signTime +
        ` ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        }`
      const timers =
        this.addForm.afterTime +
        ` ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        }`
      const timerday =
        this.addForm.feedTime +
        ` ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        }`
      if (this.addForm.signTime) {
        this.addForm.signTime = dayjs(timer).format('YYYY-MM-DD HH:mm:ss')
      }
      if (this.addForm.afterTime) {
        this.addForm.afterTime = dayjs(timers).format('YYYY-MM-DD HH:mm:ss')
      }
      if (this.addForm.feedTime) {
        this.addForm.feedTime = dayjs(timerday).format('YYYY-MM-DD HH:mm:ss')
      }
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          afterSale({
            ...this.addForm,
            orgIds: [this.dataSourceLists[0].id],
            id: this.uslist.id,
            remark: this.addForm.remark,
            afterTypeId: this.addForm.afterTypeId,
            mallcategory: this.dataSourceLists[0].id,
          }).then((res) => {
            if (res.status == 0 || res.status == 200) {
              this.$emit('closeswitch', false)
              this.$message.success('提交成功')
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-select,
  .el-date-editor {
    width: 100%;
  }
}
</style>
