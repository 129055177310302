var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "u-countdown" }, [
    _vm.showDays && (_vm.hideZeroDay || (!_vm.hideZeroDay && _vm.d != "00"))
      ? _c("div", { staticClass: "u-countdown-item", style: [_vm.itemStyle] }, [
          _c(
            "div",
            { staticClass: "u-countdown-time", style: [_vm.letterStyle] },
            [_vm._v(" " + _vm._s(_vm.d) + " ")]
          ),
        ])
      : _vm._e(),
    _vm.showDays && (_vm.hideZeroDay || (!_vm.hideZeroDay && _vm.d != "00"))
      ? _c(
          "div",
          {
            staticClass: "u-countdown-colon",
            style: {
              fontSize: _vm.separatorSize + "px",
              color: _vm.separatorColor,
              paddingBottom: _vm.separator == "colon" ? "4px" : 0,
            },
          },
          [_vm._v(" " + _vm._s(_vm.separator == "colon" ? ":" : "天") + " ")]
        )
      : _vm._e(),
    _vm.showHours
      ? _c("div", { staticClass: "u-countdown-item", style: [_vm.itemStyle] }, [
          _c(
            "div",
            {
              staticClass: "u-countdown-time",
              style: { fontSize: _vm.fontSize + "px", color: _vm.color },
            },
            [_vm._v(" " + _vm._s(_vm.h) + " ")]
          ),
        ])
      : _vm._e(),
    _vm.showHours
      ? _c(
          "div",
          {
            staticClass: "u-countdown-colon",
            style: {
              fontSize: _vm.separatorSize + "px",
              color: _vm.separatorColor,
              paddingBottom: _vm.separator == "colon" ? "4px" : 0,
            },
          },
          [_vm._v(" " + _vm._s(_vm.separator == "colon" ? ":" : "时") + " ")]
        )
      : _vm._e(),
    _vm.showMinutes
      ? _c("div", { staticClass: "u-countdown-item", style: [_vm.itemStyle] }, [
          _c(
            "div",
            {
              staticClass: "u-countdown-time",
              style: { fontSize: _vm.fontSize + "px", color: _vm.color },
            },
            [_vm._v(" " + _vm._s(_vm.i) + " ")]
          ),
        ])
      : _vm._e(),
    _vm.showMinutes
      ? _c(
          "div",
          {
            staticClass: "u-countdown-colon",
            style: {
              fontSize: _vm.separatorSize + "px",
              color: _vm.separatorColor,
              paddingBottom: _vm.separator == "colon" ? "4px" : 0,
            },
          },
          [_vm._v(" " + _vm._s(_vm.separator == "colon" ? ":" : "分") + " ")]
        )
      : _vm._e(),
    _vm.showSeconds
      ? _c("div", { staticClass: "u-countdown-item", style: [_vm.itemStyle] }, [
          _c(
            "div",
            {
              staticClass: "u-countdown-time",
              style: { fontSize: _vm.fontSize + "px", color: _vm.color },
            },
            [_vm._v(" " + _vm._s(_vm.s) + " ")]
          ),
        ])
      : _vm._e(),
    _vm.showSeconds
      ? _c(
          "div",
          {
            staticClass: "u-countdown-colon",
            style: {
              fontSize: _vm.separatorSize + "px",
              color: _vm.separatorColor,
              paddingBottom: _vm.separator == "colon" ? "4px" : 0,
            },
          },
          [_vm._v(" " + _vm._s(_vm.separator == "colon" ? "" : "秒") + " ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }