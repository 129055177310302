var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "before-close": _vm.handleClose,
            title: "事件处理进度",
            visible: _vm.dialogVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _c(
              "div",
              { staticClass: "thead_tree" },
              [
                _c(
                  "el-timeline",
                  _vm._l(_vm.list, function (ym, index) {
                    return _c(
                      "el-timeline-item",
                      {
                        key: index,
                        attrs: { placement: "top", timestamp: ym.createTime },
                      },
                      [
                        _c(
                          "el-card",
                          [
                            _c("h4", [
                              _c(
                                "span",
                                { staticStyle: { color: "#1786ff" } },
                                [_vm._v(_vm._s(ym.realName))]
                              ),
                              _vm._v(" 添加了备注"),
                            ]),
                            _c("p", [_vm._v(_vm._s(ym.remark))]),
                            ym.fileUrl && ym.fileUrl.length > 0
                              ? [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      fit: _vm.fit,
                                      "preview-src-list": ym.fileUrl,
                                      src: ym.fileUrl,
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }