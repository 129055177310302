var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container",
      style: {
        background: _vm.controls[_vm.index].pageBgColor,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "list-navigation",
          style: {
            background: _vm.controls[_vm.index].componentBgColor,
            borderRadius: `${
              _vm.controls[_vm.index].topElementAroundRadius
            }px ${_vm.controls[_vm.index].topElementAroundRadius}px ${
              _vm.controls[_vm.index].bottomElementAroundRadius
            }px ${_vm.controls[_vm.index].bottomElementAroundRadius}px`,
            padding: `${_vm.controls[_vm.index].margin.top}px ${
              _vm.controls[_vm.index].margin.both
            }px ${_vm.controls[_vm.index].margin.bottom}px  ${
              _vm.controls[_vm.index].margin.both
            }px`,
          },
        },
        _vm._l(_vm.controls[_vm.index].data, function (ym, ind) {
          return _c(
            "div",
            {
              key: ind,
              staticClass: "navigation-item",
              style: {
                background: _vm.controls[_vm.index].style.bgColor,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "list",
                  style: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "auto",
                    height: _vm.controls[_vm.index].style.contHeight + "px",
                    borderBottom:
                      "1px solid" + _vm.controls[_vm.index].style.lineColor,
                    width: `calc(100% - ${
                      _vm.controls[_vm.index].style.pgboth * 2
                    }px)`,
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm.controls[_vm.index].isShow
                      ? _c("div", { staticClass: "navigation-icon" }, [
                          _c("img", { attrs: { alt: "", src: ym.iconUrl } }),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "navigation-txt",
                        style: {
                          fontSize:
                            _vm.controls[_vm.index].style.fontSize + "px",
                          color: _vm.controls[_vm.index].style.textColor,
                          fontWeight: _vm.controls[_vm.index].style.fontWeight
                            ? "bold"
                            : "normal",
                        },
                      },
                      [_vm._v(" " + _vm._s(ym.text) + " ")]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ref" },
                    [_c("vab-icon", { attrs: { icon: "arrow-right-s-line" } })],
                    1
                  ),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }