<template>
  <div
    class="container"
    :style="{
      background: `${controls[index].pageBgColor}`,
      padding: `${controls[index].margin.top}px ${controls[index].margin.both}px ${controls[index].margin.bottom}px ${controls[index].margin.both}px `,
    }"
  >
    <div :class="controls[index].style == 2 ? 'user_thead borRadius' : 'user_thead'" :style="bgColr(controls[index].theme, controls[index].gradientAngle, controls[index].style)">
      <!-- {{controls[index]}} -->
      <div class="th_banhead">
        <div class="lef">
          <div class="tehad_userimg">
            <img alt="" src="https://uniapp.v5.niuteam.cn/public/static/img/default_img/head.png" />
          </div>
          <div class="center_info">
            <div class="club">
              <div class="name">勇敢的某某</div>
              <div class="vip_picUrl">
                <vab-icon icon="vip-crown-2-fill" />
                <!-- <img alt="" src="https://uniapp.v5.niuteam.cn/app/component/view/member_info/img/style_4_vip_tag.png"> -->
                <span>会员</span>
              </div>
            </div>
            <div class="alert_msg">点击登录享受更多精彩信息</div>
          </div>
        </div>
        <div class="ref">
          <vab-icon icon="qr-code-line" style="font-size: 20px" />
        </div>
      </div>
      <div
        v-if="controls[index].style != 2 && controls[index].style != 4"
        class="account-info"
        :style="{
          padding: `0 ${controls[index].margin.both}px`,
          marginBottom: `10px`,
        }"
      >
        <div v-for="(item, ind) in fity(controls[index].isBtnshow)" :key="ind" class="items">
          <div class="numb">{{ item.isNumber }}</div>
          <div class="numbs">{{ item.isTitle }}</div>
        </div>
      </div>
      <div
        :style="{
          padding: `0 ${controls[index].margin.both}px`,
        }"
      >
        <div v-if="controls[index].style != 4" class="super_member" :style="bGurl(controls[index].style)">
          <div
            class="lef"
            :style="{
              display: controls[index].style == 3 ? 'grid' : 'flex',
            }"
          >
            <img v-if="controls[index].style == 3" alt="" src="https://uniapp.v5.niuteam.cn/public/uniapp/member/open_member.png" style="height: 18px" />
            <vab-icon v-else icon="vip-crown-2-fill" style="font-size: 18px" />
            <span :style="{ fontSize: controls[index].style == 3 ? '12px' : '18px' }">超级会员</span>
          </div>
          <div class="ref">
            <div v-if="controls[index].style == 3" class="btn" style="">查看特权</div>
            <div v-else class="ref">
              <span>会员可享受更多权益</span>
              <vab-icon icon="arrow-right-s-line" style="font-size: 18px" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :style="{
        padding: `0 ${controls[index].margin.both}px`,
      }"
    >
      <div v-if="controls[index].style == 2" class="account-info bold_ym" style="width: calc(100% - 26px); maring: 0 auto; margin-top: 10px; color: #333; background: white">
        <div v-for="(item, ind) in fity(controls[index].isBtnshow)" :key="ind" class="items">
          <div class="numb">{{ item.isNumber }}</div>
          <div class="numbs">{{ item.isTitle }}</div>
        </div>
      </div>
    </div>
    <div
      :style="{
        padding: `0 ${controls[index].margin.both}px`,
      }"
    >
      <div
        v-if="controls[index].style == 4"
        :style="{
          background: 'white',
          padding: '15px',
          margin: '15px',
          marginTop: '-100px',
          borderRadius: '10px',
        }"
      >
        <div v-if="controls[index].style == 4" class="super_member" :style="bGurl(controls[index].style)">
          <div
            class="lef"
            :style="{
              display: controls[index].style == 4 ? 'flex' : 'grid',
              alignItems: `center`,
            }"
          >
            <img
              v-if="controls[index].style == 4"
              alt=""
              src="https://uniapp.v5.niuteam.cn/app/component/view/member_info/img/style_4_vip_huangguan.png"
              style="height: 40px; margin-right: 10px"
            />
            <vab-icon v-else icon="vip-crown-2-fill" style="font-size: 18px" />
            <span :style="{ fontSize: controls[index].style == 3 ? '12px' : '15px', fontWeight: 'bold', color: controls[index].style == 3 ? '#333' : '#333' }">
              开通会员 享六大权益
            </span>
          </div>
          <div class="ref">
            <div class="super-text">立即开通</div>
          </div>
        </div>
        <div
          v-if="controls[index].style == 4"
          class="account-info"
          :style="{
            margin: `10px ${controls[index].margin.both}px`,
          }"
        >
          <div v-for="(item, ind) in fity(controls[index].isBtnshow)" :key="ind" class="items" :style="{ borderRight: ind < 2 ? '1px solid black' : '', color: 'black' }">
            <div class="numb">{{ item.isNumber }}</div>
            <div class="numbs">{{ item.isTitle }}</div>
          </div>
        </div>
        <div v-if="controls[index].style == 4" class="setting-card">
          <div class="lef">
            <div class="balance">余额充值</div>
            <div class="balance">专属顾问</div>
          </div>
          <div class="ref">
            <img alt="" src="https://uniapp.v5.niuteam.cn/app/component/view/member_info/img/style_4_code.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    checkrow: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      activeComponent: '',
      terminal: this.index,
      pic: '#292F45 url("https://uniapp.v5.niuteam.cn/app/component/view/member_info/img/supervip_bg.png") no-repeat bottom / 100% 100%',
      picUrl: `url('https://uniapp.v5.niuteam.cn/app/component/view/member_info/img/super_member_bg.png') no-repeat bottom / 100% 100%, linear-gradient(107deg, #7C7878 0%, #201A18 100%)`,
      picforUrl: `#292F45 url("https://uniapp.v5.niuteam.cn/app/component/view/member_info/img/super_vip_bg_4.png") no-repeat bottom / 100% 100%`,
    }
  },
  computed: {
    ...mapState({
      controls: (state) => state.checkvalue,
    }),
    fity() {
      return (item) => {
        const list = []
        item.forEach((element) => {
          if (element.isShow) {
            list.push(element)
          }
        })
        return list
      }
    },
    bgColr() {
      return (item, round, check) => {
        if (check != 4) {
          if (item == 'diy') {
            return `
            background:url("https://uniapp.v5.niuteam.cn/app/component/view/member_info/img/style_4_bg.png")center bottom / cover no-repeat,linear-gradient(${round}deg, ${
              this.controls[this.index].bgColorStart
            } 0%, ${this.controls[this.index].bgColorEnd} 100%);`
          } else {
            return ``
          }
        } else {
          if (item == 'diy') {
            return `
            height:208px;
            background:url("https://uniapp.v5.niuteam.cn/app/component/view/member_info/img/style_4_bg.png")center bottom / cover no-repeat,linear-gradient(${round}deg, ${
              this.controls[this.index].bgColorStart
            } 0%, ${this.controls[this.index].bgColorEnd} 100%);`
          } else {
            return ``
          }
        }
      }
    },
    bGurl() {
      return (item) => {
        if (item === '1') {
          return this.picUrl
        } else if (item === '3') {
          return `
              background:${this.pic};
              height:60px;
              border-radius:10px;
            `
        } else if (item === '4') {
          return `
              background:${this.picforUrl};
              height:60px;
              border-radius:10px;
              display:flex;
              justify-content:space-between;
              align-items: center;
              padding: 0 10px;
            `
        }
      }
    },
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        this.terminal = newValue
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  .user_thead {
    width: 100%;
    background: url('https://uniapp.v5.niuteam.cn/app/component/view/member_info/img/style_4_bg.png') center bottom / cover no-repeat,
      linear-gradient(129deg, rgb(162, 83, 255) 0%, rgb(162, 83, 255) 100%);
    .th_banhead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      .lef {
        display: flex;
        align-items: center;
        .tehad_userimg {
          width: 60px;
          height: 60px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .center_info {
          margin-left: 10px;
          font-size: 14px;
          color: white;
          .club {
            display: flex;
            align-items: center;
          }
          .name {
            font-weight: bold;
          }
          .vip_picUrl {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 18px;
            padding: 2px 20px;
            margin-left: 20px;
            font-size: 12px;
            background: linear-gradient(107deg, #7c7878 0%, #201a18 100%);
            span {
            }
          }
          .alert_msg {
            margin-top: 10px;
            font-size: 12px;
          }
        }
      }
      .ref {
        color: white;
      }
    }

    .super_member {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 50px;
      margin: 0 12px;
      font-size: 18px;
      color: #ffdba6;
      background: url('https://uniapp.v5.niuteam.cn/app/component/view/member_info/img/super_member_bg.png') no-repeat bottom / 100% 100%,
        linear-gradient(107deg, #7c7878 0%, #201a18 100%);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      .ref {
        font-size: 14px;
        .btn {
          display: block;
          width: 80px;
          height: 28px;
          font-size: 12px;
          font-weight: bold;
          line-height: 28px;
          color: #77413e;
          text-align: center;
          background: #e3c377;
          border-radius: 28px;
        }
      }
    }
  }
  .borRadius {
    overflow: hidden;
    border-radius: 0 0 100% 100%/0 0 35px 35px;
  }
  .account-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-around;
    width: 341px;
    min-height: 39px;
    padding: 10px 0;
    margin: auto;
    color: white;
    text-align: center;
    .items {
      border-right: 1px solid #fff;
    }
    .items:last-of-type {
      border: none;
    }
    .numb {
      font-size: 19px;
      font-weight: bold;
    }
    .numbs {
      margin-top: 5px;
      font-size: 14px;
    }
    .line {
      width: 1px;
      height: 100%;
      min-height: 39px;
      background: white;
    }
  }
  .bold_ym {
    .numb {
      font-size: 19px;
      font-weight: bold;
    }
  }
  .super-text {
    width: 69px;
    height: 26px;
    border-radius: 20px;
    background: #333;
    color: #fad691;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  .setting-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .lef {
      display: flex;
      .balance {
        width: 100px;
        background: #fad691;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        font-size: 14px;
        margin-right: 10px;
        padding: 10px 15px;
      }
      .balance:nth-child(2) {
        background: transparent;
        border: 1px solid #fad691;
        color: #fad691;
      }
    }
    .ref {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: #fad691;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
</style>