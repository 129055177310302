var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vab-query-form-top-panel",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: true, "label-width": "89px", model: _vm.queryForm },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客户姓名" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入客户姓名" },
                model: {
                  value: _vm.queryForm.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "userName", $$v)
                  },
                  expression: "queryForm.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户电话" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入客户电话" },
                model: {
                  value: _vm.queryForm.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "phone", $$v)
                  },
                  expression: "queryForm.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入订单编号" },
                model: {
                  value: _vm.queryForm.orderNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "orderNo", $$v)
                  },
                  expression: "queryForm.orderNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提交员工" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入下单员工" },
                model: {
                  value: _vm.queryForm.empName,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "empName", $$v)
                  },
                  expression: "queryForm.empName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提交时间" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "default-time": ["00:00:00", "23:59:59"],
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                on: { change: _vm.changedate },
                model: {
                  value: _vm.value1,
                  callback: function ($$v) {
                    _vm.value1 = $$v
                  },
                  expression: "value1",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客情人员" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入客情人员" },
                model: {
                  value: _vm.queryForm.qualityName,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "qualityName", $$v)
                  },
                  expression: "queryForm.qualityName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "处理状态" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择客情状态" },
                  model: {
                    value: _vm.queryForm.afterType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "afterType", $$v)
                    },
                    expression: "queryForm.afterType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "待分配", value: "1" } }),
                  _c("el-option", { attrs: { label: "待处理", value: "2" } }),
                  _c("el-option", { attrs: { label: "待审核", value: "3" } }),
                  _c("el-option", { attrs: { label: "已完成", value: "4" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fold,
                  expression: "!fold",
                },
              ],
              attrs: { label: "处理时间" },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  "default-time": ["00:00:00", "23:59:59"],
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                on: { change: _vm.changedates },
                model: {
                  value: _vm.value2,
                  callback: function ($$v) {
                    _vm.value2 = $$v
                  },
                  expression: "value2",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提交部门" } },
            [
              _c("el-cascader", {
                attrs: { options: _vm.dataSourceLists },
                on: { change: _vm.getcasc },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ node, data }) {
                      return [
                        _c("span", [_vm._v(_vm._s(data.label))]),
                        !node.isLeaf
                          ? _c("span", [
                              _vm._v("(" + _vm._s(data.children.length) + ")"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.queryForm.orgIds,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "orgIds", $$v)
                  },
                  expression: "queryForm.orgIds",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    "native-type": "submit",
                    type: "primary",
                  },
                  on: { click: _vm.searchquery },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-refresh-right",
                    "native-type": "submit",
                    type: "warning",
                  },
                  on: { click: _vm.clearcont },
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.handleFold } },
                [
                  _vm.fold
                    ? _c("span", [_vm._v("展开")])
                    : _c("span", [_vm._v("合并")]),
                  _c("vab-icon", {
                    staticClass: "vab-dropdown",
                    class: { "vab-dropdown-active": _vm.fold },
                    attrs: { icon: "arrow-up-s-line" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }