var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c("Search"),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tableData },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "会员名称", prop: "date" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "性别", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "年龄", prop: "address" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "预警类型", prop: "address" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "检测时间", prop: "address" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "预警结果", prop: "address" },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": 1,
          layout: "total, sizes, prev, pager, next, jumper",
          "page-size": _vm.form.size,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }