<template>
  <div class="comprehensive-table-container">
    <div class="add-btn"> 
      <el-button icon="el-icon-plus" type="primary" @click="$router.push('/workflow/healthPlan/healthAddplan')">新增</el-button>
    </div>
    <div class="table-Date">
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column align="center" label="计划名称" prop="planName" />
        <el-table-column align="center" label="计划类型" prop="planType" />
        <el-table-column align="center" label="计划时长" prop="planDay" />
        <el-table-column align="center" label="计划数量" prop="planNum" />
        <el-table-column align="center" label="创建时间" prop="createTime" />
        <el-table-column align="center" label="累计执行人数" prop="userNum" />
        <el-table-column align="center" label="操作" prop="address">
          <template #default="scope">
            <el-button type="text" @click="showMore(scope.row.id)">查看</el-button>
            <el-button type="text" @click="edit(scope.row, 'del')">删除</el-button>
            <el-button type="text" @click="edit(scope.row, 'edit')">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="1"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="form.size"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <el-dialog :before-close="handleClose" :title="cur == 'add' ? '新增类型' : '编辑类型'" :visible.sync="dialogVisible" width="80%">
      <span>
        <el-form ref="addForm" label-width="100px" :model="subForm" :rules="rules">
          <el-form-item label="类型名称" prop="planName">
            <el-input v-model="subForm.planName" placeholder="请输入类型名称" />
          </el-form-item>
          <el-form-item label="计划类型" prop="planType">
        <el-select v-model="subForm.planType" placeholder="请选择计划类型">
          <el-option v-for="(ym, index) in subForm.platype" :key="index" :label="ym.planName" :value="ym.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="计划图片" prop="planLogo">
        <el-image v-for="(ym, index) in subForm.planLogo" :key="index" :preview-src-list="subForm.planLogo" :src="ym" style="width: 100px; height: 100px" />
      </el-form-item>
      <el-form-item label="执行计划" prop="vitMealPlanOptPos" >
      <el-table class="plans-table" :data="subForm.vitMealPlanOptPos" style="width: 100%">
        <el-table-column align="center" label="天数" prop="optDay" width="180">
          <template slot-scope="scope">
            <span style="line-height: 30px">第{{  scope.row.optDay}}天</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="时间" prop="optTime" width="200">
          <template slot-scope="scope">
            <el-time-select
            v-for="(item, ind) in scope.row.optionVos"
              :key="ind"
              v-model="item.optTime"
              :picker-options="{
                start: '00:00',
                end: '23:59',
              }"
              placeholder="选择时间"
              :value="item"
            />
          </template>
        
        </el-table-column>
        <el-table-column align="center" label="预计执行时长(分)" prop="optNum" width="180">
          <template slot-scope="scope">
            <el-input v-for="(item, ind) in scope.row.optionVos" :key="ind" v-model="item.optNum" placeholder="请输入预计执行时长" />
          </template>
        </el-table-column>
        <el-table-column align="center" label="执行内容" prop="optInfo">
          <template slot-scope="scope">
            <div class="add-tablea">
              <el-input v-for="(item, ind) in scope.row.optionVos" :key="ind" v-model="item.optInfo" placeholder="请输入执行内容" />
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" prop="name" width="180">
          <template slot-scope="scope">
            <div v-for="(item, ind) in scope.row.vitMealPlanOptionPos" :key="ind" class="sc">
              <el-button type="text" @click="checkdel(ind, scope.$index)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
      <el-form-item label="计划说明" prop="vitMealPlanDesPos" >
      <el-table :data="subForm.vitMealPlanDesPos" style="width: 100%">
        <el-table-column align="center" label="标题" prop="desTitle" width="200">
          <template slot-scope="scope">
            <el-input v-model="scope.row.desTitle" placeholder="请输入标题" />
          </template>
        </el-table-column>
        <el-table-column align="center" label="标题内容" prop="desContent">
          <template slot-scope="scope">
            <el-input v-model="scope.row.desContent" placeholder="请输入标题内容" />
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
     
        </el-form>
        
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addSubform">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { vitMealPlanpage, vitMealPlanTypeadd,vitMealPlandetailsInfo } from '@/api/mall/health/healthplantype/index'
import {delObj ,editObj} from '@/api/mall/health/planDiet/index'
// import YmUpload from './components/index.vue'
export default {
  // components: {
  //   YmUpload,
  // },
  data() {
    return {
      total: 0,
      form: {
        size: 10,
        current: 1,
        isDelete: 0,
      },
      pages: 0,
      tableData: [],
      dialogVisible: false,
      subForm: {
        planStatus: 2,
        isDelete: 0,
      },
      
      rules: {
        planName: [{ required: true, message: '请输入类型名称', trigger: 'blur' }],
      },
      cur: 'add',
    }
  },
  created() {
    this.queryPage()
  },
 
  methods: {
    showMore(id) {
      this.$router.push('/workflow/healthPlan/projectDetails?date=' + id)
    },
    add() {
      this.dialogVisible = true
      this.cur = 'add'
      this.subForm = {
        planStatus: 2,
        isDelete: 0,
      }
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.queryPage()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.queryPage()
    },
    queryPage() {
      vitMealPlanpage({ ...this.form }).then((res) => {
        this.tableData = res.data.records
        this.total = res.data.total
        this.form.size = res.data.size
        this.form.current = res.data.current
      })
    },
    handleClose() {
      this.dialogVisible = false
      // this.$refs.addForm.resetFields()
      // this.subForm.resetFields()
    },
    addSubform() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (this.cur == 'add') {
            vitMealPlanTypeadd({ ...this.subForm }).then((res) => {
              if (res.code == 0 || res.status == 200) {
                this.$message.success('新增成功')
                this.handleClose()
                this.queryPage()
                this.$router.go(-1)
                
              }
            })
          } else {
            editObj({ ...this.subForm }).then((res) => {
              if (res.code == 0 || res.status == 200) {
                this.$message.success('编辑成功')
                this.handleClose()
                this.queryPage()
              }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    edit(row, Type) {
      if (Type == 'del') {
        delObj(row.id).then((res) => {
          if (res.code == 0 || res.status == 200) {
            this.$message.success('删除成功')
            this.queryPage()
          }
        })
      } else {
        vitMealPlandetailsInfo({id:row.id}).then((res) => {
          this.subForm = res.data;
        this.dialogVisible = true
        this.cur = 'edit'
        })
        
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.comprehensive-table-container {
  .add-btn {
    margin-bottom: 20px;
  }
  .table-Date {
    margin-bottom: 20px;
  }
}
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>
