<template>
  <div>
    <el-dialog :before-close="handleClose" title="编辑基础信息" :visible.sync="dialogVisible" width="50%">
      <span>
        <el-form ref="ruleForm" class="demo-ruleForm" label-width="130px" :model="ruleForm" :rules="rules">
          <el-form-item label="身份证号码" prop="idCard">
            <el-input v-model="ruleForm.idCard" placeholder="请输入身份证号码" />
          </el-form-item>
          <el-form-item label="民族" prop="familyName">
            <el-input v-model="ruleForm.familyName" placeholder="请输入民族" />
          </el-form-item>
          <el-form-item label="血型" prop="bloodType">
            <el-select v-model="ruleForm.bloodType" placeholder="请选择血型">
              <el-option v-for="(ym, index) in bloodTypes" :key="index" :label="ym.labelName" :value="ym.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="职业" prop="profession">
            <el-input v-model="ruleForm.profession" placeholder="请输入职业" />
          </el-form-item>
          <el-form-item label="婚姻状况" prop="marStatus">
            <el-select v-model="ruleForm.marStatus" placeholder="请选择婚姻状况">
              <el-option v-for="(ym, index) in marStatus" :key="index" :label="ym.labelName" :value="ym.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="电子邮件" prop="email">
            <el-input v-model="ruleForm.email" placeholder="请输入电子邮件" />
          </el-form-item>
          <el-form-item label="紧急联系人" prop="emerName">
            <el-input v-model="ruleForm.emerName" placeholder="请输入紧急联系人" />
          </el-form-item>
          <el-form-item label="紧急联系人电话" prop="emerPhone">
            <el-input v-model="ruleForm.emerPhone" placeholder="请输入紧急联系人电话" />
          </el-form-item>
          <el-form-item label="工作单位" prop="workUnit">
            <el-input v-model="ruleForm.workUnit" placeholder="请输入工作单位" />
          </el-form-item>
          <el-form-item label="所在地区" prop="name">
            <el-cascader
              v-model.trim="provinces"
              class="full-width"
              :options="areaSelectData"
              placeholder="请选择地址"
              :props="{
                children: 'children',
                label: 'areaName',
                value: 'areaName',
              }"
              size="large"
              style="display: flex; align-items: center; justify-content: center; width: 215px; height: 32px"
              @change="addres"
            />
          </el-form-item>
          <el-form-item label="详细地址" prop="addressDetail">
            <el-input v-model="ruleForm.addressDetail" placeholder="请输入详细地址" />
          </el-form-item>
          <el-form-item label="" />
          <el-form-item label="备注" prop="remark">
            <el-input v-model="ruleForm.remark" placeholder="请输入备注" type="textarea" />
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAreaThree } from '@/api/mall/common'
import { userFitnessedit } from '@/api/mall/users/usermore'
export default {
  props: {
    show: {
      type: Boolean,
      default: () => {},
    },
    userlist: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: this.userlist,
      provinces: [],
      areaSelectData: [],
      sexoptions: [
        {
          id: 1,
          labelName: '男',
        },
        {
          id: 2,
          labelName: '女',
        },
      ],
      bloodTypes: [
        {
          id: 1,
          labelName: 'A型',
        },
        {
          id: 2,
          labelName: 'B型',
        },
        {
          id: 3,
          labelName: 'O型',
        },
        {
          id: 4,
          labelName: 'AB型',
        },
        {
          id: 5,
          labelName: 'RH型',
        },
      ],
      marStatus: [
        {
          id: 1,
          labelName: '已婚',
        },
        {
          id: 2,
          labelName: '未婚',
        },
      ],
      rules:{
        emerPhone: [
            { required: true, message: '请输入正确的手机号', trigger: 'change' },
            { 
    pattern: /^1[0-9]{10}$/, 
    message: '请输入正确的手机号格式', 
    trigger: 'change' 
  }
          ],
    }
    }
    
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue != oldValue) {
        this.dialogVisible = newValue
      }
    },
    userlist(newValue, oldValue) {
      if (newValue != oldValue) {
        this.ruleForm = newValue
      }
    },
  },
  created() {
    this.getAreaThree()
    if (this.userlist) {
      this.provinces[0] = this.ruleForm.province
      this.provinces[1] = this.ruleForm.city
      this.provinces[2] = this.ruleForm.county
    }
  },
  methods: {
    submit() {
    
      userFitnessedit({ ...this.ruleForm }).then((res) => {
        if (res.status == '200') {
          this.$message.success('编辑成功')
          this.$EventBus.$emit('repload')
          this.handleClose()
        }
      })
    },
    async getAreaThree() {
      await getAreaThree()
        .then((res) => {
          if (res.data) {
            this.areaSelectData = res.data
          }
        })
        .catch(() => {})
    },
    handleClose() {
      this.$refs.ruleForm.resetFields()
      this.$emit('cloeeditdia', false)
      this.$EventBus.$emit('repload')
    },
    addres(e) {
      if (e) {
        this.ruleForm.province = e[0]
        this.ruleForm.city = e[1]
        this.ruleForm.county = e[2]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
  .el-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .el-select,
  .el-date-editor,
  .input__inner {
    width: 100%;
  }
}
</style>