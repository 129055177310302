<template>
  <div class="comprehensive-table-container">
    <div class="tab">
      <el-button class="addClick" type="primary" @click='addClick'>新增</el-button>
      <el-table border :data="tableData">
        <el-table-column align="center" label="全称" prop="fullname" show-overflow-tooltip />
        <el-table-column align="center" label="简称" prop="shortname" show-overflow-tooltip />
        <el-table-column align="center" label="创建时间" prop="createTime" show-overflow-tooltip />
        <el-table-column align="center" fixed="right" label="操作" prop="rateValue">
          <template #default="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog :title="addorEditType ? '新增' : '编辑'" :visible.sync="dialogFormVisible">
        <el-form ref="ruleForm" class="demo-ruleForm" label-width="100px" :model="ruleForm" :rules="rules">
          <el-form-item label="全称" prop="fullname">
            <el-input v-model="ruleForm.fullname" autocomplete="off" />
          </el-form-item>
          <el-form-item label="简称" prop="shortname">
            <el-input v-model="ruleForm.shortname" autocomplete="off" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleCancel('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="handleOk('ruleForm')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getCompany, addCompany, updateCompany, deleteCompany } from '@/api/mall/admin/companyManagement'
export default {
  data() {
    return {
      tableData: [], // 列表数据
      dialogFormVisible: false, // 弹框
      ruleForm: { // 表单
        fullname: "",
        shortname: ""
      },
      companyOpitons: [], //简称数据
      addorEditType: false, // 编辑/新增
      formInline: {
        size: 10,
        current: 1,
      },
      rules: {
        fullname: [ // 全称
          { required: true, message: '请输入全称', trigger: ['change', 'blur'] }
        ],
        shortname: [ // 简称
          { required: true, message: '请输入简称', trigger: ['change', 'blur'] }
        ],
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      // 列表数据
      getCompany().then((reslist) => {
        this.tableData = reslist
      })
    },
    // 新增
    addClick() {
      this.dialogFormVisible = true
      this.addorEditType = true
      this.ruleForm.fullname = ""
      this.ruleForm.shortname = ""
      //   console.log('新增')
    },
    // 删除
    handleDelete(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteCompany({ id: row.id }).then((res) => {
          getCompany().then((reslist) => {
            this.tableData = reslist
          })
          this.$message({
            type: res ? 'success' : 'error',
            message: res ? '删除成功' : '删除失败'
          });
        })
        getCompany().then((reslist) => {
          this.tableData = reslist
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 编辑
    handleEdit(row) {
      this.ruleForm = row
      this.dialogFormVisible = true
      this.addorEditType = false
    },
    // // 确定
    handleOk(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false
          const data = {
            status: 0,
            fullname: this.ruleForm.fullname,
            shortname: this.ruleForm.shortname
          }
          console.log(data, 'data')
          if (this.addorEditType) {
            addCompany(data).then((res) => {
              if (res) {
                getCompany().then((reslist) => {
                  this.tableData = reslist
                })
              }
            })
          }
          else {
            data.id = this.ruleForm.id
            updateCompany(data).then((res) => {
              this.$message({
                type: res ? 'success' : 'error',
                message: res ? '修改成功' : '修改失败'
              });
              getCompany().then((reslist) => {
                this.tableData = reslist
              })
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消
    handleCancel(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false
      getCompany().then((reslist) => {
        this.tableData = reslist
      })
    }
  }
}
</script>

<style scoped>
.addClick {
  margin-bottom: 10px;
}
</style>