var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OrderDetail cy-container" },
    [
      _c(
        "el-row",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            background: "#f0f0f0",
            padding: "20px",
          },
        },
        [_c("el-col", { staticClass: "fs16" }, [_vm._v("主题详情")])],
        1
      ),
      _c(
        "div",
        { staticClass: "refund" },
        [
          _c(
            "el-row",
            { staticClass: "mt30" },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticClass: "themeTitel" }, [
                  _vm._v("活动主题："),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.details.actName))]),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticClass: "themeTitel" }, [
                  _vm._v("主题时间："),
                ]),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("formatDate")(_vm.details.startTime)) +
                      "-" +
                      _vm._s(_vm._f("formatDate")(_vm.details.endTime)) +
                      " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "mt30" },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticClass: "themeTitel" }, [
                  _vm._v("发布时间："),
                ]),
                _vm.details.publishTime
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatDate")(_vm.details.publishTime)
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("formatDate")(_vm.details.updateTime))
                      ),
                    ]),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticClass: "themeTitel" }, [
                  _vm._v("参与平台："),
                ]),
                _vm.details.actPlatform
                  ? _c(
                      "span",
                      _vm._l(
                        _vm.details.actPlatform.split(","),
                        function (item, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatterActPlatform(item) + ",") +
                                " "
                            ),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _c("span"),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "mt30" },
            [
              _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "themeTitel" }, [
                    _vm._v("主题封面："),
                  ]),
                  _c("img", {
                    attrs: {
                      alt: "",
                      src: _vm.details.coverPicture,
                      width: "100px",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleImgClick(_vm.details.coverPicture)
                      },
                    },
                  }),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "themeTitel" }, [
                    _vm._v("主题背景："),
                  ]),
                  _c("img", {
                    attrs: {
                      alt: "",
                      src: _vm.details.backgPicture,
                      width: "100px",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleImgClick(_vm.details.backgPicture)
                      },
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "mt30" },
            [
              _c("el-col", { staticClass: "mb20" }, [
                _c("span", { staticClass: "themeTitel" }, [
                  _vm._v("活动列表："),
                ]),
              ]),
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    data: _vm.actList,
                    "default-expand-all": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      formatter: _vm.formatterActType,
                      label: "活动类型",
                      prop: "actType",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "实例名称",
                      prop: "instName",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "实例封面",
                      prop: "instLogo",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [
                              _c("img", {
                                attrs: {
                                  alt: "",
                                  src: row.instLogo,
                                  width: "50px",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleImgClick(row.instLogo)
                                  },
                                },
                              }),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "活动时间",
                      prop: "startDate",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [
                              _c("div", [
                                _vm._v(
                                  "开始：" +
                                    _vm._s(_vm._f("formatDate")(row.startTime))
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "结束：" +
                                    _vm._s(_vm._f("formatDate")(row.endTime))
                                ),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "发布时间",
                      prop: "publishTime",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.publishTime
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDate")(row.publishTime)
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(_vm._f("formatDate")(row.updateTime))
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      formatter: _vm.formatterActState,
                      label: "状态",
                      prop: "instStatus",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "排序",
                      prop: "instSort",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.forms.currentPage,
                  layout: _vm.layout,
                  "page-size": _vm.forms.pageSize,
                  total: _vm.total,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.imgDialog },
          on: {
            "update:visible": function ($event) {
              _vm.imgDialog = $event
            },
          },
        },
        [_c("img", { attrs: { src: _vm.imagesUrl, width: "100%" } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }