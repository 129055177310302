<template>
  <div>
    <div class="thead_tree">
      <el-timeline>
        <el-timeline-item v-for="(ym,index) in list" :key="index" placement="top" :timestamp="ym.createTime">
          <el-card>
            <h4><span style="color: #1786ff;">{{ ym.realName }}</span>  {{ym.title}}</h4>
            <p>{{ ym.remark }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
    <!-- <div v-if="showhide!=4" class="t-body">
      <el-form :model="subform">
        <el-form-item label="描述备注">
          <el-input v-model="subform.remark" placeholder="请输入内容" type="textarea"/>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="submit(3)">提交</el-button>
        </el-form-item>
      </el-form>
    </div> -->
  </div>
</template>

<script>
import {uuUserSugarRecordAfterLogqueryList,uuUserSugarRecordAfterLogadd} from '@/api/mall/aftersaledata/index'

export default {
  props:{
    showhide:{
      type:Number,
      default:()=>{}
    }
  },
  data(){
    return{
      list:[],
      subform:{},
      listID:null,
      showhan:null
    }
  },
  created(){
    if(this.$route.query.date){
      this.listID=JSON.parse(this.$route.query.date)
      this.uuUserSugarRecordAfterLogqueryList()
    }
  },
  methods:{
    submit(e){
      uuUserSugarRecordAfterLogadd({remark:this.subform.remark,afterId:this.listID.id,type:e,afterState:this.listID.afterState}).then(res=>{
        if(res.status==200){
          this.$message.success("提交成功")
        }
        this.subform={}
        this.uuUserSugarRecordAfterLogqueryList()
      })
    },
    uuUserSugarRecordAfterLogqueryList(){
      uuUserSugarRecordAfterLogqueryList({afterId:this.listID.id}).then(res=>{
        this.list=res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.thead_tree{
  height: 300px;
  overflow-y: scroll;
  margin-bottom: 25px;
  ::v-deep{
    .el-timeline-item{
      margin-left: -40px;
    }
  }
}

</style>