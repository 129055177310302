var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        title: "查看",
        visible: _vm.dialogVisible,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-descriptions",
            { staticClass: "margin-top", attrs: { border: "", column: 2 } },
            [
              _c("el-descriptions-item", { attrs: { label: "昵称：" } }, [
                _vm._v(_vm._s(_vm.date.nickName)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "店铺：" } }, [
                _vm._v("18100000000"),
              ]),
              _c("el-descriptions-item", { attrs: { label: "账号：" } }, [
                _vm._v(_vm._s(_vm.date.username)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "手机号：" } }, [
                _vm._v(_vm._s(_vm.date.phone)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "邮箱：" } }, [
                _vm._v(_vm._s(_vm.date.email)),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "头像：" } },
                [
                  _c("el-image", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: { src: _vm.date.avatar },
                  }),
                ],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "创建时间" } }, [
                _vm._v(_vm._s(_vm.date.createTime)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }