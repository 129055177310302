var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "thead" }, [
    _c(
      "div",
      { staticClass: "center" },
      _vm._l(_vm.nav, function (ym, index) {
        return _c(
          "div",
          {
            key: index,
            class: _vm.tops == index + 1 ? "item active-item" : "item",
            on: {
              click: function ($event) {
                return _vm.navcheck(index)
              },
            },
          },
          [_c("vab-icon", { attrs: { icon: ym.icon } })],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "save_canvas" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.save } },
          [_vm._v("保存画布")]
        ),
        _c("el-button", { attrs: { type: "" }, on: { click: _vm.preview } }, [
          _vm._v("预览"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }