<template>
  <div class="comprehensive-table-container">
    <div class="search" style="margin-bottom: 10px">
      <el-form class="demo-form-inline" :inline="true" :model="formSearch">
        <el-form-item label="客户姓名：">
          <el-input v-model.trim="formSearch.userName" placeholder="请输入客户姓名" />
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model.trim="formSearch.phone" placeholder="请输入电话" />
        </el-form-item>
        <el-form-item label="预存时间：">
          <el-date-picker
            v-model="timeData"
            :default-time="['00:00:00', '23:59:59']"
            end-placeholder="结束日期"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="timeChange"
          />
        </el-form-item>
        <el-form-item label="预存状态：">
          <el-select v-model="formSearch.preStatus" placeholder="请选择">
            <el-option v-for="item in preStatusOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="预存账户：">
          <el-input v-model.trim="formSearch.bankNumber" placeholder="请输入预存账户" />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="onSubmit">搜索</el-button>
          <el-button icon="el-icon-refresh" type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 导出 -->
    <el-button type="primary" @click="handleExport">导出</el-button>
    <el-tabs v-model="activeName">
      <el-tab-pane label="待处理" name="0">
        <el-table ref="multipleTable" border :data="tableData" default-expand-all fit highlight-current-row max-height="300" style="margin-top: 30px">
          <el-table-column align="center" label="姓名" prop="userName" show-overflow-tooltip width="150" />
          <el-table-column align="center" label="手机号" prop="phone" show-overflow-tooltip width="150" />
          <el-table-column align="center" label="交易类型" prop="optType" width="100px">
            <template #default="scope">
              <div v-if="scope.row.optType == 0">汇入</div>
              <div v-if="scope.row.optType == 1">支出</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="交易金额" prop="logAmount" width="100px" />
          <el-table-column align="center" label="提交人" prop="commitSysName" width="100px" />
          <el-table-column align="center" label="审批人" prop="sysName" width="100px" />
          <el-table-column align="center" label="交易渠道" width="120px">
            <template #default="scope">
              <!-- 交易类型 1:微信零钱 2：支付宝 3：银行卡 -->
              <div v-if="scope.row.bussType == 1">微信</div>
              <div v-if="scope.row.bussType == 2">支付宝</div>
              <div v-if="scope.row.bussType == 3">银行卡</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="账号信息" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.bankName }} {{ scope.row.bankNumber != null ? '(' + scope.row.bankNumber + ')' : '' }}</template>
          </el-table-column>
          <el-table-column align="center" label="交易备注" prop="content" show-overflow-tooltip />
          <el-table-column align="center" label="交易状态" prop="preStatus" width="160px">
            <template #default="scope">
              <el-tag v-if="scope.row.preStatus == 1" type="succes">已到款</el-tag>
              <el-tag v-if="scope.row.preStatus == 2" type="info">未到款</el-tag>
              <el-tag v-if="scope.row.preStatus == 3" type="warning">已经取消</el-tag>
              <el-tag v-if="scope.row.preStatus == 4">已关联</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="收款时间" prop="processTime" show-overflow-tooltip />
          <el-table-column align="center" fixed="right" label="操作" prop="rateValue">
            <template #default="scope">
              <el-button type="text" @click="recoveryClick(scope.row)">驳回</el-button>
              <el-button type="text" @click="distributionClick(scope.row)">批审</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :current-page="1"
          layout="total, sizes, prev, pager, next, jumper"
          :page-size="formInline.size"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-tab-pane>
      <el-tab-pane label="已处理" name="1">
        <el-table ref="multipleTable" border :data="DoneTasktableData" default-expand-all fit highlight-current-row max-height="300" style="margin-top: 30px">
          <el-table-column align="center" label="姓名" prop="userName" show-overflow-tooltip width="150" />
          <el-table-column align="center" label="手机号" prop="phone" show-overflow-tooltip width="150" />
          <el-table-column align="center" label="交易类型" prop="optType" width="100px">
            <template #default="scope">
              <div v-if="scope.row.optType == 0">汇入</div>
              <div v-if="scope.row.optType == 1">支出</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="交易金额" prop="logAmount" width="100px" />
          <el-table-column align="center" label="提交人" prop="commitSysName" width="100px" />
          <el-table-column align="center" label="审批人" prop="sysName" width="100px" />
          <el-table-column align="center" label="审批状态" width="120px">
            <template #default="scope">
              <!-- 审批状态 1:提交 批准 驳回 -->
              <div v-if="scope.row.taskStatus == 1">提交</div>
              <div v-if="scope.row.taskStatus == 2">批准</div>
              <div v-if="scope.row.taskStatus == 3">驳回</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="交易渠道" width="120px">
            <template #default="scope">
              <!-- 交易类型 1:微信零钱 2：支付宝 3：银行卡 -->
              <div v-if="scope.row.bussType == 1">微信</div>
              <div v-if="scope.row.bussType == 2">支付宝</div>
              <div v-if="scope.row.bussType == 3">银行卡</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="账号信息" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.bankName }} {{ scope.row.bankNumber != null ? '(' + scope.row.bankNumber + ')' : '' }}</template>
          </el-table-column>
          <el-table-column align="center" label="交易备注" prop="content" show-overflow-tooltip />
          <el-table-column align="center" label="交易状态" prop="preStatus" width="160px">
            <template #default="scope">
              <el-tag v-if="scope.row.preStatus == 1" type="succes">已到款</el-tag>
              <el-tag v-if="scope.row.preStatus == 2" type="info">未到款</el-tag>
              <el-tag v-if="scope.row.preStatus == 3" type="warning">已经取消</el-tag>
              <el-tag v-if="scope.row.preStatus == 4">已关联</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="收款时间" prop="processTime" show-overflow-tooltip />
        </el-table>
        <el-pagination
          background
          :current-page="1"
          layout="total, sizes, prev, pager, next, jumper"
          :page-size="formInlineDoneTask.size"
          :total="DoneTasktotal"
          @current-change="handleCurrentChangeDone"
          @size-change="handleSizeChangeDone"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { merRecordPrestoreList, DoneTaskMerRecordPrestorePage, getApproveTaskMer, getRejectTaskMer, Export } from '@/api/mall/setting/stplabel'
export default {
  data() {
    return {
      activeName: '0', // tab默认待处理
      dialogVisible: false,
      dataSourceLists: null,
      templateSelection: '',
      userId: '',
      none: '',
      timeData: [],
      preStatusOptions: [
        {
          value: '1',
          label: '已到账',
        },
        {
          value: '2',
          label: '未到账',
        },
      ],
      formSearch: {
        userName: '', // 姓名
        phone: '', // 电话
        processStartTime: '', // 开始时间
        processEndTime: '', // 结束时间
        preStatus: '', // 预存状态
        bankNumber: '', // 预存账户
      },
      formInline: {
        size: 10,
        current: 1,
      },
      formInlineDoneTask: {
        size: 10,
        current: 1,
      },
      total: 0,
      DoneTasktotal: 0,
      tableData: [],
      DoneTasktableData: [],
      showData: [],
      gridData: [], // 员工管理 数据
      id: '',
    }
  },
  watch: {},
  created() {
    this.getUserList()
    this.getUserListDone()
  },
  methods: {
    handleExport() {
      const data = {
        taskStatusArray: this.activeName === '0' ? [1] : [2, 3],
      }
      if (this.activeName === '0') {
        if (this.tableData?.length) {
          Export({ ...data, ...this.formInline }).then((res) => {
            this.saveBlod(res)
          })
        } else {
          this.$message.error('没有可导出的数据')
        }
      } else {
        if (this.DoneTasktableData?.length) {
          Export({ ...data, ...this.formInlineDoneTask }).then((res) => {
            this.saveBlod(res)
          })
        } else {
          this.$message.error('没有可导出的数据')
        }
      }
    },
    saveBlod(res) {
      console.log(res, 'res')
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      const fileName = '预存款充值审核.xls' // 预存款充值审核-已处理.xls
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const elink = document.createElement('a')
        elink.download = fileName
        elink.href = URL.createObjectURL(blob)
        elink.style.display = 'none'
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName)
      }
    },
    timeChange() {
      this.formSearch.processStartTime = new Date(this.timeData[0]).getTime()
      this.formSearch.processEndTime = new Date(this.timeData[1]).getTime()
    },
    showMore(row) {
      this.dialogVisible = true
      this.showData.push(row)
    },
    closeDialog() {
      this.dialogVisible = false
      this.showData = [] // 清空showData数组
    },
    reset() {
      this.formSearch.userName = ''
      this.formSearch.phone = ''
      this.formSearch.processStartTime = ''
      this.formSearch.processEndTime = ''
      this.formSearch.preStatus = ''
      this.formSearch.bankNumber = ''
      this.timeData = []
      this.formInline.current = 1
      this.formInlineDoneTask.current = 1

      if (this.activeName === '0') {
        this.formInline = {
          size: this.formInline.size, // 复制 size 的值
          current: this.formInline.current, // 复制 current 的值
        }
        this.getUserList()
      } else {
        this.formInlineDoneTask = {
          size: this.formInlineDoneTask.size, // 复制 size 的值
          current: this.formInlineDoneTask.current, // 复制 current 的值
        }
        this.getUserListDone()
      }
    },
    onSubmit() {
      if (this.activeName === '0') {
        this.formInline = {
          size: this.formInline.size, // 复制 size 的值
          current: this.formInline.current, // 复制 current 的值
          ...this.formSearch,
        }
        this.getUserList()
      } else {
        this.formInlineDoneTask = {
          size: this.formInlineDoneTask.size, // 复制 size 的值
          current: this.formInlineDoneTask.current, // 复制 current 的值
          ...this.formSearch,
        }
        this.getUserListDone()
      }
    },
    handleCurrentChange(e) {
      this.formInline.current = e
      this.getUserList()
    },
    handleSizeChange(e) {
      this.formInline.size = e
      this.getUserList()
    },
    handleCurrentChangeDone(e) {
      this.formInlineDoneTask.current = e
      this.getUserListDone()
    },
    handleSizeChangeDone(e) {
      this.formInlineDoneTask.size = e
      this.getUserListDone()
    },
    getUserList() {
      console.log(this.formInline, 'this.formInline')
      merRecordPrestoreList({ ...this.formInline }).then((res) => {
        this.tableData = res.data.records
        this.formInline.size = res.data.size
        this.formInline.current = res.data.current
        this.total = res.data.total
      })
    },
    getUserListDone() {
      DoneTaskMerRecordPrestorePage({ ...this.formInlineDoneTask }).then((res) => {
        this.DoneTasktableData = res.data.records
        this.formInlineDoneTask.size = res.data.size
        this.formInlineDoneTask.current = res.data.current
        this.DoneTasktotal = res.data.total
      })
    },
    // 驳回
    recoveryClick(e) {
      this.$confirm('确定要驳回此条数据吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          getRejectTaskMer({ id: e.id, recordId: e.recordId }).then((res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.reset()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消驳回',
          })
        })
    },
    // 批审
    distributionClick(e) {
      console.log(e, 'eee')
      this.$confirm('确定要批审此条数据吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          getApproveTaskMer({ id: e.id }).then((res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.reset()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消批审',
          })
        })
    },
  },
}
</script>

<style></style>
