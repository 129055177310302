import { render, staticRenderFns } from "./tagdialo.vue?vue&type=template&id=f3505544&scoped=true"
import script from "./tagdialo.vue?vue&type=script&lang=js"
export * from "./tagdialo.vue?vue&type=script&lang=js"
import style0 from "./tagdialo.vue?vue&type=style&index=0&id=f3505544&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3505544",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\健康约-后管-前端\\health-front-backend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f3505544')) {
      api.createRecord('f3505544', component.options)
    } else {
      api.reload('f3505544', component.options)
    }
    module.hot.accept("./tagdialo.vue?vue&type=template&id=f3505544&scoped=true", function () {
      api.rerender('f3505544', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mall/user/userDetails/components/Basic-information/components/tagdialo.vue"
export default component.exports