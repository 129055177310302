<template>
  <el-dialog append-to-body :before-close="claer" title="客情部门" :visible.sync="appointDialog" width="80%">
    <div class="container">
      <el-table ref="myTable" border :data="tablist" max-height="300" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />

        <el-table-column align="center" label="序号" type="index" width="55" />
        <el-table-column align="center" label="订单编号" prop="orderNo" width="190" />

        <el-table-column align="center" label="下单用户" prop="nickName" width="140" />
        <el-table-column align="center" label="订单金额" prop="paymentPrice" width="100" />
        <el-table-column align="center" label="下单员工" prop="profile" show-overflow-tooltip width="140">
          <template #default="scope">{{ scope.row.realName }}{{ scope.row.profile == '' ? '' : '(' + scope.row.profile + ')' }}</template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="statusDesc" show-overflow-tooltip width="80" />

        <el-table-column align="center" label="所购产品" prop="product" show-overflow-tooltip />
        <el-table-column align="center" label="下单地址" prop="address" show-overflow-tooltip />

        <!-- 状态 1-待质检 2-已通过 3-驳回 -->
        <el-table-column align="center" label="质检审核" prop="quaStateDesc" width="80">
          <template #default="{ row }">
            <div>
              <el-tag v-if="row.quaState == 1" type="succes">待质检</el-tag>
              <el-tag v-if="row.quaState == 2" type="danger">已通过</el-tag>
              <el-tag v-if="row.quaState == 3" type="info">已驳回</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="质检信息" prop="quaContent" show-overflow-tooltip width="80" />
        <el-table-column align="center" label="预存金额" prop="presAmount" width="80" />
        <el-table-column align="center" label="订单日期" prop="createTime" width="200" />
      </el-table>
      <el-pagination background :current-page="1" layout="total, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="clear">取 消</el-button>
      <el-button type="primary" @click="sure">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { orderrecord } from '@/api/mall/health/casemy/index'
export default {
  beforeRouteEnter(next) {
    next((vm) => {
      vm.clear()
    })
  },
  props: {
    userlist: {
      type: Object,
      default: () => {},
    },
    showtablist: {
      type: Boolean,
      default: () => {},
    },
  },
  data() {
    return {
      total: 0,
      tablist: [],
      appointDialog: false,
      showlist: false,
      showtblist: null,
      selecuser: null, //用户选中id
      form: {
        size: 10,
        current: 1,
        recordId: this.userlist.id,
      },
    }
  },
  watch: {
    userlist(newValue, oldValue) {
      if (newValue != oldValue) {
        this.form.recordId = newValue.id
        this.uuUserSugarRecordLog()
      }
    },
    showtablist(newValue, oldValue) {
      if (newValue != oldValue) {
        this.appointDialog = newValue
      }
    },
  },
  created() {
    console.log(this.sound)
    this.tablist = []
    this.uuUserSugarRecordLog()
  },

  methods: {
    closeshow() {
      this.showlist = false
    },
    clear() {
      // if(!this.selecuser){
      //   this.$message.error('请关联订单！')

      // }
      this.$emit('closeshow', false)
      if (this.$refs.myTable) {
        this.$refs.myTable.clearSelection()
      }
    },

    claer() {
      this.$emit('closeshow', false)
      this.$refs.myTable.clearSelection()
    },
    handleSelectionChange(val) {
      console.log(val)
      if (val.length > 1) {
        const lastSelectedRow = val[val.length - 1]
        this.$refs.myTable.clearSelection()
        this.$refs.myTable.toggleRowSelection(lastSelectedRow)
        if (Array.isArray(lastSelectedRow)) {
          this.selecuser = [...lastSelectedRow]
        }
      } else if (val.length == 1) {
        console.log(val, '123456')
        this.selecuser = val
      } else {
        this.selecuser = ''
      }
    },
    sure() {
      if (this.selecuser) {
        this.$message.success('您已关联订单！')
        this.$emit('passUserid', this.selecuser)
        console.log(this.selecuser, ' this.selecuser')
        this.$emit('closeshow', false)
      } else {
        this.selecuser = ''
        this.$message.error('请关联订单！')
      }
      this.clear()
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.uuUserSugarRecordLog()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.uuUserSugarRecordLog()
    },
    uuUserSugarRecordLog() {
      orderrecord({ ...this.form }).then((res) => {
        this.total = res.data.total
        this.form.size = res.data.size
        this.form.current = res.data.current
        this.tablist = res.data.records
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>