<template>
  <div class="comprehensive-table-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部商品" name="1" />
      <el-tab-pane label="待审核商品" name="2" />
      <el-tab-pane label="审核通过商品" name="3" />
      <el-tab-pane label="审核不通过商品" name="4" />
    </el-tabs>
    <Search @datelist="rowinfo" />
    <div class="m-b">
      <el-button class="el-icon-plus" type="primary" @click="handleAdd">新增</el-button>
      <el-button class="el-icon-top" type="" @click="Batchlisting(1)">批量上架</el-button>
      <el-button class="el-icon-bottom" type="" @click="Batchlisting(0)">批量下架</el-button>
      <el-button class="el-icon-check" type="" @click="verify(1)">批量审核</el-button>
      <el-button class="el-icon-close" type="" @click="verify(2)">批量拒绝</el-button>
    </div>
    <el-table v-loading.fullscreen.lock="loadings" border :data="data" @selection-change="handselect">
      <el-table-column align="center" fixed="left" type="selection" width="55" />
      <el-table-column align="center" label="ID" prop="id" width="80" />
      <el-table-column align="center" label="店铺" prop="shopName" />
      <el-table-column align="center" label="商品名称" prop="name" show-overflow-tooltip />
      <el-table-column align="center" label="商品图片" prop="picUrls" show-overflow-tooltip>
        <template #default="scope">
          <el-image :preview-src-list="scope.row.picUrls" :src="scope.row.picUrls[0]" style="width: 60px; height: 60px" />
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="商城类目" prop="categoryId" show-overflow-tooltip /> -->
      <el-table-column align="center" label="价格" prop="priceDown" show-overflow-tooltip>
        <template #default="scope">
          <div style="color: red">￥{{ scope.row.priceDown }}{{ scope.row.priceUp == scope.row.priceDown ? '' : '~￥' + scope.row.priceUp }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="商品编码" prop="spuCode" show-overflow-tooltip sortable />
      <el-table-column align="center" label="规格类型" prop="specType" show-overflow-tooltip>
        <template #default="scope">
          <div v-if="scope.row.specType == 0">统一规格</div>
          <div v-else>多规格</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="更新时间" prop="updateTime" show-overflow-tooltip sortable />
      <el-table-column align="center" label="是否上架" show-overflow-tooltip sortable>
        <template #default="scope">
          <el-switch v-model="scope.row.shelf" active-color="#13ce66" active-value="1" inactive-color="#ff4949" inactive-value="0" @change="shelf(scope.row.id, scope.row.shelf)" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="积分赠送" prop="pointsGiveSwitch" show-overflow-tooltip sortable>
        <template #default="scope">
          <div v-if="scope.row.pointsGiveSwitch == 1">开启</div>
          <div v-else>未开启</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="审核状态" prop="verifyStatus" show-overflow-tooltip>
        <template #default="scope">
          <div>
            <el-tag v-if="scope.row.verifyStatus == 0" size="small">审核中</el-tag>
            <el-tag v-if="scope.row.verifyStatus == 1" size="small" type="success">审核通过</el-tag>
            <el-tag v-if="scope.row.verifyStatus == 2" size="small" type="danger">审核不通过</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作">
        <template #default="scope">
          <el-button type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" @click="delobj(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="total, sizes, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
  </div>
</template>

<script>
import { getPage, putObjShelf, putObjVerify, delObj } from '@/api/mall/goodspu/goodspu'
import Search from './components/search.vue'
export default {
  components: {
    Search,
  },
  data() {
    return {
      activeName: '1',
      data: [],
      drawer: true,
      date: '',
      querFrom: { size: 10, current: 1, descs: 'create_time' },
      total: 0,
      loadings: true,
      selecteddata: [],
    }
  },
  created() {
    this.limit()
  },
  methods: {
    rowinfo(row) {
      if (row != false) {
        this.data = row.records
        this.total = row.total
        this.total = row.total
        this.querFrom.size = row.size
        this.querFrom.current = row.current
      } else {
        this.limit()
      }
    },
    limit() {
      const s = this.querFrom
      getPage({ ...s }).then((res) => {
        this.data = res.data.records
        this.total = res.data.total
        this.querFrom.size = res.data.size
        this.querFrom.current = res.data.current
        this.loadings = false
      })
    },
    shelf(e, number) {
      const s = {
        ids: e,
        shelf: number,
      }
      this.loadings = true
      putObjShelf({ ...s }).then(() => {
        this.limit()
        this.loadings = false
      })
    },
    handleSizeChange(e) {
      this.querFrom.size = e
      this.limit()
    },
    handleClick(e) {
      if (e == 0) {
        this.querFrom = {
          size: 10,
          current: 1,
          descs: 'create_time',
        }
      } else {
        this.querFrom = {
          size: 10,
          current: 1,
          descs: 'create_time',
          verifyStatus: e.name,
        }
      }
      this.limit()
    },
    handleAdd() {
      this.$router.replace('GoodsspuAdd')
    },
    edit(e) {
      this.date = e
      this.$router.push({
        path: 'goodsspuEdit',
        query: { date: e.id },
      })
    },
    handleCurrentChange(e) {
      this.querFrom.current = e
      this.limit()
    },
    Batchlisting(e) {
      if (this.selecteddata.length <= 0) {
        this.$message.error('请选择数据')
      } else {
        this.loadings = true
        const id = this.selecteddata.map((item) => item.id)
        const s = {
          ids: id.join(','),
          shelf: e,
        }
        putObjShelf({ ...s }).then(() => {
          this.limit()
        })
      }
    },
    handselect(e) {
      this.selecteddata = e
    },
    verify(e) {
      if (this.selecteddata.length <= 0) {
        this.$message.error('请选择数据')
      } else {
        this.$prompt('请输入审核明细', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.loadings = true
          const id = this.selecteddata.map((item) => item.id)
          putObjVerify({
            ids: id.join(','),
            verifyStatus: e,
            verifyDetail: '',
          }).then(() => {
            this.limit()
          })
        })
      }
    },
    delobj(e) {
      this.loadings = true
      delObj(e).then(() => {
        this.limit()
      })
    },
  },
}
</script>

<style></style>
