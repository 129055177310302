<template>
  <div class="comprehensive-table-container">
    <div class="ym_head_title" style="border-bottom: 1px dashed #e8e8e8">正在为该会员下单</div>
    <div class="user_info">
      <div class="user_picurl">
        <el-image :src="date.userinfo.headimgUrl" style="width: 40px; height: 40px; border-radius: 100%" />
      </div>
      <div class="user_msg">
        <div class="user_name" style="margin: 2px 0">姓名:{{ date.userinfo.nickName }}</div>
        <div class="user_name" style="margin: 2px 0">编号:{{ date.userinfo.userCode }}</div>
        <div class="userphone" style="margin: 2px 0">手机号:{{ date.userinfo.phone }}</div>
      </div>
    </div>
    <div class="ym_head_title" style="margin-top: 50px; margin-bottom: 30px">信息填写</div>
    <el-form ref="FormData" label-width="100px" :model="formdate" :rules="rules">
      <el-form-item label="商品:">
        <el-table border :data="date.checkout" row-class-name="rowClassName">
          <el-table-column align="center" label="商品信息">
            <template #default="scope">
              <div class="shop_info" style="display: flex; align-items: center">
                <el-image :src="scope.row.picUrl" style="width: 60px; height: 60px; margin-right: 8px" />
                <div class="shop_msg">
                  <div class="shop name" style="text-align: left">{{ scope.row.name }}</div>
                  <div class="shop name" style="text-align: left">{{ scope.row.specValueNames }}</div>
                  <div class="shop name" style="color: red; text-align: left">¥ {{ scope.row.salesPrice }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="库存:" prop="stock" />
          <el-table-column align="center" label="数量:" prop="quantity">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.quantity" placeholder="" @change="quanmun" />
            </template>
          </el-table-column>
          <el-table-column align="center" label="成交价:" prop="paymentPrice">
            <template slot-scope="scope">
              <div class="cel" style="width: 150px; margin: auto">
                <el-input v-model="scope.row.paymentPrice" placeholder="" @change="sales">
                  <template slot="append">元</template>
                </el-input>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="物流方式:">
        <el-radio-group v-model="formdate.deliveryWay">
          <el-radio label="1">快递配送</el-radio>
          <el-radio label="2">上门自提</el-radio>
          <!-- <el-radio label="3">同城配送</el-radio> -->
        </el-radio-group>
      </el-form-item>
      <div v-if="formdate.deliveryWay == 1">
        <el-form-item label="收货人:" prop="userName">
          <el-input v-model="formdate.userName" placeholder="请输入 收货人" style="width: 300px" />
        </el-form-item>
        <el-form-item label="手机号:" prop="telNum">
          <el-input v-model="formdate.telNum" placeholder="请输入 手机号" style="width: 300px" />
        </el-form-item>
        <el-form-item label="地区:" prop="province">
          <el-cascader
            v-model.trim="provinces"
            class="full-width"
            :options="areaSelectData"
            placeholder="请选择地址"
            :props="{
              children: 'children',
              label: 'areaName',
              value: 'areaName',
            }"
            size="large"
            style="width: 80%"
            @change="addres"
          />
        </el-form-item>
        <el-form-item label="详细地址:" prop="address">
          <div class="ro" style="display: flex">
            <el-input v-model="formdate.address" placeholder="请输入 详细地址" style="width: 100%" type="textarea" />
            <div class="ro_btn" @click="hide = true">选择地址</div>
          </div>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item label="联系人:" prop="userName">
          <el-input v-model="formdate.userName" placeholder="请输入 联系人" style="width: 300px" />
        </el-form-item>
        <el-form-item label="联系电话:" prop="telNum">
          <el-input v-model="formdate.telNum" placeholder="请输入 联系电话" style="width: 300px" />
        </el-form-item>
        <el-form-item label="地区:" prop="province">
          <el-cascader
            v-model.trim="provinces"
            class="full-width"
            :options="areaSelectData"
            placeholder="请选择地址"
            :props="{
              children: 'children',
              label: 'areaName',
              value: 'areaName',
            }"
            size="large"
            style="width: 80%"
            @change="addres"
          />
        </el-form-item>
        <el-form-item label="详细地址:" prop="address">
          <div class="ro" style="display: flex">
            <el-input v-model="formdate.address" placeholder="请输入 详细地址" style="width: 100%" type="textarea" />
          </div>
        </el-form-item>
      </div>
    </el-form>
    <div class="ym_head_title" style="margin-top: 50px; margin-bottom: 30px">价格明细</div>
    <el-form ref="FormData" label-width="100px" :model="skus">
      <el-form-item label="运费:">
        <el-input v-model="freightPrice" placeholder="请输入运费" style="width: 190px" @change="freightPric">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="明细:">
        <template #default>
          <div class="item">商品金额 ￥{{ paymentPrice }}</div>
          <div class="item">运费 ￥{{ freightPrice }}.00</div>
          <div class="item">
            合计
            <span style="color: #ff6633">￥{{ skus.paymentPrice }}.00</span>
          </div>
        </template>
      </el-form-item>
    </el-form>
    <div class="solt" style="display: flex; justify-content: center">
      <el-button type="">取消</el-button>
      <el-button type="primary" @click="settlement">结算</el-button>
    </div>
    <addressdialog :showhide="hide" :userid="date" @tranhdie="transhow" />
  </div>
</template>

<script>
  import { getAreaThree } from '@/api/mall/common'
  import { addCustomerOrder } from '@/api/mall/confirm/confirm'
  import addressdialog from './components/addressdialog'
  export default {
    components: { addressdialog },
    data() {
      return {
        areaSelectData: [],
        provinces: null,
        paymentPrice: 0,
        freightPrice: 0,
        hide: false,
        skus: {
          paymentPrice: 0,
        },
        formdate: {
          deliveryWay: '1', //配送方式1、普通快递；2、上门自提
          // paymentType: '1', //支付方式1、货到付款；2、在线支付
          userMessage: '',
          appType: 'H5-PC',
          appId: '',
          orderType: '10', //订单类型（0、普通订单；1、砍价订单；2、拼团订单；3、秒杀订单 ,10 代客下单）
          status: '1', //订单状态1、待发货 2、待收货 3、确认收货/已完成 5、已关闭 10、拼团中
        },
        date: null,
        rules: {
          userName: [{ required: true, message: '请输入 收货人', trigger: 'blur' }],
          telNum: [{ required: true, message: '请输入 手机号', trigger: 'blur' }],
          province: [{ required: true, message: '请选择 地区', trigger: 'blur' }],
          address: [{ required: true, message: '请输入 详细地址', trigger: 'blur' }],
        },
      }
    },
    created() {
      this.getAreaThree()
      let date = this.$route.query.date
      console.log(date)
      // if (!date) {
      //   this.$router.push({ path: '*' })
      // } else {
      this.date = date
      // }
      this.payment()
      this.paynumber()
    },
    methods: {
      async getAreaThree() {
        await getAreaThree()
          .then((res) => {
            if (res.data) {
              this.areaSelectData = res.data
            }
          })
          .catch(() => {})
      },
      settlement() {
        this.formdate.skus = this.date.checkout
        this.formdate.userId = this.date.userinfo.id
        if (this.date.memberId != '') {
          this.formdate.memberId = this.date.userinfo.memberId
        }
        let list = []
        this.formdate.skus.forEach((item) => {
          console.log(item)
          let s = {
            spuId: item.spuId,
            skuId: item.id,
            shopInfo: {
              skuid: item.id,
              tenantId: item.tenantId,
              delFlag: item.delFlag,
              createTime: item.createTime,
              updateTime: item.updateTime,
              enable: item.enable,
              phone: item.phone,
              name: item.name,
              imgUrl: item.imgUrl,
              city: item.city,
              country: item.country,
              province: item.province,
              address: item.address,
              longitude: item.longitude,
              latitude: item.latitude,
              detail: item.detail,
              wxMchId: item.wxMchId,
              aliAuthToken: item.aliAuthToken,
              collectId: item.collectId,
              collectCount: item.collectCount,
              listGoodsSpu: item.listGoodsSpu,
            },
            quantity: item.quantity,
            salesPrice: item.salesPrice,
            spuName: item.spuName,
            memberId:item.userCode,//新增字段
            specInfo: item.specInfo,
            picUrl: item.picUrl,
            weight: item.weight,
            volume: item.volume,
            freightPrice: 0, //运费金额
            paymentPrice: item.paymentPrice,
            paymentPointsPrice2: item.paymentPointsPrice2,
            paymentPrice2: item.paymentPrice2,
            paymentPoints2: item.paymentPoints2,
          }
          list.push(s)
        })
        this.formdate.skus = list
        addCustomerOrder({ ...this.formdate }).then((res) => {
          if (res.code == 0) {
            this.$message.success('结算成功')
          } else {
            this.$message.error(res.msg)
          }
        })
    
        console.log(this.date.userinfo)
        console.log(this.formdate)
      },
      addres(e) {
        this.formdate.province = e[0]
        this.formdate.city = e[1]
        this.formdate.county = e[2]
      },
      quanmun() {
        this.payment()
        this.paynumber()
      },
      payment() {
        this.date.checkout.forEach((item, index) => {
          let num = item.quantity * item.salesPrice
          this.$set(this.date.checkout[index], 'paymentPrice', num)
        })
      },
      paynumber() {
        this.paymentPrice = this.date.checkout.reduce((total, item) => total + item.paymentPrice, 0)
        this.skus.paymentPrice = Number(this.paymentPrice) + Number(this.freightPrice)
      },
      sales(e) {
        if (e == '') {
          this.payment()
        }
      },
      transhow(row) {
        this.hide = false
        this.provinces = []
        const dete = row
        if (row) {
          console.log(row)
          console.log(this.provinces)
          this.provinces.push(dete.provinceName)
          this.provinces.push(dete.cityName)
          this.provinces.push(dete.countyName)
          this.$set(this.formdate, 'userName', dete.userName)
          this.$set(this.formdate, 'telNum', dete.telNum)
          this.$set(this.formdate, 'address', dete.detailInfo)
        }
      },
      freightPric() {
        this.payment()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .comprehensive-table-container {
    .ym_head_title {
      height: 40px;
      font-size: 14px;
      font-weight: bold;
      line-height: 40px;
      color: #333;
      border-bottom: 1px solid #e8e8e8;
    }
    .user_info {
      display: flex;
      align-items: center;
      width: 200px;
      height: 95px;
      margin: 10px 0;
      margin-top: 20px;
      font-size: 12px;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      .user_picurl {
        margin: 0 10px;
      }
    }
    .ro {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 80%;
    }
  }
  ::v-deep .ro_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    padding: 0 30px;
    background-color: rgb(245, 247, 250);
    border: 1px solid #e5e5e5;
    transition: ease 800ms;
    &:hover {
      color: white;
      cursor: pointer;
      background: #3782ff;
      border: transparent;
    }
  }
</style>
