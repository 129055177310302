<template>
  <div
    class="ym_container"
    :style="{
      padding: `0 ${controls[index].margin.both}px`,
      background: `${controls[index].pageBgColor}`,
    }"
  >
    <div
      class="content_canvas"
      :style="{
        borderRadius: `${controls[index].topAroundRadius}px ${controls[index].topAroundRadius}px ${controls[index].bottomAroundRadius}px ${controls[index].bottomAroundRadius}px`,
        background: `${controls[index].componentBgColor}`,
        border: `${borderline}`,
        boxShadow: `${boxshadown}`,
        marginTop: `${controls[index].margin.top}px`,
        marginBottom: `${controls[index].margin.bottom}px`,
      }"
    >
      <div
        class="container"
        :style="{
          display: `${className[0]}`,
          gridTemplateColumns: `repeat(${controls[index].rowCount}, 1fr)`,
          paddingBottom: `${Padingsize}`,
          maxHeight: `${className[3]}`,
          minHeight: `${className[5]}`,
          flexWrap: `${className[4]}`,
        }"
      >
        <div v-if="controls[index].showStyle == 'pageSlide'" class="pagesize">
          <div class="li"></div>
        </div>
        <div v-if="controls[index].showStyle == 'singleSlide'" class="over-x"></div>
        <div
          v-for="(ym, inde) in controls[index].list"
          :key="inde"
          class="card_item"
          :style="{
            minWidth: `${className[2]}%`,
          }"
        >
          <div v-if="controls[index].mode == 'graphic'" class="card_ym">
            <div class="picurl">
              <div
                v-if="ym.label.control"
                class="ym_identification"
                :style="{
                  color: `${ym.label.textColor}`,
                  background: `linear-gradient(to bottom, ${ym.label.bgColorStart}, ${ym.label.bgColorEnd})`,
                }"
              >
                {{ ym.label.text }}
              </div>
              <img
                :src="ym.imageUrl"
                :style="{
                  borderRadius: `${controls[index].aroundRadius}%`,
                  width: `${controls[index].imageSize}px`,
                  height: `${controls[index].imageSize}px`,
                }"
              />
            </div>
            <div
              class="txt"
              :style="{
                fontSize: `${controls[index].font.size}px`,
                color: `${controls[index].font.color}`,
                fontWeight: `${controls[index].font.weight}`,
              }"
            >
              {{ ym.title }}
            </div>
          </div>
          <div v-else class="card_ym">
            <div v-if="controls[index].mode == 'img'" class="picurl">
              <div
                v-if="ym.label.control"
                class="ym_identification"
                :style="{
                  color: `${ym.label.textColor}`,
                  background: `linear-gradient(to bottom, ${ym.label.bgColorStart}, ${ym.label.bgColorEnd})`,
                }"
              >
                {{ ym.label.text }}
              </div>
              <img
                :src="ym.imageUrl"
                :style="{
                  borderRadius: `${controls[index].aroundRadius}%`,
                  width: `${controls[index].imageSize}px`,
                  height: `${controls[index].imageSize}px`,
                }"
              />
            </div>
            <div
              v-else
              class="txt"
              :style="{
                fontSize: `${controls[index].font.size}px`,
                color: `${controls[index].font.color}`,
                fontWeight: `${controls[index].font.weight}`,
              }"
            >
              {{ ym.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    index: {
      type: Number,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      controls: (state) => state.checkvalue,
    }),
    borderline() {
      const ornament = this.controls[this.index].ornament
      if (ornament.type == 'circle') {
        return `1px solid ${ornament.color}`
      } else {
        return ' none'
      }
    },
    boxshadown() {
      const ornament = this.controls[this.index].ornament
      if (ornament.type == 'shadow') {
        return `${ornament.color} 0 0 5px`
      } else {
        return 'none'
      }
    },
    className() {
      let Name = this.controls[this.index]
      let wietGH = 100 / this.controls[this.index].rowCount
      if (Name.showStyle === 'fixed') {
        const ls = ['grid', 'visible', wietGH, '100%', '', '']
        return ls
      } else if (Name.showStyle === 'singleSlide') {
        const ls = ['flex', 'scroll', wietGH, '95px', '', '']
        return ls
      } else if (Name.showStyle === 'pageSlide') {
        const pahcout = this.controls[this.index].pageCount
        let count = ''
        let pagHeight = ''
        if (pahcout == 2) {
          pagHeight = 151 + 'px'
          count = 'wrap'
        } else {
          count = ''
          pagHeight = 95 + 'px'
        }
        const ls = ['grid', 'scroll', wietGH, pagHeight, count, pagHeight]
        return ls
      } else {
        return ''
      }
    },
    Padingsize() {
      let Name = this.controls[this.index]
      if (Name.showStyle == 'pageSlide') {
        return '15px'
      } else if (Name.showStyle == 'singleSlide') {
        return '15px'
      } else {
        return `0`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ym_container {
  width: 100%;
  .content_canvas {
    position: relative;
    overflow: hidden;
  }
  .container {
    width: 100%;
    overflow: hidden;
    .pagesize {
      position: absolute;
      bottom: 5px;
      left: 50%;
      height: 10px;
      transform: translateX(-50%);
      .li {
        padding: 5px;
        background: rgba(81, 81, 81, 0.5);
        border-radius: 50%;
      }
    }
    .over-x {
      position: absolute;
      bottom: 0;
      width: 50%;
      height: 3px;
      background: gray;
    }
    .card_item {
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      justify-content: center;
      padding: 7px 0;
      margin: auto;
      .txt {
        max-width: 60px;
        min-height: 16px;
        margin: 3px 0;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .picurl {
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
        .ym_identification {
          position: absolute;
          top: -5px;
          right: -12px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px 6px;
          font-size: 10px;
          border-radius: 12px;
          border-bottom-left-radius: 0;
        }
        img {
          width: 40px;
        }
      }
    }
  }
}
</style>