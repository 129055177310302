import request from '@/utils/request'

//健康计划-计划类型-列表
export function vitMealPlanTypequeryPage(data) {
  return request({
    url: '/mall/user/vitMealPlanType/queryPage',
    method: 'post',
    data: data,
  })
}

//健康计划-计划类型_添加
export function vitMealPlanTypeadd(data) {
  return request({
    url: '/mall/user/vitMealPlanType/add',
    method: 'post',
    data: data,
  })
}

//健康计划-计划类型_删除
export function vitMealPlanTypedelete(data) {
  return request({
    url: '/mall/user/vitMealPlanType/delete',
    method: 'post',
    data: data,
  })
}

//健康计划-计划类型_编辑
export function vitMealPlanTypeedit(data) {
  return request({
    url: '/mall/user/vitMealPlanType/edit',
    method: 'post',
    data: data,
  })
}

//健康计划-计划管理_添加_copy
export function vitMealPlanadd(data) {
  return request({
    url: '/mall/vitMealPlan/add',
    method: 'post',
    data: data,
  })
}

//健康计划-计划管理-列表_copy
export function vitMealPlanpage(data) {
  return request({
    url: '/mall/vitMealPlan/page',
    method: 'post',
    data: data,
  })
}

//健康计划-计划管理_详情
export function vitMealPlandetailsInfo(data) {
  return request({
    url: '/mall/vitMealPlan/detailsInfo',
    method: 'post',
    data: data,
  })
}

//计划详情-执行人员
export function vitMealUserPlanDayqueryPage(data) {
  return request({
    url: `/mall/user/vitMealUserPlanDay/queryPage`,
    method: 'post',
    data,
  })
}

//计划详情-执行人员_查看
export function vitMealPlanuserMallDetails(data) {
  return request({
    url: `/mall/vitMealPlan/userMallDetails`,
    method: 'post',
    data,
  })
}

//计划列表-停止计划
export function vitMealUserPlanDayeditStop(data) {
  return request({
    url: `/mall/user/vitMealUserPlanDay/editStop`,
    method: 'post',
    data,
  })
}
// 计划详情 - 执行人员_执行详情
export function vitMealUserPlanDayqueryList(data) {
  return request({
    url: `/mall/swm/vitMealUserPlanDiet/queryList`,
    method: 'post',
    data,
  })
}
