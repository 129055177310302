var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "div",
        { staticClass: "add-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-plus", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("/workflow/healthPlan/healthAddplan")
                },
              },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-Date" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableData },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "计划名称", prop: "planName" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "计划类型", prop: "planType" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "计划时长", prop: "planDay" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "计划数量", prop: "planNum" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "创建时间",
                  prop: "createTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "累计执行人数",
                  prop: "userNum",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作", prop: "address" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showMore(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row, "del")
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row, "edit")
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": 1,
              layout: "total, sizes, prev, pager, next, jumper",
              "page-size": _vm.form.size,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: _vm.cur == "add" ? "新增类型" : "编辑类型",
            visible: _vm.dialogVisible,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  attrs: {
                    "label-width": "100px",
                    model: _vm.subForm,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型名称", prop: "planName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入类型名称" },
                        model: {
                          value: _vm.subForm.planName,
                          callback: function ($$v) {
                            _vm.$set(_vm.subForm, "planName", $$v)
                          },
                          expression: "subForm.planName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "计划类型", prop: "planType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择计划类型" },
                          model: {
                            value: _vm.subForm.planType,
                            callback: function ($$v) {
                              _vm.$set(_vm.subForm, "planType", $$v)
                            },
                            expression: "subForm.planType",
                          },
                        },
                        _vm._l(_vm.subForm.platype, function (ym, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: ym.planName, value: ym.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "计划图片", prop: "planLogo" } },
                    _vm._l(_vm.subForm.planLogo, function (ym, index) {
                      return _c("el-image", {
                        key: index,
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: {
                          "preview-src-list": _vm.subForm.planLogo,
                          src: ym,
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "执行计划", prop: "vitMealPlanOptPos" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "plans-table",
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.subForm.vitMealPlanOptPos },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "天数",
                              prop: "optDay",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "line-height": "30px" },
                                      },
                                      [
                                        _vm._v(
                                          "第" + _vm._s(scope.row.optDay) + "天"
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "时间",
                              prop: "optTime",
                              width: "200",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.optionVos,
                                    function (item, ind) {
                                      return _c("el-time-select", {
                                        key: ind,
                                        attrs: {
                                          "picker-options": {
                                            start: "00:00",
                                            end: "23:59",
                                          },
                                          placeholder: "选择时间",
                                          value: item,
                                        },
                                        model: {
                                          value: item.optTime,
                                          callback: function ($$v) {
                                            _vm.$set(item, "optTime", $$v)
                                          },
                                          expression: "item.optTime",
                                        },
                                      })
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "预计执行时长(分)",
                              prop: "optNum",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.optionVos,
                                    function (item, ind) {
                                      return _c("el-input", {
                                        key: ind,
                                        attrs: {
                                          placeholder: "请输入预计执行时长",
                                        },
                                        model: {
                                          value: item.optNum,
                                          callback: function ($$v) {
                                            _vm.$set(item, "optNum", $$v)
                                          },
                                          expression: "item.optNum",
                                        },
                                      })
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "执行内容",
                              prop: "optInfo",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "add-tablea" },
                                      _vm._l(
                                        scope.row.optionVos,
                                        function (item, ind) {
                                          return _c("el-input", {
                                            key: ind,
                                            attrs: {
                                              placeholder: "请输入执行内容",
                                            },
                                            model: {
                                              value: item.optInfo,
                                              callback: function ($$v) {
                                                _vm.$set(item, "optInfo", $$v)
                                              },
                                              expression: "item.optInfo",
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "操作",
                              prop: "name",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.vitMealPlanOptionPos,
                                    function (item, ind) {
                                      return _c(
                                        "div",
                                        { key: ind, staticClass: "sc" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkdel(
                                                    ind,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "计划说明", prop: "vitMealPlanDesPos" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.subForm.vitMealPlanDesPos },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "标题",
                              prop: "desTitle",
                              width: "200",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入标题" },
                                      model: {
                                        value: scope.row.desTitle,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "desTitle", $$v)
                                        },
                                        expression: "scope.row.desTitle",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "标题内容",
                              prop: "desContent",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入标题内容" },
                                      model: {
                                        value: scope.row.desContent,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "desContent", $$v)
                                        },
                                        expression: "scope.row.desContent",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addSubform } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }