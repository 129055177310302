var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        title: "编辑",
        visible: _vm.dialogVisibl,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisibl = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: {
                "label-width": "80px",
                model: _vm.formDatal,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "昵称:", prop: "nickName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入昵称" },
                    model: {
                      value: _vm.formDatal.nickName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formDatal, "nickName", $$v)
                      },
                      expression: "formDatal.nickName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺:", prop: "shopId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: true, placeholder: "请选择店铺" },
                      model: {
                        value: _vm.formDatal.shopId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formDatal, "shopId", $$v)
                        },
                        expression: "formDatal.shopId",
                      },
                    },
                    _vm._l(_vm.select, function (ym, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: ym.name, value: ym.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账号:", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true, placeholder: "请输入账号" },
                    model: {
                      value: _vm.formDatal.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.formDatal, "username", $$v)
                      },
                      expression: "formDatal.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "密码:", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true, placeholder: "请输入密码" },
                    model: {
                      value: _vm.formDatal.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.formDatal, "password", $$v)
                      },
                      expression: "formDatal.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号:", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true, placeholder: "请输入手机号" },
                    model: {
                      value: _vm.formDatal.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.formDatal, "phone", $$v)
                      },
                      expression: "formDatal.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱:", prop: "email" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true, placeholder: "请输入邮箱" },
                    model: {
                      value: _vm.formDatal.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.formDatal, "email", $$v)
                      },
                      expression: "formDatal.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "头像:", prop: "avatar" } },
                [
                  _vm.formDatal.shopId
                    ? _c(
                        "div",
                        { staticClass: "aval" },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              class: {
                                hide: _vm.hideUpload,
                              },
                              attrs: {
                                action: _vm.action,
                                "before-upload": _vm.bfUpload,
                                "file-list": _vm.fileList,
                                headers: _vm.headers,
                                limit: _vm.limitCount,
                                "list-type": "picture-card",
                                "on-change": _vm.handleChange,
                                "on-preview": _vm.handlePictureCardPreview,
                                "on-remove": _vm.handleRemove,
                                "on-success": _vm.handleSuccess,
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                "append-to-body": "",
                                visible: _vm.dialogVisible,
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisible = $event
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  alt: "",
                                  src: _vm.dialogImageUrl,
                                  width: "100%",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "txt", staticStyle: { color: "gray" } },
                        [_vm._v("请选择店铺后上传")]
                      ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.clear } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }