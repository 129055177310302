import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1852b572&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=1852b572&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1852b572",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\健康约-后管-前端\\health-front-backend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1852b572')) {
      api.createRecord('1852b572', component.options)
    } else {
      api.reload('1852b572', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=1852b572&scoped=true", function () {
      api.rerender('1852b572', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mall/user/userDetails/components/Health-record/index.vue"
export default component.exports