var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "OrderList cy-container" }, [
    _c(
      "div",
      { staticClass: "GeneralOrder" },
      [
        _c(
          "div",
          { staticClass: "m-b" },
          _vm._l(_vm.searchButton, function (item) {
            return _c(
              "el-button",
              {
                key: item.id,
                attrs: { type: "primary" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.handleClickBtn(item)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.buttonName) + " ")]
            )
          }),
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            attrs: {
              border: "",
              data: _vm.orderList,
              "default-expand-all": "",
              "max-height": "600",
              "row-key": "id",
            },
            on: { "selection-change": _vm.setSelectRows },
          },
          [
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "ID",
                prop: "id",
                width: "130px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "电话1",
                prop: "phone",
                width: "130px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "电话2",
                prop: "phonev",
                width: "130px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "备注",
                prop: "comContent",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "合并时间",
                prop: "createTime",
                width: "180px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "操作员工",
                prop: "realName",
                width: "130px",
              },
            }),
            _vm.operationShow
              ? _c("el-table-column", {
                  attrs: { align: "center", label: "操作" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._l(_vm.listButton, function (item) {
                              return [
                                _vm.showListButton(row.orderState, item)
                                  ? _c(
                                      "el-button",
                                      {
                                        key: item.id,
                                        attrs: {
                                          plain:
                                            item.cssType &&
                                            item.cssType == "plain"
                                              ? true
                                              : false,
                                          size: "mini",
                                          type: item.buttonCss,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.handleClickBtn(item, row)
                                          },
                                        },
                                      },
                                      [
                                        !item.buttonIcon
                                          ? _c("span")
                                          : _c("vab-icon", {
                                              attrs: { icon: item.buttonIcon },
                                            }),
                                        _vm._v(
                                          " " + _vm._s(item.buttonName) + " "
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2417646154
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
        _c("el-pagination", {
          attrs: {
            background: "",
            "current-page": _vm.forms.current,
            layout: _vm.layout,
            "page-size": _vm.forms.size,
            total: _vm.total,
          },
          on: {
            "current-change": _vm.handleCurrentChange,
            "size-change": _vm.handleSizeChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }