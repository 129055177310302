import Vue from 'vue'
import App from './App'
import i18n from './i18n'
import store from './store'
import router from './router'
import '@/vab'

//-------------

import * as msg from '@/utils/message'
import dict from '@/config/dict'
//vue-bus

// 字体图标
import SvgIcon from '@/components/SvgIcon'
import TableEdit from '@/components/TableEdit.vue'
import CommonForm from '@/components/CommonForm.vue'
import axios from './utils/request'
import VueAxios from 'axios'
// 自定义封装消息提示框
Object.keys(msg).forEach((key) => {
  Vue.prototype[key] = msg[key]
})
//引入 Froala Editor js file.
require('froala-editor/js/froala_editor.pkgd.min.js')
//引入中文语言包
require('froala-editor/js/languages/zh_cn')
//引入 Froala Editor css files.
require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')
require('font-awesome/css/font-awesome.css')
import '@/styles/common.scss'
// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
// 把字典放到vue原型上
Vue.prototype.$dict = dict
Vue.component('SvgIcon', SvgIcon)
Vue.component('TableEdit', TableEdit)
Vue.component('CommonForm', CommonForm)
Vue.use(VueAxios, axios)
// echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
// moment
import { parseTime } from '@/utils'
// 年月日时分秒
Vue.filter('formatDate', function (value) {
  if (value) {
    if (value === '-') {
      return '-'
    } else {
      return parseTime(value)
    }
  } else {
    return ''
  }
})
// 年月日
Vue.filter('formats', function (value) {
  if (value) {
    if (value === '-') {
      return '-'
    } else {
      return parseTime(new Date(value), '{y}-{m}-{d}')
    }
  } else {
    return ''
  }
})
// 年月日
Vue.filter('formatTimes', function (value) {
  if (value) {
    if (value === '-') {
      return '-'
    } else {
      return parseTime(new Date(value), '{h}:{i}:{s}')
    }
  } else {
    return ''
  }
})
// 注册金额格式化过滤器
import numFormat from '@/utils/numFormat'
Vue.filter('numFormat', numFormat)
import mixins from '@/mixins'
Vue.mixin(mixins)

//---------------

/**
 * @description 正式环境默认使用mock，正式项目记得注释后再打包
 */
// import { baseURL } from './config'
// import { isExternal } from '@/utils/validate'

// if (process.env.NODE_ENV === 'production' && !isExternal(baseURL)) {
//   const { mockXHR } = require('@/utils/static')
//   mockXHR()
// }

// if (pwa) require('./registerServiceWorker')
import websocket from '@/layout/websoket.vue'
Vue.component('Websoket', websocket)

// import WebSocketNotificationPlugin from '@/layout/websocket-notification'
// const userId = localStorage.getItem('userId')
// const token = localStorage.getItem('admin-pro-token')
// Vue.use(WebSocketNotificationPlugin, { userId, token }) // 传递 userId 给插件
Vue.config.productionTip = false

Vue.prototype.$EventBus = new Vue({
  el: '#app',
  i18n,
  store,
  router,
  VueAxios,
  render: (h) => h(App),
})

Vue.use(VueFroala)
