export const componentMap = new Map([
  ['MemberMyOrder', () => import('../right-Controls/components/MemberMyOrder/index')], // 我的订单
  ['brandList', () => import('../right-Controls/components/brandList/index.vue')], // 自定义头部
  ['Search', () => import('../right-Controls/components/search/index.vue')], // 自定义头部
  ['Graphicnavigation', () => import('../right-Controls/components/Graphic-navigation/index.vue')], // 图文导航
  ['ManyGoodsList', () => import('../right-Controls/components/Mult-icommodity-group/index.vue')], // 多商品组
  ['header', () => import('../right-Controls/components/header-toll/index.vue')], // 头部
  ['Text', () => import('../right-Controls/components/Text/index.vue')], // 标题
  // ['brandList', () => import('../right-Controls/components/brandList/index.vue')], // 会员中心
  ['banner', () => import('../right-Controls/components/banner/index.vue')], // 轮播图
  ['Article', () => import('../right-Controls/components/Article/index.vue')], // 文章
  ['Healthconsultant', () => import('../right-Controls/components/Healthconsultant/index.vue')], // 健康顾问
  ['Listnavigation', () => import('../right-Controls/components/Listnavigation/index.vue')], // 列表导航
  ['RubikCube', () => import('../right-Controls/components/RubikCube/index.vue')], // 魔方
  ['GoodsLiss', () => import('../right-Controls/components/Commoditylist/index.vue')], // 魔方
  // ['text', () => import('./BasicsComp/textTool.vue')], // 文本
  // ['videoBox', () => import('./BasicsComp/videoTool.vue')], // 视频控件
  // ['imageText', () => import('./BasicsComp/imageText.vue')], // 图文控件
  // ['imageTextNav', () => import('./BasicsComp/imageTextNav.vue')], // 图文导航
  // ['assistDiv', () => import('./BasicsComp/assistDiv.vue')], // 铺助分割
  // ['custom', () => import('./BasicsComp/customTool.vue')], // 自定义控件
  // ['brandList', () => import('./BasicsComp/brandList.vue')], // 品牌列表
  // ['imageTextList', () => import('./BasicsComp/imageTextList.vue')], // 图文列表
  // ['notice', () => import('./BasicsComp/noticeTool.vue')], // 公告
  // ['coupon', () => import('./MarketingComp/couponTool.vue')], // 优惠券
  // ['categoryList', () => import('./goodsComp/categoryTool.vue')], // 类别列表
  // ['productList', () => import('./goodsComp/productList.vue')], // 商品列表
  // ['groupList', () => import('./shopComp/groupTool.vue')], // 拼团专区
  // ['spikeList', () => import('./shopComp/spikeTool.vue')], // 秒杀专区
  // ['priceList', () => import('./shopComp/priceTool.vue')], // 定价捆绑
  // ['discountList', () => import('./shopComp/discountTool.vue')], // 折扣列表
  // ['vip', () => import('./shopComp/vipTool.vue')], // 会员专区
  // ['newProduct', () => import('./shopComp/newProductTool.vue')], // 会员专区
  // ['live', () => import('./shopComp/liveTool.vue')], // 直播
  // ['shop', () => import('./shopComp/shopTool.vue')], // 每日好店
])
export default componentMap
