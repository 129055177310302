<template>
  <div class="comprehensive-table-container">
    <div class="search">
      <el-form ref="formName" :inline="true" label-width="80px" :model="form">
        <el-form-item label="订单编号">
          <el-input v-model="form.orderNo" placeholder="请输入 订单编号" />
        </el-form-item>
        <el-form-item label="退货状态">
          <el-select v-model="form.status" placeholder="请选择活动区域">
            <el-option v-for="(ym, index) in options" :key="index" :label="ym.labelName" :value="ym.id" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="query">搜索</el-button>
          <el-button icon="el-icon-refresh" type="warning" @click="clear">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table border :data="tableData" style="width: 100%">
      <el-table-column align="center" label="商品信息" prop="orderItemId" />
      <el-table-column align="center" label="订单信息" prop="orderId" />
      <el-table-column align="center" label="退款状态" prop="status" />
      <el-table-column align="center" label="是否退款" prop="isRefund">
        <templateset slot="scope">
          {{ scope }}
        </templateset>
      </el-table-column>
      <el-table-column align="center" label="退款原因" prop="refundReson" />
      <el-table-column align="center" label="拒绝退款原因" prop="refuseRefundReson" />
      <el-table-column align="center" label="退款流水号" prop="refundTradeNo" />
      <el-table-column align="center" label="创建时间" prop="createTime" />
    </el-table>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="form.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import { orderrefundspage } from '@/api/mall/order/order'
export default {
  data() {
    return {
      form: {
        size: 10,
        current: 1,
      },
      options: [
        {
          id: 0,
          labelName: '撤销退换货',
        },
        {
          id: 1,
          labelName: '退款申请中',
        },
        {
          id: 11,
          labelName: '同意退款',
        },
        {
          id: 12,
          labelName: '拒绝退款',
        },
        {
          id: 2,
          labelName: '退货退款申请中',
        },
        {
          id: 21,
          labelName: '同意退货退款/退货中',
        },
        {
          id: 22,
          labelName: '拒绝退货退款',
        },
        {
          id: 211,
          labelName: '收到退货同意退款',
        },
        {
          id: 212,
          labelName: '收到退货拒绝退款',
        },
      ],
      tableData: [],
      total: 0,
    }
  },
  computed: {
    fitType() {
      return (item) => {
        const lset = this.options.find((el) => el.id == item)
        return lset.labelName
      }
    },
  },
  created() {
    this.query()
  },
  methods: {
    clear() {
      this.form = {
        size: 10,
        current: 1,
      }
      this.query()
    },
    query() {
      orderrefundspage({ ...this.form }).then((res) => {
        this.tableData = res.data.records
        this.total = res.data.total
        this.form.size = res.data.size
        this.form.current = res.data.current
      })
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.query()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.query()
    },
  },
}
</script>

<style lang="scss" scoped>
.search {
  margin-bottom: 15rpx;
}
</style>