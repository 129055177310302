<template>
  <div class="textTool">
    <div class="toolTit">
      <div class="lef">标题</div>
      <div class="ref">
        <div class="switch">
          <div :class="checknum == 1 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(1)">内容</div>
          <div :class="checknum == 2 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(2)">样式</div>
        </div>
      </div>
    </div>
    <div v-if="checknum == 1" class="content_msg">
      <div class="ym_line"></div>
      <div class="ym_line" style="padding: 2px 0"></div>
      <div class="ym_title">风格设置</div>
      <div class="itemBox">
        <div class="Tit">风格选择</div>
        <div class="check-tit" style="display: flex; align-items: center; color: #3371f7" @click="hide = true">
          {{ activeComponents[indexnumer].styleName }}
          <i class="el-icon-arrow-right" style="font-size: 14px; color: gray"></i>
        </div>
      </div>
      <div class="ym_line" style="padding: 2px 0"></div>
      <div class="ym_title">标题内容</div>
      <div class="itemBox">
        <div class="Tit">标题名称</div>
        <el-input v-model="activeComponents[indexnumer].text" class="item-input" maxlength="20" placeholder="请输入内容" />
      </div>
      <div class="itemBox">
        <div class="Tit">链接地址</div>
        <div class="check-tit" style="display: flex; align-items: center; color: #3371f7" @click="checklink(1)">
          <span v-if="activeComponents[indexnumer].link.name != ''" style="color: #1780ff">{{ activeComponents[indexnumer].link.title }}</span>
          <span v-else style="color: #1780ff">请选择链接</span>
          <i class="el-icon-arrow-right" style="font-size: 14px; color: gray"></i>
        </div>
      </div>
      <div class="ym_line" style="padding: 2px 0"></div>
      <div class="ym_title">副标题内容</div>
      <div class="itemBox">
        <div class="Tit" style="margin-left: -15px">副标题名称</div>
        <el-input v-model="activeComponents[indexnumer].subTitle.text" class="item-input" maxlength="20" placeholder="请输入内容" />
      </div>
      <div class="ym_line" style="padding: 2px 0"></div>
      <div class="ym_title">“更多”按钮内容</div>
      <div class="itemBox">
        <div class="Tit">按钮文字</div>
        <el-input v-model="activeComponents[indexnumer].more.text" class="item-input" maxlength="20" placeholder="请输入内容" />
      </div>
      <div class="itemBox">
        <div class="Tit">链接地址</div>
        <div class="check-tit" style="display: flex; align-items: center; color: #3371f7" @click="checklink(2)">
          <span v-if="activeComponents[indexnumer].more.link.name != ''" style="color: #1780ff">{{ activeComponents[indexnumer].more.link.title }}</span>
          <span v-else style="color: #1780ff">请选择链接</span>
          <i class="el-icon-arrow-right" style="font-size: 14px; color: gray"></i>
        </div>
      </div>
    </div>
    <div v-if="checknum == 2" class="content_msg">
      <div class="ym_line"></div>
      <div class="ym_title">标题样式</div>
      <div class="itemBox">
        <div class="Tit">文字大小</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].fontSize" :max="20" :min="12" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].fontSize" :max="20" :min="12" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">文字粗细</div>
        <div class="check-tit">
          <el-radio-group v-model="activeComponents[indexnumer].fontWeight" size="mini">
            <el-radio-button label="bold">加粗</el-radio-button>
            <el-radio-button label="normal">常规</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">文字颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].textColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].textColor" />
            <div class="resect" @click="activeComponents[indexnumer].textColor = '#000000'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="ym_line" style="padding: 2px 0"></div>
      <div class="ym_title">副标题样式</div>
      <div class="itemBox">
        <div class="Tit">文字大小</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].subTitle.fontSize" :max="16" :min="12" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].subTitle.fontSize" :max="16" :min="12" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">文字颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].subTitle.color }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].subTitle.color" />
            <div class="resect" @click="activeComponents[indexnumer].subTitle.color = '#999999'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="ym_line" style="padding: 2px 0"></div>
      <div class="ym_title">“更多”按钮样式</div>
      <div class="itemBox">
        <div class="Tit">是否显示</div>
        <div class="check-tit">
          <el-checkbox v-if="activeComponents[indexnumer].more.isShow" v-model="activeComponents[indexnumer].more.isShow">显示</el-checkbox>
          <el-checkbox v-else v-model="activeComponents[indexnumer].more.isShow">隐藏</el-checkbox>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">文字颜色</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].more.color }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].more.color" />
            <div class="resect" @click="activeComponents[indexnumer].more.color = '#999999'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="ym_line" style="padding: 2px 0"></div>
      <div class="ym_title">组件样式</div>
      <div class="itemBox">
        <div class="Tit">底部背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].pageBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].pageBgColor" />
            <div class="resect" @click="activeComponents[indexnumer].pageBgColor = 'none'">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">组件背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].componentBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].componentBgColor" />
            <div class="resect" @click="activeComponents[indexnumer].componentBgColor = ''">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.top" :max="100" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.top" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.bottom" :max="100" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.bottom" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">左右边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.both" :max="20" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.both" :max="20" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].topElementAroundRadius" :max="50" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].topElementAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].bottomElementAroundRadius" :max="50" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].bottomElementAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
    </div>
    <linkdialog :linkshow="linkurl" @closeurl="closelink" />
    <checkstyle :chtostyle="activeComponents[indexnumer].style" :showstyle="hide" @closeinfo="closestyle" />
  </div>
</template>
<script>
// import uploadpic from '../../../upload-pic/index.vue'
import checkstyle from './components/checkstyle.vue'
import { mapGetters } from 'vuex'
import linkdialog from '../../../link-dialog/index.vue'
export default {
  components: {
    linkdialog,
    checkstyle,
  },
  props: {
    indexnumer: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      checknum: 1,
      linkurl: false,
      checklinknum: null,
      hide: false,
    }
  },
  computed: {
    ...mapGetters['state'],
    activeComponents() {
      return this.$store.state.checkvalue
    },
    imageurl() {
      let imgUrl = this.activeComponents[this.indexnumer].imageUrl
      if (imgUrl) {
        return imgUrl
      } else {
        return ''
      }
    },
  },
  methods: {
    contrl(e) {
      console.log(e)
    },
    checklink(Index) {
      this.checklinknum = Index
      this.linkurl = true
    },
    closelink(lisr) {
      if (lisr != false) {
        if (this.checklinknum == 1) {
          this.activeComponents[this.indexnumer].link = lisr
          this.linkurl = false
        }
        if (this.checklinknum == 2) {
          this.activeComponents[this.indexnumer].more.link = lisr
          this.linkurl = false
        }
      } else {
        this.linkurl = false
      }
    },
    check(e) {
      this.activeComponents[this.indexnumer].imageUrl = e
    },
    changeChecknum(type) {
      this.checknum = type
    },
    closestyle(row) {
      if (row != false) {
        this.activeComponents[this.indexnumer].styleName = row.styleName
        this.activeComponents[this.indexnumer].style = row.style
        this.hide = false
      } else {
        this.hide = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.textTool {
  width: 100%;
  padding: 20px 20px 0 20px;
  .toolTit {
    display: flex;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
    color: #333333;
    .switch {
      display: flex;
      align-items: center;
      overflow: hidden;
      font-size: 14px;
      cursor: pointer;
      background: #f5f5f5;
      border-radius: 50px;
      .swit-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 29px;
        border-radius: 50px;
      }
      .active {
        color: white;
        background: #1780ff;
      }
    }
  }
  .ym_line {
    width: 100%;
    height: 3px;
    background: #f2f4f6;
  }
  .ym_title {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .itemBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 40px;
    margin-left: 20px;
    .Tit {
      margin-right: 15px;
      color: gray;
      text-align: right;
      white-space: nowrap;
    }
    .check-tit {
      display: flex;
      justify-content: right;
      width: calc(100% - 56px);
      color: #105cfb;
      .resect {
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }
  .alert-msg {
    margin-bottom: 15px;
    font-size: 12px;
    color: rgb(210, 210, 210);
    text-align: center;
  }
  ::v-deep(.el-color-picker__trigger) {
    width: 45px;
    height: 26px;
  }
  ::v-deep(.el-icon-arrow-down:before) {
    display: none;
  }
  ::v-deep {
    .el-input-number {
      width: 80px;
      .el-input__inner {
        padding: 0;
        text-align: center;
      }
    }

    .el-input-number__decrease,
    .el-input-number__increase {
      display: none;
    }
  }
}
</style>
