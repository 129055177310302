<template>
  <div>
    <el-dialog
      :before-close="handleClose"
      title="查看"
      :visible.sync="dialogVisible"
      width="60%">
      <span>
        <el-collapse v-model="shownumber" style="border: 1px solid #ebeef5;">
          <el-collapse-item name="1" style="text-indent: 1em;" title="店铺信息">
            <el-descriptions border class="margin-top" :column="2" title="">
              <el-descriptions-item>
                <template slot="label">
                  店铺名:
                </template>
                {{activeNames.name}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  电话:
                </template>
                {{activeNames.phone}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  详细地址:
                </template>
                {{activeNames.address}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  详细介绍:
                </template>
                {{ activeNames.detail }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  店铺Logo:
                </template>
                <el-image 
                :src="activeNames.imgUrl" 
                style="width: 60px; height: 60px"/>
              </el-descriptions-item>
            </el-descriptions>
          </el-collapse-item>
          <el-collapse-item name="2" style="text-indent: 1em;"  title="店长信息">
            <el-descriptions border class="margin-top" :column="2" title="">
              <el-descriptions-item>
                <template slot="label">
                  昵称:
                </template>
                {{activeNames.userNickname}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  登陆账号:
                </template>
                {{activeNames.userUsername}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  手机号:
                </template>
                {{ activeNames.userPhone }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  邮箱:
                </template>
                {{ activeNames.userEmail }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  店长头像:
                </template>
                <el-image 
                :src="activeNames.userAvatar" 
                style="width: 60px; height: 60px"/>
              </el-descriptions-item>
            </el-descriptions>
          </el-collapse-item>
          <el-collapse-item name="3" style="text-indent: 1em;"  title="审核信息">
            <el-descriptions border class="margin-top" :column="2" title="">
              <el-descriptions-item>
                <template slot="label">
                  编号:
                </template>
                {{activeNames.id}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  更新时间:
                </template>
                {{activeNames.updateTime}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  审核状态:
                </template>
                <el-tag v-if="activeNames.status==1" type="success">通过</el-tag>
                <el-tag v-if="activeNames.status==2" type="danger">审核不通过</el-tag>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  审核意见:
                </template>
                {{ activeNames.applyDetail }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  店铺:
                </template>
                {{activeNames.shopId}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  创建时间:
                </template>
                {{activeNames.createTime}}
              </el-descriptions-item>
            </el-descriptions>
          </el-collapse-item>
        </el-collapse>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  export default{
    name:"Applydialog",
    props:{
      dbshow:{
        type:Boolean,
        default:()=>{}
      },
      applerow:{
        type:Object,
        default:()=>{}
      }
    },
    data(){
      return{
        dialogVisible:this.dbshow,
        shownumber:['1','2','3'],
        activeNames:this.applerow,
      }
    },
    watch:{
      dbshow(newValue,oldValue){
        if(newValue!=oldValue){
          this.dialogVisible=newValue
          this.shownumber=['1','2','3']
        }
      },
      applerow(newValue,oldValue){
        if(newValue!=oldValue){
          this.activeNames=newValue
        }
      }
    },
    created(){
      console.log(this.applerow)
    },
    methods:{
      handleClose(){
        this.$emit('resolve',false)
      },
      
    }
  }
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header{
  border-bottom: 1px solid #ebeef5;
}
</style>