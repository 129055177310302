<template>
  <div class="search">
    <vab-query-form-top-panel>
      <!-- <el-form :inline="true" label-width="80px" :model="searchFrom">
        <el-form-item label="页面名称">
          <el-input v-model="searchFrom.name" placeholder="请输入 页面名称" />
        </el-form-item>
        <el-form-item label="">
          <el-button icon="el-icon-search" type="primary">搜索</el-button>
          <el-button icon="el-icon-refresh-right" type="warning">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="add_btn">
        <el-button icon="el-icon-plus" type="primary" @click="addTemplate">增加模板</el-button>
      </div> -->
    </vab-query-form-top-panel>
    <el-dialog :before-close="handleClose" title="模板创建" :visible.sync="dialogVisible" width="40%">
      <span>
        <el-form ref="ruleForm" class="demo-ruleForm" label-width="100px" :model="ruleForm" :rules="rules">
          <el-form-item label="模板名称" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入 模板名称" />
          </el-form-item>
          <el-form-item label="模板类型" prop="type">
            <el-select v-model="ruleForm.type" placeholder="请选择 模板类型">
              <el-option v-for="(ym, index) in typeoptions" :key="index" :label="ym.dictValue" :value="ym.dictKey" />
            </el-select>
          </el-form-item>
          <el-form-item label="访问终端" prop="terminal">
            <el-select v-model="ruleForm.terminal" placeholder="请选择 访问终端">
              <el-option v-for="(ym, index) in options" :key="index" :disabled="ym.disbled" :label="ym.labelName" :value="ym.id" />
            </el-select>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { selectSysDict } from '@/api/mall/common'
export default {
  data() {
    return {
      searchFrom: {},
      dialogVisible: false,
      ruleForm: {
        shopId: 1,
        customId: 0,
      },
      rules: {
        name: [{ required: true, message: '请输入 模板名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择 模板类型', trigger: 'change' }],
        terminal: [{ required: true, message: '请选择 访问终端', trigger: 'change' }],
      },
      options: [
        {
          id: 1,
          disbled: false,
          labelName: '小程序',
        },
        {
          id: 2,
          disbled: false,
          labelName: 'H5',
        },
        {
          id: 3,
          disbled: false,
          labelName: 'APP',
        },
        {
          id: 4,
          disbled: true,
          labelName: 'PC',
        },
      ],
      typeoptions: [],
    }
  },
  created() {},
  methods: {
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const url = this.$router.resolve({
            path: 'fitment',
            query: { addTemplate: JSON.stringify(this.ruleForm) },
          })
          window.open(url.href, '_blank')
          this.dialogVisible = false
        } else {
          return false
        }
      })
    },
    addTemplate() {
      this.dialogVisible = true
      this.selectSysDict()
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.ruleForm.resetFields()
    },
    selectSysDict() {
      selectSysDict({ dictTypeList: ['themeDiyType'] }).then((res) => {
        this.typeoptions = res.data[0].dictInfoVoList
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.add_btn {
  margin-bottom: 15px;
}
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
  .el-inpur.el-input__inner,
  .el-select {
    width: 100%;
  }
}
</style>