<template>
  <div class="comprehensive-table-container">
    <div class="page-title">
      <div class="line"></div>
      <div class="text">基本设置</div>
    </div>
    <div class="form-item">
      <div class="item-title">活动名称：</div>
      <input
        v-model.trim="result.actName"
        class="input"
        placeholder="请输入活动名称"
        type="text"
      />
      <div class="desc">活动名称将显示在活动列表中，方便商家管理</div>
    </div>
    <div class="form-item">
      <div class="item-title">活动时间：</div>
      <el-date-picker
        v-model="timePicker"
        align="center"
        end-placeholder="请选择活动结束时间"
        range-separator="至"
        start-placeholder="请选择活动开始时间"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        @change="dateChange"
      />
      <div class="desc">活动名称将显示在活动列表中，方便商家管理</div>
    </div>
    <div class="form-item">
      <div class="item-title">活动时间：</div>
      <el-radio-group v-model="result.publishType">
        <el-radio label="1">立即发布</el-radio>
        <el-radio label="2">定时发布</el-radio>
      </el-radio-group>
    </div>
    <div v-if="result.publishType == 2" class="second-item">
      <div class="item-title">选择时间：</div>
      <el-date-picker
        v-model="result.publishTime"
        align="center"
        placeholder="选择日期时间"
        type="datetime"
        value-format="yyyy-MM-dd HH:mm:ss"
      />
    </div>
    <div class="form-item">
      <div class="item-title">参与平台：</div>
      <el-checkbox-group
        v-model="checkPlateForm"
        @change="handlePlateFormChange"
      >
        <el-checkbox
          v-for="(item, index) in plateForm"
          :key="index"
          :label="item.dictKey"
          :value="item.dictKey"
        >
          {{ item.dictValue }}
        </el-checkbox>
      </el-checkbox-group>
      <div class="desc">活动只在选中的平台进行展示，至少选择一个</div>
    </div>
    <div class="form-item">
      <div class="item-title">活动类型：</div>
      <el-radio-group v-model="result.actType">
        <el-radio
          v-for="(item, index) in activityList"
          :key="index"
          :label="item.dictKey.toString()"
        >
          {{ item.dictValue }}
        </el-radio>
      </el-radio-group>
    </div>
    <div class="form-item">
      <div class="item-title">活动封面：</div>
      <el-upload
        accept=".jpg,.png,.jpeg,.mp4"
        :action="uploadParams.uploadURL"
        :file-list="actLogoList"
        :headers="{ Authorization: uploadParams.token }"
        :limit="1"
        list-type="picture-card"
        :multiple="false"
        :on-preview="handlePreView"
        :on-remove="handleActLogoRemove"
        :on-success="handleActLogoSuccess"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </div>
    <div class="form-item">
      <div class="item-title">活动背景：</div>
      <el-upload
        accept=".jpg,.png,.jpeg,.mp4"
        :action="uploadParams.uploadURL"
        :file-list="actBackPicList"
        :headers="{ Authorization: uploadParams.token }"
        :limit="1"
        list-type="picture-card"
        :on-preview="handlePreView"
        :on-remove="handleBackPicRemove"
        :on-success="handleActBackPicSuccess"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </div>
    <div class="bottom-btns">
      <el-button size="small" @click="handleBack">返回</el-button>
      <el-button
        :loading="saveBtnLoading"
        size="small"
        type="primary"
        @click="handleSubmit"
      >
        保存
      </el-button>
    </div>
    <el-dialog title="图片详情" :visible.sync="imgDetailVisible" width="50%">
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <el-image fit="fill" :src="imgUrl" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getToken } from '@/utils/token'
  import { uploadURL } from '@/config'
  import { selectSysDict } from '@/api/mall/common'
  import { addActivity, editActivity } from '@/api/activity/activity'
  export default {
    // name: 'AddActivity',
    data() {
      return {
        timePicker: [],
        plateForm: [],
        checkPlateForm: [],
        actLogoList: [],
        actBackPicList: [],
        imgUrl: '',
        imgDetailVisible: false,
        saveBtnLoading: false,
        id: 0,
        activityList: [
          {
            dictValue: '抽奖',
            dictKey: 10,
          },
          {
            dictValue: '盲盒',
            dictKey: 20,
          },
          {
            dictValue: '拼团',
            dictKey: 30,
          },
          {
            dictValue: '秒杀',
            dictKey: 40,
          },
          {
            dictValue: '夺宝',
            dictKey: 50,
          },
        ],
        uploadParams: {
          token: `Bearer ${getToken()}`,
          uploadURL,
          fileList: [],
          dialogImageUrl: '',
        },
        detailsImages: [],
        result: {
          actName: '',
          startTime: '',
          endTime: '',
          publishType: '1',
          publishTime: '',
          actPlatform: '',
          actType: '10',
          actLogo: '',
          actBackdrop: '',
        },
      }
    },
    created() {
      const data = this.$route.query
      if (data.status == 1 || data.status == 2) {
        this.id = data.id
        Object.keys(this.result).forEach((key) => {
          this.result[key] = data[key]
        })
        this.checkPlateForm = data.actPlatform.split(',')
        this.timePicker = [data.startTime, data.endTime]
        this.actLogoList = [
          {
            url: data.actLogo,
          },
        ]
        this.actBackPicList = [
          {
            url: data.actBackdrop,
          },
        ]
      }
      this.fetchData()
    },
    methods: {
      handleBack() {
        this.$router.back()
      },
      handleSubmit() {
        const { status } = this.$route.query
        const startTime = new Date(this.result.startTime).getTime()
        const publishTime = new Date(this.result.publishTime).getTime()
        if (!this.result.actName) {
          this.$message.error('活动名称不能为空！')
        } else if (!this.result.startTime) {
          this.$message.error('活动开始时间不能为空！')
        } else if (!this.result.endTime) {
          this.$message.error('活动结束时间不能为空')
        } else if (this.result.publishType == 2 && !this.result.publishTime) {
          this.$message.error('定时发布时间不能为空')
        } else if (this.result.publishType == 2 && publishTime >= startTime) {
          this.$message.error('定时发布时间要早于开始时间')
        } else if (!this.result.actPlatform) {
          this.$message.error('活动参与平台不能为空')
        } else if (!this.result.actType) {
          this.$message.error('活动类型不能为空')
        } else if (!this.result.actLogo) {
          this.$message.error('活动封面图片不能为空')
        } else if (!this.result.actBackdrop) {
          this.$message.error('活动背景图片不能为空')
        } else {
          this.saveBtnLoading = true
          if (status == 0 || status == 2) {
            addActivity(this.result).then(() => {
              this.$message.success('新增活动成功！')
              this.saveBtnLoading = false
              this.$router.replace({
                path: '/activity/list',
              })
            })
          } else {
            editActivity({ ...this.result, id: this.id }).then(() => {
              this.$message.success('修改活动成功！')
              this.saveBtnLoading = false
              this.$router.replace({
                path: '/activity/list',
              })
            })
          }
        }
      },
      handlePreView(e) {
        this.imgUrl = e.url
        this.imgDetailVisible = true
      },
      handleActBackPicSuccess(e) {
        this.result.actBackdrop = e.data.filePath
      },
      handleBackPicRemove() {
        this.result.actBackdrop = ''
      },
      handleActLogoSuccess(e) {
        this.result.actLogo = e.data.filePath
      },
      handleActLogoRemove() {
        this.result.actLogo = ''
      },
      async fetchData() {
        const { data: dicList } = await selectSysDict({
          dictTypeList: ['actPlatform'],
        })
        this.plateForm = dicList[0].dictInfoVoList
      },
      handlePlateFormChange(e) {
        this.result.actPlatform = e.join(',')
      },
      dateChange(e) {
        if (e && e.length) {
          this.result.startTime = e[0]
          this.result.endTime = e[1]
        } else {
          this.result.startTime = ''
          this.result.endTime = ''
        }
      },
      deforeUpload() {
        console.log(this.result.detailsImages)
      },
    },
  }
</script>

<style scoped lang="scss">
  .bottom-btns {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-title {
    height: 50px;
    display: flex;
    align-items: center;
    .line {
      width: 5px;
      height: 16px;
      background-color: rgb(41, 122, 255);
      border-radius: 3px;
    }
    .text {
      font-size: 16px;
      font-weight: 600;
      margin-left: 5px;
    }
  }
  .form-item {
    display: flex;
    align-items: center;
    margin: 20px;
    .item-title {
      width: 78px;
    }
    .input {
      height: 30px;
      width: 200px;
      border: 1px solid #ddd;
      padding-left: 10px;
    }
    .input::placeholder {
      color: #bbb;
    }
    .desc {
      margin-left: 20px;
      color: #ccc;
    }
  }
  .second-item {
    display: flex;
    align-items: center;
    margin-left: 100px;
    .item-title {
      width: 78px;
    }
  }
</style>
