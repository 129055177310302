import request from '@/utils/request'
import { getToken } from '@/utils/token'
// 发布文章
export function addArticle(data) {
  return request({
    url: `/mall/bbs/bbsArticle/add`,
    method: 'post',
    data,
  })
}
// 文章编辑查询数据
export function details(data) {
  return request({
    url: `/mall/bbs/bbsArticle/details`,
    method: 'post',
    data,
  })
}
// 编辑文章保存

export function edit(data) {
  return request({
    url: `/mall/bbs/bbsArticle/edit`,
    method: 'post',
    data,
  })
}

export function getList(data) {
  return request({
    url: '/order/ordOrder/orderPage',
    method: 'post',
    data,
  })
}

export function uploadGoodsImage(data) {
  return request({
    url: '/upms/file/uploadGoodsImage',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
    data,
    //这部分非常重要，否则formdata会被转格式
    transformRequest: [
      function () {
        return data
      },
    ],
    params: data,
  })
}
