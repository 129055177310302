<template>
  <div class="register-container">
    <div class="ym_header"> 提现设置</div>
    <div class="ym_form">
      <el-form ref="formName" label-width="150px" :model="ruleForm"  :rules="rules">
        <el-form-item label="余额提现：">
          <el-input v-model="ruleForm.enable" placeholder="请输入 余额提现"/>
          <div class="text">提现功能未开启时，会员不能提现。</div>
        </el-form-item>
        <el-form-item label="最低提现额度：">
          <el-input v-model="ruleForm.enable" placeholder="请输入 最低提现额度">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="提现手续费：">
          <el-input v-model="ruleForm.enable" placeholder="请输入 确认密码">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="免手续费区间：" >
          <div class="sxf" style="display: flex;">
            <el-input v-model="ruleForm.enable">
              <template slot="append">元</template>
            </el-input>
            <span style="margin: 0 10px;">至</span>
            <el-input v-model="ruleForm.enable">
              <template slot="append">元</template>
            </el-input>
          </div>
          <div class="text">当提现手续费金额在此区间时，不扣除提现手续费</div>
        </el-form-item>
        <el-form-item label="提现审核：" >
          <el-radio-group v-model="ruleForm.radio">
            <el-radio :label="3">手动审核</el-radio>
            <el-radio :label="6">自动审核</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="提现金额：">
          <el-input v-model="ruleForm.enable" placeholder="请输入 提现金额">
            <template slot="append">元</template>
          </el-input>
          <div class="text">提现功能未开启时，会员不能提现。</div>
        </el-form-item>
        <el-form-item label="自动打款：" >
          <el-radio-group v-model="ruleForm.radio">
            <el-radio :label="3">开启</el-radio>
            <el-radio :label="6">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="提现方式：">
          <el-checkbox-group v-model="ruleForm.type">
            <el-checkbox label="提现到支付宝 " name="type"/>
            <el-checkbox label="提现到银行卡" name="type"/>
          </el-checkbox-group>
          <div class="text">提现到银行卡时需要商户手动打款，提现到支付宝需要设置打款账户。</div>
        </el-form-item>
        <el-form-item label="提现到微信钱包：" >
          <el-radio-group v-model="ruleForm.radio">
            <el-radio :label="3">开启</el-radio>
            <el-radio :label="6">关闭</el-radio>
          </el-radio-group>
          <div class="text">提现到微信钱包需要先绑定公众号和小程序支付方式，H5不支持提现到微信钱包。</div>
        </el-form-item>
        <el-form-item label="支持银行卡：" >
            <div class="" style="display: flex;align-items: center;white-space: nowrap;"> <el-input v-model="ruleForm.radio" placeholder="" style="margin-right: 10px;"/>  <el-link type="primary" :underline="false">删除</el-link></div>
            <el-link type="primary" :underline="false">+添加</el-link>
        </el-form-item>
        <el-form-item label="需提交开户行名称：" prop="withdrawBank">
          <el-radio-group v-model="ruleForm.radio">
            <el-radio :label="3">需要</el-radio>
            <el-radio :label="6">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="ym_checkbtn">
      <el-button icon="el-icon-check" type="primary" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import {giteds,putObj} from "@/api/mall/distribution/dostrionconfig.js"
  export default {
    data() {
      return {
        listLoading: true,
        options:[],
        ruleForm:{
          switch:'1',
          binding:'1',
          distribution:'1',
          rebate:"",
          picUrls:[],
          type:[]
        },
        picUrls:'',
        rules: {
          firstRebate: [
            { required: true, message: '请输入一级返佣比例', trigger: 'blur' },
          ],
          secondRebate: [
            { required: true, message: '请输入二级返佣比例', trigger: 'blur' },
          ],
          frozenTime: [
            { required: true, message: '请输入冻结时间', trigger: 'blur' },
          ],
          withdrawMin: [
            { required: true, message: '请输入提现最低金额', trigger: 'blur' },
          ],
          withdrawBank: [
            { required: true, message: '请输入提现银行', trigger: 'blur' },
          ],
          picUrls: [
            { required: true, message: '请上传图片', trigger: 'blur' },
          ],
        }
      }
    },
    computed:{
      ...mapGetters({
        token: 'user/token',
        tenantId : 'user/tenantId',
      }),
    },
    created() {
      this.headers['Authorization'] = `Bearer ${this.token}`
      this.headers['Switch-Tenant-Id'] = `${this.tenantId}`
      this.giteds()
    },
    methods: {
      giteds(){
        giteds({}).then(res=>{
          this.ruleForm=res.data
          res.data.picUrls.forEach((element,ind) => {
            this.fileList.push({"name":ind,"url":element})
          });
        })
      },
      submit(){
        putObj({...this.ruleForm}).then(()=>{
          this.$message.success("修改成功")
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
 .ym_header{
  padding: 20px 0;
  padding-bottom: 10px;
  text-indent: 2em;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 1px dashed #e5e5e5;
 }
 .ym_checkbtn{
  display: flex;
  justify-content: center;
 }
 .ym_form{
  width: 50%;
 }
 .text{
  color: #999;
 }
</style>