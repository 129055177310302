var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container",
      style: {
        padding: `0 ${_vm.controls[_vm.index].margin.both}px`,
        marginTop: `${_vm.controls[_vm.index].margin.top}px`,
        marginBottom: `${_vm.controls[_vm.index].margin.bottom}px`,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "card_multion",
          style: _vm.flexTYpe(_vm.controls[_vm.index].template)[0],
        },
        _vm._l(6, function (yms, nus) {
          return _c(
            "div",
            {
              key: nus,
              class: _vm.flexTYpe(_vm.controls[_vm.index].template)[2]
                ? "card-item cia"
                : "card-item",
              style: _vm.comStyle(
                _vm.controls[_vm.index].ornament.type,
                _vm.controls[_vm.index].ornament.color,
                _vm.controls[_vm.index].elementBgColor,
                _vm.controls[_vm.index].topElementAroundRadius,
                _vm.controls[_vm.index].bottomElementAroundRadius
              ),
            },
            [
              _c(
                "div",
                {
                  staticClass: "cia",
                  class: _vm.flexTYpe(_vm.controls[_vm.index].template)[3]
                    ? "bigpic"
                    : "",
                  style: _vm.flexTYpe(_vm.controls[_vm.index].template)[1],
                },
                [
                  _c("div", { staticClass: "lef_picUrl" }, [
                    _c("img", {
                      style: {
                        borderRadius: `${
                          _vm.controls[_vm.index].imgAroundRadius
                        }px`,
                      },
                      attrs: {
                        alt: "",
                        src: "https://uniapp.v5.niuteam.cn/public/static/img/default_img/square.png",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "ref_listgroup" }, [
                    _c(
                      "div",
                      {
                        staticClass: "thead",
                        style: {
                          fontWeight: _vm.controls[_vm.index].goodsNameStyle
                            .fontWeight
                            ? "bold"
                            : "",
                        },
                      },
                      [_vm._v("商品名称")]
                    ),
                    _c("div", { staticClass: "footer" }, [
                      _c("div", { staticClass: "left" }, [
                        _c(
                          "div",
                          {
                            staticClass: "much",
                            style: {
                              color: `${
                                _vm.controls[_vm.index].priceStyle.mainColor
                              }`,
                            },
                          },
                          [_vm._v(" ¥13.00 ")]
                        ),
                        _c(
                          "s",
                          {
                            staticClass: "Original-price",
                            style: {
                              color:
                                _vm.controls[_vm.index].priceStyle.lineColor,
                            },
                          },
                          [_vm._v(" ¥25.00 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "Original-price",
                            style: {
                              color: `${
                                _vm.controls[_vm.index].saleStyle.color
                              }`,
                            },
                          },
                          [_vm._v(" 已售出10,0件 ")]
                        ),
                      ]),
                      _c("div", { staticClass: "rig" }, [
                        _c(
                          "div",
                          {
                            staticClass: "ym_btn",
                            style: {
                              color:
                                _vm.controls[_vm.index].btnStyle.textColor == ""
                                  ? "#ffffff"
                                  : _vm.controls[_vm.index].btnStyle.textColor,
                              borderRadius: `${
                                _vm.controls[_vm.index].btnStyle.aroundRadius
                              }px`,
                              background: `${
                                _vm.controls[_vm.index].btnStyle.bgColor
                              }`,
                              fontWeight: _vm.controls[_vm.index].btnStyle
                                .fontWeight
                                ? "bold"
                                : "normal",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.controls[_vm.index].btnStyle.text) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }