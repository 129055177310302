<template>
  <vab-query-form-top-panel>
    <el-form ref="form" :inline="true" label-width="89px" :model="queryForm" @submit.native.prevent>
      <el-form-item label="客户姓名">
        <el-input v-model.trim="queryForm.userName" placeholder="请输入客户姓名" />
      </el-form-item>
      <el-form-item label="客户电话">
        <el-input v-model.trim="queryForm.phone" placeholder="请输入客户电话" />
      </el-form-item>
      <el-form-item label="订单编号">
        <el-input v-model.trim="queryForm.orderNo" placeholder="请输入订单编号" />
      </el-form-item>
      <el-form-item label="下单员工">
        <el-input v-model.trim="queryForm.empName" placeholder="请输入下单员工" />
      </el-form-item>
      <el-form-item label="下单时间">
        <el-date-picker v-model.trim="value1" :default-time="['00:00:00', '23:59:59']" end-placeholder="结束日期"
          format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss" @change="changedate" />
      </el-form-item>
      <el-form-item label="质检人员">
        <el-input v-model.trim="queryForm.qualityName" placeholder="请输入质检人员" />
      </el-form-item>
      <el-form-item label="质检状态">
        <el-select v-model="queryForm.quaState" placeholder="请选择质检状态">
          <el-option label="待质检" value="1" />
          <el-option label="已通过" value="2" />
          <el-option label="已驳回" value="3" />
        </el-select>
      </el-form-item>
      <el-form-item label="产品">
        <el-input v-model.trim="queryForm.spuName" placeholder="请输入产品" />
      </el-form-item>
      <el-form-item v-show="!fold" label="质检时间">
        <el-date-picker v-model.trim="value2" :default-time="['00:00:00', '23:59:59']" end-placeholder="结束日期"
          format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss" @change="changedates" />
      </el-form-item>
      <el-form-item label="下单部门">
        <el-cascader v-model.trim="queryForm.mallcategory" change-on-select :options="dataSourceLists"
          :props="{ checkStrictly: true, label: 'orgName', value: 'id' }" @change="getcasc">
          <template slot-scope="{ node, data }">
            <span v-if="!node.isLeaf">{{ data.orgName }}({{ data.children.length }})</span>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" native-type="submit" type="primary" @click="searchquery">查询</el-button>
        <el-button icon="el-icon-refresh-right" native-type="submit" type="warning" @click="clearcont">重置</el-button>
        <el-button type="text" @click="handleFold">
          <span v-if="fold">展开</span>
          <span v-else>合并</span>
          <vab-icon class="vab-dropdown" :class="{ 'vab-dropdown-active': fold }" icon="arrow-up-s-line" />
        </el-button>
      </el-form-item>
    </el-form>
  </vab-query-form-top-panel>
</template>

<script>
import { getAreaThree } from '@/api/mall/common'
import { uuUserSugarRecordQuality } from '@/api/mall/health/seas/seasManger'
import { queryPages } from '@/api/mall/setting/stplabel'
import { queryParentPage } from '@/api/mall/role/orgManage'
// import {selectSysDict} from '@/api/mall/common'
export default {
  data() {
    return {
      areaSelectData: [],
      provinces: null,
      queryForm: {
        menuType: 7, //menuType 1:公海管理  2：部门公海 3：我的客户 , 4:客情数据 5： 我的客情  6：客情审核 7：质检数据 8 我的质检
      },
      fold: true,
      value1: null,
      value2: null,
      value3: null,
      value4: null,
      value5: null,
      dataSourceLists: [],
      options: [
        {
          label: '男',
          key: '1',
        },
        {
          label: '女',
          key: '0',
        },
      ],
      risktatus: [
        {
          label: '有风险',
          key: '1',
        },
        {
          label: '无风险',
          key: '0',
        },
      ],
      rigest: [
        {
          label: '已注册',
          key: '1',
        },
        {
          label: '未注册',
          key: '0',
        },
      ],
      repurchases: [
        {
          label: '未购买',
          key: '0',
        },
        {
          label: '一次购买',
          key: '1',
        },
        {
          label: '两次购买',
          key: '2',
        },
        {
          label: '三次购买',
          key: '3',
        },
        {
          label: '四次购买',
          key: '4',
        },
        {
          label: '五次购买',
          key: '5',
        },
        {
          label: '多次购买',
          key: '6',
        },
      ],
      Membership: null,
    }
  },
  created() {
    this.getAreaThree()
    this.selectSysDict()
    this.fetchData()
  },
  methods: {
    getcasc() { },
    async fetchData() {
      this.listLoading = true
      const parms = {
        current: this.current,
        size: this.size,
      }
      await queryParentPage(parms)
        .then((res) => {
          if (res.data) {
            this.menuListTable = res.data.records
            this.dataSourceLists = res.data.records
            this.dataSourceLists.map((obj) => {
              obj.label = obj.orgName
              obj.value = obj.id
              const fieldArr = obj.children
              const newFieldArr = fieldArr.map((element) => {
                element.label = element.orgName
                element.value = element.id
                element.children.forEach((item) => {
                  item.label = item.orgName
                  item.value = item.id
                  if (item.children != null) {
                    item.children.forEach((el) => {
                      el.label = el.orgName
                      el.value = el.id
                    })
                  }
                })
                return element
              })
              obj.fieldArr = newFieldArr
              return obj
            })
          }
        })
        .catch(() => { })
      this.listLoading = false
    },
    clearcont() {
      this.queryForm = {}
      this.value1 = []
      this.value2 = []
      this.$emit('receive', false)
      this.$baseEventBus.$emit('inspection')
    },
    searchquery() {
      this.queryForm.menuType = 7
      uuUserSugarRecordQuality({ ...this.queryForm, size: 10, current: 1 }).then((res) => {
        if (res.data.records) {
          this.$emit('receive', res.data)
        } else {
          this.$message.error('查询为空')
        }
      })
    },
    selectSysDict() {
      // selectSysDict({"dictTypeList":["orderStatus"]}).then(res=>{
      //   // this.logisticsKey=res.data[0].dictInfoVoList
      // })
    },
    changedate() {
      this.queryForm.startPayTime = this.value1[0]
      this.queryForm.endPayTime = this.value1[1]
      console.log(this.queryForm)
    },
    changedates() {
      this.queryForm.startTime = this.value2[0]
      this.queryForm.endTime = this.value2[1]
    },
    daterange() {
      this.queryForm.buyStartTime = this.value3[0]
      this.queryForm.buyEndTime = this.value3[1]
    },
    firstStartTime() {
      this.queryForm.firstStartTime = this.value4[0]
      this.queryForm.firstEndTime = this.value4[1]
    },
    lastStartTime() {
      this.queryForm.lastStartTime = this.value5[0]
      this.queryForm.lastEndTime = this.value5[1]
    },
    addres(e) {
      this.queryForm.province = e[0]
      this.queryForm.city = e[1]
      this.queryForm.county = e[2]
    },
    async getAreaThree() {
      await getAreaThree()
        .then((res) => {
          if (res.data) {
            this.areaSelectData = res.data
          }
        })
        .catch(() => { })
    },
    queryPages(e) {
      queryPages({ labelType: e }).then((res) => {
        if (e == 4) {
          this.Membership = res.data
        }
      })
    },
    handleFold() {
      this.fold = !this.fold
      // this.handleHeight()
    },
    reset() {
      this.$emit('receive', false)
    },
    searchbtn() {
      if (this.value1) {
        console.log(this.value1)
        this.form.startPayTime = this.value1[0]
        this.form.endPayTime = this.value1[1]
      }
      if (this.value2) {
        this.form.startTime = this.value2[0]
        this.form.endTime = this.value2[1]
      }
      uuUserSugarRecordQuality({ ...this.form }).then((res) => {
        this.$emit('receive', res.data)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {

  .el-input,
  .el-date-editor {
    width: 210px;
  }

  .el-range-input,
  .el-range-separator {
    font-size: 12px;
  }

  .el-range-separator {
    width: 20px;
  }
}
</style>