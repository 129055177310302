<template>
  <div style="min-height: 79vh; padding: 10px">
    <div class="ym_header">分销设置</div>
    <el-divider/>
    <div class="ym_form">
      <el-form ref="formName" label-width="150px" :model="ruleForm"  :rules="rules">
        <el-form-item label="分销启用：">
          <el-radio-group v-model="ruleForm.enable">
            <el-radio label="1">开启</el-radio>
            <el-radio label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分销关系绑定：" >
          <el-radio-group v-model="ruleForm.bindType">
            <el-radio label="1">所有用户</el-radio>
            <el-radio label="0">新用户</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分销模式：" >
          <el-radio-group v-model="ruleForm.distributionModel">
            <el-radio label="1">指定分销</el-radio>
            <el-radio label="2">人人分销</el-radio>
            <el-radio label="3">满额分销</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="一级返佣比例：" prop="firstRebate">
          <el-input-number v-model="ruleForm.firstRebate" controls-position="right" :min="1" />
        </el-form-item>
        <el-form-item label=" 二级返佣比例：" prop="secondRebate">
          <el-input-number v-model="ruleForm.secondRebate"  controls-position="right" :min="1"/>
        </el-form-item>
        <el-form-item label="冻结时间：" prop="frozenTime">
          <el-input-number v-model="ruleForm.frozenTime"  controls-position="right" :min="1"/>
        </el-form-item>
        <el-form-item label="提现最低金额：" prop="withdrawMin">
          <el-input-number v-model="ruleForm.withdrawMin" controls-position="right" :min="1"/>
        </el-form-item>
        <el-form-item label="提现银行：" prop="withdrawBank">
          <el-input v-model="ruleForm.withdrawBank" />
        </el-form-item>
        <el-form-item label="分销图片" prop="picUrls">
          <el-upload
            ref="upload"
            :action="action"
            :before-upload="bfUpload"
            :class="{
              hide: hideUpload,
            }"
            :file-list="fileList"
            :headers="headers"
            list-type="picture-card"
            :on-change="handleChange"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"  
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog append-to-body :visible.sync="dialogVisible">
            <img alt="" :src="picUrls" width="100%" />
          </el-dialog>
        </el-form-item>
        <div class="ym_checkbtn">
          <el-button icon="el-icon-check" type="primary" @click="submit">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import {giteds,putObj} from "@/api/mall/distribution/dostrionconfig.js"
  import { uploadURL } from '@/config'
  export default {
    data() {
      return {
        listLoading: true,
        dialogVisible:false,
        ruleForm:{
          switch:'1',
          binding:'1',
          distribution:'1',
          rebate:"",
          picUrls:[],
        },
        action:uploadURL,
        hideUpload: false,
        headers: {},
        picUrls:'',
        fileList: [],
        rules: {
          firstRebate: [
            { required: true, message: '请输入一级返佣比例', trigger: 'blur' },
          ],
          secondRebate: [
            { required: true, message: '请输入二级返佣比例', trigger: 'blur' },
          ],
          frozenTime: [
            { required: true, message: '请输入冻结时间', trigger: 'blur' },
          ],
          withdrawMin: [
            { required: true, message: '请输入提现最低金额', trigger: 'blur' },
          ],
          withdrawBank: [
            { required: true, message: '请输入提现银行', trigger: 'blur' },
          ],
          picUrls: [
            { required: true, message: '请上传图片', trigger: 'blur' },
          ],
        }
      }
    },
    computed:{
      ...mapGetters({
        token: 'user/token',
        tenantId : 'user/tenantId',
      }),
    },
    created() {
      this.headers['Authorization'] = `Bearer ${this.token}`
      this.headers['Switch-Tenant-Id'] = `${this.tenantId}`
      this.giteds()
    },
    methods: {
      bfUpload(file) {
        console.log(file)
        if (
          'image/png' == file.type ||
          'image/jpeg' == file.type ||
          'image/jpg' == file.type
        ) {
          console.log()
        } else {
          this.ME('图片上传失败,请上传png,jpeg,jpg格式')
          return false
        }
      },
      handleChange(file, fileList) {
        this.hideUpload = fileList.length >= this.limitCount
      },
      handlePictureCardPreview(file) {
        this.picUrls = file.url
        this.dialogVisible = true
      },
      handleRemove(file,fileList) {
        this.ruleForm.picUrls=[]
        fileList.forEach(item=>{
          console.log(item.url)
          this.ruleForm.picUrls.push(item.url)
        })
      },
      giteds(){
        giteds({}).then(res=>{
          this.ruleForm=res.data
          res.data.picUrls.forEach((element,ind) => {
            this.fileList.push({"name":ind,"url":element})
          });
        })
      },
      handleSuccess(response) {
        this.ruleForm.picUrls.push(response.data.filePath)
      },
      submit(){
        putObj({...this.ruleForm}).then(()=>{
          this.$message.success("修改成功")
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
 .ym_header{
  padding: 20px 0;
  padding-bottom: 10px;
  text-indent: 2em;
  font-size: 16px;
  font-weight: bold;
 }
 .ym_checkbtn{
  display: flex;
  justify-content: center;
 }
</style>