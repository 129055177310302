var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-table",
        { attrs: { border: "", data: _vm.tablist, "max-height": "300" } },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              type: "index",
              width: "55",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "提交时间", prop: "submitTime" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "客情备注",
              prop: "remark",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "提交部门",
              prop: "postName",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "提交员工", prop: "realName" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "客情部门",
              prop: "afterPostName",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "客情员工",
              prop: "afterRealName",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "客情状态",
              prop: "lebalName",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "处理状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "div",
                      [
                        row.afterState == 1
                          ? _c("el-tag", { attrs: { type: "succes" } }, [
                              _vm._v("处理中"),
                            ])
                          : _vm._e(),
                        row.afterState == 2
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("已处理"),
                            ])
                          : _vm._e(),
                        row.afterState == 3
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("驳回"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "处理时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", [
                      row.afterState == "1" ? _c("span") : _vm._e(),
                      row.afterState == "2"
                        ? _c("span", [_vm._v(_vm._s(row.handleTime))])
                        : _vm._e(),
                      row.afterState == "3"
                        ? _c("span", [_vm._v(_vm._s(row.handleTime))])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", fixed: "right", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.hand(row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": 1,
          layout: "total, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("showhand", {
        attrs: { rowst: _vm.rolis, showhide: _vm.showl },
        on: { closehand: _vm.closeh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }