import { render, staticRenderFns } from "./index.vue?vue&type=template&id=11fa7293&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11fa7293",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\健康约-后管-前端\\health-front-backend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11fa7293')) {
      api.createRecord('11fa7293', component.options)
    } else {
      api.reload('11fa7293', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=11fa7293&scoped=true", function () {
      api.rerender('11fa7293', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mall/health/afterEvent/index.vue"
export default component.exports