var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "textTool" },
    [
      _c("div", { staticClass: "toolTit" }, [
        _c("div", { staticClass: "lef" }, [_vm._v("搜索框")]),
        _c("div", { staticClass: "ref" }, [
          _c("div", { staticClass: "switch" }, [
            _c(
              "div",
              {
                class: _vm.checknum == 1 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(1)
                  },
                },
              },
              [_vm._v("内容")]
            ),
            _c(
              "div",
              {
                class: _vm.checknum == 2 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(2)
                  },
                },
              },
              [_vm._v("样式")]
            ),
          ]),
        ]),
      ]),
      _vm.checknum == 1
        ? _c("div", { staticClass: "content_msg" }, [
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("搜索风格")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("搜索风格")]),
              _c(
                "div",
                { staticClass: "check-tit" },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.activeComponents[_vm.indexnumer].searchStyle,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.activeComponents[_vm.indexnumer],
                            "searchStyle",
                            $$v
                          )
                        },
                        expression: "activeComponents[indexnumer].searchStyle",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("样式1"),
                      ]),
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v("样式2"),
                      ]),
                      _c("el-radio-button", { attrs: { label: 3 } }, [
                        _vm._v("样式3"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm.activeComponents[_vm.indexnumer].searchStyle != 1
              ? _c(
                  "div",
                  { staticClass: "itemBox", staticStyle: { height: "100px" } },
                  [
                    _c("div", { staticClass: "Tit" }, [_vm._v("背景图片")]),
                    _c(
                      "div",
                      { staticClass: "check-tit" },
                      [
                        _c("uploadpic", {
                          attrs: { picurl: [{ url: `${_vm.imageurl}` }] },
                          on: { backkuck: _vm.check },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("文本位置")]),
              _c(
                "div",
                { staticClass: "check-tit" },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.activeComponents[_vm.indexnumer].textAlign,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.activeComponents[_vm.indexnumer],
                            "textAlign",
                            $$v
                          )
                        },
                        expression: "activeComponents[indexnumer].textAlign",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "left" } }, [
                        _vm._v("居左"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm.activeComponents[_vm.indexnumer].searchStyle == 3
              ? _c("div", { staticClass: "itemBox" }, [
                  _c("div", { staticClass: "Tit" }, [_vm._v("跳转链接")]),
                  _c(
                    "div",
                    {
                      staticClass: "conten_sacnner",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "right",
                        width: "100%",
                        "margin-top": "5px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "link_navlists",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            color: "#1780ff",
                          },
                          on: {
                            click: function ($event) {
                              _vm.linkurl = true
                            },
                          },
                        },
                        [
                          _vm.activeComponents[_vm.indexnumer].searchLink
                            .name != ""
                            ? _c(
                                "span",
                                { staticStyle: { color: "#1780ff" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeComponents[_vm.indexnumer]
                                        .searchLink.title
                                    )
                                  ),
                                ]
                              )
                            : _c(
                                "span",
                                { staticStyle: { color: "#1780ff" } },
                                [_vm._v("请选择链接")]
                              ),
                          _c("i", { staticClass: "el-icon-arrow-right" }),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "itemBox" },
              [
                _c("div", { staticClass: "Tit" }, [_vm._v("显示位置")]),
                _c(
                  "el-radio-group",
                  {
                    staticStyle: { "font-weight": "500" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].positionWay,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "positionWay",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].positionWay",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "static" } }, [
                      _vm._v("正常显示"),
                    ]),
                    _c("el-radio", { attrs: { label: "fixed" } }, [
                      _vm._v("滚动至底部"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("搜索内容")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("搜索内容")]),
              _c(
                "div",
                { staticClass: "check-tit" },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "20",
                      value: _vm.activeComponents[_vm.indexnumer].title,
                    },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].title,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "title",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].title",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.checknum == 2
        ? _c("div", { staticClass: "content_msg" }, [
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("搜索样式")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("框体样式")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _vm.activeComponents[_vm.indexnumer].borderType == 1
                    ? _c("div", { staticClass: "lef" }, [_vm._v("方型")])
                    : _vm._e(),
                  _vm.activeComponents[_vm.indexnumer].borderType == 2
                    ? _c("div", { staticClass: "lef" }, [_vm._v("圆型")])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          model: {
                            value:
                              _vm.activeComponents[_vm.indexnumer].borderType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.activeComponents[_vm.indexnumer],
                                "borderType",
                                $$v
                              )
                            },
                            expression:
                              "activeComponents[indexnumer].borderType",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("样式1"),
                          ]),
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v("样式2"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("文本颜色")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(_vm.activeComponents[_vm.indexnumer].textColor)
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.activeComponents[_vm.indexnumer].textColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "textColor",
                              $$v
                            )
                          },
                          expression: "activeComponents[indexnumer].textColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[_vm.indexnumer].textColor =
                                "#333333"
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("背景颜色")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(
                        _vm.activeComponents[_vm.indexnumer].elementBgColor
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer].elementBgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "elementBgColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].elementBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[
                                _vm.indexnumer
                              ].elementBgColor = ""
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("组件样式")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("底部背景")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(_vm.activeComponents[_vm.indexnumer].pageBgColor)
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer].pageBgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "pageBgColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].pageBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[_vm.indexnumer].pageBgColor =
                                ""
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "alert-msg" }, [
              _vm._v("底部背景包含边距和圆角"),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("组件背景")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(
                        _vm.activeComponents[_vm.indexnumer].componentBgColor
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer]
                              .componentBgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "componentBgColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].componentBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[
                                _vm.indexnumer
                              ].componentBgColor = ""
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("上边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 100, min: 0, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.top,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "top",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.top",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 100, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.top,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "top",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.top",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("下边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 100, min: 0, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "bottom",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.bottom",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 100, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "bottom",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.bottom",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("左右边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 20, min: 0, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.both",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 20, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.both",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("linkdialog", {
        attrs: { linkshow: _vm.linkurl },
        on: { closeurl: _vm.closelink },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }