<template>
  <div class="rank">
    <el-card class="rank-card" shadow="hover">
      <template #header>
        <div style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">
            <vab-icon icon="align-top" />
            本周业绩
          </div>
          <!-- <div class="ref" @click="dialogVisible = true">更多</div> -->
        </div>
      </template>
      <vab-chart class="rank-echart" :init-options="initOptions" :option="option" theme="vab-echarts-theme" />
    </el-card>
    <el-dialog :before-close="handleClose" title="全部排行" :visible.sync="dialogVisible" width="60%">
      <span>
        <div class="searc">
          <el-form :inline="true" label-width="80px" :model="search">
            <el-form-item label="员工姓名：">
              <el-input v-model="search.realName" placeholder="请输入搜索员工姓名" />
            </el-form-item>
            <el-form-item label="排行时间：">
              <el-date-picker
                v-model="value2"
                align="right"
                :default-time="['00:00:00', '23:59:59']"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                type="daterange"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
            <el-form-item label="">
              <el-button icon="el-icon-search" type="primary" @click="searchtab">搜索</el-button>
              <el-button icon="el-icon-refresh-right" type="warning">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="table_info">
          <el-button style="margin-bottom: 10px" type="primary" @click="handleDownload">导出 Excel</el-button>
          <el-table border :data="tableData" style="width: 100%">
            <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="50" />
            <el-table-column align="center" label="销售业绩" prop="paymentPrice" show-overflow-tooltip />
            <el-table-column align="center" label="销售员工" prop="realName" show-overflow-tooltip>
              <template #default="scope">
                <div class="profile">{{ scope.row.realName }}{{ scope.row.profile == '' ? '' :'(' + scope.row.profile + ')'  }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="所属部门" prop="postName" show-overflow-tooltip />
          </el-table>
          <el-pagination
            background
            :current-page="1"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import dayjs from 'dayjs';
  import VabChart from '@/extra/VabChart'
  import { selectEmpOrderList, selectorder } from '@/api/mall/health/boardperformc/index'
  import { exportEmpOrder } from '@/api/mall/order/order'
  export default {
    components: { VabChart },
    data() {
      return {
        search: {
          current: 1,
          size: 10,
          realName: '',
        },
        tableData: [],
        total: 0,
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
        dialogVisible: false,
        value2: null,
        initOptions: {
          renderer: 'svg',
        },
        option: {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'z-index:1',
          },
          grid: {
            top: '0%',
            left: '2%',
            right: '20%',
            bottom: '0%',
            containLabel: true,
          },
          xAxis: [
            {
              splitLine: {
                show: false,
              },
              type: 'value',
              show: false,
            },
          ],
          yAxis: [
            {
              splitLine: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              type: 'category',
              axisTick: {
                show: false,
              },
              data: ['fc', 'fge0', 'fe'],
            },
          ],
          series: [
            {
              name: '累计业绩',
              type: 'bar',
              barWidth: 15,
              label: {
                show: true,
                position: 'right',
                color: '#1890FF',
                fontSize: 12,
                formatter: ({ data }) => {
                  return `${data}元`
                },
              },
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2,
              },
              data: [20, 50, 11],
            },
          ],
        },
      }
    },
    created() {
      this.selectEmpOrderList()
      this.selectEmpOrderPage()
    },
    methods: {
      searchtab() {
        if (this.value2 != null) {
          this.search.startTime = this.value2[0]
          this.search.endTime = this.value2[1]
        }
        selectorder({ ...this.search }).then((res) => {
          this.tableData = res.data.records
        })
      },
      selectEmpOrderPage() {
        let begintime = new Date()
        const year = begintime.getFullYear()
        const month = begintime.getMonth() + 1 // 月份从0开始，需要加1
        const day = begintime.getDate()
        let end = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} 23:59:59`
        const begins = new Date()
        begins.setTime(begins.getTime() - 3600 * 1000 * 24 * 7)
        let begin=dayjs(begins).format('YYYY-MM-DD HH:mm:ss');
        const lts = {
          current: this.search.current,
          size: this.search.size,
          startTime: begin,
          endTime: end,
          realName: this.search.realName,
        }
        selectorder({ ...lts }).then((res) => {
          console.log(res.data.records)
          this.tableData = res.data.records
        })
      },
      handleClose() {
        this.dialogVisible = false
      },
      selectEmpOrderList() {
        selectEmpOrderList({}).then((res) => {
          this.option.yAxis[0].data = res.data.nameData
          this.option.series[0].data = res.data.PayData
        })
      },
      handleCurrentChange(e) {
        this.search.current = e
        this.selectEmpOrderPage()
      },
      handleSizeChange(e) {
        this.search.size = e
        this.selectEmpOrderPage()
      },

      //导出excel
      async handleDownload() {
        const listQueryData = {}
        ;(listQueryData.current = this.search.current),
          (listQueryData.size = this.search.size),
          (listQueryData.startTime = this.search.begin),
          (listQueryData.endTime = this.search.end)
        const res = await exportEmpOrder(listQueryData)
        if (!res) {
          return
        }
        console.log(res)
        this.saveBlod(res)
      },
      saveBlod(res) {
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        const fileName = '员工排行明细表.xls'
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.href = URL.createObjectURL(blob)
          elink.style.display = 'none'
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__header {
      border-bottom: 1px solid #e5e5e5;
    }
  }
</style>
