<template>
  <div ref="custom-table" class="custom-table-container" :class="{ 'vab-fullscreen': isFullscreen }">
    <searchform @receive="receiverow" />
    <div class="flexk">
      <div style="float: left; margin-bottom: 15px">
        <!-- <el-button class="addBtn" type="primary" @click="$router.push({ path: '/call/batch', query: { type: 1 } })">批量导入</el-button> -->
        <el-button type="primary" @click="exportUuUserSugar">批量导出</el-button>
        <el-button type="" @click="distrtions">分配</el-button>
        <el-button type="" @click="recyclea">回收</el-button>
        <el-button type="primary" @click="appoints">移交</el-button>
      </div>
      <div class="ref" style="float: right">
        <el-button style="margin: 0 10px 10px 0 !important" @click="clickFullScreen">
          <vab-icon :icon="isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'" />
          表格全屏
        </el-button>
        <el-popover ref="popover" popper-class="custom-table-checkbox" trigger="hover">
          <el-radio-group v-model="lineHeight">
            <el-radio label="medium">大</el-radio>
            <el-radio label="small">中</el-radio>
            <el-radio label="mini">小</el-radio>
          </el-radio-group>
          <template #reference>
            <el-button style="margin: 0 10px 10px 0 !important">
              <vab-icon icon="line-height" />
              表格尺寸
            </el-button>
          </template>
        </el-popover>
        <!-- <el-popover popper-class="custom-table-checkbox" trigger="hover">
          <el-checkbox-group v-model="checkList">
            <vab-draggable v-bind="dragOptions" :list="columns" @change="handleChange">
              <div v-for="(item, index) in columns" :key="item + index">
                <vab-icon icon="drag-drop-line" />
                <el-checkbox :disabled="item.disableCheck === true" :label="item.label" @change="handleChange">
                  {{ item.label }}
                </el-checkbox>
              </div>
            </vab-draggable>
          </el-checkbox-group>
          <template #reference>
            <el-button icon="el-icon-setting" style="margin: 0 0 10px 0 !important">可拖拽列设置</el-button>
          </template>
        </el-popover> -->
      </div>
    </div>
    <el-table ref="tableSort" v-loading="loading" border :data="data" max-height="600" :size="lineHeight" @selection-change="selectd">
      <el-table-column align="center" type="selection" width="55" />
      <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="50" />
      <el-table-column
        v-for="(item, index) in listContent"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :show-overflow-tooltip="item.tooltip"
        :width="item.width"
      >
        <template #default="{ row }">
          <el-tooltip class="item" :content="row[item.prop]" :disabled="item.tooltip" effect="dark" placement="top-end">
            <span style="white-space: nowrap">{{ row[item.prop] }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" label="分配时间" prop="allotTime" width="200" />
      <el-table-column align="center" fixed="right" label="操作" width="180">
        <template #default="{ row }">
          <el-button size="default" type="text" @click="showedis(row)">回访</el-button>
          <el-button size="default" type="text" @click="historical(row)">历史</el-button>
          <!-- <el-button size="medium" type="text" @click="reigset(row)">注册</el-button> -->
          <!-- <el-button size="medium" type="text" @click="appoint(row)">移交</el-button> -->
          <!-- <el-dropdown>
            <span class="el-dropdown-link" style="margin: 0 10px; color: #1890ff">更多</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button size="default" type="text" @click="history(row)">历史</el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button size="medium" type="text" @click="appoint(row)">移交</el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button size="medium" type="text" @click="appoint(row)">客情</el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button size="medium" type="text" @click="place(row)">下单</el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </template>
      </el-table-column>
    </el-table>
    <table-edit ref="edit" v-model="form" :config="config" :options="options" :rules="rules" @fileStatus="fileupload" @submit="submit" />
    <el-pagination
      background
      :current-page="queryForm.current"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="queryForm.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <handover :hanuser="handeuserinfo" :showtab="appointDialog" @closover="colsove" />
    <distrtion :shodist="distrtionshow" :userid="selecuserid" @closedistr="closdistr" />
    <histroy :showhislist="historylist" :showhist="historyshow" @closehis="colsehistory" />
    <ediform :edilist="shotablist" :edirow="edisrow" :showedit="showedi" @clearedi="cleareit" />
    <transfer :hanuser="handeuserinfos" :showtab="opentab" @closover="colsoves" />
    <historicaldata :hanuser="idd" :showtab="opentabs" @closover="colsovesz" />
  </div>
</template>
<script>
import { queryPage, shopinfo, getAgentMsg, seaExport, exportUuUserSugarxc } from '@/api/mall/health/seas/seasManger'
import { options, rules, config } from '@/const/seas/seasManger'
import { Recordrecycle } from '@/api/mall/seas/index'
import { deepClone } from '@/utils/util'
import { mapGetters } from 'vuex'
import distrtion from './components/distribution.vue'
import histroy from './components/history.vue'
import handover from './components/handover.vue'
import searchform from './components/search.vue'
import ediform from './components/index.vue'
import transfer from './components/transfer.vue'
import historicaldata from './components/historicaldata.vue'
//表格内容
// import VabDraggable from 'vuedraggable'
import { crmColumnDropadd, crmColumnDropqueryPage } from '@/api/mall/tableMove/index'

// const sdk = require('./components/js/sdk.min.js')
let sdk = require('./components/js/sdk.min.js')
export default {
  components: {
    distrtion,
    histroy,
    handover,
    searchform,
    ediform,
    transfer,
    historicaldata,
    // VabDraggable,
  },

  data() {
    return {
      listContent: [],
      listNew: [], //暂存list，用于多选时赋值
      searchForm: { current: 1, size: 10 },
      total: 0,
      data: [],
      isSearch: false,
      options,
      rules,
      config,
      opentab: false,
      opentabs: false,
      form: {},
      // 当前点击的指定id
      sysId: '', //系统ID
      queryForm: {
        current: 1,
        size: 10,
      },
      checkdate: null,
      handeuserinfos: null,
      appointDialog: false,
      handeuserinfo: null,
      distrtionshow: false,
      historyshow: false,
      historylist: null,
      selecuserid: null, //用户选中id
      phoneUa: null,
      showedi: false,
      shotablist: null,
      edisrow: null,
      idd: null,
      //增加操作
      isFullscreen: false,
      height: this.$baseTableHeight(1),
      lineHeight: 'mini',
      checkList: ['创建时间', '姓名', '电话1', '电话2', '渠道', '地址', '备注1', '备注2', '所属部门', '所属员工'],
      columns: [
        {
          label: '创建时间',
          width: '200',
          prop: 'createDate',
          tooltip: true,
        },
        {
          label: '姓名',
          width: '130',
          prop: 'userName',
          tooltip: true,
        },
        {
          label: '电话1',
          width: '150',
          prop: 'phone',
          tooltip: true,
        },
        {
          label: '电话2',
          width: '150',
          prop: 'phonev',
          tooltip: true,
        },
        {
          label: '渠道',
          width: '150',
          prop: 'channel',
          tooltip: true,
        },
        {
          label: '地址',
          width: '220',
          prop: 'addressDetail',
          tooltip: false,
        },
        {
          label: '备注1',
          width: '220',
          tooltip: false,
          prop: 'remark',
        },
        {
          label: '备注2',
          width: '220',
          tooltip: false,
          prop: 'remarkContent',
        },
        {
          label: '所属部门',
          width: '260',
          tooltip: false,
          prop: 'postName',
        },
        {
          label: '所属员工',
          width: '130',
          prop: 'realName',
          tooltip: true,
        },
      ],
    }
  },

  computed: {
    dragOptions() {
      return {
        animation: 600,
        group: 'description',
      }
    },
    ...mapGetters({
      userId: 'user/userId',
      type: 'user/type',
      userinfo: 'user/userinfo',
    }),
    finallyColumns() {
      return this.columns.filter((item) => this.checkList.includes(item.label))
    },
  },

  // eslint-disable-next-line vue/order-in-components
  filters: {
    //性别（1：男，2：女，0：未知）
    getSex(val) {
      let str = ''
      switch (val) {
        case 1:
          str = '男'
          break
        case 2:
          str = '女'
          break
        case 0:
          str = '未知'
          break
      }
      return str
    },
  },

  created() {
    this.list()
    this.getUserList()
    let comId = localStorage.getItem('menuinfo')
    this.crmColumnDropqueryPage(comId)
    this.getContent()
    // this.callPhone()
  },

  mounted() {
    this.$baseEventBus.$on('Qualityinspection', () => {
      this.list()
      this.getUserList()
      this.showedi = false
    })
  },

  methods: {
    handleChange() {
      this.crmColumnDropadd()
    },
    getContent() {
      let result = this.columns.filter((column) => this.checkList.includes(column.label))
      this.listContent = result
    },
    // 批量移交
    appoints() {
      let list = []
      if (this.selecuserid != null && this.selecuserid.length > 0) {
        this.selecuserid.forEach((item) => {
          list.push(item.id)
        })
        this.handeuserinfos = list
        this.opentab = true
      } else {
        this.$message.error('请选择移交用户')
      }
    },
    // 历史数据
    historical(row) {
      this.idd = row.id
      this.opentabs = true
    },
    colsovesz() {
      this.opentabs = false
    },
    colsoves() {
      this.opentab = false
    },
    crmColumnDropadd() {
      let columnDropPoList = this.columns.map((item, index) => {
        return Object.assign({}, item, { sort: index })
      })
      let comId = localStorage.getItem('menuinfo')
      crmColumnDropadd({ columnDropPoList, comId, type: '0' }).then(() => {})
    },
    crmColumnDropqueryPage(comId) {
      crmColumnDropqueryPage({ current: 1, size: 30, comId: comId }).then((res) => {
        if (res.data.records != '' && res.data.records.length != 0) {
          this.columns = res.data.records
        }
      })
    },
    //表格全屏操作
    clickFullScreen() {
      this.isFullscreen = !this.isFullscreen
      this.handleHeight()
    },
    handleHeight() {
      if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
      else this.height = this.$baseTableHeight(1)
    },

    showedis(row) {
      this.edisrow = row.id
      this.shotablist = row
      this.showedi = true
    },
    cleareit() {
      this.showedi = false
    },
    // 导出客户列表
    exportUuUserSugar() {
      if (this.selecuserid != null && this.selecuserid.length > 0) {
        let ids = []
        this.selecuserid.forEach((item) => {
          console.log(item, 'item')
          ids.push(item.id)
        })
        console.log(ids, 'ids')
        seaExport({ ids }).then((res) => {
          console.log(res, '9999999')
          this.saveBlod(res)
        })
      } else {
        this.$message.error('请选择导出用户')
      }
    },
    saveBlod(res) {
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      const fileName = '客户数据表.xls'
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const elink = document.createElement('a')
        elink.download = fileName
        elink.href = URL.createObjectURL(blob)
        elink.style.display = 'none'
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName)
      }
    },
    // 导出客户根据查询条件导出(导出客户)
    async handleExUuUserSugarxc() {
      this.downloadLoading = true
      const listQueryData = {}
      listQueryData.status = 1
      const res = await exportUuUserSugarxc(listQueryData)
      if (!res) {
        return
      }
      console.log(res)
      this.saveBlod(res)
      this.downloadLoading = false
    },
    //
    receiverow(row) {
      if (row != false) {
        this.data = row.records
        this.total = row.total
      } else {
        this.list()
      }
    },
    place(row) {
      if (row.bindStatus == 1) {
        this.$router.push({ path: '/activity/activity-customers/customers', query: { userlist: row } })
      } else {
        this.$message.error('该数据未注册请注册后下单')
      }
    },
    reigset(row) {
      if (row.bindStatus == 1) {
        this.$message.error('该数据已绑定会员')
      } else {
        this.$router.push({ path: '/drag/registration', query: { date: row } })
      }
    },
    callPhone() {},
    regisetPhone(row) {
      this.phoneUa = new sdk.SDK.cxWebPhone({
        userName: row.username,
        password: row.password,
        server: row.real,
        traceSip: false,
        builtinEnabled: true,
        audioElement: 'remoteAudio',
        in: {
          terminated: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          accepted: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          bye: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          rejected: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          failed: (response, cause, type) => this.webPhoneEvent(response, cause, type),
        },
        ua: {
          invite: (response, type) => this.webPhoneUa(response, type),
          registered: (response, type) => this.webPhoneUa(response, type),
          unregister: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          registrationFailed: (response, cause, type) => this.webPhoneEvent(response, type),
          connecting: (response, type) => this.webPhoneUa(response, type),
          connected: (response, type) => this.webPhoneUa(response, type),
          disconnecting: (response, type) => this.webPhoneUa(response, type),
          disconnected: (response, type) => this.webPhoneUa(response, type),
        },
      })
    },
    callphones(phone) {
      //坐席获取注册 SIP 信息
      getAgentMsg({ username: '1000' }).then((res) => {
        if (res.status == 200) {
          this.regisetPhone(res.data)
          this.$confirm('是否拨打该号码', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            new sdk.SDK.callWebPhone({
              phoneNumber: phone,
              out: {
                progress: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                accepted: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                rejected: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                failed: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                terminated: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                bye: (response, cause, type) => this.webPhoneEvent(response, cause, type),
              },
            })
          })
        } else {
          this.$message.error(res.msg)
          return
        }
      })
      //
    },
    webPhoneEvent(response, cause, type, d) {
      console.warn('type,response, cause', type, response, cause, '----', d)
    },
    webPhoneUa(response, type) {
      console.warn('type,response', type, response)
      if (type === 'ua.invite') {
        var msg = response.remoteIdentity.uri.user + '来电了，是否接听'
        if (confirm(msg) == true) {
          response.answer()
        } else {
          response.reject()
        }
      }
    },
    selectd(e) {
      this.selecuserid = e
    },
    history(row) {
      this.historylist = [JSON.parse(JSON.stringify(row))]
      this.historyshow = true
    },
    colsehistory() {
      this.historyshow = false
    },
    recyclea() {
      let allot = []
      if (this.selecuserid) {
        this.selecuserid.forEach((item) => {
          allot.push(item.id)
        })
        if (this.selecuserid != null) {
          this.$confirm('是否回收该用户', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              Recordrecycle({ allot }).then((res) => {
                if (res.status == 200) {
                  this.$message.success('回收成功')
                  this.list()
                } else {
                  this.$message.error(res.msg)
                }
              })
            })
            .catch(() => {
              this.$message('您已取消回收')
            })
        } else {
          this.$message.error('请选择回收用户')
        }
      } else {
        this.$message.error('请选择回收用户')
      }
    },
    distrtions() {
      if (this.selecuserid != null && this.selecuserid.length > 0) {
        if (this.selecuserid[0].realName == null) {
          this.distrtionshow = true
        } else {
          this.$message.error('此数据已被分配')
        }
      } else {
        this.$message.error('请选择分配用户')
      }
    },
    closdistr() {
      this.selecuserid = null
      this.distrtionshow = false
      this.loading = true
      setTimeout(() => {
        this.list()
        this.$message.success('分配成功')
        this.loading = false
      }, 2000)
    },
    handleCurrentChange(val) {
      this.queryForm.current = val
      console.log(this.queryForm)
      this.list()
    },
    handleSizeChange(val) {
      this.queryForm.size = val
      console.log(this.queryForm)
      this.list()
    },
    async getUserList() {
      // console.log('mall/shopinfo/page')
      shopinfo().then((res) => {
        console.log(res)
        const {
          data: { records },
        } = res
        console.log(records)
        this.appointData = records
      })
    },
    list() {
      // type: 0, //账号类型：-1、系统管理员；1、租户管理员；2、店铺管理员 3服务商
      // sysId: '', //系统ID
      queryPage({
        ...options,
        ...this.queryForm,
        // type: this.type,
        // sysId: this.userId,
        menuType: 1, //1:公海管理  2：部门公海 3：我的客户 , 4:客情数据 5： 我的客情  6：客情审核 7：质检数据 8 我的质检
      }).then((res) => {
        const {
          data: { records, total },
        } = res
        console.log(records)
        this.data = records
        this.total = total
        console.log(res)
      })
    },
    appoint(row) {
      this.handeuserinfo = row
      this.appointDialog = true
    },
    colsove() {
      this.appointDialog = false
    },
    handleAdd() {
      this.$refs['edit'].showEdit()
    },
    fileupload(msg, result) {
      console.log('成功回调')
      console.log(msg, result)
    },
    submit() {
      console.log('表单数据user', this.form)
    },
    show(row) {
      this.form = deepClone(row)
      this.$nextTick(() => {
        this.$refs['edit'].showEdit('show')
      })
    },
    edit(row) {
      this.form = deepClone(row)
      this.$nextTick(() => {
        this.$refs['edit'].showEdit('edit')
      })
    },
  },
}
</script>

<style scoped></style>