<template>
  <div class="comprehensive-table-container">
    <div class="m-b">
      <el-button icon="el-icon-plus" type="primary" @click="handleAdd">新增</el-button>
    </div>
    <el-table border :data="data" max-height="600">
      <el-table-column
        align="center"
        label="排序字段"
        prop="sort"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="名字"
        prop="name"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="积分数"
        prop="posts"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="showtab(scope.row)">查看</el-link>
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="edi(scope.row)">编辑</el-link>
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="delobj(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <table-edit
      ref="edit"
      v-model="form"
      :config="config"
      :options="options"
      :rules="rules"
      @submit="submit"
    />
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"/>
      <el-dialog
        title="查看"
        :visible.sync="dialogVisible"
        width="60%">
        <span>
          <el-descriptions border :column="2" title="">
            <el-descriptions-item label="排序字段">{{showrows.sort}}</el-descriptions-item>
            <el-descriptions-item label="名字">{{showrows.name}}</el-descriptions-item>
            <el-descriptions-item label="积分数">{{ showrows.posts }}</el-descriptions-item>
          </el-descriptions>
        </span>
      </el-dialog>
      <el-dialog
        title="编辑"
        :visible.sync="dialogshow"
        width="60%">
        <span>
            <el-form :inline="true" label-width="88px" :model="editform" :rules="rule">
              <el-form-item label="排序字段" prop="sort">
                <el-input-number v-model="editform.sort" controls-position="right" placeholder="请输入排序字段" size="small"  style="text-align: left; width: 200px;"/>
              </el-form-item>
              <el-form-item label="姓名" prop="name">
                <el-input v-model="editform.name" placeholder="请输入姓名"/>
              </el-form-item>
              <el-form-item label="积分数" prop="posts">
                <el-input v-model="editform.posts" placeholder="请输入积分数"/>
              </el-form-item>
            </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogshow = false">取 消</el-button>
          <el-button type="primary" @click="savesub">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
  import { options, rules, config } from '@/const/points/signConfig'
  import {getDetail,delDetail,addDetail,putDetail} from "@/api/mall/users/sigcofig"
  export default {
    data() {
      return {
        options,
        rules,
        config,
        form:{},
        data: [],
        cur:{
          size:10,
          current:1,
        },
        total:0,
        dialogVisible:false,
        showrows:{},
        dialogshow:false,
        showform:{},
        editform:{},
        rule:{
          sort: [{ required: true, trigger: 'blur', message: '请输入排序字段' }],
          name: [{ required: true, trigger: 'blur', message: '请输入名字' }],
          posts: [{ required: true, trigger: 'blur', message: '请输入积分数' }],
        }
      }
    },
    created(){
      this.getDetail()
    },
    methods: {
      handleAdd() {
        this.form = {}
        this.$refs['edit'].showEdit()
      },
      getDetail(){
        getDetail({...this.cur}).then(res=>{
          this.data=res.data.records
          this.cur.size=res.data.size
          this.cur.current=res.data.current
          this.total=res.data.total
        })
      },
      handleCurrentChange(e){
        this.cur.current=e
        this.getDetail()
      },
      handleSizeChange(e){
        this.cur.size=e
        this.getDetail()
      },
      delobj(userId){
        delDetail(userId).then(()=>{
          this.$message.success("删除成功")
          this.getDetail()
        })
      },
      submit(){
        addDetail({...this.form}).then(()=>{
          this.$message.success("新增成功")
          this.getDetail()
          this.$refs['edit'].close()
        })
      },
      showtab(row){
        this.showrows=row
        this.dialogVisible=true
      },
      edi(row){
        this.dialogshow=true
        this.editform=row
      },
      savesub(){
        putDetail({...this.editform}).then(()=>{
          this.$message.success("修改成功")
          this.dialogshow=false
          this.getDetail()
        })
      }
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header{
  border-bottom: 1px solid #e5e5e5;
}
</style>