<template>
  <div>
    <el-dialog
      append-to-body
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="true"	
      title="图像显示模式"
      :visible.sync="dialogVisible"
      width="30%">
      <span>
        <el-radio-group v-model="form">
          <el-radio label="scaleToFill">拉伸</el-radio>
          <el-radio label="aspectFit">缩放</el-radio>
          <el-radio label="aspectFill">填充</el-radio>
        </el-radio-group>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props:{
    showstyle:{
      type:Boolean,
      default:()=>{}
    },
    listrow:{
      type:String,
      default:()=>{}
    }
  },
  data(){
    return {
      form:'',
      dialogVisible:false
    }
  },
  watch:{
    showstyle(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisible=newValue
      }
    },
    listrow(newValue,oldValue){
      if(newValue!=oldValue){
        this.form=newValue
      }
    }
  },
  methods:{
    handleClose(){
      this.$emit('closehide',false)
    },
    save(){
      this.$emit('closehide',this.form)
    }
  }
}
</script>

<style>

</style>