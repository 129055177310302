<template>
  <div>
    <el-dialog :before-close="handleClose" title="编辑基础信息" :visible.sync="dialogVisible" width="50%">
      <span>
        <el-form ref="ruleForms" class="demo-ruleForm" label-width="100px" :model="ruleForm" :rules="rules">
          <el-form-item label="姓名" prop="userName">
            <el-input v-model="ruleForm.userName" placeholder="请输入姓名" />
          </el-form-item>
          <el-form-item label="性别" prop="userSex">
            <el-input v-model="ruleForm.userSex" placeholder="请输入性别" />
          </el-form-item>
          <el-form-item label="出生日期" prop="userYear">
            <el-date-picker v-model="ruleForm.userYear" placeholder="请选择出生日期" type="date" value-format="yyyy-MM-dd" />
          </el-form-item>
          <el-form-item label="手机号码" prop="userPhone">
            <el-input v-model="ruleForm.userPhone" placeholder="请输入手机号码" type="number" />
          </el-form-item>
          <el-form-item label="身高(cm)" prop="userHeight">
            <el-input v-model="ruleForm.userHeight" placeholder="请输入身高" type="number" @change="bmiNumber" />
          </el-form-item>
          <el-form-item label="体重(cm)" prop="userWeight">
            <el-input v-model="ruleForm.userWeight" placeholder="请输入体重" type="number" @change="bmiNumber" />
          </el-form-item>
          <el-form-item label="BMI" prop="bmi">
            <el-input v-model="ruleForm.bmi" disabled placeholder="请输入BMI" />
          </el-form-item>
          <el-form-item label="臀围(cm)" prop="circumference">
            <el-input v-model="ruleForm.circumference" placeholder="请输入臀围" type="number" />
          </el-form-item>
          <el-form-item label="腰围(cm)" prop="waistline">
            <el-input v-model="ruleForm.waistline" placeholder="请输入腰围" type="number" />
          </el-form-item>
          <el-form-item label="责任医生" prop="">
            <!-- <el-select disabled v-model="ruleForm.usersex" placeholder="请选择责任医生">
              <el-option v-for="(ym, index) in sexoptions" :key="index" :label="ym.labelName" :value="ym.id" />
            </el-select> -->
          </el-form-item>
        </el-form>
        <el-form ref="ruleForm" class="demo-ruleForm" label-width="100px" :model="ruleForm" :rules="rules" style="grid-template-columns: repeat(1, 1fr)">
          <el-form-item label="会员标签" prop="tags">
            <div style="display: flex; align-items: center; flex-wrap: wrap">
              <el-tag v-for="tag in ruleForm.tags" :key="tag.name" closable type="success" @close="handleCloses(tag)">
                {{ tag.tagName }}
              </el-tag>
              <div class="add-tags" @click="istagshow">选择标签</div>
            </div>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <tagdialog :show="tagshow" @closelist="infotag" @dialogVisible="changeTagshow"/>
  </div>
</template>

<script>
import { userFitnessedit } from '@/api/mall/users/usermore'
import tagdialog from './tagdialo.vue'
export default {
  components: {
    tagdialog,
  },
  props: {
    show: {
      type: Boolean,
      default: () => {},
    },
    userlist: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: this.userlist,
      rules: {
        userName: [{ required: true, message: '请输入 姓名', trigger: 'blur' }],
        userSex: [{ required: true, message: '请输入 性别', trigger: 'blur' }],
        userYear: [{ required: true, message: '请选择 出生日期', trigger: 'change' }],
        userPhone: [{ required: true, message: '请输入 手机号码', trigger: 'blur' }],
      },
      sexoptions: [],
      tagshow: false,
    }
  },
  watch: {
    show(newValue, oldValue) {
      console.log(newValue,'222222222222222222222222222222');
      if (newValue != oldValue) {
        this.dialogVisible = newValue
        this.bmiNumber()
      }
    },
    userlist(newValue, oldValue) {
      if (newValue != oldValue) {
        this.ruleForm = newValue
      }
    },
  },

  methods: {
    istagshow() {
      this.tagshow = true
    },
    bmiNumber() {
      const lis = this.ruleForm.userWeight / (this.ruleForm.userHeight / 100) ** 2
      console.log(lis)
      this.$set(this.ruleForm, 'bmi', lis)
    },
    handleCloses(tag) {
      this.ruleForm.tags.splice(this.ruleForm.tags.indexOf(tag), 1)
    },
  
    infotag(row) {
      if (row && row.id) { // 添加条件检查
        if (this.ruleForm.tags.length !== 0) {
            const result = this.ruleForm.tags.find((item) => item.id === row.id);
            if (!result) {
                this.ruleForm.tags.push(row);
                this.tagshow = false;
            } else {
                this.$message.error('请不要重复选择');
            }
        } else {
          console.log('11111111222222333333');
            this.ruleForm.tags.push(row);
            this.tagshow = false;
        }
    }
      
    },
    changeTagshow(value){
      this.tagshow = value
    },
    submit() {
      this.$refs.ruleForms.validate((valid) => {
        if (valid) {
          let newTags = []
          this.ruleForm.tags.forEach((item) => {
            if (item.userId != null) {
              newTags.push({
                id: item.tagId,
                tagName: item.tagName,
              })
            } else {
              newTags.push(item)
            }
          })
          this.ruleForm.tags = newTags
          userFitnessedit({ ...this.ruleForm }).then((res) => {
            if (res.status == '200') {
              this.$message.success('编辑成功')
              this.$EventBus.$emit('repload')
              this.handleClose()
            }
          })
        } else {
          return false
        }
      })
    },
    handleClose() {
      this.$refs.ruleForms.resetFields()
      this.$emit('cloeedit', false)
      this.$EventBus.$emit('repload')
      this.dialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.add-tags {
  width: 80px;
  border: 1px solid #e5e5e5;
  text-align: center;
  font-size: 12px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
  .el-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .el-select,
  .el-date-editor,
  .input__inner {
    width: 100%;
  }
}
</style>