<template>
  <div ref="custom-table" class="custom-table-container">
    <searchform @receive="receiverow" />
    <el-table border :data="dataList" max-height="600" size="medium">
      <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="50" />
      <el-table-column align="center" label="客户姓名" prop="userName" width="190">
        <template #default="scope">
          <el-button type="text" @click="userNameClick(scope.row)">
            {{ scope.row.userName }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="客户id" prop="userId" />
      <el-table-column align="center" label="部门" prop="postName" show-overflow-tooltip />
      <el-table-column align="center" label="员工id" prop="sysId" />
      <el-table-column align="center" label="员工姓名" prop="realName" />
      <el-table-column align="center" label="累计积分" prop="totalPoint" />
      <el-table-column align="center" label="使用积分" prop="usePoint" />
      <el-table-column align="center" label="积分余额" prop="pointsCurrent" />
      <!-- <el-table-column align="center" label="抵扣积分" prop="amount" /> -->
      <!-- <el-table-column align="center" label="电话" prop="phone" /> -->
      <!-- <el-table-column align="center" label="描述" prop="description" show-overflow-tooltip width="260" /> -->
      <!-- <el-table-column align="center" label="创建时间" prop="createTime" /> -->
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.current"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="queryForm.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <el-dialog :before-close="handleClose" title="积分明细" :visible.sync="dialogVisible">
      <el-table :data="detailData" style="width: 100%">
        <el-table-column align="center" label="客户id" prop="userId" />
        <el-table-column align="center" label="客户姓名" prop="userName" />
        <el-table-column align="center" label="抵扣积分" prop="amount" />
        <el-table-column align="center" label="描述" prop="description" show-overflow-tooltip />
        <el-table-column align="center" label="创建时间" prop="createTime" width="300" />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import searchform from './components/search.vue'
import { integralList, ReportForPage } from '@/api/mall/health/integral'
export default {
  components: {
    searchform,
  },
  data() {
    return {
      dialogVisible: false,
      detailData: [],
      queryForm: {
        current: 1,
        size: 10,
        createStartTime: '',
        createEndTime: '',
        postIds: [],
      },
      total: 0,
      dataList: [],
    }
  },
  created() {
    this.list()
  },
  methods: {
    list() {
      integralList({ ...this.queryForm }).then((res) => {
        console.log('res', res)
        if (res.data.records) {
          this.dataList = res.data.records
          this.total = res.data.total
        }
      })
    },
    // 客户姓名
    userNameClick(row) {
      console.log(row, 'row')
      ReportForPage({ userId: row.userId }).then((res) => {
        this.detailData = res.data.records
        this.dialogVisible = true
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    //查询条件
    receiverow(row) {
      this.queryForm.postIds = row.idOrg
      this.queryForm.createStartTime = row.createStartTime
      this.queryForm.createEndTime = row.createEndTime
      this.list()
    },
    handleCurrentChange(val) {
      this.queryForm.current = val
      this.list()
    },
    handleSizeChange(val) {
      this.queryForm.size = val
      this.list()
    },
  },
}
</script>

<style lang="scss" scoped></style>