<template>
  <div v-loading.sync="listLoading" style="padding: 10px">
    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <div>
        <div style="display: flex">
          <h3 style="margin-right: 10px">当前收货地址</h3>
          <h3 style="color: rgb(37, 115, 217)">{{ total }}</h3>
        </div>
      </div>
      <div>
        <div
          v-if="
            btnRolrs[6] &&
            btnRolrs[6]['searchBarButtons'] &&
            btnRolrs[6]['searchBarButtons'].length
          "
        >
          <div v-if="btnRolrs[6] && btnRolrs[6].searchBarButtons">
            <el-button
              v-for="item in btnRolrs[6].searchBarButtons"
              :key="item.id"
              :icon="item.buttonIcon"
              size="small"
              :type="item.buttonCss"
              @click="handleClickBtn(item.buttonUrl)"
            >
              <vab-icon :icon="item.buttonIcon || ''" />
              {{ item.buttonName }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-table border :data="list" max-height="450" style="width: 100%">
        <el-table-column align="center" label="收货人姓名" prop="consignee" />
        <el-table-column align="center" label="收货人电话" prop="phone" />
        <el-table-column align="center" label="地址" prop="geoName" />
        <el-table-column
          align="center"
          label="详细地址"
          prop="receivingAddress"
        />
        <el-table-column align="center" label="标签" prop="labelName" />
        <el-table-column align="center" label="是/否默认" prop="isDefault">
          <template #default="{ row }">
            <div>{{ row.isDefault === 1 ? '是' : '否' }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="添加时间" prop="createTime" />
      </el-table>
      <el-pagination
        background
        :current-page="queryForm.current"
        :layout="layout"
        :page-size="queryForm.size"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
  import { getReceivingAddress } from '@/api/mall/users/userDetail'
  import { checkButton } from '@/utils/mixins'
  export default {
    mixins: [checkButton],
    data() {
      return {
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        userId: 0,
        balanceAmount: 0,
        queryForm: {
          current: 1,
          size: 10,
        },
        total: 0,
        accId: '',
        list: [],
        detail: {
          userId: 0,
          couponsCount: 0,
          sumCouponsCount: 0,
          usedCouponsCount: 0,
          overdueCouponsCount: 0,
        },
      }
    },
    created() {
      const { balanceAmount, accId } = this.$route.query
      this.balanceAmount = balanceAmount
      this.accId = accId
    },
    methods: {
      async fetchData(id) {
        this.listLoading = true
        this.userId = id
        const {
          data: { records, total },
        } = await getReceivingAddress({
          userId: this.userId,
          ...this.queryForm,
        })
        this.total = total
        this.list = records
        this.listLoading = false
      },
      handleSizeChange(val) {
        this.queryForm.size = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.current = val
        this.fetchData()
      },
      handleExport() {
        // exportIntegral({
        //   accId: this.accId,
        //   userId: this.userId,
        // })
        //   .then((res) => {
        //     const url = window.URL.createObjectURL(new Blob([res]))
        //     const link = document.createElement('a')
        //     link.setAttribute('href', url)
        //     link.setAttribute('download', new Date().getTime() + '.xlsx')
        //     link.click()
        //   })
        //   .catch(() => {
        //     this.$message.error('网络错误，请稍后重试！')
        //   })
      },
    },
  }
</script>
