import request from '@/utils/request'
// 服务商列表
export function queryPage(data) {
  return request({
    url: `/upms/admin/comSysUser/servicePage`,
    method: 'post',
    data,
  })
}
export function queryPages(data) {
  return request({
    url: `/upms/admin/comSysUser/servicePage`,
    method: 'post',
    data,
  })
}
// 服务商编辑
export function editObj(data) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/edit`,
    method: 'post',
    data,
  })
}
// 服务商添加
export function addObj(data) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/add`,
    method: 'post',
    data,
  })
}
// 服务商删除
export function delObj(data) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/delete`,
    method: 'post',
    data,
  })
}
// 服务商状态
export function disableColumn(data) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/disable`,
    method: 'post',
    data,
  })
}
// 配置类型（所有文章类型）
export function queryListForList(data) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/queryListForList`,
    method: 'post',
    data,
  })
}
// 配置类型（所有文章类型）
export function addColumnType(data) {
  return request({
    url: `/user/uuUserGsmDevice/add`,
    method: 'post',
    data,
  })
}

export function zdfws(data) {
  return request({
    url: `/mall/swm/uuUserGsmDevice/addOrg`,
    method: 'post',
    data,
  })
}
