import request from '@/utils/request'

// 机构入驻审核列表
export function queryOrgApplyPage(data) {
  return request({
    url: '/admin/comBusiOrgApply/queryOrgApplyPage',
    method: 'post',
    data,
  })
}
// 机构入驻审核详情
export function selectOrgApplyDetails(data) {
  return request({
    url: '/admin/comBusiOrgApply/selectOrgApplyDetails',
    method: 'post',
    data,
  })
}
// 机构入驻审核详情
export function selectOrgApplyInfo(data) {
  return request({
    url: '/admin/comBusiOrgApply/selectOrgApplyInfo',
    method: 'post',
    data,
  })
}
// 机构入驻审核详情（机构信息）
export function selectApplyOrgDetails(data) {
  return request({
    url: '/admin/comSysOrg/selectApplyOrgDetails',
    method: 'post',
    data,
  })
}
// 机构入驻审核详情（企业信息）
export function selectOrgCompDetails(data) {
  return request({
    url: '/admin/comBusiComp/selectOrgCompDetails',
    method: 'post',
    data,
  })
}
// 机构入驻审核详情（支付合同信息）
export function selectOrgSignDetails(data) {
  return request({
    url: '/admin/comBusiSign/selectOrgSignDetails',
    method: 'post',
    data,
  })
}

// 机构入驻审核指定页面(机构List)
export function selectOrgApplyTree() {
  return request({
    url: '/admin/comBusiOrgApply/selectOrgApplyTree',
    method: 'post',
  })
}
// 机构入驻审核指定页面(员工List)
export function selectOrgUser(data) {
  return request({
    url: '/admin/comBusiOrgApply/selectOrgUser',
    method: 'post',
    data,
  })
}
// 机构入驻审核指定页面(保存)
export function updateOrgUser(data) {
  return request({
    url: '/admin/comBusiOrgApply/update',
    method: 'post',
    data,
  })
}
// 机构入驻审核判断是否第几步
export function selectApplyOrgStatus(data) {
  return request({
    url: '/admin/comBusiOrgApply/selectApplyOrgStatus',
    method: 'post',
    data,
  })
}
// 机构入驻审核完善信息第一步（行业，经营项目）
export function queryIndustry() {
  return request({
    url: '/admin/comBusiIndustry/queryIndustry',
    method: 'post',
  })
}
// 机构入驻审核完善信息第一步（验证地区）
export function checkOrg(data) {
  return request({
    url: '/admin/comSysOrg/checkOrg',
    method: 'post',
    data,
  })
}
// 机构入驻审核完善信息第一步（入驻机构类型）
export function selectOrgType(data) {
  return request({
    url: '/admin/comBusiOrgType/selectOrgType',
    method: 'post',
    data,
  })
}
// 机构入驻审核完善信息第一步（省级区域）
export function selectProvince() {
  return request({
    url: '/sys/sysArea/selectProvince',
    method: 'post',
  })
}
// 机构入驻审核完善信息第一步（区域三级树）
export function selectAreaTree() {
  return request({
    url: '/upms/sys/sysArea/selectAreaTree',
    method: 'post',
  })
}
// 机构入驻审核完善信息第一步（保存）
export function addOrg(data) {
  return request({
    url: '/admin/comSysOrg/addOrg',
    method: 'post',
    data,
  })
}
// 机构入驻审核完善信息第二步（读取认证机构信息）
export function selectComp(data) {
  return request({
    url: '/admin/comBusiComp/selectComp',
    method: 'post',
    data,
  })
}
// 机构入驻审核完善信息第二步（编辑添加）
export function addComp(data) {
  return request({
    url: '/admin/comBusiComp/addComp',
    method: 'post',
    data,
  })
}
// 机构入驻审核完善信息第三步（查询）
export function selectSignInfo(data) {
  return request({
    url: '/admin/comBusiSign/selectSignInfo',
    method: 'post',
    data,
  })
}
// 机构入驻审核完善信息第三步（保存 ）
export function addSignFunds(data) {
  return request({
    url: '/admin/comBusiSign/addSignFunds',
    method: 'post',
    data,
  })
}
// 机构入驻审核完善信息第四步（保存 ）
export function addSignContract(data) {
  return request({
    url: '/admin/comBusiSign/addSignContract',
    method: 'post',
    data,
  })
}
// 待确认合同第一步（修改读取数据）
export function selectOrgTypeDetails(data) {
  return request({
    url: '/admin/comSysOrg/selectOrgTypeDetails',
    method: 'post',
    data,
  })
}
// 待确认合同第一步（修改保存）
export function editOrg(data) {
  return request({
    url: '/admin/comSysOrg/editOrg',
    method: 'post',
    data,
  })
}
// 待确认合同第二步（修改读取数据）
// 待确认合同第二步（修改保存）
export function editComp(data) {
  return request({
    url: '/admin/comBusiComp/editComp',
    method: 'post',
    data,
  })
}
// 待确认合同第三步（读取数据）
export function selectSignFunds(data) {
  return request({
    url: '/admin/comBusiSign/selectSignFunds',
    method: 'post',
    data,
  })
}
// 待确认合同第三步（保存）
export function editSignFunds(data) {
  return request({
    url: '/admin/comBusiSign/editSignFunds',
    method: 'post',
    data,
  })
}
// 待确认合同第四步（保存）
export function editSignContract(data) {
  return request({
    url: '/admin/comBusiSign/editSignContract',
    method: 'post',
    data,
  })
}
// 机构审核（驳回、通过）
export function reviewOrgApply(data) {
  return request({
    url: '/admin/comBusiOrgApply/reviewOrgApply',
    method: 'post',
    data,
  })
}
// 机构审核（添加）
export function applyOrgToTjx(data) {
  return request({
    url: '/admin/comBusiOrgApply/applyOrgToTjx',
    method: 'post',
    data,
  })
}
