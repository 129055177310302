<template>
  <div style="padding: 20px 20px 30px 20px; height: 79vh">
    <div v-if="btnRolrs[1] && btnRolrs[1].fixedBarButtons" style="margin: 10px">
      <el-button
        v-for="item in btnRolrs[1].fixedBarButtons"
        :key="item.id"
        type="primary"
        @click="handleClickBtn(item.buttonUrl, null)"
      >
        <vab-icon :icon="item.buttonIcon || ''" />
        {{ item.buttonName }}
      </el-button>
    </div>
    <el-table
      border
      :data="list"
      lazy
      :load="load"
      :max-height="600"
      row-key="id"
      style="width: 100%; margin-bottom: 20px"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column align="center" label="排序" type="index" width="50" />
      <el-table-column label="分类名称" prop="catName" />
      <el-table-column align="center" label="分类图标" min-width="30px">
        <template #default="{ row }">
          <el-image v-if="row.deep === 3" fit="cover" :src="row.catLogo" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" min-width="20px">
        <template #default="{ row }">
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <span
              :style="`width: 15px;
                height: 15px;
                background-color: ${
                  row.isShow === 1 ? 'rgb(19,206,102)' : 'red'
                };
                border-radius: 50%;
                margin-right: 5px;`"
            ></span>
            <span>{{ row.isShow === 1 ? '正常显示' : '禁用' }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="50px">
        <template #default="{ row }">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div
              v-for="item in row.btns"
              v-show="item.isShow && item.buttonStatus == 0"
              :key="item.id"
            >
              <el-dropdown
                v-if="item.children && item.children.length"
                style="margin: 0 5px"
                @command="
                  (index) => {
                    handleClickBtn(item.buttonUrl, index, item.children, row)
                  }
                "
              >
                <el-button :type="item.buttonCss">
                  <vab-icon :icon="item.buttonIcon || ''" />
                  {{ item.buttonName }}
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item2 in item.children"
                    :key="item2.id"
                    :command="item2.id"
                  >
                    {{ item2.buttonName }}
                    <vab-icon :icon="item.buttonIcon || ''" />
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                v-else
                size="mini"
                style="margin: 0 5px"
                :type="item.buttonCss"
                @click="handleClickBtn(item.buttonUrl, row)"
              >
                <vab-icon :icon="item.buttonIcon || ''" />
                {{ item.buttonName }}
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.currentPage"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <form-dialog ref="formDialog" :status="dialogStatus" @fetch="fetchData" />
    <add-cat-image ref="addCatImage" @fetch="fetchData" />
  </div>
</template>

<script>
  import FormDialog from './components/FormDialog'
  import AddCatImage from './components/AddCatImage.vue'
  import { checkButton } from '@/utils/mixins'
  import {
    getGoodsList,
    getGoodsSubList,
    categoryDisable,
    categoryRecovery,
    categoryDelete,
  } from '@/api/mall/shop/shopCategory'
  export default {
    components: {
      FormDialog,
      AddCatImage,
    },
    mixins: [checkButton],
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          currentPage: 1,
          pageSize: 10,
        },
        dialogStatus: 0,
        categoryItem: {
          catName: '',
          catLogo: '',
          parentId: -1,
          parentName: '',
          sortNumber: 0,
          isShow: 0,
          remarks: '',
          isAfterSales: 0,
        },
      }
    },
    async created() {
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    methods: {
      async fetchData() {
        this.listLoading = true
        let {
          data: { records, total },
        } = await getGoodsList(this.queryForm)
        records = records
          .sort((a, b) => {
            return b.sortNumber - a.sortNumber
          })
          .map((item) => {
            return {
              ...item,
              deep: 1,
              hasChildren: true,
              children: [],
            }
          })
        this.handleAddTableButtonRole(records, {
          handleChangeStatusOn: (row) => row.isShow == 0,
          handleChangeStatusOff: (row) => row.isShow == 1,
          openAddCatImage: (row) => row.deep !== 1 && row.deep != 3,
          associate: (row) => row.deep == 3,
        })
        this.total = total
        this.list = records
        this.listLoading = false
      },
      load(tree, treeNode, resolve) {
        const id = tree.id
        const deep = tree.deep + 1
        const parentName = tree.catName
        let data = []
        if (typeof id === 'number' && id > 0) {
          getGoodsSubList({ parentId: id })
            .then((res) => {
              data = res.data.map((item) => ({
                ...item,
                deep,
                hasChildren: deep === 3 ? false : true,
                children: [],
                parentName,
              }))
              this.handleAddTableButtonRole(data, {
                handleChangeStatusOn: (row) => row.isShow != 1,
                handleChangeStatusOff: (row) => row.isShow == 1,
                openAddCatImage: (row) => row.deep !== 1 && row.deep != 3,
                associate: (row) => row.deep == 3,
                addchildCateGory: (row) => row.deep != 3,
              })
              // console.log(data)
              resolve(data)
            })
            .catch(() => {
              this.$message.error('加载失败！')
            })
        }
      },
      editCateGory(row) {
        //tag 0 编辑分类 1 添加一级分类 2添加子分类
        this.categoryItem = {
          catName: row.catName,
          catLogo: row.catLogo,
          sortNumber: row.sortNumber,
          isShow: row.isShow,
          remarks: row.remarks,
          isAfterSales: row.isAfterSales,
        }
        this.categoryItem.id = row.id
        this.categoryItem.parentId = row.parentId
        this.categoryItem.parentName =
          row.parentId == 0 ? '顶级分类' : row.parentName
        this.categoryItem.isShow = this.categoryItem.isShow == 1 ? true : false
        this.categoryItem.isAfterSales =
          this.categoryItem.isAfterSales == 1 ? true : false
        // console.log(this.categoryItem)
        this.$nextTick(() => {
          this.dialogStatus = 0
          this.$refs.formDialog.show(this.categoryItem, row)
        })
      },
      addTopCateGory() {
        this.categoryItem = {
          catName: '',
          catLogo: '',
          sortNumber: 0,
          isShow: 0,
          remarks: '',
          isAfterSales: 0,
        }
        this.categoryItem.parentId = 0
        this.categoryItem.parentName = '顶级分类'
        this.categoryItem.isShow = this.categoryItem.isShow == 1 ? true : false
        this.categoryItem.isAfterSales =
          this.categoryItem.isAfterSales == 1 ? true : false
        console.log(this.categoryItem)
        this.$nextTick(() => {
          this.dialogStatus = 1
          this.$refs.formDialog.show(this.categoryItem, null)
        })
      },
      addchildCateGory(row) {
        this.categoryItem = {
          catName: '',
          catLogo: '',
          sortNumber: 0,
          isShow: 0,
          remarks: '',
          isAfterSales: 0,
        }
        this.categoryItem.parentId = row.id
        this.categoryItem.parentName = row.catName
        this.categoryItem.isShow = this.categoryItem.isShow == 1 ? true : false
        this.categoryItem.isAfterSales =
          this.categoryItem.isAfterSales == 1 ? true : false
        console.log(this.categoryItem)
        this.$nextTick(() => {
          this.dialogStatus = 2
          this.$refs.formDialog.show(this.categoryItem, row)
        })
      },
      openAddCatImage(row) {
        this.$refs.addCatImage.show(row)
      },
      handleChangeStatusOn(row) {
        const { isShow, id } = row
        console.log(isShow)
        if (id) {
          categoryRecovery({ id }).then(() => {
            row.isShow = isShow == 0 ? 1 : 0
            let enableBtn = null
            let disableBtn = null
            row.btns.forEach((btn) => {
              if (btn.buttonUrl === 'handleChangeStatusOff') {
                disableBtn = btn
              }
              if (btn.buttonUrl === 'handleChangeStatusOn') {
                enableBtn = btn
              }
            })
            enableBtn.isShow = false
            disableBtn.isShow = true
            this.fetchData()
            this.$message.success(`启用成功！`)
          })
        }
      },
      handleChangeStatusOff(row) {
        const { isShow, id } = row
        if (id) {
          categoryDisable({ id }).then(() => {
            row.isShow = isShow == 0 ? 1 : 0
            let enableBtn = null
            let disableBtn = null
            row.btns.forEach((btn) => {
              if (btn.buttonUrl === 'handleChangeStatusOff') {
                disableBtn = btn
              }
              if (btn.buttonUrl === 'handleChangeStatusOn') {
                enableBtn = btn
              }
            })
            enableBtn.isShow = true
            disableBtn.isShow = false
            this.fetchData()
            this.$message.success(`禁用成功！`)
          })
        }
      },
      handleDelete(row) {
        const { id } = row
        this.$confirm('确定要删除此商品吗？', '提示！', {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          if (id && typeof id === 'number') {
            categoryDelete({ id }).then(() => {
              this.fetchData()
              this.$message.success('删除成功')
            })
          }
        })
      },
      handleCurrentChange(val) {
        this.queryForm.currentPage = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
