var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "border-box" },
      [
        _c(
          "el-tabs",
          { attrs: { type: "border-card" } },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "商品规格" } },
              [
                _vm.itemList == null || _vm.itemList.length <= 0
                  ? _c("div", { staticClass: "el-table__empty-block" }, [
                      _c("span", { staticClass: "el-table__empty-text" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "border-guige" },
                  _vm._l(_vm.itemList, function (item, index) {
                    return _c("div", { key: index }, [
                      _c("div", { staticClass: "flex-guige" }, [
                        _c(
                          "div",
                          {
                            staticClass: "ym_thead",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                              "border-bottom": "1px solid #f7f7f7",
                            },
                          },
                          [
                            _c("div", [_vm._v(_vm._s(item.specName))]),
                            _c(
                              "div",
                              { staticClass: "remove" },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removelist(index)
                                      },
                                    },
                                  },
                                  [_vm._v("移除")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "flex-guige",
                          staticStyle: {
                            "padding-bottom": "10px",
                            margin: "20px 0",
                            "border-bottom": "1px solid #e5e5e5",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "width-tag",
                              staticStyle: { "margin-bottom": "10px" },
                            },
                            _vm._l(item.specValueName, function (tag, ins) {
                              return _c(
                                "el-tag",
                                {
                                  key: ins,
                                  attrs: {
                                    closable: "",
                                    "disable-transitions": false,
                                    type: "info",
                                  },
                                  on: {
                                    close: function ($event) {
                                      return _vm.handleClose(index, ins)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(tag.specValueName) + " ")]
                              )
                            }),
                            1
                          ),
                          _c(
                            "el-popover",
                            { attrs: { placement: "top", width: "160" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "添加规格值" },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.focusConfirm(index)
                                    },
                                    change: function ($event) {
                                      return _vm.handleInputConfirm(index)
                                    },
                                  },
                                  model: {
                                    value: _vm.inputValue,
                                    callback: function ($$v) {
                                      _vm.inputValue = $$v
                                    },
                                    expression: "inputValue",
                                  },
                                },
                                _vm._l(_vm.optionsvalue, function (spec) {
                                  return _c("el-option", {
                                    key: spec.id,
                                    attrs: {
                                      disabled: spec.disabled,
                                      label: spec.name,
                                      value: spec.id,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    slot: "reference",
                                    type: "primary",
                                    underline: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.specValueName(item.specName)
                                    },
                                  },
                                  slot: "reference",
                                },
                                [_vm._v("添加规格值")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
                _c(
                  "el-popover",
                  {
                    staticStyle: {
                      position: "absolute",
                      bottom: "10px",
                      left: "50%",
                      transform: "translateX(-50%)",
                    },
                    attrs: { placement: "top", width: "160" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "添加规格" },
                        on: { change: _vm.getrow },
                        model: {
                          value: _vm.selinfo,
                          callback: function ($$v) {
                            _vm.selinfo = $$v
                          },
                          expression: "selinfo",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: {
                            disabled: item.disabled,
                            label: item.name,
                            value: item.name,
                          },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-link",
                      {
                        attrs: {
                          slot: "reference",
                          type: "primary",
                          underline: false,
                        },
                        slot: "reference",
                      },
                      [_vm._v("添加规格")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-tabs",
          {
            staticStyle: { "margin-top": "20px" },
            attrs: { type: "border-card" },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "商品SKU" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.mapList.length > 0,
                        expression: "mapList.length > 0",
                      },
                    ],
                    staticClass: "ad",
                    staticStyle: {
                      display: "flex",
                      gap: "15px",
                      "justify-content": "space-around",
                      "margin-bottom": "20px",
                    },
                  },
                  [
                    _c("div", { staticClass: "head_title" }, [
                      _c(
                        "div",
                        {
                          staticClass: "grid-content bg-purple",
                          staticStyle: {
                            "font-size": "14px",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v("批量设置")]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "menu_btn",
                        staticStyle: { width: "calc(100% - 120px)" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "grid",
                              "grid-template-columns": "repeat(7, 1fr)",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    "controls-position": "right",
                                    placeholder: "sku编码",
                                  },
                                  model: {
                                    value: _vm.batch.skuCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.batch, "skuCode", $$v)
                                    },
                                    expression: "batch.skuCode",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    "controls-position": "right",
                                    placeholder: "销售价(元)",
                                    precision: 2,
                                    step: 0.1,
                                  },
                                  model: {
                                    value: _vm.batch.salesPrice,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.batch, "salesPrice", $$v)
                                    },
                                    expression: "batch.salesPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    "controls-position": "right",
                                    placeholder: "市场价（元）",
                                    precision: 2,
                                    step: 0.1,
                                  },
                                  model: {
                                    value: _vm.batch.marketPrice,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.batch, "marketPrice", $$v)
                                    },
                                    expression: "batch.marketPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    "controls-position": "right",
                                    placeholder: "成本价（元）",
                                    precision: 2,
                                    step: 0.1,
                                  },
                                  model: {
                                    value: _vm.batch.costPrice,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.batch, "costPrice", $$v)
                                    },
                                    expression: "batch.costPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    "controls-position": "right",
                                    placeholder: "库存",
                                  },
                                  model: {
                                    value: _vm.batch.stock,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.batch, "stock", $$v)
                                    },
                                    expression: "batch.stock",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    "controls-position": "right",
                                    placeholder: "重量",
                                  },
                                  model: {
                                    value: _vm.batch.weight,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.batch, "weight", $$v)
                                    },
                                    expression: "batch.weight",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    "controls-position": "right",
                                    placeholder: "体积",
                                  },
                                  model: {
                                    value: _vm.batch.volume,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.batch, "volume", $$v)
                                    },
                                    expression: "batch.volume",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "10px" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "grid-content bg-purple" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: { click: _vm.confirm },
                                      },
                                      [_vm._v("确认")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { border: "", data: _vm.mapList },
                  },
                  [
                    _vm._l(_vm.itemList, function (item, index) {
                      return _c("el-table-column", {
                        key: index,
                        attrs: {
                          align: "center",
                          label: item.specName,
                          width: "88",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.specs[index]
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.specs[index].specValueName
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    _c("el-table-column", {
                      attrs: { label: "头图", prop: "picUrl", width: "50" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "sku编码",
                        prop: "skuCode",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: { placeholder: "sku编码" },
                                model: {
                                  value: scope.row.skuCode,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "skuCode", $$v)
                                  },
                                  expression: "scope.row.skuCode",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "销售价(元)",
                        prop: "salesPrice",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input-number", {
                                attrs: {
                                  "controls-position": "right",
                                  placeholder: "销售价(元)",
                                },
                                model: {
                                  value: scope.row.salesPrice,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "salesPrice", $$v)
                                  },
                                  expression: "scope.row.salesPrice",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "市场价(元)",
                        prop: "marketPrice",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input-number", {
                                attrs: {
                                  "controls-position": "right",
                                  placeholder: "市场价(元)",
                                },
                                model: {
                                  value: scope.row.marketPrice,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "marketPrice", $$v)
                                  },
                                  expression: "scope.row.marketPrice",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "成本价(元)",
                        prop: "costPrice",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input-number", {
                                attrs: {
                                  "controls-position": "right",
                                  placeholder: "成本价(元)",
                                },
                                model: {
                                  value: scope.row.costPrice,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "costPrice", $$v)
                                  },
                                  expression: "scope.row.costPrice",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "库存", prop: "stock" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input-number", {
                                attrs: {
                                  "controls-position": "right",
                                  placeholder: "库存",
                                },
                                model: {
                                  value: scope.row.stock,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "stock", $$v)
                                  },
                                  expression: "scope.row.stock",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "重量(kg)",
                        prop: "weight",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input-number", {
                                attrs: {
                                  "controls-position": "right",
                                  placeholder: "重量(kg)",
                                },
                                model: {
                                  value: scope.row.weight,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "weight", $$v)
                                  },
                                  expression: "scope.row.weight",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "体积(m³)",
                        prop: "volume",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input-number", {
                                attrs: {
                                  "controls-position": "right",
                                  placeholder: "体积(m³)",
                                },
                                model: {
                                  value: scope.row.volume,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "volume", $$v)
                                  },
                                  expression: "scope.row.volume",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }