var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Dinage" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "详细信息",
            visible: _vm.hides,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.hides = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "ym_container" },
            _vm._l(_vm.list, function (ym, index) {
              return _c("div", { key: index, staticClass: "ym_question" }, [
                _c("div", { staticClass: "ym_qustions-title" }, [
                  _vm._v(_vm._s(ym.title)),
                ]),
                _c(
                  "div",
                  { staticClass: "ym_question-list" },
                  _vm._l(ym.caseVos, function (qustion, number) {
                    return _c("el-tag", { key: number }, [
                      _vm._v(_vm._s(qustion.caseName)),
                    ])
                  }),
                  1
                ),
              ])
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleClose } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }