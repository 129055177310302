<template>
  <div style="height: 79vh; padding: 10px">
    <div style="display: flex; flex-wrap: wrap; margin-bottom: 15px">
      <div class="title-item">
        <span>会员信息</span>
        <el-input v-model.trim="formInline.userCode" class="title-input" placeholder="请输入用户编码" />
      </div>
      <div class="title-item">
        <span>用户名称</span>
        <el-input v-model.trim="formInline.nickName" class="title-input" placeholder="请输入用户名称" />
      </div>
      <div class="title-item">
        <span>手机号</span>
        <el-input v-model.trim="formInline.phone" class="title-input" placeholder="请输入手机号码" />
      </div>
      <div class="title-item">
        <span>来源应用</span>
        <el-select v-model.trim="formInline.appType" class="title-input" placeholder="请选择用户来源">
          <el-option v-for="(item, index) in userSource" :key="index" :label="item.dictValue" :value="item.dictKey" />
        </el-select>
      </div>

      <!-- <div class="title-item">
        <span>所在省份:</span>
        <el-input v-model.trim="formInline.province" class="title-input" placeholder="请输入省份" />
      </div> -->
      <div class="title-item">
        <span>所在城市:</span>
        <el-input v-model.trim="formInline.city" class="title-input" placeholder="请输入城市" />
      </div>
      <!-- <div class="title-item">
        <span>注册时间</span>
        <el-date-picker
          v-model="datePicker"
          align="center"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="dateChange"
        />
      </div> -->
      <div class="title-item">
        <el-button icon="el-icon-search" type="warning" @click="handleSearch">搜索</el-button>
        <el-button icon="el-icon-refresh-left" type="primary" @click="handleReset">重置</el-button>
      </div>
    </div>
    <el-table border :data="userList" :max-height="560" style="width: 100%">
      <el-table-column align="center" label="用户编码" prop="userCode" width="100" />
      <el-table-column align="center" label="头像" prop="headimgUrl" width="120">
        <template #default="{ row }">
          <div>
            <el-image alt="" fit="fill" :src="row.headimgUrl" srcset="" style="width: 50px; height: 50px" @click="handleImgClick(row.headimgUrl)" />
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="手机号" prop="phone" />
      <el-table-column align="center" label="会员名称" prop="nickName" />
      <el-table-column align="center" label="来源渠道" prop="userSource">
        <template #default="{ row }">
          <div>{{ row.appType | getSource }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="身体状态" />

      <!-- <el-table-column align="center" label="用户身份">
        <template #default="{ row }">
          <div>{{ row.memberLevel | getLevel }}</div>
        </template>
      </el-table-column> -->
      <!-- <el-table-column align="center" label="员工身份" prop="userType" /> -->
      <el-table-column align="center" label="所在城市" prop="city" />
      <!-- <el-table-column align="center" label="当前积分" prop="pointsCurrent" /> -->
      <!-- <el-table-column align="center" label="上级分享人" prop="parentId" />
      <el-table-column align="center" label="是否分销员" prop="distributionUser">
        <template #default="{ row }">
          <div>{{ row.distributionUser | getSource }}</div>
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="会员等级" prop="userGradeDesc" />
      <el-table-column align="center" label="注册时间" prop="createTime" />
      <el-table-column align="center" label="操作" width="220px">
        <template #default="{ row }">
          <div style="display: flex; align-items: center; justify-content: center">
            <div v-for="item in row.btns" v-show="item.isShow && item.buttonStatus == 0" :key="item.id">
              <el-dropdown
                v-if="item.children && item.children.length"
                style="margin: 0 5px"
                @command="
                  (index) => {
                    handleClickBtn(item.buttonUrl, index, item.children, row)
                  }
                "
              >
                <el-button size="default" :type="item.buttonCss">
                  {{ item.buttonName }}
                </el-button>
                <!-- 更多 -->
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="item2 in item.children" :key="item2.id" :command="item2.id">
                    {{ item2.buttonName }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- 查看 禁用 -->   <!-- <vab-icon :icon="item.buttonIcon || ''" /> -->
              <el-button v-else size="default" style="margin: 0 5px" :type="item.buttonCss" @click="handleClickBtn(item.buttonUrl, row)">
              
                {{ item.buttonName }}
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      :current-page="queryForm.current"
      :layout="layout"
      :page-size="queryForm.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- <coupon ref="coupon" @fetch="fetchData" /> -->
    <password ref="password" @fetch="fetchData" />
    <!-- <profit ref="profit" @fetch="fetchData" /> -->
  </div>
</template>

<script>
import { getUserList ,disableUser} from '@/api/mall/users/list'
import { selectSysDict } from '@/api/mall/common'
// import Coupon from './components/Coupon.vue'
import Password from './components/Password.vue'
// import Profit from './components/Profit.vue'
import { checkButton } from '@/utils/mixins'

export default {
  name: 'UserList',
  components: {
    // Coupon,
    Password,
    // Profit,
  },
  filters: {
    getSource(val) {
      let str = ''
      switch (val) {
        case 'H5':
          str = '普通H5'
          break
        case 'MA':
          str = '小程序'
          break
        case 'WX':
          str = '公众号H5'
          break
        case 'APP':
          str = 'APP'
          break
        case 'PC':
          str = 'PC端H5'
          break
      }
      return str
    },
    getLevel(val) {
      let str = ''
      switch (val) {
        case 1:
          str = '1'
          break
        case 23:
          str = ''
          break
      }
      return str
    },
  },
  mixins: [checkButton],
  data() {
    return {
      listLoading: true,
      userList: [],
      datePicker: [],
      userSource: [],
      userIdentity: [],
      personnelIdentity: [],
      isSearch: false,
      layout: 'total, sizes, prev, pager, next, jumper',
      formInline: {
        id: '',
        nickName: '',
        appType: '',
        phone: '',
        memberLevel: '',
        userType: '',
        beginTime: '',
        endTime: '',
        province:'',
        city:'',
      },
      total: 0,
      queryForm: {
        current: 1,
        size: 10,
      },
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    handleReset() {
      this.formInline = {
        id: '',
        nickName: '',
        appType: '',
        phone: '',
        memberLevel: '',
        userType: '',
        beginTime: '',
        endTime: '',
        province:'',
        city:'',
      }
      this.datePicker = []
      // this.handleSearch()
      this.fetchData({},false)
    },
    disableUser(row) {
      this.$confirm('此操作将禁用此用户，是否继续？', '提示', {
        confirmButtonText: '禁用',
        type: 'warning',
      }).then(() => {
        const { id } = row
        disableUser({ userId: id }).then(() => {
          this.fetchData(this.formInline)
          this.$message.success('禁用成功！')
        })
      })
    },
    openDialog(e, btns, row) {
      const obj = btns.find((item) => item.id == e)
      obj && this.$refs[obj.buttonUrl].show(row)
    },
    async fetchData(options = {}) {
      this.listLoading = true
      const {
        data: { records: userList, total },
      } = await getUserList({ ...this.queryForm, ...options })
      this.handleAddTableButtonRole(userList, {})
      const dicData = await selectSysDict({
        dictTypeList: ['userFrom', 'userIdentity', 'personnelIdentity'],
      })
      this.userSource = dicData.data[0].dictInfoVoList
      this.userIdentity = dicData.data[1].dictInfoVoList
      this.personnelIdentity = dicData.data[2].dictInfoVoList
      this.userList = userList
      this.total = total
    },
    // 搜索
    async handleSearch() {
      this.queryForm.current = 1
      if (Object.values(this.formInline).every(value => !value)) { 
    if (this.isSearch) {
      console.log('显示全部数据');
    } else {
      console.log('第一次点击搜索，输入框为空');
    }
  } else {
    console.log(this.formInline,'formInline------------');
    await this.fetchData({ ...this.formInline }, false);
  }
      // await this.fetchData({ ...this.formInline }, false)
      let temp = false
     
      Object.keys(this.formInline).forEach((key) => {
        this.formInline[key] && (temp = true)
      })
      this.isSearch = temp
    },
    openDetail(row) {
      this.$router.push({
        path: '/user/details',
        query: { userinfo: row.userCode },
      })
    },
    handleSizeChange(val) {
      this.queryForm.size = val
      let data = this.isSearch === true ? this.formInline : {}
      this.fetchData({ ...data }, false)
    },
    handleCurrentChange(val) {
      this.queryForm.current = val
      let data = this.isSearch === true ? this.formInline : {}
      this.fetchData({ ...data }, false)
    },
    // dateChange(date) {
    //   if (date && date.length) {
    //     this.formInline.beginTime = date[0]
    //     this.formInline.endTime = date[1]
    //   } else {
    //     this.formInline.beginTime = ''
    //     this.formInline.endTime = ''
    //   }
    // },
  },
}
</script>

<style scoped>
.title-input {
  width: 220px;
  margin-left: 5px;
}

.title-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.title-item span {
  width: 100px;
  text-align: center;
}
</style>
