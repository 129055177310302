import request from '@/utils/request'

export function queryPage(data) {
  return request({
    url: '/mall/stp/stpLevelConfig/queryPage',
    method: 'post',
    data,
  })
}

export function addObj(data) {
  return request({
    url: '/mall/stp/stpLevelConfig/add',
    method: 'post',
    data,
  })
}

export function getObj(data) {
  return request({
    url: '/mall/stp/stpLevelConfig/details',
    method: 'get',
    data,
  })
}

export function editObj(data) {
  return request({
    url: `/mall/stp/stpLevelConfig/edit`,
    method: 'post',
    data,
  })
}
export function delObj(data) {
  return request({
    url: '/mall/stp/stpLevelConfig/delete',
    method: 'delete',
    data,
  })
}


//查询商城用户
export function getUserInfo(data) {
  return request({
    url: `/mall/userinfo/getUserInfo`,
    method: 'post',
    data,
  })
}

//官方账户
export function getOfficialAccount(data) {
  return request({
    url: `/mall/userinfo/getOfficialAccount`,
    method: 'post',
    data,
  })
}