import request from '@/utils/request'
//团队列表
export function getUserListS(params) {
  return request({
    url: `/mall/user/uuUserSugarStaBranch/queryUserEmpOrderPage`,
    method: 'post',
    data: params,
  })
}
//粉丝员工统计
export function uuUserSugarStaBranchqueryUserEmpPage(params) {
  return request({
    url: `/mall/user/uuUserSugarStaBranch/queryUserEmpPage`,
    method: 'post',
    data: params,
  })
}
