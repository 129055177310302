<template>
  <div style="height: 79vh; padding: 10px">
    <div style="display: flex; flex-wrap: wrap; margin-bottom: 15px">
      <div class="title-item">
        <span>手机号</span>
        <el-input
          v-model.trim="formInline.phone"
          class="title-input"
          placeholder="请输入手机号码"
        />
      </div>
      <div class="title-item">
        <el-button icon="el-icon-search" type="warning" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          type="primary"
          @click="handleReset"
        >
          重置
        </el-button>
      </div>
    </div>
    <el-table border :data="userList" :max-height="560" style="width: 100%">
      <el-table-column align="center" label="用户编码" prop="userId" />
      <el-table-column align="center" label="姓名" prop="userName" />
      <el-table-column align="center" label="出生年月" prop="userYear" />
      <el-table-column align="center" label="性别" prop="userSex" />
      <el-table-column align="center" label="身高" prop="userHeight" />
      <el-table-column align="center" label="体重" prop="userWeight" />
      <el-table-column
        align="center"
        label="并发症"
        prop="illStatus"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tag :type="row.illStatus === 0 ? 'success' : 'danger'">
            {{ row.illStatus === 0 ? '无' : '有' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="高血压"
        prop="bloodStatus"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tag :type="row.bloodStatus === 0 ? 'success' : 'danger'">
            {{ row.bloodStatus === 0 ? '无' : '有' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="高尿酸"
        prop="acidStatus"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tag :type="row.acidStatus === 0 ? 'success' : 'danger'">
            {{ row.acidStatus === 0 ? '无' : '有' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="高血脂"
        prop="lipidStatus"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tag :type="row.lipidStatus === 0 ? 'success' : 'danger'">
            {{ row.lipidStatus === 0 ? '无' : '有' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        v-if="operationShow"
        align="center"
        label="操作"
        min-width="80px"
      >
        <template #default="{ row }">
          <template v-for="item in listButton">
            <el-button
              v-if="showListButton(row, item)"
              :key="item.id"
              :plain="item.cssType && item.cssType == 'plain' ? true : false"
              size="default"
              :type="item.buttonCss"
              @click.native="handleClickBtn(item, row)"
            >
              <span v-if="!item.buttonIcon"></span>
              <!-- <vab-icon v-else :icon="item.buttonIcon" /> -->
              {{ item.buttonName }}
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.current"
      :layout="layout"
      :page-size="queryForm.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <DialogBox :date="information" :show="hide" @showbox="hidden"/>
  </div>
</template>

<script>
  import { queryUuUserRecord } from '@/api/mall/health/healthRecordArchive/healthRecordArchive'
  import { selectSysDict, selectRoleMenuButtonList } from '@/api/mall/common'
  import { checkButton } from '@/utils/mixins'
  import DialogBox from './components/Dinage.vue'
  export default {
    components:{DialogBox},
    mixins: [checkButton],
    data() {
      return {
        listLoading: true,
        userList: [],
        datePicker: [],
        userIdentity: [],
        personnelIdentity: [],
        isSearch: false,
        operationShow:false,
        information:{},
        hide:false,
        layout: 'total, sizes, prev, pager, next, jumper',
        formInline: {
          id: '',
          nickname: '',
          phone: '',
          memberLevel: '',
          userType: '',
          beginTime: '',
          endTime: '',
        },
        total: 0,
        queryForm: {
          current: 1,
          size: 10,
        },
      }
    },
    created() {
      this.fetchData()
      this.selectRoleMenuButtonList()
    },
    methods: {
      handleReset() {
        this.formInline = {
          id: '',
          nickname: '',
          phone: '',
          memberLevel: '',
          userType: '',
          beginTime: '',
          endTime: '',
        }
        this.datePicker = []
        this.handleSearch()
      },
      // 按钮状态显示判断
      showListButton(row, item) {
        if (
          item.buttonUrl == 'handleLook'
        ) {
          return true
        }
        if (row.enable == 1 && item.buttonName == '开启') {
          return true
        } else if (row.enable == 0 && item.buttonName == '禁用') {
          return true
        }
      },
      // 按钮点击.
      handleClickBtn(item,row) {
        this.hide=true
        this.information={userId:item.id,Id:row.id}
      },
      // 按钮权限
      selectRoleMenuButtonList() {
        selectRoleMenuButtonList({
          roleIdStrList: this.$store.getters['acl/roleList'],
          menuId: this.$route.meta.id,
        })
          .then((res) => {
            if (res.data) {
              this.searchButton = res.data.filter(function (item) {
                return item.buttonType == 1
              })
              this.listButton = res.data.filter(function (item) {
                return item.buttonType == 2
              })
              if (this.listButton.length > 0) {
                this.operationShow = true
              } else {
                this.operationShow = false
              }
              const buttonUrl = this.listButton.map((item) => {
                return item.buttonUrl
              })
              if (buttonUrl.includes('handelEdit')) {
                this.listShows = true
              } else {
                this.listShows = false
              }
            }
          })
          .catch(() => {})
      },
      hidden(e){
        this.hide=e
      },
      openDialog(e, btns, row) {
        const obj = btns.find((item) => item.id == e)
        obj && this.$refs[obj.buttonUrl].show(row)
      },
      async fetchData(options = {}) {
        this.listLoading = true
        const {
          data: { records: userList, total },
        } = await queryUuUserRecord({ ...this.queryForm, ...options })
        this.handleAddTableButtonRole(userList, {})
        const dicData = await selectSysDict({
          dictTypeList: ['userFrom', 'userIdentity', 'personnelIdentity'],
        })
        this.userIdentity = dicData.data[1].dictInfoVoList
        this.personnelIdentity = dicData.data[2].dictInfoVoList
        this.userList = userList
        this.total = total
      },
      async handleSearch() {
        this.queryForm.current = 1
        await this.fetchData({ ...this.formInline }, false)
        let temp = false
        Object.keys(this.formInline).forEach((key) => {
          this.formInline[key] && (temp = true)
        })
        this.isSearch = temp
      },
      openDetail(row) {
        this.$router.push({
          path: '/user/detail',
          query: row,
        })
      },
      handleSizeChange(val) {
        this.queryForm.size = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData({ ...data }, false)
      },
      handleCurrentChange(val) {
        this.queryForm.current = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData({ ...data }, false)
      },
      dateChange(date) {
        if (date && date.length) {
          this.formInline.beginTime = date[0]
          this.formInline.endTime = date[1]
        } else {
          this.formInline.beginTime = ''
          this.formInline.endTime = ''
        }
      },
    },
  }
</script>

<style scoped>
  .title-input {
    width: 220px;
    margin-left: 5px;
  }

  .title-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }

  .title-item span {
    width: 100px;
    text-align: center;
  }
</style>
