import request from '@/utils/request'

export function addObj(obj) {
  return request({
    url: '/mall/user/uuUserSugarRecord/add',
    method: 'post',
    data: obj,
  })
}

//查询

export function stpCxccConfig(obj) {
  return request({
    url: '/mall/user/uuUserSugarRecord/details',
    method: 'post',
    data: obj,
  })
}

// 日志
export function uuUserSugarRecordLog(obj) {
  return request({
    url: '/mall/user/uuUserSugarRecordLog/queryPage',
    method: 'post',
    data: obj,
  })
}
// 日志
export function queryListLogForPage(obj) {
  return request({
    url: '/mall/user/uuUserSugarRecordLog/queryListLogForPage',
    method: 'post',
    data: obj,
  })
}

//日志详细查询

export function uuUserSugarRecordLogdetails(obj) {
  return request({
    url: '/mall/user/uuUserSugarRecordLog/details',
    method: 'post',
    data: obj,
  })
}

//标签种类

export function queryList(obj) {
  return request({
    url: '/mall/user/stpLabel/queryList',
    method: 'post',
    data: obj,
  })
}

//交流记录
export function Communication(obj) {
  return request({
    url: '/mall/user/uuUserSugarInterLog/queryPage',
    method: 'post',
    data: obj,
  })
}

//下单记录table

export function orderrecord(obj) {
  return request({
    url: '/mall/home/queryOrderPage',
    method: 'post',
    data: obj,
  })
}

// 订单历史记录
export function getqueryOrderOperationLog(id) {
  return request({
    url: `/mall/orderinfo/queryOrderOperationLog?id=${id}`,
    method: 'get',
  })
}

//录音记录table

export function selectRecordList(obj) {
  return request({
    url: '/mall/cxcc/selectRecordList',
    method: 'post',
    data: obj,
  })
}

// 客情处理
export function Aftersales(obj) {
  return request({
    url: '/mall/uuUserSugarRecordAfter/queryAfterPage',
    method: 'post',
    data: obj,
  })
}

// 质检查看保存
export function orderinfoupdateQuality(obj) {
  return request({
    url: '/mall/orderinfo/updateQuality',
    method: 'post',
    data: obj,
  })
}

//质检数据查看查询
export function getQualityDetails(obj) {
  return request({
    url: '/mall/swm/uuUserSugarRecordQuality/details',
    method: 'post',
    data: obj,
  })
}

//质检数据待审订单列表
export function uuUserSugarRecordQualityqueryVeryOrder(obj) {
  return request({
    url: '/mall/swm/uuUserSugarRecordQuality/queryVeryOrder',
    method: 'post',
    data: obj,
  })
}

// 历史健康数据查询
export function getHistury(obj) {
  return request({
    url: '/mall/user/uuUserSugarRecord/queryTurnOverHistory',
    method: 'post',
    data: obj,
  })
}
