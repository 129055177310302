<template>
  <svg aria-hidden="true" class="svg-icon">
    <use :xlink:href="`#${iconName}`" />
  </svg>
</template>

<script>
  export default {
    name: 'SvgIcon',
    props: {
      iconName: {
        type: String,
        required: true,
      },
    },
  }
</script>

<style>
  .svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    user-select: none;
  }
</style>
