var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "imageTofile",
      staticClass: "controls_cont",
      style: {
        background: _vm.fundemo.pageBgColor,
      },
    },
    [
      _c(
        "div",
        {
          ref: "can",
          staticClass: "center-content",
          style: {
            backgroundImage: `url(` + _vm.fundemo.bgUrl + `)`,
            backgroundSize: `100%`,
            backgroundRepeat: `no-repeat`,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "top_thead",
              style: {
                background: _vm.fundemo.topNavColor,
              },
              on: {
                click: function ($event) {
                  return _vm.checkRightToll("header", -1)
                },
              },
            },
            [
              _c("div", { staticClass: "thead" }, [
                _vm.fundemo.navBarSwitch
                  ? _c(
                      "div",
                      {
                        staticClass: "contanner_content",
                        style: {
                          justifyContent:
                            _vm.fundemo.navStyle == "1" ? "center" : "left",
                          textAlign: _vm.fundemo.textImgPosLink,
                        },
                      },
                      [
                        _vm.fundemo.navStyle == 2 || _vm.fundemo.navStyle == 3
                          ? _c("div", { staticClass: "pic" }, [
                              _c("img", {
                                attrs: { alt: "", src: _vm.fundemo.topNavImg },
                              }),
                            ])
                          : _vm._e(),
                        _vm.fundemo.navStyle == 1 || _vm.fundemo.navStyle == 2
                          ? _c(
                              "div",
                              {
                                staticClass: "title",
                                style: {
                                  width:
                                    _vm.fundemo.navStyle == 2 ? "auto" : "100%",
                                  textAlign: _vm.fundemo.textImgPosLink,
                                  color: _vm.fundemo.textNavColor,
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.fundemo.title) + " ")]
                            )
                          : _vm._e(),
                        _vm.fundemo.navStyle == 3
                          ? _c("div", { staticClass: "search" }, [
                              _c("i", { staticClass: "el-icon-search" }),
                              _vm._v(" 请输入搜索内容 "),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "cnotent_crotols",
              style: {
                width: `calc(100% - ${_vm.fundemo.template.margin.both * 2}px`,
                margin: `0 auto`,
              },
            },
            _vm._l(_vm.controls, function (cortal, index) {
              return _c(
                "div",
                {
                  key: index,
                  class:
                    _vm.checknum == index ? "active_canvas" : "canvas_itembox",
                  on: {
                    click: function ($event) {
                      return _vm.checkRightToll(cortal.type, index)
                    },
                  },
                },
                [
                  _c(_vm.contentCanvas[0].get(cortal.type), {
                    tag: "component",
                    attrs: { index: index },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }