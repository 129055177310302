<template>
  <div class="container">
    <div v-show="show == 0" v-drags class="callphone_one" @click="unfold"></div>
    <div v-show="show == 1" ref="child" v-drag class="callphone_two" onselectstart="return false">
      <div class="clsoe el-icon-close" @click="clsoecall(1)"></div>
      <div class="funcition">
        <div v-if="callnum == 1" class="call_icon" @click="checnum(2, true)">
          <i class="el-icon-phone"></i>
        </div>
        <!-- 待接听黄色1 -->
        <div v-if="callnum == 2" class="call_icon yelel hov" @click="checnum(1, false)">
          <i class="el-icon-phone anmation"></i>
        </div>
        <!-- 准备接听绿色 -->
        <div v-if="callnum == 3" class="call_icon grenyel hov" @click="checnum(1, false)">
          <i class="el-icon-phone anmation"></i>
        </div>
        <div class="call_center">
          <div class="call_top">
            <!-- <el-popover trigger="click" width="400"> -->
            <div slot="reference" style="margin-left: 4px; font-size: 12px">
              {{ takeinfo.username }}
              <!-- <i class="el-icon-caret-bottom"></i> -->
            </div>
            <!-- </el-popover> -->
          </div>
          <div class="call_bottom">
            <el-dropdown trigger="click">
              <div class="call_status" style="font-size: 12px" :style="{ color: Takestatus == '未授权' ? 'rgb(212, 6, 6)' : 'white' }">
                <i v-if="Takestatus == '未授权'" class="el-icon-warning-outline"></i>
                {{ Takestatus }}
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><div class="regis" @click="getAgentMsg">在线</div></el-dropdown-item>
                <el-dropdown-item><div class="callout" @click="unregisetPhone">离线</div></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="call_right">
          <el-tooltip>
            <div slot="content">
              人工呼叫web坐席注意事项:
              <br />
              1、注意浏览器需要是新版谷歌
              <br />
              2、注意域名需要加https
              <br />
              3、注意话机状态需要是【上线】
              <br />
              4、注意需要有麦克风
              <br />
              5、注意第一次通话时，浏览器弹出是否允许麦克风，需点击【允许】
            </div>
            <div class="el-icon-warning" style="color: white"></div>
          </el-tooltip>
          <div class="screeen" @click="pan">
            <img alt="" src="../../../assets//img/fullscreen.png" />
          </div>
        </div>
      </div>
    </div>
    <div v-show="show == 2" ref="child" v-drag class="callphone_two" onselectstart="return false" style="align-items: flex-start; width: 280px; height: 450px; border-radius: 16px">
      <div class="funcition" style="display: grid; grid-template-columns: repeat(1, 1fr); gap: 10px; width: 100%">
        <div class="call_center" style="display: grid; grid-template-columns: repeat(3, 1fr); align-items: center; margin-top: 18px">
          <div class="call_top">
            <!-- <el-popover trigger="click" width="400"> -->
            <div slot="reference" style="margin-left: 10px; font-size: 12px">
              {{ takeinfo.username }}
              <!-- <i class="el-icon-caret-bottom"></i> -->
            </div>
            <!-- </el-popover> -->
          </div>
          <div class="call_bottom">
            <el-dropdown trigger="click">
              <div class="call_status" style="font-size: 12px" :style="{ color: Takestatus == '未授权' ? 'rgb(212, 6, 6)' : 'white' }">
                <i v-if="Takestatus == '未授权'" class="el-icon-warning-outline"></i>
                {{ Takestatus }}
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><div class="regis" @click="getAgentMsg">在线</div></el-dropdown-item>
                <el-dropdown-item><div class="callout" @click="unregisetPhone">离线</div></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-popover trigger="click" width="400">
              <!-- <el-table :data="gridData" />
              <div
                slot="reference"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 20px;
                  height: 20px;
                  margin-left: 5px;
                  color: white;
                  background-color: #3e3e3e;
                  border-radius: 100%;
                "
              >
                <i class="el-icon-s-tools"></i>
              </div> -->
            </el-popover>
          </div>
        </div>
        <div class="call_right" style="top: 30px">
          <el-tooltip>
            <div slot="content">
              人工呼叫web坐席注意事项:
              <br />
              1、注意浏览器需要是新版谷歌
              <br />
              2、注意域名需要加https
              <br />
              3、注意话机状态需要是【上线】
              <br />
              4、注意需要有麦克风
              <br />
              5、注意第一次通话时，浏览器弹出是否允许麦克风，需点击【允许】
            </div>
            <div class="el-icon-warning" style="color: white"></div>
          </el-tooltip>
          <div class="screeen" @click="clsoecall(2)">
            <img alt="" src="../../../assets//img/fullscreen.png" />
          </div>
        </div>
        <div class="Keyboard">
          <div class="scanner"><input v-model="callphone" maxlength="15" placeholder="请输入拨打号码" style="background-color: none" /></div>
          <div class="Numeric-keypad">
            <div v-for="(ym, index) in 9" :key="index" class="keypad" @click="checkcall(index + 1)">{{ index + 1 }}</div>
            <div class="keypad" @click="checkcall('*')">*</div>
            <div class="keypad" @click="checkcall('0')">0</div>
            <div class="keypad" @click="checkcall('#')">#</div>
            <div class="claer" style="line-height: 50px; color: white; text-align: center" @click="callphone = ''">清空</div>
            <div class="keypad linar" style="color: white; text-align: center; background-color: #3782ff" @click="calkeys"><i class="el-icon-phone"></i></div>
            <div class="linar" style="line-height: 50px; color: white; text-align: center" @click="popnum"><i class="el-icon-caret-left"></i></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 媒体播放dom树 -->
    <audio id="remoteAudio" autoPlay />
  </div>
</template>
<script>
import { getAgentMsg } from '@/api/mall/health/seas/seasManger'
let sdk = require('../../../../public/static/js/sdk.min.js')
// import sdk from './components/sdk.min.js'
export default {
  directives: {
    drag(el) {
      // console.log(el)
      // let originalSelectStartHandler = document.onselectstart
      let isDragging = false

      el.onmousedown = function (e) {
        // 标记开始拖拽
        isDragging = true

        let disX = e.clientX - el.offsetLeft
        let disY = e.clientY - el.offsetTop

        // 删除以下禁止选中文本的相关代码
        // document.onselectstart = function () {
        //   if (isDragging) {
        //     return false;
        //   }
        // };

        document.onmousemove = function (e) {
          if (isDragging) {
            let l = e.clientX - disX
            let t = e.clientY - disY
            el.style.left = l + 'px'
            el.style.top = t + 'px'
          }
        }

        document.onmouseup = function () {
          // 结束拖拽
          isDragging = false
          // 删除以下禁止选中文本的相关代码
          // document.onselectstart = originalSelectStartHandler;
          document.onmousemove = null
          document.onmouseup = null
        }

        return true // 恢复内置事件
      }
    },
    // drag(el) {
    //   console.log(el)
    //   let originalSelectStartHandler = document.onselectstart;
    //   el.onmousedown = function (e) {
    //     let disX = e.clientX - el.offsetLeft;
    //     let disY = e.clientY - el.offsetTop;
    //     document.onselectstart = function () {
    //       return true;
    //     };
    //     document.onmousemove = function (e) {
    //       let l = e.clientX - disX;
    //       let t = e.clientY - disY;
    //       el.style.left = l + 'px';
    //       el.style.top = t + 'px';
    //     };
    //     document.onmouseup = function () {
    //       document.onselectstart = originalSelectStartHandler;
    //       document.onmousemove = null;
    //       document.onmouseup = null;
    //     };
    //     return true;//恢复内置事件
    //   };

    // },
    // drags(el) {
    //   console.log(el)
    //   let oDiv = el
    //   document.onselectstart = function () {
    //     return false
    //   }
    //   oDiv.onmousedown = function (e) {
    //     let disY = e.clientY - oDiv.offsetTop
    //     document.onmousemove = function (e) {
    //       let t = e.clientY - disY
    //       oDiv.style.top = t + 'px'
    //     }
    //     document.onmouseup = function () {
    //       document.onmousemove = null
    //       document.onmouseup = null
    //     }
    //     return false
    //   }
    // },
    drags(el) {
      // console.log(el)
      let oDiv = el
      let originalSelectStartHandler = document.onselectstart
      // document.onselectstart = function () {
      //   return false
      // }
      oDiv.onmousedown = function (e) {
        let disY = e.clientY - oDiv.offsetTop
        document.onmousemove = function (e) {
          let t = e.clientY - disY
          oDiv.style.top = t + 'px'
        }
        document.onmouseup = function () {
          document.onselectstart = originalSelectStartHandler
          document.onmousemove = null
          document.onmouseup = null
        }
        return true
      }
    },
  },
  data() {
    return {
      callnum: 1,
      show: 0,
      gridData: [],
      callphone: '',
      sop: null, //sip注册信息
      Takestatus: '未授权',
      takeinfo: { username: '还未授权' }, //坐席信息
      phoneUa: null,
    }
  },
  created() {
    // 在组件创建时，使用 $watch 监听 Vue Bus 的值变化
    this.$EventBus.$on('eventName', (e) => {
      //需要执行的代码
      this.callphones(e)
    })
  },
  methods: {
    regisetPhone(row) {
      // this.phoneUa=new SIP.SDK.cxWebPhone({
      this.phoneUa = sdk.SDK.cxWebPhone({
        userName: row.username,
        password: row.password,
        server: row.real,
        traceSip: false,
        builtinEnabled: true,
        audioElement: 'remoteAudio',
        in: {
          terminated: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          accepted: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          bye: (response, cause, type) => this.webPhoneEvent(response, cause, type),

          rejected: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          failed: (response, cause, type) => this.webPhoneEvent(response, cause, type),
        },
        ua: {
          invite: (response, type) => this.webPhoneUa(response, type),
          registered: (response, type) => this.webPhoneUa(response, type),
          unregister: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          registrationFailed: (response, cause, type) => this.webPhoneEvent(response, type),
          connecting: (response, type) => this.webPhoneUa(response, type),
          connected: (response, type) => this.webPhoneUa(response, type),
          disconnecting: (response, type) => this.webPhoneUa(response, type),
          disconnected: (response, type) => this.webPhoneUa(response, type),
        },
      })
    },
    getAgentMsg() {
      if (this.Takestatus == '未授权') {
        getAgentMsg({}).then((res) => {
          if (res.status == 200) {
            this.sop = true
            this.$message.success('坐席注册成功')
            this.Takestatus = '在线'
            this.takeinfo = res.data
            this.regisetPhone(res.data)
          }
        })
      } else {
        this.$message.error('您已在线请勿重复注册')
      }
    },
    //键盘拨打
    calkeys() {
      if (this.Takestatus == '未授权') {
        this.$confirm('您未曾授权坐席是否授权', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.getAgentMsg()
          })
          .catch(() => {
            this.$message('您已取消授权')
          })
      } else {
        if (this.callphone.length < 11) {
          this.$message.error('请输入正确的拨打号码')
        } else {
          this.callphones(this.callphone)
        }
      }
    },
    callphones(phone) {
      if (this.sop) {
        this.$confirm('是否拨打该号码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            new sdk.SDK.callWebPhone({
              phoneNumber: phone,
              out: {
                progress: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                accepted: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                rejected: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                failed: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                terminated: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                bye: (response, cause, type) => this.webPhoneEvent(response, cause, type),
              },
            })
          })
          .catch(() => {
            this.$message('您已取消拨打')
          })
      } else {
        this.$message.error('坐席获取失败，请联系管理员配置')
      }
    },
    webPhoneEvent(response, cause, type) {
      // console.log(response, cause, type)
      //电话状态
      if (type == 'out.terminated') {
        this.$message.success('通话已结束')
      }
      if (type == 'out.accepted') {
        this.callnum = 3
      }
      if (type == 'out.rejected') {
        this.$message.error('客户已拒绝接听')
        this.callnum = 1
      }
      if (type == 'out.failed') {
        this.$message.error('呼叫客户异常,请联系管理员')
        this.callnum = 1
      }
      if (type == 'out.bye') {
        this.$message.success('您挂断成功')
        this.callnum = 1
      }
    },
    webPhoneUa(response, type) {
      // console.warn('type,response', type, response)
      if (type === 'ua.invite') {
        var msg = response.remoteIdentity.uri.user + '来电了，是否接听'
        if (confirm(msg) == true) {
          response.answer()
        } else {
          response.reject()
        }
      }
    },
    hangupPhone() {
      // console.log('hangup 1111111111111')
      this.phoneUa.hangup()
    },
    unregisetPhone() {
      this.Takestatus = '未授权'
      this.takeinfo = { username: '还未授权' }
      this.phoneUa.unregister()
    },
    startWeb() {
      this.phoneUa.start()
    },
    stopWeb() {
      this.phoneUa.stop()
    },
    clsoecall(e) {
      // 通话过程关闭事件
      if (e == 1) {
        this.show = 0
        if (this.callnum != 1) {
          this.$message('已为您缩小窗口')
        }
      } else {
        if (this.callnum < 3) {
          this.$message('已为您缩小窗口')
        }
        this.show = 1
      }
    },
    checnum(e, blean) {
      if (blean) {
        // 初始化操作
        if (this.callnum == 1) {
          this.getAgentMsg()
        } else {
          this.$message.error('拨打号码不能为空')
        }
      } else if (blean != true) {
        //挂断操作
        this.callnum = 1
        this.hangupPhone()
      }
    },
    pan() {
      //判断是否是初始化拨打
      if (this.callnum == 1) {
        this.show = 2
      } else {
        this.$message.error('您正在通话中不能进行拨号操作')
      }
    },
    unfold() {
      this.show = 1
    },
    checkcall(e) {
      this.callphone += e
      // console.log(this.callphone)
    },
    popnum() {
      //删除Scanner
      this.callphone = this.callphone.substring(0, this.callphone.length - 1)
      // console.log(this.callphone)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .callphone_two {
    position: fixed;
    right: 30px;
    bottom: 5%;
    z-index: 2999;
  }
}
.callphone_one {
  position: fixed;
  right: -30px;
  bottom: 10%;
  z-index: 2999;
  width: 60px;
  height: 60px;
  cursor: move;
  background: #2b2a34;
  background-image: url('../../../assets/img/callphone.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  border-radius: 100%;
  box-shadow: 8px 0px 10px 0 rgba(79, 122, 253, 0.3);
  transition: ease 500ms;
  &:hover {
    right: 0;
  }
}
.callphone_two {
  display: flex;
  align-items: center;
  width: 260px;
  height: 60px;
  background: #2b2a34;
  border-radius: 100px;
  box-shadow: 8px 0px 10px 0 rgba(79, 122, 253, 0.3);
  .clsoe {
    position: absolute;
    top: -5px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    padding: 5px;
    color: white;
    cursor: pointer;
    background: gray;
    border-radius: 100%;
  }
  .call_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-left: 10px;
    color: white;
    background: rgb(138, 138, 138);
    border-radius: 100%;
    // animation: jellys infinite 0.5s;
  }
  .hov:hover {
    cursor: pointer;
    background: red;
    animation: infinite 0.5s;
  }
  .grenyel {
    background: rgb(3, 177, 3);
    animation: gre infinite 1s;
  }
  .yelel {
    background: rgb(235, 210, 44);
    animation: yel infinite 1s;
  }
  .anmation {
    font-size: 17px;
    animation: jelly infinite 0.5s;
  }
  @keyframes jelly {
    0%,
    100% {
      transform: scale(1, 1);
    }

    25% {
      transform: scale(0.9, 1.1);
    }

    50% {
      transform: scale(1.1, 0.9);
    }

    75% {
      transform: scale(0.95, 1.05);
    }
  }
  .funcition {
    display: flex;
    align-items: center;
    .call_center {
      margin-left: 10px;
      .call_top {
        font-weight: 600;
        color: white;
      }
      .call_status {
        padding: 1px 5px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        background: #3e3e3e;
        border-radius: 20px;
      }
      .call_bottom {
        display: flex;
        align-items: center;
      }
    }
    .call_right {
      position: absolute;
      top: 50%;
      right: 15px;
      display: flex;
      align-items: center;
      transform: translateY(-50%);
      .screeen {
        padding-left: 5px;
        margin-left: 5px;
        border-left: 1px solid rgb(116, 116, 116);
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .Keyboard {
    width: 100%;
    height: 350px;
    margin-left: 5px;
    .scanner {
      width: 90%;
      margin: auto;
      input {
        width: 100%;
        height: 35px;
        color: white;
        text-indent: 1em;
        background: none;
        background: rgb(51, 57, 70);
        border: none;
        border-radius: 8px;
      }
    }
    .Numeric-keypad {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      width: 90%;
      margin: auto;
      margin-top: 10px;
    }
    .keypad {
      width: 50px;
      height: 50px;
      margin: auto;
      font-size: 24px;
      line-height: 50px;
      color: white;
      text-align: center;
      background: #3e3e3e;
      border-radius: 100%;
    }
  }
}
</style>
