var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "textTool" },
    [
      _c("div", { staticClass: "toolTit" }, [
        _c("div", { staticClass: "lef" }, [_vm._v("图文导航")]),
        _c("div", { staticClass: "ref" }, [
          _c("div", { staticClass: "switch" }, [
            _c(
              "div",
              {
                class: _vm.checknum == 1 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(1)
                  },
                },
              },
              [_vm._v("内容")]
            ),
            _c(
              "div",
              {
                class: _vm.checknum == 2 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(2)
                  },
                },
              },
              [_vm._v("样式")]
            ),
          ]),
        ]),
      ]),
      _vm.checknum == 1
        ? _c(
            "div",
            { staticClass: "content_msg" },
            [
              _c("div", { staticClass: "ym_line" }),
              _c("div", { staticClass: "ym_title" }, [_vm._v("图文样式")]),
              _c("div", { staticClass: "itemBox" }, [
                _c("div", { staticClass: "Tit" }, [_vm._v("图文样式")]),
                _c(
                  "div",
                  { staticClass: "check-tit" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.activeComponents[_vm.indexnumer].mode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "mode",
                              $$v
                            )
                          },
                          expression: "activeComponents[indexnumer].mode",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "graphic" } }, [
                          _vm._v("图文导航"),
                        ]),
                        _c("el-radio-button", { attrs: { label: "img" } }, [
                          _vm._v("图片导航"),
                        ]),
                        _c("el-radio-button", { attrs: { label: "text" } }, [
                          _vm._v("文字导航"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "itemBox" }, [
                _c("div", { staticClass: "Tit" }, [_vm._v("显示类型")]),
                _c(
                  "div",
                  { staticClass: "check-tit" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.activeComponents[_vm.indexnumer].showStyle,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "showStyle",
                              $$v
                            )
                          },
                          expression: "activeComponents[indexnumer].showStyle",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "fixed" } }, [
                          _vm._v("默认固定"),
                        ]),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "singleSlide" } },
                          [_vm._v("单行滚动")]
                        ),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "pageSlide" } },
                          [_vm._v("分页滚动")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "itemBox" }, [
                _c("div", { staticClass: "Tit" }, [_vm._v("显示列数")]),
                _c(
                  "div",
                  { staticClass: "check-tit" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.activeComponents[_vm.indexnumer].rowCount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "rowCount",
                              $$v
                            )
                          },
                          expression: "activeComponents[indexnumer].rowCount",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "3" } }, [
                          _vm._v("3个"),
                        ]),
                        _c("el-radio-button", { attrs: { label: "4" } }, [
                          _vm._v("4个"),
                        ]),
                        _c("el-radio-button", { attrs: { label: "5" } }, [
                          _vm._v("5个"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm.activeComponents[_vm.indexnumer].showStyle != "singleSlide" &&
              _vm.activeComponents[_vm.indexnumer].showStyle != "fixed"
                ? _c("div", { staticClass: "itemBox" }, [
                    _c("div", { staticClass: "Tit" }, [_vm._v("每页行数")]),
                    _c(
                      "div",
                      { staticClass: "check-tit" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "mini" },
                            model: {
                              value:
                                _vm.activeComponents[_vm.indexnumer].pageCount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.activeComponents[_vm.indexnumer],
                                  "pageCount",
                                  $$v
                                )
                              },
                              expression:
                                "activeComponents[indexnumer].pageCount",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("1行"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "2" } }, [
                              _vm._v("2行"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "ym_line" }),
              _c("div", { staticClass: "ym_title" }, [_vm._v("图文导航项")]),
              _c("div", { staticClass: "alert-msg" }, [
                _vm._v("建议上传尺寸相同的图片(60px * 60px)"),
              ]),
              _c(
                "draggable",
                {
                  attrs: {
                    animation: "300",
                    "chosen-class": "chosen",
                    "ghost-class": "ghost",
                    group: "itxst",
                    move: _vm.onMove,
                  },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].list,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer],
                        "list",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].list",
                  },
                },
                _vm._l(
                  _vm.activeComponents[_vm.indexnumer].list,
                  function (ym, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "itemBox",
                        staticStyle: { height: "auto" },
                      },
                      [
                        _c("div", { staticClass: "card_list" }, [
                          _c("div", { staticClass: "ref_check" }, [
                            _c(
                              "div",
                              {
                                staticClass: "delclose",
                                on: {
                                  click: function ($event) {
                                    return _vm.deletelist(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                            _c("div", { staticClass: "top_headpic" }, [
                              _c("span", [_vm._v("图片")]),
                              _c(
                                "div",
                                { staticClass: "hide_pic" },
                                [
                                  _c("uploadpic", {
                                    attrs: { picurl: [{ url: ym.imageUrl }] },
                                    on: {
                                      backkuck: function ($event) {
                                        return _vm.check($event, index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "link_nav",
                                  on: {
                                    click: function ($event) {
                                      return _vm.checklist(index, ym.label)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-link" })]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "conten_sacnner" },
                              [
                                _c("span", [_vm._v("标题")]),
                                _c("el-input", {
                                  staticStyle: { "max-width": "200px" },
                                  model: {
                                    value: ym.title,
                                    callback: function ($$v) {
                                      _vm.$set(ym, "title", $$v)
                                    },
                                    expression: "ym.title",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "conten_sacnner",
                                staticStyle: {
                                  "justify-content": "space-between",
                                  "margin-top": "5px",
                                },
                              },
                              [
                                _c("span", [_vm._v("链接")]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "link_navlists",
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "margin-right": "30px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checklink(index)
                                      },
                                    },
                                  },
                                  [
                                    ym.link.name != ""
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#1780ff" } },
                                          [_vm._v(_vm._s(ym.link.title))]
                                        )
                                      : _c("span", [_vm._v("请选择链接")]),
                                    _c("i", {
                                      staticClass: "el-icon-arrow-right",
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }
                ),
                0
              ),
              _c(
                "div",
                { staticClass: "add_list", on: { click: _vm.addlist } },
                [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" 增加一个 ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.checknum == 2
        ? _c("div", { staticClass: "content_msg" }, [
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("图文样式")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("整体规范")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          model: {
                            value:
                              _vm.activeComponents[_vm.indexnumer].ornament
                                .type,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.activeComponents[_vm.indexnumer].ornament,
                                "type",
                                $$v
                              )
                            },
                            expression:
                              "activeComponents[indexnumer].ornament.type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "default" } }, [
                            _vm._v("默认"),
                          ]),
                          _c("el-radio", { attrs: { label: "shadow" } }, [
                            _vm._v("投影"),
                          ]),
                          _c("el-radio", { attrs: { label: "circle" } }, [
                            _vm._v("描边"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _vm.activeComponents[_vm.indexnumer].ornament.type != "default"
              ? _c("div", { staticClass: "itemBox" }, [
                  _c("div", { staticClass: "Tit" }, [
                    _vm.activeComponents[_vm.indexnumer].ornament.type ==
                    "shadow"
                      ? _c("span", [_vm._v("投影")])
                      : _vm.activeComponents[_vm.indexnumer].ornament.type ==
                        "circle"
                      ? _c("span", [_vm._v("描边")])
                      : _vm._e(),
                    _vm._v(" 颜色 "),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "check-tit",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c("div", { staticClass: "lef" }, [
                        _vm._v(
                          _vm._s(
                            _vm.activeComponents[_vm.indexnumer].ornament.color
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "ref",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            color: "#7aa4ff",
                          },
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.activeComponents[_vm.indexnumer].ornament
                                  .color,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.activeComponents[_vm.indexnumer].ornament,
                                  "color",
                                  $$v
                                )
                              },
                              expression:
                                "activeComponents[indexnumer].ornament.color",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "resect",
                              on: {
                                click: function ($event) {
                                  _vm.activeComponents[
                                    _vm.indexnumer
                                  ].ornament.color = "#FFFFFF"
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                          _c("i", {
                            staticClass: "iconfont icon-arrow-right",
                            staticStyle: { "font-size": "12px", color: "gray" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", {
              staticClass: "ym_line",
              staticStyle: { padding: "2px 0" },
            }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("图片设置")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("图片圆角")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, min: 0, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].aroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "aroundRadius",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].aroundRadius",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].aroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "aroundRadius",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].aroundRadius",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("图片大小")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 60, min: 30, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].imageSize,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "imageSize",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].imageSize",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 60, min: 30 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].imageSize,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "imageSize",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].imageSize",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", {
              staticClass: "ym_line",
              staticStyle: { padding: "2px 0" },
            }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("文字设置")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("文字大小")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 16, min: 12, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].font.size,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].font,
                          "size",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].font.size",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 16, min: 12 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].font.size,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].font,
                          "size",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].font.size",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("文字加粗")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.activeComponents[_vm.indexnumer].font.weight,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.activeComponents[_vm.indexnumer].font,
                            "weight",
                            $$v
                          )
                        },
                        expression: "activeComponents[indexnumer].font.weight",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "bold" } }, [
                        _vm._v("加粗"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "normal" } }, [
                        _vm._v("常规"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("文字颜色")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "check-tit",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c("div", { staticClass: "lef" }, [
                        _vm._v(
                          _vm._s(
                            _vm.activeComponents[_vm.indexnumer].font.color
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "ref",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            color: "#7aa4ff",
                          },
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.activeComponents[_vm.indexnumer].font.color,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.activeComponents[_vm.indexnumer].font,
                                  "color",
                                  $$v
                                )
                              },
                              expression:
                                "activeComponents[indexnumer].font.color",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "resect",
                              on: {
                                click: function ($event) {
                                  _vm.activeComponents[
                                    _vm.indexnumer
                                  ].font.color = "#333333"
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                          _c("i", {
                            staticClass: "iconfont icon-arrow-right",
                            staticStyle: { "font-size": "12px", color: "gray" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _c("div", {
              staticClass: "ym_line",
              staticStyle: { padding: "2px 0" },
            }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("组件样式")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("底部背景")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(_vm.activeComponents[_vm.indexnumer].pageBgColor)
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer].pageBgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "pageBgColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].pageBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[_vm.indexnumer].pageBgColor =
                                ""
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("组件背景")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(
                        _vm.activeComponents[_vm.indexnumer].componentBgColor
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer]
                              .componentBgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "componentBgColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].componentBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[
                                _vm.indexnumer
                              ].componentBgColor = ""
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("上边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 100, min: 0, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.top,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "top",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.top",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 100, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.top,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "top",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.top",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("下边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 100, min: 0, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "bottom",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.bottom",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 100, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "bottom",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.bottom",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("左右边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 20, min: 0, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.both",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 20, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.both",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("上圆角")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, min: 0, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].topAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "topAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].topAroundRadius",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].topAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "topAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].topAroundRadius",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("下圆角")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, min: 0, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].bottomAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "bottomAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].bottomAroundRadius",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].bottomAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "bottomAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].bottomAroundRadius",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("stydialog", {
        attrs: { listrow: _vm.listcheck, showstyle: _vm.showhide },
        on: { closehide: _vm.cloeshow },
      }),
      _c("linkdialog", {
        attrs: { linkshow: _vm.linkurl },
        on: { closeurl: _vm.closelink },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }