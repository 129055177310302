<template>
  <div>
    <el-dialog
      :before-close="handleClose"
      title="编辑"
      :visible.sync="dialogVisible"
      width="60%">
      <span>
          <el-collapse v-model="shownumber" style="border: 1px solid #ebeef5;">
          <el-collapse-item name="1" style="text-indent: 1em;" title="店铺信息">
            <el-form label-width="120px" :model="activeNames">
                <el-form-item label="店铺名：">
                  <el-input v-model="activeNames.name" placeholder="请输入 店铺名"/>
                </el-form-item>
                <el-form-item label="电话：">
                  <el-input v-model="activeNames.name" placeholder="请输入 电话"/>
                </el-form-item>
                <el-form-item label="详细地址：">
                  <el-input v-model="activeNames.name" placeholder="请输入 详细地址"/>
                </el-form-item>
                <el-form-item label="详细介绍：">
                  <el-input v-model="activeNames.name" placeholder="请输入 详细介绍"/>
                </el-form-item>
                <el-form-item label="店铺LOGO:">
                  <el-input v-model="activeNames.name" placeholder="请输入 店铺LOGO"/>
                </el-form-item>
            </el-form>
          </el-collapse-item>
          <el-collapse-item name="2" style="text-indent: 1em;"  title="店长信息"/>
          <el-collapse-item name="3" style="text-indent: 1em;"  title="审核信息"/>
        </el-collapse>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  export default{
    name:"Applyeditdialog",
    props:{
      edshow:{
        type:Boolean,
        default:()=>{}
      },
      editrow:{
        type:Object,
        default:()=>{}
      }
    },
    data(){
      return{
        dialogVisible:this.edshow,
        shownumber:['1','2','3'],
        activeNames:this.editrow,
        zoom: 12,
        center: [121.59996, 31.197646],
        map: null
      }
    },
    watch:{
      edshow(newValue,oldValue){
        if(newValue!=oldValue){
          this.dialogVisible=newValue
          this.shownumber=['1','2','3']
        }
      },
      editrow(newValue,oldValue){
        if(newValue!=oldValue){
          this.activeNames=newValue
        }
      }
    },
    created(){
      console.log(this.editrow)
    },
    methods:{
      handleClose(){
        this.$emit('closeedit',false)
      },
     
    }
  }
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header{
  border-bottom: 1px solid #ebeef5;
}
::v-deep .el-form{
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  display: flex;
  flex-wrap: wrap;
}

</style>