var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comprehensive-table-container" }, [
    _c(
      "div",
      { staticClass: "tab" },
      [
        _c(
          "el-button",
          {
            staticClass: "addClick",
            attrs: { type: "primary" },
            on: { click: _vm.addClick },
          },
          [_vm._v("新增")]
        ),
        _c(
          "el-table",
          { attrs: { border: "", data: _vm.tableData } },
          [
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "全称",
                prop: "fullname",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "简称",
                prop: "shortname",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "创建时间",
                prop: "createTime",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                fixed: "right",
                label: "操作",
                prop: "rateValue",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.handleEdit(scope.row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(scope.row)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.addorEditType ? "新增" : "编辑",
              visible: _vm.dialogFormVisible,
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogFormVisible = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  "label-width": "100px",
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "全称", prop: "fullname" } },
                  [
                    _c("el-input", {
                      attrs: { autocomplete: "off" },
                      model: {
                        value: _vm.ruleForm.fullname,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "fullname", $$v)
                        },
                        expression: "ruleForm.fullname",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "简称", prop: "shortname" } },
                  [
                    _c("el-input", {
                      attrs: { autocomplete: "off" },
                      model: {
                        value: _vm.ruleForm.shortname,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "shortname", $$v)
                        },
                        expression: "ruleForm.shortname",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.handleCancel("ruleForm")
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleOk("ruleForm")
                      },
                    },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }