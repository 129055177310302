<template>
  <div class="comprehensive-table-container">
    <div class="header-title">添加计划</div>
    <el-form label-width="80px" :model="subForm">
      <el-form-item label="计划名称" prop="planName">
        <el-input v-model="subForm.planName" placeholder="请输入计划名称" />
      </el-form-item>
      <el-form-item label="计划类型" prop="planType">
        <el-select v-model="subForm.planType" placeholder="请选择计划类型">
          <el-option v-for="(ym, index) in platype" :key="index" :label="ym.planName" :value="ym.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="计划图片" prop="planLogo">
        <YmUpload @backkuck="check" />
      </el-form-item>
      <el-form-item label="执行计划" prop="" />
      <el-table class="plans-table" :data="subForm.vitMealPlanOptPos" style="width: 100%">
        <el-table-column align="center" label="天数" prop="date" width="180">
          <template slot-scope="scope">
            <span style="line-height: 30px">第{{ scope.$index + 1 }}天</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="时间" prop="optTime" width="200">
          <template slot-scope="scope">
            <el-time-select
              v-for="(item, ind) in scope.row.vitMealPlanOptionPos"
              :key="ind"
              v-model="item.optTime"
              :picker-options="{
                start: '00:00',
                end: '23:59',
              }"
              placeholder="选择时间"
              :value="item"
            />
          </template>
          "
        </el-table-column>
        <el-table-column align="center" label="预计执行时长(分)" prop="optNum" width="180">
          <template slot-scope="scope">
            <el-input v-for="(item, ind) in scope.row.vitMealPlanOptionPos" :key="ind" v-model="item.optNum" placeholder="请输入预计执行时长" />
          </template>
        </el-table-column>
        <el-table-column align="center" label="执行内容" prop="optInfo">
          <template slot-scope="scope">
            <div class="add-tablea">
              <el-input v-for="(item, ind) in scope.row.vitMealPlanOptionPos" :key="ind" v-model="item.optInfo" placeholder="请输入执行内容" />
            </div>
            <el-input v-model="scope.row.addTable" placeholder="添加执行内容" @change="addTabel(scope.$index)" />
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" prop="name" width="180">
          <template slot-scope="scope">
            <div v-for="(item, ind) in scope.row.vitMealPlanOptionPos" :key="ind" class="sc">
              <el-button type="text" @click="checkdel(ind, scope.$index)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="addDaye" @click="addDayeplay">添加下一天执行方案</div>
      <el-form-item label="计划说明" prop="" />
      <el-table :data="subForm.vitMealPlanDesPos" style="width: 100%">
        <el-table-column align="center" label="标题" prop="desTitle" width="200">
          <template slot-scope="scope">
            <el-input v-model="scope.row.desTitle" placeholder="请输入标题" />
          </template>
        </el-table-column>
        <el-table-column align="center" label="标题内容" prop="desContent">
          <template slot-scope="scope">
            <el-input v-model="scope.row.desContent" placeholder="请输入标题内容" />
          </template>
        </el-table-column>
      </el-table>
      <div class="addDaye" @click="addDayered">添加下一条说明</div>
      <div style="margin: auto; display: flex; justify-content: center"><el-button type="primary" @click="submit">添加计划</el-button></div>
    </el-form>
  </div>
</template>

<script>
import { vitMealPlanTypequeryPage, vitMealPlanadd } from '@/api/mall/health/healthplantype/index'
import YmUpload from './components/index.vue'
export default {
  components: {
    YmUpload,
  },
  data() {
    return {
      subForm: {
        planLogo: [],
        vitMealPlanOptPos: [
          {
            optDay: 1,
            vitMealPlanOptionPos: [
              {
                optName: '',
                optTime: '00:00',
                optInfo: '',
                optNum: '0',
              },
            ],
            addTable: '',
          },
        ],
        vitMealPlanDesPos: [{ desTitle: '', desContent: '' }],
      },
      platype: [],
    }
  },
  created() {
    this.planType()
  },
  methods: {
    submit() {
      vitMealPlanadd({ ...this.subForm }).then((res) => {
        console.log(res.data)
        this.$router.go(-1)
      })
    },
    addDayered() {
      this.subForm.vitMealPlanDesPos.push({ desTitle: '', desContent: '' })
    },
    addDayeplay() {
      this.subForm.vitMealPlanOptPos.push({
        optDay: this.subForm.vitMealPlanOptPos.length + 1,
        vitMealPlanOptionPos: [
          {
            optName: '',
            optTime: '00:00',
            optInfo: '',
            optNum: '0',
          },
        ],
        addTable: '',
      })
    },
    addTabel(e) {
      console.log(e, this.addTable)
      console.log(this.subForm.vitMealPlanOptPos[e].vitMealPlanOptionPos)
      this.subForm.vitMealPlanOptPos[e].vitMealPlanOptionPos.push({
        optName: '',
        optTime: '00:00',
        optInfo: this.subForm.vitMealPlanOptPos[e].addTable,
        optNum: '',
      })
      this.subForm.vitMealPlanOptPos[e].addTable = ''
    },
    planType() {
      vitMealPlanTypequeryPage({ current: 1, size: 1000 }).then((res) => {
        console.log(res.data)
        this.platype = res.data.records
      })
    },
    check(e) {
      console.log('dwq', e)
      if (e.type == 'add') {
        this.subForm.planLogo.push(e.url)
      } else {
        this.subForm.planLogo.splice(this.subForm.planLogo.indexOf(e.url), 1)
      }
      console.log(this.subForm.planLogo)
    },
    checkdel(index, scId) {
      if (this.subForm.vitMealPlanOptPos[scId].vitMealPlanOptionPos.length > 1) {
        this.subForm.vitMealPlanOptPos[scId].vitMealPlanOptionPos.splice(index, 1)
      } else {
        this.$message.warning('至少保留一条执行计划内容')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.comprehensive-table-container {
  .header-title {
    font-size: 14px;
    height: 60px;
    line-height: 60px;
    color: #333;
    font-weight: bold;
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;
  }
  ::v-deep {
    .plans-table {
      .el-table__row .cell {
        min-height: 100px;
        .el-input__inner {
          margin-bottom: 10px;
        }
      }
    }
    tbody .el-table_1_column_4,
    tbody .el-table_1_column_2,
    tbody .el-table_1_column_3 {
      // background: rebeccapurple;
    }
  }
  .addDaye {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    border: 1px solid #e5e5e5;
    margin: 40px 0;
    cursor: pointer;
  }
  .sc {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
