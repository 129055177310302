var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.clear,
            title: "历史数据",
            visible: _vm.appointDialog,
            width: "75%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.appointDialog = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            _vm._l(_vm.forms, function (item, index) {
              return _c(
                "el-tab-pane",
                { key: index, attrs: { label: `数据 ${index + 1}` } },
                [
                  _c(
                    "div",
                    { class: _vm.screen ? "contents" : "content" },
                    [
                      _c(
                        "el-form",
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("体检时间")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        forma: "yyyy-MM-dd",
                                        placeholder: " 年 月 日",
                                        type: "datetime",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: item.userYear,
                                        callback: function ($$v) {
                                          _vm.$set(item, "userYear", $$v)
                                        },
                                        expression: "item.userYear",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("手机号")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "手机号" },
                                      model: {
                                        value: item.phone,
                                        callback: function ($$v) {
                                          _vm.$set(item, "phone", $$v)
                                        },
                                        expression: "item.phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("姓名")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "姓名" },
                                      model: {
                                        value: item.userName,
                                        callback: function ($$v) {
                                          _vm.$set(item, "userName", $$v)
                                        },
                                        expression: "item.userName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("性别")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择客户性别",
                                        },
                                        model: {
                                          value: item.userSex,
                                          callback: function ($$v) {
                                            _vm.$set(item, "userSex", $$v)
                                          },
                                          expression: "item.userSex",
                                        },
                                      },
                                      _vm._l(_vm.options, function (ite) {
                                        return _c("el-option", {
                                          key: ite.value,
                                          attrs: {
                                            label: ite.label,
                                            value: ite.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("年龄")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "年龄" },
                                      model: {
                                        value: item.userAge,
                                        callback: function ($$v) {
                                          _vm.$set(item, "userAge", $$v)
                                        },
                                        expression: "item.userAge",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("患病年限")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "患病年限" },
                                      model: {
                                        value: item.fattyIlness,
                                        callback: function ($$v) {
                                          _vm.$set(item, "fattyIlness", $$v)
                                        },
                                        expression: "item.fattyIlness",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("身高 cm")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("体重 kg")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("BMI")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("腰围")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("血压")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("心率")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("是否规律用药")]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "身高" },
                                      model: {
                                        value: item.userHeight,
                                        callback: function ($$v) {
                                          _vm.$set(item, "userHeight", $$v)
                                        },
                                        expression: "item.userHeight",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "体重" },
                                      model: {
                                        value: item.userWeight,
                                        callback: function ($$v) {
                                          _vm.$set(item, "userWeight", $$v)
                                        },
                                        expression: "item.userWeight",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "BMI" },
                                      model: {
                                        value: item.userMbi,
                                        callback: function ($$v) {
                                          _vm.$set(item, "userMbi", $$v)
                                        },
                                        expression: "item.userMbi",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "腰围" },
                                      model: {
                                        value: item.userWaist,
                                        callback: function ($$v) {
                                          _vm.$set(item, "userWaist", $$v)
                                        },
                                        expression: "item.userWaist",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "血压" },
                                      model: {
                                        value: item.userPressure,
                                        callback: function ($$v) {
                                          _vm.$set(item, "userPressure", $$v)
                                        },
                                        expression: "item.userPressure",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "心率" },
                                      model: {
                                        value: item.userHeart,
                                        callback: function ($$v) {
                                          _vm.$set(item, "userHeart", $$v)
                                        },
                                        expression: "item.userHeart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.medRegularly,
                                          callback: function ($$v) {
                                            _vm.$set(item, "medRegularly", $$v)
                                          },
                                          expression: "item.medRegularly",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                    staticStyle: {
                                      height: "72px",
                                      "line-height": "72px",
                                    },
                                  },
                                  [_vm._v("是否打针")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" 胰岛素 "),
                                    _c("el-checkbox", { attrs: { label: "" } }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" 药名： "),
                                    _c("el-input", {
                                      attrs: { placeholder: "药名" },
                                      model: {
                                        value: item.casePos1[0].insulinTitle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item.casePos1[0],
                                            "insulinTitle",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.casePos1[0].insulinTitle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" 剂量： "),
                                    _c("el-input", {
                                      attrs: { placeholder: "剂量" },
                                      model: {
                                        value: item.casePos1[0].caseDose,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item.casePos1[0],
                                            "caseDose",
                                            $$v
                                          )
                                        },
                                        expression: "item.casePos1[0].caseDose",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" GLP-1 类 "),
                                    _c("el-checkbox", { attrs: { label: "" } }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" 药名： "),
                                    _c("el-input", {
                                      attrs: { placeholder: "药名" },
                                      model: {
                                        value: item.casePos1[1].insulinTitle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item.casePos1[1],
                                            "insulinTitle",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.casePos1[1].insulinTitle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" 剂量： "),
                                    _c("el-input", {
                                      attrs: { placeholder: "剂量" },
                                      model: {
                                        value: item.casePos1[1].caseDose,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item.casePos1[1],
                                            "caseDose",
                                            $$v
                                          )
                                        },
                                        expression: "item.casePos1[1].caseDose",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                    staticStyle: {
                                      height: "146px",
                                      "line-height": "146px",
                                    },
                                  },
                                  [_vm._v("是否口服药")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" 药名： "),
                                    _c("el-input", {
                                      attrs: { placeholder: "药名" },
                                      model: {
                                        value: item.casePos2[0].insulinTitle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item.casePos2[0],
                                            "insulinTitle",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.casePos2[0].insulinTitle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" 一天 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos2[0].caseCishu,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos2[0],
                                                "caseCishu",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos2[0].caseCishu",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 次,一次 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos2[0].casePian,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos2[0],
                                                "casePian",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos2[0].casePian",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 片，一片 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos2[0].caseMg,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos2[0],
                                                "caseMg",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos2[0].caseMg",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" mg "),
                                  ]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" 药名： "),
                                    _c("el-input", {
                                      attrs: { placeholder: "药名" },
                                      model: {
                                        value: item.casePos2[1].insulinTitle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item.casePos2[1],
                                            "insulinTitle",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.casePos2[1].insulinTitle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" 一天 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos2[1].caseCishu,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos2[1],
                                                "caseCishu",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos2[1].caseCishu",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 次,一次 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos2[1].casePian,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos2[1],
                                                "casePian",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos2[1].casePian",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 片，一片 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos2[1].caseMg,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos2[1],
                                                "caseMg",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos2[1].caseMg",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" mg "),
                                  ]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" 药名： "),
                                    _c("el-input", {
                                      attrs: { placeholder: "药名" },
                                      model: {
                                        value: item.casePos2[2].insulinTitle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item.casePos2[2],
                                            "insulinTitle",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.casePos2[2].insulinTitle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" 一天 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos2[2].caseCishu,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos2[2],
                                                "caseCishu",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos2[2].caseCishu",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 次,一次 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos2[2].casePian,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos2[2],
                                                "casePian",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos2[2].casePian",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 片，一片 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos2[2].caseMg,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos2[2],
                                                "caseMg",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos2[2].caseMg",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" mg "),
                                  ]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" 药名： "),
                                    _c("el-input", {
                                      attrs: { placeholder: "药名" },
                                      model: {
                                        value: item.casePos2[3].insulinTitle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item.casePos2[3],
                                            "insulinTitle",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.casePos2[3].insulinTitle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _vm._v(" 一天 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos2[3].caseCishu,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos2[3],
                                                "caseCishu",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos2[3].caseCishu",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 次,一次 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos2[3].casePian,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos2[3],
                                                "casePian",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos2[3].casePian",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 片，一片 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos2[3].caseMg,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos2[3],
                                                "caseMg",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos2[3].caseMg",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" mg "),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("是否会监测血糖")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 18 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.sugarStatus,
                                          callback: function ($$v) {
                                            _vm.$set(item, "sugarStatus", $$v)
                                          },
                                          expression: "item.sugarStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                    item.sugarStatus == 1
                                      ? _c(
                                          "div",
                                          [
                                            _vm._v(" (空腹： "),
                                            _c("el-input", {
                                              staticStyle: {
                                                "max-width": "100px",
                                              },
                                              attrs: { placeholder: "" },
                                              model: {
                                                value: item.sugarStomach,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "sugarStomach",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.sugarStomach",
                                              },
                                            }),
                                            _vm._v(" 餐后2小时: "),
                                            _c("el-input", {
                                              staticStyle: {
                                                "max-width": "100px",
                                              },
                                              attrs: { placeholder: "" },
                                              model: {
                                                value: item.sugarMeal,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "sugarMeal",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.sugarMeal",
                                              },
                                            }),
                                            _vm._v(" ) "),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("是否出现低血糖")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.sugarLowStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "sugarLowStatus",
                                              $$v
                                            )
                                          },
                                          expression: "item.sugarLowStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                item.sugarLowStatus == 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [_vm._v("出现时间和频率")]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [_vm._v("出现时间和频率")]
                                    ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                item.sugarLowStatus == 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-content bg-purple-dark",
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "出现时间和频率",
                                          },
                                          model: {
                                            value: item.sugarLowMeal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "sugarLowMeal",
                                                $$v
                                              )
                                            },
                                            expression: "item.sugarLowMeal",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("div", {
                                      staticClass:
                                        "grid-content bg-purple-dark",
                                    }),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                    staticStyle: { height: "100px" },
                                  },
                                  [_vm._v("既往病史")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 18 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                    staticStyle: {
                                      "flex-wrap": "wrap",
                                      height: "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        staticStyle: { "white-space": "wrap" },
                                        model: {
                                          value: item.casePos3,
                                          callback: function ($$v) {
                                            _vm.$set(item, "casePos3", $$v)
                                          },
                                          expression: "item.casePos3",
                                        },
                                      },
                                      _vm._l(_vm.ca, function (yn, ind) {
                                        return _c("el-checkbox", {
                                          key: ind,
                                          attrs: { label: yn.caseName },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("既往史是否用药")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 18 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.drugStatus,
                                          callback: function ($$v) {
                                            _vm.$set(item, "drugStatus", $$v)
                                          },
                                          expression: "item.drugStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                    item.drugStatus == 1
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-left": "20px",
                                            },
                                          },
                                          [_vm._v("药物名称：")]
                                        )
                                      : _vm._e(),
                                    item.drugStatus == 1
                                      ? _c("el-input", {
                                          attrs: { placeholder: "药物名称" },
                                          model: {
                                            value: item.drugName,
                                            callback: function ($$v) {
                                              _vm.$set(item, "drugName", $$v)
                                            },
                                            expression: "item.drugName",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("住院史")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 18 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "住院史地址" },
                                      model: {
                                        value: item.addressDetail,
                                        callback: function ($$v) {
                                          _vm.$set(item, "addressDetail", $$v)
                                        },
                                        expression: "item.addressDetail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("脂肪肝")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.fattyStatus,
                                          callback: function ($$v) {
                                            _vm.$set(item, "fattyStatus", $$v)
                                          },
                                          expression: "item.fattyStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("家族史")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: item.fattyName,
                                        callback: function ($$v) {
                                          _vm.$set(item, "fattyName", $$v)
                                        },
                                        expression: "item.fattyName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                    staticStyle: { height: "45px" },
                                  },
                                  [_vm._v("最希望改善的地方")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 18 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                    staticStyle: {
                                      "flex-wrap": "wrap",
                                      height: "45px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        staticStyle: { "white-space": "wrap" },
                                        model: {
                                          value: item.casePos4,
                                          callback: function ($$v) {
                                            _vm.$set(item, "casePos4", $$v)
                                          },
                                          expression: "item.casePos4",
                                        },
                                      },
                                      _vm._l(_vm.cas, function (yn, inde) {
                                        return _c("el-checkbox", {
                                          key: inde,
                                          attrs: { label: yn.caseName },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                    staticStyle: { height: "257px" },
                                  },
                                  [_vm._v("身体症状")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 21 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _vm._v(
                                      " 1、有无手麻、脚麻或皮肤瘙痒等症状？ "
                                    ),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.casePos5[0].caseStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.casePos5[0],
                                              "caseStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.casePos5[0].caseStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 21 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _vm._v(" 2、有无失眠、多梦或便秘等症状？ "),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.casePos5[1].caseStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.casePos5[1],
                                              "caseStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.casePos5[1].caseStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 21 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _vm._v(" 3、是否有便秘、腹泻等症状？ "),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.casePos5[2].caseStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.casePos5[2],
                                              "caseStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.casePos5[2].caseStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 21 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _vm._v(
                                      " 4、有无心慌、胸闷、气短、头晕头痛等心脑血管症状？ "
                                    ),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.casePos5[3].caseStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.casePos5[3],
                                              "caseStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.casePos5[3].caseStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 21 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _vm._v(" 5、有无视物模糊？ "),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.casePos5[4].caseStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.casePos5[4],
                                              "caseStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.casePos5[4].caseStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 21 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _vm._v(" 6、有无尿液泡沫增多？ "),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.casePos5[5].caseStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.casePos5[5],
                                              "caseStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.casePos5[5].caseStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 21 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _vm._v(
                                      " 7、有无糖尿病足部病变（如损伤后不容易愈合）？ "
                                    ),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.casePos5[6].caseStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.casePos5[6],
                                              "caseStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.casePos5[6].caseStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-content bg-purple-dark",
                                  },
                                  [_vm._v("饮食生活调查问卷")]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 8 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                  },
                                  [_vm._v("1、平时吃一顿饭用时")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 16 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _vm._v(" 大概 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.caseMg,
                                            callback: function ($$v) {
                                              _vm.$set(item, "caseMg", $$v)
                                            },
                                            expression: "item.caseMg",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 分钟 "),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 8 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                  },
                                  [_vm._v("2、晚上 8 点后会吃食物吗？")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 16 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.casePos6[1].caseStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.casePos6[1],
                                              "caseStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.casePos6[1].caseStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 一周 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos6[1].caseCishu,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos6[1],
                                                "caseCishu",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos6[1].caseCishu",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 次 "),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 8 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                  },
                                  [_vm._v("3、三餐以外会吃食物/零食吗？")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 16 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.casePos6[2].caseStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.casePos6[2],
                                              "caseStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.casePos6[2].caseStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 一周 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos6[2].caseCishu,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos6[2],
                                                "caseCishu",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos6[2].caseCishu",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 次 "),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 8 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                  },
                                  [_vm._v("4、是否常喝饮料/酒")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 16 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.casePos6[3].caseStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.casePos6[3],
                                              "caseStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.casePos6[3].caseStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 一周 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos6[3].caseCishu,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos6[3],
                                                "caseCishu",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos6[3].caseCishu",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 次 "),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 8 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                  },
                                  [_vm._v("5、平时会运动吗？")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 16 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.casePos6[4].caseStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.casePos6[4],
                                              "caseStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.casePos6[4].caseStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 一周 "),
                                    _c(
                                      "div",
                                      { staticClass: "textundeline" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { "max-width": "55px" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: item.casePos6[4].caseCishu,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.casePos6[4],
                                                "caseCishu",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.casePos6[4].caseCishu",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 次 "),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 8 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                  },
                                  [_vm._v("6、是否 12 点之前入睡？")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 16 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.casePos6[5].caseStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.casePos6[5],
                                              "caseStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.casePos6[5].caseStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 8 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                  },
                                  [_vm._v("7、睡眠质量好吗？")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 16 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple-dark lef_ym",
                                    staticStyle: { "white-space": "nowrap" },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.casePos6[6].caseStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.casePos6[6],
                                              "caseStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.casePos6[6].caseStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("好")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("不好")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clear } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }