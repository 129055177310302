<template>
  <el-dialog :before-close="handleClose" :close-on-click-modal="false" title="新增" :visible.sync="dialogVisibl" width="60%">
    <span>
      <el-form ref="formDatal" label-width="80px" :model="formDatal" :rules="rules">
        <el-form-item label="店铺:" prop="shopId">
          <el-select v-model="formDatal.shopId" placeholder="请选择店铺">
            <el-option v-for="(ym, index) in select" :key="index" :label="ym.name" :value="ym.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="账号:" prop="username">
          <el-input v-model="formDatal.username" placeholder="请输入账号" />
        </el-form-item>
        <el-form-item label="姓名:" prop="realName">
          <el-input v-model="formDatal.realName" placeholder="请输入真实姓名" />
        </el-form-item>
        <el-form-item label="密码:" prop="password">
          <el-input v-model="formDatal.password" placeholder="请输入密码" />
        </el-form-item>
        <el-form-item label="手机号:" prop="mobile">
          <el-input v-model="formDatal.mobile" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="邮箱:" prop="email">
          <el-input v-model="formDatal.email" placeholder="请输入邮箱" />
        </el-form-item>
        <el-form-item label="头像:" prop="avatar">
          <div v-if="formDatal.shopId" class="aval">
            <el-upload
              ref="upload"
              :action="action"
              :before-upload="bfUpload"
              :class="{
                hide: hideUpload,
              }"
              :file-list="fileList"
              :headers="headers"
              :limit="limitCount"
              list-type="picture-card"
              :on-change="handleChange"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog append-to-body :visible.sync="dialogVisible">
              <img alt="" :src="dialogImageUrl" width="100%" />
            </el-dialog>
          </div>
          <div v-else class="txt" style="color: gray">请选择店铺后上传</div>
        </el-form-item>
        <el-form-item class="postPare" label="所属部门:" prop="orgIds">
          <el-cascader ref="cascaderMallCatergory" v-model="formDatal.orgIds" clearable :options="dataSourceLists" :props="props" style="width: 60%" />
        </el-form-item>
        <el-form-item label="状态:" prop="status">
          <el-radio-group v-model="formDatal.status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="clear">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { uploadURL } from '@/config'
import { getToken } from '@/utils/token'
import { getList } from '@/api/mall/shop/info'
import { addShopUser } from '@/api/mall/users/user'
import { orgTree } from '@/api/mall/role/orgManage'
export default {
  props: {
    showadd: {
      type: Boolean,
      default: () => {},
    },
  },
  data() {
    return {
      label: 1,
      action: uploadURL,
      dataSourceLists: [],
      menuListTable: [],
      // orgIds: this.menuChange(this.addForm.orgIds),
      fileList: [],
      headers: {},
      limitCount: 1,
      current: 1,
      size: 10,
      dialogVisibl: this.dishide,
      dialogVisibles: false,
      dialogVisible: false,
      formDatal: {
        notifyTodo: 2, //2:服务商 3:专家
        status: 1, //状态  1：正常 2：禁用 3删除
        tenantId: '1', //角色 租户ID
        postId: 0, //0->其它 1->专家
        delFlag: 0, //0-正常，1-删除
        organId: '29a4609f543eb7a1b0f8b38ce01cc125', //机构ID
        type: 2, //账号类型：-1、系统管理员；1、租户管理员；2、店铺管理员 3:服务商 4:专家
        // shopId: '1',
        roleIds: ['3'],
        lockFlag: 0,
        nickname: '',
        phone: '',
        nickName: '',
      },
      select: {},
      rules: {
        // nickName: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        shopId: [{ required: true, message: '请选择店铺', trigger: 'change' }],
        username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '长度在11个字符', trigger: 'blur' },
        ],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
        avatar: [{ required: true, message: '请选择图片', trigger: 'blur' }],
        orgIds: [{ required: true, message: '请选择部门', trigger: 'blur' }],
        realName: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
      },
      dialogImageUrl: '',
      hideUpload: '',
      addForm: {
        headers: '',
      },
    }
  },

  computed: {
      ...mapGetters({
        token: 'user/token',
        tenantId : 'user/tenantId',
      }),
    },
    watch: {
    showadd(newValue, oldValue) {
      if (newValue != oldValue) {
        this.dialogVisibl = newValue
      }
    },
  },
  created() {
    const db = getToken()
    this.headers['Authorization'] = `Bearer ` + `${db}`
    this.headers['Switch-Tenant-Id'] = `${this.tenantId}`
    this.getList()
    this.fetchData()
  },
  methods: {
    //提交
    submit() {
      this.$refs.formDatal.validate((valid) => {
        if (valid) {
          this.formDatal.phone = this.formDatal.mobile
          this.formDatal.nickName = this.formDatal.realName
          this.formDatal.nickname = this.formDatal.realName
          var CellPoe = /^[a-zA-Z\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/
          if (!CellPoe.test(this.formDatal.realName)) {
            this.ME('姓名格式填写错误！')
            return false
          }
          this.shopuser()
        } else {
          return false
        }
      })
    },
    shopuser() {
      // username: this.addForm.username,
      //       password: this.addForm.password,
      //       realName: this.addForm.realName,
      //       email: this.addForm.email,
      //       prefix: this.addForm.prefix,
      //       mobile: this.addForm.mobile,
      //       phone: this.addForm.mobile,
      //       avatar: 'https://i.gtimg.cn/club/item/face/img/3/15643_100.gif',
      //       nickname: this.addForm.nickname,
      //       nickName: this.addForm.nickname,
      //       status: this.addForm.status,
      //       orgIds: this.menuChange(this.addForm.orgIds),
      //       tenantId: '1', //角色 租户ID
      //       postId: 0, //0->其它 1->专家
      //       delFlag: 0, //0-正常，1-删除
      //       organId: '29a4609f543eb7a1b0f8b38ce01cc125', //机构ID
      //       type: 2, //账号类型：-1、系统管理员；1、租户管理员；2、店铺管理员 3:服务商
      //       shopId: '1',
      //       roleIds: ['3'],
      //       lockFlag: 0,

      addShopUser({ ...this.formDatal }).then(() => {
        this.$emit('hideadd', false)
        this.$message.success('新增成功')
        this.formDatal = {}
      })
    },
    //查询部门
    async fetchData() {
      const parms = {
        current: this.current,
        size: this.size,
      }
      await orgTree(parms)
        .then((res) => {
          if (res.data) {
            this.menuListTable = res.data.records
            this.dataSourceLists = res.data.records
            this.dataSourceLists.map((obj) => {
              obj.label = obj.orgName
              obj.value = obj.id
              const fieldArr = obj.children
              const newFieldArr = fieldArr.map((element) => {
                element.label = element.orgName
                element.value = element.id
                element.children.forEach((item) => {
                  item.label = item.orgName
                  item.value = item.id
                  if (item.children != null) {
                    item.children.forEach((el) => {
                      el.label = el.orgName
                      el.value = el.id
                    })
                  }
                })
                return element
              })
              obj.fieldArr = newFieldArr
              return obj
            })
          }
        })
        .catch(() => {})
    },
    // 所属商城
    menuChange(dataSourceLists) {
      if (dataSourceLists && dataSourceLists.length) {
        const arr = dataSourceLists.map((item) => {
          return item[item.length - 1]
        })
        // const str = arr.join(',')
        return arr
      } else {
        return ''
      }
    },
    handleClose() {
      this.$emit('hideadd', false)
      this.formDatal = {}
      this.dialogVisibles = false
    },
    getList() {
      getList({}).then((res) => {
        this.select = res.data
      })
    },
    bfUpload(file) {
      console.log(file)
      if ('image/png' == file.type || 'image/jpeg' == file.type || 'image/jpg' == file.type) {
        this.addForm.headers = this.headers
      } else {
        this.ME('图片上传失败,请上传png,jpeg,jpg格式')
        return false
      }
    },
    handleChange(file, fileList) {
      this.hideUpload = fileList.length >= this.limitCount
    },
    handlePictureCardPreviewaction(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleRemove(fileList) {
      this.fileList = []
      this.hideUpload = fileList.length >= this.limitCount
    },
    handleSuccess(response) {
      this.formDatal.avatar = response.data.filePath
      this.fileList.push({
        url: response.data.filePath,
      })
    },

    clear() {
      this.dialogVisibles = false
      this.formDatal = {}
      this.$emit('hideadd', false)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  border-bottom: 1px solid #edeaea;
}
::v-deep .el-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
