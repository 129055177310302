<template>
  <div class="cont">
    <el-form ref="FormData" label-position="top" label-width="100px" :model="formdate" :rules="rules">
      <el-form-item label="商品:">
        <el-table border :data="date.checkout" height="390px" row-class-name="rowClassName">
          <el-table-column align="center" label="商品信息">
            <template #default="scope">
              <div class="shop_info" style="display: flex; align-items: center">
                <el-image :src="scope.row.picUrl" style="width: 60px; height: 60px; margin-right: 8px" />
                <div class="shop_msg" style="width: calc(100% - 60px)">
                  <div class="shop name speacvname" style="text-align: left">{{ scope.row.name }}</div>
                  <div class="shop name" style="display: flex; color: red; text-align: left">
                    ¥ {{ scope.row.salesPrice }} &nbsp;
                    <div class="shop name" style="font-size: 12px; color: gray; text-align: left">规格:{{ scope.row.specValueNames }}&nbsp;库存:{{ scope.row.stock }}</div>
                  </div>
                </div>
              </div>
              <div class="flx" style="display: flex; justify-content: right">
                <el-input-number v-model="scope.row.quantity" placeholder="" size="mini" @change="quanmun" />
                <div class="cel" style="width: 150px; margin-left: 10px">
                  <el-input v-model="allprice" placeholder="" size="mini" @change="sales">
                    <template slot="append">元</template>
                  </el-input>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="" width="80">
            <template slot-scope="scope">
              <el-button type="primary" @click="remove(scope.$index, scope.row)">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <div class="con">
        <div class="span">物流方式:</div>
        <div class="showcont">
          <el-form-item label="" style="margin-top: 10px">
            <el-radio-group v-model="formdate.deliveryWay">
              <el-radio label="1">快递配送</el-radio>
              <el-radio label="2">上门自提</el-radio>
              <!-- <el-radio label="3">同城配送</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <div class="d" style="display: grid; grid-template-columns: repeat(2, 1fr)">
            <el-form-item label="订单类型:" :required="true">
              <el-select v-model="formdate.busType" placeholder="请选择 订单类型" style="width: 200px" @focus="queryPages(0)">
                <el-option v-for="item in dbotions" :key="item.id" :label="item.labelName" :value="item.id" />
              </el-select>
            </el-form-item>

            <el-form-item label="复购次数:" :required="true">
              <el-select v-model="formdate.repNum" placeholder="请选择 复购次数" style="width: 200px" @focus="queryPages(1)">
                <el-option v-for="item in dotions" :key="item.id" :label="item.labelName" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="是否加急:" :required="true">
              <el-select v-model="formdate.urgState" placeholder="请选择 是否加急" style="width: 200px">
                <el-option label="加急" value="0" />
                <el-option label="不加急" value="1" />
              </el-select>
            </el-form-item>
            <el-form-item label="预存金额：" prop="prestoreMoney">
              <!-- <div class="logAmount" style="width: 200px;height: 32px;border: 1px solid  #e3e3e3; border-radius: 3px;">
                <span style="margin-left: 15px;">{{ money }}</span>
                <div class="check-logamount" @click="logAmountshow=true">选择预存</div>
              </div> -->
              <el-input v-model="formdate.prestoreMoney" placeholder="请输入 预存金额" style="width: 200px" />
            </el-form-item>
            <el-form-item :label="formdate.deliveryWay == 1 ? '手机号:' : '联系电话:'" prop="telNum">
              <el-input v-model="formdate.telNum" placeholder="请输入 手机号" style="width: 200px" />
            </el-form-item>
            <el-form-item :label="formdate.deliveryWay == 1 ? '收货人:' : '联系人:'" prop="userName">
              <el-input v-model="formdate.userName" placeholder="请输入 收货人" style="width: 200px" />
            </el-form-item>
          </div>
          <el-form-item label="地区:" prop="province">
            <el-cascader
              v-model.trim="formdate.provinces"
              class="full-width"
              :options="areaSelectData"
              placeholder="请选择地址"
              :props="{
                children: 'children',
                label: 'areaName',
                value: 'areaName',
              }"
              size="large"
              style="width: 80%"
              @change="addres"
            />
          </el-form-item>
          <el-form-item label="详细地址:" prop="address">
            <div class="ro" style="display: flex">
              <el-input v-model="formdate.address" placeholder="请输入 详细地址" style="width: 100%" type="textarea" />
              <!-- <div v-if="formdate.deliveryWay == 1" class="ro_btn" @click="hide = true">选择地址</div> -->
            </div>
          </el-form-item>
          <div class="ym_head_title" style="margin-top: 10px; margin-bottom: 30px">价格明细</div>
          <el-form ref="FormData" label-position="top" label-width="100px" :model="skus" style="display: flex; align-items: flex-start">
            <el-form-item label="运费:" style="font-weight: bold">
              <el-input v-model="freightPrice" placeholder="请输入 运费" style="width: 190px; margin-top: 5px" @input="freightPric">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="明细:" style="margin-left: 10px; font-weight: bold">
              <template #default>
                <div class="item" style="margin-top: 0; font-size: 12px; font-weight: 500">金额 ￥{{ paymentPrice }}</div>
                <div class="item" style="font-size: 12px; font-weight: 500">运费 ￥{{ freightPrice }}.00</div>
                <div class="item" style="font-size: 12px; font-weight: 500">到货 ￥{{ (skus.paymentPrice - formdate.prestoreMoney).toFixed(2) }}</div>
                <div class="item" style="font-size: 12px; font-weight: 500">
                  合计
                  <!-- <span style="color: #ff6633">￥{{ skus.paymentPrice }}.00</span> -->
                  <input v-model="skus.paymentPrice"  type="text" >
                </div>
              </template>
            </el-form-item>
          </el-form>
          <div class="solt" style="display: flex; justify-content: right">
            <el-button style="margin-right: 118px" type="primary" @click="settlement">结算</el-button>
          </div>
        </div>
      </div>
    </el-form>
    <logamountdialog :logamounthide="logAmountshow" :sound="listinfo" @closeloagmount="closehide" />
    <addressdialog :showhide="hide" :usercode="listinfo" :userid="date" @tranhdie="transhow" />
  </div>
</template>

<script>
import { getAreaThree } from '@/api/mall/common'
import { addCustomerOrder } from '@/api/mall/confirm/confirm'
import { statistics } from '@/api/mall/setting/stplabel'
import addressdialog from './components/addressdialog'
import { queryList } from '@/api/mall/health/casemy/index'
import logamountdialog from './components/logamountdialog.vue'
export default {
  components: { addressdialog, logamountdialog },
  props: {
    checkus: {
      type: Object,
      default: () => {},
    },
    userinfo: {
      type: Object,
      default: () => {},
    },
    listinfo: {
      type: Object,
      default: () => {},
    },
    datalists:{
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      areaSelectData: [],
      allprice:null,
      province: null,
      paymentPrice: 0,
      freightPrice: 0,
      hide: false,
      arrList:this.datalists,
      logAmount: null, //预存余额
      form: {
        size: 10,
        current: 1,
        recordId: null,
      },
      skus: {
        paymentPrice: 0,
      },
      formdate: {
        deliveryWay: '1', //配送方式1、普通快递；2、上门自提
        // paymentType: '1', //支付方式1、货到付款；2、在线支付
        userMessage: '',
        appType: 'H5-PC',
        appId: '',
        userAddressId: null,
        prestoreId: 0,
        recordId: '', //列表ID
        orderType: '10', //订单类型（0、普通订单；1、砍价订单；2、拼团订单；3、秒杀订单 ,10 代客下单）
        status: '1', //订单状态1、待发货 2、待收货 3、确认收货/已完成 5、已关闭 10、拼团中
        urgState: '1',
        telNum: '',
        userName: '',
        address: '',
        prestoreMoney: 0, //预存余额
        provinces: null,
        province:'',
        city:'',
        county:'',
        busType: '',
        repNum:'',
      },
      date: null,
      userligs: [],
      rules: {
        busType:[{ required: true, message: '请填写 订单列表', trigger: 'blur' }],
        repNum:[{ required: true, message: '请填写 复购次数', trigger: 'blur' }],
        userName: [{ required: true, message: '请输入 收货人', trigger: 'blur' }],
        telNum: [{ required: true, message: '请输入 手机号', trigger: 'blur' },
        {
    validator: (rule, value, callback) => {
      if (!/^1[3-9]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号码'));
      } else {
        callback();
      }
    },
    trigger: 'blur'
  }
      ],
        province: [{ required: true, message: '请选择 地区', trigger: 'blur' }],
        provinces: [
          {
            required: true,
            type: 'array', // 添加type设置为 array
            message: '请选择 地区',
            trigger: 'blur', // 将触发条件改为 blur
          },
        ],
        address: [{ required: true, message: '请输入 详细地址', trigger: 'blur' }],
        prestoreMoney: [
    { 
      validator: (rule, value, callback) => {
        if (value > this.logAmount) {
          callback(new Error('预存金额不能大于预存余额'));
        } else {
          callback();
        }
      },
      trigger: 'blur'  
    }
  ]
      },
      listinit: null, //列表id
      dbotions: null, //订单列表
      dotions: null, //复购次数
      logAmountshow: false, //预存余额选项
      money: 0,
    }
  },
  watch: {
    checkus(newValue, oldValue) {
      if (newValue != oldValue) {
        this.date = newValue
        this.payment()
        this.paynumber()
      }
    },
    userinfo(newValue, oldValue) {
      if (newValue != oldValue) {
        this.userligs = newValue
      }
    },
    listinfo(newValue, oldValue) {
      if (newValue != oldValue) {
        this.listinit = newValue
      }
    },
  },
  created() {
    this.getAreaThree()
    this.queryPages()
    this.statistics(this.arrList.id)
    console.log(this.listinfo, '-41641-1464', this.userligs)
    this.date.checkout = this.checkus
    this.userligs = this.userinfo
    this.listinit = this.listinfo
    this.formdate.telNum = this.listinit.phone
    this.formdate.userName = this.listinit.userName
  },
  mounted() {
    this.formdate.telNum = this.arrList.phone
    this.formdate.userName = this.arrList.userName
    this.provinces = [this.arrList.province, this.arrList.city, this.arrList.county]
    this.formdate.address = this.arrList.addressDetail
    this.form.recordId = this.arrList.id
    this.formdate.province = this.dataList.province
    this.formdate.city = this.dataList.city
    this.formdate.county = this.dataList.county
  
  
  },
  methods: {
    //关闭预存弹窗
    closehide(bole) {
      if (bole != false) {
        this.$set(this.formdate, 'prestoreId', bole[0].id)
        this.money = bole[0].logAmount
      }
      this.logAmountshow = false
    },
    //余额请求
    statistics(e) {
  
      statistics({ recordId: e }).then((res) => {
        this.logAmount = res.data.logAmount
      })
    },
    queryPages(e) {
      if (e == 0) {
        queryList({ labelType: 3 }).then((res) => {
          this.dbotions = res.data
        })
      } else {
        queryList({ labelType: 8 }).then((res) => {
          this.dotions = res.data
        })
      }
    },
    //移除已选商品
    remove(index) {
      this.date.checkout.splice(index, 1)
      this.payment()
      this.paynumber()
    },
    async getAreaThree() {
      await getAreaThree()
        .then((res) => {
          if (res.data) {
            this.areaSelectData = res.data
          }
        })
        .catch(() => {})
    },
    settlement() {
      this.date.userinfo = this.userinfo
      this.formdate.skus = this.date.checkout
      this.formdate.userId = this.date.userinfo.id
      this.formdate.memberId = this.date.userinfo.userCode
      this.formdate.recordId = this.arrList.id
      this.formdate.userAddressId = null
      this.$set(this.formdate, 'paymentPrice', this.skus.paymentPrice)
      this.$set(this.formdate, 'freightPrice', this.freightPrice)
      let list = []
      this.formdate.skus.forEach((item) => {
        console.log(item.paymentPrice, '-67784-564-5324', item.paymentPrice)
        let s = {
          spuId: item.spuId,
          skuId: item.id,
          shopInfo: {
            skuid: item.id,
            tenantId: item.tenantId,
            delFlag: item.delFlag,
            createTime: item.createTime,
            updateTime: item.updateTime,
            enable: item.enable,
            phone: item.phone,
            name: item.name,
            imgUrl: item.imgUrl,
            city: item.city,
            country: item.country,
            province: item.province,
            address: item.address,
            prestoreMoney: item.prestoreMoney,
            longitude: item.longitude,
            latitude: item.latitude,
            detail: item.detail,
            wxMchId: item.wxMchId,
            aliAuthToken: item.aliAuthToken,
            collectId: item.collectId,
            collectCount: item.collectCount,
            listGoodsSpu: item.listGoodsSpu,
          },
          quantity: item.quantity,
          salesPrice: item.salesPrice,
          spuName: item.spuName,
          memberId: item.userCode, //新增字段
          specInfo: item.specInfo,
          picUrl: item.picUrl,
          weight: item.weight,
          volume: item.volume,
          freightPrice: 0, //运费金额
          paymentPrice: item.paymentPrice,
          paymentPointsPrice2: item.paymentPointsPrice2,
          paymentPrice2: item.paymentPrice2,
          paymentPoints2: item.paymentPoints2,
        }
        list.push(s)
      })
      this.formdate.skus = list
      this.$refs.FormData.validate((valid) => {
        //表单校验
        if (valid) {
          addCustomerOrder({ ...this.formdate }).then((res) => {
            if (res.code == 0) {
              this.$message.success('结算成功')
              this.$baseEventBus.$emit('checktage', 7)
            } else {
              this.$message.error("请选择商品！")
            }
          })
        } else {
          return false
        }
      })
    },
    addres(e) {
      this.formdate.province = e[0]
      this.formdate.city = e[1]
      this.formdate.county = e[2]
    },
    quanmun() {
      this.payment()
      this.paynumber()
    },
    payment() {
      this.date.checkout.forEach((item, index) => {
        let num = item.quantity * item.salesPrice
        this.$set(this.date.checkout[index], 'paymentPrice', num)
      })
    },
    paynumber() {
      this.paymentPrice = this.date.checkout.reduce((total, item) => total + item.paymentPrice, 0)
      this.allprice =  this.paymentPrice
      this.skus.paymentPrice = Number(this.paymentPrice) + Number(this.freightPrice)
    },
    sales(e) {
      if (e == '') {
        this.payment()
      }
    },
    transhow(row) {
      this.hide = false
      this.provinces = []
      const dete = row
      if (row) {
        console.log(dete, '54')
        this.provinces.push(dete.provinceName)
        this.provinces.push(dete.cityName)
        this.provinces.push(dete.countyName)
        this.$set(this.formdate, 'userName', dete.userName)
        this.$set(this.formdate, 'telNum', dete.telNum)
        this.$set(this.formdate, 'address', dete.detailInfo)
        this.$set(this.formdate, 'province', dete.provinceName)
        this.$set(this.formdate, 'county', dete.countyName)
        this.$set(this.formdate, 'city', dete.cityName)
      }
    },
    freightPric() {
      this.payment()
      this.paynumber()
    },
  },
}
</script>

<style lang="scss" scoped>
.cont {
  width: 95%;
  .ym_head_title {
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    color: #333;
    border-bottom: 1px solid #e8e8e8;
  }
  .user_info {
    display: flex;
    align-items: center;
    width: 200px;
    height: 95px;
    margin: 10px 0;
    margin-top: 20px;
    font-size: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    .user_picurl {
      margin: 0 10px;
    }
  }
  .ro {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
  }
}
::v-deep .ro_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  padding: 0 30px;
  background-color: rgb(245, 247, 250);
  border: 1px solid #e5e5e5;
  transition: ease 800ms;
  &:hover {
    color: white;
    cursor: pointer;
    background: #3782ff;
    border: transparent;
  }
}
::v-deep {
  .el-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-columns: 400px auto;
    gap: 16px;
  }
  .el-input {
    border: 1px solid #e5e5e5;
  }
  .el-input-group__append {
    border: none;
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 10px;
  }
}
.speacvname {
  width: 240px;
  overflow: hidden;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.span {
  margin: 8px 0;
}
.showcont {
  display: grid;
  max-height: 390px;
  padding-bottom: 10px;
  padding-left: 15px;
  margin-top: 5px;
  overflow-y: scroll;
  border: 1px solid #e5e5e5;
}
.logAmount {
  display: flex;
  justify-content: space-between;
  .check-logamount {
    height: 100%;
    padding: 0 10px;
    font-size: 12px;
    cursor: pointer;
    background: #f5f7fa;
  }
}
</style>
