<template>
  <div>
    <el-form label-position="top" :model="form">
      <el-form-item label="处理结果">
        <el-input v-model.trim="form.resultRemark" placeholder="请输入 处理结果"/>
      </el-form-item>
      <el-form-item label="客户建议">
        <el-input v-model.trim="form.suggRemark" placeholder="请输入 客户建议"/>
      </el-form-item>
      <el-form-item label="情况备注">
        <el-input v-model.trim="form.remark" placeholder="请输入 情况备注"/>
      </el-form-item>
      <el-form-item label="附件上传">
        <uploadpic  @backkuck="check($event)"/>
      </el-form-item>
      <el-form-item label="">
        <!-- <el-button style="margin-right: 15px;" type="primary" @click="save(1)">暂存</el-button> -->
        <el-button type="primary" @click="save(2)">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {uuUserSugarRecordAfterLogadd} from '@/api/mall/aftersaledata/index'
import uploadpic from '../../../upload-pic/upload.vue'
export default {
  components:{
    uploadpic
  },
  data(){
    return{
      form:{},
      fileList: [],
      listID:null,
      comid:null
    }
  },
  created(){
    if(this.$route.query.date){
      this.listID=JSON.parse(this.$route.query.date)
    }
  },
  methods:{
    check(event){
      console.log(event,'789');
      this.comid=event.comId
    },
    save(tp){
      this.form.type=tp
      this.form.afterId=this.listID.id
      this.form.afterState=this.listID.afterState
      if(this.comid!=null){
        this.form.comId=this.comid
      }else{
        this.form.comId=0
      }
      if (!this.form.resultRemark || !this.form.suggRemark || !this.form.remark) {
    this.$message.error('请填写完整处理结果、客户建议和情况备注后再保存！');
    return;
  }
      uuUserSugarRecordAfterLogadd({...this.form}).then(res=>{
        if(res.status==200){
         if(tp==1){
          this.$message.success("暂存成功")
         }else{
          this.$message.success("存储成功")
          this.$emit('saveSuccess');
         }
          this.form={}
         
        }
        else{
          this.$message.error(res.msg)
        }
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, ) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-input{
    width: 200px;
  }
}
</style>