<template>
  <div>
    <el-dialog append-to-body :before-close="claer" title="回访设置" :visible.sync="appointDialog" width="50%">
      <el-form ref="addForm" label-width="120px" :model="addForm" :rules="rules">
        <el-form-item label="提醒时间" prop="vistTime">
          <el-date-picker v-model="addForm.vistTime" align="right" format="yyyy-MM-dd HH:mm:ss"  :picker-options="pickerOptions" placeholder="选择日期时间" type="datetime"  value-format="yyyy-MM-dd HH:mm:ss" />
        </el-form-item>
        <el-form-item label="回访内容" prop="content">
          <el-input v-model="addForm.content" autocomplete="off" placeholder="请输入回访内容" type="textarea" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { uuUserSugarVistqueryVistPage } from '@/api/mall/health/call/repurchase'
// import { afterSale } from '@/api/mall/health/seas/seasManger'
import { queryDateisOrgorgTree } from '@/api/mall/role/orgManage'
// import dayjs from 'dayjs'
import { queryPage } from '@/api/mall/setting/stplabel'
export default {
  props: {
    showtab: {
      type: Boolean,
      default: () => {},
    },
    hanuser: {
      type: Object,
      default: () => {},
    },
    usmglist: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      appointDialog: false,
      dataSourceLists: [],
      addForm: {},
      uslist: null,
      usmsginfo: this.usmglist,
      props: {
        checkStrictly: true,
        multiple: true,
        label: 'orgName',
        value: 'id',
      },
      pickerOptions: {
      disabledDate(time) {
        return time.getTime() < Date.now() - 8.64e7; // 禁用以前的日期
      }
    },
      optionslist: [
        {
          labelName: '退货',
          id: '1',
        },
        {
          labelName: '维修',
          id: '2',
        },
        {
          labelName: '换新',
          id: '3',
        },
        {
          labelName: '补发',
          id: '4',
        },
      ],
      rules: {
        content:[{ required: true, message: '请输入 回访内容', trigger: 'change' }],
        signTime: [{ required: true, message: '请选择 提醒时间', trigger: 'change' }],
        vistTime: [{ required: true, message: '请选择 客户客情时间', trigger: 'change' }],
        feedTime: [{ required: true, message: '请选择 客户反馈时间', trigger: 'change' }],
        issueMark: [{ required: true, message: '请输入 产品问题描述', trigger: 'blur' }],
      },
    }
  },
  watch: {
    showtab(newValue, oldValue) {
      if (newValue != oldValue) {
        this.appointDialog = newValue
      }
    },
    hanuser(newValue, oldValue) {
      if (newValue != oldValue) {
        this.uslist = newValue
      }
    },
    usmglist(newValue, oldValue) {
      if (newValue != oldValue) {
        this.usmsginfo = newValue
      }
    },
  },
  created() {
    this.fetchData()
    // this.queryPage()
  },
  methods: {
    queryPage() {
      queryPage({ labelType: 9 }).then((res) => {
        console.log(res.data)
        this.optionslist = res.data.records
      })
    },
    getcasc(e) {
      console.log(e)
      console.log(this.dataSourceLists)
    },
    async fetchData() {
      this.listLoading = true
      const parms = {
        current: this.current,
        size: this.size,
      }
      await queryDateisOrgorgTree(parms)
        .then((res) => {
          if (res.data) {
            this.menuListTable = res.data.records
            this.dataSourceLists = res.data.records
            this.dataSourceLists.map((obj) => {
              obj.label = obj.orgName
              obj.value = obj.id
              const fieldArr = obj.children
              const newFieldArr = fieldArr.map((element) => {
                element.label = element.orgName
                element.value = element.id
                element.children.forEach((item) => {
                  item.label = item.orgName
                  item.value = item.id
                  if (item.children != null) {
                    item.children.forEach((el) => {
                      el.label = el.orgName
                      el.value = el.id
                    })
                  }
                })
                return element
              })
              obj.fieldArr = newFieldArr
              return obj
            })
          }
        })
        .catch(() => {})
      this.listLoading = false
    },
    clear() {
      this.addForm = {}
      this.$emit('closedemo', false)
    },
    teaChange(e) {
      if (!e.length) {
        this.appointData.forEach((item) => {
          item.disabled = false
        })
      }
    },
    claer() {
      this.addForm = {}
      this.$emit('closedemo', false)
    },
    leftChange(e) {
      this.appointData.forEach((item) => {
        if (e[0] == item.id) item.disabled = false
        else item.disabled = true
      })
    },
    sure() {

      this.$refs['addForm'].validate((valid) => {
    if (valid) {
      this.addForm.recordId = this.uslist.id
      uuUserSugarVistqueryVistPage({ ...this.addForm }).then((res) => {
        if (res.code == 0 || res.status == 200) {
          this.$message.success('添加成功')
          this.addForm = {}
          this.$EventBus.$emit('repload', true)
          this.$emit('closedemo', false)
        }
      })
    } 
  });
     
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-select,
  .el-date-editor {
    width: 100%;
  }
}
</style>
