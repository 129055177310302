<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="dialogFormVisible"
    width="60%"
    @close="close"
  >
    <el-form
      ref="form"
      :disabled="allDisable"
      :label-width="config.labelWidth"
      :model="form"
      :rules="rules"
    >
      <el-row :gutter="config.gutter || 10">
        <el-col v-for="item in column" :key="item.prop" :span="item.span || 12">
          <el-form-item
            :label="item.label"
            :label-width="
              item.labelWidth
                ? item.labelWidth
                : item.label
                ? config.labelWidth
                : '0px'
            "
            :prop="item.prop"
          >
            <slot v-if="item.slot && item.prop" :name="item.prop"></slot>
            <!-- 输入框 -->
            <el-input
              v-else-if="!item.type || item.type == 'input'"
              v-model.trim="form[item.prop]"
              :placeholder="item.label"
            />
            <!-- 单选 -->
            <template v-else-if="item.type == 'radio'">
              <el-radio
                v-for="i in item.optionData"
                :key="i.value"
                v-model="form[item.prop]"
                :label="i.value"
              >
                {{ i.label }}
              </el-radio>
            </template>
            <!-- 文本域 -->
            <el-input
              v-else-if="item.type == 'textarea'"
              v-model="form[item.prop]"
              :placeholder="item.label"
              :rows="item.rows || 1"
              type="textarea"
            />
            <!-- 上传 -->
            <el-upload
              v-else-if="item.type == 'upload'"
              action="upms/file/upload?fileType=image&dir=material/"
              :before-upload="beforeUpload"
              :file-list="fileList"
              :headers="headers"
              list-type="picture-card"
              :on-error="handleError"
              :on-success="handleSuccess"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <!-- 下拉 -->
            <template v-else-if="item.type == 'select'">
              <el-select
                v-model="form[item.prop]"
                class="w100s"
                clearable
                :placeholder="`请选择 ${item.label}`"
              >
                <el-option
                  v-for="i in item.optionData"
                  :key="item.key ? i[item.key] : i.label"
                  :label="item.key ? i[item.key] : i.label"
                  :value="item.value ? i[item.value] : i.value"
                />
              </el-select>
            </template>
            <template v-else-if="item.type == 'number'">
              <el-input-number
                v-model="form[item.prop]"
                class="w100s"
                controls-position="right"
                :max="9999"
                :min="1"
                :placeholder="item.label"
              />
            </template>
            <template v-else-if="item.type == 'checkbox'">
              <el-checkbox-group v-model="form[item.prop]">
                <el-checkbox
                  v-for="i in item.optionData"
                  :key="i.value"
                  :label="i.label"
                />
              </el-checkbox-group>
            </template>
            <!-- 富文本编辑器 -->
            <template v-else-if="item.type == 'BaseEditor'">
              <BaseEditor v-model="form[item.prop]" />
            </template>
            <!-- 操作按钮插槽 -->
            <template v-else-if="item.type == 'operation'">
              <slot name="operation"></slot>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer v-if="!allDisable">
      <el-button @click="close">取 消</el-button>
      <el-button v-if="title != '编辑'" type="primary" @click="save">
        确 定
      </el-button>
      <el-button v-else type="primary" @click="update">保 存</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import request from '@/utils/request'
  import store from '@/store'
  import BaseEditor from '@/components/editor/BaseEditor.vue'
  import { deepClone } from '@/utils/util'

  export default {
    name: 'TableEdit',
    components: { BaseEditor },
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      options: {
        type: Array,
        default() {
          return []
        },
      },
      config: {
        type: Object,
        default() {
          return { labelWidth: '80px' }
        },
      },
      rules: {
        type: Object,
        default() {
          return {}
        },
      },
      value: {
        type: Object,
        default() {
          return {}
        },
        require: true,
      },
    },
    data() {
      return {
        allDisable: false,
        fileList: [],
        title: '',
        column: this.options,
        dialogFormVisible: false,
        for: {},
        headers: {
          Authorization: 'Bearer ' + store.getters['user/token'],
          'switch-tenant-id': 1,
        },
      }
    },
    computed: {
      form: {
        get() {
          return this.value
        },
        set(e) {
          console.log('修改', e)
          this.$emit('update:value', deepClone(e))
        },
      },
    },
    created() {
      console.log()
      this.column.forEach((item) => {
        if (item.url) {
          request({
            url: item.url,
            method: 'GET',
          }).then((res) => {
            const { data } = res
            item.optionData = data
            // console.log('user', res)
          })
        }
      })
    },
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.allDisable = false
        } else {
          if (row == 'show') {
            this.title = '查看'
            this.allDisable = true
          } else if (row == 'edit') {
            this.title = '编辑'
            this.allDisable = false
          }
          const formdata = this.form
          for (const [key, value] of Object.entries(formdata)) {
            try {
              if (value && value.includes('http')) {
                console.log(key, value)
                this.fileList.push({ url: value })
              }
            } catch (e) {
              if (typeof value != 'string' || !Array.isArray(value)) {
                console.warn('类型错误')
              }
            }
          }
        }
        this.dialogFormVisible = true
      },
      setfile(params) {
        this.fileList = params
      },
      close() {
        this.fileList = []
        this.$refs['form'].resetFields()
        this.dialogFormVisible = false
      },
      reset() {
        this.$refs['form'].resetFields()
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.$emit('submit')
          }
        })
      },
      update() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.$emit('update')
          }
        })
      },
      beforeUpload(file) {
        const isPic =
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/gif' ||
          file.type === 'image/jpg'
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isPic) {
          this.$message.error('上传图片只能是 JPG、JPEG、PNG、GIF 格式!')
          return false
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!')
        }
        return isPic && isLt2M
      },
      handleError(err, file, fileList) {
        // this.$emit("fileStatus",'sucess',)
        console.log(file, fileList)
        this.$message.error(err + '')
      },
      // file, fileList
      handleSuccess(res) {
        // console.log(file, fileList)
        this.$emit('fileStatus', 'success', res)
      },
    },
  }
</script>
<style scoped lang="scss">
  :deep() {
    .el-input-number .el-input__inner {
      text-align: left;
    }
  }
</style>
