<template>
  <el-dialog :before-close="handleClose" title="编辑手术历史" :visible.sync="dialogVisible" width="50%">
    <span>
      <el-form ref="ruleForm" class="demo-ruleForm" label-width="100px" :model="ruleForm" :rules="rules">
        <el-form-item label="手术名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入 疾病名称" />
        </el-form-item>
        <el-form-item label="手术时间" prop="phyTime">
          <el-date-picker v-model="ruleForm.phyTime" align="center" placeholder="选择日期时间" type="date" value-format="yyyy-MM-dd" />
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { userFitnessInfoedit } from '@/api/mall/users/usermore'
export default {
  props: {
    show: {
      type: Boolean,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        fitNum: [{ required: true, message: '请输入 体检编号', trigger: 'blur' }],
        phyTime: [{ type: 'string', required: true, message: '请选择 手术时间', trigger: 'change' }],
        fitType: [{ required: true, message: '请选择 亲属关系', trigger: 'change' }],
        name: [{ required: true, message: '请输入 手术名称', trigger: 'blur' }],
      },
      dialogVisible: false,
      uphide: false,
    }
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue != oldValue) {
        this.dialogVisible = newValue
        this.uphide = newValue
        this.$forceUpdate()
      }
    },
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.ruleForm = newValue
      }
    },
  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          userFitnessInfoedit({ userId: this.value.userId, fitStatus: 5, ...this.ruleForm }).then((res) => {
            if (res.status == 200) {
              this.ruleForm = {
                fitUrl: [],
              }
              this.$emit('addshows', false)
            }
          })
        } else {
          return false
        }
      })
    },
    handleClose() {
      this.$emit('addshows', false)
      this.$refs.ruleForm.resetFields()
    },
    check(e) {
      if (e.url) {
        this.ruleForm.fitUrl.forEach((item, index) => {
          if (item == e.url) {
            this.ruleForm.fitUrl.splice(index, 1)
          }
        })
      } else {
        this.ruleForm.fitUrl.push(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
  .el-select,
  .el-date-editor,
  .input__inner {
    width: 100%;
  }
}
</style>