var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "购买信息",
            visible: _vm.dialogVisible,
            width: "75%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c("div", { staticClass: "theader_title" }, [
                _c("div", { staticClass: "txt" }, [_vm._v("订单信息")]),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-bottom": "15px" },
                  attrs: { border: "", data: _vm.rowlist, "max-height": "200" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "序号",
                      type: "index",
                      width: "55",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "产品名称",
                      prop: "spuName",
                      "show-overflow-tooltip": "",
                      width: "320",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "规格信息",
                      prop: "specInfo",
                      "show-overflow-tooltip": "",
                      width: "220",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "数量",
                      prop: "quantity",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "产品单价",
                      prop: "salesPrice",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "支付金额",
                      prop: "paymentPrice",
                      "show-overflow-tooltip": "",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商品说明",
                      prop: "remark",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "theader_title fix" }, [
                _c("div", { staticClass: "txt" }, [_vm._v("客户信息")]),
              ]),
              _c(
                "el-form",
                {
                  attrs: {
                    inline: true,
                    "label-width": "100px",
                    model: _vm.savform,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "客户姓名：",
                        rules: [
                          {
                            required: true,
                            message: "请输入客户姓名",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "请输入客户姓名" },
                        model: {
                          value: _vm.savform.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "userName", $$v)
                          },
                          expression: "savform.userName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户性别：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: "",
                            placeholder: "请选择客户性别",
                          },
                          model: {
                            value: _vm.savform.userSex,
                            callback: function ($$v) {
                              _vm.$set(_vm.savform, "userSex", $$v)
                            },
                            expression: "savform.userSex",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户职业：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "请输入客户职业" },
                        model: {
                          value: _vm.savform.clientOccupa,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "clientOccupa", $$v)
                          },
                          expression: "savform.clientOccupa",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户类型：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: "",
                            placeholder: "请选择客户类型",
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.queryList(5)
                            },
                          },
                          model: {
                            value: _vm.savform.clientId,
                            callback: function ($$v) {
                              _vm.$set(_vm.savform, "clientId", $$v)
                            },
                            expression: "savform.clientId",
                          },
                        },
                        _vm._l(_vm.optionsclientId, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.labelName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "区域选择：" } },
                    [
                      _c("el-cascader", {
                        staticClass: "full-width",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center",
                          width: "215px",
                          height: "32px",
                        },
                        attrs: {
                          disabled: "",
                          options: _vm.areaSelectData,
                          placeholder: "请选择地址",
                          props: {
                            children: "children",
                            label: "areaName",
                            value: "areaName",
                          },
                          size: "large",
                        },
                        on: { change: _vm.addres },
                        model: {
                          value: _vm.provinces,
                          callback: function ($$v) {
                            _vm.provinces =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "provinces",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "详细地址：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "请输入详细地址" },
                        model: {
                          value: _vm.savform.addressDetail,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "addressDetail", $$v)
                          },
                          expression: "savform.addressDetail",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "客户电话：",
                        rules: [
                          {
                            required: true,
                            message: "请输入客户电话",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "请输入详细电话" },
                        model: {
                          value: _vm.savform.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "phone", $$v)
                          },
                          expression: "savform.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户电话2：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "请输入详细电话" },
                        model: {
                          value: _vm.savform.phonev,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "phonev", $$v)
                          },
                          expression: "savform.phonev",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户备注1：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "请输入客户备注" },
                        model: {
                          value: _vm.savform.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "remark", $$v)
                          },
                          expression: "savform.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户备注2：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "请输入客户备注" },
                        model: {
                          value: _vm.savform.remarkContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "remarkContent", $$v)
                          },
                          expression: "savform.remarkContent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  attrs: {
                    inline: true,
                    "label-width": "100px",
                    model: _vm.savform,
                  },
                },
                [
                  _c("div", { staticClass: "theader_title" }, [
                    _c("div", { staticClass: "txt" }, [_vm._v("收货信息")]),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件人：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { disabled: "", placeholder: "请输入收件人" },
                        model: {
                          value: _vm.savform.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "userName", $$v)
                          },
                          expression: "savform.userName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "初联日期：" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          disabled: "",
                          placeholder: "请选择选择日期",
                          type: "date",
                        },
                        model: {
                          value: _vm.savform.initialTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "initialTime", $$v)
                          },
                          expression: "savform.initialTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { disabled: "", placeholder: "请输入联系电话" },
                        model: {
                          value: _vm.savform.telNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "telNum", $$v)
                          },
                          expression: "savform.telNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "核单说明：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { disabled: "", placeholder: "请输入核单说明" },
                        model: {
                          value: _vm.savform.approval,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "approval", $$v)
                          },
                          expression: "savform.approval",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件地区：" } },
                    [
                      _c("el-cascader", {
                        staticClass: "full-width",
                        attrs: {
                          disabled: "",
                          options: _vm.areaSelectData,
                          placeholder: "请选择地址",
                          props: {
                            children: "children",
                            label: "areaName",
                            value: "areaName",
                          },
                          size: "large",
                        },
                        on: { change: _vm.addres },
                        model: {
                          value: _vm.provinces,
                          callback: function ($$v) {
                            _vm.provinces =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "provinces",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件地址：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { disabled: "", placeholder: "请输入收件地址" },
                        model: {
                          value: _vm.savform.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "address", $$v)
                          },
                          expression: "savform.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件邮编：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { disabled: "", placeholder: "请输入收件邮编" },
                        model: {
                          value: _vm.savform.postalCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "postalCode", $$v)
                          },
                          expression: "savform.postalCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "送货说明：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { disabled: "", placeholder: "请输入送货说明" },
                        model: {
                          value: _vm.savform.delivery,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "delivery", $$v)
                          },
                          expression: "savform.delivery",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "复购次数：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            disabled: "",
                            placeholder: "请选择复购次数",
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.queryList(8)
                            },
                          },
                          model: {
                            value: _vm.savform.repNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.savform, "repNum", $$v)
                            },
                            expression: "savform.repNum",
                          },
                        },
                        _vm._l(_vm.Repurchase, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.labelName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单类型：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            disabled: "",
                            placeholder: "请选择订单类型",
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.queryList(3)
                            },
                          },
                          model: {
                            value: _vm.savform.orderType,
                            callback: function ($$v) {
                              _vm.$set(_vm.savform, "orderType", $$v)
                            },
                            expression: "savform.orderType",
                          },
                        },
                        _vm._l(_vm.Ordertype, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.labelName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注信息：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { disabled: "", placeholder: "请输入备注信息" },
                        model: {
                          value: _vm.savform.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "remark", $$v)
                          },
                          expression: "savform.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "订单记录", name: "1" },
                  }),
                ],
                1
              ),
              _vm.activeName == "1"
                ? _c(
                    "el-table",
                    {
                      attrs: {
                        border: "",
                        data: _vm.tablist,
                        "max-height": "300",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "序号",
                          type: "index",
                          width: "55",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "操作员",
                          prop: "userName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "时间",
                          prop: "operationTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "内容",
                          prop: "operationContent",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeName == "1"
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": 1,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "current-change": _vm.handleCurrentChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }