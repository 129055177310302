<template>
  <div class="comprehensive-table-container"></div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style></style>
