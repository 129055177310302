<template>
  <div class="container">
    <el-table border :data="tablist" max-height="300">
      <el-table-column align="center" label="序号" type="index" width="55" />
      <el-table-column align="center" label="订单编号" prop="orderNo" width="190" />

      <el-table-column align="center" label="下单用户" prop="nickName" width="140" />
      <el-table-column align="center" label="订单金额" prop="paymentPrice" width="100" />
      <el-table-column align="center" label="下单员工" prop="profile" show-overflow-tooltip width="140">
        <template #default="scope">{{ scope.row.realName }}{{ scope.row.profile == '' ? '' : '(' + scope.row.profile +
          ')' }}</template>
      </el-table-column>
      <!-- <el-table-column align="center" label="状态" prop="statusDesc" show-overflow-tooltip width="80" /> -->

      <el-table-column align="center" label="所购产品" prop="product" show-overflow-tooltip />
      <el-table-column align="center" label="下单地址" prop="address" show-overflow-tooltip />
      <!-- 状态 1-待质检 2-已通过 3-驳回 -->
      <!-- <el-table-column align="center" label="质检审核" prop="quaStateDesc" width="80">
        <template #default="{ row }">
          <div>
          <el-tag v-if="row.quaState == 1" type="succes">待质检</el-tag>
          <el-tag v-if="row.quaState == 2" type="danger">已通过</el-tag>
          <el-tag v-if="row.quaState == 3" type="info">已驳回</el-tag>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="订单状态" width="80">
        <template #default="{ row }">
          <!-- <span v-if="row.statusDesc"> {{ row.statusDesc }}/</span>
    <span v-if="row.logisticsStatus == 0"> 在途</span>
    <span v-if="row.logisticsStatus == 1"> 揽收/</span>
    <span v-if="row.logisticsStatus == 2"> 疑难/</span>
    <span v-if="row.logisticsStatus == 3"> 签收/</span>
    <span v-if="row.logisticsStatus == 4"> 退签/</span>
    <span v-if="row.logisticsStatus == 5"> 派件/</span>
    <span v-if="row.logisticsStatus == 6"> 退回/</span>
    <span v-if="row.logisticsStatus == 7"> 转投/</span>
    <span v-if="row.logisticsStatus == 8"> 清关/</span>
    <span v-if="row.logisticsStatus == 14"> 拒签/</span>
    <span v-if="row.logisticsStatus == null"> - /</span>
    <span v-if="row.quaState == 1"> 待质检</span>
    <span v-if="row.quaState == 2"> 已通过</span>
    <span v-if="row.quaState == 3"> 已驳回</span>
    <span v-if="row.quaState == null"> - </span> -->
          <span v-if="row.fuseState == 0">待质检</span>
          <span v-if="row.fuseState == 1">待发货</span>
          <span v-if="row.fuseState == 2">质检驳回</span>
          <span v-if="row.fuseState == 3">待收货</span>
          <span v-if="row.fuseState == 4">已完成</span>
          <span v-if="row.fuseState == 5">待退款</span>
          <span v-if="row.fuseState == 6">已退款</span>
          <span v-if="row.fuseState == 7">退款驳回</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="质检信息" prop="quaContent" show-overflow-tooltip width="80" />
      <el-table-column align="center" label="预存金额" prop="presAmount" width="80" />
      <el-table-column align="center" label="订单日期" prop="createTime" width="200" />
      <el-table-column align="center" label="操作" prop="createTime" width="200">
        <template slot-scope="scope">
          <el-button size="small" type="text" @click.native.prevent="selectUpload(scope.row)">上传</el-button>
          <el-button size="small" type="text" @click="selectDownload(scope.row)">预览</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="1" layout="total, prev, pager, next, jumper" :total="total"
      @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <Upload :comid="comId" :limitnum="99" :visible="uploadVisible" @closeUpload="closeUpload" @fillData="fillData()" />
    <el-dialog append-to-body title="请选择您要预览的文件" :visible.sync="downLoadVisible" width="30%">
      <div v-for="(item, index) in stpFileDosData" :key="index">
        <a download="" :href="item.fileUrl" style="display:block;margin:10px 0;">{{ item.fileName }}{{
          item.fileExtension }}</a>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '@/components/upload.vue'
import { orderrecord } from '@/api/mall/health/casemy/index'
import { baUrl } from '@/config'
export default {
  components: { Upload },
  props: {
    sound: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      total: 0,
      tablist: [],
      showlist: false,
      showtblist: null,
      form: {
        size: 10,
        current: 1,
        recordId: this.sound.id,
      },
      uploadVisible: false,
      comId: 0,
      report_book_url: '',
      downLoadVisible: false,
      stpFileDosData: []
    }
  },
  watch: {
    sound(newValue, oldValue) {
      if (newValue != oldValue) {
        this.form.recordId = newValue.id
      }
    },
  },
  created() {
    console.log(this.sound)
    this.uuUserSugarRecordLog()
  },
  methods: {
    closeshow() {
      this.showlist = false
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.uuUserSugarRecordLog()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.uuUserSugarRecordLog()
    },
    uuUserSugarRecordLog() {
      orderrecord({ ...this.form }).then((res) => {
        this.total = res.data.total
        this.form.size = res.data.size
        this.form.current = res.data.current
        this.tablist = res.data.records
      })
    },
    closeUpload() {
      this.uploadVisible = false
      this.comId = 0
    },
    // 上传
    selectUpload(row) {
      console.log(row, 'row')
      this.comId = row.id
      this.uploadVisible = true
    },
    fillData(data) {
      console.log(data, 'data')
      this.uuUserSugarRecordLog()
    },
    // 预览
    selectDownload(item) {
      this.stpFileDosData = item.stpFileDos
      if (this.stpFileDosData == null || this.stpFileDosData.length == 0) {
        this.$message.error('没有可预览的文件')
      } else {
        this.downLoadVisible = true
      }
      console.log(item, 'item', baUrl)
    }
  },
}
</script>

<style lang="scss" scoped></style>
