<template>
  <el-dialog
  append-to-body	
  :before-close="handleClose"
  title="风格选择"	
  :visible.sync="dialogVisible"
  width="50%">
  <div class="container">
    <div v-for="(ym,index) in date" :key="index" :class="styleName==ym.style? 'item active':'item'"><img alt="" :src="ym.picurl" @click="styleName=ym.style">
    </div>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="save">确 定</el-button>
  </span>
</el-dialog>

</template>

<script>
export default {
  props:{
    showstyle:{
      type:Boolean,
      default:()=>{}
    },
    chtostyle:{
      type:String,
      default:()=>{}
    }
  },
  data() {
      return {
        dialogVisible: false,
        styleName:this.chtostyle,
        date:[
          {
            style:'style-12',
            styleName:'风格12',
            picurl:require('@/assets/img/blp/style12.png')
          },
          {
            style:'style-14',
            styleName:'风格14',
            picurl:require('@/assets/img/blp/style14.png')
          },
        ],
        checkstyle:null,
      };
    },
  watch:{
    showstyle(newValue,oldValue){
      if(newValue!=oldValue){
       this.dialogVisible=newValue 
      }
    },
    // chtostyle(newValue,oldValue){
    //   if(newValue!=oldValue){
    //    this.styleName=newValue 
    //   }
    // }
  },
    methods: {
      handleClose() {
        this.$emit('closeinfo',false)
      },
      save(){
        const list =this.date.find(item=>item.style==this.styleName)
        this.$emit('closeinfo',list)
      }
    }
}
</script>

<style lang="scss" scoped>
.container{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 10px;
  .item{
    width: 100%;
    min-height: 80px;
    overflow: hidden;
    border: 1px solid #f7f7f7;
    background: rgb(247,248,250);
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      border: 1px solid #1780ff;
      cursor: pointer;
    }
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  .active{
    border: 1px solid #1780ff;
  }
}
::v-deep{
  .el-dialog__header{
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>