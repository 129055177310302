var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comprehensive-table-container" }, [
    _c(
      "div",
      { staticClass: "GeneralOrder" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.form.fromInfo,
              callback: function ($$v) {
                _vm.$set(_vm.form, "fromInfo", $$v)
              },
              expression: "form.fromInfo",
            },
          },
          _vm._l(_vm.tabgs, function (item, index) {
            return _c("el-tab-pane", {
              key: index,
              attrs: { label: item.label, name: item.name },
            })
          }),
          1
        ),
        _c(
          "div",
          { staticClass: "search" },
          [
            _c(
              "el-form",
              {
                staticClass: "demo-form-inline",
                attrs: { inline: true, model: _vm.formInline },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "订单状态：" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择 订单状态" },
                        model: {
                          value: _vm.formInline.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "region", $$v)
                          },
                          expression: "formInline.region",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "区域一", value: "shanghai" },
                        }),
                        _c("el-option", {
                          attrs: { label: "区域二", value: "beijing" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "结算状态：" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择 结算状态" },
                        model: {
                          value: _vm.formInline.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "region", $$v)
                          },
                          expression: "formInline.region",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "区域一", value: "shanghai" },
                        }),
                        _c("el-option", {
                          attrs: { label: "区域二", value: "beijing" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入会员号查询" },
                      model: {
                        value: _vm.formInline.vip,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "vip", $$v)
                        },
                        expression: "formInline.vip",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入订单号查询" },
                      model: {
                        value: _vm.formInline.vip,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "vip", $$v)
                        },
                        expression: "formInline.vip",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-search", type: "primary" },
                        on: { click: _vm.onSubmit },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-refresh-left",
                          type: "primary",
                        },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-table",
          { attrs: { border: "", data: _vm.tabldata } },
          [
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "序号",
                prop: "id",
                width: "60px",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "商品图片" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-image", { attrs: { src: scope.row.itemImg } }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              staticClass: "shopName",
              attrs: {
                align: "center",
                label: "商品名称",
                prop: "itemTitle",
                "show-overflow-tooltip": "",
                width: "166px",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "会员号" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "订单号", prop: "tradeId" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "商品单价", prop: "itemPrice" },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "实付金额",
                prop: "alipayTotalPrice",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "商品总佣金",
                prop: "pubSharePreFee",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "会员佣金" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "平台佣金" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "支付时间", prop: "tbPaidTime" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "来源渠道", prop: "orderType" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", fixed: "right", label: "订单状态" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", fixed: "right", label: "平台结算状态" },
            }),
          ],
          1
        ),
        _c("el-pagination", {
          attrs: {
            background: "",
            "current-page": 1,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "current-change": _vm.handleCurrentChange,
            "size-change": _vm.handleSizeChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }