import request from '@/utils/request'

export function giteds(id) {
  return request({
    url: 'mall/distributionconfig',
    method: 'get',
    params: id
  })
}

export function putObj(obj) {
  return request({
    url: 'mall/distributionconfig',
    method: 'put',
    data: obj,
  })
}
