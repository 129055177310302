<template>
  <div class="trend">
    <el-card class="trend-card" shadow="hover">
      <template #header>
        <span>
          <vab-icon icon="line-chart-fill" />
          趋势
        </span>
      </template>
      <vab-chart class="trend-echart" :init-options="initOptions" :option="chartOption" theme="vab-echarts-theme" />
    </el-card>
  </div>
</template>

<script>
import VabChart from '@/extra/VabChart'

export default {
  components: {
    VabChart,
  },
  props: {
    shuarr: {
      type: Array,
      default: () => [],
    },
    shulist: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      initOptions: {
        renderer: 'svg',
      },
    };
  },
  computed: {
    chartOption() {
      return {
        tooltip: {
          trigger: 'axis',
          extraCssText: 'z-index:1',
        },
        grid: {
          top: '4%',
          left: '2%',
          right: '2%',
          bottom: '0%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: [
              '1月',
              '2月',
              '3月',
              '4月',
              '5月',
              '6月',
              '7月',
              '8月',
              '9月',
              '10月',
              '11月',
              '12月',
            ],
            boundaryGap: false,
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: '总销量',
            type: 'line',
            data: this.shuarr,
            symbol: 'circle',
            smooth: true,
            yAxisIndex: 0,
            showSymbol: false,
            lineStyle: {},
            areaStyle: {
              opacity: 0.8,
            },
          },
          {
            name: '总成交量',
            type: 'line',
            data: this.shulist,
            symbol: 'circle',
            smooth: true,
            yAxisIndex: 0,
            showSymbol: false,
            lineStyle: {},
            areaStyle: {
              opacity: 0.8,
            },
          },
        ],
      };
    },
  },
};
</script>
