/**
 * 风险池
 */
import request from '@/utils/request'
// 获取风险池数据
export function getUserList(data) {
  return request({
    url: `/mall/healthAbnormalRecord/listHealthAbnormalRecords`,
    method: 'post',
    data,
  })
}
// 获取公海区id
export function findCustomRecordId(data) {
  return request({
    url: `/mall/healthAbnormalRecord/findCustomRecordId`,
    method: 'post',
    data,
  })
}


export function disableUser(data) {
  return request({
    url: `/user/uUserLogin/disable`,
    method: 'post',
    data,
  })
}
// 编辑风险池备注
export function editnotes(data) {
  return request({
    url: `/mall/healthAbnormalRecord/editHealthAbnormalRecordNote`,
    method: 'post',
    data,
  })
}

// 查看记录
export function findList(data) {
  return request({
    url: `/mall/healthAbnormalRecord/listUserHealthAbnormalRecords`,
    method: 'post',
    data,
  })
}
// 编辑状态
export function editHealthStatus(data) {
  return request({
    url: `/mall/healthAbnormalRecord/editHealthAbnormalRecordStatus`,
    method: 'post',
    data,
  })
}
// 编辑风险池状态
export function editstatus(data) {
  return request({
    url: `/mall/healthAbnormalRecord/editHealthAbnormalRecordStatus`,
    method: 'post',
    data,
  })
}
