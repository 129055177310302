var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register-container" },
    [
      _c("div", { staticClass: "ym_header" }, [
        _vm.ruleForm.bindStatus == 1
          ? _c("div", [_vm._v("绑定")])
          : _c("div", [_vm._v("注册")]),
      ]),
      _c(
        "div",
        { staticClass: "ym_form" },
        [
          _c(
            "el-form",
            {
              ref: "formName",
              attrs: {
                "label-width": "150px",
                model: _vm.ruleForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名：", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入 姓名" },
                    model: {
                      value: _vm.ruleForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "userName", $$v)
                      },
                      expression: "ruleForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号：", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: {
                      onfocus: "this.removeAttribute('readonly')",
                      placeholder: "请输入 手机号",
                      readonly: "",
                    },
                    model: {
                      value: _vm.ruleForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "phone", $$v)
                      },
                      expression: "ruleForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm.hide != true
                ? _c(
                    "div",
                    { staticClass: "lis" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "密码：", prop: "password" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入 密码",
                              "show-password": "",
                            },
                            model: {
                              value: _vm.ruleForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "password", $$v)
                              },
                              expression: "ruleForm.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "确认密码：", prop: "passwords" } },
                        [
                          _c("el-input", {
                            attrs: {
                              autocomplete: "off",
                              placeholder: "请输入 确认密码",
                              "show-password": "",
                            },
                            model: {
                              value: _vm.ruleForm.passwords,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "passwords", $$v)
                              },
                              expression: "ruleForm.passwords",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "会员等级：", prop: "userGrade" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择 会员等级" },
                              model: {
                                value: _vm.ruleForm.userGrade,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "userGrade", $$v)
                                },
                                expression: "ruleForm.userGrade",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.levelId,
                                attrs: {
                                  label: item.merName,
                                  value: item.levelId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "邀请人：", prop: "sharerUserCode" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "share",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  autocomplete: "off",
                                  placeholder: "请输入 邀请人",
                                },
                                model: {
                                  value: _vm.ruleForm.sharerUserCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sharerUserCode",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sharerUserCode",
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showhide = true
                                    },
                                  },
                                },
                                [_vm._v("选择")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "积分：", prop: "pointsCurrent" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入 积分" },
                            model: {
                              value: _vm.ruleForm.pointsCurrent,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "pointsCurrent", $$v)
                              },
                              expression: "ruleForm.pointsCurrent",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "性别：", prop: "sex" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              model: {
                                value: _vm.ruleForm.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "sex", $$v)
                                },
                                expression: "ruleForm.sex",
                              },
                            },
                            [_vm._v("男")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              model: {
                                value: _vm.ruleForm.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "sex", $$v)
                                },
                                expression: "ruleForm.sex",
                              },
                            },
                            [_vm._v("女")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "0" },
                              model: {
                                value: _vm.ruleForm.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "sex", $$v)
                                },
                                expression: "ruleForm.sex",
                              },
                            },
                            [_vm._v("未知")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("shareuser", {
        attrs: { hidemenber: _vm.showhide },
        on: { menbarrow: _vm.userinfo },
      }),
      _c(
        "div",
        { staticClass: "ym_checkbtn" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-check", type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }