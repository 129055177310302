<template>
  <div class="comprehensive-table-container">
    <el-form
      ref="queryForm"
      :inline="true"
      :model="queryForm"
    >
      <el-form-item label="店铺：" prop="shopId">
        <el-select v-model="queryForm.shopId"  clearable placeholder="请选择 店铺">
          <el-option
            v-for="item in selection"
            :key="item.id"
            :label="item.name"
            :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="类型：" prop="type">
        <el-select v-model="queryForm.type" clearable placeholder="请选择 类型">
          <el-option label="代金券" value="1"/>
          <el-option label="折扣券" value="2"/>
        </el-select>
      </el-form-item>
      <el-form-item label="到期类型：" prop="expireType">
        <el-select v-model="queryForm.expireType" clearable placeholder="请选择 到期类型">
          <el-option label="领券后生效" value="1"/>
          <el-option label="固定时间段" value="2"/>
        </el-select>
      </el-form-item>
      <el-form-item label="适用商品：" prop="suitType">
        <el-select v-model="queryForm.suitType" clearable placeholder="请选择 适用商品">
          <el-option label="全部商品" value="1"/>
          <el-option label="指定商品可用" value="2"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button  icon="el-icon-search"  native-type="submit"  type="warning"  @click="searchrow">
          搜索
        </el-button>
        <el-button  icon="el-icon-refresh-left"  native-type="submit"  type="primary">
          重置
        </el-button>
      </el-form-item>
      <div class="m-b" style="margin-bottom: 5px;">
        <el-form-item label="">
            <el-button icon="el-icon-plus" native-type="submit" type="primary" @click="addtab">添加</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
    border
    :data="tableData"
    style="width: 100%">
    <el-table-column align="center" label="序号" type="index" width="55"/>
    <el-table-column align="center" label="店铺" prop="shopId" />
    <el-table-column align="center" label="创建时间" prop="createTime" />
    <el-table-column align="center" label="名称" prop="name"/>
    <el-table-column align="center" label="排序" prop="sort"/>
    <el-table-column align="center" label="库存" prop="stock"/>
    <el-table-column align="center" label="订单金额" prop="premiseAmount"/>
    <el-table-column align="center" label="类型" prop="type">
      <template #default="scope">
        <el-tag v-if="scope.row.type==1" effect="dark"  type="">代金券</el-tag>
        <el-tag v-if="scope.row.type==2" effect="dark"   type="danger">折扣券</el-tag>
      </template>
    </el-table-column>
    <el-table-column align="center" label="到期类型" prop="expireType">
      <template #default="scope">
        <el-tag v-if="scope.row.expireType==1" effect="dark"  type="">领券后生效</el-tag>
        <el-tag v-if="scope.row.expireType==2" effect="dark"   type="danger">固定时间段</el-tag>
      </template>
    </el-table-column>
    <el-table-column align="center" label="是否启用" prop="enable">
      <template #default="scope">
        <el-switch
        v-model="scope.row.enable"
        active-color="#13ce66"
        active-value="1"
        inactive-color="#ff4949"/>
      </template>
    </el-table-column>
    <el-table-column align="center" label="适用商品" prop="suitType">
      <template #default="scope">
        <el-tag v-if="scope.row.suitType==1" effect="dark"  type="">全部商品</el-tag>
        <el-tag v-if="scope.row.suitType==2" effect="dark"   type="danger">指定商品可用</el-tag>
      </template>
    </el-table-column>
    <el-table-column align="center" fixed="right" label="操作">
      <template #default="scope">
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="view(scope.row)">查看</el-link>
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="view(scope.row)">编辑</el-link>
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="del(scope.row.id)">删除</el-link>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      background
      :current-page="queryForms.current"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="queryForms.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  <Newcoupon :hideadd="hide" :shoprow="selection" @closehide="closea"/>
  <viewdialog :hideview="hideView" :viewrow="viewRow" @closeview="closeVa"/>
  </div>
</template>

<script>
import { getPage,delPage } from '@/api/mall/markiting/electronicmaking';
import { getList } from '@/api/mall/shop/info';
import Newcoupon from "./components/addedalog.vue"
import viewdialog from './components/viewdialog'
export default {
  components:{Newcoupon,viewdialog},
  data(){
    return{
      queryForm: {},
      selection:[],
      tableData:[],
      queryForms:{size:10,current:1},
      total:20,
      hide:false,
      hideView:false,
      viewRow:null
    }
  },
  created(){
    this.limt()
    this.getList()
  },
  methods:{
    limt(){
      let s=this.queryForms
      getPage({...this.queryForm,...s}).then(res=>{
        this.queryForms.size=res.data.size
        this.queryForms.current=res.data.current
        this.total=res.data.total
       this.tableData=res.data.records
      })
    },
    getList(){
      getList({}).then(res=>{
        this.selection=res.data
        console.log(this.selection)
      })
    },
    handleCurrentChange(e){
      this.queryForms.current=e
      this.limt()
    },
    handleSizeChange(e){
      this.queryForms.size=e
      this.limt()
    },
    editor(e){
      console.log(e)
    },
    searchrow(){
      const data=this.queryForm
      const filteredData = Object.keys(data).filter(key => data[key] !== null && data[key] !== undefined && data[key] !== '').reduce((result, key) => {
        result[key] = data[key];
        return result;
      }, {});
      this.queryForm=filteredData
      this.limt()
    },
    addtab(){
      this.hide=true
    },
    closea(close){
      this.hide=close
    },
    del(rowId){
      delPage(rowId).then(()=>{
        this.$message.success("删除成功")
        this.limt()
      })
    },
    view(row){
      this.hideView=true
      this.viewRow=row
    },
    closeVa(hide){
      this.hideView=hide
    }
  }
}
</script>
<style lang="scss">
</style>