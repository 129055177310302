<template>
  <div class="comprehensive-table-container">
    <div class="search" style="margin-bottom: 10px">
      <el-form class="demo-form-inline" :inline="true" :model="formInline">
        <el-form-item label="姓名">
          <el-input v-model.trim="formInline.realName" placeholder="请输入搜索人姓名" />
        </el-form-item>
        <el-form-item label="下单部门">
          <el-cascader v-model="formInline.mallcategory" :options="dataSourceLists" :props="{
            checkStrictly: true,
            label: 'orgName', value: 'id'
          }" @focus="fetchData">
            <template slot-scope="{ node, data }">
              <span v-if="!node.isLeaf">{{ data.orgName }}({{ data.children.length }})</span>
            </template>
          </el-cascader>
        </el-form-item>
        <!-- <el-form-item label="分线日期">
          <el-date-picker v-model="timer" align="right" end-placeholder="结束日期" format="yyyy-MM-dd"
            :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" type="datetimerange"
            value-format="yyyy-MM-dd" />
        </el-form-item> -->
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="onSubmit">搜索</el-button>
          <el-button icon="el-icon-refresh" type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column align="center" label="姓名" prop="realName" width="120" />
        <el-table-column align="center" label="工号" prop="profile" />
        <el-table-column align="center" label="部门" prop="postName" />
        <el-table-column align="center" label="分线总量" prop="sunCount" />
        <!-- <el-table-column align="center" label="今日分线" prop="todayCount" />
        <el-table-column align="center" label="昨日分线" prop="yesDayCount" />
        <el-table-column align="center" label="本周分线" prop="weekCount" />
        <el-table-column align="center" label="本月分线" prop="mouthCount" />
        <el-table-column align="center" label="年度分线" prop="yearsCount" />
        <el-table-column align="center" label="成交数量" prop="count" />
        <el-table-column align="center" label="成交率" prop="rateValue" /> -->
        <el-table-column align="center" fixed="right" label="操作" prop="rateValue">
          <template #default="scope">
            <el-button type="text" @click="showMore(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background :current-page="1" layout="total, sizes, prev, pager, next, jumper"
      :page-size="formInline.size" :total="total" @current-change="handleCurrentChange"
      @size-change="handleSizeChange" />
    <el-dialog title="查看" :visible.sync="dialogVisible" width="60%" @close="closeDialog">
      <span>
        <el-table border :data="showData" style="width: 100%">
          <el-table-column align="center" label="姓名" prop="realName" width="120" />
          <el-table-column align="center" label="工号" prop="profile" />
          <el-table-column align="center" label="分线总量" prop="sunCount" />
          <el-table-column align="center" label="今日分线" prop="todayCount" />
          <el-table-column align="center" label="昨日分线" prop="yesDayCount" />
          <el-table-column align="center" label="本周分线" prop="weekCount" />
          <el-table-column align="center" label="本月分线" prop="mouthCount" />
          <el-table-column align="center" label="年度分线" prop="yearsCount" />
          <el-table-column align="center" label="成交数量" prop="count" />
          <el-table-column align="center" label="成交率" prop="rateValue" />
          <!-- <el-table-column align="center" label="操作" prop="rateValue">
            <template #default="scope">
              <el-button type="text" @click="showMore(scope.row)">查看</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </span>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import { orgTree } from '@/api/mall/role/orgManage'
import { uuUserSugarStaBranchqueryUserEmpPage } from '@/api/mall/health/teamdata/index'
export default {
  data() {
    return {
      dialogVisible: false,
      dataSourceLists: null,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          // {
          //   text: '最近三个月',
          //   onClick(picker) {
          //     const end = new Date()
          //     const start = new Date()
          //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          //     picker.$emit('pick', [start, end])
          //   },
          // },
        ],
      },
      formInline: {
        size: 10,
        current: 1,
      },
      timer: null,
      total: 0,
      tableData: [],
      showData: [],
    }
  },
  watch: {
    timer(nVal) {
      if (nVal) {
        this.formInline.StartTimer = nVal[0]
        this.formInline.EndTimer = nVal[1]
      } else {
        delete this.formInline.StartTimer
        delete this.formInline.EndTimer
      }
    },
  },
  created() {
    this.getUserList()
  },
  methods: {
    async fetchData() {
      const parms = {
        current: this.current,
        size: this.size,
      }
      await orgTree(parms)
        .then((res) => {
          console.log(res.data)
          if (res.data) {
            this.menuListTable = res.data.records
            this.dataSourceLists = res.data.records
            this.dataSourceLists.map((obj) => {
              obj.label = obj.orgName
              obj.value = obj.id
              const fieldArr = obj.children
              const newFieldArr = fieldArr.map((element) => {
                element.label = element.orgName
                element.value = element.id
                element.children.forEach((item) => {
                  item.label = item.orgName
                  item.value = item.id
                  if (item.children != null) {
                    item.children.forEach((el) => {
                      el.label = el.orgName
                      el.value = el.id
                    })
                  }
                })
                return element
              })
              obj.fieldArr = newFieldArr
              return obj
            })
          }
        })
        .catch(() => { })
      this.listLoading = false
    },
    showMore(row) {
      this.dialogVisible = true
      this.showData.push(row)
    },
    closeDialog() {
      this.dialogVisible = false;
      this.showData = []; // 清空showData数组
    },
    reset() {
      this.timer = []
      this.formInline = {}
      this.formInline.size = 10
      this.formInline.current = 1
      this.getUserList()
    },
    onSubmit() {
      this.formInline.current = 1
      this.getUserList()
    },
    handleCurrentChange(e) {
      this.formInline.current = e
      this.getUserList()
    },
    handleSizeChange(e) {
      this.formInline.size = e
      this.getUserList()
    },
    getUserList() {
      uuUserSugarStaBranchqueryUserEmpPage({ ...this.formInline }).then((res) => {
        this.tableData = res.data.records
        this.formInline.size = res.data.size
        this.formInline.current = res.data.current
        this.total = res.data.total
      })
    },
  },
}
</script>

<style></style>
