var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-descriptions",
        { attrs: { column: 1, size: "medium ", title: "" } },
        [
          _c("el-descriptions-item", { attrs: { label: "事件编号" } }, [
            _vm._v(_vm._s(_vm.list.eventNumber)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "事件状态" } }, [
            _vm.list.afterType == 2 ? _c("span", [_vm._v("处理中")]) : _vm._e(),
            _vm.list.afterType == 3 ? _c("span", [_vm._v("待审核")]) : _vm._e(),
            _vm.list.afterType == 4 ? _c("span", [_vm._v("完成")]) : _vm._e(),
          ]),
          _c("el-descriptions-item", { attrs: { label: "客情备注" } }, [
            _vm._v(_vm._s(_vm.list.remark)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "客情类型" } }, [
            _vm._v(_vm._s(_vm.list.lebalName)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "提交员工" } }, [
            _vm._v(_vm._s(_vm.list.realName)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "客户姓名\t" } }, [
            _vm._v(_vm._s(_vm.list.userName)),
          ]),
          _vm.list.lebalId == "1"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "客户反馈情况\t" } },
                [_vm._v(_vm._s(_vm.list.issueMark))]
              )
            : _vm._e(),
          _vm.list.lebalId == "1"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "客户客情时间\t" } },
                [_vm._v(_vm._s(_vm.list.afterTime))]
              )
            : _vm._e(),
          _vm.list.lebalId == "1"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "客户成交签收金额\t" } },
                [_vm._v(_vm._s(_vm.list.signAmount))]
              )
            : _vm._e(),
          _vm.list.lebalId == "2"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "客户签收时间\t" } },
                [_vm._v(_vm._s(_vm.list.signTime))]
              )
            : _vm._e(),
          _vm.list.lebalId == "2"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "客户反馈时间\t" } },
                [_vm._v(_vm._s(_vm.list.feedTime))]
              )
            : _vm._e(),
          _vm.list.lebalId == "2"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "产品问题描述\t" } },
                [_vm._v(_vm._s(_vm.list.issueMark))]
              )
            : _vm._e(),
          _vm.list.lebalId == "3"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "客户签收时间\t" } },
                [_vm._v(_vm._s(_vm.list.signTime))]
              )
            : _vm._e(),
          _vm.list.lebalId == "3"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "客户反馈时间\t" } },
                [_vm._v(_vm._s(_vm.list.feedTime))]
              )
            : _vm._e(),
          _vm.list.lebalId == "3"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "产品问题描述\t" } },
                [_vm._v(_vm._s(_vm.list.issueMark))]
              )
            : _vm._e(),
          _vm.list.lebalId == "4"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "客户签收时间\t" } },
                [_vm._v(_vm._s(_vm.list.signTime))]
              )
            : _vm._e(),
          _vm.list.lebalId == "4"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "客户反馈时间\t" } },
                [_vm._v(_vm._s(_vm.list.feedTime))]
              )
            : _vm._e(),
          _vm.list.lebalId == "4"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "产品问题描述\t" } },
                [_vm._v(_vm._s(_vm.list.issueMark))]
              )
            : _vm._e(),
          _vm.list.lebalId == "5"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "客户反馈情况\t" } },
                [_vm._v(_vm._s(_vm.list.issueMark))]
              )
            : _vm._e(),
          _vm.list.lebalId == "5"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "客户客情时间\t" } },
                [_vm._v(_vm._s(_vm.list.afterTime))]
              )
            : _vm._e(),
          _vm.list.lebalId == "5"
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "客户退定金金额\t" } },
                [_vm._v(_vm._s(_vm.list.refundAmount))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }