<template>
  <el-dialog append-to-body :before-close="clearclose" title="预存金额" :visible.sync="dialogVisible" width="60%">
    <span>
      <el-form ref="formName" :inline="true" label-width="120px" :model="amount" :rules="rules">
        <el-form-item label="交易类型：" prop="optType">
          <el-select v-model="amount.optType" disabled placeholder="请选择交易类型" style="width: 180px">
            <el-option label="汇入" value="0" />
            <!-- <el-option label="退款" value="1" /> -->
          </el-select>
        </el-form-item>
        <el-form-item label="交易渠道：" prop="bussType">
          <el-select v-model="amount.bussType" placeholder="请选择交易渠道" style="width: 180px" @change="getquery">
            <el-option v-for="(ym, index) in tradeType" :key="index" :label="ym.tradeName" :value="ym.key" />
          </el-select>
        </el-form-item>
        <el-form-item label="交易金额(元)：" prop="logAmount">
          <el-input v-model="amount.logAmount" placeholder="请输入 交易金额" style="width: 180px" />
        </el-form-item>
        <el-form-item :label="`${claName}：`" prop="labelId">
          <el-select v-model="amount.labelId" :placeholder="`请选择${claName}`" style="width: 180px" @change="selected" @focus="selgetter">
            <el-option v-for="item in options" :key="item.id" :label="item.labelName" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item :label="`${claName}账号：`" prop="bankNumber">
          <el-input v-model="amount.bankNumber" :placeholder="`请输入${claName} 账号`" style="width: 180px" />
        </el-form-item>
        <el-form-item :label="`${claName}户名：`" prop="labelName">
          <el-input v-model="amount.labelName" :placeholder="`请输入${claName} 户名`" style="width: 180px" />
        </el-form-item>
        <el-form-item label="收款时间：" prop="processTime">
          <el-date-picker v-model="amount.processTime" format="yyyy-MM-dd " placeholder="请选择 收款时间" style="width: 180px" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
        </el-form-item>
        <!-- <el-form-item label="汇款标签：" prop="remBank">
          <el-input v-model="amount.remBank" placeholder="请输入 汇款标签" style="width: 180px" />
        </el-form-item> -->
        <el-form-item label="交易状态：" prop="preStatus">
          <el-select v-model="amount.preStatus" placeholder="请选择 交易状态" style="width: 180px">
            <el-option label="已到款" value="1" />
            <el-option label="未到款" value="2" />
            <el-option label="已经取消" value="3" />
          </el-select>
        </el-form-item>
        <el-form-item label="交易备注：" prop="content">
          <el-input v-model="amount.content" placeholder="请输入 交易备注" style="width: 180px" type="textarea" />
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="clearclose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { queryPages, merRecordPrestore, queryPrestoreListstpLabel } from '@/api/mall/setting/stplabel'
import dayjs from 'dayjs'
export default {
  props: {
    amoutswitch: {
      type: Boolean,
      default: () => {},
    },
    savforms: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sav: null,
      amount: {
        optType: '0',
        recordId: this.savforms.id,
      },
      dialogVisible: this.amoutswitch,
      options: null,
      rules: {
        logAmount: [{ required: true, message: '请输入交易金额(元)', trigger: 'blur' }],
        optType: [{ required: true, message: '请选择交易类型', trigger: 'change' }],
        preStatus: [{ required: true, message: '请选择交易状态', trigger: 'blur' }],
        labelId: [{ required: true, message: '请选择汇入银行', trigger: 'blur' }],
        bankNumber: [{ required: true, message: '请填写银行账号', trigger: 'blur' }],
        bankName: [{ required: true, message: '请填写银行户名', trigger: 'blur' }],
        processTime: [{ required: true, message: '请选择收款时间', trigger: 'blur' }],
        remBank: [{ required: true, message: '请填写汇款标签', trigger: 'blur' }],
      },
      tradeType: [
        {
          tradeName: '微信',
          key: '1',
        },
        {
          tradeName: '支付宝',
          key: '2',
        },
        {
          tradeName: '银行卡',
          key: '3',
        },
      ],
    }
  },
  computed: {
    claName() {
      let claName = this.amount.bussType
      if (claName == '1') {
        return `微信`
      } else if (claName == '2') {
        return `支付宝`
      } else {
        return `银行`
      }
    },
  },
  watch: {
    amoutswitch(newValue, oldValue) {
      if (newValue != oldValue) {
        const dat = new Date()
        this.$set(this.amount, 'processTime', dat)
        this.dialogVisible = newValue
        this.$set(this.amount, 'recordId', this.savforms.id)
      }
    },
    // savforms(newValue, oldValue) {
    //   if (newValue != oldValue) {
    //     this.$set(this.amount, 'recordId', newValue.id)
    //   }
    // },
  },
  created() {
    // this.amount.recordId = this.savforms.id
  },
  methods: {
    getquery() {
      this.$set(this.amount, 'bankNumber', '')
      this.$set(this.amount, 'labelName', '')
      this.$set(this.amount, 'labelId', '')
      this.queryPrestoreListstpLabel()
    },
    //预存余额精确查询
    queryPrestoreListstpLabel() {
      queryPrestoreListstpLabel({ bussType: this.amount.bussType }).then((res) => {
        this.options = res.data
      })
    },
    submit() {
      const now = new Date()
      this.amount.processTime = now
      this.amount.processTime = dayjs(this.amount.processTime).format('YYYY-MM-DD HH:mm:ss')
      this.$refs.formName.validate((valid) => {
        if (valid) {
          merRecordPrestore({ ...this.amount }).then((res) => {
            if (res.status == 200) {
              this.$emit('closeswitch', false)
              this.$EventBus.$emit('repload')
              this.amount = {}
              this.amount.optType = '0'
              this.$message.success('预存成功')
              this.$refs.formName.resetFields()
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    selected(e) {
      if (this.options == null) {
        this.$message.error('请选择交易渠道')
      } else {
        this.options.forEach((element) => {
          if (element.id == e) {
            this.$set(this.amount, 'bankName', element.bankName)
            this.$set(this.amount, 'remBank', element.remBank)
            this.$set(this.amount, 'bankNumber', element.bankNumber)
            this.$set(this.amount, 'labelName', element.labelName)
          }
        })
      }
    },
    selgetter() {
      if (this.options == null) {
        this.$message.error('请选择交易渠道')
      }
    },
    queryPages() {
      queryPages({ labelType: 7 }).then((res) => {
        this.options = res.data
      })
    },
    clearclose() {
      this.amount = {}
      this.amount.optType = '0'
      this.$refs.formName.resetFields()
      this.$emit('closeswitch', false)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  border-bottom: 1px solid #e5e5e5;
}
</style>
