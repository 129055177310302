<template>
  <div class="comprehensive-table-container">
    <!-- <producttype /> -->
    <el-tabs v-model="activeName" tab-position="left" @tab-click="confirmation = false">
      <el-tab-pane label="基本信息" name="1">
        <basicinformation :switc="browa" @information="basic" />
      </el-tab-pane>
      <el-tab-pane label="规格信息" name="2" style="width: 100%">
        <el-form :model="actionscomponents">
          <el-form-item class="listing" label="规格信息:" prop="Specificationinformation">
            <el-radio-group v-model="actionscomponents.specType" style="margin-right: 180px">
              <el-radio label="0">统一规格</el-radio>
              <el-radio label="1">多规格</el-radio>
            </el-radio-group>
          </el-form-item>
          <MultispecificationVue v-show="actionscomponents.specType == 1" :switc="browa" @Multiple="multirow" />
          <specificationVue v-show="actionscomponents.specType == 0" @specifications="multi" />
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="积分赠送" name="3">
        <el-form :model="actionscomponents">
          <el-form-item class="listing" label="积分赠送:">
            <el-radio-group v-model="actionscomponents.pointsGiveSwitch" style="margin-right: 180px">
              <el-radio label="1">开启赠送</el-radio>
              <el-radio label="0">不开启</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="actionscomponents.pointsGiveSwitch == '1'" label="赠送数量:">
            <el-input-number v-model="actionscomponents.pointsGiveNum" controls-position="right" placeholder="请输入 赠送数量" style="width: 200px" />
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="积分抵扣" name="4">
        <el-form ref="refPoints" :model="actionscomponents" :rules="rules">
          <el-form-item class="listing" label="积分抵扣:">
            <el-radio-group v-model="actionscomponents.pointsDeductSwitch" style="margin-right: 180px">
              <el-radio label="1">开启抵扣</el-radio>
              <el-radio label="0">不开启</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="actionscomponents.pointsDeductSwitch == '1'" label="抵扣比例:" prop="pointsDeductScale">
            <el-input-number v-model="actionscomponents.pointsDeductScale" controls-position="right" placeholder="请输入 抵扣比例" style="width: 300px" />
          </el-form-item>
          <el-form-item v-show="actionscomponents.pointsDeductSwitch == '1'" label="抵扣规则:" prop="pointsDeductAmount">
            <el-input-number v-model="actionscomponents.pointsDeductAmount" controls-position="right" placeholder="请输入 抵扣规则" style="width: 300px; margin-right: 20px" />
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="商品详情" name="5"/>
      <div v-if="activeName==5" style="display: flex;margin-top: 45px;margin-left: 110px;">描述：
        <wangeditors />
        
      </div>
      <div v-if="activeName==5" class="text-c m-t">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button>取消</el-button>
      </div>
    </el-tabs>
  </div>
</template>

<script>
  import wangeditors from './components/wandeditinfo.vue'
  import specificationVue from './components/specification.vue'
  import MultispecificationVue from './components/multispecification.vue'
  // import producttype from './components/producttype.vue'
  import basicinformation from './components/basicinformation.vue'
  import { addObj } from '@/api/mall/goodspu/goodspu'
  import {mapState} from 'vuex'
  export default {
    components: {
      wangeditors,
      specificationVue,
      MultispecificationVue,
      // producttype,
      basicinformation,
    },
    data() {
      return {
        description:null,
        browa: false,
        activeName: '1',
        confirmation: false,
        informations: null, //基本信息
        specificationrow: null, //单一规格s
        specTypecontent: null, //多规格
        rules: {
          pointsDeductScale: [{ required: true, message: '请输入抵扣比例', trigger: 'blur' }],
          pointsDeductAmount: [{ required: true, message: '请输入抵扣规则', trigger: 'blur' }],
        },
        initrow:'<p>请输入内容</p>',
      }
    },
    computed:{
      ...mapState(['state']),
      actionscomponents(){
        return this.$store.state.goodssku
      }
    },
    created(){
      const list ={
        specType: '0',
        name: '',
        shelf: '0',
        saleNum: null,
        ensureIds: [1, 2, 3, 4],
        picUrls: [],
        pointsGiveSwitch: '1',
        pointsDeductSwitch: '1',
        description: '<p>请输入内容</p>',
      }
      this.$store.state.goodssku=list
    },
    methods: {
      save() {
       const lts = Object.assign({},this.$store.state.goodssku)
       if(lts.picUrls==''&&lts.picUrls.length==0){
        this.$message.error("请上传商品图片")
        this.activeName='1'
       }
       else if(lts.name==''){
        this.$message.error("请输入商品名称")
        this.activeName='1'
       }
       else if(lts.titleTag==''){
        this.$message.error("请选择商品标签")
        this.activeName='1'
       }
       else{
        addObj({ ...lts }).then(() => {
          this.$router.push('goodsspu')
          this.$store.state.goodssku={}
          this.$message.success('上传成功')
        })
       }
      },
      basic(row) {
        if (row) {
          this.informations = row
          this.activeName = '2'
        }
      },
      multi(row) {
        if (row) {
          this.specificationrow = row
          this.activeName = '3'
        }
      },
      descr(row) {
        this.description=row
        // this.actionscomponents.description = row
        this.confirmation = true
      },
      multirow(row) {
        this.specTypecontent = row
        this.activeName = '3'
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep ::v-deep .is-active {
    background: rgb(241, 241, 241);
  }
  ::v-deep .el-form {
    max-width: 50%;
    margin-top: 2%;
    margin-left: 5%;
    .el-tabs__item {
      height: 60px;
      font-size: 15px;
      line-height: 60px;
    }
    .el-tabs__active-bar .el-tabs__item {
      padding: 10px;
    }
    .el-form-item {
      width: 100%;
    }
    .el-form-item__content {
      display: flex;
      align-items: center;
    }
    .el-form-item__label {
      width: 80px;
      margin-right: 5px;
      white-space: nowrap;
    }
    .el-col-12 {
      width: 100%;
    }
    .el-form-item__content {
      max-width: 500px;
    }
    .el-textarea__inner {
      width: 100%;
      height: 100px;
    }
    .el-form-item--small {
      align-items: center;
    }
  }
  ::v-deep .listing .el-form-item__content {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 36px;
    margin-left: 0;
  }
  ::v-deep .el-tabs__item{
    height: 60px;
    line-height: 60px;
  }
  ::v-deep{
    .is-active{
      background: rgb(246, 246, 246);
    }
  }
</style>
