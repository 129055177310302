<template>
  <div class="comprehensive-table-container">
     <searchForm/>
     <tableShow/>
  </div>
</template>

<script>
import searchForm from './components/search/search.vue'
import tableShow from './components/tableShow/index.vue'
export default {
   components:{
    searchForm,tableShow
   }
}
</script>

<style>

</style>