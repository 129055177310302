<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick" >
      <el-tab-pane v-if="cur<4" label="客情回执" name="Aftersalesreceipt"/>
      <el-tab-pane  label="事件进度" name="Eventschedule"/>
    </el-tabs>
     <div v-if="cur<4" >
      <Aftersalesreceipt v-if="activeName=='Aftersalesreceipt'" @saveSuccess="handleSaveSuccess" />
      <Eventschedule v-if="activeName=='Eventschedule'" :showhide="cur"/>
    </div>
    <div v-else>
      <Eventschedule v-if="activeName=='Eventschedule'" :showhide="cur"/>
    </div>
  </div>
</template>

<script>
import Aftersalesreceipt from './components/Aftersalesreceipt/index.vue'
import Eventschedule from './components/Eventschedule/index.vue'
export default {
  components:{
    Aftersalesreceipt,
    Eventschedule
  },
  props:{
    showhand:{
      type:Number,
      default:()=>{}
    }
  },
  data(){
    return{
      activeName:this.cur<4?'Aftersalesreceipt':'Eventschedule',
      cur:this.showhand
    }
  },
  watch:{
    showhand(newValue,oldValue){
      if(newValue!=oldValue){
        this.cur=newValue
        console.log(this.cur,'12930vjd')
      }
    }
  },
  created(){
    this.$EventBus.$emit('shwoFrom', this.showhand)
  },
  methods:{
    handleClick(){},
    handleSaveSuccess() {
      this.activeName = 'Eventschedule'; // 切换到事件进度页
    }
  }
}
</script>

<style>

</style>