var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "textTool" },
    [
      _c("div", { staticClass: "toolTit" }, [
        _c("div", { staticClass: "lef" }, [_vm._v("图文中心")]),
        _c("div", { staticClass: "ref" }, [
          _c("div", { staticClass: "switch" }, [
            _c(
              "div",
              {
                class: _vm.checkIndex == 1 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(1)
                  },
                },
              },
              [_vm._v("内容")]
            ),
            _c(
              "div",
              {
                class: _vm.checkIndex == 2 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(2)
                  },
                },
              },
              [_vm._v("样式")]
            ),
          ]),
        ]),
      ]),
      _vm.checkIndex == 1
        ? _c(
            "div",
            { staticClass: "content_msg" },
            [
              _c("div", { staticClass: "ym_line" }),
              _c("div", { staticClass: "ym_title" }, [_vm._v("图片设置")]),
              _c(
                "div",
                {
                  staticClass: "alert-msg",
                  staticStyle: { margin: "top 20px", "text-align": "left" },
                },
                [_vm._v("建议上传尺寸相同的图片，推荐尺寸750*350")]
              ),
              _vm._l(
                _vm.activeComponent[_vm.indexnumer].list,
                function (ym, index) {
                  return _c("div", { key: index, staticClass: "itemBox" }, [
                    _c("div", { staticClass: "card-cont" }, [
                      _c(
                        "div",
                        {
                          staticClass: "close_card-item",
                          on: {
                            click: function ($event) {
                              return _vm.deletelist(index)
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-close" })]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "left_icon",
                          staticStyle: { overflow: "hidden" },
                        },
                        [
                          _c("uploadpic", {
                            attrs: {
                              forindex: index,
                              picurl: [{ url: ym.imageUrl }],
                            },
                            on: {
                              backkuck: function ($event) {
                                return _vm.check($event, index)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "ref_cont" }, [
                        _c("div", { staticClass: "ref_thead" }, [
                          _c("div", [_vm._v("链接地址")]),
                          _c(
                            "div",
                            {
                              staticClass: "ref_txt",
                              on: {
                                click: function ($event) {
                                  return _vm.checklink(index)
                                },
                              },
                            },
                            [
                              ym.link.name != ""
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#1780ff" } },
                                    [_vm._v(_vm._s(ym.link.title))]
                                  )
                                : _c(
                                    "span",
                                    { staticStyle: { color: "#1780ff" } },
                                    [_vm._v("请选择链接")]
                                  ),
                              _c("i", { staticClass: "el-icon-arrow-right" }),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "ref_thead" }, [
                          _c("div", [_vm._v("图像高度")]),
                          _c(
                            "div",
                            { staticClass: "ref_txt" },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60px" },
                                model: {
                                  value: ym.imgHeight,
                                  callback: function ($$v) {
                                    _vm.$set(ym, "imgHeight", $$v)
                                  },
                                  expression: "ym.imgHeight",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "ref_thead" }, [
                          _c("div", [_vm._v("缩放模式")]),
                          _c(
                            "div",
                            {
                              staticClass: "ref_txt",
                              staticStyle: { color: "#1780ff" },
                              on: {
                                click: function ($event) {
                                  return _vm.imgmodel(index, ym.imageMode)
                                },
                              },
                            },
                            [
                              ym.imageMode == "aspectFill"
                                ? _c("span", [_vm._v("填充")])
                                : _vm._e(),
                              ym.imageMode == "aspectFit"
                                ? _c("span", [_vm._v("缩放")])
                                : _vm._e(),
                              ym.imageMode == "scaleToFill"
                                ? _c("span", [_vm._v("拉伸")])
                                : _vm._e(),
                              _c("i", { staticClass: "el-icon-arrow-right" }),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                }
              ),
              _c("div", { staticClass: "itemBox" }, [
                _c(
                  "div",
                  { staticClass: "add-ba", on: { click: _vm.addlist } },
                  [
                    _c("i", { staticClass: "el-icon-plus" }),
                    _vm._v(" 增加一个 "),
                  ]
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.checkIndex == 2
        ? _c("div", { staticClass: "content_msg" }, [
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("组件样式")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("底部背景")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(_vm.activeComponent[_vm.indexnumer].pageBgColor)
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponent[_vm.indexnumer].pageBgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponent[_vm.indexnumer],
                              "pageBgColor",
                              $$v
                            )
                          },
                          expression: "activeComponent[indexnumer].pageBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponent[_vm.indexnumer].pageBgColor =
                                "#FFFFFF"
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "alert-msg" }, [
              _vm._v("底部背景包含边框圆角"),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("组件背景")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(
                        _vm.activeComponent[_vm.indexnumer].componentBgColor
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponent[_vm.indexnumer]
                              .componentBgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponent[_vm.indexnumer],
                              "componentBgColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponent[indexnumer].componentBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponent[
                                _vm.indexnumer
                              ].componentBgColor = "#FFFFFF"
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "alert-msg" }, [
              _vm._v("此处是统一设置图像高度"),
            ]),
            _c("div", {
              staticClass: "ym_line",
              staticStyle: { margin: "10px 0" },
            }),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("上边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 100, min: 0, size: "mini" },
                    on: {
                      change: function ($event) {
                        return _vm.demo($event, _vm.indexnumer)
                      },
                    },
                    model: {
                      value: _vm.activeComponent[_vm.indexnumer].margin.top,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponent[_vm.indexnumer].margin,
                          "top",
                          $$v
                        )
                      },
                      expression: "activeComponent[indexnumer].margin.top",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 100, min: 0 },
                    model: {
                      value: _vm.activeComponent[_vm.indexnumer].margin.top,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponent[_vm.indexnumer].margin,
                          "top",
                          $$v
                        )
                      },
                      expression: "activeComponent[indexnumer].margin.top",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("下边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 100, min: 0, size: "mini" },
                    model: {
                      value: _vm.activeComponent[_vm.indexnumer].margin.bottom,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponent[_vm.indexnumer].margin,
                          "bottom",
                          $$v
                        )
                      },
                      expression: "activeComponent[indexnumer].margin.bottom",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 100, min: 0 },
                    model: {
                      value: _vm.activeComponent[_vm.indexnumer].margin.bottom,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponent[_vm.indexnumer].margin,
                          "bottom",
                          $$v
                        )
                      },
                      expression: "activeComponent[indexnumer].margin.bottom",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("左右边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 20, min: 0, size: "mini" },
                    model: {
                      value: _vm.activeComponent[_vm.indexnumer].margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponent[_vm.indexnumer].margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponent[indexnumer].margin.both",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 20, min: 0 },
                    model: {
                      value: _vm.activeComponent[_vm.indexnumer].margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponent[_vm.indexnumer].margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponent[indexnumer].margin.both",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("上圆角")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, min: 0, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponent[_vm.indexnumer].topAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponent[_vm.indexnumer],
                          "topAroundRadius",
                          $$v
                        )
                      },
                      expression: "activeComponent[indexnumer].topAroundRadius",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value:
                        _vm.activeComponent[_vm.indexnumer].topAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponent[_vm.indexnumer],
                          "topAroundRadius",
                          $$v
                        )
                      },
                      expression: "activeComponent[indexnumer].topAroundRadius",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("下圆角")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, min: 0, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponent[_vm.indexnumer].bottomAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponent[_vm.indexnumer],
                          "bottomAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponent[indexnumer].bottomAroundRadius",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value:
                        _vm.activeComponent[_vm.indexnumer].bottomAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponent[_vm.indexnumer],
                          "bottomAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponent[indexnumer].bottomAroundRadius",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("stydialog", {
        attrs: { listrow: _vm.listcheck, showstyle: _vm.showhide },
        on: { closehide: _vm.cloeshow },
      }),
      _c("linkdialog", {
        attrs: { linkshow: _vm.linkurl },
        on: { closeurl: _vm.closelink },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }