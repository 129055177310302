var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _vm._m(0),
      _c("div", { staticClass: "form-item" }, [
        _c("div", { staticClass: "item-title" }, [_vm._v("活动名称：")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.result.actName,
              expression: "result.actName",
              modifiers: { trim: true },
            },
          ],
          staticClass: "input",
          attrs: { placeholder: "请输入活动名称", type: "text" },
          domProps: { value: _vm.result.actName },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.result, "actName", $event.target.value.trim())
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _c("div", { staticClass: "desc" }, [
          _vm._v("活动名称将显示在活动列表中，方便商家管理"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "form-item" },
        [
          _c("div", { staticClass: "item-title" }, [_vm._v("活动时间：")]),
          _c("el-date-picker", {
            attrs: {
              align: "center",
              "end-placeholder": "请选择活动结束时间",
              "range-separator": "至",
              "start-placeholder": "请选择活动开始时间",
              type: "datetimerange",
              "value-format": "yyyy-MM-dd HH:mm:ss",
            },
            on: { change: _vm.dateChange },
            model: {
              value: _vm.timePicker,
              callback: function ($$v) {
                _vm.timePicker = $$v
              },
              expression: "timePicker",
            },
          }),
          _c("div", { staticClass: "desc" }, [
            _vm._v("活动名称将显示在活动列表中，方便商家管理"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-item" },
        [
          _c("div", { staticClass: "item-title" }, [_vm._v("活动时间：")]),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.result.publishType,
                callback: function ($$v) {
                  _vm.$set(_vm.result, "publishType", $$v)
                },
                expression: "result.publishType",
              },
            },
            [
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("立即发布")]),
              _c("el-radio", { attrs: { label: "2" } }, [_vm._v("定时发布")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.result.publishType == 2
        ? _c(
            "div",
            { staticClass: "second-item" },
            [
              _c("div", { staticClass: "item-title" }, [_vm._v("选择时间：")]),
              _c("el-date-picker", {
                attrs: {
                  align: "center",
                  placeholder: "选择日期时间",
                  type: "datetime",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                model: {
                  value: _vm.result.publishTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.result, "publishTime", $$v)
                  },
                  expression: "result.publishTime",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "form-item" },
        [
          _c("div", { staticClass: "item-title" }, [_vm._v("参与平台：")]),
          _c(
            "el-checkbox-group",
            {
              on: { change: _vm.handlePlateFormChange },
              model: {
                value: _vm.checkPlateForm,
                callback: function ($$v) {
                  _vm.checkPlateForm = $$v
                },
                expression: "checkPlateForm",
              },
            },
            _vm._l(_vm.plateForm, function (item, index) {
              return _c(
                "el-checkbox",
                {
                  key: index,
                  attrs: { label: item.dictKey, value: item.dictKey },
                },
                [_vm._v(" " + _vm._s(item.dictValue) + " ")]
              )
            }),
            1
          ),
          _c("div", { staticClass: "desc" }, [
            _vm._v("活动只在选中的平台进行展示，至少选择一个"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-item" },
        [
          _c("div", { staticClass: "item-title" }, [_vm._v("活动类型：")]),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.result.actType,
                callback: function ($$v) {
                  _vm.$set(_vm.result, "actType", $$v)
                },
                expression: "result.actType",
              },
            },
            _vm._l(_vm.activityList, function (item, index) {
              return _c(
                "el-radio",
                { key: index, attrs: { label: item.dictKey.toString() } },
                [_vm._v(" " + _vm._s(item.dictValue) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-item" },
        [
          _c("div", { staticClass: "item-title" }, [_vm._v("活动封面：")]),
          _c(
            "el-upload",
            {
              attrs: {
                accept: ".jpg,.png,.jpeg,.mp4",
                action: _vm.uploadParams.uploadURL,
                "file-list": _vm.actLogoList,
                headers: { Authorization: _vm.uploadParams.token },
                limit: 1,
                "list-type": "picture-card",
                multiple: false,
                "on-preview": _vm.handlePreView,
                "on-remove": _vm.handleActLogoRemove,
                "on-success": _vm.handleActLogoSuccess,
              },
            },
            [_c("i", { staticClass: "el-icon-plus" })]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-item" },
        [
          _c("div", { staticClass: "item-title" }, [_vm._v("活动背景：")]),
          _c(
            "el-upload",
            {
              attrs: {
                accept: ".jpg,.png,.jpeg,.mp4",
                action: _vm.uploadParams.uploadURL,
                "file-list": _vm.actBackPicList,
                headers: { Authorization: _vm.uploadParams.token },
                limit: 1,
                "list-type": "picture-card",
                "on-preview": _vm.handlePreView,
                "on-remove": _vm.handleBackPicRemove,
                "on-success": _vm.handleActBackPicSuccess,
              },
            },
            [_c("i", { staticClass: "el-icon-plus" })]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom-btns" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleBack } },
            [_vm._v("返回")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.saveBtnLoading,
                size: "small",
                type: "primary",
              },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片详情",
            visible: _vm.imgDetailVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.imgDetailVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                height: "100%",
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
              },
            },
            [_c("el-image", { attrs: { fit: "fill", src: _vm.imgUrl } })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-title" }, [
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "text" }, [_vm._v("基本设置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }