<template>
  <div class="comprehensive-table-container">
    <promoter v-if="unfold=='promoter'" :userid="usID"/>
    <Promotionorder v-else :userid="usID"/>
  </div>
</template>

<script>
import promoter from './components/promoter.vue'
import Promotionorder from './components/Promotionorder.vue'
export default {
  components:{promoter,Promotionorder},
  data(){
    return{
      unfold:'',
      usID:""
    }
  },
  created(){
    this.unfold=this.$route.query.unfold
    this.usID=this.$route.query.id
    console.log(this.$route.query.id)
  }
}
</script>

<style>

</style>