<template>
  <div ref="custom-table" class="custom-table-container" style="min-height: 100vh; padding: 0; overflow: hidden">
    <!-- 头部thead -->
    <div class="top_thead">
      <Topthead />
    </div>
    <!-- 主体内容 -->
    <div class="content_main">
      <contentmain />
    </div>
  </div>
</template>

<script>
import Topthead from './thead-top/index.vue'
import contentmain from './content-main/index.vue'
import assemble from './content-main/components/left-nav/nav-assemble/assemble'
export default {
  components: {
    Topthead,
    contentmain,
  },
  data() {
    return {
      canvasContent: [],
      infofate: [],
    }
  },
  created() {
    if (this.$route.query.date != null) {
      const canvas = JSON.parse(this.$route.query.date)
      console.log(canvas)
      this.$store.state.editcontent = canvas
      if (JSON.parse(canvas.json).value.length > 0) {
        JSON.parse(canvas.json).value.forEach((item) => {
          assemble.forEach((el) => {
            el.classList.forEach((list) => {
              if (list.componentContent.componentName == item.componentName) {
                this.canvasContent.push(item)
                this.infofate.push(list)
              }
            })
          })
        })
      }
      this.$store.commit('setthead', JSON.parse(canvas.json).global)
      this.$store.state.checkcanvas = this.infofate
      this.$store.state.checkvalue = this.canvasContent
      this.$store.state.checknumber = 0
      if (this.$route.query.copy) {
        this.$store.state.editcanvas = 0
      } else {
        this.$store.state.editcanvas = 1
      }
      this.$baseEventBus.$emit('thead_canvas', true)
    } else {
      // 否则默认数据
      assemble.forEach((item) => {
        item.classList.forEach((element) => {
          if (element.type == 'header') {
            this.$store.state.checkdiy.thead = element.componentContent
          }
        })
      })
      this.$store.state.diytype = JSON.parse(this.$route.query.addTemplate).terminal
      this.$store.state.editcanvas = 0
      this.$baseEventBus.$emit('thead_canvas', true)
      this.$store.state.editcontent = JSON.parse(this.$route.query.addTemplate)
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-table-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100vh;
  .top_thead {
    width: 100%;
    height: 53px;
  }
  .content_main {
    width: 100%;
    height: calc(100% - 53px);
    background: white;
  }
}
</style>
