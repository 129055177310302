import request from '@/utils/request'
// 角色管理列表
export function rolePage(data) {
  return request({
    url: '/upms/admin/comSysRole/queryRoleListForPage',
    method: 'post',
    data,
  })
}
// 新增角色
export function rolePageAdd(data) {
  return request({
    url: '/upms/admin/comSysRole/add',
    method: 'post',
    data,
  })
}
// 编辑
export function rolePageUpdate(data) {
  return request({
    url: '/upms/admin/comSysRole/edit',
    method: 'post',
    data,
  })
}
// 删除
export function roleDelete(data) {
  return request({
    url: '/upms/admin/comSysRole/isDelete',
    method: 'post',
    data,
  })
}
// 导出角色
export function rolePageExport(data) {
  return request({
    url: '/upms/admin/comSysRole/export',
    method: 'post',
    data,
  })
}
// 授权菜单列表tree
export function selectMenuTree() {
  return request({
    url: '/upms/admin/comSysMenu/selectMenuTree',
    method: 'post',
  })
}
// 角色管理授权
export function selectMenuTrees() {
  return request({
    url: '/upms/admin/comSysMenu/listUserMenuTree',
    method: 'post',
  })
}
// 授权菜单（查询已授权）
export function selectRoleMenuId(data) {
  return request({
    url: '/upms/admin/comSysRoleMenu/selectRoleMenuId',
    method: 'post',
    data,
  })
}
// 授权菜单保存
export function accreditMenu(data) {
  return request({
    url: '/upms/admin/comSysRoleMenu/accreditMenu',
    method: 'post',
    data,
  })
}
// 授权按钮（查询已授权按钮）
export function selectRoleMenuButton(data) {
  return request({
    url: '/upms/admin/comSysRoleMenuButton/selectRoleMenuButton',
    method: 'post',
    data,
  })
}
// 授权按钮（查询菜单code对应的全部按钮）
export function selectMenuButton(data) {
  return request({
    url: '/upms/admin/comSysMenuButton/selectMenuButton',
    method: 'post',
    data,
  })
}
// 授权接口提交
export function submitUrl(data) {
  return request({
    url: '/upms/admin/comSysRoleUrl/submitUrl',
    method: 'post',
    data,
  })
}
// 授权按钮提交
export function submitButton(data) {
  return request({
    url: '/upms/sys/comSysRoleButton/submitButton',
    method: 'post',
    data,
  })
}
