var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container",
      style: {
        background: `${_vm.controls[_vm.index].pageBgColor}`,
        padding: `${_vm.controls[_vm.index].margin.top}px ${
          _vm.controls[_vm.index].margin.both
        }px ${_vm.controls[_vm.index].margin.bottom}px ${
          _vm.controls[_vm.index].margin.both
        }px`,
      },
    },
    [
      _vm.controls[_vm.index].style == "style-12"
        ? _c(
            "div",
            {
              staticClass: "canvas_cont",
              style: {
                background: `${_vm.controls[_vm.index].componentBgColor}`,
                borderTopLeftRadius: `${
                  _vm.controls[_vm.index].topElementAroundRadius
                }px `,
                borderTopRightRadius: `${
                  _vm.controls[_vm.index].topElementAroundRadius
                }px `,
                borderBottomRightRadius: `${
                  _vm.controls[_vm.index].bottomElementAroundRadius
                }px `,
                borderBottomLeftRadius: `${
                  _vm.controls[_vm.index].bottomElementAroundRadius
                }px `,
              },
            },
            [
              _c("div", { staticClass: "lef" }, [
                _c(
                  "div",
                  {
                    staticClass: "thead_title",
                    style: {
                      color: `${_vm.controls[_vm.index].textColor}`,
                      fontSize: `${_vm.controls[_vm.index].fontSize}px`,
                      fontWeight: `${_vm.controls[_vm.index].fontWeight}`,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.controls[_vm.index].text) + " ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "subheading",
                    style: {
                      color: `${_vm.controls[_vm.index].subTitle.color}`,
                      fontSize: `${
                        _vm.controls[_vm.index].subTitle.fontSize
                      }px`,
                      fontWeight: `${
                        _vm.controls[_vm.index].subTitle.fontWeight
                      }`,
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.controls[_vm.index].subTitle.text) + " "
                    ),
                  ]
                ),
              ]),
              _vm.controls[_vm.index].more.isShow
                ? _c(
                    "div",
                    {
                      staticClass: "lef",
                      style: {
                        color: `${_vm.controls[_vm.index].more.color}`,
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.controls[_vm.index].more.text) + " "
                      ),
                      _c("vab-icon", {
                        staticStyle: { "font-size": "12px" },
                        attrs: { icon: "arrow-right-s-line" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.controls[_vm.index].style == "style-14"
        ? _c("stylefouteen", { attrs: { styleinfo: _vm.controls[_vm.index] } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }