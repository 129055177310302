<template>
  <div class="textTool">
    <div class="toolTit">
      <div class="lef">魔方</div>
      <div class="ref">
        <div class="switch">
          <div :class="checknum == 1 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(1)">内容</div>
          <div :class="checknum == 2 ? 'swit-item active' : 'swit-item'" @click="changeChecknum(2)">样式</div>
        </div>
      </div>
    </div>
    <div v-if="checknum == 1" class="content_msg">
      <div class="ym_line"></div>
      <div class="ym_title">选择风格</div>
      <div class="itemBox" style="height: auto">
        <!-- <div class="Tit"></div> -->
        <div class="check-tit" style="flex-wrap: wrap; width: 100%">
          <div class="alert" style="width: 100%; margin: 10px 0; text-indent: 2em">
            <span style="color: gray">模板：</span>
            {{ modeleType(activeComponents[indexnumer].mode) }}
          </div>
          <el-radio-group
            v-model="activeComponents[indexnumer].mode"
            size="mini"
            style="display: flex; flex-wrap: wrap; justify-content: center; margin: auto"
            @change="pringtmode"
          >
            <el-radio-button label="row1-of2">样式1</el-radio-button>
            <el-radio-button label="row1-of3">样式2</el-radio-button>
            <el-radio-button label="row1-of4">样式3</el-radio-button>
            <el-radio-button label="row2-lt-of2-rt">样式4</el-radio-button>
            <el-radio-button label="row1-lt-of2-rt">样式5</el-radio-button>
            <el-radio-button label="row1-tp-of2-bm">样式6</el-radio-button>
            <el-radio-button label="row1-lt-of1-tp-of2-bm">样式7</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="ym_title">魔方布局</div>
      <div class="itemBox" style="height: auto">
        <div class="card-mode">
          <div v-for="(ym, ind) in activeComponents[indexnumer].list" :key="ind" class="card_modetype">
            <div class="lef_Pic">
              <!-- <el-image :src="ym.imageUrl == '' ? require('@/assets/img/blp/figure.png') : ym.imageUrl" style="width: 100%; height: 100%" /> -->
              <uploadpic :picurl="[{ url: `${ym.imageUrl}` }]" @backkuck="check($event, ind)" />
            </div>
            <div class="ref">
              <div class="thand thead_top">
                <div class="row-span">链接地址</div>
                <div class="ref_txt" @click="checklink(ind)">
                  <span v-if="ym.link.name != ''" style="color: #1780ff">{{ ym.link.title }}</span>
                  <span v-else style="color: #1780ff">请选择链接</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div class="thand thead_top" style="display: flex; align-items: center">
                <div class="row-span" style="white-space: nowrap">图像高度</div>
                <div class="ref_txt">
                  <div class="check-tit" style="display: flex; width: 100%">
                    <el-input-number v-model="ym.imgHeight" :min="216" style="margin-left: 20px" />
                  </div>
                </div>
              </div>
              <div class="thand thead-bottom">
                <div class="row-span">缩放模式</div>
                <div class="ref_txt" style="color: #1780ff" @click="imgmodel(ind, ym.imageMode)">
                  <span v-if="ym.imageMode == 'aspectFill'">填充</span>
                  <span v-if="ym.imageMode == 'aspectFit'">缩放</span>
                  <span v-if="ym.imageMode == 'scaleToFill'">拉伸</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="checknum == 2" class="content_msg">
      <div class="ym_line"></div>
      <div class="ym_title">魔方样式</div>
      <div class="itemBox">
        <div class="Tit">图片间隙</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].imageGap" :max="30" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].imageGap" :max="30" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].topElementAroundRadius" :max="50" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].topElementAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].bottomElementAroundRadius" :max="50" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].bottomElementAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="ym_line"></div>
      <div class="ym_title">组件样式</div>
      <div class="itemBox">
        <div class="Tit">底部背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].pageBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].pageBgColor" />
            <div class="resect" @click="activeComponents[indexnumer].pageBgColor = ''">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">组件背景</div>
        <div class="check-tit" style="display: flex; align-items: center; justify-content: space-between">
          <div class="lef">{{ activeComponents[indexnumer].componentBgColor }}</div>
          <div class="ref" style="display: flex; align-items: center; color: #7aa4ff">
            <el-color-picker v-model="activeComponents[indexnumer].componentBgColor" />
            <div class="resect" @click="activeComponents[indexnumer].componentBgColor = ''">重置</div>
            <i class="iconfont icon-arrow-right" style="font-size: 12px; color: gray"></i>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.top" :max="100" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.top" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.bottom" :max="100" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.bottom" :max="100" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">左右边距</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].margin.both" :max="20" :min="0" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].margin.both" :max="20" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">上圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].topAroundRadius" :max="50" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].topAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
      <div class="itemBox">
        <div class="Tit">下圆角</div>
        <div class="check-tit" style="display: flex; width: 260px">
          <el-slider v-model="activeComponents[indexnumer].bottomAroundRadius" :max="50" size="mini" style="width: 150px" />
          <el-input-number v-model="activeComponents[indexnumer].bottomAroundRadius" :max="50" :min="0" style="margin-left: 20px" />
        </div>
      </div>
    </div>
    <linkdialog :linkshow="linkurl" @closeurl="closelink" />
    <stydialog :listrow="listcheck" :showstyle="showhide" @closehide="cloeshow" />
  </div>
</template>
<script>
import uploadpic from '../../../upload-pic/index.vue'
import { mapGetters } from 'vuex'
import linkdialog from '../../../link-dialog/index.vue'
import stydialog from './components/stydialog.vue'
export default {
  components: {
    uploadpic,
    linkdialog,
    stydialog,
  },
  props: {
    indexnumer: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      checknum: 1,
      linkurl: false,
      Records: null,
      showhide: false,
      listcheck: null,
      checklinknum: null,
    }
  },
  computed: {
    ...mapGetters['state'],
    activeComponents() {
      return this.$store.state.checkvalue
    },
    checkcanvas() {
      return this.$store.state.checkcanvas
    },
    imageurl() {
      let imgUrl = this.activeComponents[this.indexnumer].imageUrl
      if (imgUrl) {
        return imgUrl
      } else {
        return ''
      }
    },
    modeleType() {
      return (item) => {
        if (item == 'row1-of2') {
          return '一行两个'
        }
        if (item == 'row1-of3') {
          return '一行三个'
        }
        if (item == 'row1-of4') {
          return '一行四个'
        }
        if (item == 'row2-lt-of2-rt') {
          return '2左2右'
        }
        if (item == 'row1-lt-of2-rt') {
          return '1左2右'
        }
        if (item == 'row1-tp-of2-bm') {
          return '1上2下'
        }
        if (item == 'row1-lt-of1-tp-of2-bm') {
          return '1左3右'
        }
      }
    },
  },
  created() {
    this.pringtmode(this.activeComponents[this.indexnumer].mode)
  },
  methods: {
    checklink(Index) {
      this.checklinknum = Index
      this.linkurl = true
    },
    //回传关闭
    cloeshow(e) {
      if (e == false) {
        this.showhide = false
      } else {
        this.activeComponents[this.indexnumer].list[this.Records].imageMode = e
        this.showhide = false
      }
    },
    imgmodel(Index, row) {
      this.Records = Index
      this.showhide = true
      this.listcheck = row
    },
    pringtmode(item) {
      const list = {
        imageUrl: '',
        imgWidth: '328',
        imgHeight: '216',
        previewWidth: 175.5,
        previewHeight: '115.57317073170732px',
        link: {
          name: '',
        },
        imageMode: 'scaleToFill',
      }
      if (item == 'row1-of2') {
        if (this.activeComponents[this.indexnumer].list.length < 1) {
          this.activeComponents[this.indexnumer].list.push(list)
          this.checkcanvas[this.indexnumer].list.push(list)
        } else {
          this.activeComponents[this.indexnumer].list = this.activeComponents[this.indexnumer].list.filter((element, index) => index < 2)
          this.checkcanvas[this.indexnumer].list = this.checkcanvas[this.indexnumer].list.filter((element, index) => index < 2)
        }
      }
      if (item == 'row1-of3') {
        if (this.activeComponents[this.indexnumer].list.length < 3) {
          this.activeComponents[this.indexnumer].list.push(list)
          this.checkcanvas[this.indexnumer].list.push(list)
        } else {
          this.activeComponents[this.indexnumer].list = this.activeComponents[this.indexnumer].list.filter((element, index) => index < 3)
          this.checkcanvas[this.indexnumer].list = this.checkcanvas[this.indexnumer].list.filter((element, index) => index < 3)
        }
      }
      if (item == 'row1-of4') {
        if (this.activeComponents[this.indexnumer].list.length < 4) {
          this.activeComponents[this.indexnumer].list.push(list)
          this.checkcanvas[this.indexnumer].list.push(list)
        } else {
          this.activeComponents[this.indexnumer].list = this.activeComponents[this.indexnumer].list.filter((element, index) => index < 4)
          this.checkcanvas[this.indexnumer].list = this.checkcanvas[this.indexnumer].list.filter((element, index) => index < 4)
        }
      }
      if (item == 'row2-lt-of2-rt') {
        if (this.activeComponents[this.indexnumer].list.length < 4) {
          this.activeComponents[this.indexnumer].list.push(list)
          this.checkcanvas[this.indexnumer].list.push(list)
        } else {
          this.activeComponents[this.indexnumer].list = this.activeComponents[this.indexnumer].list.filter((element, index) => index < 4)
          this.checkcanvas[this.indexnumer].list = this.checkcanvas[this.indexnumer].list.filter((element, index) => index < 4)
        }
      }
      if (item == 'row1-lt-of2-rt') {
        if (this.activeComponents[this.indexnumer].list.length < 3) {
          this.activeComponents[this.indexnumer].list.push(list)
          this.checkcanvas[this.indexnumer].list.push(list)
        } else {
          this.activeComponents[this.indexnumer].list = this.activeComponents[this.indexnumer].list.filter((element, index) => index < 3)
          this.checkcanvas[this.indexnumer].list = this.checkcanvas[this.indexnumer].list.filter((element, index) => index < 3)
        }
      }
      if (item == 'row1-tp-of2-bm') {
        if (this.activeComponents[this.indexnumer].list.length < 3) {
          this.activeComponents[this.indexnumer].list.push(list)
          this.checkcanvas[this.indexnumer].list.push(list)
        } else {
          this.activeComponents[this.indexnumer].list = this.activeComponents[this.indexnumer].list.filter((element, index) => index < 3)
          this.checkcanvas[this.indexnumer].list = this.checkcanvas[this.indexnumer].list.filter((element, index) => index < 3)
        }
      }
      if (item == 'row1-lt-of1-tp-of2-bm') {
        if (this.activeComponents[this.indexnumer].list.length < 4) {
          this.activeComponents[this.indexnumer].list.push(list)
          this.checkcanvas[this.indexnumer].list.push(list)
        } else {
          this.activeComponents[this.indexnumer].list = this.activeComponents[this.indexnumer].list.filter((element, index) => index < 4)
          this.checkcanvas[this.indexnumer].list = this.checkcanvas[this.indexnumer].list.filter((element, index) => index < 4)
        }
      }
    },
    closelink(e) {
      if (e != false) {
        this.activeComponents[this.indexnumer].list[this.checklinknum].link = e
        this.linkurl = false
      } else {
        this.linkurl = false
      }
    },
    check(event, Index) {
      this.activeComponents[this.indexnumer].list[Index].imageUrl = event
    },
    changeChecknum(type) {
      this.checknum = type
    },
  },
}
</script>

<style lang="scss" scoped>
.textTool {
  width: 100%;
  padding: 20px;
  .toolTit {
    display: flex;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
    color: #333333;
    .switch {
      display: flex;
      align-items: center;
      overflow: hidden;
      font-size: 14px;
      cursor: pointer;
      background: #f5f5f5;
      border-radius: 50px;
      .swit-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 29px;
        border-radius: 50px;
      }
      .active {
        color: white;
        background: #1780ff;
      }
    }
  }
  .ym_line {
    width: 100%;
    height: 3px;
    background: #f2f4f6;
  }
  .ym_title {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .itemBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 40px;
    margin-left: 20px;
    .card-mode {
      width: 100%;
      .card_modetype {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 0;
        margin: 10px 0;
        border: 1px dashed #e5e5e5;
        .thead_top {
          padding: 10px 0;
        }
        .lef_Pic {
          width: 70px;
          height: 70px;
          margin-left: 10px;
          overflow: hidden;
        }
        .ref {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: calc(100% - 80px);
          .thand {
            display: flex;
            justify-content: space-between;
            width: 100%;
            div {
              margin: 0 10px;
            }
          }
        }
      }
    }
    .Tit {
      margin-right: 15px;
      color: gray;
      text-align: right;
      white-space: nowrap;
    }
    .check-tit {
      display: flex;
      justify-content: right;
      width: calc(100% - 56px);
      color: #105cfb;
      .resect {
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }
  .alert-msg {
    margin-bottom: 15px;
    font-size: 12px;
    color: rgb(210, 210, 210);
    text-align: center;
  }
  ::v-deep(.el-color-picker__trigger) {
    width: 45px;
    height: 26px;
  }
  ::v-deep(.el-icon-arrow-down:before) {
    display: none;
  }
  ::v-deep {
    .el-input-number {
      width: 80px;
      .el-input__inner {
        padding: 0;
        text-align: center;
      }
    }

    .el-input-number__decrease,
    .el-input-number__increase {
      display: none;
    }
  }
}
</style>
