var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comprehensive-table-container" }, [
    _c("div", { staticClass: "top-headerUserinfo" }, [
      _c("div", { staticClass: "lef_userinfo" }, [
        _c("div", { staticClass: "lef_userPicUrl" }, [
          _c("img", { attrs: { alt: "", src: _vm.usermodel.headImage } }),
        ]),
        _c("div", { staticClass: "ref_listinfo" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.usermodel.userName)),
          ]),
          _c("div", { staticClass: "userCode" }, [
            _vm._v("会员编号:" + _vm._s(_vm.usermodel.userId)),
          ]),
          _c("div", { staticClass: "userCode" }, [_vm._v("来源渠道:")]),
          _c("div", { staticClass: "userCode" }, [
            _vm._v("注册时间:" + _vm._s(_vm.usermodel.createTime)),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "tabs" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          _vm._l(_vm.tabs, function (ym, index) {
            return _c(
              "el-tab-pane",
              { key: index, attrs: { label: ym.labelName, name: ym.id } },
              [
                _c(
                  "span",
                  { attrs: { slot: "label" }, slot: "label" },
                  [
                    _c("vab-icon", { attrs: { icon: ym.icon } }),
                    _vm._v(" " + _vm._s(ym.labelName) + " "),
                  ],
                  1
                ),
              ]
            )
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content-value" },
      [
        _vm.activeName == 1
          ? _c("BasicInformation", { attrs: { value: _vm.usermodel } })
          : _vm._e(),
        _vm.activeName == 2
          ? _c("HealthRecord", { attrs: { value: _vm.usermodel } })
          : _vm._e(),
        _vm.activeName == 3
          ? _c("HealthData", { attrs: { value: _vm.usermodel } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }