<template>
  <el-dialog :before-close="handleClose" title="选择会员标签" :visible.sync="dialogVisible" width="50%">
    <span>
      <div class="card-tag">
        <div v-for="(ym, index) in sexoptions" :key="index" :class="cur == index ? 'item-tag active' : 'item-tag'" @click="check(index)">{{ ym.tagName }}</div>
      </div>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { userTagqueryPage } from '@/api/mall/users/usertag'
export default {
  props: {
    show: {
      type: Boolean,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      cur: null,
      sexoptions: [],
    }
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue != oldValue) {
       
        this.dialogVisible = newValue
        if(newValue){
          this.userTagqueryPage()
        }
      
      }
    }
  },
  methods: {
    check(list) {
      console.log(list)
      this.cur = list
    },
    userTagqueryPage() {
      const form = {
        size: 1000,
        current: 1,
        isDelete: 0,
      }
      userTagqueryPage({ ...form }).then((res) => {
        this.sexoptions = res.data.records
      })
    },
    submit() {
      if (this.cur != null) {
        const list = {
          id: this.sexoptions[this.cur].id,
          tagName: this.sexoptions[this.cur].tagName,
        }
        this.$emit('closelist', list)
        // this.$EventBus.$emit('repload')
        this.cur = null
      } else {
        this.$message.error('请选择会员标签')
      }
    },
    handleClose() {
      this.cur = null
      this.$emit('closelist')
      this.dialogVisible = false
      this.$emit('dialogVisible', this.dialogVisible)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-tag {
  display: flex;
  flex-wrap: wrap;
  .item-tag {
    padding: 5px 10px;
    border: 1px solid #e5e5e5;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  .active {
    border: 1px solid #1780ff;
  }
}
</style>