var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticStyle: { border: "1px solid #ccc" } },
      [
        _c("Toolbar", {
          staticStyle: { "border-bottom": "1px solid #ccc" },
          attrs: { "default-config": _vm.toolbarConfig, editor: _vm.editor },
        }),
        _c("Editor", {
          staticStyle: { height: "500px" },
          attrs: { "default-config": _vm.editorConfig, editor: _vm.editor },
          on: { onChange: _vm.onChange, onCreated: _vm.onCreated },
          model: {
            value: _vm.html,
            callback: function ($$v) {
              _vm.html = $$v
            },
            expression: "html",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }