<template>
  <div class="search">
    <div class="scanner" style="height: 35px">
      <div class="info">
        <div class="input-item">
          <div class="input-item-prepend">
            <span class="input-item-text" style="width: 10rem">请输入关键字</span>
          </div>
          <input id="input" v-model="plearch" placeholder="请输入关键词" type="text" />
        </div>
        <p><span id="input-info"></span></p>
      </div>
    </div>
    <div id="AMapContainer" style="width: 100%; height: 450px; margin-top: 20px"></div>
  </div>
</template>

<script>
  import AMapLoader from '@amap/amap-jsapi-loader'
  window._AMapSecurityConfig = {
    securityJsCode: '4308b0548ca151afd1f8a8d629e283af', // '「申请的安全密钥」',
  }
  export default {
    data() {
      return {
        map: null,
        geoCoder: null,
        address: null,
        plearch: '',
        search: false,
      }
    },
    mounted() {
      //DOM初始化完成进行地图初始化
      this.initAMap()
    },
    methods: {
      initAMap() {
        AMapLoader.load({
          key: '407d4a6e511169ea4e98fa7163522243', // 申请好的Web端开发者Key，首次调用 load 时必填
          version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: [
            'AMap.Scale',
            'AMap.PlaceSearch',
            'AMap.ToolBar',
            'AMap.ControlBar',
            'AMap.Geocoder',
            'AMap.Marker',
            'AMap.CitySearch',
            'AMap.Geolocation',
            'AMap.AutoComplete',
            'AMap.InfoWindow',
          ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        })
          .then((AMap) => {
            // 获取到作为地图容器的DOM元素，创建地图实例
            this.map = new AMap.Map('AMapContainer', {
              //设置地图容器id
              resizeEnable: true,
              viewMode: '3D', // 使用3D视图
              zoomEnable: true, // 地图是否可缩放，默认值为true
              dragEnable: true, // 地图是否可通过鼠标拖拽平移，默认为true
              doubleClickZoom: true, // 地图是否可通过双击鼠标放大地图，默认为true
              zoom: 17, //初始化地图级别
              showBuildingBlock: true, // 是否出现建筑模型
              pitch: 55, // 视角控制
            })
            this.geoCoder = new AMap.Geocoder({
              city: '', //城市设为北京，默认：“全国”
              radius: 1000, //范围，默认：500
            })
            var autoComplete = new AMap.Autocomplete(this.geoCoder)
            autoComplete.search(this.plearch, function (status, result) {
              // 搜索成功时，result即是对应的匹配数据
              console.log(result)
              // var node = new PrettyJSON.view.Node({
              //   el: document.querySelector('#input-info'),
              //   data: result,
              // })
            })
            this.map.on('click', (e) => {
              // // 获取经纬度
              this.toGeoCoder(e.lnglat.lng, e.lnglat.lat)
            })
          })
          .catch((e) => {
            console.log(e)
          })
      },

      //坐标转换获取address
      toGeoCoder(lng, lat) {
        let lnglat = [lng, lat]
        this.geoCoder.getAddress(lnglat, (status, result) => {
          if (status === 'complete' && result.regeocode) {
            this.address = result.regeocode.formattedAddress
          }
        })
        if (this.address) {
          const addlist = {
            address: this.address,
            latitude: lat,
            longitude: lng,
          }
          this.$emit('addlist', addlist)
        } else {
          this.$message.error('选择为空')
        }
      },
    },
  }
</script>
<style lang="scss" scope>
  .search {
    position: relative;
    .info {
      position: absolute;
      top: 0;
      z-index: 11;
      background: white;
      border: 1px solid #ebeef5;
      .input-item {
        display: flex;
        align-items: center;
        .input-item-prepend {
          height: 35px;
          padding: 0 5px;
          line-height: 35px;
          color: #909399;
          background: #fafafa;
          border-bottom: 1px solid #ebeef5;
        }
        input {
          height: 35px;
          text-indent: 0.5em;
          border: transparent;
          border-bottom: 1px solid #ebeef5;
        }
      }
    }
  }
</style>
