<template>
  <el-dialog append-to-body :before-close="handleClose" :modal-append-to-body="true" :show-close="false" title="选择规格" :visible.sync="dialogVisible" width="30%">
    <span>
      <div v-for="(ym, index) in qustion" :key="index" class="questions">
        <div class="ym_checkname">{{ ym.value }}</div>
        <div class="ym_select">
          <div v-for="(kys, ind) in ym.leaf" :key="ind" class="ym_keys" :class="{ active: ym.itemIndex == ind }" @click="clik(index, ind)">{{ kys.value }}</div>
        </div>
      </div>
      <div class="ym_checkname">单价</div>
      <div class="ym_stock">
        {{ form.salesPrice }}
      </div>
      <div class="ym_checkname">库存</div>
      <div class="ym_stock">
        {{ stock }}
        <!-- ce -->
      </div>
      <div class="ym_checkname">数量</div>
      <el-input-number v-model="quantit" label="描述文字" :min="1" />
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="clear">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { choosTree } from '@/api/mall/goodspu/goodspu'
  export default {
    props: {
      showtab: {
        type: Boolean,
        default: () => {},
      },
      spuid: {
        type: Number,
        default: () => {},
      },
    },
    data() {
      return {
        dialogVisible: this.showtab,
        spu: this.spuid,
        userlist: {},
        stock: '',
        quantit: 1, //购买数量
        qustion: [],
        form: {},
      }
    },
    watch: {
      spuid(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.spu = newValue
          this.qustion = []
          this.form = {}
        }
      },
      showtab(newValue, oldValue) {
        if (newValue != oldValue) {
          this.dialogVisible = newValue
          this.quantit = 1
          this.qustion = []
          this.choosTree()
        }
      },
    },
    mounted() {
      // this.choosTree()
    },
    methods: {
      handleClose() {},
      choosTree() {
        choosTree({ spuId: this.spu }).then((res) => {
          this.userlist = res.data
          if (res.data.specs.length < 1) {
            this.form = res.data.skus[0]
          } else {
            this.userlist.specs.forEach((element) => {
              Object.assign(element, { itemIndex: -1 })
              if (element) {
                this.qustion.push(element)
              }
            })
          }
          this.stock = this.userlist.skus[0].stock
        })
      },
      clik(e, ind) {
        this.stock = ''
        if (this.qustion[e].itemIndex === ind) {
          this.qustion[e].itemIndex = -1
        } else {
          this.qustion[e].itemIndex = ind
        }
        let array = []
        this.qustion.forEach((ruslt) => {
          if (ruslt.itemIndex > -1) {
            array.push(ruslt.leaf[ruslt.itemIndex].id)
            this.userlist.skus.forEach((row) => {
              if (array.join(',') === row.specsId) {
                this.stock = row.stock
                this.form = {}
                this.form = row
              }
            })
          }
        })
        this.stock = this.userlist.skus[ind].stock
      },
      clear() {
        this.form = {};
        this.$emit('selsku', null)
        this.qustion = []
      },
      save() {
        if (this.userlist.specs.length > 0) {
          let bolean = this.qustion.every((item) => item.itemIndex > -1)
          if (bolean) {
            this.qustion = []
            this.$set(this.form, 'quantity', this.quantit)
            let pay = Number(this.form.salesPrice) * Number(this.form.quantity)
            this.$set(this.form, 'paymentPrice', Number(pay))
            this.$emit('selsku', this.form)
          } else {
            this.$message.error('请补充规格信息')
          }
        } else {
          this.qustion = []
          this.$set(this.form, 'quantity', this.quantit)
          let pay = Number(this.form.salesPrice) * Number(this.form.quantity)
          this.$set(this.form, 'paymentPrice', Number(pay))
          this.$emit('selsku', this.form)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .ym_checkname {
    font-weight: bold;
    line-height: 40px;
    color-interpolation-filters: #333;
  }
  .ym_select {
    margin: 10px 0;
    .ym_keys {
      display: inline-block;
      padding: 8px;
      margin: 0 5px;
      cursor: pointer;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      &:hover {
        border: 1px solid #3782ff;
      }
    }
    .active {
      border: 1px solid #3782ff;
    }
  }
  ::v-deep .el-dialog__header {
    border-bottom: 1px dashed #e8e8e8;
  }
</style>
