<template>
  <div class="comprehensive-table-container">
    <el-button
      class="addBtn"
      icon="el-icon-plus"
      type="primary"
      @click="handleAdd"
    >
      新增
    </el-button>
    <el-table
      border
      :data="tableData"
      style="width: 100%">
      <el-table-column
        align="center"
        label="所属租户"
        prop="tenantId"/>
      <el-table-column
        align="center"
        label="推送ID"
        prop="appId" 
        :show-overflow-tooltip="true"/>
      <el-table-column
        align="center"
        label="app_key"
        prop="appKey" 
        :show-overflow-tooltip="true"/>
      <el-table-column
        align="center"
        label="密钥"
        prop="appSecret" 
        :show-overflow-tooltip="true"/>
      <el-table-column
        align="center"
        label="domain"
        prop="domain" 
        :show-overflow-tooltip="true"/>
      <el-table-column
        align="center"
        label="类型"
        prop="pushType" 
        :show-overflow-tooltip="true">
        <template #default="scope">
          <el-tag  v-if="scope.row.pushType==0" type="success">uniapp</el-tag>
          <el-tag  v-else-if="scope.row.pushType==1" type="success">极光</el-tag>
          <el-tag  v-else type="success">个推</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="应用标识" 
        prop="androidIdentity" 
        :show-overflow-tooltip="true"/>
        <el-table-column
        align="center"
        label="创建时间"
        prop="createTime" 
        :show-overflow-tooltip="true"/>
      <el-table-column
        align="center"
        label="操作" 
        :show-overflow-tooltip="true">
        <template #default="scope">
          <el-link type="primary" :underline="false" @click="eddit(scope.row)">编辑</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="ym_page">
      <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="1"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"/>
    </div>
      <el-dialog
        title="新增"
        :visible.sync="dialogVisible"
        width="60%">
        <span class="rlfrom">
          <el-form ref="ruleForm" class="demo-ruleForm" label-width="100px" :model="ruleForm" :rules="rules">
            <el-form-item label="所属租户:" prop="tenantId">
              <el-input v-model="ruleForm.tenantId" placeholder=""/>
            </el-form-item>
            <el-form-item label="推送ID	:" prop="appId">
              <el-input v-model="ruleForm.appId" placeholder=""/>
            </el-form-item>
            <el-form-item label="app_key	:" prop="appKey">
              <el-input v-model="ruleForm.appKey" placeholder=""/>
            </el-form-item>
            <el-form-item label="密钥:" prop="appSecret">
              <el-input v-model="ruleForm.appSecret" placeholder=""/>
            </el-form-item>
            <el-form-item label="domain:" prop="domain">
              <el-input v-model="ruleForm.domain" placeholder=""/>
            </el-form-item>
            <el-form-item label="类型:" prop="pushType">
              <el-select v-model="ruleForm.pushType">
                <el-option label="uniapp" :value="0"/>
                <el-option label="极光" :value="1"/>
                <el-option label="个推" :value="2"/>
              </el-select>
            </el-form-item>
            <el-form-item label="应用标识	:" prop="androidIdentity">
              <el-input v-model="ruleForm.androidIdentity" placeholder=""/>
            </el-form-item>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="save">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="编辑"
        :visible.sync="edita"
        width="60%">
        <span class="rlfrom">
          <el-form ref="editForm" class="demo-ruleForm" label-width="100px" :model="editaForm" :rules="rules">
            <el-form-item label="所属租户:" prop="tenantId">
              <el-input v-model="editaForm.tenantId" placeholder=""/>
            </el-form-item>
            <el-form-item label="推送ID	:" prop="appId">
              <el-input v-model="editaForm.appId" placeholder=""/>
            </el-form-item>
            <el-form-item label="app_key	:" prop="appKey">
              <el-input v-model="editaForm.appKey" placeholder=""/>
            </el-form-item>
            <el-form-item label="密钥:" prop="appSecret">
              <el-input v-model="editaForm.appSecret" placeholder=""/>
            </el-form-item>
            <el-form-item label="domain:" prop="domain">
              <el-input v-model="editaForm.domain" placeholder=""/>
            </el-form-item>
            <el-form-item label="类型:" prop="pushType">
              <el-select v-model="editaForm.pushType">
                <el-option label="uniapp" :value="0"/>
                <el-option label="极光" :value="1"/>
                <el-option label="个推" :value="2"/>
              </el-select>
            </el-form-item>
            <el-form-item label="应用标识	:" prop="androidIdentity">
              <el-input v-model="editaForm.androidIdentity" placeholder=""/>
            </el-form-item>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="edita = false">取 消</el-button>
          <el-button type="primary" @click="eit">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import {queryPage,addColumn,editColumn} from "@/api/mall/setting/pushmsg"
  export default {
    data() {
      return {
        tableData:[],
        total:0,
        size:10,
        dialogVisible:false,
        edita:false,
        current:1,
        ruleForm:{
          pushType:0
        },
        editaForm:{
          tenantId:'',
          appId:'',
          appKey:'',
          appSecret:'',
          domain:'',
          pushType:0,
          androidIdentity:'',
        },
        rules:{
          tenantId: [
            { required: true, message: '请输入所属租户', trigger: 'blur' },
          ],
          appId: [
            { required: true, message: '请输入推送ID', trigger: 'blur' },
          ],
          appSecret: [
            { required: true, message: '请输入密钥', trigger: 'blur' },
          ],
          appKey: [
            { required: true, message: '请输入app_key', trigger: 'blur' },
          ],
          domain: [
            { required: true, message: '请输入domain', trigger: 'blur' },
          ],
          pushType: [
            { required: true, message: '请输入类型', trigger: 'blur' },
          ],
          androidIdentity: [
            { required: true, message: '请输入应用标识', trigger: 'blur' },
          ],
        }
      }
    },
    created(){
      this.queryPage(this.size,this.current)
    },
    methods: {
      queryPage(size,cur){
        const lst={
          size:size,
          current:cur
        }
        queryPage({...lst}).then(res=>{
          this.tableData=res.data.records
          this.total=res.data.total
          this.size=res.data.size
          this.current=res.data.current
        })
      },
      handleCurrentChange(e){
        this.current=e
        this.queryPage(this.size,this.current)
      },
      handleSizeChange(e){
        this.size=e
        this.queryPage(this.size,this.current)
      },
      handleAdd(){
        this.dialogVisible=true
      },
      save(){
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
           this.addColumn()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      addColumn(){
        const lts={
          tenantId:this.ruleForm.tenantId,
          appId:this.ruleForm.appId,
          appKey:this.ruleForm.appKey,
          appSecret:this.ruleForm.appSecret,
          domain:this.ruleForm.domain,
          pushType:this.ruleForm.pushType,
          androidIdentity:this.ruleForm.androidIdentity
        }
        addColumn({...lts}).then(()=>{
          this.dialogVisible=false
          this.$message.success("添加成功")
          this.queryPage(this.size,this.current)
        })
      },
      eit(){
        this.$refs.editForm.validate((valid) => {
          if (valid) {
           this.editColumn()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      eddit(row){
        this.editaForm={}
        this.editaForm={...row}
        this.edita=true
        console.log(this.editaForm)
      },
      editColumn(){
        const lts={
          id:this.editaForm.id,
          tenantId:this.editaForm.tenantId,
          appId:this.editaForm.appId,
          appKey:this.editaForm.appKey,
          appSecret:this.editaForm.appSecret,
          domain:this.editaForm.domain,
          pushType:this.editaForm.pushType,
          androidIdentity:this.editaForm.androidIdentity
        }
        console.log(lts)
        editColumn({...lts}).then(()=>{
          this.edita=false
          this.$message.success("编辑成功")
          this.queryPage(this.size,this.current)
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $gap: 10px;
  .addBtn {
    margin-bottom: $gap;
  }
  ::v-deep .el-dialog__header{
    border-bottom: 1px solid #d8d8d8;
  }
  .ym_page{
    display: flex;
    justify-content: center;
  }
  ::v-deep .rlfrom .el-form{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
</style>
