<template>
  <div class="comprehensive-table-container">
    <el-dialog :close-on-click-modal="false" :fullscreen="screen" :show-close="false" title=""
      :visible.sync="centerDialogVisible" width="75%">
      <div>
        <div class="thead"
          style="display: flex; align-items: center; justify-content: space-between; padding-bottom: 10px; margin-top: -40px; margin-bottom: 10px; border-bottom: 1px solid #e5e5e5">
          <div class="lef">
            <i class="el-icon-phone"></i>
            电话回访 (客户代表：{{ usmsg.realName }}&nbsp;{{ usmsg.profile }})
          </div>
          <div class="re" style="display: flex; align-items: center; font-size: 24px">
            <i class="el-icon-full-screen" style="margin: 0 10px; font-size: 18px" @click="scren"></i>
            <i class="el-icon-close" @click="clostab"></i>
          </div>
        </div>
        <userinfo :dlrow="dormdate" :usmginfo="usmsg" @closelist="clearn" />
        <!-- tags选项切换 -->
        <el-tabs v-model="act" @tab-click="checkover">
          <el-tab-pane label="综合看板" name="1" />
          <el-tab-pane label="交流记录" name="2" />
          <el-tab-pane label="健康档案" name="3" />
          <el-tab-pane label="App数据" name="4" />
          <el-tab-pane label="预存金额" name="5" />
          <el-tab-pane label="代客下单" name="6" />
          <el-tab-pane label="下单记录" name="7" />
          <el-tab-pane label="客情处理" name="8" />
          <el-tab-pane label="日志记录" name="9" />
          <el-tab-pane label="通话记录" name="12" />
          <el-tab-pane label="录音记录" name="10" />
          <el-tab-pane label="代客注册" name="11" />
          <el-tab-pane label="预付金统计" name="13" />
          <el-tab-pane label="积分统计" name="14" />
        </el-tabs>
        <!-- 健康档案/ -->
        <div v-if="act == 3" :class="screen ? 'contents' : 'content'">
          <el-form :model="dormdate">
            <el-row>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">体检时间</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  <el-date-picker v-model="dormdate.userYear" format="yyyy-MM-dd HH:mm:ss" placeholder=" 年 月 日"
                    type="datetime" />
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">手机号</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.phone" placeholder="手机号" /></div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark">姓名</div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userName" placeholder="姓名" /></div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark">性别</div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark">
                  <el-select v-model="dormdate.userSex" placeholder="请选择客户性别">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                  <!-- <el-input v-model="dormdate.userSex" placeholder="性别" /> -->
                </div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark">年龄</div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userAge" placeholder="年龄" /></div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark">患病年限</div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.fattyIlness" placeholder="患病年限" />
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark">身高 cm</div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark">体重 kg</div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark">BMI</div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark">腰围</div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark">血压</div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark">心率</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">是否规律用药</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userHeight" placeholder="身高" />
                </div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userWeight" placeholder="体重" />
                </div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userMbi" placeholder="BMI" /></div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userWaist" placeholder="腰围" />
                </div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userPressure" placeholder="血压" />
                </div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark"><el-input v-model="dormdate.userHeart" placeholder="心率" />
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  <el-radio-group v-model="dormdate.medRegularly">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark" style="height: 72px; line-height: 72px">是否打针</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  胰岛素
                  <el-checkbox label="" />
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  药名：
                  <el-input v-model="dormdate.casePos1[0].insulinTitle" placeholder="药名" />
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  剂量：
                  <el-input v-model="dormdate.casePos1[0].caseDose" placeholder="剂量" />
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  GLP-1 类
                  <el-checkbox label="" />
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  药名：
                  <el-input v-model="dormdate.casePos1[1].insulinTitle" placeholder="药名" />
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  剂量：
                  <el-input v-model="dormdate.casePos1[1].caseDose" placeholder="剂量" />
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark" style="height: 146px; line-height: 146px">是否口服药</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  药名：
                  <el-input v-model="dormdate.casePos2[0].insulinTitle" placeholder="药名" />
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple-dark">
                  一天
                  <div class="textundeline"><el-input v-model="dormdate.casePos2[0].caseCishu" placeholder=""
                      style="max-width: 55px" /></div>
                  次,一次
                  <div class="textundeline"><el-input v-model="dormdate.casePos2[0].casePian" placeholder=""
                      style="max-width: 55px" /></div>
                  片，一片
                  <div class="textundeline"><el-input v-model="dormdate.casePos2[0].caseMg" placeholder=""
                      style="max-width: 55px" /></div>
                  mg
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  药名：
                  <el-input v-model="dormdate.casePos2[1].insulinTitle" placeholder="药名" />
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple-dark">
                  一天
                  <div class="textundeline"><el-input v-model="dormdate.casePos2[1].caseCishu" placeholder=""
                      style="max-width: 55px" /></div>
                  次,一次
                  <div class="textundeline"><el-input v-model="dormdate.casePos2[1].casePian" placeholder=""
                      style="max-width: 55px" /></div>
                  片，一片
                  <div class="textundeline"><el-input v-model="dormdate.casePos2[1].caseMg" placeholder=""
                      style="max-width: 55px" /></div>
                  mg
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  药名：
                  <el-input v-model="dormdate.casePos2[2].insulinTitle" placeholder="药名" />
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple-dark">
                  一天
                  <div class="textundeline"><el-input v-model="dormdate.casePos2[2].caseCishu" placeholder=""
                      style="max-width: 55px" /></div>
                  次,一次
                  <div class="textundeline"><el-input v-model="dormdate.casePos2[2].casePian" placeholder=""
                      style="max-width: 55px" /></div>
                  片，一片
                  <div class="textundeline"><el-input v-model="dormdate.casePos2[2].caseMg" placeholder=""
                      style="max-width: 55px" /></div>
                  mg
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  药名：
                  <el-input v-model="dormdate.casePos2[3].insulinTitle" placeholder="药名" />
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple-dark">
                  一天
                  <div class="textundeline"><el-input v-model="dormdate.casePos2[3].caseCishu" placeholder=""
                      style="max-width: 55px" /></div>
                  次,一次
                  <div class="textundeline"><el-input v-model="dormdate.casePos2[3].casePian" placeholder=""
                      style="max-width: 55px" /></div>
                  片，一片
                  <div class="textundeline"><el-input v-model="dormdate.casePos2[3].caseMg" placeholder=""
                      style="max-width: 55px" /></div>
                  mg
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">是否会监测血糖</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-dark">
                  <el-radio-group v-model="dormdate.sugarStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                  <div v-if="dormdate.sugarStatus == 1">
                    (空腹：
                    <el-input v-model="dormdate.sugarStomach" placeholder="" style="max-width: 100px" />
                    餐后2小时:
                    <el-input v-model="dormdate.sugarMeal" placeholder="" style="max-width: 100px" />
                    )
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">是否出现低血糖</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  <el-radio-group v-model="dormdate.sugarLowStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
              <el-col :span="6">
                <div v-if="dormdate.sugarLowStatus == 1" class="grid-content bg-purple-dark">出现时间和频率</div>
                <div v-else class="grid-content bg-purple-dark">出现时间和频率</div>
              </el-col>
              <el-col :span="6">
                <div v-if="dormdate.sugarLowStatus == 1" class="grid-content bg-purple-dark">
                  <el-input v-model="dormdate.sugarLowMeal" placeholder="出现时间和频率" />
                </div>
                <div v-else class="grid-content bg-purple-dark"></div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark" style="height: 100px">既往病史</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-dark" style="flex-wrap: wrap; height: 100px">
                  <el-checkbox-group v-model="dormdate.casePos3" style="white-space: wrap">
                    <el-checkbox v-for="(yn, index) in ca" :key="index" :label="yn.caseName" />
                  </el-checkbox-group>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">既往史是否用药</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-dark" style="white-space: nowrap">
                  <el-radio-group v-model="dormdate.drugStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                  <div v-if="dormdate.drugStatus == 1" style="margin-left: 20px">药物名称：</div>
                  <el-input v-if="dormdate.drugStatus == 1" v-model="dormdate.drugName" placeholder="药物名称" />
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">住院史</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-dark" style="white-space: nowrap">
                  <el-input v-model="dormdate.addressDetail" placeholder="住院史地址" />
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">脂肪肝</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark" style="white-space: nowrap">
                  <el-radio-group v-model="dormdate.fattyStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">家族史</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark">
                  <el-input v-model="dormdate.fattyName" placeholder="" />
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <div class="grid-content bg-purple-dark" style="height: 45px">最希望改善的地方</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-dark" style="flex-wrap: wrap; height: 45px">
                  <el-checkbox-group v-model="dormdate.casePos4" style="white-space: wrap">
                    <el-checkbox v-for="(yn, index) in cas" :key="index" :label="yn.caseName" />
                  </el-checkbox-group>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3">
                <div class="grid-content bg-purple-dark" style="height: 257px">身体症状</div>
              </el-col>
              <el-col :span="21">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  1、有无手麻、脚麻或皮肤瘙痒等症状？
                  <el-radio-group v-model="dormdate.casePos5[0].caseStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
              <el-col :span="21">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  2、有无失眠、多梦或便秘等症状？
                  <el-radio-group v-model="dormdate.casePos5[1].caseStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
              <el-col :span="21">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  3、是否有便秘、腹泻等症状？
                  <el-radio-group v-model="dormdate.casePos5[2].caseStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
              <el-col :span="21">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  4、有无心慌、胸闷、气短、头晕头痛等心脑血管症状？
                  <el-radio-group v-model="dormdate.casePos5[3].caseStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
              <el-col :span="21">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  5、有无视物模糊？
                  <el-radio-group v-model="dormdate.casePos5[4].caseStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
              <el-col :span="21">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  6、有无尿液泡沫增多？
                  <el-radio-group v-model="dormdate.casePos5[5].caseStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
              <el-col :span="21">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  7、有无糖尿病足部病变（如损伤后不容易愈合）？
                  <el-radio-group v-model="dormdate.casePos5[6].caseStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple-dark">饮食生活调查问卷</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="grid-content bg-purple-dark lef_ym">1、平时吃一顿饭用时</div>
              </el-col>
              <el-col :span="16">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  大概
                  <div class="textundeline"><el-input v-model="dormdate.caseMg" placeholder=""
                      style="max-width: 55px" /></div>
                  分钟
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="grid-content bg-purple-dark lef_ym">2、晚上 8 点后会吃食物吗？</div>
              </el-col>
              <el-col :span="16">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  <el-radio-group v-model="dormdate.casePos6[1].caseStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                  一周
                  <div class="textundeline"><el-input v-model="dormdate.casePos6[1].caseCishu" placeholder=""
                      style="max-width: 55px" /></div>
                  次
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="grid-content bg-purple-dark lef_ym">3、三餐以外会吃食物/零食吗？</div>
              </el-col>
              <el-col :span="16">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  <el-radio-group v-model="dormdate.casePos6[2].caseStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                  一周
                  <div class="textundeline"><el-input v-model="dormdate.casePos6[2].caseCishu" placeholder=""
                      style="max-width: 55px" /></div>
                  次
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="grid-content bg-purple-dark lef_ym">4、是否常喝饮料/酒</div>
              </el-col>
              <el-col :span="16">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  <el-radio-group v-model="dormdate.casePos6[3].caseStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                  一周
                  <div class="textundeline"><el-input v-model="dormdate.casePos6[3].caseCishu" placeholder=""
                      style="max-width: 55px" /></div>
                  次
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="grid-content bg-purple-dark lef_ym">5、平时会运动吗？</div>
              </el-col>
              <el-col :span="16">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  <el-radio-group v-model="dormdate.casePos6[4].caseStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                  一周
                  <div class="textundeline"><el-input v-model="dormdate.casePos6[4].caseCishu" placeholder=""
                      style="max-width: 55px" /></div>
                  次
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="grid-content bg-purple-dark lef_ym">6、是否 12 点之前入睡？</div>
              </el-col>
              <el-col :span="16">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  <el-radio-group v-model="dormdate.casePos6[5].caseStatus">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="grid-content bg-purple-dark lef_ym">7、睡眠质量好吗？</div>
              </el-col>
              <el-col :span="16">
                <div class="grid-content bg-purple-dark lef_ym" style="white-space: nowrap">
                  <el-radio-group v-model="dormdate.casePos6[6].caseStatus">
                    <el-radio :label="1">好</el-radio>
                    <el-radio :label="0">不好</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!-- 综合看板 -->
        <Integratedsignage v-if="act == 1" :userinfo="userinfo" />
        <!-- 日志记录 -->
        <tablelogs v-if="act == 9" :userinfo="usId" />
        <!-- 交流记录 -->
        <Communication v-if="act == 2" :userinfo="rowlist" />
        <!-- 代客注册 -->
        <registration v-if="act == 11" :uslist="userinfo" />
        <!-- 下单记录 -->
        <orderrecord v-if="act == 7" :sound="userinfo" />
        <!-- 代客下单 -->
        <activity v-if="act == 6" :uslideo="userinfo" @switch="checkswitch" />
        <!-- 录音记录 -->
        <Soundrecording v-if="act == 10" :sound="userinfo" />
        <!-- 通话记录 -->
        <calllog v-if="act == 12" :sound="userinfo" />
        <!-- App数据 -->
        <appdata v-if="act == 4" :sound="userinfo" />
        <!-- 预存金额 -->
        <amountdeposited v-if="act == 5" :sound="userinfo" />
        <!-- 客情处理 -->
        <Aftersalesrecord v-if="act == 8" :sound="userinfo" />
        <!-- 预付金统计 -->
        <DepositStatisticsCom v-if="act == 13" :sound="userinfo" />
        <!-- 积分统计 -->
        <integralStatisticsCom v-if="act == 14" :sound="userinfo" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tablelogs from './tablelogs.vue'
import { stpCxccConfig } from '@/api/mall/health/casemy/index'
import { mapGetters } from 'vuex'
import userinfo from './userinfo'
import Communication from './Communication.vue'
import registration from './registration/index.vue'
import orderrecord from './orderrecord.vue'
import activity from './activity-customers/index.vue'
import Soundrecording from './Sound-recording/index.vue'
import calllog from './call-log/index.vue'
import appdata from './app-data/index.vue'
import amountdeposited from './Amount-deposited/index.vue'
import Aftersalesrecord from './After-sales-record/index.vue'
import Integratedsignage from './Integrated-signage/index.vue'
import DepositStatisticsCom from '../../DepositStatisticsCom/index.vue'
import integralStatisticsCom from '../../integralStatisticsCom/index.vue'
export default {
  components: {
    tablelogs,
    userinfo,
    appdata,
    calllog,
    activity,
    Communication,
    registration,
    orderrecord,
    amountdeposited,
    Aftersalesrecord,
    Soundrecording,
    Integratedsignage,
    DepositStatisticsCom,
    integralStatisticsCom
  },
  props: {
    showedit: {
      type: Boolean,
      default: () => { },
    },
    edirow: {
      type: Number,
      default: () => { },
    },
    edilist: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      screen: false,
      centerDialogVisible: false,
      rowlist: null,
      userinfo: null,
      uslis: null,
      act: '1',
      usId: '',
      options: [
        {
          label: '男',
          value: '1',
        },
        {
          label: '女',
          value: '0',
        },
      ],
      dormdate: {
        sugarStatus: '1',
        medRegularly: '1',
        sugarLowStatus: '1',
        drugStatus: '1',
        fattyStatus: '1',
        casePos1: [
          {
            caseName: '胰岛素',
          },
          {
            caseName: 'GLP-1 类',
          },
        ],
        casePos2: [{}, {}, {}, {}],
        casePos3: [],
        casePos4: [],
        casePos5: [
          { caseClassify: '1', caseStatus: 0 },
          { caseClassify: '2', caseStatus: 0 },
          { caseClassify: '3', caseStatus: 0 },
          { caseClassify: '4', caseStatus: 0 },
          { caseClassify: '5', caseStatus: 0 },
          { caseClassify: '6', caseStatus: 0 },
          { caseClassify: '7', caseStatus: 0 },
        ],
        casePos6: [
          { caseClassify: '1' },
          { caseClassify: '2', caseStatus: 0 },
          { caseClassify: '3', caseStatus: 0 },
          { caseClassify: '4', caseStatus: 0 },
          { caseClassify: '5', caseStatus: 0 },
          { caseClassify: '6', caseStatus: 0 },
          { caseClassify: '7', caseStatus: 0 },
        ],
      },
      cas: [
        { selected: false, caseStatus: 0, caseName: '血糖平稳' },
        { selected: false, caseStatus: 0, caseName: '减重' },
        { selected: false, caseStatus: 0, caseName: '减药' },
        { selected: false, caseStatus: 0, caseName: '停药' },
        { selected: false, caseStatus: 0, caseName: '预防/延缓并发症' },
      ],
      ca: [
        { selected: false, caseStatus: 0, caseName: '以下均无' },
        { selected: false, caseStatus: 0, caseName: '高脂血症' },
        { selected: false, caseStatus: 0, caseName: '高血压' },
        { selected: false, caseStatus: 0, caseName: '肾病' },
        { selected: false, caseStatus: 0, caseName: '胆囊炎' },
        { selected: false, caseStatus: 0, caseName: '尿蛋白/尿微量白蛋白异常' },
        { selected: false, caseStatus: 0, caseName: '其他' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      usmsg: 'user/userinfo',
    }),
  },
  watch: {
    showedit(newValue, oldValue) {
      if (newValue != oldValue) {
        this.centerDialogVisible = newValue
      }
    },
    edirow(newValue, oldValue) {
      if (newValue != oldValue) {
        this.rowlist = newValue
        this.stpCxccConfig(this.rowlist)
      }
    },
    edilist(newValue, oldValue) {
      if (newValue != oldValue) {
        this.userinfo = newValue
        this.uslis = newValue
      }
    },
  },
  created() { },
  mounted() {
    this.$baseEventBus.$on('checktage', (e) => {
      this.act = e
    })
  },
  methods: {
    //监听选项切换
    checkover(e) {
      console.log(e.name)
    },
    checkswitch(e) {
      console.log(e,'e')
      this.act = e
      if (e == 3) {
        this.stpCxccConfig()
      }
    },
    scren() {
      if (this.screen) {
        this.screen = false
      } else {
        this.screen = true
      }
    },
    clearn() {
      this.$emit('clearedi', false)
    },
    stpCxccConfig(NUMbitd) {
      stpCxccConfig({ id: NUMbitd }).then((res) => {
        let date = res.data
        if (date) {
          //检查对象中如果字段是空或者 null亦或者是数组长度为0就删除字段
          for (let key of Object.keys(date)) {
            if (date[key] === null || date[key] === undefined) {
              delete date[key]
            } else if (Array.isArray(date[key])) {
              if (date[key].length === 0) {
                delete date[key]
              }
            }
          }
          for (let i = 3; i <= 4; i++) {
            if (date['casePos' + i]) {
              let cas = []
              date['casePos' + i].forEach((element) => {
                cas.push(element.caseName)
              })
              date['casePos' + i] = cas
            }
          }
          if (date.casePos1) {
            let cas = []
            date.casePos1.forEach((element) => {
              cas.push({
                insulinTitle: element.insulinTitle,
                caseDose: element.caseDose,
              })
              date.casePos1 = cas
            })
          }
          if (date.casePos2) {
            let cas = []
            date.casePos2.forEach((element) => {
              cas.push({
                insulinTitle: element.insulinTitle,
                caseCishu: element.caseCishu,
                casePian: element.casePian,
                caseMg: element.caseMg,
              })
              date.casePos2 = cas
            })
          }
          for (let i = 5; i <= 6; i++) {
            if (date['casePos' + i]) {
              let cas = []
              date['casePos' + i].forEach((element) => {
                cas.push({ caseClassify: element.caseClassify, caseStatus: element.caseStatus })
              })
              date['casePos' + i] = cas
            }
          }
          //合并字段
          this.dormdate = Object.assign({}, this.dormdate, date)
        }
      })
    },
    clostab() {
      this.act = '1'
      this.$emit('clearedi', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.ym_header {
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px dashed #ececec;
}

::v-deep {
  .el-col {
    border: 1px solid #b4b4b4;
  }

  .el-input__inner {
    border: none;
  }
}

.content {
  width: 90%;
  max-height: 350px;
  margin: auto;
  overflow-y: scroll;
}

.bg-purple-dark {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 0 10px;
  white-space: nowrap;
}

.textundeline {
  min-width: 20px;
  border-bottom: 1px solid #a3a3a3;
}

.lef_ym {
  justify-content: left;
}

.solt {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

.tage {
  font-size: 14px;
}

.activ {
  font-size: 16px;
}

.sc {
  width: 100%;
  height: 100%;
  margin-top: 0;
}

.contents {
  width: 70%;
  height: calc(100vh - 450px);
  margin: auto;
  overflow: hidden;
  overflow-y: scroll;
}
</style>
