export default {
  components: {},
  data() {
    return {
      pageLayout: 'total, sizes, prev, pager, next, jumper',
    }
  },
  mounted() {},
  methods: {},
}
