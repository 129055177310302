var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comprehensive-table-container" }, [
    _c("div", { staticClass: "thead" }, [
      _c("div", { staticClass: "header-title" }, [
        _c("div", { staticClass: "lef" }, [_vm._v("健康综合指数分值")]),
        _c(
          "div",
          { staticClass: "ref" },
          [
            _c("vab-icon", {
              staticStyle: { "font-size": "28px", color: "#007aff" },
              attrs: { icon: "edit-box-line" },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "line-echart" }, [
        _c("div", { staticClass: "scroll-value" }, [
          _c("div", { staticClass: "scroll-view" }, [
            _c(
              "div",
              {
                staticClass: "item seizure",
                style: { width: _vm.scroll + "%" },
              },
              [
                _c("div", { staticClass: "move" }, [
                  _vm._v(_vm._s(_vm.scroll)),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "seizure-txt",
                    staticStyle: { color: "#ff0000" },
                  },
                  [_vm._v("疾病发作状态")]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "item seizure",
                style: { width: _vm.scrollvalue + "%", background: "#F66105" },
              },
              [
                _c("div", { staticClass: "move" }, [
                  _vm._v(_vm._s(_vm.scrollvalue)),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "seizure-txt",
                    staticStyle: { color: "#f66105" },
                  },
                  [_vm._v("疾病发作前的前驱状态")]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "item seizure",
                style: { width: _vm.scrollvalues + "%", background: "#F9AE45" },
              },
              [
                _c("div", { staticClass: "move" }, [
                  _vm._v(_vm._s(_vm.scrollvalues)),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "seizure-txt",
                    staticStyle: { color: "#f9ae45" },
                  },
                  [_vm._v("亚健康状态")]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "item seizure",
                style: { width: _vm.succes + "%", background: "#1FC030" },
              },
              [
                _c("div", { staticClass: "move" }, [
                  _vm._v(_vm._s(_vm.succes)),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "seizure-txt",
                    staticStyle: { color: "#1fc030" },
                  },
                  [_vm._v("健康状态")]
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "thead", staticStyle: { "margin-top": "55px" } }, [
      _c("div", { staticClass: "header-title" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "ref" },
          [
            _c("vab-icon", {
              staticStyle: { "font-size": "28px", color: "#007aff" },
              attrs: { icon: "edit-box-line" },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "content-value" }, [
      _c("div", { staticClass: "scanner" }, [
        _c("div", { staticClass: "irem" }, [
          _c(
            "div",
            { staticClass: "item-scanner stor" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("数据指数占比")]),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入内容", type: "number" },
                  model: {
                    value: _vm.form.healData,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "healData", $$v)
                    },
                    expression: "form.healData",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-scanner" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("血压指数占比")]),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入内容", type: "number" },
                  model: {
                    value: _vm.form.data[0].indexValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.data[0], "indexValue", $$v)
                    },
                    expression: "form.data[0].indexValue",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-scanner" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("血糖指数占比")]),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入内容", type: "number" },
                  model: {
                    value: _vm.form.data[1].indexValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.data[1], "indexValue", $$v)
                    },
                    expression: "form.data[1].indexValue",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-scanner" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("心率指数占比")]),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入内容", type: "number" },
                  model: {
                    value: _vm.form.data[2].indexValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.data[2], "indexValue", $$v)
                    },
                    expression: "form.data[2].indexValue",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-scanner" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("血氧指数占比")]),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入内容", type: "number" },
                  model: {
                    value: _vm.form.data[3].indexValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.data[3], "indexValue", $$v)
                    },
                    expression: "form.data[3].indexValue",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "irem" }, [
          _c(
            "div",
            { staticClass: "item-scanner stor" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("饮食指数占比")]),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入内容", type: "number" },
                  model: {
                    value: _vm.form.healDiet,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "healDiet", $$v)
                    },
                    expression: "form.healDiet",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-scanner" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("早餐指数占比")]),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入内容", type: "number" },
                  model: {
                    value: _vm.form.diet[0].indexValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.diet[0], "indexValue", $$v)
                    },
                    expression: "form.diet[0].indexValue",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-scanner" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("午餐指数占比")]),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入内容", type: "number" },
                  model: {
                    value: _vm.form.diet[1].indexValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.diet[1], "indexValue", $$v)
                    },
                    expression: "form.diet[1].indexValue",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-scanner" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("晚餐指数占比")]),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入内容", type: "number" },
                  model: {
                    value: _vm.form.diet[2].indexValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.diet[2], "indexValue", $$v)
                    },
                    expression: "form.diet[2].indexValue",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-scanner" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("水果指数占比")]),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入内容", type: "number" },
                  model: {
                    value: _vm.form.diet[3].indexValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.diet[3], "indexValue", $$v)
                    },
                    expression: "form.diet[3].indexValue",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "irem" }, [
          _c(
            "div",
            { staticClass: "item-scanner stor" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("运动指数占比")]),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入内容", type: "number" },
                  model: {
                    value: _vm.form.healMove,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "healMove", $$v)
                    },
                    expression: "form.healMove",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-scanner" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("步数指数占比")]),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入内容", type: "number" },
                  model: {
                    value: _vm.form.move[0].indexValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.move[0], "indexValue", $$v)
                    },
                    expression: "form.move[0].indexValue",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "btn-slot" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lef" }, [
      _vm._v(" 分值占比例 "),
      _c("span", { staticStyle: { "font-size": "14px", color: "gray" } }, [
        _vm._v(
          "注：健康分值指根据用户的相关健康数据给出一个综合的分数，健康、饮食、运动占比指数相加为100；每个板块的子项目指数相加为100"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }