var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        "show-close": false,
        title: "选择规格",
        visible: _vm.dialogVisible,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _vm._l(_vm.qustion, function (ym, index) {
            return _c("div", { key: index, staticClass: "questions" }, [
              _c("div", { staticClass: "ym_checkname" }, [
                _vm._v(_vm._s(ym.value)),
              ]),
              _c(
                "div",
                { staticClass: "ym_select" },
                _vm._l(ym.leaf, function (kys, ind) {
                  return _c(
                    "div",
                    {
                      key: ind,
                      staticClass: "ym_keys",
                      class: { active: ym.itemIndex == ind },
                      on: {
                        click: function ($event) {
                          return _vm.clik(index, ind)
                        },
                      },
                    },
                    [_vm._v(_vm._s(kys.value))]
                  )
                }),
                0
              ),
            ])
          }),
          _c("div", { staticClass: "ym_checkname" }, [_vm._v("库存")]),
          _c("div", { staticClass: "ym_stock" }, [
            _vm._v(" " + _vm._s(_vm.stock) + " "),
          ]),
          _c("div", { staticClass: "ym_checkname" }, [_vm._v("数量")]),
          _c("el-input-number", {
            attrs: { label: "描述文字", min: 1 },
            model: {
              value: _vm.quantit,
              callback: function ($$v) {
                _vm.quantit = $$v
              },
              expression: "quantit",
            },
          }),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.clear } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }