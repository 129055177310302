<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    title="编辑"
    :visible.sync="dialogVisibl"
    width="60%">
    <span>
      <el-form ref="addform" label-width="80px" :model="formDatal" :rules="rules">
        <el-form-item label="昵称:" prop="nickName">
          <el-input v-model="formDatal.nickName" placeholder="请输入昵称"/>
        </el-form-item>
        <el-form-item  label="店铺:" prop="shopId">
          <el-select v-model="formDatal.shopId" :disabled="true" placeholder="请选择店铺">
            <el-option v-for="(ym,index) in select" :key="index" :label="ym.name" :value="ym.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="账号:" prop="username">
          <el-input v-model="formDatal.username" :disabled="true" placeholder="请输入账号"/>
        </el-form-item>
        <el-form-item label="密码:" prop="password">
          <el-input v-model="formDatal.password" :disabled="true" placeholder="请输入密码"/>
        </el-form-item>
        <el-form-item label="手机号:" prop="phone">
          <el-input v-model="formDatal.phone" :disabled="true" placeholder="请输入手机号"/>
        </el-form-item>
        <el-form-item label="邮箱:" prop="email">
          <el-input v-model="formDatal.email" :disabled="true" placeholder="请输入邮箱"/>
        </el-form-item>
        <el-form-item label="头像:" prop="avatar">
          <div v-if="formDatal.shopId" class="aval">
            <el-upload
            ref="upload"
            :action="action"
            :before-upload="bfUpload"
            :class="{
              hide: hideUpload,
            }"
            :file-list="fileList"
            :headers="headers"
            :limit="limitCount"
            list-type="picture-card"
            :on-change="handleChange"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog append-to-body :visible.sync="dialogVisible">
            <img alt="" :src="dialogImageUrl" width="100%" />
          </el-dialog>
          </div>
          <div v-else class="txt" style="color: gray;">请选择店铺后上传</div>
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="clear">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { uploadURL } from '@/config'
import { getToken } from '@/utils/token'
import {getList} from '@/api/mall/shop/info'
import {putrow} from '@/api/mall/users/user'
export default {
  name:'Editdialog',
  props:{
    edithid:{
      type:Boolean,
      default:()=>{}
    },
    editrow:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
    return{
      action: uploadURL,
      fileList: [],
      headers: {},
      limitCount: 1,
      dialogVisibl:this.dishide,
      dialogVisibles:false,
      dialogVisible:false,
      formDatal:{},
      select:{},
      rules:{
        nickName: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
        ],
        shopId: [
          {required: true, message: '请选择店铺', trigger: 'change' },
        ],
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '长度在11个字符', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
        ],
        avatar: [
          { required: true, message: '请选择图片', trigger: 'blur' },
        ],
      },
      dialogImageUrl:'',
      hideUpload:'',
      addForm:{
        headers:""
      }
    }
  },

  computed: {
      ...mapGetters({
        token: 'user/token',
        tenantId : 'user/tenantId',
      }),
    },
    watch:{
    edithid(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisibl=newValue
      }
    },
    editrow(newValue,oldValue){
      if(newValue!=oldValue){
        this.formDatal=newValue
        this.fileList=[{name:'pic',url:newValue.avatar}]
      }
    },
  },
  created(){
    const db = getToken()
    this.headers['Authorization'] = `Bearer ` + `${db}`
    this.headers['Switch-Tenant-Id'] = `${this.tenantId}`
    this.getList()
  },
  methods:{
    handleClose(){
      this.$emit("closedit",false)
      this.dialogVisibles=false
    },
    getList(){
      getList({}).then(res=>{
        this.select=res.data
      })
    },
    bfUpload(file) {
      console.log(file)
      if (
        'image/png' == file.type ||
        'image/jpeg' == file.type ||
        'image/jpg' == file.type
      ) {
        this.addForm.headers = this.headers
      } else {
        this.ME('图片上传失败,请上传png,jpeg,jpg格式')
        return false
      }
    },
    handleChange(file, fileList) {
      this.hideUpload = fileList.length >= this.limitCount
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleRemove(fileList) {
      this.fileList = []
      this.hideUpload = fileList.length >= this.limitCount
    },
    handleSuccess(response) {
      this.formDatal.avatar=response.data.filePath
    },
    submit(){
      this.$refs.addform.validate((valid) => {
          if (valid) {
            this.shopuser()
          } else {
            return false;
          }
        });
    },
    shopuser(){
      putrow({...this.formDatal}).then(()=>{
        this.$emit('closedit',false)
        this.$message.success("编辑成功")
      })
    },
    clear(){
      this.dialogVisibles=false
      this.$emit('closedit',false)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header{
  border-bottom: 1px solid #edeaea;
}
::v-deep .el-form{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>