var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "link_dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "before-close": _vm.handleClose,
            title: "选择渠道",
            visible: _vm.dialogVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "cont" }, [
            _c(
              "div",
              { staticClass: "rig_cont" },
              _vm._l(_vm.data, function (ym, index) {
                return _c("div", { key: index, staticClass: "lis_goods" }, [
                  _c("div", { staticClass: "thead_title" }, [
                    _vm._v(_vm._s(ym.title)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "cont_card" },
                    _vm._l(ym.child_list, function (yms, num) {
                      return _c(
                        "div",
                        {
                          key: num,
                          class: _vm.rules(index, num),
                          on: {
                            click: function ($event) {
                              return _vm.checklist(yms, num, index)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(yms.title) + " ")]
                      )
                    }),
                    0
                  ),
                ])
              }),
              0
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }