<template>
  <div class="comprehensive-table-container">
    <div class="search" style="margin-bottom: 10px">
      <el-form class="demo-form-inline" :inline="true" :model="formInline">
        <el-form-item label="坐席工号">
          <el-input v-model.trim="formInline.profile" placeholder="请输入坐席工号" />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="onSubmit">搜索</el-button>
          <el-button icon="el-icon-refresh" type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-button style="margin-bottom:10px;" type="primary" @click="addClick">新增</el-button>
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column align="center" label="坐席id" prop="id" width="120" />
        <el-table-column align="center" label="坐席工号" prop="profile" />
        <el-table-column align="center" label="创建时间" prop="createTime" />
        <el-table-column align="center" label="更新时间" prop="updateTime" />
        <el-table-column align="center" fixed="right" label="操作" prop="rateValue">
          <template #default="scope">
            <el-button type="text" @click="editClick(scope.row)">编辑</el-button>
            <el-button :disabled="scope.row.status === 0 ? true : false" type="text"
              @click="recoveryClick(scope.row)">回收</el-button>
            <el-button :disabled="scope.row.status === 0 ? false : true" type="text"
              @click="distributionClick(scope.row)">分配</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background :current-page="1" layout="total, sizes, prev, pager, next, jumper"
      :page-size="formInline.size" :total="total" @current-change="handleCurrentChange"
      @size-change="handleSizeChange" />
    <el-dialog :visible.sync="dialogVisible">
      <el-table :data="gridData" height="500" @row-click="singleElection">
        <el-table-column align="center" label="请选择" width="80">
          <template slot-scope="scope">
            <!-- 可以手动的修改label的值，从而控制选择哪一项 -->
            <el-radio v-model="templateSelection" class="radio" :label="scope.row">{{ none }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column align="center" label="姓名" prop="realName" />
        <el-table-column align="center" label="手机号" prop="mobile" />
        <el-table-column align="center" label="工号" prop="profile" />
        <el-table-column align="center" label="账号" prop="username" />
        <el-table-column align="center" label="角色" prop="roleIdCn" />
        <el-table-column align="center" label="所属部门" prop="orgIdCn" />
        <el-table-column align="center" label="创建时间" prop="createTime" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleOk">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :before-close="handleClose" :title="addType ? '新增' : '编辑'" :visible.sync="AddVisible" width="30%">
      <el-form ref="ruleForm" class="demo-ruleForm" label-width="100px" :model="ruleForm" :rules="rules">
        <el-form-item label="坐席工号：" prop="profile">
          <el-input v-model="ruleForm.profile" />
        </el-form-item>
        <el-form-item>
          <el-button @click="resetForm('ruleForm')">取消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getPage, getRetrievingProfile, getStaffList, getDistributionProfile, saveComProfileInfo } from '@/api/mall/role/seatsManage'
export default {
  data() {
    return {
      dialogVisible: false,
      AddVisible: false,
      addType: false,
      dataSourceLists: null,
      templateSelection: "",
      userId: "",
      none: "",
      formInline: {
        size: 10,
        current: 1,
      },
      total: 0,
      tableData: [],
      showData: [],
      gridData: [], // 员工管理 数据
      distributionObject: {
        id: '',
        profile: "",
        userId: ""
      },
      ruleForm: {
        profile: '',
      },
      rules: {
        profile: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
      }
    }
  },
  watch: {
  },
  created() {
    this.getUserList()
  },
  methods: {
    // 新增
    addClick() {
      this.AddVisible = true
      this.addType = true
      this.ruleForm.id = ''
      this.ruleForm.profile = ''
      console.log(this.ruleForm, 'this,ruleForm')
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          saveComProfileInfo(this.ruleForm).then((res) => {
            this.$message({
              type: res.ok ? 'success' : 'error',
              message: res.msg
            });
            this.AddVisible = !res.ok
            this.reset()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.AddVisible = false
      this.getUserList()
    },
    showMore(row) {
      this.dialogVisible = true
      this.showData.push(row)
    },
    closeDialog() {
      this.dialogVisible = false;
      this.showData = []; // 清空showData数组
    },
    reset() {
      this.timer = []
      this.formInline = {}
      this.formInline.size = 10
      this.formInline.current = 1
      this.getUserList()
    },
    onSubmit() {
      this.formInline.current = 1
      this.getUserList()
    },
    handleCurrentChange(e) {
      this.formInline.current = e
      this.getUserList()
    },
    handleSizeChange(e) {
      this.formInline.size = e
      this.getUserList()
    },
    getUserList() {
      getPage({ ...this.formInline }).then((res) => {
        this.tableData = res.data.records
        this.formInline.size = res.data.size
        this.formInline.current = res.data.current
        this.total = res.data.total
      })
    },
    // 编辑
    editClick(row) {
      this.addType = false
      this.AddVisible = true
      this.ruleForm.id = row.id
      this.ruleForm.profile = row.profile
    },
    // 回收
    recoveryClick(e) {
      const data = {
        id: e.id,
        profile: e.profile
      }
      this.$confirm('确定要回收此条数据吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getRetrievingProfile(data).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.reset()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消回收'
        });
      });
    },
    distributionClick(e) {
      this.distributionObject.id = e.id
      this.distributionObject.profile = e.profile
      this.dialogVisible = true
      getStaffList({ status: 1 }).then((res) => {
        console.log(res, 'res')
        this.gridData = res.data
      })

    },
    // 表格单勾选
    singleElection(row) {
      console.log(row.id, 'row')
      this.distributionObject.userId = row.id
    },
    // 分配
    handleOk() {
      this.$confirm('确定要分配此条数据吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getDistributionProfile(this.distributionObject).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.dialogVisible = false
          this.reset()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消分配'
        });
      });
    }
  },
}
</script>

<style></style>