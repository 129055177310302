<template>
  <div class="ym_tk">
    <el-form :inline="true" :v-model="form">
      <el-form-item label="">
        <el-input
          v-model="form"
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          width="100%"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="ymtab"
      border
      :data="
        tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      "
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="配比名称" prop="subName" />
      <el-table-column label="占百分比" prop="subPrice" />
      <el-table-column label="克重" prop="subAmount" />
      <el-table-column label="创建时间" prop="createTime" />
    </el-table>
    <div class="block" style="margin-top: 15px">
      <el-pagination
        background
        :current-page="currentPage"
        layout="total, sizes,prev, pager, next,jumper"
        :page-size="pageSize"
        :total="tableData.length"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </div>
</template>

<script>
  import { queryPage } from '@/api/mall/health/planMatch/index'
  export default {
    props: {
      match: {
        type: Number,
        requried: true,
        default: () => {},
      },
    },
    data() {
      return {
        tableData: [],
        currentPage: 1,
        total: 20,
        pageSize: 3,
        date: [],
        from: '',
      }
    },
    watch: {
      match(oldValue, newValue) {
        if (oldValue != newValue) {
          this.date = []
          this.$refs.ymtab.clearSelection()
        } else {
          const s = this.date
          this.$refs.ymtab.clearSelection()
          if (s) {
            this.date = s
          }
        }
      },
    },
    created() {
      this.queryPage()
    },
    methods: {
      queryPage() {
        queryPage({}).then((res) => {
          console.log(res.data)
          this.tableData = res.data.records
          this.total = res.data.total
          this.pageSize = res.data.size
        })
      },
      handleSizeChange(val) {
        this.currentPage = 1
        this.pageSize = val
      },
      handleCurrentChange(val) {
        this.currentPage = val
      },
      save() {
        this.$emit('transfers', this.date)
      },
      handleSelectionChange(e) {
        this.date = {
          id: e.map((item) => item.id),
          subName: e.map((item) => item.subName),
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .ym_tk {
    width: 100%;
    .dialog-footer,
    .el-pagination {
      display: flex;
      justify-content: right;
      margin-bottom: 10px;
    }
  }
</style>
