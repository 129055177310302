var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt40 stepOne" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { margin: "0 auto", width: "60%" },
          attrs: { "label-width": "150px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动主题：", prop: "actName" } },
            [
              _c("el-input", {
                staticClass: "fromInput",
                attrs: { placeholder: "请输入活动主题" },
                model: {
                  value: _vm.form.actName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "actName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.actName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主题时间：", prop: "datePicker" } },
            [
              _c("el-date-picker", {
                attrs: {
                  align: "center",
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                on: { change: _vm.dateChange },
                model: {
                  value: _vm.form.datePicker,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "datePicker", $$v)
                  },
                  expression: "form.datePicker",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发布时间：", prop: "publishType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.publishType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "publishType", $$v)
                    },
                    expression: "form.publishType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("立即发布"),
                  ]),
                  _c("el-radio", { attrs: { label: "2" } }, [
                    _vm._v("定时发布"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.publishType == 2
            ? _c(
                "el-form-item",
                { attrs: { prop: "publishTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      align: "center",
                      placeholder: "选择日期时间",
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.form.publishTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "publishTime", $$v)
                      },
                      expression: "form.publishTime",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "参与平台：", prop: "actPlatform" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.actPlatform,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "actPlatform", $$v)
                    },
                    expression: "form.actPlatform",
                  },
                },
                _vm._l(_vm.actPlatformList, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item.dictKey, attrs: { label: item.dictKey } },
                    [_vm._v(" " + _vm._s(item.dictValue) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "themeLogo",
              attrs: { label: "主题封面：", prop: "coverPicture" },
            },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  class: {
                    hide: _vm.hideUpload,
                  },
                  attrs: {
                    action: _vm.action,
                    "before-upload": _vm.bfUpload,
                    "file-list": _vm.fileList,
                    headers: _vm.headers,
                    limit: _vm.limitCount,
                    "list-type": "picture-card",
                    "on-change": _vm.handleChange,
                    "on-preview": _vm.handlePictureCardPreview,
                    "on-remove": _vm.handleRemove,
                    "on-success": _vm.handleSuccess,
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
              _c(
                "el-dialog",
                {
                  attrs: { "append-to-body": "", visible: _vm.dialogVisible },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { alt: "", src: _vm.dialogImageUrl, width: "100%" },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "themeLogo",
              attrs: { label: "主题背景：", prop: "backgPicture" },
            },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  class: {
                    hide: _vm.hideUpload2,
                  },
                  attrs: {
                    action: _vm.action,
                    "before-upload": _vm.bfUpload,
                    "file-list": _vm.fileList2,
                    headers: _vm.headers,
                    limit: _vm.limitCount2,
                    "list-type": "picture-card",
                    "on-change": _vm.handleChange2,
                    "on-preview": _vm.handlePictureCardPreview2,
                    "on-remove": _vm.handleRemove2,
                    "on-success": _vm.handleSuccess2,
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
              _c(
                "el-dialog",
                {
                  attrs: { "append-to-body": "", visible: _vm.dialogVisible2 },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible2 = $event
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { alt: "", src: _vm.dialogImageUrl2, width: "100%" },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pay-button-group mt20" },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 下一步 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }