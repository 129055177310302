<template>
  <div class="containers">
    <div class="add-table">
      <el-button type="primary" @click="addshow = true">新增</el-button>
    </div>
    <el-table border :data="tableData" style="width: 100%">
      <el-table-column align="center" label="疾病名称" prop="name" />
      <el-table-column align="center" label="亲属关系" prop="fitType">
        <template #default="scope">
          {{ labty(scope.row.fitType) }}
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" prop="address">
        <template #default="scope">
          <el-button style="font-size: 14px" type="text" @click="edittab(scope.row)">编辑</el-button>
          <el-button style="font-size: 14px" type="text" @click="del(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="from.current"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="from.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <adddialog :show="addshow" :value="lis" @addshows="listinfo" />
    <editdialog :show="addshows" :value="editlist" @addshows="listinfoa" />
    <showdialog :show="showtab" :value="showlist" @addshows="shotabclose" />
  </div>
</template>

<script>
import { userFitnessInfoqueryPage, userFitnessInfodelete } from '@/api/mall/users/usermore'
import adddialog from './components/addialog.vue'
import editdialog from './components/editdialog.vue'
import showdialog from './components/showdialog.vue'

export default {
  components: {
    adddialog,
    editdialog,
    showdialog,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableData: [{}],
      total: null,
      from: {
        size: 10,
        current: 1,
        userId: null,
        fitStatus: 4,
      },
      lis: this.value,
      labType: [
        {
          id: 1,
          labelName: '配偶',
        },
        {
          id: 2,
          labelName: '儿子',
        },
        {
          id: 3,
          labelName: '女儿',
        },
        {
          id: 4,
          labelName: '孙子',
        },
        {
          id: 5,
          labelName: '孙女',
        },
        {
          id: 6,
          labelName: '外孙子',
        },
        {
          id: 7,
          labelName: '外孙女',
        },
        {
          id: 8,
          labelName: '父母',
        },
        {
          id: 9,
          labelName: '兄长',
        },
        {
          id: 10,
          labelName: '弟弟',
        },
        {
          id: 11,
          labelName: '姐姐',
        },
        {
          id: 12,
          labelName: '妹妹',
        },
        {
          id: 13,
          labelName: '其他',
        },
      ],
      addshow: false,
      addshows: false,
      editlist: null,
      showlist: null,
      showtab: false,
    }
  },
  computed: {
    labty() {
      return (item) => {
        const lis = this.labType.find((el) => el.id === item)
        if (lis) {
          return lis.labelName
        } else {
          return '错误类型'
        }
      }
    },
    Pic() {
      return (item) => {
        if (item) {
          return item[0]
        } else {
          return ''
        }
      }
    },
  },
  created() {
    this.from.userId = this.value.userId
    this.userFitnessInfoqueryPage()
  },
  methods: {
    shotabclose() {
      this.showtab = false
      this.userFitnessInfoqueryPage()
    },
    showhand(e) {
      this.showlist = e
      this.showtab = true
    },
    edittab(e) {
      this.editlist = e
      this.addshows = true
    },
    listinfoa() {
      this.addshows = false
      this.userFitnessInfoqueryPage()
    },
    listinfo() {
      this.addshow = false
      this.userFitnessInfoqueryPage()
    },
    del(e) {
      userFitnessInfodelete({ id: e }).then((res) => {
        if (res.status == 200) {
          this.$message.success('删除成功')
          this.userFitnessInfoqueryPage()
        }
      })
    },
    userFitnessInfoqueryPage() {
      userFitnessInfoqueryPage({ ...this.from }).then((res) => {
        this.tableData = res.data.records
        this.from.size = res.data.size
        this.from.current = res.data.current
        this.total = res.data.total
      })
    },
    handleCurrentChange(e) {
      this.from.current = e
      this.userFitnessInfoqueryPage()
    },
    handleSizeChange(e) {
      this.from.size = e
      this.userFitnessInfoqueryPage()
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  .add-table {
    margin-bottom: 15px;
  }
}
</style>