<template>
  <div class="container">
    <searchform :hh="getid" @receive="receiverow" />
    <el-table border :data="tablist" max-height="500">
      <el-table-column align="center" label="序号" type="index" width="55" />
      <el-table-column align="center" label="订单编号" prop="orderNo" width="190">
        <template #default="scope">
          <el-button type="text" @click="orderNoClick(scope.row)">
            {{ scope.row.orderNo }}
          </el-button>
        </template>
      </el-table-column>

      <el-table-column align="center" label="下单用户" prop="uusrUserName" width="140" />
      <el-table-column align="center" label="订单金额" prop="paymentPrice" width="100" />
      <el-table-column align="center" label="下单员工" prop="profile" show-overflow-tooltip width="140">
        <template #default="scope">{{ scope.row.realName }}{{ scope.row.profile == '' ? '' : '(' + scope.row.profile +
          ')' }}</template>
      </el-table-column>
      <!-- <el-table-column align="center" label="状态" prop="statusDesc" show-overflow-tooltip width="80" /> -->

      <el-table-column align="center" label="所购产品" prop="product" show-overflow-tooltip />
      <el-table-column align="center" label="下单地址" prop="address" show-overflow-tooltip />
      <!-- 状态 1-待质检 2-已通过 3-驳回 -->
      <!-- <el-table-column align="center" label="质检审核" prop="quaStateDesc" width="80">
        <template #default="{ row }">
          <div>
          <el-tag v-if="row.quaState == 1" type="succes">待质检</el-tag>
          <el-tag v-if="row.quaState == 2" type="danger">已通过</el-tag>
          <el-tag v-if="row.quaState == 3" type="info">已驳回</el-tag>

          </div>
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="订单状态" width="80">
        <template #default="{ row }">
          <span v-if="row.fuseState == 0">待质检</span>
          <span v-if="row.fuseState == 1">待发货</span>
          <span v-if="row.fuseState == 2">质检驳回</span>
          <span v-if="row.fuseState == 3">待收货</span>
          <span v-if="row.fuseState == 4">已完成</span>
          <span v-if="row.fuseState == 5">待退款</span>
          <span v-if="row.fuseState == 6">已退款</span>
          <span v-if="row.fuseState == 7">退款驳回</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="质检信息" prop="quaContent" show-overflow-tooltip width="80" />
      <el-table-column align="center" label="预付金额" prop="presAmount" width="80" />
      <el-table-column align="center" label="订单日期" prop="createTime" width="200" />
      <el-table-column align="center" label="收货时间" prop="receiverTime" width="200" />
    </el-table>
    <el-pagination background :current-page="1" layout="total, prev, pager, next, jumper" :total="total"
      @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <shwotables :recordedshow="shwohidetab" :sound="recorduserinfo" @closeshowtab="hidelog" />
  </div>
</template>

<script>
import { orderrecord } from '@/api/mall/health/casemy/index'
import searchform from './components/search.vue'
import shwotables from './components/showtable/index.vue'
export default {
  components: {
    searchform, shwotables
  },
  props: {

  },
  data() {
    return {
      total: 0,
      tablist: [],
      showlist: false,
      showtblist: null,
      recorduserinfo: null, //录音选中人信息
      shwohidetab: false, //查看
      form: {
        size: 10,
        current: 1,
        sysId: '',
      },
      getid: '',

    }
  },
  watch: {
    sound(newValue, oldValue) {
      if (newValue != oldValue) {
        this.form.recordId = newValue.id
      }
    },
  },
  created() {
    // console.log(this.sound)
    const userId = localStorage.getItem('userId');
    console.log('本地存儲的 userId:', userId);

    this.form.sysId = userId;
    this.getid = userId
    console.log(this.getid, 'this.getid');
    this.uuUserSugarRecordLog()

  },
  methods: {
    //查看关闭
    hidelog() {
      this.shwohidetab = false
    },
    // 订单编号查看
    orderNoClick(row) {
      console.log(row, '1234')
      this.recorduserinfo = row
      console.log(this.recorduserinfo, 'this.recorduserinfo666')
      if (row.orderId !== null && row.qualityId !== null) {
        this.shwohidetab = true
      } else {
        this.$message.error('没有关联订单')
      }
    },
    closeshow() {
      this.showlist = false
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.uuUserSugarRecordLog()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.uuUserSugarRecordLog()
    },
    uuUserSugarRecordLog() {
      orderrecord({ ...this.form }).then((res) => {
        this.total = res.data.total
        this.form.size = res.data.size
        this.form.current = res.data.current
        this.tablist = res.data.records
      })
    },
    receiverow(row) {
      if (row != false) {
        this.tablist = row.records
        this.total = row.total
      } else {
        this.list()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title-input {
  width: 200px;
  margin-left: 5px;
}

.title-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  margin-right: 10px;
}

.title-item:last-of-type {
  margin-left: 10px;
}

.title-item span {
  margin-right: 10px;
}
</style>
