var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-badge",
        {
          attrs: { value: _vm.errorLogs.length },
          nativeOn: {
            click: function ($event) {
              _vm.dialogTableVisible = true
            },
          },
        },
        [_c("vab-icon", { attrs: { icon: "chat-3-line" } })],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "当前未处理信息",
            visible: _vm.dialogTableVisible,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogTableVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-table",
            { attrs: { border: "", data: _vm.errorLogs, height: "250" } },
            [
              _c("el-table-column", {
                attrs: { label: "用户姓名", prop: "userName" },
              }),
              _c("el-table-column", {
                attrs: { label: "信息内容", prop: "content" },
              }),
              _c("el-table-column", {
                attrs: { label: "时间", prop: "vistTime" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }