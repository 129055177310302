<template>
  <div class="container">
    <div class="lef_echarts">
      <div class="echarts-item"></div>
    </div>
    <div class="ref_echarts"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      echartData: [
        {
          value: '平均收缩压',
          unit: '/mmhg',
          props: '',
        },
        {
          unit: '/mmhg',
          value: '最高收缩压',
          props: '',
        },
        {
          value: '最低收缩压',
          unit: '/mmhg',
          props: '',
        },
        {
          value: '平均舒张压',
          unit: '/mmhg',
          props: '',
        },
        {
          value: '最高舒张压',
          unit: '/mmhg',
          props: '',
        },
        {
          value: '最低舒张压',
          unit: '/mmhg',
          props: '',
        },
      ],
      charst: {
        value: 68,
        value1: 68,
        value2: 68,
        value3: 68,
        value4: 68,
      },
    }
  },
}
</script>

<style>
</style>