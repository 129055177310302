import Quill from 'quill'
let Parchment = Quill.import('parchment')
console.log('Parchment', Parchment)
// 字符间距
class letterSpacingAttributor extends Parchment.Attributor.Style {}
const letterSpacingStyle = new letterSpacingAttributor(
  'letter-spacing',
  'letterSpacing',
  {
    scope: Parchment.Scope.INLINE,
    whitelist: [
      'initial',
      '2px',
      '4px',
      '6px',
      '8px',
      '10px',
      '12px',
      '14px',
      '16px',
    ],
  }
)
export { letterSpacingStyle }
// const config = {
//   scope: Parchment.Scope.INLINE,
//   whitelist: [
//     'initial',
//     '2px',
//     '4px',
//     '6px',
//     '8px',
//     '10px',
//     '12px',
//     '14px',
//     '16px',
//   ],
// }

// class letterSpacingAttributor extends Parchment.Attributor.Style {
//   value(node) {
//     return super.value(node).replace(/["']/g, '')
//   }
// }

// const letterSpacing = new letterSpacingAttributor(
//   'letterSpacing',
//   'letter-spacing',
//   config
// )

// export default letterSpacing
