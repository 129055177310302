var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ym_container" },
    [
      _c(
        "el-form",
        {
          ref: "basicform",
          attrs: { model: _vm.actionscomponents, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "销售价(元)" } },
            [
              _c("el-input-number", {
                attrs: { label: "描述文字", min: 0 },
                model: {
                  value: _vm.actionscomponents.salesPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.actionscomponents, "salesPrice", $$v)
                  },
                  expression: "actionscomponents.salesPrice",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "市场价(元)" } },
            [
              _c("el-input-number", {
                attrs: { label: "描述文字", min: 1 },
                model: {
                  value: _vm.actionscomponents.marketPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.actionscomponents, "marketPrice", $$v)
                  },
                  expression: "actionscomponents.marketPrice",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "成本价(元)" } },
            [
              _c("el-input-number", {
                attrs: { label: "描述文字", min: 1 },
                model: {
                  value: _vm.actionscomponents.costPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.actionscomponents, "costPrice", $$v)
                  },
                  expression: "actionscomponents.costPrice",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "库存" } },
            [
              _c("el-input-number", {
                attrs: { label: "描述文字", min: 1 },
                model: {
                  value: _vm.actionscomponents.stock,
                  callback: function ($$v) {
                    _vm.$set(_vm.actionscomponents, "stock", $$v)
                  },
                  expression: "actionscomponents.stock",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "重量(Kg)" } },
            [
              _c("el-input-number", {
                attrs: { label: "描述文字", min: 1 },
                model: {
                  value: _vm.actionscomponents.weight,
                  callback: function ($$v) {
                    _vm.$set(_vm.actionscomponents, "weight", $$v)
                  },
                  expression: "actionscomponents.weight",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "体积(m²)" } },
            [
              _c("el-input-number", {
                attrs: { label: "描述文字", min: 1 },
                model: {
                  value: _vm.actionscomponents.volume,
                  callback: function ($$v) {
                    _vm.$set(_vm.actionscomponents, "volume", $$v)
                  },
                  expression: "actionscomponents.volume",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }