<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape	="false"
    :title="title"
    :visible.sync="dialogVisible"
    width="60%"
    @close="close"
    >
    <span>
      <div class="ym_btn" style="margin-bottom: 20px;">
        <el-button type="primary" @click="newaddte">新增</el-button>
        <el-dialog
          append-to-body
          title="新增"
          :visible.sync="addnew"
          width="30%">
          <span>
            <el-form ref="form" :model="newadd">
              <el-form-item label="名称">
                <el-input v-model="newadd.name"/>
              </el-form-item>
            </el-form>
          </span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addnew = false">取 消</el-button>
            <el-button type="primary" @click="submi">确 定</el-button>
          </span>
        </el-dialog>
      </div>
      <el-table border :data="data">
      <el-table-column align="center" label="名称" prop="name"/>
      <el-table-column align="center" label="创建时间" prop="createTime" />
      <el-table-column align="center" label="更新时间" prop="updateTime" />
      <el-table-column align="center" label="操作" prop="id">
        <template slot-scope="scope">
          <el-link style="margin: 0 10px;" type="primary" :underline="false" @click="edita(scope.row)">编辑</el-link>
          <el-link style="margin: 0 10px;" type="primary" :underline="false" @click="del(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      />
    </div>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="cancel">确 定</el-button>
    </span>
    <el-dialog
      append-to-body
      title="编辑"
      :visible.sync="editashow"
      width="30%">
      <span>
        <el-form ref="form" :model="form">
          <el-form-item label="名称">
            <el-input v-model="form.name"/>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editashow = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {getlistmore,updateput,deleteobj,addnewobj} from "@/api/mall/goods/goodsSpecs"
export default {
  props:{
    hide:{
      type:Boolean,
      default:()=>{}
    },
    dat:{
      type:String,
      default:()=>{}
    },
    cheknum:{
      type:String,
      default:()=>{}
    }
  },
  data() {
      return {
        date:this.dat,
        dialogVisible: this.hide,
        currentPage2: 5,
        editashow:false,
        addnew:false,
        data:[],
        size:10,
        total:0,
        current:1,
        form:{
          id:'',
          name:''
        },
        newadd:{
          name:''
        },
        pleId:this.cheknum,
        title:'规格值列表，所属规格：'+this.dat
      };
    },
  watch:{
    hide(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisible=newValue
      }
    },
    dat(newValue,oldValue){
      if(newValue!=oldValue){
        this.title='规格值列表，所属规格：'+newValue
      }
    },
    cheknum(newValue,oldValue){
      if(newValue!=oldValue){
        this.pleId=newValue
        this.getlistmore(this.size,this.current,this.pleId)
      }
    }
  },
  created(){
    this.getlistmore(this.size,this.current,this.pleId)
  },
  methods: {
    getlistmore(size,cur,datId){
      const s={
        size:size,
        current:cur,
        specId:datId
      }
      getlistmore({...s}).then(res=>{
        this.data=res.data.records
        this.total=res.data.total
        this.size=res.data.size
        this.current=res.data.current
      })
    },
    cancel(){
      this.$emit('transhow',false)
    },
    close(){
      this.$emit('transhow',false)
    },
    handleSizeChange(e){
      this.size=e
      this.getlistmore(this.size,this.current,this.pleId)
    },
    handleCurrentChange(e){
      this.current=e
      this.getlistmore(this.size,this.current,this.pleId)
    },
    edita(e){
      this.form.id=e.id
      this.form.name=e.name
      this.editashow=true
    },
    save(){
      const put={
        id:this.form.id,
        name:this.form.name,
        specId:this.pleId,
      }
      console.log(put)
      updateput({...put}).then(()=>{
        this.editashow=false
        this.$message.success('修改成功')
      })
    },
    del(e){
      const id={
        id:e
      }
      deleteobj({...id}).then(()=>{
        this.$message.success('删除成功')
        this.getlistmore(this.size,this.current,this.pleId)
      })
    },
    newaddte(){
      this.addnew=true,
      this.newadd.name=""
    },
    submi(){
      addnewobj({name:this.newadd.name,specId:this.pleId}).then(()=>{
        this.getlistmore(this.size,this.current,this.pleId)
        this.$message.success('新增成功')
        this.addnew=false
      })
    }
  }
}
</script>

<style>

</style>