import request from '@/utils/request'
// 按钮列表
export function buttonQueryPage(data) {
  return request({
    url: '/upms/sys/comSysButton/queryPage',
    method: 'post',
    data,
  })
}
// 新增
export function buttonAdd(data) {
  return request({
    url: '/upms/sys/comSysButton/add',
    method: 'post',
    data,
  })
}
// 编辑
export function buttonEdit(data) {
  return request({
    url: '/upms/sys/comSysButton/edit',
    method: 'post',
    data,
  })
}
// 删除
export function buttonDelete(data) {
  return request({
    url: '/upms/sys/comSysButton/isDelete',
    method: 'post',
    data,
  })
}
// 查询已授权按钮
export function selectMenuButtonId(data) {
  return request({
    url: '/upms/sys/comSysRoleButton/selectRoleButtonId',
    method: 'post',
    data,
  })
}
