/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/vab/layouts'
import { publicPath, routerMode } from '@/config'

Vue.use(VueRouter)
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    meta: {
      hidden: true,
    },
  },
]

export const asyncRoutes = [
  {
    path: '/',
    name: 'Root',
    component: Layout,
    meta: {
      title: '首页',
      icon: 'home-2-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        // component: () => import('@/views/index'),
        component: () => import('@/views/mall/health/boardPerforMc/index'),
        meta: {
          title: '首页',
          icon: 'home-2-line',
          noClosable: true,
          noColumn: true,
        },
      },
    ],
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Layout,
    meta: {
      title: '店铺',
      icon: 'code-box-line',
    },

    children: [
      {
        id: 27,
        path: '/shopStore',
        name: 'ShopStore',
        component: 'Layout',
        meta: {
          id: 27,
          title: '店铺管理',
          icon: 'file-list-3-line',
        },
        children: [
          {
            path: 'info',
            name: 'Info',
            component: () => import('@/views/mall/shop/info'),
            meta: {
              title: '店铺设置',
            },
          },
          {
            path: 'shopuser',
            name: 'Shopuser',
            component: () => import('@/views/mall/shop/user'),
            meta: {
              title: '店员管理',
            },
          },
          {
            path: 'shopapply',
            name: 'Shopapply',
            component: () => import('@/views/mall/shop/apply'),
            meta: {
              title: '店铺入驻',
            },
          },
          {
            path: 'shopapplyAdd',
            name: 'ShopapplyAdd',
            component: () => import('@/views/mall/shop/operation/shopapplyAdd'),
            meta: {
              title: '入驻申请',
              hidden: true,
            },
          },
          {
            path: 'shopCategory',
            name: 'ShopCategory',
            component: () => import('@/views/mall/shop/shopCategory/index'),
            meta: {
              title: '店铺分类',
            },
          },
        ],
      },
      {
        id: 27,
        path: '/template',
        name: 'Template',
        component: 'Layout',
        meta: {
          id: 27,
          title: '装修管理',
          icon: 'file-list-3-line',
        },
        children: [
          {
            path: 'templateset',
            name: 'Templateset',
            component: () => import('@/views/mall/shop/template/templateset'),
            meta: {
              title: '模板管理',
              hidden: true,
            },
          },
          {
            path: 'fitment',
            name: 'Fitment',
            component: () => import('@/views/mall/fitment/fitment'),
            meta: {
              title: '装修中心',
              hidden: true,
            },
          },
          {
            path: 'preview',
            name: 'Preview',
            component: () => import('@/views/mall/preview/'),
            meta: {
              title: '页面管理',
              hidden: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/mall',
    name: 'Mall',
    component: Layout,
    meta: {
      title: '商品',
      icon: 'apps-line',
    },
    children: [
      {
        id: 27,
        path: '/goods',
        name: 'Goods',
        component: 'Layout',
        meta: {
          id: 27,
          title: '商品管理',
          icon: 'file-list-3-line',
        },
        children: [
          {
            path: 'goodsspu',
            name: 'Goodsspu',
            component: () => import('@/views/mall/goods/goodsspu'),
            meta: {
              title: '商品管理',
            },
          },
          {
            path: 'goodsspec',
            name: 'Goodsspec',
            component: () => import('@/views/mall/goods/goodsspec'),
            meta: {
              title: '规格管理',
            },
          },
          {
            path: 'goodscategory',
            name: 'Goodscategory',
            component: () => import('@/views/mall/goods/goodscategory'),
            meta: {
              title: '商品类目',
            },
          },
          {
            path: 'goodscategoryshop',
            name: 'Goodscategoryshop',
            component: () => import('@/views/mall/goods/goodscategoryshop'),
            meta: {
              title: '商品分类',
            },
          },
          {
            path: 'goodsappraises',
            name: 'Goodsappraises',
            component: () => import('@/views/mall/goods/goodsappraises'),
            meta: {
              title: '商品评价',
            },
          },
          {
            path: 'goodsspuAdd',
            name: 'GoodsspuAdd',
            component: () => import('@/views/mall/goods/operation/goodsspuAdd'),
            meta: {
              title: '商品添加',
              hidden: true,
            },
          },
          {
            path: 'goodsspuEdit',
            name: 'GoodsspuEdit',
            component: () => import('@/views/mall/goods/operationedit/goodsspuEdit'),
            meta: {
              title: '商品编辑',
              hidden: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/order',
    name: 'Order',
    component: Layout,
    meta: {
      title: '订单',
      icon: 'delete-column',
    },
    children: [
      {
        id: 27,
        path: '/orderManage',
        name: 'OrderManage',
        component: 'Layout',
        meta: {
          id: 27,
          title: '订单管理',
          icon: 'file-list-3-line',
        },
        children: [
          {
            id: 33,
            path: '/orderList',
            name: 'OrderList',
            component: () => import('@/views/mall/order/orderList/orderList'),
            meta: {
              id: 33,
              title: '普通订单',
            },
          },
          {
            id: 33,
            path: '/orderrefunds',
            name: 'Orderrefunds',
            component: () => import('@/views/mall/order/orderrefunds/index'),
            meta: {
              id: 33,
              title: '退款订单',
            },
          },
          {
            id: 30,
            path: '/orderDetail',
            name: 'OrderDetail',
            component: () => import('@/views/mall/order/orderList/orderDetail'),
            meta: {
              id: 30,
              title: '订单详情',
              hidden: true,
            },
          },
          {
            id: 34,
            path: '/orderGoods',
            name: 'OrderStockList',
            component: () => import('@/views/mall/order/orderList/orderList'),
            meta: {
              id: 34,
              title: '积分订单',
            },
          },
        ],
      },

      {
        id: 27,
        path: '/orderBoluo',
        name: 'OrderBoluo',
        component: 'Layout',
        meta: {
          id: 27,
          title: '菠萝管理',
          icon: 'file-list-3-line',
        },
        children: [
          {
            id: 33,
            path: '/orderBoluoList',
            name: 'OrderBoluoList',
            component: () => import('@/views/mall/order/orderBoluoList/index'),
            meta: {
              id: 33,
              title: '菠萝订单',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/user',
    name: 'User',
    component: Layout,
    meta: {
      title: '会员',
      icon: 'error-warning-line',
    },
    children: [
      {
        path: 'drag',
        name: 'Drag',
        component: () => import('@/views/mall/permission/permission'),
        meta: {
          title: '会员管理',
          icon: 'account-circle-line',
        },
        children: [
          {
            path: 'tabs',
            name: 'Tabs',
            component: () => import('@/views/mall/user/userList'),
            meta: {
              id: 39,
              title: '会员列表',
            },
          },
          {
            path: 'userLevel',
            name: 'UserLevel',
            component: () => import('@/views/mall/user/userLevel/index'),
            meta: {
              id: 39,
              title: '会员等级',
            },
          },
          {
            id: 41,
            path: '/user/detail',
            name: 'UserDetail',
            component: () => import('@/views/mall/user/userDetail'),
            meta: {
              id: 41,
              title: '用户详情',
              hidden: true,
            },
          },
          {
            id: 41,
            path: '/user/details',
            name: 'UserDetails',
            component: () => import('@/views/mall/user/userDetails'),
            meta: {
              id: 41,
              title: '用户详情',
              hidden: true,
            },
          },
          {
            path: 'userfootprint',
            name: 'Userfootprint',
            component: () => import('@/views/mall/user/userfootprint/index'),
            meta: {
              title: '会员足迹',
            },
          },
          {
            path: 'dynamicSegment',
            name: 'DynamicSegment',
            component: () => import('@/views/mall/permission/permission'),
            meta: {
              title: '商品收藏',
            },
          },
          {
            path: 'drag',
            name: 'Drag',
            component: () => import('@/views/mall/permission/permission'),
            meta: {
              title: '店铺收藏',
            },
          },
          {
            path: 'userDiet',
            name: 'UserDiet',
            component: () => import('@/views/mall/health/userdiet/index'),
            meta: {
              title: '会员计划',
            },
          },
          {
            path: 'registration',
            name: 'Registration',
            component: () => import('@/views/mall/user/registration/index'),
            meta: {
              title: '代客注册',
            },
          },
          {
            path: 'userTag',
            name: 'UserTag',
            component: () => import('@/views/mall/user/userTag/index'),
            meta: {
              title: '会员标签',
            },
          },
        ],
      },
      {
        path: 'distribution',
        name: 'Distribution',
        meta: {
          title: '分销管理',
          icon: 'account-circle-line',
        },
        children: [
          {
            path: 'distributionconfig',
            name: 'Distributionconfig',
            component: () => import('@/views/mall/distribution/distributionconfig/index'),
            meta: {
              id: 39,
              title: '分销设置',
            },
          },
          {
            path: 'distributionuser',
            name: 'Distributionuser',
            component: () => import('@/views/mall/distribution/distributionuser/index'),
            meta: {
              id: 39,
              title: '分销员管理',
            },
          },
          {
            id: 41,
            path: '/distribution/distribution',
            name: 'DistriBution',
            component: () => import('@/views/mall/distribution/userDetail'),
            meta: {
              id: 41,
              title: '分销员详情',
              hidden: true,
            },
          },
        ],
      },
      {
        path: '/healthRecord',
        name: 'HealthRecord',
        meta: {
          title: '会员档案',
          icon: 'account-circle-line',
        },
        children: [
          {
            path: 'healthRecordArchive',
            name: 'HealthRecordArchive',
            component: () => import('@/views/mall/health/healthRecordArchive/index'),
            meta: {
              id: 39,
              title: '健康档案',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/information',
    name: 'Information',
    component: Layout,
    meta: {
      title: '数据',
      icon: 'group-line',
    },
    children: [
      {
        path: 'goodData',
        name: 'GoodData',
        meta: {
          title: '商品数据',
          icon: 'remixicon-line',
        },
        children: [
          {
            path: 'gooRranking',
            name: 'GooRranking',
            component: () => import('@/views/mall/permission/permission'),
            meta: {
              title: '商品排行',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/marketing',
    name: 'Marketing',
    component: Layout,
    meta: {
      title: '营销',
      icon: 'menu-2-fill',
    },
    children: [
      {
        path: '/activity',
        name: 'Activity',
        meta: {
          title: '活动中心',
          icon: 'auction-line',
        },
        children: [
          {
            path: '/theme-list',
            component: () => import('@/views/mall/activity/theme-list'),
            name: 'ThemeList',
            meta: {
              title: '主题列表',
            },
          },
          {
            path: '/theme-add',
            component: () => import('@/views/mall/activity/theme-list/theme-add'),
            name: 'ThemeAdd',
            meta: {
              title: '添加主题',
            },
          },
          {
            path: '/theme-detail',
            component: () => import('@/views/mall/activity/theme-list/theme-detail'),
            name: 'ThemeDetail',
            meta: {
              title: '主题详情',
            },
          },
          {
            path: '/activity-list',
            component: () => import('@/views/mall/activity/activity-list'),
            name: 'ActivityList',
            meta: {
              title: '活动列表',
            },
          },
          {
            path: '/activity/add',
            component: () => import('@/views/mall/activity/activity-add'),
            name: 'ActivityAdd',
            meta: {
              title: '添加活动',
            },
          },
          {
            path: '/activity/indiana/list',
            component: () => import('@/views/mall/activity/indiana-list'),
            name: 'IndianaList',
            meta: {
              title: '夺宝列表',
            },
          },
          {
            path: '/activity/indiana/detail',
            component: () => import('@/views/mall/activity/indiana-detail'),
            name: 'IndianaDetail',
            meta: {
              title: '查看实例',
            },
          },
          {
            path: '/activity/indiana/add',
            component: () => import('@/views/mall/activity/indiana-add'),
            name: 'IndianaAdd',
            meta: {
              title: '创建/编辑实例',
            },
          },
          {
            path: '/activity/indiana/add/success',
            component: () => import('@/views/mall/activity/create-res'),
            name: 'CreateRes',
            meta: {
              title: '创建成功',
            },
          },
          {
            path: '/activity/lucky/list',
            component: () => import('@/views/mall/activity/lucky-record'),
            name: 'LuckyList',
            meta: {
              title: '抽奖记录',
            },
          },
          {
            path: '/activity/lucky/detail',
            component: () => import('@/views/mall/activity/lucky-detail'),
            name: 'LuckyDetail',
            meta: {
              title: '抽奖详情',
            },
          },
          {
            path: '/activity/box-list/list',
            component: () => import('@/views/mall/activity/box-list'),
            name: 'BoxList',
            meta: {
              title: '盲盒列表',
            },
          },

          {
            path: '/activity/openbox-record/record',
            component: () => import('@/views/mall/activity/openbox-record'),
            name: 'BoxDetail',
            meta: {
              title: '开盒记录',
            },
          },
          {
            path: '/activity/boxjoin-detail/detail',
            component: () => import('@/views/mall/activity/boxjoin-detail'),
            name: 'BoxjoinDetail',
            meta: {
              title: '参与明细',
            },
          },
          {
            path: '/activity/activity-customers/customers',
            component: () => import('@/views/mall/activity/activity-customers'),
            name: 'Customers',
            meta: {
              title: '代客下单',
            },
          },
          {
            path: '/activity/confirm-order/confirm',
            component: () => import('@/views/mall/activity/confirm-order'),
            name: 'Confirm',
            meta: {
              title: '确认订单',
            },
          },
        ],
      },
      {
        path: 'points',
        name: 'Points',
        meta: {
          title: '积分管理',
          icon: 'remixicon-line',
        },
        children: [
          {
            path: 'pointsConfig',
            name: 'PointsConfig',
            component: () => import('@/views/mall/points/pointsConfig'),
            meta: {
              title: '积分设置',
            },
          },
          {
            path: 'pointsRecord',
            name: 'PointsRecord',
            component: () => import('@/views/mall/points/pointsRecord'),
            meta: {
              title: '积分明细',
            },
          },
          {
            path: 'signConfig',
            name: 'SignConfig',
            component: () => import('@/views/mall/points/signConfig'),
            meta: {
              title: '签到设置',
            },
          },
          {
            path: 'signRecord',
            name: 'SignRecord',
            component: () => import('@/views/mall/points/signRecord'),
            meta: {
              title: '签到统计',
            },
          },
        ],
      },
      {
        path: '/electronic',
        name: 'Electronic ',
        meta: {
          title: '电子券管理',
          icon: 'remixicon-line',
        },
        children: [
          {
            path: 'ElectronicMaking',
            name: 'ElectronicMaking',
            component: () => import('@/views/mall/marketing/electronicmaking'),
            meta: {
              title: '电子券制作',
            },
          },
          {
            path: 'CouponRecord',
            name: 'CouponRecord',
            component: () => import('@/views/mall/marketing/couponcecord'),
            meta: {
              title: '领券记录',
            },
          },
        ],
      },
      {
        path: '/bargain',
        name: 'Bargain ',
        meta: {
          title: '砍价管理',
          icon: 'remixicon-line',
        },
        children: [
          {
            path: 'BargainArrangement',
            name: 'BargainArrangement',
            component: () => import('@/views/mall/bargain/bargainarrangement'),
            meta: {
              title: '砍价配置',
            },
          },
          {
            path: 'BargainRecord',
            name: 'BargainRecord',
            component: () => import('@/views/mall/bargain/bargainrecord'),
            meta: {
              title: '砍价记录',
            },
          },
        ],
      },
      {
        path: '/groupbooking',
        name: 'GroupBooking ',
        meta: {
          title: '拼团管理',
          icon: 'remixicon-line',
        },
        children: [
          {
            path: 'GroupConfiguration',
            name: 'GroupConfiguration',
            component: () => import('@/views/mall/groupbooking/groupconfiguration'),
            meta: {
              title: '拼团配置',
            },
          },
          {
            path: 'GroupingRecord',
            name: 'GroupingRecord',
            component: () => import('@/views/mall/groupbooking/groupingrecord'),
            meta: {
              title: '拼团记录',
            },
          },
        ],
      },
      {
        path: '/killcontrol',
        name: 'KillControl ',
        meta: {
          title: '秒杀管理',
          icon: 'remixicon-line',
        },
        children: [
          {
            path: 'InstantGoods',
            name: 'InstantGoods',
            component: () => import('@/views/mall/killcontrol/instantgoods'),
            meta: {
              title: '秒杀商品',
            },
          },
          {
            path: 'KillTheVenue',
            name: 'KillTheVenue',
            component: () => import('@/views/mall/killcontrol/killthevenue'),
            meta: {
              title: '秒杀会场',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/telemark',
    name: 'Telemark',
    component: Layout,
    meta: {
      title: '电销',
      icon: 'archive-line',
    },
    children: [
      {
        path: 'seas',
        name: 'Seas',
        meta: {
          title: '客户公海',
          icon: 'remixicon-line',
        },
        children: [
          {
            path: 'boardPerforMc',
            name: 'BoardPerforMc',
            component: () => import('@/views/mall/health/boardPerforMc/index'),
            meta: {
              title: '业绩看板',
            },
          },
          {
            path: 'seasManger',
            name: 'SeasManger',
            component: () => import('@/views/mall/health/openSea/seasManger'),
            meta: {
              title: '公海管理',
            },
          },
          {
            path: 'openSeaImport',
            name: 'OpenSeaImport',
            component: () => import('@/views/mall/health/openSeaImport/openSeaImport'),
            meta: {
              title: '客户导入',
            },
          },
          {
            path: 'openSeaPooledData',
            name: 'OpenSeaPooledData',
            component: () => import('@/views/mall/health/openSeaPooledData/index'),
            meta: {
              title: '合并数据',
            },
          },
          {
            path: 'stpLabel',
            name: 'StpLabel',
            component: () => import('@/views/mall/setting/stpLabel/index'),
            meta: {
              title: '标签管理',
            },
          },
          {
            path: 'openSeaEmpStatistics',
            name: 'OpenSeaEmpStatistics',
            component: () => import('@/views/mall/health/openSeaEmpStatistics/index'),
            meta: {
              title: '员工统计',
            },
          },
        ],
      },
      {
        path: 'eleData',
        name: 'EleData',
        meta: {
          title: '电销数据',
          icon: 'remixicon-line',
        },
        children: [
          {
            path: 'eleboardPerforMc',
            name: 'EleboardPerforMc',
            component: () => import('@/views/mall/health/boardPerforMc/index'),
            meta: {
              title: '运营看板',
            },
          },
          {
            path: 'eleOpenSeaEmpStatistics',
            name: 'EleOpenSeaEmpStatistics',
            component: () => import('@/views/mall/health/openSeaEmpStatistics/index'),
            meta: {
              title: '员工统计',
            },
          },
        ],
      },
      {
        path: 'call',
        name: 'Call',
        meta: {
          title: '客服中心',
          icon: 'remixicon-line',
        },
        children: [
          {
            path: 'boardOperational',
            name: 'BoardOperational',
            component: () => import('@/views/mall/health/boardOperational/index'),
            meta: {
              title: '运营看板',
            },
          },
          {
            path: 'openSeaDepart',
            name: 'OpenSeaDepart',
            component: () => import('@/views/mall/health/openSeaDepart/openSeaDepart'),
            meta: {
              title: '部门公海',
            },
          },
          {
            path: 'openSeaClient',
            name: 'OpenSeaClient',
            // component: () => import('@/views/mall/health/customerMy/myClient'),
            component: () => import('@/views/mall/health/openSeaClient/openSeaClient'),
            meta: {
              title: '我的客户',
            },
          },
          {
            path: 'returnvisitlist',
            name: 'Returnvisitlist',
            component: () => import('@/views/mall/health/returnvisitlist/index.vue'),
            meta: {
              title: '回访列表',
            },
          },
          {
            path: 'editTab',
            name: 'EditTab',
            component: () => import('@/views/mall/health/customerMy/edittab/'),
            meta: {
              title: '客户详细',
            },
          },
          {
            path: 'riskPool',
            name: 'RiskPool',
            component: () => import('@/views/mall/health/customerRiskPool/index'),
            meta: {
              title: '风险池',
            },
          },
          {
            path: 'followUp',
            name: 'FollowUp',
            component: () => import('@/views/mall/health/customerFollowUp/index'),
            meta: {
              title: '回访提醒',
            },
          },
          {
            path: 'batch',
            name: 'Batch',
            component: () => import('@/views/mall/health/customerRiskPool/deviceImport'),
            meta: {
              title: '批量导入',
            },
          },
          {
            path: 'repurchase',
            name: 'Repurchase',
            component: () => import('@/views/mall/health/customerRepurchase/index'),
            meta: {
              title: '客户复购',
            },
          },
          {
            path: 'afterRecord',
            name: 'AfterRecord',
            component: () => import('@/views/mall/health/afterRecord/index'),
            meta: {
              title: '客情记录',
            },
          },
          {
            path: 'afterRecordView',
            name: 'AfterRecordView',
            component: () => import('@/views/mall/health/afterRecordView/index'),
            meta: {
              title: '客情查看',
            },
          },
        ],
      },

      //客情中心=客情中心
      {
        path: 'after',
        name: 'After',
        meta: {
          title: '客情中心',
          icon: 'remixicon-line',
        },
        children: [
          {
            path: 'afterSaleData',
            name: 'AfterSaleData',
            component: () => import('@/views/mall/health/afterSaleData/index'),
            meta: {
              title: '客情数据',
            },
          },
          {
            path: 'aftersaletreatment',
            name: 'Aftersaletreatment',
            component: () => import('@/views/mall/health/aftersaletreatment/index'),
            meta: {
              title: '客情处理',
            },
          },
          {
            path: 'aftersaleRefundeposit',
            name: 'AftersaleRefundeposit',
            component: () => import('@/views/mall/health/aftersaleRefundeposit/index'),
            meta: {
              title: '退定金',
            },
          },
          {
            path: 'aftersaleRefund',
            name: 'AftersaleRefund',
            component: () => import('@/views/mall/health/aftersaleRefund/index'),
            meta: {
              title: '退货',
            },
          },
          {
            path: 'aftersaleMaintenance',
            name: 'AftersaleMaintenance',
            component: () => import('@/views/mall/health/aftersaleMaintenance/index'),
            meta: {
              title: '维修',
            },
          },
          {
            path: 'afterMeSale',
            name: 'AfterMeSale',
            component: () => import('@/views/mall/health/afterMeSale/index'),
            meta: {
              title: '我的客情',
            },
          },
          {
            path: 'afterMeSaleAudit',
            name: 'AfterMeSaleAudit',
            component: () => import('@/views/mall/health/afterMeSaleAudit/index'),
            meta: {
              title: '客情审核',
            },
          },
          {
            path: 'afterHistory',
            name: 'AfterHistory',
            component: () => import('@/views/mall/health/afterHistory/index'),
            meta: {
              title: '客情历史',
            },
          },
          {
            path: 'afterEvent',
            name: 'AfterEvent',
            component: () => import('@/views/mall/health/afterEvent/index'),
            meta: {
              title: '处理事件',
            },
          },
          {
            path: 'afterEventHandle',
            name: 'AfterEventHandle',
            component: () => import('@/views/mall/health/afterEventHandle/index'),
            meta: {
              title: '事件处理',
            },
          },
        ],
      },
      //质检中心
      {
        path: 'quality',
        name: 'Quality',
        meta: {
          title: '质检中心',
          icon: 'remixicon-line',
        },
        children: [
          {
            path: 'qualitySaleData',
            name: 'QualitySaleData',
            component: () => import('@/views/mall/health/qualitySaleData/index'),
            meta: {
              title: '部门全部质检',
            },
          },
          {
            path: 'qualityMeSale',
            name: 'QualityMeSale',
            component: () => import('@/views/mall/health/qualityMeSale/index'),
            meta: {
              title: '我的质检数据',
            },
          },
          {
            path: 'qualityMeData',
            name: 'QualityMeData',
            component: () => import('@/views/mall/health/qualityMeData/index'),
            meta: {
              title: '部门待质检',
            },
          },
          {
            path: 'qualityHistory',
            name: 'QualityHistory',
            component: () => import('@/views/mall/health/qualityHistory/index'),
            meta: {
              title: '质检历史',
            },
          },
        ],
      },
      //
    ],
  },

  //电销放出来=======放到第一菜单
  {
    path: '/pdata',
    name: 'Pdata',
    component: Layout,
    meta: {
      title: '电销',
      icon: 'code-box-line',
    },

    children: [
      {
        id: 27,
        path: '/eleData',
        name: 'EleData',
        component: 'Layout',
        meta: {
          id: 27,
          title: '电销数据',
          icon: 'file-list-3-line',
        },
        children: [
          {
            path: 'eleboardPerforMc',
            name: 'EleboardPerforMc',
            component: () => import('@/views/mall/health/boardPerforMc/index'),
            meta: {
              title: '运营看板',
            },
          },
          {
            path: 'eleOpenSeaEmpStatistics',
            name: 'EleOpenSeaEmpStatistics',
            component: () => import('@/views/mall/health/openSeaEmpStatistics/index'),
            meta: {
              title: '员工统计',
            },
          },
          {
            path: 'teamdata',
            name: 'Teamdata',
            component: () => import('@/views/mall/health/teamdata/index'),
            meta: {
              title: '团队数据',
            },
          },
          {
            path: 'branchworker',
            name: 'Branchworker',
            component: () => import('@/views/mall/health/Branchworker/index'),
            meta: {
              title: '分线统计',
            },
          },
          {
            path: 'integralStatistics',
            name: 'IntegralStatistics',
            component: () => import('@/views/mall/health/integralStatistics/index'),
            meta: {
              title: '积分统计',
            },
          },
          {
            path: 'epositStatistics',
            name: 'DepositStatistics',
            component: () => import('@/views/mall/health/DepositStatistics/index'),
            meta: {
              title: '预付金统计',
            },
          },
          {
            path: 'productStatistics',
            name: 'ProductStatistics',
            component: () => import('@/views/mall/health/ProductStatistics/index'),
            meta: {
              title: '商品统计',
            },
          },
          {
            path: 'telephoneStatistics',
            name: 'TelephoneStatistics',
            component: () => import('@/views/mall/health/TelephoneStatistics/index'),
            meta: {
              title: '电话统计',
            },
          },
          {
            path: 'employeeOrderStatistics',
            name: 'EmployeeOrderStatistics',
            component: () => import('@/views/mall/health/EmployeeOrderStatistics/index'),
            meta: {
              title: '订单统计',
            },
          },
          {
            id: 27,
            path: '/orderListStatistics',
            name: 'OrderListStatistics',
            component: 'OrderList',
            meta: {
              title: '订单列表',
              icon: 'file-list-3-line',
            },
            children: [
              {
                path: 'allOrderOrderListStatistics',
                name: 'AllOrderOrderListStatistics',
                component: () => import('@/views/mall/health/OrderListStatistics/allOrder/index'),
                meta: {
                  title: '全部订单',
                },
              },
              {
                path: 'myOrderOrderListStatistics',
                name: 'MyOrderOrderListStatistics',
                component: () => import('@/views/mall/health/OrderListStatistics/myOrder/index'),
                meta: {
                  title: '我的订单',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  //公海放出来=======放到第一菜单
  {
    path: '/sata',
    name: 'Sdata',
    component: Layout,
    meta: {
      title: '公海',
      icon: 'code-box-line',
    },

    children: [
      {
        id: 27,
        path: '/seasUser',
        name: 'SeasUser',
        component: 'Layout',
        meta: {
          id: 27,
          title: '客户公海',
          icon: 'file-list-3-line',
        },
        children: [
          {
            path: 'seasManger',
            name: 'SeasManger',
            component: () => import('@/views/mall/health/openSea/seasManger'),
            meta: {
              title: '公海管理',
            },
          },
          {
            path: 'openSeaImport',
            name: 'OpenSeaImport',
            component: () => import('@/views/mall/health/openSeaImport/openSeaImport'),
            meta: {
              title: '客户导入',
            },
          },
          {
            path: 'openSeaPooledData',
            name: 'OpenSeaPooledData',
            component: () => import('@/views/mall/health/openSeaPooledData/index'),
            meta: {
              title: '合并数据',
            },
          },
          {
            path: 'stpLabel',
            name: 'StpLabel',
            component: () => import('@/views/mall/setting/stpLabel/index'),
            meta: {
              title: '标签管理',
            },
          },
        ],
      },
    ],
  },
  //客服放出来=======放到第一菜单
  {
    path: '/tcustomer',
    name: 'Tcustomer',
    component: Layout,
    meta: {
      title: '销售',
      icon: 'code-box-line',
    },

    children: [
      {
        id: 27,
        path: '/call',
        name: 'Call',
        component: 'Layout',
        meta: {
          id: 27,
          title: '客服中心',
          icon: 'file-list-3-line',
        },
        children: [
          {
            path: 'openSeaDepart',
            name: 'OpenSeaDepart',
            component: () => import('@/views/mall/health/openSeaDepart/openSeaDepart'),
            meta: {
              title: '部门公海',
            },
          },
          {
            path: 'openSeaClient',
            name: 'OpenSeaClient',
            // component: () => import('@/views/mall/health/customerMy/myClient'),
            component: () => import('@/views/mall/health/openSeaClient/openSeaClient'),
            meta: {
              title: '我的客户',
            },
          },
          {
            path: 'editTab',
            name: 'EditTab',
            component: () => import('@/views/mall/health/customerMy/edittab'),
            meta: {
              title: '客户详细',
            },
          },
          {
            path: 'riskPool',
            name: 'RiskPool',
            component: () => import('@/views/mall/health/customerRiskPool/index'),
            meta: {
              title: '风险池',
            },
          },
          {
            path: 'afterRecord',
            name: 'AfterRecord',
            component: () => import('@/views/mall/health/afterRecord/index'),
            meta: {
              title: '客情记录',
            },
          },
          {
            path: 'afterRecordView',
            name: 'AfterRecordView',
            component: () => import('@/views/mall/health/afterRecordView/index'),
            meta: {
              title: '客情查看',
            },
          },
        ],
      },
      {
        id: 27,
        path: '/orderData',
        name: 'OrderData',
        // component: 'Layout',
        meta: {
          id: 27,
          title: '订单列表',
          icon: 'file-list-3-line',
        },
        children: [
          {
            path: 'allOrder',
            name: 'AllOrder',
            component: () => import('@/views/mall/health/allOrder/index'),
            meta: {
              title: '全部订单',
            },
          },
          {
            path: 'myOrder',
            name: 'MyOrder',
            component: () => import('@/views/mall/health/myOrder/index'),
            meta: {
              title: '我的订单',
            },
          },
        ],
      },
    ],
  },
  //客情放出来=======放到第一菜单
  {
    path: '/tAfter',
    name: 'TAfter',
    component: Layout,
    meta: {
      title: '客情',
      icon: 'code-box-line',
    },

    children: [
      {
        id: 27,
        path: '/sale',
        name: 'Sale',
        component: 'Layout',
        meta: {
          id: 27,
          title: '客情中心',
          icon: 'file-list-3-line',
        },
        children: [
          {
            path: 'afterSaleData',
            name: 'AfterSaleData',
            component: () => import('@/views/mall/health/afterSaleData/index'),
            meta: {
              title: '客情数据',
            },
          },
          {
            path: 'aftersaletreatmentHandle',
            name: 'aftersaletreatmentHandle',
            component: () => import('@/views/mall/health/aftersaletreatment/index'),
            meta: {
              title: '客情处理',
            },
          },
          {
            path: 'afterMeSale',
            name: 'AfterMeSale',
            component: () => import('@/views/mall/health/afterMeSale/index'),
            meta: {
              title: '我的客情',
            },
          },
          {
            path: 'afterMeSaleAudit',
            name: 'AfterMeSaleAudit',
            component: () => import('@/views/mall/health/afterMeSaleAudit/index'),
            meta: {
              title: '客情审核',
            },
          },
          {
            path: 'afterHistory',
            name: 'AfterHistory',
            component: () => import('@/views/mall/health/afterHistory/index'),
            meta: {
              title: '客情历史',
            },
          },
          {
            path: 'afterEvent',
            name: 'AfterEvent',
            component: () => import('@/views/mall/health/afterEvent/index'),
            meta: {
              title: '处理事件',
            },
          },
          {
            path: 'afterEventHandle',
            name: 'AfterEventHandle',
            component: () => import('@/views/mall/health/afterEventHandle/index'),
            meta: {
              title: '事件处理',
            },
          },
        ],
      },
    ],
  },
  //质检放出来=======放到第一菜单
  {
    path: '/aquality',
    name: 'Aquality',
    component: Layout,
    meta: {
      title: '质检',
      icon: 'code-box-line',
    },

    children: [
      {
        id: 27,
        path: '/quality',
        name: 'Quality',
        component: 'Layout',
        meta: {
          id: 27,
          title: '质检中心',
          icon: 'file-list-3-line',
        },
        children: [
          {
            path: 'qualitySaleData',
            name: 'QualitySaleData',
            component: () => import('@/views/mall/health/qualitySaleData/index'),
            meta: {
              title: '质检数据',
            },
          },
          {
            path: 'qualityMeSale',
            name: 'QualityMeSale',
            component: () => import('@/views/mall/health/qualityMeSale/index'),
            meta: {
              title: '我的质检',
            },
          },
          {
            path: 'qualityHistory',
            name: 'QualityHistory',
            component: () => import('@/views/mall/health/qualityHistory/index'),
            meta: {
              title: '质检历史',
            },
          },
          {
            path: 'preDepositRechargeReview',
            name: 'PreDepositRechargeReview',
            component: () => import('@/views/mall/health/preDepositRechargeReview/index'),
            meta: {
              title: '预存款充值审核',
            },
          },
        ],
      },
    ],
  },
  //===========================
  {
    path: '/workflow',
    name: 'Workflow',
    component: Layout,
    meta: {
      title: '应用',
      icon: 'flow-chart',
    },
    children: [
      //健康计划
      {
        path: 'healthPlan',
        name: 'HealthPlan',
        meta: {
          title: '健康计划',
          icon: 'album-line',
        },
        children: [
          {
            path: 'healthPlanList',
            name: 'HealthPlanList',
            component: () => import('@/views/mall/apps/healthPlan/healthPlanList/index'),
            meta: {
              title: '计划列表',
            },
          },
          {
            path: 'healthPlanType',
            name: 'HealthPlanType',
            component: () => import('@/views/mall/apps/healthPlan/healthPlanType/index'),
            meta: {
              title: '计划类型',
              hidden: true,
            },
          },
          {
            path: 'healthPlanManage',
            name: 'HealthPlanManage',
            component: () => import('@/views/mall/apps/healthPlan/healthPlanManage/index'),
            meta: {
              title: '计划管理',
            },
          },
          {
            path: 'healthAddplan',
            name: 'HealthAddplan',
            component: () => import('@/views/mall/apps/healthPlan/healthAddplan/index'),
            meta: {
              title: '添加计划',
            },
          },
          {
            path: 'projectDetails',
            name: 'ProjectDetails',
            component: () => import('@/views/mall/apps/healthPlan/ProjectDetails/index'),
            meta: {
              title: '详情计划',
            },
          },
        ],
      },
      {
        path: 'healthdetection',
        name: 'Healthdetection',
        meta: {
          title: '健康检测',
          icon: 'album-line',
        },
        children: [
          {
            path: 'quipmentmanagement',
            name: 'Equipmentmanagement',
            component: () => import('@/views/mall/apps/healthdetection/Equipmentmanagement/index.vue'),
            meta: {
              title: '设备管理',
            },
          },
          {
            path: 'healthchecklist',
            name: 'Healthchecklist',
            component: () => import('@/views/mall/apps/healthdetection/healthchecklist/index.vue'),
            meta: {
              title: '健康指数',
            },
          },
          {
            path: 'healthdatacenter',
            name: 'Healthdatacenter',
            component: () => import('@/views/mall/apps/healthdetection/Healthdatacenter/index.vue'),
            meta: {
              title: '健康数据',
            },
          },
          {
            path: 'healthwarning',
            name: 'Healthwarning',
            component: () => import('@/views/mall/apps/healthdetection/Health-warning/index.vue'),
            meta: {
              title: '健康预警',
            },
          },
          {
            path: 'earlywarningmanagement',
            name: 'Earlywarningmanagement',
            component: () => import('@/views/mall/apps/healthdetection/Earlywarningmanagement/index.vue'),
            meta: {
              title: '预警管理',
            },
          },
        ],
      },
      //===============
      {
        path: 'equipment',
        name: 'Equipment',
        meta: {
          title: '设备管理',
          icon: 'album-line',
        },
        children: [
          {
            path: 'device',
            name: 'Device',
            component: () => import('@/views/mall/health/device/index'),
            meta: {
              title: '设备列表',
            },
          },
          {
            path: 'deviceImport',
            name: 'DeviceImport',
            component: () => import('@/views/mall/health/device/deviceImport'),
            meta: {
              title: '导入设备',
              hidden: true,
            },
          },
          {
            path: 'glueDevice',
            name: 'GlueDevice',
            component: () => import('@/views/mall/health/device/gluedevice'),
            meta: {
              title: '蓝牙设备',
            },
          },
        ],
      },
      //===============
      {
        path: 'course',
        name: 'Course',
        meta: {
          title: '课程管理',
          icon: 'account-circle-fill',
        },
        children: [
          {
            path: 'expertManagement',
            name: 'ExpertManagement',
            component: () => import('@/views/mall/health/expertManagement/index'),
            meta: {
              title: '专家管理',
            },
          },

          {
            path: 'coursecate',
            name: 'Coursecate',
            component: () => import('@/views/mall/health/coursecate/index'),
            meta: {
              title: '课程分类',
            },
          },
          {
            path: 'coursemanage',
            name: 'Coursemanage',
            component: () => import('@/views/mall/health/coursemanage/index'),
            meta: {
              title: '课程内容',
            },
          },
          {
            path: 'threeManagement',
            name: 'ThreeManagement',
            component: () => import('@/views/mall/health/threeManagement'),
            meta: {
              title: '三期6G法',
            },
          },
          {
            path: 'sugarManagement',
            name: 'SugarManagement',
            component: () => import('@/views/mall/health/sugarManagement'),
            meta: {
              title: '控糖管理',
            },
          },
        ],
      },
      //=================
      {
        path: 'icon',
        name: 'Icon',
        meta: {
          title: '计划管理',
          icon: 'ancient-pavilion-line',
        },
        children: [
          {
            path: 'planDiet',
            name: 'PlanDiet',
            component: () => import('@/views/mall/health/planDiet/index'),
            meta: {
              title: '饮食列表',
            },
          },
          {
            path: 'planMatch',
            name: 'PlanMatch',
            component: () => import('@/views/mall/health/planMatch/index'),
            meta: {
              title: '饮食搭配',
            },
          },
          {
            path: 'cardType',
            name: 'CardType',
            component: () => import('@/views/mall/health/cardType/index'),
            meta: {
              title: '打卡类型',
            },
          },
        ],
      },
      {
        id: 90,
        path: '/manage',
        name: 'Manage',
        component: 'Layout',
        meta: {
          id: 90,
          title: '文章管理',
          icon: 'folder-4-line',
        },
        children: [
          {
            id: 91,
            path: '/artColumn',
            name: 'ArtColumn',
            component: () => import('@/views/mall/article/manage/artColumn'),
            meta: {
              id: 91,
              title: '文章类型',
            },
          },
          {
            id: 93,
            path: '/artLlist',
            name: 'ArtLlist',
            component: () => import('@/views/mall/article/manage/artLlist'),
            meta: {
              id: 93,
              title: '文章列表',
            },
          },
          {
            id: 94,
            path: '/editArt',
            name: 'EditArt',
            component: () => import('@/views/mall/article/manage/editArt'),
            meta: {
              id: 94,
              title: '编辑文章',
              hidden: true,
            },
          },
          {
            id: 95,
            path: '/articleRelease',
            name: 'ArticleRelease',
            component: () => import('@/views/mall/article/release'),

            meta: {
              id: 95,
              title: '发布文章',
              icon: 'send-plane-fill',
              hidden: true,
            },
          },

          {
            id: 92,
            path: '/artType',
            name: 'ArtType',
            component: () => import('@/views/mall/article/manage/artType'),
            meta: {
              id: 92,
              title: '文章类型',
            },
          },
        ],
      },
      {
        id: 90,
        path: '/management',
        name: 'Management',
        component: 'Layout',
        meta: {
          id: 90,
          title: '海报管理',
          icon: 'folder-4-line',
        },
        children: [
          {
            id: 91,
            path: '/placard',
            name: 'Placard',
            component: () => import('@/views/mall/management/placard'),
            meta: {
              id: 91,
              title: '海报管理',
            },
          },
        ],
      },
      {
        id: 90,
        path: '/menumanagement',
        name: 'Menumanagement',
        component: 'Layout',
        meta: {
          id: 90,
          title: '菜单管理',
          icon: 'folder-4-line',
        },
        children: [
          {
            id: 91,
            path: '/menu',
            name: 'Menu',
            component: () => import('@/views/mall/menu/menumanagement/'),
            meta: {
              id: 91,
              title: '菜单管理',
            },
          },
        ],
      },
      {
        id: 90,
        path: '/msgcontent',
        name: 'Msgcontent',
        component: 'Layout',
        meta: {
          id: 90,
          title: '消息管理',
          icon: 'folder-4-line',
        },
        children: [
          {
            id: 91,
            path: '/msglist',
            name: 'Msglist',
            component: () => import('@/views/mall/msgcontent/messageslist/'),
            meta: {
              id: 91,
              title: '消息列表',
            },
          },
          {
            id: 91,
            path: '/msgpusg',
            name: 'Msgpusg',
            component: () => import('@/views/mall/msgcontent/messagespush/'),
            meta: {
              id: 91,
              title: '消息推送',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/print',
    name: 'Print',
    component: Layout,
    meta: {
      title: '财务',
      icon: 'printer-line',
    },
    children: [
      {
        path: 'financial',
        name: 'Financial',
        meta: {
          title: '财务管理',
          icon: 'remixicon-line',
        },
        children: [
          {
            path: 'withdrawal',
            name: 'Withdrawal',
            component: () => import('@/views/mall/financial/operationFin/withdrawal/index'),
            meta: {
              id: 104,
              title: '用户提现',
            },
          },
          {
            path: 'Withdrawalsetup',
            name: 'Withdrawalsetup',
            component: () => import('@/views/mall/financial/operationFin/Withdrawalsetup'),
            meta: {
              id: 104,
              title: '提现设置',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/other',
    name: 'Other',
    component: Layout,
    meta: {
      title: '渠道',
      icon: 'archive-line',
    },
    children: [
      {
        path: 'provider',
        name: 'Provider',
        meta: {
          title: '服务商',
          icon: 'remixicon-line',
        },
        children: [
          {
            path: 'facilitator',
            name: 'Facilitator',
            component: () => import('@/views/mall/health/facilitator/index'),
            meta: {
              title: '服务商列表',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/timeline',
    name: 'Timeline',
    component: Layout,
    meta: {
      title: '设置',
      icon: 'archive-line',
    },
    children: [
      {
        path: 'rbac',
        name: 'Rbac',
        meta: {
          title: '权限管理',
          icon: 'lock-fill',
        },
        children: [
          {
            path: 'sysuser',
            name: 'SysUser',
            component: () => import('@/views/mall/admin/sysuser'),
            meta: {
              id: 6,
              title: '员工管理',
            },
          },
          {
            path: 'companyManagement',
            name: 'CompanyManagement',
            component: () => import('@/views/mall/admin/companyManagement'),
            meta: {
              id: 9,
              title: '公司管理',
            },
          },
          {
            path: 'sysorg',
            name: 'SysOrg',
            component: () => import('@/views/mall/admin/sysorg'),
            meta: {
              id: 7,
              title: '组织架构',
            },
          },
          {
            path: 'syspost',
            name: 'SysPost',
            component: () => import('@/views/mall/admin/syspost'),
            meta: {
              id: 8,
              title: '岗位管理',
            },
          },
          {
            path: 'sysrole',
            name: 'SysRole',
            component: () => import('@/views/mall/authority/sysrole'),
            meta: {
              id: 275,
              title: '角色管理',
            },
          },
          {
            path: 'sysSeats',
            name: 'SysSeats',
            component: () => import('@/views/mall/authority/sysSeats'),
            meta: {
              id: 276,
              title: '坐席管理',
            },
          },
        ],
      },
      {
        path: 'base',
        name: 'Base',
        meta: {
          title: '资源管理',
          icon: 'lock-fill',
        },
        children: [
          {
            path: 'sysmenu',
            name: 'SysMenu',
            component: () => import('@/views/mall/admin/sysmenu'),
            meta: {
              id: 10,
              title: '菜单管理',
            },
          },
          {
            path: 'sysbutton',
            name: 'Sysbutton',
            component: () => import('@/views/mall/admin/sysbutton'),
            meta: {
              id: 11,
              title: '按钮管理',
            },
          },
          {
            path: 'sysurl',
            name: 'SysUrl',
            component: () => import('@/views/mall/authority/sysurl'),
            meta: {
              id: 12,
              title: '接口管理',
            },
          },
        ],
      },
      {
        children: [
          {
            children: [],
            component: '@/views/mall/feature/business',
            id: 87,
            meta: {
              id: 87,
              title: '行业配置',
            },
            name: 'Business',
            path: '/business',
          },
          {
            children: [],
            component: '@/views/mall/feature/mall',
            id: 86,
            meta: {
              id: 86,
              title: '商城设置',
            },
            name: 'Mall',
            path: '/mall',
          },
          {
            children: [],
            component: '@/views/mall/feature/status',
            id: 85,
            meta: {
              hidden: true,
              id: 85,
              title: '身份设置',
            },
            name: 'Status',
            path: '/status',
          },
          {
            children: [],
            component: '@/views/mall/feature/share',
            id: 84,
            meta: {
              id: 84,
              title: '分享设置',
            },
            name: 'Share',
            path: '/share',
          },
        ],
        component: 'Layout',
        id: 83,
        meta: {
          icon: 'xbox-line',
          id: 83,
          title: '功能设置',
        },
        name: 'Functions',
        path: '/functions',
      },
      {
        alwaysShow: true,
        children: [
          {
            component: () => import('@/views/mall/admin/version'),
            id: 88,
            meta: {
              id: 88,
              title: '版本管理',
            },
            name: 'Version',
            path: '/version',
          },
          {
            component: () => import('@/views/mall/admin/sysdictionary'),
            id: 81,
            meta: {
              id: 81,
              title: '字典管理',
            },
            name: 'Sysdictionary',
            path: '/sysdictionary',
          },
          {
            component: () => import('@/views/mall/admin/syssetting'),
            id: 81,
            meta: {
              id: 81,
              title: '系统设置',
            },
            name: 'SysSetting',
            path: '/sysSetting',
          },
          {
            component: () => import('@/views/mall/admin/CopySetup'),
            id: 81,
            meta: {
              id: 81,
              title: '文案设置',
            },
            name: 'CopySetup',
            path: '/Copysetup',
          },
        ],
        component: 'Layout',
        id: 82,
        meta: {
          icon: 'settings-2-line',
          id: 82,
          title: '系统设置',
        },
        name: 'system',
        path: '/system',
      },

      {
        name: 'Setting',
        path: '/setting',
        component: 'Layout',
        id: 82,
        meta: {
          icon: 'settings-2-line',
          id: 82,
          title: '应用设置',
        },

        children: [
          {
            component: () => import('@/views/mall/setting/pushMsg/index'),
            id: 88,
            meta: {
              id: 88,
              title: '推送管理',
            },
            name: 'PushMsg',
            path: '/pushMsg',
          },
          {
            component: () => import('@/views/mall/setting/callcenter/index'),
            id: 88,
            meta: {
              id: 88,
              title: 'CXCC呼叫中心',
            },
            name: 'Callcenter',
            path: '/callcenter',
          },
        ],
      },
      {
        path: 'delSetting',
        name: 'DelSetting',
        meta: {
          title: '配送设置',
          icon: 'lock-fill',
        },
        children: [
          {
            path: 'logistics',
            name: 'Logistics',
            component: () => import('@/views/mall/setting/logistics/index'),
            meta: {
              id: 6,
              title: '物流接口',
            },
          },
          {
            path: 'express',
            name: 'Express',
            component: () => import('@/views/mall/setting/express/index'),
            meta: {
              id: 7,
              title: '运费模板',
            },
          },
        ],
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
    meta: {
      hidden: true,
    },
  },
]

const router = createRouter()

function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

export function resetRouter(routes = constantRoutes) {
  routes.map((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.matcher = createRouter(routes).matcher
}

function createRouter(routes = constantRoutes) {
  return new VueRouter({
    base: publicPath,
    mode: routerMode,
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: routes,
  })
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
