<template>
  <div class="deviceImport-container">
    <el-alert :closable="false" effect="dark" title="">
      <p class="tip">温情提示：请尽量在服务器空闲时间操作，获取商品会占用大量内存与带宽，在获取过程中，请不要进行任何操作!</p>
      <div class="flex-top tip">
        <div>使用方法：</div>
        <div>
          <p style="margin-top: 0">
            1.将您获取到的文件转存为Excel格式
            <el-link type="primary" :underline="true" @click="downloadTemplate">Excel示例文件下载</el-link>
          </p>
          <p>2.立即上传</p>
        </div>
      </div>
    </el-alert>
    <div>
      <el-form>
        <el-row>
          <el-col :span="6">
            <el-form-item label="导入Excel文件：">
              <!-- <el-upload
            ref="fileUpload"
            action="upms/file/upload?fileType=image&dir=material/"
            :auto-upload="false"
            :before-upload="beforeUpload"
            class="upload-demo"
            :file-list="fileList"
            :headers="headers"
            :on-error="handleError"
            :on-success="handleSuccess"
            @on-progress="progress"
          > -->
              <div class="uploadDialog">
                <el-upload
                  accept=".xlsx, .xls"
                  :auto-upload="false"
                  :before-upload="beforeUploadFile"
                  drag
                  :file-list="batchFileList"
                  :headers="headers"
                  :limit="1"
                  :on-change="fileChange"
                  :on-error="handleError"
                  :on-exceed="exceedFile"
                  :on-remove="batchRemove"
                  :on-success="handleSuccess"
                >
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">
                    <em>点击上传</em>
                  </div>
                  <div slot="tip" class="el-upload__tip">只能上传xlsx文件，且不超过10M</div>
                  <!-- <div slot="tip" class="el-upload__tip">
                上传前不知道excel模板的，请点击
                <span class="clickMe" @click="poDownload">点我下载模板</span>
                去下载
              </div> -->
                </el-upload>
                <br />
                <el-button size="small" type="primary" @click="uploadFile">立即上传</el-button>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-form-item label="">
          <el-button v-if="true" @click="submit">立即导入</el-button>
        </el-form-item> -->
      </el-form>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import { importuUserSugarRecord } from '@/api/mall/health/device/device'
  // import { uploadURL } from '@/config'
  export default {
    data() {
      return {
        headers: {
          Authorization: 'Bearer ' + store.getters['user/token'],
          'switch-tenant-id': 1,
        },
        fileList: [],
        batchFileList: [],
        UploadUrl: 'http://47.109.83.206:9007/file/upload',
        typeId: 0,
      }
    },
    created() {
      if (this.$route.query.type != '') {
        this.typeId = this.$route.query.type
      }
    },
    methods: {
      beforeUpload(file) {
        const isPic = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpg'
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isPic) {
          this.$message.error('上传图片只能是 JPG、JPEG、PNG、GIF 格式!')
          return false
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!')
        }
        return isPic && isLt2M
      },
      handleError(err) {
        this.$message.error(err + '')
      },
      // file, fileList
      handleSuccess(res) {
        console.log(res.link)
      },
      progress(event, file, fileList) {
        console.log(event, file, fileList)
      },
      batchRemove() {
        this.batchFileList = []
      },
      // 文件状态改变时的钩子
      fileChange(file) {
        console.log(file.raw)
        this.batchFileList.push(file.raw)
        console.log(this.batchFileList)
      },
      // *********************导入部分
      UploadUrls: function () {
        // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
        return ''
      },
      // 下载模板
      poDownload() {},
      // 导入
      uploadFile() {
        console.log(this.batchFileList.length)
        if (this.batchFileList.length === 0) {
          this.$message.warning('请上传文件')
        } else {
          const formDate = new FormData()
          formDate.append('file', this.batchFileList[0])
          console.log(formDate.get('file'))
          //导入糖脂管家客户基础信息调查
          importuUserSugarRecord(formDate, this.typeId).then((res) => {
            console.log(res)
            this.$message.success('导入成功')
            this.batchFileList = []
          })
        }
      },
      submit() {
        // this.$refs['fileUpload'].submit()
      },
      /**
       * excel示例模板下载
       */
      downloadTemplate() {
        window.open('https://chongtui.oss-cn-chengdu.aliyuncs.com/wxApplet/healthy/public/xls/client.xlsx', '_blank')
        this.$baseMessage('下载成功', 'success', 'vab-hey-message-success')
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-alert--info.is-dark {
    background-color: rgba($color: #409eff, $alpha: 0.1);
  }
  .tip {
    color: #333;
    &-indent {
      text-indent: 4.2em;
    }
  }

  .uploadDialog {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    >>> .el-upload__tip {
      display: inline-block;
      width: 360px;
      margin: auto;
    }
  }
</style>
