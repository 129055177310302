<template>
 <div>
  <el-dialog
    :before-close="handleClose"
    title="请选择商品"
    :visible.sync="dialogVisible"
    width="80%">
    <span>
      <el-table border="" :data="tabData" max-height="300px"  @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"/>
        <el-table-column align="center" label="店铺" prop="shopId"/>
        <el-table-column align="center" label="商品名称"  prop="name" :show-overflow-tooltip="true"/>
        <el-table-column align="center" label="商品图片" prop="picUrls">
          <template #default="scope">
            <el-image :src="scope.row.picUrls[0]" style="width: 60px;height: 60px;"/>
          </template>
        </el-table-column>
        <el-table-column align="center" label="商城类目" prop="categoryId"/>
        <el-table-column align="center" label="店铺类目"/>
        <el-table-column align="center" label="卖点" prop="sellPoint"/>
        <el-table-column align="center" label="价位" prop="priceDown"/>
        <el-table-column align="center" label="商品编码" prop="spuCode"/>
        <el-table-column align="center" label="规格类型" prop="specType"/>
        <el-table-column align="center" label="虚拟销量" prop="saleNum"/>
        <el-table-column align="center" label="创建时间" prop="createTime"/>
        <el-table-column align="center" label="更新时间" prop="updateTime"/>
        <el-table-column align="center" label="是否上架" prop="shelf">
          <template #default="scope">
            <el-switch
              v-model="scope.row.shelf"
              active-color="#13ce66"
              active-value="0"
              inactive-color="#ff4949"
              inactive-value="1"/>
          </template>
        </el-table-column>
        <el-table-column align="center" label="积分赠送" prop="pointsGiveSwitch">
          <template #default="scope">
            <el-tag v-if="scope.row.pointsGiveSwitch==1" type="success">开启</el-tag>
            <el-tag v-if="scope.row.pointsGiveSwitch!=1" type="danger">关闭</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="积分抵扣" prop="pointsDeductSwitch">
          <template #default="scope">
            <el-tag v-if="scope.row.pointsDeductSwitch==1" type="success">开启</el-tag>
            <el-tag v-if="scope.row.pointsDeductSwitch!=1" type="danger">关闭</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="审核状态" prop="verifyStatus">
          <template #default="scope">
            <el-tag v-if="scope.row.verifyStatus==0" type="info">审核中</el-tag>
            <el-tag v-if="scope.row.verifyStatus==1" type="success">审核通过</el-tag>
            <el-tag v-if="scope.row.verifyStatus==2" type="danger">审核不通过</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"/>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="clears">取 消</el-button>
      <el-button type="primary" @click="saverow">确 定</el-button>
    </span>
  </el-dialog>
 
 </div>
</template>

<script> 
import {getPage} from "@/api/mall/goodspu/goodspu"
export default {
  props:{
    showadd:{
      type:Boolean,
      default:()=>{}
    },
    shopis:{
      type:String,
      default:()=>{}
    }
  },
  data(){
    return{
      dialogVisible:this.showadd,
      total:0,
      tabData:[],
      form:{
        size:10,
        current:1,
        shopId:this.shopis
      },
      checkrow:[]
    }
  },
  watch:{
    showadd(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisible=newValue
      }
    },
    shopis(newValue,oldValue){
      if(newValue!=oldValue){
        this.form.shopId=newValue
        this.getPage()
      }
    }
  },
  created(){
    this.getPage()
  },
  methods:{
    getPage(){
      getPage({...this.form}).then(res=>{
        console.log(res.data)
        this.tabData=res.data.records
        this.total=res.data.total
        this.form.size=res.data.size
        this.form.current=res.data.current
      })
    },
    handleClose(){},
    clears(){
      this.$emit("closerows",'')
    },
    handleCurrentChange(e){
      this.form.current=e
      this.getPage()
    },
    handleSizeChange(e){
      this.form.size=e
      this.getPage()
    },
    handleSelectionChange(e){
      this.checkrow=e
    },
    saverow(){
      this.$emit("closerows",this.checkrow)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__header{
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>