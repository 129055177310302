<template>
  <div class="comprehensive-table-container">
    <Search />
    <el-table border :data="tableData" style="width: 100%">
      <el-table-column align="center" label="会员名称" prop="date" />
      <el-table-column align="center" label="性别" prop="name" />
      <el-table-column align="center" label="年龄" prop="address" />
      <el-table-column align="center" label="预警类型" prop="address" />
      <el-table-column align="center" label="检测时间" prop="address" />
      <el-table-column align="center" label="预警结果" prop="address" />
    </el-table>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="form.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import Search from './search'
export default {
  components: {
    Search,
  },
  data() {
    return {
      form: {
        size: 10,
        current: 1,
      },
      total: 0,
      tableData: [],
    }
  },
  methods: {
    handleCurrentChange(e) {
      this.form.current = e
    },
    handleSizeChange(e) {
      this.form.size = e
    },
  },
}
</script>

<style>
</style>