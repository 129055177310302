<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="700px"
    @close="close"
  >
    <div>
      <el-row style="padding-left: 35px; display: flex">
        <el-col>物流公司：</el-col>
        <el-col>快递单号：</el-col>
      </el-row>
    </div>
    <div class="mt30">
      <el-timeline :reverse="reverse">
        <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          :timestamp="activity.timestamp"
        >
          {{ activity.content }}
        </el-timeline-item>
      </el-timeline>
    </div>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'DetailsLogistics',
    data() {
      return {
        dialogFormVisible: false,
        title: '物流详情',
        reverse: true,
        activities: [
          {
            content: '订单成功',
            timestamp: '2018-04-15',
          },
          {
            content: '订单派送',
            timestamp: '2018-04-13',
          },
          {
            content: '订单拣货',
            timestamp: '2018-04-11',
          },
        ],
      }
    },
    created() {},
    methods: {
      // 弹框
      showEdit() {
        this.dialogFormVisible = true
      },
      // 弹框关闭
      close() {
        this.dialogFormVisible = false
      },
      // 确定
      save() {},
    },
  }
</script>
