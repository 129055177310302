import request from '@/utils/request'
// 组织结构列表
export function orgTree(data) {
  return request({
    url: '/upms/admin/comSysOrg/queryPage',
    method: 'post',
    data,
  })
}
export function queryParentPage(data) {
  return request({
    url: '/upms/admin/comSysOrg/queryParentPage',
    method: 'post',
    data,
  })
}
// 获取当前用户的组织结构列表
export function orgTreeId(data) {
  return request({
    url: '/upms/admin/comSysOrg/queryListByOrgId',
    method: 'post',
    data,
  })
}

// 销售部门公海分配
export function orgTrees(data) {
  return request({
    url: '/upms/admin/comSysOrg/listUserOrg',
    method: 'post',
    data,
  })
}

//单一部门查询
export function queryDateisOrgorgTree(data) {
  return request({
    url: '/mall/admin/comSysOrg/queryDateisOrg',
    method: 'post',
    data,
  })
}
// 新增组织架构
export function orgAdd(data) {
  return request({
    url: '/upms/admin/comSysOrg/add',
    method: 'post',
    data,
  })
}
// 删除组织架构
export function orgIsDelete(data) {
  return request({
    url: '/upms/admin/comSysOrg/isDelete',
    method: 'post',
    data,
  })
}
// 编辑组织架构
export function orgEdit(data) {
  return request({
    url: '/upms/admin/comSysOrg/edit',
    method: 'post',
    data,
  })
}
