/**
 * @description 电销-我的客户api
 * @author
 */
import request from '@/utils/request'
import { getToken } from '@/utils/token'

export function queryRiskPoolPage(data) {
  return request({
    url: '/mall/user/uuUserSugarRecord/queryRiskPoolPage',
    method: 'post',
    data: data,
  })
}

export function getPage(data) {
  return request({
    url: '/mall/userinfo/queryComPage',
    method: 'post',
    data: data,
  })
}

// 客户新增
export function addUuUserSugarRecord(data) {
  return request({
    url: '/mall/user/uuUserSugarRecord/addUuUserSugarRecord',
    method: 'post',
    data: data,
  })
}

//客户基础信息调查表服务
export function queryPage(data) {
  return request({
    url: '/mall/user/uuUserSugarRecord/queryPage',
    method: 'post',
    data: data,
  })
}
//查询部门的公海
export function querySeaDepartPage(data) {
  return request({
    url: '/mall/user/uuUserSugarRecord/querySeaDepartPage',
    method: 'post',
    data: data,
  })
}
//查询员工的公海
export function querySeaEmpPage(data) {
  return request({
    url: '/mall/user/uuUserSugarRecord/querySeaEmpPage',
    method: 'post',
    data: data,
  })
}

export function addObj(obj) {
  return request({
    url: '/upms/shopuser',
    method: 'post',
    data: obj,
  })
}

export function getObj(id) {
  return request({
    url: '/upms/shopuser/' + id,
    method: 'get',
  })
}

export function delObj(id) {
  return request({
    url: '/upms/shopuser/' + id,
    method: 'delete',
  })
}

export function putObj(obj) {
  return request({
    url: '/upms/shopuser',
    method: 'put',
    data: obj,
  })
}

export function editPassword(obj) {
  return request({
    url: '/upms/user/password',
    method: 'put',
    data: obj,
  })
}
// 1261193346845495297
// export function shopinfo(id) {
//   return request({
//     url: '/mall/shopinfo/' + id,
//     method: 'get',
//   })
// }
export function shopinfo(params = { current: 1, size: 10, type: 2 }) {
  return request({
    url: '/upms/shopuser/page',
    method: 'get',
    params,
  })
}

//pms/shopuser/page?current=1&size=20&type=2
// 指定部门客户基础信息调查表_移交
export function uuUserSys(data) {
  return request({
    url: '/mall/sys/uuUserSys/add',
    method: 'post',
    data,
  })
}
// 公海移交部门
export function updateTurnOver(data) {
  return request({
    url: '/mall/user/uuUserSugarRecord/turnOver',
    method: 'post',
    data,
  })
}
// 公海批量移交部门
export function updatebatchTurnOverOver(data) {
  return request({
    url: '/mall/user/uuUserSugarRecord/batchTurnOver',
    method: 'post',
    data,
  })
}

export function afterSale(data) {
  return request({
    url: '/mall/user/uuUserSugarRecord/afterSale',
    method: 'post',
    data,
  })
}
//分页列表--查询店铺员工
export function distrtionshow(data) {
  return request({
    url: '/upms/shopuser/queryPage',
    method: 'post',
    data,
  })
}

//获取配置信息

export function getAgentMsg(data) {
  return request({
    url: '/mall/cxcc/getAgentMsg',
    method: 'post',
    data,
  })
}

// 导出客户
export function seaExport(data) {
  return request({
    url: '/mall/user/uuUserSugarRecord/exportUuUserSugar',
    headers: {
      'Switch-Tenant-Id': '1',
      Authorization: `Bearer ${getToken()}`,
      // 'Content-Type': 'application/vnd.ms-excel; charset=UTF-8',
    },
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 导出客情客户
export function exportUuUserSugarRecordAfter(data) {
  return request({
    url: '/mall/uuUserSugarRecordAfter/exportUuUserSugarRecordAfter',
    headers: {
      'Switch-Tenant-Id': '1',
      Authorization: `Bearer ${getToken()}`,
      // 'Content-Type': 'application/vnd.ms-excel; charset=UTF-8',
    },
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 导出客户根据查询条件导出(导出客户)
export function exportUuUserSugarxc(data) {
  return request({
    url: '/mall/user/uuUserSugarRecord/exportUuUserSugarxc',
    headers: {
      'Switch-Tenant-Id': '1',
      Authorization: `Bearer ${getToken()}`,
      // 'Content-Type': 'application/vnd.ms-excel; charset=UTF-8',
    },
    method: 'post',
    data,
    responseType: 'blob',
  })
}

//质检列表
export function uuUserSugarRecordQuality(data) {
  return request({
    url: '/mall/swm/uuUserSugarRecordQuality/queryPage',
    method: 'post',
    data,
  })
}

//客情列表
export function uuUserSugarRecordAfter(data) {
  return request({
    url: '/mall/uuUserSugarRecordAfter/queryAfterPage',
    method: 'post',
    data,
  })
}
// 公海管理-员工统计.
export function queryEmpPage(data) {
  return request({
    url: '/mall/admin/user/queryEmpPage',
    method: 'post',
    data,
  })
}

//  公海管理-员工统计.-导出
export function exportUuUserEmp(data) {
  return request({
    url: '/mall/admin/user/exportUuUserEmp',
    headers: {
      'Switch-Tenant-Id': '1',
      Authorization: `Bearer ${getToken()}`,
      // 'Content-Type': 'application/vnd.ms-excel; charset=UTF-8',
    },
    method: 'post',
    data,
    responseType: 'blob',
  })
}
