<template>
  <div class="comprehensive-table-container">
    <div class="thead">
      <div class="header-title">
        <div class="lef">健康综合指数分值</div>
        <div class="ref">
          <vab-icon icon="edit-box-line" style="font-size: 28px; color: #007aff" />
        </div>
      </div>
      <div class="line-echart">
        <div class="scroll-value">
          <div class="scroll-view">
            <div class="item seizure" :style="{ width: scroll + '%' }">
              <div class="move">{{ scroll }}</div>
              <div class="seizure-txt" style="color: #ff0000">疾病发作状态</div>
            </div>
            <div class="item seizure" :style="{ width: scrollvalue + '%', background: '#F66105' }">
              <div class="move">{{ scrollvalue }}</div>
              <div class="seizure-txt" style="color: #f66105">疾病发作前的前驱状态</div>
            </div>
            <div class="item seizure" :style="{ width: scrollvalues + '%', background: '#F9AE45' }">
              <div class="move">{{ scrollvalues }}</div>
              <div class="seizure-txt" style="color: #f9ae45">亚健康状态</div>
            </div>
            <div class="item seizure" :style="{ width: succes + '%', background: '#1FC030' }">
              <div class="move">{{ succes }}</div>
              <div class="seizure-txt" style="color: #1fc030">健康状态</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="thead" style="margin-top: 55px">
      <div class="header-title">
        <div class="lef">
          分值占比例
          <span style="font-size: 14px; color: gray">注：健康分值指根据用户的相关健康数据给出一个综合的分数，健康、饮食、运动占比指数相加为100；每个板块的子项目指数相加为100</span>
        </div>
        <div class="ref">
          <vab-icon icon="edit-box-line" style="font-size: 28px; color: #007aff" />
        </div>
      </div>
    </div>
    <div class="content-value">
      <div class="scanner">
        <div class="irem">
          <div class="item-scanner stor">
            <div class="title">数据指数占比</div>
            <el-input v-model="form.healData" placeholder="请输入内容" type="number">
              <template slot="append">%</template>
            </el-input>
          </div>
          <div class="item-scanner">
            <div class="title">血压指数占比</div>
            <el-input v-model="form.data[0].indexValue" placeholder="请输入内容" type="number">
              <template slot="append">%</template>
            </el-input>
          </div>
          <div class="item-scanner">
            <div class="title">血糖指数占比</div>
            <el-input v-model="form.data[1].indexValue" placeholder="请输入内容" type="number">
              <template slot="append">%</template>
            </el-input>
          </div>
          <div class="item-scanner">
            <div class="title">心率指数占比</div>
            <el-input v-model="form.data[2].indexValue" placeholder="请输入内容" type="number">
              <template slot="append">%</template>
            </el-input>
          </div>
          <div class="item-scanner">
            <div class="title">血氧指数占比</div>
            <el-input v-model="form.data[3].indexValue" placeholder="请输入内容" type="number">
              <template slot="append">%</template>
            </el-input>
          </div>
        </div>
        <div class="irem">
          <div class="item-scanner stor">
            <div class="title">饮食指数占比</div>
            <el-input v-model="form.healDiet" placeholder="请输入内容" type="number">
              <template slot="append">%</template>
            </el-input>
          </div>
          <div class="item-scanner">
            <div class="title">早餐指数占比</div>
            <el-input v-model="form.diet[0].indexValue" placeholder="请输入内容" type="number">
              <template slot="append">%</template>
            </el-input>
          </div>
          <div class="item-scanner">
            <div class="title">午餐指数占比</div>
            <el-input v-model="form.diet[1].indexValue" placeholder="请输入内容" type="number">
              <template slot="append">%</template>
            </el-input>
          </div>
          <div class="item-scanner">
            <div class="title">晚餐指数占比</div>
            <el-input v-model="form.diet[2].indexValue" placeholder="请输入内容" type="number">
              <template slot="append">%</template>
            </el-input>
          </div>
          <div class="item-scanner">
            <div class="title">水果指数占比</div>
            <el-input v-model="form.diet[3].indexValue" placeholder="请输入内容" type="number">
              <template slot="append">%</template>
            </el-input>
          </div>
        </div>
        <div class="irem">
          <div class="item-scanner stor">
            <div class="title">运动指数占比</div>
            <el-input v-model="form.healMove" placeholder="请输入内容" type="number">
              <template slot="append">%</template>
            </el-input>
          </div>
          <div class="item-scanner">
            <div class="title">步数指数占比</div>
            <el-input v-model="form.move[0].indexValue" placeholder="请输入内容" type="number">
              <template slot="append">%</template>
            </el-input>
          </div>
        </div>
      </div>
      <div class="btn-slot">
        <el-button type="primary" @click="submit">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { Bullet } from '@antv/g2plot'
import { vitComHealdetails, vitComHealadd } from '@/api/mall/apps/healthdetection/index.js'
export default {
  data() {
    return {
      form: {
        data: [
          { indexValue: 0, healType: 2 },
          { indexValue: 0, healType: 3 },
          { indexValue: 0, healType: 5 },
          { indexValue: 0, healType: 4 },
        ],
        diet: [
          { indexValue: 0, healType: 1 },
          { indexValue: 0, healType: 2 },
          { indexValue: 0, healType: 3 },
          { indexValue: 0, healType: 4 },
        ],
        move: [{ indexValue: 0, healType: 1 }],
      },
      scroll: 25,
      scrollvalue: 50,
      scrollvalues: 15,
      succes: 10,
    }
  },
  mounted() {
    this.queryInfo()
  },
  methods: {
    queryInfo() {
      vitComHealdetails({}).then((res) => {
        console.log(res.data)
        this.form = res.data
      })
    },
    submit() {
      vitComHealadd({ ...this.form }).then((res) => {
        if (res.code == 0 || res.status == 200) {
          this.$message.success('保存成功')
          this.queryInfo()
        } else {
          this.$message.error('保存失败')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.thead {
  .header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid #e5e5e5;
    .lef {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .line-echart {
    height: 100px;
    .scroll-value {
      width: 100%;
      height: 30px;
      position: relative;
      margin-top: 16px;
      &::before {
        position: absolute;
        top: 35px;
        font-size: 20px;
        font-weight: bold;
        content: '0';
      }
      &::after {
        position: absolute;
        top: 35px;
        right: 0;
        font-size: 20px;
        font-weight: bold;
        content: '100';
      }
      .scroll-view {
        width: 100%;
        height: 100%;
        // border-radius: 50px;
        // background: red;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        .item {
          height: 100%;
          text-align: right;
          line-height: 90px;
          position: relative;
          &:first-of-type {
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
          }
          &:last-of-type {
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
          }
          .seizure-txt {
            position: absolute;
            left: 50%;
            top: 30px;
            font-size: 16px;
            width: 100%;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transform: translateX(-50%);
          }
        }
        .seizure {
          background: #ff0000;
        }
      }
    }
  }
}
.content-value {
  width: 100%;
  .scanner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    margin-top: 32px;
    .item-scanner {
      display: flex;
      align-items: center;
      min-height: 87px;
      padding: 32px 100px;
      white-space: nowrap;
      background: #fbfbfb;
      margin-bottom: 23px;
      .title {
        margin-right: 16px;
      }
      ::v-deep .el-input__inner {
        color: #1fc030;
      }
    }
    .stor {
      background-color: rgba(31, 192, 48, 0.05);
      margin-bottom: 25px;
      .title {
        font-weight: bold;
      }
      ::v-deep .el-input {
        font-size: 24px;
      }
    }
  }
  .btn-slot {
    display: flex;
    justify-content: center;
  }
}
</style>