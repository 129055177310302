<template>
  <div class="container">
    <div class="lef-tbas">
      <el-tabs v-model="activeName" style="height: 100%" :tab-position="'left'">
        <el-tab-pane v-for="(ym, index) in tabs" :key="index" :label="ym.labelName" :value="ym.id" />
      </el-tabs>
    </div>
    <div class="ref_records">
      <!-- 体检记录 -->
      <PhysicalRedords v-if="activeName == 0" :value="userindo" />
      <!-- 既往病史 -->
      <HistoryRedords v-if="activeName == 1" :value="userindo" />
      <!-- 家族病史 -->
      <FamilyRedords v-if="activeName == 2" :value="userindo" />
      <!-- 手术历史 -->
      <SurgicalRedords v-if="activeName == 3" :value="userindo" />
      <!-- 过敏历史 -->
      <AllergyRedords v-if="activeName == 4" :value="userindo" />
      <!-- 就医记录 -->
      <MedicalRedords v-if="activeName == 5" :value="userindo" />
      <!-- 用药记录 -->
      <MedicationRedords v-if="activeName == 6" :value="userindo" />
    </div>
  </div>
</template>

<script>
import PhysicalRedords from './components/Physical-records/index.vue'
// import HistoryRedords from './components/history-records/index.vue'
import HistoryRedords from './components/history-records/index.vue'
import FamilyRedords from './components/Family-records/index.vue'
import SurgicalRedords from './components/Surgical-records/index.vue'
import AllergyRedords from './components/Allergy-records/index.vue'
import MedicalRedords from './components/Medical-records/index.vue'
import MedicationRedords from './components/Medication-records/index.vue'
export default {
  components: {
    PhysicalRedords,
    HistoryRedords,
    FamilyRedords,
    SurgicalRedords,
    AllergyRedords,
    MedicalRedords,
    MedicationRedords,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeName: '0',
      tabs: [
        {
          id: 1,
          labelName: '体检记录',
        },
        {
          id: 2,
          labelName: '既往病史',
        },
        {
          id: 3,
          labelName: '家族病史',
        },
        {
          id: 4,
          labelName: '手术历史',
        },
        {
          id: 5,
          labelName: '过敏历史',
        },
        {
          id: 6,
          labelName: '就医记录',
        },
        {
          id: 7,
          labelName: '用药记录',
        },
      ],
      userindo: this.value,
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  height: calc(100vh - 482px);
  overflow-x: hidden;
  .lef-tbas {
    width: 100px;
    height: 100%;
    margin-right: 25px;
  }
  .ref_records {
    width: calc(100% - 100px);
    height: 100%;
  }
}
::v-deep {
  .el-tabs__nav-wrap::after {
    background: gray;
  }
  .el-tabs__item {
    height: 50px;
  }
  .el-tabs--left .el-tabs__nav-wrap.is-left::after {
    width: 1px;
    background: #e5e5e5;
  }
}
</style>