<template>
  <div class="comprehensive-table-container">
    <el-button class="addBtn" icon="el-icon-plus" type="primary" @click="handleAdd">新增</el-button>
    <el-table border :data="data" lazy row-key="id" style="width: 100%" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
      <el-table-column label="ID" type="index" />
      <el-table-column label="名称" prop="name" />
      <el-table-column align="center" label="是否显示" prop="enable">
        <template #default="scope">
          <el-switch v-model="scope.row.enable" active-color="#13ce66" active-value="1" inactive-color="#ff4949" inactive-value="0" @change="handchenange(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="排序" prop="sort" />
      <el-table-column align="center" label="图片" prop="picUrl">
        <template #default="scope">
          <el-image v-if="scope.row.picUrl" :preview-src-list="srcList" :src="scope.row.picUrl" style="width: 50px; height: 50px" @click="prev(scope.row.picUrl)" />
          <meta content="no-referrer" name="referrer" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="createTime" />
      <el-table-column align="center" label="更新时间" prop="updateTime" />
      <el-table-column align="center" fixed="right" label="操作" prop="address">
        <template #default="scope">
          <el-link style="margin: 0 5px" type="primary" :underline="false" @click="showrow(scope.row)">查看</el-link>
          <el-link style="margin: 0 5px" type="primary" :underline="false">编辑</el-link>
          <el-link style="margin: 0 5px" type="primary" :underline="false" @click="del(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <table-edit ref="edit" v-model="form" :config="config" :options="options" :rules="rules" @submit="submit" />
    <div class="showtable">
      <el-dialog title="查看" :visible.sync="dialogVisible" width="60%">
        <span class="ym_showtb">
          <el-form label-width="100px" :model="tableData">
            <el-form-item label="名称：">
              <el-input v-model="tableData.name" :disabled="true" placeholder="" />
            </el-form-item>
            <el-form-item label="图片：">
              <el-image :src="tableData.picUrl" />
            </el-form-item>
            <el-form-item label="状态：">
              <el-radio-group v-show="tableData.enable == 0" v-model="tableData.enable" :disabled="true">
                <el-radio :label="tableData.enable">关闭</el-radio>
                <el-radio :label="1">启用</el-radio>
              </el-radio-group>
              <el-radio-group v-show="tableData.enable == 1" v-model="tableData.enable" :disabled="true">
                <el-radio :label="0">关闭</el-radio>
                <el-radio :label="tableData.enable">启用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="排序：">
              <el-input v-model="tableData.sort" :disabled="true" placeholder="" />
            </el-form-item>
            <el-form-item label="创建时间：">
              <el-input v-model="tableData.createTime" :disabled="true" placeholder="" />
            </el-form-item>
            <el-form-item label="更新时间：">
              <el-input v-model="tableData.updateTime" :disabled="true" placeholder="" />
            </el-form-item>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import { options, rules, config } from './const/goodscategory'
  import { goodscategoryshop } from '@/api/mall/goods/goodsSpecs'
  import { deleteShopobj, putShopobj } from '@/api/mall/goods/goodsCategory'
  export default {
    data() {
      return {
        options,
        rules,
        config,
        form: {},
        data: [],
        srcList: [],
        dialogVisible: false,
        tableData: '',
      }
    },
    created() {
      this.getObjtype()
    },
    methods: {
      getObjtype() {
        goodscategoryshop({}).then((res) => {
          console.log(res.data)
          this.data = res.data
        })
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      submit() {
        console.log(this.form)
      },
      prev(e) {
        this.srcList = []
        this.srcList.push(e)
      },
      showrow(row) {
        console.log(row)
        this.tableData = row
        this.dialogVisible = true
      },
      del(e) {
        deleteShopobj(e).then(() => {
          this.$message.success('删除成功')
          this.getObjtype()
        })
      },
      handchenange(row) {
        const lis = {
          enable: row.enable,
          id: row.id,
        }
        putShopobj({ ...lis }).then(() => {
          this.$message.success('修改成功')
          this.getObjtype()
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $gap: 10px;
  .addBtn {
    margin-bottom: $gap;
  }
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #d8d8d8;
  }
  ::v-deep .ym_showtb .el-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .el-form-item {
      margin: 0;
      border: 1px solid #fefefe;

      .el-form-item__label {
        background: #fafafa;
      }
      .el-input__inner {
        background: transparent;
        border: transparent;
      }
    }
  }
</style>
