var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "请选择商品",
            visible: _vm.dialogVisible,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    data: _vm.tabData,
                    "max-height": "300px",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "店铺", prop: "shopId" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商品名称",
                      prop: "name",
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商品图片",
                      prop: "picUrls",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-image", {
                              staticStyle: { width: "60px", height: "60px" },
                              attrs: { src: scope.row.picUrls[0] },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商城类目",
                      prop: "categoryId",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "店铺类目" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "卖点",
                      prop: "sellPoint",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "价位",
                      prop: "priceDown",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商品编码",
                      prop: "spuCode",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "规格类型",
                      prop: "specType",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "虚拟销量",
                      prop: "saleNum",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "创建时间",
                      prop: "createTime",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "更新时间",
                      prop: "updateTime",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "是否上架",
                      prop: "shelf",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#13ce66",
                                "active-value": "0",
                                "inactive-color": "#ff4949",
                                "inactive-value": "1",
                              },
                              model: {
                                value: scope.row.shelf,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "shelf", $$v)
                                },
                                expression: "scope.row.shelf",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "积分赠送",
                      prop: "pointsGiveSwitch",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.pointsGiveSwitch == 1
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("开启"),
                                ])
                              : _vm._e(),
                            scope.row.pointsGiveSwitch != 1
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("关闭"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "积分抵扣",
                      prop: "pointsDeductSwitch",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.pointsDeductSwitch == 1
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("开启"),
                                ])
                              : _vm._e(),
                            scope.row.pointsDeductSwitch != 1
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("关闭"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "审核状态",
                      prop: "verifyStatus",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.verifyStatus == 0
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("审核中"),
                                ])
                              : _vm._e(),
                            scope.row.verifyStatus == 1
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("审核通过"),
                                ])
                              : _vm._e(),
                            scope.row.verifyStatus == 2
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("审核不通过"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": 1,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clears } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saverow } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }