import request from '@/utils/request'

// 客情管理-客情事件进度_回执信息
export function uuUserSugarRecordAfterLogadd(data) {
  return request({
    url: `/mall/after/uuUserSugarRecordAfterLog/add`,
    method: 'post',
    data,
  })
}

// -客情管理-客情事件进度
export function uuUserSugarRecordAfterLogqueryList(data) {
  return request({
    url: `/mall/after/uuUserSugarRecordAfterLog/queryList`,
    method: 'post',
    data,
  })
}

// -客情事件-详情
export function uuUserSugarRecordAfterdetails(data) {
  return request({
    url: `/mall/uuUserSugarRecordAfter/details`,
    method: 'post',
    data,
  })
}
