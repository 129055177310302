
import request from '@/utils/request'

export function queryPage(data) {
  return request({
    url: '/upms/shopuser/page',
    method: 'get',
    params: data,
  })
}

export function shopuser(data) {
  return request({
    url: '/upms/shopuser',
    method: 'post',
    data,
  })
}
export function addShopUser(data) {
  return request({
    url: '/upms/shopuser/addShopUser',
    method: 'post',
    data,
  })
}

export function deltobj(data) {
  return request({
    url: '/upms/shopuser/'+data,
    method: 'delete',
  })
}

//物理删除
export function ddeleteSysUser(data) {
  return request({
    url: '/upms/admin/comSysUser/ddeleteSysUser',
    method: 'post',
    data,
  })
}
//物理禁用系统会员用户
export function disabledSysUser(data) {
  return request({
    url: '/upms/admin/comSysUser/disabledSysUser',
    method: 'post',
    data,
  })
}

//删除系统会员用户
export function sysdeleteUser(data) {
  return request({
    url: '/upms/admin/comSysUser/deleteSysUser',
    method: 'post',
    data,
  })
}

export function putpass(data) {
  return request({
    url: '/upms/user/password',
    method: 'PUT',
    data
  })
}


export function putrow(data) {
  return request({
    url: '/upms/shopuser',
    method: 'PUT',
    data
  })
}

//设置拨号/

export function retrieveUserCxcc(data) {
  return request({
    url: '/upms/admin/comSysUser/retrieveUserCxcc',
    method: 'post',
    data,
  })
}