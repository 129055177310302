
import request from '@/utils/request'

export function getDetail(data) {
  return request({
    url: '/mall/signconfig/page',
    method: 'get',
    params: data,
  })
}
export function delDetail(data) {
  return request({
    url: '/mall/signconfig/'+data,
    method: 'delete',
  })
}
export function addDetail(data) {
  return request({
    url: '/mall/signconfig',
    method: 'post',
    data:data,
  })
}
export function putDetail(data) {
  return request({
    url: '/mall/signconfig',
    method: 'put',
    data:data,
  })
}