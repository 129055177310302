var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ym_container",
      style: {
        padding: `0 ${_vm.controls[_vm.index].margin.both}px`,
        background: `${_vm.controls[_vm.index].pageBgColor}`,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "content_canvas",
          style: {
            borderRadius: `${_vm.controls[_vm.index].topAroundRadius}px ${
              _vm.controls[_vm.index].topAroundRadius
            }px ${_vm.controls[_vm.index].bottomAroundRadius}px ${
              _vm.controls[_vm.index].bottomAroundRadius
            }px`,
            background: `${_vm.controls[_vm.index].componentBgColor}`,
            border: `${_vm.borderline}`,
            boxShadow: `${_vm.boxshadown}`,
            marginTop: `${_vm.controls[_vm.index].margin.top}px`,
            marginBottom: `${_vm.controls[_vm.index].margin.bottom}px`,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "container",
              style: {
                display: `${_vm.className[0]}`,
                gridTemplateColumns: `repeat(${
                  _vm.controls[_vm.index].rowCount
                }, 1fr)`,
                paddingBottom: `${_vm.Padingsize}`,
                maxHeight: `${_vm.className[3]}`,
                minHeight: `${_vm.className[5]}`,
                flexWrap: `${_vm.className[4]}`,
              },
            },
            [
              _vm.controls[_vm.index].showStyle == "pageSlide"
                ? _c("div", { staticClass: "pagesize" }, [
                    _c("div", { staticClass: "li" }),
                  ])
                : _vm._e(),
              _vm.controls[_vm.index].showStyle == "singleSlide"
                ? _c("div", { staticClass: "over-x" })
                : _vm._e(),
              _vm._l(_vm.controls[_vm.index].list, function (ym, inde) {
                return _c(
                  "div",
                  {
                    key: inde,
                    staticClass: "card_item",
                    style: {
                      minWidth: `${_vm.className[2]}%`,
                    },
                  },
                  [
                    _vm.controls[_vm.index].mode == "graphic"
                      ? _c("div", { staticClass: "card_ym" }, [
                          _c("div", { staticClass: "picurl" }, [
                            ym.label.control
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "ym_identification",
                                    style: {
                                      color: `${ym.label.textColor}`,
                                      background: `linear-gradient(to bottom, ${ym.label.bgColorStart}, ${ym.label.bgColorEnd})`,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(ym.label.text) + " ")]
                                )
                              : _vm._e(),
                            _c("img", {
                              style: {
                                borderRadius: `${
                                  _vm.controls[_vm.index].aroundRadius
                                }%`,
                                width: `${_vm.controls[_vm.index].imageSize}px`,
                                height: `${
                                  _vm.controls[_vm.index].imageSize
                                }px`,
                              },
                              attrs: { src: ym.imageUrl },
                            }),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "txt",
                              style: {
                                fontSize: `${
                                  _vm.controls[_vm.index].font.size
                                }px`,
                                color: `${_vm.controls[_vm.index].font.color}`,
                                fontWeight: `${
                                  _vm.controls[_vm.index].font.weight
                                }`,
                              },
                            },
                            [_vm._v(" " + _vm._s(ym.title) + " ")]
                          ),
                        ])
                      : _c("div", { staticClass: "card_ym" }, [
                          _vm.controls[_vm.index].mode == "img"
                            ? _c("div", { staticClass: "picurl" }, [
                                ym.label.control
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ym_identification",
                                        style: {
                                          color: `${ym.label.textColor}`,
                                          background: `linear-gradient(to bottom, ${ym.label.bgColorStart}, ${ym.label.bgColorEnd})`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(ym.label.text) + " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("img", {
                                  style: {
                                    borderRadius: `${
                                      _vm.controls[_vm.index].aroundRadius
                                    }%`,
                                    width: `${
                                      _vm.controls[_vm.index].imageSize
                                    }px`,
                                    height: `${
                                      _vm.controls[_vm.index].imageSize
                                    }px`,
                                  },
                                  attrs: { src: ym.imageUrl },
                                }),
                              ])
                            : _c(
                                "div",
                                {
                                  staticClass: "txt",
                                  style: {
                                    fontSize: `${
                                      _vm.controls[_vm.index].font.size
                                    }px`,
                                    color: `${
                                      _vm.controls[_vm.index].font.color
                                    }`,
                                    fontWeight: `${
                                      _vm.controls[_vm.index].font.weight
                                    }`,
                                  },
                                },
                                [_vm._v(" " + _vm._s(ym.title) + " ")]
                              ),
                        ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }