<template>
  <div class="comprehensive-table-container">
    <!-- <el-steps :active="active" align-center finish-status="success">
      <el-step title="填写申请单" />
      <el-step title="审核申请单" />
      <el-step title="审核完成" />
    </el-steps> -->
    <el-card shadow="none">
      <div slot="header" class="clearfix">
        <span>店铺入驻申请</span>
      </div>
      <el-form ref="subForm" label-width="80px" :model="form" :rules="rules">
        <el-row>
          <el-collapse v-model="activeNames">
            <el-collapse-item name="1" title="店铺信息">
              <el-col :span="12">
                <el-form-item label="店铺名:" prop="name">
                  <el-input v-model="form.name" placeholder="店铺名" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电话:" prop="phone">
                  <el-input v-model="form.phone" placeholder="电话" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="所在地区：" prop="applyGeoCode">
                  <el-cascader
                    ref="areas"
                    v-model="form.applyGeoCode"
                    class="fromInput"
                    :options="areaOptions"
                    placeholder="请选择入驻区域"
                    :props="areaProps"
                    size="large"
                    style="width: 100%"
                    @change="changeAreaTree"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item class="detailAddr" label="详细地址" prop="applyAddress">
                  <div @click="handelMaps">
                    <el-input v-model="form.applyAddress" class="fromInput" placeholder="请选择详细地址">
                      <i slot="suffix" class="el-input__icon el-icon-map-location"></i>
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="24">
                <el-form-item label="详细地址:">
                  <div class="clk" @click="maps">
                    <el-input v-model="form.address" placeholder="详细地址" />
                  </div>
                </el-form-item>
              </el-col> -->
              <el-col :span="24">
                <el-form-item label="详细介绍:">
                  <el-input v-model="form.detail" :autosize="{ minRows: 4, maxRows: 8 }" placeholder="详细介绍" type="textarea" />
                </el-form-item>
              </el-col>
            </el-collapse-item>
            <el-collapse-item name="2" title="店长信息">
              <el-col :span="12">
                <el-form-item label="登录账号:" prop="userUsername">
                  <el-input v-model="form.userUsername" placeholder="登录账号" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="登录密码:" prop="userPassword">
                  <el-input v-model="form.userPassword" placeholder="登录密码" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号:" prop="userPhone">
                  <el-input v-model="form.userPhone" placeholder="手机号" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="邮箱:" prop="userEmail">
                  <el-input v-model="form.userEmail" placeholder="邮箱" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="昵称:" prop="userNickname">
                  <el-input v-model="form.userNickname" placeholder="昵称" />
                </el-form-item>
              </el-col>
            </el-collapse-item>
          </el-collapse>
          <el-col :offset="0" :span="24">
            <div class="m-t text-c">
              <el-button type="primary" @click="submit">提交</el-button>
              <el-button>清空</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <el-dialog :before-close="handleClose" title="地图选择" :visible.sync="malshow" width="60%">
      <span>
        <MapGold @addlist="chekeck" />
      </span>
    </el-dialog>
    <!-- 地图 -->
    <maps ref="maps" :infos="form.treeAdr" @on-select="addressSelectHandler" />
  </div>
</template>

<script>
  import MapGold from '@/components/map/map.vue'
  import { addObjrow } from '@/api/mall/shop/apply'
  import Maps from '@/components/maps/maps.vue'
  import { getAreaThree } from '@/api/mall/common'
  export default {
    components: {
      MapGold,
      Maps,
    },
    data() {
      return {
        areaOptions: [], //区域
        form: {
          applyGeoCode: [],
          applyAddress: '',
          longitude: '',
          latitude: '',
          treeAdr: '',
        },
        areaProps: {
          children: 'children',
          label: 'areaName',
          value: 'areaCode',
        }, //区域
        active: 0,
        activeNames: ['1', '2'],
        malshow: false,
        rules: {
          name: [{ required: true, message: '请输入 店铺名', trigger: 'blur' }],
          phone: [
            { required: true, message: '请输入 电话', trigger: 'blur' },
            { min: 11, max: 11, message: '长度在 11 个字符', trigger: 'blur' },
          ],
          userUsername: [{ required: true, message: '请输入 登录账号', trigger: 'blur' }],
          userPassword: [{ required: true, message: '请输入 登录密码', trigger: 'blur' }],
          userPhone: [
            { required: true, message: '请输入 手机号', trigger: 'blur' },
            { min: 11, max: 11, message: '长度在 11 个字符', trigger: 'blur' },
          ],
          userEmail: [{ required: true, message: '请输入 邮箱', trigger: 'blur' }],
          userNickname: [{ required: true, message: '请输入 昵称', trigger: 'blur' }],
        },
      }
    },
    created() {
      this.getAreaThree()
    },
    methods: {
      submit() {
        this.$refs.subForm.validate((valid) => {
          if (valid) {
            this.sub()
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      sub() {
        delete this.form.applyGeoCode
        addObjrow({ ...this.form,tenantId:'1' }).then(() => {
          this.$message.success("新增成功")
          this.$router.push({
            path: 'shopapply',})
          })
      },
      maps() {
        this.malshow = true
      },
      handleClose() {},
      chekeck(row) {
        this.malshow = false
        this.form.address = row.address
        this.form.latitude = row.latitude
        this.form.longitude = row.longitude
      },

      // 详细地址
      addressSelectHandler(rootAddress, name, lat, lng, address) {
        this.form.applyAddress = address + rootAddress
        this.form.longitude = lng
        this.form.latitude = lat
      },
      // 地图
      handelMaps() {
        this.$refs['maps'].shows()
      },
      // 省市区三级联动
      getAreaThree() {
        getAreaThree()
          .then((res) => {
            if (res.data) {
              this.areaOptions = res.data
            }
          })
          .catch(() => {})
      },
      changeAreaTree() {
        const addr = this.$refs['areas'].getCheckedNodes()[0].pathLabels.toString()
        // if (addr.includes('北京城区')) {
        //   this.form.treeAdr = addr.replace('北京城区', '')
        // } else if (addr.includes('重庆城区')) {
        //   this.form.treeAdr = addr.replace('重庆城区', '')
        // } else if (addr.includes('重庆郊县')) {
        //   this.form.treeAdr = addr.replace('重庆郊县', '')
        // } else if (addr.includes('天津城区')) {
        //   this.form.treeAdr = addr.replace('天津城区', '')
        // } else if (addr.includes('上海城区')) {
        //   this.form.treeAdr = addr.replace('上海城区', '')
        // } else {
        this.form.treeAdr = addr
        // }
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
</style>
