<template>
  <div class="comprehensive-table-container">
    <div class="search" style="margin-bottom: 10px">
      <el-form class="demo-form-inline" :inline="true" :model="formInline">
        <el-form-item label="员工姓名">
          <el-input v-model.trim="formInline.realName" placeholder="请输入搜索人姓名" />
        </el-form-item>
        <el-form-item label="客户姓名">
          <el-input v-model.trim="formInline.userName" placeholder="请输入搜索人姓名" />
        </el-form-item>
        <el-form-item label="部门">
          <el-cascader v-model="formInline.postIds" :options="dataSourceLists" :props="{
            checkStrictly: true,
            label: 'orgName', value: 'id'
          }" @focus="fetchData">
            <template slot-scope="{ node, data }">
              <span v-if="!node.isLeaf">{{ data.orgName }}({{ data.children.length }})</span>
            </template>
          </el-cascader>
        </el-form-item>
        <!-- <el-form-item label="日期">
          <el-date-picker v-model="timer" align="right" end-placeholder="结束日期" format="yyyy-MM-dd" range-separator="至"
            start-placeholder="开始日期" type="datetimerange" value-format="yyyy-MM-dd" />
        </el-form-item> -->
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="onSubmit">搜索</el-button>
          <el-button icon="el-icon-refresh" type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-table border :data="tableData" show-summary style="width: 100%" :summary-method="getSummaries">
        <el-table-column align="center" label="员工姓名" prop="realName" width="120" />
        <el-table-column align="center" label="客户姓名" prop="userName" width="120" />
        <el-table-column align="center" label="所属部门" prop="postName" />
        <el-table-column align="center" label="预存余额" prop="remainingAmount" />
        <el-table-column align="center" label="存入总金额" prop="enterAmount" />
        <!-- <el-table-column align="center" label="支出总金额" prop="outAmount" /> -->
        <!-- <el-table-column align="center" fixed="right" label="操作" prop="rateValue">
          <template #default="scope">
            <el-button type="text" @click="showMore(scope.row)">查看</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <el-pagination background :current-page="1" layout="total, sizes, prev, pager, next, jumper"
      :page-size="formInline.size" :total="total" @current-change="handleCurrentChange"
      @size-change="handleSizeChange" />
    <!-- <el-dialog title="查看" :visible.sync="dialogVisible" width="60%" @close="closeDialog">
      <span>
        <el-table border :data="showData" style="width: 100%">
          <el-table-column align="center" label="时间" prop="userName" width="120" />
          <el-table-column align="center" label="汇入预存" prop="profile" />
          <el-table-column align="center" label="使用预存" prop="sunCount" />
          <el-table-column align="center" label="预存金额" prop="todayCount" />
        </el-table>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { orgTrees } from '@/api/mall/role/orgManage'
import { getQuery } from '@/api/mall/health/DepositStatistics/depositStatistics'
export default {
  data() {
    return {
      dialogVisible: false,
      dataSourceLists: null,
      formInline: {
        size: 10,
        current: 1,
      },
      // timer: null,
      total: 0,
      tableData: [],
      showData: [],
    }
  },
  watch: {
    // timer(nVal) {
    //   if (nVal) {
    //     this.formInline.createStartTimer = nVal[0]
    //     this.formInline.createEndTimer = nVal[1]
    //   } else {
    //     delete this.formInline.createStartTimer
    //     delete this.formInline.createEndTimer
    //   }
    // },
  },
  created() {
    this.getUserList()
  },
  methods: {
    async fetchData() {
      const parms = {
        current: this.current,
        size: this.size,
      }
      await orgTrees(parms)
        .then((res) => {
          console.log(res.data)
          if (res.data) {
            this.menuListTable = res.data.records
            this.dataSourceLists = res.data.records
            this.dataSourceLists.map((obj) => {
              obj.label = obj.orgName
              obj.value = obj.id
              const fieldArr = obj.children
              const newFieldArr = fieldArr.map((element) => {
                element.label = element.orgName
                element.value = element.id
                element.children.forEach((item) => {
                  item.label = item.orgName
                  item.value = item.id
                  if (item.children != null) {
                    item.children.forEach((el) => {
                      el.label = el.orgName
                      el.value = el.id
                    })
                  }
                })
                return element
              })
              obj.fieldArr = newFieldArr
              return obj
            })
          }
        })
        .catch(() => { })
      this.listLoading = false
    },
    showMore(row) {
      this.dialogVisible = true
      this.showData.push(row)
    },
    closeDialog() {
      this.dialogVisible = false;
      this.showData = []; // 清空showData数组
    },
    reset() {
      this.timer = []
      this.formInline = {}
      this.formInline.size = 10
      this.formInline.current = 1
      this.getUserList()
    },
    onSubmit() {
      this.formInline.current = 1
      this.getUserList()
    },
    handleCurrentChange(e) {
      this.formInline.current = e
      this.getUserList()
    },
    handleSizeChange(e) {
      this.formInline.size = e
      this.getUserList()
    },
    getUserList() {
      getQuery({ ...this.formInline }).then((res) => {
        this.tableData = res.data.records
        this.formInline.size = res.data.size
        this.formInline.current = res.data.current
        this.total = res.data.total
      })
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          console.log(sums[index], 'sums[index]')
          sums[index] = sums[index].toString().includes('.') ? (sums[index].toFixed(2) + ' 元').trim() : sums[index] + ' 元';
        } else {
          sums[index] = '';
        }
      });

      return sums;
    }
  },
}
</script>

<style></style>