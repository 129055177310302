import request from '@/utils/request'

export function queryUuUserRecord(data) {
  return request({
    url: `/mall/swm/uuUserRecord/queryPage`,
    method: 'post',
    data,
  })
}
/**
 *查询分页列表List信息
 * @param {*} data
 * @returns
 */
export function queryUuUserRecordCase(data) {
  return request({
    url: `/mall/swm/uuUserRecordCase/queryPage`,
    method: 'post',
    data,
  })
}
export function queryList(data) {
  return request({
    url: `/mall/swm/uuUserRecordCase/queryList`,
    method: 'post',
    data,
  })
}