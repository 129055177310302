/**
 * @description 店铺设置api
 * @author
 */

import request from '@/utils/request'

export function getList(query) {
  return request({
    url: '/mall/shopinfo/list',
    method: 'get',
    params: query,
  })
}

export function getPage(query) {
  return request({
    url: '/mall/shopinfo/page',
    method: 'get',
    params: query,
  })
}
//分页列表--查询店铺员工
export function queryShopUserPage(data) {
  return request({
    url: '/upms/shopuser/queryPage',
    method: 'post',
    data: data,
  })
}
export function addObj(obj) {
  return request({
    url: '/mall/shopinfo',
    method: 'post',
    data: obj,
  })
}

export function getObj(id) {
  return request({
    url: '/mall/shopinfo/' + id,
    method: 'get',
  })
}

export function delObj(id) {
  return request({
    url: '/mall/shopinfo/' + id,
    method: 'delete',
  })
}

export function putObj(obj) {
  return request({
    url: '/mall/shopinfo',
    method: 'put',
    data: obj,
  })
}

export function getCount(query) {
  return request({
    url: '/mall/shopinfo/count',
    method: 'get',
    params: query,
  })
}
