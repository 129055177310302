import request from '@/utils/request'
// 接口列表
export function selectMenuUrl(data) {
  return request({
    url: '/admin/comSysUrl/queryPage',
    method: 'post',
    data,
  })
}
// 新增接口
export function urlAdd(data) {
  return request({
    url: '/admin/comSysUrl/add',
    method: 'post',
    data,
  })
}
// 编辑接口
export function urlUpdate(data) {
  return request({
    url: '/admin/comSysUrl/edit',
    method: 'post',
    data,
  })
}

// 删除接口
export function UrlDelete(data) {
  return request({
    url: '/admin/comSysUrl/isDelete',
    method: 'post',
    data,
  })
}
// 查询已授权接口
export function selectRoleUrlId(data) {
  return request({
    url: '/admin/comSysRoleUrl/selectRoleUrlId',
    method: 'post',
    data,
  })
}
// 接口编辑回显
export function queryMenuIdList(data) {
  return request({
    url: '/admin/comSysUrl/queryMenuIdList',
    method: 'post',
    data,
  })
}
