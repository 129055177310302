<template>
  <div>
    <!-- 新增(编辑) -->
    <el-dialog
      class="addDialog"
      :close-on-click-modal="false"
      :title="dialogTitle"
      :visible.sync="addVisible"
      width="40%"
      @close="close"
    >
      <el-form
        ref="addForm"
        label-width="100px"
        :model="addForm"
        :rules="rules"
      >
        <!-- <el-form-item label="角色编码：" prop="roleCode">
          <el-input
            v-model.trim="addForm.roleCode"
            autocomplete="off"
            clearable
            placeholder="请输入"
          />
        </el-form-item> -->
        <el-form-item label="角色名称：" prop="roleName">
          <el-input
            v-model.trim="addForm.roleName"
            autocomplete="off"
            clearable
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="角色描述：">
          <el-input
            v-model.trim="addForm.roleDescription"
            autocomplete="off"
            clearable
            placeholder="请输入"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { rolePageAdd, rolePageUpdate } from '@/api/mall/role/roleManage'
  export default {
    data() {
      // 验证
      let checkRowCode = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入角色编码！'))
        } else {
          callback()
        }
      }
      let checkRowName = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入角色名称！'))
        } else {
          callback()
        }
      }
      return {
        rules: {
          roleCode: [
            {
              required: true,
              validator: checkRowCode,
              trigger: ['blur', 'change'],
            },
          ],
          roleName: [
            {
              required: true,
              validator: checkRowName,
              trigger: ['blur', 'change'],
            },
          ],
        },
        addVisible: false,
        addForm: {},
        dialogTitle: '',
        rowId: '',
        row: {},
      }
    },
    methods: {
      // 添加编辑title
      showEdit(row) {
        console.log(row)
        this.row = row
        if (!row) {
          this.dialogTitle = '添加'
        } else {
          this.dialogTitle = '编辑'
          this.rowId = row.id
          this.addForm = Object.assign({}, row)
        }
        this.addVisible = true
      },
      // 关闭
      close() {
        this.$refs['addForm'].resetFields()
        this.addForm = this.$options.data().addForm
        this.addVisible = false
      },
      // 保存
      save() {
        this.$refs['addForm'].validate(async (valid) => {
          if (valid) {
            const parms = {
              roleCode: this.addForm.roleCode,
              roleName: this.addForm.roleName,
              roleDescription: this.addForm.roleDescription,
            }
            if (!this.row) {
              await rolePageAdd(parms)
                .then(() => {
                  this.MS('新增成功')
                  this.$emit('fetch-data')
                  this.close()
                })
                .catch(() => {})
            } else {
              await rolePageUpdate({ ...parms, id: this.rowId })
                .then(() => {
                  this.MS('修改成功')
                  this.$emit('fetch-data')
                  this.close()
                })
                .catch(() => {})
            }
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .addDialog {
    // .el-form-item__label {
    //   font-weight: 700;
    //   font-size: 14px;
    //   width: 90px;
    // }
    .el-input {
      width: 90%;
    }
    .el-textarea {
      width: 90%;
    }
    .el-form-item {
      margin-bottom: 22px;
    }
  }
</style>
