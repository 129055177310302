<template>
  <div>
    <el-dialog :before-close="handleClose" :close-on-click-modal="false" title="提示" :visible.sync="dialogVisible" width="50%">
      <span>
        <el-form label-width="100px" :model="addForm" :rules="rules">
          <el-form-item label="移交部门：" prop="mallcategory">
            <el-cascader
              v-model="addForm.mallcategory"
              :options="dataSourceLists"
              :props="{
                checkStrictly: true,
              }"
              @change="changemall"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.label }}</span>
                <span v-if="!node.isLeaf">({{ data.children.length }})</span>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item label="指定员工：">
            <el-transfer
              v-model="appointUser"
              :data="appointData"
              filterable
              :props="{
                key: 'id',
                label: 'realName',
              }"
              :titles="['员工列表', '指定员工']"
              @change="teaChange"
              @left-check-change="leftChange"
            />
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { distrtionshow } from '@/api/mall/health/seas/seasManger'
  import { distributiondepartment } from '@/api/mall/seas/index'
  import { orgTrees } from '@/api/mall/role/orgManage'
  export default {
    props: {
      shodist: {
        type: Boolean,
        default: () => {},
      },
      userid: {
        type: Array,
        default: () => {},
      },
    },
    data() {
      return {
        appointUser: [],
        appointData: [],
        dataSourceLists: [],
        value: [],
        addForm: { mallcategory: '' },
        dialogVisible: this.showbloan,
        userinfo: this.userid,
        sysId: null,
        rules: {
          mallcategory: [{ required: true, message: '请选择部门', trigger: 'change' }],
        },
      }
    },
    watch: {
      shodist(newValue, oldValue) {
        if (newValue != oldValue) {
          this.dialogVisible = newValue
          this.fetchData()
        }
      },
      userid(newValue, oldValue) {
        if (newValue != oldValue) {
          this.userinfo = newValue
        }
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      changemall(e) {
        this.getUserList(e[e.length - 1])
        this.sysId = e[e.length - 1]
      },
      async fetchData() {
        this.listLoading = true
        const parms = {
          current: this.current,
          size: this.size,
        }
        await orgTrees(parms)
          .then((res) => {
            console.log(res.data)
            if (res.data) {
              this.menuListTable = res.data.records
              this.dataSourceLists = res.data.records
              this.dataSourceLists.map((obj) => {
                obj.label = obj.orgName
                obj.value = obj.id
                const fieldArr = obj.children
                const newFieldArr = fieldArr.map((element) => {
                  element.label = element.orgName
                  element.value = element.id
                  element.children.forEach((item) => {
                    item.label = item.orgName
                    item.value = item.id
                    if (item.children != null) {
                      item.children.forEach((el) => {
                        el.label = el.orgName
                        el.value = el.id
                      })
                    }
                  })
                  return element
                })
                obj.fieldArr = newFieldArr
                return obj
              })
            }
          })
          .catch(() => {})
        this.listLoading = false
      },
      submit() {
        let usid = []
        this.userinfo.forEach((item) => {
          usid.push(item.id)
        })
        const lts = {
          ids: usid,
          sysId: this.appointUser[0],
        }
        distributiondepartment({ ...lts }).then((res) => {
          if (res.status == 200) {
            this.$message.success('分配成功')
            this.appointUser = []
            this.addForm = { mallcategory: '' }
            this.appointData = []
            this.dataSourceLists = null
            this.$emit('closedistr', false)
          } else {
            this.$message.error(res.msg)
            this.appointUser = []
            this.appointData = []
            this.dataSourceLists = null
            this.$emit('closedistr', false)
          }
        })
      },
      leftChange(e) {
        this.appointData.forEach((item) => {
          if (e[0] == item.id) item.disabled = false
          else item.disabled = true
        })
      },
      teaChange(e) {
        if (!e.length) {
          this.appointData.forEach((item) => {
            item.disabled = false
          })
        }
      },
      async getUserList(e) {
        distrtionshow({ size: 10, current: 1, type: 2, orgId: e }).then((res) => {
          this.appointData = res.data.records
        })
      },
      handleClose() {
        this.addForm = { mallcategory: '' }
        this.dataSourceLists = null
        this.appointData = []
        this.$emit('closedistr', false)
      },
      clear() {
        this.appointData = []
        this.addForm = { mallcategory: '' }
        this.dataSourceLists = null
        this.$emit('closedistr', false)
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep .el-cascader .el-input {
    width: 500px;
  }
</style>
