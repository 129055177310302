<template>
  <div class="cy-container" style="height: 79vh;">
    <el-form
      ref="queryForm"
      :inline="true"
      :model="queryForm"
    >
      <el-form-item label="店铺：" prop="merName">
        <el-select v-model="value" placeholder="请选择 店铺">
          <el-option
            v-for="item in queryForm.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item label="商品：" prop="merName">
        <el-select v-model="value" placeholder="请选择 商品">
          <el-option
            v-for="item in queryForm.type"
            :key="item.value"
            :label="item.label"
            :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item label="是否启用：" prop="merName">
        <el-select v-model="value" placeholder="请选择 是否启用">
          <el-option
            v-for="item in queryForm.type"
            :key="item.value"
            :label="item.label"
            :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          icon="el-icon-search"
          native-type="submit"
          type="warning"
        >
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          native-type="submit"
          type="primary"
        >
          重置
        </el-button>
      </el-form-item>
      <div class="m-b" style="margin-bottom: 5px;">
        <el-form-item label="">
            <el-button
              icon="el-icon-plus"
              native-type="submit"
              type="primary"
            >
              添加
            </el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
    border
    :data="tableData"
    max-height="500"
    style="width: 100%">
    <el-table-column align="center" type="selection" width="55"/>
    <el-table-column align="center" label="店铺" prop="shop" />
    <el-table-column align="center" label="商品" prop="creationtime" />
    <el-table-column align="center" label="商品规格" prop="name"/>
    <el-table-column align="center" label="创建时间" prop="sort"/>
    <el-table-column align="center" label="图片" prop="inventory"/>
    <el-table-column align="center" label="是否启用" prop="enableornot">
      <template #default="scope">
        <el-switch
        v-model="scope.row.enableornot"
        active-color="#13ce66"
        inactive-color="#ff4949"/>
      </template>
    </el-table-column>
    <el-table-column align="center" label="秒杀名称" prop="orderamount"/>
    <el-table-column align="center" label="秒杀低价" prop="type"/>
    <el-table-column align="center" label="已售数量" prop="maturitytype"/>
    <el-table-column align="center" label="总限量" prop="applicablegoods"/>
    <el-table-column align="center" label="每人限购数量" prop="applicablegoods"/>
    <el-table-column align="center" label="分享标题" prop="applicablegoods"/>
    <el-table-column align="center" fixed="right" label="操作">
      <template #default="scope">
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="editor(scope.row.id)">查看</el-link>
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="editor(scope.row.id)">编辑</el-link>
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="editor(scope.row.id)">删除</el-link>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      background
      :current-page="queryForms.current"
      :layout="layout"
      :page-size="queryForms.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
export default {
  data(){
    return{
      queryForm: {
          colStatus: '',
          merName: '',
      },
      tableData:[
        {
          id:'1',
          shop:'ceshi',
          creationtime:'202-7-28',
          name:'cv',
          sort:'1',
          inventory:'100',
          orderamount:'3300',
          type:'代金券',
          maturitytype:'领券后生效',
          enableornot:true,
          applicablegoods:'指定商品可用'
        }
      ],
      queryForms:{size:1,current:10},
      layout:'total, sizes, prev, pager, next, jumper',
      total:20
    }
  },
  methods:{
    handleCurrentChange(){

    },
    handleSizeChange(){
      
    },
    editor(e){
      console.log(e)
    }
  }
}
</script>

<style lang="scss">
</style>