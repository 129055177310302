<template>
  <div
    class="container"
    :style="{
      background: `${controls[index].pageBgColor}`,
    }"
  >
    <div
      class="content"
      :style="{
        background: `${controls[index].componentBgColor}`,
        margin: `${controls[index].margin.top}px ${controls[index].margin.both}px ${controls[index].margin.bottom}px ${controls[index].margin.both}px `,
      }"
    >
      <div
        class="health-card"
        :style="{
          background: controls[index].elementBgColor,
          borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
        }"
      >
        <div class="thead_list">
          <div class="lef_info">
            <img alt="" :src="picUrl" />
          </div>
          <div class="ref_info">
            <div class="health_info">
              <div class="lef">
                <div class="title">刘医生</div>
                <div class="mian_info">主治医师</div>
              </div>
              <div class="ref" style="color: red">忙碌</div>
            </div>
            <div class="Administrative">慢性病科</div>
            <div class="nosocomium">重庆第一人民医院</div>
          </div>
        </div>
        <div class="thead_bottom">擅长: 慢性疾病检查诊断，尤其控长治疗糖尿病、心脑血管疾病等各种身休问题。</div>
      </div>
      <div
        class="health-card"
        :style="{
          background: controls[index].elementBgColor,
          borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
        }"
      >
        <div class="thead_list">
          <div class="lef_info">
            <img alt="" :src="picUrl" />
          </div>
          <div class="ref_info">
            <div class="health_info">
              <div class="lef">
                <div class="title">刘医生</div>
                <div class="mian_info">主治医师</div>
              </div>
              <div class="ref" style="color: red">忙碌</div>
            </div>
            <div class="Administrative">慢性病科</div>
            <div class="nosocomium">重庆第一人民医院</div>
          </div>
        </div>
        <div class="thead_bottom">擅长: 慢性疾病检查诊断，尤其控长治疗糖尿病、心脑血管疾病等各种身休问题。</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    index: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      picUrl: require('@/assets/img/blp/figure.png'),
    }
  },
  computed: {
    ...mapState({
      controls: (state) => state.checkvalue,
    }),
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  .content {
    height: 100%;
  }
  .health-card {
    width: 100%;
    padding: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    &:first-child{
      margin-bottom: 10px;
    }
    .thead_list {
      height: 97px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lef_info {
        width: 107px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .ref_info {
        width: calc(100% - 117px);
        .health_info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .lef {
            display: flex;
            align-items: flex-end;
            margin-bottom: 10px;
            .title {
              font-size: 20px;
              color: #333;
              font-weight: bold;
            }
            .mian_info {
              font-size: 14px;
            }
          }
          .ref {
            font-size: 14px;
            margin-right: 10px;
          }
        }
        .Administrative {
          margin-bottom: 10px;
        }
      }
    }
    .thead_bottom {
      font-size: 14px;
      line-height: 1.5;
      margin-top: 10px;
      color: gray;
    }
  }
}
</style>