<template>
  <div class="container">
    <Search />
    <div class="add-new" style="margin-bottom: 15px">
      <el-button type="primary" @click="add">新增</el-button>
    </div>
    <el-table border :data="tableData" style="width: 100%">
      <el-table-column align="center" label="会员名称" prop="date" />
      <el-table-column align="center" label="性别" prop="name" />
      <el-table-column align="center" label="年龄" prop="address" />
      <el-table-column align="center" label="设备类型" prop="address" />
      <el-table-column align="center" label="设备SN码" prop="address" />
      <el-table-column align="center" label="检测时间" prop="address" />
      <el-table-column align="center" label="检查阶段" prop="address" />
      <el-table-column align="center" label="血糖值mmol/L" prop="address" />
      <el-table-column align="center" label="上传方式" prop="address" />
      <el-table-column align="center" label="状态" prop="address" />
      <el-table-column align="center" fixed="right" label="操作">
        <template slot-scope="">
          <el-button type="text" @click="edit">编辑</el-button>
          <el-button type="text">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="form.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <AddTa :content="infocheck" :labname="info" :show="shoahand" @close="closedia" />
  </div>
</template>

<script>
import Search from './search.vue'
import AddTa from './newTab.vue'
export default {
  components: {
    Search,
    AddTa,
  },
  data() {
    return {
      tableData: [{}],
      total: 0,
      form: { size: 10, current: 1 },
      info: null,
      shoahand: false,
      infocheck: null,
    }
  },
  methods: {
    edit() {
      this.info = '编辑'
      this.shoahand = true
    },
    closedia() {
      this.shoahand = false
      this.info = null
    },
    add() {
      this.info = '新增'
      this.shoahand = true
    },
    handleCurrentChange(e) {
      this.form.current = e
    },
    handleSizeChange(e) {
      this.form.size = e
    },
  },
}
</script>

<style>
</style>