var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "before-close": _vm.handleClosex,
            title: "上传附件",
            visible: _vm.dialogVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { staticClass: "demo-ruleForm", attrs: { "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "文件上传：" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.action,
                        "before-remove": _vm.beforeRemove,
                        data: _vm.additionalData,
                        "file-list": _vm.fileList,
                        headers: { Authorization: _vm.uploadParams.token },
                        limit: _vm.limitnum,
                        multiple: "",
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove,
                        "on-success": _vm.handleSuccess,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.upload },
                        },
                        [_vm._v("点击上传")]
                      ),
                    ],
                    1
                  ),
                  _vm.fileLength === 0 && _vm.handleType
                    ? _c(
                        "p",
                        { staticStyle: { "font-size": "12px", color: "red" } },
                        [_vm._v("请上传文件")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleClose()
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: _vm.fileType, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleOk()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }