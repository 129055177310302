import request from '@/utils/request'

// 模板中心列表
export function queryDiyCanvasPage(data) {
  return request({
    url: '/mall/themeDiyCanvas/queryDiyCanvasPage',
    method: 'post',
    data,
  })
}

//模板中心新增保存
export function themeDiyCanvasaddDiyCanvas(data) {
  return request({
    url: '/mall/themeDiyCanvas/addDiyCanvas',
    method: 'post',
    data,
  })
}

//模板中心编辑保存
export function themeDiyCanvaseditDiyCanvas(data) {
  return request({
    url: '/mall/themeDiyCanvas/editDiyCanvas',
    method: 'post',
    data,
  })
}

//模板暂时删除

export function themeDiyCanvasdeleteDiyCanvas(data) {
  return request({
    url: '/mall/themeDiyCanvas/deleteDiyCanvas',
    method: 'post',
    data,
  })
}

//模板linkurl地址

export function goodscategoryfirstList(data) {
  return request({
    url: '/mall/goodscategory/firstList',
    method: 'post',
    data,
  })
}

//div选择器
export function themeDiyCanvasdivSelector(data) {
  return request({
    url: '/mall/themeDiyCanvas/divSelector',
    method: 'post',
    data,
  })
}

//div选择器-数据渠道-mall_copy
export function themeDiyCanvasdivChannel(data) {
  return request({
    url: '/mall/themeDiyCanvas/divChannel',
    method: 'post',
    data,
  })
}

//div选择器-数据渠道-mall_copy
export function themeDiyCanvaschannel(data) {
  return request({
    url: '/mall/themeDiyCanvas/divChannel',
    method: 'post',
    data,
  })
}

//diy模板发布
export function themeDiyCanvaspushDiyCanvas(data) {
  return request({
    url: '/mall/themeDiyCanvas/pushDiyCanvas',
    method: 'post',
    data,
  })
}
