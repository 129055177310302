var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "lef-tbas" },
      [
        _c(
          "el-tabs",
          {
            staticStyle: { height: "100%" },
            attrs: { "tab-position": "left" },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          _vm._l(_vm.tabs, function (ym, index) {
            return _c("el-tab-pane", {
              key: index,
              attrs: { label: ym.labelName, value: ym.id },
            })
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ref_records" },
      [
        _vm.activeName == 0
          ? _c("PhysicalRedords", { attrs: { value: _vm.userindo } })
          : _vm._e(),
        _vm.activeName == 1
          ? _c("HistoryRedords", { attrs: { value: _vm.userindo } })
          : _vm._e(),
        _vm.activeName == 2
          ? _c("FamilyRedords", { attrs: { value: _vm.userindo } })
          : _vm._e(),
        _vm.activeName == 3
          ? _c("SurgicalRedords", { attrs: { value: _vm.userindo } })
          : _vm._e(),
        _vm.activeName == 4
          ? _c("AllergyRedords", { attrs: { value: _vm.userindo } })
          : _vm._e(),
        _vm.activeName == 5
          ? _c("MedicalRedords", { attrs: { value: _vm.userindo } })
          : _vm._e(),
        _vm.activeName == 6
          ? _c("MedicationRedords", { attrs: { value: _vm.userindo } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }