<template>
  <div class="comprehensive-table-container">
    <!-- <div class="top-title">
      <div v-for="(item, index) in titleList" :key="index" class="top-item">
        <div class="top-icon">
          <span class="el-icon-loading"></span>
        </div>
        <div class="top-desc">
          <span class="price">{{ item.price || 0 }}</span>
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div> -->
    <div style="display: flex; flex-wrap: wrap; margin: 10px 0; background-color: rgb(245, 245, 245); border-radius: 10px">
      <div class="title-item">
        <span>电话号码</span>
        <el-input v-model.trim="formInline.phone" class="title-input" placeholder="请输入用户名称" />
      </div>
      <div class="title-item">
        <span>提现方式</span>
        <el-select v-model="formInline.withdrawWay" class="title-input" placeholder="请选择">
          <el-option v-for="(item, index) in dicValues.withdrawWay" :key="index" :label="item.dictValue" :value="item.dictKey" />
        </el-select>
      </div>
      <div class="title-item">
        <span>提现状态</span>
        <el-select v-model="formInline.withdrawStatus" class="title-input" placeholder="请选择">
          <el-option v-for="(item, index) in dicValues.withdrawStatus" :key="index" :label="item.dictValue" :value="item.dictKey" />
        </el-select>
      </div>
      <div class="title-item">
        <span>提现时间</span>
        <el-date-picker
          v-model="datePicker"
          align="center"
          end-placeholder="选择结束时间"
          range-separator="至"
          start-placeholder="选择起止时间"
          type="daterange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="dateChange"
        />
      </div>
      <div class="title-item">
        <el-button icon="el-icon-search" type="warning" @click="handleSearch">搜索</el-button>
        <el-button icon="el-icon-refresh-left" type="primary" @click="handleReset">重置</el-button>
      </div>
    </div>
    <el-table border :data="userList" :max-height="600" style="width: 100%">
      <el-table-column align="center" label="ID" prop="id" />
      <el-table-column align="center" label="用户名称" prop="userId" width="260">
        <template slot-scope="scope">
          <div class="grid-temp" style="display: flex; align-items: center">
            <el-image :src="scope.row.headimgUrl" style="margin-right: 10px" />
            <div class="user_info" style="text-align: left">
              <div class="user_name">昵称： {{ scope.row.nickName }}</div>
              <div class="user_name">编号： {{ scope.row.userCode }}</div>
              <div class="user_name">手机号: {{ scope.row.phone }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="提现金额" prop="cashTotal" />
      <el-table-column align="center" label="提现手续费" prop="cashRate" />
      <el-table-column align="center" label="实际打款金额" prop="applyAmount" />
      <el-table-column align="center" label="提现方式" prop="withdrawWay">
        <template #default="{ row }">
          {{ getDicKey('withdrawWay', row.paymentMethod) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="收款明细" prop="paymentDetail" />
      <el-table-column align="center" label="提现状态" prop="withdrawStatus">
        <template #default="{ row }">
          {{ getDicKey('withdrawStatus', row.status) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="提现时间" prop="createTime" />
      <el-table-column align="center" label="操作" width="220px">
        <template #default="{ row }">
          <div style="display: flex; align-items: center; justify-content: center">
            <div v-for="item in row.btns" v-show="item.buttonStatus == 0" :key="item.id">
              <el-dropdown
                v-if="item.children && item.children.length"
                style="margin: 0 5px"
                @command="
                  (index) => {
                    handleClickBtn(item.buttonUrl, index, item.children, row)
                  }
                "
              >
                <el-button :type="item.buttonCss">
                  <vab-icon :icon="item.buttonIcon || ''" />
                  {{ item.buttonName }}
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="item2 in item.children" :key="item2.id" :command="item2.id">
                    {{ item2.buttonName }}
                    <vab-icon :icon="item.buttonIcon || ''" />
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                v-else
                size="mini"
                style="margin: 0 5px"
                :type="item.buttonCss"
                @click="
                  handleClickBtn(item.buttonUrl, () => {
                    let arr = []
                    if (item.buttonUrl === 'handleUpdate') {
                      arr = [row, '恢复']
                    } else {
                      arr = [row]
                    }
                    return arr
                  })
                "
              >
                <vab-icon :icon="item.buttonIcon || ''" />
                {{ item.buttonName }}
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.currentPage"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <el-dialog :close-on-click-modal="false" title="提示" :visible.sync="unPassVisible" width="30%" @close="closeDialog">
      <div style="margin-bottom: 20px">请输入未通过原因</div>
      <el-input v-model="unpassForm.content" clearable placeholder="请输入未通过原因" :rows="10" size="normal" type="textarea" />

      <span slot="footer">
        <el-button @click="unPassVisible = false">取消</el-button>
        <el-button :loading="unPassLoading" type="primary" @click="handleConfirmUnpass">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" title="提示" :visible.sync="detailVisible" width="50%" @close="closeDetail">
      <el-table border :data="detailData" :max-height="500" style="width: 100%">
        <el-table-column align="center" label="记录ID" prop="id" />
        <el-table-column align="center" label="用户名称" prop="account" />
        <el-table-column align="center" label="提现金额" prop="withdrawAmount" />
        <el-table-column align="center" label="提现手续费" prop="withdrawFee" />
        <el-table-column align="center" label="实际打款金额" prop="realArrival" />
        <el-table-column align="center" label="提现方式" prop="withdrawWay">
          <template #default="{ row }">
            {{ getDicKey('withdrawWay', row.withdrawWay) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="提现时间" prop="createTime" />
        <el-table-column align="center" label="提现状态" prop="withdrawStatus">
          <template #default="{ row }">
            {{ getDicKey('withdrawStatus', row.status) }}
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px; margin-bottom: 10px">审核时间：{{ detailData[0] ? detailData[0].auditTime : '' }}</div>
      <div v-if="detailData[0] && detailData[0].withdrawStatus == 5" style="margin-bottom: 10px">拒绝原因：{{ detailData[0] ? detailData[0].refuseContent : '' }}</div>
      <div v-if="detailData[0] && detailData[0].withdrawStatus == 4">打款时间：{{ detailData[0] ? detailData[0].successTime : '' }}</div>
      <span slot="footer">
        <el-button :loading="unPassLoading" type="primary" @click="detailVisible = false">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getPage,
    // getWithdrawDetail,
    updateRefuse,
    updatePass,
    doPayment,
  } from '@/api/mall/financial/withdraw'
  import { selectSysDict } from '@/api/mall/common'
  import { checkButton } from '@/utils/mixins'
  import { parseTime } from '@/utils'
  export default {
    mixins: [checkButton],
    data() {
      return {
        titleList: [
          {
            title: '提现金额',
            price: 0,
            key: 'withdrawMiddleAmount',
          },
          {
            title: '提现中金额',
            price: 0,
            key: 'withdrawKeAmount',
          },
          {
            title: '已提现金额',
            price: 0,
            key: 'withdrawAlreadyAmount',
          },
        ],
        unpassForm: {
          content: '',
          id: 0,
        },
        detailVisible: false,
        detailData: [],
        unPassLoading: false,
        unPassVisible: false,
        listLoading: true,
        userList: [],
        datePicker: [],
        withdrawWay: [],
        withdrawStatus: [],
        isSearch: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        dicKeys: ['withdrawStatus', 'withdrawWay'],
        dicValues: {},
        formInline: {
          withdrawWay: '',
          withdrawStatus: '',
          startTime: '',
          endTime: '',
          orgNo: '',
        },
        total: 0,
        queryForm: {
          currentPage: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      openPostMoney(row) {
        this.$confirm('确定要打款到用户账户吗？打款后将无法恢复请谨慎操作', '提示', {
          confirmButtonText: '打款',
          type: 'warning',
        }).then(() => {
          doPayment({
            id: row.id,
            verifyDetail: '通过',
            status: 1, //通过
          }).then(() => {
            this.$message.success('打款成功！')
            this.handleReset()
          })
        })
      },
      openDetailDialog(row) {
        this.detailData.push(row)
        this.detailVisible = true
      },
      closeDetail() {
        this.detailData.splice(0, 1)
        console.log(this.detailData)
        this.detailVisible = false
      },
      handleConfirmUnpass() {
        this.unPassLoading = true
        updateRefuse({
          id: this.unpassForm.id,
          verifyDetail: this.unpassForm.content,
          status: 2, //不通过
        }).then(() => {
          this.$message.success('拒绝成功！')
          this.unPassLoading = false
          this.closeDialog()
        })
      },
      openUnpassDialog(row) {
        this.unpassForm.id = row.id
        this.unPassVisible = true
      },
      closeDialog() {
        this.unPassVisible = false
        this.unpassForm = {
          content: '',
          id: 0,
        }
        this.handleReset()
      },
      handleUpdatePass(row) {
        this.$confirm('确定要审核通过吗？审核通过后将无法恢复，请谨慎操作！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          // const { id } = row
          // updatePass({ id }).then(() => {
          //   this.$message.success('审核通过！')
          //   this.handleReset()
          // })

          updatePass({
            id: row.id,
            verifyDetail: '通过',
            status: 1, //通过
          }).then(() => {
            this.$message.success('打款成功！')
            this.handleReset()
          })
        })
      },
      getDicKey(key, val) {
        if (this.dicValues[key]) {
          const obj = this.dicValues[key].find((item) => item.dictKey == val)
          return obj ? obj.dictValue : ''
        }
        return ''
      },
      handleReset() {
        this.formInline = {
          withdrawWay: '',
          withdrawStatus: '',
          startTime: '',
          endTime: '',
          orgNo: '',
        }
        this.datePicker = []
        this.handleSearch()
      },
      async fetchData(options = {}) {
        this.listLoading = true
        const {
          data: { records: userList, total },
        } = await getPage({ ...this.queryForm, ...options })
        const dicData = await selectSysDict({
          dictTypeList: this.dicKeys,
        })
        // // 获取页面头部卡片信息
        // getWithdrawDetail({}).then((res) => {
        //   Object.keys(res.data).forEach((key) => {
        //     const index = this.titleList.findIndex((item) => item.key === key)
        //     this.titleList[index].price = res.data[key]
        //   })
        // })
        // 遍历对比设置字典
        dicData.data.forEach((item) => {
          const index = this.dicKeys.findIndex((key) => key === item.dictType)
          if (index !== -1) {
            const k = this.dicKeys[index]
            const v = item.dictInfoVoList
            this.$set(this.dicValues, k, v)
          }
        })
        this.handleAddTableButtonRole(userList, {
          handleUpdatePass: (row) => row.withdrawStatus == 0,
          openUnpassDialog: (row) => row.withdrawStatus == 0,
          openPostMoney: (row) => row.withdrawStatus == 2,
        })
        this.userList = userList
        this.total = total
      },
      async handleSearch() {
        this.queryForm.currentPage = 1
        await this.fetchData({ ...this.formInline }, false)
        let temp = false
        Object.keys(this.formInline).forEach((key) => {
          this.formInline[key] && (temp = true)
        })
        this.isSearch = temp
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData({ ...data }, false)
      },
      handleCurrentChange(val) {
        this.queryForm.currentPage = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData({ ...data }, false)
      },
      dateChange(date) {
        if (date && date.length) {
          this.formInline.startTime = date[0]
          this.formInline.endTime = parseTime(new Date(date[1]).getTime() + (1000 * 60 * 60 * 24 - 1000))
          console.log(this.formInline.endTime)
        } else {
          this.formInline.startTime = ''
          this.formInline.endTime = ''
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .title-input {
    width: 160px;
    margin-left: 5px;
  }
  .title-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
  .title-item span {
    width: 80px;
    text-align: center;
  }
  .top-title {
    display: flex;
    justify-content: space-between;
    padding: 20px 100px;
    background-color: rgb(245, 245, 245);
    border-radius: 10px;
    .top-item {
      display: flex;
      align-items: center;
      .top-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        font-size: 35px;
        color: rgb(41, 121, 255);
        border-radius: 50%;
        box-shadow: 0 0 10px #ccc;
        .el-icon-loading {
          animation: 0s !important;
        }
      }
      .top-desc {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .price {
          margin-bottom: 5px;
          font-size: 18px;
          font-weight: 700;
        }
        span {
          margin-top: 5px;
          font-size: 16px;
        }
      }
    }
  }
</style>
