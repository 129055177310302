var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("quill-editor", {
        directives: [{ name: "screen", rawName: "v-screen" }],
        ref: "myQuillEditor",
        staticClass: "quilleditor",
        attrs: { options: _vm.editorOption },
        on: {
          blur: function ($event) {
            return _vm.onEditorBlur($event)
          },
          change: function ($event) {
            return _vm.onEditorChange($event)
          },
          focus: function ($event) {
            return _vm.onEditorFocus($event)
          },
          ready: function ($event) {
            return _vm.onEditorReady($event)
          },
        },
        model: {
          value: _vm.articleForm.artContent,
          callback: function ($$v) {
            _vm.$set(_vm.articleForm, "artContent", $$v)
          },
          expression: "articleForm.artContent",
        },
      }),
      _c(
        "el-upload",
        {
          ref: "Quillupload",
          staticClass: "pictureQuill",
          staticStyle: { display: "none" },
          attrs: {
            action: _vm.action,
            "before-upload": _vm.bfUpload,
            "file-list": _vm.fileListQuill,
            headers: _vm.headers,
            "list-type": "picture-card",
            "on-success": _vm.handleSuccessQuill,
          },
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
      _c(
        "el-upload",
        {
          staticClass: "pictureQuillVideo",
          staticStyle: { display: "none" },
          attrs: {
            action: _vm.action,
            "before-upload": _vm.beforeUploadVideo,
            headers: _vm.headers,
            "on-progress": _vm.uploadVideoProcess,
            "on-success": _vm.handleVideoSuccess,
            "show-file-list": false,
          },
        },
        [
          _vm.videoForm.showVideoPath != "" && !_vm.videoFlag
            ? _c(
                "video",
                {
                  attrs: {
                    controls: "",
                    height: "100",
                    src: _vm.videoForm.showVideoPath,
                    width: "400",
                  },
                },
                [_vm._v("您的浏览器不支持视频播放")]
              )
            : _vm.videoForm.showVideoPath == "" && !_vm.videoFlag
            ? _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })
            : _vm._e(),
          _vm.videoFlag == true
            ? _c("el-progress", {
                staticStyle: { "margin-top": "7px" },
                attrs: { percentage: _vm.videoUploadPercent, type: "circle" },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }