import { render, staticRenderFns } from "./FormDialog.vue?vue&type=template&id=721a8802&scoped=true"
import script from "./FormDialog.vue?vue&type=script&lang=js"
export * from "./FormDialog.vue?vue&type=script&lang=js"
import style0 from "./FormDialog.vue?vue&type=style&index=0&id=721a8802&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "721a8802",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\健康约-后管-前端\\health-front-backend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('721a8802')) {
      api.createRecord('721a8802', component.options)
    } else {
      api.reload('721a8802', component.options)
    }
    module.hot.accept("./FormDialog.vue?vue&type=template&id=721a8802&scoped=true", function () {
      api.rerender('721a8802', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mall/shop/shopCategory/components/FormDialog.vue"
export default component.exports