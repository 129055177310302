<template>
  <el-dialog
    :before-close="handleClose"
    title="编辑"
    :visible.sync="dialogVisible"
    width="60%">
    <span>
      <el-form  :inline="true"  :model="formlist" :rules="rules">
        <el-form-item label="累计佣金金额:" prop="commissionTotal">
          <el-input v-model="formlist.commissionTotal" placeholder="请输入 累计佣金金额"/>
        </el-form-item>
        <el-form-item label="已提现佣金金额:" prop="commissionWithdrawal">
          <el-input v-model="formlist.commissionWithdrawal" placeholder="请输入 已提现佣金金额"/>
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="clea">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {putobj} from "@/api/mall/distUsers/list"
export default {
  props:{
    showform:{
      type:Boolean,
      default:()=>{}
    },
    edirow:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
   return{
     dialogVisible:this.showform,
     formlist:{},
     rules:{
       commissionTotal:[
         { required: true, message: '请输入累计佣金金额', trigger: 'blur' },
       ],
       commissionWithdrawal:[
         { required: true, message: '请输入已提现佣金金额', trigger: 'blur' },
       ]
     }
   }
  },
  watch:{
    showform(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisible=newValue
      }
    },
    edirow(newValue,oldValue){
      if(newValue!=oldValue){
        this.formlist=newValue
      }
    },
  },
  methods:{
    clea(){
      this.$emit("closeedit",false)
      this.dialogVisible=false,
      this.formlist={}
    },
    save(){
      putobj({...this.formlist}).then(()=>{
        this.$message.success("修改成功")
        this.$emit("closeedit",false)
        this.dialogVisible=false,
        this.formlist={}
      })
    },
    handleClose(){
      this.$emit("closeedit",false)
      this.dialogVisible=false,
      this.formlist={}
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header{
  border-bottom: 1px solid #e5e5e5;
}
</style>