import request from '@/utils/request'
// 蓝牙设备列表
export function queryPage(data) {
  return request({
    url: `/mall/swm/uuUserGlueDevice/queryPage`,
    method: 'post',
    data,
  })
}

// 蓝牙设备编辑
export function editColumn(data) {
  return request({
    url: `/mall/swm/uuUserGlueDevice/edit`,
    method: 'post',
    data,
  })
}
// 蓝牙设备添加
export function addColumn(data) {
  return request({
    url: `/mall/swm/uuUserGlueDevice/add`,
    method: 'post',
    data,
  })
}
// 蓝牙设备删除
export function deleteColumn(data) {
  return request({
    url: `/mall/swm/uuUserGlueDevice/delete`,
    method: 'post',
    data,
  })
}
// 蓝牙设备状态
export function disableColumn(data) {
  return request({
    url: `/mall/swm/uuUserGlueDevice/disable`,
    method: 'post',
    data,
  })
}

// 配置类型（所有文章类型）
export function addColumnType(data) {
  return request({
    url: `/user/uuUserGlueDevice/add`,
    method: 'post',
    data,
  })
}
