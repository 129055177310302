import request from '@/utils/request'

//表格拖拽列--添加or编辑
export function crmColumnDropadd(data) {
  return request({
    url: `/mall/crm/crmColumnDrop/add`,
    method: 'post',
    data,
  })
}

//表格可拖拽列-设置服务查询
export function crmColumnDropqueryPage(data) {
  return request({
    url: `/mall/crm/crmColumnDrop/queryPage`,
    method: 'post',
    data,
  })
}
