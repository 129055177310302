var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        title: "编辑",
        visible: _vm.dialogVisible,
        width: "40%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: { "label-width": "100px", model: _vm.addForm },
            },
            [
              _c(
                "div",
                { staticClass: "dialogForm" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "demo-form-inline",
                      attrs: { inline: true, label: "店铺", prop: "shopId" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { disabled: "", placeholder: "请选择 店铺" },
                          model: {
                            value: _vm.addForm.shopId,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "shopId", $$v)
                            },
                            expression: "addForm.shopId",
                          },
                        },
                        _vm._l(_vm.options, function (ym, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: ym.name, value: ym.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "demo-form-inline",
                      attrs: { inline: true, label: "名称", prop: "name" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "请输入 名称" },
                        model: {
                          value: _vm.addForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "name", $$v)
                          },
                          expression: "addForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "demo-form-inline",
                      attrs: { inline: true, label: "排序", prop: "sort" },
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          "controls-position": "right",
                          disabled: "",
                          placeholder: "排序",
                        },
                        model: {
                          value: _vm.addForm.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "sort", $$v)
                          },
                          expression: "addForm.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "demo-form-inline",
                      attrs: { inline: true, label: "模板类型", prop: "type" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.addForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "type", $$v)
                            },
                            expression: "addForm.type",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled: _vm.addForm.type == 1 ? false : true,
                                label: "1",
                              },
                            },
                            [_vm._v("卖家承担运费")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled: _vm.addForm.type == 2 ? false : true,
                                label: "2",
                              },
                            },
                            [_vm._v("卖家包邮")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.addForm.type == 1
                ? _c(
                    "div",
                    { staticClass: "showhand" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            label: "计费方式",
                            prop: "chargeType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { input: _vm.contName },
                              model: {
                                value: _vm.addForm.chargeType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "chargeType", $$v)
                                },
                                expression: "addForm.chargeType",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.addForm.chargeType == 1
                                        ? false
                                        : true,
                                    label: "1",
                                  },
                                },
                                [_vm._v("按件数")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.addForm.chargeType == 2
                                        ? false
                                        : true,
                                    label: "2",
                                  },
                                },
                                [_vm._v("按重量")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.addForm.chargeType == 3
                                        ? false
                                        : true,
                                    label: "3",
                                  },
                                },
                                [_vm._v("按体积")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            label: `首` + _vm.labelName,
                            prop: "firstNum",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              "controls-position": "right",
                              disabled: "",
                              placeholder: `请输入 首` + _vm.labelName,
                            },
                            model: {
                              value: _vm.addForm.firstNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "firstNum", $$v)
                              },
                              expression: "addForm.firstNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            label: "首运费",
                            prop: "firstFreight",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              "controls-position": "right",
                              disabled: "",
                              placeholder: "首运费",
                            },
                            model: {
                              value: _vm.addForm.firstFreight,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "firstFreight", $$v)
                              },
                              expression: "addForm.firstFreight",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            label: `续` + _vm.labelName,
                            prop: "continueNum",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              "controls-position": "right",
                              disabled: "",
                              placeholder: `请输入 续` + _vm.labelName,
                            },
                            model: {
                              value: _vm.addForm.continueNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "continueNum", $$v)
                              },
                              expression: "addForm.continueNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            label: "续运费",
                            prop: "continueFreight",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              "controls-position": "right",
                              disabled: "",
                              placeholder: "续运费",
                            },
                            model: {
                              value: _vm.addForm.continueFreight,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "continueFreight", $$v)
                              },
                              expression: "addForm.continueFreight",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }