<template>
  <div class="cy-container">
    <!-- 添加按钮 -->
    <el-form ref="queryForm" :inline="true" :model="queryForm" @submit.native.prevent>
      <el-form-item>
        <el-button
          v-for="item in fixedButton"
          :key="item.id"
          :plain="item.cssType && item.cssType == 'plain' ? true : false"
          :type="item.buttonCss"
          @click.native="handleClickBtn(item)"
        >
          <span v-if="!item.buttonIcon"></span>
          <vab-icon v-else :icon="item.buttonIcon" />
          {{ item.buttonName }}
        </el-button>
        <!-- <el-button icon="el-icon-plus" type="primary" @click="handelEdit">
          添加版本
        </el-button> -->
      </el-form-item>
    </el-form>
    <!-- 表格列表 -->
    <el-table v-loading="listLoading" border :data="tableData" default-expand-all>
      <el-table-column align="center" label="版本名称" prop="versionName" show-overflow-tooltip />
      <el-table-column align="center" label="版本号" prop="versionNo" show-overflow-tooltip />
      <el-table-column align="center" label="强制更新" prop="isUpdate" show-overflow-tooltip>
        <template #default="{ row }">
          <span v-if="row.isUpdate == 1">强制更新</span>
          <span v-if="row.isUpdate == 2">非强制更新</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="类型" prop="appType" show-overflow-tooltip>
        <template #default="{ row }">
          <span v-if="row.appType == 1">全部</span>
          <span v-if="row.appType == 2">苹果</span>
          <span v-if="row.appType == 3">安卓</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="更新时间" prop="updateOnline" show-overflow-tooltip>
        <template #default="{ row }">
          <span>{{ row.updateOnline | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" prop="isDelete" show-overflow-tooltip width="100px">
        <template #default="{ row }">
          <el-tag :type="row.isDelete === 0 ? 'success' : 'danger'">
            {{ row.isDelete === 0 ? '正常' : '删除' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="operationShow" align="center" label="操作">
        <template #default="{ row }">
          <!-- <el-button type="text" @click="handelLook(row)">查看</el-button>
          <el-button type="text" @click="handelEdit(row)">编辑</el-button>
          <el-button type="text" @click="handelDelete(row)">删除</el-button> -->
          <el-button
            v-for="item in listButton"
            :key="item.id"
            :plain="item.cssType && item.cssType == 'plain' ? true : false"
            :type="item.buttonCss"
            @click.native="handleClickBtn(item, row)"
          >
            <span v-if="!item.buttonIcon"></span>
            <vab-icon v-else :icon="item.buttonIcon" />
            {{ item.buttonName }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination background :current-page="current" :layout="layout" :page-size="size" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <!-- 新增(编辑) -->
    <el-dialog class="addDialog" :close-on-click-modal="false" :title="dialogTitle" :visible.sync="addVisible" width="50%" @close="close">
      <el-form ref="addForm" label-width="100px" :model="addForm" :rules="rules">
        <el-form-item label="版本名称" prop="versionName">
          <el-input v-model.trim="addForm.versionName" autocomplete="off" clearable placeholder="请输入" style="width: 50%" />
        </el-form-item>

        <el-form-item label="版本号" prop="versionNo">
          <el-input v-model.trim="addForm.versionNo" autocomplete="off" clearable placeholder="请输入" style="width: 50%" />
        </el-form-item>
        <!-- <el-form-item label="更新时间" prop="updateOnline">
          <el-date-picker
            v-model="addForm.updateOnline"
            align="center"
            class="fromInput"
            clearable
            type="datetime"
          />
        </el-form-item> -->
        <el-form-item label="强制更新" prop="isUpdate">
          <el-radio-group v-model="addForm.isUpdate">
            <el-radio v-for="item in isUpdateList" :key="item.dictKey" :label="item.dictKey">
              {{ item.dictValue }}
            </el-radio>
            <!-- <el-radio :label="2">翻译</el-radio>
          <el-radio :label="3">转载</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="更新类型" prop="appType">
          <el-radio-group v-model="addForm.appType">
            <el-radio v-for="item in appTypeList" :key="item.dictKey" :label="item.dictKey">
              {{ item.dictValue }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="苹果下载地址" prop="iosUrl">
          <el-input v-model.trim="addForm.iosUrl" autocomplete="off" clearable placeholder="请输入" style="width: 40%" />
          <span style="font-size: 12px; color: #7f7f7f">AppStore中App项目下载目录，可从下载App页面点击分享，拷贝链接</span>
        </el-form-item>
        <el-form-item label="安卓下载地址" prop="androidUrl">
          <el-input v-model.trim="addForm.androidUrl" autocomplete="off" clearable placeholder="请输入" style="width: 40%" />
          <span style="font-size: 12px; color: #7f7f7f">安卓链接为应用的下载地址</span>
        </el-form-item>
        <el-form-item label="更新内容" prop="versionContent">
          <!-- <el-input
            v-model.trim="addForm.versionContent"
            autocomplete="off"
            clearable
            placeholder="请输入"
            style="height: 200px"
            type="textarea"
          />  -->
          <quill-editor ref="myQuillEditor" v-model="addForm.versionContent" class="quilleditor" :options="editorOption" style="height: 150px" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 查看 -->
    <el-dialog class="addDialog" :close-on-click-modal="false" title="查看" :visible.sync="lookVisible" width="40%">
      <el-form ref="addForm" label-width="150px" :model="lookForm">
        <el-form-item label="版本名称：">
          <el-row class="color117">{{ lookForm.versionName }}</el-row>
        </el-form-item>
        <el-form-item label="版本号：">
          <el-row class="color117">{{ lookForm.versionNo }}</el-row>
        </el-form-item>
        <el-form-item label="更新时间：">
          <el-row class="color117">
            {{ lookForm.updateOnline | formatDate }}
          </el-row>
        </el-form-item>
        <el-form-item label="强制更新：">
          <el-row class="color117">
            <span v-if="lookForm.isUpdate == 1">强制更新</span>
            <span v-if="lookForm.isUpdate == 2">非强制更新</span>
          </el-row>
        </el-form-item>
        <el-form-item label="更新类型：">
          <el-row class="color117">
            <span v-if="lookForm.appType == 1">全部</span>
            <span v-if="lookForm.appType == 2">苹果</span>
            <span v-if="lookForm.appType == 3">安卓</span>
          </el-row>
        </el-form-item>
        <el-form-item label="苹果下载地址：">
          <el-row class="color117">{{ lookForm.iosUrl }}</el-row>
        </el-form-item>
        <el-form-item label="安卓下载地址：">
          <el-row class="color117">{{ lookForm.androidUrl }}</el-row>
        </el-form-item>
        <el-form-item class="constans" label="更新内容：">
          <el-row class="color117">
            <span v-html="lookForm.versionContent"></span>
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="lookVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { queryPage, addVersion, editVersion, isDelete } from '@/api/mall/role/versionManage'
  import { selectRoleMenuButtonList } from '@/api/mall/common'
  import { quillEditor } from 'vue-quill-editor'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import '@/assets/css/quillEditor.css'
  // 工具栏配置
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
    [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
    [{ indent: '-1' }, { indent: '+1' }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
    ['clean'], // 清除文本格式-----['clean']
  ]
  export default {
    components: {
      quillEditor,
    },

    data() {
      return {
        operationShow: false,
        fixedButton: [],
        listButton: [],
        lookVisible: false,
        appTypeList: [
          {
            dictKey: 1,
            dictValue: '强制更新',
          },
          {
            dictKey: 2,
            dictValue: '非强制更新',
          },
        ],
        isUpdateList: [
          {
            dictKey: 1,
            dictValue: '全部',
          },
          {
            dictKey: 2,
            dictValue: '苹果',
          },
          {
            dictKey: 3,
            dictValue: '安卓',
          },
        ],
        listLoading: false,
        tableData: [],
        current: 1,
        queryForm: {},
        size: 10,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        dialogTitle: '',
        lookForm: {},
        addForm: {
          versionName: '',
          versionNo: '',
          appType: 1,
          isUpdate: 1,
          iosUrl: '',
          androidUrl: '',
          versionContent: '',
        },
        rules: {
          versionName: [
            {
              required: true,
              message: '请输入版本名称',
              trigger: ['blur', 'change'],
            },
          ],
          versionNo: [
            {
              required: true,
              message: '请输入版本号',
              trigger: ['blur', 'change'],
            },
          ],
          // updateOnline: [
          //   {
          //     required: true,
          //     message: '请选择更新时间',
          //     trigger: ['blur', 'change'],
          //   },
          // ],
          versionContent: [
            {
              required: true,
              message: '请输入版本更新内容',
              trigger: ['blur', 'change'],
            },
          ],
        },
        rowId: '',
        addVisible: false,
        editorOption: {
          modules: {
            toolbar: {
              container: toolbarOptions, //自定义工具栏
            },
          },
          //主题
          theme: 'snow',
          placeholder: '请输入正文',
        },
      }
    },
    created() {
      this.fetchData()
      this.selectRoleMenuButtonList()
    },
    methods: {
      // 按钮点击
      handleClickBtn(item, row) {
        if (item.buttonUrl) {
          this[item.buttonUrl](row)
        }
      },
      // 按钮权限
      selectRoleMenuButtonList() {
        selectRoleMenuButtonList({
          roleIdStrList: this.$store.getters['acl/roleList'],
          menuId: this.$route.meta.id,
        })
          .then((res) => {
            if (res.data) {
              this.fixedButton = res.data.filter(function (item) {
                return item.buttonType == 3
              })
              this.listButton = res.data.filter(function (item) {
                return item.buttonType == 2
              })
              if (this.listButton.length > 0) {
                this.operationShow = true
              } else {
                this.operationShow = false
              }
            }
          })
          .catch(() => {})
      },
      // 列表
      async fetchData() {
        this.listLoading = true
        await queryPage({
          current: this.current,
          size: this.size,
        })
          .then((res) => {
            if (res.data) {
              this.tableData = res.data.records
              this.total = res.data.total
            }
          })
          .catch(() => {})
        this.listLoading = false
      },
      // 编辑
      handelEdit(row) {
        if (row) {
          this.showEdit(row)
        } else {
          this.showEdit()
        }
      },
      // 查看
      handelLook(row) {
        this.lookForm = Object.assign({}, row)
        this.lookVisible = true
      },
      // 添加编辑title
      showEdit(row) {
        this.row = row
        if (!row) {
          this.dialogTitle = '添加'
        } else {
          this.dialogTitle = '编辑'
          this.rowId = row.id
          this.addForm = Object.assign({}, row)
        }
        this.addVisible = true
      },
      // 关闭
      close() {
        this.$refs['addForm'].resetFields()
        this.addForm = this.$options.data().addForm
        this.addVisible = false
      },
      // 保存
      save() {
        this.$refs['addForm'].validate(async (valid) => {
          if (valid) {
            const parms = {
              versionName: this.addForm.versionName,
              versionNo: this.addForm.versionNo,
              appType: this.addForm.appType,
              isUpdate: this.addForm.isUpdate,
              // updateOnline: this.addForm.updateOnline,
              iosUrl: this.addForm.iosUrl,
              androidUrl: this.addForm.androidUrl,
              versionContent: this.addForm.versionContent,
              tenantId: '1',
            }
            if (!this.row) {
              await addVersion(parms)
                .then(() => {
                  this.MS('新增成功')
                  this.fetchData()
                  this.close()
                })
                .catch(() => {})
            } else {
              await editVersion({ ...parms, id: this.rowId })
                .then(() => {
                  this.MS('修改成功')
                  this.fetchData()
                  this.close()
                })
                .catch(() => {})
            }
          }
        })
      },
      // 删除
      handelDelete(row) {
        this.$confirm('是否要删除此行?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            isDelete({ id: row.id })
              .then(() => {
                this.MS('删除成功')
                this.fetchData()
                this.close()
              })
              .catch(() => {})
          })
          .catch(() => {})
      },
      // 分页
      handleSizeChange(val) {
        this.size = val
        this.fetchData()
      },
      // 分页
      handleCurrentChange(val) {
        this.current = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .constans {
    ::v-deep(.el-form-item__content) {
      p {
        margin: 0px;
      }
    }
  }

  .filterItems {
    width: 150px;
  }

  ::v-deep(.el-textarea) {
    .el-textarea__inner {
      height: 200px;
    }
  }
</style>
