<template>
  <div class="tabs">
    <el-card class="tabs-card" shadow="hover">
      <el-tabs v-model="activeName">
        <el-tab-pane label="订单" name="first">
          <el-table border :data="tableData" height="280">
            <el-table-column align="center" label="订单编号" prop="orderNo" />
            <el-table-column align="center" label="下单用户" prop="phone" />
            <el-table-column align="center" label="订单日期" prop="createTime" />
            <el-table-column align="center" label="订单金额" prop="paymentPrice" />
            <el-table-column align="center" label="下单员工" prop="profile">
              <template #default="scope">{{ scope.row.realName }}{{ scope.row.profile == '' ? '' :'(' + scope.row.profile + ')'  }}</template>
            </el-table-column>
            <!-- <el-table-column align="center" label="状态" prop="statusDesc" /> -->
            <el-table-column align="center" label="订单状态 / 物流状态 / 质检审核" width="240">
  <template #default="{ row }">
    <span v-if="row.statusDesc"> {{ row.statusDesc }}/</span>
    <span v-if="row.logisticsStatus == 0"> 在途</span>
    <span v-if="row.logisticsStatus == 1"> 揽收/</span>
    <span v-if="row.logisticsStatus == 2"> 疑难/</span>
    <span v-if="row.logisticsStatus == 3"> 签收/</span>
    <span v-if="row.logisticsStatus == 4"> 退签/</span>
    <span v-if="row.logisticsStatus == 5"> 派件/</span>
    <span v-if="row.logisticsStatus == 6"> 退回/</span>
    <span v-if="row.logisticsStatus == 7"> 转投/</span>
    <span v-if="row.logisticsStatus == 8"> 清关/</span>
    <span v-if="row.logisticsStatus == 14"> 拒签/</span>
    <span v-if="row.logisticsStatus == null"> - /</span>
    <span v-if="row.quaState == 1"> 待质检</span>
    <span v-if="row.quaState == 2"> 已通过</span>
    <span v-if="row.quaState == 3"> 已驳回</span>
    <span v-if="row.quaState == null"> - </span>
  </template>
</el-table-column>
            <el-table-column align="center" label="所购产品" prop="product" show-overflow-tooltip />
          </el-table>
        </el-tab-pane>
        <!-- <el-tab-pane label="区域管理" name="second">敬请期待</el-tab-pane> -->
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import { queryPage } from '@/api/mall/health/boardperformc/index'
export default {
  components: {},
  data() {
    return {
      activeName: 'first',
      tableData: [],
    }
  },
  created() {
    this.queryPage()
  },
  methods: {
    queryPage() {
      queryPage({ size: 1000, current: 1 }).then((res) => {
        this.tableData = res.data.records
      })
    },
  },
}
</script>

<style lang="scss" scoped>
:deep() {
  .el-card {
    height: 383px !important;
  }
}
</style>
