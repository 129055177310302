var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.dialogFormVisible, width: "30%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "120px",
            model: _vm.form,
            rules: _vm.rulesform,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "发货方式：", prop: "deliveryType" } },
            _vm._l(_vm.delieryTypyList, function (item) {
              return _c(
                "el-radio",
                {
                  key: item.id,
                  attrs: { label: item.id },
                  model: {
                    value: _vm.form.delieryTypy,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "delieryTypy", $$v)
                    },
                    expression: "form.delieryTypy",
                  },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            1
          ),
          _vm.form.delieryTypy === 10
            ? _c(
                "el-form-item",
                { attrs: { label: "物流公司：", prop: "deliveryCompany" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "mb20 query-item query-input",
                      attrs: { clearable: "", placeholder: "请选择物流公司" },
                      model: {
                        value: _vm.form.deliveryCompany,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "deliveryCompany", $$v)
                        },
                        expression: "form.deliveryCompany",
                      },
                    },
                    _vm._l(_vm.deliveryCompanyList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.delieryTypy === 10
            ? _c(
                "el-form-item",
                { attrs: { label: "快递单号：", prop: "deliveryCode" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.deliveryCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deliveryCode", $$v)
                      },
                      expression: "form.deliveryCode",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.delieryTypy === 20
            ? _c(
                "el-form-item",
                {
                  attrs: { label: "预计取货时间：", prop: "expectReceiveTime" },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      align: "center",
                      "picker-options": _vm.pickerOptions,
                      placeholder: "选择时间",
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.form.expectReceiveTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "expectReceiveTime", $$v)
                      },
                      expression: "form.expectReceiveTime",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.delieryTypy === 20
            ? _c(
                "el-form-item",
                { attrs: { label: "备注：" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.expectReceiveRemark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "expectReceiveRemark", $$v)
                      },
                      expression: "form.expectReceiveRemark",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }