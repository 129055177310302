var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "质检信息",
            visible: _vm.dialogVisible,
            width: "75%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c("div", { staticClass: "theader_title" }, [
                _c("div", { staticClass: "txt" }, [_vm._v("订单信息")]),
              ]),
              _c(
                "el-form",
                {
                  attrs: {
                    inline: true,
                    "label-width": "100px",
                    model: _vm.savform,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入产品名称" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品单价：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入产品单价" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "购买类型：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入购买类型" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "成交单价：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入成交单价" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品数量：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入商品数量" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品金额：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入商品金额" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品说明：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入商品说明" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款方式：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择付款方式" },
                          on: {
                            focus: function ($event) {
                              return _vm.queryList(1)
                            },
                          },
                          model: {
                            value: _vm.savform.interId,
                            callback: function ($$v) {
                              _vm.$set(_vm.savform, "interId", $$v)
                            },
                            expression: "savform.interId",
                          },
                        },
                        _vm._l(_vm.optionsinter, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.labelName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "使用预存：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入使用预存/定金" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否到款：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择是否到款" },
                          on: {
                            focus: function ($event) {
                              return _vm.queryList(1)
                            },
                          },
                          model: {
                            value: _vm.savform.interId,
                            callback: function ($$v) {
                              _vm.$set(_vm.savform, "interId", $$v)
                            },
                            expression: "savform.interId",
                          },
                        },
                        _vm._l(_vm.optionsinter, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.labelName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否带票：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择是否带票" },
                          on: {
                            focus: function ($event) {
                              return _vm.queryList(1)
                            },
                          },
                          model: {
                            value: _vm.savform.interId,
                            callback: function ($$v) {
                              _vm.$set(_vm.savform, "interId", $$v)
                            },
                            expression: "savform.interId",
                          },
                        },
                        _vm._l(_vm.optionsinter, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.labelName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "theader_title" }, [
                    _c("div", { staticClass: "txt" }, [_vm._v("订单信息")]),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件人：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入收件人" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "初联日期：" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请选择选择日期", type: "date" },
                        model: {
                          value: _vm.savform.value1,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "value1", $$v)
                          },
                          expression: "savform.value1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请输入联系电话" },
                          model: {
                            value: _vm.savform.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.savform, "phone", $$v)
                            },
                            expression: "savform.phone",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _c(
                              "div",
                              {
                                staticClass: "cal",
                                on: {
                                  click: function ($event) {
                                    return _vm.callphone(_vm.savform.phone)
                                  },
                                },
                              },
                              [_vm._v("拨打")]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "核单说明：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入核单说明" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件省份：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择渠道来源" },
                          on: {
                            focus: function ($event) {
                              return _vm.queryList(2)
                            },
                          },
                          model: {
                            value: _vm.savform.channelId,
                            callback: function ($$v) {
                              _vm.$set(_vm.savform, "channelId", $$v)
                            },
                            expression: "savform.channelId",
                          },
                        },
                        _vm._l(_vm.optionschannelId, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.labelName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件地区：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择咨询产品" },
                          on: {
                            focus: function ($event) {
                              return _vm.queryList(6)
                            },
                          },
                          model: {
                            value: _vm.savform.goodId,
                            callback: function ($$v) {
                              _vm.$set(_vm.savform, "goodId", $$v)
                            },
                            expression: "savform.goodId",
                          },
                        },
                        _vm._l(_vm.optionsgoodId, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.labelName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件地址：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入收件地址" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件邮编：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入收件邮编" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "送货说明：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入送货说明" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "复购次数：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择复购次数" },
                          on: {
                            focus: function ($event) {
                              return _vm.queryList(1)
                            },
                          },
                          model: {
                            value: _vm.savform.interId,
                            callback: function ($$v) {
                              _vm.$set(_vm.savform, "interId", $$v)
                            },
                            expression: "savform.interId",
                          },
                        },
                        _vm._l(_vm.optionsinter, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.labelName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单类型：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择订单类型" },
                          on: {
                            focus: function ($event) {
                              return _vm.queryList(1)
                            },
                          },
                          model: {
                            value: _vm.savform.interId,
                            callback: function ($$v) {
                              _vm.$set(_vm.savform, "interId", $$v)
                            },
                            expression: "savform.interId",
                          },
                        },
                        _vm._l(_vm.optionsinter, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.labelName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注信息：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入备注信息" },
                        model: {
                          value: _vm.savform.interContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.savform, "interContent", $$v)
                          },
                          expression: "savform.interContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("保存"),
                      ]),
                      _c("el-button", { attrs: { type: "" } }, [
                        _vm._v("通过"),
                      ]),
                      _c("el-button", { attrs: { type: "" } }, [
                        _vm._v("驳回"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  attrs: { border: "", data: _vm.tablist, "max-height": "300" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "序号",
                      type: "index",
                      width: "55",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "订单编号",
                      prop: "orderNo",
                      width: "220",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "订单日期",
                      prop: "createTime",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "订单金额",
                      prop: "paymentPrice",
                      "show-overflow-tooltip": "",
                      width: "140",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "下单员工",
                      prop: "profile",
                      "show-overflow-tooltip": "",
                      width: "140",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(scope.row.realName) +
                                _vm._s(
                                  scope.row.profile == ""
                                    ? ""
                                    : "(" + scope.row.profile + ")"
                                )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "状态",
                      prop: "statusDesc",
                      "show-overflow-tooltip": "",
                      width: "80",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "所购产品",
                      prop: "product",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "下单地址",
                      prop: "address",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": 1,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }