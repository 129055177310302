<template>
  <div class="container">
    <div class="lef-tbas">
      <el-tabs v-model="activeName" style="height: 100%" :tab-position="'left'">
        <el-tab-pane v-for="(ym, index) in tabs" :key="index" :label="ym.labelName" :value="ym.id" />
      </el-tabs>
    </div>
    <div class="ref_records">
      <!-- 血压 -->
      <PhysicalRedords v-if="activeName == 0" :value="userindo" />
      <!-- 血糖 -->
      <HistoryRedords v-if="activeName == 1" :value="userindo" />
      <!-- 心率 -->
      <FamilyRedords v-if="activeName == 2" :value="userindo" />
      <!-- 血氧 -->
      <!-- <SurgicalRedords v-if="activeName == 3" :value="userindo" /> -->
      <!-- 身高 -->
      <!-- <AllergyRedords v-if="activeName == 4" :value="userindo" /> -->
      <!-- 体重 -->
      <!-- <MedicalRedords v-if="activeName == 5" :value="userindo" /> -->
      <!-- 跑步 -->
      <!-- <MedicationRedords v-if="activeName == 6" :value="userindo" /> -->
    </div>
  </div>
</template>

<script>
import PhysicalRedords from './components/Physical-records/index.vue'
// import HistoryRedords from './components/history-records/index.vue'
import HistoryRedords from './components/history-records/index.vue'
import FamilyRedords from './components/Family-records/index.vue'
// import SurgicalRedords from './components/Surgical-records/index.vue'
// import AllergyRedords from './components/Allergy-records/index.vue'
// import MedicalRedords from './components/Medical-records/index.vue'
// import MedicationRedords from './components/Medication-records/index.vue'
export default {
  components: {
    PhysicalRedords,
    HistoryRedords,
    FamilyRedords,
    // SurgicalRedords,
    // AllergyRedords,
    // MedicalRedords,
    // MedicationRedords,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeName: '0',
      tabs: [
        {
          id: 1,
          labelName: '血压',
        },
        {
          id: 2,
          labelName: '血糖',
        },
        {
          id: 3,
          labelName: '心率',
        },
        // {
        //   id: 4,
        //   labelName: '血氧',
        // },
        // {
        //   id: 5,
        //   labelName: '身高',
        // },
        // {
        //   id: 6,
        //   labelName: '体重',
        // },
        // {
        //   id: 7,
        //   labelName: '跑步',
        // },
      ],
      userindo: this.value,
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  height: calc(100vh - 482px);
  overflow-x: hidden;
  .lef-tbas {
    height: 100%;
    margin-right: 25px;
  }
  .ref_records {
    width: calc(100% - 100px);
    height: 100%;
  }
}
::v-deep {
  .el-tabs__nav-wrap::after {
    background: gray;
  }
  .el-tabs__item {
    height: 50px;
    display: flex;
    justify-content: center;
    text-align: left;
    align-items: center;
  }
  .el-tabs--left .el-tabs__nav-wrap.is-left::after {
    width: 1px;
    background: #e5e5e5;
  }
}
</style>