var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "lock-scroll": true,
        modal: true,
        title: "风格模板",
        visible: _vm.dialogVisible,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("span", [
        _c(
          "div",
          { staticClass: "style-container" },
          _vm._l(_vm.stylellist, function (ym, index) {
            return _c(
              "div",
              {
                key: index,
                class: _vm.active == ym.navStyle ? "ym_item active" : "ym_item",
                on: {
                  click: function ($event) {
                    _vm.active = ym.navStyle
                  },
                },
              },
              [_c("img", { attrs: { alt: "", src: ym.url } })]
            )
          }),
          0
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }