var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.detailDialog
        ? _c(
            "el-dialog",
            {
              staticClass: "detailDialog",
              attrs: { visible: _vm.detailDialog },
              on: {
                "update:visible": function ($event) {
                  _vm.detailDialog = $event
                },
              },
            },
            [
              _c("div", { staticClass: "waper" }, [
                _c("div", [
                  _c("p", { staticClass: "artTitle" }, [
                    _vm._v(_vm._s(_vm.articleForm.artTitle)),
                  ]),
                  _c("div", { staticClass: "artdiv" }, [
                    _c("p", { staticClass: "artli" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatDate")(_vm.articleForm.createTime)
                          )
                        ),
                      ]),
                      _c("span", { staticClass: "ml30 mr5" }, [
                        _vm._v(_vm._s(_vm.articleForm.artTypeName)),
                      ]),
                      _c("span", [_vm._v("/")]),
                      _c("span", { staticClass: "ml5 mr5" }, [
                        _vm._v(
                          " " + _vm._s(_vm.articleForm.artColumnName) + " "
                        ),
                      ]),
                      _vm.articleForm.artTags
                        ? _c("span", [_vm._v("/")])
                        : _vm._e(),
                      _c("span", { staticClass: "ml5" }, [
                        _vm._v(_vm._s(_vm.articleForm.artTags)),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticStyle: { padding: "10px 60px" } }, [
                  _c("div", { staticClass: "mt30" }, [
                    _c("span", [_vm._v("摘要：")]),
                    _c("span", [_vm._v(_vm._s(_vm.articleForm.artShort))]),
                  ]),
                  _vm.articleForm.artLogo
                    ? _c(
                        "div",
                        {
                          staticClass: "mt20 articlelis",
                          staticStyle: {
                            width: "40%",
                            height: "40%",
                            margin: "20px auto",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              width: "100%",
                              height: "100%",
                              margin: "0px auto",
                            },
                            attrs: {
                              alt: "",
                              fit: "fill",
                              src: _vm.articleForm.artLogo,
                              srcset: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm.articleForm.artImg
                    ? _c(
                        "div",
                        {
                          staticClass: "mt20 articlelis",
                          staticStyle: {
                            width: "63%",
                            height: "52%",
                            margin: "20px auto",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              width: "100%",
                              height: "100%",
                              margin: "0px auto",
                            },
                            attrs: {
                              alt: "",
                              fit: "fill",
                              src: _vm.articleForm.artImg,
                              srcset: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c("div", {
                    staticClass: "mt30 articlelis",
                    staticStyle: { "text-indent": "30px" },
                    domProps: { innerHTML: _vm._s(_vm.articleForm.artContent) },
                  }),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }