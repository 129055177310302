import request from '@/utils/request'
// 积分编辑
export function pointsconfig(data) {
  return request({
    url: '/mall/pointsconfig/update',
    method: 'post',
    data,
  })
}

// 积分查询
export function details(data) {
  return request({
    url: '/mall/pointsconfig/details',
    method: 'post',
    data,
  })
}

