<template>
  <div class="container">
    <el-table border :data="tablist" max-height="300">
      <el-table-column align="center" label="序号" type="index" width="55" />
      <el-table-column align="center" label="提交时间" prop="submitTime" />
      <el-table-column align="center" label="客情备注" prop="remark" show-overflow-tooltip />
      <el-table-column align="center" label="提交部门" prop="postName" show-overflow-tooltip />
      <el-table-column align="center" label="提交员工" prop="realName" />
      <el-table-column align="center" label="客情部门" prop="afterPostName" show-overflow-tooltip />
      <el-table-column align="center" label="客情员工" prop="afterRealName" show-overflow-tooltip />
      <el-table-column align="center" label="客情状态" prop="lebalName" width="100" />
      <!--     //客情状态 1-待客情 2-已处理 3-驳回 -->
      <el-table-column align="center" label="处理状态">
        <template #default="{ row }">
          <div>
            <el-tag v-if="row.afterState == 1" type="succes">处理中</el-tag>
            <el-tag v-if="row.afterState == 2" type="danger">已处理</el-tag>
            <el-tag v-if="row.afterState == 3" type="warning">驳回</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="处理时间">
        <template #default="{ row }">
          <div>
            <span v-if="row.afterState == '1'"></span>
            <span v-if="row.afterState == '2'">{{ row.handleTime }}</span>
            <span v-if="row.afterState == '3'">{{ row.handleTime }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作">
        <template #default="{ row }">
          <div>
            <el-link type="primary" :underline="false" @click="hand(row)">查看</el-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="1" layout="total, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <showhand :rowst="rolis" :showhide="showl" @closehand="closeh" />
  </div>
</template>

<script>
import { Aftersales } from '@/api/mall/health/casemy/index'
import showhand from './components/Eventschedule/index.vue'
export default {
  components: {
    showhand,
  },
  props: {
    sound: {
      type: Object,
      default: () => {},
    },
    record: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showl: false,
      rolis: null,
      total: 0,
      tablist: [],
      showlist: false,
      showtblist: null,
      form: {
        size: 10,
        current: 1,
        recordId: this.record,
      },
    }
  },
  watch: {
    record(newValue, oldValue) {
      if (newValue != oldValue) {
        this.form.recordId = newValue
      }
    },
  },
  created() {
    // this.form.recordId=this.userinfo.id
    this.uuUserSugarRecordLog()
  },
  methods: {
    hand(row) {
      this.rolis = row
      this.showl = true
    },
    closeh() {
      this.showl = false
    },
    closeshow() {
      this.showlist = false
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.uuUserSugarRecordLog()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.uuUserSugarRecordLog()
    },
    //客情处理接口请求
    uuUserSugarRecordLog() {
      Aftersales({ ...this.form }).then((res) => {
        this.total = res.data.total
        this.form.size = res.data.size
        this.form.current = res.data.current
        this.tablist = res.data.records
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
