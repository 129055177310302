<template>
  <div
    ref="imageTofile"
    class="controls_cont"
    :style="{
      background: fundemo.pageBgColor,
    }"
  >
    <div
      ref="can"
      class="center-content"
      :style="{
        backgroundImage: `url(` + fundemo.bgUrl + `)`,
        backgroundSize: `100%`,
        backgroundRepeat: `no-repeat`,
      }"
    >
      <div
        class="top_thead"
        :style="{
          background: fundemo.topNavColor,
        }"
        @click="checkRightToll('header', -1)"
      >
        <div class="thead">
          <div
            v-if="fundemo.navBarSwitch"
            class="contanner_content"
            :style="{
              justifyContent: fundemo.navStyle == '1' ? 'center' : 'left',
              textAlign: fundemo.textImgPosLink,
            }"
          >
            <div v-if="fundemo.navStyle == 2 || fundemo.navStyle == 3" class="pic">
              <img alt="" :src="fundemo.topNavImg" />
            </div>
            <div
              v-if="fundemo.navStyle == 1 || fundemo.navStyle == 2"
              class="title"
              :style="{
                width: fundemo.navStyle == 2 ? 'auto' : '100%',
                textAlign: fundemo.textImgPosLink,
                color: fundemo.textNavColor,
              }"
            >
              {{ fundemo.title }}
            </div>
            <div v-if="fundemo.navStyle == 3" class="search">
              <i class="el-icon-search"></i>
              请输入搜索内容
            </div>
          </div>
        </div>
      </div>
      <div
        class="cnotent_crotols"
        :style="{
          width: `calc(100% - ${fundemo.template.margin.both * 2}px`,
          margin: `0 auto`,
        }"
      >
        <!-- <draggable v-model="controls" animation="1000" chosen-class="chosen" force-fallback="true" group="people" scroll-speed="1000"> -->
        <div v-for="(cortal, index) in controls" :key="index" :class="checknum == index ? 'active_canvas' : 'canvas_itembox'" @click="checkRightToll(cortal.type, index)">
          <component :is="contentCanvas[0].get(cortal.type)" :index="index" />
        </div>
        <!-- </draggable> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import draggable from 'vuedraggable'
import contentCanvas from './components/centercanvas'
import html2canvas from 'html2canvas'
import { canvasPicurl } from '@/api/mall/canvaspicurl/index'
export default {
  components: {
    // draggable
    html2canvas,
  },
  data() {
    return {
      initlist: null,
      contentCanvas: contentCanvas,
      htmlUrl: '',
    }
  },
  computed: {
    ...mapGetters(['state']),
    fundemo() {
      return this.$store.state.checkdiy.thead
    },
    checknum() {
      return this.$store.state.checknumber
    },
    controls() {
      return this.$store.state.checkcanvas
    },
  },
  mounted() {
    this.$baseEventBus.$on('deletecanvas', () => {
      this.deleteCanvas()
    })
    this.$baseEventBus.$on('canvaspicUrl-view', () => {
      this.canvaspicUrl()
    })
  },
  methods: {
    canvaspicUrl() {
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.imageTofile, {
        backgroundColor: null,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png')
        this.htmlUrl = url // 把生成的base64位图片上传到服务器,生成在线图片地址
        this.sendUrl()
      })
    },
    sendUrl() {
      canvasPicurl(this.htmlUrl).then((res) => {
        console.log(res, 'chuaner')
      })
    },
    //删除
    deleteCanvas() {
      // console.log(this.$store.state.checkdiy.value.length - 1)
      this.$store.state.checkvalue.splice(this.checknum, 1)
      this.$store.state.checkcanvas.splice(this.checknum, 1)
      this.$store.state.checknumber = this.$store.state.checkvalue.length - 1
      if (this.$store.state.checkvalue.length - 1 < 0) {
        this.$store.state.checkcomtorls = { type: 'header' }
      } else {
        this.btn = false
      }
    },
    // 开启编辑区域
    checkRightToll(list, num) {
      this.$store.state.checkcomtorls = { type: list }
      this.$store.state.checknumber = num
      this.$store.state.checklist = this.$store.state.checkvalue[num]
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss" scoped>
.controls_cont {
  width: 375px;
  min-height: 90%;
  max-height: 90%;
  overflow: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b6b6b6;
    border-radius: 5px;
  }
  .cnotent_crotols {
    width: 100%;
    min-height: 400px;
    padding-right: 3px;
  }

  .active_canvas {
    border: 2px solid #1780ff;
    box-shadow: 1px 1px 3px #a6cefe;
  }
  .canvas_itembox {
    border: 2px dashed transparent;
    &:hover {
      cursor: pointer;
      border: 2px dashed #1780ff;
    }
  }
}
.center-content {
  width: 375px;
}
.top_thead {
  width: 100%;
  height: 64px;
  .thead {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    background-image: url('https://uniapp.v5.niuteam.cn/public/static/ext/diyview/img/preview_head.png');
    background-repeat: no-repeat;
    background-size: 100% 64px;
    .contanner_content {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0 10px;
      margin-bottom: 3px;
      .pic {
        margin-right: 10px;
      }
      img {
        width: 42px;
        height: 28px;
      }
      .title {
        max-width: 80px;
        height: 28px;
        overflow: hidden;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .search {
        display: flex;
        align-items: center;
        width: 200px;
        max-width: calc(100% - 98px);
        height: 32px;
        background: white;
        border: 1px solid #eeeeee;
        border-radius: 50px;
        i {
          margin-right: 5px;
          text-indent: 1em;
        }
      }
    }
  }
}
</style>
