<template>
  <el-dialog append-to-body :before-close="handleClose" :close-on-click-modal="false" :lock-scroll="true" :modal="true" title="风格模板" :visible.sync="dialogVisible" width="60%">
    <span>
      <div class="style-container">
        <div v-for="(ym, index) in stylellist" :key="index" :class="active == ym.navStyle ? 'ym_item active' : 'ym_item'" @click="active = ym.navStyle">
          <img alt="" :src="ym.url" />
        </div>
      </div>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      stylehide: {
        type: Boolean,
        default: () => {},
      },
    },
    data() {
      return {
        active: '1',
        dialogVisible: false,
        stylellist: [
          {
            url: 'https://uniapp.v5.niuteam.cn/public/static/ext/diyview/img/nav_style/nav_style0.png',
            navStyle: '1',
          },
          {
            url: 'https://uniapp.v5.niuteam.cn/public/static/ext/diyview/img/nav_style/nav_style1.png',
            navStyle: '2',
          },
          {
            url: 'https://uniapp.v5.niuteam.cn/public/static/ext/diyview/img/nav_style/nav_style2.png',
            navStyle: '3',
          },
        ],
      }
    },
    watch: {
      stylehide(newValue, oldValue) {
        if (newValue != oldValue) {
          this.dialogVisible = newValue
        }
      },
    },
    methods: {
      handleClose() {
        this.$emit('closestyle', false)
      },
      save() {
        this.$emit('closestyle', this.active)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .style-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 100%;
    height: 300px;
    max-height: 300px;
    overflow: auto;
    .ym_item {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 300px;
      height: 100px;
      cursor: pointer;
      background: #e5e5e5;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid #1780ff;
      }
      img {
        max-width: 98%;
        max-height: 100%;
      }
    }
    .active {
      border: 1px solid #1780ff;
    }
  }
  ::v-deep {
    .el-dialog__header {
      border-bottom: 1px solid #e5e5e5;
    }
  }
</style>
