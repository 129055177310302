var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.act,
            callback: function ($$v) {
              _vm.act = $$v
            },
            expression: "act",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "客户详细", name: "1" } }),
          _c("el-tab-pane", { attrs: { label: "日志记录", name: "2" } }),
        ],
        1
      ),
      _vm.act == 1
        ? _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.dormdate } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("体检时间")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-date-picker", {
                              attrs: { placeholder: " 年 月 日", type: "date" },
                              model: {
                                value: _vm.dormdate.userYear,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "userYear", $$v)
                                },
                                expression: "dormdate.userYear",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("手机号")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "手机号" },
                              model: {
                                value: _vm.dormdate.phone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "phone", $$v)
                                },
                                expression: "dormdate.phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("姓名")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "姓名" },
                              model: {
                                value: _vm.dormdate.userName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "userName", $$v)
                                },
                                expression: "dormdate.userName",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("性别")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "性别" },
                              model: {
                                value: _vm.dormdate.userSex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "userSex", $$v)
                                },
                                expression: "dormdate.userSex",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("年龄")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "年龄" },
                              model: {
                                value: _vm.dormdate.userAge,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "userAge", $$v)
                                },
                                expression: "dormdate.userAge",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("患病年限")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "患病年限" },
                              model: {
                                value: _vm.dormdate.fattyIlness,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "fattyIlness", $$v)
                                },
                                expression: "dormdate.fattyIlness",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("身高 cm")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("体重 kg")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("BMI")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("腰围")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("血压")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("心率")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("是否规律用药")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "身高" },
                              model: {
                                value: _vm.dormdate.userHeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "userHeight", $$v)
                                },
                                expression: "dormdate.userHeight",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "体重" },
                              model: {
                                value: _vm.dormdate.userWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "userWeight", $$v)
                                },
                                expression: "dormdate.userWeight",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "BMI" },
                              model: {
                                value: _vm.dormdate.userMbi,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "userMbi", $$v)
                                },
                                expression: "dormdate.userMbi",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "腰围" },
                              model: {
                                value: _vm.dormdate.userWaist,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "userWaist", $$v)
                                },
                                expression: "dormdate.userWaist",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "血压" },
                              model: {
                                value: _vm.dormdate.userPressure,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "userPressure", $$v)
                                },
                                expression: "dormdate.userPressure",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "心率" },
                              model: {
                                value: _vm.dormdate.userHeart,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "userHeart", $$v)
                                },
                                expression: "dormdate.userHeart",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.medRegularly,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dormdate, "medRegularly", $$v)
                                  },
                                  expression: "dormdate.medRegularly",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark",
                            staticStyle: {
                              height: "72px",
                              "line-height": "72px",
                            },
                          },
                          [_vm._v("是否打针")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" 胰岛素 "),
                            _c("el-checkbox", { attrs: { label: "" } }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" 药名： "),
                            _c("el-input", {
                              attrs: { placeholder: "药名" },
                              model: {
                                value: _vm.dormdate.casePos1[0].insulinTitle,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dormdate.casePos1[0],
                                    "insulinTitle",
                                    $$v
                                  )
                                },
                                expression: "dormdate.casePos1[0].insulinTitle",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" 剂量： "),
                            _c("el-input", {
                              attrs: { placeholder: "剂量" },
                              model: {
                                value: _vm.dormdate.casePos1[0].caseDose,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dormdate.casePos1[0],
                                    "caseDose",
                                    $$v
                                  )
                                },
                                expression: "dormdate.casePos1[0].caseDose",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" GLP-1 类 "),
                            _c("el-checkbox", { attrs: { label: "" } }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" 药名： "),
                            _c("el-input", {
                              attrs: { placeholder: "药名" },
                              model: {
                                value: _vm.dormdate.casePos1[1].insulinTitle,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dormdate.casePos1[1],
                                    "insulinTitle",
                                    $$v
                                  )
                                },
                                expression: "dormdate.casePos1[1].insulinTitle",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" 剂量： "),
                            _c("el-input", {
                              attrs: { placeholder: "剂量" },
                              model: {
                                value: _vm.dormdate.casePos1[1].caseDose,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dormdate.casePos1[1],
                                    "caseDose",
                                    $$v
                                  )
                                },
                                expression: "dormdate.casePos1[1].caseDose",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark",
                            staticStyle: {
                              height: "146px",
                              "line-height": "146px",
                            },
                          },
                          [_vm._v("是否口服药")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" 药名： "),
                            _c("el-input", {
                              attrs: { placeholder: "药名" },
                              model: {
                                value: _vm.dormdate.casePos2[0].insulinTitle,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dormdate.casePos2[0],
                                    "insulinTitle",
                                    $$v
                                  )
                                },
                                expression: "dormdate.casePos2[0].insulinTitle",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" 一天 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos2[0].caseCishu,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos2[0],
                                        "caseCishu",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dormdate.casePos2[0].caseCishu",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" 次,一次 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos2[0].casePian,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos2[0],
                                        "casePian",
                                        $$v
                                      )
                                    },
                                    expression: "dormdate.casePos2[0].casePian",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" 片，一片 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos2[0].caseMg,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos2[0],
                                        "caseMg",
                                        $$v
                                      )
                                    },
                                    expression: "dormdate.casePos2[0].caseMg",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" mg "),
                          ]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" 药名： "),
                            _c("el-input", {
                              attrs: { placeholder: "药名" },
                              model: {
                                value: _vm.dormdate.casePos2[1].insulinTitle,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dormdate.casePos2[1],
                                    "insulinTitle",
                                    $$v
                                  )
                                },
                                expression: "dormdate.casePos2[1].insulinTitle",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" 一天 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos2[1].caseCishu,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos2[1],
                                        "caseCishu",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dormdate.casePos2[1].caseCishu",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" 次,一次 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos2[1].casePian,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos2[1],
                                        "casePian",
                                        $$v
                                      )
                                    },
                                    expression: "dormdate.casePos2[1].casePian",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" 片，一片 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos2[1].caseMg,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos2[1],
                                        "caseMg",
                                        $$v
                                      )
                                    },
                                    expression: "dormdate.casePos2[1].caseMg",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" mg "),
                          ]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" 药名： "),
                            _c("el-input", {
                              attrs: { placeholder: "药名" },
                              model: {
                                value: _vm.dormdate.casePos2[2].insulinTitle,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dormdate.casePos2[2],
                                    "insulinTitle",
                                    $$v
                                  )
                                },
                                expression: "dormdate.casePos2[2].insulinTitle",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" 一天 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos2[2].caseCishu,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos2[2],
                                        "caseCishu",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dormdate.casePos2[2].caseCishu",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" 次,一次 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos2[2].casePian,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos2[2],
                                        "casePian",
                                        $$v
                                      )
                                    },
                                    expression: "dormdate.casePos2[2].casePian",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" 片，一片 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos2[2].caseMg,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos2[2],
                                        "caseMg",
                                        $$v
                                      )
                                    },
                                    expression: "dormdate.casePos2[2].caseMg",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" mg "),
                          ]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" 药名： "),
                            _c("el-input", {
                              attrs: { placeholder: "药名" },
                              model: {
                                value: _vm.dormdate.casePos2[3].insulinTitle,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dormdate.casePos2[3],
                                    "insulinTitle",
                                    $$v
                                  )
                                },
                                expression: "dormdate.casePos2[3].insulinTitle",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _vm._v(" 一天 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos2[3].caseCishu,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos2[3],
                                        "caseCishu",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dormdate.casePos2[3].caseCishu",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" 次,一次 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos2[3].casePian,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos2[3],
                                        "casePian",
                                        $$v
                                      )
                                    },
                                    expression: "dormdate.casePos2[3].casePian",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" 片，一片 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos2[3].caseMg,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos2[3],
                                        "caseMg",
                                        $$v
                                      )
                                    },
                                    expression: "dormdate.casePos2[3].caseMg",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" mg "),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("是否会监测血糖")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.sugarStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dormdate, "sugarStatus", $$v)
                                  },
                                  expression: "dormdate.sugarStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                            _vm.dormdate.sugarStatus == 1
                              ? _c(
                                  "div",
                                  [
                                    _vm._v(" (空腹： "),
                                    _c("el-input", {
                                      staticStyle: { "max-width": "100px" },
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.dormdate.sugarStomach,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dormdate,
                                            "sugarStomach",
                                            $$v
                                          )
                                        },
                                        expression: "dormdate.sugarStomach",
                                      },
                                    }),
                                    _vm._v(" 餐后2小时: "),
                                    _c("el-input", {
                                      staticStyle: { "max-width": "100px" },
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.dormdate.sugarMeal,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dormdate,
                                            "sugarMeal",
                                            $$v
                                          )
                                        },
                                        expression: "dormdate.sugarMeal",
                                      },
                                    }),
                                    _vm._v(" ) "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("是否出现低血糖")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.sugarLowStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate,
                                      "sugarLowStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.sugarLowStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _vm.dormdate.sugarLowStatus == 1
                          ? _c(
                              "div",
                              { staticClass: "grid-content bg-purple-dark" },
                              [_vm._v("出现时间和频率")]
                            )
                          : _c(
                              "div",
                              { staticClass: "grid-content bg-purple-dark" },
                              [_vm._v("出现时间和频率")]
                            ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _vm.dormdate.sugarLowStatus == 1
                          ? _c(
                              "div",
                              { staticClass: "grid-content bg-purple-dark" },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "出现时间和频率" },
                                  model: {
                                    value: _vm.dormdate.sugarLowMeal,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate,
                                        "sugarLowMeal",
                                        $$v
                                      )
                                    },
                                    expression: "dormdate.sugarLowMeal",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div", {
                              staticClass: "grid-content bg-purple-dark",
                            }),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark",
                            staticStyle: { height: "100px" },
                          },
                          [_vm._v("既往病史")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark",
                            staticStyle: {
                              height: "100px",
                              "flex-wrap": "wrap",
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                staticStyle: { "white-space": "wrap" },
                                model: {
                                  value: _vm.dormdate.casePos3,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dormdate, "casePos3", $$v)
                                  },
                                  expression: "dormdate.casePos3",
                                },
                              },
                              _vm._l(_vm.ca, function (yn, index) {
                                return _c("el-checkbox", {
                                  key: index,
                                  attrs: { label: yn.caseName },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("既往史是否用药")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.drugStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dormdate, "drugStatus", $$v)
                                  },
                                  expression: "dormdate.drugStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                            _vm.dormdate.drugStatus == 1
                              ? _c(
                                  "div",
                                  { staticStyle: { "margin-left": "20px" } },
                                  [_vm._v("药物名称：")]
                                )
                              : _vm._e(),
                            _vm.dormdate.drugStatus == 1
                              ? _c("el-input", {
                                  attrs: { placeholder: "药物名称" },
                                  model: {
                                    value: _vm.dormdate.drugName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dormdate, "drugName", $$v)
                                    },
                                    expression: "dormdate.drugName",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("住院史")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "住院史地址" },
                              model: {
                                value: _vm.dormdate.addressDetail,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "addressDetail", $$v)
                                },
                                expression: "dormdate.addressDetail",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("脂肪肝")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.fattyStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dormdate, "fattyStatus", $$v)
                                  },
                                  expression: "dormdate.fattyStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("家族史")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.dormdate.fattyName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dormdate, "fattyName", $$v)
                                },
                                expression: "dormdate.fattyName",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark",
                            staticStyle: { height: "45px" },
                          },
                          [_vm._v("最希望改善的地方")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark",
                            staticStyle: {
                              "flex-wrap": "wrap",
                              height: "45px",
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                staticStyle: { "white-space": "wrap" },
                                model: {
                                  value: _vm.dormdate.casePos4,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dormdate, "casePos4", $$v)
                                  },
                                  expression: "dormdate.casePos4",
                                },
                              },
                              _vm._l(_vm.cas, function (yn, index) {
                                return _c("el-checkbox", {
                                  key: index,
                                  attrs: { label: yn.caseName },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark",
                            staticStyle: { height: "257px" },
                          },
                          [_vm._v("身体症状")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 21 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _vm._v(" 1、有无手麻、脚麻或皮肤瘙痒等症状？ "),
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.casePos5[0].caseStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate.casePos5[0],
                                      "caseStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.casePos5[0].caseStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 21 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _vm._v(" 2、有无失眠、多梦或便秘等症状？ "),
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.casePos5[1].caseStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate.casePos5[1],
                                      "caseStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.casePos5[1].caseStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 21 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _vm._v(" 3、是否有便秘、腹泻等症状？ "),
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.casePos5[2].caseStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate.casePos5[2],
                                      "caseStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.casePos5[2].caseStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 21 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _vm._v(
                              " 4、有无心慌、胸闷、气短、头晕头痛等心脑血管症状？ "
                            ),
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.casePos5[3].caseStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate.casePos5[3],
                                      "caseStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.casePos5[3].caseStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 21 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _vm._v(" 5、有无视物模糊？ "),
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.casePos5[4].caseStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate.casePos5[4],
                                      "caseStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.casePos5[4].caseStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 21 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _vm._v(" 6、有无尿液泡沫增多？ "),
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.casePos5[5].caseStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate.casePos5[5],
                                      "caseStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.casePos5[5].caseStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 21 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _vm._v(
                              " 7、有无糖尿病足部病变（如损伤后不容易愈合）？ "
                            ),
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.casePos5[6].caseStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate.casePos5[6],
                                      "caseStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.casePos5[6].caseStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark" },
                          [_vm._v("饮食生活调查问卷")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark lef_ym" },
                          [_vm._v("1、平时吃一顿饭用时")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 16 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _vm._v(" 大概 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.caseMg,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dormdate, "caseMg", $$v)
                                    },
                                    expression: "dormdate.caseMg",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" 分钟 "),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark lef_ym" },
                          [_vm._v("2、晚上 8 点后会吃食物吗？")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 16 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.casePos6[1].caseStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate.casePos6[1],
                                      "caseStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.casePos6[1].caseStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" 一周 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos6[1].caseCishu,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos6[1],
                                        "caseCishu",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dormdate.casePos6[1].caseCishu",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" 次 "),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark lef_ym" },
                          [_vm._v("3、三餐以外会吃食物/零食吗？")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 16 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.casePos6[2].caseStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate.casePos6[2],
                                      "caseStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.casePos6[2].caseStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" 一周 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos6[2].caseCishu,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos6[2],
                                        "caseCishu",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dormdate.casePos6[2].caseCishu",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" 次 "),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark lef_ym" },
                          [_vm._v("4、是否常喝饮料/酒")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 16 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.casePos6[3].caseStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate.casePos6[3],
                                      "caseStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.casePos6[3].caseStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" 一周 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos6[3].caseCishu,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos6[3],
                                        "caseCishu",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dormdate.casePos6[3].caseCishu",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" 次 "),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark lef_ym" },
                          [_vm._v("5、平时会运动吗？")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 16 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.casePos6[4].caseStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate.casePos6[4],
                                      "caseStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.casePos6[4].caseStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" 一周 "),
                            _c(
                              "div",
                              { staticClass: "textundeline" },
                              [
                                _c("el-input", {
                                  staticStyle: { "max-width": "55px" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.dormdate.casePos6[4].caseCishu,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dormdate.casePos6[4],
                                        "caseCishu",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dormdate.casePos6[4].caseCishu",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" 次 "),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark lef_ym" },
                          [_vm._v("6、是否 12 点之前入睡？")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 16 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.casePos6[5].caseStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate.casePos6[5],
                                      "caseStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.casePos6[5].caseStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-dark lef_ym" },
                          [_vm._v("7、睡眠质量好吗？")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 16 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content bg-purple-dark lef_ym",
                            staticStyle: { "white-space": "nowrap" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dormdate.casePos6[6].caseStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dormdate.casePos6[6],
                                      "caseStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dormdate.casePos6[6].caseStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("好"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("不好"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "solt" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.submit } },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("tablelogs", { attrs: { userinfo: _vm.usId } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }