var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "demo" }, [
    _c(
      "div",
      {
        staticClass: "container",
        style: {
          background: `${_vm.controls[_vm.index].pageBgColor}`,
          padding: `${_vm.controls[_vm.index].margin.top}px ${
            _vm.controls[_vm.index].margin.both
          }px ${_vm.controls[_vm.index].margin.bottom}px ${
            _vm.controls[_vm.index].margin.both
          }px`,
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "ym-dbline",
            style: {
              background: `${_vm.controls[_vm.index].componentBgColor}`,
              borderTopLeftRadius: `${
                _vm.controls[_vm.index].topAroundRadius
              }px `,
              borderTopRightRadius: `${
                _vm.controls[_vm.index].topAroundRadius
              }px `,
              borderBottomRightRadius: `${
                _vm.controls[_vm.index].bottomAroundRadius
              }px `,
              borderBottomLeftRadius: `${
                _vm.controls[_vm.index].bottomAroundRadius
              }px `,
            },
          },
          [
            _vm.controls[_vm.index].mode == "row1-of2"
              ? _c(
                  "div",
                  {
                    class: _vm.controls[_vm.index].mode,
                    style: {
                      gap: _vm.controls[_vm.index].imageGap + "px",
                    },
                  },
                  _vm._l(_vm.controls[_vm.index].list, function (ym, ind) {
                    return _c(
                      "div",
                      {
                        key: ind,
                        staticClass: "mode_pic",
                        style: {
                          borderRadius: `${
                            _vm.controls[_vm.index].topElementAroundRadius
                          }px ${
                            _vm.controls[_vm.index].topElementAroundRadius
                          }px ${
                            _vm.controls[_vm.index].bottomElementAroundRadius
                          }px ${
                            _vm.controls[_vm.index].bottomElementAroundRadius
                          }px`,
                        },
                      },
                      [
                        _c("el-image", {
                          style: {
                            height: ym.imgHeight + "px",
                          },
                          attrs: {
                            fit: "cover ",
                            src: ym.imageUrl == "" ? _vm.initPic : ym.imageUrl,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm.controls[_vm.index].mode == "row1-of3"
              ? _c(
                  "div",
                  {
                    class: _vm.controls[_vm.index].mode,
                    style: {
                      gap: _vm.controls[_vm.index].imageGap + "px",
                    },
                  },
                  _vm._l(_vm.controls[_vm.index].list, function (ym, ind) {
                    return _c(
                      "div",
                      {
                        key: ind,
                        staticClass: "mode_pic",
                        style: {
                          borderRadius: `${
                            _vm.controls[_vm.index].topElementAroundRadius
                          }px ${
                            _vm.controls[_vm.index].topElementAroundRadius
                          }px ${
                            _vm.controls[_vm.index].bottomElementAroundRadius
                          }px ${
                            _vm.controls[_vm.index].bottomElementAroundRadius
                          }px`,
                        },
                      },
                      [
                        _c("el-image", {
                          style: {
                            height: ym.imgHeight + "px",
                          },
                          attrs: {
                            fit: "cover ",
                            src: ym.imageUrl == "" ? _vm.initPic : ym.imageUrl,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm.controls[_vm.index].mode == "row1-of4"
              ? _c(
                  "div",
                  {
                    class: _vm.controls[_vm.index].mode,
                    style: {
                      gap: _vm.controls[_vm.index].imageGap + "px",
                    },
                  },
                  _vm._l(_vm.controls[_vm.index].list, function (ym, ind) {
                    return _c(
                      "div",
                      {
                        key: ind,
                        staticClass: "mode_pic",
                        style: {
                          borderRadius: `${
                            _vm.controls[_vm.index].topElementAroundRadius
                          }px ${
                            _vm.controls[_vm.index].topElementAroundRadius
                          }px ${
                            _vm.controls[_vm.index].bottomElementAroundRadius
                          }px ${
                            _vm.controls[_vm.index].bottomElementAroundRadius
                          }px`,
                        },
                      },
                      [
                        _c("el-image", {
                          style: {
                            width: "100%",
                            height: ym.imgHeight + "px",
                          },
                          attrs: {
                            fit: "cover ",
                            src: ym.imageUrl == "" ? _vm.initPic : ym.imageUrl,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm.controls[_vm.index].mode == "row2-lt-of2-rt"
              ? _c(
                  "div",
                  {
                    class: _vm.controls[_vm.index].mode,
                    style: {
                      gap: _vm.controls[_vm.index].imageGap + "px",
                    },
                  },
                  _vm._l(_vm.controls[_vm.index].list, function (ym, ind) {
                    return _c(
                      "div",
                      {
                        key: ind,
                        staticClass: "mode_pic",
                        style: {
                          borderRadius: `${
                            _vm.controls[_vm.index].topElementAroundRadius
                          }px ${
                            _vm.controls[_vm.index].topElementAroundRadius
                          }px ${
                            _vm.controls[_vm.index].bottomElementAroundRadius
                          }px ${
                            _vm.controls[_vm.index].bottomElementAroundRadius
                          }px`,
                        },
                      },
                      [
                        _c("el-image", {
                          style: {
                            width: "100%",
                            height: ym.imgHeight / 2 + "px",
                          },
                          attrs: {
                            fit: "cover ",
                            src: ym.imageUrl == "" ? _vm.initPic : ym.imageUrl,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm.controls[_vm.index].mode == "row1-lt-of2-rt"
              ? _c(
                  "div",
                  {
                    class: _vm.controls[_vm.index].mode,
                    style: {
                      gap: _vm.controls[_vm.index].imageGap + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mode_pic", style: {} },
                      [
                        _c("el-image", {
                          style: {
                            width: "100%",
                            height:
                              _vm.controls[_vm.index].list[0].imgHeight / 2 +
                              "px",
                          },
                          attrs: {
                            fit: "cover ",
                            src:
                              _vm.controls[_vm.index].list[0].imageUrl == ""
                                ? _vm.initPic
                                : _vm.controls[_vm.index].list[0].imageUrl,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mode_pics" },
                      [
                        _c("el-image", {
                          style: {
                            width: "100%",
                            height:
                              _vm.controls[_vm.index].list[1].imgHeight / 2 +
                              "px",
                            borderRadius: `${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px`,
                          },
                          attrs: {
                            fit: "cover ",
                            src:
                              _vm.controls[_vm.index].list[1].imageUrl == ""
                                ? _vm.initPic
                                : _vm.controls[_vm.index].list[1].imageUrl,
                          },
                        }),
                        _c("el-image", {
                          style: {
                            width: "100%",
                            height:
                              _vm.controls[_vm.index].list[2].imgHeight / 2 +
                              "px",
                            borderRadius: `${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px`,
                          },
                          attrs: {
                            fit: "cover ",
                            src:
                              _vm.controls[_vm.index].list[2].imageUrl == ""
                                ? _vm.initPic
                                : _vm.controls[_vm.index].list[2].imageUrl,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm.controls[_vm.index].mode == "row1-tp-of2-bm"
              ? _c(
                  "div",
                  {
                    class: _vm.controls[_vm.index].mode,
                    style: {
                      gap: _vm.controls[_vm.index].imageGap + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mode_pic" },
                      [
                        _c("el-image", {
                          style: {
                            width: "100%",
                            height:
                              _vm.controls[_vm.index].list[0].imgHeight / 2 +
                              "px",
                            borderRadius: `${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px`,
                          },
                          attrs: {
                            fit: "cover ",
                            src:
                              _vm.controls[_vm.index].list[0].imageUrl == ""
                                ? _vm.initPic
                                : _vm.controls[_vm.index].list[0].imageUrl,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mode_pics" },
                      [
                        _c("el-image", {
                          style: {
                            width: "100%",
                            height:
                              _vm.controls[_vm.index].list[1].imgHeight / 2 +
                              "px",
                            borderRadius: `${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px`,
                          },
                          attrs: {
                            fit: "cover ",
                            src:
                              _vm.controls[_vm.index].list[1].imageUrl == ""
                                ? _vm.initPic
                                : _vm.controls[_vm.index].list[1].imageUrl,
                          },
                        }),
                        _c("el-image", {
                          style: {
                            width: "100%",
                            height:
                              _vm.controls[_vm.index].list[2].imgHeight / 2 +
                              "px",
                            borderRadius: `${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px`,
                          },
                          attrs: {
                            fit: "cover ",
                            src:
                              _vm.controls[_vm.index].list[2].imageUrl == ""
                                ? _vm.initPic
                                : _vm.controls[_vm.index].list[2].imageUrl,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm.controls[_vm.index].mode == "row1-lt-of1-tp-of2-bm"
              ? _c(
                  "div",
                  {
                    class: _vm.controls[_vm.index].mode,
                    style: {
                      gap: _vm.controls[_vm.index].imageGap + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mode_pic" },
                      [
                        _c("el-image", {
                          style: {
                            width: "100%",
                            height:
                              _vm.controls[_vm.index].list[0].imgHeight / 2 +
                              "px",
                            borderRadius: `${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px`,
                          },
                          attrs: {
                            fit: "cover ",
                            src: _vm.controls[_vm.index].list[0].imageUrl,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mode_pics" },
                      [
                        _c("el-image", {
                          style: {
                            width: "100%",
                            height:
                              _vm.controls[_vm.index].list[1].imgHeight / 2 +
                              "px",
                            borderRadius: `${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].topElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px ${
                              _vm.controls[_vm.index].bottomElementAroundRadius
                            }px`,
                          },
                          attrs: {
                            fit: "cover ",
                            src: _vm.controls[_vm.index].list[1].imageUrl,
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "mode_picl" },
                          [
                            _c("el-image", {
                              style: {
                                width: "100%",
                                height:
                                  _vm.controls[_vm.index].list[2].imgHeight /
                                    2 +
                                  "px",
                                borderRadius: `${
                                  _vm.controls[_vm.index].topElementAroundRadius
                                }px ${
                                  _vm.controls[_vm.index].topElementAroundRadius
                                }px ${
                                  _vm.controls[_vm.index]
                                    .bottomElementAroundRadius
                                }px ${
                                  _vm.controls[_vm.index]
                                    .bottomElementAroundRadius
                                }px`,
                              },
                              attrs: {
                                fit: "cover ",
                                src: _vm.controls[_vm.index].list[2].imageUrl,
                              },
                            }),
                            _c("el-image", {
                              style: {
                                width: "100%",
                                height:
                                  _vm.controls[_vm.index].list[3].imgHeight /
                                    2 +
                                  "px",
                                borderRadius: `${
                                  _vm.controls[_vm.index].topElementAroundRadius
                                }px ${
                                  _vm.controls[_vm.index].topElementAroundRadius
                                }px ${
                                  _vm.controls[_vm.index]
                                    .bottomElementAroundRadius
                                }px ${
                                  _vm.controls[_vm.index]
                                    .bottomElementAroundRadius
                                }px`,
                              },
                              attrs: {
                                fit: "cover ",
                                src: _vm.controls[_vm.index].list[3].imageUrl,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }