<template>
  <div class="container">
    <vab-query-form-top-panel>
      <el-form ref="form" :inline="true" label-width="89px" :model="queryForm" @submit.native.prevent>
        <el-form-item label="会员名称">
          <el-input v-model="queryForm.userName" placeholder="请输入 会员名称" />
        </el-form-item>
        <el-form-item label="检测时间">
          <el-input v-model="queryForm.phone" placeholder="请输入 检测时间" />
        </el-form-item>
        <el-form-item label="预警类型">
          <el-input v-model="queryForm.phone" placeholder="请输入 预警类型" />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" native-type="submit" type="primary" @click="searchquery">查询</el-button>
          <el-button icon="el-icon-refresh-right" native-type="submit" type="warning" @click="clearcont">重置</el-button>
          <!-- <el-button type="text" @click="handleFold">
            <span v-if="fold">展开</span>
            <span v-else>合并</span>
            <vab-icon class="vab-dropdown" :class="{ 'vab-dropdown-active': fold }" icon="arrow-up-s-line" />
          </el-button> -->
        </el-form-item>
      </el-form>
    </vab-query-form-top-panel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryForm: {},
    }
  },
  methods: {
    handleFold() {
      this.fold = !this.fold
    },
  },
}
</script>

<style>
</style>