var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: "新增",
            visible: _vm.dialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  attrs: {
                    "label-width": "95px",
                    model: _vm.formData,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺：", prop: "shopId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择店铺" },
                          on: { change: _vm.shop },
                          model: {
                            value: _vm.formData.shopId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "shopId", $$v)
                            },
                            expression: "formData.shopId",
                          },
                        },
                        _vm._l(_vm.selction, function (ym, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: ym.name, value: ym.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入名称" },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序：", prop: "sort" } },
                    [
                      _c("el-input-number", {
                        attrs: { "controls-position": "right", min: 1 },
                        model: {
                          value: _vm.formData.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "sort", $$v)
                          },
                          expression: "formData.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "库存：", prop: "stock" } },
                    [
                      _c("el-input-number", {
                        attrs: { "controls-position": "right", min: 1 },
                        model: {
                          value: _vm.formData.stock,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "stock", $$v)
                          },
                          expression: "formData.stock",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单金额：", prop: "premiseAmount" } },
                    [
                      _c("el-input-number", {
                        attrs: { "controls-position": "right", min: 1 },
                        model: {
                          value: _vm.formData.premiseAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "premiseAmount", $$v)
                          },
                          expression: "formData.premiseAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型：", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "type", $$v)
                            },
                            expression: "formData.type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("代金券"),
                          ]),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("折扣券"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formData.type == 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "减免金额：", prop: "reduceAmount" },
                        },
                        [
                          _c("el-input-number", {
                            attrs: { "controls-position": "right", min: 1 },
                            model: {
                              value: _vm.formData.reduceAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "reduceAmount", $$v)
                              },
                              expression: "formData.reduceAmount",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formData.type == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "折扣率：", prop: "discount" } },
                        [
                          _c("el-input-number", {
                            attrs: { "controls-position": "right", min: 1 },
                            model: {
                              value: _vm.formData.discount,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "discount", $$v)
                              },
                              expression: "formData.discount",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "到期类型：", prop: "expireType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.expireType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "expireType", $$v)
                            },
                            expression: "formData.expireType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("领券后生效"),
                          ]),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("固定时间段"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formData.expireType == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "有效天数：", prop: "validDays" } },
                        [
                          _c("el-input-number", {
                            attrs: { "controls-position": "right", min: 1 },
                            model: {
                              value: _vm.formData.validDays,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "validDays", $$v)
                              },
                              expression: "formData.validDays",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formData.expireType == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "时间范围：" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "end-placeholder": "结束日期",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              type: "datetimerange",
                            },
                            on: { change: _vm.changeDate },
                            model: {
                              value: _vm.day,
                              callback: function ($$v) {
                                _vm.day = $$v
                              },
                              expression: "day",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否启用：", prop: "enable" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.enable,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "enable", $$v)
                            },
                            expression: "formData.enable",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "0" } }, [
                            _vm._v("关闭"),
                          ]),
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("启用"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "适用商品：", prop: "suitType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { input: _vm.changesuit },
                          model: {
                            value: _vm.formData.suitType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "suitType", $$v)
                            },
                            expression: "formData.suitType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("全部商品"),
                          ]),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("指定商品"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formData.suitType == 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "指定商品：", prop: "listGoodsSpu" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "el-icon-plus", type: "primary" },
                              on: { click: _vm.addrow },
                            },
                            [_vm._v("添加")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                _vm._l(_vm.listGoodsSpu, function (ym, index) {
                  return _c(
                    "el-col",
                    { key: index, attrs: { offset: 1, span: 4 } },
                    [
                      _c(
                        "el-card",
                        { attrs: { "body-style": { padding: "5px" } } },
                        [
                          _c("img", {
                            staticClass: "image",
                            staticStyle: { width: "80px", height: "100px" },
                            attrs: { src: ym.picUrls },
                          }),
                          _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: ym.name,
                                    effect: "dark",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "block",
                                        width: "80px",
                                        "text-align": "left",
                                        "white-space": "nowrap",
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis",
                                      },
                                    },
                                    [_vm._v(_vm._s(ym.name))]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "bottom clearfix" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "red" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.remove(index)
                                        },
                                      },
                                    },
                                    [_vm._v("移除")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clears } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("shoplist", {
        attrs: { shopis: _vm.ships, showadd: _vm.hide },
        on: { closerows: _vm.daterow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }