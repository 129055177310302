var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content" }, [
    _c(
      "div",
      { staticClass: "canvas-item" },
      [_c("LeftNav", { attrs: { navlist: _vm.assemble } })],
      1
    ),
    _c(
      "div",
      { staticClass: "canvas-item" },
      [
        _c("CenterContent"),
        _c("div", { staticClass: "menu_navinto" }, [
          _c(
            "div",
            { staticClass: "menu_item", on: { click: _vm.topdown } },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: "前移选中组件",
                    effect: "dark",
                    placement: "right",
                  },
                },
                [_c("i", { staticClass: "el-icon-arrow-up" })]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "menu_item", on: { click: _vm.btndown } },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: "后移选中组件",
                    effect: "dark",
                    placement: "right",
                  },
                },
                [_c("i", { staticClass: "el-icon-arrow-down" })]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "menu_item", on: { click: _vm.copydown } },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: "复制选中组件",
                    effect: "dark",
                    placement: "right",
                  },
                },
                [_c("i", { staticClass: "el-icon-copy-document" })]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "menu_item", on: { click: _vm.deleteCanvas } },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: "删除选中组件",
                    effect: "dark",
                    placement: "right",
                  },
                },
                [_c("i", { staticClass: "el-icon-delete" })]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "canvas-item" }, [_c("RightControls")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }