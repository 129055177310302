var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "addDialog",
          attrs: {
            "close-on-click-modal": false,
            title: _vm.dialogTitle,
            visible: _vm.addVisible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                "label-width": "100px",
                model: _vm.addForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名称：", prop: "roleName" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      clearable: "",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.addForm.roleName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "roleName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.roleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色描述：" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      clearable: "",
                      placeholder: "请输入",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.addForm.roleDescription,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "roleDescription",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.roleDescription",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }