var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", data: [_vm.tableData[0]] },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "页面名称",
              prop: "name",
              width: "250",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "渠道",
              prop: "terminal",
              width: "250",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "页面类型",
              prop: "name",
              width: "250",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "更新时间",
              prop: "updateTime",
              width: "350",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              prop: "address",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "parimay", underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.editCanvas(scope.row)
                          },
                        },
                      },
                      [_vm._v("装修")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.current,
          layout: "total, sizes, prev, pager, next, jumper",
          "page-size": _vm.queryForm.size,
          "page-sizes": [100, 200, 300, 400],
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }