import request from '@/utils/request'
// 健康指数-查询
export function vitComHealdetails(data) {
  return request({
    url: `/mall/heal/vitComHeal/details`,
    method: 'post',
    data,
  })
}

//健康指数-添加
export function vitComHealadd(data) {
  return request({
    url: `/mall/heal/vitComHeal/add`,
    method: 'post',
    data,
  })
}
