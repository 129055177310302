var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c("div", { staticClass: "ym_thead_title" }, [_vm._v("积分设置")]),
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          attrs: {
            inline: false,
            "label-position": "right",
            "label-width": "120px",
            model: _vm.formInline,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户初始积分" } },
            [
              _c("el-input-number", {
                attrs: { "controls-position": "right", min: 1 },
                model: {
                  value: _vm.formInline.initPosts,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "initPosts", $$v)
                  },
                  expression: "formInline.initPosts",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单满" } },
            [
              _c("el-input-number", {
                attrs: { "controls-position": "right", min: 1 },
                model: {
                  value: _vm.formInline.premiseAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "premiseAmount", $$v)
                  },
                  expression: "formInline.premiseAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "抵扣比例" } },
            [
              _c("el-input-number", {
                attrs: { "controls-position": "right", min: 1 },
                model: {
                  value: _vm.formInline.defaultDeductScale,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "defaultDeductScale", $$v)
                  },
                  expression: "formInline.defaultDeductScale",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "抵扣规则" } },
            [
              _c("el-input-number", {
                attrs: { "controls-position": "right", min: 1 },
                model: {
                  value: _vm.formInline.defaultDeductAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "defaultDeductAmount", $$v)
                  },
                  expression: "formInline.defaultDeductAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "打卡积分" } },
            [
              _c("el-input-number", {
                attrs: { "controls-position": "right", min: 1 },
                model: {
                  value: _vm.formInline.clockAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "clockAmount", $$v)
                  },
                  expression: "formInline.clockAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "text-c" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }