<template>
  <div class="cy-container" style="height: 79vh;">
    <el-form
      ref="queryForm"
      :inline="true"
      :model="queryForm"
    >
      <el-form-item label="店铺：" prop="merName">
        <el-select v-model="value" placeholder="请选择 店铺">
          <el-option
            v-for="item in queryForm.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item label="砍价信息：" prop="merName">
        <el-select v-model="value" placeholder="请选择 砍价信息">
          <el-option
            v-for="item in queryForm.type"
            :key="item.value"
            :label="item.label"
            :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          icon="el-icon-search"
          native-type="submit"
          type="warning"
        >
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          native-type="submit"
          type="primary"
        >
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
    border
    :data="tableData"
    max-height="500"
    style="width: 100%">
    <el-table-column align="center" label="店铺" prop="shop" />
    <el-table-column align="center" label="创建时间" prop="creationtime" />
    <el-table-column align="center" label="用户信息" prop="name"/>
    <el-table-column align="center" label="砍价信息" prop="sort"/>
    <el-table-column align="center" label="商品" prop="inventory"/>
    <el-table-column align="center" label="规格" prop="orderamount"/>
    <el-table-column align="center" label="状态" prop="type"/>
    <el-table-column align="center" label="开始时间" prop="maturitytype"/>
    <el-table-column align="center" label="结束时间" prop="enableornot"/>
    <el-table-column align="center" label="砍价低价￥" prop="enableornot"/>
    <el-table-column align="center" label="已砍金额￥" prop="enableornot"/>
    <el-table-column align="center" label="必须低价购买" prop="enableornot"/>
    <el-table-column align="center" label="是否购买" prop="enableornot"/>
    <!-- <el-table-column align="center" label="操作">
      <template #default="scope">
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="editor(scope.row.id)">查看</el-link>
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="editor(scope.row.id)">编辑</el-link>
          <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="editor(scope.row.id)">删除</el-link>
      </template>
    </el-table-column> -->
  </el-table>
  <el-pagination
      background
      :current-page="queryForms.current"
      :layout="layout"
      :page-size="queryForms.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
export default {
  data(){
    return{
      queryForm: {
          colStatus: '',
          merName: '',
      },
      tableData:[
        {
          id:'1',
          shop:'ceshi',
          creationtime:'202-7-28',
          name:'cv',
          sort:'1',
          inventory:'100',
          orderamount:'3300',
          type:'代金券',
          maturitytype:'领券后生效',
          enableornot:true,
          applicablegoods:'指定商品可用'
        }
      ],
      queryForms:{size:1,current:10},
      layout:'total, sizes, prev, pager, next, jumper',
      total:20
    }
  },
  methods:{
    handleCurrentChange(){

    },
    handleSizeChange(){
      
    },
    editor(e){
      console.log(e)
    }
  }
}
</script>

<style lang="scss">
</style>