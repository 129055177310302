<template>
  <div
    class="canvas_cont"
    :style="{
      background: `${styleinfo.componentBgColor}`,
      borderTopLeftRadius: `${styleinfo.topElementAroundRadius}px `,
      borderTopRightRadius: `${styleinfo.topElementAroundRadius}px `,
      borderBottomRightRadius: `${styleinfo.bottomElementAroundRadius}px `,
      borderBottomLeftRadius: `${styleinfo.bottomElementAroundRadius}px `,
    }"
  >
    <div class="lef">
      <div
        class="thead_title"
        :style="{
          color: `${styleinfo.textColor}`,
          fontSize: `${styleinfo.fontSize}px`,
          fontWeight: `${styleinfo.fontWeight}`,
        }"
      >
        {{ styleinfo.text }}
      </div>
      <div
        class="subheading"
        :style="{
          color: `${styleinfo.subTitle.color}`,
          fontSize: `${styleinfo.subTitle.fontSize}px`,
          fontWeight: `${styleinfo.subTitle.fontWeight}`,
        }"
      >
        {{ styleinfo.subTitle.text }}
      </div>
    </div>
    <div
      v-if="styleinfo.more.isShow"
      class="lef ref"
      :style="{
        color: `${styleinfo.more.color}`,
      }"
    >
      {{ styleinfo.more.text }}
      <vab-icon icon="arrow-right-s-line" style="font-size: 12px" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    styleinfo: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.canvas_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 60px;
  padding: 0 15px;
  .lef {
    // display: flex;
    // align-items: center;
  }
  .ref {
    padding: 0 10px;
    min-height: 34px;
    background: rgb(242, 242, 247);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-size: 12px;
  }
}
</style>