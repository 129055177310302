<template>
  <div
    class="content"
    :style="{
      background: controls[index].pageBgColor,
    }"
  >
    <div
      class="container"
      :style="{
        gridTemplateColumns: ` repeat(${Typeflex(controls[index].articletype)[1]}, 1fr)`,
        background: controls[index].componentBgColor,
        margin: `${controls[index].margin.top}px ${controls[index].margin.both}px ${controls[index].margin.bottom}px ${controls[index].margin.both}px`,
        borderRadius: `${controls[index].topElementAroundRadius}px ${controls[index].topElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px ${controls[index].bottomElementAroundRadius}px`,
      }"
    >
      <div
        class="item"
        :style="{
          display: Typeflex(controls[index].articletype),
          background: controls[index].elementBgColor,
        }"
      >
        <div
          class="item_picUrl"
          :style="{
            width: controls[index].articletype == 2 ? '80px' : '100%',
          }"
        >
          <img alt="" :src="intoPic" />
        </div>
        <div
          class="ref_contrl"
          :style="{
            width: controls[index].articletype == 2 ? 'calc(100% - 80px)' : '100%',
          }"
        >
          <div
            class="Article-title"
            :style="{
              margin: controls[index].articletype == 2 ? ' 0' : '10px 0',
            }"
          >
            文章标题
          </div>
          <div class="Article-bottom">
            <div v-if="controls[index].articletype == 2" class="Article-tag">
              <div class="tag_item">慢病管理</div>
              <div class="tag_item">饮食运动</div>
            </div>
            <div class="Article-type">12345浏览</div>
          </div>
        </div>
      </div>
      <div
        class="item"
        :style="{
          display: Typeflex(controls[index].articletype),
          background: controls[index].elementBgColor,
        }"
      >
        <div
          class="item_picUrl"
          :style="{
            width: controls[index].articletype == 2 ? '80px' : '100%',
          }"
        >
          <img alt="" :src="intoPic" />
        </div>
        <div
          class="ref_contrl"
          :style="{
            width: controls[index].articletype == 2 ? 'calc(100% - 80px)' : '100%',
          }"
        >
          <div
            class="Article-title"
            :style="{
              margin: controls[index].articletype == 2 ? ' 0' : '10px 0',
            }"
          >
            文章标题
          </div>
          <div class="Article-bottom">
            <div v-if="controls[index].articletype == 2" class="Article-tag">
              <div class="tag_item">慢病管理</div>
              <div class="tag_item">饮食运动</div>
            </div>
            <div class="Article-type">12345浏览</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    index: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      intoPic: require('@/assets/img/blp/figure.png'),
    }
  },
  computed: {
    ...mapState({
      controls: (state) => state.checkvalue,
    }),
    Typeflex() {
      return (item) => {
        if (item == 1) {
          const list = ['grid', 2]
          return list
        } else {
          const list = ['flex', 1]
          return list
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 0;
}
.container {
  display: grid;
  gap: 10px;
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item_picUrl {
      width: 80px;
      height: 80px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .ref_contrl {
      width: calc(100% - 117px);
      margin-left: 10px;
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .Article-title {
        font-weight: bold;
        font-size: 15px;
      }
      .Article-bottom {
        font-size: 14px;
        color: #999ca7;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .Article-tag {
          display: flex;
          align-items: center;
          .tag_item {
            width: 65px;
            height: 25px;
            border-radius: 25px;
            text-align: center;
            line-height: 25px;
            color: #1fc030;
            margin-right: 5px;
            font-size: 12px;
            background: rgb(228, 245, 230);
          }
        }
        .Article-type {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>