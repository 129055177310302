<template>
  <el-dialog
    :before-close="handleClose"
    title="审核信息"
    :visible.sync="dialogVisible"
    width="30%">
    <span>
      <el-form ref="examin" label-width="90px" :model="form" :rules="rules">
        <el-form-item label="审核类型：" prop="type">
          <el-select v-model="form.type" placeholder="请选择 审核类型" style="width: 100%;">
            <el-option
              v-for="item in options"
              :key="item.key"
              :label="item.labelName"
              :value="item.key"/>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.type==2" label="驳回缘由：" prop="content">
          <el-input v-model="form.content" placeholder="请输入 驳回缘由"/>
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
    </el-dialog>
</template>

<script>
import { uuUserSugarRecordAftercheckPass, uuUserSugarRecordAftercheckTurnDown } from '@/api/mall/seas/index'
export default {
  props:{
    showedit:{
      type:Boolean,
      default:()=>{}
    },
    edirow:{
      type:Number,
      default:()=>{}
    }
  },
  data(){
    return{
      form:{},
      options:[
        {
          labelName:'审核通过',
          key:1,
        },
        {
          labelName:'审核驳回',
          key:2,
        },
      ],
      dialogVisible:false,
      rules: {
        type: [
          { required: true, message: '请选择 审核类型', trigger: 'change' },
        ],
        content: [
          { required: true, message: '请输入 驳回缘由', trigger: 'blur' },
        ],
      },
      checklist:this.edirow,
    }
  },
  watch:{
    showedit(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisible=newValue
      }
    },
    edirow(newValue,oldValue){
      if(newValue!=oldValue){
        console.log(newValue)
        this.checklist=newValue
        this.form={}
      }
    },
  },
  created(){
    console.log(this.edirow,'=1')
  },
  methods:{
    handleClose(){
      this.$emit('clearedi',false)
    },
    save(){
      this.$refs.examin.validate((valid) => {
        if (valid) {
          let ids=[this.edirow]
          if(this.form.type==1){
            uuUserSugarRecordAftercheckPass({ids}).then(res=>{
              if (res.status == 200) {
                this.$message.success('通过成功')
                this.$emit('clearedi',true)
                this.$emit('newget')
              } else {
                this.$message.error(res.msg)
              }
            })
          }else{
            uuUserSugarRecordAftercheckTurnDown({ids,content:this.form.content}).then(res=>{
              if (res.status == 200) {
                this.$message.success('驳回成功')
                this.$emit('clearedi',true)
                this.$emit('newget')
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-dialog__header{
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>