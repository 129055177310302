<template>
  <div class="comprehensive-table-container">
    <common-form ref="commonForm" v-model="searchForm" :options="searchOptions">
      <template slot="operation">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reseta">重置</el-button>
      </template>
    </common-form>
    <el-table border :data="data">
      <el-table-column align="center" label="序号" type="index" width="50" />
      <el-table-column align="center" label="用户" show-overflow-tooltip />
      <el-table-column align="center" label="数量" prop="amount" show-overflow-tooltip sortable />
      <el-table-column align="center" label="描述" prop="description" show-overflow-tooltip />
      <el-table-column align="center" label="店铺" show-overflow-tooltip />
      <el-table-column align="center" label="创建时间" prop="createTime" show-overflow-tooltip sortable />
    </el-table>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
  import { searchOptions } from '@/const/points/pointsRecord'
  import { getIntegral } from '@/api/mall/users/userDetail'
  export default {
    data() {
      return {
        data: [],
        searchForm: {},
        searchOptions,
        total: 0,
        form: {
          size: 10,
          current: 1,
        },
      }
    },
    created() {
      this.getIntegral()
    },
    methods: {
      getIntegral() {
        getIntegral({ ...this.searchForm, ...this.form }).then((res) => {
          console.log(res.data.records)
          this.data = res.data.records
          this.form.size = res.data.size
          this.form.current = res.data.current
          this.total = res.data.total
        })
      },
      handleCurrentChange(e) {
        this.form.current = e
        this.getIntegral()
      },
      handleSizeChange(e) {
        this.form.size = e
        this.getIntegral()
      },
      search() {
        this.getIntegral()
      },
      reseta() {
        this.searchForm = {}
        this.$refs['commonForm'].showEdit()
      },
    },
  }
</script>
