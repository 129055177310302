var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _vm.cur < 4
            ? _c("el-tab-pane", {
                attrs: { label: "客情回执", name: "Aftersalesreceipt" },
              })
            : _vm._e(),
          _c("el-tab-pane", {
            attrs: { label: "事件进度", name: "Eventschedule" },
          }),
        ],
        1
      ),
      _vm.cur < 4
        ? _c(
            "div",
            [
              _vm.activeName == "Aftersalesreceipt"
                ? _c("Aftersalesreceipt", {
                    on: { saveSuccess: _vm.handleSaveSuccess },
                  })
                : _vm._e(),
              _vm.activeName == "Eventschedule"
                ? _c("Eventschedule", { attrs: { showhide: _vm.cur } })
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.activeName == "Eventschedule"
                ? _c("Eventschedule", { attrs: { showhide: _vm.cur } })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }