var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c("span", [_vm._v("可选择您想查看的时间区域！ ")]),
      _c("el-date-picker", {
        attrs: {
          "default-time": ["00:00:00", "23:59:59"],
          "end-placeholder": "结束日期",
          "start-placeholder": "开始日期",
          type: "daterange",
        },
        model: {
          value: _vm.time,
          callback: function ($$v) {
            _vm.time = $$v
          },
          expression: "time",
        },
      }),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "15px" }, attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } }, [
            _c(
              "div",
              { staticClass: "ym_card ym_blue" },
              [
                _c("top-card", {
                  attrs: {
                    background: "blue",
                    icon: "money-cny-circle-line",
                    ordermoney: _vm.orderlist.totalPayableAmount,
                    percentage: "0%",
                    title: "总销量（￥）",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } }, [
            _c(
              "div",
              { staticClass: "ym_card" },
              [
                _c("top-card", {
                  attrs: {
                    background: "white",
                    icon: "user-search-line",
                    ordermoney: _vm.orderlist.sucessOrderNumber,
                    percentage: "0%",
                    title: "总订单（单）",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } }, [
            _c(
              "div",
              { staticClass: "ym_card" },
              [
                _c("top-card", {
                  attrs: {
                    background: "white",
                    icon: "money-cny-box-line",
                    ordermoney: _vm.orderlist.tgotalConcludeAmount,
                    percentage: "0%",
                    title: "总成交订单金额（￥）",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } }, [
            _c(
              "div",
              { staticClass: "ym_card" },
              [
                _c("top-card", {
                  attrs: {
                    background: "white",
                    icon: "passport-line",
                    ordermoney: _vm.orderlist.tgotalConcludeNumber,
                    percentage: "0%",
                    title: "总成交订单数（单）",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 16, md: 20, sm: 40, xl: 16, xs: 40 } },
            [_c("trend", { attrs: { shuarr: _vm.arr, shulist: _vm.list } })],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, md: 16, sm: 32, xl: 8, xs: 32 } },
            [_c("rank")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 24, md: 24, sm: 24, xl: 24, xs: 24 } },
            [_c("tabs")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }