import request from '@/utils/request'
// 用户查询角色信息
export function queryRoles(data) {
  return request({
    url: '/upms/admin/comSysRoleUser/queryRoles',
    method: 'post',
    data,
  })
}
// 用户列表
export function userPage(data) {
  return request({
    url: '/upms/admin/comSysUser/page',
    method: 'post',
    data,
  })
}
// 添加用户 '/upms/admin/comSysUser/add',
// export function userAdd(data) {
//   return request({
//     url: '/upms/user/comSysUser/add',
//     method: 'post',
//     data,
//   })
// }

export function userPhone(params) {
  return request({
    url: '/upms/organ/queryComSysUserByPhone',
    method: 'get',
    params,
  })
}

export function userAdd(obj) {
  return request({
    url: '/upms/user/add',
    method: 'post',
    data: obj,
  })
}
// 编辑用户
export function userUpdate(data) {
  return request({
    url: '/upms/admin/comSysUser/update',
    method: 'post',
    data,
  })
}
// 根据用户查询岗位列表
export function userPost(data) {
  return request({
    url: '/upms/admin/comSysUserPost/userPost',
    method: 'post',
    data,
  })
}
// 岗位tree
export function postOptionTree() {
  return request({
    url: '/upms/admin/comSysUserPost/postOptionTree',
    method: 'post',
  })
}
// 编辑用户读取数据
export function loadDetails(data) {
  return request({
    url: '/upms/admin/comSysUser/loadDetails',
    method: 'post',
    data,
  })
}
// 用户重置密码
export function resetPwd(data) {
  return request({
    url: '/upms/admin/comSysUser/resetPwd',
    method: 'post',
    data,
  })
}
// 添加用户岗位
export function addPost(data) {
  return request({
    url: '/upms/admin/comSysUserPost/add',
    method: 'post',
    data,
  })
}
// 编辑

export function updatePost(data) {
  return request({
    url: '/upms/admin/comSysUserPost/update',
    method: 'post',
    data,
  })
}
// 用户分配角色

export function allotRole(data) {
  return request({
    url: '/upms/admin/comSysRoleUser/allotRole',
    method: 'post',
    data,
  })
}
