import request from '@/utils/request'
// 栏目列表
export function queryPage(data) {
  return request({
    url: `/mall/bbs/bbsArticleColumn/queryPage`,
    method: 'post',
    data,
  })
}
// 栏目编辑
export function editColumn(data) {
  return request({
    url: `/mall/bbs/bbsArticleColumn/edit`,
    method: 'post',
    data,
  })
}
// 栏目添加
export function addColumn(data) {
  return request({
    url: `/mall/bbs/bbsArticleColumn/add`,
    method: 'post',
    data,
  })
}
// 栏目删除
export function deleteColumn(data) {
  return request({
    url: `/mall/bbs/bbsArticleColumn/delete`,
    method: 'post',
    data,
  })
}
// 栏目状态
export function disableColumn(data) {
  return request({
    url: `/mall/bbs/bbsArticleColumn/disable`,
    method: 'post',
    data,
  })
}
// 配置类型（所有文章类型）
export function queryListForList(data) {
  return request({
    url: `/mall/bbs/bbsArticleType/queryListForList`,
    method: 'post',
    data,
  })
}
// 配置类型（所有文章类型）
export function addColumnType(data) {
  return request({
    url: `/user/bbsArticleColumnType/add`,
    method: 'post',
    data,
  })
}
