/**
 * 回访提醒
 */
import request from '@/utils/request'
// 获取回访提醒数据
export function getUserList(data) {
  return request({
    url: `/mall/user/uuUserSugarVist/listVisits`,
    method: 'post',
    data,
  })
}
// 获取公海区id
export function findCustomRecordId(data) {
  return request({
    url: `/mall/healthAbnormalRecord/findCustomRecordId`,
    method: 'post',
    data,
  })
}


export function disableUser(data) {
  return request({
    url: `/user/uUserLogin/disable`,
    method: 'post',
    data,
  })
}
// 编辑风险池备注
export function editnotes(data) {
  return request({
    url: `/mall/user/uuUserSugarVist/editVisitNote`,
    method: 'post',
    data,
  })
}

// // 查看记录
// export function findList(data) {
//   return request({
//     url: `/mall/healthAbnormalRecord/listUserHealthAbnormalRecords`,
//     method: 'post',
//     data,
//   })
// }
// 编辑状态
export function editHealthStatus(data) {
  return request({
    url: `/mall/user/uuUserSugarVist/editVisitStatus`,
    method: 'post',
    data,
  })
}

