var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "textTool" },
    [
      _c("div", { staticClass: "toolTit" }, [
        _c("div", { staticClass: "lef" }, [_vm._v("头部设置")]),
        _c("div", { staticClass: "ref" }, [
          _c("div", { staticClass: "switch" }, [
            _c(
              "div",
              {
                class: _vm.checkIndex == 1 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(1)
                  },
                },
              },
              [_vm._v("内容")]
            ),
            _c(
              "div",
              {
                class: _vm.checkIndex == 2 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(2)
                  },
                },
              },
              [_vm._v("样式")]
            ),
          ]),
        ]),
      ]),
      _vm.checkIndex == 1
        ? _c("div", { staticClass: "content_msg" }, [
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("页面内容")]),
            _c(
              "div",
              { staticClass: "itemBox" },
              [
                _c("div", { staticClass: "Tit" }, [_vm._v("页面名称")]),
                _c("el-input", {
                  staticClass: "item-input",
                  attrs: { maxlength: "20", placeholder: "请输入内容" },
                  model: {
                    value: _vm.activeComponent.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.activeComponent, "title", $$v)
                    },
                    expression: "activeComponent.title",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("顶部开关")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    color: "#3371f7",
                  },
                },
                [
                  _vm.activeComponent.navBarSwitch
                    ? _c(
                        "el-checkbox",
                        {
                          attrs: { label: "true" },
                          model: {
                            value: _vm.activeComponent.navBarSwitch,
                            callback: function ($$v) {
                              _vm.$set(_vm.activeComponent, "navBarSwitch", $$v)
                            },
                            expression: "activeComponent.navBarSwitch",
                          },
                        },
                        [_vm._v("显示")]
                      )
                    : _c(
                        "el-checkbox",
                        {
                          attrs: { label: "true" },
                          model: {
                            value: _vm.activeComponent.navBarSwitch,
                            callback: function ($$v) {
                              _vm.$set(_vm.activeComponent, "navBarSwitch", $$v)
                            },
                            expression: "activeComponent.navBarSwitch",
                          },
                        },
                        [_vm._v("隐藏")]
                      ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("选择风格")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    color: "#3371f7",
                  },
                  on: {
                    click: function ($event) {
                      _vm.styleshow = true
                    },
                  },
                },
                [
                  _vm._v(" 风格" + _vm._s(_vm.activeComponent.navStyle) + " "),
                  _c("i", {
                    staticClass: "el-icon-arrow-right",
                    staticStyle: { "font-size": "14px", color: "gray" },
                  }),
                ]
              ),
            ]),
            _vm.activeComponent.navStyle == 2 ||
            _vm.activeComponent.navStyle == 3
              ? _c("div", { staticClass: "itemBox" }, [
                  _c("div", { staticClass: "Tit" }, [_vm._v("图片")]),
                  _c(
                    "div",
                    {
                      staticClass: "check-tit",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#3371f7",
                      },
                    },
                    [
                      _c("uploadpic", {
                        attrs: {
                          picurl: [{ url: _vm.activeComponent.topNavImg }],
                        },
                        on: { backkuck: _vm.backkick },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.activeComponent.navStyle == 2 ||
            _vm.activeComponent.navStyle == 3
              ? _c("div", { staticClass: "alert-msg" }, [
                  _vm._v("宽度自适应（85px），高度30px"),
                ])
              : _vm._e(),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("链接地址")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    color: "#3371f7",
                  },
                  on: {
                    click: function ($event) {
                      _vm.linkurl = true
                    },
                  },
                },
                [
                  _vm.activeComponent.moreLink.name != ""
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.activeComponent.moreLink.title)),
                      ])
                    : _c("span", [_vm._v("请选择链接地址")]),
                  _c("i", {
                    staticClass: "el-icon-arrow-right",
                    staticStyle: { "font-size": "14px", color: "gray" },
                  }),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("导航栏")]),
              _c(
                "div",
                { staticClass: "check-tit" },
                [
                  _vm.activeComponent.topNavBg
                    ? _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.activeComponent.topNavBg,
                            callback: function ($$v) {
                              _vm.$set(_vm.activeComponent, "topNavBg", $$v)
                            },
                            expression: "activeComponent.topNavBg",
                          },
                        },
                        [_vm._v("显示")]
                      )
                    : _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.activeComponent.topNavBg,
                            callback: function ($$v) {
                              _vm.$set(_vm.activeComponent, "topNavBg", $$v)
                            },
                            expression: "activeComponent.topNavBg",
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "alert-msg" }, [
              _vm._v("此处控制当前页面导航栏是否显示"),
            ]),
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("底部导航")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("底部导航")]),
              _c(
                "div",
                { staticClass: "check-tit" },
                [
                  _vm.activeComponent.openBottomNav
                    ? _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.activeComponent.openBottomNav,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.activeComponent,
                                "openBottomNav",
                                $$v
                              )
                            },
                            expression: "activeComponent.openBottomNav",
                          },
                        },
                        [_vm._v("显示")]
                      )
                    : _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.activeComponent.openBottomNav,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.activeComponent,
                                "openBottomNav",
                                $$v
                              )
                            },
                            expression: "activeComponent.openBottomNav",
                          },
                        },
                        [_vm._v("隐藏")]
                      ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.checkIndex == 2
        ? _c("div", { staticClass: "content_msg" }, [
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("标题样式")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("页面颜色")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(_vm._s(_vm.activeComponent.pageBgColor)),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.activeComponent.pageBgColor,
                          callback: function ($$v) {
                            _vm.$set(_vm.activeComponent, "pageBgColor", $$v)
                          },
                          expression: "activeComponent.pageBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponent.pageBgColor = "#FFFFFF"
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("顶部颜色")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(_vm._s(_vm.activeComponent.topNavColor)),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.activeComponent.topNavColor,
                          callback: function ($$v) {
                            _vm.$set(_vm.activeComponent, "topNavColor", $$v)
                          },
                          expression: "activeComponent.topNavColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponent.topNavColor = "#FFFFFF"
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("标题颜色")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(_vm._s(_vm.activeComponent.textNavColor)),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.activeComponent.textNavColor,
                          callback: function ($$v) {
                            _vm.$set(_vm.activeComponent, "textNavColor", $$v)
                          },
                          expression: "activeComponent.textNavColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponent.textNavColor = "#333333"
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("顶部透明")]),
              _c(
                "div",
                { staticClass: "check-tit" },
                [
                  _vm.activeComponent.navBarSwitch
                    ? _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.activeComponent.navBarSwitch,
                            callback: function ($$v) {
                              _vm.$set(_vm.activeComponent, "navBarSwitch", $$v)
                            },
                            expression: "activeComponent.navBarSwitch",
                          },
                        },
                        [_vm._v("显示")]
                      )
                    : _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.activeComponent.navBarSwitch,
                            callback: function ($$v) {
                              _vm.$set(_vm.activeComponent, "navBarSwitch", $$v)
                            },
                            expression: "activeComponent.navBarSwitch",
                          },
                        },
                        [_vm._v("隐藏")]
                      ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("背景图片")]),
              _c("div", { staticClass: "check-tit" }, [
                _c(
                  "div",
                  {
                    staticClass: "check-tit",
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      color: "#3371f7",
                    },
                  },
                  [
                    _c("uploadpic", {
                      attrs: { picurl: [{ url: _vm.activeComponent.bgUrl }] },
                      on: { backkuck: _vm.backbgurl },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("边距设置")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("左右边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 20, size: "mini" },
                    model: {
                      value: _vm.activeComponent.template.margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponent.template.margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponent.template.margin.both",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 20, min: 0 },
                    model: {
                      value: _vm.activeComponent.template.margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponent.template.margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponent.template.margin.both",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("styledialog", {
        attrs: { stylehide: _vm.styleshow },
        on: { closestyle: _vm.closestyledialog },
      }),
      _c("linkdialog", {
        attrs: { linkshow: _vm.linkurl },
        on: { closeurl: _vm.closelink },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }