var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "thead" }, [
        _vm._v("剩余积分：" + _vm._s(_vm.logAmount)),
      ]),
      _c(
        "el-table",
        {
          attrs: {
            border: "",
            data: _vm.tablist,
            "max-height": "600",
            size: "medium",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "用户名称", prop: "userName" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "积分余额",
              prop: "pointsCurrent",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "抵扣积分", prop: "amount" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "描述",
              prop: "description",
              "show-overflow-tooltip": "",
              width: "260",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", prop: "createTime" },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": 1,
          layout: "total, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }