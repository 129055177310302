var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "target" },
    [
      _c(
        "el-card",
        { staticClass: "target-card2", attrs: { shadow: "hover" } },
        [
          _c("vab-chart", {
            staticClass: "target-echart2",
            attrs: {
              "init-options": _vm.initOptions,
              option: _vm.option2,
              theme: "vab-echarts-theme",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }