<template>
  <div class="container">
    <el-dialog :before-close="handleClose" title="质检信息" :visible.sync="dialogVisible" width="75%">
      <span>
        <div class="theader_title">
          <div class="txt">订单信息</div>
        </div>
        <el-form :inline="true" label-width="100px" :model="savform">
          <el-form-item label="产品名称：">
            <el-input v-model="savform.interContent" placeholder="请输入产品名称" style="width: 200px" />
          </el-form-item>
          <el-form-item label="产品单价：">
            <el-input v-model="savform.interContent" placeholder="请输入产品单价" style="width: 200px" />
          </el-form-item>
          <el-form-item label="购买类型：">
            <el-input v-model="savform.interContent" placeholder="请输入购买类型" style="width: 200px" />
          </el-form-item>
          <el-form-item label="成交单价：">
            <el-input v-model="savform.interContent" placeholder="请输入成交单价" style="width: 200px" />
          </el-form-item>
          <el-form-item label="商品数量：">
            <el-input v-model="savform.interContent" placeholder="请输入商品数量" style="width: 200px" />
          </el-form-item>
          <el-form-item label="商品金额：">
            <el-input v-model="savform.interContent" placeholder="请输入商品金额" style="width: 200px" />
          </el-form-item>
          <el-form-item label="商品说明：">
            <el-input v-model="savform.interContent" placeholder="请输入商品说明" style="width: 200px" />
          </el-form-item>
          <el-form-item label="付款方式：">
            <el-select v-model="savform.interId" placeholder="请选择付款方式" style="width: 200px" @focus="queryList(1)">
              <el-option v-for="item in optionsinter" :key="item.id" :label="item.labelName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="使用预存：">
            <el-input v-model="savform.interContent" placeholder="请输入使用预存/定金" style="width: 200px" />
          </el-form-item>
          <el-form-item label="是否到款：">
            <el-select v-model="savform.interId" placeholder="请选择是否到款" style="width: 200px" @focus="queryList(1)">
              <el-option v-for="item in optionsinter" :key="item.id" :label="item.labelName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="是否带票：">
            <el-select v-model="savform.interId" placeholder="请选择是否带票" style="width: 200px" @focus="queryList(1)">
              <el-option v-for="item in optionsinter" :key="item.id" :label="item.labelName" :value="item.id" />
            </el-select>
          </el-form-item>
          <div class="theader_title">
            <div class="txt">订单信息</div>
          </div>
          <el-form-item label="收件人：">
            <el-input v-model="savform.interContent" placeholder="请输入收件人" style="width: 200px" />
          </el-form-item>
          <el-form-item label="初联日期：">
            <el-date-picker v-model="savform.value1" placeholder="请选择选择日期" style="width: 200px" type="date" />
          </el-form-item>
          <el-form-item label="联系电话：">
            <el-input v-model="savform.phone" placeholder="请输入联系电话" style="width: 200px">
              <template slot="append">
                <div class="cal" @click="callphone(savform.phone)">拨打</div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="核单说明：">
            <el-input v-model="savform.interContent" placeholder="请输入核单说明" style="width: 200px" />
          </el-form-item>
          <el-form-item label="收件省份：">
            <el-select v-model="savform.channelId" placeholder="请选择渠道来源" style="width: 200px" @focus="queryList(2)">
              <el-option v-for="item in optionschannelId" :key="item.id" :label="item.labelName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="收件地区：">
            <el-select v-model="savform.goodId" placeholder="请选择咨询产品" style="width: 200px" @focus="queryList(6)">
              <el-option v-for="item in optionsgoodId" :key="item.id" :label="item.labelName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="收件地址：">
            <el-input v-model="savform.interContent" placeholder="请输入收件地址" style="width: 200px" />
          </el-form-item>
          <el-form-item label="收件邮编：">
            <el-input v-model="savform.interContent" placeholder="请输入收件邮编" style="width: 200px" />
          </el-form-item>
          <el-form-item label="送货说明：">
            <el-input v-model="savform.interContent" placeholder="请输入送货说明" style="width: 200px" />
          </el-form-item>
          <el-form-item label="复购次数：">
            <el-select v-model="savform.interId" placeholder="请选择复购次数" style="width: 200px" @focus="queryList(1)">
              <el-option v-for="item in optionsinter" :key="item.id" :label="item.labelName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="订单类型：">
            <el-select v-model="savform.interId" placeholder="请选择订单类型" style="width: 200px" @focus="queryList(1)">
              <el-option v-for="item in optionsinter" :key="item.id" :label="item.labelName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="备注信息：">
            <el-input v-model="savform.interContent" placeholder="请输入备注信息" style="width: 200px" />
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary">保存</el-button>
            <el-button type="">通过</el-button>
            <el-button type="">驳回</el-button>
          </el-form-item>
        </el-form>
        <el-table border :data="tablist" max-height="300">
          <el-table-column align="center" label="序号" type="index" width="55" />
          <el-table-column align="center" label="订单编号" prop="orderNo" width="220" />
          <el-table-column align="center" label="订单日期" prop="createTime" width="200" />
          <el-table-column align="center" label="订单金额" prop="paymentPrice" show-overflow-tooltip width="140" />
          <el-table-column align="center" label="下单员工" prop="profile" show-overflow-tooltip width="140">
            <template #default="scope">{{ scope.row.realName }}{{ scope.row.profile == '' ? '' :'(' + scope.row.profile + ')'  }}</template>
          </el-table-column>
          <el-table-column align="center" label="状态" prop="statusDesc" show-overflow-tooltip width="80" />
          <el-table-column align="center" label="所购产品" prop="product" show-overflow-tooltip />
          <el-table-column align="center" label="下单地址" prop="address" show-overflow-tooltip />
        </el-table>
        <el-pagination
          background
          :current-page="1"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { orderrecord } from '@/api/mall/health/casemy/index'
  export default {
    props: {
      sound: {
        type: Object,
        default: () => {},
      },
      recordedshow: {
        type: Boolean,
        default: () => {},
      },
    },
    data() {
      return {
        total: 0,
        tablist: [],
        showlist: false,
        showtblist: null,
        savform: {},
        form: {
          size: 10,
          current: 1,
          recordId: null,
        },
        domainUrl: '',
        dialogVisible: false,
        optionsgoodId: null,
        optionsinter: null,
        optionschannelId: null,
      }
    },
    watch: {
      sound(newValue, oldValue) {
        if (newValue != oldValue) {
          this.form.recordId = newValue.recordId
        }
      },
      recordedshow(newValue, oldValue) {
        if (newValue != oldValue) {
          this.dialogVisible = newValue
          this.uuUserSugarRecordLog()
        }
      },
    },
    created() {
      // this.form.recordId = this.sound.recordId
      this.uuUserSugarRecordLog()
    },
    methods: {
      //关闭对话框
      handleClose() {
        this.tablist = []
        this.$emit('closeshowtab', false)
        this.dialogVisible = false
      },
      //关闭回传
      closeshow() {
        this.showlist = false
      },
      showhide(row) {
        this.showtblist = row
        this.showlist = true
        console.log(this.showlist)
      },
      handleCurrentChange(e) {
        this.form.current = e
        this.uuUserSugarRecordLog()
      },
      handleSizeChange(e) {
        this.form.size = e
        this.uuUserSugarRecordLog()
      },
      //列表请求
      uuUserSugarRecordLog() {
        orderrecord({ ...this.form }).then((res) => {
          this.total = res.data.total
          this.form.size = res.data.size
          this.form.current = res.data.current
          this.tablist = res.data.records
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .theader_title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    margin-bottom: 10px;
    background: rgb(237, 237, 237);
    .txt {
      margin-left: 10px;
      text-indent: 1em;
      border-left: 5px solid #3782ff;
    }
  }
  ::v-deep {
    // .el-input__inner {
    //   width: 200px;
    // }
    .el-dialog__header {
      border-bottom: 1px solid #e5e5e5;
    }
  }
</style>
