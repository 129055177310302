<template>
  <div ref="custom-table" class="custom-table-container" :class="{ 'vab-fullscreen': isFullscreen }">
    <searchform @receive="receiverow" />
    <div class="flexk">
      <div style="float: left; margin-bottom: 15px">
        <el-button type="primary" @click="distrtions">分配</el-button>
        <!-- <el-button type="" @click="recyclea('1')">通过</el-button> -->
        <el-button type="" @click="recyclea('2')">驳回</el-button>
      </div>
      <div class="ref" style="float: right">
        <el-button style="margin: 0 10px 10px 0 !important" @click="clickFullScreen">
          <vab-icon :icon="isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'" />
          表格s全屏
        </el-button>
        <el-popover ref="popover" popper-class="custom-table-checkbox" trigger="hover">
          <el-radio-group v-model="lineHeight">
            <el-radio label="medium">大</el-radio>
            <el-radio label="small">中</el-radio>
            <el-radio label="mini">小</el-radio>
          </el-radio-group>
          <template #reference>
            <el-button style="margin: 0 10px 10px 0 !important">
              <vab-icon icon="line-height" />
              表格尺寸
            </el-button>
          </template>
        </el-popover>
        <!-- <el-popover popper-class="custom-table-checkbox" trigger="hover">
          <el-checkbox-group v-model="checkList">
            <vab-draggable v-bind="dragOptions" :list="columns" @change="handleChange">
              <div v-for="(item, index) in columns" :key="item + index">
                <vab-icon icon="drag-drop-line" />
                <el-checkbox :disabled="item.disableCheck === true" :label="item.label">
                  {{ item.label }}
                </el-checkbox>
              </div>
            </vab-draggable>
          </el-checkbox-group>
          <template #reference>
            <el-button icon="el-icon-setting" style="margin: 0 0 10px 0 !important">可拖拽列设置</el-button>
          </template>
        </el-popover> -->
      </div>
    </div>
    <el-table ref="tableSort" border :data="data" max-height="600" :size="lineHeight" @selection-change="selectd">
      <el-table-column align="center" type="selection" width="55" />
      <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="50" />
      <el-table-column
        v-for="(item, index) in listContent"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :show-overflow-tooltip="item.tooltip"
        :width="item.width"
      >
        <template #default="{ row }">
          <el-tooltip class="item" :content="getContent(row[item.prop])" :disabled="item.tooltip" effect="dark" placement="top-end" :value="item.tooltip">
            <span v-if="columns[index].prop == 'afterState'">
              <el-tag v-if="row[item.prop] == 1" type="succes">处理中</el-tag>
              <el-tag v-if="row[item.prop] == 2" type="danger">已通过</el-tag>
              <el-tag v-if="row[item.prop] == 3" type="warning">已驳回</el-tag>
            </span>
            <span v-if="columns[index].prop == 'urgState'">
              <span v-if="row[item.prop] == '0'">是</span>
              <span v-if="row[item.prop] == '1'">否</span>
            </span>
            <span v-if="columns[index].prop == 'paymentWay'">
              <span v-if="row[item.prop] == '1'">货到付款</span>
              <span v-if="row[item.prop] == '2'">在线支付</span>
              <span v-if="row[item.prop] == '3'">付款发货</span>
            </span>
            <span v-else style="white-space: nowrap">{{ row[item.prop] }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="180">
        <template #default="{ row }">
          <!-- <el-button size="default" type="text" @click="operation(row)">录音</el-button> -->
          <el-button size="default" type="text" @click="showhi(row)">查看</el-button>
          <el-button size="default" type="text" @click="showedis(row)">审核</el-button>
          <el-button size="default" type="text" @click="handle(row)">处理</el-button>
        </template>
      </el-table-column>
    </el-table>
    <table-edit ref="edit" v-model="form" :config="config" :options="options" :rules="rules" @fileStatus="fileupload" @submit="submit" />
    <el-pagination
      background
      :current-page="queryForm.current"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="queryForm.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- <handover :hanuser="handeuserinfo" :showtab="appointDialog" @closover="colsove" /> -->
    <distrtion :shodist="distrtionshow" :userid="selecuserid" @closedistr="closdistr" />
    <!-- <histroy :showhislist="historylist" :showhist="historyshow" @closehis="colsehistory" /> -->
    <!-- <ediform :edilist="shotablist" :edirow="edisrow" :showedit="showedi" @clearedi="cleareit" /> -->
    <soundrecirding :recordedshow="Recordedhide" :sound="recorduserinfo" @closesound="closesounhide" />
    <shwotables :recordedshow="shwohidetab" :sound="recorduserinfo" @changeStatus="gaiStatus" @closeshowtab="hidelog" />
    <examinedialog :edirow="edisrow" :showedit="showedi"  @clearedi="cleareit" @newget="getnew"/>
 </div>
</template>
<script>
import { uuUserSugarRecordAfter, getAgentMsg, exportUuUserSugarxc } from '@/api/mall/health/seas/seasManger'
import { options, rules, config } from '@/const/seas/seasManger'
import { uuUserSugarRecordQualitypass, uuUserSugarRecordAfterturnDown } from '@/api/mall/seas/index'
import { deepClone } from '@/utils/util'
import { mapGetters } from 'vuex'
import distrtion from './components/distribution/distribution.vue'
// import histroy from './components/history.vue'
// import handover from './components/handover/handover.vue'
import searchform from './components/search/search.vue'
// import ediform from './components/index.vue'
import soundrecirding from './components/Sound-recording/index.vue'
import shwotables from './components/showtable/index.vue'
import examinedialog from './components/examinedialog/index.vue'
// //表格内容
// import VabDraggable from 'vuedraggable'
import { crmColumnDropadd } from '@/api/mall/tableMove/index'
// const sdk = require('./components/js/sdk.min.js')
let sdk = require('../../../../../public/static/js/sdk.min.js')
export default {
  components: {
    distrtion,
    // histroy,
    // handover,
    searchform,
    // ediform,
    // VabDraggable,
    shwotables,
    soundrecirding,
    examinedialog
  },

  data() {
    return {
      listContent: [],
      shwohidetab: false, //查看
      recorduserinfo: null, //录音选中人信息
      Recordedhide: false, //录音
      listNew: [], //暂存list，用于多选时赋值
      searchForm: { current: 1, size: 10 },
      total: 0,
      data: [],
      isSearch: false,
      options,
      rules,
      config,
      form: {},
      // 当前点击的指定id
      sysId: '', //系统ID
      queryForm: {
        current: 1,
        size: 10,
      },
      checkdate: null,
      appointDialog: false,
      handeuserinfo: null,
      distrtionshow: false,
      historyshow: false,
      historylist: null,
      selecuserid: [], //用户选中id
      phoneUa: null,
      showedi: false,
      shotablist: null,
      edisrow: null,

      //增加操作
      isFullscreen: false,
      height: this.$baseTableHeight(1),
      lineHeight: 'mini',
      checkList: ['客户姓名', '客户电话','订单编号','产品名称','商品价格', '客情类型', '客情备注', '提交时间', '提交部门', '提交员工', '客情人员', '处理状态', '处理时间'],
      columns: [
        {
          label: '客户姓名',
          width: '120',
          prop: 'userName',
          tooltip: true,
        },
        {
          label: '客户电话',
          width: '120',
          prop: 'phone',
          tooltip: true,
        },
        {
          label: '订单编号',
          width: '200',
          prop: 'orderNo',
          tooltip: true,
        },
        {
          label: '产品名称',
          width: '120',
          prop: 'name',
          tooltip: true,
        },
        {
          label: '商品价格',
          width: '120',
          prop: 'salesPrice',
          tooltip: true,
        },
        {
          label: '客情类型',
          width: '160',
          prop: 'lebalName',
          tooltip: true,
        },
        {
          label: '客情备注',
          width: '150',
          prop: 'remark',
          tooltip: false,
        },

        {
          label: '提交部门',
          width: '200',
          prop: 'postName',
          tooltip: false,
        },
        {
          label: '提交员工',
          width: '220',
          tooltip: true,
          prop: 'realName',
        },
        {
          label: '客情人员',
          width: '120',
          tooltip: true,
          prop: 'afterRealName',
        },
        {
          label: '处理状态',
          width: '120',
          tooltip: true,
          prop: 'afterState',
        },
        {
          label: '提交时间',
          width: '180',
          prop: 'createTime',
          tooltip: true,
        },
        {
          label: '处理时间',
          width: '180',
          prop: 'handleTime',
          tooltip: true,
        },
      ],
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 600,
        group: 'description',
      }
    },
    ...mapGetters({
      userId: 'user/userId',
      type: 'user/type',
      userinfo: 'user/userinfo',
    }),
  },
  created() {
    // this.callPhone()
    this.getUserList()
    // this.crmColumnDropqueryPage()
    this.getContents()
  },
  mounted() {
    this.$baseEventBus.$on('Aftersale', () => {
      this.getUserList()
    })
  },
  methods: {
    gaiStatus() {
      this.getUserList()
    },
    getContents() {
      let result = this.columns.filter((column) => this.checkList.includes(column.label))
      this.listContent = result
    },
    handleChange() {
      this.crmColumnDropadd()
    },
    crmColumnDropadd() {
      let columnDropPoList = this.columns.map((item, index) => {
        return Object.assign({}, item, { sort: index })
      })
      let comId = localStorage.getItem('menuinfo')
      crmColumnDropadd({ columnDropPoList, comId, type: '0' }).then(() => {})
    },
    // crmColumnDropqueryPage() {
    //   let comId = localStorage.getItem('menuinfo')
    //   crmColumnDropqueryPage({ current: 1, size: 30, comId }).then((res) => {
    //     if (res.data.records != '' && res.data.records.length != 0) {
    //       this.columns = res.data.records
    //     }
    //   })
    // },
    handle(row) {
      this.$router.push({ path: '/aftersaletreatmentHandle', query: { date: JSON.stringify(row) } })
    },
    //查看关闭
    hidelog() {
      this.shwohidetab = false
    },
    //查看
    showhi(row) {
      console.log(row, '123')
      this.recorduserinfo = row
      if (row.orderId !== null && row.qualityId !== null) {
        this.shwohidetab = true
      } else {
        this.$message.error('没有关联订单')
      }
    },
    //录音回传关闭
    closesounhide() {
      this.Recordedhide = false
    },
    //录音操作
    operation(row) {
      this.recorduserinfo = row
      this.Recordedhide = true
    },
    //转换格式
    getContent(value) {
      return String(value)
    },
    //表格全屏操作
    clickFullScreen() {
      this.isFullscreen = !this.isFullscreen
      this.handleHeight()
    },
    handleHeight() {
      if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
      else this.height = this.$baseTableHeight(1)
    },

    showedis(row) {
      this.edisrow = row.id
      this.shotablist = row
      this.showedi = true
    },
    getnew(){
      this.getUserList()
    },
    cleareit() {
      this.showedi = false
    },
    saveBlod(res) {
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      const fileName = '客户数据表.xls'
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const elink = document.createElement('a')
        elink.download = fileName
        elink.href = URL.createObjectURL(blob)
        elink.style.display = 'none'
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName)
      }
    },
    // 导出客户根据查询条件导出(导出客户)
    async handleExUuUserSugarxc() {
      this.downloadLoading = true
      const listQueryData = {}
      listQueryData.status = 1
      const res = await exportUuUserSugarxc(listQueryData)
      if (!res) {
        return
      }
      console.log(res)
      this.saveBlod(res)
      this.downloadLoading = false
    },
    //
    receiverow(row) {
      if (row != false) {
        this.data = row.records
        this.total = row.size
      } else {
        console.log('0')
      }
    },
    place(row) {
      if (row.bindStatus == 1) {
        this.$router.push({ path: '/activity/activity-customers/customers', query: { userlist: row } })
      } else {
        this.$message.error('该数据未注册请注册后下单')
      }
    },
    reigset(row) {
      if (row.bindStatus == 1) {
        this.$message.error('该数据已绑定会员')
      } else {
        this.$router.push({ path: '/drag/registration', query: { date: row } })
      }
    },
    callPhone() {},
    regisetPhone(row) {
      this.phoneUa = new sdk.SDK.cxWebPhone({
        userName: row.username,
        password: row.password,
        server: row.real,
        traceSip: false,
        builtinEnabled: true,
        audioElement: 'remoteAudio',
        in: {
          terminated: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          accepted: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          bye: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          rejected: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          failed: (response, cause, type) => this.webPhoneEvent(response, cause, type),
        },
        ua: {
          invite: (response, type) => this.webPhoneUa(response, type),
          registered: (response, type) => this.webPhoneUa(response, type),
          unregister: (response, cause, type) => this.webPhoneEvent(response, cause, type),
          registrationFailed: (response, cause, type) => this.webPhoneEvent(response, type),
          connecting: (response, type) => this.webPhoneUa(response, type),
          connected: (response, type) => this.webPhoneUa(response, type),
          disconnecting: (response, type) => this.webPhoneUa(response, type),
          disconnected: (response, type) => this.webPhoneUa(response, type),
        },
      })
    },
    callphones(phone) {
      //坐席获取注册 SIP 信息
      getAgentMsg({ username: '1000' }).then((res) => {
        if (res.status == 200) {
          this.regisetPhone(res.data)
          this.$confirm('是否拨打该号码', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            new sdk.SDK.callWebPhone({
              phoneNumber: phone,
              out: {
                progress: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                accepted: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                rejected: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                failed: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                terminated: (response, cause, type) => this.webPhoneEvent(response, cause, type),
                bye: (response, cause, type) => this.webPhoneEvent(response, cause, type),
              },
            })
          })
        } else {
          this.$message.error(res.msg)
          return
        }
      })
      //
    },
    webPhoneEvent(response, cause, type, d) {
      console.warn('type,response, cause', type, response, cause, '----', d)
    },
    webPhoneUa(response, type) {
      console.warn('type,response', type, response)
      if (type === 'ua.invite') {
        var msg = response.remoteIdentity.uri.user + '来电了，是否接听'
        if (confirm(msg) == true) {
          response.answer()
        } else {
          response.reject()
        }
      }
    },
    selectd(e) {
      this.selecuserid = e
      console.log(e)
    },
    history(row) {
      this.historylist = [JSON.parse(JSON.stringify(row))]
      this.historyshow = true
    },
    colsehistory() {
      this.historyshow = false
    },
    recyclea(numk) {
      let ids = []
      if (this.selecuserid) {
        this.selecuserid.forEach((item) => {
          ids.push(item.id)
        })
      }
      if (ids.length === 0) {
        this.$message.error('请选择驳回用户')
        return
      }

      if (numk == '1') {
        this.$confirm('是否通过该用户', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            uuUserSugarRecordQualitypass({ ids }).then((res) => {
              if (res.status == 200) {
                this.$message.success('通过成功')
                this.getUserList()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            this.$message('您已取消通过')
          })
      } else {
        this.$prompt('请输入驳回缘由', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then((da) => {
            if (da.value) {
              console.log(da.value)
              uuUserSugarRecordAfterturnDown({ ids, content: da.value }).then((res) => {
                if (res.status == 200) {
                  this.$message.success('驳回成功')
                  this.getUserList()
                } else {
                  this.$message.error(res.msg)
                }
              })
              this.getUserList()
            } else {
              this.getUserList()
              this.$message.error('请填写驳回缘由')
            }
          })
          .catch(() => {
            this.$message('您已取消驳回')
          })
      }
    },
    distrtions() {
      if (this.selecuserid != null && this.selecuserid.length > 0) {
        this.distrtionshow = true
      } else {
        this.$message.error('请选择分配用户')
      }
    },
    closdistr() {
      this.distrtionshow = false
      this.selecuserid = null
      this.getUserList()
    },
    handleCurrentChange(val) {
      this.queryForm.current = val
      this.getUserList()
    },
    handleSizeChange(val) {
      this.queryForm.size = val
      this.getUserList()
    },
    async getUserList() {
      // console.log('mall/shopinfo/page')
      // menuType:2,//1:公海管理  2：部门公海 3：我的客户 , 4:客情数据 5： 我的客情  6：客情审核 7：质检数据 8 我的质检
      uuUserSugarRecordAfter({ ...this.queryForm, menuType: 4, labelIds: [1, 6] }).then((res) => {
        console.log(res.data)
        this.data = res.data.records
        this.total = res.data.total
        this.queryForm.size = res.data.size
        this.queryForm.current = res.data.current
      })
      // uuUserSugarRecordQuality().then((res) => {
      //   console.log(res)
      //   const {
      //     data: { records },
      //   } = res
      //   console.log(records)
      //   this.appointData = records
      // })
    },
    appoint() {
      this.handeuserinfo = this.selecuserid
      this.appointDialog = true
    },
    colsove() {
      this.appointDialog = false
    },
    handleAdd() {
      this.$refs['edit'].showEdit()
    },
    fileupload(msg, result) {
      console.log('成功回调')
      console.log(msg, result)
    },
    submit() {
      console.log('表单数据user', this.form)
    },
    show(row) {
      this.form = deepClone(row)
      this.$nextTick(() => {
        this.$refs['edit'].showEdit('show')
      })
    },
    edit(row) {
      this.form = deepClone(row)
      this.$nextTick(() => {
        this.$refs['edit'].showEdit('edit')
      })
    },
  },
}
</script>

<style scoped></style>
