<template>
  <div class="cy-container">
    <div class="header_title">消息推送</div>
    <el-form ref="queryForm" :inline="true" :model="queryForm" :rules="rules" @submit.native.prevent>
      <el-form-item label="标题：" prop="noticeTitle">
        <el-input v-model="queryForm.noticeTitle" class="filterItems" placeholder="请输入 标题" />
      </el-form-item>
      <el-form-item label="消息类型：" prop="noticeType">
        <el-select v-model="queryForm.noticeType" class="filterItems" clearable placeholder="请选择">
          <el-option v-for="(item, index) in noticeType" :key="index" :label="item.title" :value="item.noticeType" />
        </el-select>
      </el-form-item>
      <el-form-item label="接收用户：" prop="receive">
        <el-select v-model="queryForm.receive" class="filterItems" clearable placeholder="请选择">
          <el-option v-for="(item, index) in artStatusLis" :key="index" :label="item.title" :value="item.receive" />
        </el-select>
      </el-form-item>
    </el-form>
    <!-- <WangEditorVue @wang="listrow"/> -->
    <quilledit :editcontent="articleContent" @editrow="ck" />
    <div class="footer-button" style="display: flex; justify-content: center; margin: 20px 0"><el-button type="primary" @click="submit">发送</el-button></div>
  </div>
</template>
<script>
import { msgadd } from '@/api/mall/mscontent/index'
// import WangEditorVue from '@/components/editor/WangEditor.vue'
import quilledit from '@/components/editor/quilledit'
export default {
  components: {
    // WangEditorVue
    quilledit,
  },
  data() {
    return {
      queryForm: {},
      noticeType: [
        { noticeType: 1, title: '系统消息（系统自动发送）' },
        // { noticeType: 2, title: '公告（后台录入）' },
        // { noticeType: 3, title: '站内信' },
      ], //消息类型
      artStatusLis: [
        { receive: 1, title: '全部用户 ' },
        { receive: 2, title: '商家' },
        { receive: 3, title: '普通用户' },
      ],
      rules: {
        noticeTitle: [{ required: true, message: '请输入 标题', trigger: 'blur' }],
        noticeType: [{ required: true, message: '请选择 消息类型', trigger: 'change' }],
        receive: [{ required: true, message: '请选择 接收用户', trigger: 'change' }],
      },
    }
  },
  methods: {
    ck(e) {
      this.queryForm.noticeContent = e.html
    },
    // listrow(row) {
    //   this.queryForm.noticeContent = row
    // },
    submit() {
      this.$refs.queryForm.validate((valid) => {
        if (valid) {
          if (this.queryForm.noticeContent != '<p><br></p>') {
            msgadd({ ...this.queryForm }).then((res) => {
              if (res.code == 0) {
                this.$message.success('发布成功')
                this.$router.push('/msglist')
              } else {
                this.$message.error('错误：', res.msg)
              }
            })
          } else {
            this.$message.error('发布内容不能为空')
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep(.ql-video) {
  width: 20% !important;
  height: 200px;
}
.filterItems {
  width: 150px;
}
.header_title {
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px dashed #e5e5e5;
  padding: 20px 0;
  margin-bottom: 20px;
}
</style>
