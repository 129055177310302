<template>
  <div class="container">
    <div class="thead">预存余额：￥{{ logAmount }}</div>
    <el-table border :data="tablist" max-height="300">
      <!-- <el-table-column align="center" label="序号" prop="id" show-overflow-tooltip width="50" /> -->
      <el-table-column align="center" label="序号" show-overflow-tooltip type="index" width="50" />
      <el-table-column align="center" label="交易类型" prop="optType" width="100px">
        <template #default="scope">
          <div v-if="scope.row.optType == 0">汇入</div>
          <div v-if="scope.row.optType == 1">支出</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="交易金额" prop="logAmount" width="100px" />
      <el-table-column align="center" label="交易渠道" width="120px">
        <template #default="scope">
          <!-- 交易类型 1:微信零钱 2：支付宝 3：银行卡 -->
          <div v-if="scope.row.bussType == 1">微信</div>
          <div v-if="scope.row.bussType == 2">支付宝</div>
          <div v-if="scope.row.bussType == 3">银行卡</div>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="银行账号" prop="bankNumber" show-overflow-tooltip width="200px" /> -->
      <!-- <el-table-column align="center" label="银行户名" prop="title" show-overflow-tooltip width="200px" /> -->

      <el-table-column align="center" label="账号信息" show-overflow-tooltip>
        <template #default="scope"> {{ scope.row.bankName }} {{ scope.row.bankNumber != null ? "(" +scope.row.bankNumber + ")": ''  }}</template>
      </el-table-column>
      <!-- <el-table-column align="center" label="汇款标签" prop="remBank" show-overflow-tooltip width="200px" /> -->
      <el-table-column align="center" label="交易备注" prop="content" show-overflow-tooltip />

      <el-table-column align="center" label="交易状态" prop="preStatus" width="160px">
        <template #default="scope">
          <el-tag v-if="scope.row.preStatus == 1" type="succes">已到款</el-tag>
          <el-tag v-if="scope.row.preStatus == 2" type="info">未到款</el-tag>
          <el-tag v-if="scope.row.preStatus == 3" type="warning">已经取消</el-tag>
          <el-tag v-if="scope.row.preStatus == 4" type="danger">已关联</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="关联订单"  prop="orderNo" width="200px"  /> -->
      <el-table-column align="center" label="收款时间" prop="processTime" width="220px" />
      <el-table-column align="center" fixed="right" label="操作" prop="preStatus" width="88px">
        <template #default="scope">
          <el-link type="primary" :underline="false" @click="editrow(scope.row)">编辑</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="1" layout="total, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <el-dialog append-to-body :before-close="handleClose" title="修改状态" :visible.sync="dialogVisible" width="30%">
      <span>
        <el-form :model="editlist">
          <el-form-item label="交易状态">
            <el-select v-model="editlist.preStatus" placeholder="请选择 交易状态">
              <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveedit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { merRecordPrestorequeryPage, statistics, editstatistics } from '@/api/mall/setting/stplabel'
export default {
  props: {
    sound: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      total: 0,
      tablist: [],
      showlist: false,
      showtblist: null,
      logAmount: null, //预存余额
      form: {
        size: 10,
        current: 1,
        recordId: null,
      },
      editlist: {}, //交易修改
      dialogVisible: false,
      options: [
        {
          label: '已到款',
          id: '1',
        },
        {
          label: '未到款',
          id: '2',
        },
        {
          label: '已经取消',
          id: '3',
        },
      ],
    }
  },
  watch: {
    sound(newValue, oldValue) {
      if (newValue != oldValue) {
        this.form.recordId = newValue.id
        this.userinfo = newValue
      }
    },
  },
  created() {
    this.form.recordId = this.sound.id
    this.uuUserSugarRecordLog()
    this.statistics()
    this.$EventBus.$on('repload', () => {
      //需要执行的代码
      this.uuUserSugarRecordLog()
      this.statistics()
    })
  },
  methods: {
    //关闭修改对话框
    handleClose() {
      this.dialogVisible = false
      this.editlist = {}
    },
    closeshow() {
      this.showlist = false
    },
    showhide(row) {
      this.showtblist = row
      this.showlist = true
      console.log(this.showlist)
    },
    handleCurrentChange(e) {
      this.form.current = e
      this.uuUserSugarRecordLog()
    },
    handleSizeChange(e) {
      this.form.size = e
      this.uuUserSugarRecordLog()
    },
    //列表请求
    uuUserSugarRecordLog() {
      merRecordPrestorequeryPage({ ...this.form }).then((res) => {
        this.total = res.data.total
        this.form.size = res.data.size
        this.form.current = res.data.current
        this.tablist = res.data.records
      })
    },
    //余额请求
    statistics() {
      statistics({ recordId: this.form.recordId }).then((res) => {
        this.logAmount = res.data.logAmount
      })
    },
    // 编辑交易状态
    editrow(row) {
      if (4 == row.preStatus) {
        this.ME('已经关联订单，不能编辑！')
        return
      } else {
        this.options.forEach((item) => {
          if (item.id == row.preStatus) {
            this.$set(this.editlist, 'preStatus', item.label)
            this.$set(this.editlist, 'id', row.id)
          }
        })
        this.dialogVisible = true
      }
    },
    //保存修改状态
    saveedit() {
      if (isNaN(this.editlist.preStatus)) {
        this.options.forEach((item) => {
          if (item.label == this.editlist.preStatus) {
            this.editlist.preStatus = item.id
          }
        })
      }
      editstatistics({ ...this.editlist }).then((res) => {
        if (res.status == 200) {
          // this.$message.succes('修改成功')
          this.MS('修改成功')
          this.uuUserSugarRecordLog()
          this.statistics()
          this.dialogVisible = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.thead {
  margin-bottom: 10px;
}
</style>
