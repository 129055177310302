<template>
  <div class="cy-container">
    <div class="el-container">
      <!-- content -->
      <div ref="contantRef" style="width: 100%">
        <!-- 查询 -->
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <!-- <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
              新增
            </el-button>
            <el-button type="primary" @click="handleExport">
              <vab-icon icon="file-copy-2-fill" />
              导出
            </el-button>
            <el-button
              icon="el-icon-delete"
              plain
              type="danger"
              @click="handleDelete"
            >
              删除
            </el-button> -->
            <el-button
              v-for="(item,ind) in fixedButton"
              :key="item.id"
              :plain="item.cssType && item.cssType == 'plain' ? true : false"
              :type="ind==0?item.buttonCss:''"
              @click.native="handleClickBtn(item)"
            >
              <span v-if="!item.buttonIcon"></span>
              <vab-icon v-else :icon="item.buttonIcon" />
              {{ item.buttonName }}
            </el-button>
          </el-form-item>
        </el-form>
        <!-- table列表 -->
        <el-table v-loading="listLoading" border :data="list" max-height="600" row-key="id" :tree-props="{ children: 'children' }" @selection-change="setSelectRows">
          <el-table-column show-overflow-tooltip type="selection" />

          <el-table-column label="部门名称" prop="orgName" show-overflow-tooltip />
          <el-table-column align="center" label="部门编码" prop="orgCode" show-overflow-tooltip />
          <!-- <el-table-column align="center" label="部门类型" prop="orgType" show-overflow-tooltip /> -->
          <el-table-column label="部门描述" prop="orgDescription" show-overflow-tooltip />
          <el-table-column align="center" label="排序" prop="orgSequence" show-overflow-tooltip width="100px" />
          <el-table-column align="center" label="创建时间" prop="createTime" show-overflow-tooltip>
            <template #default="{ row }">
              <div>
                {{ row.createTime | formatDate }}
              </div>
            </template>
          </el-table-column>

          <el-table-column v-if="operationShow" align="center" label="操作" width="180">
            <template #default="{ row }">
              <el-button
                v-for="item in listButton"
                :key="item.id"
                :plain="item.cssType && item.cssType == 'plain' ? true : false"
                size="default"
                :type="item.buttonCss"
                @click.native="handleClickBtn(item, row)"
              >
                <!-- <span v-if="!item.buttonIcon"></span>
                <vab-icon v-else :icon="item.buttonIcon" /> -->
                {{ item.buttonName }}
              </el-button>
              <!-- <el-button
                icon="el-icon-edit-outline"
                style="margin-right: 10px"
                type="text"
                @click="handleEdit(row)"
              >
                编辑
              </el-button>
              <el-button
                icon="el-icon-plus"
                size="small"
                style="margin-right: 10px"
                type="text"
                @click="handleAddson(row)"
              >
                添加
              </el-button>
              <el-button
                icon="el-icon-delete"
                size="small"
                type="text"
                @click="handleDelete(row)"
              >
                删除
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination background :current-page="current" :layout="layout" :page-size="size" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
      </div>
    </div>
    <!-- 编辑、添加弹框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogFormVisible" @close="close">
      <el-form ref="postForm" label-width="80px" :model="postForm" :rules="rules">
        <el-form-item label="部门名称" prop="orgName">
          <el-input v-model.trim="postForm.orgName" clearable />
        </el-form-item>
        <!-- <el-form-item label="部门编码" prop="orgCode">
          <el-input v-model.trim="postForm.orgCode" clearable />
        </el-form-item> -->
        <el-form-item label="部门描述" prop="orgDescription">
          <el-input v-model.trim="postForm.orgDescription" clearable />
        </el-form-item>
        <el-form-item label="部门类型" prop="orgType">
          <el-select v-model="postForm.orgType" clearable placeholder="请选择">
            <el-option v-for="item in orgTypeList" :key="item.label" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="orgSequence">
          <el-input-number v-model.trim="postForm.orgSequence" :min="1" placeholder="请输入" size="small" style="width: 100%" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="title === '新增' ? createData() : updateData()">确 定</el-button>
      </template>
    </el-dialog>
    <!-- 添加子级弹框 -->
    <el-dialog :close-on-click-modal="false" title="添加子级" :visible.sync="dialogFormVisibleSon" @close="closeSon">
      <el-form ref="postFormSon" label-width="80px" :model="postFormSon" :rules="rulesSon">
        <el-form-item label="部门名称" prop="orgName">
          <el-input v-model.trim="postFormSon.orgName" clearable />
        </el-form-item>
        <!-- <el-form-item label="部门编码" prop="orgCode">
          <el-input v-model.trim="postFormSon.orgCode" clearable />
        </el-form-item> -->
        <el-form-item label="部门描述" prop="orgDescription">
          <el-input v-model.trim="postFormSon.orgDescription" clearable />
        </el-form-item>
        <el-form-item label="排序" prop="orgSequence">
          <el-input-number v-model.trim="postFormSon.orgSequence" :min="1" placeholder="请输入" size="small" style="width: 100%" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="closeSon">取 消</el-button>
        <el-button type="primary" @click="createDataSon()">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { orgTree, orgEdit, orgAdd, orgIsDelete } from '@/api/mall/role/orgManage'
import { selectSysDict, selectRoleMenuButtonList } from '@/api/mall/common'
export default {
  data() {
    return {
      operationShow: false,
      fixedButton: [],
      listButton: [],
      rowIdson: '',
      rowPareIdson: '',
      dialogFormVisibleSon: false,
      postFormSon: {},
      parentName: '',
      rowId: '',
      postName: '',
      listLoading: false,
      current: 1,
      size: 10,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      list: [],
      selectRows: '',
      queryForm: {},
      title: '',
      dialogFormVisible: false,
      postForm: {
        parentName: '',
        parentId: '',
        orgType:'tjx_org'
      },
      orgTypeList:[
        {
          value:'tjx_org',
          label:'平台部门'
        },{
          value:'proxy_shop',
          label:'代理商'
        }
      ],
      rules: {
        orgName: [
          {
            required: true,
            message: '请输入部门名称',
            trigger: ['blur', 'change'],
          },
        ],
        orgType: [
          {
            required: true,
            message: '请选择部门类型！',
            trigger: ['blur', 'change'],
          },
        ],
        orgCode: [
          {
            required: true,
            message: '请选择部门编码！',
            trigger: ['blur', 'change'],
          },
        ],
      },
      rulesSon: {
        orgName: [
          {
            required: true,
            message: '请输入部门名称',
            trigger: ['blur', 'change'],
          },
        ],
        orgType: [
          {
            required: true,
            message: '请选择部门类型！',
            trigger: ['blur', 'change'],
          },
        ],
        orgCode: [
          {
            required: true,
            message: '请选择部门编码！',
            trigger: ['blur', 'change'],
          },
        ],
      },
      orgsName: '',
      orgsListTable: [],
      leftIcon: true,
      rightIcon: false,
      roleSelectList: [],
      editParentId: '',
    }
  },
  created() {
    this.fetchData()
    this.selectSysDict()
    this.selectRoleMenuButtonList()
  },
  methods: {
    // 按钮点击
    handleClickBtn(item, row) {
      if (item.buttonUrl) {
        this[item.buttonUrl](row)
      }
    },
    // 按钮权限
    selectRoleMenuButtonList() {
      selectRoleMenuButtonList({
        roleIdStrList: 1, // this.$store.getters['acl/roleList'],
        menuId: this.$route.meta.id,
      })
        .then((res) => {
          if (res.data) {
            this.fixedButton = res.data.filter(function (item) {
              return item.buttonType == 3
            })
            this.listButton = res.data.filter(function (item) {
              return item.buttonType == 2
            })
            if (this.listButton.length > 0) {
              this.operationShow = true
            } else {
              this.operationShow = false
            }
          }
        })
        .catch(() => {})
    },
    // 读取列表
    async fetchData() {
      this.listLoading = true
      const parms = {
        current: this.current,
        size: this.size,
      }
      await orgTree({ ...parms })
        .then((res) => {
          if (res.data) {
            this.list = res.data.records
            this.total = res.data.total
            this.orgsListTable = res.data.records
          }
        })
        .catch(() => {})
      this.listLoading = false
    },
    // 部门类型字典
    async selectSysDict() {
      await selectSysDict({ dictTypeList: ['innerOrgType'] })
        .then((res) => {
          if (res.data) {
            this.roleSelectList = res.data[0].dictInfoVoList
          }
        })
        .catch(() => {})
    },
    // 点击收缩
    handleLeftIcon() {
      this.$refs.asideRef.style.width = '10px'
      this.$refs.asideRef.style.minWidth = '10px'
      this.$refs.contantRef.style.width = '98%'
      this.leftIcon = false
      this.rightIcon = true
    },
    // 点击展开
    handleRightIcon() {
      this.$refs.asideRef.style.width = '180px'
      this.$refs.asideRef.style.minWidth = '180px'
      this.$refs.contantRef.style.width = '87%'
      this.leftIcon = true
      this.rightIcon = false
    },
    // 导出
    handleExport() {},

    // 新增/编辑
    handleEdit(row) {
      if (row) {
        this.showEdit(row)
      } else {
        this.showEdit()
      }
    },
    // 添加编辑title
    showEdit(row) {
      console.log(row,'row')
      if (row) {
        this.rowId = row.id
        this.editParentId = row.parentId
        this.title = '编辑'
        this.postForm = Object.assign({}, row)
      } else {
        this.title = '新增'
      }
      this.dialogFormVisible = true
    },
    // 关闭
    close() {
      this.dialogFormVisible = false
      this.parentName = ''
      this.$refs['postForm'].resetFields()
      this.postForm = this.$options.data().postForm
    },
    // 关闭子级弹框
    closeSon() {
      this.dialogFormVisibleSon = false
      this.$refs['postFormSon'].resetFields()
      this.postFormSon = this.$options.data().postFormSon
    },
    // 添加子级
    handleAddson(row) {
      this.rowIdson = row.id
      this.rowPareIdson = row.parentId
      this.dialogFormVisibleSon = true
    },
    // 新增
    createData() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          const parms = {
            orgCode: this.postForm.orgCode,
            orgName: this.postForm.orgName,
            orgType: this.postForm.orgType,
            orgDescription: this.postForm.orgDescription,
            orgSequence: this.postForm.orgSequence,
            parentId: 0,
          }
          orgAdd(parms).then(() => {
            this.MS('新增成功')
            this.fetchData()
            this.close()
          })
        }
      })
    },
    // 新增子级
    createDataSon() {
      this.$refs['postFormSon'].validate((valid) => {
        if (valid) {
          const parms = {
            orgCode: this.postFormSon.orgCode,
            orgName: this.postFormSon.orgName,
            orgType: this.postFormSon.orgType,
            orgDescription: this.postFormSon.orgDescription,
            orgSequence: this.postFormSon.orgSequence,
            parentId: this.rowIdson,
          }
          orgAdd(parms).then(() => {
            this.MS('新增成功')
            this.fetchData()
            this.closeSon()
          })
        }
      })
    },
    // 编辑保存
    updateData() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          const tempData = {}
          tempData.id = this.rowId
          tempData.orgCode = this.postForm.orgCode
          tempData.orgName = this.postForm.orgName
          tempData.orgType = this.postForm.orgType
          tempData.orgDescription = this.postForm.orgDescription
          tempData.orgSequence = this.postForm.orgSequence
          tempData.parentId = this.editParentId
          orgEdit(tempData)
            .then(() => {
              this.MS('编辑成功')
              this.fetchData()
              this.dialogFormVisible = false
            })
            .catch(() => {})
        }
      })
    },

    // 删除
    handleDelete(row) {
      if (row.children != null) {
        return this.ME('此数据下有子级不可删除')
      }
      if (row.id) {
        this.$confirm(`${row.children == null ? '是否要删除此行?' : '删除此数据将删除整个下级部门的数据,是否继续?'}`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            orgIsDelete({ id: row.id })
              .then(() => {
                this.MS('删除成功')
                this.fetchData()
              })
              .catch(() => {})
          })
          .catch(() => {})
      } else {
        if (this.selectRows.length > 0) {
          const ids = this.selectRows.map((item) => item.id).join()
          console.log(ids)
          this.$confirm('是否要删除此行?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {})
            .catch(() => {})
        } else {
          if (this.selectRows.length === 0) {
            this.ME('请勾选需要删除的记录')
            return
          }
        }
      }
    },
    // 列表选中
    setSelectRows(val) {
      this.selectRows = val
    },

    // 分页
    handleSizeChange(val) {
      this.size = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.current = val
      this.fetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.postPare {
  .el-select {
    width: 100%;
  }
}
.el-aside {
  width: 190px !important;
}
.asideAuthorizeUrl {
  width: 190px;
  height: 600px;
  overflow: scroll;
  border: 1px solid #c0d4f0;
  .el-table {
    ::v-deep(th.is-leaf) {
      background-color: #fff !important;
    }
  }
  ::v-deep(.el-input) {
    width: 140px;
    padding: 10px;
  }
  ::v-deep .aside-table td,
  .aside-table th.is-leaf {
    cursor: pointer;
  }
  ::v-deep(.aside-table th) {
    .cell {
      font-weight: 700 !important;
      color: #909399 !important;
    }
  }
  .aside-table::before {
    width: 0;
  }
}
.clickLine {
  width: 8px;
  height: 600px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: #c0d4f0;
  .clickLineLeft {
    position: relative;
    top: 50%;
    left: -3px;
    color: #97a8be;
    cursor: pointer;
  }
  .clickLineRight {
    position: relative;
    top: 50%;
    left: -5px;
    color: #97a8be;
    cursor: pointer;
  }
}
</style>