var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show == 0,
          expression: "show == 0",
        },
        { name: "drags", rawName: "v-drags" },
      ],
      staticClass: "callphone_one",
      on: { click: _vm.unfold },
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show == 1,
            expression: "show == 1",
          },
          { name: "drag", rawName: "v-drag" },
        ],
        ref: "child",
        staticClass: "callphone_two",
        attrs: { onselectstart: "return false" },
      },
      [
        _c("div", {
          staticClass: "clsoe el-icon-close",
          on: {
            click: function ($event) {
              return _vm.clsoecall(1)
            },
          },
        }),
        _c("div", { staticClass: "funcition" }, [
          _vm.callnum == 1
            ? _c(
                "div",
                {
                  staticClass: "call_icon",
                  on: {
                    click: function ($event) {
                      return _vm.checnum(2, true)
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-phone" })]
              )
            : _vm._e(),
          _vm.callnum == 2
            ? _c(
                "div",
                {
                  staticClass: "call_icon yelel hov",
                  on: {
                    click: function ($event) {
                      return _vm.checnum(1, false)
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-phone anmation" })]
              )
            : _vm._e(),
          _vm.callnum == 3
            ? _c(
                "div",
                {
                  staticClass: "call_icon grenyel hov",
                  on: {
                    click: function ($event) {
                      return _vm.checnum(1, false)
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-phone anmation" })]
              )
            : _vm._e(),
          _c("div", { staticClass: "call_center" }, [
            _c("div", { staticClass: "call_top" }, [
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "4px", "font-size": "12px" },
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [_vm._v(" " + _vm._s(_vm.takeinfo.username) + " ")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "call_bottom" },
              [
                _c(
                  "el-dropdown",
                  { attrs: { trigger: "click" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "call_status",
                        staticStyle: { "font-size": "12px" },
                        style: {
                          color:
                            _vm.Takestatus == "未授权"
                              ? "rgb(212, 6, 6)"
                              : "white",
                        },
                      },
                      [
                        _vm.Takestatus == "未授权"
                          ? _c("i", { staticClass: "el-icon-warning-outline" })
                          : _vm._e(),
                        _vm._v(" " + _vm._s(_vm.Takestatus) + " "),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "div",
                            {
                              staticClass: "regis",
                              on: { click: _vm.getAgentMsg },
                            },
                            [_vm._v("在线")]
                          ),
                        ]),
                        _c("el-dropdown-item", [
                          _c(
                            "div",
                            {
                              staticClass: "callout",
                              on: { click: _vm.unregisetPhone },
                            },
                            [_vm._v("离线")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "call_right" },
            [
              _c("el-tooltip", [
                _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                  _vm._v(" 人工呼叫web坐席注意事项: "),
                  _c("br"),
                  _vm._v(" 1、注意浏览器需要是新版谷歌 "),
                  _c("br"),
                  _vm._v(" 2、注意域名需要加https "),
                  _c("br"),
                  _vm._v(" 3、注意话机状态需要是【上线】 "),
                  _c("br"),
                  _vm._v(" 4、注意需要有麦克风 "),
                  _c("br"),
                  _vm._v(
                    " 5、注意第一次通话时，浏览器弹出是否允许麦克风，需点击【允许】 "
                  ),
                ]),
                _c("div", {
                  staticClass: "el-icon-warning",
                  staticStyle: { color: "white" },
                }),
              ]),
              _c("div", { staticClass: "screeen", on: { click: _vm.pan } }, [
                _c("img", {
                  attrs: {
                    alt: "",
                    src: require("../../../assets//img/fullscreen.png"),
                  },
                }),
              ]),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show == 2,
            expression: "show == 2",
          },
          { name: "drag", rawName: "v-drag" },
        ],
        ref: "child",
        staticClass: "callphone_two",
        staticStyle: {
          "align-items": "flex-start",
          width: "280px",
          height: "450px",
          "border-radius": "16px",
        },
        attrs: { onselectstart: "return false" },
      },
      [
        _c(
          "div",
          {
            staticClass: "funcition",
            staticStyle: {
              display: "grid",
              "grid-template-columns": "repeat(1, 1fr)",
              gap: "10px",
              width: "100%",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "call_center",
                staticStyle: {
                  display: "grid",
                  "grid-template-columns": "repeat(3, 1fr)",
                  "align-items": "center",
                  "margin-top": "18px",
                },
              },
              [
                _c("div", { staticClass: "call_top" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-left": "10px",
                        "font-size": "12px",
                      },
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [_vm._v(" " + _vm._s(_vm.takeinfo.username) + " ")]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "call_bottom" },
                  [
                    _c(
                      "el-dropdown",
                      { attrs: { trigger: "click" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "call_status",
                            staticStyle: { "font-size": "12px" },
                            style: {
                              color:
                                _vm.Takestatus == "未授权"
                                  ? "rgb(212, 6, 6)"
                                  : "white",
                            },
                          },
                          [
                            _vm.Takestatus == "未授权"
                              ? _c("i", {
                                  staticClass: "el-icon-warning-outline",
                                })
                              : _vm._e(),
                            _vm._v(" " + _vm._s(_vm.Takestatus) + " "),
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("el-dropdown-item", [
                              _c(
                                "div",
                                {
                                  staticClass: "regis",
                                  on: { click: _vm.getAgentMsg },
                                },
                                [_vm._v("在线")]
                              ),
                            ]),
                            _c("el-dropdown-item", [
                              _c(
                                "div",
                                {
                                  staticClass: "callout",
                                  on: { click: _vm.unregisetPhone },
                                },
                                [_vm._v("离线")]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("el-popover", {
                      attrs: { trigger: "click", width: "400" },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "call_right", staticStyle: { top: "30px" } },
              [
                _c("el-tooltip", [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(" 人工呼叫web坐席注意事项: "),
                    _c("br"),
                    _vm._v(" 1、注意浏览器需要是新版谷歌 "),
                    _c("br"),
                    _vm._v(" 2、注意域名需要加https "),
                    _c("br"),
                    _vm._v(" 3、注意话机状态需要是【上线】 "),
                    _c("br"),
                    _vm._v(" 4、注意需要有麦克风 "),
                    _c("br"),
                    _vm._v(
                      " 5、注意第一次通话时，浏览器弹出是否允许麦克风，需点击【允许】 "
                    ),
                  ]),
                  _c("div", {
                    staticClass: "el-icon-warning",
                    staticStyle: { color: "white" },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "screeen",
                    on: {
                      click: function ($event) {
                        return _vm.clsoecall(2)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        alt: "",
                        src: require("../../../assets//img/fullscreen.png"),
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "Keyboard" }, [
              _c("div", { staticClass: "scanner" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.callphone,
                      expression: "callphone",
                    },
                  ],
                  staticStyle: { "background-color": "none" },
                  attrs: { maxlength: "15", placeholder: "请输入拨打号码" },
                  domProps: { value: _vm.callphone },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.callphone = $event.target.value
                    },
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "Numeric-keypad" },
                [
                  _vm._l(9, function (ym, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "keypad",
                        on: {
                          click: function ($event) {
                            return _vm.checkcall(index + 1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(index + 1))]
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "keypad",
                      on: {
                        click: function ($event) {
                          return _vm.checkcall("*")
                        },
                      },
                    },
                    [_vm._v("*")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "keypad",
                      on: {
                        click: function ($event) {
                          return _vm.checkcall("0")
                        },
                      },
                    },
                    [_vm._v("0")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "keypad",
                      on: {
                        click: function ($event) {
                          return _vm.checkcall("#")
                        },
                      },
                    },
                    [_vm._v("#")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "claer",
                      staticStyle: {
                        "line-height": "50px",
                        color: "white",
                        "text-align": "center",
                      },
                      on: {
                        click: function ($event) {
                          _vm.callphone = ""
                        },
                      },
                    },
                    [_vm._v("清空")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "keypad linar",
                      staticStyle: {
                        color: "white",
                        "text-align": "center",
                        "background-color": "#3782ff",
                      },
                      on: { click: _vm.calkeys },
                    },
                    [_c("i", { staticClass: "el-icon-phone" })]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "linar",
                      staticStyle: {
                        "line-height": "50px",
                        color: "white",
                        "text-align": "center",
                      },
                      on: { click: _vm.popnum },
                    },
                    [_c("i", { staticClass: "el-icon-caret-left" })]
                  ),
                ],
                2
              ),
            ]),
          ]
        ),
      ]
    ),
    _c("audio", { attrs: { id: "remoteAudio", autoPlay: "" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }