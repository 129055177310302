<template>
  <div>
    <el-descriptions :column="1" size="medium " title="">
        <el-descriptions-item label="事件编号">{{list.eventNumber	}}</el-descriptions-item>
        <el-descriptions-item label="事件状态">
          <!-- <span v-if="list.afterType==1">待分配</span> -->
          <span v-if="list.afterType==2">处理中</span>
          <span v-if="list.afterType==3">待审核</span>
          <span v-if="list.afterType==4">完成</span>
        </el-descriptions-item>
        <el-descriptions-item label="客情备注">{{list.remark}}</el-descriptions-item>
        <el-descriptions-item label="客情类型">{{list.lebalName}}</el-descriptions-item>
        <el-descriptions-item label="提交员工">{{list.realName}}</el-descriptions-item>
        <el-descriptions-item label="客户姓名	">{{list.userName}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='1'" label="客户反馈情况	">{{list.issueMark}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='1'" label="客户客情时间	">{{list.afterTime}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='1'" label="客户成交签收金额	">{{list.signAmount}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='2'" label="客户签收时间	">{{list.signTime}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='2'" label="客户反馈时间	">{{list.feedTime}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='2'" label="产品问题描述	">{{list.issueMark}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='3'" label="客户签收时间	">{{list.signTime}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='3'" label="客户反馈时间	">{{list.feedTime}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='3'" label="产品问题描述	">{{list.issueMark}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='4'" label="客户签收时间	">{{list.signTime}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='4'" label="客户反馈时间	">{{list.feedTime}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='4'" label="产品问题描述	">{{list.issueMark}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='5'" label="客户反馈情况	">{{list.issueMark}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='5'" label="客户客情时间	">{{list.afterTime}}</el-descriptions-item>
        <el-descriptions-item v-if="list.lebalId=='5'" label="客户退定金金额	">{{list.refundAmount}}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import {uuUserSugarRecordAfterdetails} from '@/api/mall/aftersaledata/index'
export default {
  data(){
    return{
    
      list:[],
      listID:null
    }
  },
  created(){
    if(this.$route.query.date){
      this.listID=JSON.parse(this.$route.query.date)
      this.uuUserSugarRecordAfterdetails()
    }
  
  },
 
  methods:{
    uuUserSugarRecordAfterdetails(){
      uuUserSugarRecordAfterdetails({id:this.listID.id}).then(res=>{
        console.log(res.data,'12')
        this.list=res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-descriptions-item__content{
    margin-left: 10px;
  }
  .el-descriptions-item__label{
    width: 130px;
    text-justify: distribute;
    display: inline-block;
  }
}
</style>