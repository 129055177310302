import request from '@/utils/request'
import { getToken } from '@/utils/token'
// 角色按钮权限
export function selectRoleMenuButtonList(data) {
  return request({
    url: `/upms/sys/comSysRoleButton/selectRoleMenuButtonList`,
    method: 'post',
    data,
  })
}
// 字典
export function selectSysDict(data) {
  return request({
    url: `/upms/sys/sysDict/selectSysDict`,
    method: 'post',
    data,
  })
}
// 省
export function getArea(data) {
  return request({
    url: `/sys/sysArea/selectProvince`,
    method: 'post',
    data,
  })
}
// 省市区三级联动
export function getAreaThree(data) {
  return request({
    url: `/upms/sys/sysArea/selectAreaTree`,
    method: 'post',
    data,
  })
}
export function getCompany(data) {
  return request({
    url: `/upms/admin/comSysComputer/computerList`,
    method: 'get',
    data,
  })
}
// 图标管理
export function getIconInfo(data) {
  return request({
    url: `/upms/sys/sysIconInfo/queryPage`,
    method: 'post',
    data,
  })
}

// 行业
export function queryIndustry() {
  return request({
    url: '/admin/comBusiIndustry/queryIndustry',
    method: 'post',
  })
}
export function uploadGoodsImageQill(data) {
  return request({
    url: '/upms/file/uploadImages',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
    data,
    //这部分非常重要，否则formdata会被转格式
    transformRequest: [
      function () {
        return data
      },
    ],
    params: data,
  })
}
// 富文本框秀米图片上传
export function uploadUrlImgs(data) {
  return request({
    url: `/common/uploadFile/uploadUrlImgs`,
    method: 'post',
    data,
  })
}

export function uploadGoodsImage(data) {
  return request({
    url: '/upms/file/uploadGoodsImage',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
    data,
    //这部分非常重要，否则formdata会被转格式
    transformRequest: [
      function () {
        return data
      },
    ],
    params: data,
  })
}
// Cxcc呼叫系统配置服务
export function stpCxccConfig(data) {
  return request({
    url: `/mall/user/stpCxccConfig/details`,
    method: 'post',
    data,
  })
}

// export function uploadGoodsImage(data) {
//   return request({
//     url: '/upms/file/uploadImages',
//     method: 'post',
//     headers: {
//       'Content-Type': 'multipart/form-data',
//       Authorization: `Bearer ${getToken()}`,
//     },
//     data,
//     //这部分非常重要，否则formdata会被转格式
//     transformRequest: [
//       function () {
//         return data
//       },
//     ],
//     params: data,
//   })
// }

//

//合并数据

export function uuUserSugarCombineLog(data) {
  return request({
    url: `/mall/user/uuUserSugarCombineLog/queryPage`,
    method: 'post',
    data,
  })
}
