var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "div",
        { staticClass: "search", staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.formInline },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "下单部门" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      clearable: "",
                      options: _vm.dataSourceLists,
                      props: {
                        checkStrictly: true,
                        label: "orgName",
                        value: "id",
                      },
                    },
                    on: { focus: _vm.fetchData },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ node, data }) {
                          return [
                            !node.isLeaf
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(data.orgName) +
                                      "(" +
                                      _vm._s(data.children.length) +
                                      ")"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.formInline.orgId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "orgId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.orgId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      align: "right",
                      "end-placeholder": "结束日期",
                      format: "yyyy-MM-dd",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.timer,
                      callback: function ($$v) {
                        _vm.timer = $$v
                      },
                      expression: "timer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-search", type: "primary" },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", type: "warning" },
                      on: { click: _vm.reset },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData,
                "show-summary": "",
                "summary-method": _vm.getSummaries,
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "商品名称", prop: "name" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品单价",
                  prop: "salesPrice",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "下单总量",
                  prop: "orderTotal",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品总额",
                  prop: "orderTotalMoney",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "未付单量",
                  prop: "unpayOrderTotal",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "未付金额",
                  prop: "unpayOrderTotalMoney",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "收货单量",
                  prop: "receiveOrderTotal",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "退款单量",
                  prop: "returnOrderTotal",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "已付定金单量",
                  prop: "prePayOrderTotal",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "已付定金",
                  prop: "prePayOrderTotalMoney",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": 1,
          layout: "total, sizes, prev, pager, next, jumper",
          "page-size": _vm.formInline.size,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }