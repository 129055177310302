import request from '@/utils/request'
// 主题列表
export function queryPage(data) {
  return request({
    url: `/mall/activity/acActTheme/queryPage`,
    method: 'post',
    data,
  })
}
// 主题详情
export function details(data) {
  return request({
    url: `/mall/activity/acActTheme/details`,
    method: 'post',
    data,
  })
}
// 主题详情(活动列表)
export function detailsActList(data) {
  return request({
    url: `/mall/activity/acActivity/queryDetailThemeActivityList`,
    method: 'post',
    data,
  })
}
// 添加主题（第一步）
export function addAct(data) {
  return request({
    url: `/mall/activity/acActTheme/add`,
    method: 'post',
    data,
  })
}
// 添加主题（第二部分）
export function addThemeInstInfo(data) {
  return request({
    url: `/mall/activity/acActThemeInst/addThemeInstInfo`,
    method: 'post',
    data,
  })
}
// 添加主题（第二部分--活动列表）
export function actListPage(data) {
  return request({
    url: `/mall/activity/acActivity/queryThemeActivityListForPage`,
    method: 'post',
    data,
  })
}
// 编辑主题（）
export function edit(data) {
  return request({
    url: `/mall/activity/acActTheme/edit`,
    method: 'post',
    data,
  })
}
// 删除
export function deletes(data) {
  return request({
    url: `/mall/activity/acActTheme/delete`,
    method: 'post',
    data,
  })
}
// 编辑活动排序
export function editActSort(data) {
  return request({
    url: `/mall/activity/acActInstance/editActSort`,
    method: 'post',
    data,
  })
}
