import request from '@/utils/request'
// 文章列表
export function queryListForPage(data) {
  return request({
    url: `/mall/bbs/bbsArticle/queryListForPage`,
    method: 'post',
    data,
  })
}

// 文章类型
export function queryListType(data) {
  return request({
    url: `/mall/bbs/bbsArticleType/queryList`,
    method: 'post',
    data,
  })
}
// 文章专栏
export function queryListColumn(data) {
  return request({
    url: `/mall/bbs/bbsArticleColumn/queryList`,
    method: 'post',
    data,
  })
}
// 是否加精
export function isHot(data) {
  return request({
    url: `/mall/bbs/bbsArticle/isHot`,
    method: 'post',
    data,
  })
}
// 是否置顶
export function isTop(data) {
  return request({
    url: `/mall/bbs/bbsArticle/isTop`,
    method: 'post',
    data,
  })
}
// 发布
export function release(data) {
  return request({
    url: `/mall/bbs/bbsArticle/release`,
    method: 'post',
    data,
  })
}
// 删除

export function deleted(data) {
  return request({
    url: `/mall/bbs/bbsArticle/delete`,
    method: 'post',
    data,
  })
}
// 恢复

export function restore(data) {
  return request({
    url: `/mall/bbs/bbsArticle/restore`,
    method: 'post',
    data,
  })
}
// 下架

export function shelves(data) {
  return request({
    url: `/mall/bbs/bbsArticle/shelves`,
    method: 'post',
    data,
  })
}

// 消息列表
export function messageList(data) {
  return request({
    url: `/mall/user/msgNotice/queryPage`,
    method: 'post',
    data,
  })
}
