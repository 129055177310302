<template>
  <div>
    <el-table
      border
      :data="[tableData[0]]"
      style="width: 100%">
      <el-table-column
        align="center"
        label="页面名称"
        prop="name"
        width="250"/>
      <el-table-column
        align="center"
        label="渠道"
        prop="terminal"
        width="250"/>
      <el-table-column
        align="center"
        label="页面类型"
        prop="name"
        width="250"
        />
      <el-table-column
        align="center"
        label="更新时间"
        prop="updateTime"
        width="350"
        />
      <!-- <el-table-column
        align="center"
        label="到期时间"
        prop="address">
        <template #default="">
          -
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="浏览权限"
        prop="address"
        width="80px"> 
        <template #default="">
          -
        </template>
      </el-table-column> -->
      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        prop="address">
        <template #default="scope">
          <el-link type="parimay" :underline="false" @click="editCanvas(scope.row)">装修</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.current"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="queryForm.size"
      :page-sizes="[100, 200, 300, 400]"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"/>
  </div>
</template>

<script>
import {queryPage} from '@/api/mall/preview/index'
export default {
  data(){
    return{
      tableData:[],
      total:0,
      queryForm:{
        size:10,
        current:1
      }
    }
  },
  created(){
    this.queryPage()
  },
  methods:{
    editCanvas(row){
      const url =this.$router.resolve({
        path:'fitment',
        query:{date:JSON.stringify(row),copy:true}
      })
      window.open(url.href,'_blank')
    },
    queryPage(){
      queryPage({...this.queryForm}).then(res=>{
        console.log(res.data)
        this.tableData=res.data.records
        this.queryForm.size=res.data.size
        this.queryForm.current=res.data.current
        this.total=1
      })
    },
    handleCurrentChange(e){
      this.queryForm.current=e
      this.queryPage()
    },
    handleSizeChange(e){
      this.queryForm.size=e
      this.queryPage()
    },
  }
}
</script>

<style>

</style>