<template>
  <div class="dashboard-container">
    <span>可选择您想查看的时间区域！ </span>
    <!-- <el-date-picker v-model="time" :default-time="['00:00:00', '23:59:59']" end-placeholder="结束日期"
      :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" style="margin-bottom: 10px"
      type="datetimerange" /> -->
    <el-date-picker v-model="time" :default-time="['00:00:00', '23:59:59']" end-placeholder="结束日期"
      start-placeholder="开始日期" type="daterange" />
    <el-row :gutter="20" style="margin-bottom: 15px">
      <el-col :lg="6" :md="12" :sm="24" :xl="6" :xs="24">
        <div class="ym_card ym_blue">
          <top-card background="blue" icon="money-cny-circle-line" :ordermoney="orderlist.totalPayableAmount"
            percentage="0%" title="总销量（￥）" />
        </div>
      </el-col>
      <el-col :lg="6" :md="12" :sm="24" :xl="6" :xs="24">
        <div class="ym_card">
          <top-card background="white" icon="user-search-line" :ordermoney="orderlist.sucessOrderNumber" percentage="0%"
            title="总订单（单）" />
        </div>
      </el-col>
      <el-col :lg="6" :md="12" :sm="24" :xl="6" :xs="24">
        <div class="ym_card">
          <top-card background="white" icon="money-cny-box-line" :ordermoney="orderlist.tgotalConcludeAmount"
            percentage="0%" title="总成交订单金额（￥）" />
        </div>
      </el-col>
      <el-col :lg="6" :md="12" :sm="24" :xl="6" :xs="24">
        <div class="ym_card">
          <top-card background="white" icon="passport-line" :ordermoney="orderlist.tgotalConcludeNumber" percentage="0%"
            title="总成交订单数（单）" />
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :lg="16" :md="20" :sm="40" :xl="16" :xs="40">
        <trend :shuarr="arr" :shulist="list" />
      </el-col>
      <!-- <el-col :lg="6" :md="12" :sm="24" :xl="6" :xs="24">
        <branch />
      </el-col> -->
      <el-col :lg="8" :md="16" :sm="32" :xl="8" :xs="32">
        <rank />
      </el-col>
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <tabs />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Trend from './components/Trend.vue'
// import Branch from './components/Branch.vue'
import Rank from './components/Rank.vue'
import Tabs from './components/Tabs.vue'
import TopCard from './components/TopCard.vue'
import { statisticsOrderCount } from '@/api/mall/health/boardperformc/index'
export default {
  name: 'Dashboard',
  components: { Trend, Rank, Tabs, TopCard },
  data() {
    return {
      time: '',
      orderlist: {},
      arr: [],
      list: [],
      // pickerOptions: {
      //   disabledDate(time) {
      //     // 禁用将来日期
      //     return time.getTime() > Date.now();
      //   }
      // }
    }
  },
  watch: {
    time: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.time = newVal
        this.statisticsOrderCount()
      },
    },
  },
  created() {
    this.statisticsOrderCount()
    // 获取当天的日期
    const today = new Date();
    // 设置开始时间为当天的 00:00:00
    const startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    // 设置结束时间为当天的 23:59:59
    const endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
    // 将开始时间和结束时间赋值给 time 数组
    this.time = [startTime, endTime];
  },
  methods: {
    statisticsOrderCount() {
      const data = {
        beginTime: this.time ? new Date(this.time[0]).getTime() : '',
        endTime: this.time ? new Date(this.time[1]).getTime() : ''
      };
      statisticsOrderCount(this.time ? data : {}).then((res) => {
        this.orderlist = res.data
        this.arr = res.data.totalPayableAmounts
        this.list = res.data.tgotalConcludeAmounts
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.time {
  margin-bottom: 10px;
  background-image: linear-gradient(90deg, #74bcff, #1890ff);
  color: #fff;
}

.dashboard-container {
  padding: 0 !important;
  background: $base-color-background !important;

  .ym_card {
    padding: 20px;
    background: white;
    border: 1px solid #e2e2e2;
    transition: ease 500ms;

    &:hover {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .ym_blue {
    background-image: linear-gradient(90deg, #74bcff, #1890ff);
  }

  :deep() {
    .el-card {
      height: 300px;

      [class*='-echart'] {
        width: 100%;
        height: 200px;
      }
    }
  }
}
</style>
