var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _vm.unfold == "promoter"
        ? _c("promoter", { attrs: { userid: _vm.usID } })
        : _c("Promotionorder", { attrs: { userid: _vm.usID } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }