var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "全部商品", name: "1" } }),
          _c("el-tab-pane", { attrs: { label: "待审核商品", name: "2" } }),
          _c("el-tab-pane", { attrs: { label: "审核通过商品", name: "3" } }),
          _c("el-tab-pane", { attrs: { label: "审核不通过商品", name: "4" } }),
        ],
        1
      ),
      _c("Search", { on: { datelist: _vm.rowinfo } }),
      _c(
        "div",
        { staticClass: "m-b" },
        [
          _c(
            "el-button",
            {
              staticClass: "el-icon-plus",
              attrs: { type: "primary" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("新增")]
          ),
          _c(
            "el-button",
            {
              staticClass: "el-icon-top",
              attrs: { type: "" },
              on: {
                click: function ($event) {
                  return _vm.Batchlisting(1)
                },
              },
            },
            [_vm._v("批量上架")]
          ),
          _c(
            "el-button",
            {
              staticClass: "el-icon-bottom",
              attrs: { type: "" },
              on: {
                click: function ($event) {
                  return _vm.Batchlisting(0)
                },
              },
            },
            [_vm._v("批量下架")]
          ),
          _c(
            "el-button",
            {
              staticClass: "el-icon-check",
              attrs: { type: "" },
              on: {
                click: function ($event) {
                  return _vm.verify(1)
                },
              },
            },
            [_vm._v("批量审核")]
          ),
          _c(
            "el-button",
            {
              staticClass: "el-icon-close",
              attrs: { type: "" },
              on: {
                click: function ($event) {
                  return _vm.verify(2)
                },
              },
            },
            [_vm._v("批量拒绝")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.loadings,
              expression: "loadings",
              modifiers: { fullscreen: true, lock: true },
            },
          ],
          attrs: { border: "", data: _vm.data },
          on: { "selection-change": _vm.handselect },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "left",
              type: "selection",
              width: "55",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", prop: "id", width: "80" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "店铺", prop: "shopName" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品名称",
              prop: "name",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品图片",
              prop: "picUrls",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "60px", height: "60px" },
                      attrs: {
                        "preview-src-list": scope.row.picUrls,
                        src: scope.row.picUrls[0],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "价格",
              prop: "priceDown",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        "￥" +
                          _vm._s(scope.row.priceDown) +
                          _vm._s(
                            scope.row.priceUp == scope.row.priceDown
                              ? ""
                              : "~￥" + scope.row.priceUp
                          )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品编码",
              prop: "spuCode",
              "show-overflow-tooltip": "",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "规格类型",
              prop: "specType",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.specType == 0
                      ? _c("div", [_vm._v("统一规格")])
                      : _c("div", [_vm._v("多规格")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "更新时间",
              prop: "updateTime",
              "show-overflow-tooltip": "",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "是否上架",
              "show-overflow-tooltip": "",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "active-value": "1",
                        "inactive-color": "#ff4949",
                        "inactive-value": "0",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.shelf(scope.row.id, scope.row.shelf)
                        },
                      },
                      model: {
                        value: scope.row.shelf,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "shelf", $$v)
                        },
                        expression: "scope.row.shelf",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "积分赠送",
              prop: "pointsGiveSwitch",
              "show-overflow-tooltip": "",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.pointsGiveSwitch == 1
                      ? _c("div", [_vm._v("开启")])
                      : _c("div", [_vm._v("未开启")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "审核状态",
              prop: "verifyStatus",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        scope.row.verifyStatus == 0
                          ? _c("el-tag", { attrs: { size: "small" } }, [
                              _vm._v("审核中"),
                            ])
                          : _vm._e(),
                        scope.row.verifyStatus == 1
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "success" } },
                              [_vm._v("审核通过")]
                            )
                          : _vm._e(),
                        scope.row.verifyStatus == 2
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "danger" } },
                              [_vm._v("审核不通过")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", fixed: "right", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.delobj(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }