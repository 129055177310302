import request from '@/utils/request'
// 列表左侧
export function selectSysDictMenu(data) {
  return request({
    url: '/upms/sys/sysDict/selectSysDictMenu',
    method: 'post',
    data,
  })
}
// 字典列表
export function queryPage(data) {
  return request({
    url: '/upms/sys/sysDict/queryPage',
    method: 'post',
    data,
  })
}
// 新增
export function add(data) {
  return request({
    url: '/upms/sys/sysDict/add',
    method: 'post',
    data,
  })
}

// 编辑
export function edit(data) {
  return request({
    url: '/upms/sys/sysDict/edit',
    method: 'post',
    data,
  })
}
// 删除

export function isDelete(data) {
  return request({
    url: '/upms/sys/sysDict/isDelete',
    method: 'post',
    data,
  })
}
