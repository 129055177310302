<template>
  <el-dialog :before-close="handleClose" title="新增用药记录" :visible.sync="dialogVisible" width="50%">
    <span>
      <el-form ref="ruleForm" label-width="100px" :model="ruleForm" :rules="rules">
        <div class="demo-ruleForms">
          <el-form-item label="开始时间" prop="phyTime">
            <el-date-picker v-model="ruleForm.phyTime" align="center" placeholder="选择开始时间" type="date" value-format="yyyy-MM-dd" />
          </el-form-item>
          <el-form-item label="结束时间" prop="leaveTime">
            <el-date-picker v-model="ruleForm.leaveTime" align="center" placeholder="选择结束时间" type="date" value-format="yyyy-MM-dd" />
          </el-form-item>
        </div>
        <el-form-item label="针对情况" prop="precept">
          <el-input v-model="ruleForm.precept" placeholder="请输入 针对情况" />
        </el-form-item>
        <el-table border :data="ruleForm.fitness" style="width: 100%">
          <el-table-column align="center" label="药品名称" prop="name">
            <template #default="scope">
              <el-input v-model="scope.row.name" placeholder="请输入 药品名称" />
            </template>
          </el-table-column>
          <el-table-column align="center" label="药品规格" prop="title">
            <template #default="scope">
              <el-input v-model="scope.row.title" placeholder="请输入 药品规格" />
            </template>
          </el-table-column>
          <el-table-column align="center" label="每天次数" prop="freNum">
            <template #default="scope">
              <el-input v-model="scope.row.freNum" placeholder="请输入 每天次数" />
            </template>
          </el-table-column>
          <el-table-column align="center" label="每天用量" prop="fitDosage">
            <template #default="scope">
              <el-input v-model="scope.row.fitDosage" placeholder="请输入 每天次数" />
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" prop="fitDosage">
            <template #default="scope">
              <el-button type="text" @click="handleDelete(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="add-item" @click="addlist">
          <i class="el-icon-plus"></i>
          添加
        </div>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { userFitnessInfoadd } from '@/api/mall/users/usermore'
export default {
  props: {
    show: {
      type: Boolean,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ruleForm: {
        fitness: [{}],
      },
      rules: {
        title: [{ required: true, message: '请输入 就医单位', trigger: 'blur' }],
        content: [{ required: true, message: '请输入 就诊科室', trigger: 'blur' }],
        fitNum: [{ required: true, message: '请输入 就医卡号', trigger: 'blur' }],
        phyTime: [{ type: 'string', required: true, message: '请选择 开始时间', trigger: 'change' }],
        leaveTime: [{ type: 'string', required: true, message: '请选择 结束时间', trigger: 'change' }],
        fitType: [{ required: true, message: '请选择 就医类型', trigger: 'change' }],
        medicName: [{ required: true, message: '请输入 主管医生', trigger: 'blur' }],
        fitEnable: [{ required: true, message: '请选择 当前状态', trigger: 'change' }],
        chiefTitle: [{ required: true, message: '请输入 主诉', trigger: 'blur' }],
        mark: [{ required: true, message: '请输入 检查', trigger: 'blur' }],
        diagnosis: [{ required: true, message: '请输入 诊断', trigger: 'blur' }],
        precept: [{ required: true, message: '请输入 针对情况', trigger: 'blur' }],
      },
      dialogVisible: false,
      uphide: false,
      sub: 0,
      checknum: [
        {
          id: 1,
          labelName: '门诊',
        },
        {
          id: 2,
          labelName: '住院',
        },
      ],
      labType: [
        {
          id: 1,
          labelName: '已康复',
        },
        {
          id: 2,
          labelName: '治疗中',
        },
      ],
    }
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue != oldValue) {
        this.dialogVisible = newValue
        this.uphide = newValue
        this.$forceUpdate()
      }
    },
  },
  methods: {
    handleDelete(e) {
      if (this.ruleForm.fitness.length > 1) {
        this.ruleForm.fitness.splice(e, 1)
      } else {
        this.$message.error('用药记录必须保留一条')
      }
    },
    addlist() {
      this.ruleForm.fitness.push({})
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          userFitnessInfoadd({ userId: this.value.userId, fitStatus: 8, ...this.ruleForm }).then((res) => {
            if (res.status == 200) {
              this.ruleForm = {
                fitUrl: [],
              }
              this.$emit('addshows', false)
              this.ruleForm = {};
            }
          })
        } else {
          return false
        }
      })
    },
    handleClose() {
      this.$refs.ruleForm.resetFields()
      this.$emit('addshows', false)
      this.ruleForm = {};
    },
    check(e) {
      if (e.url) {
        this.ruleForm.fitUrl.forEach((item, index) => {
          if (item == e.url) {
            this.ruleForm.fitUrl.splice(index, 1)
          }
        })
      } else {
        this.ruleForm.fitUrl.push(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.demo-ruleForms {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.add-item {
  border: 1px solid #e5e5e5;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  font-size: 14px;
}
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
  .el-select,
  .el-date-editor,
  .input__inner {
    width: 100%;
  }
}
</style>