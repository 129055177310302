<template>
  <div class="main-content">
    <div class="canvas-item">
      <LeftNav :navlist="assemble"/>
    </div>
    <div class="canvas-item">
      <CenterContent/>
      <div class="menu_navinto">
        <div class="menu_item" @click="topdown">
          <el-tooltip class="item" content="前移选中组件" effect="dark" placement="right" >
            <i class="el-icon-arrow-up"></i>
          </el-tooltip>
        </div>
        <div class="menu_item" @click="btndown">
          <el-tooltip class="item" content="后移选中组件" effect="dark" placement="right">
            <i class="el-icon-arrow-down"></i>
          </el-tooltip>
        </div>
        <div class="menu_item" @click="copydown">
          <el-tooltip class="item" content="复制选中组件" effect="dark" placement="right">
            <i class="el-icon-copy-document"></i>
          </el-tooltip>
        </div>
        <div class="menu_item" @click="deleteCanvas">
          <el-tooltip class="item" content="删除选中组件" effect="dark" placement="right">
            <i class="el-icon-delete"></i>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="canvas-item">
      <RightControls/>
    </div>
  </div>
</template>

<script>
  import LeftNav from './components/left-nav'
  import CenterContent from './components/center-canvas/index.vue'
  import RightControls from './components/right-Controls/index.vue'
  import assemble from './components/left-nav/nav-assemble/assemble'
  export default {
    components: {
      LeftNav,
      CenterContent,
      RightControls,
    },
    data(){
      return{
        assemble:assemble,
        checkcanvas:[]
      }
    },
    mounted(){
      this.$baseEventBus.$on('checkdiycanvas', (e)=>{
          //需要执行的代码
          this.checkcanvas.push(e)
          console.log(this.checkcanvas,'测试数据')
      })
    },
    methods:{
      //上移
      topdown(){
        const checnum=this.$store.state.checknumber
       if(checnum!=0){
          const list=this.$store.state.checkvalue[checnum]
          const can=this.$store.state.checkcanvas[checnum]
          this.$store.state.checkvalue.splice(checnum, 1);
          this.$store.state.checkcanvas.splice(checnum, 1);
          this.$store.state.checkvalue.splice(checnum-1, 0, list); 
          this.$store.state.checkcanvas.splice(checnum-1, 0, can); 
          this.$store.state.checknumber=checnum-1
       }else{
        this.$message.error('已经是位列第一了哦～')
       }
      },
      //下移
      btndown(){
        const checnum=this.$store.state.checknumber
        const maxlen=this.$store.state.checkvalue.length-1
       if(checnum>=0&&checnum<maxlen){
          const list=this.$store.state.checkvalue[checnum]
          const can=this.$store.state.checkcanvas[checnum]
          this.$store.state.checkvalue.splice(checnum, 1);
          this.$store.state.checkcanvas.splice(checnum, 1);
          this.$store.state.checkvalue.splice(checnum+1, 0, list); 
          this.$store.state.checkcanvas.splice(checnum+1, 0, can); 
          this.$store.state.checknumber=checnum+1
       }else{
        this.$message.error('已经是位列最后了哦～')
       }
      },
      copydown(){
        const checnum=this.$store.state.checknumber
        const list=this.$store.state.checkvalue[checnum]
        const can=this.$store.state.checkcanvas[checnum]
        this.$store.state.checkvalue.splice(checnum+1, 0, list); 
        this.$store.state.checkcanvas.splice(checnum+1, 0, can); 
        this.$store.state.checknumber=checnum+1
      },
      deleteCanvas(){
        this.$confirm('是否删除该组件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          this.$baseEventBus.$emit('deletecanvas')
        })
        .catch(() => {
          this.$message('您已取消删除')
        })
      }
    }
  }
</script>

<style lang="scss">
  .main-content {
    display: grid;
    grid-template-columns: 280px auto 390px;
    width: 100%;
    height: 100%;
    .canvas-item {
      min-height: calc(100vh - 53px);
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(219, 219, 219);
      }
      &::-webkit-scrollbar {
        width: 3px;
      }
    }
    .canvas-item:nth-child(2) {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 395px;
      background: #f0f3f4;
      .menu_navinto {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2000;
        width: 40px;
        background: white;
        box-shadow: 3px 8px 10px rgb(221, 221, 221);
        transform: translate(500%,-50%);
        .menu_item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          font-size: 22px;
          font-weight: bold;
          cursor: pointer;
          &:hover {
            color: rgb(71, 71, 71);
            background: rgba(190, 190, 190, 0.2);
          }
        }
      }
    }
  }
</style>
