<template>
  <div class="cy-container">
    <div class="el-container">
      <!-- content -->
      <div ref="contantRef" style="width: 100%">
        <!-- 查询 -->
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item label="菜单名称">
            <el-input v-model.trim="queryForm.menuName" class="filter-input" clearable placeholder="菜单名称" />
          </el-form-item>
          <el-form-item label="菜单图标">
            <el-input v-model="queryForm.menuIcon" class="filter-input" clearable placeholder="菜单图标" />
          </el-form-item>
          <el-form-item label="菜单路由">
            <el-input v-model="queryForm.menuPath" class="filter-input" clearable placeholder="菜单路由" />
          </el-form-item>
          <el-form-item label="菜单状态">
            <el-select v-model="queryForm.menuStatus" class="filter-input" clearable placeholder="菜单状态">
              <el-option v-for="item in roleSelectList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="handleQuery">查询</el-button>
            <el-button icon="el-icon-refresh-right" type="warning" @click="handleReset">重置</el-button>
          </el-form-item>
          <div class="ref">
            <el-form-item>
              <el-button
                v-for="(item,ind) in searchButton"
                :key="item.id"
                :plain="item.cssType && item.cssType == 'plain' ? true : false"
                :type="ind==0?item.buttonCss:''"
                @click.native="handleClickBtn(item)"
              >
                <span v-if="!item.buttonIcon"></span>
                <vab-icon v-else :icon="item.buttonIcon" />
                {{ item.buttonName }}
              </el-button>
              <!-- <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
              新增
            </el-button>
            <el-button type="primary" @click="handleExport">
              <vab-icon icon="file-copy-2-fill" />
              导出
            </el-button>
            <el-button
              icon="el-icon-delete"
              plain
              type="danger"
              @click="handleDelete"
            >
              删除
            </el-button> -->
            </el-form-item>
          </div>
        </el-form>
        <!-- table表单 -->
        <el-table v-loading="listLoading" border :data="list" max-height="650" row-key="id" :tree-props="{ children: 'children' }" @selection-change="setSelectRows">
          <el-table-column align="center" show-overflow-tooltip type="selection" />
          <el-table-column label="菜单名称" prop="menuName" show-overflow-tooltip />

          <el-table-column align="center" label="菜单图标" prop="menuIcon" show-overflow-tooltip width="250px">
            <template #default="{ row }">
              <div>
                <div style="position: relative; align-items: center">
                  <div>
                    <vab-icon v-if="!row.menuIcon" icon="" />
                    <vab-icon v-else :icon="row.menuIcon" />
                    <div>{{ row.menuIcon }}</div>
                  </div>
                  <i v-if="listShows" class="el-icon-edit" style="position: absolute; top: 10px; right: 0px; color: #1890ff; cursor: pointer" @click="handleIconedit(row)"></i>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="菜单路由" prop="menuPath" show-overflow-tooltip />
          <el-table-column align="center" label="菜单排序" prop="menuSort" show-overflow-tooltip>
            <template #default="{ row }">
              <div>
                <el-input
                  v-if="row.inputVisible"
                  v-model.trim="row.menuSort"
                  :max="0"
                  :maxlength="2"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  style="width: 80px"
                  @blur="handleInputConfirm(row)"
                  @keyup.enter.native="handleInputConfirm(row)"
                />
                <span v-else>{{ row.menuSort }}</span>
                <i v-if="listShows" class="el-icon-edit" style="position: absolute; top: 40%; right: 10px; color: #1890ff; cursor: pointer" @click="handleSortdit(row)"></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="菜单状态" show-overflow-tooltip>
            <template #default="{ row }">
              <el-tag :type="row.menuStatus === 0 ? 'success' : 'danger'">
                {{ row.menuStatus === 0 ? '正常' : '删除' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="创建时间" prop="createTime" show-overflow-tooltip />
          <el-table-column v-if="operationShow" align="center" label="操作">
            <template #default="{ row }">
              <el-button
                v-for="item in listButton"
                :key="item.id"
                :plain="item.cssType && item.cssType == 'plain' ? true : false"
                size="default"
                :type="item.buttonCss"
                @click.native="handleClickBtn(item, row)"
              >
                <!-- <span v-if="!item.buttonIcon"></span>
                <vab-icon v-else :icon="item.buttonIcon" /> -->
                {{ item.buttonName }}
              </el-button>
              <!-- <el-button
                icon="el-icon-edit-outline"
                style="margin-right: 10px"
                type="text"
                @click="handleEdit(row)"
              >
                编辑
              </el-button>
              <el-button
                icon="el-icon-plus"
                size="small"
                style="margin-right: 10px"
                type="text"
                @click="handleAddson(row)"
              >
                添加
              </el-button>
              <el-button
                icon="el-icon-delete"
                size="small"
                type="text"
                @click="handleDelete(row)"
              >
                删除
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          :current-page="forms.current"
          :layout="layout"
          :page-size="forms.size"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
    <!-- 图标弹框 -->
    <el-dialog class="dialogMenu" :visible.sync="dialogFormVisibleIcon">
      <vab-icon-selector @handle-icon="handleIcon" />
    </el-dialog>
    <!-- 添加编辑弹框 -->
    <el-dialog class="dialogMenu" :close-on-click-modal="false" :title="title" :visible.sync="dialogFormVisible" @close="close">
      <el-form ref="postForm" label-width="125px" :model="postForm" :rules="rules">
        <el-form-item label="菜单名称" prop="menuName">
          <el-input v-model.trim="postForm.menuName" clearable />
        </el-form-item>
        <el-form-item label="菜单路由" prop="menuPath">
          <el-input v-model.trim="postForm.menuPath" clearable />
        </el-form-item>
        <el-form-item label="菜单组件" prop="menuComponent">
          <el-input v-model.trim="postForm.menuComponent" clearable />
        </el-form-item>
        <el-form-item label="路由名称" prop="routesName">
          <el-input v-model.trim="postForm.routesName" clearable />
        </el-form-item>
        <el-form-item label="侧栏突出显示路径" prop="activeMenu">
          <el-input v-model.trim="postForm.activeMenu" clearable />
        </el-form-item>
        <el-form-item label="菜单排序" prop="menuSort">
          <el-input-number v-model.trim="postForm.menuSort" :min="1" placeholder="请输入" size="small" />
        </el-form-item>
        <el-form-item label="菜单状态" prop="menuStatus">
          <el-radio-group v-model="postForm.menuStatus">
            <el-radio :label="1">删除</el-radio>
            <el-radio :label="0">正常</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否显示根菜单" prop="alwaysShow">
          <el-select v-model="postForm.alwaysShow" clearable placeholder="是否显示根菜单" style="width: 100%">
            <el-option v-for="item in isShowList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否显示面包屑" prop="breadcrumbHidden">
          <el-select v-model="postForm.breadcrumbHidden" clearable placeholder="是否显示面包屑" style="width: 100%">
            <el-option v-for="item in isShowList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否隐藏" prop="hidden">
          <el-select v-model="postForm.hidden" clearable placeholder="是否隐藏" style="width: 100%">
            <el-option v-for="item in isShowListhden" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="动态选择选项" prop="dynamicNewTab">
          <el-select v-model="postForm.dynamicNewTab" clearable placeholder="动态选择选项" style="width: 100%">
            <el-option v-for="item in isShowList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="title === '新增' ? createData() : updateData()">确 定</el-button>
      </template>
    </el-dialog>
    <!-- 添加子级弹框 -->
    <el-dialog class="dialogMenu" :close-on-click-modal="false" title="添加子级" :visible.sync="dialogFormVisibleSon" @close="closeSon">
      <el-form ref="postFormSon" label-width="125px" :model="postFormSon" :rules="rulesSon">
        <el-form-item label="菜单名称" prop="menuName">
          <el-input v-model.trim="postFormSon.menuName" clearable />
        </el-form-item>
        <el-form-item label="菜单路由" prop="menuPath">
          <el-input v-model.trim="postFormSon.menuPath" clearable />
        </el-form-item>
        <el-form-item label="菜单组件" prop="menuComponent">
          <el-input v-model.trim="postFormSon.menuComponent" clearable />
        </el-form-item>
        <el-form-item label="路由名称" prop="routesName">
          <el-input v-model.trim="postFormSon.routesName" clearable />
        </el-form-item>
        <el-form-item label="侧栏突出显示路径" prop="activeMenu">
          <el-input v-model.trim="postFormSon.activeMenu" clearable />
        </el-form-item>
        <el-form-item label="菜单排序" prop="menuSort">
          <el-input-number v-model.trim="postFormSon.menuSort" :min="1" placeholder="请输入" size="small" />
        </el-form-item>
        <el-form-item label="菜单状态" prop="menuStatus">
          <el-radio-group v-model="postFormSon.menuStatus">
            <el-radio :label="1">删除</el-radio>
            <el-radio :label="0">正常</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否显示根菜单" prop="alwaysShow">
          <el-select v-model="postFormSon.alwaysShow" clearable placeholder="是否显示根菜单" style="width: 100%">
            <el-option v-for="item in isShowList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否显示面包屑" prop="breadcrumbHidden">
          <el-select v-model="postFormSon.breadcrumbHidden" clearable placeholder="是否显示面包屑" style="width: 100%">
            <el-option v-for="item in isShowList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否隐藏" prop="hidden">
          <el-select v-model="postFormSon.hidden" clearable placeholder="是否隐藏" style="width: 100%">
            <el-option v-for="item in isShowListhden" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="动态选择选项" prop="dynamicNewTab">
          <el-select v-model="postFormSon.dynamicNewTab" clearable placeholder="动态选择选项" style="width: 100%">
            <el-option v-for="item in isShowList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-form>

      <template #footer>
        <el-button @click="closeSon">取 消</el-button>
        <el-button type="primary" @click="createDataSon()">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { selectRoleMenuButtonList } from '@/api/mall/common'
import { selectMenuTree } from '@/api/mall/role/roleManage'
import { queryPage, menuAdd, menuUpdate, menuDelete, loadUpdate } from '@/api/mall/role/menuManage'
import VabIconSelector from '@/components/VabIconSelector/index.vue'
export default {
  components: { VabIconSelector },
  data() {
    return {
      operationShow: false,
      listShows: false,
      searchButton: [],
      listButton: [],
      isShowList: [
        {
          label: '显示',
          value: true,
        },
        {
          label: '隐藏',
          value: false,
        },
      ],
      isShowListhden: [
        {
          label: '显示',
          value: false,
        },
        {
          label: '隐藏',
          value: true,
        },
      ],
      inputVisible: false,
      handleIconShow: false,
      dialogFormVisibleIcon: false,
      icon: '24-hours-fill',
      listLoading: false,
      isIndeterminate: true,
      checkAll: false,
      menuCode: '',
      parentName: '',
      searchShow: false,
      list: [],
      current: 1,
      size: 10,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      selectRows: '',
      forms: { current: 1, size: 20 },
      isShow: true,
      queryForm: {
        menuName: '',
        menuIcon: '',
        menuPath: '',
        menuStatus: '',
      },
      title: '',
      dialogFormVisible: false,
      postForm: {
        menuName: '',
        parentId: '',
        menuStatus: 0,
        menuIcon: '',
        menuPath: '',
        menuComponent: '',
        menuSort: '',
        routesName: '',
        alwaysShow: null,
        breadcrumbHidden: null,
        dynamicNewTab: null,
        hidden: null,
      },
      dialogFormVisibleSon: false,
      postFormSon: {
        menuStatus: 0,
      },
      checkboxBtns: [],
      radio: '1',
      rulesSon: {
        menuName: [
          {
            required: true,
            message: '请输入菜单名称！',
            trigger: ['blur', 'change'],
          },
        ],
        menuPath: [
          {
            required: true,
            message: '请输入菜单路由！',
            trigger: ['blur', 'change'],
          },
        ],
        menuStatus: [
          {
            required: true,
            message: '请选择菜单状态',
            trigger: ['blur', 'change'],
          },
        ],
      },
      rules: {
        menuName: [
          {
            required: true,
            message: '请输入菜单名称！',
            trigger: ['blur', 'change'],
          },
        ],
        menuPath: [
          {
            required: true,
            message: '请输入菜单路由！',
            trigger: ['blur', 'change'],
          },
        ],
        menuStatus: [
          {
            required: true,
            message: '请选择菜单状态',
            trigger: ['blur', 'change'],
          },
        ],
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      orgsName: '',
      orgsListTable: [],
      leftIcon: true,
      rightIcon: false,
      roleSelectList: [
        {
          label: '正常',
          value: '0',
        },
        {
          label: '删除',
          value: '1',
        },
      ],
      btns: [],
      rowId: '',
      rowIdson: '',
      rowPareIdson: '',
      iconId: '',
      Sort: '',
    }
  },
  created() {
    this.queryPage()
    this.selectMenuTree()
    this.selectRoleMenuButtonList()
  },
  methods: {
    // 按钮点击
    handleClickBtn(item, row) {
      if (item.buttonUrl) {
        this[item.buttonUrl](row)
      }
    },
    // 按钮权限
    selectRoleMenuButtonList() {
      selectRoleMenuButtonList({
        roleIdStrList: this.$store.getters['acl/roleList'],
        menuId: this.$route.meta.id,
      })
        .then((res) => {
          if (res.data) {
            this.searchButton = res.data.filter(function (item) {
              return item.buttonType == 1
            })
            this.listButton = res.data.filter(function (item) {
              return item.buttonType == 2
            })
            if (this.listButton.length > 0) {
              this.operationShow = true
            } else {
              this.operationShow = false
            }
            const buttonUrl = this.listButton.map((item) => {
              return item.buttonUrl
            })
            if (buttonUrl.includes('handleEdit')) {
              this.listShows = true
            } else {
              this.listShows = false
            }
          }
        })
        .catch(() => {})
    },
    // 编辑菜单图标
    handleIconedit(row) {
      this.dialogFormVisibleIcon = true
      this.iconId = row.id
    },
    // 菜单排序
    handleInputConfirm(row) {
      const tempData = {}
      tempData.id = this.Sort
      tempData.menuSort = row.menuSort
      menuUpdate(tempData)
        .then(() => {
          row.inputVisible = false
          this.queryPage()
        })
        .catch(() => {})
    },
    // 菜单排序
    handleSortdit(row) {
      this.$set(row, 'inputVisible', true)
      row.inputVisible = true
      this.Sort = row.id
    },
    // 图标
    handleIcon(item) {
      const tempData = {}
      tempData.id = this.iconId
      tempData.menuIcon = item.iconKey
      menuUpdate(tempData)
        .then(() => {
          this.dialogFormVisibleIcon = false
          this.queryPage()
        })
        .catch(() => {})
    },

    // 读取菜单
    async selectMenuTree() {
      this.listLoading = true
      await selectMenuTree()
        .then((res) => {
          if (res.data) {
            this.orgsListTable = res.data
          }
        })
        .catch(() => {})
      this.listLoading = false
    },
    // 读取菜单列表
    async queryPage(options) {
      this.listLoading = true

      await queryPage({ ...options, ...this.forms })
        .then((res) => {
          if (res.data) {
            this.list = res.data.records
            this.list = res.data.records.map((v) => {
              this.$set(v, 'inputVisible', false)
              return v
            })
            this.total = res.data.total
          }
        })
        .catch(() => {})
      this.listLoading = false
    },

    // 编辑
    updateData() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          const tempData = {}
          tempData.id = this.rowId
          tempData.parentId = this.postForm.parentId
          tempData.menuName = this.postForm.menuName
          tempData.menuIcon = this.postForm.menuIcon
          tempData.menuPath = this.postForm.menuPath
          tempData.menuComponent = this.postForm.menuComponent
          tempData.menuSort = this.postForm.menuSort
          tempData.routesName = this.postForm.routesName
          tempData.alwaysShow = this.postForm.alwaysShow
          tempData.menuStatus = this.postForm.menuStatus
          tempData.breadcrumbHidden = this.postForm.breadcrumbHidden
          tempData.hidden = this.postForm.hidden
          tempData.dynamicNewTab = this.postForm.dynamicNewTab
          tempData.activeMenu = this.postForm.activeMenu

          menuUpdate(tempData)
            .then(() => {
              this.close()
              this.MS('编辑成功')
              this.queryPage()
            })
            .catch(() => {})
        }
      })
    },
    // 添加子级按钮
    handleAddson(row) {
      this.rowIdson = row.id
      this.rowPareIdson = row.parentId
      this.dialogFormVisibleSon = true
    },
    // 关闭
    closeSon() {
      this.$refs['postFormSon'].resetFields()
      this.postFormSon = this.$options.data().postFormSon
      this.dialogFormVisibleSon = false
    },
    // 添加子级保存
    createDataSon() {
      this.$refs['postFormSon'].validate((valid) => {
        if (valid) {
          const parms = {
            menuName: this.postFormSon.menuName,
            menuIcon: this.postFormSon.menuIcon,
            menuPath: this.postFormSon.menuPath,
            menuComponent: this.postFormSon.menuComponent,
            menuSort: this.postFormSon.menuSort,
            menuStatus: this.postFormSon.menuStatus,
            routesName: this.postFormSon.routesName,
            alwaysShow: this.postFormSon.alwaysShow,
            breadcrumbHidden: this.postFormSon.breadcrumbHidden,
            hidden: this.postFormSon.hidden,
            dynamicNewTab: this.postFormSon.dynamicNewTab,
            activeMenu: this.postFormSon.activeMenu,

            parentId: this.rowIdson,
          }
          menuAdd(parms).then(() => {
            this.MS('新增成功')
            this.closeSon()
            this.queryPage()
          })
        }
      })
    },
    // 新增
    createData() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          const parms = {
            menuName: this.postForm.menuName,
            menuIcon: this.postForm.menuIcon,
            menuPath: this.postForm.menuPath,
            menuComponent: this.postForm.menuComponent,
            menuSort: this.postForm.menuSort,
            menuStatus: this.postForm.menuStatus,
            routesName: this.postForm.routesName,
            alwaysShow: this.postForm.alwaysShow,
            breadcrumbHidden: this.postForm.breadcrumbHidden,
            hidden: this.postForm.hidden,
            dynamicNewTab: this.postForm.dynamicNewTab,
            activeMenu: this.postForm.activeMenu,
            parentId: 0,
          }
          menuAdd(parms).then(() => {
            this.MS('新增成功')
            this.close()
            this.queryPage()
          })
        }
      })
    },

    // 导出
    handleExport() {},
    // 新增/编辑
    handleEdit(row) {
      if (row) {
        this.menuCode = row.menuCode
        this.rowId = row.id
        this.showEdit(row)
      } else {
        this.showEdit()
      }
      // this.selectMenuButton()
    },
    // handleClearParentId() {
    //   this.parentName = ''
    //   this.postForm.parentId = ''
    // },
    // handleNodeClickParentId(data) {
    //   this.parentName = data.menuName
    //   this.postForm.parentId = data.id
    //   this.$refs.selectParentId.blur()
    //   this.validateParentId()
    // },
    // validateParentId() {
    //   this.$nextTick(() => {
    //     this.$refs['postForm'].clearValidate('parentId')
    //   })
    // },
    // 添加编辑title
    showEdit(row) {
      this.selectMenuTree()
      if (row) {
        this.title = '编辑'
        // this.$nextTick(() => {
        //   this.$refs.postForm.clearValidate()
        //   if (this.postForm.parentId === '0') {
        //     this.parentName = '请选择'
        //   } else {
        //     this.parentName = this.$refs.treeParentId.getNode(
        //       this.postForm.parentId
        //     ).label
        //   }
        // })
        this.postForm.parentId = row.parentId
        loadUpdate({ id: row.id })
          .then((res) => {
            console.log(res)
            this.postForm = res.data
          })
          .catch(() => {})
        //  this.postForm = Object.assign({}, row)
      } else {
        this.title = '新增'
      }
      this.dialogFormVisible = true
    },
    // 关闭
    close() {
      this.$refs['postForm'].resetFields()
      this.parentName = ''
      this.postForm = this.$options.data().postForm
      this.dialogFormVisible = false
    },

    // 上级
    handleNodeClick(node) {
      this.postForm.parentName = node.name
      this.postForm.parentId = node.id
    },

    // 删除
    handleDelete(row) {
      if (row) {
        if (row.children != null) {
          return this.ME('此数据下有子级不可删除')
        }
        if (row.id) {
          this.$confirm(`${row.children == null ? '是否要删除此行?' : '删除此数据将删除整个下级数据,是否继续?'}`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              menuDelete({ id: row.id })
                .then(() => {
                  this.MS('删除成功')
                  this.queryPage()
                })
                .catch(() => {})
            })
            .catch(() => {})
        }
      } else {
        if (this.selectRows.length > 0) {
          const ids = this.selectRows.map((item) => item.id).join()
          console.log(ids)
          this.$confirm('是否要删除此行?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {})
            .catch(() => {})
        } else {
          if (this.selectRows.length === 0) {
            this.ME('请勾选需要删除的记录')
            return
          }
        }
      }
    },
    // 点击收缩
    handleLeftIcon() {
      this.$refs.asideRef.style.width = '10px'
      this.$refs.asideRef.style.minWidth = '10px'
      this.$refs.contantRef.style.width = '98%'
      this.leftIcon = false
      this.rightIcon = true
    },
    // 点击展开
    handleRightIcon() {
      this.$refs.asideRef.style.width = '190px'
      this.$refs.asideRef.style.minWidth = '190px'
      this.$refs.contantRef.style.width = '87%'
      this.leftIcon = true
      this.rightIcon = false
    },
    // 列表选中
    setSelectRows(val) {
      this.selectRows = val
    },
    // 重置
    handleReset() {
      this.queryForm.parentId = ''
      this.forms.current = 1
      this.forms.size = 10
      this.queryForm = {}
      this.handleQuery()
    },
    // 搜索
    async handleQuery() {
      this.forms.current = 1
      await this.queryPage({ ...this.queryForm }, false)
      let temp = false
      Object.keys(this.queryForm).forEach((key) => {
        this.queryForm[key] && (temp = true)
      })
      this.isShow = temp
    },
    // 分页
    handleSizeChange(val) {
      this.forms.size = val
      let data = this.isShow === true ? this.queryForm : {}
      this.queryPage({ ...data }, false)
    },
    // 分页
    handleCurrentChange(val) {
      this.forms.current = val
      let data = this.isShow === true ? this.queryForm : {}
      this.queryPage({ ...data }, false)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep(.el-aside) {
  width: 180px !important;
}

.filter-input {
  width: 150px;
}
.dialogMenu {
  ::v-deep(.el-dialog__body) {
    padding: 0px 20px !important;
  }
  .el-input-number {
    width: 100%;
  }
  .el-form-item {
    width: 80%;
    margin: 17px auto;
  }
}
.asideAuthorizeUrl {
  width: 200px;
  height: 600px;
  overflow: scroll;
  border: 1px solid #c0d4f0;
  ::v-deep(.el-table) {
    th.is-leaf {
      background: #fff;
    }
  }
  .el-input {
    width: 140px;
    padding: 10px;
  }
  ::v-deep .aside-table td,
  .aside-table th.is-leaf {
    padding: 10px 0;
    cursor: pointer;
  }
  ::v-deep(.aside-table th) {
    .cell {
      padding: 5px 10px;
      font-weight: 700 !important;
      color: #909399 !important;
    }
  }
  .aside-table::before {
    width: 0;
  }
}
.clickLine {
  width: 8px;
  height: 600px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: #c0d4f0;
  .clickLineLeft {
    position: relative;
    top: 50%;
    left: -3px;
    color: #97a8be;
    cursor: pointer;
  }
  .clickLineRight {
    position: relative;
    top: 50%;
    left: -5px;
    color: #97a8be;
    cursor: pointer;
  }
}
</style>
