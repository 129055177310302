import request from '@/utils/request'
export function getPage(query) {
  return request({
    url: '/mall/goodsspu/page',
    method: 'get',
    params: query,
  })
}

export function putObjShelf(obj) {
  return request({
    url: '/mall/goodsspu/shelf',
    method: 'put',
    params: obj,
  })
}

export function putObjVerify(obj) {
  return request({
    url: '/mall/goodsspu/verify',
    method: 'put',
    params: obj,
  })
}

export function delObj(id) {
  return request({
    url: '/mall/goodsspu/' + id,
    method: 'delete',
  })
}

export function addObj(data) {
  return request({
    url: '/mall/goodsspu',
    method: 'post',
    data: data,
  })
}

export function choosTree(obj) {
  return request({
    url: '/mall/goodsspuspec/choosTree',
    method: 'get',
    params: obj,
  })
}
//商品详情查询
export function getGoodsObj(id) {
  return request({
    url: '/mall/goodsspu/' + id,
    method: 'get',
  })
}

//商品详情编辑查询
export function updateGoodsSelect(data) {
  return request({
    url: '/mall/goodsspu/updateGoods',
    method: 'post',
    data: data,
  })
}
