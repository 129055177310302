<template>
  <div class="comprehensive-table-container">
    <el-form ref="form"  inline  :model="formInline">
      <el-form-item label="用户I:">
        <el-input v-model.trim="formInline.id" class="title-input" placeholder="请输入用户ID" />
      </el-form-item>
      <el-form-item label="用户名称:">
        <el-input v-model.trim="formInline.nickname" class="title-input" placeholder="用户名称" />
      </el-form-item>
      <el-form-item label="创建时间:">
        <el-date-picker
          v-model="datePicker"
          align="center"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>
      <el-form-item label="">
        <el-button icon="el-icon-search" type="warning">搜索</el-button>
        <el-button icon="el-icon-refresh-left" type="primary">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button icon="el-icon-plus" style="margin-bottom: 15px;" type="primary" @click="addnew">新增</el-button>
    <el-table
      border
      :data="tableData"
      style="width: 100%">
      <el-table-column align="center" label="序号" type="index" width="50"/>
      <el-table-column align="center" label="用户信息" prop="name" width="300">
        <template #default="scope">
          <el-row align="middle" :gutter="20" style="display: flex; align-items: center;">
            <el-col :span="4"><div class="grid-content bg-purple"><el-image :src="scope.row.userInfo.headimgUrl"/></div></el-col>
            <el-col :offset="1" :span="19"><div class="grid-content bg-purple">
              <el-row  :gutter="20">
                <el-col class="el-tyx" :span="24">昵称：{{ scope.row.userInfo.nickName }}</el-col>
                <el-col class="el-tyx" :span="24">编号：{{ scope.row.userInfo.userCode }}</el-col>
                <el-col class="el-tyx" :span="24">电话：{{ scope.row.userInfo.phone }}</el-col>
              </el-row>
            </div></el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column align="center" label="累计佣金金额" prop="commissionTotal"/>
      <el-table-column align="center" label="已提现佣金金额" prop="commissionWithdrawal"/>
      <el-table-column align="center" label="创建时间" prop="createTime"/>
      <el-table-column align="center" label="操作">
       <template #default="scope">
        <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="showview(scope.row)">查看</el-link>
        <el-link style="margin: 0 5px;" type="primary" :underline="false" @click="editrow(scope.row)">编辑</el-link>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link" style="margin:  5px;line-height: 23px; color: #1890ff;">
            更多
          </span>
          <el-dropdown-menu slot="dropdown" style="text-align: center;">
            <el-dropdown-item>
              <el-link  type="primary" :underline="false" @click="delobj(scope.row.id)">删除</el-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-link  type="primary" :underline="false">
              <router-link :to="{name:'DistriBution',query:{id:scope.row.userId,unfold:'promoter'}}">推广人</router-link>
              </el-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{name:'DistriBution',query:{id:scope.row.userId,unfold:'Promotionorder'}}">推广订单</router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
       </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"/>
    <Newdistribution :rowhide="rowshow" @closeadd="clsoehide"/>
    <Distributioneditor :edirow="edirow" :showform="bole" size="small" @closeedit="closedit"/>
    <el-dialog
      title="查看"
      :visible.sync="dialogVisible"
      width="60%">
      <span>
        <el-descriptions border class="margin-top" :column="4" >
          <el-descriptions-item>
            <template slot="label">
              累计佣金金额:
            </template>
            {{ dialorow.commissionTotal }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              已提现佣金金额:
            </template>
            {{ dialorow.commissionWithdrawal }}
          </el-descriptions-item>
        </el-descriptions>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Newdistribution from './components/distribution.vue'
  import Distributioneditor from "./components/Distributioneditor.vue"
  import {getUserList,deldistributionuser} from "@/api/mall/distUsers/list"
  export default {
    name: 'UserList',
    components: {
      Newdistribution,Distributioneditor
    },
    data() {
      return {
        rowshow:false,
        formInline:{},
        datePicker:null,
        tableData:[],
        total:0,
        form:{
          size:10,
          current:1,
        },
        dialogVisible:false,
        dialorow:{},
        bole:false,
        edirow:{}
      }
    },
    created() {
      this.getUserList()
    },
    methods: {
      getUserList(){
        getUserList({...this.form}).then(res=>{
          console.log(res.data)
          this.tableData=res.data.records
          this.form.size=res.data.size
          this.form.current=res.data.current
          this.total=res.data.total
        })
      },
      addnew(){
        this.rowshow=true
      },
      clsoehide(e){
        this.getUserList()
        this.rowshow=e
      },
      handleSizeChange(e){
        this.form.size=e
        this.getUserList()
      },
      handleCurrentChange(e){
        this.form.current=e
        this.getUserList()
      },
      delobj(delId){
        deldistributionuser({delId}).then(()=>{
          this.getUserList()
          this.$message.success("删除成功")
        })
      },
      showview(row){
        this.dialorow=row
        this.dialogVisible=true
      },
      editrow(row){
        this.bole=true
        this.edirow=row
      },
      closedit(e){
        this.bole=e
        this.getUserList()
      },
    },
  }
</script>
<style scoped>
::v-deep .el-dialog__header{
  border-bottom: 1px solid #e5e5e5;
}

.el-tyx{
  text-align: left;
}
</style>