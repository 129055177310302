import request from '@/utils/request'

export function queryPage(query) {
  return request({
    url: '/mall/articleinfo/page',
    method: 'get',
    params: query,
  })
}
export function queryArticlecategory(query) {
  return request({
    url: '/mall/articlecategory/page',
    method: 'get',
    params: query,
  })
}
export function addObj(obj) {
  return request({
    url: '/mall/articleinfo',
    method: 'post',
    data: obj,
  })
}

export function getObj(id) {
  return request({
    url: '/mall/articleinfo/' + id,
    method: 'get',
  })
}

export function editObj(data) {
  return request({
    url: '/mall/articleinfo',
    method: 'put',
    data: data,
  })
}
export function delObj(id) {
  return request({
    url: '/mall/articleinfo/' + id.id,
    method: 'delete',
  })
}

export function putObj(obj) {
  return request({
    url: '/mall/articleinfo',
    method: 'put',
    data: obj,
  })
}

// 状态
export function disableColumn(data) {
  return request({
    url: `/bbs/bbsArticleColumn/disable`,
    method: 'post',
    data,
  })
}

// 配置类型（所有类型）
export function addColumnType(data) {
  return request({
    url: `/user/bbsArticleColumnType/add`,
    method: 'post',
    data,
  })
}
