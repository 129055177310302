var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "textTool" }, [
    _c("div", { staticClass: "toolTit" }, [
      _c("div", { staticClass: "lef" }, [_vm._v("会员信息")]),
      _c("div", { staticClass: "ref" }, [
        _c("div", { staticClass: "switch" }, [
          _c(
            "div",
            {
              class: _vm.checknum == 1 ? "swit-item active" : "swit-item",
              on: {
                click: function ($event) {
                  return _vm.changeChecknum(1)
                },
              },
            },
            [_vm._v("内容")]
          ),
          _c(
            "div",
            {
              class: _vm.checknum == 2 ? "swit-item active" : "swit-item",
              on: {
                click: function ($event) {
                  return _vm.changeChecknum(2)
                },
              },
            },
            [_vm._v("样式")]
          ),
        ]),
      ]),
    ]),
    _vm.checknum == 1
      ? _c("div", { staticClass: "content_msg" }, [
          _c("div", { staticClass: "ym_line" }),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("风格")]),
            _c(
              "div",
              { staticClass: "check-tit" },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].style,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "style",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].style",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "1" } }, [
                      _vm._v("样式1"),
                    ]),
                    _c("el-radio", { attrs: { label: "2" } }, [
                      _vm._v("样式2"),
                    ]),
                    _c("el-radio", { attrs: { label: "3" } }, [
                      _vm._v("样式3"),
                    ]),
                    _c("el-radio", { attrs: { label: "4" } }, [
                      _vm._v("样式4"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("背景")]),
            _c(
              "div",
              { staticClass: "check-tit" },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].theme,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "theme",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].theme",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "diy" } }, [
                      _vm._v("自定义"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.activeComponents[_vm.indexnumer].theme == "diy"
            ? _c("div", { staticClass: "itemBox" }, [
                _c("div", { staticClass: "Tit" }, [_vm._v("背景颜色")]),
                _c(
                  "div",
                  {
                    staticClass: "check-tit",
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c("div", { staticClass: "lef" }, [
                      _vm._v(
                        _vm._s(
                          _vm.activeComponents[_vm.indexnumer].bgColorStart
                        ) +
                          _vm._s(
                            _vm.activeComponents[_vm.indexnumer].bgColorEnd
                          )
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "ref",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          color: "#7aa4ff",
                        },
                      },
                      [
                        _c("el-color-picker", {
                          model: {
                            value:
                              _vm.activeComponents[_vm.indexnumer].bgColorStart,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.activeComponents[_vm.indexnumer],
                                "bgColorStart",
                                $$v
                              )
                            },
                            expression:
                              "activeComponents[indexnumer].bgColorStart",
                          },
                        }),
                        _c("el-color-picker", {
                          model: {
                            value:
                              _vm.activeComponents[_vm.indexnumer].bgColorEnd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.activeComponents[_vm.indexnumer],
                                "bgColorEnd",
                                $$v
                              )
                            },
                            expression:
                              "activeComponents[indexnumer].bgColorEnd",
                          },
                        }),
                        _c("div", { staticClass: "resect" }, [_vm._v("重置")]),
                        _c("i", {
                          staticClass: "iconfont icon-arrow-right",
                          staticStyle: { "font-size": "12px", color: "gray" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.activeComponents[_vm.indexnumer].theme == "diy"
            ? _c("div", { staticClass: "itemBox" }, [
                _c("div", { staticClass: "Tit" }, [_vm._v("渐变角度")]),
                _c(
                  "div",
                  { staticClass: "check-tit" },
                  [
                    _c("el-slider", {
                      staticStyle: { width: "100%", "margin-right": "10px" },
                      attrs: { maxlength: "360", "show-tooltip": false },
                      model: {
                        value:
                          _vm.activeComponents[_vm.indexnumer].gradientAngle,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.activeComponents[_vm.indexnumer],
                            "gradientAngle",
                            $$v
                          )
                        },
                        expression:
                          "activeComponents[indexnumer].gradientAngle",
                      },
                    }),
                    _c("el-input-number", {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { max: 360, min: 0 },
                      model: {
                        value:
                          _vm.activeComponents[_vm.indexnumer].gradientAngle,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.activeComponents[_vm.indexnumer],
                            "gradientAngle",
                            $$v
                          )
                        },
                        expression:
                          "activeComponents[indexnumer].gradientAngle",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "ym_title" }, [_vm._v("数据设置")]),
          _c(
            "div",
            { staticClass: "alert-msg", staticStyle: { "margin-top": "20px" } },
            [_vm._v("数据显示字段（只能3个）")]
          ),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("步数")]),
            _c(
              "div",
              { staticClass: "check-tit" },
              [
                _c(
                  "el-checkbox-group",
                  {
                    on: { change: _vm.showlist },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].isBtnshow[0]
                          .isShow,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].isBtnshow[0],
                          "isShow",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].isBtnshow[0].isShow",
                    },
                  },
                  [
                    _c("el-checkbox", [
                      _vm._v(
                        _vm._s(
                          _vm.activeComponents[_vm.indexnumer].isBtnshow[0]
                            .isShow
                            ? "显示"
                            : "隐藏"
                        )
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("余额")]),
            _c(
              "div",
              { staticClass: "check-tit" },
              [
                _c(
                  "el-checkbox-group",
                  {
                    on: { change: _vm.showlist },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].isBtnshow[1]
                          .isShow,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].isBtnshow[1],
                          "isShow",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].isBtnshow[1].isShow",
                    },
                  },
                  [
                    _c("el-checkbox", [
                      _vm._v(
                        _vm._s(
                          _vm.activeComponents[_vm.indexnumer].isBtnshow[1]
                            .isShow
                            ? "显示"
                            : "隐藏"
                        )
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("积分")]),
            _c(
              "div",
              { staticClass: "check-tit" },
              [
                _c(
                  "el-checkbox-group",
                  {
                    on: { change: _vm.showlist },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].isBtnshow[2]
                          .isShow,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].isBtnshow[2],
                          "isShow",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].isBtnshow[2].isShow",
                    },
                  },
                  [
                    _c("el-checkbox", [
                      _vm._v(
                        _vm._s(
                          _vm.activeComponents[_vm.indexnumer].isBtnshow[2]
                            .isShow
                            ? "显示"
                            : "隐藏"
                        )
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("优惠券")]),
            _c(
              "div",
              { staticClass: "check-tit" },
              [
                _c(
                  "el-checkbox-group",
                  {
                    on: { change: _vm.showlist },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].isBtnshow[3]
                          .isShow,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].isBtnshow[3],
                          "isShow",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].isBtnshow[3].isShow",
                    },
                  },
                  [
                    _c("el-checkbox", [
                      _vm._v(
                        _vm._s(
                          _vm.activeComponents[_vm.indexnumer].isBtnshow[3]
                            .isShow
                            ? "显示"
                            : "隐藏"
                        )
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm.checknum == 2
      ? _c("div", { staticClass: "content_msg" }, [
          _c("div", { staticClass: "ym_line" }),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("间距")]),
            _c(
              "div",
              { staticClass: "check-tit" },
              [
                _c("el-slider", {
                  staticStyle: { width: "100%", "margin-right": "10px" },
                  attrs: { maxlength: "20", "show-tooltip": false },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].infoMargin,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer],
                        "infoMargin",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].infoMargin",
                  },
                }),
                _c("el-input-number", {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { max: 20, min: 0 },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].infoMargin,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer],
                        "infoMargin",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].infoMargin",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("底部背景")]),
            _c(
              "div",
              {
                staticClass: "check-tit",
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "space-between",
                },
              },
              [
                _c("div", { staticClass: "lef" }, [
                  _vm._v(
                    _vm._s(_vm.activeComponents[_vm.indexnumer].pageBgColor)
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "ref",
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      color: "#7aa4ff",
                    },
                  },
                  [
                    _c("el-color-picker", {
                      model: {
                        value: _vm.activeComponents[_vm.indexnumer].pageBgColor,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.activeComponents[_vm.indexnumer],
                            "pageBgColor",
                            $$v
                          )
                        },
                        expression: "activeComponents[indexnumer].pageBgColor",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "resect",
                        on: {
                          click: function ($event) {
                            _vm.activeComponents[_vm.indexnumer].pageBgColor =
                              ""
                          },
                        },
                      },
                      [_vm._v("重置")]
                    ),
                    _c("i", {
                      staticClass: "iconfont icon-arrow-right",
                      staticStyle: { "font-size": "12px", color: "gray" },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "alert-msg" }, [
            _vm._v("底部背景包含边距和圆角"),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("组件背景")]),
            _c(
              "div",
              {
                staticClass: "check-tit",
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "space-between",
                },
              },
              [
                _c("div", { staticClass: "lef" }, [
                  _vm._v(
                    _vm._s(
                      _vm.activeComponents[_vm.indexnumer].componentBgColor
                    )
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "ref",
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      color: "#7aa4ff",
                    },
                  },
                  [
                    _c("el-color-picker", {
                      model: {
                        value:
                          _vm.activeComponents[_vm.indexnumer].componentBgColor,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.activeComponents[_vm.indexnumer],
                            "componentBgColor",
                            $$v
                          )
                        },
                        expression:
                          "activeComponents[indexnumer].componentBgColor",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "resect",
                        on: {
                          click: function ($event) {
                            _vm.activeComponents[
                              _vm.indexnumer
                            ].componentBgColor = ""
                          },
                        },
                      },
                      [_vm._v("重置")]
                    ),
                    _c("i", {
                      staticClass: "iconfont icon-arrow-right",
                      staticStyle: { "font-size": "12px", color: "gray" },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("上边距")]),
            _c(
              "div",
              {
                staticClass: "check-tit",
                staticStyle: { display: "flex", width: "260px" },
              },
              [
                _c("el-slider", {
                  staticStyle: { width: "150px" },
                  attrs: { disabled: "", max: 100, size: "mini" },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].margin.top,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].margin,
                        "top",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].margin.top",
                  },
                }),
                _c("el-input-number", {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { max: 100, min: 0 },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].margin.top,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].margin,
                        "top",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].margin.top",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("下边距")]),
            _c(
              "div",
              {
                staticClass: "check-tit",
                staticStyle: { display: "flex", width: "260px" },
              },
              [
                _c("el-slider", {
                  staticStyle: { width: "150px" },
                  attrs: { max: 100, size: "mini" },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].margin,
                        "bottom",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].margin.bottom",
                  },
                }),
                _c("el-input-number", {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { max: 100, min: 0 },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].margin,
                        "bottom",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].margin.bottom",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "itemBox" }, [
            _c("div", { staticClass: "Tit" }, [_vm._v("左右边距")]),
            _c(
              "div",
              {
                staticClass: "check-tit",
                staticStyle: { display: "flex", width: "260px" },
              },
              [
                _c("el-slider", {
                  staticStyle: { width: "150px" },
                  attrs: { max: 20, size: "mini" },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].margin.both,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].margin,
                        "both",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].margin.both",
                  },
                }),
                _c("el-input-number", {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { max: 20, min: 0 },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].margin.both,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].margin,
                        "both",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].margin.both",
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }