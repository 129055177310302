<template>
  <div v-if="isShowToast" class="toast">
    <p>您一共有<span class="num">{{ num }}</span>条未处理的信息</p>
    <button @click="closeToast">×</button>
  </div>
</template>

<script>
import { getList } from '@/api/websoket'
export default {
  data() {
    return {
      isShowToast: false,
      num: 0,
      toastTimer: null, // 用于存储setTimeout返回的ID，以便在需要时清除
    };
  },
  mounted() {
    this.showToast(); // 初始显示弹窗
  },
  beforeDestroy() {
    // 清除计时器
    if (this.toastTimer) {
      clearTimeout(this.toastTimer);
      this.toastTimer = null;
    }
  },
  methods: {
    showToast() {
      // 判断是否登录了账号 登录后显示
      if (localStorage.getItem('admin-pro-token') && localStorage.getItem('admin-pro-token') != '') {
        getList({ status: 0 }).then((res) => {
          if (res.data && res.data.length != 0)
            this.num = res.data.length
        })
        if (this.num != 0) {
          this.isShowToast = true;
        }
      }
      // 清除之前的计时器（如果有的话）
      if (this.toastTimer) {
        clearTimeout(this.toastTimer);
      }
      // 设置新的计时器，以便在10分钟后再次显示弹窗
      this.toastTimer = setTimeout(() => {
        this.showToast();
      }, 10 * 60 * 1000);
    },
    closeToast() {
      this.isShowToast = false;
      // 清除当前的计时器（如果有的话），防止在关闭时立即重新显示
      if (this.toastTimer) {
        clearTimeout(this.toastTimer);
      }
      // 设置新的计时器，以便在10分钟后再次显示弹窗
      this.toastTimer = setTimeout(() => {
        this.showToast();
      }, 10 * 60 * 1000); // 10分钟提醒一次
    },
  },
};
</script>

<style scoped>
.toast {
  position: fixed;
  /* 从底部开始 */
  right: 20px;
  bottom: 20px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  /* 垂直居中 */
  align-items: flex-start;
  justify-content: center;
  width: 300px;
  height: 100px;
  /* 设置高度 */
  padding: 10px;
  background-color: #fff;
  border: 1px #ccc solid;
  border-radius: 5px;
  /* 水平靠左对齐 */
}

.num {
  margin: 0 10px;
  font-family: '微软雅黑';
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
  color: red;
  text-decoration: underline;
  text-shadow: 1px 1px 1px #ccc;
  cursor: pointer;
}

.toast button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
  background-color: #ccc;
  border: none;
  border-radius: 50px;
}
</style>
