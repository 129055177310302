var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "textTool" },
    [
      _c("div", { staticClass: "toolTit" }, [
        _c("div", { staticClass: "lef" }, [_vm._v("标题")]),
        _c("div", { staticClass: "ref" }, [
          _c("div", { staticClass: "switch" }, [
            _c(
              "div",
              {
                class: _vm.checknum == 1 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(1)
                  },
                },
              },
              [_vm._v("内容")]
            ),
            _c(
              "div",
              {
                class: _vm.checknum == 2 ? "swit-item active" : "swit-item",
                on: {
                  click: function ($event) {
                    return _vm.changeChecknum(2)
                  },
                },
              },
              [_vm._v("样式")]
            ),
          ]),
        ]),
      ]),
      _vm.checknum == 1
        ? _c("div", { staticClass: "content_msg" }, [
            _c("div", { staticClass: "ym_line" }),
            _c("div", {
              staticClass: "ym_line",
              staticStyle: { padding: "2px 0" },
            }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("风格设置")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("风格选择")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    color: "#3371f7",
                  },
                  on: {
                    click: function ($event) {
                      _vm.hide = true
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.activeComponents[_vm.indexnumer].styleName) +
                      " "
                  ),
                  _c("i", {
                    staticClass: "el-icon-arrow-right",
                    staticStyle: { "font-size": "14px", color: "gray" },
                  }),
                ]
              ),
            ]),
            _c("div", {
              staticClass: "ym_line",
              staticStyle: { padding: "2px 0" },
            }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("标题内容")]),
            _c(
              "div",
              { staticClass: "itemBox" },
              [
                _c("div", { staticClass: "Tit" }, [_vm._v("标题名称")]),
                _c("el-input", {
                  staticClass: "item-input",
                  attrs: { maxlength: "20", placeholder: "请输入内容" },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].text,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer],
                        "text",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].text",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("链接地址")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    color: "#3371f7",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.checklink(1)
                    },
                  },
                },
                [
                  _vm.activeComponents[_vm.indexnumer].link.name != ""
                    ? _c("span", { staticStyle: { color: "#1780ff" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.activeComponents[_vm.indexnumer].link.title
                          )
                        ),
                      ])
                    : _c("span", { staticStyle: { color: "#1780ff" } }, [
                        _vm._v("请选择链接"),
                      ]),
                  _c("i", {
                    staticClass: "el-icon-arrow-right",
                    staticStyle: { "font-size": "14px", color: "gray" },
                  }),
                ]
              ),
            ]),
            _c("div", {
              staticClass: "ym_line",
              staticStyle: { padding: "2px 0" },
            }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("副标题内容")]),
            _c(
              "div",
              { staticClass: "itemBox" },
              [
                _c(
                  "div",
                  {
                    staticClass: "Tit",
                    staticStyle: { "margin-left": "-15px" },
                  },
                  [_vm._v("副标题名称")]
                ),
                _c("el-input", {
                  staticClass: "item-input",
                  attrs: { maxlength: "20", placeholder: "请输入内容" },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].subTitle.text,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].subTitle,
                        "text",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].subTitle.text",
                  },
                }),
              ],
              1
            ),
            _c("div", {
              staticClass: "ym_line",
              staticStyle: { padding: "2px 0" },
            }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("“更多”按钮内容")]),
            _c(
              "div",
              { staticClass: "itemBox" },
              [
                _c("div", { staticClass: "Tit" }, [_vm._v("按钮文字")]),
                _c("el-input", {
                  staticClass: "item-input",
                  attrs: { maxlength: "20", placeholder: "请输入内容" },
                  model: {
                    value: _vm.activeComponents[_vm.indexnumer].more.text,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.activeComponents[_vm.indexnumer].more,
                        "text",
                        $$v
                      )
                    },
                    expression: "activeComponents[indexnumer].more.text",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("链接地址")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    color: "#3371f7",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.checklink(2)
                    },
                  },
                },
                [
                  _vm.activeComponents[_vm.indexnumer].more.link.name != ""
                    ? _c("span", { staticStyle: { color: "#1780ff" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.activeComponents[_vm.indexnumer].more.link.title
                          )
                        ),
                      ])
                    : _c("span", { staticStyle: { color: "#1780ff" } }, [
                        _vm._v("请选择链接"),
                      ]),
                  _c("i", {
                    staticClass: "el-icon-arrow-right",
                    staticStyle: { "font-size": "14px", color: "gray" },
                  }),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.checknum == 2
        ? _c("div", { staticClass: "content_msg" }, [
            _c("div", { staticClass: "ym_line" }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("标题样式")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("文字大小")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 20, min: 12, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].fontSize,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "fontSize",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].fontSize",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 20, min: 12 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].fontSize,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "fontSize",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].fontSize",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("文字粗细")]),
              _c(
                "div",
                { staticClass: "check-tit" },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.activeComponents[_vm.indexnumer].fontWeight,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.activeComponents[_vm.indexnumer],
                            "fontWeight",
                            $$v
                          )
                        },
                        expression: "activeComponents[indexnumer].fontWeight",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "bold" } }, [
                        _vm._v("加粗"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "normal" } }, [
                        _vm._v("常规"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("文字颜色")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(_vm.activeComponents[_vm.indexnumer].textColor)
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.activeComponents[_vm.indexnumer].textColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "textColor",
                              $$v
                            )
                          },
                          expression: "activeComponents[indexnumer].textColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[_vm.indexnumer].textColor =
                                "#000000"
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", {
              staticClass: "ym_line",
              staticStyle: { padding: "2px 0" },
            }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("副标题样式")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("文字大小")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 16, min: 12, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].subTitle.fontSize,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].subTitle,
                          "fontSize",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].subTitle.fontSize",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 16, min: 12 },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer].subTitle.fontSize,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].subTitle,
                          "fontSize",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].subTitle.fontSize",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("文字颜色")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(
                        _vm.activeComponents[_vm.indexnumer].subTitle.color
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer].subTitle.color,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer].subTitle,
                              "color",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].subTitle.color",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[
                                _vm.indexnumer
                              ].subTitle.color = "#999999"
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", {
              staticClass: "ym_line",
              staticStyle: { padding: "2px 0" },
            }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("“更多”按钮样式")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("是否显示")]),
              _c(
                "div",
                { staticClass: "check-tit" },
                [
                  _vm.activeComponents[_vm.indexnumer].more.isShow
                    ? _c(
                        "el-checkbox",
                        {
                          model: {
                            value:
                              _vm.activeComponents[_vm.indexnumer].more.isShow,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.activeComponents[_vm.indexnumer].more,
                                "isShow",
                                $$v
                              )
                            },
                            expression:
                              "activeComponents[indexnumer].more.isShow",
                          },
                        },
                        [_vm._v("显示")]
                      )
                    : _c(
                        "el-checkbox",
                        {
                          model: {
                            value:
                              _vm.activeComponents[_vm.indexnumer].more.isShow,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.activeComponents[_vm.indexnumer].more,
                                "isShow",
                                $$v
                              )
                            },
                            expression:
                              "activeComponents[indexnumer].more.isShow",
                          },
                        },
                        [_vm._v("隐藏")]
                      ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("文字颜色")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(_vm.activeComponents[_vm.indexnumer].more.color)
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer].more.color,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer].more,
                              "color",
                              $$v
                            )
                          },
                          expression: "activeComponents[indexnumer].more.color",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[_vm.indexnumer].more.color =
                                "#999999"
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", {
              staticClass: "ym_line",
              staticStyle: { padding: "2px 0" },
            }),
            _c("div", { staticClass: "ym_title" }, [_vm._v("组件样式")]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("底部背景")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(_vm.activeComponents[_vm.indexnumer].pageBgColor)
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer].pageBgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "pageBgColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].pageBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[_vm.indexnumer].pageBgColor =
                                "none"
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("组件背景")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "lef" }, [
                    _vm._v(
                      _vm._s(
                        _vm.activeComponents[_vm.indexnumer].componentBgColor
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ref",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        color: "#7aa4ff",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        model: {
                          value:
                            _vm.activeComponents[_vm.indexnumer]
                              .componentBgColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.activeComponents[_vm.indexnumer],
                              "componentBgColor",
                              $$v
                            )
                          },
                          expression:
                            "activeComponents[indexnumer].componentBgColor",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "resect",
                          on: {
                            click: function ($event) {
                              _vm.activeComponents[
                                _vm.indexnumer
                              ].componentBgColor = ""
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _c("i", {
                        staticClass: "iconfont icon-arrow-right",
                        staticStyle: { "font-size": "12px", color: "gray" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("上边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 100, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.top,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "top",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.top",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 100, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.top,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "top",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.top",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("下边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 100, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "bottom",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.bottom",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 100, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.bottom,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "bottom",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.bottom",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("左右边距")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 20, size: "mini" },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.both",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 20, min: 0 },
                    model: {
                      value: _vm.activeComponents[_vm.indexnumer].margin.both,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer].margin,
                          "both",
                          $$v
                        )
                      },
                      expression: "activeComponents[indexnumer].margin.both",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("上圆角")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer]
                          .topElementAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "topElementAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].topElementAroundRadius",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer]
                          .topElementAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "topElementAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].topElementAroundRadius",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "itemBox" }, [
              _c("div", { staticClass: "Tit" }, [_vm._v("下圆角")]),
              _c(
                "div",
                {
                  staticClass: "check-tit",
                  staticStyle: { display: "flex", width: "260px" },
                },
                [
                  _c("el-slider", {
                    staticStyle: { width: "150px" },
                    attrs: { max: 50, size: "mini" },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer]
                          .bottomElementAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "bottomElementAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].bottomElementAroundRadius",
                    },
                  }),
                  _c("el-input-number", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { max: 50, min: 0 },
                    model: {
                      value:
                        _vm.activeComponents[_vm.indexnumer]
                          .bottomElementAroundRadius,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeComponents[_vm.indexnumer],
                          "bottomElementAroundRadius",
                          $$v
                        )
                      },
                      expression:
                        "activeComponents[indexnumer].bottomElementAroundRadius",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("linkdialog", {
        attrs: { linkshow: _vm.linkurl },
        on: { closeurl: _vm.closelink },
      }),
      _c("checkstyle", {
        attrs: {
          chtostyle: _vm.activeComponents[_vm.indexnumer].style,
          showstyle: _vm.hide,
        },
        on: { closeinfo: _vm.closestyle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }