<template>
  <div class="cy-container">
    <!-- <el-container style="width: 100%"> -->
    <!-- aside侧边菜单（机构列表） -->
    <!-- <div
        ref="asideRef"
        class="asideAuthorizeUrl"
        style="
          width: 200px;
          height: 700px;
          margin-right: 5px;
          overflow: scroll;
          border: 1px solid #c0d4f0;
        "
      >
        <el-aside>
          <el-table
            v-loading="listLoading"
            class="aside-table"
            :data="menuListTable"
            :highlight-current-row="true"
            row-key="id"
            style="width: 100%; margin-bottom: 20px"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @row-click="clickAsideData"
          >
            <el-table-column label="机构名称" prop="orgName" />
          </el-table>
        </el-aside>
      </div> -->
    <!-- 点击收缩 -->
    <!-- <div class="clickLine">
        <i
          v-if="leftIcon"
          class="el-icon-caret-left"
          style="
            position: relative;
            top: 50%;
            left: -3px;
            color: #97a8be;
            cursor: pointer;
          "
          @click="handleLeftIcon"
        />
        <i
          v-if="rightIcon"
          class="el-icon-caret-right"
          style="
            position: relative;
            top: 50%;
            left: -5px;
            color: #97a8be;
            cursor: pointer;
          "
          @click="handleRightIcon"
        />
      </div> -->
    <!-- 查询 -->
    <div ref="contantRef">
      <div class="mb10">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item label="岗位编码">
            <el-input v-model.trim="queryForm.postCode" class="query-item query-input" clearable placeholder="岗位编码" />
          </el-form-item>
          <el-form-item label="岗位名称">
            <el-input v-model.trim="queryForm.postName" class="query-item query-input" clearable placeholder="岗位名称" />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="handleQuery">查询</el-button>
            <el-button icon="el-icon-refresh-right" type="warning" @click="handleReset">重置</el-button>
          </el-form-item>
          <div class="ref">
            <el-form-item>
              <el-button
                v-for="(item,ind) in searchButton"
                :key="item.id"
                :plain="item.cssType && item.cssType == 'plain' ? true : false"
                :type="ind==0?item.buttonCss:''"
                @click.native="handleClickBtn(item)"
              >
                <span v-if="!item.buttonIcon"></span>
                <vab-icon v-else :icon="item.buttonIcon" />
                {{ item.buttonName }}
              </el-button>
              <!-- <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
                新增
              </el-button>
              <el-button type="primary" @click="handleExport">
                <vab-icon icon="file-copy-2-fill" />
                导出
              </el-button>
              <el-button
                icon="el-icon-delete"
                plain
                type="danger"
                @click="handleDelete"
              >
                删除
              </el-button> -->
            </el-form-item>
          </div>
        </el-form>
        <!-- table列表-->
        <el-table v-loading="listLoading" border :data="list" max-height="600" row-key="id" :tree-props="{ children: 'children' }" @selection-change="setSelectRows">
          <el-table-column align="center" show-overflow-tooltip type="selection" />
          <el-table-column label="岗位编码" prop="postCode" show-overflow-tooltip />
          <el-table-column align="center" label="岗位名称" prop="postName" show-overflow-tooltip />

          <el-table-column align="center" label="岗位排序" prop="postSequence" show-overflow-tooltip />
          <el-table-column align="center" label="创建时间" prop="createTime" show-overflow-tooltip sortable>
            <template #default="{ row }">
              <div>
                {{ row.createTime | formatDate }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
        align="center"
        label="是否删除"
        prop="isDelete"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <div>{{ row.isDelete == '1' ? '是' : '否' }}</div>
        </template>
      </el-table-column> -->
          <el-table-column v-if="operationShow" align="center" label="操作" width="180">
            <template #default="{ row }">
              <!-- <el-button
                  icon="el-icon-edit-outline"
                  style="margin-right: 10px"
                  type="text"
                  @click="handleEdit(row)"
                >
                  编辑
                </el-button>
                <el-button
                  icon="el-icon-plus"
                  size="small"
                  style="margin-right: 10px"
                  type="text"
                  @click="handleAddson(row)"
                >
                  添加
                </el-button>
                <el-button
                  icon="el-icon-delete"
                  size="small"
                  type="text"
                  @click="handleDelete(row)"
                >
                  删除
                </el-button> -->
              <el-button
                v-for="item in listButton"
                :key="item.id"
                :plain="item.cssType && item.cssType == 'plain' ? true : false"
                size="default"
                :type="item.buttonCss"
                @click.native="handleClickBtn(item, row)"
              >
                <!-- <span v-if="!item.buttonIcon"></span>
                <vab-icon v-else :icon="item.buttonIcon" /> -->
                {{ item.buttonName }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          :current-page="forms.current"
          :layout="layout"
          :page-size="forms.size"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
    <!-- </el-container> -->

    <!-- 编辑、添加弹框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogFormVisible" @close="close">
      <el-form ref="postForm" label-width="80px" :model="postForm" :rules="rules">
        <!-- <el-form-item label="岗位编码" prop="postCode">
          <el-input v-model.trim="postForm.postCode" />
        </el-form-item> -->
        <el-form-item label="岗位名称" prop="postName">
          <el-input v-model.trim="postForm.postName" />
        </el-form-item>
        <el-form-item class="postPare" label="所属机构" prop="orgId">
          <el-cascader ref="cascaderMallCatergory" v-model="postForm.orgId" clearable :options="dataSourceLists" :props="props" style="width: 100%" />
        </el-form-item>
        <el-form-item label="岗位排序" prop="postSequence">
          <el-input-number v-model.trim="postForm.postSequence" :min="1" placeholder="请输入" size="small" style="width: 100%" />
        </el-form-item>
        <!-- <el-form-item class="postPare" label="上级岗位" prop="postParent">
          <el-tooltip
            content="如不选择默认根节点"
            effect="light"
            placement="bottom"
          >
            <el-select
              ref="selectParentId"
              v-model="postForm.parentId"
              clearable
              placeholder="请选择"
              @clear="handleClearParentId"
            >
              <el-option
                :key="postForm.parentId"
                hidden
                :label="parentName"
                style="height: auto; padding: 0"
                :value="postForm.parentId"
              />
              <el-tree
                ref="treeParentId"
                :check-on-click-node="true"
                class="aside-table"
                :data="orgsListTable"
                default-expand-all
                :expand-on-click-node="false"
                node-key="id"
                :props="{ label: 'postName' }"
                style="width: 100%; margin-bottom: 20px"
                :tree-props="{
                  children: 'children',
                  hasChildren: 'hasChildren',
                }"
                @node-click="handleNodeClickParentId"
              />
            </el-select>
          </el-tooltip>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="title === '新增' ? createData() : updateData()">确 定</el-button>
      </template>
    </el-dialog>
    <!-- 添加子级弹框 -->
    <el-dialog :close-on-click-modal="false" title="添加子级" :visible.sync="dialogFormVisibleSon" @close="closeSon">
      <el-form ref="postFormSon" label-width="80px" :model="postFormSon" :rules="rulesSon">
        <el-form-item label="岗位名称" prop="postName">
          <el-input v-model.trim="postFormSon.postName" />
        </el-form-item>
        <!-- <el-form-item label="岗位编码" prop="postCode">
          <el-input v-model.trim="postFormSon.postCode" />
        </el-form-item> -->

        <el-form-item label="岗位排序" prop="postSequence">
          <el-input-number v-model.trim="postFormSon.postSequence" :min="1" placeholder="请输入" size="small" style="width: 100%" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="closeSon">取 消</el-button>
        <el-button type="primary" @click="createDataSon()">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { queryPostPage, queryPostUpdate, queryPostAdd, selectOrgPost,queryPostDelete } from '@/api/mall/role/postManage'
import { orgTree } from '@/api/mall/role/orgManage'
import { selectRoleMenuButtonList } from '@/api/mall/common'
export default {
  data() {
    return {
      operationShow: false,
      searchButton: [],
      listButton: [],
      dataSourceLists: [],
      props: {
        checkStrictly: true,
        label: 'orgName',
        value: 'id',
      },
      menuListTable: [],
      leftIcon: true,
      rightIcon: false,
      rowIdson: '',
      rowPareIdson: '',
      dialogFormVisibleSon: false,
      postFormSon: {},
      rowId: '',
      orgsListTable: [],
      parentName: '',
      list: [],
      listLoading: false,
      current: 1,
      size: 10,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      selectRows: '',
      forms: { current: 1, size: 10 },
      isShow: true,
      queryForm: {},
      title: '',
      dialogFormVisible: false,
      postForm: {
        parentName: '',
        parentId: '',
        postCode: '',
        postName: '',
        orgId: '',
      },
      rules: {
        orgId: [
          {
            required: true,
            message: '请选择所属机构',
            trigger: ['blur', 'change'],
          },
        ],

        postSequence: [
          {
            type: 'number',
            message: '岗位排序必须为数字值',
            trigger: ['blur', 'change'],
          },
        ],
        postCode: [
          {
            required: true,
            message: '请输入岗位编码！',
            trigger: ['blur', 'change'],
          },
        ],
        postName: [
          {
            required: true,
            message: '请输入岗位名称！',
            trigger: ['blur', 'change'],
          },
        ],
      },
      rulesSon: {
        postSequence: [
          {
            type: 'number',
            message: '岗位排序必须为数字值',
            trigger: ['blur', 'change'],
          },
        ],
        postCode: [
          {
            required: true,
            message: '请输入岗位编码！',
            trigger: ['blur', 'change'],
          },
        ],
        postName: [
          {
            required: true,
            message: '请输入岗位名称！',
            trigger: ['blur', 'change'],
          },
        ],
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      editParentId: '',
      orgIds: '',
    }
  },
  created() {
    this.fetchData()
    this.orgfetchData()
    this.selectRoleMenuButtonList()
  },
  methods: {
    // 按钮点击
    handleClickBtn(item, row) {
      if (item.buttonUrl) {
        this[item.buttonUrl](row)
      }
    },
    // 按钮权限
    selectRoleMenuButtonList() {
      selectRoleMenuButtonList({
        roleIdStrList: 1, // this.$store.getters['acl/roleList'],
        menuId: this.$route.meta.id,
      })
        .then((res) => {
          if (res.data) {
            this.searchButton = res.data.filter(function (item) {
              return item.buttonType == 1
            })
            this.listButton = res.data.filter(function (item) {
              return item.buttonType == 2
            })
            if (this.listButton.length > 0) {
              this.operationShow = true
            } else {
              this.operationShow = false
            }
          }
        })
        .catch(() => {})
    },
    // 机构列表
    async orgfetchData() {
      this.listLoading = true
      const parms = {
        current: this.current,
        size: this.size,
      }
      await orgTree(parms)
        .then((res) => {
          if (res.data) {
            this.menuListTable = res.data.records
            this.dataSourceLists = res.data.records
          }
        })
        .catch(() => {})
      this.listLoading = false
    },

    // 点击机构列表
    clickAsideData(row, event, column) {
      console.log(row, event, column)
      // this.forms.current = 1
      // console.log(row.id)
      // this.listQuerys.orgId = row.id
      // this.listQuerys.orgName = row.orgName
      selectOrgPost({ orgId: row.id })
        .then((res) => {
          if (res.data) {
            this.list = res.data
            // this.total = res.data.total
          }
        })
        .catch(() => {})
    },
    // 点击收缩
    handleLeftIcon() {
      this.$refs.asideRef.style.width = '10px'
      this.$refs.asideRef.style.minWidth = '10px'
      this.$refs.contantRef.style.width = '98%'
      this.leftIcon = false
      this.rightIcon = true
    },
    // 点击展开
    handleRightIcon() {
      this.$refs.asideRef.style.width = '200px'
      this.$refs.asideRef.style.minWidth = '200px'
      this.$refs.contantRef.style.width = '88%'
      this.leftIcon = true
      this.rightIcon = false
    },
    // 岗位列表
    async fetchData(options) {
      this.listLoading = true
      await queryPostPage({
        ...options,
        ...this.forms,
      })
        .then((res) => {
          if (res.data) {
            this.list = res.data.records
            this.total = res.data.total
            this.orgsListTable = res.data.records
          }
        })
        .catch(() => {})
      this.listLoading = false
    },
    // handleNodeClickParentId(data) {
    //   this.parentName = data.postName
    //   this.postForm.parentId = data.id
    //   this.$refs.selectParentId.blur()
    //   this.validateParentId()
    // },
    // validateParentId() {
    //   this.$nextTick(() => {
    //     this.$refs['postForm'].clearValidate('parentId')
    //   })
    // },
    // handleClearParentId() {
    //   this.parentName = ''
    //   this.postForm.parentId = ''
    // },
    // 方法（数组最后一个）
    menuChange(dataSourceLists) {
      if (typeof dataSourceLists === 'string') {
        return dataSourceLists
      } else {
        if (dataSourceLists && dataSourceLists.length) {
          const arrList = dataSourceLists[dataSourceLists.length - 1]
          return arrList
        } else {
          return ''
        }
      }
    },
    // 新增
    createData() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          const parms = {
            postCode: this.postForm.postCode,
            postName: this.postForm.postName,
            postSequence: this.postForm.postSequence,
            parentId: 0,
            orgId: this.menuChange(this.postForm.orgId),
          }
          if (!this.postForm.parentId) {
            this.postForm.parentId = 0
          }
          queryPostAdd(parms).then(() => {
            this.MS('新增成功')
            this.fetchData()
            this.close()
          })
        }
      })
    },
    // 添加子级
    createDataSon() {
      this.$refs['postFormSon'].validate((valid) => {
        if (valid) {
          const parms = {
            postCode: this.postFormSon.postCode,
            postName: this.postFormSon.postName,
            postSequence: this.postFormSon.postSequence,
            parentId: this.rowIdson,
            orgId: this.orgIds,
          }
          queryPostAdd(parms).then(() => {
            this.MS('新增成功')
            this.fetchData()
            this.closeSon()
          })
        }
      })
    },
    // 关闭子级弹框
    closeSon() {
      this.dialogFormVisibleSon = false
      this.$refs['postFormSon'].resetFields()
      this.postFormSon = this.$options.data().postFormSon
    },
    // 添加子级弹框
    handleAddson(row) {
      this.orgIds = row.orgId
      this.rowIdson = row.id
      this.rowPareIdson = row.parentId
      this.dialogFormVisibleSon = true
    },
    // 编辑保存
    updateData() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          const tempData = {}
          tempData.id = this.rowId
          tempData.postCode = this.postForm.postCode
          tempData.postName = this.postForm.postName
          tempData.postSequence = this.postForm.postSequence
          tempData.parentId = this.editParentId
          tempData.orgId = this.menuChange(this.postForm.orgId)
          queryPostUpdate(tempData)
            .then(() => {
              this.MS('编辑成功')
              this.fetchData()
              this.dialogFormVisible = false
            })
            .catch(() => {})
        }
      })
    },

    // 导出
    handleExport() {},
    // 新增/编辑
    handleEdit(row) {
      if (row) {
        this.showEdit(row)
      } else {
        this.showEdit()
      }
    },
    // 添加编辑title
    showEdit(row) {
      if (row) {
        this.editParentId = row.parentId
        this.rowId = row.id
        this.title = '编辑'
        // this.$nextTick(() => {
        //   this.$refs.postForm.clearValidate()
        //   if (this.postForm.parentId === '0') {
        //     this.parentName = '请选择'
        //   } else {
        //     this.parentName = this.$refs.treeParentId.getNode(
        //       this.postForm.parentId
        //     ).label
        //   }
        // })
        this.postForm = Object.assign({}, row)
      } else {
        this.title = '新增'
      }
      this.dialogFormVisible = true
    },
    // 关闭
    close() {
      this.dialogFormVisible = false
      this.parentName = ''
      this.$refs['postForm'].resetFields()
      this.postForm = this.$options.data().postForm
    },

    // 删除
    handleDelete(row) {
      if (row) {
        if (row.children != null) {
          return this.ME('此数据下有子级不可删除')
        }
        if (row.id) {
          this.$confirm(`${row.children == null ? '是否要删除此行?' : '删除此数据将删除整个下级岗位的数据,是否继续?'}`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              queryPostDelete({ id: row.id, isDelete: row.isDelete })
                .then(() => {
                  this.MS('删除成功')
                  this.fetchData()
                  this.dialogFormVisible = false
                })
                .catch(() => {})
            })
            .catch(() => {})
        }
      } else {
        if (this.selectRows.length > 0) {
          const ids = this.selectRows.map((item) => item.id).join(',')
          console.log(ids)
          this.$confirm('是否要删除此行?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {})
            .catch(() => {})
        } else {
          if (this.selectRows.length === 0) {
            this.ME('请勾选需要删除的记录')
            return
          }
        }
      }
    },
    // 列表选中
    setSelectRows(val) {
      this.selectRows = val
    },
    // 重置
    handleReset() {
      this.forms.current = 1
      this.forms.size = 10
      this.queryForm = {}
      this.handleQuery()
    },
    // 搜索
    async handleQuery() {
      this.forms.current = 1
      await this.fetchData({ ...this.queryForm }, false)
      let temp = false
      Object.keys(this.queryForm).forEach((key) => {
        this.queryForm[key] && (temp = true)
      })
      this.isShow = temp
    },
    // 分页
    handleSizeChange(val) {
      this.forms.size = val
      let data = this.isShow === true ? this.queryForm : {}
      this.fetchData({ ...data }, false)
    },
    // 分页
    handleCurrentChange(val) {
      this.forms.current = val
      let data = this.isShow === true ? this.queryForm : {}
      this.fetchData({ ...data }, false)
    },
  },
}
</script>
<style lang="scss" scoped>
.asideAuthorizeUrl {
  // width: 210px;
  // height: 600px;
  // overflow: scroll;
  // border: 1px solid #c0d4f0;
  ::v-deep(.el-table) {
    th.is-leaf {
      padding: 15px 0;
      background: #fff;
    }
  }
  ::v-deep(.el-input) {
    width: 130px;
    padding: 10px;
  }
  ::v-deep .aside-table td,
  .aside-table th.is-leaf {
    // border: none;
    cursor: pointer;
  }
  ::v-deep(.aside-table th) {
    .cell {
      font-weight: 700 !important;
      color: #909399 !important;
    }
  }
  ::v-deep(.aside-table::before) {
    width: 0;
  }
}
.clickLine {
  width: 8px;
  height: 700px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: #c0d4f0;
  .clickLineLeft {
    position: relative;
    top: 50%;
    left: -3px;
    color: #97a8be;
    cursor: pointer;
  }
  .clickLineRight {
    position: relative;
    top: 50%;
    left: -5px;
    color: #97a8be;
    cursor: pointer;
  }
}
.postPare {
  .el-select {
    width: 100%;
  }
}
</style>
